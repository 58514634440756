import { useInterpret } from "@xstate/react";
import { type PropsWithChildren, createContext } from "react";
import type { InterpreterFrom } from "xstate";
import { type MapInteractionStateMachine, defaultMapInteractionMachine } from "./mapInteractionStateMachine";

export const MapInteractionStateMachineContext = createContext<StateMachineContext>({
  mapInteractionService: {} as InterpreterFrom<MapInteractionStateMachine>,
});

type StateMachineContext = {
  mapInteractionService: InterpreterFrom<MapInteractionStateMachine>;
};

export const MapInteractionStateMachineProvider = (props: PropsWithChildren<any>) => {
  const mapInteractionService = useInterpret(defaultMapInteractionMachine, { devTools: true });

  return (
    <MapInteractionStateMachineContext.Provider value={{ mapInteractionService: mapInteractionService }}>
      {props.children}
    </MapInteractionStateMachineContext.Provider>
  );
};

import { parseHexString, RgbaColor } from "color-lib";
import { ValueRange } from "./ValueRange";

interface Sample {
  position: number;
  color: RgbaColor;
}

export interface ColorMapResponse_Transferable {
  readonly usualValueRange: ValueRange;
  readonly samples: RgbaColor[];
}

export class ColorMapResponse {
  /**
   * Wrap the raw response of a color map.
   *
   * @param usualValueRange Its recommended to apply the color map to the given range, clamping values outside
   * @param samples equidistant samples of the color map
   */
  constructor(
    readonly usualValueRange: ValueRange,
    readonly samples: RgbaColor[],
  ) {}

  /**
   * Parse a CSV API response
   *
   * - Valid format has a single line header
   * - at least two sample lines of the transfer function
   * - each sample has format `position;rgbaHexColor`
   * - samples are equidistant, sorted from lowest to highest position
   *
   * @param txt
   */
  static parseFromCsv(txt: string): ColorMapResponse {
    // TODO: rewrite this as a clean parser

    const format = txt
      .split("\n")
      .filter((l) => l.trim() !== "")
      .map((line, lineNumber) => ({ line, lineNumber }));
    const regex = /^(?<position>-?[0-9]+(?:\.[0-9]+)?(?:e[+-]?[0-9]+)?);#(?<hexColor>[A-F0-9]{8})$/;

    const readSample = (v: undefined | { line: string; lineNumber: number }): Sample => {
      if (!v) {
        throw Error(`unexpected end of CSV`);
      }
      const { line, lineNumber } = v;

      const groups = line?.match(regex)?.groups!;
      if (!groups) {
        throw Error(`'${line}' not a valid sample at line ${lineNumber}`);
      }
      const color = parseHexString(groups.hexColor);
      if (!color) {
        throw Error(`'${groups.hexColor}' not a valid color at line ${lineNumber}`);
      }
      const position = parseFloat(groups.position);
      return { position, color };
    };

    const _header = format.shift();
    const firstLine = readSample(format.shift());
    const lastLine = readSample(format.pop());
    const otherLines = format.map((v, idx) => readSample(v));

    // the response is a equidistant sampling in descending order. reverse the map to ascending order.
    const usualValueRange = {
      lowerBound: lastLine.position,
      upperBound: firstLine.position,
    };

    const samples = [firstLine, ...otherLines, lastLine].map((sample) => sample.color).reverse();

    return new ColorMapResponse(usualValueRange, samples);
  }

  toTransferable(): ColorMapResponse_Transferable {
    return {
      usualValueRange: this.usualValueRange,
      samples: this.samples,
    };
  }

  static fromTransferable(v: ColorMapResponse_Transferable): ColorMapResponse {
    return new ColorMapResponse(v.usualValueRange, v.samples);
  }
}

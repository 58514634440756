import { ReservedValues } from "@/constants/reservedValues";
import type { JsonWithParameter } from "@/json";
import type { WeatherSymbolSeriesOptions } from "@/overlay/components/CommonParts/PlotHighchart/plugins/weatherSymbol";
import type { CustomWindBarbSeriesOptions } from "@/overlay/components/CommonParts/PlotHighchart/plugins/windbarbs";
import { CalibratedLabel } from "@/overlay/components/LayerStackWindow/LayerFormElements";
import type { CustomSeriesOptionsType } from "@/plot";
import type {
  PlotSeriesType,
  PlotSeriesZone,
  PlotSeriesZoneSafe,
  SeriesDashStyles,
  SeriesTypes,
} from "@/reducer/client-models";
import { applyRoundingFilter } from "@/utility/geojsonValueRounding";
import type { ModelSchema } from "@mm/api-layers.meteomatics.com";
import type { ParameterUnit } from "@mm/api.meteomatics.com";
import type * as Highcharts from "highcharts";
import type {
  SeriesAreaOptions,
  SeriesArearangeOptions,
  SeriesAreasplineOptions,
  SeriesColumnOptions,
  SeriesLineOptions,
  SeriesOptionsType,
  SeriesScatterOptions,
  SeriesSplineOptions,
  SeriesWindbarbOptions,
} from "highcharts";
import { DateTime } from "luxon";

export type AvailableHighchartsSeriesOptions =
  | SeriesSplineOptions
  | SeriesLineOptions
  | SeriesColumnOptions
  | SeriesAreaOptions
  | SeriesAreasplineOptions
  | SeriesScatterOptions
  | SeriesWindbarbOptions
  | CustomWindBarbSeriesOptions
  | WeatherSymbolSeriesOptions
  | SeriesArearangeOptions;

export function createHighchartsSeriesId(
  model: string,
  parameter: string,
  idx: number,
  plotSeriesOptions: Omit<PlotSeriesType, "model" | "parameter_unit"> | undefined,
): { id: string; name: string } {
  const calibratedStr = plotSeriesOptions?.calibrated ? ` (${CalibratedLabel()})` : "";
  return { id: `${model} ${parameter} ${idx}`, name: `${model} ${parameter}${calibratedStr}` };
}

export function parameterAndIndex(parameter: string, index: number) {
  return `${parameter}_${index}`;
}

export function createHighchartsSeriesWithErrorInformation(
  index: number,
  mapOrPlotTitle: string | undefined,
  errorText: string,
  params: { model: ModelSchema; parameter: ParameterUnit },
  plotSerieOptions: Omit<PlotSeriesType, "model" | "parameter_unit"> | undefined,
): SeriesOptionsType[] {
  const seriesOptions: Highcharts.SeriesOptionsType[] = [];
  const { model, parameter } = params;
  const { id: seriesId } = createHighchartsSeriesId(model.identifier, parameter, index, plotSerieOptions);
  const seriesName = `${mapOrPlotTitle ? `${mapOrPlotTitle} ` : ""} | ${errorText}`;
  const seriesData: AvailableHighchartsSeriesOptions = {
    id: seriesId,
    zIndex: index,
    name: seriesName,
    data: [],
    type: "spline",
    dashStyle: "Solid",
    color: "#000000",
    yAxis: 0,
    visible: true,
  };
  seriesOptions.push(seriesData);
  return seriesOptions;
}

export function convertApiSinglePointJSONToPlotWindBarbSeries(
  index: number,
  data: JsonWithParameter,
  param: { model: ModelSchema; parameter: ParameterUnit },
  plotSeriesOptions: Omit<PlotSeriesType, "model" | "parameter_unit"> | undefined,
): CustomSeriesOptionsType[] {
  const seriesOptions: CustomSeriesOptionsType[] = [];
  const model = param.model;

  const [[windSpeeds], [windDirs]] = data;

  const dataArray = windSpeeds.dates.map((windSpeed, index) => {
    return [DateTime.fromISO(windSpeed.date).toMillis(), windSpeed.value, windDirs.dates[index].value];
  });
  const { id: seriesId, name } = createHighchartsSeriesId(
    model.identifier,
    windSpeeds.parameter,
    index,
    plotSeriesOptions,
  );
  const seriesData: AvailableHighchartsSeriesOptions = {
    id: seriesId,
    zIndex: index,
    name: name,
    data: dataArray,
    type: "windbarb",
    yAxis: plotSeriesOptions?.series_y_axis ?? 0,
    visible: plotSeriesOptions?.show !== undefined ? plotSeriesOptions?.show : true,
    threshold: null,
    color: plotSeriesOptions?.series_color,
  };

  seriesOptions.push(seriesData);

  return seriesOptions;
}
export function converterApiSinglePointJSONToAreaRangeSeries(
  index: number,
  data: JsonWithParameter,
  param: { model: ModelSchema; parameter: ParameterUnit },
  plotSeriesOptions: Omit<PlotSeriesType, "model" | "parameter_unit"> | undefined,
): Highcharts.SeriesOptionsType[] {
  const firstData = data[0][0];
  const secondData = data[1][0];
  let newData: [number, number, number][] = [];
  let parameters = "";
  if (firstData.dates.length === secondData.dates.length) {
    parameters = `${firstData.parameter} ${secondData.parameter}`;
    newData = firstData.dates.map((val, idx) => {
      const firstValue = !ReservedValues.includes(val.value) ? val.value : null;
      const secondValue =
        secondData.dates[idx].date === val.date
          ? !ReservedValues.includes(secondData.dates[idx].value)
            ? secondData.dates[idx].value
            : null
          : null;
      return [new Date(val.date).valueOf(), firstValue, secondValue];
    });
  }
  const model = param.model;
  const { id: seriesId, name: seriesName } = createHighchartsSeriesId(
    model.identifier,
    parameters,
    index,
    plotSeriesOptions,
  );
  const seriesOptionType: SeriesArearangeOptions[] = [
    {
      id: seriesId,
      zIndex: index,
      name: seriesName,
      lineWidth: 1,
      type: "arearange",
      fillOpacity: 0.3,
      yAxis: plotSeriesOptions?.series_y_axis ?? 0,
      visible: plotSeriesOptions?.show !== undefined ? plotSeriesOptions?.show : true,
      data: newData,
    },
  ];

  if (plotSeriesOptions?.zones) {
    seriesOptionType[0].zones = plotSeriesNullToZero(plotSeriesOptions.zones);
  } else if (plotSeriesOptions?.series_negative_color) {
    seriesOptionType[0].zones = [
      {
        value: plotSeriesOptions.series_threshold,
        color: plotSeriesOptions.series_negative_color,
      },
    ];
  }

  return seriesOptionType;
}
export function converterApiSinglePointJSONToPlotSeries(
  index: number,
  data: JsonWithParameter,
  param: { model: ModelSchema; parameter: ParameterUnit },
  plotSeriesOptions: Omit<PlotSeriesType, "model" | "parameter_unit"> | undefined,
): CustomSeriesOptionsType[] {
  const seriesOptions: CustomSeriesOptionsType[] = [];

  const model = param.model;
  for (const tmpData of data) {
    for (const subData of tmpData) {
      const { id: seriesId, name: seriesName } = createHighchartsSeriesId(
        model.identifier,
        subData.parameter,
        index,
        plotSeriesOptions,
      );
      const seriesData: AvailableHighchartsSeriesOptions = {
        id: seriesId,
        zIndex: index,
        name: seriesName,
        data: subData.dates.map((obj) => [
          new Date(obj.date).valueOf(),
          applyValueFilters(subData.parameter, obj.value),
        ]),
        type: (plotSeriesOptions?.series_type as SeriesTypes) ?? "spline",
        dashStyle: (plotSeriesOptions?.series_line_type as SeriesDashStyles) ?? "Solid",
        yAxis: plotSeriesOptions?.series_y_axis ?? 0,
        visible: plotSeriesOptions?.show !== undefined ? plotSeriesOptions?.show : true,
        negativeColor: undefined,
        dataLabels: { enabled: !!plotSeriesOptions?.show_series_label },
      };
      // we don't set color on models with num_ensemble_members so that we get different colors on each series
      if (!model.num_ensemble_members) {
        seriesData.color = plotSeriesOptions?.series_color ?? "#000000";
      }

      if (plotSeriesOptions?.zones) {
        seriesData.zones = plotSeriesNullToZero(plotSeriesOptions.zones);
      } else if (plotSeriesOptions?.series_negative_color) {
        seriesData.zones = [
          {
            value: plotSeriesOptions.series_threshold,
            color: plotSeriesOptions.series_negative_color,
          },
        ];
      }

      seriesOptions.push(seriesData);
    }
  }
  return seriesOptions;
}

export function applyValueFilters(parameterUnit: string, value: any) {
  const roundedValueStr = applyRoundingFilter(parameterUnit, value);
  const roundedValue = Number.parseFloat(roundedValueStr);
  return !ReservedValues.includes(roundedValue) ? roundedValue : null;
}

export type RawWeatherColumnData = {
  id: string;
  name: string;
  sort?: string;
  dataMap: WeatherColumnDataMap;
};

export function converterApiSinglePointJSONToTableColumnData(
  data: JsonWithParameter,
  columnModel: string,
  columnIdx: number,
  sort?: string,
): RawWeatherColumnData[] {
  const columnData: RawWeatherColumnData[] = [];
  for (const dataPointList of data) {
    for (const dataPoint of dataPointList) {
      const rawColumnData: RawWeatherColumnData = {
        id: columnModel + dataPoint.parameter,
        // needs to be same as field from group.columns
        name: parameterAndIndex(dataPoint.parameter, columnIdx),
        sort,
        dataMap: createMappingFromSeries(dataPoint.dates),
      };

      columnData.push(rawColumnData);
    }
  }

  return columnData;
}

export type WeatherColumnDataMap = { [key: string]: any };

function createMappingFromSeries(rawData: { date: string; value: any }[]): WeatherColumnDataMap {
  const mapping: WeatherColumnDataMap = {};
  for (const item of rawData) {
    mapping[item.date] = item.value;
  }
  return mapping;
}
const plotSeriesNullToZero = (zones: PlotSeriesZone[]): PlotSeriesZoneSafe[] => {
  return zones.map(({ color, value }) => ({ color, value: value !== null ? value : 0 }));
};

import { PrivateRoute } from "@/auth";
import { apiThreadPool } from "@/cache/SpatioTemporalTileCache/ApiQueryThreadPool";
import ProfilePage from "@/pages/profile";
import * as route from "@/route";
import ActivateWorkspace from "@/route/activate/workspace";
import React, { useEffect } from "react";
import { Redirect, Switch } from "react-router-dom";

export function BetaArea() {
  useEffect(() => {
    apiThreadPool
      .switchApiEndpoint()
      .then(() => {})
      .catch((e) => {
        console.error("Could not switch to other api", e);
      });
  }, []);

  return (
    <Switch>
      <PrivateRoute exact={true} path={route.pageBeta.pattern() + route.pageProfiles.pattern()}>
        <ProfilePage />
      </PrivateRoute>
      <PrivateRoute exact={true} path={route.pageBetaWorkspace.pattern()}>
        <ActivateWorkspace />
      </PrivateRoute>
      <Redirect exact from={""} to={route.pageBeta.pattern() + route.pageProfiles.pattern()} />
    </Switch>
  );
}

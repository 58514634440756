import { SynchrounousState } from "@/cache/AsyncResult";
import type { MultiWMSTile } from "@/cache/PVSTileService/TileGetters/MultiWMSTileGetter";
import { createUnprojectedTileProgram } from "../shaders";
import { bindAttribute, bindFramebuffer, createBuffer, createEmptyPixels, createTexture } from "../util";

export const createSliceRenderer = (gl: WebGLRenderingContext, width: number, height: number) => {
  const _sliceDrawingProgram = createUnprojectedTileProgram(gl);
  const _frameBuffer = gl.createFramebuffer();
  const _texture = createTexture(gl, gl.LINEAR, createEmptyPixels(width, height), width, height);
  const _quadBuffer = createBuffer(gl, new Float32Array([0, 0, 1, 0, 0, 1, 0, 1, 1, 0, 1, 1]));

  const render = (slice: MultiWMSTile) => {
    const { synchronous } = _sliceDrawingProgram;

    if (
      synchronous === SynchrounousState.StillPending ||
      synchronous === SynchrounousState.PermanentlyFailed ||
      synchronous === SynchrounousState.NotRequested
    ) {
      return;
    }

    const { program, parameters } = synchronous;

    gl.useProgram(program);

    bindFramebuffer(gl, _frameBuffer, _texture);
    gl.viewport(0, 0, width, height);
    gl.clear(gl.COLOR_BUFFER_BIT);

    const [vectorU, vectorV] = slice.data;

    bindAttribute(gl, _quadBuffer, Number(parameters.a_pos), 2);

    gl.activeTexture(gl.TEXTURE0);
    const tileTexture = vectorU.texture(gl);
    gl.uniform1i(parameters.u_windVectorUSampler, 0);
    gl.bindTexture(gl.TEXTURE_2D, tileTexture);

    gl.activeTexture(gl.TEXTURE1);
    const tileTexture2 = vectorV.texture(gl);
    gl.uniform1i(parameters.u_windVectorVSampler, 1);
    gl.bindTexture(gl.TEXTURE_2D, tileTexture2);

    // Now that we set the metrices in GL context, we run the shader
    gl.drawArrays(gl.TRIANGLES, 0, 6);

    gl.bindRenderbuffer(gl.RENDERBUFFER, null);
  };

  return {
    render,
    texture: _texture,
  };
};

export type SliceRenderer = ReturnType<typeof createSliceRenderer>;

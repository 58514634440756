import { Api } from "@/api/Api";
import { classNames } from "@/utility/jsx";
import { Trans } from "@lingui/macro";
import React, { type ComponentType, type ReactElement, useCallback, useEffect, useRef, useState } from "react";
import styles from "./style.module.scss";

type SessionOverlayProps = {
  sessionActivated: boolean;
  invalid?: boolean;
};

export function withDisabledSessionOverlay<T>(Component: ComponentType<T>) {
  return (hocProps: T & SessionOverlayProps): ReactElement => {
    const { invalid } = hocProps;

    return (
      <>
        {invalid && <InvalidSessionNotification />}
        {/* @ts-ignore */}
        <div className={invalid ? styles["invalid-session"] : ""}>{React.createElement(Component, hocProps)}</div>
      </>
    );
  };
}

export function InvalidSessionNotification() {
  return (
    <div className={styles["invalid-session-container"]}>
      <div className={styles["invalid-session-container__notification"]}>
        <div>
          <span className={classNames("material-icons", styles.icon)}>warning</span>
          <span>
            <Trans>
              A User is allowed to login only by one device at the same time. To use MetX with multiple users, please
              contact our customer support at{" "}
              <a href="mailto:support@meteomatics.com?subject=Use MetX with multiple users">support@meteomatics.com</a>.
            </Trans>
          </span>
        </div>
      </div>
    </div>
  );
}

export const PING_INTERVAL: number = 60000;

// Helper function for useSyncInterval
function timeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

async function ping(delayInMilliSecs: number): Promise<Response> {
  try {
    const [, result] = await Promise.all([timeout(delayInMilliSecs), Api.session.v1PingSessionRaw()]);
    return result.raw;
  } catch (e) {
    console.error(e?.toString());
    throw e;
  }
}

export function usePingInterval(delayInMilliSecs: number) {
  // Ref to make sure, that single ping request is active at same time
  const pingInProgress = useRef(false);

  const [result, setResult] = useState<Response>();
  const [active, setActive] = useState(false);

  const [loopMutex, setLoopMutex] = useState(true);

  // Exploit useEffect to make async fetch loop
  useEffect(() => {
    if (active && !pingInProgress.current) {
      pingInProgress.current = true;
      ping(delayInMilliSecs)
        .then((res) => {
          setResult(res);
          setLoopMutex(!loopMutex);
          pingInProgress.current = false;
        })
        .catch((err) => {
          setResult(err);
          pingInProgress.current = false;
        });
    }
  }, [active, loopMutex, pingInProgress, delayInMilliSecs]);

  const start = useCallback(() => {
    setActive(true);
  }, [setActive]);

  return { result, start };
}

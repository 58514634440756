import { SynchrounousState } from "@/cache/AsyncResult";
import { constructHashId } from "@/cache/CustomGeoJSONCache/utils";
import { networkCaches } from "@/cache/GlobalCache";
import { customGeoJsonLayerConfig } from "@/constants/layerConfigAttributes";
import { emptyGeoJsonSource } from "@/geojson";
import { type CheckedProps, LayerBase, type PropsChecker } from "@/layers";
import { ChangeDetector } from "@/layers/ChangeDetector";
import type { ScenePublicApi } from "@/layers/Compositor";
import { customGeoJSONMapboxLayerGroup, thresholdToExpression } from "@/layers/geojson/custom/utils";
import {
  type MapboxLayerGroup,
  MapboxSourceGroup,
  addLayerGroup,
  addSource,
  moveZIndex,
  removeLayerGroup,
  removeSource,
  updateMapboxLayoutProperties,
  updateMapboxPaintProperties,
  updateSourceData,
} from "@/layers/utility/mapbox-utils";
import { safeFmt } from "@/utility/safeFmt";
import type { BaseRequest } from "@mm/api.meteomatics.com/lib/models/BaseRequest";
import type { CustomGeoJSONCustomOptions, CustomGeoJSONLayer, GuiTimeZone } from "@mm/metx-workbench.meteomatics.com";
import { isEmpty } from "lodash";
import type { DateTime } from "luxon";

export type CustomGeoJsonRequestOption = Pick<CustomGeoJSONLayer, "file_id" | "model" | "parameter_unit"> & {
  customOption: CustomGeoJSONCustomOptions;
};

export class CustomGeoJSONLayerImpl extends LayerBase<CustomGeoJSONLayer> {
  private dirty = true;
  private displayDateTime: ChangeDetector<string>;
  private thresholdFlag: ChangeDetector<boolean> = new ChangeDetector<boolean>(false);

  private mapboxLayerGroup: MapboxLayerGroup<"line-layer" | "point-layer" | "polygon-layer" | "polygon-layer-outline">;

  private mapboxSrcGroup: MapboxSourceGroup<"line-data-source" | "point-data-source" | "polygon-data-source">;

  readonly mapbox_id: string;
  readonly mapbox_source: string;

  constructor(id: number, props: CustomGeoJSONLayer, scene: ScenePublicApi, timezone: GuiTimeZone) {
    super(id, props, scene, timezone);
    this.displayDateTime = new ChangeDetector(this.scene.getDisplayTimeWithOffset().toISO());
    this.mapbox_id = this.humanReadableId();
    this.mapbox_source = safeFmt`source_${this.humanReadableId()}`;

    this.mapboxSrcGroup = new MapboxSourceGroup(this.mapbox_id, [
      "line-data-source",
      "point-data-source",
      "polygon-data-source",
    ]);

    addSource(this.scene.getMapboxMap(), [
      {
        sourceId: this.mapboxSrcGroup.id("line-data-source"),
        source: emptyGeoJsonSource(),
      },
      {
        sourceId: this.mapboxSrcGroup.id("point-data-source"),
        source: emptyGeoJsonSource(),
      },
      {
        sourceId: this.mapboxSrcGroup.id("polygon-data-source"),
        source: emptyGeoJsonSource(),
      },
    ]);

    this.mapboxLayerGroup = customGeoJSONMapboxLayerGroup(this.mapbox_id, props, [
      this.mapboxSrcGroup.id("point-data-source"),
      this.mapboxSrcGroup.id("line-data-source"),
      this.mapboxSrcGroup.id("polygon-data-source"),
    ]);
    addLayerGroup(this.scene.getMapboxMap(), this.mapboxLayerGroup.initialSpecs());
  }

  beforeRender(): void {
    // If display datetime has changed, redraw is necessary
    if (this.displayDateTime.changed(this.scene.getDisplayTimeWithOffset().toISO())) {
      this.dirty = true;
    }

    // If layer is dirty, trigger redraw and reset flag to indicate, that it's clean
    if (this.dirty) {
      this.updateGeoJsonSource(
        {
          file_id: this.props.file_id,
          model: this.props.model,
          parameter_unit: this.props.parameter_unit,
          customOption: this.props.custom_options,
        },
        this.scene.getDisplayTimeWithOffset(),
      );

      const thresholdFlagChanged = this.thresholdFlag.changed(!isEmpty(this.props.thresholds));

      if (thresholdFlagChanged) {
        const colorExpression = thresholdToExpression(
          this.props.thresholds,
          this.props.custom_options.line_color || "black",
        );

        updateMapboxPaintProperties(this.scene.getMapboxMap(), this.mapboxLayerGroup.id("polygon-layer"), {
          "fill-opacity": this.props.opacity || 1,
          "fill-color": colorExpression,
        });
        updateMapboxPaintProperties(this.scene.getMapboxMap(), this.mapboxLayerGroup.id("polygon-layer-outline"), {
          "line-opacity": this.props.opacity || 1,
          "line-color": colorExpression,
          "line-width": this.props.custom_options.line_width || customGeoJsonLayerConfig.defaultLineWidth,
        });
        updateMapboxPaintProperties(this.scene.getMapboxMap(), this.mapboxLayerGroup.id("line-layer"), {
          "line-opacity": this.props.opacity || 1,
          "line-color": colorExpression,
          "line-width": this.props.custom_options.line_width || customGeoJsonLayerConfig.defaultLineWidth,
        });
      }

      this.dirty = false;
    }
  }

  checker(): PropsChecker<CustomGeoJSONLayer, LayerBase<CustomGeoJSONLayer>> {
    type CheckerProps = CheckedProps<CustomGeoJSONLayer>;
    const checkLayerProp = (property: CheckerProps) => (prev: any, curr: any) => {
      const changed = prev[property] !== curr[property];
      if (changed) {
        this.setLayerProps({ [property]: curr[property] });
        switch (property) {
          case "show":
            updateMapboxLayoutProperties(
              this.scene.getMapboxMap(),
              this.mapboxLayerGroup.id("line-layer", "point-layer"),
              {
                visibility: curr.show ? "visible" : "none",
              },
            );
            updateMapboxLayoutProperties(this.scene.getMapboxMap(), this.mapboxLayerGroup.id("polygon-layer"), {
              visibility: !isEmpty(this.props.thresholds) && this.props.show ? "visible" : "none",
            });
            updateMapboxLayoutProperties(this.scene.getMapboxMap(), this.mapboxLayerGroup.id("polygon-layer-outline"), {
              visibility: isEmpty(this.props.thresholds) && this.props.show ? "visible" : "none",
            });
            break;
          case "opacity":
            updateMapboxPaintProperties(this.scene.getMapboxMap(), this.mapboxLayerGroup.id("line-layer"), {
              "line-opacity": curr.opacity,
            });
            updateMapboxPaintProperties(this.scene.getMapboxMap(), this.mapboxLayerGroup.id("polygon-layer-outline"), {
              "line-opacity": curr.opacity,
            });
            updateMapboxPaintProperties(this.scene.getMapboxMap(), this.mapboxLayerGroup.id("point-layer"), {
              "circle-opacity": curr.opacity,
            });
            updateMapboxPaintProperties(this.scene.getMapboxMap(), this.mapboxLayerGroup.id("polygon-layer"), {
              "fill-opacity": curr.opacity,
            });
            break;
          case "custom_options":
            {
              if (
                curr.custom_options.segmentLength !== prev.custom_options.segmentLength ||
                curr.custom_options.units !== prev.custom_options.units
              ) {
                this.dirty = true;
                break;
              }

              const colorExpression = thresholdToExpression(curr.thresholds, curr.custom_options.line_color || "black");

              updateMapboxPaintProperties(this.scene.getMapboxMap(), this.mapboxLayerGroup.id("line-layer"), {
                "line-color": colorExpression,
                "line-width": curr.custom_options.line_width,
              });
              updateMapboxPaintProperties(
                this.scene.getMapboxMap(),
                this.mapboxLayerGroup.id("polygon-layer-outline"),
                {
                  "line-color": colorExpression,
                  "line-width": curr.custom_options.line_width,
                },
              );
              updateMapboxPaintProperties(this.scene.getMapboxMap(), this.mapboxLayerGroup.id("point-layer"), {
                "circle-color": curr.custom_options.line_color,
              });
              updateMapboxPaintProperties(this.scene.getMapboxMap(), this.mapboxLayerGroup.id("polygon-layer"), {
                "fill-color": colorExpression,
              });
            }
            break;
          case "file_id":
            this.dirty = true;
            break;
          case "calibrated":
            break;
          // TODO Handle threshold change here
          case "thresholds":
            {
              const colorExpression = thresholdToExpression(curr.thresholds, curr.custom_options.line_color || "black");

              updateMapboxPaintProperties(this.scene.getMapboxMap(), this.mapboxLayerGroup.id("polygon-layer"), {
                "fill-color": colorExpression,
              });
              updateMapboxLayoutProperties(this.scene.getMapboxMap(), this.mapboxLayerGroup.id("polygon-layer"), {
                visibility: !isEmpty(this.props.thresholds) && this.props.show ? "visible" : "none",
              });

              updateMapboxPaintProperties(this.scene.getMapboxMap(), this.mapboxLayerGroup.id("line-layer"), {
                "line-color": colorExpression,
              });

              updateMapboxLayoutProperties(
                this.scene.getMapboxMap(),
                this.mapboxLayerGroup.id("polygon-layer-outline"),
                {
                  visibility: isEmpty(this.props.thresholds) && this.props.show ? "visible" : "none",
                },
              );
            }
            break;
          // TODO Handle model and parameter_unit change here
          case "model":
          case "ens_select":
          case "parameter_unit":
            this.dirty = true;
            break;
          default: {
            const _exhaustive: never = property;
            return _exhaustive;
          }
        }
      }
      return changed;
    };
    return {
      calibrated: checkLayerProp("calibrated"),
      file_id: checkLayerProp("file_id"),
      opacity: checkLayerProp("opacity"),
      show: checkLayerProp("show"),
      custom_options: checkLayerProp("custom_options"),
      parameter_unit: checkLayerProp("parameter_unit"),
      ens_select: checkLayerProp("ens_select"),
      thresholds: checkLayerProp("thresholds"),
      model: checkLayerProp("model"),
    };
  }
  private updateGeoJsonSource(customGeoJsonRequestOption: CustomGeoJsonRequestOption, datetime?: DateTime) {
    const { asynchronous } = networkCaches.custom_geojson_cache.retrieveCustomGeoJSON(
      customGeoJsonRequestOption,
      datetime,
    );

    asynchronous.then((slicedGeoJSON) => {
      updateSourceData(
        this.scene.getMapboxMap(),
        this.mapboxSrcGroup.id("line-data-source"),
        slicedGeoJSON.lineStrings,
      );
      updateSourceData(this.scene.getMapboxMap(), this.mapboxSrcGroup.id("point-data-source"), slicedGeoJSON.points);
      updateSourceData(
        this.scene.getMapboxMap(),
        this.mapboxSrcGroup.id("polygon-data-source"),
        slicedGeoJSON.polygons,
      );
    });
  }

  createRequest(): BaseRequest {
    return { url: this.props.file_id };
  }
  getActiveWeatherParametersAsString(): { model: string; parameter: string }[] {
    return [];
  }

  fetchData(_timeFrame: DateTime): Promise<void> {
    return new Promise((resolve) => resolve());
  }

  private humanReadableId() {
    return safeFmt`metx.customgeojsonlayer#${this.uid}`;
  }

  get mapboxIndex(): string {
    return this.mapboxLayerGroup.mainId();
  }

  isRebuffering(time: DateTime): boolean {
    const cacheId = constructHashId(this.props.file_id, time, this.props.model, this.props.parameter_unit);

    const { synchronous } = networkCaches.custom_geojson_cache.peekCache(cacheId);
    return synchronous === SynchrounousState.StillPending;
  }

  removeLayer(): void {
    removeLayerGroup(this.scene.getMapboxMap(), this.mapboxLayerGroup.allIds());
    removeSource(this.scene.getMapboxMap(), this.mapboxSrcGroup.allIds());
  }

  moveZIndex(beforeLayerId?: string | undefined): void {
    moveZIndex(this.scene.getMapboxMap(), this.mapboxLayerGroup.allIds(), beforeLayerId);
  }
}

import type { DateTime } from "luxon";
import React from "react";
type CurrentTimeType = {
  currentTime: Map<number, DateTime>;
  setCurrentTime: (currentTime: Map<number, DateTime>) => void;
};

// Note: the default value here should not be used and overwritten at declaration time
export const CurrentTimeContext = React.createContext<CurrentTimeType>({
  currentTime: new Map(),
  setCurrentTime: (currentTime: Map<number, DateTime>) => {
    throw new Error("should overwrite setter");
  },
});

// If you're creating a new repository, it's recommended that you use one the following naming conventions:
// openfeature-provider-<language name>
// <vender/tool name>-openfeature-provider-<language name>

import type { EvaluationContext, JsonValue, Provider, ResolutionDetails } from "@openfeature/web-sdk";
import type { ClientContext } from "featurehub-javascript-client-sdk";
import { EdgeFeatureHubConfig } from "featurehub-javascript-client-sdk";

export class FeatureHubProvider implements Provider {
  public readonly runsOn = "client";
  readonly metadata = {
    name: "FeatureHub Provider",
  } as const;

  private featureHubConfig: EdgeFeatureHubConfig;
  private featureHubClient?: ClientContext;

  constructor(edgeUrl: string, apiKey: string) {
    this.featureHubConfig = new EdgeFeatureHubConfig(edgeUrl, apiKey);
  }

  resolveBooleanEvaluation(flagKey: string, defaultValue: boolean): ResolutionDetails<boolean> {
    const booleanFeatureFlag = this.featureHubClient?.getBoolean(flagKey);

    if (booleanFeatureFlag === undefined) {
      return { value: defaultValue };
    }

    return {
      value: booleanFeatureFlag,
    };
  }
  resolveStringEvaluation(flagKey: string, defaultValue: string): ResolutionDetails<string> {
    const stringFeatureFlag = this.featureHubClient?.getString(flagKey);

    if (stringFeatureFlag === undefined) {
      return { value: defaultValue };
    }

    return {
      value: stringFeatureFlag,
    };
  }
  resolveNumberEvaluation(flagKey: string, defaultValue: number): ResolutionDetails<number> {
    const numberFeaturFlag = this.featureHubClient?.getNumber(flagKey);

    if (numberFeaturFlag === undefined) {
      return { value: defaultValue };
    }

    return {
      value: numberFeaturFlag,
    };
  }
  resolveObjectEvaluation<T extends JsonValue>(flagKey: string, defaultValue: T): ResolutionDetails<T> {
    const jsonFeaturFlag = this.featureHubClient?.getJson(flagKey);

    if (jsonFeaturFlag === undefined) {
      return { value: defaultValue };
    }

    return {
      value: jsonFeaturFlag,
    };
  }

  onContextChange(oldContext: EvaluationContext, newContext: EvaluationContext): Promise<void> {
    // Update only userKey for now, but it's possible to pass any value into context
    if (newContext.userKey !== oldContext.userKey) {
      return this.featureHubConfig
        .newContext()
        .userKey(newContext.userKey as string)
        .build()
        .then((context) => {
          this.featureHubClient = context;
          return Promise.resolve();
        });
    }

    return Promise.resolve();
  }

  initialize(context?: EvaluationContext | undefined): Promise<void> {
    this.featureHubConfig.init();
    return this.featureHubConfig
      .newContext()
      .userKey((context?.userKey as string) || "")
      .build()
      .then((client) => {
        this.featureHubClient = client;
        return Promise.resolve();
      });
  }

  onClose?(): Promise<void> {
    this.featureHubClient?.close();
    return Promise.resolve();
  }
}

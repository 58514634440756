import { compile } from "ejs";
import type { MapboxGeoJSONFeature } from "mapbox-gl";

import template from "./templates/poi.ejs";

const SYSTEM_PROPERTIES = ["value", "item-color", "item-icon", "item-text", "item-icon-size", "item-text-size"];

const compiledTemplate = compile(template);

export function createPoiTooltip(columns: { name: string; value: any }[]) {
  return compiledTemplate({ columns });
}

export function extractColumnsFromProperties({ properties }: MapboxGeoJSONFeature) {
  return properties
    ? Object.keys(properties)
        .filter((key) => !SYSTEM_PROPERTIES.includes(key))
        .map((key) => ({
          name: key,
          value: properties[key] || "",
        }))
    : [];
}

export function handleRenderTooltip(features: MapboxGeoJSONFeature[]) {
  if (features[0]) {
    const columns = extractColumnsFromProperties(features[0]);

    if (columns.length) {
      return createPoiTooltip(columns);
    }
  }
}

import { searchEngineWeatherModels } from "@/weather-parameters";
import type { ModelSchema } from "@mm/api-layers.meteomatics.com";
import { useMemo } from "react";
import type { ModelSearchEngine } from "weather-parameter-utils";

/**
 * Equivalent of useModel, but does not provide memorization and can be used as a normal function.
 */
export function getModel(
  modelIdentifier: string,
  modelSearchEngine: ModelSearchEngine = searchEngineWeatherModels,
): ModelSchema {
  const model: ModelSchema | null = modelSearchEngine.matchModelExactly(modelIdentifier);
  if (!model) {
    // We return the mix model when the model is not found, and assume mix always exist.
    return modelSearchEngine.matchModelExactly("mix") as ModelSchema;
  }
  return model;
}

/**
 * Given a model identifier string (e.g: mix, cmc-gem, etc), returns a model object which you can use to derive the model info.
 */
export function useModel(
  modelIdentifier: string,
  modelSearchEngine: ModelSearchEngine = searchEngineWeatherModels,
): ModelSchema {
  const model: ModelSchema = useMemo(
    () => getModel(modelIdentifier, modelSearchEngine),
    [modelIdentifier, modelSearchEngine],
  );
  return model;
}

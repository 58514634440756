import { RUNTIME, Runtime } from "detect-runtime-lib";
import type { PerformanceHint } from "../performanceIntrospection/PerformanceHint";
import type { Connection } from "./Connection";
import { ConnectionManagement } from "./ConnectionManagement";

// TODO: handle case where multiple api instances are concurrently running, e.g. multiple metx tabs are open.
// In this case, the api limit is still reached and we have to reschedule dynamically

// We do not sell accounts with a smaller budget.
export const GUARANTEED_HARD_PARALLEL_LIMIT = 10;

export const MAX_CONCURRENT_CONNECTIONS_PER_HOST = (() => {
  // This depends on the browser and settings. See https://stackoverflow.com/a/30064610
  // But 6 is a good mostly conservative approximation. This value is only used for
  // optimization and does not affect correctness. So it CAN be incorrect, but might
  // result in suboptimal distribution to proxies.
  switch (RUNTIME) {
    case Runtime.Node:
      return Number.POSITIVE_INFINITY;
    case Runtime.BrowserMain:
    case Runtime.BrowserWorker:
      return 6;
  }
})();

export interface Settings {
  /**
   * Each baseURL is assumed to be on a different host!
   */
  baseUrls: string[];

  hardParallelLimit?: number;
  maxConcurrentConnectionsPerHost?: number;
}

export type ConnectionRange = [number, number];

export interface PendingRequest {
  promise: Promise<Connection>;
  resolve: (_: Connection) => void;
  reject: (_: any) => void;
  connectionRange: ConnectionRange;
  baseUrlSubset: string[];
}

export class HardParallelLimitedConnectionManagement extends ConnectionManagement {
  protected maxConcurrentConnections: number;

  protected settings: Required<Settings>;

  protected inFlightRequestsPerHost: Record<string, number>;
  protected inFlightRequests = 0;
  protected requestQueue: PendingRequest[] = [];

  constructor(settings: Settings) {
    super();

    this.settings = {
      ...settings,
      baseUrls: [...settings.baseUrls],
      hardParallelLimit: settings.hardParallelLimit ?? GUARANTEED_HARD_PARALLEL_LIMIT,
      maxConcurrentConnectionsPerHost: settings.maxConcurrentConnectionsPerHost ?? MAX_CONCURRENT_CONNECTIONS_PER_HOST,
    };

    if (this.settings.baseUrls.length < 2) {
      throw Error("at least two proxies required");
    }

    this.inFlightRequestsPerHost = {};

    for (const url of this.settings.baseUrls) {
      this.inFlightRequestsPerHost[url] = 0;
    }

    this.maxConcurrentConnections = this.updateMaxConcurrentConnections();
    this.updateHardParallelLimit(this.settings.hardParallelLimit);
  }

  getSettings(): Settings {
    return { ...this.settings };
  }

  stats() {
    const s: Record<string, any> = {
      hardParallelLimit: this.settings.hardParallelLimit,
      maxConcurrentConnectionsPerHost: this.settings.maxConcurrentConnectionsPerHost,
      countBaseUrls: this.settings.baseUrls.length,
      maxConcurrentConnections: this.maxConcurrentConnections,
      requestQueueLength: this.requestQueue.length,
      inFlightRequests: this.inFlightRequests,
    };

    for (const host of this.settings.baseUrls) {
      s[`inFlightRequests (${host})`] = this.inFlightRequestsPerHost[host];
    }

    return s;
  }

  getMaxConcurrentConnections() {
    return this.maxConcurrentConnections;
  }

  updateMaxConcurrentConnections() {
    return Math.min(
      this.settings.hardParallelLimit,
      this.settings.maxConcurrentConnectionsPerHost * this.settings.baseUrls.length,
    );
  }

  updateHardParallelLimit(hardParallelLimit: number) {
    this.settings.hardParallelLimit = hardParallelLimit;
    this.maxConcurrentConnections = this.updateMaxConcurrentConnections();
  }

  getBaseUrl(performanceHint?: PerformanceHint): Promise<Connection> {
    const connectionPool = this.getConnectionRange(performanceHint);
    return this.selectConnection(connectionPool);
  }

  private getBaseUrlSubset(connectionRange: ConnectionRange): string[] {
    const start = ~~(connectionRange[0] / this.settings.maxConcurrentConnectionsPerHost);
    const end = ~~((connectionRange[1] - 1) / this.settings.maxConcurrentConnectionsPerHost);
    return [...Array(end - start + 1).keys()].map((i) => i + start).map((hostIdx) => this.settings.baseUrls[hostIdx]);
  }

  /**
   * Enqueue a connection. The promise will resolve as soon as a connectionb becomes available.
   */
  private selectConnection(connectionRange: ConnectionRange): Promise<Connection> {
    const baseUrlSubset = this.getBaseUrlSubset(connectionRange);

    let resolve: PendingRequest["resolve"] | null = null;
    let reject: PendingRequest["reject"] | null = null;

    const promise = new Promise<Connection>((res, rej) => {
      resolve = res;
      reject = rej;
    });

    const pendingRequest: PendingRequest = {
      promise,
      // biome-ignore lint/style/noNonNullAssertion: TODO this is bad
      resolve: resolve!,
      // biome-ignore lint/style/noNonNullAssertion: TODO this is bad too
      reject: reject!,
      connectionRange,
      baseUrlSubset,
    }; // TODO: get rid of unsafe cast

    this.requestQueue.push(pendingRequest);
    this.checkQueue();
    return promise;
  }

  private poolAtCapacity() {
    return this.inFlightRequests >= this.maxConcurrentConnections;
  }

  private hostAtCapacity(baseUrl: string) {
    return this.inFlightRequestsPerHost[baseUrl] >= this.settings.maxConcurrentConnectionsPerHost;
  }

  private checkQueue() {
    const pendingRequests: PendingRequest[] = [];
    while (!this.poolAtCapacity()) {
      const waitingRequest = this.requestQueue.pop();
      if (waitingRequest === undefined) {
        break;
      }
      const baseUrls = waitingRequest.baseUrlSubset;

      let resolved = false;
      for (const baseUrl of baseUrls) {
        if (!this.hostAtCapacity(baseUrl)) {
          // TODO: so, here would be a great point to cancel a request if the user does not want it anymore,
          // our current architecture schedules the connection and aborts it immediately.

          // found a valid schedule point
          this.inFlightRequestsPerHost[baseUrl]++;
          this.inFlightRequests++;

          const free = () => {
            this.inFlightRequestsPerHost[baseUrl]--;
            this.inFlightRequests--;
            this.checkQueue();
          };
          resolved = true;
          waitingRequest.resolve({ baseUrl, free });
          break;
        }
      }
      if (!resolved) {
        pendingRequests.push(waitingRequest);
      }
    }
    this.requestQueue.push(...pendingRequests);

    // Comment in to debug queue state
    // console.log("=== QUEUE STATE ===");
    // console.log("inflight", this.inFlightRequests, "queue", this.requestQueue);
    // for(const baseUrl of this.settings.baseUrls) {
    //     console.log(baseUrl, this.inFlightRequestsPerHost[baseUrl]);
    // }
  }

  /**
   * Get the connection range a request might be scheduled on.
   *
   * @param performanceHint
   * @returns range of valid connections `[min,max)`.
   */
  protected getConnectionRange(performanceHint?: PerformanceHint): ConnectionRange {
    return [0, this.maxConcurrentConnections]; // the whole range
  }

  setHardParallelLimit(hardParallelLimit: number) {
    this.settings.hardParallelLimit = hardParallelLimit;
    this.checkQueue();
  }
}

import { COLOR_HAZARD } from "@/layers/vector/AviationLayer/sublayers/styles";
import styles from "./style.module.scss";

interface InternationalSigmetLegendItemProps {
  color: string;
  label: string;
  value: string;
}

const InternationalSigmetLegendItem = ({ color, label, value }: InternationalSigmetLegendItemProps) => (
  <div style={{ backgroundColor: `hsl(from ${color} h s l / 50%)` }} className={styles["sigmet-legend__box"]}>
    <div className={styles["sigmet-legend__box__title"]}>{value}</div>
    <div className={styles["sigmet-legend__box__caption"]}>{label}</div>
  </div>
);

export const InternationalSigmetLegend = (): JSX.Element => {
  return (
    <div className={styles["sigmet-legend"]}>
      <div className={styles["sigmet-legend__row"]}>
        <InternationalSigmetLegendItem color={COLOR_HAZARD.TURB} label="Turbulence" value="TURB" />
        <InternationalSigmetLegendItem color={COLOR_HAZARD.ICE} label="Icing" value="ICE" />
        <InternationalSigmetLegendItem color={COLOR_HAZARD.TS} label="Thunderstorm" value="TS" />
      </div>
      <div className={styles["sigmet-legend__row"]}>
        <InternationalSigmetLegendItem color={COLOR_HAZARD.VA} label="Volcanic Ash" value="VA" />
        <InternationalSigmetLegendItem color={COLOR_HAZARD.TC} label="Tropical cyclone" value="TC" />
        <InternationalSigmetLegendItem color={COLOR_HAZARD.RDOACT} label="Radioactive Cloud" value="RDOACT" />
      </div>
      <div className={styles["sigmet-legend__row"]}>
        <InternationalSigmetLegendItem color={COLOR_HAZARD.MTW} label="Mountain wave" value="MTW" />
        <InternationalSigmetLegendItem color={COLOR_HAZARD.DS} label="Duststorm" value="DS" />
        <InternationalSigmetLegendItem color={COLOR_HAZARD.SS} label="Sandstorm" value="SS" />
      </div>
    </div>
  );
};

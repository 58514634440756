import { Trans } from "@lingui/macro";
import { useVideoExportContext } from "../VideoExportContext";

import { useProfileAndTabParams } from "@/hooks";
import { useCallback, useEffect } from "react";
import { useVideoGenerator } from "../../generator/videoGenerator";
import style from "./styles.module.scss";

export function VideoProgressOverlay() {
  const { frameRecordingQueueStatus, frameQueue } = useVideoExportContext();
  const { tabId } = useProfileAndTabParams();
  const { cancelUIRecording } = useVideoGenerator(tabId);

  const handleResize = useCallback(() => {
    if (frameQueue && !frameQueue.isCanceled) {
      cancelUIRecording();
    }
  }, [frameQueue, cancelUIRecording]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  if (!frameRecordingQueueStatus) {
    return <></>;
  }

  return (
    <div className={style["recording-overlay"]} data-testid={"recording-overlay"}>
      <div className={style["recording-information-row"]}>
        <div>
          <div className={style["recording-light"]} />
          <Trans>
            Recording {frameRecordingQueueStatus.processedFrames} of {frameRecordingQueueStatus.totalFrames} frames...
          </Trans>
          <button
            type="button"
            className={style["cancel-recording-button"]}
            onClick={() => cancelUIRecording()}
            data-testid={"cancel-video-recording-btn"}
          >
            <Trans>Cancel</Trans>
          </button>
        </div>
      </div>
    </div>
  );
}

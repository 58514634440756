import type { BoundingBox } from "@mm/api-layers.meteomatics.com/models";

export function mercatorXfromLng(lng: number) {
  return (180 + lng) / 360;
}

export function mercatorYfromLat(lat: number) {
  return (180 - (180 / Math.PI) * Math.log(Math.tan(Math.PI / 4 + (lat * Math.PI) / 360))) / 360;
}

export function lngFromMercatorX(x: number) {
  return x * 360 - 180;
}

export function latFromMercatorY(y: number) {
  const y2 = 180 - y * 360;
  return (360 / Math.PI) * Math.atan(Math.exp((y2 * Math.PI) / 180)) - 90;
}

// TODO: the mercator functions should also use the CoordinateSystem and Area abstractions!
export function mercatorFromBoundingBox(bb: BoundingBox): [number, number, number, number] {
  return [
    mercatorYfromLat(bb.north), // north bound
    mercatorXfromLng(bb.west), // west bound
    mercatorYfromLat(bb.south), // south bound
    mercatorXfromLng(bb.east), // east bound
  ];
}

import type { TimeStep } from "@/cache/SpatioTemporalTileCache/TimeStep";
import {
  type DateTimeDesc,
  GuiTimeZone,
  RelativeDateTimePosition,
  RelativeDateTimeRoundingDirection,
} from "@mm/metx-workbench.meteomatics.com";
import {
  type DateObjectUnits,
  DateTime,
  Duration,
  type DurationLikeObject,
  type DurationObjectUnits,
  type DurationUnit,
  Interval,
} from "luxon";

export type DateTimeRoundingUnit = DurationUnit & keyof DateTime;
export type TemporalDiscretization = [number, DateTimeRoundingUnit];

// TODO: this should be a component that renders the format nicely and maybe provides the iso timestamp on hover
export function dateTimeToTimeZoneFormat(dateTime: DateTime, timeZone: GuiTimeZone): string {
  return dateTime.setZone(timeZone).toISO();
}

export function dateTimeToTimeZone(dateTime: DateTime | null, timeZone: GuiTimeZone): DateTime | null {
  if (!dateTime) {
    return null;
  }
  return dateTime.setZone(timeZone);
}

export const defaultRoundingUnit: TemporalDiscretization = [5, "minute"];
export enum RoundingMode {
  Ceil = 0,
  Floor = 1,
}
// round now to some reasonable precision. We do this since most users, for example, will not want to have seconds precision
export function datetime_round_default(datetime: DateTime) {
  return datetime_round_to(datetime, defaultRoundingUnit[1], defaultRoundingUnit[0], RoundingMode.Floor);
}

/**
 * Sets the comparision mode for rounding of datetimes.
 */
export enum RoundingPrecision {
  /**
   * Round to unit precision, e.g. if the rounding unit is "5 minutes" in a hypotetical function
   * `ceil(hh:mm:ss)`, `ceil(00:30:00) == ceil(00:30:01) == 00:30:00`.
   */
  Unit = 0,
  /**
   * Round to nanosecond precision, e.g. if the rounding unit is "5 minutes" in a hypotetical function
   * `ceil(hh:mm:ss)`, `ceil(00:30:00) == 00:30:00`, but ceil(00:30:01) == 00:35:00`.
   */
  ArbitarilyPrecise = 1,
}

function to_zero_based_datetime_unit(val: number, unit: DateTimeRoundingUnit) {
  switch (unit) {
    // one-based
    case "day":
    case "month":
    case "year":
    case "quarter":
      return val - 1;
    // zero-based
    case "hour":
    case "millisecond":
    case "minute":
    case "second":
      return val;
  }
}

// inverse of `to_zero_based_datetime_unit`
function to_natural_based_datetime_unit(val: number, unit: DateTimeRoundingUnit) {
  switch (unit) {
    // one-based
    case "day":
    case "month":
    case "year":
    case "quarter":
      return val + 1;
    // zero-based
    case "hour":
    case "millisecond":
    case "minute":
    case "second":
      return val;
  }
}

export function datetime_round_to(
  datetime: DateTime,
  unit: DateTimeRoundingUnit,
  // biome-ignore lint/style/useDefaultParameterLast: <explanation> TODO rearrange or use if in function body
  multiple = 1,
  roundingMode: RoundingMode,
  roundingPrecision: RoundingPrecision = RoundingPrecision.ArbitarilyPrecise,
) {
  // Floow the time to the unit first.
  let unitVal = datetime.get(unit);
  unitVal = to_zero_based_datetime_unit(unitVal, unit);
  const roundoff = unitVal % multiple;
  let unitValRounded = unitVal - roundoff;
  const setter: Partial<Record<DateTimeRoundingUnit, number>> = {};
  setter[unit] = to_natural_based_datetime_unit(unitValRounded, unit);
  const roundedToUnit = datetime.startOf(unit);
  let roundedToMultipleOfUnit = roundedToUnit.set(setter);
  switch (roundingMode) {
    case RoundingMode.Floor:
      // No more things to do when flooring.
      break;
    case RoundingMode.Ceil:
      // Ceil the time.
      if (
        (roundingPrecision === RoundingPrecision.ArbitarilyPrecise &&
          datetime.toSeconds() > roundedToUnit.toSeconds()) ||
        (roundingPrecision === RoundingPrecision.Unit && roundoff !== 0)
      ) {
        // What if 57 is rounded to 60s, will this correctly convert to minutes? => Looks like it!
        unitValRounded += multiple;
        const setter: Partial<Record<DateTimeRoundingUnit, number>> = {};
        setter[unit] = to_natural_based_datetime_unit(unitValRounded, unit);
        roundedToMultipleOfUnit = roundedToUnit.set(setter);
      }
      break;
    default: {
      const _exhaustive: never = roundingMode;
      return _exhaustive;
    }
  }

  return roundedToMultipleOfUnit;
}

/**
 * Resolve a relative datetime specification to an actual, absolute datetime value.
 * @param pos
 * @param d
 * @returns
 */
export function realize_relative_datetime_position(
  pos: RelativeDateTimePosition,
  d: DateTime,
  roundingDirection: RelativeDateTimeRoundingDirection,
): DateTime {
  const roundMode =
    roundingDirection === RelativeDateTimeRoundingDirection.forward ? RoundingMode.Ceil : RoundingMode.Floor;
  switch (pos) {
    case RelativeDateTimePosition.day:
      return datetime_round_to(d, "day", 1, roundMode);
    case RelativeDateTimePosition.now_with_5min_precision:
      return datetime_round_to(d, "minute", 5, roundMode);
    case RelativeDateTimePosition.now_with_10min_precision:
      return datetime_round_to(d, "minute", 10, roundMode);
    case RelativeDateTimePosition.now_with_15min_precision:
      return datetime_round_to(d, "minute", 15, roundMode);
    case RelativeDateTimePosition.now_with_30min_precision:
      return datetime_round_to(d, "minute", 30, roundMode);
    case RelativeDateTimePosition.now_with_1h_precision:
      return datetime_round_to(d, "hour", 1, roundMode);
    case RelativeDateTimePosition.now_with_6h_precision:
      return datetime_round_to(d, "hour", 6, roundMode);
    case RelativeDateTimePosition.now_with_12h_precision:
      return datetime_round_to(d, "hour", 12, roundMode);
    default:
      return pos;
  }
}

export function realize_datetime_from_millis(valueMilliseconds: number): DateTime {
  return DateTime.fromMillis(valueMilliseconds, { zone: GuiTimeZone.utc });
}

export function realize_relative_datetime_desc(
  /**
   * Specifies if we round the current time to calculate the start time
   * (which equals to display time in time point mode and the animation start in animation).
   */
  isRoundingOn: boolean,
  roundingPrecision: RelativeDateTimePosition,
  roundingDirection: RelativeDateTimeRoundingDirection,
  /**
   * Specifies if we shift the start date time
   * (which equals to display time in time point mode and the animation start in animation).
   */
  isShiftOn: boolean,
  start: Duration,
  end: Duration,
  timeZone: GuiTimeZone,
): Interval {
  let startDate: DateTime = DateTime.utc().setZone(timeZone);
  if (isRoundingOn) {
    startDate = realize_relative_datetime_position(
      roundingPrecision,
      DateTime.utc().setZone(timeZone),
      roundingDirection,
    ).setZone(timeZone);
  }
  if (isShiftOn) {
    startDate = startDate.plus(start);
  }
  const endDate: DateTime = startDate.plus(end);
  const interval = Interval.fromDateTimes(startDate, endDate);
  if (!interval.isValid) {
    throw Error(`Invalid Interval ${startDate.toISO()} ${endDate.toISO()}`);
  }
  return interval;
}

/**
 * Convert the first or only value of a date time specification to an actual date time object. This means:
 * - relative date time specifications are converted to an absolute datetime
 * - a series of date times, returns the first element of the seriest (the element furthest in the past)
 * @param time
 * @param timeZone
 * @returns
 */
export function realize_datetime_desc(time: DateTimeDesc, timeZone: GuiTimeZone): Interval {
  if (time.is_relative) {
    return realize_relative_datetime_desc(
      time.rel_rounding_on,
      time.rel_position,
      time.rel_rounding_direction,
      time.rel_shift_on,
      Duration.fromISO(time.rel_start),
      Duration.fromISO(time.rel_end),
      timeZone,
    );
  }
  const interval = Interval.fromDateTimes(
    DateTime.fromISO(time.abs_start, { zone: GuiTimeZone.utc }),
    DateTime.fromISO(time.abs_end, { zone: GuiTimeZone.utc }),
  );
  return checkInterval(interval);
}

export function setTimeList(interval: Interval, temporalResolution: Duration): DateTime[] {
  const list = [];

  // one more timeframe because of the bug that we show one more frame at the end
  for (let time = interval.start; time < interval.end.plus(temporalResolution); time = time.plus(temporalResolution)) {
    list.push(time);
  }
  return list;
}

function checkInterval(interval: Interval): Interval {
  if (!interval.isValid) {
    throw Error(`Invalid Interval ${interval.start} ${interval.end}`);
  }
  return interval;
}

export function realize_first_value_of_datetime_desc(time: DateTimeDesc, timeZone: GuiTimeZone): DateTime {
  return realize_datetime_desc(time, timeZone).start;
}

/**
 * Rounds a timestap on a continous time axis to the two closest neighboring timestamps on a discrete time axis.
 *
 * (We use this to discretize render time to a courser level for data retrieval)
 */
export function discretize_datetime(
  datetime: DateTime,
  temporalDiscretization: [number, DateTimeRoundingUnit] | null,
): TimeStep<DateTime> {
  if (temporalDiscretization == null) {
    return { future: datetime, past: datetime };
  }

  const past = datetime_round_to(datetime, temporalDiscretization[1], temporalDiscretization[0], RoundingMode.Floor);
  const future = datetime_round_to(datetime, temporalDiscretization[1], temporalDiscretization[0], RoundingMode.Ceil);
  return { future, past };
}

export function realize_temporal_resolution(time: DateTimeDesc): Duration {
  return Duration.fromISO(time.temporal_resolution);
}

export function realize_temporal_discretization_rounded(temporalResolution: Duration): TemporalDiscretization {
  const duration = temporalResolution.toObject();
  const [key, value] = Object.entries(duration).filter(
    ([value]) => value !== "locale" && value !== "numberingSystem" && value !== "conversionAccuracy",
  )[0] as [Exclude<keyof DurationLikeObject, "numberingSystem" | "conversionAccuracy" | "locale">, number];

  switch (key) {
    case "year":
    case "years":
      return [value, "year"];
    case "quarter":
    case "quarters":
      return [value * 4, "month"];
    case "month":
    case "months":
      return [value, "month"];
    case "week":
    case "weeks":
      return [value * 7, "day"];
    case "day":
    case "days":
      return [value, "day"];
    case "hour":
    case "hours":
      return [value, "hour"];
    case "minute":
    case "minutes":
      return [value, "minute"];
    case "second":
    case "seconds":
    case "millisecond":
    case "milliseconds":
      return defaultRoundingUnit;
    default: {
      const _exhaustive: never = key;
      return _exhaustive;
    }
  }
}

/**
 * Overwrite the time zone information of DateTime without actually converting the datetime value.
 * @param datetime
 */
export function overwriteDateTimezone(datetime: DateTime, timezone: GuiTimeZone): DateTime {
  function twoDigit(number: number): string {
    return `0${number.toString()}`.slice(-2);
  }
  const d = datetime;
  const isoFormatWithoutTimezone = `${d.year}-${twoDigit(d.month)}-${twoDigit(d.day)}T${twoDigit(d.hour)}:${twoDigit(
    d.minute,
  )}:${twoDigit(d.second)}`;
  const result = DateTime.fromISO(isoFormatWithoutTimezone, { zone: timezone });
  return result;
}

/**
 * Update only the date portion of DateTime
 * @param baseDatetime
 * @param newDatetime
 */
export function updateOnlyDate(baseDatetime: DateTime, newDatetime: DateTime): DateTime {
  return baseDatetime.set({ year: newDatetime.year, month: newDatetime.month, day: newDatetime.day });
}

/**
 * Check if a duration is postive
 */
export function isPositiveDuration(duration: Duration) {
  if (duration.as("seconds") >= 0) {
    return true;
  }
  return false;
}

export function formatDateTimeToTimeStr(datetime: DateTime): string {
  return datetime.toFormat("HH:mm");
}
export function getDurationFromTimeStr(time: string): DateObjectUnits {
  const timeArr = time.split(":");
  return { hour: Number(timeArr[0]), minute: Number(timeArr[1]), second: 0, millisecond: 0 };
}

/**
 * Replace the timezone of the given datetime, without converting it.
 */
export function castTimezone(datetime: DateTime, timezone: string) {
  return datetime.setZone(timezone, { keepLocalTime: true });
}

// Reference documentation is at
// https://github.com/mapbox/mapbox-gl-js/blob/main/src/source/custom_source.js

import { SynchrounousState } from "@/cache/AsyncResult";
import { networkCaches } from "@/cache/GlobalCache";
import type { CombinedPNGTile, WMSRequestParams } from "@/cache/PVSTileService/TileGetters/WMSTileGetter";
import type { TilesByLocation } from "@/cache/SpatioTemporalTileCache/PotentiallyVisibleTileSet";
import type { TileDataDescription } from "@/cache/SpatioTemporalTileCache/TileDataDescription";
import { TileGeometry } from "@/cache/SpatioTemporalTileCache/TileGeometry";
import type { ScenePublicApi } from "@/layers/Compositor";
import { getModelBoundingBox } from "@/weather-parameters/lookup";
import { ColorMap, type CoordinateSystem, type EnsSelectIdentifier } from "@mm/api.meteomatics.com";
import type { WmsLayer } from "@mm/metx-workbench.meteomatics.com";
import { isEqual } from "lodash";
import type { DateTime } from "luxon";
import type mapboxgl from "mapbox-gl";
import type { CustomSourceInterface } from "mapbox-gl";
import type { AppliedStyle } from "../LayerBase";

type TilePosition = { x: number; y: number; z: number };

function createEmptyTile(tileSize: number) {
  const canvas = document.createElement("canvas");

  canvas.width = tileSize;
  canvas.height = tileSize;
  const ctx = canvas.getContext("2d");

  // biome-ignore lint/style/noNonNullAssertion: ctx can be null TODO
  return ctx!.getImageData(0, 0, tileSize, tileSize);
}

export class IntegratedSource implements CustomSourceInterface<ImageBitmap | ImageData> {
  id: string;
  type = "custom" as const;
  dataType = "raster" as const;
  tileSize?: number | undefined;
  tiles?: Map<number, TilesByLocation<CombinedPNGTile>>;

  map?: mapboxgl.Map;
  scene: ScenePublicApi;
  props: WmsLayer;
  displayTimeWithOffset: DateTime;
  nextTime: DateTime;

  private emptyTile: ImageData;

  constructor(id: string, scene: ScenePublicApi, props: WmsLayer) {
    this.id = id;
    this.scene = scene;
    this.tileSize = 256;
    this.props = props;

    this.displayTimeWithOffset = this.scene.getDisplayTimeWithOffset();
    this.nextTime = this.scene.getNextTime();

    this.emptyTile = createEmptyTile(this.tileSize);
  }

  public updateSource(props: WmsLayer) {
    // As update function gets triggered like crazy
    // we need to make sure, that source gets updated only
    // when there is some actual changes in data.
    // For that reason source caches some props
    if (
      isEqual(this.props, props) &&
      this.displayTimeWithOffset.equals(this.scene.getDisplayTimeWithOffset()) &&
      this.nextTime.equals(this.scene.getNextTime())
    ) {
      return;
    }

    this.props = props;
    this.displayTimeWithOffset = this.scene.getDisplayTimeWithOffset();
    this.nextTime = this.scene.getNextTime();

    // While this MR is pending - https://github.com/mapbox/mapbox-gl-js/pull/12063
    // we need to do more direct and restricted access to "update" function
    // It's not good practice in general, but we don't have other options to
    // force source update data

    // @ts-ignore
    this.map?.getSource(this.id)._update();
    this.scene.repaint();
  }

  public isLoading() {
    // Use next time for loading, as it's indicates more precise loading time.
    // It's because current logic preloads next time, and then updates display time
    return this.getLoadingState(this.nextTime);
  }

  public isRebuffering(datetime: DateTime) {
    const dateTimeWithOffset = this.scene.getDateTimeWithOffset(datetime);
    return this.getLoadingState(dateTimeWithOffset);
  }

  private getLoadingState(dateTimeWithOffset: DateTime) {
    // Use function from CustomSource implementation
    // I'm not sure, why this function isn't defined in interface
    // @ts-ignore
    const tiles: TilePosition[] = this.coveringTiles();

    /**
     * Previously this logic was done with reduce function, but
     * it turns out, reduce creates sync behavior for promises
     * https://css-tricks.com/why-using-reduce-to-sequentially-resolve-promises-works/
     */

    // Map Loading state to array of booleans loaded/not-loaded
    const tilesLoadingMap = tiles.map((tile) => {
      try {
        const cachedTile = this.getTileFromCache(tile.x, tile.y, tile.z, dateTimeWithOffset);
        if (cachedTile) {
          const { synchronous, asynchronous } = cachedTile;

          // We need to catch asynchronous error hacky way because synchronous/asynchronous creates new promise
          // and if promise fails, synchronous doesn't catch that error and it's thrown out
          // It seems, that any error inside mapbox custom source causes it to reload
          // This point to minor cache logic flaw. Expected behavior could be: UI isn't getting "promise rejected"
          // error, when using only "synchronous" state from cache.
          // TODO investigate caching more extensively and handle errors, also when only "synchronous" is used
          asynchronous.catch(() => {});

          if (synchronous === null || synchronous === SynchrounousState.StillPending) {
            return true;
          }

          if (synchronous === SynchrounousState.PermanentlyFailed) {
            return false;
          }
        }

        return false;
      } catch {
        return false;
      }
    });

    // Check if any of tiles are loading
    return tilesLoadingMap.includes(true);
  }

  public async getTilePromiseList(dateTimeWithOffset: DateTime) {
    // Use function from CustomSource implementation
    // I'm not sure, why this function isn't defined in interface
    //@ts-ignore
    const tiles: TilePosition[] = this.coveringTiles();

    return await Promise.all(
      tiles
        .map((tile) => this.getTileFromCache(tile.x, tile.y, tile.z, dateTimeWithOffset)?.asynchronous)
        .filter((promise) => !!promise),
    );
  }

  public hasTile({ x, y, z }: TilePosition): boolean {
    const geometry = new TileGeometry([x, y, z]);
    const modelBounds = getModelBoundingBox(this.props.model);
    const geometryEpsg3857 = geometry.toEpsg3857();
    const intersection = modelBounds.contains(geometryEpsg3857.project(modelBounds.crs));

    return intersection.kind !== "OutOfBounds";
  }

  private getTileFromCache(x: number, y: number, z: number, dateTimeWithOffset: DateTime) {
    if (!this.hasTile({ x, y, z })) {
      return;
    }

    const { model, color_map, parameter_unit, calibrated, ens_select, vertical_interpolation } = this.props;

    const appliedStyle: AppliedStyle = color_map !== "" ? ColorMap[color_map as keyof typeof ColorMap] : undefined;
    const appliedEnsSelect: EnsSelectIdentifier = ens_select ? ens_select : "";

    const rasterDesc: TileDataDescription<WMSRequestParams<CoordinateSystem.EPSG3857>> = {
      datetime: dateTimeWithOffset,
      geometry: new TileGeometry([x, y, z]),
      requestParams: {
        model,
        boundingBoxLimit: getModelBoundingBox(model),
        colorMap: appliedStyle,
        ensSelect: appliedEnsSelect,
        calibrated,
        vertical_interpolation,
        parameters: [parameter_unit],
      },
    };

    return networkCaches.wms_tile_cache.getSingleTile(rasterDesc);
  }

  onAdd(map: mapboxgl.Map) {
    this.map = map;
  }

  async loadTile({ x, y, z }: TilePosition, options: { signal: AbortSignal }): Promise<ImageBitmap | ImageData> {
    // TODO Implement abort signal handling, coming from mapbox
    // problem is, that cache breaks, when some tiles are aborted and
    // it looks like, didn't even retry loading them
    // Most likely it's necessary to set
    // this.setDirty(areaRequest); and
    // compositor.invalidatePvs(areaRequest);

    // options.signal.addEventListener(
    // "abort",

    //   Inject datetime, as this.displayTime could be already changed when abort is invoked

    //   ((isoDatetime: string) => {
    //     return () => this.abortTile(x, y, z, DateTime.fromISO(isoDatetime));
    //   })(this.displayTime.toISO())
    // );

    // If there is previous display time, try aborting it

    const cachedTile = this.getTileFromCache(x, y, z, this.displayTimeWithOffset);
    if (cachedTile) {
      const { asynchronous } = cachedTile;

      try {
        const tile = await asynchronous;

        // @ts-ignore
        if (tile?.data) {
          // @ts-ignore
          return tile.data.payload as ImageBitmap;
        }
        return this.emptyTile;
      } catch (_e) {
        return this.emptyTile;
      }
    }

    return this.emptyTile;
  }
}

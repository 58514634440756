import { CoordinateSystem } from "./CoordinateSystem";
import { DateTime, Duration } from "luxon";
import {
  DateTime_fromTransferable,
  DateTime_toTransferable,
  DateTime_Transferable,
  Duration_fromTransferable,
  Duration_toTransferable,
  Duration_Transferable,
} from "./DateTime";
import { GridDimension } from "./GridDimension";
import { ApiRequestOptions } from "./ApiRequestOptions";
import {
  BaseRequest,
  MultiParamRequest,
  MultiParamRequest_Transferable,
} from "./BaseRequest";
import { Area } from "./Area";
import { Positions, Positions_Transferable } from "./Positions";

export enum Aggregate {
  "min" = "min",
  "max" = "max",
  "mean" = "mean",
  "median" = "median",
  "mode" = "mode",
}

export interface PolygonRequest<C extends CoordinateSystem>
  extends GridDimension,
    BaseRequest,
    MultiParamRequest {
  polygons: Positions<C>[];
  // TODO Maybe use enum instead
  aggregate: Aggregate;
  startDatetime: DateTime;
  duration?: Duration;
  temporalResolution?: Duration;
  requestOptions?: ApiRequestOptions;
}

export interface PolygonRequest_Transferable<C extends CoordinateSystem>
  extends GridDimension,
    MultiParamRequest_Transferable {
  polygons: Positions_Transferable<C>[];
  // TODO Maybe use enum instead
  aggregate: Aggregate;
  startDatetime: DateTime_Transferable;
  duration?: Duration_Transferable;
  temporalResolution?: Duration_Transferable;
  requestOptions?: ApiRequestOptions;
}

export function PolygonRequest_toTransferable<C extends CoordinateSystem>(
  req: PolygonRequest<C>
): PolygonRequest_Transferable<C> {
  return {
    ...req,
    polygons: req.polygons.map((polygon) => polygon.toTransferable()),
    // TODO Maybe use enum instead
    aggregate: req.aggregate,
    startDatetime: DateTime_toTransferable(req.startDatetime),
    duration: req.duration ? Duration_toTransferable(req.duration) : undefined,
    temporalResolution: req.temporalResolution
      ? Duration_toTransferable(req.temporalResolution)
      : undefined,
    boundingBoxLimit: req.boundingBoxLimit
      ? req.boundingBoxLimit.toTransferable()
      : null,
  };
}

export function PolygonRequest_fromTransferable<C extends CoordinateSystem>(
  req: PolygonRequest_Transferable<C>
): PolygonRequest<C> {
  return {
    ...req,
    polygons: req.polygons.map((polygon) =>
      Positions.fromTransferable(polygon)
    ),
    aggregate: req.aggregate,
    startDatetime: DateTime_fromTransferable(req.startDatetime),
    duration: req.duration
      ? Duration_fromTransferable(req.duration)
      : undefined,
    temporalResolution: req.temporalResolution
      ? Duration_fromTransferable(req.temporalResolution)
      : undefined,
    boundingBoxLimit: req.boundingBoxLimit
      ? Area.fromTransferable(req.boundingBoxLimit)
      : null,
  };
}

import type { EnsSelectArray, EnsSelectValue } from "@/utility/ensemble";
import { classNames } from "@/utility/jsx";
import { union } from "lodash";
import { type ChangeEvent, useMemo } from "react";

import { EnsMemberCheckbox } from "./EnsMemberCheckbox";

import { useEnsSelectionContext } from "..";

import styles from "./style.module.scss";

const ensFilter = (query: string, value: EnsSelectValue) => value.toLowerCase().startsWith(query.toLowerCase());

interface EnsSelectFormProps {
  searchQuery?: string;
  onChange?: (values: EnsSelectArray) => void;
  groupMembersAndQuantiles?: boolean;
}

export const EnsSelectForm = ({ searchQuery, onChange }: EnsSelectFormProps) => {
  const {
    availableValues: { members, quantiles, spread, stat },
    selectedEns,
  } = useEnsSelectionContext();

  const handleSelectEns = ({ target: { checked } }: ChangeEvent<HTMLInputElement>, value: EnsSelectValue) => {
    if (checked) {
      onChange?.(union(selectedEns, [value]));
    } else {
      const newMembers = selectedEns.filter((member) => member !== value);
      onChange?.(newMembers);
    }
  };

  const filteredAdditional = useMemo(
    () => [...spread, ...stat].filter((ens) => ensFilter(searchQuery || "", ens)),
    [searchQuery, spread, stat],
  );
  const filteredQuantiles = useMemo(
    () => quantiles.filter((ens) => ensFilter(searchQuery || "", ens)),
    [searchQuery, quantiles],
  );
  const filteredMembers = useMemo(
    () => members.filter((ens) => ensFilter(searchQuery || "", ens)),
    [searchQuery, members],
  );
  return (
    <div className={styles["ens-form"]}>
      {filteredAdditional.length ? (
        <div className={classNames(styles["ens-container"], styles.additional)}>
          {filteredAdditional.map((ens) => (
            <EnsMemberCheckbox
              key={ens}
              value={ens}
              checked={selectedEns.includes(ens)}
              name={ens}
              onChange={(e) => handleSelectEns(e, ens)}
            />
          ))}
        </div>
      ) : undefined}
      {filteredQuantiles.length ? (
        <>
          <div className={styles.delimiter} />
          <div className={classNames(styles["ens-container"])}>
            {filteredQuantiles.map((ens) => (
              <EnsMemberCheckbox
                key={ens}
                value={ens}
                checked={selectedEns.includes(ens)}
                name={ens}
                onChange={(e) => handleSelectEns(e, ens)}
              />
            ))}
          </div>
        </>
      ) : undefined}

      {filteredMembers.length ? (
        <>
          <div className={styles.delimiter} />
          <div className={classNames(styles["ens-container"])}>
            {filteredMembers.map((ens) => (
              <EnsMemberCheckbox
                key={ens}
                value={ens}
                name={ens}
                checked={selectedEns.includes(ens)}
                onChange={(e) => handleSelectEns(e, ens)}
              />
            ))}
          </div>
        </>
      ) : undefined}
    </div>
  );
};

import { Api } from "@/api/Api";
import { LayoutType, type TephigramCreate, type TephigramSchema } from "@mm/metx-workbench.meteomatics.com";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const createTephigram = createAsyncThunk(
  "api/tephigram/create",
  async (payLoad: { data: TephigramCreate; layoutId: number }, _thunkAPI) => {
    return await Api.tephigram
      .v2CreateTephigram({
        tephigramCreate: payLoad.data,
      })
      .then((tephigram: TephigramSchema) => {
        return { tool: tephigram, layoutType: LayoutType.Tephigram, layoutId: payLoad.layoutId };
      });
  },
);

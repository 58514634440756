import {
  PopupPlotActionType,
  type PopupPlotsActions,
  type SetPopupPlotState,
  type SetShow,
} from "@/reducer/plot/action";
import type { PopupPlotState } from "@/reducer/plot/state";

export function popupPlotsReducer(state: PopupPlotState, action: PopupPlotsActions): PopupPlotState {
  switch (action.type) {
    case PopupPlotActionType.ResetPopupPlot:
      return action.payload;
    case PopupPlotActionType.SetShow:
      return { ...state, show: action.payload };
    case PopupPlotActionType.SetPopupPlotState:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export const togglePlotPopup = (show: boolean): SetShow => ({
  type: PopupPlotActionType.SetShow,
  payload: show,
});

export const setPopupPlotState = (state: PopupPlotState): SetPopupPlotState => ({
  type: PopupPlotActionType.SetPopupPlotState,
  payload: state,
});

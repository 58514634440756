import type Logger from "logging";
import { useEffect } from "react";

export enum EffectResult {
  /**
   * The dependency list changed and the effect executed.
   */
  Executed = "effect executed",
  /**
   * The dependency list changed, but the body of the effect was not executed because of
   * some additional constraints.
   */
  EarlyOut = "effect earlyout",
}

// biome-ignore lint/suspicious/noConfusingVoidType: <explanation> TODO check what linter needs
export type EffectDestructor = () => void | undefined;

function isEffectDestructor(val: any): val is EffectDestructor {
  return typeof val === "function";
}

export function _useTracedEffect(
  tracingEnabled: boolean,
  prefix: string,
  label: string,
  logger: Logger,
  cb: () => EffectResult | EffectDestructor,
  deps?: React.DependencyList,
) {
  useEffect(() => {
    const res = cb();
    if (tracingEnabled) {
      // returning a destructor should only be necessary when the effect actually ran and did not immediately early out.
      const type = isEffectDestructor(res) ? EffectResult.Executed : res;
      logger.debug(`[tracing][${prefix}][${type}] ${label}`);
    }

    if (isEffectDestructor(res)) {
      return res;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

export function makeTracedEffect(tracingEnabled: boolean, logger: Logger, prefix: string) {
  return (label: string, cb: () => EffectResult | EffectDestructor, deps?: React.DependencyList) =>
    _useTracedEffect(tracingEnabled, prefix, label, logger, cb, deps);
}

export function traceComponent(tracingEnabled: boolean, logger: Logger, prefix: string) {
  const trace = (...args: any[]) => {
    if (tracingEnabled) {
      logger.debug(`[tracing][${prefix}]`, ...args);
    }
  };
  trace("=== pass ===");
  return { useTracedEffect: makeTracedEffect(tracingEnabled, logger, prefix), trace };
}

import { GridRequest, GridRequest_Transferable } from "./GridRequest";
import { CoordinateSystem } from "./CoordinateSystem";
import { BaseRequest } from "./BaseRequest";
import { DateTime_fromTransferable, DateTime_toTransferable } from "./DateTime";
import { Area } from "./Area";
import { ParameterUnit } from "./Identifier";
import { GridSamplingStrategyKind } from "./GridSamplingStrategy";

// Sample Query
// https://api-a.meteomatics.com/get_weatherfronts?datetime=2021-06-13T09:05:00Z
// &model=ecmwf-ens
// &bbox=-44.021800000000006,32.1421,52.031,62.625099999999996
// &ens_select=member:0

export interface WeatherFrontsRequest
  extends Omit<GridRequest<CoordinateSystem.WGS84>, "sampling" | "parameters">,
    BaseRequest {
  path: string;
  parameter: ParameterUnit;
  calibrated?: boolean;
}

export interface WeatherFrontsRequest_Transferable
  extends Omit<GridRequest_Transferable<CoordinateSystem.WGS84>, "sampling" | "parameters"> {
  path: string;
  parameter: ParameterUnit;
}

export function WeatherFrontsRequest_toTransferable(req: WeatherFrontsRequest): WeatherFrontsRequest_Transferable {
  return {
    ...req,
    area: req.area.toTransferable(),
    boundingBoxLimit: req.boundingBoxLimit ? req.boundingBoxLimit.toTransferable() : null,
    datetime: DateTime_toTransferable(req.datetime),
  };
}

export function WeatherFrontsRequest_fromTransferable<C extends CoordinateSystem>(
  req: WeatherFrontsRequest_Transferable,
): WeatherFrontsRequest {
  return {
    ...req,
    area: Area.fromTransferable(req.area),
    boundingBoxLimit: req.boundingBoxLimit ? Area.fromTransferable(req.boundingBoxLimit) : null,
    datetime: DateTime_fromTransferable(req.datetime),
  };
}

export function WeatherFrontsRequest_toGridRequest<C extends CoordinateSystem>(
  weatherFrontsRequest: WeatherFrontsRequest,
): GridRequest<CoordinateSystem.WGS84> {
  return {
    ...weatherFrontsRequest,
    parameters: [weatherFrontsRequest.parameter],
    sampling: { kind: GridSamplingStrategyKind.Point },
  };
}

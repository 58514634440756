import { iconLayerConfig } from "@/constants/layerConfigAttributes";
import { ColorPickerInput } from "@/overlay/components/LayerStackWindow/LayerFormElements";
import { PoiTypeField } from "@/overlay/components/LayerStackWindow/PoiLayerPanel/PoiTypeField";
import { FormField, InputFormField } from "@/overlay/components/form-field";
import { batchGroupBy } from "@/reducer/batchGroupBy";
import type { IconData, PoiDisplayData } from "@/reducer/client-models";
import { t } from "@lingui/macro";

export const IconOptions: string[] = [
  // When you add a new entry, you can alphabetically sort them by hand
  // or use https://www.textfixer.com/tools/alphabetical-order.php
  "agriculture",
  "aircraft",
  "broadcasting_tower",
  "circle",
  "cross",
  "dam",
  "diamond",
  "docks",
  "electricity_pylon",
  "fire",
  "heart",
  "house",
  "hydroelectric_plant",
  "lightning",
  "marker",
  "nuclear_power_plant",
  "offshore_platform",
  "point",
  "power_plant",
  "power_plant_2",
  "rocketdrome",
  "ship",
  "solar_collector",
  "star_5",
  "tank",
  "tractor",
  "turbine",
  "warning_sign",
];

export interface PoiInputFieldsProps {
  item: PoiDisplayData;
  hideLabels?: boolean;
  onToggleLabels: (enabled: boolean) => void;
  onInputChange: (data: Partial<IconData>) => void;
}

export const PoiInputFields = ({ item, hideLabels, onInputChange, onToggleLabels }: PoiInputFieldsProps) => {
  const iconOptions = ["", ...IconOptions]; // one empty options

  return (
    <>
      <PoiTypeField
        onTypeChange={onInputChange}
        onToggleLabels={onToggleLabels}
        hideLabels={hideLabels}
        text={item.text}
        icon={item.icon}
      />
      <FormField label={t`Icon`}>
        <select
          value={item.icon}
          onChange={(event) => onInputChange({ icon: event.target.value })}
          data-testid="poi-icon"
        >
          {iconOptions.map((iconName, idx) => (
            <option key={iconName} value={iconName}>
              {iconName ? iconName : t`None`}
            </option>
          ))}
        </select>
      </FormField>
      <InputFormField
        label={t`Text`}
        type="text"
        {...iconLayerConfig.textSizeLimits}
        onChange={(event) => onInputChange({ text: event.target.value })}
        value={item.text}
        data-testid="poi-text"
      />
      <ColorPickerInput
        label={t`Default Color`}
        change={(color) => onInputChange({ color: color })}
        value={item.color}
      />
      <InputFormField
        label={t`Size`}
        type="range"
        onChange={(event) => onInputChange({ size: +event.target.value })}
        min="0.05"
        max="1"
        step="0.05"
        value={item.size}
        onMouseDown={() => batchGroupBy.start()}
        onMouseUp={() => batchGroupBy.end()}
        data-testid="poi-icon-size"
      />
      {!hideLabels && (
        <InputFormField
          label={t`Opacity`}
          type="range"
          onChange={(event) => onInputChange({ labelBackgroundOpacity: +event.target.value })}
          min="0"
          max="1"
          step="0.05"
          value={item.labelBackgroundOpacity ?? 1}
          onMouseDown={() => batchGroupBy.start()}
          onMouseUp={() => batchGroupBy.end()}
          data-testid="poi-label-background-opacity"
        />
      )}
    </>
  );
};

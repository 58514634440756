// This is a region that contains windows in horizontal order (layer stack with each layer shown below each other)
import { classNames } from "@/utility/jsx";
import { Trans } from "@lingui/macro";
import { Fragment, type ReactNode } from "react";
import { type DropTargetMonitor, useDrop } from "react-dnd";
import type { DraggingWindowState } from ".";
import { type WindowInstanceId, useDesktop } from "./DesktopContext";
import { TrackId, allowedDragItemTypes } from "./TrackId";
import type { WindowElement } from "./Window";

export function TrackDropZone(props: {
  trackId: TrackId;
  /**
   * The dropzone will position the dropped element before this element.
   * If null, the element will become the last element of the track.
   */
  positionBefore?: WindowInstanceId;
}): ReactNode {
  const desktop = useDesktop();

  const accept = allowedDragItemTypes[props.trackId];

  const [{ canDrop, draggedItem, isHovered }, drop] = useDrop({
    accept,
    drop(item: DraggingWindowState, monitor: DropTargetMonitor) {
      const didDrop = monitor.didDrop();

      if (didDrop) {
        return;
      }

      desktop.dispatch({
        type: "setPosition",
        windowId: item.instanceId,
        position: { isManaged: true, trackId: props.trackId },
      });
      desktop.dispatch({
        type: "setWindowOrder",
        windowId: item.instanceId,
        before: props.positionBefore,
      });
    },
    collect: (monitor: DropTargetMonitor) => ({
      draggedItem: monitor.getItem() as DraggingWindowState,
      isHovered: monitor.isOver({ shallow: true }),
      canDrop: monitor.canDrop(),
    }),
  });

  if (draggedItem == null || !canDrop || draggedItem.instanceId === props.positionBefore) {
    return;
  }

  return (
    <div
      className={classNames(
        "window-manager__track__dropzone enable-user-interaction",
        isHovered && "window-manager__track__dropzone--hovered",
      )}
      ref={drop}
    >
      <Trans>Drop here!</Trans>
    </div>
  );
}

// Used as "default placement" of windows.
export function WindowTrack(props: {
  id: TrackId;
  windows: { instanceId: WindowInstanceId; renderer: () => WindowElement }[];
}) {
  let windows: WindowElement;

  // as an optimization, only render the top most window in modals
  if (props.id === TrackId.Modal) {
    const window = props.windows.values().next();
    if (!window.done) {
      const { renderer } = window.value;
      windows = renderer();
    }
  } else {
    windows = (
      <>
        {props.windows.map(({ instanceId, renderer }) => (
          <Fragment key={instanceId}>
            <TrackDropZone trackId={props.id} positionBefore={instanceId} />
            {renderer()}
          </Fragment>
        ))}
        <TrackDropZone trackId={props.id} />
      </>
    );
  }

  return (
    <div className="window-manager__track" data-window-track-id={props.id}>
      {/* @ts-ignore */}
      {windows}
    </div>
  );
}

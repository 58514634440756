import { fetchTab } from "@/api/hooks/tab";
import type { ElementStyle, Location, TephigramSchema, TephigramUpdate } from "@mm/metx-workbench.meteomatics.com";
import { type PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import type { RootState } from ".";

import { createTephigram } from "@/api/hooks/tools/tephigram";
import Logger from "logging";

const logger = Logger.fromFilename(__filename);

export type TephigramState = {
  [id: number]: TephigramSchema;
};

const initialState: TephigramState = {};

const tephigramSlice = createSlice({
  name: "tephigram",
  initialState: initialState as TephigramState,
  reducers: {
    addTephigram(state, action: PayloadAction<TephigramSchema>) {
      state[action.payload.id] = action.payload;
    },
    discardTephigram(state, action: PayloadAction<{ id: number }>) {
      const { id } = action.payload;
      const tool = state[id];

      if (!tool) {
        logger.error("Could not find tool with id", id);
        return;
      }
      delete state[id];
    },
    setTephigramLocation(state, action: PayloadAction<{ id: number; props: Location }>) {
      const { id, props } = action.payload;

      const tephigram = state[id];
      if (!tephigram) {
        logger.error("Could not find tool with id", id);
        return;
      }

      tephigram.location = props;
    },
    setTephigramTitle(state, action: PayloadAction<{ id: number; title: string }>) {
      const { id, title } = action.payload;

      const tephigram = state[id];
      if (!tephigram) {
        logger.error("Could not find tephigram with id", id);
        return;
      }

      tephigram.title = title;
    },
    setTephigramTitleStyle(state, action: PayloadAction<{ style: ElementStyle; id: number }>) {
      const { style, id } = action.payload;

      const tephigram = state[id];

      if (!tephigram) {
        logger.error("Could not find tephigram with id", id);
        return;
      }

      tephigram.titleStyle = style;
    },
    updateTephigramState(
      state,
      action: PayloadAction<{
        id: TephigramSchema["id"];
        tephigram: TephigramUpdate;
      }>,
    ) {
      const { tephigram, id } = action.payload;

      const tephigramFromState = state[id];

      if (!tephigramFromState) {
        logger.error("Could not find tephigram with id", id);
        return;
      }

      const updatedTephigram = {
        ...tephigramFromState,
        ...tephigram,
      };

      state[id] = updatedTephigram;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTab.fulfilled, (state, action) => {
      const tab = action.payload;
      for (const key in state) {
        delete state[+key];
      }
      for (const tephigram of tab.tephigrams) {
        state[tephigram.id] = tephigram;
      }
    });
    builder.addCase(createTephigram.fulfilled, (state, action) => {
      const tephigram = action.payload.tool;
      state[tephigram.id] = tephigram;
    });
  },
});

const selectTephigrams = (state: RootState) => state.tabGroup.present.tephigrams;

const makeSelectTephigramsByTabId = createSelector(
  [selectTephigrams, (state: RootState, tabId: number) => tabId],
  (tephigrams, tabId) => Object.values(tephigrams).filter((tephigram) => tephigram.id_tab === tabId),
);

export function useTephigrams(tabId: number) {
  return useSelector((state: RootState) => makeSelectTephigramsByTabId(state, tabId));
}

const makeSelectTephigramById = createSelector(
  [selectTephigrams, (state: RootState, id: number) => id],
  (tephigrams, id) => tephigrams[id],
);

export function useTephigram(id: number) {
  return useSelector((state: RootState) => makeSelectTephigramById(state, id));
}

export const {
  addTephigram,
  discardTephigram,
  setTephigramLocation,
  setTephigramTitle,
  setTephigramTitleStyle,
  updateTephigramState,
} = tephigramSlice.actions;

export default tephigramSlice.reducer;

import { useAppDispatch } from "@/reducer";
import { isMacLike } from "@/utility/platformUtils";
import { type PropsWithChildren, useEffect } from "react";
import { ActionCreators } from "redux-undo";

const UNDO_KEY = "z";
const REDO_KEY = "y";

export function WorkspaceKeyShortcutProvider(props: PropsWithChildren<unknown>) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const handleUndoKey = (event: KeyboardEvent) => {
      // Stop key event propagation if event is coming from tiptap
      if (event.target && "editor" in event.target) {
        return;
      }
      if (isUndoShortcut(event)) {
        event.preventDefault();
        event.stopPropagation();
        dispatch(ActionCreators.undo());
      }
    };

    const handleRedoKey = (event: KeyboardEvent) => {
      // Stop key event propagation if event is coming from tiptap
      if (event.target && "editor" in event.target) {
        return;
      }
      if (isRedoShortcut(event)) {
        event.preventDefault();
        event.stopPropagation();
        dispatch(ActionCreators.redo());
      }
    };

    // Set up the key shortcuts
    document.addEventListener("keydown", handleUndoKey);
    document.addEventListener("keydown", handleRedoKey);
    return () => {
      document.removeEventListener("keydown", handleUndoKey);
      document.removeEventListener("keydown", handleRedoKey);
    };
  }, [dispatch]);
  return <>{props.children}</>;
}

function isUndoShortcut(event: KeyboardEvent) {
  if (isMacLike) {
    return event.metaKey && !event.shiftKey && event.key === UNDO_KEY;
  }
  return event.ctrlKey && event.key === UNDO_KEY;
}

function isRedoShortcut(event: KeyboardEvent) {
  if (isMacLike) {
    return event.metaKey && event.shiftKey && event.key === UNDO_KEY;
  }
  return event.ctrlKey && event.key === REDO_KEY;
}

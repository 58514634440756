import { IconStore, getIconMetaData } from "@/icon-store/IconStore";
import type { MapboxMap } from "@/layers/MapboxMap";
import { MapInteractionEventType } from "@/models/map-interaction/mapInteractionStateEvents";
import type { MapInteractionStateMachine } from "@/models/map-interaction/mapInteractionStateMachine";
import Logger from "logging";
import { type EventData, type MapMouseEvent, Point } from "mapbox-gl";
import type { InterpreterFrom } from "xstate";
const logger = Logger.fromFilename(__filename);

export const fetchIconImg = (map: MapboxMap) => {
  return ({ id }: EventData) => {
    const metadata = getIconMetaData(id);
    if (!metadata) {
      return;
    }

    // Note: We add an empty image on the map until the actual image comes back from request.
    // https://github.com/mapbox/mapbox-gl-js/issues/8335#issuecomment-783570168
    map.addImage(id, { width: 0, height: 0, data: new Uint8Array() }, { sdf: metadata.sdf });

    IconStore.instance
      .getIcon(id)
      .then((iconImage) => {
        map.removeImage(id);
        map.addImage(id, iconImage as HTMLImageElement, {
          sdf: metadata.sdf,
        });

        // Workaround for old open issue in mapbox
        // in which icons don't render right after they are loaded.
        // Loop trough all symbol sources and refresh data
        // https://github.com/mapbox/mapbox-gl-js/issues/6231
        for (const sourceId of Object.keys(map.style._symbolSourceCaches)) {
          // @ts-ignore
          if (map.getSource(sourceId)._updateWorkerData) {
            // @ts-ignore
            map.getSource(sourceId)._updateWorkerData();
          }
        }
      })
      .catch((e) => logger.error(e));
  };
};

/**
 * Create a event listener callback that emits a click event to the map interaction state machine
 * @param mapId
 * @param mapInteractionService
 * @returns
 */
export const emitClickToInteractionStateMachine =
  (mapId: number, mapInteractionService: InterpreterFrom<MapInteractionStateMachine>) => (e: MapMouseEvent) => {
    const mapBoxLngLat = e.lngLat.wrap();
    mapInteractionService.send({
      type: MapInteractionEventType.click,
      // Map ID is the DB record ID
      mapId: mapId,
      coordinates: {
        lat: mapBoxLngLat.lat,
        lon: mapBoxLngLat.lng,
      },
    });
  };

export const emitContextMenuToInteractionStateMachine =
  (mapId: number, mapInteractionService: InterpreterFrom<MapInteractionStateMachine>) => (e: MapMouseEvent) => {
    // If event isn't originating from client interaction, don't do anything
    if (!e.originalEvent) return;
    const {
      lngLat,
      originalEvent: { clientX, clientY },
    } = e;
    const mapBoxLngLat = lngLat.wrap();
    mapInteractionService.send({
      type: MapInteractionEventType.contextMenu,
      // Map ID is the DB record ID
      mapId: mapId,
      coordinates: {
        lat: mapBoxLngLat.lat,
        lon: mapBoxLngLat.lng,
      },
      point: new Point(clientX, clientY),
    });
  };

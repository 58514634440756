import type { RootState } from "@/reducer/index";
import type { ColorThreshold } from "@mm/metx-workbench.meteomatics.com";
import { type PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

export type ThresholdsState = {
  [tableId: number]: ColorThreshold[];
};

const initialState: ThresholdsState = {};

const thresholdsSlice = createSlice({
  name: "thresholds",
  initialState: initialState as ThresholdsState,
  reducers: {
    replaceThresholds(
      state,
      action: PayloadAction<{
        tableId: number;
        thresholds: ColorThreshold[];
      }>,
    ) {
      const { tableId, thresholds } = action.payload;
      state[tableId] = thresholds;
    },
  },
});

const selectThresholds = (state: RootState) => state.tabGroup.present.locationTableThresholds;
const selectThresholdByTableId = createSelector(
  [selectThresholds, (_, tableId: number) => tableId],
  (thresholds, tableId) => thresholds[tableId] || [],
);

export function useLocationTableThresholds(tableId: number) {
  return useSelector((state: RootState) => selectThresholdByTableId(state, tableId));
}
export const { replaceThresholds } = thresholdsSlice.actions;

export default thresholdsSlice.reducer;

import { createLayer } from "@/api/hooks/layer";
import { useProfileAndTabParams } from "@/hooks";
import type { LayerCreateControllerProp } from "@/overlay/components/SmartForm/LayerForms/LayerFormMapping";
import { useAppDispatch } from "@/reducer";
import type { BarbsLayerCreate } from "@mm/metx-workbench.meteomatics.com";
import { merge } from "lodash";
import type { SubmitHandler } from "react-hook-form";
import { BarbsLayerCreateForm, type BarbsLayerCreateFormData } from "../view";

/**
 * Handles all the logic for barbs layer creation.
 * Note:
 * This is a controller, meaning that we should generally NOT delegate layer creation logic
 * to the parent component by passing callback from parent etc.
 */
export function BarbsLayerCreateController(props: LayerCreateControllerProp<BarbsLayerCreate>) {
  const { selectedMapId, defaultPayload, onDone } = props;
  const dispatch = useAppDispatch();
  const { profileId } = useProfileAndTabParams();

  const onSubmit: SubmitHandler<BarbsLayerCreateFormData> = (formData) => {
    if (!profileId) {
      return;
    }

    const finalPayload = merge(defaultPayload, formData.layerData);
    dispatch(createLayer({ mapId: selectedMapId, profileId: profileId, data: finalPayload }));

    onDone?.();
  };

  return (
    <BarbsLayerCreateForm
      defaultFormData={defaultPayload}
      onSubmit={onSubmit}
      submitMode="onSubmit"
      onCancel={props.onDone}
    />
  );
}

import { useEffect, useState } from "react";
import { Playback } from "./Playback";

export function useNewPlaybackContext() {
  const [playback, setPlayback] = useState<{ playback: Playback }>();

  // biome-ignore lint/correctness/useExhaustiveDependencies: TODO it was requested before
  useEffect(() => {
    new Playback(setPlayback);
  }, [setPlayback]);

  return playback;
}

import type { LayerUpdateControllerProp } from "@/overlay/components/SmartForm/LayerForms/LayerFormMapping";
import { useAppDispatch } from "@/reducer";
import { setLayersProps } from "@/reducer/MapsState";
import type { BarbsLayerUpdate } from "@mm/metx-workbench.meteomatics.com";
import type { SubmitHandler } from "react-hook-form";
import type { BarbsLayerCreateFormData } from "../view";
import { BarbsLayerUpdateForm } from "../view/BarbsLayerUpdateForm";

/**
 * Handles all the logic for barbs layer update.
 * Note:
 * This is a controller, meaning that we should generally NOT delegate layer creation logic
 * to the parent component by passing callback from parent etc.
 */
export function BarbsLayerUpdateController(props: LayerUpdateControllerProp<BarbsLayerUpdate>) {
  const dispatch = useAppDispatch();

  const onSubmit: SubmitHandler<BarbsLayerCreateFormData> = (formData) => {
    dispatch(setLayersProps({ layerId: props.layer.id, id: props.mapId.map, props: formData.layerData }));
  };

  return <BarbsLayerUpdateForm defaultFormData={props.layer} onSubmit={onSubmit} submitMode="onChange" />;
}

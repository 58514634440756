import { LayerExpansionPanel } from "@/overlay/components/LayerStackWindow/LayerExpansionPanel";
import { setLayerOrder } from "@/reducer/MapsState";
import type { CartographicMap, MapId } from "@mm/metx-workbench.meteomatics.com";
import Logger from "logging";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { type DraggableItemIdentifier, SortableList } from "../../CommonParts/SortableList";
import "./style.scss";

const logger = Logger.fromFilename(__filename);

export function LayerStack(props: { id: MapId; cartographicMap: CartographicMap }) {
  const descriptionLayers = props.cartographicMap.layers;
  const dispatch = useDispatch();

  const sortedLayers = (descriptionLayers || []).concat().sort((a, b) => (a.index < b.index ? 1 : -1));

  const moveLayerIdx = useCallback(
    (dragStart: DraggableItemIdentifier, dropTarget: DraggableItemIdentifier) => {
      if (!descriptionLayers) {
        logger.error("Layer orders were swapped but no layer data is available");
        return;
      }
      const dragStartLayer = sortedLayers[dragStart.index];
      const moveUpBy = dragStart.index - dropTarget.index;
      dispatch(setLayerOrder({ layerId: dragStartLayer.id, id: props.id.map, moveUpBy: moveUpBy }));
    },
    [descriptionLayers, dispatch, props.id, sortedLayers],
  );

  return (
    <>
      <SortableList
        className="box-section--breakout"
        onSwapItems={moveLayerIdx}
        items={sortedLayers.map((layer) => ({
          id: layer.id,
          content: (
            <LayerExpansionPanel
              key={layer.id + layer.index}
              layer={layer}
              mapId={props.id}
              cartographicMap={props.cartographicMap}
            />
          ),
        }))}
      />
    </>
  );
}

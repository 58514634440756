import { CustomGeoJsonFileSelect } from "@/overlay/components/LayerStackWindow/CustomGeoJsonForm/CustomGeoJsonFileSelect";
import type { CustomGeoJSON, CustomGeoJSONLayer } from "@mm/metx-workbench.meteomatics.com";

type CustomGeoJsonCreateFormProps = {
  onChange: (propsValue: Partial<CustomGeoJSONLayer>) => void;
  customGeoJsonList: CustomGeoJSON[];
  selectedGeoJsonFileId: CustomGeoJSON["id"];
};

export function CustomGeoJsonCreateForm({
  onChange,
  customGeoJsonList,
  selectedGeoJsonFileId,
}: CustomGeoJsonCreateFormProps) {
  return (
    <>
      <CustomGeoJsonFileSelect
        selectedFileId={selectedGeoJsonFileId}
        list={customGeoJsonList}
        onChange={(fileId) => {
          onChange({ file_id: fileId });
        }}
      />
    </>
  );
}

import { type BaseButtonProps, buttonProps } from "@/overlay/components/CommonParts/buttons/Button";

export type IconButtonProps = Omit<BaseButtonProps, "children" | "icon"> & { icon: string };

/**
 * A circular button with an icon as a label.
 *
 * List of available icons: https://material.io/resources/icons/?style=baseline
 *
 * For example, to render a round button containing a plus-sign:
 *
 * ```
 * <IconButton icon="add" />
 * ```
 */
export function IconButton(props: IconButtonProps) {
  return (
    <button {...buttonProps(props, "icon-button")}>
      <span className="material-icons">{props.icon}</span>
    </button>
  );
}

import { customGeoJsonLayerConfig } from "@/constants/layerConfigAttributes";
import { IntegerInput } from "@/overlay/components/CommonParts/CustomInput";
import { SmartSelector } from "@/overlay/components/CommonParts/SmartSelector";
import type { SmartSelectorItem } from "@/overlay/components/CommonParts/SmartSelector/SmartSelector";
import { FormField } from "@/overlay/components/form-field";
import { t } from "@lingui/macro";
import { Units } from "@mm/metx-workbench.meteomatics.com";

type CustomGeoJsonLineResolutionOptionsProps = {
  segmentLength: number;
  onSegmentChange: (segmentLength: number) => void;
  unit: Units;
  onUnitChange: (unit: Units) => void;
};
export function CustomGeoJsonLineResolutionOptions({
  segmentLength,
  unit,
  onUnitChange,
  onSegmentChange,
}: CustomGeoJsonLineResolutionOptionsProps) {
  const lineResolutionUnit: SmartSelectorItem<Units>[] = [
    {
      label: t`Kilometers`,
      value: Units.kilometers,
      key: Units.kilometers,
    },
    { label: t`Miles`, value: Units.miles, key: Units.miles },
  ];

  return (
    <>
      <FormField label={t`Line resolution unit`}>
        <SmartSelector
          items={lineResolutionUnit}
          selectedItemKey={unit}
          onSelect={onUnitChange}
          data-testid={"line-resolution-unit"}
        />
      </FormField>
      <FormField label={t`Line resolution length`}>
        <IntegerInput
          {...customGeoJsonLayerConfig.lineSegmentLimits}
          value={segmentLength}
          onChange={(value) => onSegmentChange(value)}
          step={1}
          //decimals={allowDecimals || !Number.isInteger(step)}
          data-testid={"line-resolution-length"}
        />
      </FormField>
    </>
  );
}

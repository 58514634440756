import type { PerformanceHint } from "../performanceIntrospection/PerformanceHint";
import type { Connection } from "./Connection";

/**
 * The whole purpose of this abstraction is to return a base url to pointing to the meteomatics api.
 * This allows custom traffic shaping strategies to ensure some specific quality of service goals.
 *
 * For example:
 *
 * - You may return the base url of a proxy to work around CORS issues.
 * - You may return one of multiple urls to work around the `maximal number of connections per host` limit
 *   of browsers.
 * - You may limit the number of parallel connections by not resolving the promise until previous
 *   requests have concluded.
 * - etc.
 */
export abstract class ConnectionManagement {
  abstract getBaseUrl(performanceHint?: PerformanceHint): Promise<Connection>;
  stats(): Record<string, any> {
    return {};
  }

  getMaxConcurrentConnections(): number {
    return Number.POSITIVE_INFINITY;
  }
}

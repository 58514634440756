import { networkCaches } from "@/cache/GlobalCache";
import { SingleRequestKind } from "@/cache/PointJSONCache";
import type { WeatherSymbolSeriesOptions } from "@/overlay/components/CommonParts/PlotHighchart/plugins/weatherSymbol";
import type { CustomWindBarbSeriesOptions } from "@/overlay/components/CommonParts/PlotHighchart/plugins/windbarbs";
import { HighchartsChartOptionsDefault } from "@/plot";
import type { CoordinateSystem, PointRequest } from "@mm/api.meteomatics.com";
import { type PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { Options, SeriesOptionsRegistry, XAxisOptions, YAxisOptions } from "highcharts";
import { isEqual } from "lodash";
import Logger from "logging";
const logger = Logger.fromFilename(__filename);
/**
 * TODO:
 * There is some strange behavior on Highcharts when we add more then 1 Icons below the chart.
 * Also if the order is different there could be some side effects.
 * To fix this i created this working order list. There is also a non working list but not used in the moment.
 * Add it here to keep the researched information.
 * notWorking = [
 *   [0, 1, 0, 0, 1],
 *   [1, 0, 0, 1, 0],
 *   [1, 0, 1, 0],
 *   [0, 1, 0, 1],
 *   [0, 1],
 *   [1, 0],
 * ]
 *
 *
 *
 * */
export const WORKING_WIND_AND_WEATHER_SYMBOL_ORDER = [
  [1, 0, 0, 0, 1],
  [0, 1, 1, 1, 0],
  [0, 1, 0, 1, 0],
  [1, 0, 0, 0, 1],
  [1, 0, 0, 1],
  [0, 1, 1, 0],
  [0, 1, 0],
  [1, 0, 1],
  [1],
  [1, 1],
  [1, 1, 1],
  [1, 1, 1, 1],
  [0],
  [0, 0],
  [0, 0, 0],
  [0, 0, 0, 0],
];
export const ChartIconSize = 40;
export const RESERVED_Y_AXIS_IDX = 7;

type CustomSeriesOptionsRegistry = SeriesOptionsRegistry & {
  CustomWindBarbSeriesOptions: CustomWindBarbSeriesOptions;
  WeatherSymbolSeriesOptions: WeatherSymbolSeriesOptions;
};

export type CustomSeriesOptionsType = CustomSeriesOptionsRegistry[keyof CustomSeriesOptionsRegistry];
export type CustomOptions = Omit<Options, "series"> & { series?: CustomSeriesOptionsType[] };

export interface ChartState {
  chartOptions: CustomOptions | undefined;
}
export const initialChartState: ChartState = {
  chartOptions: HighchartsChartOptionsDefault,
};

export const fetchSinglePoint = createAsyncThunk(
  "series/fetchSinglePoint",
  async (payLoad: { request: PointRequest<CoordinateSystem.WGS84> }, _thunkAPI) => {
    const response = await networkCaches.pointJson_cache.retrievePoint(
      payLoad.request,
      SingleRequestKind.SingleParameter,
    );
    return response;
  },
);

const chartSlice = createSlice({
  name: "chart",
  initialState: initialChartState,
  reducers: {
    setSeries: (state, action: PayloadAction<(CustomSeriesOptionsType | undefined)[]>) => {
      if (state.chartOptions) {
        const series = action.payload.filter(
          (serie: CustomSeriesOptionsType | undefined) => !!serie,
        ) as CustomSeriesOptionsType[];
        const { xAxis, series: newSeries } = getNewSeriesAndXAxisOffset(series);

        const firstXAxis = (state.chartOptions.xAxis as XAxisOptions[])[0] ?? undefined;
        const secondXAxis = (state.chartOptions.xAxis as XAxisOptions[])[1] ?? undefined;

        if (!firstXAxis || !secondXAxis) {
          logger.error("one X-Axis is not there!", firstXAxis, secondXAxis);
        }

        state.chartOptions = {
          ...state.chartOptions,
          // @ts-ignore FIX TYPE ERROR SHOWN IN TERMINAL
          series: newSeries,
          // offset overwriting is  used to place symbole right
          xAxis: [{ ...firstXAxis, offset: xAxis.offset }, { ...secondXAxis }],
          yAxis: state.chartOptions.yAxis,
        };
      }
    },
    setYAxis: (state, action: PayloadAction<YAxisOptions[] | undefined>) => {
      const newYAxis = action.payload;
      state.chartOptions = {
        ...state.chartOptions,
        yAxis: newYAxis,
      };
    },
  },
  // Extra reducer for update data?
});

export const { setSeries, setYAxis } = chartSlice.actions;
export const chartReducer = chartSlice.reducer;

export function getNewSeriesAndXAxisOffset(allSeries: CustomSeriesOptionsType[]) {
  // wind barb => 0
  // weather symbole => 1
  const currentWindAndWeatherSymbolOrder = allSeries
    .filter((serie) => serie.visible && (serie.type === "windbarb" || serie.type === "weathersymbol"))
    .map((serie) => {
      return serie.type === "windbarb" ? 0 : 1;
    });
  const hasVisibleWindBarbSeries = allSeries.filter(
    (serie) => serie && serie.type === "windbarb" && serie.visible,
  ).length;
  const hasVisibleWeatherSymbolSeries = allSeries.filter(
    (serie) => serie && serie.type === "weathersymbol" && serie.visible,
  ).length;

  const isWorking = WORKING_WIND_AND_WEATHER_SYMBOL_ORDER.some((arr) => isEqual(arr, currentWindAndWeatherSymbolOrder));

  let sumYAxisOffset = 0;

  // merge array of extra series ordered by series.
  const newSeries = allSeries.map((serie) => {
    if (serie.visible && serie.type === "weathersymbol") {
      const tmpAdd = ChartIconSize;
      const yOffset = -(sumYAxisOffset + tmpAdd);
      const newSerie = { ...serie, yOffset, yAxis: RESERVED_Y_AXIS_IDX };
      sumYAxisOffset = sumYAxisOffset + tmpAdd;
      return newSerie;
    }
    if (serie.type === "windbarb" && serie.visible) {
      const tmpAdd = ChartIconSize;

      let yOffset = -(sumYAxisOffset + tmpAdd) + 20;
      if (!isWorking) {
        let extraY = 0;
        if (
          (currentWindAndWeatherSymbolOrder[0] === 0 ||
            currentWindAndWeatherSymbolOrder[currentWindAndWeatherSymbolOrder.length - 1] === 0) &&
          currentWindAndWeatherSymbolOrder[0] !==
            currentWindAndWeatherSymbolOrder[currentWindAndWeatherSymbolOrder.length - 1] &&
          hasVisibleWindBarbSeries > 0
        ) {
          if (
            hasVisibleWindBarbSeries === 1 &&
            !(hasVisibleWeatherSymbolSeries === 1 && hasVisibleWindBarbSeries === 1)
          ) {
            extraY = +extraY + extraY;
          }
          extraY = -extraY;
        }
        yOffset = yOffset + extraY;
      }
      const newSerie = { ...serie, yOffset, yAxis: RESERVED_Y_AXIS_IDX };
      sumYAxisOffset = sumYAxisOffset + tmpAdd;
      return newSerie;
    }
    return serie;
  });

  return {
    series: newSeries,
    xAxis: {
      offset: sumYAxisOffset,
    },
  };
}

import { type GenericLayout, LayoutType } from "@mm/metx-workbench.meteomatics.com";
import type { StateFrom } from "xstate";
import { wrongActionPlacementError } from "../state-machine-utils/stateMachineUtils";
import type { MapInteractionContextUnion, MapStateCoord } from "./mapInteractionStateCtxTypes";
import { MapInteractionEventType, type MapInteractionEventUnion } from "./mapInteractionStateEvents";
import type { MapInteractionStateMachine } from "./mapInteractionStateMachine";

export const isTargetMap = (ctx: MapInteractionContextUnion, event: MapInteractionEventUnion) => {
  if (event.type === MapInteractionEventType.click) {
    if (ctx.modeOpts && event.mapId !== ctx.modeOpts.activeMapId) {
      // Should not transition to the next state if the event is not for the
      // active target map.
      return false;
    }
    return true;
  }
  throw wrongActionPlacementError(isTargetMap.name);
};

export const roundCoordinate = (coord: MapStateCoord, decimal = 6): MapStateCoord => {
  return { lat: +coord.lat.toFixed(decimal), lon: +coord.lon.toFixed(decimal) };
};

/**
 * Check if the given tool (map, plot, etc) should be disabled during COORD_SELECT mode.
 */
export const isDisabledGrid = (mapState: StateFrom<MapInteractionStateMachine>, layout: GenericLayout): boolean => {
  if (mapState.matches("COORD_SELECT")) {
    // While in COORD_SELECT mode, all the tools except for the focused map should be disabled.
    if (layout.type !== LayoutType.Map) {
      return true;
    }
    // The tool is a map
    const mapId = layout.id_tool;
    if (mapState.context.modeOpts.activeMapId !== mapId) {
      return true;
    }
  }
  return false;
};

import { QueryKey } from "@/api";
import { Api } from "@/api/Api";
import type { CustomGeoJSON } from "@mm/metx-workbench.meteomatics.com";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

export const useGetCustomGeoJsonList = (shouldFetch = true) => {
  const { data, isLoading, error } = useQuery<CustomGeoJSON[], Error>(
    [QueryKey.CUSTOM_GEOJSON],
    () => Api.customGeojson.v2GetListCustomGeojson(),
    {
      staleTime: Number.POSITIVE_INFINITY,
      enabled: shouldFetch,
    },
  );
  return { list: data, isLoading, error };
};

// return the GeoJSON
export const useFetchCustomGeoJson = (fileId: string) => {
  const { data, isLoading, error } = useQuery<CustomGeoJSON, Error>(
    [QueryKey.CUSTOM_GEOJSON],
    () => Api.customGeojson.v2GetCustomGeojson({ fileId: fileId }),
    {
      staleTime: Number.POSITIVE_INFINITY,
    },
  );
  return { data, isLoading, error };
};

export const useFindCustomGeoJsonById = (id: string) => {
  const { list, isLoading, error } = useGetCustomGeoJsonList(true);

  const data = useMemo(() => list?.find((item) => item.id === id), [list, id]);

  return { data, isLoading, error };
};

import type { WeatherParamFormat } from "weather-parameter-utils";

/**
 * For both wind and ocean barbs, only "kn" formats are available
 * because configuraring different units won't change the visualization.
 */
export function onlyKnParamFormat(layerFormat: WeatherParamFormat) {
  if (!layerFormat.unit.includes("kn")) {
    // Format without "kn" option is disabled
    return null;
  }
  // Only "kn" option is available
  layerFormat.unit = ["kn"];
  return layerFormat;
}

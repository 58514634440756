import type { MapStateCoord } from "@/models/map-interaction/mapInteractionStateCtxTypes";
import type { ParsedPoiListResponse, PoiDataGrid } from "@/threads/DataProcessingThread/types";
import { Trans, t } from "@lingui/macro";
import type { ColDef, ValueSetterParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { isEmpty, omit } from "lodash";
import { useCallback, useMemo, useRef, useState } from "react";

import { DropZone } from "@/overlay/components/CommonParts/DropZone";
import { dataProcessingThreadPool } from "@/threads";
import { Button } from "../CommonParts/buttons";
import { PoiLayerCsvUpload } from "../LayerStackWindow/PoiLayerCsvUpload";
import { PoiColumnHeader } from "./PoiColumnHeader";
import { PoiInput } from "./PoiInput";
import {
  addPropertyToData,
  coordColDef,
  dataColDef,
  exportColumnsFromData,
  groupColumns,
  replaceKeyInObject,
} from "./utils";

import { PoiLocationInput } from "@/overlay/components/PoiManagerWindow/PoiLocationInput";
import "@/style/aggrid.style.scss";
import "./gridStyle.scss";
import styles from "./style.module.scss";

type PoiTableProps = {
  initialData: PoiDataGrid;
  mapId?: number;
  onChange: (data: PoiDataGrid) => void;
};

function createDedupeRegexForColumnName(name: string) {
  return new RegExp(`^${name}(?: \\((\\d+)\\))?$`);
}

export function PoiTable({ mapId, initialData, onChange }: PoiTableProps) {
  // Shallow clone rows as we don't wan't to mutate actual data
  const [data, setData] = useState(initialData || []);
  const tableRef = useRef<AgGridReact>(null);

  const [rowsSelected, setRowsSelected] = useState(false);

  // Workaround for Ag-Grid + React fundamental issue
  // https://stackoverflow.com/questions/67617716/ag-grid-prevents-access-to-current-value-of-react-state-variable
  const dataRef = useRef<{ [column: string]: any }[]>([]);
  // make stateRef always have the current count
  // your "fixed" callbacks can refer to this object whenever
  // they need the current value.  Note: the callbacks will not
  // be reactive - they will not re-run the instant state changes,
  // but they *will* see the current value whenever they do run
  // columnsDefRef.current = columnsDef;
  dataRef.current = data;

  const gridApi = tableRef.current?.api;

  const handleAddPropery = () => {
    // 2 stands for lat/lon columns as we don't count it in as properties
    const properyIndex = colDefs.length - 2;
    const propertyName = `property${properyIndex ? `_${properyIndex}` : ""}`;

    const regex = createDedupeRegexForColumnName(propertyName);
    const duplicateColumnCount = colDefs.filter((colDef) => regex.test(colDef.field || "")).length;

    const dedupedPropertyName =
      duplicateColumnCount > 0 ? `${propertyName} (${duplicateColumnCount + 1})` : propertyName;

    const updatedData = addPropertyToData(data, dedupedPropertyName);
    setData(updatedData);
    onChange(updatedData);

    // As updating state is async, data inside grid isn't available at first, so we need
    // to scroll to visible row and auto-size on next event loop
    setTimeout(() => {
      gridApi?.ensureColumnVisible(propertyName);
    });
  };

  const handleRemoveProperty = useCallback(
    (colId: string) => {
      const { current: rowsData } = dataRef;

      const filteredData = rowsData.map((row) => omit(row, [colId]));
      setData(filteredData);
      onChange(filteredData);

      // As updating state is async, data inside grid isn't available at first, so we need
      // to scroll to visible row and auto-size on next event loop
      setTimeout(() => {
        gridApi?.autoSizeAllColumns();
      });
    },
    [gridApi, onChange],
  );

  const handleColumnNameChange = useCallback(
    (newName: string, oldName: string) => {
      const { current: rowsData } = dataRef;

      const updatedRowsData = rowsData.map((row) => {
        const regex = createDedupeRegexForColumnName(newName);
        const duplicateColumnCount = Object.keys(row).filter(
          (columnName) => oldName !== columnName && regex.test(columnName),
        ).length;
        const dedupedName = duplicateColumnCount > 0 ? `${newName} (${duplicateColumnCount + 1})` : newName;
        return replaceKeyInObject(row, oldName, dedupedName, row[oldName]);
      });

      setData(updatedRowsData);
      onChange(updatedRowsData);

      // As updating state is async, data inside grid isn't available at first, so we need
      // to scroll to visible row and auto-size on next event loop
      setTimeout(() => {
        gridApi?.autoSizeAllColumns();
      });
    },
    [gridApi, onChange],
  );

  const handleCellValueSetter = useCallback(
    (params: ValueSetterParams) => {
      if (params.node && params.node.rowIndex !== null) {
        const { rowIndex } = params.node;
        const updatedRowsData = [...data];
        const updatableRow = updatedRowsData[rowIndex];
        const updatedColumnId = params.column.getColId();
        const newValue = params.newValue;
        updatedRowsData[rowIndex] = { ...updatableRow, [updatedColumnId]: newValue };
        setData(updatedRowsData);
        onChange(updatedRowsData);

        setTimeout(() => {
          gridApi?.ensureIndexVisible(rowIndex);
        });
      }
      return false; // Always return false so React can handle the cell value update
    },
    [data, gridApi, onChange],
  );

  const colDefs = useMemo<ColDef[]>(() => {
    if (!data.length) {
      return [];
    }

    const checkboxColumns: ColDef = {
      checkboxSelection: true,
      lockPosition: true,
      resizable: false,
      editable: false,
      sortable: false,
      pinned: true,
      maxWidth: 34,
    };

    const columns = exportColumnsFromData(data);
    const { coordCols, dataCols } = groupColumns(columns);

    const coordColumns = coordCols.map((column) => ({
      ...coordColDef(column),
      valueSetter: handleCellValueSetter,
    }));
    const dataColumns = dataCols.map((column) => ({
      ...dataColDef(column),
      valueSetter: handleCellValueSetter,
      headerComponent: PoiColumnHeader,
      headerComponentParams: {
        onRemoveColumn: handleRemoveProperty,
        onColumnNameChange: handleColumnNameChange,
      },
    }));
    return [checkboxColumns, ...coordColumns, ...dataColumns];
  }, [data, handleCellValueSetter, handleRemoveProperty, handleColumnNameChange]);

  const handleCsvUploaded = (poiList: ParsedPoiListResponse) => {
    setData(poiList.data);
    onChange(poiList.data);

    // As updating state is async, data inside grid isn't available at first, so we need
    // to scroll to visible row and auto-size on next event loop
    setTimeout(() => {
      gridApi?.autoSizeAllColumns();
    });
  };

  const handleAddPoint = (coord: MapStateCoord & { label?: string }) => {
    const addedRowIndex = data.length;
    const updatedData = coord.label
      ? data.concat({ lat: coord.lat, lon: coord.lon, label: coord.label })
      : data.concat({ lat: coord.lat, lon: coord.lon });
    setData(updatedData);
    onChange(updatedData);

    // As updating state is async, data inside grid isn't available at first, so we need
    // to scroll to visible row and auto-size on next event loop
    setTimeout(() => {
      gridApi?.autoSizeAllColumns();
      gridApi?.ensureIndexVisible(addedRowIndex);
    });
  };

  const handleSelectRows = () => {
    setRowsSelected(!isEmpty(gridApi?.getSelectedRows()));
  };

  const handleRemoveRows = () => {
    if (gridApi) {
      const selectedNodes = gridApi.getSelectedNodes();
      const selectedIndex = selectedNodes.map((node) => node.rowIndex);

      const updatedData = data.filter((_row, index) => !selectedIndex.includes(index));
      setData(updatedData);
      onChange(updatedData);
    }
  };

  const handleCsvFileDrop = (files: FileList) => {
    dataProcessingThreadPool.parseCsvFiles(files).then((response: ParsedPoiListResponse) => {
      handleCsvUploaded(response);
    });
  };

  return (
    <DropZone placeholder={t`Upload CSV files`} type="text/csv" onDrop={handleCsvFileDrop}>
      <div className={styles["poi-panel"]} id={"poi-panel"}>
        {mapId ? <PoiInput mapId={mapId} onApply={handleAddPoint} /> : <PoiLocationInput onApply={handleAddPoint} />}
        <PoiLayerCsvUpload type="button" onCsvUpload={handleCsvUploaded} />
        <div className={styles["poi-panel__action-bar"]}>
          {mapId && (
            <Button icon="add" onClick={handleAddPropery} disabled={!data.length}>
              Add Property
            </Button>
          )}
        </div>
        <div className={styles["poi-panel-grid"]}>
          <AgGridReact
            className={"ag-theme-alpine"}
            ref={tableRef}
            rowData={data}
            columnDefs={colDefs}
            suppressHeaderFocus={true}
            onSelectionChanged={handleSelectRows}
            rowSelection="multiple"
            headerHeight={30}
            rowHeight={28}
            autoSizeStrategy={{
              type: "fitCellContents",
            }}
            suppressRowClickSelection={true}
          />
        </div>
        <div>
          <Button icon="delete" onClick={handleRemoveRows} disabled={!rowsSelected}>
            Remove Selected Points
          </Button>
        </div>
        <div className={styles["poi-panel__footer"]}>
          <a href="/poi/example.csv" download="example.csv" target="_blank" rel="noreferrer">
            <Trans>Download example CSV</Trans>
          </a>
        </div>
      </div>
    </DropZone>
  );
}

import type { CircleLayer, SymbolLayer } from "mapbox-gl";
import { DETAILS_FILTER_EXPRESSION, POINT_FILTER_EXPRESSION, TIMED_OUT_EXPRESSION } from "./filters";
import { COMMON_LAYOUT_STYLE, TEXT_PAINT_STYLE } from "./styles";
import { constructWindBarbsIconMapExpression, convertRange } from "./utils";

export const INTERACTIVE_LAYERS_NAMES = ["metar_position", "metar_cloud_coverage"];

export const LAYER_DEFINITION: { [key: string]: SymbolLayer | CircleLayer } = {
  metar_position: {
    id: "metar_position",
    filter: POINT_FILTER_EXPRESSION,
    "source-layer": "aviation_reports",
    type: "circle",
    paint: {
      "circle-color": ["case", ["has", "weather_warning"], "#DC2826", "#404040"],
      "circle-radius": 3,
      "circle-opacity": TIMED_OUT_EXPRESSION,
    },
  },
  metar_cloud_coverage: {
    id: "metar_cloud_coverage",
    type: "symbol",
    "source-layer": "aviation_reports",
    filter: DETAILS_FILTER_EXPRESSION,
    layout: {
      "icon-image": ["get", "clouds"],
      "icon-size": 0.24,
      "symbol-avoid-edges": true,
      "icon-allow-overlap": true,
      "icon-rotation-alignment": "map",
      "icon-pitch-alignment": "map",
      "icon-keep-upright": true,
      visibility: "visible",
    },
    paint: {
      "icon-opacity": TIMED_OUT_EXPRESSION,
      "icon-color": ["case", ["has", "weather_warning"], "#DC2826", "#404040"],
    },
  },
  metar_wind_barb: {
    id: "metar_wind_barb",
    type: "symbol",
    "source-layer": "aviation_reports",
    filter: DETAILS_FILTER_EXPRESSION,
    layout: {
      "icon-image": constructWindBarbsIconMapExpression(),
      "icon-rotate": ["to-number", ["get", "wind_direction"]],
      "icon-offset": [0, -23],
      "icon-size": 0.54,
      "symbol-avoid-edges": true,
      "icon-allow-overlap": true,
      "icon-rotation-alignment": "map",
      visibility: "visible",
    },
    paint: {
      "icon-opacity": TIMED_OUT_EXPRESSION,
      "icon-color": "#404040",
    },
  },
  metar_weather_symbol: {
    id: "metar_weather_symbol",
    type: "symbol",
    "source-layer": "aviation_reports",
    filter: DETAILS_FILTER_EXPRESSION,
    layout: {
      "icon-image": "{weather_symbol_key}",
      "icon-offset": [-130, -5],
      "icon-size": 0.24,
      "symbol-avoid-edges": true,
      "icon-allow-overlap": true,
      "icon-rotation-alignment": "map",
      visibility: "visible",
    },
    paint: {
      "icon-opacity": TIMED_OUT_EXPRESSION,
      "icon-color": "#404040",
    },
  },
  metar_visibility_near: {
    id: "metar_visibility_near",
    type: "symbol",
    "source-layer": "aviation_reports",
    filter: DETAILS_FILTER_EXPRESSION,
    layout: {
      ...COMMON_LAYOUT_STYLE,
      "text-field": ["case", ["has", "weather_symbol_key"], "", ["get", "visibility"]],
      "text-padding": 250,
      "text-offset": [-2.5, 0],
      "text-size": 14.5,
      visibility: "visible",
    },
    paint: {
      ...TEXT_PAINT_STYLE,
      "text-opacity": TIMED_OUT_EXPRESSION,
    },
  },
  metar_visibility_far: {
    id: "metar_visibility_far",
    type: "symbol",
    "source-layer": "aviation_reports",
    filter: DETAILS_FILTER_EXPRESSION,
    layout: {
      ...COMMON_LAYOUT_STYLE,
      "text-field": ["case", ["has", "weather_symbol_key"], ["get", "visibility"], ""],
      "text-padding": 250,
      "text-offset": [-5.2, 0],
      "text-size": 14.5,
      visibility: "visible",
    },
    paint: { ...TEXT_PAINT_STYLE, "text-opacity": TIMED_OUT_EXPRESSION },
  },
  metar_ceiling: {
    id: "metar_ceiling",
    type: "symbol",
    "source-layer": "aviation_reports",
    filter: DETAILS_FILTER_EXPRESSION,
    layout: {
      ...COMMON_LAYOUT_STYLE,
      "text-anchor": "left",
      "text-field": "{clouds_ceil}",
      "text-padding": 250,
      "text-offset": [1.7, 0],
      "text-size": 14.5,
      visibility: "visible",
    },
    paint: { ...TEXT_PAINT_STYLE, "text-opacity": TIMED_OUT_EXPRESSION },
  },
  metar_icao_airport_id: {
    id: "metar_icao_airport_id",
    type: "symbol",
    "source-layer": "aviation_reports",
    filter: DETAILS_FILTER_EXPRESSION,
    layout: {
      ...COMMON_LAYOUT_STYLE,
      "text-field": ["get", "icao"],
      "text-padding": 50,
      "text-offset": [0, 2],
      "text-justify": "center",
      "text-size": 14.5,
    },
    paint: { ...TEXT_PAINT_STYLE, "text-opacity": TIMED_OUT_EXPRESSION },
  },
  SPECI: {
    id: "SPECI",
    type: "symbol",
    "source-layer": "aviation_reports",
    filter: ["all", ["has", "has_speci"], DETAILS_FILTER_EXPRESSION],
    layout: {
      ...COMMON_LAYOUT_STYLE,
      "text-field": "SPECI",
      "text-padding": 50,
      "text-offset": [-3, -1.5],
      "text-justify": "center",
      "text-size": 12,
      visibility: "visible",
    },
    paint: { ...TEXT_PAINT_STYLE, "text-opacity": TIMED_OUT_EXPRESSION },
  },
  temperature_and_dew_point: {
    id: "temperature_and_dew_point",
    type: "symbol",
    "source-layer": "aviation_reports",
    filter: DETAILS_FILTER_EXPRESSION,
    layout: {
      ...COMMON_LAYOUT_STYLE,
      "text-field": "{temperature}/{dew_point}",
      "text-padding": 50,
      "text-offset": [-3, 1.5],
      "text-justify": "center",
      "text-size": 12,
      visibility: "visible",
    },
    paint: { ...TEXT_PAINT_STYLE, "text-opacity": TIMED_OUT_EXPRESSION },
  },
};

// Min icon/text size is half of default
// Max icon/text size is double of default
export const ICON_LAYERS = {
  metar_cloud_coverage: {
    minSize: 0.12,
    maxSize: 0.48,
  },
  metar_wind_barb: {
    minSize: 0.27,
    maxSize: 1.08,
  },

  metar_weather_symbol: {
    minSize: 0.12,
    maxSize: 0.48,
  },
};

export const TEXT_LAYERS = {
  metar_visibility_near: {
    minSize: 7.25,
    maxSize: 29,
  },

  metar_visibility_far: {
    minSize: 7.25,
    maxSize: 29,
  },

  metar_ceiling: {
    minSize: 7.25,
    maxSize: 29,
  },

  metar_icao_airport_id: {
    minSize: 7.25,
    maxSize: 29,
  },

  SPECI: {
    minSize: 6,
    maxSize: 24,
  },

  temperature_and_dew_point: {
    minSize: 6,
    maxSize: 24,
  },
};

export const composeMetarLayers = (sourceId: string, initialSize: number): Array<SymbolLayer | CircleLayer> => {
  return Object.entries(LAYER_DEFINITION).map(([id, layer]) => {
    if (ICON_LAYERS[layer.id as keyof typeof ICON_LAYERS]) {
      const { minSize, maxSize } = ICON_LAYERS[layer.id as keyof typeof ICON_LAYERS];
      const layout = {
        ...layer.layout,
        "icon-size": convertRange(initialSize, [10, 34], [minSize, maxSize]),
      };

      return {
        ...layer,
        id: `${id}_${sourceId}`,
        source: sourceId,
        layout,
      } as SymbolLayer;
    }

    if (TEXT_LAYERS[layer.id as keyof typeof TEXT_LAYERS]) {
      const { minSize, maxSize } = TEXT_LAYERS[layer.id as keyof typeof TEXT_LAYERS];
      const layout = {
        ...layer.layout,
        "text-size": convertRange(initialSize, [10, 34], [minSize, maxSize]),
      };

      return {
        ...layer,
        id: `${id}_${sourceId}`,
        source: sourceId,
        layout,
      } as SymbolLayer;
    }

    return {
      ...layer,
      id: `${id}_${sourceId}`,
      source: sourceId,
    };
  });
};

export enum Runtime {
  Node = 0,
  BrowserMain = 1,
  BrowserWorker = 2,
}

export const RUNTIME: Runtime = detectRuntime();

function detectRuntime() {
  const isNode =
    typeof process !== "undefined" && typeof process.release === "object" && process.release.name === "node";
  if (isNode) {
    return Runtime.Node;
  }

  const isBrowserWorker = typeof WorkerGlobalScope !== "undefined" && self instanceof WorkerGlobalScope;

  if (isBrowserWorker) {
    return Runtime.BrowserWorker;
  }

  return Runtime.BrowserMain;
}

/**
 * Check if the runtime
 * @param oneOf
 */
export function ASSERT_RUNTIME(oneOf: Runtime[]) {
  if (!oneOf.includes(RUNTIME)) {
    throw new Error(
      `wrong runtime! expected one of: ${oneOf.map((id) => Runtime[id]).join(", ")}. actual is: ${Runtime[RUNTIME]}.`,
    );
  }
}

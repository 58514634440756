/**
 * Get the variant names of an enum as an array
 */
export function enumKeys<T extends object>(e: T): (keyof T)[] {
  return Object.keys(e).filter((item) => {
    return Number.isNaN(Number(item));
  }) as (keyof T)[];
}

export function enumValues<T extends Record<string, any>, G extends keyof T = keyof T>(e: T): T[G][] {
  const keys = enumKeys(e);
  return keys.map((k) => e[k]) as any;
}

import { networkCaches } from "@/cache/GlobalCache";
import type { WMSRequestParams } from "@/cache/PVSTileService/TileGetters/WMSTileGetter";
import type { TileArea } from "@/cache/SpatioTemporalTileCache/TileArea";
import type { AreaRequest } from "@/layers/AreaRequest";
import type { AppliedStyle } from "@/layers/LayerBase";
import { getModelBoundingBox } from "@/weather-parameters/lookup";
import { ColorMap, type CoordinateSystem, type EnsSelectIdentifier } from "@mm/api.meteomatics.com";
import type { WmsLayer } from "@mm/metx-workbench.meteomatics.com";
import type { DateTime } from "luxon";

/**
 * Given a viewport of the map, create a AreaRequest for the tiling service.
 */
export function getAreaRequest(
  dateTimeWithOffset: DateTime,
  layerProps: WmsLayer,
  viewportArea: TileArea,
): AreaRequest<WMSRequestParams<CoordinateSystem.EPSG3857>> {
  const area = viewportArea;
  const colorMap = layerProps.color_map;
  const appliedStyle: AppliedStyle =
    colorMap !== "" ? ColorMap[layerProps.color_map as keyof typeof ColorMap] : undefined;
  const appliedEnsSelect: EnsSelectIdentifier = layerProps.ens_select ? layerProps.ens_select : "";

  let initDate: string | undefined;

  if (layerProps.custom_options && (layerProps.custom_options as any).init_date) {
    initDate = (layerProps.custom_options as any).init_date;
  }

  return {
    datetime: dateTimeWithOffset,
    area,
    requestParams: {
      parameters: [layerProps.parameter_unit],
      model: layerProps.model,
      boundingBoxLimit: getModelBoundingBox(layerProps.model),
      colorMap: appliedStyle,
      ensSelect: appliedEnsSelect,
      calibrated: layerProps.calibrated,
      vertical_interpolation: layerProps.vertical_interpolation,
      initDate,
    },
  };
}

/**
 * Get a tile set. The tile set includes only the tiles at the time of calling the function.
 */
export function getTileSet(dateTimeWithOffset: DateTime, layerProps: WmsLayer, viewportArea: TileArea) {
  const areaRequest = getAreaRequest(dateTimeWithOffset, layerProps, viewportArea);
  const pvs = networkCaches.wms_tile_cache.getTileSetInstant(areaRequest);
  return pvs;
}

/**
 * Get a tile set promise which resolves after all the tiles are fetched or failed.
 */
export function getTileSetPromise(dateTimeWithOffset: DateTime, layerProps: WmsLayer, viewportArea: TileArea) {
  const areaRequest = getAreaRequest(dateTimeWithOffset, layerProps, viewportArea);
  const pvsPromise = networkCaches.wms_tile_cache.getTileSetPromise(areaRequest);
  return pvsPromise;
}

import type {
  EnergyForecastApi,
  EnergyForecastTimeSeriesV2,
  GetEnergyForecastRequest,
} from "@mm/forecast-data.meteomatics.com";

import type { PlotRequest } from "../models";
import type { BasePlotSeriesDefinition } from "./BasePlotDefinition";

type HighchartsXyData = [number, number | null][];

type EnergyForecastTimeSeriesForecastWithCombined = EnergyForecastTimeSeriesV2["forecast"] & {
  combined_delta_1h: HighchartsXyData;
  combined_delta_6h: HighchartsXyData;
  combined_delta_12h: HighchartsXyData;
  combined_delta_24h: HighchartsXyData;
};

export type EnergyForecastTimeSeriesWithCombined = Omit<EnergyForecastTimeSeriesV2, "forecast"> & {
  forecast: EnergyForecastTimeSeriesForecastWithCombined;
};

const FIXED_POWER_DECIMAL = 3;

/**
 * Forecast server returns values in MW, but we handle the data in GW after fetched data.
 */
export function scaleEnergyForecastVal(val: number): number {
  return Number((val / 1000).toFixed(FIXED_POWER_DECIMAL));
}

export class EnergyPlotDefinition
  implements BasePlotSeriesDefinition<EnergyForecastApi, PlotRequest<GetEnergyForecastRequest>>
{
  transform(data: EnergyForecastTimeSeriesV2): EnergyForecastTimeSeriesWithCombined {
    // power and deltas have sometimes different array length!
    const parsedCombinedDelta24h: HighchartsXyData = [];
    const parsedCombinedDelta12h: HighchartsXyData = [];
    const parsedCombinedDelta6h: HighchartsXyData = [];
    const parsedCombinedDelta1h: HighchartsXyData = [];
    const tsPower: { [ts: number]: number } = {};

    // Convert from timestamps to js ms
    const parsedPower = data.forecast.power.map(([timestamp, value]) => {
      const tsPowerScaled = [timestamp * 1000, scaleEnergyForecastVal(value)];
      tsPower[timestamp * 1000] = scaleEnergyForecastVal(value);
      return tsPowerScaled;
    });

    const parsedActual = data.forecast.actual?.map(([timestamp, value]) => {
      return [timestamp * 1000, scaleEnergyForecastVal(value)];
    });

    const parsedDelta12h = data.forecast.delta_12h.map(([timestamp, value]) => {
      const msTs = timestamp * 1000;
      const powerIsNumber = Number.isNaN(Number(tsPower[msTs]));
      if (tsPower[msTs] === undefined || powerIsNumber) {
        parsedCombinedDelta12h.push([msTs, null]);
      } else {
        const powerMinusDelta = tsPower[msTs] - scaleEnergyForecastVal(value);
        parsedCombinedDelta12h.push([msTs, Number(powerMinusDelta.toFixed(FIXED_POWER_DECIMAL))]);
      }

      return [msTs, scaleEnergyForecastVal(value)];
    });

    const parsedDelta6h = data.forecast.delta_6h.map(([timestamp, value]) => {
      const msTs = timestamp * 1000;
      const powerIsNumber = Number.isNaN(Number(tsPower[msTs]));
      if (tsPower[msTs] === undefined || powerIsNumber) {
        parsedCombinedDelta6h.push([msTs, null]);
      } else {
        const powerMinusDelta = tsPower[msTs] - scaleEnergyForecastVal(value);
        parsedCombinedDelta6h.push([msTs, Number(powerMinusDelta.toFixed(FIXED_POWER_DECIMAL))]);
      }

      return [msTs, scaleEnergyForecastVal(value)];
    });
    const parsedDelta1h = data.forecast.delta_1h?.map(([timestamp, value]) => {
      const msTs = timestamp * 1000;
      const powerIsNumber = Number.isNaN(Number(tsPower[msTs]));
      if (tsPower[msTs] === undefined || powerIsNumber) {
        parsedCombinedDelta1h.push([msTs, null]);
      } else {
        const powerMinusDelta = tsPower[msTs] - scaleEnergyForecastVal(value);
        parsedCombinedDelta1h.push([msTs, Number(powerMinusDelta.toFixed(FIXED_POWER_DECIMAL))]);
      }

      return [msTs, scaleEnergyForecastVal(value)];
    });

    const parsedDelta24h = data.forecast.delta_24h?.map(([timestamp, value]) => {
      const msTs = timestamp * 1000;
      const powerIsNumber = Number.isNaN(Number(tsPower[msTs]));
      if (tsPower[msTs] === undefined || powerIsNumber) {
        parsedCombinedDelta24h.push([msTs, null]);
      } else {
        const powerMinusDelta = tsPower[msTs] - scaleEnergyForecastVal(value);
        parsedCombinedDelta24h.push([msTs, Number(powerMinusDelta.toFixed(FIXED_POWER_DECIMAL))]);
      }

      return [msTs, scaleEnergyForecastVal(value)];
    });

    return {
      ...data,
      forecast: {
        ...data.forecast,
        power: parsedPower,
        actual: parsedActual,
        delta_24h: parsedDelta24h,
        delta_12h: parsedDelta12h,
        delta_6h: parsedDelta6h,
        delta_1h: parsedDelta1h,
        combined_delta_24h: parsedCombinedDelta24h,
        combined_delta_12h: parsedCombinedDelta12h,
        combined_delta_6h: parsedCombinedDelta6h,
        combined_delta_1h: parsedCombinedDelta1h,
      },
    };
  }

  request(
    api: EnergyForecastApi,
    definition: PlotRequest<GetEnergyForecastRequest>,
  ): Promise<EnergyForecastTimeSeriesV2> {
    return api.getEnergyForecastV2(definition);
  }
}

import { FormField } from "@/overlay/components/form-field";
import { useOutsideClick } from "@/utility/customHooks";
import { useRef, useState } from "react";
import { SketchPicker } from "react-color";

export function ColorPickerInput(props: { label: string; value: string; change: (color: string) => void }) {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const wrapperRef = useRef<HTMLDivElement>(null);
  useOutsideClick(wrapperRef, () => {
    setDisplayColorPicker(false);
  });

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  return (
    <div ref={wrapperRef}>
      <div className="color-picker">
        <FormField label={props.label}>
          <div className="color-picker__swatch" onClick={handleClick} data-testid="color-picker-input">
            <div className="color-picker__color" style={{ background: props.value }} />
          </div>
        </FormField>
      </div>
      {displayColorPicker ? (
        <div className="color-picker__popup" data-testid="color-picker-popup">
          <div className="color-picker__cover" onClick={handleClose} />
          <SketchPicker color={props.value} onChangeComplete={(color) => props.change(color.hex)} />
        </div>
      ) : null}
    </div>
  );
}

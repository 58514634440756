/**
 *
 * Note that typescript is a structural language. Thus, the types on these abstractions
 * are for documentation purposes. The compiler will not fail to compile invalid mixtures
 * of enum types or swapped argument orders since all types reduce to `number`.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type BitField<T> = number;

/**
 * Check if all of the flags in `subfield` are contained within `field`
 *
 * @param field
 * @param subfield
 * @returns
 */
export function has<T extends { [key: string]: number }>(field: BitField<T>, subfield: BitField<T>) {
  return (subfield & field) === subfield;
}

/**
 * Check if any of the flags in `subfield` are contained within `field`
 *
 * @param field
 * @param subfield
 * @returns
 */
export function any<T extends { [key: string]: number }>(field: BitField<T>, subfield: BitField<T>) {
  return (subfield & field) > 0;
}

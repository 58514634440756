import { useUser } from "@/api/hooks/user";
import { OpenFeature } from "@openfeature/react-sdk";
import { useEffect } from "react";

export const useInitFeatureFlagContext = () => {
  const { user } = useUser();

  useEffect(() => {
    if (user) {
      OpenFeature.setContext({ userKey: user.username });
    }
  }, [user]);
};

import type { CrossModelParameterSpecification } from "@mm/api-layers.meteomatics.com";
import type { WeatherOptionFilterFlag } from "../paramFilterTypes";

export type GeneralParamFilter = (props: {
  paramGroup: MutableLayerGroupModel;
  filterFlag: WeatherOptionFilterFlag;
}) => MutableLayerGroupModel | null;

/**
 * Used for the GeneralParamFilter to signify the layers attribute should not be modfied
 * within GeneralParamFilter. If you need to modify the `LayerGroupModel.layers` (=parameter format),
 * use the format filter.
 */
type MutableLayerGroupModel = Omit<CrossModelParameterSpecification, "layers">;

/**
 * Given a general weather parameter's particular format LayerGroupModel (e.g: wind_speed),
 * this function will determine
 * 1. If the given weather parameter should be used for the layer kind.
 * 2. Apply necessary modification.
 *
 * Note: You can directly modify the object as it's already deep copied.
 *
 * @returns
 * - When returning null, the format will be disabled for the given layer kind.
 * - When returning a LayerGroupModel, its values will be used for the layer kind.
 */
export const pickAndModifyParamGroup: GeneralParamFilter = ({
  paramGroup,
  filterFlag,
}: {
  paramGroup: MutableLayerGroupModel;
  filterFlag: WeatherOptionFilterFlag;
}): MutableLayerGroupModel | null => {
  switch (filterFlag) {
    case "WindAnimationLayerDescription": {
      // We had model limitations here as paramGroup.available_models = ["mix"];
      // Leave this as placeholder, if some issue comes up with models
      break;
    }
  }
  return paramGroup;
};

import { type StateMachine, createMachine } from "xstate";
import { dismissContextMenu, setContextMenu, setSelectedMap, updateCoordinates } from "./mapInteractionStateActions";
import type {
  ContextMenuCtx,
  DefaultCtx,
  MapInteractionContextUnion,
  POISelectCtx,
} from "./mapInteractionStateCtxTypes";
import type { MapInteractionEventUnion } from "./mapInteractionStateEvents";
import { isTargetMap } from "./mapInteractionStateUtils";

type MapInteractionTypeState =
  | {
      value: "COORD_SELECT";
      context: POISelectCtx;
    }
  | {
      value: "CONTEXT_MENU";
      context: ContextMenuCtx;
    };

export type MapInteractionStateMachine = StateMachine<
  MapInteractionContextUnion,
  any,
  MapInteractionEventUnion,
  MapInteractionTypeState
>;

export const createMapInteractionStateMachine = (
  initialCtx: MapInteractionContextUnion = { modeOpts: null } as DefaultCtx,
  initialState = "DEFAULT",
): MapInteractionStateMachine => {
  return createMachine<MapInteractionContextUnion, MapInteractionEventUnion, MapInteractionTypeState>({
    id: "mapInteractionState",
    initial: initialState,
    context: initialCtx,
    states: {
      DEFAULT: {
        on: {
          contextMenu: { target: "CONTEXT_MENU", actions: [setContextMenu] },
          setCoordSelect: { target: "COORD_SELECT", actions: [setSelectedMap] },
        },
      },
      // Mode to allow selecting single coordinate from a particular map by click.
      COORD_SELECT: {
        on: {
          click: { target: "DEFAULT", actions: [updateCoordinates], cond: isTargetMap },
        },
      },
      CONTEXT_MENU: {
        on: {
          dismiss: { target: "DEFAULT", actions: [dismissContextMenu] },
        },
      },
    },
    predictableActionArguments: true,
  });
};

export const defaultMapInteractionMachine = createMapInteractionStateMachine();

import type { YAxisOptions } from "highcharts";
import React, { type PropsWithChildren, useContext, useReducer } from "react";

import { useProfileAndTabParams } from "@/hooks";
import type { ActiveLayers } from "@/overlay/components/PopupPlotWindow";
import { HighchartsYAxis, HighchartsYAxisPointForecast } from "@/plot";
import { useCreateActiveLayers } from "@/plotView/hooks";
import type { PopupPlotsActions } from "@/reducer/plot/action";
import { type PopupPlotState, PopupPlotType, initialPopupPlotState } from "@/reducer/plot/state";

import { DefaultPointForecastSeriesList } from "@/reducer/client-models";
import { useActiveLayers } from "./hooks";
import { popupPlotsReducer } from "./reducer";

/**
 * Holds the popup plot state
 * with the needed information for creating the highcharts and when
 */

export const PopupPlotContext = React.createContext<{
  state: PopupPlotState;
  dispatch: React.Dispatch<PopupPlotsActions>;
}>({
  state: initialPopupPlotState,
  dispatch: () => undefined,
});

const PlotDataContext = React.createContext<{ activeLayers: ActiveLayers[]; yAxis: YAxisOptions[] }>({
  activeLayers: [],
  yAxis: [],
});

const ActiveLayersProvider = ({ children }: PropsWithChildren<unknown>) => {
  const { tabId } = useProfileAndTabParams();
  const { layers } = useActiveLayers(tabId);

  return (
    <PlotDataContext.Provider value={{ activeLayers: layers, yAxis: HighchartsYAxis }}>
      {children}
    </PlotDataContext.Provider>
  );
};

const PointForecastLayersProvider = ({ children }: PropsWithChildren<unknown>) => {
  const activeLayers = useCreateActiveLayers(DefaultPointForecastSeriesList);

  return (
    <PlotDataContext.Provider value={{ activeLayers: activeLayers, yAxis: HighchartsYAxisPointForecast }}>
      {children}
    </PlotDataContext.Provider>
  );
};

const PlotDataProviderSwitch = ({ children, popupPlotType }: PropsWithChildren<{ popupPlotType: PopupPlotType }>) => {
  switch (popupPlotType) {
    case PopupPlotType.ActiveLayers:
      return <ActiveLayersProvider>{children}</ActiveLayersProvider>;
    case PopupPlotType.PointForecast:
      return <PointForecastLayersProvider>{children}</PointForecastLayersProvider>;
    case PopupPlotType.Tephigram:
      return <>{children}</>;
  }
};

export const PopupPlotProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [state, dispatch] = useReducer(popupPlotsReducer, initialPopupPlotState);

  return (
    <PopupPlotContext.Provider value={{ state, dispatch }}>
      {/* Don't create data provider while popup plot is not visible, as we don't wan't to re-render when layers change */}
      {state.show ? <PlotDataProviderSwitch popupPlotType={state.type}>{children}</PlotDataProviderSwitch> : children}
    </PopupPlotContext.Provider>
  );
};

export const usePopupPlot = () => {
  return useContext(PopupPlotContext);
};

export const usePlotLayers = () => {
  return useContext(PlotDataContext);
};

import { merge } from "lodash";
import {
  type HasLayerIdxNarrowing,
  type PartialWeatherParameter,
  autofill,
  parameterToString,
} from "weather-parameter-utils";

/**
 * Update the original partial weather parameter with patche objects which includes the updated attributes.
 * Then it returns the string parameter unit.
 * @param originalParam
 * @param patches
 * @returns
 */
export function getUpdatedParamUnit(
  originalParam: HasLayerIdxNarrowing<PartialWeatherParameter>,
  patches: Partial<HasLayerIdxNarrowing<PartialWeatherParameter>>[],
): string {
  let updatedParam = merge(originalParam, ...patches);
  updatedParam = autofill(updatedParam);
  const paramUnit: string = parameterToString(updatedParam).formatted;
  return paramUnit;
}

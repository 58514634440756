import { createId } from "@paralleldrive/cuid2";
import { groupByActionTypes } from "redux-undo";

export const batchGroupBy = {
  _group: null as any | null,
  start(group: any = createId()): void {
    this._group = group;
  },
  end(): void {
    this._group = null;
  },
  init(rawActions: any[]): (action: any) => any[] {
    const defaultGroupBy = groupByActionTypes(rawActions);
    // Because of RTK, we just use action types (strings) for grouping/filtering
    // redux-undo does not have proper types, we can improve it in our fork
    // @ts-ignore
    return (action: any) => this._group || defaultGroupBy([action]);
  },
};

import { useUser } from "@/api/hooks/user";
import {
  aviationLayerConfig,
  barbsLayerConfig,
  gridLayerConfig,
  isoLinesLayerConfig,
  stationLayerConfig,
  symbolLayerConfigWWcode,
  symbolLayerConfigWeatherSymbol,
  weatherFrontsLayerConfig,
} from "@/constants/layerConfigAttributes";
import { FeatureHubGuard } from "@/featureFlags";
import type { LayerUnion, LayerUnionWithAll } from "@/layers";
import type { AviationTypesUnion } from "@/layers/utility/aviationType";
import { type LayerKind, LayerKindName, type LayerWithParameterUnit } from "@/layers/utility/createLayerObject";
import { WindowKind, instanceId, useDesktop } from "@/overlay/components";
import { IntegerInput } from "@/overlay/components/CommonParts/CustomInput";
import { DataResolutionSettings } from "@/overlay/components/DataResolutionSettings";
import { AviationLegends } from "@/overlay/components/LayerStackWindow/AviationLegends";
import { CustomGeoJsonUpdateForm } from "@/overlay/components/LayerStackWindow/CustomGeoJsonForm";
import { InitDateField } from "@/overlay/components/LayerStackWindow/InitDateField";
import { InitTimeField } from "@/overlay/components/LayerStackWindow/InitTimeField";
import {
  AviationTypeSelect,
  CalibratedToggle,
  ColorPickerInput,
  ModelSelect,
  OpacityInput,
  StepConfigInput,
  WMSLayerSelect,
} from "@/overlay/components/LayerStackWindow/LayerFormElements";
import { IconSizeInput } from "@/overlay/components/LayerStackWindow/LayerFormElements/IconSizeInput";
import { PoiLayerPanel } from "@/overlay/components/LayerStackWindow/PoiLayerPanel";
import { TropicalCycloneFeatureSelect } from "@/overlay/components/LayerStackWindow/TropicalCycloneFeatureSelect";
import { ModelAndWeatherParameterForm } from "@/overlay/components/ModelAndWeatherParameterForm";
import { FormField, InputFormField } from "@/overlay/components/form-field";
import {
  setLayersIconItemProps,
  setLayersProps,
  setLayersPropsAndColoringOptions,
  setPoiOptions,
  useMap,
} from "@/reducer/MapsState";
import { setSelectedLayerForAddParameter, setSelectedMap } from "@/reducer/UIState";
import { batchGroupBy } from "@/reducer/batchGroupBy";
import type { ColoringOptions, IconData, PoiOptions } from "@/reducer/client-models";
import type { PoiLayer } from "@/reducer/client-models/PoiLayer";
import { hasCalibratedAvailable } from "@/utility/calibratedAvailabilityMap";
import { transformEnsSelectStringToArray } from "@/utility/ensemble";
import { getDefaultModelName } from "@/utility/layer";
import { getSearchEngine, stationMosModel_TEMPORAL, stationObsModel_TEMPORAL } from "@/weather-parameters";
import { getModelSchemFromStr } from "@/weather-parameters/lookup";
import { t } from "@lingui/macro";
import {
  type AviationLayer,
  type BackgroundMapLayer,
  type BarbsLayer,
  type CartographicMap,
  type CustomGeoJSONLayer,
  type GridLayer,
  type IsoLinesLayer,
  type LightningLayer,
  type MapId,
  type PressureSystemLayer,
  ProjectionNameEnum,
  type StationLayer,
  type SymbolLayer,
  SymbolLayerType,
  type TropicalCycloneLayer,
  VerticalInterpolationType,
  type WeatherFrontsLayer,
  type WmsLayer,
} from "@mm/metx-workbench.meteomatics.com";
import { useDispatch } from "react-redux";
import * as weatherParameter from "weather-parameter-utils";
import type { HasLayerIdxNarrowing, PartialWeatherParameter } from "weather-parameter-utils";
import { LightningLayerUpdateForm } from "../../LightningLayerForm/LightningLayerUpdateForm";
import "./style.scss";

export type LayerConfigType = { mapId: MapId; layer: LayerUnionWithAll; cartographicMap: CartographicMap };

const listOfLayerKindWithoutInitTime: LayerKind[] = [
  "BackgroundMapDescription",
  "WeatherFrontsLayerDescription",
  "StationLayerDescription",
  "AviationLayerDescription",
  LayerKindName.CustomGeoJSONLayerDescription,
];

export function LayerConfig(props: LayerConfigType) {
  const hasInitTimeCheckboxVisible =
    !listOfLayerKindWithoutInitTime.includes(props.layer.kind as LayerKind) &&
    (
      props.layer as Exclude<
        typeof props.layer,
        BackgroundMapLayer | AviationLayer | TropicalCycloneLayer | CustomGeoJSONLayer
      >
    ).model !== "mix";
  const newLayer = props.layer as Exclude<
    typeof props.layer,
    BackgroundMapLayer | AviationLayer | TropicalCycloneLayer | CustomGeoJSONLayer
  >;

  return (
    <>
      <LayerConfigSwitch {...props} />
      {hasInitTimeCheckboxVisible && <InitTimeField mapId={props.mapId} layer={newLayer} />}
    </>
  );
}

export function LayerConfigSwitch(props: LayerConfigType) {
  const dispatch = useDispatch();
  const desktop = useDesktop();
  // This is such a bullshit. We need whole map here, as it's used for adding new parameter to layer
  // Why just don't use layer directly?
  const map = useMap(props.mapId.map);
  const { user } = useUser();

  function changeProps(propsValue: Partial<LayerUnion>, isGrouped?: boolean) {
    const layerProps = { layerId: props.layer.id, id: props.mapId.map, props: propsValue };
    if (isGrouped) {
      batchGroupBy.start();
    }
    dispatch(setLayersProps(layerProps));
  }

  function changePoiLayerItemProps(newItem: IconData) {
    dispatch(setLayersIconItemProps({ layerId: props.layer.id, id: props.mapId.map, item: newItem }));
  }

  function changePoiLayerColoringOptionsProps(poiOptions: PoiOptions, options: ColoringOptions | undefined) {
    let param = {};
    // remove parameter_unit to prevent request
    if (options === undefined) {
      param = {
        parameter_unit: "",
      };
    }

    dispatch(
      setLayersProps({
        layerId: props.layer.id,
        id: props.mapId.map,
        props: {
          poiOptions: {
            ...poiOptions,
            coloringOptions: options,
          },
          ...param,
        },
      }),
    );
  }

  function onEditLayerParam() {
    function submitLayerParamChange(param: HasLayerIdxNarrowing<PartialWeatherParameter>) {
      const modelBefore = (props.layer as LayerWithParameterUnit).model;

      changeProps({
        parameter_unit: weatherParameter.parameterToString(param).formatted,
        model: getDefaultModelName(param, modelBefore),
      });
    }

    desktop.dispatch({
      type: "setWindowCustomProps",
      windowId: instanceId(WindowKind.AddParameter),
      windowKind: WindowKind.AddParameter,
      customProps: {
        source: "edit-map-param",
        onSubmitParam: submitLayerParamChange,
        searchEngines: [{ label: "Standard", searchEngine: getSearchEngine(props.layer) }],
      },
    });
    desktop.dispatch({ type: "setWindowVisibility", windowId: instanceId(WindowKind.AddParameter), isVisible: true });
  }

  function addPoiParameter(map: CartographicMap, layer: PoiLayer) {
    function submitNewParam(map: CartographicMap, param: HasLayerIdxNarrowing<PartialWeatherParameter>) {
      const model = getDefaultModelName(param);
      dispatch(
        setLayersPropsAndColoringOptions({
          id: map.id,
          layerId: layer.id,
          props: {
            model: model,
            parameter_unit: weatherParameter.parameterToString(param).formatted,
          },
        }),
      );
    }

    dispatch(setSelectedMap(map));
    dispatch(setSelectedLayerForAddParameter(props.layer));
    desktop.dispatch({
      type: "setWindowCustomProps",
      windowId: instanceId(WindowKind.AddParameter),
      windowKind: WindowKind.AddParameter,
      customProps: { source: "add-map-param", onSubmitParam: (param) => submitNewParam(map, param) },
    });
    desktop.dispatch({ type: "setWindowVisibility", windowId: instanceId(WindowKind.AddParameter), isVisible: true });
  }

  function handleUpdatePoiOptions(map: CartographicMap, layerId: number, options: Partial<PoiOptions>) {
    dispatch(
      setPoiOptions({
        id: map.id,
        layerId,
        poiOptions: options,
      }),
    );
  }

  // Why? At this point we will always has a map, as this dialog just simply can't show up without map layer
  if (!map) {
    return <></>;
  }

  switch (props.layer.kind) {
    case LayerKindName.WmsLayerDescription: {
      const wmsLayer = props.layer as WmsLayer;
      return (
        <>
          <ModelAndWeatherParameterForm layer={wmsLayer} mapId={props.mapId} onEditParameter={onEditLayerParam} />
          <WMSLayerSelect id={props.mapId} layer={wmsLayer} change={(propsValue) => changeProps(propsValue, false)} />
          <OpacityInput
            layer={wmsLayer}
            change={changeProps}
            onStart={() => batchGroupBy.start()}
            onEnd={() => batchGroupBy.end()}
          />
          <DataResolutionSettings
            id={props.mapId}
            lod_bias={props.cartographicMap.lod_bias ?? 0}
            disabled={
              !!props.cartographicMap.map_projection?.name &&
              props.cartographicMap.map_projection?.name !== ProjectionNameEnum.mercator
            }
          />
          {user?.is_developer && (
            <FormField label={t`Downscale`}>
              <input
                type="checkbox"
                checked={props.layer.vertical_interpolation !== VerticalInterpolationType.none}
                onChange={() => {
                  changeProps({
                    vertical_interpolation:
                      props.layer.vertical_interpolation === VerticalInterpolationType.none
                        ? VerticalInterpolationType.downscale
                        : VerticalInterpolationType.none,
                  });
                }}
              />
            </FormField>
          )}
          {hasCalibratedAvailable(wmsLayer.parameter_unit) && (
            <CalibratedToggle value={!!props.layer.calibrated} change={changeProps} />
          )}
          <FeatureHubGuard flag="init_date">
            <InitDateField
              defaultValue={(wmsLayer.custom_options as any).init_date ?? ""}
              onChange={(propsValue?: string) => {
                changeProps({
                  custom_options: {
                    ...wmsLayer.custom_options,
                    init_date: propsValue || "",
                  },
                });
              }}
            />
          </FeatureHubGuard>
        </>
      );
    }
    case LayerKindName.GridLayerDescription: {
      const gridLayer = props.layer as GridLayer;
      return (
        <>
          <ModelAndWeatherParameterForm layer={gridLayer} mapId={props.mapId} onEditParameter={onEditLayerParam} />
          <OpacityInput
            layer={gridLayer}
            change={changeProps}
            onStart={() => batchGroupBy.start()}
            onEnd={() => batchGroupBy.end()}
          />
          <ColorPickerInput
            label={t`Text Color`}
            change={(color) => changeProps({ text_color: color })}
            value={gridLayer.text_color}
          />
          <FormField label={t`Text Size`}>
            <IntegerInput
              {...gridLayerConfig.textSizeLimits}
              onChange={(value) => changeProps({ text_size: value })}
              value={gridLayer.text_size || 0}
            />
          </FormField>
          <StepConfigInput
            layer={gridLayer}
            stepRange={{ ...gridLayerConfig.stepRange }}
            id={props.mapId}
            change={changeProps}
          />
          {hasCalibratedAvailable(gridLayer.parameter_unit) && (
            <CalibratedToggle value={!!props.layer.calibrated} change={changeProps} />
          )}
        </>
      );
    }
    case LayerKindName.IsoLinesLayerDescription: {
      const isoLineLayer = props.layer as IsoLinesLayer;
      return (
        <>
          <ModelAndWeatherParameterForm layer={isoLineLayer} mapId={props.mapId} onEditParameter={onEditLayerParam} />
          <OpacityInput
            layer={isoLineLayer}
            change={changeProps}
            onStart={() => batchGroupBy.start()}
            onEnd={() => batchGroupBy.end()}
          />
          {transformEnsSelectStringToArray(isoLineLayer.ens_select ?? "").length > 1 || (
            <ColorPickerInput
              label={t`Text Color`}
              change={(color) => changeProps({ text_color: color })}
              value={isoLineLayer.text_color}
            />
          )}
          <FormField label={t`Text Size`}>
            <IntegerInput
              {...isoLinesLayerConfig.textSizeLimits}
              onChange={(value) => changeProps({ text_size: value })}
              value={isoLineLayer.text_size || 0}
            />
          </FormField>
          {transformEnsSelectStringToArray(isoLineLayer.ens_select ?? "").length > 1 || (
            <ColorPickerInput
              label={t`Line Color`}
              change={(color) => changeProps({ line_color: color })}
              value={isoLineLayer.line_color}
            />
          )}
          <FormField label={t`Line Width`}>
            <IntegerInput
              {...isoLinesLayerConfig.lineWidthLimits}
              onChange={(value) => changeProps({ line_width: value })}
              value={isoLineLayer.line_width || 0}
            />
          </FormField>

          <InputFormField
            label={t`Values`}
            onChange={(event) => changeProps({ values: event.target.value })}
            value={isoLineLayer.values}
          />
          <InputFormField
            label={t`Range`}
            placeholder={t`Min,Max,Step`}
            onChange={(event) => changeProps({ value_range: event.target.value })}
            value={isoLineLayer.value_range}
          />

          <FormField label={t`Median filter`}>
            <IntegerInput
              {...isoLinesLayerConfig.gaussianAndMedianFilterLimits}
              onChange={(value) => changeProps({ filter_median: value })}
              value={isoLineLayer.filter_median || 0}
            />
          </FormField>
          <FormField label={t`Gaussian filter`}>
            <IntegerInput
              {...isoLinesLayerConfig.gaussianAndMedianFilterLimits}
              onChange={(value) => changeProps({ filter_gauss: value })}
              value={isoLineLayer.filter_gauss || 0}
            />
          </FormField>
          {hasCalibratedAvailable(isoLineLayer.parameter_unit) && (
            <CalibratedToggle value={!!props.layer.calibrated} change={changeProps} />
          )}
        </>
      );
    }
    case LayerKindName.WeatherFrontsLayerDescription: {
      const weatherFrontsLayer = props.layer as WeatherFrontsLayer;
      const initialModels = [
        getModelSchemFromStr("ecmwf-ifs"),
        getModelSchemFromStr("ncep-gfs"),
        getModelSchemFromStr("ukmo-um10"),
      ];
      const models = initialModels.filter((model): model is Exclude<typeof model, null> => model !== null);

      return (
        <>
          <ModelSelect models={models} layerModelIdentifier={weatherFrontsLayer.model} change={changeProps} />
          <FormField label={t`Line Width`}>
            <IntegerInput
              {...weatherFrontsLayerConfig.lineWidthLimits}
              onChange={(value) => changeProps({ line_width: value })}
              value={weatherFrontsLayer.line_width || 0}
            />
          </FormField>
          <InputFormField
            label={t`Icon Size`}
            type="range"
            onChange={(event) => changeProps({ text_size: Number(event.target.value) })}
            // Calculate the min & max in the input so increasing the input will result in smaller steps.
            {...weatherFrontsLayerConfig.iconSizeLimits}
            step={weatherFrontsLayerConfig.iconSizeStep}
            onMouseDown={() => batchGroupBy.start()}
            onMouseUp={() => batchGroupBy.end()}
            value={Number(weatherFrontsLayer.text_size)}
          />
        </>
      );
    }
    case LayerKindName.BarbsLayerDescription: {
      const barbsLayer = props.layer as BarbsLayer;

      return (
        <>
          <ModelAndWeatherParameterForm layer={barbsLayer} mapId={props.mapId} />
          <StepConfigInput
            layer={barbsLayer}
            stepRange={{ ...barbsLayerConfig.stepRange }}
            id={props.mapId}
            change={changeProps}
          />
          <IconSizeInput
            layer={barbsLayer}
            stepRange={{ ...barbsLayerConfig.iconSizeRange, step: 0.01 }}
            id={props.mapId}
            change={changeProps}
          />
          <ColorPickerInput
            label={t`Element Color`}
            change={(color) => changeProps({ element_color: color })}
            value={barbsLayer.element_color}
          />
          {hasCalibratedAvailable(barbsLayer.parameter_unit) && (
            <CalibratedToggle value={!!props.layer.calibrated} change={changeProps} />
          )}
        </>
      );
    }
    case LayerKindName.SymbolLayerDescription: {
      const symbolLayer = props.layer as SymbolLayer;
      const checkedShowOnlySignificantWeather = !!symbolLayer.custom_options?.show_only_significant_weather;

      const { layer_type: symbolLayerType } = symbolLayer;
      return (
        <>
          <ModelAndWeatherParameterForm layer={symbolLayer} mapId={props.mapId} />
          {/* Weather symbol layer */}
          {symbolLayerType === SymbolLayerType.WeatherSymbol && (
            <>
              <StepConfigInput
                layer={symbolLayer}
                stepRange={{ ...symbolLayerConfigWeatherSymbol.stepRange }}
                id={props.mapId}
                change={changeProps}
              />
              <IconSizeInput
                layer={symbolLayer}
                stepRange={{ ...symbolLayerConfigWeatherSymbol.iconSizeRange, step: 0.01 }}
                id={props.mapId}
                change={changeProps}
              />
            </>
          )}
          {symbolLayerType === SymbolLayerType.WeatherCode && (
            <>
              <StepConfigInput
                layer={symbolLayer}
                stepRange={{ ...symbolLayerConfigWWcode.stepRange }}
                id={props.mapId}
                change={changeProps}
              />
              <IconSizeInput
                layer={symbolLayer}
                stepRange={{ ...symbolLayerConfigWWcode.iconSizeRange, step: 0.01 }}
                id={props.mapId}
                change={changeProps}
              />
            </>
          )}

          <FormField label={t`only significant weather`}>
            <input
              type="checkbox"
              checked={checkedShowOnlySignificantWeather}
              onChange={() =>
                changeProps({
                  custom_options: {
                    ...symbolLayer.custom_options,
                    show_only_significant_weather: !checkedShowOnlySignificantWeather,
                  },
                })
              }
            />
          </FormField>
        </>
      );
    }
    case LayerKindName.StationLayerDescription: {
      const stationLayer = props.layer as StationLayer;
      // TODO-StationFeature: Remove the following code.
      // This is only to show a model on the Model select depending on the station layer model.
      let models: any;
      if (stationLayer.model === "mm-mos") {
        models = [stationMosModel_TEMPORAL];
      } else {
        models = [stationObsModel_TEMPORAL];
      }

      return (
        <>
          <ModelSelect models={models} layerModelIdentifier={stationLayer.model} change={changeProps} />
          <OpacityInput
            layer={stationLayer}
            change={changeProps}
            onStart={() => batchGroupBy.start()}
            onEnd={() => batchGroupBy.end()}
          />
          <ColorPickerInput
            label={t`Text Color`}
            change={(color) => changeProps({ text_color: color })}
            value={stationLayer.text_color ? stationLayer.text_color : "#000000"}
          />
          <FormField label={t`Text Size`}>
            <IntegerInput
              {...stationLayerConfig.textSizeLimits}
              onChange={(value) => changeProps({ text_size: value })}
              value={stationLayer.text_size || 0}
            />
          </FormField>
        </>
      );
    }
    case LayerKindName.GenericPoiLayerDescription: {
      const poiLayer = props.layer as PoiLayer;
      return (
        <>
          <PoiLayerPanel
            poiOptions={poiLayer.poiOptions}
            onChangePoiOptions={(options) => handleUpdatePoiOptions(map, poiLayer.id, options)}
            onChangeItem={(newItem: IconData) => changePoiLayerItemProps(newItem)}
            onChangeColoringOptions={(options) => changePoiLayerColoringOptionsProps(poiLayer.poiOptions, options)}
            onEditParameter={onEditLayerParam}
            onAddParameter={() => addPoiParameter(map, poiLayer)}
            layer={poiLayer}
            mapId={props.mapId}
          />
        </>
      );
    }
    case LayerKindName.AviationLayerDescription: {
      const aviationLayer = props.layer as AviationLayer;
      return (
        <>
          <AviationTypeSelect
            aviationType={aviationLayer.aviation_type}
            change={(propsValue) => changeProps(propsValue)}
          />
          {/*<InputFormField*/}
          {/*    label={t`Size`}*/}
          {/*    type="number"*/}
          {/*    {...aviationLayerConfig.sizeLimits}*/}
          {/*    onChange={(event) => changeProps({ text_size: Number(event.target.value) })}*/}
          {/*    defaultValue={aviationLayer.text_size}*/}
          {/*/>*/}
          <InputFormField
            label={t`Size`}
            type="range"
            onChange={(event) => changeProps({ text_size: Number(event.target.value) })}
            // Calculate the min & max in the input so increasing the input will result in smaller steps.
            {...aviationLayerConfig.sizeLimits}
            onMouseDown={() => batchGroupBy.start()}
            onMouseUp={() => batchGroupBy.end()}
            value={Number(aviationLayer.text_size)}
          />
          <AviationLegends aviationType={aviationLayer.aviation_type as AviationTypesUnion} />
        </>
      );
    }
    case LayerKindName.PressureSystemLayerDescription: {
      const pressureSystemLayer = props.layer as PressureSystemLayer;
      return (
        <>
          <ModelAndWeatherParameterForm
            layer={pressureSystemLayer}
            mapId={props.mapId}
            onEditParameter={onEditLayerParam}
          />
          <InputFormField
            label={t`Values`}
            onChange={(event) => changeProps({ values: event.target.value })}
            value={pressureSystemLayer.values}
          />
          <InputFormField
            label={t`Range`}
            placeholder={t`Min,Max,Step`}
            onChange={(event) => changeProps({ value_range: event.target.value })}
            value={pressureSystemLayer.value_range}
          />

          <FormField label={t`Median filter`}>
            <IntegerInput
              {...isoLinesLayerConfig.gaussianAndMedianFilterLimits}
              onChange={(value) => changeProps({ filter_median: value })}
              value={pressureSystemLayer.filter_median || 0}
            />
          </FormField>
          <FormField label={t`Gaussian filter`}>
            <IntegerInput
              {...isoLinesLayerConfig.gaussianAndMedianFilterLimits}
              onChange={(value) => changeProps({ filter_gauss: value })}
              value={pressureSystemLayer.filter_gauss || 0}
            />
          </FormField>
          {hasCalibratedAvailable(pressureSystemLayer.parameter_unit) && (
            <CalibratedToggle value={!!props.layer.calibrated} change={changeProps} />
          )}
        </>
      );
    }
    case LayerKindName.LightningLayerDescription: {
      const fileteredModels = [getModelSchemFromStr("mix")].filter(
        (model): model is Exclude<typeof model, null> => model !== null,
      );
      return (
        <LightningLayerUpdateForm
          {...props}
          layer={props.layer as LightningLayer}
          onChange={changeProps}
          models={fileteredModels}
        />
      );
    }
    case LayerKindName.TropicalCycloneLayerDescription: {
      const tropicalCycloneLayer = props.layer as TropicalCycloneLayer;
      return (
        <>
          <TropicalCycloneFeatureSelect
            tropicalyCyclone={tropicalCycloneLayer.aviation_type}
            change={(propsValue) => changeProps(propsValue)}
          />
          <ColorPickerInput
            label={t`Icon Color`}
            change={(color) => changeProps({ line_color: color })}
            value={tropicalCycloneLayer.line_color}
          />
          <InputFormField
            label={t`Line Width`}
            type="range"
            onChange={(event) => changeProps({ line_width: Number(event.target.value) })}
            value={Number(tropicalCycloneLayer.line_width)}
            onMouseDown={() => batchGroupBy.start()}
            onMouseUp={() => batchGroupBy.end()}
            min={1}
            max={10}
          />
          <OpacityInput
            layer={tropicalCycloneLayer}
            change={changeProps}
            onStart={() => batchGroupBy.start()}
            onEnd={() => batchGroupBy.end()}
          />
        </>
      );
    }
    case LayerKindName.CustomGeoJSONLayerDescription:
      return <CustomGeoJsonUpdateForm {...props} layer={props.layer as CustomGeoJSONLayer} onChange={changeProps} />;
    case undefined:
      return null;
    default: {
      // @ts-expect-error
      const _exhaustive: never = props.layer.kind;
      return _exhaustive;
    }
  }
}

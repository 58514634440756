import type { Position } from "geojson";
import proj4 from "proj4";
import {CoordinateSystem} from "./CoordinateSystem";

/**
 * Variant of `Position List` that is safe to transfer between workers and over the network.
 */
export interface Positions_Transferable<Crs extends CoordinateSystem> {
  crs: Crs;
  positions: Array<Position>;
}

export class Positions<Crs extends CoordinateSystem> {
  constructor(public positions: Array<Position>, public readonly crs: Crs) {}
  private inCrs(v: any): v is Crs {
    return this.crs === v;
  }

  project(toCrs: Crs): Positions<Crs>;
  project<TargetCrs extends CoordinateSystem>(
    toCrs: TargetCrs
  ): Positions<TargetCrs>;
  project(toCrs: CoordinateSystem): Positions<CoordinateSystem> {
    if (this.inCrs(toCrs)) {
      return this.copy();
    } else {
      const projectedPolygon = this.positions.map<Position>(([lng, lat]) =>
        proj4(this.crs, toCrs, [lng, lat])
      );
      return new Positions(projectedPolygon, toCrs);
    }
  }

  toTransferable(): Positions_Transferable<Crs> {
    return {
      crs: this.crs,
      positions: this.positions,
    };
  }
  copy(): Positions<Crs> {
    return new Positions(this.positions, this.crs);
  }

  static fromTransferable<Crs extends CoordinateSystem>({
    positions,
    crs,
  }: Positions_Transferable<Crs>): Positions<Crs> {
    return new Positions(positions, crs);
  }
}

import { IconStore } from "@/icon-store/IconStore";
const ICON_SIZE = IconStore?.instance.dimension;

/**
 * Computes the `icon-size` of an element of the `IconStore`.
 *
 *                      Note that some weather icons contain intrinsic padding, thus `perfect covering` may include space around icons.
 * @param scalingFactorPercent
 * @param desiredSizePx desired size in hardware pixels
 * @param devicePixelRatio used to convert `desiredSizePx` to software pixels
 */
export function getMapboxIconSize(
  scalingFactorPercent: number,
  desiredSizePx: number,
  devicePixelRatio = window.devicePixelRatio,
) {
  // currently, the ICON_SIZE is fixed to 64
  return (scalingFactorPercent / ICON_SIZE) * desiredSizePx * (1 / devicePixelRatio);
}

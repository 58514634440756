/**
 * Wrapper around `CacheEntry`s that become available asynchronously.
 */
export enum CacheDatumState {
  /**
   * Data is already requested, but still loading
   */
  Pending = 0,
  /**
   * Data is available
   */
  Loaded = 1,
  /**
   * Data retrieval failed permanently
   */
  PermanentlyFailed = 2,
}

export interface PendingCacheDatum<T, B> {
  state: CacheDatumState.Pending;
  /**
   * A number indicating the age of the cache line.
   *
   * This number MUST not be interpreted as a timestamp. But instead, as an opaque number sorting cache lines monotonically.
   * Older datetimes have lower numbers.
   */
  requestDatetime: number;

  promise: Promise<T>;
  cancel?: () => void;
  payload: B;
}

export interface LoadedCacheDatum<T> {
  state: CacheDatumState.Loaded;

  /**
   * A number indicating the age of the cache line.
   *
   * This number MUST not be interpreted as a timestamp. But instead, as an opaque number sorting cache lines monotonically.
   * Older datetimes have lower numbers.
   */
  requestDatetime: number;
  /**
   * A number indicating how recently the cache line was accessed.
   *
   * This number MUST not be interpreted as a timestamp. But instead, as an opaque number sorting cache lines monotonically.
   * More recent access times have larger numbers.
   */
  lastUsed: number;

  payload: T;
}

export interface PermanentlyFailedCacheDatum<T> {
  state: CacheDatumState.PermanentlyFailed;

  /**
   * A number indicating the age of the cache line.
   *
   * This number MUST not be interpreted as a timestamp. But instead, as an opaque number sorting cache lines monotonically.
   * Older datetimes have lower numbers.
   */
  requestDatetime: number;

  payload: T;
}

/**
 * A cache line in our cache.
 */
export type CacheDatum<T, B = null, C = null> =
  | PendingCacheDatum<T, B>
  | LoadedCacheDatum<T>
  | PermanentlyFailedCacheDatum<C>;

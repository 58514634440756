import { DateTime } from "luxon";

export interface Metars_Transferable {
  icao_code: string;
  issue_time: string;
  metar_report: string;
}

export interface Metars {
  icao_code: string;
  issue_time: DateTime;
  metar_report: string;
}

export interface MetarsResponse {
  metars: Metars[]
}

export interface MetarsResponse_Transferable {
  metars: Metars_Transferable[];
}

export function metarsFromTransferable({ metars }: MetarsResponse_Transferable): MetarsResponse {
  const parsedMetars = metars.map<Metars>((m) => ({
    icao_code: m.icao_code,
    metar_report: m.metar_report,
    issue_time: DateTime.fromISO(m.issue_time),
  }));

  return {
    metars: parsedMetars
  }
}
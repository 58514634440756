import { cleanedEnvFF } from "@/env/validate";
import type { AvailableBuildFF } from "@/env/validate";
import { useFlag } from "@openfeature/react-sdk";
import type { PropsWithChildren } from "react";

type FeatureGuardProps = {
  flag: AvailableBuildFF;
};

const isFeatureFlagEnabled = (flag: AvailableBuildFF) => {
  if (cleanedEnvFF[flag] !== undefined) {
    return cleanedEnvFF[flag];
  }
  // Always show feature, if flag is missing
  return true;
};

/**
 * Component to toggle certain feature based on build-time feature flags which are injected as env variable during the build time.
 */
export function FeatureGuard({ children, flag }: PropsWithChildren<FeatureGuardProps>) {
  const enabled = isFeatureFlagEnabled(flag);
  if (enabled) return <>{children}</>;

  return <></>;
}

// TODO Cleanup
type FeatureHubGuardProps = {
  flag: string;
};

export function FeatureHubGuard({ children, flag }: PropsWithChildren<FeatureHubGuardProps>) {
  const { value } = useFlag(flag, false);

  if (!value) {
    return <></>;
  }

  return children;
}

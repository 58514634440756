import { createLayer } from "@/api/hooks/layer";
import { useProfileAndTabParams } from "@/hooks";
import type { LayerCreateControllerProp } from "@/overlay/components/SmartForm/LayerForms/LayerFormMapping";
import { useAppDispatch } from "@/reducer";
import type { BackgroundMapLayerCreate } from "@mm/metx-workbench.meteomatics.com";
import _ from "lodash";
import type { SubmitHandler } from "react-hook-form";
import {
  CartographicMaterialCreateForm,
  type CartographicMaterialFormData,
} from "../view/CartographicMaterialCreateForm";

/**
 * Handles all the logic for map background layer implementation creation.
 * Note:
 * This is a controller, meaning that we should generally NOT delegate layer creation logic
 * to the parent component by passing callback from parent etc.
 */
export function CartographicMaterialCreateController(props: LayerCreateControllerProp<BackgroundMapLayerCreate>) {
  const { selectedMapId, defaultPayload, onDone } = props;
  const dispatch = useAppDispatch();
  const { profileId } = useProfileAndTabParams();

  const onSubmit: SubmitHandler<CartographicMaterialFormData> = (formData) => {
    if (!profileId) {
      return;
    }
    const finalPayload = _.merge(defaultPayload, formData.layerData);
    dispatch(createLayer({ mapId: selectedMapId, profileId: profileId, data: finalPayload }));

    onDone?.();
  };

  return (
    <>
      <CartographicMaterialCreateForm
        defaultFormData={defaultPayload}
        onSubmit={onSubmit}
        submitMode="onSubmit"
        onCancel={props.onDone}
      />
    </>
  );
}

import type { Point } from "mapbox-gl";
import type { MapStateCoord } from "./mapInteractionStateCtxTypes";

/**
 * Event types to trigger transitions between states
 */
enum TransitionEventType {
  contextMenu = "contextMenu",
  dismiss = "dismiss",

  setCoordSelect = "setCoordSelect",
  click = "click",
}

/**
 * Event types to trigger self transition within a single state
 */
enum SelfTransitionEventType {}

export const MapInteractionEventType = { ...TransitionEventType, ...SelfTransitionEventType };

export type MapInteractionEventUnion =
  | {
      type: TransitionEventType.setCoordSelect;
      mapId: number;
      onCoordinateSelect: (coordinates: MapStateCoord) => void;
    }
  | { type: TransitionEventType.contextMenu; mapId: number; coordinates: MapStateCoord; point: Point }
  | { type: TransitionEventType.click; mapId: number; coordinates: MapStateCoord }
  | { type: TransitionEventType.dismiss };

export const SENTRY_TRANSACTION_NAMES = {
  MAP_LOADING_SPEED: "Map Loading Speed",
  AVIATION_LAYER_LOADING_SPEED: "Aviation Layer Loading Speed",
  BACKGROUND_LAYER_LOADING_SPEED: "Background Layer Loading Speed",
  BARBS_LAYER_LOADING_SPEED: "Barbs Layer Loading Speed",
  GRID_LAYER_LOADING_SPEED: "Grid Layer Loading Speed",
  ISOLINES_LAYER_LOADING_SPEED: "ISO Lines Layer Loading Speed",
  LIGHTNING_LAYER_LOADING_SPEED: "Lightning Layer Loading Speed",
  POI_LAYER_LOADING_SPEED: "POI Layer Loading Speed",
  PRESSURE_SYSTEM_LAYER_LOADING_SPEED: "Pressure System Layer Loading Speed",
  STATION_LAYER_LOADING_SPEED: "Station Layer Loading Speed",
  SYMBOL_LAYER_LOADING_SPEED: "Symbol Layer Loading Speed",
  TROPICAL_CYCLONE_LAYER_LOADING_SPEED: "Tropical Cyclone Layer Loading Speed",
  FRONTS_LAYER_LOADING_SPEED: "Weather Fronts Layer Loading Speed",
  WMS_LAYER_LOADING_SPEED: "WMS Layer Loading Speed",
};

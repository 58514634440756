import type { DrawingLayer } from "@/layers/geojson/drawing/TEMPORAL_TYPE_DRAWING";
import { type LayerKind, LayerKindName } from "@/layers/utility/createLayerObject";
import type { PoiLayer, PoiLayerCreate } from "@/reducer/client-models/PoiLayer";
import type {
  AviationLayer,
  BackgroundMapLayer,
  CartographicMap,
  CartographicMapCreate,
  CustomGeoJSONLayer,
  GenericPoiLayer,
  GenericPoiLayerCreate,
  TropicalCycloneLayer,
} from "@mm/metx-workbench.meteomatics.com";

export type LayerUnion = Exclude<Exclude<CartographicMap["layers"], undefined>[number], GenericPoiLayer> | PoiLayer;
export type LayerCreateUnion =
  | Exclude<Exclude<CartographicMapCreate["layers"], undefined>[number], GenericPoiLayerCreate>
  | PoiLayerCreate;

/**
 *
 */
export type WeatherLayerUnion = Exclude<
  LayerUnion,
  BackgroundMapLayer | AviationLayer | TropicalCycloneLayer | CustomGeoJSONLayer
>;

// special case
export type LayerUnionWithAll = Exclude<CartographicMap["layers"], undefined>[number];
export const nonTimeRelatedLayerKind: LayerKind[] = [
  LayerKindName.BackgroundMapDescription,
  LayerKindName.TropicalCycloneLayerDescription,
  LayerKindName.CustomGeoJSONLayerDescription,
];

import type { ComponentSwitchMapping } from "./types";

/**
 * A utility component to switch different components based on the props.
 *
 * The definition of "which component should be picked" can be configured by the mapping object parameter.
 */
export function ComponentSwitcher<CompProps extends object, ComponentId extends string>(props: {
  componentProps: CompProps;
  componentMapping: ComponentSwitchMapping<CompProps, ComponentId>;
}) {
  const { componentProps, componentMapping } = props;
  const componentId = componentMapping.resolvePropsToComponentId(componentProps);
  const createComponent = componentMapping.componentsById[componentId];

  if (!createComponent) {
    return <span>Nothing to display</span>;
  }
  const formComponet = createComponent(componentProps);

  return <>{formComponet}</>;
}

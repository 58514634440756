import { enumValues } from "@mm/api.meteomatics.com/lib/utility/enum";

export enum ConditionOptions {
  "<" = "<",
  "<=" = "<=",
  "==" = "==",
  ">" = ">",
  ">=" = ">=",
}

export type ConditionOptionsType = {
  [key: string]: (a: number, b: number) => boolean;
};

export const ConditionExpression: ConditionOptionsType = {
  ">=": (a, b) => a >= b,
  "<=": (a, b) => a <= b,
  ">": (a, b) => a > b,
  "<": (a, b) => a < b,
  "==": (a, b) => a === b,
  "!=": (a, b) => a !== b,
};

export const evaluateExpression = (expression: keyof ConditionOptionsType, a: number, b: number) => {
  const evaluator = ConditionExpression[expression];
  return evaluator(a, b);
};

export const conditionValues = enumValues(ConditionOptions);

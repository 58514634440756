import { createLayer } from "@/api/hooks/layer";
import type { LayerUnion, LayerUnionWithAll } from "@/layers";
import type { Plot, PlotSeriesType } from "@/reducer/client-models";
import type {
  CartographicMap,
  Profile,
  SlimProfile,
  Tab,
  TabBase,
  TephigramSchema,
  WeatherTableSchema,
} from "@mm/metx-workbench.meteomatics.com";
import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

type ProfileElement = {
  type: "profile";
  item: Profile | SlimProfile;
};
type TabElement = {
  type: "tab";
  item: Tab | TabBase;
};
type LayerElement = {
  type: "layer";
  tabId: Tab["id"];
  item: LayerUnionWithAll;
};
type PlotSeriesElement = {
  type: "plot-serie";
  plotSerieIdx: PlotSeriesType["index"];
  item: Plot;
};
type TableColumnElement = {
  type: "table-column";
  profileId: Profile["id"];
  item: WeatherTableSchema;
  groupIdx: number;
  columnIdx: number;
};
type RemoveWindowLabel = {
  /**
   * A text label to show in the delete modal.
   */
  label: string;
};

export type RemovableElement =
  | (ProfileElement & RemoveWindowLabel)
  | (TabElement & RemoveWindowLabel)
  | (LayerElement & RemoveWindowLabel)
  | (PlotSeriesElement & RemoveWindowLabel)
  | (TableColumnElement & RemoveWindowLabel)
  | null;

type UiState = {
  selectedLayerId: LayerUnion["id"] | null;
  selectedElementToRemove: RemovableElement;
  headerVisibilityMode: "ALWAYS_SHOW" | "TOGGLABLE_ON_HOVER";
  footerVisibilityMode: "ALWAYS_SHOW" | "TOGGLABLE_ON_HOVER";
  selectedMap: CartographicMap | null;
  selectedPlot: Plot | null;
  selectedTool: Plot | WeatherTableSchema | TephigramSchema | null; //TODO create Tool type? to combine Plot and table and more
  selectedPlotSerieForEditParameter: PlotSeriesType | null;
  selectedLayerForAddParameter: LayerUnionWithAll | null;
  selectedLayerForEditParameter: LayerUnionWithAll | null;
  isLayoutArrangementActive: boolean;
};

const initialState: UiState = {
  selectedLayerId: null,
  selectedElementToRemove: null,
  headerVisibilityMode: "TOGGLABLE_ON_HOVER",
  footerVisibilityMode: "TOGGLABLE_ON_HOVER",
  selectedMap: null,
  selectedPlot: null,
  selectedTool: null,
  selectedPlotSerieForEditParameter: null,
  selectedLayerForAddParameter: null,
  selectedLayerForEditParameter: null,
  isLayoutArrangementActive: false,
};

// Processes any sort of UI state that is required to control multiple components.
const uiReducer = createSlice({
  name: "ui",
  initialState: initialState,
  reducers: {
    /**
     * Set a currently selected layer. Used to create focusing effects on a particular layer.
     * @param state
     * @param action
     */
    selectLayerById(state, action: PayloadAction<LayerUnion["id"]>) {
      state.selectedLayerId = action.payload;
    },
    /**
     * Keep track of selected item that a user is trying to remove across components. Used for the delete confirmation window.
     * @param state
     * @param action
     */
    selectedElementToRemove(state, action: PayloadAction<RemovableElement>) {
      state.selectedElementToRemove = action.payload;
    },
    /**
     * Set the visibility mode of the header.
     * @param state
     * @param action "ALWAYS_SHOW" to always show the header, and "TOGGLABLE_ON_HOVER" to allow a user to hide/show itby hover.
     */
    setHeaderVisibilityMode(state, action: PayloadAction<UiState["headerVisibilityMode"]>) {
      state.headerVisibilityMode = action.payload;
    },
    /**
     * Set the visibility mode of the footer.
     * @param state
     * @param action "ALWAYS_SHOW" to always show the footer, and "TOGGLABLE_ON_HOVER" to allow a user to hide/show it by hover.
     */
    setFooterVisibilityMode(state, action: PayloadAction<UiState["footerVisibilityMode"]>) {
      state.footerVisibilityMode = action.payload;
    },
    setSelectedMap(state, action: PayloadAction<CartographicMap | null>) {
      state.selectedMap = action.payload;
      state.selectedLayerForEditParameter = null; // clear selecteLaeyerId Selection
    },
    setSelectedPlot(state, action: PayloadAction<Plot | null>) {
      state.selectedPlot = action.payload;
      state.selectedPlotSerieForEditParameter = null; // clear plotSerie Selection
    },
    setSelectedTool(state, action: PayloadAction<Plot | WeatherTableSchema | TephigramSchema | null>) {
      state.selectedTool = action.payload;
    },
    setSelectedPlotSerieForEditParameter(state, action: PayloadAction<PlotSeriesType | null>) {
      state.selectedPlotSerieForEditParameter = action.payload;
    },
    setSelectedLayerForEditParameter(state, action: PayloadAction<LayerUnionWithAll | null>) {
      state.selectedLayerForEditParameter = action.payload;
    },
    setSelectedLayerForAddParameter(state, action: PayloadAction<LayerUnionWithAll | null>) {
      state.selectedLayerForAddParameter = action.payload;
    },
    setLayoutArrangement(state, action: PayloadAction<boolean>) {
      state.isLayoutArrangementActive = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createLayer.fulfilled, (state, action) => {
      state.selectedLayerId = action.payload.id;
    });
  },
});

export const {
  selectLayerById,
  selectedElementToRemove,
  setHeaderVisibilityMode,
  setFooterVisibilityMode,
  setSelectedLayerForEditParameter,
  setSelectedLayerForAddParameter,
  setSelectedMap,
  setSelectedPlot,
  setSelectedTool,
  setSelectedPlotSerieForEditParameter,
  setLayoutArrangement,
} = uiReducer.actions;
export default uiReducer.reducer;

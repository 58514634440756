import { IsoLinesRequest } from "./IsoLinesRequest";
import { Timer } from "../performanceIntrospection/Timer";
import { BaseResponse } from "./BaseResponse";
import {GeoJSONFeatureCollection} from "./GeoJSONFeatureCollection";

export class IsoLinesResponse implements BaseResponse<IsoLinesRequest, GeoJSONFeatureCollection> {
  request: IsoLinesRequest;
  data: GeoJSONFeatureCollection;
  timer: Timer;

  constructor(response: IsoLinesResponse) {
    this.data = response.data;
    this.request = response.request;
    this.timer = response.timer;
  }
}

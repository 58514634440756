import { apiThreadPool } from "@/cache/SpatioTemporalTileCache/ApiQueryThreadPool";
import { setGlobalContextRegistry } from "@/cache/Texture";
import { Scene } from "@/layers/Scene";
import type { LayerKind } from "@/layers/utility/createLayerObject";
import { DEFAULT_MAX_MAPBOX_CONCURRENT_INSTANCES, ObjectPool, type WithInUseMarker } from "@/map/ObjectPool";
import type { TemporalDiscretization } from "@/time";
import { safeFmt } from "@/utility/safeFmt";
import type { GridDimension } from "@mm/api.meteomatics.com";
import type {
  CartographicMap,
  DateTimeDesc,
  GuiTimeZone,
  MapProjection,
  Viewport,
} from "@mm/metx-workbench.meteomatics.com";
import Logger from "logging";
import type { DateTime } from "luxon";
import mapboxgl, { type Marker } from "mapbox-gl";
import type { LayerBase } from "./LayerBase";
import { type LayerUnion, type LayerUnionWithAll, nonTimeRelatedLayerKind } from "./LayerUnion";
import type { MapboxMap } from "./MapboxMap";
import type { SceneId } from "./SceneId";
import type { SceneLayerApi } from "./SceneLayerApi";

const logger = Logger.fromFilename(__filename);

/** Readonly data object containing scene composition */
export interface SceneProps {
  id: SceneId;

  map: MapboxMap;
  temporalDiscretization: TemporalDiscretization;
  dateTimeDesc: DateTimeDesc;
  timeOffsetMinutes: CartographicMap["time_offset_mins"];
  displayTime: DateTime;
  nextTime: DateTime;
  renderSize: GridDimension;
  lodBias: number;
}

/**
 * Methods visible to the reconciler
 */
export interface ScenePublicApi extends SceneLayerApi {
  readonly id: SceneId;

  setDisplayTime(displayTime: DateTime): void;
  setNextTime(nextTime: DateTime): void;
  setDateTimeDescription(abstractDateTime: DateTimeDesc): void;
  setTimeOffsetMinutes(durationIsoStr: CartographicMap["time_offset_mins"] | undefined): void;
  setLayerStackDescription(layers: LayerUnionWithAll[], timezone: GuiTimeZone, fallback?: boolean): void;
  setViewportDescription(viewport: Viewport): void;
  syncToMapboxViewport(): void;
  syncMapboxToViewport(viewport: Viewport): void;
  layers: LayerBase<LayerUnion>[];
  dateTimeDesc: DateTimeDesc; // for plot window
  isMapboxLoaded(): boolean;
  getDateTimeDescription(): DateTimeDesc;
  setProjection(param: MapProjection): void;
  getProjection(): MapProjection;
  setLodBias(lodBias: number): void;
  getLodBias(): number;
  getPopupMarker(): Marker | null;
  setPopupMarker(newMarker: Marker | null): void;
}

type SceneAllocatorCallback = () => SceneProps;
export type SceneAllocatorParameters = [SceneAllocatorCallback];

type MapboxAllocatorCallback = () => mapboxgl.MapboxOptions;
type MapboxAllocatorParameters = [MapboxAllocatorCallback];

export interface CompositorPublicApi {
  getScene(id: SceneId, cb: SceneAllocatorCallback): ScenePublicApi;
  getMapboxMapFromPool(id: SceneId, allocatorParams: MapboxAllocatorCallback): mapboxgl.Map;

  tryGetScene(id: SceneId): ScenePublicApi | undefined;
  getAllSceneIter(): IterableIterator<[SceneId, WithInUseMarker<Scene>]>; // # TODO
  inUse(id: SceneId): boolean;
  free(id: SceneId): void;
}

export type CompositorLayerApi = any;

/**
 * The compositor carries global rendering state of MetX by holding a collection of all allocated visualizations.
 *
 * # Why would there be a compositor in addition to the browser compositor?
 *
 * 1. We need a global place that has knowledge about the whole map rendering state across
 *    all maps for: network abort controllers, deduplication of data frame compositing and
 *    maybe others. So, this abstraction primarily exists to allow us to iterate over
 *    the active visualizations.
 * 2. It allows us to render to a single canvas that is shared across all rendering engines.
 *    But this __massive__ benefit is currently prevented by our dependence on mapbox-gl-js.
 *    A future commit should let the Compositor manage a single WebGl context that is passed
 *    to the visualization renderer.
 */
export class Compositor implements CompositorPublicApi, CompositorLayerApi {
  scenes: ObjectPool<SceneId, Scene, SceneAllocatorParameters>;
  mapboxMaps: ObjectPool<SceneId, mapboxgl.Map, MapboxAllocatorParameters>;

  constructor() {
    this.scenes = this.allocateScenePool();
    this.mapboxMaps = this.allocateMapboxMapPool();
  }

  _setDisplayTimeAndTemporalDiscretization(scene: Scene): void {
    if (this.inUse(scene.id)) {
      this._updateAbortController();
      scene.repaint();
    }
  }

  _setBounds(scene: Scene): void {
    if (this.inUse(scene.id)) {
      this._updateAbortController();
      scene.repaint();
    }
  }

  _setLayerStack(scene: Scene): void {
    if (this.inUse(scene.id)) {
      this._updateAbortController();
      scene.repaint();
    }
  }

  _setRenderSize(scene: Scene): void {
    if (this.inUse(scene.id)) {
      this._updateAbortController();
      scene.repaint();
    }
  }

  _setZoomLevelBias(scene: Scene): void {
    // TODO: hard to guess the abort condition for data and render size, maybe just kill every tile request
    // thats older than a timestamp
    if (this.inUse(scene.id)) {
      this._updateAbortController();
      scene.repaint();
    }
  }

  _updateAbortController() {
    // TODO: most updates will update a single scene. So its probably worthwile to add an additional sparser update
    // to the dataset, not only this 'update everything' path.
    const newAbortDataSet = [];

    for (const [/*sceneId*/ , { obj: scene, inUse }] of this.scenes._get_cache()) {
      // this aborts retrieval of tiles that are not currently visible. For example, since they
      // are in a different tab.
      if (!inUse) {
        continue;
      }

      newAbortDataSet.push(...scene.getAbortDecisionDataSet());
    }

    apiThreadPool.updateAbortController(newAbortDataSet);
  }

  _repaint(scene: Scene): void {
    if (!this.inUse(scene.id)) {
      logger.warn(safeFmt`ignoring repaint request of unused scene <${scene.id}>`);
      return;
    }
    scene.map.triggerRepaint();
  }

  getScene(id: SceneId, allocatorParams: SceneAllocatorCallback): ScenePublicApi {
    const scene = this.scenes.allocate(id, allocatorParams);

    // recompute all data since the scene is either new or switches from an inactive state to `inUse: true`
    this._updateAbortController();

    return scene;
  }

  getMapboxMapFromPool(id: SceneId, allocatorParams: MapboxAllocatorCallback): mapboxgl.Map {
    const map = this.mapboxMaps.allocate(id, allocatorParams);
    return map;
  }

  tryGetScene(id: SceneId): ScenePublicApi | undefined {
    return this.scenes._get_cache().get(id)?.obj;
  }

  /**
   * Returns an iterator to loop all the scenes.
   * Use it with caution as it is a O(n) operation which might have performance impact.
   */
  getAllSceneIter(): IterableIterator<[SceneId, WithInUseMarker<Scene>]> {
    return this.scenes._get_cache().entries();
  }

  free(id: SceneId): void {
    this.scenes.free(id);
  }

  inUse(id: SceneId) {
    const scene = this.scenes._get_cache().get(id);
    return scene?.inUse ?? false;
  }

  private allocateScene(args: SceneAllocatorParameters[0]): Scene {
    const obj = args();
    return new Scene(obj);
  }

  // Release all active requests, debounces, etc.
  // Basically any async tasks, that we want to cancel
  // when scene is destroyed
  private releaseScene(scene: Scene) {
    for (const layer of scene.layers) {
      layer.releaseLayer?.();
    }
  }

  private deallocateScene(scene: Scene) {
    // TODO: cleanup all gpu resources, e.g.
    // - delete gl from prepass cache
    // - delete gl from all textures

    // Rebind render function.
    scene.map.on("render", () => {});
    scene.deallocate();
    // Free map.
    this.mapboxMaps.free(scene.id);
  }

  private allocateScenePool() {
    // TODO: we could test for the maximal number of webgl contexts that do not result in a context lost
    //       and pass it as object pool size.
    return new ObjectPool<SceneId, Scene, SceneAllocatorParameters>(
      this.allocateScene.bind(this),
      this.deallocateScene.bind(this),
      this.releaseScene.bind(this),
    );
  }

  private allocateMapboxMap(args: MapboxAllocatorParameters[0]): mapboxgl.Map {
    const obj = args();
    return new mapboxgl.Map(obj);
  }

  private deallocateMapboxMap(map: mapboxgl.Map) {
    //
  }
  private allocateMapboxMapPool() {
    return new ObjectPool<SceneId, mapboxgl.Map, MapboxAllocatorParameters>(
      this.allocateMapboxMap.bind(this),
      this.deallocateMapboxMap.bind(this),
      undefined,
      DEFAULT_MAX_MAPBOX_CONCURRENT_INSTANCES,
      true,
    );
  }
}

export const compositor_internal: Compositor = new Compositor();
/**
 * Global compositor instance shared by all profiles and tabs in MetX2.
 */
export const compositor: CompositorPublicApi = compositor_internal;

export function* gpuContexts() {
  for (const [, { inUse, obj }] of compositor_internal.scenes._get_cache().entries()) {
    yield { inUse, gl: obj.getWebGl() };
  }
}

setGlobalContextRegistry(gpuContexts);

export function _debug_scenes() {
  return compositor_internal.scenes._get_cache().entries();
}

// Get All layers for animation
// Filter non-time related layers
export function _debug_all_layers_from_used_scenes() {
  const allLayer = [];
  for (const [, { inUse, obj: scene }] of _debug_scenes()) {
    if (!inUse || !scene.isActive) {
      continue;
    }

    // filter by shown layer and nonTimeRelatedLayer
    const filteredLayers = scene.layers
      .filter((layer) => layer.getLayerProps().show)
      .filter((layer) => {
        const layerKind = layer?.getLayerProps().kind;
        return layerKind ? !nonTimeRelatedLayerKind.includes(layerKind as LayerKind) : false;
      });
    if (filteredLayers.length) {
      allLayer.push(...filteredLayers);
    }
  }
  return allLayer;
}

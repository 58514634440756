import { LazyAuthentication } from "./LazyAuthentication";

export class AccessTokenAuthentication extends LazyAuthentication<{ token: string }> {
  getAccessToken(): Promise<string> {
    if (typeof this.value === "string") {
      return Promise.resolve(this.value);
    }
    return this.value.promise;
  }

  getAuthorizationHeaderValue(): Promise<string | null> {
    return Promise.resolve(null);
  }

  protected makeValue(value: { token: string }): string {
    return value.token;
  }

  /**
   * Access Token authentication is only supported as a get request.
   */
  forceGetRequest(): boolean {
    return true;
  }
}

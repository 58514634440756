import { Language, languageOptions, onlyBorderStyleLayer } from "@/constants/maptilerStyleOptions";
import type { ParamOptionInputData } from "@/overlay/components/SmartForm/HookInputs/ParamOptionSelectList";
import { SelectInput } from "@/overlay/components/SmartForm/HookInputs/SelectInput";
import { ModeSwitchableForm } from "@/overlay/components/SmartForm/SmartFormUtils/FormComponents/ModeSwitchableFormProps";
import { useFormWithUtils } from "@/overlay/components/SmartForm/SmartFormUtils/FormHooks/useFormUtils";
import type { BaseFormProps } from "@/overlay/components/SmartForm/SmartFormUtils/commonTypeUtils";
import { t } from "@lingui/macro";
import type { BackgroundMapLayer, BackgroundMapLayerCreate } from "@mm/metx-workbench.meteomatics.com";
import { useLanguageOptions, useMaptilerStyleOptions } from "./hooks";

type BackgroundMapLayerPatch = Partial<BackgroundMapLayerCreate | BackgroundMapLayer>;

/**
 * Form data passed from the child components.
 */
export type CartographicMaterialFormData = {
  // Snake case as they get passed as layer props directly.
  layerData: {
    style: string;
    custom_options: {
      map_label_language: string;
    };
  };
  // Form internal data to construct the above data.
  internal: {
    paramOptionPatch: ParamOptionInputData;
  };
};

type CartographicMaterialLayerFormProps = {
  defaultFormData: BackgroundMapLayerPatch;
} & BaseFormProps<CartographicMaterialFormData>;

/**
 * Responsible for rendering the whole layer form and send back the data on submit hadler.
 */
export function CartographicMaterialCreateForm({
  submitMode,
  onSubmit,
  onCancel,
  defaultFormData,
}: CartographicMaterialLayerFormProps) {
  const { formMethods, formValName } = useFormWithUtils<CartographicMaterialFormData>({ mode: submitMode });
  const { layerData } = formMethods.watch();

  const onSubmitWithFormatting: typeof onSubmit = (formData) => {
    onSubmit(formData);
  };

  const languageOptions = useLanguageOptions();
  const styleOptions = useMaptilerStyleOptions();

  return (
    <ModeSwitchableForm
      onSubmit={formMethods.handleSubmit(onSubmitWithFormatting)}
      onCancel={onCancel}
      submitMode={submitMode}
    >
      <SelectInput
        label={t`Basemap`}
        values={styleOptions}
        instruction={{
          currValue: defaultFormData.style,
          formValueName: formValName("layerData.style"),
          formMethods,
        }}
      />

      {/* For some reason values get's populated only when select is open first time */}
      {!onlyBorderStyleLayer.includes(layerData?.style) && (
        <SelectInput
          label={t`Map Label Language`}
          values={languageOptions}
          instruction={{
            currValue: Language.EN,
            formValueName: formValName("layerData.custom_options.map_label_language"),
            formMethods,
          }}
        />
      )}
    </ModeSwitchableForm>
  );
}

import { useEffect, useRef } from "react";

/**
 * Callback invoked by `useAnimationFrame`.
 *
 * @param deltaTime delta between the last invocation and this invocation. Measured in milliseconds.
 */
export type AnimationFrameCallback = (deltaTime: number) => void;

export const useAnimationFrame = (callback: AnimationFrameCallback) => {
  // Use useRef for mutable variables that we want to persist
  // without triggering a re-render on their change
  const requestRef = useRef<number | undefined>();
  const previousTimeRef = useRef<number | undefined>();

  useEffect(() => {
    const animate = (time: number) => {
      if (previousTimeRef.current !== undefined) {
        const deltaTime = time - previousTimeRef.current;
        callback(deltaTime);
      }
      previousTimeRef.current = time;
      requestRef.current = requestAnimationFrame(animate);
    };

    requestRef.current = requestAnimationFrame(animate);
    return () => {
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
      }
    };
  }, [callback]);
};

import type {
  EnergyForecastApi,
  EnergyForecastTimeSeriesV2,
  GetEnergyForecastEnsRequest,
} from "@mm/forecast-data.meteomatics.com";

import type { PlotRequest } from "../models";
import type { BasePlotSeriesDefinition } from "./BasePlotDefinition";
import { scaleEnergyForecastVal } from "./EnergyPlotDefinition";

export type EnergyForecastEnsSeries = {
  [key: string]: Array<Array<any>>;
};

export class EnergyPlotEnsDefinition
  implements BasePlotSeriesDefinition<EnergyForecastApi, PlotRequest<GetEnergyForecastEnsRequest>>
{
  transform(data: { [key: string]: EnergyForecastTimeSeriesV2 }): EnergyForecastEnsSeries {
    const ensPower: EnergyForecastEnsSeries = {};

    for (const ensMember in data) {
      // biome-ignore lint/suspicious/noPrototypeBuiltins: old ts
      if (data.hasOwnProperty(ensMember)) {
        const parsedPower = data[ensMember].forecast.power.map(([timestamp, value]) => {
          const tsPowerScaled: number[] = [timestamp * 1000, scaleEnergyForecastVal(value)];
          return tsPowerScaled;
        });
        ensPower[ensMember] = parsedPower;
      }
    }

    return ensPower;
  }

  request(
    api: EnergyForecastApi,
    definition: PlotRequest<GetEnergyForecastEnsRequest>,
  ): Promise<{ [key: string]: EnergyForecastTimeSeriesV2 }> {
    return api.getEnergyForecastEns(definition);
  }
}

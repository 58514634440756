import type { LayerUnion } from "@/layers";
import type { DrawingLayer } from "@/layers/geojson/drawing/TEMPORAL_TYPE_DRAWING";
import { useActor } from "@xstate/react";
import { useContext } from "react";
import { TempLayerStateMachineContext } from "./tempLayerStateMachineContext";

export function useTempLayerService() {
  const { tempLayerService } = useContext(TempLayerStateMachineContext);
  return {
    tempLayerService,
    send: tempLayerService.send,
  };
}

export function useCurrTempLayerState() {
  const { tempLayerService } = useTempLayerService();
  const [state] = useActor(tempLayerService);
  return state;
}
const useCurrTempLayerAlias = useCurrTempLayerState;

/**
 * Returns an array of temporal layer description objects for the given map.
 *
 * These temporal layers used for the purpose of preview, visual effect, etc.
 */
export function useTempLayerDescriptions(mapId: number, useCurrTempLayerState = useCurrTempLayerAlias): LayerUnion[] {
  const state = useCurrTempLayerState();
  const tempLayersByMapId = state.context.tempLayersByMapId;
  if (!(mapId in tempLayersByMapId)) {
    return [];
  }
  const layers = Object.entries(tempLayersByMapId[mapId]).map(([_, layer]) => layer);
  return layers;
}

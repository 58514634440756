import { type DateTime, Duration } from "luxon";
import type { CoordinateSystem, GeoJSONFeatureCollection, LightningListRequest } from "../models";
import type { RequestSplitter } from "./RequestSplitter";

export function splitTimeRange(
  endTime: DateTime,
  timeDuration: Duration,
  timeResolutionSplit: Duration,
): Pick<LightningListRequest<CoordinateSystem.WGS84>, "timeDuration" | "datetime">[] {
  const results: Pick<LightningListRequest<CoordinateSystem.WGS84>, "timeDuration" | "datetime">[] = [];
  const numberOfSplits = Math.ceil(timeDuration.as("minutes") / timeResolutionSplit.as("minutes"));
  for (let i = 0; i < numberOfSplits; i++) {
    const diffMinutes = Duration.fromObject({ minutes: timeResolutionSplit.minutes * i });
    results.push({
      datetime: endTime.minus(diffMinutes),
      timeDuration: timeResolutionSplit,
    });
  }

  return results;
}

export class LightningListTimeSplitter
  implements
    RequestSplitter<LightningListRequest<CoordinateSystem.WGS84>, GeoJSONFeatureCollection, GeoJSONFeatureCollection>
{
  mergeResponses(responses: GeoJSONFeatureCollection[]): GeoJSONFeatureCollection {
    return {
      ...responses[0],
      // biome-ignore lint/complexity/useFlatMap: <explanation>
      features: responses.map<GeoJSONFeatureCollection["features"]>((res) => res.features).flat(),
    };
  }

  splitRequest(request: LightningListRequest<CoordinateSystem.WGS84>): LightningListRequest<CoordinateSystem.WGS84>[] {
    const { datetime: endTime, timeDuration } = request;
    const timeResolutionSplit = Duration.fromObject({ minutes: 5 });

    // we move here on step minus, because the get_lightning_list time_range=${datetime}${duration} is not handled left align
    // get_lightning_list is using start Datetime and duration.
    // i.e. datetime = **T12:00ZPT5M returns all lightnings between T12:00Z and 12:05Z
    const startTime = endTime.minus(timeResolutionSplit);
    const subTimes = splitTimeRange(startTime, timeDuration, timeResolutionSplit);
    return subTimes.map((subTime) => ({ ...request, ...subTime }));
  }
}

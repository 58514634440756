import type { Tile } from "@/cache/SpatioTemporalTileCache/Tile";
import { Texture } from "@/cache/Texture";
import type { GridDimension, GridPayload } from "@mm/api.meteomatics.com";
import { InterpolationMode } from "weather-parameter-utils";

export interface WMSTexture extends GridPayload<ImageBitmap>, GridDimension {
  interpolationMode: InterpolationMode;
}

export class PNGTile extends Texture implements Tile {
  constructor(readonly data: WMSTexture) {
    super();
  }

  memoryConsumptionCpu(): number {
    return this.data.estimatedByteSize;
  }

  memoryConsumptionGpu(): number {
    let mipmappingOverhead = 1.0;

    const interpolationMode: InterpolationMode = this.data.interpolationMode;
    switch (interpolationMode) {
      case InterpolationMode.IntervalOrRatio_NonLinear:
      case InterpolationMode.NominalOrOrdinal_NearestNeighbour:
        break;
      case InterpolationMode.IntervalOrRatio_Linear:
        mipmappingOverhead = 1.34;
        break;
      default: {
        const _exhaustive: never = interpolationMode;
        return _exhaustive;
      }
    }

    const numberOfTimesUploaded = this._textures.size;

    return this.data.estimatedByteSize * mipmappingOverhead * numberOfTimesUploaded;
  }

  protected upload(gl: WebGLRenderingContext, texture: WebGLTexture): void {
    const level = 0;
    const internalFormat = gl.RGBA;
    const srcFormat = gl.RGBA;
    const srcType = gl.UNSIGNED_BYTE;
    gl.texImage2D(gl.TEXTURE_2D, level, internalFormat, srcFormat, srcType, this.data.payload);

    // our mapping is not 100% precise. A default clamping strategy of gl.REPEAT can result in discolorations at tile edges.
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);

    // Param Setting 	Linear within mip-level 	Has mipmapping 	Linear between mip-levels
    // GL_NEAREST 	No 	No
    // GL_LINEAR 	Yes 	No
    // GL_NEAREST_MIPMAP_NEAREST 	No 	Yes 	No
    // GL_LINEAR_MIPMAP_NEAREST 	Yes 	Yes 	No
    // GL_NEAREST_MIPMAP_LINEAR 	No 	Yes 	Yes
    // GL_LINEAR_MIPMAP_LINEAR 	Yes 	Yes 	Yes
    const interpolationMode: InterpolationMode = this.data.interpolationMode;
    switch (interpolationMode) {
      case InterpolationMode.IntervalOrRatio_NonLinear:
      case InterpolationMode.NominalOrOrdinal_NearestNeighbour:
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
        break;
      case InterpolationMode.IntervalOrRatio_Linear:
        // Fallback to nearest interpolation.
        // https://webgl2fundamentals.org/webgl/lessons/webgl-cross-platform-issues.html
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
        break;
      default: {
        const _exhaustive: never = interpolationMode;
        // biome-ignore lint/correctness/noVoidTypeReturn: TODO why?
        return _exhaustive;
      }
    }
  }
}

export class EmptyPNGTile extends Texture implements Tile {
  memoryConsumptionCpu(): number {
    return 0;
  }

  memoryConsumptionGpu(): number {
    return 0;
  }

  unload(): void {}

  protected upload(gl: WebGLRenderingContext, texture: WebGLTexture): void {}
}

export class FailurePNGTile extends Texture implements Tile {
  memoryConsumptionCpu(): number {
    return 0;
  }

  memoryConsumptionGpu(): number {
    return 0;
  }

  unload(): void {}

  protected upload(gl: WebGLRenderingContext, texture: WebGLTexture): void {}
}

import {
  type AsyncResult,
  createNotRequestedAsyncResult,
  createPendingAsyncResult,
  createResolvedAsyncResult,
} from "@/cache/AsyncResult";
import {
  type CacheDatum,
  CacheDatumState,
  type LoadedCacheDatum,
  type PermanentlyFailedCacheDatum,
} from "@/cache/GlobalCache";
import { apiThreadPool } from "@/cache/SpatioTemporalTileCache/ApiQueryThreadPool";
import { FailureState, type RetryState, createRetryState } from "@/cache/SpatioTemporalTileCache/RetryState";
import { type Stats, jsxTable } from "@/cache/Stats";
import { emptyFeatureCollection } from "@/geojson";
import { generatePOILayerCacheId } from "@/layers/geojson/LayerUtils";
import type { IconData } from "@/reducer/client-models";
import { dataProcessingThreadPool } from "@/threads/DataProcessingThread/DataProcessingThreadPool";
import {
  type CoordinateSystem,
  type GeoJSONFeatureCollection,
  type JSONResponseDataPoints,
  type PointRequest,
  WORLD_BBOX,
} from "@mm/api.meteomatics.com";
import type { MeteomaticsApiError } from "@mm/api.meteomatics.com/lib/error";
import { type Abort, isAbortable } from "@mm/api.meteomatics.com/lib/middleware";
import { isEmpty } from "lodash";

export interface GeoJSONCacheStats {
  count: number;
  pending: number;
}

export enum PoiParameterRequestKind {
  SingleParameterColoring = "single parameter coloring",
}

export class PoiCache implements Stats<GeoJSONCacheStats> {
  // Cache for storing raw data from API response used for constructing POI GeoJson
  protected dataCache: { [key: string]: CacheDatum<JSONResponseDataPoints[], RetryState> } = {};
  protected cache: { [key: string]: CacheDatum<GeoJSONFeatureCollection, RetryState> } = {};

  constructor(private readonly label_: string) {}

  stats(): GeoJSONCacheStats {
    let count = 0;
    let pending = 0;
    const cache = this.cache;

    for (const line in cache) {
      if (Object.hasOwn(cache, line)) {
        count++;

        if (cache[line].state === CacheDatumState.Pending) {
          pending++;
        }
      }
    }

    return { count, pending };
  }

  descJsx(): JSX.Element {
    return <>Special-purpose cache for API JSON Requests. Replacement Policy is LRU.</>;
  }

  label(): string {
    return this.label_;
  }

  statsJsx(): JSX.Element {
    return jsxTable(this.stats(), (key, val) => val);
  }

  /**
   * A method to return a cached data from cache.
   * It does not trigger a new request when the given request hasn't been emitted.
   * @param request
   * @param kind
   * @returns
   */
  peekCache(cacheId: string): AsyncResult<GeoJSONFeatureCollection> {
    const v = this.cache[cacheId];
    if (!v) {
      return createNotRequestedAsyncResult(emptyFeatureCollection);
    }
    switch (v.state) {
      case CacheDatumState.Pending: {
        const retry_ = v.payload;
        if (retry_.failureState !== FailureState.FailedTemporarily) {
          return createPendingAsyncResult(v.promise);
        }
        // since the current invocation moves the state from [Pending/FailedTemporarily] to [Pending/Queued]
        // mark it as such to prevent concurrent invocations from reentering the remaining function body
        retry_.failureState = undefined;
        return createPendingAsyncResult(v.promise);
      }
      case CacheDatumState.PermanentlyFailed:
        return createResolvedAsyncResult(emptyFeatureCollection);
      case CacheDatumState.Loaded:
        return createResolvedAsyncResult(v.payload);
      default: {
        const _exhaustive: never = v;
        return _exhaustive;
      }
    }
  }

  /**
   * A method to return a cached data from cache containing raw parameter values.
   * It does not trigger a new request when the given request hasn't been emitted.
   * @param request
   * @param kind
   * @returns
   */
  peekDataCache(cacheId: string): AsyncResult<JSONResponseDataPoints[]> {
    const v = this.dataCache[cacheId];
    if (!v) {
      return createNotRequestedAsyncResult([]);
    }
    switch (v.state) {
      case CacheDatumState.Pending: {
        const retry_ = v.payload;
        if (retry_.failureState !== FailureState.FailedTemporarily) {
          return createPendingAsyncResult(v.promise);
        }
        // since the current invocation moves the state from [Pending/FailedTemporarily] to [Pending/Queued]
        // mark it as such to prevent concurrent invocations from reentering the remaining function body
        retry_.failureState = undefined;
        return createPendingAsyncResult(v.promise);
      }
      case CacheDatumState.PermanentlyFailed:
        return createResolvedAsyncResult([]);
      case CacheDatumState.Loaded:
        return createResolvedAsyncResult(v.payload);
      default: {
        const _exhaustive: never = v;
        return _exhaustive;
      }
    }
  }

  retrieveParameter(
    request: PointRequest<CoordinateSystem.WGS84>,
    item: IconData,
    kind: PoiParameterRequestKind = PoiParameterRequestKind.SingleParameterColoring,
  ): AsyncResult<JSONResponseDataPoints[]> {
    const cacheId = generatePOILayerCacheId(request, kind, item);
    if (Object.hasOwn(this.dataCache, cacheId)) {
      return this.peekDataCache(cacheId);
    }

    const retry_: RetryState | null = null;
    const modelBoundingBox = request.boundingBoxLimit || WORLD_BBOX;
    const retry = retry_ ?? createRetryState(modelBoundingBox);
    const promise = apiThreadPool
      .GetPointJSON(request, retry)
      .then((result) => {
        if (isEmpty(result.data)) return [];
        const { coordinates } = result.data[0];
        const { requestDatetime } = this.cache[cacheId];
        const cacheData: LoadedCacheDatum<JSONResponseDataPoints[]> = {
          requestDatetime,
          lastUsed: Number.NEGATIVE_INFINITY,
          state: CacheDatumState.Loaded,
          payload: coordinates,
        };
        this.dataCache[cacheId] = cacheData;
        return coordinates;
      })
      .catch((e: MeteomaticsApiError | Abort) => {
        if (isAbortable(e)) {
          delete this.cache[cacheId];
          return Promise.reject(e);
        }
        return this.reactOnMeteomaticsApiError(e, retry, cacheId);
      });

    this.dataCache[cacheId] = {
      requestDatetime: performance.now(),
      state: CacheDatumState.Pending,
      promise,
      payload: retry,
    };
    return createPendingAsyncResult(promise);
  }

  retrievePoiByParameter(
    request: PointRequest<CoordinateSystem.WGS84>,
    item: IconData,
    kind: PoiParameterRequestKind = PoiParameterRequestKind.SingleParameterColoring,
    refresh?: boolean,
  ): AsyncResult<GeoJSONFeatureCollection> {
    const cacheId = generatePOILayerCacheId(request, kind, item);
    if (!refresh && Object.hasOwn(this.cache, cacheId)) {
      return this.peekCache(cacheId);
    }

    // if there is no parameter or no items we don't need to request anything.
    if (request.parameters[0] === "" || item.coords.length === 0) {
      this.cache[cacheId] = {
        requestDatetime: performance.now(),
        lastUsed: Number.NEGATIVE_INFINITY,
        state: CacheDatumState.Loaded,
        payload: emptyFeatureCollection,
      };
      return createResolvedAsyncResult(emptyFeatureCollection);
    }

    const retry_: RetryState | null = null;
    const modelBoundingBox = request.boundingBoxLimit || WORLD_BBOX;
    const retry = retry_ ?? createRetryState(modelBoundingBox);

    const { asynchronous } = this.retrieveParameter(request, item, kind);

    const promise = asynchronous
      .then((res) => {
        // Create geojson into data processing thread
        return dataProcessingThreadPool.createPOIGeoJson(item, res);
      })
      .then((result) => {
        const { requestDatetime } = this.cache[cacheId];
        const cacheData: LoadedCacheDatum<GeoJSONFeatureCollection> = {
          requestDatetime,
          lastUsed: Number.NEGATIVE_INFINITY,
          state: CacheDatumState.Loaded,
          payload: result,
        };
        this.cache[cacheId] = cacheData;
        return result;
      })
      .catch((e: MeteomaticsApiError | Abort) => {
        if (isAbortable(e)) {
          delete this.cache[cacheId];
          return Promise.reject(e);
        }
        return this.reactOnMeteomaticsApiError(e, retry, cacheId);
      });

    this.cache[cacheId] = {
      requestDatetime: performance.now(),
      state: CacheDatumState.Pending,
      promise,
      payload: retry,
    };
    return createPendingAsyncResult(promise);
  }

  reactOnMeteomaticsApiError(e: MeteomaticsApiError, retry: RetryState, cacheId: string): Promise<never> {
    // Todo: Make different reactions on Errors, currently always return a CacheDatumState.PermanentlyFailed
    // ToDo: Handle Out of bounds.
    switch (e.kind) {
      case "BoundsError":
      case "TimeError":
      case "ComputationTimeoutError":
      case "AuthError":
      case "UnknownError":
      case "UnknownParameterError":
      case "MissingDataError": {
        const { requestDatetime } = this.cache[cacheId];
        const cacheData: PermanentlyFailedCacheDatum<null> = {
          requestDatetime,
          state: CacheDatumState.PermanentlyFailed,
          payload: null,
        };
        this.cache[cacheId] = cacheData;
        // retry.failureState = FailureState.FailedPermanently;
        return Promise.reject(e);
      }
      default: {
        const _exhaustive: never = e;
        return _exhaustive;
      }
    }
    // Still here for retry functionality
    // Retry would be something for boundsError -> cropping bbox and retry ...
    // retry.failedAttempts++; // accumulated failures for the current tile only
    // retry.failureState = FailureState.FailedTemporarily;
    // if (retry.failedAttempts > NETWORK_EXPONENTIAL_BACKOFF_MAX_RETRIES) {
    //   retry.failureState = FailureState.FailedPermanently;
    //   return Promise.reject(e);
    // }
    //
    // return Promise.reject(e);
  }
}

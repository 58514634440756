import { wrongActionPlacementError } from "@/models/state-machine-utils/stateMachineUtils";
import { assign } from "xstate";
import { LoadingStateEventType, type LoadingStateEventUnion } from "./loadingStateEvents";
import type { DefaultLoadingCtx } from "./loadingStateMachineCtxTypes";

export const updateLoadingState = assign((ctx: DefaultLoadingCtx, event: LoadingStateEventUnion): DefaultLoadingCtx => {
  if (event.type === LoadingStateEventType.updateLoadingState) {
    return {
      stateById: event.stateById,
    };
  }
  throw wrongActionPlacementError(updateLoadingState.name);
});

import {
  type ElementProps,
  type FloatingContext,
  autoUpdate,
  flip,
  shift,
  size,
  useFloating,
  useInteractions,
} from "@floating-ui/react";
import { t } from "@lingui/macro";
import { difference, pullAll, union } from "lodash";
import { createContext, useContext, useMemo, useState } from "react";

import {
  type EnsSelectArray,
  EnsSelectStat,
  type EnsSelectValue,
  Quantile,
  Spread,
  quantileBandList,
} from "@/utility/ensemble";
import { classNames } from "@/utility/jsx";

import { Controls } from "./Controls";
import { EnsChip } from "./EnsChip";
import { EnsSelectForm } from "./EnsSelectForm";
import { EnsSelectGroupedForm } from "./EnsSelectForm/EnsSelectGroupedForm";

import styles from "./style.module.scss";

const useCustomLogic = (
  { onOpenChange, refs }: FloatingContext,
  options?: {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  },
): ElementProps => {
  return useMemo(
    () => ({
      reference: {
        onChange(e: React.ChangeEvent<HTMLInputElement>) {
          options?.onChange(e);
          if (e.target.value && e.target.value.length > 0) {
            onOpenChange(true);
          }
        },
        onFocus: () => {
          onOpenChange(true);
        },
        onBlur: (e) => {
          if (refs.floating.current?.contains(e.relatedTarget)) {
            return;
          }
          onOpenChange(false);
        },
        onKeyDown: (e) => {
          if (e.key === "Escape") {
            onOpenChange(false);
          }
        },
      },
      floating: {
        onKeyDown: (e) => {
          if (e.key === "Escape") {
            onOpenChange(false);
          }
        },
        onBlur: (e) => {
          if (
            refs.floating.current?.contains(e.relatedTarget) ||
            refs.domReference.current?.contains(e.relatedTarget)
          ) {
            return;
          }
          onOpenChange(false);
        },
      },
    }),
    [onOpenChange, options, refs],
  );
};

const QUANTILES = Object.values(Quantile);

interface EnsSelectionPanelContextType {
  availableValues: {
    bands?: EnsSelectArray;
    spread: EnsSelectArray;
    stat: EnsSelectArray;

    quantiles: EnsSelectArray;
    members: EnsSelectArray;
  };

  selectedEns: EnsSelectArray;

  allMembersSelected: boolean;
  allQuantilesSelected: boolean;

  onChange?: (value: EnsSelectArray) => void;
}

const EnsSelectionPanelContext = createContext<EnsSelectionPanelContextType>({
  availableValues: {
    spread: [],
    stat: [],
    quantiles: [],
    members: [],
  },
  selectedEns: [],

  allMembersSelected: false,
  allQuantilesSelected: false,
});

const MultipleEnsSelectionForm = ({ grouped, customForm }: { grouped?: boolean; customForm?: boolean }) => {
  const {
    availableValues: { members, quantiles },
    selectedEns,
    onChange,
  } = useEnsSelectionContext();

  const [searchQuery, setSearchQuery] = useState("");
  const [open, setOpen] = useState(false);

  const { x, y, strategy, refs, context } = useFloating({
    open,
    onOpenChange: setOpen,
    middleware: [
      flip(),
      shift(),
      size({
        apply({ rects }) {
          if (refs.floating.current) {
            Object.assign(refs.floating.current.style, {
              width: `${rects.reference.width}px`,
            });
          }
        },
      }),
    ],
    whileElementsMounted: autoUpdate,
  });

  const custom = useCustomLogic(context, {
    onChange: ({ target: { value } }) => {
      setSearchQuery(value);
    },
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([custom]);

  const handleRemoveEnsSelection = (value: EnsSelectValue) => {
    const newMembers = selectedEns.filter((member) => member !== value);
    onChange?.(newMembers);
  };

  const handleSelecAllQuentilens = (remove: boolean) => {
    onChange?.(remove ? pullAll(selectedEns, quantiles) : union(selectedEns, quantiles));
  };

  const handleSelectAllMembers = (remove: boolean) => {
    onChange?.(remove ? pullAll(selectedEns, members) : union(selectedEns, members));
  };

  return (
    <div className={styles["ens-selection-panel"]}>
      <input type="text" placeholder={t`select`} {...getReferenceProps()} ref={refs.setReference} value={searchQuery} />
      {!grouped && !customForm && (
        <Controls
          onSelectOrRemoveAllMembers={handleSelectAllMembers}
          onSelectOrRemoveAllQuentilens={handleSelecAllQuentilens}
        />
      )}
      <div className={styles["chips-container"]}>
        {selectedEns.map((selectedEns: EnsSelectValue) => (
          <EnsChip key={selectedEns} value={selectedEns} onRemove={handleRemoveEnsSelection} />
        ))}
      </div>
      {open && (
        <div
          ref={refs.setFloating}
          className={classNames("float", styles["ens-dialog"])}
          style={{
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
          }}
          {...getFloatingProps()}
        >
          {grouped ? (
            <EnsSelectGroupedForm onChange={onChange} searchQuery={searchQuery} />
          ) : (
            <EnsSelectForm onChange={onChange} searchQuery={searchQuery} />
          )}
        </div>
      )}
    </div>
  );
};

type MultipleEnsSelectionProps = {
  memberList: EnsSelectArray;
  quantiles?: EnsSelectArray;
  selected: EnsSelectArray;
  onChange: (v: EnsSelectArray) => void;
  custom?: boolean;
  grouped?: boolean;
};

const MultipleEnsSelection = ({
  memberList,
  selected,
  onChange,
  grouped,
  quantiles = QUANTILES,
  custom,
}: MultipleEnsSelectionProps) => {
  const allMembersSelected = useMemo(() => difference(memberList, selected).length === 0, [memberList, selected]);
  const allQuantilesSelected = useMemo(() => difference(quantiles, selected).length === 0, [selected]);

  return (
    <EnsSelectionPanelContext.Provider
      value={{
        availableValues: {
          bands: quantileBandList,
          spread: custom ? [] : [...Object.values(Spread)],
          stat: custom ? [] : [...Object.values(EnsSelectStat)],
          quantiles: quantiles,
          members: memberList,
        },
        selectedEns: selected,

        onChange,

        allMembersSelected,
        allQuantilesSelected,
      }}
    >
      <MultipleEnsSelectionForm grouped={grouped} customForm={custom} />
    </EnsSelectionPanelContext.Provider>
  );
};

const useEnsSelectionContext = () => useContext(EnsSelectionPanelContext);

export { MultipleEnsSelection, useEnsSelectionContext };

import type * as Mapbox from "mapbox-gl";

import type { AsyncResult } from "@/cache/AsyncResult";
import { IsoLinesRequestKind } from "@/cache/GeoJSONCache";
import { networkCaches } from "@/cache/GlobalCache";
import type { CheckedProps, LayerBase, PropsChecker } from "@/layers";
import type { ScenePublicApi } from "@/layers/Compositor";
import { generateIsoLinesLayerCacheId } from "@/layers/geojson/LayerUtils";
import { MapboxGeoJSONLayer } from "@/layers/geojson/MapboxGeoJSONLayer";
import { SENTRY_TRANSACTION_NAMES } from "@/sentry/TransactionConst";
import { captureTransaction } from "@/sentry/helpers";
import { safeFmt } from "@/utility/safeFmt";
import { getModelBoundingBox } from "@/weather-parameters/lookup";
import type { GeoJSONFeatureCollection, IsoLinesRequest } from "@mm/api.meteomatics.com";
import type { GuiTimeZone, IsoLinesLayer } from "@mm/metx-workbench.meteomatics.com";
import type { DateTime } from "luxon";

export class IsoLinesLayerImpl extends MapboxGeoJSONLayer<IsoLinesLayer> {
  readonly mapboxSymbolLayerId: string;

  constructor(id: number, props: IsoLinesLayer, scene: ScenePublicApi, timezone: GuiTimeZone) {
    // Swap line and symbol layers in places as current layer logic doesn't have good logic for
    // internal order changing to move before primary layer (broke due other layers, that are drawn async)
    super(id, props, scene, timezone, "line");
    // A symbol layer is needed to display the values of the GeoJSON.
    this.mapboxSymbolLayerId = `${this.humanReadableId()}-symbol`;
    this.scene.getMapboxMap().addLayer({
      id: this.mapboxSymbolLayerId,
      type: "symbol",
      source: this.mapbox_source,

      layout: {
        visibility: this.props.show ? "visible" : "none",
        "symbol-placement": "line",

        "text-field": ["get", "title"],
        "text-size": this.props.text_size,
        "icon-ignore-placement": true,
        "text-ignore-placement": true,
      },
      paint: {
        "text-color": this.props.text_color,
        "text-opacity": this.props.opacity,

        "text-halo-color": "hsl(0,0%,100%)",
        "text-halo-width": 2,
      },
    });

    // update the symbol layer
    this.updateMapboxLayoutProperties({
      visibility: this.props.show ? "visible" : "none",
    });

    this.updateMapboxPaintProperties({
      "line-opacity": this.props.opacity,
      "line-width": this.props.line_width,
      "line-color": this.props.line_color,
    });
  }

  removeLayer() {
    this.scene.getMapboxMap().removeLayer(this.mapboxSymbolLayerId);
    super.removeLayer();
  }
  moveZIndex(beforeLayerId: string) {
    const map = this.scene.getMapboxMap();
    super.moveZIndex(beforeLayerId);
    map.moveLayer(this.mapboxSymbolLayerId, beforeLayerId);
  }

  checker(): PropsChecker<IsoLinesLayer, LayerBase<IsoLinesLayer>> {
    type CheckerProps = CheckedProps<IsoLinesLayer>;
    const checkLayerProp = (property: CheckerProps) => (prev: any, curr: any) => {
      const changed = prev[property] !== curr[property];
      if (changed) {
        this.setLayerProps({ [property]: curr[property] });
        switch (property) {
          case "model":
            this.beforeRender();
            break;
          case "parameter_unit":
            this.beforeRender();
            break;
          case "ens_select":
            this.beforeRender();
            break;
          case "calibrated":
            this.beforeRender();
            break;
          case "filter_median":
          case "filter_gauss":
          case "values":
          case "value_range":
            this.beforeRender();
            break;
          case "show":
            this.updateMapboxLayoutProperties({ visibility: curr.show ? "visible" : "none" });
            this.updateMapboxSymbolLayoutProperties({ visibility: curr.show ? "visible" : "none" });
            break;
          case "opacity":
            this.updateMapboxSymbolPaintProperties({
              "text-opacity": curr[property],
            });
            this.updateMapboxPaintProperties({
              "line-opacity": curr[property],
            });
            break;
          case "text_size":
            this.updateMapboxSymbolLayoutProperties({
              "text-size": curr[property],
            });
            break;
          case "text_color":
            this.updateMapboxSymbolPaintProperties({
              "text-color": curr[property],
            });
            break;
          case "line_width":
            this.updateMapboxPaintProperties({
              "line-width": curr[property],
            });
            break;
          case "line_color":
            this.updateMapboxPaintProperties({
              "line-color": curr[property],
            });
            break;
          case "custom_options":
            break;
          default: {
            const _exhaustive: never = property;
            return _exhaustive;
          }
        }
      }
      return changed;
    };
    return {
      model: checkLayerProp("model"),
      opacity: checkLayerProp("opacity"),
      parameter_unit: checkLayerProp("parameter_unit"),
      calibrated: checkLayerProp("calibrated"),
      show: checkLayerProp("show"),
      text_size: checkLayerProp("text_size"),
      text_color: checkLayerProp("text_color"),
      line_width: checkLayerProp("line_width"),
      line_color: checkLayerProp("line_color"),
      filter_median: checkLayerProp("filter_median"),
      filter_gauss: checkLayerProp("filter_gauss"),
      values: checkLayerProp("values"),
      value_range: checkLayerProp("value_range"),
      ens_select: checkLayerProp("ens_select"),
      custom_options: checkLayerProp("custom_options"),
    };
  }

  protected updateMapboxSymbolLayoutProperties(props: Mapbox.SymbolLayout) {
    for (const entry of Object.entries(props)) {
      this.scene.getMapboxMap().setLayoutProperty(this.mapboxSymbolLayerId, entry[0], entry[1]);
    }
  }
  protected updateMapboxSymbolPaintProperties(props: Mapbox.SymbolPaint) {
    for (const entry of Object.entries(props)) {
      this.scene.getMapboxMap().setPaintProperty(this.mapboxSymbolLayerId, entry[0], entry[1]);
    }
  }

  humanReadableId(): string {
    return safeFmt`metx.isolinelayer#${this.uid}#${this.props.model}.${this.props.parameter_unit}`;
  }

  createRequest(dateTimeWithOffset: DateTime): IsoLinesRequest {
    const map = this.scene.getMapboxMap();

    /**
     * TODO: rm distance 0 and remove width and height on url request
     * (has so far no effect on url but may on the request splitter)
     */
    const { area } = this.computeBoundingBox(0);
    const { width, height } = map.getCanvas();

    return {
      path: "get_isolines",
      area: area,
      parameter: this.props.parameter_unit,
      model: this.props.model,
      ensSelect: this.props.ens_select ? this.props.ens_select : "",
      boundingBoxLimit: getModelBoundingBox(this.props.model),
      datetime: dateTimeWithOffset,
      height: height,
      width: width,
      radiusGaussianFilter: this.props.filter_gauss,
      radiusMedianFilter: this.props.filter_median,
      isolineRange: this.props.value_range,
      isolineValues: this.props.values,
      calibrated: this.props.calibrated,
    };
  }

  beforeRender(): void {
    if (this.scene.isAnimation()) {
      this.updateTrigger();
    } else {
      this.debouncedUpdateTrigger();
    }
  }

  updateTrigger(): void {
    const dateTimeWithOffset = this.scene.getDisplayTimeWithOffset();
    const request = this.createRequest(dateTimeWithOffset);

    const result = networkCaches.geojson_cache.retrieveIsoLine(request);
    this.updateSource(result, dateTimeWithOffset);
  }

  fetchData(timeFrame: DateTime): Promise<void> {
    const dateTimeWithOffset = this.scene.getDateTimeWithOffset(timeFrame);
    const request = this.createRequest(dateTimeWithOffset);

    const tags = {
      layerKind: `${this.props.kind ? this.props.kind : "undefined"}`,
      parameter: `${this.props.parameter_unit}`,
      requestedDate: `${dateTimeWithOffset}`,
    };

    const { asynchronous } = networkCaches.geojson_cache.retrieveIsoLine(request);

    if (!this.scene.isAnimation()) {
      return captureTransaction(asynchronous, SENTRY_TRANSACTION_NAMES.ISOLINES_LAYER_LOADING_SPEED, tags);
    }

    return asynchronous
      .then(() => {
        return Promise.resolve();
      })
      .catch((err) => {
        return Promise.resolve();
      });
  }

  getActiveWeatherParametersAsString() {
    return [
      {
        model: this.props.model,
        parameter: this.props.parameter_unit,
      },
    ];
  }

  peekLayerCache(request: IsoLinesRequest): AsyncResult<GeoJSONFeatureCollection> {
    const cacheId = generateIsoLinesLayerCacheId(request, IsoLinesRequestKind.IsoLines);
    return networkCaches.geojson_cache.peekCache(cacheId);
  }
}

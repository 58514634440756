import { FormField } from "@/overlay/components/form-field";
import {
  type EnsOption,
  Quantile,
  createMembers,
  quantilenValues,
  transformEnsSelectArrayToString,
  transformEnsSelectStringToArray,
} from "@/utility/ensemble";
import { t } from "@lingui/macro";
import type { ModelSchema } from "@mm/api-layers.meteomatics.com";
import { useEffect, useState } from "react";
import { MultipleEnsSelection } from "../../CommonParts/MultipleEnsSelection";

interface EnsambleProps {
  ensSelect: EnsOption | undefined;
  selectedModelSchema: ModelSchema;
  onChange: (propsValue: { ens_select: EnsOption }) => void;
  allowMultipleEnsSelect?: boolean;
  custom?: boolean;
}

export function EnsembleMemberSelect({
  ensSelect,
  selectedModelSchema,
  onChange,
  allowMultipleEnsSelect,
  custom,
}: EnsambleProps) {
  const [memberList, setMemberList] = useState<string[]>(createMembers(selectedModelSchema.num_ensemble_members ?? 0));

  useEffect(() => {
    setMemberList(createMembers(selectedModelSchema.num_ensemble_members ?? 0));
    // TODO: update ensSelect value if member not in the selcetedModelSchema i.e. switch from ecmwf-ifs-ens (51 member) to ncep-gfs-ens (only 21 member)
  }, [selectedModelSchema]);

  return (
    <FormField label={t`Property`} noSemanticLinking>
      {allowMultipleEnsSelect ? (
        // Shown on Map Layers with Iso-lines Ens only
        <MultipleEnsSelection
          memberList={memberList}
          selected={ensSelect ? transformEnsSelectStringToArray(ensSelect) : ["member:0"]}
          onChange={(selectedOptions: any) => {
            onChange({
              ens_select: transformEnsSelectArrayToString(selectedOptions),
            });
          }}
        />
      ) : (
        <select
          value={ensSelect || "member:0"}
          onChange={(event) => onChange({ ens_select: event.target.value as EnsOption })}
        >
          {custom && (
            <>
              <option value={"spread"}>{t`Spread`}</option>
              <option value={"median"}>{t`Median`}</option>
              <option value={"mean"}>{t`Mean`}</option>
            </>
          )}
          <>
            {quantilenValues.map((quantil) => (
              <option key={quantil} value={quantil}>
                {Quantile[quantil]}
              </option>
            ))}
            {memberList.map((member, index) => (
              <option key={member} value={member}>
                {member}
              </option>
            ))}
          </>
        </select>
      )}
    </FormField>
  );
}

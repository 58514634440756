import {
  DEFAULT_PARTICLE_AMOUNT,
  DEFAULT_PARTICLE_SIZE,
  opacityLimits,
  particleAmountLimit,
  particleSizeLimit,
} from "@/constants/layerConfigAttributes";
import type { LayerKind } from "@/layers/utility/createLayerObject";
import {
  ColorMapStyleSelect,
  type ColorMapStyleSelectData,
} from "@/overlay/components/SmartForm/HookInputs/ColorMapStyleSelect";
import { type ModelSelectData, ParamModelSelect } from "@/overlay/components/SmartForm/HookInputs/ParamModelSelect";
import {
  type ParamOptionInputData,
  ParamOptionSelectList,
} from "@/overlay/components/SmartForm/HookInputs/ParamOptionSelectList";
import { SliderInput, type SliderInputData } from "@/overlay/components/SmartForm/HookInputs/SliderInput";
import { ModeSwitchableForm } from "@/overlay/components/SmartForm/SmartFormUtils/FormComponents/ModeSwitchableFormProps";
import { useFormWithUtils as useFormWithUtilsDefault } from "@/overlay/components/SmartForm/SmartFormUtils/FormHooks/useFormUtils";
import { usePartialWeatherParameter as usePartialWeatherParameterDefault } from "@/overlay/components/SmartForm/SmartFormUtils/FormHooks/usePartialWeatherParameter";
import { usePartialWeatherParameterModels as usePartialWeatherParameterModelsDefault } from "@/overlay/components/SmartForm/SmartFormUtils/FormHooks/usePartialWeatherParameterModels";
import type { BaseFormProps } from "@/overlay/components/SmartForm/SmartFormUtils/commonTypeUtils";
import { searchEngineWeatherModels, searchEnginesWeatherParams } from "@/weather-parameters";
import { t } from "@lingui/macro";
import type { WindAnimationLayer, WindAnimationLayerCreate } from "@mm/metx-workbench.meteomatics.com";
import { getUpdatedParamUnit } from "../../../SmartFormUtils/FormDataFormatter/mergePartialWeatherParameter";

type WindLayerPatch = Partial<WindAnimationLayerCreate | WindAnimationLayer> &
  Partial<{
    custom_options: any;
  }>;

/**
 * Form data passed from the child components.
 */
export type WindAnimationFormData = {
  // Snake case as they get passed as layer props directly.
  layerData: {
    model: ModelSelectData;
    opacity: SliderInputData;
    color_map: ColorMapStyleSelectData;
    parameter_unit: string; // Constructed in form from input data.
    custom_options: {
      size: number;
      amount: number;
    };
  };
  // Form internal data to construct the above data.
  internal: {
    paramOptionPatch: ParamOptionInputData;
  };
};

type WindAnimationLayerFormProps = {
  defaultFormData: WindLayerPatch;
} & BaseFormProps<WindAnimationFormData>;

const searchEngine = searchEnginesWeatherParams.standard;
const modelSearchEngine = searchEngineWeatherModels;

/**
 * Responsible for rendering the whole layer form and send back the data on submit hadler.
 */
export function WindAnimationLayerForm({
  usePartialWeatherParameter = usePartialWeatherParameterDefault,
  usePartialWeatherParameterModels = usePartialWeatherParameterModelsDefault,
  useFormWithUtils = useFormWithUtilsDefault,
  ...props
}: WindAnimationLayerFormProps & {
  // Allow hook dependency injection for unit test.
  usePartialWeatherParameter?: typeof usePartialWeatherParameterDefault;
  usePartialWeatherParameterModels?: typeof usePartialWeatherParameterModelsDefault;
  useFormWithUtils?: typeof useFormWithUtilsDefault;
}) {
  const { submitMode, onSubmit, defaultFormData } = props;

  const { formMethods, formValName } = useFormWithUtils<WindAnimationFormData>({
    mode: submitMode,
  });

  const paramUnit = defaultFormData.parameter_unit;
  const { parameter } = usePartialWeatherParameter(paramUnit ?? "", defaultFormData.kind as LayerKind, searchEngine);
  const availableModels = usePartialWeatherParameterModels(parameter, modelSearchEngine);

  const onSubmitWithFormatting: typeof onSubmit = (formData) => {
    // Format the internal objects to layer data.
    const paramUnit = getUpdatedParamUnit(parameter, [formData.internal.paramOptionPatch]);
    onSubmit({
      ...formData,
      layerData: { ...formData.layerData, parameter_unit: paramUnit },
    });
  };

  return (
    <ModeSwitchableForm
      onSubmit={formMethods.handleSubmit(onSubmitWithFormatting)}
      onCancel={props.onCancel}
      submitMode={submitMode}
    >
      <ParamModelSelect
        instruction={{
          currValue: defaultFormData.model,
          formMethods: formMethods,
          formValueName: formValName("layerData.model"),
        }}
        availableModels={availableModels}
        isCreationMode={!props.showAllConfig}
      />
      {/* We currently don't show format selector as only wind U & V component parameters are used. */}
      <ParamOptionSelectList
        instruction={{
          currValue: {
            parameter: parameter,
          },
          formMethods: formMethods,
          formValueName: formValName("internal.paramOptionPatch"),
        }}
        isCreationMode={!props.showAllConfig}
      />
      <ColorMapStyleSelect
        instruction={{
          currValue: {
            colorMapName: defaultFormData.color_map,
            paramUnit: defaultFormData.parameter_unit ?? "",
          },
          formMethods: formMethods,
          formValueName: formValName("layerData.color_map"),
        }}
        isCreationMode={!props.showAllConfig}
      />
      {props.showAllConfig && (
        <>
          <SliderInput
            label={t`Opacity`}
            range={opacityLimits}
            instruction={{
              currValue: defaultFormData.opacity ?? 1,
              formMethods: formMethods,
              formValueName: formValName("layerData.opacity"),
            }}
          />
          <SliderInput
            label={t`Size`}
            range={particleSizeLimit}
            instruction={{
              currValue: defaultFormData.custom_options?.size ?? DEFAULT_PARTICLE_SIZE,
              formMethods: formMethods,
              formValueName: formValName("layerData.custom_options.size"),
            }}
          />
          <SliderInput
            label={t`Amount`}
            range={particleAmountLimit}
            instruction={{
              currValue: defaultFormData.custom_options?.amount ?? DEFAULT_PARTICLE_AMOUNT,
              formMethods: formMethods,
              formValueName: formValName("layerData.custom_options.amount"),
            }}
          />
        </>
      )}
    </ModeSwitchableForm>
  );
}

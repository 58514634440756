import { useCurrTimeState } from "@/models/time-control/timeStateHooks";
import type { ReactNode } from "react";
import { createContext, useContext, useMemo, useState } from "react";
import { FrameQueue } from "../utils/frameQueue";
import { createTimeFramesFromState } from "../utils/videoUtils";
import { VideoProgressOverlay } from "./VideoProgressOverlay";

type VideoExportContextType = {
  frameQueue?: FrameQueue<void>;
  frameRecordingQueueStatus?: FrameGeneratorStatus;
  isVideoRecordingActive: boolean;
};

type VideoExportProviderProps = {
  children?: ReactNode;
};

export enum VideoExportStatus {
  IDLE = 0,
  RECORDING_FRAMES = 1,
  CREATING_VIDEO = 2,
}

export type FrameGeneratorStatus = {
  totalFrames: number;
  processedFrames: number;
};

const VideoExportContext = createContext<VideoExportContextType>({
  isVideoRecordingActive: false,
});

export const VideoExportProvider = ({ children }: VideoExportProviderProps) => {
  const timeState = useCurrTimeState();

  const [isVideoRecordingActive, setVideoRecorderActive] = useState(false);

  const timeList = useMemo(() => createTimeFramesFromState(timeState), [timeState]);

  const frameQueue = useMemo(() => new FrameQueue<void>(setVideoRecorderActive), []);

  const frameRecordingQueueStatus = useMemo<FrameGeneratorStatus>(
    () => ({
      totalFrames: timeList.length,
      processedFrames: timeList.length - frameQueue.chain.length,
    }),
    [frameQueue.chain.length, timeList],
  );

  return (
    <VideoExportContext.Provider
      value={{
        frameQueue,
        frameRecordingQueueStatus,
        isVideoRecordingActive,
      }}
    >
      {children}
      {isVideoRecordingActive && <VideoProgressOverlay />}
    </VideoExportContext.Provider>
  );
};

export const useVideoExportContext = () => useContext(VideoExportContext);

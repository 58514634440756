export interface Environment {
  baseUrlLogin: string;
  oauthClientId: string;
  // base url of the metx api. If not given, the default base url configured in the api bindings package is used.
  baseUrlMetx: string;
  baseUrlApiLayers: string;
  baseUrlGeocoder: string;
  baseUrlApiMeteomatics: string[];
  baseUrlEnergyForecast: string;
  maptilerKey: string;
  // a static string prepended to the document title
  documentTitlePrefix: string;
  // a static string appended to the document title
  documentTitleSuffix: string;
  publicUrl: string;
  mapboxKey: string;
}

export const defaultEnvironment: Pick<Environment, "documentTitleSuffix" | "documentTitlePrefix" | "publicUrl"> = {
  documentTitlePrefix: "metx | ",
  documentTitleSuffix: "",
  publicUrl: process.env.PUBLIC_URL,
};

import type { CircleLayer, SymbolLayer } from "mapbox-gl";
import { DETAILS_FILTER_EXPRESSION, POINT_FILTER_EXPRESSION } from "./filters";
import { COMMON_LAYOUT_STYLE, TEXT_PAINT_STYLE } from "./styles";
import { constructWindBarbsIconMapExpression, convertRange } from "./utils";

export const INTERACTIVE_LAYERS_NAMES = ["taf_position", "taf_cloud_coverage"];

export const LAYER_DEFINITION: { [key: string]: SymbolLayer | CircleLayer } = {
  taf_position: {
    id: "taf_position",
    filter: POINT_FILTER_EXPRESSION,
    "source-layer": "aviation_reports",
    type: "circle",
    paint: {
      "circle-color": "#404040",
      "circle-radius": 3,
    },
  },
  taf_wind_barb: {
    id: "taf_wind_barb",
    filter: DETAILS_FILTER_EXPRESSION,
    type: "symbol",
    "source-layer": "aviation_reports",
    layout: {
      "icon-image": constructWindBarbsIconMapExpression(),
      "icon-rotate": ["to-number", ["get", "wind_direction"]],
      "icon-offset": [0, -23],
      "icon-size": 0.54,
      "symbol-avoid-edges": true,
      "icon-allow-overlap": true,
      "icon-rotation-alignment": "map",
      visibility: "visible",
    },
    paint: {
      "icon-color": "#404040",
    },
  },
  taf_weather_symbol: {
    id: "taf_weather_symbol",
    filter: DETAILS_FILTER_EXPRESSION,
    type: "symbol",
    "source-layer": "aviation_reports",
    layout: {
      "icon-image": "{weather_symbol_key}",
      "icon-offset": [-130, -5],
      "icon-size": 0.24,
      "symbol-avoid-edges": true,
      "icon-allow-overlap": true,
      "icon-rotation-alignment": "map",
      visibility: "visible",
    },
    paint: {
      "icon-color": "#404040",
    },
  },
  taf_cloud_coverage: {
    id: "taf_cloud_coverage",
    type: "symbol",
    "source-layer": "aviation_reports",
    filter: DETAILS_FILTER_EXPRESSION,
    layout: {
      "icon-image": "{clouds}",
      "icon-size": 0.24,
      "symbol-avoid-edges": true,
      "icon-allow-overlap": true,
      "icon-rotation-alignment": "map",
      visibility: "visible",
    },
    paint: {
      "icon-color": "#404040",
    },
  },
  taf_visibility_near: {
    id: "taf_visibility_near",
    filter: DETAILS_FILTER_EXPRESSION,
    type: "symbol",
    "source-layer": "aviation_reports",
    layout: {
      ...COMMON_LAYOUT_STYLE,
      "text-field": ["case", ["has", "weather_symbol_key"], "", ["get", "visibility"]],
      "text-padding": 250,
      "text-offset": [-2.5, 0],
      "text-size": 14.5,
      visibility: "visible",
    },
    paint: TEXT_PAINT_STYLE,
  },
  taf_visibility_far: {
    id: "taf_visibility_far",
    filter: DETAILS_FILTER_EXPRESSION,
    type: "symbol",
    "source-layer": "aviation_reports",
    layout: {
      ...COMMON_LAYOUT_STYLE,
      "text-field": ["case", ["has", "weather_symbol_key"], ["get", "visibility"], ""],
      "text-padding": 250,
      "text-offset": [-5.2, 0],
      "text-size": 14.5,
      visibility: "visible",
    },
    paint: TEXT_PAINT_STYLE,
  },
  taf_ceiling: {
    id: "taf_ceiling",
    filter: DETAILS_FILTER_EXPRESSION,
    type: "symbol",
    "source-layer": "aviation_reports",
    layout: {
      ...COMMON_LAYOUT_STYLE,
      "text-anchor": "left",
      "text-field": "{ceil}",
      "text-padding": 250,
      "text-offset": [1.7, 0],
      "text-size": 14.5,
      visibility: "visible",
    },
    paint: TEXT_PAINT_STYLE,
  },
  taf_id: {
    id: "taf_id",
    filter: DETAILS_FILTER_EXPRESSION,
    type: "symbol",
    "source-layer": "aviation_reports",
    layout: {
      ...COMMON_LAYOUT_STYLE,
      "text-field": "{id}",
      "text-padding": 50,
      "text-offset": [0.5, 2],
      "text-justify": "center",
      "text-size": 14.5,
      visibility: "visible",
    },
    paint: TEXT_PAINT_STYLE,
  },
  taf_warning_flag: {
    id: "taf_warning_flag",
    filter: ["all", ["has", "should_warn"], DETAILS_FILTER_EXPRESSION],
    "source-layer": "aviation_reports",
    type: "symbol",
    layout: {
      "icon-image": "warning_symbol",
      "icon-allow-overlap": true,
      "icon-offset": [110, -110],
      "icon-size": 0.24,
      "icon-rotation-alignment": "map",
      "icon-keep-upright": true,
    },
  },
};

// Min icon/text size is half of default
// Max icon/text size is double of default
export const ICON_LAYERS = {
  taf_wind_barb: {
    minSize: 0.27,
    maxSize: 1.08,
  },
  taf_weather_symbol: {
    minSize: 0.12,
    maxSize: 0.48,
  },
  taf_cloud_coverage: {
    minSize: 0.12,
    maxSize: 0.48,
  },
  taf_warning_flag: {
    minSize: 0.12,
    maxSize: 0.48,
  },
};

export const TEXT_LAYERS = {
  taf_visibility_near: {
    minSize: 7.25,
    maxSize: 29,
  },
  taf_visibility_far: {
    minSize: 7.25,
    maxSize: 29,
  },
  taf_ceiling: {
    minSize: 7.25,
    maxSize: 29,
  },
  taf_id: {
    minSize: 7.25,
    maxSize: 29,
  },
};

export const composeTafLayers = (sourceId: string, initialSize: number): Array<SymbolLayer | CircleLayer> => {
  return Object.entries(LAYER_DEFINITION).map(([id, layer]) => {
    if (ICON_LAYERS[layer.id as keyof typeof ICON_LAYERS]) {
      const { minSize, maxSize } = ICON_LAYERS[layer.id as keyof typeof ICON_LAYERS];
      const layout = {
        ...layer.layout,
        "icon-size": convertRange(initialSize, [10, 34], [minSize, maxSize]),
      };

      return {
        ...layer,
        id: `${id}_${sourceId}`,
        source: sourceId,
        layout,
      } as SymbolLayer;
    }

    if (TEXT_LAYERS[layer.id as keyof typeof TEXT_LAYERS]) {
      const { minSize, maxSize } = TEXT_LAYERS[layer.id as keyof typeof TEXT_LAYERS];
      const layout = {
        ...layer.layout,
        "text-size": convertRange(initialSize, [10, 34], [minSize, maxSize]),
      };

      return {
        ...layer,
        id: `${id}_${sourceId}`,
        source: sourceId,
        layout,
      } as SymbolLayer;
    }

    return {
      ...layer,
      id: `${id}_${sourceId}`,
      source: sourceId,
    };
  });
};

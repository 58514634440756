export function compileShader(
  gl: WebGLRenderingContext,
  shaderSource: string,
  shaderType: number,
): WebGLShader | Error {
  // biome-ignore lint/style/noNonNullAssertion: TODO check createShader
  const shader = gl.createShader(shaderType)!;

  gl.shaderSource(shader, shaderSource);

  gl.compileShader(shader);

  const success = gl.getShaderParameter(shader, gl.COMPILE_STATUS);

  if (!success) {
    return new Error(`could not compile shader: ${gl.getShaderInfoLog(shader)}`);
  }
  return shader;
}

import { applyParamFilters } from "@/utility/weather-param-config/applyParamFilter";
import type { WeatherOptionFilterFlag } from "@/utility/weather-param-config/paramFilterTypes";
import type { ParameterUnit } from "@mm/api.meteomatics.com";
import Logger from "logging";
import { useCallback, useMemo } from "react";
import {
  type ExactMatchResult,
  type HasLayerIdxNarrowing,
  type PartialWeatherParameter,
  type WeatherParamSearchParallel,
  autofill,
} from "weather-parameter-utils";
const logger = Logger.fromFilename(__filename);

const description = "Invalid or deprecated parameter";
const fallbackPartialWeatherParameter: HasLayerIdxNarrowing<PartialWeatherParameter> = {
  parameter: {
    name: "invalid_parameter",
    description_de: description,
    description_en: description,
    description_fr: description,
    default_values: {},
    flags: [],
    layers: [
      {
        format: description,
        unit: [],
      },
    ],
  },
  narrowed_selection: {
    layerIdx: 0,
    fields: {},
  },
};

type PartialWeatherParameterReturnType = {
  parameter: HasLayerIdxNarrowing<PartialWeatherParameter>;
  search: () => Promise<HasLayerIdxNarrowing<PartialWeatherParameter> | undefined>;
};

/**
 * Given a string weather parameter, returns a instantiated PartialWeatherParameter which
 * can be used to derive detail information about the given parameter and related parameter formats.
 *
 * Responsible for:
 * 1. Instantiate the parameter unit string using search engine.
 * 2. Apply custom filter based on the layer requirements.
 */
export function usePartialWeatherParameter(
  paramUnit: ParameterUnit,
  filterFlag: WeatherOptionFilterFlag,
  searchEngine: WeatherParamSearchParallel,
): PartialWeatherParameterReturnType {
  const parameter = useMemo(() => {
    const param = searchEngine.matchSomeParameterNameExactly(paramUnit);
    if (!param) {
      // Return a fallback if the parameter doesn't exist.
      // This can happen if the api-layers removes the existing parameter,
      // but not removed from MetX DB.
      logger.error(
        `Parameter does not exist on the search engine: "${paramUnit}". This could be a deprecated parameter that was still in the user account, or a bug in frontend code.`,
      );
      return fallbackPartialWeatherParameter;
    }
    return applyParamFilters({
      param: param,
      filterFlag: filterFlag,
    });
  }, [searchEngine, paramUnit, filterFlag]);

  const search = useCallback(() => {
    return searchEngine
      .search(paramUnit)
      .then((response) => {
        if (response.results?.regular?.[0]) {
          const result: ExactMatchResult = response.results.regular[0];
          return autofill({
            parameter: result.layerGroup,
            narrowed_selection: {
              layerIdx: result.matchingLayers[0].layerIdx,
              // biome-ignore lint/style/noNonNullAssertion: TODO check
              fields: result.matchingLayers[0].match.groups!,
            },
          });
        }
        // TODO: Feedback to user, show a available parameter or hide the old Layer
        logger.error(`no exactMatchResult for term   ${paramUnit} errored out.`);
        return undefined;
      })
      .catch((e) => {
        // TODO: errors here are most likely reoccuring. We should probably show an error in this case instead of logging.
        // However, any error here renders the application unusable... So, adding CI tests to prevent this is most likely
        // a better option than a nice error message.
        logger.error(`search for term probably close to ${paramUnit} errored out.`, e);
        return undefined;
      });
  }, [paramUnit, searchEngine]);

  return {
    parameter,
    search,
  };
}

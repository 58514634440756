import { setTitle } from "@/effects";
import { Trans, t } from "@lingui/macro";
import BrowserInfo from "@smartbear/browser-info";
import { useEffect } from "react";

BrowserInfo.detect();

const HeaderName_RequestId = "x-request-id";

function tryFindRequestId(error: any): string | null {
  // vanilla HTTP fetch API response
  if (error instanceof Response) {
    return error.headers.get(HeaderName_RequestId);
  }

  // ApiResponse<T> is an abstraction around the vanilla fetch api and part of the
  // runtime generated by OpenAPIs typescript-fetch generator.
  if (error && error.raw instanceof Response) {
    return error.raw.headers.get(HeaderName_RequestId);
  }

  return null;
}

export default function ErrorPage(props: { error?: any }) {
  useEffect(() => {
    setTitle(t({ id: "error.title", message: "Error" }));
  }, []);

  const requestId = tryFindRequestId(props.error);

  // TODO: do not hardcode support email address here
  return (
    <div className="page page--error cover-window background--app-error-state overlay--center-children">
      <div className="text dialog dialog--large background--white">
        <h1>
          <Trans id="error.heading">An Error Occurred</Trans>
        </h1>

        <p>
          <Trans id="error.body.generic-msg">The application encountered an unrecoverable error.</Trans>
        </p>

        <h2>
          <Trans id="error.support.heading">Getting Help</Trans>
        </h2>

        <p>
          <Trans id="error.support.contact.methods">
            You can reach support any time through <b>support@meteomatics.com</b> or using the chat dialog on our
            website.
          </Trans>
        </p>
        <p>
          <Trans id="error.support.contact.should-include-meta-information">
            Please include the following information below in your message.
          </Trans>
        </p>
        <div className="form-group">
          <label className="text-input-field">
            <span>
              <Trans id="error.request-id.label">Request ID / Correlation ID</Trans>
            </span>
            <input
              type="text"
              readOnly
              value={requestId ?? t({ id: "error.request-id.unavailable", message: "not available" })}
            />
          </label>
          <label className="text-input-field">
            <span>
              <Trans id="error.browser.label">Browser</Trans>
            </span>
            <input type="text" readOnly value={`${BrowserInfo.name} ${BrowserInfo.version}`} />
          </label>
          <label className="text-input-field">
            <span>
              <Trans id="error.operating-system.label">Operating System</Trans>
            </span>
            <input type="text" readOnly value={`${BrowserInfo.os}`} />
          </label>
        </div>
      </div>
    </div>
  );
}

import { compositor } from "@/layers/Compositor";
import type { MapboxMap } from "@/layers/MapboxMap";
import { useCountryPlots } from "@/reducer/CountryPlotState";
import { useEnergyPlots } from "@/reducer/EnergyPlotsState";
import { useMaps } from "@/reducer/MapsState";
import { useNotes } from "@/reducer/NoteState";
import { usePlots } from "@/reducer/PlotsState";
import { useTephigrams } from "@/reducer/TephigramState";
import { useWeatherTables } from "@/reducer/WeatherTablesState";
import type { Snapshot } from "@/threads/MediaExportThread/MediaExportThreadPoolMsg";
import { toBlob } from "html-to-image";
import { useCallback } from "react";

function getMapImageBitmapAsync(map: MapboxMap, cb: (bitmap: ImageBitmap) => void) {
  map.once("idle", () => {
    createImageBitmap(map.getCanvas()).then((bitmap) => {
      cb(bitmap);
    });
  });
  /* trigger render */
  map.triggerRepaint();
}

// No error handlings here
// TODO implement error handling
export const useToolSnapshotConstructor = (tabId: number) => {
  const maps = useMaps(tabId);
  const plots = usePlots(tabId);
  const energyPlots = useEnergyPlots(tabId);
  const weatherTables = useWeatherTables(tabId);
  const tephigrams = useTephigrams(tabId);
  const notes = useNotes(tabId);
  const countryPlots = useCountryPlots(tabId);

  /**
   * A callback to construct the blob for all the tools present in the current UI
   */
  const snapshotActiveToolsAsync = useCallback(() => {
    const snapshotPromises: Promise<Snapshot>[] = [];

    const mapSnapshotPromises: Promise<Snapshot>[] = [];
    for (const map of maps) {
      const scene = compositor.tryGetScene([map.id, map.id_profile, map.id_tab].join("."));
      const mapContainer = document.getElementById(`map-${map.id}`);

      if (mapContainer && scene) {
        mapSnapshotPromises.push(
          new Promise<Snapshot>((resolve) => {
            requestAnimationFrame(() =>
              getMapImageBitmapAsync(scene.getMapboxMap(), (bitmap) =>
                resolve({
                  snapshot: bitmap,
                  rect: mapContainer.getBoundingClientRect(),
                }),
              ),
            );
          }),
        );
      }
    }
    snapshotPromises.push(...mapSnapshotPromises);

    for (const plot of plots) {
      const plotContainer = document.getElementById(`plot-${plot.id}`)?.parentElement;
      if (plotContainer) {
        snapshotPromises.push(createSnapshotPromise(plotContainer));
      }
    }

    for (const energyPlot of energyPlots) {
      const plotContainer = document.getElementById(`energy-plot-${energyPlot.id}`)?.parentElement;
      if (plotContainer) {
        snapshotPromises.push(createSnapshotPromise(plotContainer));
      }
    }

    for (const countryPlot of countryPlots) {
      const plotContainer = document.getElementById(`country-plot-${countryPlot.id}`)?.parentElement;
      if (plotContainer) {
        snapshotPromises.push(createSnapshotPromise(plotContainer));
      }
    }

    for (const weatherTable of weatherTables) {
      const plotContainer = document.getElementById(`weather-table-${weatherTable.id}`)?.parentElement;
      if (plotContainer) {
        snapshotPromises.push(createSnapshotPromise(plotContainer));
      }
    }

    for (const tephigram of tephigrams) {
      const plotContainer = document.getElementById(`tephigram-${tephigram.id}`)?.parentElement;
      if (plotContainer) {
        snapshotPromises.push(createSnapshotPromise(plotContainer));
      }
    }

    for (const note of notes) {
      const noteContainer = document.getElementById(`note-${note.id}`)?.parentElement;
      if (noteContainer) {
        snapshotPromises.push(createSnapshotPromise(noteContainer));
      }
    }

    return Promise.all(snapshotPromises);
  }, [maps, energyPlots, plots, weatherTables, tephigrams]);

  /**
   * Returns a snapshot promise for a given container
   */
  const createSnapshotPromise = (container: HTMLElement) => {
    return new Promise<Snapshot>(async (resolve, reject) => {
      requestAnimationFrame(async () => {
        const elementImage = await toBlob(container);
        if (elementImage) {
          resolve({
            snapshot: elementImage,
            rect: container.getBoundingClientRect(),
          });
        } else {
          // TODO Error handling
          reject("Couldn't create blob!!!");
        }
      });
    });
  };

  /**
   * A callback to construct the blob for map grid over lay
   * This HTML element currently contains supplemental visual elements like WMS legends.
   */
  const snapshotMapOverlaysAsync = useCallback(() => {
    const overlays = document.getElementsByClassName("map-grid-overlay");

    const mapOverlayPromises = Array.from(overlays).map((overlay) => {
      return new Promise<Snapshot>((resolve, reject) => {
        toBlob(overlay as HTMLElement, {
          quality: 1,
          filter: (node: any) => node.nodeValue !== "straighten",
        }).then((blob) => {
          if (blob) {
            // TODO fix error handling
            resolve({
              snapshot: blob,
              rect: overlay.getBoundingClientRect(),
            });
          } else {
            reject("Couldn't create blob!!!");
          }
        });
      });
    });

    return Promise.all(mapOverlayPromises);
  }, []);

  return {
    snapshotActiveToolsAsync,
    snapshotMapOverlaysAsync,
  };
};

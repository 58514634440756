import type { IconData, PoiOptions, ThresholdColor } from "@/reducer/client-models/PoiOptions";
import { ConditionOptions } from "@/utility/conditionalExpressions";
import { enumKeys } from "@/utility/enum";
import type { GenericPlot, GenericPlotCreate, GenericPlotUpdate } from "@mm/metx-workbench.meteomatics.com/dist";
import type { YAxisOptions } from "highcharts";

/**
 * Same as GenericPlot, but plotSeries property is typed with the client model.
 */
export interface Plot extends GenericPlot {
  plotSeries: PlotSeriesType[];
  plotYAxis: YAxisOptions[];
}

/**
 * Same as GenericPlotCreate, but plotSeries property is typed with the client model.
 */
export interface PlotCreate extends GenericPlotCreate {
  plotSeries: PlotSeriesType[];
  plotYAxis: YAxisOptions[];
}

/**
 * Same as GenericPlotUpdate, but plotSeries property is typed with the client model.
 */
export interface PlotUpdate extends GenericPlotUpdate {
  plotSeries: PlotSeriesType[];
  plotYAxis: YAxisOptions[];
}

export enum SeriesTypes {
  spline = "spline",
  line = "line",
  column = "column",
  area = "area",
  areaspline = "areaspline",
  scatter = "scatter",
}

export const StartColor = "#7cb5ec";
export const seriesTypes = enumKeys(SeriesTypes);

export enum SeriesDashStyles {
  Solid = "Solid",
  ShortDash = "ShortDash",
  ShortDot = "ShortDot",
  ShortDashDot = "ShortDashDot",
  ShortDashDotDot = "ShortDashDotDot",
  Dot = "Dot",
  Dash = "Dash",
  LongDash = "LongDash",
  DashDot = "DashDot",
  LongDashDot = "LongDashDot",
  LongDashDotDot = "LongDashDotDot",
}

export const seriesDashStyles = enumKeys(SeriesDashStyles);

// we have 8 but want to make the last idx 7 not selectable for the user
export const yAxisList: number[] = [0, 1, 2, 3, 4, 5, 6];

export type PlotSeriesZone = {
  value: number | null;
  color: string;
};

export type PlotSeriesZoneSafe = {
  value: number;
  color: string;
};

export type ColumnSeriesType = BaseSeriesType & {
  title: string;
};

export type BaseSeriesType = {
  parameter_unit: string; // t_2m:C
  model: string; // mix
  // String to store the layer's Ensemble Member Selection option.
  // See: https://www.meteomatics.com/en/api/request/optional-parameters/ensemble-member-selection/
  ens_select?: string; // '', member:1
  show: boolean; // true
  index: number; // order
  calibrated: boolean; // false
  sort?: string;
};

type ZonesType = PlotSeriesZone;
export type PlotSeriesType = BaseSeriesType & {
  series_color: string; // black
  series_type: string; // spline
  series_line_type: string; // Solid
  series_y_axis: number; // 0
  series_threshold?: number; // 0
  series_negative_color?: string;
  show_series_label?: boolean;
  zones?: ZonesType[];
};

export const DefaultPlotSeriesWithOptionals: PlotSeriesType = {
  calibrated: false,
  index: 0,
  model: "mix",
  parameter_unit: "t_2m:C",
  ens_select: "",
  series_color: StartColor,
  series_line_type: "Solid",
  series_type: "spline",
  series_y_axis: 0,
  show: true,
  series_threshold: 0,
  series_negative_color: "#FF0000",
  zones: [],
};
export const DefaultPlotSeries: PlotSeriesType = {
  calibrated: false,
  index: 0,
  model: "mix",
  parameter_unit: "t_2m:C",
  series_color: StartColor,
  series_line_type: "Solid",
  series_type: "spline",
  series_y_axis: 0,
  show: true,
};
const meteogrammHighchartsColors = [
  "#7cb5ec",
  "#434348",
  "#90ed7d",
  "#f7a35c",
  "#8085e9",
  "#f15c80",
  "#e4d354",
  "#2b908f",
  "#f45b5b",
  "#91e8e1",
];
const TemperatureColor = "#FF3333";

export const DefaultPointForecastSeriesList: PlotSeriesType[] = [
  {
    calibrated: false,
    index: 0,
    model: "mix",
    parameter_unit: "effective_cloud_cover:p",
    series_color: "rgba(150, 146, 146, 0.5)",
    series_line_type: "Solid",
    series_type: "areaspline",
    series_y_axis: 5,
    show: true,
  },
  {
    calibrated: false,
    index: 1,
    model: "mix",
    parameter_unit: "global_rad:W",
    series_color: meteogrammHighchartsColors[3],
    series_line_type: "Solid",
    series_type: "area",
    series_y_axis: 3,
    show: true,
  },
  {
    calibrated: false,
    index: 2,
    model: "mix",
    parameter_unit: "sunshine_duration_1h:min",
    series_color: meteogrammHighchartsColors[6],
    series_line_type: "Solid",
    series_type: "area",
    series_y_axis: 4,
    show: true,
  },
  {
    calibrated: false,
    index: 3,
    model: "mix",
    parameter_unit: "t_2m:C",
    series_color: TemperatureColor,
    series_line_type: "Solid",
    series_type: "spline",
    series_y_axis: 0,
    show: true,
  },
  {
    calibrated: false,
    index: 4,
    model: "mix",
    parameter_unit: "msl_pressure:hPa",
    series_color: meteogrammHighchartsColors[2],
    series_line_type: "ShortDot",
    series_type: "spline",
    series_y_axis: 1,
    show: true,
  },
  {
    calibrated: false,
    index: 5,
    model: "mix",
    parameter_unit: "precip_1h:mm",
    series_color: "rgba(104,207,232,0.5)",
    series_line_type: "Solid",
    series_type: "column",
    series_y_axis: 2,
    show: true,
    show_series_label: true,
  },
  {
    calibrated: false,
    index: 6,
    model: "mix",
    parameter_unit: "wind_speed_10m:kn",
    series_color: "#90ed7d",
    series_line_type: "Solid",
    series_type: "windbarb",
    series_y_axis: 7,
    show: true,
  },
  {
    calibrated: false,
    index: 7,
    model: "mix",
    parameter_unit: "weather_symbol_1h:idx",
    series_color: "#90ed7d",
    series_line_type: "Solid",
    series_type: "weathersymbol",
    series_y_axis: 7,
    show: true,
  },
];

export const DefaultIconData: IconData = {
  coords: [{ lat: 52, lon: 12 }],
  icon: "marker",
  text: "",
  color: "#FF0000",
  size: 0.2,
};
export const DefaultPoiOptions: PoiOptions = {
  item: DefaultIconData,
};
export const DefaultPoiOptionsWithColoringOptions: PoiOptions = {
  item: DefaultIconData,
  coloringOptions: { thresholds: [] },
};

export const DefaultThreshold: ThresholdColor = {
  color: "#FF0000",
  condition: ConditionOptions["=="],
  value: 0,
};

/// <reference types="build-info/compile_time_constants" />

import { type RefObject, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { type MapId, ProjectionNameEnum, ViewportKind } from "@mm/metx-workbench.meteomatics.com";

import mapboxgl, { type ResourceType, ScaleControl, type Style } from "mapbox-gl";

import "./style.scss";

import { setLayerPropsChanges, setViewportChanges } from "@/reducer/PrefetchState";
import { EffectResult, traceComponent } from "@/utility/tracing";

import { ENV } from "@/env";

import { usePlaybackState } from "@/animation";
import { type ScenePublicApi, compositor } from "@/layers/Compositor";
import { usePopupPlot } from "@/reducer/plot/PopupPlotContext";
import { setPopupPlotState } from "@/reducer/plot/reducer";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import Logger from "logging";
import useResizeObserver from "use-resize-observer";

/**
 * Global map icon configurations. Uses Sprite images.
 * Sprite images: https://docs.mapbox.com/mapbox-gl-js/style-spec/sprite/
 * These icons are used to render icons based on GeoJSONFeatureCollection in layer implementations.
 *
 * CAUTION: We need to keep this, so webpack copies over atlases to media folder,
 * unless we figure out how to configure webpack to copy these files without importing.
 */
import { useCurrentTabViewportLinkingStatus } from "@/grid/useCurrentTabViewportLinkingStatus";
import {
  emitClickToInteractionStateMachine,
  emitContextMenuToInteractionStateMachine,
  fetchIconImg,
} from "@/map/mapboxListeners";
import { useDrawingLayerDesc } from "@/models/map-drawing-tools";
import {
  useCurrMapInteractionState,
  useMapInteractionService,
} from "@/models/map-interaction/mapInteractionStateHooks";
import { useTempLayerDescriptions } from "@/models/temporal-layer/tempLayerStateMachineHooks";
import { useCurrTimeState } from "@/models/time-control/timeStateHooks";
import type { RootState } from "@/reducer";
import { useDescriptionLayers } from "@/reducer/MapsState";
import { setViewport } from "@/reducer/ViewportsState";
import { batchGroupBy } from "@/reducer/batchGroupBy";
import { useMapAndViewport } from "@/reducer/selectors/ToolsSelectors";
import { SentrySpans } from "@/sentry/SentrySpans";
import { withProfiler } from "@sentry/react";

const logger = Logger.fromFilename(__filename);

/**
 * If enabled, prints debug messages to the console that aid in debugging synchronization problems between
 * the reactive data store of the application and the state within the mapbox map.
 *
 * To enable, replace `false` with `true`.
 *
 * Another tip is to replace `useTracedEffect` calls with `useEffect`. This will print a list of missing
 * dependencies during linting. However, make sure these messages are not false positives!
 */

const __TRACE_MAP_CONSOLIDATION = !__IS_PRODUCTION__ && false;

type SerializedMapId = string;

export function serializeMapId(id: MapId): SerializedMapId {
  return [id.map, id.profile, id.tab].join(".");
}

const glyphs = `https://api.maptiler.com/fonts/{fontstack}/{range}.pbf?key=${ENV.maptilerKey}`;

mapboxgl.accessToken = ENV.mapboxKey;

// This check is needed because of hot-reload error: Uncaught Error: setRTLTextPlugin cannot be called multiple times.
if (mapboxgl.getRTLTextPluginStatus() === "unavailable") {
  // Plugin for right-to-left languages. Added for Arabic support.
  // https://docs.mapbox.com/mapbox-gl-js/api/properties/#setrtltextplugin
  mapboxgl.setRTLTextPlugin(
    new URL("mapbox-plugins/mapbox-gl-rtl-text/v0.2.0/mapbox-gl-rtl-text.js", document.location.origin).href,
    (e) => {
      console.log("ERROR:", e);
    },
  );
}

const emptyStyle: Style = {
  version: 8,
  sources: {},
  layers: [],
  glyphs,
};

// TODO: the viewport sync code is soooooooo dump. Reiner see your notes on page 62.

type MapProps = {
  id: MapId;
};
// Wrapped with Sentry for default profiling
// biome-ignore lint/suspicious/noShadowRestrictedNames: TODO shadowing should be fixed
export const Map = withProfiler(MapImpl, { name: "Map" });

/**
 * A react component wrapping `Visualization` into a reactive component.
 *
 * @param props
 * @returns
 */
function MapImpl(props: MapProps) {
  const { useTracedEffect, trace } = traceComponent(__TRACE_MAP_CONSOLIDATION, logger, serializeMapId(props.id));

  const [cartographicMap, viewport] = useMapAndViewport(props.id);
  const dispatch = useDispatch();
  const playback = usePlaybackState();
  const currentTime = playback?.currentTime;
  const nextTime = playback?.nextTime;
  const timeDesc = playback?.sanitizedDescription();
  const temporalDiscretization = playback?.temporalDiscretization;
  const isLayoutArrangementActive = useSelector((state: RootState) => state.ui.isLayoutArrangementActive);

  const [scene, setScene] = useState<ScenePublicApi | null>(null);
  const mapElement: RefObject<HTMLDivElement> | null = useRef(null);
  const descriptionLayers = useDescriptionLayers(props.id.map);
  const tempDescriptionLayers = useTempLayerDescriptions(props.id.map);
  const drawingLayer = useDrawingLayerDesc(props.id.map);
  const { state: plotState, dispatch: popupPlotDispatch } = usePopupPlot();
  const { coords, show } = plotState;
  const timeOffsetMinutes = cartographicMap?.time_offset_mins;
  const mapProjection = cartographicMap?.map_projection;
  const mapLodBias = cartographicMap?.lod_bias;

  const mapInteractionState = useCurrMapInteractionState();
  const showCrosshairCursor = mapInteractionState.matches("COORD_SELECT");
  const { mapService } = useMapInteractionService();
  const areCurrentMapsLinked = useCurrentTabViewportLinkingStatus();

  const {
    context: { timezone },
  } = useCurrTimeState();

  const scale = useMemo(
    () =>
      new ScaleControl({
        maxWidth: 100,
        unit: "metric",
      }),
    [],
  );

  // mapbox only observes the window size for automatic resizing.
  // As a result, we have to resize explicitly when the map/plot grid changes (which changes
  // the container size without changing the window size.)
  useResizeObserver<HTMLDivElement>({
    ref: mapElement,
    onResize: ({ width, height }) => {
      const sceneId = serializeMapId(mapId);
      const tmpScene = compositor.tryGetScene(sceneId);
      if (tmpScene != null) {
        const map = tmpScene.getMapboxMap();

        // Workaround for fixing difference in bounding box caused by resize
        // I'm not sure, I need to try finding better solution, because all sync logic is messed up
        // TODO Refactor whole way how bounding boxes gots synhronized, probably using subscriptions
        // to get rid of redraws on each map interaction (that could increase performance greatly)
        map.once("resize", () => {
          if (!compositor.inUse(sceneId)) return;
          if (viewport && viewport.lastUpdatedBy === mapId.map) {
            const bounds = map.getBounds();
            const sw = bounds.getSouthWest();
            const ne = bounds.getNorthEast();

            const zoom = map.getZoom();
            const center = map.getCenter();
            dispatch(
              setViewport({
                id: mapId,
                viewportId: viewport.id,
                viewport: {
                  // if the viewport is changed
                  kind: ViewportKind.ViewportFull,
                  southWest_lng: sw.lng,
                  southWest_lat: sw.lat,
                  northEast_lng: ne.lng,
                  northEast_lat: ne.lat,
                  zoom,
                  center_lat: center.lat,
                  center_lng: center.lng,
                },
              }),
            );
            dispatch(
              setViewportChanges({
                id: mapId,
                viewport: {
                  // if the viewport is changed
                  kind: ViewportKind.ViewportFull,
                  southWest_lng: sw.lng,
                  southWest_lat: sw.lat,
                  northEast_lng: ne.lng,
                  northEast_lat: ne.lat,
                  zoom,
                  center_lat: center.lat,
                  center_lng: center.lng,
                },
              }),
            );
          }
          tmpScene.syncToMapboxViewport();
        });
        tmpScene.getMapboxMap().resize();
      }
    },
  });

  // make sure object equality holds for the `mapId`. Otherwise we have to list
  // all fields of the map id individually in the dependency list of each effect.
  const mapId = useMemo(
    () => ({
      map: props.id.map,
      profile: props.id.profile,
      tab: props.id.tab,
    }),
    [props.id.map, props.id.profile, props.id.tab],
  );

  useTracedEffect(
    "update cursor to match mapInteractionState",
    () => {
      if (!scene) {
        return EffectResult.EarlyOut;
      }

      const map = scene.getMapboxMap();
      if (showCrosshairCursor) {
        map.getCanvas().style.cursor = "crosshair";
      } else {
        map.getCanvas().style.cursor = "unset";
      }
      return EffectResult.Executed;
    },
    [scene, showCrosshairCursor],
  );

  // update viewport of mapbox to match redux store
  useTracedEffect(
    "update viewport of mapbox to match redux store",
    () => {
      // This place is causing sync bug
      // See hacky solution in TabSettingsWindow/index.tsx line 41
      // TODO Refactor whole syncing to flag inside DB so we have more control on behavior
      //   TODO last updated by is the cause of undo problem, we need to overcome that.
      if (scene && viewport && viewport.lastUpdatedBy !== mapId.map) {
        scene.setViewportDescription(viewport);
        return EffectResult.Executed;
      }
      return EffectResult.EarlyOut;
    },
    [viewport, scene, mapId],
  );

  useTracedEffect(
    "update scene to match viewport of mapbox",
    () => {
      if (!scene) {
        return EffectResult.EarlyOut;
      }
      scene.syncToMapboxViewport();
      return EffectResult.Executed;
    },
    [scene, viewport],
  );

  useTracedEffect(
    "set marker when needed",
    () => {
      //TODO: simplify
      if (!scene) {
        return EffectResult.EarlyOut;
      }
      const map = scene.getMapboxMap();
      const marker = scene.getPopupMarker();
      if (!show) {
        if (marker) {
          marker.remove();
        }
        return EffectResult.EarlyOut;
      }

      if (coords && map) {
        if (marker) {
          marker.remove();
        }
        const tmpMarker = new mapboxgl.Marker({
          color: "#d90e0e",
          draggable: true,
        })
          .setLngLat([coords.lon, coords.lat])
          .addTo(map);
        scene.setPopupMarker(tmpMarker);
        tmpMarker.on("dragend", onDragEnd.bind(tmpMarker));
      } else {
        marker?.remove();
      }

      return () => {
        marker?.remove();
      };

      function onDragEnd() {
        // @ts-ignore
        if (this) {
          // @ts-ignore
          const lngLat = this.getLngLat();
          popupPlotDispatch(
            setPopupPlotState({
              type: plotState.type,
              coords: { lon: lngLat.lng, lat: lngLat.lat },
              show: true,
            }),
          );
        }
      }
    },
    [scene, show, coords, popupPlotDispatch],
  );

  // update redux store to match mapbox viewport
  useTracedEffect(
    "update redux store to match mapbox viewport",
    () => {
      if (!scene) {
        return EffectResult.EarlyOut;
      }

      const map = scene.getMapboxMap();

      map.on("move", onMapBoundsChange);
      // Move start and move end original events are actual user interactions, and they help starting and stopping batches of viewport changes
      // 'movestart' is executed before 'move', and 'moveend' is executed after 'move'
      map.on("movestart", (e) => {
        if (e.originalEvent) {
          batchGroupBy.start();
        }
      });
      map.on("moveend", (e) => {
        if (e.originalEvent) {
          batchGroupBy.end();
        }
      });

      const handleMissingImage = fetchIconImg(map);

      map.on("styleimagemissing", handleMissingImage);

      const emitClickToStateMachine = emitClickToInteractionStateMachine(mapId.map, mapService);
      map.on("click", emitClickToStateMachine);

      const emitContextMenuToStateMachine = emitContextMenuToInteractionStateMachine(mapId.map, mapService);
      map.on("contextmenu", emitContextMenuToStateMachine);

      return () => {
        map.off("move", onMapBoundsChange);
        map.off("movestart", suspendMapLoadingTransaction);
        map.off("styleimagemissing", handleMissingImage);
        map.off("click", emitClickToStateMachine);
      };

      function suspendMapLoadingTransaction() {
        // Stop the on-going map loading speed measurement if the user has interacted with the map,
        // because an interaction causes new content to load and measurements become inconsistent.
        SentrySpans.discardLayerSpans();
      }

      function onMapBoundsChange(event: any) {
        if (!cartographicMap) return;
        // `isUserInteraction` is false if the change was triggered programmatically using the Mapbox API. In this case, do nothing
        // to avoid endless recursion.
        const isUserInteraction = !!event.originalEvent;
        if (map && isUserInteraction) {
          const bounds = map.getBounds();

          const sw = bounds.getSouthWest();
          const ne = bounds.getNorthEast();

          const zoom = map.getZoom();
          const center = map.getCenter();

          dispatch(
            setViewport({
              id: mapId,
              viewportId: cartographicMap.id_viewport,
              viewport: {
                // if the viewport is changed
                kind: ViewportKind.ViewportFull,
                southWest_lng: sw.lng,
                southWest_lat: sw.lat,
                northEast_lng: ne.lng,
                northEast_lat: ne.lat,
                zoom,
                center_lat: center.lat,
                center_lng: center.lng,
              },
              isMapSync: areCurrentMapsLinked,
            }),
          );
          dispatch(
            setViewportChanges({
              id: mapId,
              viewport: {
                // if the viewport is changed
                kind: ViewportKind.ViewportFull,
                southWest_lng: sw.lng,
                southWest_lat: sw.lat,
                northEast_lng: ne.lng,
                northEast_lat: ne.lat,
                zoom,
                center_lat: center.lat,
                center_lng: center.lng,
              },
            }),
          );
        }
      }
    },
    [scene, dispatch, mapId, cartographicMap?.id_viewport, areCurrentMapsLinked],
  );

  useTracedEffect(
    "update scene to match abstract datetime description",
    () => {
      if (!scene || !timeDesc) {
        return EffectResult.EarlyOut;
      }
      scene.setDateTimeDescription(timeDesc);
      return EffectResult.Executed;
    },
    [scene, timeDesc],
  );

  useTracedEffect(
    "update relOffsetTime scene to match concrete/realized datetime",
    () => {
      if (!scene || !currentTime) {
        return EffectResult.EarlyOut;
      }
      scene.setTimeOffsetMinutes(timeOffsetMinutes);
      return EffectResult.Executed;
    },
    [scene, timeOffsetMinutes],
  );

  useTracedEffect(
    "update mapProjection scene to match concrete/realized mapProjection",
    () => {
      if (!scene || !mapProjection) {
        return EffectResult.EarlyOut;
      }
      if (mapProjection.name !== scene.getProjection().name && !!descriptionLayers) {
        const sortedLayers = descriptionLayers.toSorted((a, b) => {
          if (a.index < b.index) {
            return -1;
          }
          if (a.index > b.index) {
            return 1;
          }
          return 0;
        });
        scene.setLayerStackDescription(
          [...sortedLayers, drawingLayer],
          timezone,
          mapProjection.name !== ProjectionNameEnum.mercator,
        );
      }

      scene.setProjection(mapProjection);
      return EffectResult.Executed;
    },
    [scene, timezone, mapProjection],
  );

  useTracedEffect(
    "update lodBias scene to match concrete/realized lodBias",
    () => {
      if (!scene || mapLodBias === undefined) {
        return EffectResult.EarlyOut;
      }
      scene.setLodBias(mapLodBias);
      return EffectResult.Executed;
    },
    [scene, mapLodBias],
  );

  useTracedEffect(
    "update scene to match concrete/realized datetime",
    () => {
      if (!scene || !currentTime) {
        return EffectResult.EarlyOut;
      }
      scene.setDisplayTime(currentTime);
      return EffectResult.Executed;
    },
    [scene, currentTime],
  );

  useTracedEffect(
    "update scene to match next/pre-cache datetime",
    () => {
      if (!scene || !nextTime) {
        return EffectResult.EarlyOut;
      }
      scene.setNextTime(nextTime);
      return EffectResult.Executed;
    },
    [scene, nextTime],
  );

  // update layer stack
  useTracedEffect(
    "update scene to match layer stack description",
    () => {
      if (!descriptionLayers || !scene) {
        return EffectResult.EarlyOut;
      }
      const _descriptionLayers = [...descriptionLayers];
      const sortedLayers = _descriptionLayers.sort((a, b) => {
        if (a.index < b.index) {
          return -1;
        }
        if (a.index > b.index) {
          return 1;
        }
        return 0;
      });
      scene.setLayerStackDescription(
        // Temporal layers "tempDescriptionLayers" (preview etc) & drawing are always set on the top indices.
        [...sortedLayers, ...tempDescriptionLayers, drawingLayer],
        timezone,
        mapProjection?.name && mapProjection?.name !== ProjectionNameEnum.mercator,
      );

      dispatch(
        setLayerPropsChanges({
          id: mapId,
          layers: sortedLayers,
        }),
      );

      return EffectResult.Executed;
    },
    [descriptionLayers, tempDescriptionLayers, timezone, scene],
  );

  const allAvailable = viewport != null && temporalDiscretization != null && currentTime != null && timeDesc != null;

  // This effect should be run once after all data becomes available for the first time
  // We check this condition with `allAvailable`. It may happen that react reuses this
  // component for another map, so we have to observe the mapId to reinitialize reuses.
  useTracedEffect(
    "initialize mapbox",
    () => {
      // for some reason, we were already initialized, but some info became unavailable. This should not happen
      if (!allAvailable && mapElement.current != null) {
        return EffectResult.EarlyOut;
      }

      if (!mapElement.current) {
        return EffectResult.EarlyOut;
      }

      const sceneId = serializeMapId(mapId);

      mapElement.current.innerHTML = ""; // erase all child nodes. This is necessary for hot reloading

      // this is how mapbox gets the values internally, see `_containerDimensions`
      // setting these values before mapbox fully initializes reduces incorrect data retrieval
      const renderSizeAt1x = {
        width: mapElement.current.clientWidth || 400,
        height: mapElement.current.clientHeight || 300,
      };

      // mapbox does not support changing the container element after initialization. (We would like to attach to `mapElement.current`.)
      // Thus, we create an intermediate element that serves as a container.
      const container: HTMLElement = document.createElement("div");
      mapElement.current.appendChild(container);

      // TODO: grab all necessary init data first instead of filling it in with garbage
      const visualization: ScenePublicApi = compositor.getScene(sceneId, () => {
        const projection: {
          name:
            | "albers"
            | "equalEarth"
            | "equirectangular"
            | "lambertConformalConic"
            | "mercator"
            | "naturalEarth"
            | "winkelTripel"
            | "globe";
          center?: [number, number];
          parallels?: [number, number];
        } = { name: "mercator" };

        const mapboxOptions = {
          container,
          fadeDuration: 0,
          pitchWithRotate: true,
          showCollisionBoxes: false,
          style: emptyStyle,
          interactive: true,
          attributionControl: false,
          dragRotate: false, //TODO limit userinteraction for now to sync split view. Currently bearing and pitch are not synced
          projection,
          // Replace png sprite atlas to webp, as we want to use more modern image format
          transformRequest: (url: string, resourceType: ResourceType) => ({
            url: resourceType === "SpriteImage" ? url.replace("png", "webp") : url,
          }),
        };

        const map = compositor.getMapboxMapFromPool(sceneId, () => mapboxOptions);

        // For some reason official typing for mapbox (@types/mapbox-gl) doesn't
        // have typed projection. One of solutions is to extend typing by
        // adding missing types, but that's not very good approach, as there
        // must be typing library that works
        // TODO: Fix type is missing

        // Mapbox pool returns any unused mapbox instance, without taking projection in account.
        // It means - if we cache mapbox instance with globe projection, and try to reuse it where
        // mercator projection is expected, we will see "globe" projected map.
        // To fix that we need to make sure, that mapbox has right projection before rendering it.
        // This solution may not work, if we will decide to use projection transitions to make
        // UX more appealing (in that case refactoring of ObjectPool will be only option)

        if (map.getProjection().name !== mapboxOptions.projection.name) {
          // TODO: Fix type is missing
          map.setProjection(mapboxOptions.projection);
        }

        return {
          id: sceneId,
          // biome-ignore lint/style/noNonNullAssertion: TODO check why
          temporalDiscretization: temporalDiscretization!,
          // biome-ignore lint/style/noNonNullAssertion: TODO check why
          dateTimeDesc: timeDesc!,
          // biome-ignore lint/style/noNonNullAssertion: TODO check why
          displayTime: currentTime!,
          // biome-ignore lint/style/noNonNullAssertion: TODO check why
          nextTime: nextTime!,
          renderSize: renderSizeAt1x,
          map,
          lodBias: cartographicMap?.lod_bias || 1,
          timeOffsetMinutes: cartographicMap?.time_offset_mins || 0,
        };
      });
      const map = visualization.getMapboxMap();
      // reference comparing
      if (container !== map.getContainer()) {
        mapElement.current.innerHTML = ""; // erase all child nodes. This is necessary for hot reloading
        // add the old container to mapElement
        mapElement.current.appendChild(map.getContainer());
      }

      // TODO: the map initializes to the wrong size since the `container` is attached to the DOM after the mapbox.Map is constructed.
      // However, it's weird that we have to call resize ourselves.
      // Should also not be necessary since we set the size above
      map.resize();

      if (viewport != null) {
        visualization.syncMapboxToViewport(viewport);
      }

      // map was restored from a cached version, run onMapLoad explicitly since "load" already fired
      // TODO: this reorders operations, which should be avoided.
      if (visualization.isMapboxLoaded()) {
        onMapLoad();
      } else {
        map.on("load", onMapLoad);
      }

      return () => {
        if (visualization) {
          compositor.free(serializeMapId(mapId));

          const map = visualization.getMapboxMap();
          visualization.getPopupMarker()?.remove();
          map.off("load", onMapLoad);
          // Make sure we remove control, when map wrapper gets destroyed
          if (map.hasControl(scale)) {
            map.removeControl(scale);
          }
        }
      };

      function onMapLoad() {
        if (visualization === null) {
          return;
        }

        if (!map.hasControl(scale)) {
          map.addControl(scale, "bottom-right");
        }
        // [DEBUG-TILE-ALIGNMENT] map.showTileBoundaries = true;
        // [DEBUG-OVERDRAW] // @ts-expect-error
        // [DEBUG-OVERDRAW] map.showOverdrawInspector = true;
        trace("mapbox finished initializing", visualization);
        setScene(visualization);
      }
    },
    // This handler should only be run once on component mount to initialize the map. Any dependencies are thus ignored
    // and should only be used to enhance the initial state of the map.
    [mapId, mapElement, allAvailable],
  );

  return (
    // Added "id" so image export could find map container
    <div className="map-container overlay overlay__layer" id={`map-${mapId.map}`} data-testid="map-tool">
      <div
        className="grid grid--cover"
        ref={mapElement}
        style={isLayoutArrangementActive ? { pointerEvents: "none" } : {}}
      />
    </div>
  );
}

import type { FeatureCollection } from "geojson";

export enum MapDrawingToolEventType {
  toggle = "toggle",
  toDrawRadius = "toDrawRadius",
  publish = "publish",
}

export type MapDrawingToolEventUnion =
  | {
      type: MapDrawingToolEventType.toggle;
    }
  | {
      type: MapDrawingToolEventType.toDrawRadius;
    }
  | {
      type: MapDrawingToolEventType.publish;
      data: FeatureCollection;
      mapId: number;
    };

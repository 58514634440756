export type CancelablePromise<T> = {
  cancel: () => void;
  promise: Promise<T>;
};

export function cancelable<T>(promise: Promise<T>): CancelablePromise<T> {
  let hasCancelled = false;

  return {
    promise: promise.then((v) => {
      if (hasCancelled) {
        // eslint-disable-next-line no-throw-literal
        throw { isCancelled: true };
      }

      return v;
    }),
    cancel: () => {
      hasCancelled = true;
    },
  };
}

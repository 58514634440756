import type { LayerCreateUnion, LayerUnionWithAll } from "@/layers";
import type { LayerKind } from "@/layers/utility/createLayerObject";
import type { ComponentSwitchMapping } from "@/overlay/components/CommonParts/ComponentSwitcher/types";
import { WindAnimationLayerUpdateController } from "@/overlay/components/SmartForm/LayerForms/WindAnimationLayerForm";
import type {
  BackgroundMapLayer,
  BackgroundMapLayerCreate,
  BarbsLayer,
  BarbsLayerCreate,
  CartographicMap,
  MapId,
  WindAnimationLayer,
  WindAnimationLayerCreate,
} from "@mm/metx-workbench.meteomatics.com";
import { BarbsLayerCreateController, BarbsLayerUpdateController } from "./BarbsLayerForm/controller";
import {
  CartographicMaterialCreateController,
  CartographicMaterialLayerUpdateController,
} from "./CartographicMaterialLayerForm";
import { WindAnimationCreateController } from "./WindAnimationLayerForm/controller/WindAnimationCreateController";

/**
 * A common props to be passed to all the layer form controller component.
 */
export type LayerUpdateControllerProp<LayerType = LayerUnionWithAll> = {
  layer: LayerType;
  mapId: MapId;
  cartographicMap: CartographicMap;
};

/**
 * A mapping describing which layer uses which form controller.
 * Used by component switcher
 */
export const layerToUpdateControllerMapping: ComponentSwitchMapping<
  LayerUpdateControllerProp<LayerUnionWithAll>,
  LayerKind
> = {
  resolvePropsToComponentId: (props) => (props.layer.kind as LayerKind) ?? "",
  componentsById: {
    // Note: We can safely cast the props type here as this is guaranteeded
    // if the ComponentSwitcher is working properly.
    BarbsLayerDescription: (props) => (
      <BarbsLayerUpdateController {...(props as LayerUpdateControllerProp<BarbsLayer>)} />
    ),
    WindAnimationLayerDescription: (props) => (
      <WindAnimationLayerUpdateController {...(props as LayerUpdateControllerProp<WindAnimationLayer>)} />
    ),
    BackgroundMapDescription: (props) => (
      <CartographicMaterialLayerUpdateController {...(props as LayerUpdateControllerProp<BackgroundMapLayer>)} />
    ),
  },
};

export type LayerCreateControllerProp<LayerCreate extends LayerCreateUnion = LayerCreateUnion> = {
  selectedMapId: number;
  defaultPayload: LayerCreate;
  /**
   * Callback to be called
   * 1. after the user creates a new layer.
   * 2. when the user clicks on the abort button.
   */
  onDone: () => void;
};

/**
 * A mapping describing which layer uses which form controller.
 * Used by component switcher
 */
export const layerToCreateControllerMapping: ComponentSwitchMapping<LayerCreateControllerProp, LayerKind> = {
  resolvePropsToComponentId: (props) => props.defaultPayload.kind as LayerKind,
  componentsById: {
    // Note: We can safely cast the props type here as this is guaranteeded
    // if the ComponentSwitcher is working properly.
    BarbsLayerDescription: (props) => (
      <BarbsLayerCreateController {...(props as LayerCreateControllerProp<BarbsLayerCreate>)} />
    ),
    WindAnimationLayerDescription: (props) => (
      <WindAnimationCreateController {...(props as LayerCreateControllerProp<WindAnimationLayerCreate>)} />
    ),
    BackgroundMapDescription: (props) => (
      <CartographicMaterialCreateController {...(props as LayerCreateControllerProp<BackgroundMapLayerCreate>)} />
    ),
  },
};

import { useCallback, useState } from "react";

import Logger from "logging";
const logger = Logger.fromFilename(__filename);

export type PersistentSetState<T> = (value: T | ((val: T) => T), shouldPersist?: boolean) => void;

export function useLocalStorage<T>(
  key: string,
  validator: (maybeT: any) => boolean,
  initialValue: T,
  behaviour: "persist-on-request" | "persist-by-default",
): [T, PersistentSetState<T>] {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      if (item) {
        const itemJson = JSON.parse(item);

        if (validator(itemJson)) {
          return itemJson;
        }

        logger.warn(
          "persisted local storage <",
          key,
          "> was available, but invalid. May be stale data from a previous release.",
        );
      }

      return initialValue;
    } catch (error) {
      // If error also return initialValue
      logger.log(error);
      return initialValue;
    }
  });

  // Wraped inside useCallback for safe usage with hooks
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = useCallback(
    (value: T | ((val: T) => T), shouldPersist?: boolean) => {
      const currentShouldPersist = shouldPersist ?? behaviour === "persist-by-default";
      try {
        // Allow value to be a function so we have same API as useState
        const valueToStore = value instanceof Function ? value(storedValue) : value;
        // Save state
        setStoredValue(valueToStore);
        // Save to local storage
        if (currentShouldPersist) {
          if (valueToStore === null || valueToStore === undefined) {
            // Remove local storage entry, if it's undefined
            window.localStorage.removeItem(key);
          } else {
            window.localStorage.setItem(key, JSON.stringify(valueToStore));
          }
        }
      } catch (error) {
        // A more advanced implementation would handle the error case
        logger.error("failed to persist local storage <", key, ">", error);
      }
      // Removed storedValue from dependency array, because it causes infinite loop
      // TODO Refactor "persist" functionality to more readable and usable inside hooks
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setStoredValue, behaviour, key],
  );

  return [storedValue, setValue];
}

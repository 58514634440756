import { createTexture } from "@/layers/utility/webgl/createTexture";
import { type GpuContextId, gpuContextId } from "@/shader-compiler/id";

import Logger from "logging";
const logger = Logger.fromFilename(__filename);

type ContextItr = () => Generator<
  {
    inUse: boolean;
    gl: WebGLRenderingContext;
  },
  void,
  unknown
>;

let contextRegistry: ContextItr | null;

// TODO: this is here to avoid a circular dependency between Compositor and this Texture Module
export function setGlobalContextRegistry(v: ContextItr) {
  contextRegistry = v;
}
export abstract class Texture {
  protected _textures: Map<GpuContextId, WebGLTexture> = new Map();

  /**
   * Implementors should assume the correct texture is already bound and upload data to it.
   * Maybe setting other properties or generating mipmaps.
   */
  protected abstract upload(gl: WebGLRenderingContext, texture: WebGLTexture): void;

  // TODO: track GPU memory. mimaps produce an additional 33% overhead
  /**
   * Allocates a mipmapped texture for the given context. Garbage collection of the
   * context will automatically discard the cached copy of the texture. Calling `unload()`
   * is not required.
   *
   * @param gl
   * @returns
   *
   * @throws if texture allocation fails
   */
  texture(gl: WebGLRenderingContext) {
    const glId = gpuContextId(gl);
    const preallocatedTexture = this._textures.get(glId);

    if (preallocatedTexture != null) {
      return preallocatedTexture;
    }

    const texture = createTexture(gl);

    this._setTexture(glId, texture);

    // Disable feature enabled by mapbox
    const premultiplyAlpha = gl.getParameter(gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL);

    if (premultiplyAlpha) {
      gl.pixelStorei(gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL, false);
    }

    gl.bindTexture(gl.TEXTURE_2D, texture);
    this.upload(gl, texture);

    if (premultiplyAlpha) {
      gl.pixelStorei(gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL, premultiplyAlpha);
    }

    return texture;
  }

  private _setTexture(glId: GpuContextId, texture: WebGLTexture) {
    this._textures.set(glId, texture);
  }

  /**
   * Unload the tile from the given GPU context. If no context is given, the tile
   * is dropped from all contexts.
   *
   * @param gl only unload the tile from this context
   */
  unload(gl?: WebGLRenderingContext) {
    if (gl != null) {
      const glId = gpuContextId(gl);
      const texture = this._textures.get(glId);

      if (texture != null) {
        gl.deleteTexture(texture);
        this._textures.delete(glId);
      }
    } else if (contextRegistry != null) {
      for (const { gl } of contextRegistry()) {
        this.unload(gl);
      }

      // TODO: we assume that webgl context handle drops will automatically drop texture memory, test
      // if this is true. Otherwise we have to add a loop over all Textures when a map is dropped from
      // the compositor.
      if (this._textures.size > 0) {
        logger.unreachable("bug in gpu texture memory tracking, still allocated in unknown context");
      }
    } else {
      logger.error("no global webgl context registry available to unload gpu texture memory");
    }
  }
}

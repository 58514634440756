import type { GridDimension } from "@mm/api.meteomatics.com";
import { InterpolationMode } from "weather-parameter-utils";
import { createTexture } from "./createTexture";

/**
 * Create a texture that can be used as a render target.
 *
 * @param gl
 * @param param1
 * @param param2
 * @returns
 */
export function createRenderTexture(
  gl: WebGLRenderingContext,
  { width, height }: GridDimension,
  {
    internalFormat,
    format,
    type,
    interpolationMode,
  }: { internalFormat: number; format: number; type: number; interpolationMode: InterpolationMode },
) {
  const targetTexture = createTexture(gl);
  gl.bindTexture(gl.TEXTURE_2D, targetTexture);

  {
    // define size and format of level 0
    const level = 0;
    const border = 0;
    // no data is uploaded from the host
    const data = null;
    gl.texImage2D(gl.TEXTURE_2D, level, internalFormat, width, height, border, format, type, data);

    // set the filtering so we don't need mips
    switch (interpolationMode) {
      case InterpolationMode.IntervalOrRatio_NonLinear:
      case InterpolationMode.NominalOrOrdinal_NearestNeighbour:
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
        break;
      case InterpolationMode.IntervalOrRatio_Linear:
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
        break;
      default: {
        const _exhaustive: never = interpolationMode;
        return _exhaustive;
      }
    }

    // gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
  }

  return targetTexture;
}

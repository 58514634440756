import type { TileDataDescription } from "@/cache/SpatioTemporalTileCache/TileDataDescription";
import {
  type BinarySearchResult,
  BinarySearchResultKind,
  binarySearchSampledContinousSpace,
} from "@/utility/binarySearchSampledContinousSpace";
import { formatByteSize } from "@/utility/bytesize";
import type { CoordinateSystem } from "@mm/api.meteomatics.com";
import Logger from "logging";
import type { DateTime } from "luxon";
import type { TileFromGetter, TileGetter } from "../PVSTileService/TileGetters/TileGetter";
import { type Stats, jsxTable } from "../Stats";
import { type DataAge, type MultilevelLruEvictionResult, multilevel_lru_evict } from "../evictionStrategy";
import type { SliceDataDescription } from "./SliceDataDescription";
import { SpatialSubcache } from "./SpatialSubcache";
import { type SpatiotemporalTileCacheStats, emptySpatioTemporalTileCacheStats } from "./SpatioTemporalTileCacheStats";
import type { SpatiotemporalTileCache } from "./SpatiotemporalTileCache";
import type { TimeSlice } from "./TimeSlice";
import { TimeSliceIterator, TimeSliceIteratorDirection } from "./TimeSliceIterator";

const logger = Logger.fromFilename(__filename);

/**
 * The spatio-temporal tile cache sliced to a fixed weather parameter.
 * This should slice __all__ dimensions apart from the time and space.
 */
export class TemporalSubcache<
  TTileGetter extends TileGetter<any, any>,
  TTile extends TileFromGetter<TTileGetter> = TileFromGetter<TTileGetter>,
> implements Stats<SpatiotemporalTileCacheStats>
{
  public stats_: SpatiotemporalTileCacheStats = emptySpatioTemporalTileCacheStats();

  constructor(
    private root: SpatiotemporalTileCache<TTileGetter, TTile>,
    private sortedTimeSlices: TimeSlice<TTileGetter>[] = [],
  ) {}

  stats(): SpatiotemporalTileCacheStats {
    return this.stats_;
  }
  statsJsx(): JSX.Element {
    return jsxTable(this.stats_, formatByteSize);
  }

  label(): string {
    return "TemporalSubcache";
  }

  descJsx(): JSX.Element {
    return (
      <>
        <p>
          Replacement Policy is LRU. The maximal cache size limit is controlled globally across all temporal subcache
          instances by the root cache.
        </p>
        <p>
          <strong>Note:</strong> The shown statistics are only counted since the last complete eviction of the parameter
          / temporal subcache.
        </p>
      </>
    );
  }

  iterateIntoPast(from: DateTime): TimeSliceIterator<TTileGetter> {
    const firstIdx = this.closestPastTimeSlice(from) ?? Number.POSITIVE_INFINITY;
    return new TimeSliceIterator(this.sortedTimeSlices, TimeSliceIteratorDirection.FutureToPast, firstIdx);
  }

  iterateIntoFuture(from: DateTime): TimeSliceIterator<TTileGetter> {
    const firstIdx = this.closestFutureTimeSlice(from) ?? Number.NEGATIVE_INFINITY;
    return new TimeSliceIterator(this.sortedTimeSlices, TimeSliceIteratorDirection.PastToFuture, firstIdx);
  }

  iterateInto(from: DateTime, dir: TimeSliceIteratorDirection): TimeSliceIterator<TTileGetter> {
    switch (dir) {
      case TimeSliceIteratorDirection.FutureToPast:
        return this.iterateIntoPast(from);
      case TimeSliceIteratorDirection.PastToFuture:
        return this.iterateIntoFuture(from);
    }
  }

  iterate(from: DateTime): {
    intoPast: TimeSliceIterator<TTileGetter>;
    intoFuture: TimeSliceIterator<TTileGetter>;
  } {
    const slices = this.closestTimeSlices(from);

    let pastStartIdx = Number.NEGATIVE_INFINITY;
    let futureStartIdx = Number.POSITIVE_INFINITY;

    if (slices.kind !== BinarySearchResultKind.Empty) {
      pastStartIdx = slices.lowIdx;
      futureStartIdx = slices.highIdx;
    }

    return {
      intoPast: new TimeSliceIterator(this.sortedTimeSlices, TimeSliceIteratorDirection.FutureToPast, pastStartIdx),
      intoFuture: new TimeSliceIterator(this.sortedTimeSlices, TimeSliceIteratorDirection.PastToFuture, futureStartIdx),
    };
  }

  private createEmptyTimeslice<C extends CoordinateSystem>(
    tile: TileDataDescription | SliceDataDescription<C>,
  ): TimeSlice<TTileGetter> {
    return {
      seconds: tile.datetime.toSeconds(),
      datetime: tile.datetime,
      data: { tiles: new SpatialSubcache(this.root, this) },
    };
  }

  reduceMemoryUsageBy_lru(
    excessSize: number,
    maxLeastRecentlyUsed: number = Number.POSITIVE_INFINITY,
  ): MultilevelLruEvictionResult {
    const cacheSize = this.stats().estimatedMemoryConsumption;

    const reduceBySize = Math.min(excessSize, cacheSize);

    logger.debug(
      "evicting; cacheSize =",
      formatByteSize(cacheSize),
      "; excessSize =",
      formatByteSize(excessSize),
      "; reduceBySize =",
      formatByteSize(reduceBySize),
    );

    // TODO: since unload within the cache hierarchy is only called, when the child is a leaf/empty, recursing in `unload` is unnecessary
    // and could be a noop.

    // this unloads GPU state and marks array cells in `sortedTimeSlices` as unused
    const eviction = multilevel_lru_evict<TimeSlice<TTileGetter>, number>(
      reduceBySize,
      this.sortedTimeSlices,
      this.usage.bind(this),
      this.lru_evict.bind(this),
      logger,
      formatByteSize,
      maxLeastRecentlyUsed,
    );

    // remove unused array cells in `sortedTimeSlices`
    this.lru_compact();

    if (eviction.newLimitLeastRecentlyUsed != null) {
      // biome-ignore lint/style/noNonNullAssertion: Check if usage already exists
      this.stats_.usage!.leastRecentlyUsed = eviction.newLimitLeastRecentlyUsed;
    } else {
      // cache is empty: does not have a single loaded tile, but might have pending or failed tiles
      this.stats_.usage = null;
    }

    return eviction;
  }

  private lru_evict(
    line: TimeSlice<TTileGetter>,
    idx: number,
    excessSize: number,
    maxLeastRecentlyUsed: number,
  ): MultilevelLruEvictionResult {
    const eviction: MultilevelLruEvictionResult = line.data.tiles.reduceMemoryUsageBy_lru(
      excessSize,
      maxLeastRecentlyUsed,
    );

    if (eviction.canUnload) {
      // unload keeping indices -- which are used as tags -- constant.
      // we compact afterwards. See
      this.unloadTimeslice(line, idx);
    }

    return eviction;
  }

  private lru_compact() {
    // eviction previously marked evicted entries as undefined, remove them.
    const compacted = this.sortedTimeSlices.filter((v) => v != null);
    this.sortedTimeSlices = compacted;
  }

  private usage(cacheLine: TimeSlice<TTileGetter>): DataAge | null {
    return cacheLine.data.tiles.stats().usage;
  }

  /**
   * Unload a timeslice.
   *
   * You MUST either set `compact` to true, or call `lru_compact` before calling any other method.
   */
  private unloadTimeslice(cacheLine: TimeSlice<TTileGetter>, idx: number, compact = false) {
    cacheLine.data.tiles.unload();
    if (compact) {
      this.sortedTimeSlices.splice(idx, 1);
    } else {
      // TODO: this subverts the typesystem and forces a value to undefined without the types reflecting this.
      delete this.sortedTimeSlices[idx];
    }
  }

  unload() {
    for (const timeSlice of this.sortedTimeSlices) {
      timeSlice.data.tiles.unload();
    }
  }

  getTimeSlice<C extends CoordinateSystem>(
    tile: TileDataDescription | SliceDataDescription<C>,
  ): TimeSlice<TTileGetter> {
    const match = this.closestTimeSlices(tile.datetime);

    switch (match.kind) {
      case BinarySearchResultKind.Exact:
        return this.sortedTimeSlices[match.lowIdx];
      case BinarySearchResultKind.Empty:
      case BinarySearchResultKind.Overflow: {
        const timeslice = this.createEmptyTimeslice(tile);
        this.sortedTimeSlices.push(timeslice);
        return timeslice;
      }
      case BinarySearchResultKind.Underflow: {
        const timeslice = this.createEmptyTimeslice(tile);
        this.sortedTimeSlices.unshift(timeslice);
        return timeslice;
      }
      case BinarySearchResultKind.Between: {
        const timeslice = this.createEmptyTimeslice(tile);
        this.sortedTimeSlices.splice(match.highIdx, 0, timeslice);
        return timeslice;
      }
    }
  }

  /**
   * Get timeslices for the given record of tiles
   */
  getTimeSlices<R extends Record<string, TileDataDescription>>(tiles: R): Record<keyof R, TimeSlice<TTileGetter>> {
    const timeslices: Record<string, TimeSlice<TTileGetter>> = {};
    for (const [label, tile] of Object.entries(tiles)) {
      timeslices[label] = this.getTimeSlice(tile);
    }

    return timeslices as Record<keyof R, TimeSlice<TTileGetter>>;
  }

  /**
   * Find the two closest available timeslices for an arbitrary point in time.
   *
   * @param from an arbitrary point in time
   *
   * @returns two closest time slices, which could be the same tile slice in case of an overflow, underflow
   * or if the requested time slices was sampled.
   */
  private closestTimeSlices(from: DateTime): BinarySearchResult {
    const seconds = from.toSeconds();
    return binarySearchSampledContinousSpace<{ seconds: number }>(
      this.sortedTimeSlices,
      { seconds },
      (a, b) => a.seconds === b.seconds,
      (a, b) => a.seconds < b.seconds,
    );
  }

  closestPastTimeSlice(from: DateTime): number | null {
    const match = this.closestTimeSlices(from);
    return match.kind === BinarySearchResultKind.Empty ? null : match.lowIdx;
  }

  closestFutureTimeSlice(from: DateTime): number | null {
    const match = this.closestTimeSlices(from);
    return match.kind === BinarySearchResultKind.Empty ? null : match.highIdx;
  }

  createCacheId(desc: TileDataDescription) {
    return desc.datetime.toSeconds();
  }
}

export type FrameQueueStatus = {
  left: number;
};

// TODO Remove this when Safari release requestIdleCallback support
// @ts-ignore
const requestIdleCallbackPolyFill = (cb: IdleRequestCallback) => {
  // @ts-ignore
  if (window.requestIdleCallback) {
    // @ts-ignore
    window.requestIdleCallback(cb);
  } else {
    setTimeout(cb, 100);
  }
};

// TODO Rename to CancelablePromiseQueue and move outside of video-generator
export class FrameQueue<T> {
  isCanceled = false;

  chain: ((index: number, lenght: number) => Promise<T>)[] = [];
  statusListener?: (isActive: boolean) => void;

  private _onCancel?: () => void;

  private _totalTaskNum = 0;

  constructor(statusListener?: (isActive: boolean) => void) {
    this.statusListener = statusListener;
  }

  // Recursive function to resolve promises in sequential order
  // TODO: Implement error handling
  private async processPromiseChain(
    promiseChain: ((index: number, lenght: number) => Promise<T>)[],
    callback: (res: T[]) => void,
    results: T[] = [],
  ) {
    if (this.isCanceled) {
      callback([]);
      return;
    }
    if (!promiseChain.length) {
      callback(results);
      return;
    }

    const index = this._totalTaskNum - promiseChain.length;
    const promise = promiseChain.shift();

    try {
      if (promise) {
        const result = await promise(index, this._totalTaskNum);
        results.push(result);
      }
    } catch (_e) {
      // Ignore for now
    }
    requestIdleCallbackPolyFill(() => this.processPromiseChain(promiseChain, callback, results));
  }

  createQueue(fns: ((index: number, totalTaskNum: number) => Promise<T>)[]) {
    this._totalTaskNum = fns.length;
    this.chain = fns;
  }

  private processQueue(): Promise<{ results: T[]; wasCanceled: boolean }> {
    return new Promise<{ results: T[]; wasCanceled: boolean }>((resolve) => {
      // Set up a callback that gets callced upon cancellation.
      // This allows us to immediately resolve this function when the queue is cancelled
      this._onCancel = () => {
        resolve({ results: [], wasCanceled: true });
        this.statusListener?.(false);
      };

      // Start processing all the given promises
      this.processPromiseChain(this.chain, (results) => {
        resolve({ results, wasCanceled: false });
      });
    });
  }

  async process(): Promise<{ results: T[]; wasCanceled: boolean }> {
    this.isCanceled = false;
    this.statusListener?.(true);
    const res = await this.processQueue();
    this.statusListener?.(false);
    return res;
  }

  cancel() {
    this.isCanceled = true;
    this._onCancel?.();
    this._onCancel = undefined;
  }
}

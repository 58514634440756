import type { DrawCustomMode } from "@mapbox/mapbox-gl-draw";

export enum DrawingTypes {
  radius = "radius",
  line = "line",
}

export enum DrawModes {
  idle = "idle",
  draw_radius = "draw_radius",
}

export type AvailableDrawModes = {
  [DrawModes.idle]: DrawCustomMode;
  [DrawModes.draw_radius]: DrawCustomMode;
};

export type AvailableDrawModeName = keyof AvailableDrawModes;

import { PNGTile } from "@/cache/PVSTileService/TileGetters/WMSTileGetter";
import {
  type AvailableTimeframeRequest,
  AvailableTimeframeResponse,
  type ColorMapRequest,
  ColorMapResponse,
  type CoordinateSystem,
  type GeoJSONFeatureCollection,
  type GridRequest,
  GridRequest_toTransferable,
  type InitialTimeRequest,
  InitialTimeResponse,
  type IsoLinesRequest,
  IsoLinesRequest_toTransferable,
  type JSONResponseBody,
  type LightningListRequest,
  LightningListRequest_toTransferable,
  type MetarsRequest,
  MetarsRequest_toTransferable,
  type PixelGridRequest,
  PixelGridRequest_toTransferable,
  type PlotKind,
  PlotRequest_toTransferable,
  type PointRequest,
  PointRequest_toTransferable,
  type PolygonRequest,
  PolygonRequest_toTransferable,
  type VectorLayerStyleRequest,
  type VectorTileRequestUnion,
  VectorTileRequest_toTransferable,
  type WeatherFrontsRequest,
  WeatherFrontsRequest_toTransferable,
  type WfsRequest,
  WfsRequest_toTransferable,
} from "@mm/api.meteomatics.com";
import { MeteomaticsApiError_fromTransferable, isTransferableApiError } from "@mm/api.meteomatics.com/lib/error";
import type { LegendRequest } from "@mm/api.meteomatics.com/lib/models/LegendRequest";
import { type MetarsResponse, metarsFromTransferable } from "@mm/api.meteomatics.com/lib/models/MetarsResponse";
import type { UserStatsResponse } from "@mm/api.meteomatics.com/lib/models/UserStats";
import type { WfsCapabilities } from "@mm/api.meteomatics.com/models/WfsCapabilities";
import type { Style } from "mapbox-gl";
import { ThreadPool } from "threading-lib";
import * as weatherParameter from "weather-parameter-utils";
import type { Metadata } from "../Metadata";
import type { ApiQueryAbortDecisionData } from "./ApiQueryAbortDecisionData";
import {
  type ApiQueryPool_MsgToPool,
  type ApiQueryPool_MsgToWorkerThread,
  type MsgAnswer_UpdateAbortController,
  type MsgAnswer_UpdateAuth,
  type MsgAnswer_UpdateHardParallelLimit,
  isFailureMsg,
} from "./ApiQueryThreadPoolMsg";
import { type RetryState, RetryState_toTransferable } from "./RetryState";

// TODO: Move this to threads folder
export class ApiQueryThreadPool extends ThreadPool<ApiQueryPool_MsgToWorkerThread, ApiQueryPool_MsgToPool> {
  constructor() {
    // TODO: we currently always run on a single thread since our connection manager
    // has no idea how to manage the hard parallel limit with multiple threads.
    //
    // A simple, suboptimal solution would just assign a subset of connections
    // to each thread.
    super(1);
  }

  newThread(threadId: number): Worker {
    return new Worker(new URL("./ApiQueryThreadPool.worker.ts", import.meta.url), { type: "module" });
  }

  multiplex(
    query: { resolve: (value: any) => void; reject: (reason?: any) => void },
    msg: ApiQueryPool_MsgToPool,
  ): void {
    if (isFailureMsg(msg)) {
      if (isTransferableApiError(msg.payload)) {
        query.reject(MeteomaticsApiError_fromTransferable(msg.payload));
      } else {
        query.reject(msg.payload);
      }
      return;
    }

    switch (msg.kind) {
      case "MsgAnswer_ChangeToBetaAPI":
        query.resolve(msg.payload);
        break;
      case "MsgAnswer_UpdateAuth":
        query.resolve(msg.payload);
        break;
      case "MsgAnswer_UpdateHardParallelLimit":
        query.resolve(msg.payload);
        break;
      case "MsgAnswer_GetUserStats":
        query.resolve(msg.payload.userStats);
        break;
      // case "MsgAnswer_GetBinaryTile":
      //   {
      //     // TODO: copy back retry state? error handling!
      //     // TODO: Make sure the spatial interplation mode is consistent with all parameters.
      //     const interpolationMode = weatherParameter.getSpatialInterpolationMode(msg.payload.parameters[0]);
      //     const binaryTile = new BinaryTile({
      //       ...msg.payload.tile,
      //       interpolationMode,
      //     });

      //     query.resolve(binaryTile);
      //   }
      //   break;
      case "MsgAnswer_GetWMSTile":
        {
          const interpolationMode = weatherParameter.getSpatialInterpolationMode(msg.payload.parameter);
          const tile = msg.payload.bitmap;
          query.resolve(
            new PNGTile({ ...tile, width: tile.payload.width, height: tile.payload.height, interpolationMode }),
          );
        }
        break;
      case "MsgAnswer_GetVectorTile": {
        query.resolve(msg.payload.data);
        break;
      }
      case "MsgAnswer_GetVectorLayerStyle": {
        query.resolve(msg.payload.style);
        break;
      }
      case "MsgAnswer_GetIsoLines": {
        query.resolve(msg.payload.isoLinesResponse);
        break;
      }
      case "MsgAnswer_GetWeatherFronts": {
        query.resolve(msg.payload.weatherFrontsResponse);
        break;
      }
      case "MsgAnswer_GetLightningList": {
        query.resolve(msg.payload.lightningListResponse);
        break;
      }
      case "MsgAnswer_GetPointJSON": {
        query.resolve(msg.payload.jsonApiResponse);

        break;
      }
      case "MsgAnswer_GetPolygonJSON": {
        query.resolve(msg.payload.jsonApiResponse);
        break;
      }
      case "MsgAnswer_GetJSON":
        query.resolve(msg.payload.jsonApiResponse);
        break;
      case "MsgAnswer_GetAvailableTimeframe": {
        const timeframes = AvailableTimeframeResponse.fromTransferable(msg.payload.timeframes);
        query.resolve(timeframes);
        break;
      }
      case "MsgAnswer_GetInitTime": {
        const initTimes = InitialTimeResponse.fromTransferable(msg.payload.initTimes);
        query.resolve(initTimes);
        break;
      }
      case "MsgAnswer_GetColorMap": {
        const colormap = ColorMapResponse.fromTransferable(msg.payload.colormap);
        query.resolve(colormap);
        break;
      }
      case "MsgAnswer_GetLegend": {
        query.resolve(msg.payload.legend);
        break;
      }
      case "MsgAnswer_UpdateAbortController":
        query.resolve(msg.payload);
        break;
      case "MsgAnswer_GetWfs":
        query.resolve(msg.payload.wfsApiResponseAsJSON);
        break;
      case "MsgAnswer_GetWfsCapabilities":
        query.resolve(msg.payload.wfsApiResponseAsJSON);
        break;
      case "MsgAnswer_GetPlot":
        query.resolve(msg.payload.response);
        break;
      case "MsgAnswer_GetMetars":
        query.resolve(metarsFromTransferable(msg.payload));
        break;
      default: {
        const _exhaustive: never = msg;
        // biome-ignore lint/correctness/noVoidTypeReturn: Unknown
        return _exhaustive;
      }
    }
    query.resolve(msg);
  }

  updateAuthentication(token: string | null) {
    return this.send<MsgAnswer_UpdateAuth>({ kind: "Msg_UpdateAuth", payload: { token } });
  }

  updateHardParallelLimit(newHardParallelLimit: number) {
    return this.send<MsgAnswer_UpdateHardParallelLimit>({
      kind: "Msg_UpdateHardParallelLimit",
      payload: { newHardParallelLimit },
    });
  }

  updateAbortController(abortDecisionDataSet: ApiQueryAbortDecisionData[]) {
    return this.send<MsgAnswer_UpdateAbortController>({
      kind: "Msg_UpdateAbortController",
      payload: { abortDecisionDataSet },
    });
  }

  getUserStats() {
    return this.send<UserStatsResponse>({ kind: "Msg_GetUserStats", payload: void 0 });
  }

  // getBinaryTile(
  //   tileGeometry_: TileGeometry,
  //   request_: GridRequest<CoordinateSystem.EPSG3857>,
  //   requestRetryState_: RetryState
  // ) {
  //   const request = GridRequest_toTransferable(request_);
  //   const requestRetryState = RetryState_toTransferable(requestRetryState_);
  //   const tileGeometry = tileGeometry_.toTransferable();
  //   return this.send<BinaryTile>({ kind: "Msg_GetBinaryTile", payload: { request, requestRetryState, tileGeometry } });
  // }

  getWMSTile(request_: PixelGridRequest<CoordinateSystem>, requestRetryState_: RetryState) {
    const request = PixelGridRequest_toTransferable(request_);
    const requestRetryState = RetryState_toTransferable(requestRetryState_);
    return this.send<PNGTile>({ kind: "Msg_GetWMSTile", payload: { request, requestRetryState } });
  }

  abortRequestByUrl(urls: string[]) {
    // Only trigger abort, if there is any url present
    if (urls.length) {
      return this.send({ kind: "Msg_AbortRequests", payload: { urls } });
    }
  }

  getVectorTile(request_: VectorTileRequestUnion, requestRetryState_: RetryState) {
    const request = VectorTileRequest_toTransferable(request_);
    const requestRetryState = RetryState_toTransferable(requestRetryState_);
    return this.send<ArrayBuffer>({ kind: "Msg_GetVectorTile", payload: { request, requestRetryState } });
  }

  getVectorLayerStyle(request: VectorLayerStyleRequest, requestRetryState_: RetryState) {
    const requestRetryState = RetryState_toTransferable(requestRetryState_);
    return this.send<Style>({ kind: "Msg_GetVectorLayerStyle", payload: { request, requestRetryState } });
  }

  getWfs(request_: WfsRequest<CoordinateSystem.WGS84>, requestRetryState_: RetryState) {
    const request = WfsRequest_toTransferable(request_);
    const requestRetryState = RetryState_toTransferable(requestRetryState_);
    return this.send<GeoJSONFeatureCollection>({
      kind: "Msg_GetWfs_GetFeature",
      payload: { request, requestRetryState },
    });
  }

  getWfsCapabilitieas(request_: WfsRequest<CoordinateSystem.WGS84>, requestRetryState_: RetryState) {
    const request = WfsRequest_toTransferable(request_);
    const requestRetryState = RetryState_toTransferable(requestRetryState_);
    return this.send<WfsCapabilities>({
      kind: "Msg_GetWfs_GetCapabilities",
      payload: { request, requestRetryState },
    });
  }

  getIsoLines(request_: IsoLinesRequest, requestRetryState_: RetryState) {
    const request = IsoLinesRequest_toTransferable(request_);
    const requestRetryState = RetryState_toTransferable(requestRetryState_);
    return this.send<GeoJSONFeatureCollection>({
      kind: "Msg_GetIsoLines",
      payload: { request, requestRetryState },
    });
  }

  getWeatherFronts(request_: WeatherFrontsRequest, requestRetryState_: RetryState) {
    const request = WeatherFrontsRequest_toTransferable(request_);
    const requestRetryState = RetryState_toTransferable(requestRetryState_);
    return this.send<GeoJSONFeatureCollection>({
      kind: "Msg_GetWeatherFronts",
      payload: { request, requestRetryState },
    });
  }

  getLightningList(request_: LightningListRequest<CoordinateSystem.WGS84>, requestRetryState_: RetryState) {
    const request = LightningListRequest_toTransferable(request_);
    const requestRetryState = RetryState_toTransferable(requestRetryState_);
    return this.send<GeoJSONFeatureCollection>({
      kind: "Msg_GetLightningList",
      payload: { request, requestRetryState },
    });
  }

  getJSON(request_: GridRequest<CoordinateSystem.WGS84>, requestRetryState_: RetryState) {
    const request = GridRequest_toTransferable(request_);
    const requestRetryState = RetryState_toTransferable(requestRetryState_);
    return this.send<JSONResponseBody>({ kind: "Msg_GetJSON", payload: { request, requestRetryState } });
  }

  GetPointJSON(
    request_: PointRequest<CoordinateSystem.WGS84>,
    requestRetryState_: RetryState,
    metadata: Metadata = {},
  ) {
    const request = PointRequest_toTransferable(request_);
    const requestRetryState = RetryState_toTransferable(requestRetryState_);
    return this.send<JSONResponseBody>({ kind: "Msg_GetPointJSON", payload: { request, requestRetryState, metadata } });
  }

  getPolygonJson(
    request_: PolygonRequest<CoordinateSystem.WGS84>,
    requestRetryState_: RetryState,
    metadata: Metadata = {},
  ) {
    const request = PolygonRequest_toTransferable(request_);
    const requestRetryState = RetryState_toTransferable(requestRetryState_);
    return this.send<JSONResponseBody>({
      kind: "Msg_GetPolygonJSON",
      payload: { request, requestRetryState, metadata },
    });
  }

  getPlotData<T>(kind: PlotKind, request_: T, requestRetryState_: RetryState) {
    const request = PlotRequest_toTransferable<T>(kind, request_);
    const requestRetryState = RetryState_toTransferable(requestRetryState_);
    return this.send({ kind: "Msg_GetPlot", payload: { request, requestRetryState } });
  }

  getLegend(request: LegendRequest): Promise<string> {
    return this.send<string>({ kind: "Msg_GetLegend", payload: { request } });
  }
  getColorMap(request: ColorMapRequest): Promise<ColorMapResponse> {
    return this.send<ColorMapResponse>({ kind: "Msg_GetColorMap", payload: { request } });
  }

  getAvailableTimeframe(request: AvailableTimeframeRequest) {
    return this.send<AvailableTimeframeResponse>({ kind: "Msg_GetAvailableTimeframe", payload: { request } });
  }

  getInitTime(request: InitialTimeRequest) {
    return this.send<InitialTimeResponse>({ kind: "Msg_GetInitTime", payload: { request } });
  }

  getMetars(req: MetarsRequest) {
    const request = MetarsRequest_toTransferable(req);
    return this.send<MetarsResponse>({ kind: "Msg_GetMetars", payload: { request } });
  }

  switchApiEndpoint(): Promise<any> {
    return this.send({
      kind: "Msg_ChangeToBetaAPI",
      payload: {
        apiUrls: ["https://api-beta.meteomatics.com"],
      },
    });
  }
}

export const apiThreadPool = new ApiQueryThreadPool();

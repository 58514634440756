import { classNames } from "@/utility/jsx";
import { type ReactNode, forwardRef } from "react";
import type { ContextMenuActions } from "./constants";
import styles from "./style.module.scss";

export const MenuItem = forwardRef<
  HTMLButtonElement,
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    icon: string | ReactNode;
    label: string;
    action: ContextMenuActions;
    disabled?: boolean;
  }
>(({ label, icon, disabled, action, ...props }, ref) => {
  return (
    <button
      {...props}
      className={styles.menu__item}
      ref={ref}
      role="menuitem"
      disabled={disabled}
      data-testid={"context-menu-item"}
    >
      <span className={classNames("material-icons", styles.menu__item__icon)}>{icon}</span>
      {label}
    </button>
  );
});

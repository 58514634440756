import { type PropsWithChildren, createContext } from "react";
import type { InterpreterFrom } from "xstate";
import { type MapDrawingMasterMachine, globalMapDrawingService } from "./machine";

export const MapDrawingMasterMachineContext = createContext<StateMachineContext>({
  mapDrawingService: {} as InterpreterFrom<MapDrawingMasterMachine>,
});

type StateMachineContext = {
  mapDrawingService: InterpreterFrom<MapDrawingMasterMachine>;
};

export const MapDrawingMasterMachineProvider = (props: PropsWithChildren<any>) => {
  return (
    <MapDrawingMasterMachineContext.Provider value={{ mapDrawingService: globalMapDrawingService }}>
      {props.children}
    </MapDrawingMasterMachineContext.Provider>
  );
};

import * as windowManager from "@/overlay/components/window";
import type { WindowIdentityProps } from "@/overlay/components/window";
import { t } from "@lingui/macro";

/**
 * A placeholder window while lazy loading windows
 */
export default function LoadingWindow(props: WindowIdentityProps<any>) {
  return (
    <windowManager.Window kind={"Loading"} {...props}>
      <windowManager.WindowSection>
        <mm-loader />
      </windowManager.WindowSection>
    </windowManager.Window>
  );
}

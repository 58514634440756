import type { MapboxGeoJSONFeature, Style } from "mapbox-gl";
import { BaseSubLayer } from "./BaseSubLayer";
import { ICON_LAYERS, INTERACTIVE_LAYERS_NAMES, TEXT_LAYERS, composeTafLayers } from "./tafLayerCompositor";
import { createTafTooltip } from "./tooltips/tafTooltip";
import { convertRange } from "./utils";

export class TafLayer extends BaseSubLayer {
  createLayers(_style: Style): void {
    const map = this.scene.getMapboxMap();
    const tafVectorLayers = composeTafLayers(`${this.uuid}_tiles`, this.initialProps.text_size);
    for (const layer of tafVectorLayers) {
      map.addLayer(layer, this.beforeLayerId);
      this.layers.push(layer.id);
    }

    const interactiveLayers = INTERACTIVE_LAYERS_NAMES.map((layerName) => `${layerName}_${this.uuid}_tiles`);

    this.setEventListeners(interactiveLayers);
  }

  createTooltip(feature: MapboxGeoJSONFeature): string {
    return createTafTooltip(feature.properties);
  }

  updateSize(size: number): void {
    for (const [name, { minSize, maxSize }] of Object.entries(ICON_LAYERS)) {
      const newValue = convertRange(size, [10, 34], [minSize, maxSize]);
      this.updateMapboxLayoutProperties(`${name}_${this.uuid}_tiles`, { "icon-size": newValue });
    }
    for (const [name, { minSize, maxSize }] of Object.entries(TEXT_LAYERS)) {
      const newValue = convertRange(size, [10, 34], [minSize, maxSize]);
      this.updateMapboxLayoutProperties(`${name}_${this.uuid}_tiles`, { "text-size": newValue });
    }
  }
}

import type {
  AnimationCtx,
  AutoRefreshCtx,
  IdleCtx,
  StoredTimePointCtx,
  TimeContextUnion,
  TimePointCtx,
} from "@/models/time-control/timeStateCtxTypes";
import type { TimeEventUnion } from "@/models/time-control/timeStateEvents";
import { GuiTimeZone } from "@mm/metx-workbench.meteomatics.com";
import { type StateFrom, type StateMachine, createMachine } from "xstate";
import {
  clearAnimationConfig,
  constructAnimationConfig,
  constructDefaultAnimationConfig,
  recomputeDisplayTime,
  recomputeDisplayTimeUponInitialization,
  resetToIdleMode,
  updateDisplayTime,
  updateGuiTimezone,
} from "./timeStateActions";
import { defaultTimeCtx, defaultTimeStateVal } from "./timeStateConsts";
import { conditionalTransition } from "./timeStateUtils";

// Typing for the state and context shape:
// https://xstate.js.org/docs/guides/typescript.html#typestates
type TimeTypestate =
  | {
      value: "IDLE";
      context: IdleCtx;
    }
  | {
      value: "TIME_POINT";
      context: TimePointCtx;
    }
  | {
      value: { TIME_POINT: "DEFAULT_TIME" };
      context: TimePointCtx;
    }
  | {
      value: { TIME_POINT: "STORED_TIME" };
      context: StoredTimePointCtx;
    }
  | {
      value: "ANIMATION";
      context: AnimationCtx;
    }
  | {
      value: "AUTO_REFRESH";
      context: AutoRefreshCtx;
    };

const idleState = "IDLE";
const idleCtx: IdleCtx = {
  id_profile: null,
  displayTime: null,
  timezone: GuiTimeZone.utc,
  modeOpts: null,
  meta: {
    timeStateApi: null,
  },
};

export type TimeStateMachine = StateMachine<TimeContextUnion, any, TimeEventUnion, TimeTypestate>;
export type TimeState = StateFrom<TimeStateMachine>;

// Note: Keep this file as minimal as possible, so we can import this file's code
// to an online visualizer directly for development purposes (https://stately.ai/)
export const createTimeStateMachine = (
  initialCtx: TimeContextUnion = idleCtx,
  initialStateName: string = idleState,
): TimeStateMachine =>
  createMachine<TimeContextUnion, TimeEventUnion, TimeTypestate>({
    // Note: By using the XState VSCode extension, you can view the diagram directly on IDE.
    // The following comment value is auto generated by the extension
    // to store the diagram layout.
    /** @xstate-layout N4IgpgJg5mDOIC5QBUCWBbMBlALgQxzAFk8BjAC1QDswA6NTABQHtqcBiAJzjB2WYCSEADbFmEMAG0ADAF1EoAA7NYqHKmZUFIAB6IAtABYArLQCcAJgAc06dbvHj0gGxWANCACeiMwHZahlau0obOjgCMFs6+FgC+sR4M2PiEJBTUdEksbOywvACCVBgEGlRE4lJy2sqq6praegjh0uFm5iFmgWbGoWbuXogWLQG+DiZm0la+gfGJGMkExGSUNPTz2VQceTj5AK44zABKYABm3LDk5RIy8kggNWqlDQbhxuG0LuHOoZPSvq3hADMHm8CAsFkMAQsgLM3UBgOMFj8MVmICSuEWaRWmXWrE27F2iggiwAIqhYIphHhPEkbtUVI96ndGv9AbRvoiggjDP9DCCDFZTD8eU4wm9Eb5UeiUkt0qssniOITiYQAOK7VBJABemkqtyUDLqWmZiEM0ja0Wc5rs0mMvitfIGCH0flobxhthiVnBgOczil8wxqWWGTWTEVtBJpzwu2EOA2W14uGY3AgCauevptSeJoQCIstHC4W94UMxfCvmMVmBTuhpimdimhmsvsMoQDmCDsuxYbACdoydTCpy22HmwzdLuDyNzwQ038pZarJtMJroIspdoSMBRZaX0MvvCHYWwbldEKxSNBKJiwv6BKmgAwpoTqgoJODdmmaBGkCebR4RaZxAT+PoJmcfk80sQtAR5VoEV8KxqztY8uyxUM7wfKhrxVMB1U1eYdRoD97kNHMf0QIE7C3YxYUmKxIgsXxAX6dcy1oGw3m+L5SycJDUJldDVkwq9zl4fghFECcqinMjv10RBYMhWEbGmMJwRcSDDD6AJvjLaszD9HkogEzEQ2E-YjlOc5yBwxZ8O1XUSOnciFOdQI2QhKIWKA+jHVBYDnELYwwm+f4bG9KxTNPHs9gOY4zjgWyxL4QQRDEa4ZM-RljQo51RkhIEmMQ4xWyGcJIN8Mw2R3bkPNGUs4gSNFA0E8zz0shKbPYHRYBlWg8BOQhOAACmaWwAEp2GlMyz1oOKrMSi5nLk3K3KMNpYWcPw+gsN5wT2yCgjacKolhWiXAReJmqoCp4DuGaYoyLMctnfRKwLIrfBKsqWkg-Ri2UyxYOApEWmhaLu1DSSwBemdc30faYL+KwRUFbS10GNprERK1zRMIFvsMSGhJxcM2Dh1zGhAwriw3fSKyrTGEFccwGL9Biph6Uqouax6oflXE2EjaNY3jRVKfkxoq1MWDASq0IehY5nXiCn4i3lpD4WJvnWtmnsxxwAcDiHIXNkltbparXSkTMVWmehSCkVMWEVMFRwJm9f1dc7Nq5pE1yXKlyjS38GxtcsIFYKCSDYNMUspkFb6QgYpq5l9-WMM66ykott6hisAC3lRlobBcIILEgu1C55UIIRiP47CPa6gA */
    id: "TimeStateMachine",
    initial: initialStateName,
    states: {
      // IDLE state represents the state before the state machine gets initialized with API data,
      // and after the user navigate back to the dashboard page where time state goes back to idle.
      IDLE: {
        entry: [resetToIdleMode],
        on: {
          initializeStateMachine: conditionalTransition({
            allowedStates: ["TIME_POINT.DEFAULT_TIME", "TIME_POINT.STORED_TIME", "AUTO_REFRESH"],
            fallback: {
              state: defaultTimeStateVal,
              ctx: defaultTimeCtx,
            },
          }),
        },
      },
      TIME_POINT: {
        initial: "DEFAULT_TIME",
        entry: [recomputeDisplayTimeUponInitialization],
        states: {
          hist: {
            type: "history",
            history: "shallow",
          },
          DEFAULT_TIME: {
            on: {
              setStoredPointMode: {
                target: "STORED_TIME",
              },
            },
          },
          STORED_TIME: {
            on: {
              setTimePointMode: {
                target: "DEFAULT_TIME",
              },
            },
          },
        },
        on: {
          resetToIdleMode: {
            target: "IDLE",
          },
          setAnimationMode: {
            target: "ANIMATION",
            actions: [constructDefaultAnimationConfig],
          },
          setAutoRefreshMode: {
            target: "AUTO_REFRESH",
          },
          updateDisplayTime: {
            internal: true,
            actions: [updateDisplayTime],
          },
          updateGuiTimezone: {
            internal: true,
            actions: [updateGuiTimezone],
          },
        },
      },

      ANIMATION: {
        on: {
          updateAnimationConfig: {
            internal: true,
            // TODO: Maybe we can strictly type different context shapes using this:
            // https://xstate.js.org/docs/guides/models.html#createmodel
            actions: [constructAnimationConfig],
          },
          setTimePointMode: {
            target: "TIME_POINT.hist",
            actions: [clearAnimationConfig],
          },
          updateDisplayTime: {
            internal: true,
            actions: [updateDisplayTime],
          },
          updateGuiTimezone: {
            internal: true,
            actions: [updateGuiTimezone],
          },
          resetToIdleMode: {
            target: "IDLE",
          },
        },
      },

      AUTO_REFRESH: {
        on: {
          setTimePointMode: {
            target: "TIME_POINT.hist",
          },
          updateGuiTimezone: {
            internal: true,
            actions: [updateGuiTimezone],
          },
          resetToIdleMode: {
            target: "IDLE",
          },
        },
        // Delayed self-transition.
        // We refresh the display time on entry to the state,
        // and we trigger the re-entry periodically to achive auto refresh.
        // Ref: https://xstate.js.org/docs/guides/delays.html#delayed-transitions
        entry: [recomputeDisplayTime],
        after: {
          10000: { target: "AUTO_REFRESH" },
        },
      },
    },
    schema: {
      context: {} as TimeContextUnion,
      events: {} as TimeEventUnion,
    },
    context: initialCtx,
    predictableActionArguments: true,
    preserveActionOrder: true,
  });

export const defaultTimeStateMachine = createTimeStateMachine();

import { usePlaybackState } from "@/animation";
import { usePrevious } from "@/utility/customHooks";
import type { DateTime } from "luxon";
import { useEffect, useState } from "react";

// Workaround hook to normalize current time value!
// Right now value changes like crazy, causing lot of unwanted redraws and fetch requests
// Playback state refactoring is needed to be able to get rid of this hook
export function useNormalizeCurrentTime() {
  const playback = usePlaybackState();
  const currentTime = playback?.currentTime;

  const [normalizeCurrentTime, setNormalizedCurrentTime] = useState<DateTime | undefined>(currentTime);

  const previousCurrentTime = usePrevious<DateTime | undefined>(currentTime);

  useEffect(() => {
    if (previousCurrentTime?.toMillis() !== currentTime?.toMillis()) {
      setNormalizedCurrentTime(currentTime);
    }
  }, [currentTime, previousCurrentTime]);

  return {
    currentTime: normalizeCurrentTime,
  };
}

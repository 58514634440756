import type { BackgroundMapCustomOptions, BackgroundMapLayer, GuiTimeZone } from "@mm/metx-workbench.meteomatics.com";
import type mapboxgl from "mapbox-gl";
import type { Expression, FilterOptions, GeoJSONSourceRaw, LinePaint, StyleFunction, SymbolLayer } from "mapbox-gl";

import { LayerBase } from "../LayerBase";

import { networkCaches } from "@/cache/GlobalCache";
import { backgroundLayerConfig } from "@/constants/layerConfigAttributes";
import {
  Language,
  MaptilerBorderId,
  MaptilerEuropeFIRId,
  groundStyles,
  maptilerUrl,
  onlyBorderStyleLayer,
} from "@/constants/maptilerStyleOptions";
import { emptyFeatureCollection } from "@/geojson";
import Logger from "logging";
import type { DateTime } from "luxon";
import type { SceneLayerApi } from "../SceneLayerApi";

const logger = Logger.fromFilename(__filename);

function layerHasSource(v: any): v is { source: string } {
  return Object.hasOwn(v, "source") && typeof v.source === "string";
}

const backgroundPaintProperties: string[] = [
  "background-opacity",
  "fill-opacity",
  "line-opacity",
  "icon-opacity",
  "text-opacity",
  "raster-opacity",
  "circle-opacity",
  "fill-extrusion-opacity",
  "heatmap-opacity",
  "sky-opacity",

  "hillshade-exaggeration",
];

const layerIdCountryLines = "country-borders"; // https://cloud.maptiler.com/maps/3958f66e-04a7-48e9-a088-a3b3c617c617/
const layerIdEuroFirs = "euro firs"; // https://cloud.maptiler.com/maps/ff991184-9793-4129-9cc5-79bf690b8efb/

export class BackgroundMapLayerImpl extends LayerBase<BackgroundMapLayer> {
  abortController: AbortController | undefined;

  sources: mapboxgl.Sources = {};
  layers: mapboxgl.AnyLayer[] = [];
  private firstLayer = "";

  constructor(id: number, props: BackgroundMapLayer, scene: SceneLayerApi, timezone: GuiTimeZone) {
    super(id, props, scene, timezone);
    this.addPlaceholderLayer();
    this.updateStyle(props.style);
    // TODO: why is this here?
    //this.invalidate(InvalidationType.ALL);
  }

  get mapboxIndex(): string {
    return this.firstLayer;
  }

  moveZIndex(beforeLayerId?: string) {
    const map = this.scene.getMapboxMap();
    map.moveLayer(this.getPlaceholderId(), beforeLayerId);
    for (const layer of this.layers) {
      const layerId = this.getSourceId(layer.id);
      if (map.getLayer(layerId)) {
        map.moveLayer(layerId, this.getPlaceholderId());
      }
    }
  }

  getActiveWeatherParametersAsString() {
    return [];
  }

  getActiveWeatherParameters() {
    return [];
  }

  isRebuffering(_: DateTime): boolean {
    // Check all the source consisting of the background layer in order to conclude
    // if it is rebuffering.
    Object.keys(this.sources).reduce((sourceRebuffering, key) => {
      const sourceId = this.getSourceId(key);
      const currSourceRebuffering = !this.scene.getMapboxMap().isSourceLoaded(sourceId);
      // If at least one source is rebuffering, we consider the background is still loading.
      return sourceRebuffering || currSourceRebuffering;
    }, false);
    return false;
  }

  private updateBorderLayerFilter(style: string, customOptions: BackgroundMapCustomOptions) {
    if (style === MaptilerBorderId) {
      const showValue = !!customOptions.show_state_border;
      this.updateFilter(style, layerIdCountryLines, showStateBorderExpression(showValue));
    }
  }
  private updateBorderPaintProperties(styleId: string, customOptions: BackgroundMapCustomOptions) {
    // border line-width
    if (styleId === MaptilerBorderId) {
      this.updateMapboxLinePaintProperties(layerIdCountryLines, {
        "line-width": BorderLineWithExpression,
      });
      this.updateMapboxLinePaintProperties(layerIdCountryLines, {
        "line-color": customOptions.line_color ?? backgroundLayerConfig.defaultLineColor,
      });
    }
    // border line-color
    if (styleId === MaptilerEuropeFIRId) {
      this.updateMapboxLinePaintProperties(layerIdEuroFirs, {
        "line-color": customOptions.line_color ?? backgroundLayerConfig.defaultLineColor,
      });
    }
  }
  private updateMapLabelLanguageProperty(styleId: string, customOptions: BackgroundMapCustomOptions) {
    const languageStr = customOptions?.map_label_language ? customOptions?.map_label_language : Language.EN;

    // we don't want to update label for border style layers
    // additional MaptilerSaBorderId has text with other fields then (name, "name:" + languageStr)
    if (!onlyBorderStyleLayer.includes(styleId)) {
      let nameLang = "name";
      if (languageStr !== Language.NATIVE) {
        nameLang = `name:${languageStr}`;
      }
      for (const layer of this.layers) {
        try {
          this.scene.getMapboxMap().setLayoutProperty(this.getSourceId(layer.id), "text-field", ["get", nameLang]);
        } catch (e) {
          // No warning console.warn(e);
        }
      }
    }
  }

  updateMapboxLinePaintProperties(layerId: string, props: LinePaint) {
    if (this.scene.getMapboxMap().getLayer(this.getSourceId(layerId))) {
      for (const entry of Object.entries(props)) {
        try {
          this.scene.getMapboxMap().setPaintProperty(this.getSourceId(layerId), entry[0], entry[1]);
        } catch (e) {
          console.warn(e);
        }
      }
    }
  }

  updateFilter(maptilerId: string, layerId: string, filter?: any[] | boolean | null, options?: FilterOptions | null) {
    if (this.scene.getMapboxMap().getLayer(this.getSourceId(layerId))) {
      this.scene.getMapboxMap().setFilter(this.getSourceId(layerId), filter, options);
    }
  }

  updateOpacity(opacity: number) {
    for (const layer of this.layers) {
      for (const property of backgroundPaintProperties) {
        try {
          this.scene.getMapboxMap().setPaintProperty(this.getSourceId(layer.id), property, opacity);
        } catch (e) {
          // No warning console.warn(e);
        }
      }
    }
  }

  updateShow(show: boolean) {
    for (const layer of this.layers) {
      try {
        this.scene
          .getMapboxMap()
          .setLayoutProperty(this.getSourceId(layer.id), "visibility", show ? "visible" : "none");
      } catch (e) {
        // No warning console.warn(e);
      }
    }
  }

  checker() {
    return {
      opacity: (prev: BackgroundMapLayer, curr: BackgroundMapLayer) => {
        if (prev.opacity !== curr.opacity) {
          this.setLayerProps({ opacity: curr.opacity });
          this.updateOpacity(curr.opacity);
        }
        return false; // internally managed change detection by mapbox
      },
      style: (prev: BackgroundMapLayer, curr: BackgroundMapLayer) => {
        if (prev.style !== curr.style) {
          this.setLayerProps({ style: curr.style });
          this.updateStyle(curr.style);
        }
        return false; // internally managed change detection by mapbox
      },
      show: (prev: BackgroundMapLayer, curr: BackgroundMapLayer) => {
        if (prev.show !== curr.show) {
          this.setLayerProps({ show: curr.show });
          this.updateShow(curr.show);
        }

        return false; // internally managed change detection by mapbox
      },
      index: (prev: BackgroundMapLayer, curr: BackgroundMapLayer) => {
        if (prev.index !== curr.index) {
          this.setLayerProps({ index: curr.index });
        }
        return false; // internally managed change detection by mapbox
      },
      calibrated: () => false,
      custom_options: (prev: BackgroundMapLayer, curr: BackgroundMapLayer) => {
        if (prev.custom_options !== curr.custom_options) {
          this.setLayerProps({ custom_options: curr.custom_options });
          this.updateBorderLayerFilter(curr.style, curr.custom_options);
          // set style for line with paint properties of the border
          this.updateBorderPaintProperties(curr.style, curr.custom_options);

          this.updateMapLabelLanguageProperty(curr.style, curr.custom_options);
        }
        return false;
      },
      vertical_interpolation: () => false,
      experimental: () => false,
    };
  }

  /**
   * Update the background layer by removing the whole mapbox state and rebuilding it from scratch.
   */
  private updateStyle(newStyle: string) {
    this.removeLayer();
    this.addPlaceholderLayer();
    this.fetchMaptilerStyle(newStyle).then((style) => {
      // if the style is null, this means that the first of two rapid style transitions was cancelled.
      // just keep the old style until the second transition resolves.
      if (style != null) {
        this.setStyle(style);
        this.updateOpacity(this.props.opacity);
        this.updateShow(this.props.show);
        // set filter of country border level
        this.updateBorderLayerFilter(newStyle, this.props.custom_options);
        // set style for line with paint properties of the border
        this.updateBorderPaintProperties(newStyle, this.props.custom_options);
        this.updateMapLabelLanguageProperty(newStyle, this.props.custom_options);
      }
    });
  }

  private getSourceId(key: string): string {
    return `metx.background#${key}#source${this.uid}`;
  }

  private getPlaceholderId(): string {
    return `metx.background#placeholder#${this.uid}`;
  }

  // TODO: this should be a generalized method
  // TODO: how is this cached?
  protected fetchMaptilerStyle(style: string): Promise<mapboxgl.Style | null> {
    const groundStyle = groundStyles.find((groundStyle) => groundStyle.id === style);
    const url = groundStyle ? groundStyle.uri : maptilerUrl(style);

    if (this.abortController?.signal.aborted) {
      this.abortController = new AbortController();
    } else {
      this.abortController?.abort();
      this.abortController = new AbortController();
    }
    const signal = this.abortController.signal;
    const myRequest = new Request(url);
    return fetch(myRequest, {
      signal,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP status ${response.status}`);
        }
        return response.json(); // TODO: validate
      })
      .catch((err) => {
        if (err.name !== "AbortError") {
          throw err;
        }
        return null;
      });
  }

  private async setStyle(data: mapboxgl.Style) {
    if (data.sprite) {
      // @ts-ignore Mapbox typings are bad because it's flow project. Id is always there.
      const id = data.id;
      const { asynchronous } = networkCaches.maptiler_sprite_cache.loadSpriteSheet(id, window.devicePixelRatio);

      const icons = await asynchronous;
      if (icons.length) {
        const map = this.scene.getMapboxMap();
        for (const icon of icons) {
          if (map.hasImage(icon.name)) {
            continue;
          }
          map.addImage(icon.name, icon.data, {
            pixelRatio: icon.pixelRation,
            sdf: icon.sdf,
          });
        }
      }
    }

    if (data.sources) {
      this.sources = data.sources;
      // biome-ignore lint/complexity/noForEach: Sources do not have value
      Object.entries(this.sources).forEach(([key, value]) => {
        this.scene.getMapboxMap().addSource(this.getSourceId(key), value);
      });
    }
    if (data.layers?.length) {
      this.layers = data.layers;
      this.layers.forEach((layer, idx) => {
        let newLayer: mapboxgl.AnyLayer;

        if (layerHasSource(layer)) {
          const sourceLayer = {
            ...layer,
            id: this.getSourceId(layer.id),
            source: this.getSourceId(layer.source),
          };
          newLayer = sourceLayer;
        } else {
          newLayer = { ...layer, id: this.getSourceId(layer.id) };
        }
        if (idx === 0) {
          this.firstLayer = newLayer.id;
        }
        this.scene.getMapboxMap().addLayer(newLayer, this.getPlaceholderId());
      });
    }
  }

  private addPlaceholderLayer() {
    if (this.scene.getMapboxMap().getLayer(this.getPlaceholderId())) {
      return;
    }
    const source: GeoJSONSourceRaw = {
      type: "geojson",
      data: emptyFeatureCollection,
    };
    const placeholderLayer: SymbolLayer = {
      id: this.getPlaceholderId(),
      type: "symbol",
      source: source,
    };
    this.firstLayer = placeholderLayer.id;
    this.scene.getMapboxMap().addLayer(placeholderLayer);
  }

  removeLayer() {
    this.removePlaceholderLayer();
    this.removeStyleLayers();
  }

  private removePlaceholderLayer() {
    this.scene.getMapboxMap().removeLayer(this.getPlaceholderId());
    this.scene.getMapboxMap().removeSource(this.getPlaceholderId());
  }

  private removeStyleLayers(): void {
    for (const layer of this.layers) {
      const id = this.getSourceId(layer.id);
      if (this.scene.getMapboxMap().getLayer(id)) {
        this.scene.getMapboxMap().removeLayer(id);
      } else {
        logger.error(`No Layer with Id: ${id}`);
      }
    }
    this.layers = [];
    for (const entry of Object.entries(this.sources)) {
      const id = this.getSourceId(entry[0]);
      if (this.scene.getMapboxMap().getSource(id)) {
        this.scene.getMapboxMap().removeSource(id);
      } else {
        logger.error(`No Source with Id: ${id}`);
      }
    }
    this.sources = {};
  }

  beforeRender(): void {
    // Empty since mapbox calls the render function separately.
  }

  // No prefetching
  fetchData(_timeFrame: DateTime): Promise<void> {
    return Promise.resolve(undefined);
  }
}

const BorderLineWithExpression: number | StyleFunction | Expression | undefined = [
  "case",
  ["==", ["get", "level"], 1],
  1,
  ["==", ["get", "level"], 0],
  2,
  2,
];

const showStateBorderExpression = (showStateBorder: boolean) => {
  if (showStateBorder) {
    return ["any", ["<=", "level", 1]];
  }
  return ["any", ["==", "level", 0]];
};

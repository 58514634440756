import type { ColorMapping } from "@/cache/ColorMapCache";
import { Area, CoordinateSystem } from "@mm/api.meteomatics.com";

import type { MapboxMap } from "@/layers/MapboxMap";
import { computeGridDimensionFromStep } from "@/layers/utility/getGridBoundingBox";
import type { WindMetaData } from "@/layers/wind/types";

export function computeBoundingBoxForMercatorProjection(distanceBetweenItem: number, mapbox: MapboxMap) {
  const map = mapbox;
  const bounds = map.getBounds();
  const canvas = map.getCanvas();

  const { verticalGridNum, horizontalGridNum } = computeGridDimensionFromStep(canvas, distanceBetweenItem);

  const area = new Area(CoordinateSystem.WGS84, {
    east: bounds.getEast(),
    west: bounds.getWest(),
    north: bounds.getNorth(),
    south: bounds.getSouth(),
  });
  return { area, height: verticalGridNum, width: horizontalGridNum };
}

export function createwindMetaData(
  { upperBound: max, lowerBound: min }: ColorMapping["recommendedValueRange"],
  width: number,
  height: number,
): WindMetaData {
  return {
    height: height,
    uMax: max,
    uMin: min,
    vMax: max,
    vMin: min,
    width: width,
  };
}

import { ENV } from "@/env";
import { t } from "@lingui/macro";

type LayerStyle = {
  id: string;
  label: () => string;
  uri: string;
};

export const maptilerFont = `https://api.maptiler.com/fonts/{fontstack}/{range}.pbf?key=${ENV.maptilerKey}`;

export function maptilerUrl(mapName: string): string {
  return `https://api.maptiler.com/maps/${mapName}/style.json?key=${ENV.maptilerKey}`;
}

export const MaptilerBorderId = "3958f66e-04a7-48e9-a088-a3b3c617c617";
export const MaptilerEuropeFIRId = "ff991184-9793-4129-9cc5-79bf690b8efb";
export const MaptilerSaBorderId = "ac4c3d0c-1979-4c94-b56c-e60ce151b2c0";

export const MaptilerMmWindFarmsId = "61bd317a-d1b2-4d81-ae68-011ad0d4d9b5";

export const onlyBorderStyleLayer = [MaptilerBorderId, MaptilerSaBorderId, MaptilerEuropeFIRId, MaptilerMmWindFarmsId];

// The Maptiler basic styles are copied from here: https://www.maptiler.com/maps/
// First in the list is always default
export const groundStyles: LayerStyle[] = [
  {
    // Map custom map with "topographique" id
    id: "topographique",
    label: () => t`Maptiler: Topographique`,
    uri: maptilerUrl("b17445a1-7e6b-49f3-b877-644b09fe2f0b"),
  },
  {
    id: "f866428e-554f-4ef7-b745-5b893ea778dd",
    label: () => t`Basic`,
    uri: maptilerUrl("f866428e-554f-4ef7-b745-5b893ea778dd"),
  },
  {
    id: "basic",
    label: () => t`Maptiler: Basic`,
    uri: maptilerUrl("basic"),
  },
  {
    id: "bright",
    label: () => t`Maptiler: Bright`,
    uri: maptilerUrl("bright"),
  },
  {
    id: "darkmatter",
    label: () => t`Maptiler: Darkmatter`,
    uri: maptilerUrl("darkmatter"),
  },
  {
    id: "hybrid",
    label: () => t`Maptiler: Satellite Hybrid`,
    uri: maptilerUrl("hybrid"),
  },
  {
    id: "outdoor",
    label: () => t`Maptiler: Outdoor`,
    uri: maptilerUrl("outdoor-v2"),
  },
  {
    id: "pastel",
    label: () => t`Maptiler: Pastel`,
    uri: maptilerUrl("pastel"),
  },
  {
    id: "positron",
    label: () => t`Maptiler: Positron`,
    uri: maptilerUrl("positron"),
  },
  {
    id: "toner",
    label: () => t`Maptiler: Toner`,
    uri: maptilerUrl("toner"),
  },
  {
    id: "topo",
    label: () => t`Maptiler: Topo`,
    uri: maptilerUrl("topo-v2"),
  },
  {
    id: "voyager",
    label: () => t`Maptiler: Voyager`,
    uri: maptilerUrl("voyager"),
  },
  {
    id: "0e914266-89b2-46c2-8359-1b47522855c0",
    label: () => t`Maptiler: Dark Blue and Green`,
    uri: maptilerUrl("0e914266-89b2-46c2-8359-1b47522855c0"),
  },
  {
    id: MaptilerBorderId,
    label: () => t`Border`,
    uri: maptilerUrl(MaptilerBorderId),
  },
  {
    id: "b4033781-d6cb-42c4-ab61-59d1921da345",
    label: () => t`City Label`,
    uri: maptilerUrl("b4033781-d6cb-42c4-ab61-59d1921da345"),
  },
  {
    id: "542e86e1-7347-4309-826b-006008055f48",
    label: () => t`No Border No City Label`,
    uri: maptilerUrl("542e86e1-7347-4309-826b-006008055f48"),
  },
  {
    id: MaptilerEuropeFIRId,
    label: () => t`Europe FIR`,
    uri: maptilerUrl(MaptilerEuropeFIRId),
  },
  {
    id: MaptilerSaBorderId,
    label: () => t`Border Saudi Arabia`,
    uri: maptilerUrl(MaptilerSaBorderId),
  },
  {
    id: MaptilerMmWindFarmsId,
    label: () => t`Wind Farms`,
    uri: maptilerUrl(MaptilerMmWindFarmsId),
  },
];

export enum Language {
  NATIVE = "native", // default
  EN = "en",
  DE = "de",
  FR = "fr",
  ES = "es",
  CS = "cs",
  SQ = "sq",
  AR = "ar",
  HY = "hy",
  AZ = "az",
  BE = "be",
  BS = "bs",
  BR = "br",
  BG = "bg",
  CA = "ca",
  ZH = "zh",
  HR = "hr",
  DA = "da",
  NL = "nl",
  EO = "eo",
  ET = "et",
  FI = "fi",
  KA = "ka",
  EL = "el",
  HE = "he",
  HU = "hu",
  IS = "is",
  GA = "ga",
  IT = "it",
  JA = "ja",
  JA_KANA = "ja_kana",
  JA_RM = "ja_rm",
  KN = "kn",
  KK = "kk",
  KO = "ko",
  KO_RM = "ko_rm",
  LA = "la",
  LV = "lv",
  LT = "lt",
  LB = "lb",
  MK = "mk",
  MT = "mt",
  NO = "no",
  PL = "pl",
  PT = "pt",
  RO = "ro",
  RM = "rm",
  RU = "ru",
  GD = "gd",
  SR = "sr",
  SR_LATN = "sr-Latn",
  SK = "sk",
  SL = "sl",
  SV = "sv",
  TH = "th",
  TR = "tr",
  UK = "uk",
  CY = "cy",
  FY = "fy",
}

interface SelectOption {
  value: Language;
  label: () => string;
}

// No Translation for now
export const languageOptions: SelectOption[] = [
  { value: Language.NATIVE, label: () => "Native" },
  { value: Language.EN, label: () => t`English` },
  { value: Language.DE, label: () => t`German` },
  { value: Language.FR, label: () => t`French` },
  { value: Language.AR, label: () => t`Arabic` },

  { value: Language.ES, label: () => t`Spanish` },
  { value: Language.CS, label: () => t`Czech` },
  { value: Language.SQ, label: () => t`Albanian` },
  { value: Language.HY, label: () => t`Armenian` },
  { value: Language.AZ, label: () => t`Azerbaijani` },
  {
    value: Language.BE,
    label: () => t`Belarusian`,
  },
  { value: Language.BS, label: () => t`Bosnian` },
  { value: Language.BR, label: () => t`Breton` },
  {
    value: Language.BG,
    label: () => t`Bulgarian`,
  },
  { value: Language.CA, label: () => t`Catalan` },
  { value: Language.ZH, label: () => t`Chinese` },
  {
    value: Language.HR,
    label: () => t`Croatian`,
  },
  { value: Language.DA, label: () => t`Danish` },
  { value: Language.NL, label: () => t`Dutch` },
  {
    value: Language.EO,
    label: () => t`Esperanto`,
  },
  { value: Language.ET, label: () => t`Estonian` },
  { value: Language.FI, label: () => t`Finnish` },
  {
    value: Language.KA,
    label: () => t`Georgian`,
  },
  { value: Language.EL, label: () => t`Greek` },
  { value: Language.HE, label: () => t`Hebrew` },
  {
    value: Language.HU,
    label: () => t`Hungarian`,
  },
  { value: Language.IS, label: () => t`Icelandic` },
  { value: Language.GA, label: () => t`Irish` },
  {
    value: Language.IT,
    label: () => t`Italian`,
  },
  { value: Language.JA, label: () => t`Japanese` },
  {
    value: Language.JA_KANA,
    label: () => t`Japanese (Kana)`,
  },
  { value: Language.JA_RM, label: () => t`Japanese (Latin)` },
  { value: Language.KN, label: () => t`Kannada` },
  {
    value: Language.KK,
    label: () => t`Kazakh`,
  },
  { value: Language.KO, label: () => t`Korean` },
  { value: Language.KO_RM, label: () => t`Korean (Latin)` },
  {
    value: Language.LA,
    label: () => t`Latin`,
  },
  { value: Language.LV, label: () => t`Latvian` },
  { value: Language.LT, label: () => t`Lithuanian` },
  {
    value: Language.LB,
    label: () => t`Luxembourgish`,
  },
  { value: Language.MK, label: () => t`Macedonian` },
  { value: Language.MT, label: () => t`Maltese` },
  {
    value: Language.NO,
    label: () => t`Norwegian`,
  },
  { value: Language.PL, label: () => t`Polish` },
  { value: Language.PT, label: () => t`Portuguese` },
  {
    value: Language.RO,
    label: () => t`Romania`,
  },
  { value: Language.RM, label: () => t`Romansh` },
  { value: Language.RU, label: () => t`Russian` },
  {
    value: Language.GD,
    label: () => t`Scottish Gaelic`,
  },
  { value: Language.SR, label: () => t`Serbian (Cyrillic)` },
  {
    value: Language.SR_LATN,
    label: () => t`Serbian (Latin)`,
  },
  { value: Language.SK, label: () => t`Slovak` },
  { value: Language.SL, label: () => t`Slovene` },
  {
    value: Language.SV,
    label: () => t`Swedish`,
  },
  { value: Language.TH, label: () => t`Thai` },
  { value: Language.TR, label: () => t`Turkish` },
  {
    value: Language.UK,
    label: () => t`Ukrainian`,
  },
  { value: Language.CY, label: () => t`Welsh` },
  { value: Language.FY, label: () => t`Western Frisian` },
];

import type { AnyLayer } from "mapbox-gl";

/**
 * Same as "Omit<T>" but keeps the underlining discriminate union in T
 * See: https://stackoverflow.com/questions/67794339/why-doesnt-discriminated-union-work-when-i-omit-require-props
 */
type Without<T, K extends PropertyKey> = T extends any ? Omit<T, K> : never;

export type AnyLayerWithoutId<LayerName extends string> = Without<AnyLayer, "id"> & { layerName: LayerName };

function _generateUniqueLayerId(groupId: string, layerName: string) {
  return `${groupId}:${layerName}`;
}

/**
 * Utility to keep track of multiple mapbox layer IDs as a single group.
 * It essentially acts as a dictionary to lookup the mapbox layer IDs by human-readable name.
 *
 * Note that we intentionally don't put the mapbox instance here at the moment,
 * because we can directly derive those information from the mapbox instance using IDs.
 */
export class MapboxLayerGroup<LayerName extends string> {
  private groupId: string;
  /**
   * This represents all the layers names that a consumer can use to retrieve the mapbox layer ID.
   */
  private registeredLayerNames: Set<LayerName>;
  /**
   * This represents the actual mapbox layer IDs
   */
  private registeredLayerIds: string[];

  /**
   * We keep the initial specs of the mapbox layer within this class for the purposes of:
   * 1. This class generates the ID for the given layer automatically based on the group ID.
   *    Keeping the spec objects here allows us to assign the IDs in a consistent mannger.
   * 2. This class memorizes the registering order,
   *    which allows us to tell which layer can be considered the "main layer" when re-ordering z-index.
   */
  private initialLayerSpecs: AnyLayer[];

  constructor(groupId: string, layers: [...AnyLayerWithoutId<LayerName>[]]) {
    this.groupId = groupId;
    this.registeredLayerNames = new Set(layers.map((l) => l.layerName)) as Set<LayerName>;
    this.registeredLayerIds = layers.map((l) => _generateUniqueLayerId(groupId, l.layerName));

    this.initialLayerSpecs = layers.map((l, i) => {
      const { layerName, ...spec } = l;
      return { ...spec, id: this.registeredLayerIds[i] };
    });
  }

  /**
   * Returns the initial specifications of mapbox layers in this group.
   * You can directly add the resulting array of specs to Mapbox.
   */
  initialSpecs(): AnyLayer[] {
    return this.initialLayerSpecs;
  }

  /**
   * Returns all the layer IDs in the group
   */
  allIds() {
    return this.registeredLayerIds;
  }

  /**
   * Returns IDs to identify a unique mapbox layer.
   * @param layerNames
   * @returns
   */
  id(...layerNames: LayerName[]): string[] {
    const layerIds: string[] = [];
    for (let i = 0; i < layerNames.length; i++) {
      const layer = layerNames[i];
      if (!this.registeredLayerNames.has(layer)) {
        throw Error(`Layer with name "${layer}" doesn't exist in the layer group ${this.groupId}`);
      }
      layerIds.push(_generateUniqueLayerId(this.groupId, layer));
    }
    return layerIds;
  }

  /**
   * Returns a single layer ID that represents the main layer of the group, which is at the bottom of the layer group.
   * The purpose of this function is to return the single mapbox layer that we can use to reorder the whole layer group
   * in relation to other groups.
   */
  mainId(): string {
    return this.registeredLayerIds[0];
  }
}

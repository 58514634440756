import { type Environment, defaultEnvironment } from "./type";
import { cleanedEnv } from "./validate";

/**
 * Current main object to use the env variables in other parts of the code.
 *
 * Note: What is the difference between ENV object here and cleanedEnv?
 * -> Essentially they are duplicate. We have added `cleanedEnv` later in the project in order to ensure
 *    the data type of the env variables. We could technically just switch to `cleanedEnv` and use the variables
 *    from there in the code later on.
 *
 * Context:
 * - Previously we had two typescript file production.ts and development.ts which switch between prod and local builds
 *   based on the `build-info` varibale. While the exact reason for the setup is unknown, the standard approach seems to be
 *   1) having a single TypeScript file where all env variables are validated and compiled, 2) use different `.env` files
 *   between environment. So the later is our current plan, and this object sits in the transition phase.
 *   At the time of writing this, our intention is to get rid of this object, and have a single typescript object where
 *   we can read the variables during the runtime, which can be the `cleanedEnv` object.
 */
export const ENV: Environment = {
  ...defaultEnvironment,
  maptilerKey: cleanedEnv.REACT_APP_MAPTILER_KEY,
  oauthClientId: cleanedEnv.REACT_APP_OAUTH_CLIENT_ID,
  baseUrlMetx: cleanedEnv.REACT_APP_BASE_URL_METX,
  baseUrlLogin: cleanedEnv.REACT_APP_OAUTH_BASE_URL,
  baseUrlApiLayers: cleanedEnv.REACT_APP_BASE_URL_API_LAYERS,
  baseUrlGeocoder: cleanedEnv.REACT_APP_BASE_URL_GEOCODER,
  baseUrlEnergyForecast: cleanedEnv.REACT_APP_BASE_URL_ENERGY_FORECAST,
  baseUrlApiMeteomatics: cleanedEnv.REACT_APP_METEOMATICS_API_URLS,
  mapboxKey: cleanedEnv.REACT_APP_MAPBOX_KEY,
};

export const IS_NCM = process.env.REACT_APP_BUILD_NAME === "NCM";

import { Map as MBMap } from "@/map";
import type { CartographicMap, GridCellLayout } from "@mm/metx-workbench.meteomatics.com";
import { pickBy } from "lodash";
import { type ComponentType, Suspense, createElement } from "react";
import { hasClosingBorder, hasOpeningBorder } from "./utils";

export type GridMapProps = {
  map: CartographicMap;
  MapOverlay: ComponentType<{ map: CartographicMap; cellLayout?: GridCellLayout }>;
  cellLayout?: GridCellLayout;
};

export function GridMap({ map, MapOverlay, cellLayout }: GridMapProps) {
  return (
    <div className="overlay">
      <div className="overlay__layer">
        {/* TODO Maybe pass whole map? */}
        <MBMap id={{ profile: map.id_profile, tab: map.id_tab, map: map.id }} />
      </div>
      <div className="overlay__layer gui-overlay map-grid-overlay">
        <Suspense fallback={<mm-loader size="large" />}>{createElement(MapOverlay, { map, cellLayout })}</Suspense>
      </div>
    </div>
  );
}

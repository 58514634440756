import React from "react";
import { Playback } from "./Playback";

/**
 * Holds animation state for each tab. Playback state takes the description of some temporal
 * description, which might be anything from `yesterday to tomorrow` or just `September 11. 2001 at 10:00`,
 * and turns it into a concrete point of time. So, in the given example `yesterday to tomorrow` might
 * be resolved to `1/1/2021 00:00 to 1/2/2021 00:00, with current animation progress at 1/1/2021 12:00`.
 *
 * For performance reasons, the contents of this context are reference updated.
 * This means that depending on the component rendering order, components might see inconsistent state.
 * However, state will converge to a consistent state when no updates are applied within a frame.
 *
 * If you want to check, if anything in the playback state has changed. Do a
 * reference check against the container:
 *
 * ```ts
 * const playback = useContext(PlaybackContext);
 * useEffect(() => {
 *    // do stuff with new playback state
 * }, [playback])
 * ```
 *
 * If you want to run a piece of code, when the playback state becomes available for the first
 * time (e.g. on application startup), run a check against the payload itself:
 *
 * ```ts
 * const { playback } = useContext(PlaybackContext); // Note the unpacking on the left hand-side
 * useEffect(() => {
 *    // do stuff on playback initialization
 * }, [playback])
 * ```
 *
 * In most cases, you will want to observe the playback state of the current tab only,
 * in this case, use the `usePlayback` hook instead!
 */
export const PlaybackContext = React.createContext<{ playback: Playback }>({ playback: new Playback(() => {}) });

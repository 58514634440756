import {
  constructRadiusDisplay,
  findFeatureByMeta,
} from "@/layers/geojson/drawing/mapbox-draw-mode/draw-radius/drawing-utils";
import { DrawModes, DrawingTypes } from "@/layers/geojson/drawing/mapbox-draw-mode/modeNames";
import type {
  DrawCustomMode,
  DrawCustomModeThis,
  DrawLineString,
  MapMouseEvent,
  MapTouchEvent,
} from "@mapbox/mapbox-gl-draw";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { booleanValid } from "@turf/turf";
import type { Feature, GeoJSON, LineString } from "geojson";

type DrawRadiusState = {
  line: DrawLineString;
  currentVertexPosition: number;
  direction: "backwards" | "forward";
};

type DrawRadiusMode = {
  handleClick: (state: DrawRadiusState, e: MapMouseEvent | MapTouchEvent) => void;
  clickAnywhere: (state: DrawRadiusState, e: MapMouseEvent) => void;
} & DrawCustomMode<DrawRadiusState, any>;

type DrawRadiusModeThis = DrawCustomModeThis & {
  handleClick: (state: any, e: any) => void;
  clickAnywhere: (state: any, e: any) => void;
  changeMode(mode: DrawModes, opts?: object, eventOpts?: object): void;
};

/**
 * Implements the custom radius interaction
 * See: https://github.com/mapbox/mapbox-gl-draw/blob/main/docs/MODES.md#available-custom-modes
 */
export const DrawRadius = (): DrawRadiusMode => ({
  ...MapboxDraw.modes.draw_line_string,

  onStop: function (state) {
    const { line } = state;
    if (!line.id) {
      return;
    }

    if (!line.isValid()) {
      this.deleteFeature(line.id as string, { silent: true });
    } else {
      line.setProperty("role", DrawingTypes.radius);
    }
  },

  handleClick: function (this: DrawRadiusModeThis, state, e) {
    if (this.getSelected().length) {
      this.clearSelectedFeatures();

      return this.changeMode(DrawModes.idle);
    }

    if (state.currentVertexPosition > 0) {
      return this.clickAnywhere(state, e);
    }

    const features = e.target.queryRenderedFeatures(e.point);

    const handle = findFeatureByMeta(features, "radius-handle");
    if (handle?.properties) {
      this.updateUIClasses({ mouse: MapboxDraw.constants.cursors.MOVE });
      this.setSelected([handle.properties.parent]);
      return;
    }

    const center = findFeatureByMeta(features, "center-handle");
    if (center?.properties) {
      this.deleteFeature(center.properties.parent);
      return this.changeMode(DrawModes.idle);
    }

    return this.clickAnywhere(state, e);
  },

  onClick: function (state, e) {
    this.handleClick(state, e);
  },

  onTap: function (state, e) {
    this.handleClick(state, e);
  },

  onMouseMove: function (state, e) {
    const selectedRadius = this.getSelected();
    if (selectedRadius.length) {
      for (const feature of selectedRadius) {
        feature.updateCoordinate("1", e.lngLat.lng, e.lngLat.lat);
      }

      return;
    }

    if (state.currentVertexPosition === 0) {
      const handle = e.target
        .queryRenderedFeatures(e.point)
        .find(
          (feature) => feature.properties?.meta === "radius-handle" || feature.properties?.meta === "center-handle",
        );

      if (handle) {
        this.updateUIClasses({ mouse: MapboxDraw.constants.cursors.POINTER });
        return;
      }
    }

    state.line.updateCoordinate(state.currentVertexPosition.toString(), e.lngLat.lng, e.lngLat.lat);
  },

  clickAnywhere: function (this: DrawRadiusModeThis, state: any, e: any) {
    // this ends the drawing after the user creates a second point, triggering this.onStop
    if (state.currentVertexPosition === 1) {
      this.changeMode(DrawModes.idle, { state });
      return;
    }

    this.updateUIClasses({ mouse: MapboxDraw.constants.cursors.ADD });
    state.line.updateCoordinate(state.currentVertexPosition, e.lngLat.lng, e.lngLat.lat);
    if (state.direction === "forward") {
      state.currentVertexPosition += 1;
      state.line.updateCoordinate(state.currentVertexPosition, e.lngLat.lng, e.lngLat.lat);
    } else {
      state.line.addCoordinate(0, e.lngLat.lng, e.lngLat.lat);
    }

    return state;
  },

  // @ts-ignore
  onKeyUp: function (this: DrawRadiusModeThis, state, e) {
    if (MapboxDraw.lib.CommonSelectors.isEnterKey(e)) {
      this.changeMode(DrawModes.idle);
    } else if (MapboxDraw.lib.CommonSelectors.isEscapeKey(e)) {
      // Delete previous line
      this.deleteFeature(state.line.id as string, { silent: false });
      setTimeout(() => this.changeMode(DrawModes.idle));
    }
  },

  // biome-ignore lint/complexity/useArrowFunction: use function for consistency
  toDisplayFeatures: function (_state, geojson: Feature<LineString>, display: (geojson: GeoJSON) => void) {
    if (!booleanValid(geojson)) {
      return;
    }

    const displayFeatures = constructRadiusDisplay(geojson);

    for (const feature of displayFeatures) {
      display(feature);
    }
  },
});

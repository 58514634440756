import { t } from "@lingui/macro";
import { useEffect, useMemo, useState } from "react";
import { Controller } from "react-hook-form";

import { barbsLayerConfig } from "@/constants/layerConfigAttributes";
import type { LayerKind } from "@/layers/utility/createLayerObject";
import { EnsembleMemberSelect } from "@/overlay/components/Ensamble";
import { FormCheckbox } from "@/overlay/components/SmartForm/HookInputs/Checkbox";
import { ColorPicker } from "@/overlay/components/SmartForm/HookInputs/ColorPicker";
import { ParamModelSelect } from "@/overlay/components/SmartForm/HookInputs/ParamModelSelect";
import type { ParamOptionInputData } from "@/overlay/components/SmartForm/HookInputs/ParamOptionSelectList";
import { SliderInput } from "@/overlay/components/SmartForm/HookInputs/SliderInput";
import { WrappedStepConfigInputForm } from "@/overlay/components/SmartForm/HookInputs/WrappedStepConfigInput";
import { WrappedWeatherParameterForm } from "@/overlay/components/SmartForm/HookInputs/WrappedWeatherParameterForm";
import { ModeSwitchableForm } from "@/overlay/components/SmartForm/SmartFormUtils/FormComponents/ModeSwitchableFormProps";
import { getUpdatedParamUnit } from "@/overlay/components/SmartForm/SmartFormUtils/FormDataFormatter/mergePartialWeatherParameter";
import { useFormWithUtils } from "@/overlay/components/SmartForm/SmartFormUtils/FormHooks/useFormUtils";
import { usePartialWeatherParameter } from "@/overlay/components/SmartForm/SmartFormUtils/FormHooks/usePartialWeatherParameter";
import { usePartialWeatherParameterModels } from "@/overlay/components/SmartForm/SmartFormUtils/FormHooks/usePartialWeatherParameterModels";
import type { BaseFormProps } from "@/overlay/components/SmartForm/SmartFormUtils/commonTypeUtils";
import { hasCalibratedAvailable } from "@/utility/calibratedAvailabilityMap";
import type { EnsOption } from "@/utility/ensemble";
import { searchEngineWeatherModels, searchEnginesWeatherParams } from "@/weather-parameters";
import type { BarbsLayer, BarbsLayerCreate, GridLayer, SymbolLayer } from "@mm/metx-workbench.meteomatics.com";
import type { HasLayerIdxNarrowing, PartialWeatherParameter } from "weather-parameter-utils";

type BarbsLayerPatch = Partial<BarbsLayerCreate | BarbsLayer>;

/**
 * Form data passed from the child components.
 */
export type BarbsLayerCreateFormData = {
  // Snake case as they get passed as layer props directly.
  layerData: {
    parameter_unit: string;
    model: string;
    element_color: string;
    step?: number;
    calibrated?: boolean;
    ens_select?: EnsOption;
    custom_options: {
      icon_size?: number;
    };
  };
  // Form internal data to construct the above data.
  internal: {
    defaultParameter: HasLayerIdxNarrowing<PartialWeatherParameter>;
    paramOptionPatch: ParamOptionInputData;
    stepPatch: Partial<BarbsLayer | SymbolLayer | GridLayer>; // TODO Get rid of this by refactoring WrappedStepConfigInput
  };
};

type BarbsLayerCreateFormProps = {
  defaultFormData: BarbsLayerPatch;
} & BaseFormProps<BarbsLayerCreateFormData>;

const searchEngine = searchEnginesWeatherParams.standard;
const modelSearchEngine = searchEngineWeatherModels;

const Form = ({
  parameter,
  submitMode,
  onSubmit,
  onCancel,
  defaultFormData,
}: BarbsLayerCreateFormProps & { parameter: HasLayerIdxNarrowing<PartialWeatherParameter> }) => {
  const { formMethods, formValName } = useFormWithUtils<BarbsLayerCreateFormData>({ mode: submitMode });
  const availableModels = usePartialWeatherParameterModels(parameter, modelSearchEngine);

  const modelSchema = useMemo(
    () => availableModels.find((model) => model.identifier === defaultFormData.model),
    [defaultFormData.model, availableModels],
  );

  const onSubmitWithFormatting: typeof onSubmit = (formData) => {
    if (formData.internal?.paramOptionPatch) {
      const paramUnit = getUpdatedParamUnit(parameter, [formData.internal.paramOptionPatch]);
      onSubmit({
        ...formData,
        layerData: {
          ...formData.layerData,
          parameter_unit: paramUnit,
          step: formData.internal.stepPatch.step, // TODO Cleanup this
        },
      });
    }
  };
  if (!defaultFormData.kind) return <></>;

  return (
    <ModeSwitchableForm
      onSubmit={formMethods.handleSubmit(onSubmitWithFormatting)}
      onCancel={onCancel}
      submitMode={submitMode}
    >
      <ParamModelSelect
        instruction={{
          currValue: defaultFormData.model,
          formMethods: formMethods,
          formValueName: formValName("layerData.model"),
        }}
        availableModels={availableModels}
      />
      <WrappedWeatherParameterForm
        instruction={{
          currValue: {
            kind: defaultFormData.kind as LayerKind,
            parameter: parameter,
          },
          formMethods,
          formValueName: formValName("internal.paramOptionPatch"),
        }}
      />
      {/* TODO: Refactor EnsembleMemberSelect as form v2 component */}
      {modelSchema?.num_ensemble_members && (
        <Controller
          control={formMethods.control}
          name="layerData.ens_select"
          render={({ field: { value, onChange } }) => (
            <EnsembleMemberSelect
              ensSelect={value}
              selectedModelSchema={modelSchema}
              onChange={({ ens_select }) => onChange(ens_select)}
              allowMultipleEnsSelect={false}
            />
          )}
        />
      )}
      <WrappedStepConfigInputForm
        instruction={{
          formValueName: formValName("internal.stepPatch"),
          currValue: {
            layer: defaultFormData as BarbsLayer,
            stepRange: barbsLayerConfig.stepRange,
          },
          formMethods,
        }}
      />
      <SliderInput
        label={t`Icon Size`}
        instruction={{
          currValue: defaultFormData.custom_options?.icon_size,
          formValueName: formValName("layerData.custom_options.icon_size"),
          formMethods,
        }}
        range={{ ...barbsLayerConfig.iconSizeRange, step: 0.01 }}
      />
      <ColorPicker
        label={t`Element Color`}
        instruction={{
          currValue: defaultFormData.element_color,
          formValueName: formValName("layerData.element_color"),
          formMethods,
        }}
      />
      {hasCalibratedAvailable(defaultFormData.parameter_unit) && (
        <FormCheckbox
          label={t`Calibrated`}
          instruction={{
            currValue: !!defaultFormData.calibrated,
            formValueName: formValName("layerData.calibrated"),
            formMethods,
          }}
        />
      )}
    </ModeSwitchableForm>
  );
};

/**
 * Responsible for rendering the whole layer form and send back the data on submit hadler.
 */
export function BarbsLayerUpdateForm(props: BarbsLayerCreateFormProps) {
  const { defaultFormData } = props;
  const [parameter, setParameter] = useState<HasLayerIdxNarrowing<PartialWeatherParameter>>();

  const { search } = usePartialWeatherParameter(
    defaultFormData.parameter_unit ?? "",
    defaultFormData.kind as LayerKind,
    searchEngine,
  );

  useEffect(() => {
    search().then(setParameter);
  }, [search]);

  // We need to keep parameter async, because of old workaround, introduced inside "search" function
  // Initial problem is, that there is no distinguishable difference between wind_speed_<measure>_<level>_<interval>:<unit>
  // and wind_speed_<measure>_<height>_<interval>:<unit>, if height values matches values inside level.
  // Workaround is - if multiple possible matches are returned, we always use second one (because measure regex is put after level)
  // This kinda works, but isn't long term solution and prevent's us to use "matchSomeParameterNameExactly" from search engine.
  // To simplify async operation, form is created only when search engine returns parameter to not complicate hooks more
  if (parameter) {
    return <Form {...props} parameter={parameter} />;
  }

  return <></>;
}

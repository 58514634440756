import { CapabilitiesCache } from "@/cache/CapabilitiesCache";
import { CustomGeoJSONCache } from "@/cache/CustomGeoJSONCache";
import { GenericCache } from "@/cache/GenericCache";
import { GeoJSONCache } from "@/cache/GeoJSONCache";
import { createWmsTileGetter } from "@/cache/PVSTileService/TileGetters/WMSTileGetter";
import { PlotCache } from "@/cache/PlotCache";
import { PoiCache } from "@/cache/PoiCache";
import { PointJSONCache } from "@/cache/PointJSONCache";
import { PolygonJSONCache } from "@/cache/PolygonJSONCache";
import { TILE_SIZE } from "@/cache/SpatioTemporalTileCache/config";
import { VectorTileCache } from "@/cache/VectorTileCache";
import { KiB, MiB } from "@/utility/bytesize";
import type { CoordinateSystem } from "@mm/api.meteomatics.com";
import { AvailableTimeFrameCache } from "./AvailableTimeFrameCache";
import { ColorMapCache } from "./ColorMapCache";
import { InitDateCache } from "./InitDateCache";
import { MaptilerSpritesCache } from "./MaptilerSpritesCache";
import { createPVSTileService } from "./PVSTileService/PVSTileServiceFactory";
import { createMultiWmsTileGetter } from "./PVSTileService/TileGetters/MultiWMSTileGetter";
export * from "./CacheDatumState";

/**
 * Global network caches shared by all scenes and visualization layers.
 *
 * Note: the limits here should be significantly larger than the data that is expected to be 'in use' at once.
 * Otherwise data will be evicted and refetched in each frame. If you want a data structure with tighter memory bounds,
 * that does not evict 'in use' data, check out the `ObjectPool` and `GenerationalObjectPool` classes.
 */
export const networkCaches = {
  wms_tile_cache: createPVSTileService<CoordinateSystem.EPSG3857>({
    label: "Spatiotemporal PNG Tile Cache",
    maxSize: MiB(750),
    formatSize: (val) => `${val} MiB`,
    delayEvictionUntilSize: MiB(800),
    tileSize: TILE_SIZE,
    tileGetter: createWmsTileGetter<CoordinateSystem.EPSG3857>(),
  }),

  /**
   * A tile service to allow requesting multiple images for a single tile.
   * Use this cache if you need multiple WMS images for a single tile.
   */
  multi_wms_tile_cache: createPVSTileService<CoordinateSystem.EPSG3857>({
    label:
      "Spatiotemporal Multi PNG Tile Tile Cache. Allows requesting multiple images for multiple parameters for a single tile",
    maxSize: MiB(750),
    formatSize: (val) => `${val} MiB`,
    delayEvictionUntilSize: MiB(800),
    tileSize: TILE_SIZE,
    tileGetter: createMultiWmsTileGetter<CoordinateSystem.EPSG3857>(),
  }),

  multi_wms_slice_cache: createPVSTileService<CoordinateSystem.EPSG4326>({
    label:
      "Spatiotemporal Multi PNG Slice Cache. Allows requesting multiple images for multiple parameters for a single slice",
    maxSize: MiB(750),
    formatSize: (val) => `${val} MiB`,
    delayEvictionUntilSize: MiB(800),
    tileSize: TILE_SIZE,
    tileGetter: createMultiWmsTileGetter<CoordinateSystem.EPSG4326>(),
  }),

  spatiotemporal_vector_tile_cache: new VectorTileCache(),

  // let the cache outgrow its size by 10% before triggering the eviction routine.
  //
  // the largest colormap that we have is 6000 samples large. 500 KiB corresponds to 21 of these
  // colormaps. Most other colormaps have less than 1000 samples. Thereferoe, these values should
  // be enough. 50KiB corresponds to 12800 samples. So, at least two colormaps will be evicted
  // at once.
  colormap_cache: new ColorMapCache("Colormap Cache", KiB(500), KiB(550)),
  // [DEBUG-CACHE-MEMORY] // Note: this is so low that the application will crash for some tabs because the active working set is evicted.
  // [DEBUG-CACHE-MEMORY] colormap_cache: new ColorMapCache("Colormap Cache", KiB(1), KiB(2)),
  geojson_cache: new GeoJSONCache("GeoJSON Cache"),
  // Cache for holding custom GeoJSON, sliced by feature types
  custom_geojson_cache: new CustomGeoJSONCache("Custom GeoJSON Cache"),

  poi_cache: new PoiCache("POI Cache"),

  capabilities_cache: new CapabilitiesCache("WFS GetCapabilities Cache"),

  pointJson_cache: new PointJSONCache("Point Cache"),
  polygonJson_cache: new PolygonJSONCache("Polygon Cache"),

  plot_cache: new PlotCache("Plot Cache"),

  initDate_cache: new InitDateCache("Init Date Cache"),

  availableTimeFrame_cache: new AvailableTimeFrameCache("Available Time Frame Cache"),

  maptiler_sprite_cache: new MaptilerSpritesCache("Maptiler Sprite cache"),
  // Note: We would like to move most caches into this Cache (wms / tiling cache should still have an own)
  generic_cache: new GenericCache("Generic Cache"),
};

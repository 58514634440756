import { Units } from "@mm/metx-workbench.meteomatics.com";

export const DEFAULT_PARTICLE_SIZE = 1.5;
export const particleSizeLimit = { step: 0.01, min: 1, max: 6 };

export const DEFAULT_PARTICLE_AMOUNT = 128;
export const particleAmountLimit = { step: 1, min: 32, max: 256 };

export const opacityLimits = { step: 0.01, min: 0, max: 1 };
export const defaultTextColor = "#000000";
export const defaultOpacity = 0.7;

export const isoLinesLayerConfig = {
  textSizeLimits: { min: 6, max: 50 },
  /**
   * Represents a range of the available line width for the isoline layers.
   */
  lineWidthLimits: { min: 0, max: 20 },
  defaultIsoLineWidth: 1,
  defaultTextSize: 16,
  defaultLineColor: "#000000",
  gaussianAndMedianFilterLimits: { min: 0, max: 10 },
  defaultGaussian: 3,
  defaultMedian: 1,
};

export const weatherFrontsLayerConfig = {
  lineWidthLimits: { min: 1, max: 10 },
  defaultLineWidth: 2,
  iconSizeLimits: { min: 10, max: 30 }, // transformed in class to scale / 100
  iconSizeStep: 1,
  iconDefaultSize: 25,
};

/**
 * Is used for all layer with a grid so that they always lay over each other with the same step
 * */
const gridLayerStepConfig = {
  /**
   * step represent distance between each icon or text
   * fewer means more icons
   */
  stepRange: {
    min: 25,
    max: 60,
    step: 5,
  },
  /**
   * Default distance between each icon or text
   */
  defaultStep: 35,
};

export const gridLayerConfig = {
  ...gridLayerStepConfig,
  /**
   * Represents a range of available text size limits for the grid layers.
   */
  textSizeLimits: { min: 6, max: 50 },
  defaultTextSize: 16,
};

export const symbolLayerConfigWWcode = {
  ...gridLayerStepConfig,
  iconSizeRange: {
    min: 0.3,
    max: 0.9,
  },
  defaultIconSize: 0.4,
};

export const symbolLayerConfigWeatherSymbol = {
  ...gridLayerStepConfig,
  iconSizeRange: {
    min: 0.3,
    max: 0.9,
  },
  defaultIconSize: 0.4,
};

export const barbsLayerConfig = {
  ...gridLayerStepConfig,
  defaultIconSize: 0.5,
  iconSizeRange: {
    min: 0.4,
    max: 1,
  },
};

export const aviationLayerConfig = {
  /**
   * Represents the size of available size limits for the aviation layers.
   */
  sizeLimits: { min: 10, max: 34, step: 1 },
  defaultSize: 14,
};

export const wmsLayerConfig = {
  defaultLodBias: 0, // devicePixelRatio < 2 ? 0 : 1,
};

export const stationLayerConfig = {
  defaultTextSize: 15,
  textSizeLimits: { min: 10, max: 20 },
};

//  for poiLayer
export const iconLayerConfig = {
  defaultTextSize: 15,
  textSizeLimits: { min: 10, max: 20 },
  elementSizeRange: {
    min: 0.095,
    max: 0.3,
  },
};

export const lightningLayerConfig = {
  defaultTextSize: 30,
  defaultColor: "yellow",
  elementSizeRange: { min: 10, max: 70 },
};

export const tropicalCycloneLayerConfig = {
  defaultLineWidth: 1,
  defaultLineColor: "red",
};

export const backgroundLayerConfig = {
  defaultLineColor: "#000000",
};

export const customGeoJsonLayerConfig = {
  defaultUnit: Units.kilometers,
  defaultLineSegmentLength: 100,
  lineSegmentLimits: { min: 1, max: 200 },
  defaultLineSegmentUnit: Units.kilometers,
  defaultLineWidth: 1,
  lineWidthLimits: { min: 1, max: 10 },
};

import type { SeriesLegendItemClickEventObject } from "highcharts";

export const setChartOffsetDependingOnSerieIcon = (e: SeriesLegendItemClickEventObject) => {
  e.browserEvent?.stopImmediatePropagation();
  e.browserEvent?.preventDefault();
  e.preventDefault?.();
  const IconSerieId = e.target.options.id;
  // this is previous state, so we assume, that current state is !visible
  const show = !e.target.options.visible;

  if (IconSerieId) {
    const series = e.target.chart.userOptions?.series ?? [];
    const leftVisibleIcons = series.map((serie) => {
      // change visible for id
      if (IconSerieId === serie.id) {
        serie.visible = show;
      }
      return serie;
    });
    // @ts-ignore
    e.target.chart.options?.customDispatch(e.target.chart.options?.customSetSeries(leftVisibleIcons));
  }
};

import {
  type AsyncResult,
  createNotRequestedAsyncResult,
  createPendingAsyncResult,
  createResolvedAsyncResult,
} from "@/cache/AsyncResult";
import {
  type CacheDatum,
  CacheDatumState,
  type LoadedCacheDatum,
  type PermanentlyFailedCacheDatum,
} from "@/cache/GlobalCache";
import { apiThreadPool } from "@/cache/SpatioTemporalTileCache/ApiQueryThreadPool";
import { FailureState, type RetryState, createRetryState } from "@/cache/SpatioTemporalTileCache/RetryState";
import { type Stats, jsxTable } from "@/cache/Stats";

import type { InitialTimeRequest, JSONInitDate } from "@mm/api.meteomatics.com";
import type { MeteomaticsApiError } from "@mm/api.meteomatics.com/lib/error";
import { type Abort, isAbortable } from "@mm/api.meteomatics.com/lib/middleware";
import { cancelable } from "../utils";

export interface InitDateCacheStats {
  count: number;
  pending: number;
}

export const emptyJSONInitDate: JSONInitDate = {
  //TODO think of something better
  parameter: "",
  model: "",
  initDate: "",
};

export class InitDateCache implements Stats<InitDateCacheStats> {
  protected cache: { [key: string]: CacheDatum<JSONInitDate, RetryState> } = {};

  constructor(private readonly label_: string) {}

  stats(): InitDateCacheStats {
    let count = 0;
    let pending = 0;
    const cache = this.cache;

    for (const line in cache) {
      if (Object.hasOwn(cache, line)) {
        count++;

        if (cache[line].state === CacheDatumState.Pending) {
          pending++;
        }
      }
    }

    return { count, pending };
  }

  descJsx(): JSX.Element {
    return <>Special-purpose cache for API JSON Requests. Replacement Policy is LRU.</>;
  }

  label(): string {
    return this.label_;
  }

  statsJsx(): JSX.Element {
    return jsxTable(this.stats(), (key, val) => val);
  }

  /**
   * A method to return a cached data from cache.
   * It does not trigger a new request when the given request hasn't been emitted.
   * @param request
   * @param kind
   * @returns
   */
  peekCache(cacheId: string): AsyncResult<JSONInitDate> {
    const v = this.cache[cacheId];
    if (!v) {
      return createNotRequestedAsyncResult(emptyJSONInitDate);
    }
    switch (v.state) {
      case CacheDatumState.Pending: {
        const retry_ = v.payload;
        if (retry_.failureState !== FailureState.FailedTemporarily) {
          return createPendingAsyncResult(v.promise);
        }
        // since the current invocation moves the state from [Pending/FailedTemporarily] to [Pending/Queued]
        // mark it as such to prevent concurrent invocations from reentering the remaining function body
        retry_.failureState = undefined;
        return createPendingAsyncResult(v.promise);
      }
      case CacheDatumState.PermanentlyFailed:
        return createResolvedAsyncResult(emptyJSONInitDate);
      case CacheDatumState.Loaded:
        return createResolvedAsyncResult(v.payload);
      default: {
        const _exhaustive: never = v;
        return _exhaustive;
      }
    }
  }

  retrieveInitDate(request: InitialTimeRequest): AsyncResult<JSONInitDate> {
    const cacheId = `initDate${request.parameters[0]}${request.model}${request.validDate}`;

    if (Object.hasOwn(this.cache, cacheId)) {
      return this.peekCache(cacheId);
    }

    const retry_: RetryState | null = null;
    const retry = retry_ ?? createRetryState();
    const cancelablePromise = cancelable(
      apiThreadPool
        .getInitTime(request)
        .then((data) => {
          let result: any;
          if (data.initTimes[0].initTime.includes("00-00-00")) {
            result = {
              parameter: request.parameters[0],
              model: request.model,
              initDate: "init time not assignable to requested time",
            };
          } else {
            result = { parameter: request.parameters[0], model: request.model, initDate: data.initTimes[0].initTime };
          }

          const { requestDatetime } = this.cache[cacheId];
          const cacheData: LoadedCacheDatum<JSONInitDate> = {
            requestDatetime,
            lastUsed: Number.NEGATIVE_INFINITY,
            state: CacheDatumState.Loaded,
            payload: result,
          };
          this.cache[cacheId] = cacheData;
          return result;
        })
        .catch((e: MeteomaticsApiError | Abort) => {
          if (isAbortable(e)) {
            delete this.cache[cacheId];
            return Promise.reject(e);
          }
          return this.reactOnMeteomaticsApiError(e, retry, cacheId);
        }),
    );

    this.cache[cacheId] = {
      requestDatetime: performance.now(),
      state: CacheDatumState.Pending,
      promise: cancelablePromise.promise,
      cancel: cancelablePromise.cancel,
      payload: retry,
    };
    return createPendingAsyncResult(cancelablePromise.promise, cancelablePromise.cancel);
  }

  reactOnMeteomaticsApiError(e: MeteomaticsApiError, retry: RetryState, cacheId: string): Promise<never> {
    switch (e.kind) {
      case "BoundsError":
      case "TimeError":
      case "ComputationTimeoutError":
      case "AuthError":
      case "UnknownError":
      case "UnknownParameterError":
      case "MissingDataError": {
        const { requestDatetime } = this.cache[cacheId];
        const cacheData: PermanentlyFailedCacheDatum<null> = {
          requestDatetime,
          state: CacheDatumState.PermanentlyFailed,
          payload: null,
        };
        this.cache[cacheId] = cacheData;
        return Promise.reject(e);
      }
      default: {
        const _exhaustive: never = e;
        return _exhaustive;
      }
    }
  }
}

export enum InterpolationMode {
  /**
   * nominal or ordinal data that should not be interpolated.
   */
  NominalOrOrdinal_NearestNeighbour = 0,
  /**
   * Ratio or interval data that can be interpolated linearily.
   */
  IntervalOrRatio_Linear = 1,
  /**
   * Some other interpolation type, e.g. vector data
   */
  IntervalOrRatio_NonLinear = 2,
}

export function getSpatialInterpolationMode(parameter_unit: string) {
  // As a side note: parameters like `sat_rgb` are queried with unit `idx`, so they currently default to nearest neighbour.
  const parts = parameter_unit.split(":");
  const unit = parts[parts.length - 1];
  if (unit === "idx" || isPacked(parameter_unit)) {
    return InterpolationMode.NominalOrOrdinal_NearestNeighbour;
  }
  return InterpolationMode.IntervalOrRatio_Linear;
}

export function getTemporalInterpolationMode(parameter_: string) {
  // Default to NonLinear for now.
  return InterpolationMode.IntervalOrRatio_NonLinear;
}

// TODO: so, here it is noticable that the answer to the question "Can this parameter be interpolated",
// depends on the data representation. So, for example: satellite data is just an rgb color and thus
// spatially and temporally interpolatable -- however the data is packed into a datagram that is not
// interpolatable.
// Our internal tool, metx performs spatial interpolation before unpacking, so we would like to answer
// no there. But restructuring of the *consumer code* in metx to unpack first, would imply that the
// answer should be no.
export function isPacked(parameter_unit: string) {
  return parameter_unit.startsWith("sat_rgb") || parameter_unit.startsWith("sat_rgb_hd");
}

import type { LayerUnion } from "@/layers";
import type { LayerKind, LayerWithParameterUnit } from "@/layers/utility/createLayerObject";
import { EnsembleMemberSelect } from "@/overlay/components/Ensamble/EnsembleMemberSelect";
import { ModelSelect } from "@/overlay/components/LayerStackWindow/LayerFormElements";
import {
  type ParameterEditCallback,
  type ParameterRemoveCallback,
  WeatherParameterForm,
} from "@/overlay/components/WeatherParameterForm";
import { setLayersProps } from "@/reducer/MapsState";
import { startsWithExtraPath } from "@/route";
import * as route from "@/route";
import { getSearchEngine, useWeatherParamModels, useWeatherParams } from "@/weather-parameters";
import type { ModelSchema } from "@mm/api-layers.meteomatics.com";
import type { MapId } from "@mm/metx-workbench.meteomatics.com";
import Logger from "logging";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import * as weatherParameter from "weather-parameter-utils";
import type { ExactMatchResult, HasLayerIdxNarrowing, PartialWeatherParameter } from "weather-parameter-utils";

const logger = Logger.fromFilename(__filename);

/**
 *  Temporary model list to hide in production but not for /beta endpoint.
 *  This should be done later in the api-layers.meteomatics.com repo.
 *  Because this model may only be used for showcases and / or still has some restrictions
 *  TODO: remove this when we are able to show different models for customer and developers over api-layers ___rm_or_refactor_if_it_is_in_api-layers___
 *  all areas are marked with ___rm_or_refactor_if_it_is_in_api-layers___
 */
export const hiddenModelList: string[] = ["mm-singapore1k-demo"];

export function ModelAndWeatherParameterForm(props: {
  layer: LayerWithParameterUnit;
  mapId: MapId;
  onEditParameter?: ParameterEditCallback;
  onRemoveParameter?: ParameterRemoveCallback;
}) {
  const dispatch = useDispatch();
  const { layer } = props;
  const weatherParams = useWeatherParams();
  const [currentParam, setCurrentParam] = useState<PartialWeatherParameter | null>(null);
  const weatherModels = useWeatherParamModels();
  const [ensModels, setEnsModels] = useState<ModelSchema[] | null>(null);
  const [models, setModels] = useState<ModelSchema[] | null>(null); // wait for weatherModels
  const [selectedModelSchema, setSelectedModelSchema] = useState<ModelSchema | null>(null);
  const match = useRouteMatch();

  useEffect(() => {
    if ("parameter_unit" in layer && weatherModels) {
      const filteredModelByParameter = weatherModels.filter((model) =>
        currentParam ? currentParam.parameter.available_models?.includes(model.identifier) : false,
      );
      let filteredModelByPath = filteredModelByParameter;
      // filter on not /beta and not /ncm paths
      // TODO: ___rm_or_refactor_if_it_is_in_api-layers___
      if (
        startsWithExtraPath(match.path) !== route.pageBeta.asString() &&
        startsWithExtraPath(match.path) !== route.pageNcm.asString()
      ) {
        // filters all models that start with the string in the list
        filteredModelByPath = filteredModelByParameter.filter(
          (model) => !hiddenModelList.some((hiddenModel) => model.identifier.startsWith(hiddenModel)),
        );
      }

      setModels(filteredModelByPath);

      setEnsModels(weatherModels.filter((model) => model.num_ensemble_members));
      const modelSchema = weatherModels?.find((model) => model.identifier === layer.model);
      setSelectedModelSchema(modelSchema ? modelSchema : null);
    }
  }, [layer, weatherModels, currentParam, match]);

  useEffect(() => {
    // TODO-StationFeature:
    // Support the station layer configuration.
    // LayerConfigPanel needs PartialWeatherParameter to get the config options.
    // But if we use the standard param search engines,
    // we cannot retrieve the PartialWeatherParameter object for station layers. We could switch the search engine
    // when the layer is staion layer, but this doesn't scale when adding lightning and tropical cyclone layers.
    // It needs better solution to retrieve the PartialWeatherParameterObject. Maybe pass in a search engine object or store
    // the config info in Redux layer object?

    const searchEngine = getSearchEngine(layer);
    if (!searchEngine || !layer.parameter_unit) {
      return;
    }
    searchEngine
      .search(layer.parameter_unit)
      .then((response) => {
        if (response.results?.regular?.[0]) {
          const result: ExactMatchResult = response.results.regular[0];
          setCurrentParam(
            weatherParameter.autofill({
              parameter: result.layerGroup,
              narrowed_selection: {
                layerIdx: result.matchingLayers[0].layerIdx,
                // biome-ignore lint/style/noNonNullAssertion: TODO groups can be null, multiple places lack of handling
                fields: result.matchingLayers[0].match.groups!,
              },
            }),
          );
        } else {
          // TODO: Feedback to user, show a available parameter or hide the old Layer
          logger.error(`no exactMatchResult for term   ${layer.parameter_unit} errored out.`);
        }
      })
      .catch((e) => {
        // TODO: errors here are most likely reoccuring. We should probably show an error in this case instead of logging.
        // However, any error here renders the application unusable... So, adding CI tests to prevent this is most likely
        // a better option than a nice error message.
        logger.error(`search for term probably close to ${layer.parameter_unit} errored out.`, e);
      });
  }, [layer]);

  function changeProps(propsValue: Partial<LayerWithParameterUnit>) {
    let newPropsValue: Partial<LayerUnion> = { ...propsValue };
    // check if ens_select there and changePropsValue is with model
    if (
      props.layer.ens_select &&
      propsValue.model &&
      ensModels != null &&
      !ensModels.some((ensModel) => propsValue.model === ensModel.identifier)
    ) {
      //reset ens_select
      newPropsValue = { ...newPropsValue, ens_select: "" };
    }
    if (
      // model switch, if ens not saved and is available in new ens model
      (!props.layer.ens_select &&
        ensModels != null &&
        ensModels.some((ensModel) => propsValue.model === ensModel.identifier)) ||
      // model switch ens to other ens model with check if ens_select available in new ens model
      (props.layer.ens_select &&
        propsValue.model &&
        ensModels != null &&
        ensModels.some((ensModel) => propsValue.model === ensModel.identifier) &&
        ensModels.some((ensModel) => props.layer.model === ensModel.identifier))
    ) {
      //set to empty string '' => member:0
      newPropsValue = { ...newPropsValue, ens_select: "member:0" };
    }

    dispatch(setLayersProps({ layerId: props.layer.id, id: props.mapId.map, props: newPropsValue }));
  }
  function update(param: HasLayerIdxNarrowing<PartialWeatherParameter>) {
    if (currentParam && weatherParams) {
      const newCurrentParam = weatherParameter.autofill(param);
      const parameter = weatherParameter.parameterToString(newCurrentParam)?.formatted;
      // TODO: check if new Parameter has other Models then befor if so change the select of model, models options anyway needs to be updated
      if (parameter) {
        dispatch(
          setLayersProps({ layerId: props.layer.id, id: props.mapId.map, props: { parameter_unit: parameter } }),
        );
        setCurrentParam(newCurrentParam);
      } else {
        logger.warn("Could not change parameter_unit of layer");
      }
    }
  }
  if (weatherModels == null || weatherParams == null) {
    return <></>;
  }

  return (
    <>
      {models && <ModelSelect models={models} layerModelIdentifier={layer.model} change={changeProps} />}
      {currentParam && (
        <WeatherParameterForm
          key={layer.id}
          layerKind={layer.kind as LayerKind}
          autofill={true}
          showExactParameter={true}
          parameter={currentParam}
          onChange={update}
          onEditParameter={props.onEditParameter}
          onRemoveParameter={props.onRemoveParameter}
        />
      )}
      {selectedModelSchema?.num_ensemble_members && (
        <EnsembleMemberSelect
          ensSelect={layer.ens_select}
          selectedModelSchema={selectedModelSchema}
          onChange={changeProps}
          allowMultipleEnsSelect={layer.kind === "IsoLinesLayerDescription" && layer.experimental}
        />
      )}
    </>
  );
}

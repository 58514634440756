import { debounce } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { type Control, useWatch } from "react-hook-form";

import type { FilterAndSorterFormTypes } from "@/dashboard/types";

export function useDebounceQueryFilter(control: Control<FilterAndSorterFormTypes>) {
  const query = useWatch({ control, name: "query" });
  const [debouncedQuery, setDebouncedQuery] = useState(query);

  const debounceQueryValue = useMemo(
    () => debounce((value: FilterAndSorterFormTypes["query"]) => setDebouncedQuery(value), 250),
    [],
  );

  useEffect(() => {
    debounceQueryValue(query);
  }, [query, debounceQueryValue]);

  return debouncedQuery;
}

import { useCallback, useEffect, useState } from "react";
import type { DraggableItem } from ".";
import { Card } from "./Card";
import "./style.scss";

export type ListContainerProps = {
  className?: string;
  itemType: string; // If two lists have the same item type, items can be dragged to both lists. Two ListContainers have to be under the same DnD provider.
  items: DraggableItem[];
  onDropItem: (
    dragIndex: number,
    dropIndex: number,
    dragItemId: DraggableItem["id"],
    dropItemId: DraggableItem["id"],
  ) => void; //Additional swap callback to run when items are swapped on the list.
};

export function ListContainer(props: ListContainerProps) {
  // Create a internal state to keep track of visual item positions.
  const [cards, setCards] = useState(props.items);
  const [dragStartIndex, setDragStartIndex] = useState<null | number>(null);
  const { onDropItem, items } = props;

  useEffect(() => {
    // Update the list internal state with the prop items if the parent passes a new set of items.
    setCards(props.items);
  }, [props.items]);

  const moveInternalItemStates = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      // Memorize the initial position of the dragged item so that we can pass on the callback on drop.
      if (dragStartIndex === null) {
        setDragStartIndex(dragIndex); //Set the index of the dragged item.
      }
      // // Re-order the items in the internal state to update the card order.
      const copiedCards = [...cards];
      // Move the card from the drag index to the hover index.
      const targetCard = copiedCards.splice(dragIndex, 1)[0];
      copiedCards.splice(hoverIndex, 0, targetCard);
      setCards(copiedCards);
    },
    [cards, dragStartIndex],
  );

  const onDropItem_Internal = useCallback(
    (dropTargetIndex: number) => {
      // Pass the dragged item's original index and the index where the item is dropped to the callback.
      if (dragStartIndex !== null) {
        onDropItem(dragStartIndex, dropTargetIndex, items[dragStartIndex].id, items[dropTargetIndex].id);
      }
      setDragStartIndex(null);
    },
    [dragStartIndex, setDragStartIndex, onDropItem, items],
  );

  return (
    <ul className={`draggable-list ${props.className ?? ""}`}>
      {cards.map((item, i) => (
        <Card
          key={item.id}
          index={i}
          id={item.id}
          content={item.content}
          onHoverNewItem={moveInternalItemStates}
          onDropItem={onDropItem_Internal}
          itemType={props.itemType}
        />
      ))}
    </ul>
  );
}

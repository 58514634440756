import type { ModelSchema } from "@mm/api-layers.meteomatics.com";
import { useMemo } from "react";
import type { ModelSearchEngine, PartialWeatherParameter } from "weather-parameter-utils";

/**
 * Given a weather parameter, returns all of its available models as instantiated ModelSchema
 * which can be used to derive detail information about the models.
 *
 */
export function usePartialWeatherParameterModels(
  parameter: PartialWeatherParameter,
  modelSearchEngine: ModelSearchEngine,
  sortAlphabetically = true,
): ModelSchema[] {
  const availableModels: ModelSchema[] = useMemo(() => {
    const modelIdentifiers = parameter.parameter.available_models ?? [];
    const modelSchemas: (ModelSchema | null)[] = modelIdentifiers.map((identifier) => {
      return modelSearchEngine.matchModelExactly(identifier);
    });
    const models = modelSchemas.filter((modelSchemaOrNull) => modelSchemaOrNull !== null) as ModelSchema[];

    if (sortAlphabetically) {
      models.sort((a, b) => {
        if (a.identifier === "mix") return -1;
        if (b.identifier === "mix") return 1;
        return a.name.localeCompare(b.name);
      });
    }

    return models;
  }, [parameter.parameter.available_models, modelSearchEngine, sortAlphabetically]);
  return availableModels;
}

import { useFindCustomGeoJsonById } from "@/api/hooks/customGeojson";
import { groundStyles } from "@/constants/maptilerStyleOptions";
import type { LayerUnion, LayerUnionWithAll } from "@/layers";
import type { AviationTypes } from "@/layers/utility/aviationType";
import { allAviationProperties } from "@/layers/utility/aviationValues";
import { LayerKindName, allLayerModes } from "@/layers/utility/createLayerObject";
import { LayerConfig } from "@/overlay/components";
import { ComponentSwitcher } from "@/overlay/components/CommonParts/ComponentSwitcher";
import { DeleteConfirmationTrigger } from "@/overlay/components/CommonParts/DeleteConfirmationTrigger";
import { ToggleLegend } from "@/overlay/components/CommonParts/ToggleLegend";
import { Button } from "@/overlay/components/CommonParts/buttons";
import { layerToUpdateControllerMapping } from "@/overlay/components/SmartForm/LayerForms/LayerFormMapping";
import { v2LayerUpdateFormSupported } from "@/overlay/components/SmartForm/LayerForms/supportCheckerLayer";
import { setLayersProps } from "@/reducer/MapsState";
import type { PoiOptions } from "@/reducer/client-models";
import type { PoiLayer } from "@/reducer/client-models/PoiLayer";
import { classNames } from "@/utility/jsx";
import { Trans, t } from "@lingui/macro";
import type {
  AviationLayer,
  BackgroundMapLayer,
  BarbsLayer,
  CartographicMap,
  CustomGeoJSONLayer,
  GenericPoiLayer,
  IsoLinesLayer,
  MapId,
  TropicalCycloneLayer,
  WmsLayer,
} from "@mm/metx-workbench.meteomatics.com";
import { useState } from "react";
import { useDispatch } from "react-redux";
import "./style.scss";

export function LayerExpansionHeader(props: {
  isOpen: boolean;
  setIsOpen: (v: boolean) => void;
  layer: LayerUnionWithAll;
  mapId: MapId;
}) {
  const dispatch = useDispatch();

  const mode = allLayerModes.find((l) => l.kind === props.layer.kind);

  if (!mode) {
    return <></>;
  }

  const toggle = () => props.setIsOpen(!props.isOpen);

  function changeProps(propsValue: Partial<LayerUnion>) {
    dispatch(setLayersProps({ layerId: props.layer.id, id: props.mapId.map, props: propsValue }));
  }

  let label: JSX.Element;
  let element_color = "#000000";
  switch (mode.kind) {
    case LayerKindName.BackgroundMapDescription: {
      const backgroundLayer = props.layer as BackgroundMapLayer;
      const groundStyle = groundStyles.find((styleLayer) => styleLayer.id === backgroundLayer.style);
      label = <span className="layer-label__kindName">{groundStyle?.label() ?? backgroundLayer.style}</span>;
      break;
    }
    case LayerKindName.CustomGeoJSONLayerDescription: {
      const customGeoJsonLayer = props.layer as CustomGeoJSONLayer;
      element_color = customGeoJsonLayer.custom_options.line_color
        ? customGeoJsonLayer.custom_options.line_color
        : "#000000";
      label = (
        <span className="layer-label__kindName">
          <GeoJSONFileName fileId={customGeoJsonLayer.file_id} />
        </span>
      );
      break;
    }
    case LayerKindName.AviationLayerDescription: {
      const dataLayer = props.layer as AviationLayer;
      label = (
        <span className="layer-label__kindName layer-label__kindName--parameter">
          <Trans>Aviation Data</Trans>
          &nbsp;{allAviationProperties[dataLayer.aviation_type as AviationTypes]?.name()}
        </span>
      );
      break;
    }
    case LayerKindName.GenericPoiLayerDescription: {
      const dataLayer = props.layer as GenericPoiLayer;
      const poiOptions = dataLayer.poiOptions as PoiOptions;
      element_color = poiOptions.item.color;
      label = (
        <span className="layer-label__kindName layer-label__kindName--parameter">
          <Trans>Icon/Text</Trans>
        </span>
      );
      break;
    }
    case LayerKindName.TropicalCycloneLayerDescription: {
      const dataLayer = props.layer as TropicalCycloneLayer;
      label = (
        <span className="layer-label__kindName layer-label__kindName--parameter">
          {dataLayer.aviation_type.replace("tropical_cyclone_", "")}
        </span>
      );
      break;
    }
    case LayerKindName.WindAnimationLayerDescription: {
      label = (
        <span className="layer-label__kindName layer-label__kindName--parameter">
          <Trans>Wind Animation</Trans>
        </span>
      );
      break;
    }
    case LayerKindName.BarbsLayerDescription: {
      const dataLayer = props.layer as BarbsLayer;
      element_color = dataLayer.element_color;
      label = (
        <span className="layer-label__kindName layer-label__kindName--parameter" title={dataLayer.parameter_unit}>
          {dataLayer.parameter_unit}
        </span>
      );
      break;
    }
    case LayerKindName.IsoLinesLayerDescription: {
      const dataLayer = props.layer as IsoLinesLayer;
      element_color = dataLayer.line_color;
      label = (
        <span className="layer-label__kindName layer-label__kindName--parameter" title={dataLayer.parameter_unit}>
          {dataLayer.parameter_unit}
        </span>
      );
      break;
    }
    default: {
      const dataLayer = props.layer as Exclude<
        LayerUnion,
        BackgroundMapLayer | PoiLayer | AviationLayer | TropicalCycloneLayer | CustomGeoJSONLayer
      >;
      label = (
        <span className="layer-label__kindName layer-label__kindName--parameter" title={dataLayer.parameter_unit}>
          {dataLayer.parameter_unit}
        </span>
      );
      break;
    }
  }

  return (
    <div className={`expansion-panel__header ${!props.layer.show ? "hidden" : ""}`} data-testid={props.layer.kind}>
      <div data-testid="layer-expansion-toggle" className="expansion-panel__header__layer-info" onClick={toggle}>
        <span className="expansion-panel__chevron">
          <span className="material-icons">{props.isOpen ? "expand_less" : "expand_more"}</span>
        </span>
        <span className="layer-label">
          <span className="layer-tag__container">
            {/* Quick workaround while we have separate isolines for ensembles. TODO Cleanup when complete renaming will be made */}
            {props.layer.experimental && props.layer.kind !== "IsoLinesLayerDescription" && (
              <span className="tag secondary">{t`Experimental`}</span>
            )}
            <span className="layer-label__kindName tag">
              {props.layer.experimental && props.layer.kind === "IsoLinesLayerDescription"
                ? t`Isolines Ensemble`
                : mode.name()}
              {element_color !== "#000000" ? (
                <span className="tag-param-color" style={{ backgroundColor: element_color }} />
              ) : null}
            </span>
          </span>
          <span className="layer-label__kindName">{label}</span>
        </span>
      </div>
      <div className="expansion-panel__header__right-control">
        {(props.layer as WmsLayer).legend_visible !== undefined && props.layer.show && (
          <mm-tooltip-wrapper
            tail={true}
            position="top"
            tooltip-text={(props.layer as WmsLayer).legend_visible ? t`Hide legend` : t`Show legend`}
          >
            <ToggleLegend
              id={`layer_${props.layer.id}`}
              legendVisible={(props.layer as WmsLayer).legend_visible}
              onToggle={(enabled) => changeProps({ legend_visible: enabled })}
            />
          </mm-tooltip-wrapper>
        )}
        <mm-tooltip-wrapper tail={true} position="top" tooltip-text={!props.layer.show ? t`Show` : t`Hide`}>
          <Button
            icon={props.layer.show ? "visibility" : "visibility_off"}
            onClick={(_event) => changeProps({ show: !props.layer.show })}
          />
        </mm-tooltip-wrapper>
        <mm-tooltip-wrapper tail={true} position="top-left" tooltip-text={t`Delete`}>
          <DeleteConfirmationTrigger
            element={{
              type: "layer",
              tabId: props.mapId.tab,
              item: props.layer,
              label: t`Do you want to delete layer "${mode.name()}"?`,
            }}
          >
            <Button data-testid="layer-delete-button" icon="delete" />
          </DeleteConfirmationTrigger>
        </mm-tooltip-wrapper>
      </div>
    </div>
  );
}

export function LayerExpansionPanel(props: {
  layer: LayerUnionWithAll;
  mapId: MapId;
  cartographicMap: CartographicMap;
}) {
  const [isOpen, setIsOpen] = useState(false);
  // TODO: split into components here is weird. there is a div called `expansion-panel__header` with buttons
  // that contains a LayerExpansionHeader component, that just contains a string. The div and buttons should
  // clearly be part of the LayerExpansionHeader component.
  return (
    <div
      className={classNames("expansion-panel box-section--breakout actionable", isOpen && "expansion-panel--expanded")}
      key={props.layer.id}
    >
      <div className="box-section--breakout">
        <LayerExpansionHeader {...props} isOpen={isOpen} setIsOpen={setIsOpen} />
        {isOpen && (
          <div>
            <div
              className="expansion-panel__content"
              // Prevent the drag event to bubble up to the SortableList so the dragging event doesn't fire on the config panel.
              draggable={true}
              onDragStart={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              {v2LayerUpdateFormSupported(props.layer) ? (
                <ComponentSwitcher
                  key={props.layer.id}
                  componentProps={props}
                  componentMapping={layerToUpdateControllerMapping}
                />
              ) : (
                <LayerConfig key={props.layer.id} {...props} />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function GeoJSONFileName({ fileId }: { fileId: string }) {
  const { data, isLoading } = useFindCustomGeoJsonById(fileId);

  if (isLoading) {
    return <></>;
  }

  if (!data) {
    return <Trans>File not found</Trans>;
  }

  return <>{data.name}</>;
}

import type { Authentication } from "./auth";
import type { ConnectionManagement } from "./connectionManagement";

export const DEFAULT_BASE_URL: string = "https://api.meteomatics.com";

export interface MeteomaticsApiConfiguration<A extends Authentication> {
  /**
   * Authentication method.
   *
   * See `NoAuthentication`, `BasicAuthentication` `BearerAuthentication` and `AccessTokenAuthentication` for
   * for some common authentication variants.
   */
  authentication: A;
  /**
   * Base URL in case you use a proxy to work around CORS or other issues. Defaults to `DEFAULT_BASE_URL`.
   *
   * URLs MUST be absolute in NodeJs, but are allowed to be relative in browsers.
   *
   * You can also provide a function that returns a baseUrl to implement custom load balancing strategies.
   *
   * This is useful if you have a lot of parallel requests in flight since browsers limit the number of parallel requests per host.
   * Chrome, for example, has a limit of 6 parallel requests.
   *
   * We provide several connection management strategies for more advanced usages. See `ConnectionManagement` for details.
   */
  baseUrl?: string | string[] | ConnectionManagement;
}

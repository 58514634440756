import { QueryCache, QueryClient, type QueryKey, type QueryState } from "@tanstack/react-query";
import { type Stats, jsxTable } from "../Stats";

interface CacheStats {
  count: number;
  pending: number;
}

export class GenericCache implements Stats<CacheStats> {
  private queryClient: QueryClient;
  constructor(private readonly label_: string) {
    this.queryClient = new QueryClient({
      queryCache: new QueryCache(),
    });
  }

  statsJsx(): JSX.Element {
    return jsxTable(this.stats(), (val) => val);
  }

  descJsx(): JSX.Element {
    return <>Special-purpose cache for Energy Plot JSON Requests. Replacement Policy is LRU.</>;
  }

  label(): string {
    return this.label_;
  }

  stats(): CacheStats {
    const cache = this.queryClient.getQueryCache();
    const queries = cache.getAll();
    const count = queries.length;
    const pending = queries.filter((query) => query.state.status === "loading").length;

    return { count, pending };
  }

  retrieve<TQueryFnData, TError, TData>(key: QueryKey, queryFn: () => Promise<TQueryFnData>): Promise<TData> {
    return this.queryClient.fetchQuery<TQueryFnData, TError, TData>(key, queryFn, {
      staleTime: Number.POSITIVE_INFINITY,
    });
  }

  getQueryState<TData = unknown, TError = unknown>(key: QueryKey): QueryState<TData, TError> | undefined {
    return this.queryClient.getQueryState<TData, TError>(key);
  }
}

import { type TimeStateMachine, defaultTimeStateMachine } from "@/models/time-control/timeStateMachine";
import { useInterpret } from "@xstate/react";
import { type PropsWithChildren, createContext, useEffect } from "react";
import type { InterpreterFrom } from "xstate";
import { saveTimeStateDesc } from "./timeStateApiHandlers";

export const TimeStateMachineContext = createContext<StateMachineContext>({
  timeStateService: {} as InterpreterFrom<TimeStateMachine>,
});

type StateMachineContext = {
  timeStateService: InterpreterFrom<TimeStateMachine>;
};

export const StateMachineProvider = (props: PropsWithChildren<any>) => {
  const timeStateService = useInterpret(defaultTimeStateMachine, { devTools: true });

  useEffect(() => {
    const sub = timeStateService.subscribe((state) => {
      saveTimeStateDesc(state).catch((e) => console.error(e));
    });
    return () => sub.unsubscribe();
  }, [timeStateService]);

  return (
    <TimeStateMachineContext.Provider value={{ timeStateService }}>{props.children}</TimeStateMachineContext.Provider>
  );
};

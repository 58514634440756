import { type StateMachine, createMachine } from "xstate";

import { updateLoadingState } from "./loadingStateActions";
import type { LoadingStateEventUnion } from "./loadingStateEvents";
import type { DefaultLoadingCtx } from "./loadingStateMachineCtxTypes";

type LoadingContextUnionTypeState = {
  value: "DEFAULT";
  context: DefaultLoadingCtx;
};

export type LoadingStateMachine = StateMachine<
  DefaultLoadingCtx,
  any,
  LoadingStateEventUnion,
  LoadingContextUnionTypeState
>;

export const createLoadingStateMachine = (
  /**
   * Loadingering state machine is generic. Namespace allows us to create separate
   * instance of the machine so we can keep track of different kinds of items (e.g: Map, plot, etc).
   */
  namespace: string,
  initialCtx: DefaultLoadingCtx = { stateById: {} } as DefaultLoadingCtx,
  initialState = "DEFAULT",
): LoadingStateMachine => {
  return createMachine<DefaultLoadingCtx, LoadingStateEventUnion, LoadingContextUnionTypeState>({
    id: `REBUFFERING_STATE.${namespace.toUpperCase()}`,
    initial: initialState,
    context: initialCtx,
    states: {
      DEFAULT: {
        on: {
          updateLoadingState: {
            internal: true,
            actions: [updateLoadingState],
          },
        },
      },
    },
    predictableActionArguments: true,
  });
};

export const defaultMapLayerLoadingMachine = createLoadingStateMachine("MAP_LAYER");

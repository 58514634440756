import { _debug_all_layers_from_used_scenes } from "@/layers/Compositor";
import { setTimeList } from "@/time";
import type { DateTime, Duration, Interval } from "luxon";

type Frame = {
  timestamp: DateTime;
  loaded: boolean;
};

/* <- length ->                  */
/* |#|#|#|#|#|#| | | | | | | | | */
class FramesSeeker {
  private _position = 0;

  constructor(
    readonly frames: Frame[],
    readonly lenght: number,
  ) {}

  nextSlice(): Frame[] {
    const framesSlice = this.frames.slice(this._position, this._position + this.lenght);

    this._position += this.lenght;

    return framesSlice;
  }
}

export class PrefetchPool {
  private disposed = false;
  private seeker: FramesSeeker;
  private frames: Frame[] = [];

  constructor(
    interval: Interval,
    temporalResolution: Duration,
    readonly seekerLength = 2,
  ) {
    this.frames = setTimeList(interval, temporalResolution).map((time) => ({
      timestamp: time,
      loaded: false,
    }));

    this.seeker = new FramesSeeker(this.frames, seekerLength);
  }

  processPool() {
    if (this.disposed) {
      return;
    }
    const frames = this.seeker.nextSlice();

    if (!frames.length) {
      return;
    }

    const framePromise = frames.map((frame) => this._fetchFrame(frame));
    Promise.all(framePromise).then(() => {
      this.processPool();
    });
  }

  dispose() {
    this.disposed = true;
  }

  private _fetchFrame(frame: Frame) {
    return new Promise<void>((resolve) => {
      for (const layer of _debug_all_layers_from_used_scenes()) {
        layer.fetchData(frame.timestamp);
      }

      const checkRebufferingStatus = (frame: Frame) => {
        if (!this._isFrameRebuffering(frame)) {
          resolve();
          return;
        }

        requestAnimationFrame(() => checkRebufferingStatus(frame));
      };

      checkRebufferingStatus(frame);
    });
  }

  private _isFrameRebuffering(frame: Frame) {
    return _debug_all_layers_from_used_scenes().reduce((isRebuffering, layer) => {
      if (layer.isRebuffering(frame.timestamp) || isRebuffering) {
        return true;
      }

      return false;
    }, false);
  }
}

import type { MapStateCoord } from "@/models/map-interaction/mapInteractionStateCtxTypes";
import LocationSearchDropdown from "@/overlay/components/LayerStackWindow/LocationSearch/LocationSearchDropdown";
import { toCoordObj, toLocationObj, validateCoordStr } from "@/utility/validateCoordinates";
import { Trans, t } from "@lingui/macro";
import { useState } from "react";
import style from "./style.module.scss";

type LocationInputProps = {
  defaultValue: string;
  editCoord?: (coord: MapStateCoord) => void;
  editLocation?: (location: MapStateCoord & { label?: string }) => void; // TODO how to type it correctly? the same thing is in multiple places
};

/**
 * A coordinate input to edit an existing POI coordinate
 */
export function LocationInput(props: LocationInputProps) {
  const [validCoords, setValidCoords] = useState<boolean>(true);

  function checkAndEditCoordsString(coordsText: string) {
    const isValid = validateCoordStr(coordsText);
    setValidCoords(isValid);
    if (isValid) {
      props.editCoord?.(toCoordObj(coordsText));
      props.editLocation?.(toLocationObj(coordsText));
    }
  }

  return (
    <>
      <div className={style["point-label"]}>{t`Point`}</div>
      <LocationSearchDropdown
        onChangeLocation={checkAndEditCoordsString}
        defaultValue={props.defaultValue}
        onValidCoordinateBlur={checkAndEditCoordsString}
      />
      {validCoords ? null : <Trans>Please check the Coordinates</Trans>}
    </>
  );
}

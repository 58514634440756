export type GrayscaleColor = number;
export type RgbColor = [number, number, number];
export type RgbaColor = [number, number, number, number];
export type Color = GrayscaleColor | RgbColor | RgbaColor;

/**
 * Parse the hexadecimal representation of a color.
 *
 * For example, the following formats
 * - `222`,
 * - `#222`,
 * - `222222`,
 * - `222222`,
 * - `#222222`,
 * - `222222FF`,
 * - `#222222FF`,
 * - `222F`
 * - `#222F`
 * are all parsed to `[0.13,0.13,0.13,1.0]`.
 *
 * @return a rgba color normalized to `[0,1]` per channel. If alpha is one if not given in the input.
 */
export function parseHexString(hex: string): RgbaColor | null {
  let hexCode = hex;
  if (hexCode[0] === "#") {
    hexCode = hexCode.slice(1);
  }

  let rgbParts: any;
  if (hex.length === 3 || hex.length === 4) {
    rgbParts = hex.split("").map((chr) => chr + chr);
  } else {
    rgbParts = hex.match(/.{2}/g);
    if (rgbParts?.length !== 3 && rgbParts?.length !== 4) {
      return null;
    }
  }

  const rgba = [];

  for (const part of rgbParts) {
    const val = Number.parseInt(part, 16);
    if (val < 0 || val > 255) {
      return null;
    }
    rgba.push(val / 255);
  }

  if (rgba.length === 3) {
    rgba.push(1);
  }

  return rgba as RgbaColor; // TODO: unsafe cast
}

/**
 * Serialize color to a string that can be used in cascading style sheets.
 */
export function toCssColor(c: Color): { color: string; isOpaque: boolean } {
  const { color, isOpaque } = toRgbaColor(c, 255, 1.0, true, false);
  const [r, g, b, a] = color;

  let css: string;

  if (isOpaque) {
    css = `rgb(${r},${g},${b})`;
  } else {
    css = `rgba(${r},${g},${b}, ${a})`;
  }

  return { color: css, isOpaque };
}

export function toRgbaColor(
  c: Color,
  scaleRgb = 1,
  scaleA = 1,
  roundingRgb = true,
  roundingA = false,
): { color: RgbaColor; isOpaque: boolean } {
  let r: number;
  let g: number;
  let b: number;
  let a: number;

  if (Array.isArray(c)) {
    r = c[0];
    g = c[1];
    b = c[2];
    a = c[3] ?? 1.0;
  } else {
    r = c;
    g = c;
    b = c;
    a = 1.0;
  }

  const isOpaque = a >= 1.0;

  r *= scaleRgb;
  g *= scaleRgb;
  b *= scaleRgb;
  a *= scaleA;

  if (roundingRgb) {
    r = Math.round(r);
    g = Math.round(g);
    b = Math.round(b);
  }

  if (roundingA) {
    a = Math.round(a);
  }

  return { color: [r, g, b, a], isOpaque };
}

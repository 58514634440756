import type { AddParameterWindowProps } from "@/overlay/components/AddParameterWindow";
import type { CustomPopupProps } from "@/overlay/components/CustomPopupWindow/popup";
import type { DatetimeRangePickerProps } from "@/overlay/components/PlotDatetimeRangeWindow";
import type { RenameDashboardWindowProps } from "@/overlay/components/RenameDashboardWindow";
import type { ConfirmationWindowProps } from "../ConfirmationWindow";
import type { PoiManagerWindowProps } from "../PoiManagerWindow";
import type { WindowInstanceId } from "./DesktopContext";

/**
 * Window identifiers may be arbitrary strings and change at runtime. (This is useful if a window can
 * be opend an arbitrary number of times or if windows are dynamically generated at runtime.)
 *
 * These are just some type-safe wrappers for the strings available in the metx default overlay.
 */
export enum WindowKind {
  DatetimeSelection = "DatetimeSelection",
  DatetimeSlider = "DatetimeSlider",
  DatetimeLabel = "DatetimeLabel",
  Account = "Account",
  LayoutSettings = "LayoutSettings",
  LayerStack = "LayerStack",
  AddLayer = "AddLayer",
  AddParameter = "AddParameter",
  AddDashboard = "AddDashboard",
  RenameDashboard = "RenameDashboard",
  About = "About",
  Developer = "Developer",
  PopupPlot = "PopupPlot",
  Geolocator = "Geolocator",
  LocationSearch = "LocationSearch",
  DeleteConfirmation = "DeleteConfirmation",
  ConfirmationWindow = "ConfirmationWindow",
  FileWindow = "FileWindow",
  PlotDatetimeRange = "PlotDatetimeRange",
  PoiManagerWindow = "PoiManagerWindow",
  CustomPopup = "CustomPopup",
}

/**
 * Conditional type to define custom prop object that could be passed to windows
 * using "setWindowCustomProps" action.
 *
 * To enable customProps to a new component, define the prop type for each window here.
 * @example
 *     K extends WindowKind.<NewWindowKind> ? NewWindowCustomPropType :
 *     K extends WindowKind.<NewWindowKind2> ? NewWindowCustomPropType2 :
 *     EmptyObject
 *
 */
export type WindowCustomProps<K extends WindowKind> = Partial<
  K extends WindowKind.AddParameter
    ? AddParameterWindowProps
    : K extends WindowKind.PlotDatetimeRange
      ? DatetimeRangePickerProps
      : K extends WindowKind.ConfirmationWindow
        ? ConfirmationWindowProps
        : K extends WindowKind.RenameDashboard
          ? RenameDashboardWindowProps
          : K extends WindowKind.PoiManagerWindow
            ? PoiManagerWindowProps
            : K extends WindowKind.CustomPopup
              ? CustomPopupProps
              : EmptyObject
>;

type EmptyObject = {
  [property: string]: never;
};

export function instanceId(kind: WindowKind, refCount = 0): WindowInstanceId {
  return [kind, refCount].join(":");
}

/**
 * Converts some WebGL error or status code to a human readable label.
 *
 * For Example, checkFrameBufferStatus might return `36054`, which will
 * be converted to `"FRAMEBUFFER_INCOMPLETE_ATTACHMENT"` by this function.
 *
 * @return string on success, the original number on error
 */
export function constantByName(gl: WebGLRenderingContext, val: number): string | number {
  for (const k in gl) {
    if ((gl as any)[k] === val) {
      return k;
    }
  }

  return val;
}

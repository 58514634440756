import { SynchrounousState } from "@/cache/AsyncResult";
import type { ColorMapping } from "@/cache/ColorMapCache";
import { networkCaches } from "@/cache/GlobalCache";
import type { MultiWMSTile } from "@/cache/PVSTileService/TileGetters/MultiWMSTileGetter";
import type { WMSRequestParams } from "@/cache/PVSTileService/TileGetters/WMSTileGetter";
import type { SliceDataDescription } from "@/cache/SpatioTemporalTileCache/SliceDataDescription";
import type { AreaRequest } from "@/layers/AreaRequest";
import type { SceneLayerApi } from "@/layers/SceneLayerApi";
import { uploadColorMap } from "@/layers/wind/gl";
import {
  type TileMapRenderer,
  type WindParticlesRenderer,
  createTileMapRenderer,
  createWindParticlesRenderer,
} from "@/layers/wind/gl/renderers";
import {
  type WindLayerProperties,
  type WindLayerPropertySpecProp,
  type WindLayerPropertySpecValue,
  type WindLayerStyleOptions,
  defaultPropertySpec,
  defaultPropertyValues,
} from "@/layers/wind/layer";
import type { WindMetaData } from "@/layers/wind/types";
import { getModelBoundingBox } from "@/weather-parameters/lookup";
// @mapbox/mapbox-gl-style-spec doesn't have typescript typings, as library is written in flow
// some "patch" types are created in typings definition for code readability
import { type StylePropertyExpression, expression } from "@mapbox/mapbox-gl-style-spec";
import { Area, ColorMap, CoordinateSystem, WORLD_BBOX } from "@mm/api.meteomatics.com";
import type { WindAnimationLayer } from "@mm/metx-workbench.meteomatics.com";
import { cloneDeep, debounce } from "lodash";
import type { DateTime } from "luxon";
import type { CustomLayerInterface, Expression, LngLatBounds } from "mapbox-gl";
import { type ProjectionPlaneRenderer, createProjectionPlaneRenderer } from "../gl/renderers/projectionPlaneRenderer";
import { type SliceRenderer, createSliceRenderer } from "../gl/renderers/sliceRenderer";
import { adjustBoundsToModel } from "./utils";

const SLICE_SIZE = 256;

interface WindLayerParameters {
  id: string;
  options?: WindLayerStyleOptions;
}

export class WindLayer implements CustomLayerInterface {
  // We still need access to rendering context in some functions
  // In good scenario, that shouldn't be needed
  private _gl?: WebGLRenderingContext;
  private _map?: mapboxgl.Map;

  id: string;
  type = "custom" as const;
  renderingMode?: "2d" | "3d" | undefined = "3d";

  windMapTexture: WebGLTexture | null = null;
  colorMapTexture: WebGLTexture | null = null;
  projectionPlaneTexture: WebGLTexture | null = null;
  metadata?: WindMetaData;

  private _zoomUpdatable: WindLayerStyleOptions = {};
  private _pendingUpdates: WindLayerStyleOptions = {};

  private _layerProperties: WindLayerProperties = cloneDeep(defaultPropertyValues);
  private _props: WindAnimationLayer;

  private _frozen = false;

  // TODO Refactor WindLayer in next iteration
  // Renderer count is already out of hands
  // By using WebGL2 and "Transform Feedback" it's possible to create
  // universal particle solution, that will work in all projections,
  // will support 3d particles and remove all renderers
  // https://gpfault.net/posts/webgl2-particles.txt.html
  // Note: Mapbox will support webgl2 from 3.0 version
  private _windParticlesRenderer?: WindParticlesRenderer;
  private _tileMapRenderer?: TileMapRenderer;
  private _projectionPlaneRenderer?: ProjectionPlaneRenderer;
  private _sliceRenderer?: SliceRenderer;
  private _bounds?: LngLatBounds;

  private _projection: "mercator" | "globe" = "mercator";

  constructor(
    readonly scene: SceneLayerApi,
    props: WindAnimationLayer,
    { id, options }: WindLayerParameters,
  ) {
    this.id = id;
    this._props = props;
    // This will initialize the default values
    for (const spec of Object.keys(defaultPropertySpec) as WindLayerPropertySpecProp[]) {
      this.setProperty(spec, options?.[spec] || defaultPropertySpec[spec].default);
    }
  }

  updateWindMetadata(windData: WindMetaData) {
    this.metadata = windData;
  }

  setColorMap(colorMap: ColorMapping) {
    const gl = this._gl;

    if (gl) {
      this.colorMapTexture = uploadColorMap(gl, colorMap);
    }
  }

  refresh() {
    this._windParticlesRenderer?.clear();
    this._projectionPlaneRenderer?.clear();
  }

  onAdd?(map: mapboxgl.Map, gl: WebGLRenderingContext): void {
    this._gl = gl;
    this._map = map;

    const { width, height } = map.getCanvas();

    this._windParticlesRenderer = createWindParticlesRenderer(gl, width, height);
    this._tileMapRenderer = createTileMapRenderer(gl, this.scene);

    this._projectionPlaneRenderer = createProjectionPlaneRenderer(gl, width, height);
    this._updateAllProperties(this._pendingUpdates);
    this._updateAllProperties(this._zoomUpdatable);

    this._sliceRenderer = createSliceRenderer(gl, SLICE_SIZE, SLICE_SIZE);

    requestAnimationFrame(() => {
      this._bounds = adjustBoundsToModel(map.getBounds(), this._props.model);
      this._projectionPlaneRenderer?.updatePlane(this._bounds);
    });
  }

  updateBounds(bounds: LngLatBounds) {
    if (!this._projectionPlaneRenderer || !this._map) {
      return;
    }
    this._bounds = adjustBoundsToModel(bounds, this._props.model);
    this._projectionPlaneRenderer.updatePlane(this._bounds);
  }

  /**
   * Runs the first step of rendering pipeline
   * The whole rendering pipeline starts here.
   */
  // @ts-ignore
  prerender(
    _gl: WebGLRenderingContext,
    projectionMatrix: number[],
    projection: { name: string; center: [number, number] },
    globeToMercMatrix: number[],
    transition: number,
    centerInMercator: [number, number],
    pixelsPerMeterRatio: number,
  ): void {
    if (projection && projection.name === "globe") {
      this._projection = projection.name;
    } else {
      // Fallback to mercator in other projection, as wind animation
      // is supported only on globe and mercator projections
      this._projection = "mercator";
    }

    if (this._frozen || !this.metadata || !this.isVisible) {
      return;
    }
    // Check is all necessary renderers ready
    if (!this._tileMapRenderer || !this._windParticlesRenderer || !this._sliceRenderer) {
      return;
    }

    const dateTimeWithOffset = this.scene.getDisplayTimeWithOffset();

    // Check is globe projection and bounds set
    if (this.projection === "globe" && this._bounds) {
      const { synchronous } = this.getDataSlice(dateTimeWithOffset, this._bounds);

      if (synchronous === SynchrounousState.StillPending) {
        return;
      }

      this._sliceRenderer.render(synchronous as MultiWMSTile);
      this._windParticlesRenderer.prerender(
        this._sliceRenderer.texture,
        this.colorMapTexture,
        this.metadata,
        this._layerProperties,
      );

      this._projectionPlaneRenderer?.prerender(
        projectionMatrix,
        globeToMercMatrix,
        transition,
        centerInMercator,
        pixelsPerMeterRatio,
        this._windParticlesRenderer.texture,
      );

      return;
    }

    const pvs = this.getPVS(dateTimeWithOffset);

    if (pvs) {
      // If shaders not compiled, trigger repaint to check it again and halt prerendering
      if (!this._tileMapRenderer.areProgramsCompiled()) {
        this.scene.repaint();
        return;
      }
      this._tileMapRenderer.prerender(projectionMatrix, pvs);
      if (this._tileMapRenderer.windMap) {
        this._windParticlesRenderer.prerender(
          this._tileMapRenderer.windMap,
          this.colorMapTexture,
          this.metadata,
          this._layerProperties,
        );
      }
    }
  }

  // Currently typings doesn't include mapbox POC features
  // @ts-ignore
  render(
    _gl: WebGLRenderingContext,
    _projectionMatrix: number[],
    _projection: { name: string; center: [number, number] },
  ): void {
    // Draw texture containing particles on screen
    if (this.projection === "globe") {
      this._projectionPlaneRenderer?.render(this._layerProperties);
    } else {
      this._windParticlesRenderer?.render(this._layerProperties);
    }
  }

  // Update zoom dependent properties, right now there is only one property: "particle-speed"
  zoom() {
    this._updateAllProperties(this._zoomUpdatable);
  }

  setProperty(prop: WindLayerPropertySpecProp, value: WindLayerPropertySpecValue) {
    const spec = defaultPropertySpec[prop];
    if (!spec) {
      return;
    }
    const expr = expression.createPropertyExpression(value, spec);
    if (expr.result === "success") {
      switch (expr.value.kind) {
        case "camera":
        case "composite": {
          this._zoomUpdatable = expr.value;
          return;
        }
        default:
          return this._setPropertyValue(prop, expr.value);
      }
    }
    throw new Error(expr.value);
  }

  updateProps(props: WindAnimationLayer) {
    this._props = props;
  }

  freeze() {
    this._frozen = true;
  }

  unfreeze() {
    this._frozen = false;
  }

  isBuffering() {
    if (this.projection === "mercator") {
      const tiles = this.getPVS(this.scene.getDisplayTimeWithOffset());
      return !tiles.isCovering();
    }
    if (!this._bounds) {
      return false;
    }
    const { synchronous } = this.getDataSlice(this.scene.getDisplayTimeWithOffset(), this._bounds);
    return synchronous === SynchrounousState.StillPending;
  }

  getPVS(dateTimeWithOffset: DateTime) {
    const pvs = networkCaches.multi_wms_tile_cache.getTileSetInstant(this._getAreaRequest(dateTimeWithOffset));
    return pvs;
  }

  getDataSlice(datetime: DateTime, bounds: LngLatBounds) {
    const south = bounds.getSouth();
    const north = bounds.getNorth();
    const east = bounds.getEast();
    const west = bounds.getWest();

    const area = new Area(CoordinateSystem.EPSG4326, {
      east,
      west,
      north,
      south,
    });

    const request: SliceDataDescription<CoordinateSystem.EPSG4326, WMSRequestParams<CoordinateSystem.EPSG4326>> = {
      area,
      datetime,
      width: SLICE_SIZE,
      height: SLICE_SIZE,
      requestParams: {
        parameters: [this._props.parameter_unit, this._props.parameter_unit.replace("wind_speed_u", "wind_speed_v")],
        model: this._props.model,
        boundingBoxLimit: getModelBoundingBox(this._props.model),
        colorMap: ColorMap.gray,
        ensSelect: this._props.ens_select ?? "",
        calibrated: this._props.calibrated,
      },
    };

    return networkCaches.multi_wms_slice_cache.getDataSlice(request);
  }

  get isVisible() {
    return this._props.show && this._props.opacity > 0;
  }

  get isFrozen() {
    return this._frozen;
  }

  private _getAreaRequest(dateTimeWithOffset: DateTime): AreaRequest<WMSRequestParams<CoordinateSystem.EPSG3857>> {
    const tiles = this.scene.getMapViewport();
    const area = tiles.viewportArea;
    return {
      area,
      datetime: dateTimeWithOffset,
      /**
       * Additional parameters needed to fetch the tile data.
       * This object is forwarded to the tile getter when it requests a tile.
       */
      requestParams: {
        parameters: [this._props.parameter_unit, this._props.parameter_unit.replace("wind_speed_u", "wind_speed_v")],
        model: this._props.model,
        colorMap: ColorMap.gray,
        boundingBoxLimit: getModelBoundingBox(this._props.model) ?? WORLD_BBOX,
        ensSelect: this._props.ens_select || "",
        calibrated: this._props.calibrated,
      },
    };
  }

  // Calls related function for changing property, for example particle-speed calls setParticleSpeed
  // If wind instance is not available, postpone updating when onAdd is called
  private _setPropertyValue(prop: WindLayerPropertySpecProp, value: StylePropertyExpression) {
    if (!this._gl) {
      this._pendingUpdates[prop] = value;
      return;
    }

    const name = prop
      .split("-")
      .map((a) => a[0].toUpperCase() + a.slice(1))
      .join("");
    const setterName = `set${name}` as keyof WindLayer;

    if (this[setterName]) {
      if (!!this[setterName] && typeof this[setterName] === "function") {
        const setterFunction = this[setterName] as (...args: unknown[]) => unknown;

        setterFunction(
          value.evaluate({
            zoom: this._map?.getZoom(),
          }) as WindLayerPropertySpecValue,
          this,
        );
      }
    }
  }

  private _updateAllProperties(properties: WindLayerStyleOptions) {
    for (const [k, v] of Object.entries(properties) as [
      WindLayerPropertySpecProp,
      WindLayerPropertySpecValue | Expression,
    ][]) {
      this._setPropertyValue(k, v);
    }
  }

  private _triggerUpdateParticleIndexBuffer = debounce(
    (value: number) => this._windParticlesRenderer?.updateParticleIndexBuffer(value),
    100,
  );

  get projection() {
    return this._projection;
  }

  /* --------- style property setter functions --------- */
  setParticleSpeed(value: number, layer: WindLayer) {
    layer._layerProperties["particle-speed"] = value;
  }
  setParticleSize(value: number, layer: WindLayer) {
    layer._layerProperties["particle-size"] = value;
  }
  setParticleAmount(value: number, layer: WindLayer) {
    layer._layerProperties["particle-amount"] = value;
    layer._triggerUpdateParticleIndexBuffer(value);
  }
  setOpacity(value: number, layer: WindLayer) {
    layer._layerProperties.opacity = value;
  }
}

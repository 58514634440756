import { enumValues } from "@/utility/enum";
import type { DateTime } from "luxon";
import type mapboxgl from "mapbox-gl";

export enum AviationTypes {
  isigmet = "isigmet", // International Sigmet  // TODO: combined
  sigmet = "sigmet", // TODO: combined
  taf = "taf",
  metar = "metar",
  airep = "airep", // PIREP
}

export type AviationTypesUnion = keyof typeof AviationTypes;
export type AviationProperties = {
  [key in keyof typeof AviationTypes]: {
    name: () => string;
    mapboxStyleOptions: {
      popupText: (feature: mapboxgl.MapboxGeoJSONFeature) => string;
    };
    createUrl: (time: DateTime, bbox: string, zoom: number) => string;
    fixedBbox?: string;
    /**
     * scale factor for sizing the different icons of aviation layer by only one attribute in the backend.
     * text_size will also change the size of the icons.
     * all types of aviation layers have sometimes also multi icons on each feature point that need to have other sizes.
     *
     */
    iconScale?: any;
  };
};
export const aviationTypeList = enumValues(AviationTypes);

import { OAuthState, useAuth } from "@/auth";
import ErrorPage from "@/pages/error";
import { pageProfiles } from "@/route";
import { Trans } from "@lingui/macro";
import { useEffect, useLayoutEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

export default function OAuthAuthorizationCode() {
  const location = useLocation();
  const history = useHistory();
  const auth = useAuth();

  const [oauthError, setOAuthError] = useState<string | null>(null);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get("code");
    if (code) {
      const session = auth.session;
      if (session && session.state === OAuthState.AuthorizationCode) {
        auth.authorizationCode(code).then((newState) => {
          if (newState) {
            if (newState.redirectUrl) {
              history.push(newState.redirectUrl);
            } else {
              history.push(`${pageProfiles.asString()}`);
            }
          }
        });
      }
    }
  }, [auth, history, location]);

  useLayoutEffect(() => {
    const params = new URLSearchParams(location.search);
    const error = params.get("error");
    if (error) {
      setOAuthError(error);
      return;
    }
    setOAuthError(null);
  }, [location]);

  if (oauthError) {
    return <ErrorPage error={`OAuth: ${oauthError}`} />;
  }

  return (
    <div className="text dialog dialog--large background--white">
      <p>
        <Trans id="oauth.body.redirect-msg">You should be redirected within a few seconds..</Trans>
      </p>
      <p>
        <Trans>
          Please <a href="/">click here</a> if you are not redirected!
        </Trans>
      </p>
    </div>
  );
}

import { type LayerKind, LayerKindName } from "@/layers/utility/createLayerObject";

export const EXCLUDED_LAYERS: LayerKind[] = [
  LayerKindName.BackgroundMapDescription,
  LayerKindName.GenericPoiLayerDescription,
  LayerKindName.AviationLayerDescription,
  LayerKindName.PressureSystemLayerDescription,
  LayerKindName.TropicalCycloneLayerDescription,
  LayerKindName.CustomGeoJSONLayerDescription,
];

import { useAuth } from "@/auth";
import { isAuthenticated } from "@/auth/utils";
import { apiThreadPool } from "@/cache/SpatioTemporalTileCache/ApiQueryThreadPool";
import { useEffect } from "react";

export function useMeteomaticsApi() {
  const session = useAuth().session;

  useEffect(() => {
    if (!isAuthenticated(session) || session?.token === undefined) {
      apiThreadPool.updateAuthentication(null);
      return;
    }

    apiThreadPool
      .updateAuthentication(session.token.access_token)
      .then(() => apiThreadPool.getUserStats())
      .then((userStats) =>
        apiThreadPool.updateHardParallelLimit(userStats["user statistics"]["requests in parallel"]["hard limit"]),
      );
  }, [session]);

  return apiThreadPool;
}

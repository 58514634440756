import { Area } from "../models";
/**
 * A fallback bounding box for models.
 */

export const WORLD_BBOX = Area.ofWgs84BoundingBox({
  resolution_lng: 0.000833333,
  resolution_lat: 0.000833333,
  south: -90,
  west: -180,
  north: 90,
  east: 180,
});

// Calc wind kn rounded for Icon
export function getWindNrByKn(windSpeedKn: number) {
  if (windSpeedKn < 5) {
    return 0;
  }
  if (windSpeedKn <= 9.99) {
    return 5;
  }
  if (windSpeedKn <= 14.99) {
    return 10;
  }
  if (windSpeedKn <= 19.99) {
    return 15;
  }
  if (windSpeedKn <= 24.99) {
    return 20;
  }
  if (windSpeedKn <= 29.99) {
    return 25;
  }
  if (windSpeedKn <= 34.99) {
    return 30;
  }
  if (windSpeedKn <= 39.99) {
    return 35;
  }
  if (windSpeedKn <= 44.99) {
    return 40;
  }
  if (windSpeedKn <= 49.99) {
    return 45;
  }
  if (windSpeedKn <= 54.99) {
    return 50;
  }
  if (windSpeedKn <= 59.99) {
    return 55;
  }
  if (windSpeedKn <= 64.99) {
    return 60;
  }
  if (windSpeedKn <= 69.99) {
    return 65;
  }
  if (windSpeedKn <= 74.99) {
    return 70;
  }
  if (windSpeedKn <= 79.99) {
    return 75;
  }
  if (windSpeedKn <= 84.99) {
    return 80;
  }
  if (windSpeedKn <= 89.99) {
    return 85;
  }
  if (windSpeedKn <= 94.99) {
    return 90;
  }
  if (windSpeedKn <= 99.99) {
    return 95;
  }
  if (windSpeedKn <= 104.99) {
    return 100;
  }
  if (windSpeedKn <= 109.99) {
    return 105;
  }
  if (windSpeedKn <= 114.99) {
    return 110;
  }
  if (windSpeedKn <= 119.99) {
    return 115;
  }
  if (windSpeedKn <= 124.99) {
    return 120;
  }

  return 125;
}

import { dataProcessingThreadPool } from "@/threads/DataProcessingThread/DataProcessingThreadPool";
import type { ParsedPoiListResponse } from "@/threads/DataProcessingThread/types";
import { Trans } from "@lingui/macro";
import type { ChangeEvent } from "react";

import { InputFile } from "./InputFile";
import styles from "./style.module.scss";

type PoiLayerCsvUploadProps = {
  onCsvUpload?: (response: ParsedPoiListResponse) => void;
  type?: "input" | "button";
};

export function PoiLayerCsvUpload({ onCsvUpload, type = "input" }: PoiLayerCsvUploadProps) {
  function onChangeFile(event: ChangeEvent<HTMLInputElement>) {
    const { files } = event.target;

    if (files) {
      dataProcessingThreadPool.parseCsvFiles(files).then((response: ParsedPoiListResponse) => {
        onCsvUpload?.(response);
      });
    }
  }

  return (
    <div className={type === "button" ? styles["button-type"] : styles["input-type"]}>
      {type === "button" ? (
        <label className={`button ${styles["import-button"]}`}>
          <Trans>Import from CSV</Trans>
          <InputFile onChangeFile={onChangeFile} type={type} />
        </label>
      ) : (
        <InputFile onChangeFile={onChangeFile} type={type} />
      )}
    </div>
  );
}

import {
  airepPopupText,
  aviatioinDomain,
  getMetarPopupText,
  getTafPopupText,
  getiSigmetPopupText,
} from "@/layers/utility/aviationStyle";
import type { AviationProperties } from "@/layers/utility/aviationType";
import { t } from "@lingui/macro";
import type { DateTime } from "luxon";
import type { AnySourceData, Expression, SymbolLayer, SymbolPaint } from "mapbox-gl";

const mapboxZoomToAviationWeatherZoom = (mapboxZoom: number): number => {
  if (mapboxZoom < 3) {
    return 3;
  }
  if (mapboxZoom <= 4) {
    return 4;
  }
  if (mapboxZoom <= 6) {
    return 5;
  }
  if (mapboxZoom <= 7) {
    return 6;
  }

  return 10;
};
export const allAviationProperties: AviationProperties = {
  airep: {
    mapboxStyleOptions: {
      popupText: airepPopupText,
    },
    name: () => t`AIREPs`,
    createUrl: (time: DateTime, bbox): string =>
      `${aviatioinDomain}/cgi-bin/json/AirepJSON.php?type=all&age=1.5&top=undef&bottom=undef&inten=all&noneg=0&date=${time.toFormat(
        "yyyyMMddHHmm",
      )}&bbox=${bbox}`,
    fixedBbox: "-180,-90,180,90",
    iconScale: {
      wx__icon: 2.2,
    },
  },
  isigmet: {
    mapboxStyleOptions: {
      popupText: getiSigmetPopupText,
    },
    name: () => t`International SIGMETs`,
    createUrl: (time: DateTime, bbox): string =>
      `${aviatioinDomain}/cgi-bin/json/IsigmetJSON.php?type=trop,conv,turb,icing,ifr,va,&date=${time.toFormat(
        "yyyyMMddHHmm",
      )}&bbox=${bbox}`,
    fixedBbox: "-180,-90,180,90",
  },
  sigmet: {
    mapboxStyleOptions: {
      popupText: getiSigmetPopupText,
    },
    name: () => t`SIGMETs`,
    createUrl: (time: DateTime, bbox): string =>
      `${aviatioinDomain}/cgi-bin/json/SigmetJSON.php?type=trop,conv,turb,icing,ifr,va,&outlook=on&date=${time.toFormat(
        "yyyyMMddHHmm",
      )}&bbox=${bbox}`,
    fixedBbox: "-180,-90,180,90",
  },
  metar: {
    mapboxStyleOptions: {
      popupText: getMetarPopupText,
    },
    name: () => t`METARs`,
    createUrl: (time: DateTime, bbox, zoom): string =>
      `${aviatioinDomain}/cgi-bin/json/MetarJSON.php?zoom=${mapboxZoomToAviationWeatherZoom(
        zoom,
      )}&filter=prior&density=0&taf=0&date=${time.toFormat("yyyyMMddHHmm")}&bbox=${bbox}`,
    iconScale: {
      cloud__icon: 1.2,
      wx__icon: 1.2,
      wind__icon: 4,
    },
  },
  taf: {
    mapboxStyleOptions: {
      popupText: getTafPopupText,
    },
    name: () => t`TAFs`,
    createUrl: (time: DateTime, bbox, zoom): string =>
      `${aviatioinDomain}/cgi-bin/json/TafJSON.php?zoom=${mapboxZoomToAviationWeatherZoom(
        zoom,
      )}&filter=prior&fore=0&density=0&metar=0&tempo=0&date=${time.toFormat("yyyyMMddHHmm")}&bbox=${bbox}`,
    iconScale: {
      cloud__icon: 1.2,
      wx__icon: 1.2,
      wind__icon: 4,
    },
  },
};

/**
 * Compose a symbol layer with text. It helps to create custom text with offset
 * @param id
 * @param source
 * @param text
 * @param textOffset
 * @param paintProperty
 * @param textSize
 * @return SymbolLayer
 */
export function createMapboxSymbolLayerText(
  id: string,
  source: string | AnySourceData,
  text: string | Expression,
  // biome-ignore lint/style/useDefaultParameterLast: TODO multiple usages
  textOffset: [number, number] = [0, 0],
  // biome-ignore lint/style/useDefaultParameterLast: TODO multiple usages
  paintProperty: SymbolPaint = {},
  textSize: number,
): SymbolLayer {
  return {
    id: id,
    type: "symbol",
    source: source,
    layout: {
      "text-field": text,
      "text-offset": textOffset,
      "text-ignore-placement": true,
      "text-allow-overlap": true,
      "text-size": textSize,
    },
    paint: { ...paintProperty },
  };
}

/**
 * Compose a symbol layer with text. It helps to create custom text with offset
 * @param id
 * @param source
 * @param icon
 * @param iconRotation
 * @param iconSize
 * @param iconOffset
 * @param paintProperty
 * @return SymbolLayer
 */
export function createMapboxSymbolLayerIcon(
  id: string,
  source: string | AnySourceData,
  icon: string | Expression,
  // biome-ignore lint/style/useDefaultParameterLast: TODO multiple usages
  iconRotation: number | Expression = 0,
  iconSize: number,
  iconOffset: [number, number],
  paintProperty: SymbolPaint = {},
): SymbolLayer {
  return {
    id: id,
    type: "symbol",
    source: source,
    layout: {
      "icon-image": icon,
      "icon-allow-overlap": true,
      "icon-size": iconSize,
      "icon-keep-upright": true,
      "icon-offset": iconOffset,
      "icon-rotation-alignment": "map",
      "icon-rotate": iconRotation,
      "icon-ignore-placement": true,
    },
    paint: { ...paintProperty },
  };
}

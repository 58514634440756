import { type HTMLProps, type ReactChild, type ReactChildren, type ReactNode, useRef, useState } from "react";
import "./style.scss";

export type TooltipProps = {
  message: string | ReactNode;
  direction: "top" | "bottom" | "right" | "left" | "bottom-left" | "top-right";
  delayBeforeShow?: number;
  disabled?: boolean;
  /**
   * A boolean to control the display state of the tooltip from other component.
   * If passed, this will overwrite the current display state.
   */
  forceShow?: boolean;
  children?: ReactChild | ReactChildren;
  onShow?: () => void;
  onHide?: () => void;
};

// Shows a tooltip on mouse hover. The direction of tooltip can be customized by the direction prop
export function Tooltip(props: TooltipProps & HTMLProps<HTMLDivElement>) {
  const { message, direction, delayBeforeShow, forceShow, onShow, onHide, disabled, children, className, ...forward } =
    props;
  const timeoutId = useRef<NodeJS.Timeout>();
  const [hover, setHover] = useState(false);

  const showTip = () => {
    if (!disabled) {
      timeoutId.current = setTimeout(() => {
        setHover(true);
        onShow?.();
      }, props.delayBeforeShow || 100);
    }
  };

  const hideTip = () => {
    if (!disabled) {
      timeoutId.current && clearInterval(timeoutId.current);
      setHover(false);
      onHide?.();
    }
  };

  const shouldShow = props.forceShow === undefined ? hover : props.forceShow;
  return (
    <div className="tooltip-wrapper" onMouseEnter={showTip} onMouseLeave={hideTip}>
      {props.children}
      {shouldShow && (
        <div className={`tooltip-tip ${props.direction}${className ? ` ${className}` : ""}`} {...forward}>
          {props.message}
        </div>
      )}
    </div>
  );
}

export default Tooltip;

import { replaceLayouts } from "@/api/hooks/layout";
import { fetchTab } from "@/api/hooks/tab";
import { createCountryForecastPlot } from "@/api/hooks/tools/countryForecastPlot";
import { createEnergyPlot } from "@/api/hooks/tools/energyPlot";
import { createLocationTable } from "@/api/hooks/tools/locationTable";
import { createNote } from "@/api/hooks/tools/note";
import { createPlot } from "@/api/hooks/tools/plot";
import { createTephigram } from "@/api/hooks/tools/tephigram";
import { createWeatherTable } from "@/api/hooks/tools/weatherTable";
import { discardCountryPlot } from "@/reducer/CountryPlotState";
import { type GenericLayout, LayoutType } from "@mm/metx-workbench.meteomatics.com";
import { type PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { createMapWithViewport } from "metx-core/src/api/hooks/tools/map";
import { discardMap } from "metx-core/src/reducer/MapsState";
import { useSelector } from "react-redux";
import type { RootState } from ".";
import { discardEnergyPlot } from "./EnergyPlotsState";
import { discardNote } from "./NoteState";
import { discardPlot } from "./PlotsState";
import { discardTephigram } from "./TephigramState";
import { discardWeatherTable } from "./WeatherTablesState";

export type LayoutState = {
  [id: number]: GenericLayout;
};

const initialState: LayoutState = {};

function assignToolToLayout(
  state: LayoutState,
  props: {
    tool: { id: number }; // Represents CartographicMap, Plot, etc
    layoutType: LayoutType;
    layoutId: number;
  },
) {
  const { tool, layoutType, layoutId } = props;
  if (!(layoutId in state)) {
    console.error(
      `Layout with ID '${layoutId}' doesn't exit. Failed to update the layout ${layoutType.toString()} for the following data: \n${JSON.stringify(
        tool,
      )}`,
    );
    return;
  }
  const layout = state[layoutId];
  layout.id_tool = tool.id;
  layout.type = layoutType;
  state[layoutId] = layout;
}

function unassignLayoutFromTool(state: LayoutState, props: { layoutType: LayoutType; toolId: number }) {
  const { toolId, layoutType } = props;
  const select = Object.values(state).filter((layout) => layout.id_tool === toolId && layout.type === layoutType);
  if (select.length === 0) {
    console.error(
      `Failed to unassign layout from a tool. Layout with tool ID '${toolId}' of type '${layoutType.toString()}' doesn't exit.`,
    );
    return;
  }
  const [layout, layoutId] = [select[0], select[0].id];
  layout.id_tool = undefined;
  layout.type = LayoutType.Undefined;
  state[layoutId] = layout;
}

const layoutsSlice = createSlice({
  name: "layouts",
  initialState: initialState as LayoutState,
  reducers: {
    switchLayouts(
      state,
      action: PayloadAction<{
        tabId: number;
        layoutId1: GenericLayout["id"];
        layoutId2: GenericLayout["id"];
      }>,
    ) {
      const { layoutId1, layoutId2 } = action.payload;
      const layout1 = { ...state[layoutId1].gridCellLayout };
      const layout2 = { ...state[layoutId2].gridCellLayout };

      state[layoutId1].gridCellLayout = layout2;
      state[layoutId2].gridCellLayout = layout1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTab.fulfilled, (state, action) => {
      const tab = action.payload;

      for (const key in state) {
        delete state[+key];
      }
      for (const layout of tab.layouts) {
        state[layout.id] = layout;
      }
    });
    builder.addCase(replaceLayouts.fulfilled, (state, action) => {
      const newLayouts = action.payload;
      const tab = action.payload;
      for (const layoutId of Object.keys(state)) {
        const id = Number.parseInt(layoutId, 10);
        delete state[id];
      }
      for (const layout of newLayouts) {
        state[layout.id] = layout;
      }
    });
    builder.addCase(createMapWithViewport.fulfilled, (state, action) => {
      assignToolToLayout(state, action.payload);
    });
    builder.addCase(discardMap, (state, action) => {
      unassignLayoutFromTool(state, { toolId: action.payload.id, layoutType: LayoutType.Map });
    });
    // Handle plot layout update
    builder.addCase(createPlot.fulfilled, (state, action) => {
      assignToolToLayout(state, action.payload);
    });
    builder.addCase(discardPlot, (state, action) => {
      unassignLayoutFromTool(state, { toolId: action.payload.id, layoutType: LayoutType.Plot });
    });
    // Handle weather table layout update
    builder.addCase(createWeatherTable.fulfilled, (state, action) => {
      assignToolToLayout(state, action.payload);
    });
    builder.addCase(createLocationTable.fulfilled, (state, action) => {
      assignToolToLayout(state, action.payload);
    });
    builder.addCase(discardWeatherTable, (state, action) => {
      unassignLayoutFromTool(state, { toolId: action.payload.id, layoutType: LayoutType.WeatherTable });
    });
    // Handle energy plot layout update
    builder.addCase(createEnergyPlot.fulfilled, (state, action) => {
      assignToolToLayout(state, action.payload);
    });
    builder.addCase(discardEnergyPlot, (state, action) => {
      unassignLayoutFromTool(state, { toolId: action.payload.id, layoutType: LayoutType.EnergyPlot });
    });
    // Handle tephigram layout update
    builder.addCase(createTephigram.fulfilled, (state, action) => {
      assignToolToLayout(state, action.payload);
    });
    builder.addCase(discardTephigram, (state, action) => {
      unassignLayoutFromTool(state, { toolId: action.payload.id, layoutType: LayoutType.Tephigram });
    });
    // Handle countryPlot layout update
    builder.addCase(createCountryForecastPlot.fulfilled, (state, action) => {
      assignToolToLayout(state, action.payload);
    });
    builder.addCase(discardCountryPlot, (state, action) => {
      unassignLayoutFromTool(state, { toolId: action.payload.id, layoutType: LayoutType.CountryPlot });
    });
    // Handle note
    builder.addCase(createNote.fulfilled, (state, action) => {
      assignToolToLayout(state, action.payload);
    });
    builder.addCase(discardNote, (state, action) => {
      unassignLayoutFromTool(state, { toolId: action.payload.id, layoutType: LayoutType.Notes });
    });
  },
});

const selectLayouts = (state: RootState) => state.tabGroup.present.layouts;

const makeSelectLayoutsByTabId = createSelector([selectLayouts, (_, tabId: number) => tabId], (layouts, tabId) =>
  Object.values(layouts).filter((layout) => layout.id_tab === tabId),
);

export const useLayouts = (tabId: number) => useSelector((state: RootState) => makeSelectLayoutsByTabId(state, tabId));

const makeSelectToolLayout = createSelector(
  [selectLayouts, (_, tabId: number, toolId: number) => toolId],
  (layouts, toolId) => Object.values(layouts).find((layout) => layout.id_tool === toolId),
);

export const useToolLayout = (tabId: number, toolId: number) =>
  useSelector((state: RootState) => makeSelectToolLayout(state, tabId, toolId));

const makeSelectLayoutById = createSelector([selectLayouts, (_, id: number) => id], (layouts, id) => layouts[id]);

export const useLayout = (id: number) => useSelector((state: RootState) => makeSelectLayoutById(state, id));

export const { switchLayouts } = layoutsSlice.actions;

export default layoutsSlice.reducer;

import { dateTimeToTimeZoneFormat } from "@/time/helper-function";
import { i18n } from "@lingui/core";
import type { GuiTimeZone } from "@mm/metx-workbench.meteomatics.com";
import type { DateTime, LocaleOptions } from "luxon";
import { useMemo } from "react";

/**
 * Component to pretty print a `DateTime` object.
 */
export function DatetimeLabel(props: {
  datetime: DateTime;
  timezone: GuiTimeZone;
  /**
   * A time format options to specify how the datetime is printed.
   * @see https://moment.github.io/luxon/api-docs/index.html#datetimetolocalestring
   * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat
   */
  format?: LocaleOptions & Intl.DateTimeFormatOptions;
}) {
  const { datetime, timezone } = props;
  const format = props.format || {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  };

  // Note `title` shows the ISO timestamp on hover
  const isoStr = useMemo(() => dateTimeToTimeZoneFormat(datetime, timezone), [datetime, timezone]);
  const timezonedDateTime = useMemo(() => datetime.setZone(timezone), [datetime, timezone]);

  return (
    <span data-testid={"datetime-label-formatted"} className="formatted-datetime" title={isoStr}>
      {timezonedDateTime.setLocale(i18n.locale).toLocaleString(format).replace(/,/g, "\u00a0").replace(/ /g, "\u00a0")}
    </span>
  );
}

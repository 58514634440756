import type { StringStateValueFrom } from "@/models/state-machine-utils/typeUtilts";
import type { ModeOpts, TimeContextUnion } from "@/models/time-control/timeStateCtxTypes";
import type { TimeStateMachine } from "@/models/time-control/timeStateMachine";
import type { GuiTimeZone } from "@mm/metx-workbench.meteomatics.com";
import type { DateTime, Duration } from "luxon";

/**
 * Event types to trigger transitions between states
 */
enum TransitionEventType {
  initializeStateMachine = "initializeStateMachine",
  setAutoRefreshMode = "setAutoRefreshMode",
  setTimePointMode = "setTimePointMode",
  setStoredPointMode = "setStoredPointMode",
  setAnimationMode = "setAnimationMode",
  resetToIdleMode = "resetToIdleMode",
}

/**
 * Event types to trigger self transition within a single state
 */
enum SelfTransitionEventType {
  refreshDisplayTime = "refreshDisplayTime", // For auto refresh mode
  updateDisplayTime = "updateDisplayTime",
  updateGuiTimezone = "updateGuiTimezone",
  updateAnimationConfig = "updateAnimationConfig",
}

export const TimeEventType = { ...TransitionEventType, ...SelfTransitionEventType };

export type InitializationEvent = {
  type: TransitionEventType.initializeStateMachine;
  // String name of the state. It can be a dotted notation "Parent.Child"
  // if the state is nested.
  stateValue: StringStateValueFrom<TimeStateMachine>;
  stateCtx: TimeContextUnion;
};

export type TimeEventUnion =
  | InitializationEvent
  | { type: TransitionEventType.resetToIdleMode }
  | { type: TransitionEventType.setAutoRefreshMode }
  | { type: SelfTransitionEventType.refreshDisplayTime }
  | { type: TransitionEventType.setTimePointMode }
  | { type: TransitionEventType.setStoredPointMode }
  | {
      type: TransitionEventType.setAnimationMode;
    }
  | {
      type: SelfTransitionEventType.updateAnimationConfig;
      modeOpts: ModeOpts;
    }
  | {
      type: SelfTransitionEventType.updateDisplayTime;
      displayTime: DateTime;
    }
  | {
      type: SelfTransitionEventType.updateGuiTimezone;
      timezone: GuiTimeZone;
    };

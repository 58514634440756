import type { User } from "@mm/metx-workbench.meteomatics.com";
import { useQuery } from "@tanstack/react-query";
import { QueryKey } from "..";
import { Api } from "../Api";

export const useUser = (shouldFetch = true) => {
  const { data, isLoading, error } = useQuery<User, Error>([QueryKey.USER], () => Api.user.v1ReadCurrentUser(), {
    staleTime: Number.POSITIVE_INFINITY,
    enabled: shouldFetch,
  });

  return { user: data, isLoading, error };
};

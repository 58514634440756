import { CacheDatumState, networkCaches } from "@/cache/GlobalCache";
import type { SceneLayerApi } from "@/layers/SceneLayerApi";
import { VectorTileData } from "@mm/api.meteomatics.com";
import type { AviationLayer, GuiTimeZone } from "@mm/metx-workbench.meteomatics.com";
import { flatten } from "lodash";
import type { DateTime } from "luxon";
import {
  type EventData,
  type MapMouseEvent,
  type MapboxGeoJSONFeature,
  Popup,
  type Style,
  type SymbolLayout,
} from "mapbox-gl";

export interface IBaseSubLayer {
  loadTiles: (timeFrame: DateTime) => Promise<void>;
  createLayers: (style: Style) => void;
  moveLayers: (beforeLayerId?: string) => void;
  updateSize: (size: number) => void;
  expireTiles: () => void;
  hasAllSourcesLoaded: (dateTime: DateTime) => boolean;
  toggleVisibility: (visible: boolean) => void;
  setTimezone: (timezone: GuiTimeZone) => void;
  dispose: () => void;
}

export abstract class BaseSubLayer implements IBaseSubLayer {
  private eventMap: Map<
    string | string[],
    [typeof this.handleMouseClick, typeof this.handleMouseEnter, typeof this.handleMouseLeave]
  > = new Map();

  private popup = new Popup({ maxWidth: "400px" });

  protected sources: string[] = [];
  protected layers: string[] = [];

  constructor(
    protected readonly uuid: number,
    protected readonly scene: SceneLayerApi,
    protected readonly initialProps: AviationLayer,
    public timezone: GuiTimeZone,
    public displayDateTime: DateTime,
    protected readonly beforeLayerId?: string,
  ) {
    const { asynchronous } = networkCaches.spatiotemporal_vector_tile_cache.retrieveVectorLayerStyle({
      parameter: `${this.aviationType}:0`,
      vectorTileData: VectorTileData.AVIATION_REPORT,
    });
    asynchronous
      .then((style) => {
        if (style) {
          this.createSource(style);
        }
      })
      .catch();
  }

  public async loadTiles(dateTime: DateTime) {
    this.displayDateTime = dateTime;
    // fetchData is expecting Promise<void> so let it be
    return Promise.all(this.getTileRequestList(dateTime).map(({ asynchronous }) => asynchronous)).then(() =>
      Promise.resolve(),
    );
  }

  public setTimezone(timezone: GuiTimeZone) {
    this.timezone = timezone;
  }

  public moveLayers(beforeLayerId?: string) {
    const map = this.scene.getMapboxMap();
    for (const layerId of this.layers) {
      if (map.getLayer(layerId)) {
        map.moveLayer(layerId, beforeLayerId);
      }
    }
  }

  public toggleVisibility(visible: boolean) {
    for (const layerId of this.layers) {
      this.updateMapboxLayoutProperties(layerId, { visibility: visible ? "visible" : "none" });
    }
  }

  public expireTiles() {
    const map = this.scene.getMapboxMap();
    for (const sourceId of this.sources) {
      const source = map.getSource(sourceId);
      // @ts-ignore
      source.expireTiles();
    }
  }

  public hasAllSourcesLoaded(dateTime: DateTime) {
    const tileStates = this.getTileRequestList(dateTime).map(({ synchronous }) => synchronous);

    return !tileStates.includes(CacheDatumState.Pending);
  }

  private createSource(style: mapboxgl.Style) {
    const map = this.scene.getMapboxMap();
    for (const sourceName of Object.keys(style.sources)) {
      const sourceId = `${this.uuid}_${sourceName}`;
      map.addSource(sourceId, {
        ...style.sources[sourceName],
        // @ts-ignore
        fetchTile: (params) => {
          const { asynchronous } = this.fetchTile(params, this.scene.getDisplayTimeWithOffset(), sourceId);
          return asynchronous;
        },
      });

      this.sources.push(sourceId);
    }

    this.createLayers(style);
  }

  abstract createLayers(style: Style): void;
  abstract updateSize(size: number): void;
  abstract createTooltip(feature: MapboxGeoJSONFeature): string;

  public dispose() {
    const map = this.scene.getMapboxMap();

    for (const [layerId, [handleMouseClick, handleMouseEnter, handleMouseLeave]] of this.eventMap.entries()) {
      map.off("click", layerId, handleMouseClick);
      map.off("mouseenter", layerId, handleMouseEnter);
      map.off("mouseleave", layerId, handleMouseLeave);
    }

    for (const layer of this.layers) {
      if (map.getLayer(layer)) {
        map.removeLayer(layer);
      }
    }
    for (const source of this.sources) {
      if (map.getSource(source)) {
        map.removeSource(source);
      }
    }
  }

  protected updateMapboxLayoutProperties(layerId: string, props: SymbolLayout) {
    if (this.scene.getMapboxMap().getLayer(layerId)) {
      for (const [name, value] of Object.entries(props)) {
        try {
          this.scene.getMapboxMap().setLayoutProperty(layerId, name, value);
        } catch (e) {
          console.warn(e);
        }
      }
    }
  }

  protected updateTooltip(html: string) {
    this.popup.setHTML(html);
  }

  protected setEventListeners(idLayer: string | string[]) {
    const mapBoxMap = this.scene.getMapboxMap();

    const handleMouseClick = this.handleMouseClick.bind(this);
    const handleMouseEnter = this.handleMouseEnter.bind(this);
    const handleMouseLeave = this.handleMouseLeave.bind(this);

    mapBoxMap.on("click", idLayer, handleMouseClick);
    mapBoxMap.on("mouseenter", idLayer, handleMouseEnter);
    mapBoxMap.on("mouseleave", idLayer, handleMouseLeave);

    this.eventMap.set(idLayer, [handleMouseClick, handleMouseEnter, handleMouseLeave]);
  }

  private handleMouseClick(
    e: MapMouseEvent & {
      features?: MapboxGeoJSONFeature[] | undefined;
    } & EventData,
  ) {
    if (!e.features) {
      return;
    }
    const mapBoxMap = this.scene.getMapboxMap();

    const feature = e.features[0];
    this.popup.setLngLat(e.lngLat).setHTML(this.createTooltip(feature)).addTo(mapBoxMap);
    return;
  }

  private handleMouseEnter() {
    const mapBoxMap = this.scene.getMapboxMap();
    mapBoxMap.getCanvas().style.cursor = "pointer";
  }

  private handleMouseLeave() {
    const mapBoxMap = this.scene.getMapboxMap();
    mapBoxMap.getCanvas().style.cursor = "";
  }

  private getTileRequestList(dateTime: DateTime) {
    const map = this.scene.getMapboxMap();

    const statuses = flatten(
      this.sources.map((sourceName) => {
        const source = map.getSource(sourceName);

        // Check if source has implemented coveringTiles function
        // Otherwise just return empty array
        // @ts-ignore
        if (source.coveringTiles) {
          return (
            source
              // @ts-ignore
              .coveringTiles()
              .map((tile: any) => this.fetchTile(tile, dateTime, sourceName))
          );
        }

        return [];
      }),
    );

    return statuses;
  }

  private fetchTile({ x, y, z }: { x: number; y: number; z: number }, datetime: DateTime, source: string) {
    // @ts-ignore
    return networkCaches.spatiotemporal_vector_tile_cache.retrieveVectorTile({
      source,
      parameter: `${this.aviationType}:0`,
      vectorTileData: VectorTileData.AVIATION_REPORT,
      geometry: {
        x,
        y,
        z,
      },
      datetime,
    });
  }

  get aviationType() {
    // Replace workaround is needed, as ISIGMET on Meteomatics API is called SIGMET
    // And we can't replace SIGMET with ISIGMET, as USA data is not available
    return this.initialProps.aviation_type.replace("isigmet", "sigmet");
  }
}

import type {
  AnyLayerSchema,
  HasLayerIdxNarrowing,
  PartialWeatherParameter,
  SelectionNarrowing,
} from "../search-engines/WeatherParamSearchEngine/model/PartialWeatherParameter";
import { isParameterRange } from "./typecheck";

import type { DefaultValues } from "@mm/api-layers.meteomatics.com";
import Logger from "logging";
import type { WeatherParamFormat, WeatherParamFormatOption } from "../search-engines";
import { BaseParameterProblem } from "../search-engines/WeatherParamSearchEngine/model/PartialWeatherParameter";
import { formatParameterRange } from "./format";
const logger = Logger.fromFilename(__filename);

export const BASE_PARAMETER_FORMAT_REGEX = /([^<>]+)|<([^>]+)>/g;
export const DEFAULT_LAYER_IDX = 0;

/**
 * Chooses default value for the selected layer of the partial weather parameter.
 */
export function autofillLayerSelection(
  baseParameter_: PartialWeatherParameter,
): HasLayerIdxNarrowing<PartialWeatherParameter> {
  const baseParameter = copy(baseParameter_);

  if (baseParameter.narrowed_selection == null) {
    baseParameter.narrowed_selection = {
      layerIdx: DEFAULT_LAYER_IDX,
      fields: {},
    };
  }

  return {
    parameter: baseParameter.parameter,
    narrowed_selection: baseParameter.narrowed_selection,
  };
}

export function copy<T extends PartialWeatherParameter>({ parameter, narrowed_selection }: T) {
  const narrowing: T["narrowed_selection"] = !narrowed_selection
    ? narrowed_selection
    : {
        layerIdx: narrowed_selection.layerIdx,
        fields: { ...narrowed_selection.fields },
      };

  return {
    parameter,
    narrowed_selection: narrowing,
  };
}

/**
 * Chooses default values for the whole partial weather parameter. This ensures that the parameter can be instantiated.
 */
export function autofill(baseParameter_: PartialWeatherParameter): HasLayerIdxNarrowing<PartialWeatherParameter> {
  const baseParameter = autofillLayerSelection(baseParameter_);

  const layer = baseParameter.parameter.layers[baseParameter.narrowed_selection.layerIdx];
  const { default_values } = baseParameter.parameter;
  const missingFields = missingFieldsOfLayer(layer, baseParameter.narrowed_selection);
  for (const missingField of missingFields) {
    const fieldSpec: any = (layer as any)[missingField];
    if (Array.isArray(fieldSpec)) {
      // choose default or lowest value (allowed values are sorted in ascending order by the backend)
      baseParameter.narrowed_selection.fields[missingField] =
        default_values[missingField as keyof DefaultValues] ?? fieldSpec[0];
    } else if (isParameterRange(fieldSpec)) {
      // for range parameters, choose the lowest value
      baseParameter.narrowed_selection.fields[missingField] = formatParameterRange(fieldSpec, fieldSpec.start);
    } else {
      // TODO: make this typesafe, error will let it through, instead of crashing metX
      logger.error(`unknown parameter field ${missingField}`);
    }
  }

  return baseParameter;
}

// get a list of fields that are not yet narrowed. Returns null if the format is not yet selected
export function missingFields(
  baseParameter: PartialWeatherParameter,
): string[] | BaseParameterProblem.NotNarrowedToFormat {
  if (baseParameter.narrowed_selection == null) {
    // base parameter must be narrowed to a layer. Otherwise the parameter format is unknown.
    return BaseParameterProblem.NotNarrowedToFormat;
  }

  const layerIdx = baseParameter.narrowed_selection.layerIdx;
  const layer = baseParameter.parameter.layers[layerIdx];

  return missingFieldsOfLayer(layer, baseParameter.narrowed_selection);
}

export function missingFieldsOfLayer(layer: { format: string }, narrowed_selection: SelectionNarrowing): string[] {
  const missingFields: string[] = [];

  // TODO: use match instead of replace
  layer.format.replace(BASE_PARAMETER_FORMAT_REGEX, (_match, fixedSubstring, variableField) => {
    if (fixedSubstring !== void 0) {
      return "";
    }

    if (typeof narrowed_selection.fields[variableField] !== "string") {
      missingFields.push(variableField);
    }

    return "";
  });

  return missingFields;
}

/**
 * Check if the BaseParameter is fully narrowed and can thus be turned into an instance of the parameter without auto completion.
 *
 * @param baseParameter input base parameter
 */
export function canBeInstantiated(baseParameter: PartialWeatherParameter): boolean {
  return parameterToString(baseParameter)?.missingFields.length === 0 ?? false;
}

interface StringFormattingResult {
  missingFields: string[];
  formatted: string;
}

/**
 * Renders the base parameter as a string. Not yet narrowed fields will be rendered as a placeholder.
 *
 * @return parameter as string. May fail with `null` if the base parameter is not narrowed to a parameter format.
 */
export function parameterToString(baseParameter: HasLayerIdxNarrowing<PartialWeatherParameter>): StringFormattingResult;
export function parameterToString(baseParameter: PartialWeatherParameter): StringFormattingResult | null;
export function parameterToString(baseParameter: PartialWeatherParameter): StringFormattingResult | null {
  if (baseParameter.narrowed_selection == null) {
    // base parameter must be narrowed to a layer. Otherwise the parameter format is unknown.
    return null;
  }

  const layerIdx = baseParameter.narrowed_selection.layerIdx;
  const layer = baseParameter.parameter.layers[layerIdx];

  const missingFields: string[] = [];

  const formatted = layer.format.replace(BASE_PARAMETER_FORMAT_REGEX, (_match, fixedSubstring, variableField) => {
    if (fixedSubstring !== void 0) {
      return fixedSubstring;
    }
    if (
      baseParameter.narrowed_selection &&
      typeof baseParameter.narrowed_selection.fields[variableField] === "string"
    ) {
      return baseParameter.narrowed_selection.fields[variableField];
    }

    missingFields.push(variableField);
    return `<${variableField}>`;
  });

  return { formatted, missingFields };
}

/**
 * Remove all invalid narrowing fields. A narrowing might be invalid because it is not part of the current format
 * or because the given field value is not allowed.
 *
 */
export function removeInvalidNarrowing(baseParameter: PartialWeatherParameter) {
  if (!baseParameter.narrowed_selection) {
    return;
  }
  const layerSelection: AnyLayerSchema = baseParameter.parameter.layers[baseParameter.narrowed_selection.layerIdx];
  if (!layerSelection) {
    return;
  }

  for (const [key, value] of Object.entries(layerSelection)) {
    const tmpField = key as keyof AnyLayerSchema;

    const preNarrowedSelectionField = baseParameter.narrowed_selection?.fields[tmpField];
    // if not exist then we don't need to delete the field (i.e. "format")
    if (!preNarrowedSelectionField) {
      return;
    }

    if (value && Array.isArray(value) && !value.includes(preNarrowedSelectionField)) {
      delete baseParameter.narrowed_selection?.fields[tmpField];
    }
  }
}

export function validateNarrowing(baseParameter: PartialWeatherParameter) {
  // removeInvalidNarrowing(baseParameter);
  // diff checking if the field was modified => invalid
}

/**
 * Shorthand for functional chaining of transformations. For example:
 *
 * ```
 * let param = pipe(param,
 *  removeInvalidNarrowing,
 *  autocomplete
 * )
 * ```
 * @param baseParameter input base parameter
 * @param fns the chain of transformations to apply
 */
export function pipe(
  baseParameter: PartialWeatherParameter,
  ...fns: ((baseParameter: PartialWeatherParameter) => PartialWeatherParameter)[]
): PartialWeatherParameter {
  let newParameter: PartialWeatherParameter = baseParameter;
  for (const fn of fns) {
    newParameter = fn(baseParameter);
  }
  return newParameter;
}

/**
 * Given a particular format of weather parameter (HasLayerIdxNarrowing<PartialWeatherParameter>), returns all of its available options.
 * 
 * e.g:
 * Given a HasLayerIdxNarrowing<PartialWeatherParameter> object which represents "t_<level>:<unit>" format.
 * 
 * Returns the parameter's options:
 * {
        "unit":[
            "C",
            "K",
            "F"
        ],
        "level":[
            "0m",
            "2m",
            "10m",
            "50m",
            "100m",
            ....
        ],
    }
 * 
 */
export function getSelectedFormatOptions(
  parameter: HasLayerIdxNarrowing<PartialWeatherParameter>,
): WeatherParamFormatOption {
  const optionWithFormat: WeatherParamFormat = parameter.parameter.layers[parameter.narrowed_selection.layerIdx];
  const { format, ...formatOption } = optionWithFormat;
  return formatOption;
}

/**
 * Same as getSelectedFormatOptions but includes the format name.
 * Essentially it returns the selected format as object.
 * @param parameter
 */
export function getSelectedFormat(parameter: HasLayerIdxNarrowing<PartialWeatherParameter>): WeatherParamFormat {
  const optionWithFormat: WeatherParamFormat = parameter.parameter.layers[parameter.narrowed_selection.layerIdx];
  return optionWithFormat;
}

/**
 * Given a particular format of weather parameter (HasLayerIdxNarrowing<PartialWeatherParameter>), returns the option values which is currently selected.
 * 
 * e.g:
 * Given a HasLayerIdxNarrowing<PartialWeatherParameter> object which represents "t_2m:C" format.
 * 
 * Returns the parameter's options:
 * ```
 * {
        "unit": "C",
        "height": "2m"
    }
 * ```
 */
export function getSelectedFormatOptionValues(
  parameter: HasLayerIdxNarrowing<PartialWeatherParameter>,
): HasLayerIdxNarrowing<PartialWeatherParameter>["narrowed_selection"]["fields"] {
  return parameter.narrowed_selection.fields;
}

/**
 * Given a particular format of weather parameter group (PartialWeatherParameter), returns all of its format templates.
 * 
 * e.g:
 * Given a PartialWeatherParameter object which represents "wind_speed" parameter group.
 * 
 * Returns the all of its format template objects:
 * ```
 * [
    {
      unit: ["bft", "kmh", "kn", "mph", "ms"],
      height: { start: 2, stop: 20000, unit: "m", format: "<value>m" },
      format: "wind_speed_<height>:<unit>",
    },
    {
        unit: ["bft", "kmh", "kn", "mph", "ms"],
        measure: ["mean"],
        level: ["10m", "100m"],
        interval: ["1h", "2h", "3h", "6h", "12h", "24h"],
        format: "wind_speed_<measure>_<level>_<interval>:<unit>",
    },
    ...
 * ]
 * ```
 * 
 */
export function getAllFormats(parameter: PartialWeatherParameter): WeatherParamFormat[] {
  return parameter.parameter.layers;
}

import type { CrossModelParameterSpecification } from "@mm/api-layers.meteomatics.com";
import _ from "lodash";
import {
  type HasLayerIdxNarrowing,
  type PartialWeatherParameter,
  type WeatherParamFormat,
  autofill,
} from "weather-parameter-utils";
import { type FormatFilter, pickAndModifyFormat } from "./format-filter/paramFormatFilter";
import { type GeneralParamFilter, pickAndModifyParamGroup } from "./param-group-filter/paramGroupFilter";
import type { WeatherOptionFilterFlag } from "./paramFilterTypes";

export function applyFiltersOnParamGroupList({
  paramGroups,
  filterFlag,
  formatFilter = pickAndModifyFormat,
  paramGroupFilter = pickAndModifyParamGroup,
}: {
  paramGroups: CrossModelParameterSpecification[];
  filterFlag: WeatherOptionFilterFlag;
  formatFilter?: FormatFilter;
  paramGroupFilter?: GeneralParamFilter;
}): CrossModelParameterSpecification[] {
  const updatedGroups = paramGroups
    // Deep close so it doesn't affect the original data.
    .map((paramGroup) => _.cloneDeep(paramGroup))
    .reduce((groupArray, paramGroup) => {
      const updatedParamGroup = paramGroupFilter({ paramGroup, filterFlag }) as CrossModelParameterSpecification;
      if (!updatedParamGroup) {
        // Skip adding this layer group
        return groupArray;
      }
      // Apply filter for each format, and reassign
      updatedParamGroup.layers = updatedParamGroup.layers.reduce((formatArr, layerFormat) => {
        const updatedFormat = formatFilter({ layerFormat, filterFlag });
        if (!updatedFormat) {
          return formatArr;
        }
        formatArr.push(updatedFormat);
        return formatArr;
      }, [] as WeatherParamFormat[]);
      groupArray.push(updatedParamGroup);
      return groupArray;
    }, [] as CrossModelParameterSpecification[]);

  return updatedGroups;
}

/**
 * An utility to modify the PartialWeatherParameter based on the requirements for each layer kind.
 * The goals include:
 * 1. Filter out unsupported parameter format (e.g: wind_speed)
 * 2. Filter out unsupported options for some parameter formats. (e.g: unit, level, etc)
 */
export function applyParamFilters({
  param,
  filterFlag,
  formatFilter = pickAndModifyFormat,
}: {
  param: PartialWeatherParameter;
  filterFlag: WeatherOptionFilterFlag;
  formatFilter?: FormatFilter;
}): HasLayerIdxNarrowing<PartialWeatherParameter> {
  const updatedParam = _.cloneDeep(param);
  const updatedGroup: CrossModelParameterSpecification = applyFiltersOnParamGroupList({
    paramGroups: [updatedParam.parameter],
    filterFlag: filterFlag,
    formatFilter,
  })[0];

  // @ts-ignore - Supress readonly assignment error. We can safely ignore this as we deepcopy the param.
  updatedParam.parameter = updatedGroup;

  // Run autofill, which sets the selected index
  return autofill(updatedParam);
}

import {
  type AsyncResult,
  createNotRequestedAsyncResult,
  createPendingAsyncResult,
  createResolvedAsyncResult,
} from "@/cache/AsyncResult";
import { type CacheDatum, CacheDatumState, type LoadedCacheDatum } from "@/cache/GlobalCache";
import { type Stats, jsxTable } from "@/cache/Stats";
import { mapboxIconsThreadPool } from "@/threads/MapboxIconsThread/MapboxIconsThreadPool";
import type { IconSlice } from "@/threads/MapboxIconsThread/types";

export interface MaptilerSpritesStats {
  count: number;
  pending: number;
}

export class MaptilerSpritesCache implements Stats<MaptilerSpritesStats> {
  protected cache: { [key: string]: CacheDatum<IconSlice[]> } = {};

  constructor(private readonly label_: string) {}

  stats(): MaptilerSpritesStats {
    let count = 0;
    let pending = 0;
    const cache = this.cache;

    for (const line in cache) {
      if (Object.hasOwn(cache, line)) {
        count++;

        if (cache[line].state === CacheDatumState.Pending) {
          pending++;
        }
      }
    }

    return { count, pending };
  }

  descJsx(): JSX.Element {
    return <>Special-purpose cache for Maptiler icon sprites</>;
  }

  label(): string {
    return this.label_;
  }

  statsJsx(): JSX.Element {
    return jsxTable(this.stats(), (key, val) => val);
  }

  /**
   * A method to return a cached data from cache containing raw parameter values.
   * It does not trigger a new request when the given request hasn't been emitted.
   * @param cacheId
   * @returns
   */
  peekDataCache(cacheId: string): AsyncResult<IconSlice[]> {
    const v = this.cache[cacheId];
    if (!v) {
      return createNotRequestedAsyncResult([]);
    }
    switch (v.state) {
      case CacheDatumState.Pending: {
        return createPendingAsyncResult(v.promise);
      }
      case CacheDatumState.PermanentlyFailed:
        return createResolvedAsyncResult([]);
      case CacheDatumState.Loaded:
        return createResolvedAsyncResult(v.payload);
      default: {
        const _exhaustive: never = v;
        return _exhaustive;
      }
    }
  }

  loadSpriteSheet(id: string, dpi: number): AsyncResult<IconSlice[]> {
    const keySuffix = dpi > 1 ? "@2x" : "@1x";

    const cacheId = `${id}${keySuffix}`;
    if (Object.hasOwn(this.cache, cacheId)) {
      return this.peekDataCache(cacheId);
    }

    const promise = mapboxIconsThreadPool.loadAndSliceSprite(id, dpi).then((payload) => {
      const cacheData: LoadedCacheDatum<IconSlice[]> = {
        requestDatetime: performance.now(),
        lastUsed: Number.NEGATIVE_INFINITY,
        state: CacheDatumState.Loaded,
        payload: payload.data,
      };
      this.cache[cacheId] = cacheData;

      return payload.data;
    });

    this.cache[cacheId] = {
      requestDatetime: performance.now(),
      state: CacheDatumState.Pending,
      promise,
      payload: null,
    };
    return createPendingAsyncResult(promise);
  }
}

import { PlotKind } from "../plots/types";

export type PlotRequest<T extends {}> = T & {
  kind: PlotKind;
};

export type PlotRequest_Transferable<T> = {
  kind: PlotKind;
  request: T;
};

export function PlotRequest_toTransferable<T>(kind: PlotKind, req: T): PlotRequest_Transferable<T> {
  return {
    request: { ...req },
    kind: kind,
  };
}

// There is no point for - from transferable function in this case
export function PlotRequest_fromTransferable<T>(req: PlotRequest_Transferable<T>): PlotRequest_Transferable<T> {
  return {
    request: { ...req.request },
    kind: req.kind,
  };
}

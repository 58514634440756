import {
  type EnsSelectArray,
  type EnsSelectValue,
  band20p,
  band40p,
  band60p,
  band80p,
  band100p,
  getAllEnsSelect,
  transformEnsSelectArrayToString,
} from "@/utility/ensemble";
import { classNames } from "@/utility/jsx";
import { pull, union } from "lodash";
import { type ChangeEvent, useMemo } from "react";
import { useEnsSelectionContext } from "..";
import { EnsMemberCheckbox } from "./EnsMemberCheckbox";

import { t } from "@lingui/macro";
import styles from "./style.module.scss";

const QUANTILE = "Quantile";
const MEMBERS = "Members";

const ensFilter = (query: string, value: EnsSelectValue) => value.toLowerCase().startsWith(query.toLowerCase());

export const quantileBandsLabels = (): { [key: string]: string } => {
  return {
    [band100p]: t`100% band`,
    [band80p]: t`80% band`,
    [band60p]: t`60% band`,
    [band40p]: t`40% band`,
    [band20p]: t`20% band`,
  };
};

interface EnsSelectFormProps {
  searchQuery?: string;
  onChange?: (values: EnsSelectArray) => void;
  groupMembersAndQuantiles?: boolean;
}

export const EnsSelectGroupedForm = ({ searchQuery, onChange }: EnsSelectFormProps) => {
  const {
    availableValues: { members, quantiles, spread, stat, bands },
    selectedEns,
  } = useEnsSelectionContext();

  const handleSelectEns = ({ target: { checked } }: ChangeEvent<HTMLInputElement>, value: EnsSelectValue) => {
    onChange?.(checked ? union(selectedEns, [value]) : pull(selectedEns, value));
  };

  const filteredBands = useMemo(() => {
    if (bands) {
      return bands.filter((ens) => ensFilter(searchQuery || "", quantileBandsLabels()[ens]));
    }
  }, [searchQuery, bands]);

  const filteredAdditional = useMemo(
    () => [...spread, ...stat].filter((ens) => ensFilter(searchQuery || "", ens)),
    [searchQuery, spread, stat],
  );

  return (
    <div className={styles["ens-form"]}>
      {filteredBands?.length && (
        <div className={classNames(styles["ens-container"], styles.additional)}>
          {filteredBands.map((ens) => (
            <EnsMemberCheckbox
              key={ens}
              value={ens}
              checked={selectedEns.includes(ens)}
              name={quantileBandsLabels()[ens]}
              onChange={(e) => handleSelectEns(e, ens)}
            />
          ))}
        </div>
      )}
      {filteredAdditional.length ? (
        <div className={classNames(styles["ens-container"], styles.additional)}>
          {filteredAdditional.map((ens) => (
            <EnsMemberCheckbox
              key={ens}
              value={ens}
              checked={selectedEns.includes(ens)}
              name={ens}
              onChange={(e) => handleSelectEns(e, ens)}
            />
          ))}
        </div>
      ) : undefined}
      {ensFilter(searchQuery || "", QUANTILE) && (
        <div className={classNames(styles["ens-container"])}>
          <EnsMemberCheckbox
            key={QUANTILE}
            value={transformEnsSelectArrayToString(quantiles)}
            checked={selectedEns.includes(transformEnsSelectArrayToString(quantiles))}
            name={QUANTILE}
            onChange={(e) => handleSelectEns(e, transformEnsSelectArrayToString(quantiles))}
          />
        </div>
      )}
      {ensFilter(searchQuery || "", MEMBERS) && (
        <div className={classNames(styles["ens-container"])}>
          <EnsMemberCheckbox
            key={MEMBERS}
            value={getAllEnsSelect("member:", members.length)}
            checked={selectedEns.includes(getAllEnsSelect("member:", members.length))}
            name={MEMBERS}
            onChange={(e) => handleSelectEns(e, getAllEnsSelect("member:", members.length))}
          />
        </div>
      )}
    </div>
  );
};

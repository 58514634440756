import { useEffect } from "react";
import { useController } from "react-hook-form";
import type { HasLayerIdxNarrowing, PartialWeatherParameter } from "weather-parameter-utils";
import { useValueNameCreator } from "../../SmartFormUtils/FormHooks/useFormInstructions";
import type { InputInstruction } from "../../SmartFormUtils/commonTypeUtils";

import styles from "./style.module.scss";

type InputProps = {
  instruction: InputInstruction<HasLayerIdxNarrowing<PartialWeatherParameter>>;
  parameters: Array<HasLayerIdxNarrowing<PartialWeatherParameter>>;
};

export function ToggleParametersGroup({ parameters, instruction }: InputProps) {
  const { createInputName } = useValueNameCreator(instruction);
  const { formMethods, currValue } = instruction;

  const {
    field: { onChange, value },
  } = useController({
    name: createInputName(),
    control: formMethods.control,
    defaultValue: currValue,
  });

  useEffect(() => {
    formMethods.setValue(createInputName(), currValue);
  }, [createInputName, currValue, formMethods]);

  return (
    <div className={styles["toggleable-group"]}>
      {parameters.map((param) => (
        <div key={param.parameter.name} className={styles.toggleable}>
          <input
            type="radio"
            id={param.parameter.name}
            value={param.parameter.name}
            checked={value.parameter.name === param.parameter.name}
            onChange={() => {
              onChange(param);
            }}
          />
          <label htmlFor={param.parameter.name} className={styles.toggle}>
            <span>{param.parameter.description_en}</span>
          </label>
        </div>
      ))}
    </div>
  );
}

import type { PoiDataGrid } from "@/threads/DataProcessingThread/types";
import type { ColDef } from "ag-grid-community";
import { has, union } from "lodash";
import { resetToPrevNumberWhenInvaid } from "./validator";

export function exportColumnsFromData(data: PoiDataGrid) {
  let columns: string[] = [];

  for (let i = 0; i < data.length; i++) {
    const rowColumns = Object.keys(data[i]);
    columns = union(columns, rowColumns);
  }
  return columns;
}

export function groupColumns(columns: string[]): { coordCols: string[]; dataCols: string[] } {
  const groups = { coordCols: [] as string[], dataCols: [] as string[] };
  for (let i = 0; i < columns.length; i++) {
    const col = columns[i];
    if (col === "lat" || col === "lon") {
      groups.coordCols.push(col);
    } else {
      groups.dataCols.push(col);
    }
  }
  return groups;
}

export function addPropertyToData(rowsData: PoiDataGrid, property: string) {
  return rowsData.map((row) => ({
    ...row,
    [property]: undefined,
  }));
}

export function replaceKeyInObject<T>(object: { [key: string]: T }, oldKey: string, newKey: string, newValue: T) {
  // Check if the old key exists in the object
  if (!has(object, oldKey)) {
    return object;
  }

  const newObject: { [key: string]: T } = {};

  for (const key in object) {
    // Make sure that the loop only iterates over properties that belong directly to the object
    // itself, and not over properties that are inherited through the prototype chain.
    if (Object.hasOwnProperty.call(object, key)) {
      if (key === oldKey) {
        newObject[newKey] = newValue;
      } else {
        const value = object[key];
        newObject[key] = value;
      }
    }
  }

  return newObject;
}

export const baseColDef = (column: string): ColDef => ({
  headerName: column,
  field: column,
  editable: true,
  sortable: false,
  resizable: true,
  lockPosition: true,
});

/**
 * Generates an appropriate column definition for coordinate columns
 */
export function coordColDef(colName: string) {
  return {
    ...baseColDef(colName),
    // Use the number validation for lat,lon cells
    valueParser: resetToPrevNumberWhenInvaid,
  };
}

/**
 * Generates an appropriate column definition for custom data columns
 */
export function dataColDef(colName: string) {
  return {
    ...baseColDef(colName),
    // Use the number validation for lat,lon cells
    cellDataType: "text",
  };
}

import {
  type AsyncResult,
  createPendingAsyncResult,
  createPermanentlyFailedAsyncResult,
  createResolvedAsyncResult,
} from "@/cache/AsyncResult";
import type { GeoJSONCacheStats } from "@/cache/GeoJSONCache";
import { type CacheDatum, CacheDatumState, type LoadedCacheDatum } from "@/cache/GlobalCache";
import { apiThreadPool } from "@/cache/SpatioTemporalTileCache/ApiQueryThreadPool";
import {
  FailureState,
  NETWORK_EXPONENTIAL_BACKOFF_MAX_RETRIES,
  type RetryState,
  createRetryState,
} from "@/cache/SpatioTemporalTileCache/RetryState";
import { type Stats, jsxTable } from "@/cache/Stats";

import type { PlotKind } from "@mm/api.meteomatics.com";
import type { MeteomaticsApiError } from "@mm/api.meteomatics.com/lib/error";
import { type Abort, isAbortable } from "@mm/api.meteomatics.com/lib/middleware";

export class PlotCache implements Stats<GeoJSONCacheStats> {
  protected cache: { [key: string]: CacheDatum<any, RetryState> } = {};

  constructor(private readonly label_: string) {}

  statsJsx(): JSX.Element {
    return jsxTable(this.stats(), (key, val) => val);
  }

  descJsx(): JSX.Element {
    return <>Special-purpose cache for Energy Plot JSON Requests. Replacement Policy is LRU.</>;
  }

  label(): string {
    return this.label_;
  }

  stats(): GeoJSONCacheStats {
    let count = 0;
    let pending = 0;
    const cache = this.cache;

    for (const line in cache) {
      Object.hasOwn(cache, line);
      if (Object.hasOwn(cache, line)) {
        count++;

        if (cache[line].state === CacheDatumState.Pending) {
          pending++;
        }
      }
    }

    return { count, pending };
  }

  retrievePlot<T extends {}>(kind: PlotKind, request: T, cacheId: string): AsyncResult<any> {
    let retry_: RetryState | null = null;
    if (Object.hasOwn(this.cache, cacheId)) {
      const v = this.cache[cacheId];
      switch (v.state) {
        case CacheDatumState.Pending:
          retry_ = v.payload;
          if (retry_.failureState !== FailureState.FailedTemporarily) {
            return createPendingAsyncResult(v.promise);
          }
          // since the current invocation moves the state from [Pending/FailedTemporarily] to [Pending/Queued]
          // mark it as such to prevent concurrent invocations from reentering the remaining function body
          retry_.failureState = undefined;
          break;
        case CacheDatumState.PermanentlyFailed:
          // TODO: store and forward failure reason?
          return createPermanentlyFailedAsyncResult(v.payload);
        case CacheDatumState.Loaded:
          // Another workaround for reference bug
          // TODO Remove JSON parse and fix bug appropriate way
          return createResolvedAsyncResult(JSON.parse(JSON.stringify(v.payload)));
        default: {
          const _exhaustive: never = v;
          return _exhaustive;
        }
      }
    }

    const retry = retry_ ?? createRetryState();

    const promise = apiThreadPool
      .getPlotData<T>(kind, request, retry)
      .then((result) => {
        const { requestDatetime } = this.cache[cacheId];
        const cacheLine: LoadedCacheDatum<any> = {
          requestDatetime,
          lastUsed: Number.NEGATIVE_INFINITY,
          state: CacheDatumState.Loaded,
          // Workaround for reference bug
          // TODO Remove JSON parse and fix bug appropriate way
          payload: JSON.parse(JSON.stringify(result)),
        };
        this.cache[cacheId] = cacheLine;

        return result;
      })
      .catch((e: MeteomaticsApiError | Abort) => {
        if (isAbortable(e)) {
          delete this.cache[cacheId];
          return Promise.reject(e);
        }

        retry.failedAttempts++;
        retry.failureState = FailureState.FailedTemporarily;

        if (retry.failedAttempts > NETWORK_EXPONENTIAL_BACKOFF_MAX_RETRIES) {
          retry.failureState = FailureState.FailedPermanently;
          return Promise.reject(e);
        }

        return Promise.reject(e);
      });

    this.cache[cacheId] = {
      requestDatetime: performance.now(),
      state: CacheDatumState.Pending,
      promise,
      payload: retry,
    };

    return createPendingAsyncResult(promise);
  }
}

import * as windowManager from "@/overlay/components/window";
import { Trans } from "@lingui/macro";
import { useMemo } from "react";
import type { ReactNode } from "react";
import { Button, ButtonBar } from "../CommonParts/buttons";
import { type WindowElement, type WindowIdentityProps, type WindowKind, useDesktop } from "../window";
import "./style.scss";

export type CustomPopupProps = {
  title: string;
  mainMsg: string | ReactNode;
  onOk: () => void;
  secondaryMsgs?: Array<string | ReactNode> | undefined;
  onCancel?: () => void;
};

export function CustomPopup(props: WindowIdentityProps<WindowKind.CustomPopup>): WindowElement {
  const {
    instanceId,
    customProps: { title, mainMsg, onOk, secondaryMsgs, onCancel },
  } = props;

  const desktop = useDesktop();

  const actions = useMemo(
    () => [
      windowManager.closeAction(() =>
        desktop.dispatch({ type: "setWindowVisibility", windowId: instanceId, isVisible: false }),
      ),
    ],
    [desktop, instanceId],
  );

  return (
    <windowManager.Window kind={"CustomPopup"} title={title} actions={actions} {...props}>
      <windowManager.WindowSection>
        <div className="main-msg">{mainMsg}</div>
        {secondaryMsgs && (
          <div className="secondary-msgs">
            {secondaryMsgs.map((msg: string | ReactNode, i: number) => (
              <div key={`${i}_${msg?.toString()?.toString()}`}>{msg}</div>
            ))}
          </div>
        )}
        <ButtonBar align="right">
          {onCancel && (
            <Button className="cancel-btn" onClick={onCancel}>
              <Trans>Cancel</Trans>
            </Button>
          )}
          <Button onClick={onOk}>
            <Trans>Ok</Trans>
          </Button>
        </ButtonBar>
      </windowManager.WindowSection>
    </windowManager.Window>
  );
}

import { ColorMap, defaultColorMap, defaultColorMapFromString } from "@mm/api.meteomatics.com";
import type { PartialWeatherParameter } from "weather-parameter-utils";

export function unsafeGetColormapByName(
  colormap_str: string | null,
  weatherParameter: string | PartialWeatherParameter,
): ColorMap {
  if (colormap_str != null && colormap_str !== "") {
    // TODO: remove the empty string check as soon as typing of `color_map` is fixed.
    // https://gitlab.meteomatics.com/mm/web/metx-workbench/-/issues/182
    return ColorMap[colormap_str as keyof typeof ColorMap];
  }
  return typeof weatherParameter === "string"
    ? defaultColorMapFromString(weatherParameter)
    : defaultColorMap(weatherParameter);
}

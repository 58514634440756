import { createPendingAsyncResult, createResolvedAsyncResult } from "@/cache/AsyncResult";
import {
  type CacheDatum,
  CacheDatumState,
  type LoadedCacheDatum,
  type PermanentlyFailedCacheDatum,
} from "@/cache/GlobalCache";
import { apiThreadPool } from "@/cache/SpatioTemporalTileCache/ApiQueryThreadPool";
import { FailureState, type RetryState, createRetryState } from "@/cache/SpatioTemporalTileCache/RetryState";
import { type Stats, defaultCacheFormatter, jsxTable } from "@/cache/Stats";
import { type CoordinateSystem, MeteomaticsApiUrl, WORLD_BBOX, type WfsRequest } from "@mm/api.meteomatics.com";
import type { MeteomaticsApiError } from "@mm/api.meteomatics.com/lib/error";
import { type Abort, isAbortable } from "@mm/api.meteomatics.com/lib/middleware";
import type { WfsCapabilities } from "@mm/api.meteomatics.com/models/WfsCapabilities";
import type { GeoJSONCacheStats } from "../GeoJSONCache";

export class CapabilitiesCache implements Stats<GeoJSONCacheStats> {
  protected cache: { [key: string]: CacheDatum<WfsCapabilities, RetryState> } = {};
  constructor(private readonly label_: string) {}

  stats(): GeoJSONCacheStats {
    let count = 0;
    let pending = 0;
    const cache = this.cache;

    for (const line in cache) {
      if (Object.hasOwn(cache, line)) {
        count++;

        if (cache[line].state === CacheDatumState.Pending) {
          pending++;
        }
      }
    }

    return { count, pending };
  }
  statsJsx(): JSX.Element {
    return jsxTable(this.stats(), defaultCacheFormatter);
  }
  label(): string {
    return this.label_;
  }
  descJsx(): JSX.Element {
    return <>Special-purpose cache for API Capabilities Requests.</>;
  }

  retrieveWfsCapabilities(request: WfsRequest<CoordinateSystem.WGS84, "GetCapabilities">) {
    const url = MeteomaticsApiUrl.forWfsCapabilities(request);
    const cacheId = `wfs${url.toString()}`;

    // TODO: This cache script is duplicated among different methods. Put it into a single function.
    let retry_: RetryState | null = null;
    if (Object.hasOwn(this.cache, cacheId)) {
      const v = this.cache[cacheId];
      switch (v.state) {
        case CacheDatumState.Pending:
          retry_ = v.payload;
          if (retry_.failureState !== FailureState.FailedTemporarily) {
            return createPendingAsyncResult(v.promise);
          }
          // since the current invocation moves the state from [Pending/FailedTemporarily] to [Pending/Queued]
          // mark it as such to prevent concurrent invocations from reentering the remaining function body
          retry_.failureState = undefined;
          break;
        case CacheDatumState.PermanentlyFailed:
          // TODO: store and forward failure reason?
          // fill with empty
          return createResolvedAsyncResult({});
        // return createPermanentlyFailedAsyncResult(v.payload);
        case CacheDatumState.Loaded:
          return createResolvedAsyncResult(v.payload);
        default: {
          const _exhaustive: never = v;
          return _exhaustive;
        }
      }
    }

    const modelBoundingBox = request.boundingBoxLimit || WORLD_BBOX;
    const retry = retry_ ?? createRetryState(modelBoundingBox);

    const promise = apiThreadPool
      .getWfsCapabilitieas(request, retry)
      .then((result) => {
        const { requestDatetime } = this.cache[cacheId];
        const cacheData: LoadedCacheDatum<WfsCapabilities> = {
          requestDatetime,
          lastUsed: Number.NEGATIVE_INFINITY,
          state: CacheDatumState.Loaded,
          payload: result,
        };
        this.cache[cacheId] = cacheData;
        return result;
      })
      .catch((e: MeteomaticsApiError | Abort) => {
        if (isAbortable(e)) {
          delete this.cache[cacheId];
          return Promise.reject(e);
        }
        return this.reactOnMeteomaticsApiError(e, retry, cacheId);
      });

    this.cache[cacheId] = {
      requestDatetime: performance.now(),
      state: CacheDatumState.Pending,
      promise: promise,
      payload: retry,
    };
    return createPendingAsyncResult(promise);
  }

  reactOnMeteomaticsApiError(e: MeteomaticsApiError, retry: RetryState, cacheId: string): Promise<never> {
    // Todo: Make different reactions on Errors, currently always return a CacheDatumState.PermanentlyFailed
    // ToDo: Handle Out of bounds.
    switch (e.kind) {
      case "BoundsError":
      case "TimeError":
      case "ComputationTimeoutError":
      case "AuthError":
      case "UnknownError":
      case "UnknownParameterError":
      case "MissingDataError": {
        const { requestDatetime } = this.cache[cacheId];
        const cacheData: PermanentlyFailedCacheDatum<null> = {
          requestDatetime,
          state: CacheDatumState.PermanentlyFailed,
          payload: null,
        };
        this.cache[cacheId] = cacheData;
        // retry.failureState = FailureState.FailedPermanently;
        return Promise.reject(e);
      }
      default: {
        const _exhaustive: never = e;
        return _exhaustive;
      }
    }
    // Still here for retry functionality
    // Retry would be something for boundsError -> cropping bbox and retry ...
    // retry.failedAttempts++; // accumulated failures for the current tile only
    // retry.failureState = FailureState.FailedTemporarily;
    // if (retry.failedAttempts > NETWORK_EXPONENTIAL_BACKOFF_MAX_RETRIES) {
    //   retry.failureState = FailureState.FailedPermanently;
    //   return Promise.reject(e);
    // }
    //
    // return Promise.reject(e);
  }
}

import {
  useCurrMapInteractionState,
  useMapInteractionService,
} from "@/models/map-interaction/mapInteractionStateHooks";
import type { PropsWithChildren } from "react";

import type { ContextMenuCtx } from "@/models/map-interaction/mapInteractionStateCtxTypes";
import { MapInteractionEventType } from "@/models/map-interaction/mapInteractionStateEvents";
import { PopupPlotType, setPopupPlotState, usePopupPlot } from "@/reducer/plot";
import TephigramSvg from "@/style/img/TephigramSvg";
import { FloatingPortal } from "@floating-ui/react";
import { t } from "@lingui/macro";
import { WindowKind, instanceId, useDesktop } from "..";
import { TrackId } from "../window/TrackId";
import { Menu } from "./Menu";
import { MenuItem } from "./MenuItem";
import { ContextMenuActions } from "./constants";

export function ContextMenuProvider({ children }: PropsWithChildren<unknown>) {
  const { send } = useMapInteractionService();
  const mapInteractionState = useCurrMapInteractionState();

  // To save some time and complexity, wire up context menu with react context
  // but in next iterations, it would make more sense to use xstate everywhere
  // TODO Move context to xstate
  const { dispatch } = usePopupPlot();
  const { dispatch: desktopDispatch, getPosition } = useDesktop();
  const showContextMenu = mapInteractionState.matches("CONTEXT_MENU");

  const setPositionBasedOnTrackId = (trackId: TrackId) => {
    const position = getPosition(instanceId(WindowKind.PopupPlot));
    if (position.isManaged) {
      desktopDispatch({
        type: "setPosition",
        windowId: instanceId(WindowKind.PopupPlot),
        position: { isManaged: true, trackId: trackId },
      });
    }
  };
  const dispatchSetPopupPlotState = (
    coords: ContextMenuCtx["modeOpts"]["coordinates"],
    popupPlotType: PopupPlotType,
    hideLegend = true,
  ) => {
    // TODO: Plot window somehow uses a separate "show" flag to control its visibility status
    // but the desktop system already has the visibility control. Later reconsider this
    // Window can also receive props using "setWindowCustomProps" action, so we might also be able to get rid of the
    // reducer.
    dispatch(
      setPopupPlotState({
        type: popupPlotType,
        coords: coords,
        hideLegend: hideLegend,
        show: true,
      }),
    );
  };

  const handleContextMenuAction = (action: ContextMenuActions, context: ContextMenuCtx) => {
    switch (action) {
      case ContextMenuActions.POPUP_PLOT: {
        setPositionBasedOnTrackId(TrackId.Right);
        dispatchSetPopupPlotState(context.modeOpts.coordinates, PopupPlotType.ActiveLayers, false);
        break;
      }
      case ContextMenuActions.POINT_FORECAST_PLOT: {
        setPositionBasedOnTrackId(TrackId.Bottom);
        dispatchSetPopupPlotState(context.modeOpts.coordinates, PopupPlotType.PointForecast);
        break;
      }
      case ContextMenuActions.TEPHIGRAM: {
        setPositionBasedOnTrackId(TrackId.Bottom);
        dispatchSetPopupPlotState(context.modeOpts.coordinates, PopupPlotType.Tephigram);
        break;
      }
    }
    send({
      type: MapInteractionEventType.dismiss,
    });
  };

  const handleContextMenuClose = () => {
    send({
      type: MapInteractionEventType.dismiss,
    });
  };

  return (
    <>
      {showContextMenu && (
        <FloatingPortal>
          <Menu
            context={mapInteractionState.context}
            onClose={handleContextMenuClose}
            onAction={handleContextMenuAction}
          >
            <MenuItem action={ContextMenuActions.POPUP_PLOT} label={t`Plot`} icon={"show_chart"} />
            <MenuItem action={ContextMenuActions.POINT_FORECAST_PLOT} label={t`Meteogram`} icon={"thermostat"} />
            <MenuItem action={ContextMenuActions.TEPHIGRAM} label={t`Tephigram`} icon={<TephigramSvg />} />
          </Menu>
        </FloatingPortal>
      )}
      {children}
    </>
  );
}

export class ShaderBuilder {
  constructor(
    public shaderName: string,
    public macros: Map<string, number | boolean> = new Map(),
  ) {}

  enable(macro: string) {
    this.macros.set(macro, true);
  }

  disable(macro: string) {
    this.macros.delete(macro);
  }

  set(k: string, v: number | boolean): void;
  set(macros: Iterable<[string, number | boolean]>): void;
  set(macros: Iterable<[string, number | boolean]> | string, b?: number | boolean) {
    if (typeof macros === "string" && b != null) {
      this.macros.set(macros, b);
    } else if (typeof macros !== "string") {
      for (const [k, v] of macros) {
        this.macros.set(k, v);
      }
    }
  }
}

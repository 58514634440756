import { Api } from "@/api/Api";
import { LayoutType, type WeatherTableCreate } from "@mm/metx-workbench.meteomatics.com";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const createWeatherTable = createAsyncThunk(
  "api/weather-table/create",
  async (payLoad: { data: WeatherTableCreate; layoutId: number }, _thunkAPI) => {
    return await Api.weatherTable
      .v2CreateWeatherTable({
        weatherTableCreate: payLoad.data,
      })
      .then((table) => {
        return { tool: table, layoutType: LayoutType.WeatherTable, layoutId: payLoad.layoutId };
      });
  },
);

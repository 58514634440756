import type { EventObject, StateMachine, StateSchema, StateValueFrom } from "xstate";

/**
 * A mapping of deserializer callbacks.
 * A callback should take a value within an object and specify how the value
 * should be converted (e.g: From ISO string to DateTime object)
 *
 * The key should be a dotted notation of the key.
 * ```
 * {
 *  parentAttr: {
 *      childAttr: value
 *    }
 * }
 * ```
 * -> Key "parentAttr.childAttr" specifies how the "value" is deserialized.
 */
export type DeserializerMap = {
  [keyStr: string]: (value: any) => any;
};

/**
 * Applies a callback given in deserializerMapping on an object.
 * The purpose of the function is to allow converting the types of each property within an object.
 * @param obj
 * @param deserializerMapping
 * @param _keyPrefix
 */
export function applyPropertyDeserializer<T extends object>(
  obj: T,
  deserializerMapping: DeserializerMap,
  _keyPrefix = "",
) {
  const result: any = {};

  for (const key in obj) {
    if (Object.hasOwn(obj, key)) {
      // value can be any type, we have special case for object, and different case for the rest of types
      const value: any = obj[key];
      const flattenKey = `${_keyPrefix}${key}`;
      // If the key should be deserialized, do so
      if (flattenKey in deserializerMapping) {
        const deserializer = deserializerMapping[flattenKey];
        result[key] = deserializer(value);
      }
      // If the key has a nested object, recursively proceed to deserialize its child keys.
      else if (typeof value === "object" && value !== null) {
        result[key] = applyPropertyDeserializer(value, deserializerMapping, `${flattenKey}.`);
      } else {
        result[key] = value;
      }
    }
  }

  return result;
}
function _flattenByFirstKey(val: any, parentKey = ""): any {
  if (typeof val !== "object") {
    if (parentKey === "") {
      return val;
    }
    return `${parentKey}${val}`;
  }
  const firstKey = Object.keys(val)[0];
  const flattenKey = `${firstKey}.${parentKey}`;
  return _flattenByFirstKey(val[firstKey], flattenKey);
}

/**
 * A state machine's state value can be either a string or object.
 * A child state can be a nested object such as {ParentState: "ChildState"}
 *
 * This utility converts the state value into a dotted string representation
 * e.g: {ParentState: "ChildState"} -> "ParentState.ChildState"
 */
export function stringifyStateVal<TContext, TStateSchema extends StateSchema, TEvent extends EventObject>(
  value: StateValueFrom<StateMachine<TContext, TStateSchema, TEvent>>,
): string {
  return _flattenByFirstKey(value);
}

/**
 * Note: If this is raised, you're likely placing the action at the unintended transition path
 * within the state machine. To resolve it, you either want to modify the action to support the context
 * change on the path, or double check if you didn't place the action on the wrong place.
 */
export const wrongActionPlacementError = (actionName: string) => {
  return Error(`${actionName} received an incorrect event. The action must be used in the wrong place.`);
};

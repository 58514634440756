import { FormField } from "@/overlay/components/form-field";
import { t } from "@lingui/macro";
import type { CustomGeoJSON } from "@mm/metx-workbench.meteomatics.com";

type CustomGeoJsonFilesSelectProps = {
  selectedFileId: string;
  list: CustomGeoJSON[];
  onChange: (file: string) => void;
};
export function CustomGeoJsonFileSelect({ selectedFileId, list, onChange }: CustomGeoJsonFilesSelectProps) {
  return (
    <FormField label={t`File`}>
      <select
        value={selectedFileId}
        onChange={(event) => onChange(event.target.value)}
        data-testid="geojson-file-select"
      >
        {list.map((geoJsonItem: CustomGeoJSON) => {
          return (
            <option key={geoJsonItem.id} value={geoJsonItem.id}>
              {geoJsonItem.name}
            </option>
          );
        })}
      </select>
    </FormField>
  );
}

import { useProfileAndTabParams } from "@/hooks";
import { useMaps } from "@/reducer/MapsState";
import { useMemo } from "react";

export function useCurrentTabViewportLinkingStatus(): boolean | undefined {
  const { tabId } = useProfileAndTabParams();
  const maps = useMaps(tabId);
  // TODO: this only tests if all are identical and assumes all are unlinked otherwise. Viewports
  // could also be in a mixed state --  a few are linked, a few are unlinked. Should be reflected here.
  const areViewportsLinked = useMemo(
    // Note: access to arr[0] is valid since the closure will only be invoked if there is at least one
    // member in the array
    () => maps?.every((cartographicMap, _i, arr) => cartographicMap.id_viewport === arr[0].id_viewport),
    [maps],
  );
  return areViewportsLinked;
}

/**
 * A less error-prone replacement of string literals.
 *
 * Instead of writing
 *
 * ```
 * document.body.innerHTML = `test ${ { oops: "my mistake" } }`;
 * // results in `test [object Object]`
 * ```
 *
 * write the following to get a compile-time error
 *
 * ```
 * document.body.innerHTML = safeFmt`test ${ { oops: "my mistake" } }`;
 * ```
 */
export function safeFmt(literals: TemplateStringsArray, ...vars: (string | number)[]) {
  const parts: any[] = [];

  // zip literals and variables
  literals.raw.forEach((lit, litIdx) => {
    parts.push(lit);
    if (litIdx < vars.length) {
      const nonLit = vars[litIdx];
      parts.push(nonLit);
    }
  });

  return parts.join("");
}

import type { MeteomaticsApi } from "../MeteomaticsApi";
import type { GridRequest } from "../models";
import { type Abortable, execute } from "./Abortable";
import { RequestAdaptor } from "./RequestAdaptor";

/**
 * Ensures that the response can be mip mapped using WebGL by restricting grid dimensions
 * to power of two textures.
 */
export class MipMappable extends RequestAdaptor<GridRequest<any>, any> {
  enterStateWaiting(_: MeteomaticsApi<any>, request: GridRequest<any>): Abortable<GridRequest<any>> {
    const scaledRequest = {
      ...request,
      width: this.floorNearestPowerOf2(request.width),
      height: this.floorNearestPowerOf2(request.height),
    };

    return execute(scaledRequest);
  }

  private floorNearestPowerOf2(n: number) {
    return 1 << (31 - Math.clz32(n));
  }

  private ceilNearestPowerOf2(n: number) {
    const floored = this.floorNearestPowerOf2(n);
    return floored === n ? n : n * 2;
  }
}

export const middlewareMipMappable = new MipMappable();

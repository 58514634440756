import type {
  BoundsError,
  ComputationTimeoutError,
  MeteomaticsApiError,
  MissingDataError,
  TimeError,
  UnknownParameterError,
} from "./MeteomaticsApiError";
import { T_AREA, T_DATETIME, T_MODEL_ID, T_SECONDS, T_WEATHER_PARAMETER, errorMessage } from "./matching";

// Note: the missing colon after "Available domain" is not a typo on our side, the message is delivered this way from the backend.
/*prettier-ignore*/ const matcherBoundsError = errorMessage<BoundsError>`Model ${[
  "model",
  T_MODEL_ID,
]} not available at queried location. Available domain ${["available", T_AREA]}. Queried domain: ${[
  "queried",
  T_AREA,
]}.`;
/*prettier-ignore*/ const matcherTimeError = errorMessage<TimeError>`Parameter ${[
  "parameter",
  T_WEATHER_PARAMETER,
]} not available at time ${["given", T_DATETIME]}. Available between ${["availableStart", T_DATETIME]} and ${[
  "availableEnd",
  T_DATETIME,
]} (possibly without interval borders).`;
/*prettier-ignore*/ const matcherUnknownParameterError = errorMessage<UnknownParameterError<false>>`Parameter ${[
  "parameter",
  T_WEATHER_PARAMETER,
]} not available in model ${["model", T_MODEL_ID]}`;
/*prettier-ignore*/ const matcherComputationTimeoutErrorWms = errorMessage<ComputationTimeoutError>`Query from user was killed after ${[
  "processingTime",
  T_SECONDS,
]} seconds due to timeout.`;
/*prettier-ignore*/ const matcherComputationTimeoutErrorJson = errorMessage<ComputationTimeoutError>`Query from user timed out after ${[
  "processingTime",
  T_SECONDS,
]} seconds`;
/*prettier-ignore*/ const matcherMissingDataError = errorMessage<MissingDataError<false>>`Missing data for parameter ${[
  "parameter",
  T_WEATHER_PARAMETER,
]}.`;

/**
 * Turn an API error into a structured error message.
 *
 * @param response
 * @param msg the error message, this is simply `response.text()` for binary queries, the `"message"` field of `response.json()` etc depending on the fileFormat requested from the API.
 * @returns
 */
export function decodeApiError(response: Response, msg: string): MeteomaticsApiError {
  if (response.status === 403) {
    return { kind: "AuthError", isEncodedApiError: false };
  }
  if (response.status === 408) {
    // There are different variations of error messages for Computation timeout error.
    return (
      matcherComputationTimeoutErrorWms("ComputationTimeoutError", msg) ??
      matcherComputationTimeoutErrorJson("ComputationTimeoutError", msg) ?? {
        kind: "ComputationTimeoutError",
        isEncodedApiError: false,
        processingTime: 0,
      }
    );
  }

  return (
    matcherBoundsError("BoundsError", msg) ??
    matcherTimeError("TimeError", msg) ??
    matcherMissingDataError("MissingDataError", msg) ??
    matcherUnknownParameterError("UnknownParameterError", msg) ?? {
      kind: "UnknownError",
      msg,
      isEncodedApiError: false,
    }
  );
}

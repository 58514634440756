// TODO: as far as I understand this should not be necessary?
import type { Locale } from "./locale";
export type { Locale };

export interface BuildInfo {
  runtime: "browser";
  isProduction: boolean;
  // all locales that were part of the angular build. Note that this:
  // - will only contain the current locale if angular was invoked once for each locale
  // - will be empty if angular was told to build all available versions instead of an explicit whitelist
  locales: Locale[];
  defaultLocale: Locale;
  // A version string build from the latest git tag and commit hash
  gitVersion: string;
  // A version string extracted from the npm package
  npmVersion: string;
  commitHash: string;
  branch: string;
  datetimeOfBuild: string;
  authors: string[];
}

export const BUILD_INFO: BuildInfo = {
  runtime: __RUNTIME__,
  isProduction: __IS_PRODUCTION__,
  locales: __LOCALES__,
  defaultLocale: __DEFAULT_LOCALE__,
  gitVersion: __GIT_VERSION__,
  npmVersion: __NPM_VERSION__,
  commitHash: __COMMIT_HASH__,
  branch: __BRANCH__,
  datetimeOfBuild: __DATETIME_OF_BUILD__,
  authors: __AUTHORS__,
};

import { SpatialUpsamplingTechnique } from "@/layers/SpatialUpsamplingTechnique";

/**
 * Size of tiles fetched from the Meteomatics API.
 *
 * The tradeoffs here are as follows. A smaller size means more in flight requests and thus:
 *
 * - (a) parallel limit of the API is reached faster
 * - (b) the client is billed more API requests
 * - (c) the parallel limit of the browser is reached faster
 * - (d) a lower latency since the backend does not parallelize requests.
 *       So smaller tiles give us more backend parallelism.
 *
 * The `docs/` contain a performance analysis.
 */

export const TILE_SIZE = 256;

/**
 * Number of requests per tile. Since the backend uses a single core to process a request,
 * this is equivalent to increasing the number of threads in the backend.
 *
 * Use cases for this configuration variable are:
 * - increasing this factor with a constant TILE_SIZE.
 *   In this case, tiles should be available with a lower latency by increasing the strain
 *   on the backend.
 *   Note that this will increase the overall number of data points fetched since the world
 *   is chunked/tiled more corsely. So for example, if only a 10px wide stripe of a tile
 *   is within the viewport, 512px will be fetched for `TILE_SIZE = 512, TILE_PARALLELIZATION_FACTOR = 4`,
 *   while 256px (approximately half the work) would be fetched for `TILE_SIZE = 256, TILE_PARALLELIZATION_FACTOR = 1`.
 * - increasing this factor and adapting TILE_SIZE to keep the number of grid points per
 *   request constant.
 *   In this case, the CPU overhead in the renderer for occlusion culling and cache lookups
 *   is reduced since less, but larger tiles have to be processed.
 *   For example: `TILE_SIZE = 256, TILE_PARALLELIZATION_FACTOR = 1` could be changed to
 *   four times the tile size `TILE_SIZE = 512, TILE_PARALLELIZATION_FACTOR = 4`.
 * - Note: the above discussion assumes that the user has a sufficiently high hard parallel limit.
 *   Increasing the number of parallel requests could negatively affect performance if
 *   the user is already exhausting his hard parallel limit.
 */
export const TILE_PARALLELIZATION_FACTOR = 4;

/**
 * The maximal difference of zoom level between the current zoom level `z_actual` and the zoom
 * level of rendered tiles `z_data` while zooming out (`z_data < z_actual`). So we have more data
 * samples than required.
 *
 * How does a minifcation differ from serving the actual correct tile size?
 *
 * - Visual quality is actually better. The meteomatics API backend computes each pixel
 *   of the grid by fetching and interpolating its nearest neighbors. When using minification,
 *   the mip mapping process averages all values within the pyramid. So, results in regions
 *   with undesampling/high frequency will be more accurate and not suffer from aliasing.
 * - But minification may degrade performance. A difference of `z` levels between the actual
 *   tile zoom and the minified level will result in `pow(2,z) - 1` additional draw calls and
 *   `pow(2,z) - 1` the memory traffic.
 * - So this strategy should only be used across one or two levels to hide the parameter latency
 *   while zooming out rapidly.
 *
 *
 * MUST be a natural number. SHOULD either be `0` or `1`. MUST NOT be `Infinity`, `NaN`.
 *
 * Other values are impractical since work grows quadratically. To give an intuition for the quadratic growth.
 * If this value is set to `2`, draw calls for the tile `0/0/0` would in a worst case scenario additionally
 * look up the following tiles:
 *
 * ```
 * 1/0/0, 1/0/1, 1/1/0, 1/1/1, // first level children
 * 2/0/0, 2/0/1, 2/0/2, 2/0/3, 2/1/0, 2/1/1, 2/1/2, 2/1/3, 2/2/0, 2/2/1, 2/2/2, 2/2/3, 2/3/0, 2/3/1, 2/3/2, 2/3/3 // second level children
 * ```
 *
 * These 20 lookups would be performed for each visible tile.
 */
export const TILE_MAX_ZOOM_MINIFICATION: number = 1;

/**
 * The maximal difference of zoom level between the current zoom level `z_actual` and the zoom
 * level of rendered tiles `z_data` while zooming in (`z_data > z_actual`). So we have to fill
 * in, resp. interpolate, data to fill pixel gaps.
 *
 * How does a magnification differ from serving the actual correct tile size?
 *
 * - Visual quality is worse. We interpolate samples bilinearly instead of having a sample per
 *   pixel.
 * - Performance is better since we have to draw less tiles and move less data.
 *
 * MUST be a valid natural number. Since all levels in the range will be tried,
 * a low value is recommended.
 *
 * Can be `Infinity`, which means try all values in the range `[0,z_actual)`.
 */
export const TILE_MAX_ZOOM_MAGNIFICATION: number = Number.POSITIVE_INFINITY;

/**
 * [EXPERIMENTAL] controls the aggressiveness of the automatic quality scaling
 */
export const DESIRED_TIME_TO_FINISH_QUEUE_MS = 2000;

/**
 * Data Upsampling technique for __linearly interpolatable__ data if the data resolution
 * is coarser than the rendering resolution.
 *
 * Catmul Rom is massively more expensive, but will perform a trilinear interpolation.
 * A trilinear interpolation will hide the visible grid structure for large zoom lod
 * biases.
 */
export const SPATIAL_UPSAMPLING_TECHNIQUE = SpatialUpsamplingTechnique.HarwareBilinear;

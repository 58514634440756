import type { GridCellLayout } from "@mm/metx-workbench.meteomatics.com";
import { pickBy } from "lodash";

export const hasOpeningBorder = (position: number) => [1, 33, 49, 65].indexOf(position) === -1;
export const hasClosingBorder = (position: number) => position !== 97;

export const getBorderClasses = (layout: GridCellLayout) => {
  const { gridColumnStart, gridColumnEnd, gridRowStart, gridRowEnd } = layout;

  const borderClasses = Object.keys(
    pickBy(
      {
        top: hasOpeningBorder(gridRowStart),
        bottom: hasClosingBorder(gridRowEnd),
        left: hasOpeningBorder(gridColumnStart),
        right: hasClosingBorder(gridColumnEnd),
      },
      (value) => value,
    ),
  );

  return `${borderClasses.length ? "grid-border" : ""} ${borderClasses.join(" ")}`;
};

import { useCurrTimeState, useTimeService } from "@/models/time-control/timeStateHooks";
import type { TimeStateMachine } from "@/models/time-control/timeStateMachine";
import type { DateTimeDesc, GuiTimeZone } from "@mm/metx-workbench.meteomatics.com";
import { RelativeDateTimePosition, RelativeDateTimeRoundingDirection } from "@mm/metx-workbench.meteomatics.com";
import { DateTime } from "luxon";
import { useContext, useEffect } from "react";
import type { StateFrom } from "xstate";
import type { Playback } from "./Playback";
import { PlaybackContext } from "./PlaybackContext";
import type { PlaybackState } from "./PlaybackState";

// - Temporal synchronization functions start -
// We are currently migrating the time logic to the state machine
// as an intermediate change, we sync the time state machine and playback,
// which essentially replaces Redux's role with the state machine in the time control logic.

/**
 * Playback currently consists of multiple states
 * 1) one playback state for each tab
 * 2) one global playback state
 * But with the transition to the state machine, we just need one playback state
 * which gets synched with the state machine's context.
 */
const getAppGlobalPlaybackState_tmp = (playback: Playback): PlaybackState | null => {
  return playback.getTab(globalPlaybackStateId);
};

const globalPlaybackStateId = -1;

const convertStateMachineCtxToDateTimeDesc = (
  timeState: StateFrom<TimeStateMachine>,
): { timezone: GuiTimeZone; desc: DateTimeDesc } => {
  if (timeState.matches("ANIMATION")) {
    const timeCtx = timeState.context;
    const desc = {
      // Below releavant properties to initialize animation playback.
      is_relative: false,
      is_series: true,
      abs_start: timeCtx.modeOpts.startTime.toISO(),
      abs_end: timeCtx.modeOpts.endTime.toISO(),
      temporal_resolution: timeCtx.modeOpts.temporalResolution.toISO(),
      fps: timeCtx.modeOpts.fps,
      // Below irrelevant properties.
      is_auto_time_refresh_on: false,
      rel_rounding_on: false,
      rel_position: RelativeDateTimePosition.now_with_5min_precision,
      rel_rounding_direction: RelativeDateTimeRoundingDirection.backward,
      rel_shift_on: false,
      rel_start: "",
      rel_end: "",
      id: 0,
      id_profile: 0,
      time_created: "",
      time_updated: "",
    };
    return { desc, timezone: timeState.context.timezone };
  }
  const timeCtx = timeState.context;
  const displayTime = timeCtx.displayTime ? timeCtx.displayTime : DateTime.utc().setZone(timeCtx.timezone);
  const desc = {
    // Below releavant properties to initialize animation playback.
    is_relative: false,
    is_series: false,
    abs_start: displayTime.toISO(),
    abs_end: displayTime.toISO(),
    temporal_resolution: "PT3H",
    fps: 10,
    // Below irrelevant properties.
    is_auto_time_refresh_on: false,
    rel_rounding_on: false,
    rel_position: RelativeDateTimePosition.now_with_5min_precision,
    rel_rounding_direction: RelativeDateTimeRoundingDirection.backward,
    rel_shift_on: false,
    rel_start: "",
    rel_end: "",
    id: 0,
    id_profile: 0,
    time_created: "",
    time_updated: "",
  };
  return { desc, timezone: timeState.context.timezone };
};

function useDisplayTimeSync_tmp(playback: Playback) {
  const timeState = useCurrTimeState();

  const stateValue = timeState.value.toString();
  const displayTime = timeState.context.displayTime;
  const displayTime_sm = displayTime ? displayTime.toISO() : null;

  // biome-ignore lint/correctness/useExhaustiveDependencies: TODO it was required before
  useEffect(() => {
    if (!displayTime_sm) {
      return;
    }
    const currPlaybackState = getAppGlobalPlaybackState_tmp(playback);
    if (!currPlaybackState) {
      return;
    }
    currPlaybackState.displayTimePointer = DateTime.fromISO(displayTime_sm);

    // Sync the display time when display time and time mode has changed.
  }, [displayTime_sm, playback, stateValue]);
}

function usePlaybackInit_tmp(playback: Playback) {
  // Initialize the playback state if it doesn't exist, using the state machine context values.
  const playbackState = getAppGlobalPlaybackState_tmp(playback);

  const timeState = useCurrTimeState();

  useEffect(() => {
    if (!playbackState) {
      // If not intialized, initialize the playback state from the state machine.
      const { desc, timezone } = convertStateMachineCtxToDateTimeDesc(timeState);

      playback.reinitializeTab(globalPlaybackStateId, desc, timezone);
    }
  }, [timeState, playback, playbackState]);
}

function useTimezoneSync_tmp(playback: Playback) {
  const timeState = useCurrTimeState();
  const timezone = timeState.context.timezone;

  const { timeService } = useTimeService();

  // biome-ignore lint/correctness/useExhaustiveDependencies: TODO why need playback
  useEffect(() => {
    const { desc, timezone } = convertStateMachineCtxToDateTimeDesc(timeService.getSnapshot());

    playback.reinitializeTab(globalPlaybackStateId, desc, timezone);
  }, [playback, timezone, timeService]);
}

function useAnimationRangeSync_tmp(playback: Playback) {
  // Hook to sync the state machine's animation config with playback
  const timeState = useCurrTimeState();
  const modeOpts = timeState.context.modeOpts;

  const { timeService } = useTimeService();

  useEffect(() => {
    // Hack to reinitialize the playback state when the state machine context is updated.
    // We shove the values from the state machine into the DateTimeDesc object, so
    // we don't need to change playback yet.
    // This is an ugly hack, but our plan is to replace the playback entirely with the
    // state machine.
    if (timeService.getSnapshot().matches("ANIMATION") && modeOpts) {
      const { desc, timezone } = convertStateMachineCtxToDateTimeDesc(timeService.getSnapshot());
      playback.reinitializeTab(globalPlaybackStateId, desc, timezone);
    }
    // Trigger initialization upon time context value change.
  }, [modeOpts, playback, timeService]);
}

// - Temporal synchronization functions end -

/**
 * @deprecated
 * ################################################################################################
 *
 * We are in the process of migrating playback logic to use XState state machine to encapsulate the complex logic in the machine.
 * Playback state use to be a single point to access all the time state.
 * Time state machine ( @see TimeStateMachine ) is now responsible as a single time state access point in all parts of application.
 * Please prefer to use @see useCurrTimeState
 *
 * usePlaybackState is still here to support the old logic before the time state machine was created.
 * Currently, it works by deriving the time state from the state machine, and synchronizing the legacy playback state.
 * ################################################################################################
 *
 * Get the animation state of the current tab.
 *
 * Since this only extracts the current tab from the playback context,
 * please see the documentation in `PlaybackContext` for details.
 *
 * @returns playback state of the current tab
 */
export function usePlaybackState(): PlaybackState | null {
  const { playback } = useContext(PlaybackContext);
  const timeState = useCurrTimeState();

  usePlaybackInit_tmp(playback);
  useDisplayTimeSync_tmp(playback);
  useTimezoneSync_tmp(playback);
  useAnimationRangeSync_tmp(playback);

  if (timeState.matches("IDLE")) {
    return null;
  }

  return getAppGlobalPlaybackState_tmp(playback);
}

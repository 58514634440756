import { opacityLimits } from "@/constants/layerConfigAttributes";
import type { LayerUnion } from "@/layers";
import { InputFormField } from "@/overlay/components/form-field";
import { t } from "@lingui/macro";

export function OpacityInput(props: {
  layer: LayerUnion;
  change: (propsValue: Pick<LayerUnion, "opacity">) => void;
  onStart?: () => void;
  onEnd?: () => void;
}) {
  return (
    <InputFormField
      label={t`Opacity`}
      type="range"
      {...opacityLimits}
      onChange={(event) => props.change({ opacity: Number(event.target.value) })}
      onMouseDown={() => props.onStart?.()}
      onMouseUp={() => props.onEnd?.()}
      value={props.layer.opacity}
      data-testid="opacity-input"
    />
  );
}

import { type CheckedProps, LayerBase, type PropsChecker } from "@/layers";
import type { ScenePublicApi } from "@/layers/Compositor";
import type { DrawingLayer } from "@/layers/geojson/drawing/TEMPORAL_TYPE_DRAWING";
import { getDrawLayers } from "@/layers/geojson/drawing/drawing-layer-utilities";
import { DrawRadius, Idle } from "@/layers/geojson/drawing/mapbox-draw-mode";
import type { AvailableDrawModes } from "@/layers/geojson/drawing/mapbox-draw-mode/modeNames";
import {
  removeLayerGroup,
  removeSource,
  updateMapboxLayoutProperties,
  updateMapboxPaintProperties,
} from "@/layers/utility/mapbox-utils";
import {
  MapDrawingToolEventType,
  type MapDrawingToolEventUnion,
  type MapDrawingToolStateType,
  globalMapDrawingService,
} from "@/models/map-drawing-tools";
import { safeFmt } from "@/utility/safeFmt";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import type { GuiTimeZone } from "@mm/metx-workbench.meteomatics.com";
import type { FeatureCollection } from "geojson";
import type { DateTime } from "luxon";
import type { BaseActionObject, ResolveTypegenMeta, ServiceMap, State, TypegenDisabled } from "xstate";
import { DRAWING_STYLES } from "./style";

// @ts-ignore: TODO Error should go away, once DrawingLaer is part of LayerBase
export class DrawingLayerImpl extends LayerBase<DrawingLayer> {
  private mapboxDraw: MapboxDraw;
  private drawingModes: AvailableDrawModes;

  constructor(
    id: number,
    props: DrawingLayer,
    scene: ScenePublicApi,
    timezone: GuiTimeZone,
    drawings: FeatureCollection,
  ) {
    super(id, props, scene, timezone);
    const map = this.scene.getMapboxMap();

    this.drawingModes = {
      ...MapboxDraw.modes,
      idle: Idle(),
      draw_radius: DrawRadius(),
    };

    this.mapboxDraw = new MapboxDraw({
      defaultMode: "idle",
      displayControlsDefault: false,
      modes: this.drawingModes,
      styles: DRAWING_STYLES,
      userProperties: true,
    });

    // Typing seems to have issue on mapbox-gl-draw level: https://github.com/mapbox/mapbox-gl-draw/issues/1257
    // @ts-ignore
    map.addControl(this.mapboxDraw);

    if (drawings) {
      this.mapboxDraw.add(drawings);
    }

    map.on("draw.modechange", (e) => {
      if (e.mode === "idle") {
        globalMapDrawingService.send({
          type: MapDrawingToolEventType.publish,
          data: this.mapboxDraw.getAll(),
          mapId: this.props.id_cartographicmap,
        });
      }
    });

    globalMapDrawingService.subscribe(this.changeDrawingMode.bind(this));
  }

  changeDrawingMode(
    state: State<
      unknown,
      MapDrawingToolEventUnion,
      any,
      MapDrawingToolStateType,
      ResolveTypegenMeta<TypegenDisabled, MapDrawingToolEventUnion, BaseActionObject, ServiceMap>
    >,
  ) {
    if (Object.keys(this.drawingModes).some(state.matches)) {
      const mode = state.value as keyof AvailableDrawModes;
      if (this.mapboxDraw.getMode() !== mode) {
        this.mapboxDraw.changeMode(mode);
      }
    } else {
      if (this.mapboxDraw.getMode() !== "idle") {
        this.mapboxDraw.changeMode("idle");
      }
    }
  }

  humanReadableId(): string {
    return safeFmt`metx.DrawingLayer#${this.uid}`;
  }

  moveZIndex(_beforeLayerId?: string | undefined): void {
    // TODO: Check if the layer ordering works, without these lines of code.
    // const drawingLayerIds = getDrawLayers(this.scene.getMapboxMap()).reverse();
    // moveZIndex(this.scene.getMapboxMap(), drawingLayerIds, beforeLayerId);
  }

  // biome-ignore lint/suspicious/noEmptyBlockStatements: <explanation>
  beforeRender(): void {}

  // @ts-ignore: TODO Error should go away, once DrawingLayer is part of LayerBase
  checker(): PropsChecker<DrawingLayer, LayerBase<DrawingLayer>> {
    type CheckerProps = CheckedProps<DrawingLayer>;
    const checkLayerProp = (property: CheckerProps) => (prev: any, curr: any) => {
      const changed = prev[property] !== curr[property];
      if (changed) {
        const map = this.scene.getMapboxMap();
        this.setLayerProps({ [property]: curr[property] });
        switch (property) {
          case "show":
            updateMapboxLayoutProperties(map, getDrawLayers(map), {
              visibility: curr.show ? "visible" : "none",
            });
            break;
          case "opacity":
            updateMapboxPaintProperties(map, getDrawLayers(map), {
              "icon-opacity": curr.opacity,
              "text-opacity": curr.opacity,
            });
            break;
          default: {
            const _exhaustive: never = property;
            return _exhaustive;
          }
        }
      }

      return changed;
    };
    return {
      opacity: checkLayerProp("opacity"),
      show: checkLayerProp("show"),
    };
  }

  get mapboxIndex(): string {
    return getDrawLayers(this.scene.getMapboxMap())[0];
  }

  removeLayer(): void {
    const map = this.scene.getMapboxMap();
    removeLayerGroup(this.scene.getMapboxMap(), getDrawLayers(map));
    removeSource(this.scene.getMapboxMap(), getDrawLayers(map));
    // @ts-ignore
    this.scene.getMapboxMap().removeControl(this.mapboxDraw);
  }

  fetchData(_: DateTime): Promise<void> {
    return Promise.resolve();
  }

  isRebuffering(_: DateTime): boolean {
    return false;
  }

  getActiveWeatherParametersAsString() {
    return [];
  }
}

import type { SvgComponentProps } from "@/style/img/MapSvg";
import type React from "react";

const EnergySvg: React.FunctionComponent<SvgComponentProps> = ({ className }) => (
  <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path d="M512 837.12c-98.679467 0-183.995733-35.259733-253.559467-104.840533C189.474133 663.313067 154.197333 578.901333 153.6 481.3824a17.066667 17.066667 0 0 1 16.964267-17.169067H170.666667a17.066667 17.066667 0 0 1 17.066666 16.964267c0.529067 88.200533 32.443733 164.573867 94.839467 226.9696 62.941867 62.941867 140.117333 94.839467 229.4272 94.839467a17.066667 17.066667 0 1 1 0 34.133333z m341.333333-341.333333a17.066667 17.066667 0 0 1-17.066666-17.066667c0-89.2928-31.8976-166.485333-94.839467-229.4272C678.485333 186.350933 601.2928 154.453333 512 154.453333s-166.485333 31.914667-229.4272 94.839467a342.1696 342.1696 0 0 0-52.8384 67.447467 17.066667 17.066667 0 1 1-29.3888-17.373867 376.849067 376.849067 0 0 1 58.094933-74.205867C328.004267 155.5968 413.320533 120.32 512 120.32s183.995733 35.2768 253.559467 104.840533S870.4 380.040533 870.4 478.72a17.066667 17.066667 0 0 1-17.066667 17.066667z" />
    <path d="M853.333333 922.453333h-85.333333c-27.904 0-52.343467-10.308267-72.6528-30.600533-15.598933-15.581867-25.122133-33.928533-28.433067-54.7328H512a17.066667 17.066667 0 1 1 0-34.133333h154.914133c3.2768-20.804267 12.7488-39.389867 28.228267-55.381334 20.6848-20.0192 45.056-29.952 72.8576-29.952h85.333333a17.066667 17.066667 0 0 1 17.066667 17.066667V750.933333h68.266667a17.066667 17.066667 0 1 1 0 34.133334h-68.266667v69.12h68.266667a17.066667 17.066667 0 1 1 0 34.133333h-68.266667v17.066667a17.066667 17.066667 0 0 1-17.066667 17.066666z m-153.6-102.4c0 18.807467 6.468267 34.389333 19.746134 47.6672 13.8752 13.858133 29.730133 20.599467 48.520533 20.599467h68.266667v-136.533333h-68.266667c-18.8928 0-34.833067 6.536533-48.708267 19.950933-13.038933 13.4656-19.5584 29.3888-19.5584 48.315733z m153.6-238.933333c-0.7168 0-1.4336-0.0512-2.1504-0.136533h-0.034133a16.9472 16.9472 0 0 1-9.966933-4.949334l-85.248-85.248a17.0496 17.0496 0 1 1 24.132266-24.132266L836.266667 522.8544V478.72a17.066667 17.066667 0 1 1 34.133333 0v44.1344l56.200533-56.200533a17.0496 17.0496 0 1 1 24.132267 24.132266l-85.333333 85.333334-0.034134 0.034133a17.066667 17.066667 0 0 1-10.888533 4.932267h-0.034133a24.064 24.064 0 0 1-1.109334 0.034133zM170.666667 498.346667a17.066667 17.066667 0 0 1-17.066667-17.066667v-46.6944l-56.200533 56.200533a17.0496 17.0496 0 1 1-24.132267-24.132266l85.333333-85.333334a17.066667 17.066667 0 0 1 10.922667-4.9664h0.017067a14.8992 14.8992 0 0 1 3.259733 0.1024h0.017067a16.9984 16.9984 0 0 1 9.984 4.949334l85.248 85.248a17.0496 17.0496 0 1 1-24.132267 24.132266L187.733333 434.5856V481.28a17.066667 17.066667 0 0 1-17.066666 17.066667z" />
  </svg>
);

export default EnergySvg;

import { GeocoderApiConfiguration } from "@/env";
import type { LngLat } from "@/layers";
import { GeocoderApi, type Language } from "@mm/geocoder.meteomatics.com";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useRef } from "react";

type SearchLocationParams = {
  searchTerm: string;
  language?: Language;
};

export type SearchLocationResult = {
  mapBounds: MapBounds;
  name: string;
  geometry: LngLat;
};

export type MapBounds = {
  southWest: LngLat;
  northEast: LngLat;
};

export const SEARCH_LOCATION_QUERY_KEY = "SEARCH_LOCATION";

const geocoderApi = new GeocoderApi(GeocoderApiConfiguration);

const fetchLocations = async (params: SearchLocationParams): Promise<SearchLocationResult[]> => {
  const response = await geocoderApi.directGeocoderApiV1GeocoderDirectGet({
    location: params.searchTerm,
    language: params.language,
  });
  const locationsResponse: SearchLocationResult[] = response.results.map((result) => {
    return { mapBounds: result.bounds, name: result.formatted, geometry: result.geometry };
  });

  return locationsResponse;
};

export const useSearchLocation = (params: SearchLocationParams) => {
  const queryClient = useQueryClient();
  const queryKey = [SEARCH_LOCATION_QUERY_KEY, params.searchTerm];
  const query = useQuery<SearchLocationResult[], Error>(queryKey, () => fetchLocations(params), {
    enabled: !!params.searchTerm,
  });
  const prevInputValue = useRef(params.searchTerm);

  useEffect(() => {
    // Only invalidate the previous query if the inputValue has changed
    if (prevInputValue.current !== params.searchTerm) {
      // Check if it works without invalidate.
      queryClient.invalidateQueries([SEARCH_LOCATION_QUERY_KEY, prevInputValue.current]).then(() => {
        prevInputValue.current = params.searchTerm;
      });
    }
  }, [params.searchTerm, queryClient]);

  return query;
};

import { setChartOffsetDependingOnSerieIcon } from "@/plot/highchartsUtility/legends-helper";
import type { Options, XAxisOptions, YAxisOptions } from "highcharts";

export const HighchartsXAxisBottomDefault: XAxisOptions = {
  type: "datetime",
  title: {
    text: "Date (UTC)",
  },
  plotLines: [],
  plotBands: [],
  minorTickInterval: 1000 * 60 * 60 * 6,
  tickInterval: 1000 * 60 * 60 * 3,
  crosshair: true,
};

export const HighchartsXAxisTop: XAxisOptions = {
  linkedTo: 0,
  type: "datetime",
  tickInterval: 24 * 3600 * 1000,
  labels: {
    format: '{value:<span style="font-size: 12px; font-weight: bold">%a</span> %b %e}',
    align: "left",
    x: 3,
    y: 4,
  },
  opposite: true,
  tickLength: 20,
  gridLineWidth: 1,
};

const DefaultIconYAxis: YAxisOptions = {
  visible: false,
  title: {
    text: null,
  },
};

// We always set the chart to these 8 yAxis options
export const HighchartsYAxis: Array<YAxisOptions> = [
  {
    visible: true,
    title: {
      text: null,
    },
    opposite: false,
  },
  {
    visible: true,
    title: {
      text: null,
    },
    opposite: true,
  },
  {
    visible: true,
    title: {
      text: null,
    },
    opposite: false,
  },
  {
    visible: true,
    title: {
      text: null,
    },
    opposite: true,
  },
  {
    visible: true,
    title: {
      text: null,
    },
    opposite: false,
  },
  {
    visible: true,
    title: {
      text: null,
    },
    opposite: true,
  },
  {
    visible: true,
    title: {
      text: null,
    },
    opposite: false,
  },
  // last yaxis is reserved for icon (weather symbol and wind barbs)
  DefaultIconYAxis,
];

const DefaultPrecipitation1hYAxis: YAxisOptions = {
  visible: false,
  softMax: 10,
  // labels TODO: labels is place above column (could maybe set on series)
  title: {
    text: null,
  },
  opposite: false,
};

const DefaultMslPressureYAxis: YAxisOptions = {
  visible: true,
  min: 950,
  max: 1030,
  // min max could also not be set as long series point to yAxis idx without other series data on it.
  title: {
    text: null,
  },
  opposite: true,
};
const DefaultSunDurationYAxis: YAxisOptions = {
  visible: false,
  min: 0,
  max: 60, // minutes
  endOnTick: false,
  title: {
    text: null,
  },
  opposite: false,
};
const DefaultGlobalRadYAxis: YAxisOptions = {
  visible: false,
  title: {
    text: null,
  },
  opposite: false,
};

const DefaultCloudAreaFraction: YAxisOptions = {
  visible: false,
  min: 0,
  max: 100, // percent
  reversed: true,
  endOnTick: false,
  title: {
    text: null,
  },
};

/**
 * yAxis list with some preset of yAxis
 *
 * Note: Remember that we also want to implement a function for the popup plot
 * Checks the unit of each parameter and if they are different they should get different Y-axes.
 * So question is which slots should we reserve for what.
 * */
export const HighchartsYAxisPointForecast: Array<YAxisOptions> = [
  HighchartsYAxis[0], //temperature
  DefaultMslPressureYAxis,
  DefaultPrecipitation1hYAxis,
  DefaultGlobalRadYAxis,
  DefaultSunDurationYAxis,
  DefaultCloudAreaFraction,
  HighchartsYAxis[6],
  DefaultIconYAxis,
];

// TODO: better weekend view - plotOptions:
export const HighchartsChartOptionsDefault: Options = {
  // https://api.highcharts.com/highcharts/boost default is 50
  boost: { seriesThreshold: 18 },
  chart: {
    zooming: { type: "xy" },
    animation: false,
  },
  title: {
    text: undefined,
  },
  credits: {
    enabled: false,
  },
  yAxis: HighchartsYAxis,
  xAxis: [{ ...HighchartsXAxisBottomDefault }, { ...HighchartsXAxisTop }],
  legend: {},
  series: [],
  plotOptions: {
    windbarb: {
      tooltip: {
        valueDecimals: 2,
      },
      grouping: true,
      events: {
        legendItemClick: setChartOffsetDependingOnSerieIcon,
      },
    },
    // @ts-ignore
    weathersymbol: {
      tooltip: {
        valueDecimals: 2,
      },
      grouping: true,
      events: {
        legendItemClick: setChartOffsetDependingOnSerieIcon,
      },
    },
  },
};

import type { AsyncResult } from "@/cache/AsyncResult";
import { networkCaches } from "@/cache/GlobalCache";
import type { CheckedProps, LayerBase, PropsChecker } from "@/layers";
import type { ScenePublicApi } from "@/layers/Compositor";
import { generateWeatherFrontsLayerCacheId } from "@/layers/geojson/LayerUtils";
import { MapboxGeoJSONLayer } from "@/layers/geojson/MapboxGeoJSONLayer";
import { getMapboxIconSize } from "@/layers/utility/iconSize";
import { SENTRY_TRANSACTION_NAMES } from "@/sentry/TransactionConst";
import { captureTransaction } from "@/sentry/helpers";
import { safeFmt } from "@/utility/safeFmt";
import { getModelBoundingBox } from "@/weather-parameters/lookup";
import type { GeoJSONFeatureCollection, WeatherFrontsRequest } from "@mm/api.meteomatics.com";
import type { GuiTimeZone, WeatherFrontsLayer } from "@mm/metx-workbench.meteomatics.com";
import { debounce } from "lodash";
import type { DateTime } from "luxon";
import type { Anchor, Expression, LineLayout, LinePaint, StyleFunction } from "mapbox-gl";

export class WeatherFrontsLayerImpl extends MapboxGeoJSONLayer<WeatherFrontsLayer> {
  readonly mapboxLineLayerId: string;

  constructor(id: number, props: WeatherFrontsLayer, scene: ScenePublicApi, timezone: GuiTimeZone) {
    super(id, props, scene, timezone);

    this.mapboxLineLayerId = `${this.humanReadableId()}-line`;
    this.scene.getMapboxMap().addLayer({
      id: this.mapboxLineLayerId,
      type: "line",
      source: this.mapbox_source,
      paint: {
        "line-color": WeatherFrontsColor, //"#FF0000",// this.props.line_color,
        "line-width": this.props.line_width,
      },
    });

    // symbol layer
    this.updateMapboxLayoutProperties({
      visibility: this.props.show ? "visible" : "none",
      "symbol-placement": "line",
      "symbol-spacing": 40,
      "icon-size": getIconSizeExpression(
        getMapboxIconSize(this.getWeatherFrontsIconSizeAsScale(this.props.text_size), 64),
      ),
      // this value we would get from the feature properties to know on which side we need to draw.
      // rotate 0 and anchor bottom it draws the icons on the left side of the line (start - end)
      "icon-rotate": WeatherFrontsIconDirection,
      "icon-anchor": WeatherFrontsIconAnchor,
      "icon-padding": 0,
      "icon-allow-overlap": true,
      "icon-image": WeatherFrontsIconName, //name of the icon from the image or sprite
      "icon-ignore-placement": true,
    });
    this.updateMapboxPaintProperties({
      "icon-color": WeatherFrontsColor,
    });

    // update the line layer
    this.updateMapboxLineLayoutProperties({
      visibility: this.props.show ? "visible" : "none",
    });

    this.updateMapboxLinePaintProperties({
      "line-width": this.props.line_width,
    });
  }
  getWeatherFrontsIconSizeAsScale(value: number): number {
    return Number(value / 100);
  }

  protected updateMapboxLineLayoutProperties(props: LineLayout) {
    for (const [name, value] of Object.entries(props)) {
      this.scene.getMapboxMap().setLayoutProperty(this.mapboxLineLayerId, name, value);
    }
  }

  protected updateMapboxLinePaintProperties(props: LinePaint) {
    for (const [name, value] of Object.entries(props)) {
      this.scene.getMapboxMap().setPaintProperty(this.mapboxLineLayerId, name, value);
    }
  }

  beforeRender(): void {
    if (this.scene.isAnimation()) {
      this.updateTrigger();
    } else {
      this.debouncedUpdateTrigger();
    }
  }

  debouncedUpdateTrigger = debounce(() => {
    this.updateTrigger();
  }, 400);

  checker(): PropsChecker<WeatherFrontsLayer, LayerBase<WeatherFrontsLayer>> {
    type CheckerProps = CheckedProps<WeatherFrontsLayer>;
    const checkLayerProp = (property: CheckerProps) => (prev: any, curr: any) => {
      const changed = prev[property] !== curr[property];
      if (changed) {
        this.setLayerProps({ [property]: curr[property] });
        switch (property) {
          case "model":
            this.beforeRender();
            break;
          case "parameter_unit":
            this.beforeRender();
            break;
          case "ens_select":
            this.beforeRender();
            break;
          case "calibrated":
            this.beforeRender();
            break;
          case "show":
            this.updateMapboxLayoutProperties({ visibility: curr.show ? "visible" : "none" });
            this.updateMapboxLineLayoutProperties({ visibility: curr.show ? "visible" : "none" });
            break;
          case "opacity":
            this.updateMapboxPaintProperties({
              "text-opacity": curr[property],
            });
            this.updateMapboxLinePaintProperties({
              "line-opacity": curr[property],
            });
            break;
          case "text_size":
            this.updateMapboxLayoutProperties({
              "icon-size": getIconSizeExpression(
                getMapboxIconSize(this.getWeatherFrontsIconSizeAsScale(curr[property]), 64),
              ),
            });
            break;
          case "line_width":
            this.updateMapboxLinePaintProperties({
              "line-width": curr[property],
            });
            break;
          case "custom_options":
            break;
          default: {
            const _exhaustive: never = property;
            return _exhaustive;
          }
        }
      }
      return changed;
    };
    return {
      model: checkLayerProp("model"),
      opacity: checkLayerProp("opacity"),
      parameter_unit: checkLayerProp("parameter_unit"),
      calibrated: checkLayerProp("calibrated"),
      show: checkLayerProp("show"),
      text_size: checkLayerProp("text_size"),
      line_width: checkLayerProp("line_width"),
      ens_select: checkLayerProp("ens_select"),
      custom_options: checkLayerProp("custom_options"),
    };
  }

  updateTrigger(): void {
    const dateTimeWithOffset = this.scene.getDisplayTimeWithOffset();
    const request = this.createRequest(dateTimeWithOffset);

    const result = networkCaches.geojson_cache.retrieveWeatherFronts(request);
    this.updateSource(result, dateTimeWithOffset);
  }

  isRebuffering(time: DateTime): boolean {
    return super.isRebuffering(time);
  }

  createRequest(dateTimeWithOffset: DateTime): WeatherFrontsRequest {
    const map = this.scene.getMapboxMap();

    /**
     * TODO: rm distance 0 and remove width and height on url request
     * (has so far no effect on url but may on the request splitter)
     */
    const { area } = this.computeBoundingBox(0);
    const { width, height } = map.getCanvas();
    return {
      path: "get_weather_fronts",
      area: area,
      parameter: this.props.parameter_unit,
      model: this.props.model,
      ensSelect: this.props.ens_select ? this.props.ens_select : "",
      boundingBoxLimit: getModelBoundingBox(this.props.model),
      datetime: dateTimeWithOffset,
      height: height,
      width: width,
      calibrated: this.props.calibrated,
    };
  }

  fetchData(timeFrame: DateTime): Promise<void> {
    const dateTimeWithOffset = this.scene.getDateTimeWithOffset(timeFrame);
    const request = this.createRequest(dateTimeWithOffset);
    const tags = {
      layerKind: `${this.props.kind ? this.props.kind : "undefined"}`,
      parameter: `${this.props.parameter_unit}`,
      requestedDate: `${dateTimeWithOffset}`,
    };

    // Station data loading speed measurement.
    const { asynchronous } = networkCaches.geojson_cache.retrieveWeatherFronts(request);

    if (!this.scene.isAnimation()) {
      return captureTransaction(asynchronous, SENTRY_TRANSACTION_NAMES.FRONTS_LAYER_LOADING_SPEED, tags);
    }

    return asynchronous
      .then(() => {
        return Promise.resolve();
      })
      .catch((err) => {
        return Promise.resolve();
      });
  }

  getActiveWeatherParametersAsString(): { model: string; parameter: string }[] {
    return [];
  }

  humanReadableId(): string {
    return safeFmt`metx.weatherfrontslayer#${this.uid}#${this.props.model}.${this.props.parameter_unit}`;
  }

  removeLayer() {
    this.scene.getMapboxMap().removeLayer(this.mapboxLineLayerId);
    super.removeLayer();
  }

  moveZIndex(beforeLayerId: string) {
    const map = this.scene.getMapboxMap();
    // order matters
    super.moveZIndex(beforeLayerId);
    map.moveLayer(this.mapboxLineLayerId, beforeLayerId);
  }

  peekLayerCache(request: WeatherFrontsRequest): AsyncResult<GeoJSONFeatureCollection> {
    const cacheId = generateWeatherFrontsLayerCacheId(request);
    return networkCaches.geojson_cache.peekCache(cacheId);
  }
}

export const WeatherFrontsColor: string | Expression = [
  "case",
  ["==", ["get", "front-type"], "cold"],
  "#1a1dee",
  ["==", ["get", "front-type"], "warm"],
  "#ff0000",
  ["==", ["get", "front-type"], "stationary"],
  "#9400d3",
  "#000000",
];

export const WeatherFrontsIconDirection: number | Expression = [
  "case",
  ["==", ["get", "direction"], "left"],
  0,
  ["==", ["get", "direction"], "right"],
  180,
  0,
];

export const WeatherFrontsIconAnchor: Anchor | StyleFunction | Expression | undefined = [
  "case",
  ["==", ["get", "front-type"], "cold"],
  "bottom",
  ["==", ["get", "front-type"], "warm"],
  "bottom",
  ["==", ["get", "front-type"], "stationary"],
  "center",
  "center",
];
export const WeatherFrontsIconName: string | Expression = [
  "case",
  ["==", ["get", "front-type"], "cold"],
  "mm-triangle",
  ["==", ["get", "front-type"], "warm"],
  "mm-semi-circle",
  ["==", ["get", "front-type"], "stationary"],
  "mm-stationary-cold-up",
  "",
];

function getIconSizeExpression(scaleValue: number): number | Expression {
  return [
    "case",
    ["==", ["get", "front-type"], "cold"],
    scaleValue,
    ["==", ["get", "front-type"], "warm"],
    scaleValue,
    ["==", ["get", "front-type"], "stationary"],
    // stationary-cold-up icon has half size
    Number(scaleValue * 2),
    0.5,
  ];
}

import { apiThreadPool } from "@/cache/SpatioTemporalTileCache/ApiQueryThreadPool";
import type { MapboxGeoJSONFeature, Style } from "mapbox-gl";
import { BaseSubLayer } from "./BaseSubLayer";
import { ICON_LAYERS, INTERACTIVE_LAYERS_NAMES, TEXT_LAYERS, composeMetarLayers } from "./metarLayerCompositor";
import { createMetarTooltip } from "./tooltips/metarTooltip";
import { convertRange } from "./utils";

const METAR_TIME_SPAN = 24 * 5;

export class MetarLayer extends BaseSubLayer {
  createLayers(_style: Style): void {
    const map = this.scene.getMapboxMap();

    const metarVectorLayers = composeMetarLayers(`${this.uuid}_tiles`, this.initialProps.text_size);
    for (const layer of metarVectorLayers) {
      map.addLayer(layer, this.beforeLayerId);
      this.layers.push(layer.id);
    }

    const interactiveLayers = INTERACTIVE_LAYERS_NAMES.map((layerName) => `${layerName}_${this.uuid}_tiles`);

    this.setEventListeners(interactiveLayers);
  }

  createTooltip(feature: MapboxGeoJSONFeature): string {
    if (feature.properties && this.displayDateTime) {
      const startDateTime = this.displayDateTime.minus({ hours: METAR_TIME_SPAN });
      const endDateTime = this.displayDateTime;

      apiThreadPool
        .getMetars({
          icao: feature.properties.icao as string,
          startDateTime,
          endDateTime,
        })
        .then((res) => {
          this.updateTooltip(createMetarTooltip(feature.properties, res.metars));
        });
    }

    return createMetarTooltip(feature.properties);
  }

  updateSize(size: number): void {
    for (const [name, { minSize, maxSize }] of Object.entries(ICON_LAYERS)) {
      const newValue = convertRange(size, [10, 34], [minSize, maxSize]);
      this.updateMapboxLayoutProperties(`${name}_${this.uuid}_tiles`, { "icon-size": newValue });
    }

    for (const [name, { minSize, maxSize }] of Object.entries(TEXT_LAYERS)) {
      const newValue = convertRange(size, [10, 34], [minSize, maxSize]);

      this.updateMapboxLayoutProperties(`${name}_${this.uuid}_tiles`, { "text-size": newValue });
    }
  }
}

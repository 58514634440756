import { useActor } from "@xstate/react";
import { useContext } from "react";
import { MapInteractionStateMachineContext } from "./mapInteractionStateContext";

export function useMapInteractionService() {
  const { mapInteractionService } = useContext(MapInteractionStateMachineContext);
  return {
    mapService: mapInteractionService,
    send: mapInteractionService.send,
  };
}

export function useCurrMapInteractionState() {
  const { mapService } = useMapInteractionService();
  const [state] = useActor(mapService);
  return state;
}

import { useCallback, useEffect, useRef, useState } from "react";

/**
 * Callback invoked by `useInFixedInterval`.
 *
 * @param time the current time measured in milliseconds. (Not the delta to the last invocation since the delta should be approximately constant.)
 */
export type InFixedIntervalCallback = (time: number) => void;

export const useInFixedInterval = (intervalMilliseconds: number, callback: InFixedIntervalCallback) => {
  // Use useRef for mutable variables that we want to persist
  // without triggering a re-render on their change
  const requestRef = useRef<number | undefined>();

  useEffect(() => {
    const animate = (time: number) => {
      callback(performance.now());
      requestRef.current = setTimeout(animate, intervalMilliseconds);
    };

    requestRef.current = setTimeout(animate, intervalMilliseconds);
    return () => {
      if (requestRef.current) {
        clearTimeout(requestRef.current);
      }
    };
  }, [callback, intervalMilliseconds]);
};

export function DrawAtFixedFramerate(props: { millisecondInterval: number; draw: () => any }) {
  const [, setTime] = useState<number | undefined>();

  const updateTick = useCallback((time: number) => {
    setTime(time);
  }, []);

  useInFixedInterval(props.millisecondInterval, updateTick);

  return props.draw();
}

import { classNames } from "@/utility/jsx";
import type { ButtonHTMLAttributes } from "react";
import SvgArrowHeadToLeft from "./arrow-head-to-left.msvg";
import SvgArrowHeadToRight from "./arrow-head-to-right.msvg";
import "./style.scss";

export interface BaseButtonProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  /**
   * Is this the primary button? Defaults to `false`.
   *
   * Interfaces often have a preferable choice among some others. For example a dialog may have
   * the options `Accept` and `Cancel`. In such a case, `Accept` should be marked as primary, while
   * `Cancel` is not marked as primary.
   */
  primary?: boolean;
  /**
   * Style that should be used for buttons that successfully complete an action.
   */
  styleAccept?: boolean;
  /**
   * Style that should be used for buttons that unsuccessfully complete an action.
   */
  styleAbort?: boolean;
  /**
   * Style that should be used for buttons that confirm a delete an action.
   */
  styleRemove?: boolean;
  /**
   * Is this button active?
   *
   * This is useful if the button marks an enabled or disabled state. Use for example, in combination
   * with `<ButtonBar pill><Button>Utc</Button><Button>Local</Button></ButtonBar>`.
   */

  active?: boolean;
}

export interface ButtonProps extends BaseButtonProps {
  /**
   * Should the button be shaped as an arrow to communication that it has semantic meaning of next/prev, left/right, past/future, etc?
   * Defaults to `"none"`.
   *
   * Some Usage Examples:
   *
   * ```
   * // merged left right button
   * <ButtonBar pill>
   *   <Button arrowhead="to-left">-1</Button>
   *   <Button arrowhead="to-right">+1</Button>
   * </ButtonBar>
   * // merged sequence to left
   * <ButtonBar pill>
   *   <Button arrowhead="to-left">-3</Button>
   *   <Button arrowhead="to-left">-2</Button>
   *   <Button arrowhead="to-left">-1</Button>
   * </ButtonBar>
   * // merged sequence to right
   * <ButtonBar pill>
   *   <Button arrowhead="to-left">+1</Button>
   *   <Button arrowhead="to-left">+2</Button>
   *   <Button arrowhead="to-left">+3</Button>
   * </ButtonBar>
   * // single buttons to left and to right
   * <ButtonBar>
   *   <Button arrowhead="to-left">Prev</Button>
   *   <Button arrowhead="to-right">Next</Button>
   * </ButtonBar>
   * ```
   */
  arrowhead?: "to-left" | "to-right" | "none";
  /**
   * Adds a supporting icon next to the button text
   *
   * List of available icons: https://material.io/resources/icons/?style=baseline
   *
   * For example, to render a round button containing a plus-sign:
   */
  icon?: string;
  /**
   * Horizontally mirror the icon
   */
  iconFlipped?: boolean;
  /**
   * "type" attribute of <button/> HTML element.
   * You may want to specify this, for example to "button", when you want the button to not trigger form submission.
   */
  type?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
}

export function buttonProps(props: BaseButtonProps, ...extraClassNames: (string | boolean | undefined)[]) {
  const { primary, styleAccept, styleAbort, styleRemove, active, className, ...forwarded } = props;
  return {
    className: classNames(
      props.primary && "button--primary",
      props.active && "button--active",
      props.styleAccept && "button--accept",
      props.styleAbort && "button--abort",
      props.styleRemove && "button--remove",
      className,
      ...extraClassNames,
    ),

    ...forwarded,
  };
}

export function Button(props: ButtonProps) {
  const { icon, iconFlipped, type, ...baseProps } = props;
  const noChildren = props.children == null;

  return (
    <button
      type={props.type}
      {...buttonProps(
        baseProps,
        "button",
        props.arrowhead === "to-left" && "button--arrow-head--to-left",
        // WARNIG: Right arrow head buttons' hover effect only works if they are in a "direction:row-reverse" flex box
        //         due to the CSS limitation. The "adjacent sibling selector" only allows us to select item right after it, not before it.
        //         This is caused because we implemented the tiny triangular section at the end of the button as an SVG element in the
        //         next adjacent button. Inspect the element and CSS style for further implementation details.
        //         Original implementation used an SVG element for the arrow head, but it might be better to re-write it with
        //         a pseudo element.
        props.arrowhead === "to-right" && "button--arrow-head--to-right",
        noChildren && "button--compact-width",
      )}
    >
      <span className="button__body">
        {icon && <span className={classNames("material-icons", iconFlipped && "flip")}>{icon}</span>}
        {props.children}
      </span>
      {props.arrowhead === "to-right" && <SvgArrowHeadToRight />}
      {props.arrowhead === "to-left" && <SvgArrowHeadToLeft />}
    </button>
  );
}

/**
 * Rounding for some Parameter
 *
 * Rules that apply to this parameter:
 *  value lower 1 must show one decimal. if a value is 0.05 display it like this ~0
 *
 * List of specially rounded parameter values (only in station layers):
 * - visibility:km
 * - precip_1h:mm
 *
 *
 * Possible additional parameters (check with stakeholder):
 * - precip_3h:mm, precip_6h:mm, precip_12h:mm, precip_24h:mm
 * - fresh_snow_24:cm
 * - snow_depth:cm
 * - sunshine_duration_1h:h,sunshine_duration_10min:h
 *
 *
 * */
import { round } from "lodash";

type RoundingFunction = (valueIn: string) => string;
export interface ParameterRoundingMapping {
  [key: string]: RoundingFunction;
}

/**
 * Rules that apply to this parameter:
 * - value lower 1 must show one decimal.
 * - if a value is 0.05 display it like this ~0
 * - all other numbers should round to integer
 * - a string not convertable to a number should be return as origin string
 * */
export const intRoundingForPrecip = (valueIn: string): string => {
  const valueNumber = Number(valueIn);
  if (valueIn.length === 0 || Number.isNaN(valueNumber)) {
    return valueIn;
  }

  if (valueNumber < 1) {
    if (valueNumber === 0.05) {
      return "~0";
    }
    return round(valueNumber, 1).toString();
  }

  return round(valueNumber).toString(); // precision=0
};

/**
 * Rules that apply to this parameter:
 * - all numbers should round to integer
 * - a string not convertable to a number should be return as origin string
 * */
export const intRoundingForTemperature = (valueIn: string): string => {
  const valueNumber = Number(valueIn);
  if (valueIn.length === 0 || Number.isNaN(valueNumber)) {
    return valueIn;
  }

  return round(valueNumber).toString();
};

/**
 * Rules that apply to this parameter:
 * - value lower 1 must show one decimal.
 * - value lower 0.1 must show two decimal
 * - all other numbers should round to integer
 * - a string not convertable to a number should be return as origin string
 * */
export const intRoundingForVisibility = (valueIn: string): string => {
  const valueNumber = Number(valueIn);
  if (valueIn.length === 0 || Number.isNaN(valueNumber)) {
    return valueIn;
  }

  if (valueNumber < 0.1) {
    return round(valueNumber, 2).toString();
  }

  if (valueNumber < 1) {
    return round(valueNumber, 1).toString();
  }

  return round(valueNumber).toString(); // precision=0
};

export const oneDecimalRounding = (valueIn: string): string => {
  const valueNumber = Number(valueIn);
  if (valueIn.length === 0 || Number.isNaN(valueNumber)) {
    return valueIn;
  }
  return round(valueNumber, 1).toString();
};

export const filterMapping: ParameterRoundingMapping = {
  "precip_*": intRoundingForPrecip,
  "visibility*": intRoundingForVisibility,
};

export const defaultNoRoundingFn: RoundingFunction = (valueIn) => valueIn;

/**
 * Given a Metematics API parameter, and the value, return the formatted value which is specified in the mapping.
 * @param parameter String parameter unit. e.g: `t_2m:C`
 * @param valueIn Numerical or string value. e.g: `20`.
 * @param mapping Contains a filter function that specifies how the value should be transformed.
 * @param fallbackRoundingFn
 * @returns
 */
export const applyRoundingFilter = (
  parameter: string,
  valueIn: string,
  fallbackRoundingFn: RoundingFunction = defaultNoRoundingFn,
  mapping: ParameterRoundingMapping = filterMapping,
): string => {
  let filterMappingKey: string | undefined;

  // Iterate through the keys of the mapping object to find a matching pattern
  for (const key of Object.keys(mapping)) {
    const regex = new RegExp(`^${key.replace("*", ".*")}`);
    if (regex.test(parameter)) {
      filterMappingKey = key;
      break; // Exit the loop once a matching key is found
    }
  }

  // Check if a matching key was found and if it exists in the mapping object
  if (filterMappingKey && mapping[filterMappingKey]) {
    const roundingFun = mapping[filterMappingKey];
    return roundingFun(valueIn).toString();
  }

  return fallbackRoundingFn(valueIn);
};

/**
 * Default rounding for Grid and Station Layer because on this layer it is more like an overview
 * for more detail user clicks on the station or open the plot view
 * */
export const defaultStationAndGridRounding = (valueIn: string): string => {
  const valueNumber = Number(valueIn);
  if (valueIn.length === 0 || Number.isNaN(valueNumber)) {
    return valueIn;
  }
  return Math.round(valueNumber).toString();
};

import { isEmpty } from "lodash";
// Sadly Firefox doesn't allow reading from clipboards, so we need to use
// some kind of "global" context for storing clipboard data
import { type ReactChild, createContext, useContext, useState } from "react";

enum BufferName {
  MapLayers = "mapLayers",
  PlotSeries = "plotSeries",
}

type StorageType = {
  [key: string]: unknown;
};

type LocalClipboardContextState = {
  store: (buffer: BufferName, data: unknown) => void;
  read: (buffer: BufferName) => unknown;
};

const LocalClipboardContext = createContext<LocalClipboardContextState>({
  store: (_buffer: BufferName, _data: unknown) => {},
  read: (_buffer: BufferName) => undefined,
});

function useLocalClipboard<T>() {
  const { store, read } = useContext(LocalClipboardContext);

  function storeData(buffer: BufferName, data: T) {
    store(buffer, data);
  }

  function readData(buffer: BufferName) {
    return read(buffer) as T;
  }

  function hasData(buffer: BufferName) {
    return !isEmpty(read(buffer));
  }

  return {
    storeData,
    readData,
    hasData,
  };
}

type LocalClipboardContextProviderProps = {
  children?: ReactChild;
};

function LocalClipboardContextProvider({ children }: LocalClipboardContextProviderProps) {
  const [storage, setStorage] = useState<StorageType>({});

  function store<T>(buffer: BufferName, data: T) {
    const cache = storage;
    cache[buffer] = data;
    setStorage(cache);
  }

  function read<T>(buffer: BufferName) {
    return storage[buffer] as T;
  }

  return <LocalClipboardContext.Provider value={{ store, read }}>{children}</LocalClipboardContext.Provider>;
}

export { LocalClipboardContextProvider, useLocalClipboard, BufferName };

import type { FillLayer, SymbolLayer } from "mapbox-gl";
import { constructSigmetHazardColorExpression, convertRange } from "./utils";

export const INTERACTIVE_LAYER_NAME = "sigmet_polygon";

const hazardColorsExpression = constructSigmetHazardColorExpression();

export const LAYER_DEFINITION: { [key: string]: FillLayer | SymbolLayer } = {
  sigmet_polygon: {
    id: "sigmet_polygon",
    type: "fill",
    "source-layer": "aviation_reports",
    paint: {
      "fill-color": hazardColorsExpression,
      "fill-outline-color": hazardColorsExpression,
      "fill-opacity": 0.5,
    },
  },
  sigmet_annotation: {
    id: "sigmet_annotation",
    type: "symbol",
    "source-layer": "aviation_reports",
    layout: {
      "symbol-avoid-edges": true,
      "symbol-placement": "point",
      "text-field": "{hazard_type}",
      "text-font": ["Roboto_Medium_Italic"],
      "text-size": 14.5,
      "text-allow-overlap": false,
    },
    paint: {
      "text-color": "black",
    },
  },
};

export const TEXT_LAYERS = {
  sigmet_annotation: {
    minSize: 7.25,
    maxSize: 29,
  },
};

export const composeInternationalSigmetLayers = (
  sourceId: string,
  initialSize: number,
): Array<SymbolLayer | FillLayer> => {
  return Object.entries(LAYER_DEFINITION).map(([id, layer]) => {
    if (TEXT_LAYERS[layer.id as keyof typeof TEXT_LAYERS]) {
      const { minSize, maxSize } = TEXT_LAYERS[layer.id as keyof typeof TEXT_LAYERS];
      const layout = {
        ...layer.layout,
        "text-size": convertRange(initialSize, [10, 34], [minSize, maxSize]),
      };

      return {
        ...layer,
        id: `${id}_${sourceId}`,
        source: sourceId,
        layout,
      } as SymbolLayer;
    }

    return {
      ...layer,
      id: `${id}_${sourceId}`,
      source: sourceId,
    };
  });
};

import type { PropsWithChildren, ReactElement } from "react";

import styles from "./style.module.scss";

export function ToolDropdown({
  children,
  icon,
  id,
  titleElement,
}: PropsWithChildren<{ id: string; icon: ReactElement; titleElement: ReactElement }>) {
  return (
    <div className={styles["tool-dropdown"]} data-testid="tool-dropdown">
      <input className={styles.toggle} type="checkbox" id={`dropdown_${id}`} />
      <label className={styles["toggle-label"]} htmlFor={`dropdown_${id}`}>
        <div className={styles.illustration}>{icon}</div>
        <span>{titleElement}</span>
      </label>
      {/* Menu item is toggled by checkbox above. Order here is very important, and this element should be places after "input" */}
      <div className={styles["menu-items"]}>{children}</div>
    </div>
  );
}

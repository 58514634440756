import { BuildInfoComponent } from "@/build-info";
import { setTitle } from "@/effects";
import { LocalePicker } from "@/i18n/locale-picker";
import Logo from "@/logo";
import { Button, ButtonBar } from "@/overlay/components/CommonParts/buttons";
import * as route from "@/route";
import { Trans, t } from "@lingui/macro";
import { useEffect, useState } from "react";
import { useAuth } from "../auth/index";
import { useInterval } from "../utility/customHooks";
import "./login.scss";

function LogoutErrorDialog() {
  return (
    <section className="dialog dialog--login background--white">
      <Logo />
      <p>
        <Trans id="logout.error-msg">Something went wrong while you're logging out.</Trans>
      </p>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          window.location.replace(route.defaultRoute.asString());
        }}
      >
        <ButtonBar>
          <Button primary>
            <Trans id="logout.go-back-to-workspace">Go back to workspace</Trans>
          </Button>
        </ButtonBar>
      </form>
    </section>
  );
}

export default function LogoutPage() {
  const auth = useAuth();
  const [logoutErr, setLogoutErr] = useState(false);
  const [retryCount, setRetryCount] = useState(0);

  useInterval(() => {
    setLogoutErr(true);
    if (retryCount < 3) {
      setRetryCount((count) => count + 1);
      // Try again just a few more times in case the logout API call fails.
      auth.signout();
    }
  }, 4000);

  useEffect(() => {
    setTitle(t({ id: "logout.title", message: "Logout" }));
    auth.signout();
    // NOTE: Run logout redirection once on component mount.
    // useAuth() returns a new object on every render, so adding auth object to the deps array will
    // trigger numerous requests to the login redirection endpoint, which results in numerous redirect responses.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="page page--login cover-window background--primary">
      <LocalePicker />
      {!logoutErr ? <mm-loader size="large">{t`Logging you out...`}</mm-loader> : <LogoutErrorDialog />}
      <BuildInfoComponent copyright />
    </div>
  );
}

import {
  isValidWeatherParameterFieldDisplayName,
  weatherParameterFieldDisplayName,
} from "@/i18n/weather-parameter-fields";
import type { LayerKind } from "@/layers/utility/createLayerObject";
import { WindowKind, instanceId, useDesktop } from "@/overlay/components";
import { FormField } from "@/overlay/components/form-field";
import { classNames } from "@/utility/jsx";
import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import type { CrossModelParameterSpecification, ParameterRange } from "@mm/api-layers.meteomatics.com";
import Logger from "logging";
import { useMemo } from "react";
import * as weatherParameter from "weather-parameter-utils";
import type { AllFieldNames, FieldNames, HasLayerIdxNarrowing, PartialWeatherParameter } from "weather-parameter-utils";
import { weatherParamOptionFilter, weatherParamOptionLayersIdxToFilter } from "./optionFilter";
import styles from "./style.module.scss";

const logger = Logger.fromFilename(__filename);

export type ParameterChangeCallback = (_: HasLayerIdxNarrowing<PartialWeatherParameter>) => void;
export type ParameterEditCallback = () => void;
export type ParameterRemoveCallback = () => void;

/**
 * Renders a single layer of a `PartialWeatherParameter` as a form, which allows a user to modify the fields
 * of the weather parameter.
 *
 * Does not allow modification of the parameter format, resp. selected layer. Use `WeatherParameterForm` for these cases.
 */
// TODO: factor this into some higher oder input components: SelectInputField, NumberInputField, make suffix and prefix a property, make hint a property or composable Component etc
export function ParameterFormatForm(props: {
  parameter: HasLayerIdxNarrowing<PartialWeatherParameter>;
  layerKind?: LayerKind;
  onChange: ParameterChangeCallback;
}) {
  const MAGIC_VALUE_NONE_SELECTED = "__none_selected";
  const _layer = props.parameter.parameter.layers[props.parameter.narrowed_selection.layerIdx];
  const layer = weatherParamOptionFilter(_layer, props.layerKind);

  // TODO: this should be possible without the type assertions, maybe do not assert at all and use parametername as fallback
  // This could actually occur if the backend is updated without reloading the frontend.
  const allFields: [AllFieldNames, string[] | string | ParameterRange][] = Object.entries(layer) as unknown as any;
  const fields: [FieldNames, string[] | ParameterRange][] = allFields.filter(
    ([fieldName]) => fieldName !== "format",
  ) as unknown as any;

  function changeParameterValue(field: FieldNames, fieldDesc: ParameterRange | string[], newValue: string) {
    const newParam: HasLayerIdxNarrowing<PartialWeatherParameter> = weatherParameter.copy(props.parameter);

    if (weatherParameter.isParameterRange(fieldDesc)) {
      // TODO: we continuously parse and format parameter range, which seems pretty stupid.
      if (newValue === "") {
        delete newParam.narrowed_selection.fields[field];
      } else {
        // TODO: better treatment for negative numbers than `Math.abs`
        // also: inputs like `1e4` have excellent behaviour only by chance
        newParam.narrowed_selection.fields[field] = weatherParameter.formatParameterRange(
          fieldDesc,
          Math.abs(+newValue),
        );
      }
    } else {
      newParam.narrowed_selection.fields[field] = newValue;
    }

    props.onChange(newParam);
  }

  function rangeParameterValue(param: ParameterRange, value: string | undefined): string {
    if (!value) {
      return "";
    }
    const parsed = weatherParameter.parseParameterRange(param, value);

    if (parsed == null) {
      logger.error("failed to parse range paramter value <", value, "> for", param);
      return "";
    }

    return `${parsed}`;
  }

  return (
    <>
      {fields.map(([fieldName, fieldDesc], fieldIdx) => {
        const daKey = "lower_level";
        if (!isValidWeatherParameterFieldDisplayName(daKey)) {
          console.warn("Something wrong in weather parameter form: ", fieldName);
          return <></>;
        }
        if (weatherParameter.isParameterRange(fieldDesc)) {
          return (
            <FormField key={fieldName} label={weatherParameterFieldDisplayName[fieldName]()}>
              <div className="grid grid--center-horizontal" data-columns="1,flex">
                <input
                  type="number"
                  min={fieldDesc.start}
                  max={fieldDesc.stop}
                  value={rangeParameterValue(fieldDesc, props.parameter.narrowed_selection.fields[fieldName])}
                  onChange={(e) => changeParameterValue(fieldName, fieldDesc, e.target.value)}
                />
                <div>{fieldDesc.unit}</div>
              </div>
              {/* <span>Use any integral value between {weatherParameter.formatParameterRange(fieldDesc, fieldDesc.start)} and {weatherParameter.formatParameterRange(fieldDesc, fieldDesc.stop)} inclusive.</span> */}
            </FormField>
          );
        }
        // biome-ignore lint/correctness/useJsxKeyInIterable:Key is added
        return fieldDesc.length <= 0 ? null : (
          <FormField key={fieldName} label={weatherParameterFieldDisplayName[fieldName]()}>
            <select
              value={props.parameter.narrowed_selection.fields[fieldName] ?? MAGIC_VALUE_NONE_SELECTED}
              onChange={(e) => changeParameterValue(fieldName, fieldDesc, e.target.value)}
            >
              <option hidden disabled key={-1} value={MAGIC_VALUE_NONE_SELECTED} />
              {fieldDesc.map((val) => {
                return (
                  <option key={val} value={val}>
                    {val}
                  </option>
                );
              })}
            </select>
          </FormField>
        );
      })}
    </>
  );
}

export interface WeatherParameterFormProps {
  parameter: PartialWeatherParameter;
  /**
   * A string of layer description used to hide particular options that are unavalable.
   */
  layerKind?: LayerKind;
  onChange: ParameterChangeCallback;
  /**
   * Weather to prefill all fields with a default value, even if the field was not yet narrowed by the user.
   */
  autofill?: boolean;
  withTitle?: boolean;
  showExactParameter: boolean;
  /**
   * A callback for when the user clicks on the parameter edit button. If given, it will show a parameter edit button.
   */
  onEditParameter?: ParameterEditCallback;
  onSubmitParam?: (param: HasLayerIdxNarrowing<PartialWeatherParameter>) => void;
  /**
   * A callback for when the user clock on the parameter remove button.  If given, it will show a parameter remove button.
   */
  onRemoveParameter?: ParameterRemoveCallback;
}

/**
 * A form to allow a user select the weather parameter's option.
 */
export function WeatherParameterForm(props: WeatherParameterFormProps) {
  const locale = useLingui().i18n.locale;
  const descriptionField: keyof CrossModelParameterSpecification = locale.startsWith("de")
    ? "description_de"
    : "description_en";
  const desktop = useDesktop();

  const parameter: HasLayerIdxNarrowing<PartialWeatherParameter> = useMemo(() => {
    if (props.autofill) {
      return weatherParameter.autofill(props.parameter);
    }
    return weatherParameter.autofillLayerSelection(props.parameter);

    // TODO: should emit event props.onChange
  }, [props.parameter, props.autofill]);
  const filteredParameterLayerIdx = weatherParamOptionLayersIdxToFilter(parameter, props.layerKind);

  const parameterStr: string = useMemo(() => {
    const newCurrentParam = weatherParameter.autofill(parameter);
    return weatherParameter.parameterToString(newCurrentParam)?.formatted;
  }, [parameter]);

  function changeLayer(idx: number) {
    const newParam: HasLayerIdxNarrowing<PartialWeatherParameter> = weatherParameter.copy(parameter);
    newParam.narrowed_selection.layerIdx = idx;
    weatherParameter.removeInvalidNarrowing(newParam);
    // newParam.narrowed_selection.fields = {};
    // TODO: values are preserved if the layer is changed by design of the PartialWeatherParameter description,
    // but we have to verify that the currently chosen value is still valid in the new layer. at least for non range parameters
    props.onChange(newParam);
  }

  function updateParameter(param: HasLayerIdxNarrowing<PartialWeatherParameter>) {
    weatherParameter.removeInvalidNarrowing(param);
    props.onChange(param);
  }
  function openSimpleParamWindow() {
    desktop.dispatch({
      type: "setWindowVisibility",
      windowId: instanceId(WindowKind.AddParameter),
      isVisible: true,
    });
    desktop.dispatch({
      type: "setWindowCustomProps",
      windowId: instanceId(WindowKind.AddParameter),
      windowKind: WindowKind.AddParameter,
      customProps: {
        source: "edit-plot-param",
        onSubmitParam: props.onSubmitParam,
      },
    });
  }

  return (
    <>
      {props.withTitle ? <h1>{props.parameter.parameter[descriptionField]}</h1> : null}
      {/* Parameter display */}
      {props.showExactParameter && parameterStr ? (
        <FormField label={t`Parameter`}>
          <div className={"editable-variable"}>
            <span
              className={styles["parameter-name-span"]}
              // Title attribute shows the full parameter name in case it's too long and cut off at the end.
              title={parameterStr}
            >
              {parameterStr}
            </span>
            <div className={"editable-variable__actions"}>
              {props.onEditParameter || props.onSubmitParam ? (
                <span
                  onClick={props.onEditParameter || openSimpleParamWindow}
                  className="material-icons editable-variable__edit"
                >
                  edit
                </span>
              ) : null}
              {props.onRemoveParameter && (
                <span
                  onClick={props.onRemoveParameter}
                  className={classNames("material-icons editable-variable__edit")}
                >
                  delete
                </span>
              )}
            </div>
          </div>
        </FormField>
      ) : null}
      {/* Parameter format selector */}
      {parameter.parameter.layers.length <= 1 ? null : (
        <FormField label={weatherParameterFieldDisplayName.format()}>
          <select value={parameter.narrowed_selection.layerIdx} onChange={(e) => changeLayer(+e.currentTarget.value)}>
            {parameter.parameter.layers.map((layer, layerIdx) => {
              // biome-ignore lint/correctness/useJsxKeyInIterable: Key is correct
              return filteredParameterLayerIdx.includes(layerIdx) ? null : (
                <option key={layer.format + layer.unit} value={layerIdx}>
                  {layer.format}
                </option>
              );
            })}
          </select>
        </FormField>
      )}
      {/* Parameter format details */}
      <ParameterFormatForm layerKind={props.layerKind} parameter={parameter} onChange={updateParameter} />
    </>
  );
}

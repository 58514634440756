import { IS_NCM } from "@/env";
import { constructDefaultModeOptions } from "@/models/time-control/defaultModeOptions";
import { RoundingMode, datetime_round_to } from "@/time";
import { DateTime } from "luxon";
import { type ActionFunction, type AssignMeta, assign } from "xstate";
import type {
  AnimationCtx,
  AutoRefreshCtx,
  StoredTimePointCtx,
  TimeContextUnion,
  TimePointCtx,
} from "./timeStateCtxTypes";
import { TimeEventType, type TimeEventUnion } from "./timeStateEvents";

/**
 * Note: If this is raised, you're likely placing the action at the unintended transition path
 * within the state machine. To resolve it, you either want to modify the action to support the context
 * change on the path, or double check if you didn't place the action on the wrong place.
 */
export const wrongActionPlacementError = (actionName: string) => {
  return Error(`${actionName} received an incorrect event. The action must be used in the wrong place.`);
};

// -- Context updater actions start --
// These callbacks are responsible for updating the state context from one state to another.
// TODO: Ideally the input of assigner is a specifc ctx object type, not a union.
//       Then we can remove the runtime event.type check. Currently runtime check is needed
//       because each callback is only usable between certain states.
export const constructAnimationConfig = assign(
  (ctx: any, event: TimeEventUnion, meta: AssignMeta<TimeContextUnion, TimeEventUnion>): AnimationCtx => {
    if (event.type === TimeEventType.updateAnimationConfig) {
      return {
        ...ctx,
        displayTime: ctx.displayTime,
        timezone: ctx.timezone,
        modeOpts: event.modeOpts,
      };
    }
    throw wrongActionPlacementError(constructAnimationConfig.name);
  },
);

export const constructDefaultAnimationConfig = assign((ctx: any, event: TimeEventUnion): AnimationCtx => {
  const modeOpts = constructDefaultModeOptions(ctx);
  if (event.type === TimeEventType.setAnimationMode) {
    return {
      ...ctx,
      displayTime: ctx.displayTime,
      timezone: ctx.timezone,
      modeOpts,
    };
  }
  throw wrongActionPlacementError(constructAnimationConfig.name);
});

export const clearAnimationConfig: ActionFunction<AnimationCtx, TimeEventUnion> = assign(
  (ctx: any, event: TimeEventUnion): TimePointCtx | StoredTimePointCtx => {
    if (event.type === TimeEventType.setTimePointMode || event.type === TimeEventType.setStoredPointMode) {
      return { ...ctx, displayTime: ctx.displayTime, timezone: ctx.timezone, modeOpts: null };
    }
    throw wrongActionPlacementError(clearAnimationConfig.name);
  },
);

export const getAutoRefreshTime = (currTime: DateTime) => {
  // Currently by specification, the auto refresh always sets the display time to
  // 1) currentTime - 15 mins
  // 2) round the time backwards by 15mins step.
  // in order to ensure that we can display the latest data from most models,
  // which have slight delay (approx 15mins) until update.
  const roundedDisplayTime = datetime_round_to(currTime.minus({ minutes: 15 }), "minute", 15, RoundingMode.Floor);
  return roundedDisplayTime;
};

/**
 * Recompute the display time based on the real current time.
 */
export const recomputeDisplayTime = assign((ctx: AutoRefreshCtx, _: TimeEventUnion) => {
  const now = DateTime.now().setZone(ctx.timezone);
  return { ...ctx, displayTime: getAutoRefreshTime(now), timezone: ctx.timezone, modeOpts: null };
});

/**
 * If the event is the initialization event, we compute the display time.
 */
export const recomputeDisplayTimeUponInitialization = assign((ctx: AutoRefreshCtx, e: TimeEventUnion) => {
  if (e.type === TimeEventType.initializeStateMachine && e.stateValue === "TIME_POINT.DEFAULT_TIME") {
    const now = DateTime.now().setZone(ctx.timezone);
    return { ...ctx, displayTime: getAutoRefreshTime(now) };
  }
  return ctx;
});

export const updateDisplayTime = assign(<T extends TimeContextUnion>(ctx: T, event: TimeEventUnion) => {
  if (event.type === TimeEventType.updateDisplayTime) {
    return { ...ctx, displayTime: event.displayTime };
  }
  throw wrongActionPlacementError(updateDisplayTime.name);
});

export const updateGuiTimezone = assign((ctx: TimeContextUnion, event: TimeEventUnion): TimeContextUnion => {
  if (event.type === TimeEventType.updateGuiTimezone) {
    return { ...ctx, timezone: event.timezone };
  }
  throw wrongActionPlacementError(updateGuiTimezone.name);
});

export const resetToIdleMode = assign((ctx: TimeContextUnion, _: TimeEventUnion) => {
  return { ...ctx, id_profile: null, displayTime: null, modeOpts: null };
});

// -- Context updater actions end   --

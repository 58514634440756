const w = 0;
const s = 1;
const e = 1;
const n = 0;

export const fullscreenQuad = new Float32Array([
  // lower left triangle of the quad (◣), counter-clockwise
  w,
  s,
  e,
  s,
  w,
  n,
  // top right triangle of the quad (◥), counter-clockwise
  w,
  n,
  e,
  s,
  e,
  n,
]);

export function bufferData(gl: WebGLRenderingContext, buffer: WebGLBuffer | null, data: Float32Array = fullscreenQuad) {
  gl.bindBuffer(gl.ARRAY_BUFFER, buffer);
  gl.bufferData(gl.ARRAY_BUFFER, data, gl.STATIC_DRAW);
}

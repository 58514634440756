import { t } from "@lingui/macro";
import type { Metars } from "@mm/api.meteomatics.com/lib/models/MetarsResponse";
import type { GeoJsonProperties } from "geojson";
import type React from "react";
import { useState } from "react";
import { createRoot } from "react-dom/client";
import ReactDOMServer from "react-dom/server";

interface MetarTooltipProps {
  features: GeoJsonProperties;
  history?: Metars[];
  containerId?: string;
}

const METAR_HOUR_INTERVALS = [1, 2, 3, 6, 12, 24, 48, 96, 120];

const DEFAULT_INTERVAL = 24;

const formatTimeInterval = (hours: number): string => {
  const translationKey = hours === 1 ? t`Hour` : t`Hours`;
  return `${hours} ${translationKey}`;
};

const MetarTooltip = ({ features, history = [] }: MetarTooltipProps) => {
  const [selectedInterval, setSelectedInterval] = useState(DEFAULT_INTERVAL);

  const handleIntervalChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const hours = Number(event.target.value);
    setSelectedInterval(hours);
  };

  const filteredHistory = history.slice(0, selectedInterval);

  return (
    <div className="mapbox-tooltip">
      <div className="mapbox-tooltip__title_header mapbox-tooltip__title--sticky">
        <div className="mapbox-tooltip__title">METAR: {features?.icao}</div>
        <div>
          <select value={selectedInterval} onChange={handleIntervalChange}>
            {METAR_HOUR_INTERVALS.map((hours) => (
              <option key={hours} value={hours}>
                {formatTimeInterval(hours)}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="mapbox-tooltip__content">{features?.raw_metar}</div>

      {history.length > 0 ? (
        <div className="mapbox-tooltip--scrollable">
          <div className="mapbox-tooltip__title mapbox-tooltip__title--sticky">{t`HISTORY`}</div>
          <table className="mapbox-tooltip-table">
            <tbody>
              {filteredHistory.map((metar) => (
                <tr key={metar.issue_time.toUnixInteger()}>
                  <td>{metar.metar_report}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="mapbox-tooltip__content">{t`Loading history...`}</div>
      )}
    </div>
  );
};

const generateTooltipId = () => `metar-tooltip-${Math.random().toString(36).substr(2, 9)}`;

export const hydrateTooltip = (container: HTMLElement, props: Omit<MetarTooltipProps, "containerId">) => {
  const root = createRoot(container);
  root.render(<MetarTooltip {...props} />);
};

export const createMetarTooltip = (features: GeoJsonProperties, history: Metars[] = []): string => {
  const containerId = generateTooltipId();
  const tooltipHtml = ReactDOMServer.renderToString(
    <div id={containerId}>
      <MetarTooltip features={features} history={history} />
    </div>,
  );

  // Scheduler trick to hydrate after the initial render
  setTimeout(() => {
    const container = document.getElementById(containerId);
    if (container) {
      hydrateTooltip(container, { features, history });
    }
  }, 0);

  return tooltipHtml;
};

export default MetarTooltip;

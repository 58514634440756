import type { ApiQueryAbortDecisionData } from "@/cache/SpatioTemporalTileCache/ApiQueryAbortDecisionData";
import type { TileArea } from "@/cache/SpatioTemporalTileCache/TileArea";
import { TILE_SIZE } from "@/cache/SpatioTemporalTileCache/config";
import { type SceneProps, type ScenePublicApi, compositor, compositor_internal } from "@/layers/Compositor";
import type { LayerBase } from "@/layers/LayerBase";
import type { LayerUnion } from "@/layers/LayerUnion";
import type { LngLatBounds } from "@/layers/LngLatBounds";
import type { MapboxMap } from "@/layers/MapboxMap";
import type { SceneId } from "@/layers/SceneId";
import type { SceneLayerApi } from "@/layers/SceneLayerApi";
import { BackgroundMapLayerImpl } from "@/layers/background/BackgroundMapLayerImpl";
import { BarbsLayerImpl, GridLayerImpl, StationLayerImpl, SymbolLayerImpl } from "@/layers/geojson";
import { AviationLayerImpl } from "@/layers/geojson/AviationLayerImpl";
import { IsoLinesLayerImpl } from "@/layers/geojson/IsoLinesLayerImpl";
import { LightningLayerImpl } from "@/layers/geojson/LightningLayerImpl";
import { PressureSystemLayerImpl } from "@/layers/geojson/PressureSystemLayerImpl";
import { TropicalTropicalCycloneLayerImpl } from "@/layers/geojson/TropicalCycloneLayerImpl";
import { WeatherFrontsLayerImpl } from "@/layers/geojson/WeatherFrontsLayerImpl";
import { CustomGeoJSONLayerImpl } from "@/layers/geojson/custom";
import { DrawingLayerImpl } from "@/layers/geojson/drawing/DrawingLayerImpl";
import type { DrawingLayer } from "@/layers/geojson/drawing/TEMPORAL_TYPE_DRAWING";
import { PoiLayerImpl } from "@/layers/geojson/poi/PoiLayerImpl";
import { FallbackWmsLayerImpl, WmsLayerImpl } from "@/layers/png";
import { AviationTypes } from "@/layers/utility/aviationType";
import { LayerKindName } from "@/layers/utility/createLayerObject";
import { IsoLinesLayerImpl as VectorIsLinesLayerImpl } from "@/layers/vector/IsoLinesLayerImpl";
import { WindAnimationLayerImpl } from "@/layers/wind";
import reduxStore from "@/reducer";
import type { PoiLayer } from "@/reducer/client-models/PoiLayer";
import type { TemporalDiscretization } from "@/time";
import { isIos, isIpadPro } from "@/utility/platformUtils";
import { safeFmt } from "@/utility/safeFmt";
import { tilesInMapBounds } from "@/utility/tiling";
import type { GridDimension } from "@mm/api.meteomatics.com";
import {
  type AviationLayer,
  type BackgroundMapLayer,
  type BarbsLayer,
  type CartographicMap,
  type CustomGeoJSONLayer,
  type DateTimeDesc,
  type GridLayer,
  type GuiTimeZone,
  type IsoLinesLayer,
  type LightningLayer,
  type MapProjection,
  type PressureSystemLayer,
  ProjectionNameEnum,
  type StationLayer,
  type SymbolLayer,
  type TropicalCycloneLayer,
  type Viewport,
  ViewportKind,
  type WeatherFrontsLayer,
  type WindAnimationLayer,
  type WmsLayer,
} from "@mm/metx-workbench.meteomatics.com";
import type { FeatureCollection } from "geojson";
import Logger from "logging";
import { type DateTime, Duration } from "luxon";
import type { LngLat, Marker } from "mapbox-gl";
import { VectorAviationLayerImpl } from "./vector/AviationLayer/VectorAviationLayerImpl";

const logger = Logger.fromFilename(__filename);

export class Scene implements SceneLayerApi, ScenePublicApi {
  id: SceneId;
  map: MapboxMap;
  popupMarker: Marker | null = null;
  temporalDiscretization: TemporalDiscretization;
  displayTime: DateTime;

  nextTime: DateTime;
  timeOffsetMinutes: CartographicMap["time_offset_mins"];
  dateTimeDesc: DateTimeDesc;
  lodBias: number;

  // TODO: use area class
  bounds: LngLatBounds;
  zoom: number;
  center: LngLat;

  // Fallback flag for layers, that doesn't support globe and lambert projections
  fallback = false;

  /**
   * Rendersize in hardware pixel precision
   */
  renderSize: GridDimension;
  layers: LayerBase<LayerUnion>[] = [];

  public isActive: boolean;

  /**
   *
   * Calling this constructor will not propagate invalidations to the compositer.
   * You MUST call all invalidation functions on the compositor yourself. Since
   * This makes sense since the compositor is currently the only caller of this
   * constructor and can optimize this case.
   */
  constructor(props: SceneProps /* private compositor: Compositor | null */) {
    this.id = props.id;
    this.map = props.map;
    this.map.on("render", this.beforeRender.bind(this));

    this.bounds = this.getBoundsFromMapbox(this.map);
    this.zoom = this.map.getZoom();
    this.center = this.map.getCenter();
    this.temporalDiscretization = props.temporalDiscretization;
    this.displayTime = props.displayTime;
    this.nextTime = props.nextTime;
    this.timeOffsetMinutes = props.timeOffsetMinutes;
    this.dateTimeDesc = props.dateTimeDesc;
    this.renderSize = props.renderSize;
    this.lodBias = props.lodBias;

    this.isActive = true;

    // TODO Fix typing
    // @ts-ignore
    this.fallback = this.map.getProjection().name !== ProjectionNameEnum.mercator;
  }

  signalLodBias(): void {
    compositor_internal._setZoomLevelBias(this);
  }
  setDateTimeDescription(abstractDateTime: DateTimeDesc): void {
    this.dateTimeDesc = abstractDateTime;
  }
  getPopupMarker(): Marker | null {
    return this.popupMarker;
  }
  setPopupMarker(newMarker: Marker) {
    this.popupMarker = newMarker;
  }

  isAbsoluteDateTimeEvent() {
    return !this.dateTimeDesc.is_series;
  }

  getWebGl(): WebGLRenderingContext {
    return (this.getMapboxMap() as any).painter.context.gl;
  }

  getDateTimeDescription(): DateTimeDesc {
    return this.dateTimeDesc;
  }

  isAnimation(): boolean {
    return this.dateTimeDesc.is_series;
  }
  getDateTimeWithOffset(tmpDateTime: DateTime) {
    if (this.timeOffsetMinutes) {
      return tmpDateTime.plus(Duration.fromObject({ minutes: this.timeOffsetMinutes }));
    }
    return tmpDateTime;
  }
  /**
   * @return the luxon DateTime of the displayTime with the offset
   * */
  getDisplayTimeWithOffset(): DateTime {
    return this.getDateTimeWithOffset(this.displayTime);
  }

  /**
   * @return the luxon DateTime of the displayTime "without Offset!!"
   */
  getDisplayTime(): DateTime {
    return this.displayTime;
  }

  getNextTime(): DateTime {
    return this.nextTime;
  }

  getRenderSize(): GridDimension {
    return this.renderSize;
  }

  getMemoryLeaserId(): string {
    return `${this.id}`;
  }

  /**
   * Called each time before the map is redrawn.
   */
  beforeRender() {
    if (!compositor.inUse(this.id) || !this.isActive) {
      // Skip rendering if the map is unused / in the process of deallocation.
      return;
    }
    // the actual rendering of the layers is then performed by mapbox internally along the lines of
    for (const layer of this.layers) {
      const layerProps = layer.getLayerProps();
      if (layerProps.show) {
        layer.beforeRender();
      }
      if (!layerProps.show && layerProps.kind === LayerKindName.LightningLayerDescription) {
        // TODO: clear cache completely for lightning when its hidden
        layer.beforeRender();
      }
    }
  }

  repaint() {
    compositor_internal._repaint(this);
  }

  deallocate() {
    this.isActive = false;
    for (const layer of this.layers) {
      layer.removeLayer();
    }
  }

  getReferenceTileSize() {
    return TILE_SIZE;
  }

  // TODO: this conflicts with untiled mapbox layers
  // TODO: cache this?
  getMapViewport(): { zoom: number; zoomWithPrecision: number; viewportArea: TileArea } {
    const zLodBias = this.getLodBias();
    return tilesInMapBounds(this.map, this.getReferenceTileSize(), zLodBias);
  }

  /**
   * Get information about the active data subspaces that is required to decide whether a tile request
   * should be aborted.
   */
  // TODO: either add a `getActiveDataSubspaces()` for other tasks like data prefetching in animations,
  // or generalize this getter to cover both cases: prefetching and abort decision making
  getAbortDecisionDataSet(): ApiQueryAbortDecisionData[] {
    const areas: Record<number, TileArea> = {};

    return this.layers.flatMap((layer) => {
      const parameters = layer.getActiveWeatherParametersAsString();
      const appliedStyle = layer.getAppliedStyle();
      const appliedEnsSelect = layer.getAppliedEnsSelect();

      if (parameters.length === null) {
        return [];
      }
      const zoomLevelBias = this.getLodBias();

      if (areas[zoomLevelBias] == null) {
        areas[zoomLevelBias] = this.getMapViewport().viewportArea;
      }

      const area = areas[zoomLevelBias];

      return [
        {
          area,
          // TODO: so, this transfers a lot per parameter because of the readonly reference to the parameter structure.
          //       The same is true for the whole ApiQuery per tile
          parameters,
          appliedStyle,
          appliedEnsSelect,
        },
      ];
    });
  }

  setDisplayTime(displayTime: DateTime) {
    this.displayTime = displayTime;
    compositor_internal._setDisplayTimeAndTemporalDiscretization(this);
  }
  setNextTime(nextTime: DateTime) {
    this.nextTime = nextTime;
    compositor_internal._setDisplayTimeAndTemporalDiscretization(this);
  }
  setTimeOffsetMinutes(durationIsoStr: CartographicMap["time_offset_mins"] | undefined) {
    this.timeOffsetMinutes = durationIsoStr || 0;
    compositor_internal._setDisplayTimeAndTemporalDiscretization(this); // check
  }
  setProjection(mapProjection: MapProjection) {
    //TODO: Fix type is missing
    // @ts-ignore
    this.map.setProjection(mapProjection);
    if (mapProjection.name === ProjectionNameEnum.globe) {
      this.map.setFog({});
    }
  }

  getProjection(): MapProjection {
    //TODO: Fix type is missing
    // @ts-ignore
    return this.map.getProjection();
  }
  getLodBias(): number {
    return this.lodBias;
  }

  setLodBias(lodBias: number) {
    this.lodBias = lodBias;
    this.signalLodBias();
  }

  setLayerStackDescription(layers: LayerUnion[], timezone: GuiTimeZone, fallback?: boolean) {
    const changed = this.reconciler(layers, timezone, fallback);
    if (changed) {
      // start this from behind
      for (let idx = this.layers.length - 1; idx >= 0; idx--) {
        const currentLayer = this.layers[idx];
        const nextLayer = this.layers[idx + 1];
        currentLayer.setTimezone(timezone);
        currentLayer.moveZIndex(nextLayer ? nextLayer.mapboxIndex : undefined);
      }

      compositor_internal._setLayerStack(this);
    }
  }

  setViewportDescription(viewport: Viewport) {
    // TODO: setGridDimensions? This should work without react driving the update loop
    this.syncMapboxToViewport(viewport);
    this.bounds = this.getBoundsFromMapbox(this.map);
    compositor_internal._setBounds(this);
  }

  getMapboxMap() {
    return this.map;
  }

  isMapboxLoaded() {
    return this.getMapboxMap()._loaded ?? false;
  }

  /**
   * Updates the rendering engine to match the viewport state in mapbox.
   */
  syncToMapboxViewport() {
    this.bounds = this.getBoundsFromMapbox(this.map);
    this.center = this.map.getCenter();
    this.zoom = this.map.getZoom();
    compositor_internal._setBounds(this); // this triggers a repaint of the scene as every other _function does.

    // TODO: we implicitly expect canvas resizes to always implicitly update the map bounds
    // and implicitly update rendersize here
    this.renderSize = this.getRenderSizeFromMapbox();
    compositor_internal._setRenderSize(this);
  }

  /**
   * Updates the viewport of mapbox to match the viewport of the rendering engine.
   */
  syncMapboxToViewport(viewport: Viewport): void {
    const map = this.getMapboxMap();

    switch (viewport.kind) {
      // This is temporary fix, while we figure out better approach on syncing viewport
      // Globe projection requires setting center and zoom too, but search is requiring
      // only setting bounds, so another ViewportKind is required
      case ViewportKind.ViewportFull:
        map.fitBounds(
          [
            { lat: viewport.southWest_lat, lng: viewport.southWest_lng },
            { lat: viewport.northEast_lat, lng: viewport.northEast_lng },
          ],
          {
            animate: false,
          },
        );

        map.setCenter([viewport.center_lng, viewport.center_lat]);
        map.setZoom(viewport.zoom);

        break;
      case ViewportKind.ViewportContainBounds:
        map.fitBounds(
          [
            { lat: viewport.southWest_lat, lng: viewport.southWest_lng },
            { lat: viewport.northEast_lat, lng: viewport.northEast_lng },
          ],
          {
            animate: false,
          },
        );

        // We need to set center for lamber projection, otherwise it breaks
        map.setCenter([viewport.center_lng, viewport.center_lat]);

        break;
      case ViewportKind.ViewportCenterPoint:
        map.setCenter([viewport.center_lng, viewport.center_lat]);
        map.setZoom(viewport.zoom);

        break;
    }
  }
  protected decodeLayer(layer: LayerUnion, timezone: GuiTimeZone, fallback?: boolean): LayerBase<any> | null {
    // TODO: the backend generates incorrect typings for `layer.kind`
    switch (layer.kind) {
      case "WmsLayerDescription":
        // Switch to fallback layer on globe projection or if device is iOS
        // WMS layer implementation is outdated and doesn't work on iOS anymore,
        // as Apple removed support for EXT_float_blend after safari 15.6
        // TODO: Revisit WMS layer implementation and refresh it to support
        // all devices
        // TODO: Remove fallback flag for globe projection, as mapbox already
        // supports custom layer interface
        if (fallback || isIos || isIpadPro) {
          return new FallbackWmsLayerImpl(layer.id, layer as WmsLayer, this, timezone);
        }
        return new WmsLayerImpl(layer.id, layer as WmsLayer, this, timezone);

      case LayerKindName.GridLayerDescription:
        return new GridLayerImpl(layer.id, layer as GridLayer, this, timezone);
      case LayerKindName.IsoLinesLayerDescription:
        if (layer.experimental) {
          return new VectorIsLinesLayerImpl(layer.id, layer as IsoLinesLayer, this, timezone);
        }
        return new IsoLinesLayerImpl(layer.id, layer as IsoLinesLayer, this, timezone);
      case LayerKindName.BarbsLayerDescription:
        return new BarbsLayerImpl(layer.id, layer as BarbsLayer, this, timezone);
      case LayerKindName.SymbolLayerDescription:
        return new SymbolLayerImpl(layer.id, layer as SymbolLayer, this, timezone);
      case LayerKindName.BackgroundMapDescription:
        return new BackgroundMapLayerImpl(layer.id, layer as BackgroundMapLayer, this, timezone);
      case LayerKindName.StationLayerDescription:
        return new StationLayerImpl(layer.id, layer as StationLayer, this, timezone);
      case LayerKindName.GenericPoiLayerDescription:
        return new PoiLayerImpl(layer.id, layer as PoiLayer, this, timezone);
      case LayerKindName.AviationLayerDescription: {
        const aviationLayer = layer as AviationLayer;
        if (
          aviationLayer.aviation_type === AviationTypes.metar ||
          aviationLayer.aviation_type === AviationTypes.taf ||
          aviationLayer.aviation_type === AviationTypes.isigmet
        ) {
          return new VectorAviationLayerImpl(layer.id, layer as AviationLayer, this, timezone);
        }
        // Fallback to GeoJson layer if it's not a supported aviation layer
        return new AviationLayerImpl(layer.id, layer as AviationLayer, this, timezone);
      }
      case LayerKindName.PressureSystemLayerDescription:
        return new PressureSystemLayerImpl(layer.id, layer as PressureSystemLayer, this, timezone);
      case LayerKindName.LightningLayerDescription:
        return new LightningLayerImpl(layer.id, layer as LightningLayer, this, timezone);
      case LayerKindName.TropicalCycloneLayerDescription:
        return new TropicalTropicalCycloneLayerImpl(layer.id, layer as TropicalCycloneLayer, this, timezone);
      case LayerKindName.WeatherFrontsLayerDescription:
        return new WeatherFrontsLayerImpl(layer.id, layer as WeatherFrontsLayer, this, timezone);
      case LayerKindName.WindAnimationLayerDescription:
        return new WindAnimationLayerImpl(layer.id, layer as WindAnimationLayer, this, timezone);
      case LayerKindName.CustomGeoJSONLayerDescription:
        return new CustomGeoJSONLayerImpl(layer.id, layer as CustomGeoJSONLayer, this, timezone);
      case LayerKindName.DrawingLayerDescription: {
        const drawings = // @ts-ignore Messed up typing
          reduxStore.getState().tabGroup.present.maps[layer.id_cartographicmap].drawing as FeatureCollection;
        return new DrawingLayerImpl(layer.id, layer as DrawingLayer, this, timezone, drawings);
      }
      default:
        logger.error(safeFmt`unknown layer kind <${layer.kind ?? "(no kind field)"}>`);
        return null;
    }
  }

  private isFallbackChanged(fallback: boolean, layer: LayerBase<LayerUnion>) {
    return fallback !== this.fallback && layer.getLayerProps().kind === "WmsLayerDescription";
  }

  /**
   * Reconciler that updates the rendering engine to match the given layer stack with
   * the minimal amount of invalidations.
   *
   * If the type of a layer changes, a new `id` MUST be given to the layer.
   *
   * @return boolean indicating if anything changed. `false` if nothing changed, `true`
   * for any modification. For example: layer was added, removed, changed or if the order of layers changed.
   */
  private reconciler(descriptionLayers: LayerUnion[], timezone: GuiTimeZone, fallback?: boolean) {
    // [STEP 1 -- remove no longer used layers] relative complement currentState / prevState
    const curr: (null | LayerUnion)[] = [...descriptionLayers]; // TODO: is a copy needed?
    const prev = this.layers;
    const res: LayerBase<LayerUnion>[] = [];

    // indicates that the transfer function changed and that we have to repaint
    // the layer, even when the data inputs did not change.
    let mustRerender = false;

    for (const [pI, p] of prev.entries()) {
      const cI = curr.findIndex((c) => c && c.id === p.uid);

      // Trigger removing layer, if aviation type changes
      // TODO Remove this once we will have support for all
      //  aviation layers on vector tiles
      const hasAviationTypeChanges =
        // @ts-ignore
        p.getLayerProps().aviation_type !== curr[cI]?.aviation_type;

      const hasTimezoneChanges = p.timezone !== timezone;

      // Hack to prevent layer recreation on any of changes in state
      // TODO Remove this as soon as drawing layer will get extracted
      // as actual layer, with actual id
      const isDrawingLayer = p.getLayerProps().kind === LayerKindName.DrawingLayerDescription;

      // if fallback state changes, make sure we remove previous WMS layer,
      // so reconciler can create new layer in place
      if (isDrawingLayer || (cI >= 0 && !this.isFallbackChanged(!!fallback, p) && !hasAviationTypeChanges)) {
        // biome-ignore lint/style/noNonNullAssertion: TODO a lot of todos around
        const cI_mustRerender = !isDrawingLayer ? p.checkChanges(curr[cI]!) : false;

        mustRerender = mustRerender || cI_mustRerender || hasTimezoneChanges;
        res[cI] = p;
        curr[cI] = null;
        if (cI !== pI) {
          // if the layer was moved, make sure we rerender even if the layer
          // itself did not change internally.
          mustRerender = true;
        }
      } else {
        // allow the layer to perform explicit deallocations
        p.removeLayer();
        mustRerender = true;
      }
    }

    // Update compositor fallback state
    this.fallback = !!fallback;

    // [STEP 2 -- add new layers] relative complement prevState / currentState
    for (const [cI, c] of curr.entries()) {
      if (c != null) {
        const layer = this.decodeLayer(c, timezone, fallback);
        if (layer != null) {
          res[cI] = layer;
          mustRerender = true;
        }
      }
    }

    this.layers = res;
    return mustRerender;
  }

  /**
   * Get Rendersize in hardware pixel units.
   */
  private getRenderSizeFromMapbox(): GridDimension {
    const canvas = this.map.getCanvas();
    const width = Math.round(canvas.width);
    const height = Math.round(canvas.height);

    return { width, height };
  }

  /**
   * Get the bounds from Mapbox.
   */
  private getBoundsFromMapbox(map: MapboxMap = this.getMapboxMap()): LngLatBounds {
    // TODO: use `Area` here?
    const bounds = map.getBounds();
    const sw = bounds.getSouthWest();
    const ne = bounds.getNorthEast();

    return {
      southWest: {
        lng: sw.lng,
        lat: sw.lat,
      },
      northEast: {
        lng: ne.lng,
        lat: ne.lat,
      },
    };
  }
}

import type { LayerUnion } from "@/layers";
import { FormField } from "@/overlay/components/form-field";
import { unsafeGetColormapByName } from "@/utility/unsafeGetColormapByName";
import { t } from "@lingui/macro";
import { ColorMap, colormaps } from "@mm/api.meteomatics.com";
import type { MapId, WmsLayer } from "@mm/metx-workbench.meteomatics.com";

export function WMSLayerSelect(props: {
  id: MapId;
  layer: WmsLayer;
  change: (propsValue: Partial<LayerUnion>) => void;
}) {
  const colorMaps: ColorMap[] = colormaps;
  const defaultColormap = unsafeGetColormapByName("", props.layer.parameter_unit);
  return (
    <FormField label={t`Color Map`}>
      <select
        value={unsafeGetColormapByName(props.layer.color_map, props.layer.parameter_unit)}
        onChange={(event) => props.change({ color_map: ColorMap[+event.target.value] })}
      >
        {colorMaps?.map((colorMap, idx) => (
          <option key={colorMap} value={colorMap}>
            {ColorMap[colorMap]} {colorMap === defaultColormap ? t`(default)` : ""}
          </option>
        ))}
      </select>
    </FormField>
  );
}

import { i18n } from "@lingui/core";
import { fromNavigator, fromStorage, fromUrl, multipleDetect } from "@lingui/detect-locale";
import { BUILD_INFO, type Locale } from "build-info";

const localeStorageKey = "lang";

// A list of locales that might be preferred by the user. For example: ["de-DE", "en-US"].
// Note that this list may contain languages that are not available in the application!
export const preferredLocales = multipleDetect(fromUrl("lang"), fromStorage(localeStorageKey), fromNavigator());

export function languageFromLocale(langAndTerritory: string) {
  return langAndTerritory.split("-")[0];
}

// Languages without territories. For example: ["en", "de"]
export const preferredLanguages = preferredLocales.map(languageFromLocale);

export async function dynamicActivate(locale: string) {
  const { messages } = await import(/* webpackChunkName: "[request]" */ `../locales/${locale}/messages.po`);
  i18n.load(locale, messages);
  window.localStorage.setItem(localeStorageKey, locale);
  i18n.activate(locale);
}

// pick one of the available languages that best matches the users preferred languages
export function negotiateLocale(): Locale {
  // TODO: find a library to do this. We should support the full BCP47 standard since the preferredLanguage is
  // from an external source and may use any BCP47 feature.
  const fullMatch: Locale[] = [];
  const langMatch: Locale[] = [];

  for (const locale of BUILD_INFO.locales) {
    if (preferredLocales.indexOf(locale.languageTag) !== -1) {
      fullMatch.push(locale);
    }

    const lang = languageFromLocale(locale.languageTag);

    if (preferredLanguages.indexOf(lang) !== -1) {
      langMatch.push(locale);
    }
  }

  return fullMatch[0] ?? langMatch[0] ?? BUILD_INFO.defaultLocale;
}

import { getModelBoundingBox } from "@/weather-parameters/lookup";
import { clamp } from "lodash";
import { LngLatBounds } from "mapbox-gl";

export function adjustBoundsToModel(bounds: LngLatBounds, model: string) {
  const modelbounds = getModelBoundingBox(model);

  const [southWest, northEast] = bounds.toArray();
  const [vLngMin, vLatMin] = southWest;
  const [vLngMax, vLatMax] = northEast;

  const latMin = clamp(vLatMin, modelbounds.latMin, modelbounds.latMax);
  const latMax = clamp(vLatMax, modelbounds.latMin, modelbounds.latMax);
  const lngMin = clamp(vLngMin, modelbounds.lngMin, modelbounds.lngMax);
  const lngMax = clamp(vLngMax, modelbounds.lngMin, modelbounds.lngMax);

  return new LngLatBounds([lngMin, latMin], [lngMax, latMax]);
}

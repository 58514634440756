const unitMapping: Record<string, string> = {
  bft: "bft",
  c: "°C",
  cm: "cm",
  d: "d",
  dam: "dam",
  dn: "dn",
  f: "°F",
  ft: "ft",
  gcm2: "g/cm²",
  gdd: "gdd",
  gm3: "g/m³",
  grainsm3: "grains/m³",
  h: "h",
  hdd: "hdd",
  hpa: "hPa",
  idx: "idx",
  j: "J",
  jkg: "J/kg",
  k: "K",
  kgkg: "kg/kg",
  kgm2: "kg/m²",
  kgm3: "kg/m³",
  km: "km",
  kmh: "km/h",
  kn: "kn",
  kw: "kW",
  m: "m",
  m3m3: "m³/m³",
  min: "min",
  mm: "mm",
  mph: "mph",
  ms: "m/s",
  mw: "MW",
  octas: "octas",
  p: "%",
  pa: "Pa",
  pas: "Pa/s",
  pgm3: "pg/m³",
  psi: "psi",
  psu: "psu",
  s: "s",
  s2: "1/s²",
  sql: "sql",
  str: "str",
  ugm2: "µg/m²",
  ugm3: "µg/m³",
  ux: "ux",
  w: "W",
  wh: "Wh",
  wm2umsr: "W/m²·μm·sr",
  ws: "Ws",
  dbz: "dBZ",
  x: "x",
  y: "y",
};

/**
 *
 * @param unit_
 */
export function prettyPrintUnit(unit_: string): string | null {
  const unit = unit_.toLowerCase();
  // biome-ignore lint/suspicious/noPrototypeBuiltins: ES2022 needed
  return unitMapping.hasOwnProperty(unit) ? unitMapping[unit] : null;
}

/**
 * Try to pretty print the unit and return the input if pretty printing fails
 *
 * @param parameterUnit A valid Meteomatics weather parameter. e.g) t_2m:C
 */
export function tryPrettyPrintUnit(parameterUnit: string): string {
  const parts = parameterUnit.split(":");
  const unit = parts[parts.length - 1]; // Last part of the weather parameterUnit is always unit.
  return prettyPrintUnit(unit) ?? parameterUnit;
}

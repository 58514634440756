import type { DateTime } from "luxon";
import type { PositionedTile } from "./PositionedTile";
import type { Tile } from "./Tile";
import type { TileGeometry } from "./TileGeometry";

export interface PotentiallyVisibleTileSetProperties {
  /**
   * Tiles that are missing or yet to be retrieved.
   */
  uncovered: TileGeometry[];
  /**
   * True if the PVS only contains actually requested tiles
   * and no neighboring placeholder tiles that were found
   * through a temporal and spatial lookup.
   *
   * So, a frame is fully loaded if it `isCovering && isExact`.
   */
  isExact: boolean;
}

function defaultProps(): PotentiallyVisibleTileSetProperties {
  return {
    // an empty set is covering
    uncovered: [],
    // an empty set is exact
    isExact: true,
  };
}

/**
 * Represents all tiles for a single zoom level.
 * {<xyz tile location ID>: <Tile>}
 */
export type TilesByLocation<T extends Tile> = Record<string, PositionedTile<T>>;

export function getTileZoomRange(tilesByZoomLevel: Map<number, TilesByLocation<any>>) {
  // TODO: seems to me like this has a problem when we have a PVS that spans into the negative area
  let min = Number.POSITIVE_INFINITY;
  let max = Number.NEGATIVE_INFINITY;

  for (const zoom of tilesByZoomLevel.keys()) {
    min = Math.min(min, zoom);
    max = Math.max(max, zoom);
  }

  return { min, max };
}

/**
 * A set of CPU-side occlusion culled tiles that are potentially visible in the given region.
 *
 * Tiles are sorted by zoom level, meaning the 2d array is indexed as
 * ```
 * [zoom][tiles of zoom in no particular order]
 * ```
 * Individual zoom levels may be `undefined`.
 */
export class PotentiallyVisibleTileSet<T extends Tile> {
  tilePromises: Promise<T>[] = [];
  constructor(
    public readonly datetime: DateTime,
    public readonly seconds: number,
    /**
     * {zoomLevel: {tileGeometryStrId: <TileData>}
     */
    private tilesByZoomLevel: Map<number, TilesByLocation<T>> = new Map(),
    public props: PotentiallyVisibleTileSetProperties = defaultProps(),
  ) {}

  addTilePromise(loadTileList: Promise<T>[]) {
    this.tilePromises.push(...loadTileList);
  }
  clearTilePromiseList() {
    this.tilePromises = [];
  }
  add(tile: PositionedTile<T>) {
    const prev = this.tilesByZoomLevel.get(tile.tileGeometry.zoom);
    if (prev === undefined) {
      this.tilesByZoomLevel.set(tile.tileGeometry.zoom, { [tile.tileGeometry.toId()]: tile });
    } else {
      // TODO: Potentially multiple `TileGeometry`s are assigned to a single `tileData` object. (One copy for each world copy visible.)
      // We currently draw each world copy separately. However, each copy is identical... so we could upload tileData once and draw multiple `TileGeometry`s.
      //this.sublayers.set(tile.tileGeometry.zoom, { ...prev, [tile.tileGeometry.toId()]: tile });
      prev[tile.tileGeometry.toId()] = tile;
      this.tilesByZoomLevel.set(tile.tileGeometry.zoom, prev);
    }
  }

  addAll(tiles: Iterable<PositionedTile<T>>) {
    for (const tile of tiles) {
      this.add(tile);
    }
  }

  addPvs(pvs: PotentiallyVisibleTileSet<T>) {
    for (const [zoom, tiles] of pvs.tilesByZoomLevel.entries()) {
      const sublayer = this.tilesByZoomLevel.get(zoom);
      if (sublayer !== undefined) {
        this.tilesByZoomLevel.set(zoom, { ...sublayer, ...tiles });
      } else {
        this.tilesByZoomLevel.set(zoom, { ...tiles });
      }
    }

    this.props.uncovered = [...this.props.uncovered, ...pvs.props.uncovered];
    this.props.isExact = this.props.isExact && pvs.props.isExact;
    this.addTilePromise(pvs.tilePromises);
    //this.props.isDirty = this.props.isDirty && pvs.props.isDirty;
  }

  contains(tile: PositionedTile<T>) {
    const sublayer = this.tilesByZoomLevel.get(tile.tileGeometry.zoom);
    if (sublayer) {
      return Object.hasOwn(sublayer, tile.tileGeometry.toId());
    }
    return false;
  }

  /**
   * Checks if the visible area has some tiles.
   * @returns True if the area in question is covered by some tiles, false if there are missing tiles.
   */
  isCovering(): boolean {
    return this.props.uncovered.length === 0;
  }

  hasFailedTiles(): boolean {
    let hasFailedTile = false;
    for (const [, sublayer] of this.tilesByZoomLevel.entries()) {
      for (const tileId in sublayer) {
        const tile = sublayer[tileId];
        if (tile.isFailedTile) {
          hasFailedTile = true;
          break;
        }
      }
      if (hasFailedTile) {
        break;
      }
    }
    return hasFailedTile;
  }

  get(): Map<number, TilesByLocation<T>> {
    return this.tilesByZoomLevel;
  }
}

export enum BinarySearchResultKind {
  Exact = 0,
  Between = 1,
  Overflow = 2,
  Underflow = 3,
  Empty = 4,
}

export interface BinarySearchResultOk {
  kind: Exclude<BinarySearchResultKind, BinarySearchResultKind.Empty>;
  lowIdx: number;
  highIdx: number;
}

export type BinarySearchResult = BinarySearchResultOk | { kind: BinarySearchResultKind.Empty };

/**
 * Finds the two indices closest to some value in a sorted array.
 *
 * For example `binarySearchSampledContinousSpace<number>([0,1,2,3,4], 3.5)` returns `[3,4]`.
 * For exact matches the same index is returned twice. So, `binarySearchFloat([1], 1.0)` returns `[0,0]`.
 *
 * @param array a sorted array. MUST NOT contain duplicate elements.
 * @param value reference floating point number.
 * @param low restrict the search on `array` to this lower bound
 * @param high restrict the search on `array` to this upper bound
 */
export function binarySearchSampledContinousSpace<T>(
  array: T[],
  value: T,
  equal: (a: T, b: T) => boolean = (a, b) => a === b,
  lessThan: (a: T, b: T) => boolean = (a, b) => a < b,
  initialLow = 0,
  initialHigh: number = array.length,
): BinarySearchResult {
  let low = initialLow;
  let high = initialHigh;
  if (array.length === 0) {
    return { kind: BinarySearchResultKind.Empty };
  }

  while (low < high) {
    const mid = (low + high) >>> 1;
    if (lessThan(array[mid], value)) low = mid + 1;
    else high = mid;
  }

  if (array[low] != null && equal(array[low], value)) {
    return { kind: BinarySearchResultKind.Exact, lowIdx: low, highIdx: low };
  }
  if (high >= array.length) {
    return { kind: BinarySearchResultKind.Overflow, lowIdx: low - 1, highIdx: low - 1 };
  }
  if (low - 1 >= 0) {
    return { kind: BinarySearchResultKind.Between, lowIdx: low - 1, highIdx: low };
  }

  return { kind: BinarySearchResultKind.Underflow, lowIdx: 0, highIdx: 0 };
}

import { enumValues } from "@/utility/enum";

export enum DragItemType {
  /**
   * A standard window that can be placed in the left or right window track, e.g. most windows
   */
  NormalWindow = "NormalWindow_",
  /**
   * A window that can only be placed in the bottom track, e.g. the time slider
   */
  ThinWindow = "ThinWindow_",
  /**
   * A window that can be placed in all tracks including the bottom, left and right track, e.g. the time label
   */
  SmallWindow = "SmallWindow_",
  /**
   * A window that cannot be placed in any tracks
   */
  LargeWindow = "LargeWindow_",
  /**
   * A window, which is shown as a modal. As window steals focus, it's non-draggable
   */
  ModalWindow = "ModalWindow_",
  /**
   * An element of the grid (Layout)
   */
  GridElement = "GridElement_",
}

export const allDragItemTypes = enumValues(DragItemType);

import type { CoordinateSystem } from "@mm/api.meteomatics.com";
import {
  SpatiotemporalTileCache,
  type SpatiotemporalTileCacheConfiguration,
} from "../SpatioTemporalTileCache/SpatiotemporalTileCache";
import { PvsTileService } from "./PvsTileService";
import type { TileGetter } from "./TileGetters/TileGetter";

/**
 * A factory method to creat a tiling service from a configuration.
 */
export function createPVSTileService<TCrs extends CoordinateSystem, TTileGetter extends TileGetter<TCrs, any> = any>(
  conf: SpatiotemporalTileCacheConfiguration<TTileGetter>,
) {
  const stCache = new SpatiotemporalTileCache(conf);
  return new PvsTileService<TCrs, TTileGetter>(stCache);
}

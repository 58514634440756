import { FormField } from "@/overlay/components/form-field";
import { t } from "@lingui/macro";
import { useRef, useState } from "react";

export type InitDateInputProps = {
  defaultValue: string;
  onChange: (value: string) => void;
};

export const InitDateField = ({ defaultValue, onChange }: InitDateInputProps) => {
  const initialValueSet = useRef(false);
  const [inputValue, setInputValue] = useState("");

  if (!initialValueSet.current) {
    setInputValue(defaultValue);
    initialValueSet.current = true;
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    onChange(newValue);
  };

  return (
    <FormField label={t`Init date`}>
      <input type="text" value={inputValue} onChange={handleChange} />
    </FormField>
  );
};

/**
 * Filter a list of classes.
 *
 * Usage example:
 *
 * ```
 * <div className={classNames(isBold && "button--bold", "button")}></div>
 * ```
 */
export const classNames = (...arr: (string | boolean | undefined)[]) => arr.filter(Boolean).join(" ");

import { useEffect } from "react";
import { useState } from "react";
import { type ReactNode, useRef } from "react";
import * as React from "react";
import { DndProvider, createDndContext } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ListContainer } from "./ListContainer";

// React DnD context that can track drag & drop state independently from other DnD contexts within the same app.
// Reference:https://github.com/react-dnd/react-dnd/issues/186#issuecomment-635699417
const SortablaListDnDContext = createDndContext(HTML5Backend);

// ReactDnD allows different droppable components to share the same item type so you can drag and drop item from one to the other.
// But for the sake of reusability and independence of sortable list component, each list have different item type.
const generateUniqueItemType = () => {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters after the decimal.
  return `_${Math.random().toString(36).substr(2, 9)}`;
};

export type DraggableItem = {
  id: string | number;
  content: ReactNode;
};

export type DraggableItemIdentifier = {
  index: number;
  id: string | number;
};

type SortableListProp = {
  className?: string;
  items: DraggableItem[];
  onSwapItems?: (dragStartItem: DraggableItemIdentifier, dropTargetItem: DraggableItemIdentifier) => void;
};

// A wrapper to provide the shared DnDProvider for all the instances of SortableList components.
export function SortableList(props: SortableListProp) {
  const manager = useRef(SortablaListDnDContext);
  const [itemType, setItemType] = useState<undefined | string>(undefined);
  const dndManager = manager.current.dragDropManager;

  useEffect(() => {
    setItemType(generateUniqueItemType());
  }, []);

  if (!itemType) {
    return <></>;
  }
  return (
    // @ts-ignore
    <DndProvider backend={HTML5Backend} manager={dndManager}>
      <ListContainer
        className={props.className}
        itemType={itemType}
        items={props.items}
        onDropItem={(dragIndex, dropIndex, dragItemId, dropItemId) => {
          if (props.onSwapItems) {
            props.onSwapItems({ index: dragIndex, id: dragItemId }, { index: dropIndex, id: dropItemId });
          }
        }}
      />
    </DndProvider>
  );
}

import type { ColDef, RowStyle } from "ag-grid-community";

export const getExpandedRows = (
  rows: NestedRowData[],
  expandedRowIds: string[],
  shouldExpand: boolean,
): NestedRowData[] => {
  const result: NestedRowData[] = [];
  const addRowWithChildren = (row: NestedRowData) => {
    if (shouldExpand || !row.children || row.children.length === 0) {
      result.push(row);
    }
    if ((expandedRowIds.includes(row.id) || !shouldExpand) && row.children) {
      for (const child of row.children) {
        addRowWithChildren(child);
      }
    }
  };
  for (const item of rows) {
    addRowWithChildren(item);
  }
  return result;
};

export const childRowBackgroundStyle = (params: any, color?: string): RowStyle => {
  if (params.data.parentId) {
    return { backgroundColor: color || "#f2f2f2" };
  }
  return {};
};

export type NestedRowData = {
  id: string;
  parentId: number | string | null;
  children?: NestedRowData[];
  [key: string]: any;
};

export type TableOptions = {
  isFloatingHeader?: boolean;
  isNested?: boolean;
};

export type TypedColDef<T> = ColDef & {
  field?: keyof T; // Ensures the field value must be a key of T
  children?: TypedColDef<T>[]; // Column grouping
};

import type { RootState } from "@/reducer";
import { createSelector } from "@reduxjs/toolkit";
import { useEffect, useRef, useState } from "react";
import { type TypedUseSelectorHook, useSelector } from "react-redux";

const selectPrefetchState = (state: RootState) => state.prefetchState;

const selectViewportPrefetchState = createSelector([selectPrefetchState], (prefetchState) => prefetchState.viewports);

const selectActiveLayerPropsPrefetchState = createSelector(
  [selectPrefetchState],
  (prefetchState) => prefetchState.activeLayersProps,
);

export function useViewportPrefetchState() {
  return useSelector(selectViewportPrefetchState);
}

export function useActiveLayerPropsPrefetchState() {
  return useSelector(selectActiveLayerPropsPrefetchState);
}

// S is the RootState. If used with reselect, it can be derived. Otherwise, it could also initialized here with S = RootState
export function useDebouncedSelector<T, S>(
  selector: (state: S) => T,
  time = 100,
  equalityFn?: (left: T, right: T) => boolean,
) {
  const useAppSelector: TypedUseSelectorHook<S> = useSelector;
  const [state, setState] = useState<{ data: T | undefined }>({ data: undefined });
  const result = useRef<T>();
  const data = useAppSelector<T>(selector, equalityFn);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (result.current !== data) {
        result.current = data;
        setState({ data });
      }
    }, time);

    return () => clearTimeout(handler);
  }, [data, time]);

  return state.data;
}

import { PrivateRoute } from "@/auth";
import ProfilePage from "@/pages/profile";
import * as route from "@/route";
import ActivateWorkspace from "@/route/activate/workspace";
import React from "react";
import { Redirect, Switch } from "react-router-dom";

export function NcmArea() {
  return (
    <Switch>
      <PrivateRoute exact={true} path={route.pageNcm.pattern() + route.pageProfiles.pattern()}>
        <ProfilePage showCustomLogo={true} />
      </PrivateRoute>
      <PrivateRoute exact={true} path={route.pageNcmWorkspace.pattern()}>
        <ActivateWorkspace />
      </PrivateRoute>
      <Redirect exact from={""} to={route.pageNcm.pattern() + route.pageProfiles.pattern()} />
    </Switch>
  );
}

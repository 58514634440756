import Logger from "logging";
import type { QueryId, ThreadId, WithQueryId } from "../shared";

// Note: Safari does not support `navigator.hardwareConcurrency`.
const DEFAULT_THREAD_POOL_SIZE = Math.max(navigator.hardwareConcurrency / 2 ?? 4, 8);

const MAX_IN_FLIGHT_PROMISES = Number.MAX_SAFE_INTEGER;

type PendingQuery = {
  resolve: (value: any) => void;
  reject: (reason?: any) => void;
};

const logger = Logger.fromFilename(__filename);

/**
 * A thread pool build around promises.
 *
 * When creating a conrete class for this pool, you will probably want
 * to do the following things.
 *
 * 1. use a union type with a `kind` discriminant as conrete types for
 *    the generics `MsgToWorkerThread` and `MsgToPool`.
 * 2. implement a switch over this `kind` discriminant in `multiplex`.
 * 3. add a few public methods that each map to a value of the `kind`
 *    discriminant and just call `send(msg)` after constructing the
 *    message from the function arguments.
 */
export abstract class ThreadPool<MsgToWorkerThread, MsgToPool> {
  private pending: Map<QueryId, PendingQuery> = new Map();

  private nextQueryId: QueryId = 0;
  private nextThreadId: ThreadId = 0;
  private threads: Worker[] = [];

  constructor(readonly numThreads = DEFAULT_THREAD_POOL_SIZE) {
    for (let threadId = 0; threadId < numThreads; threadId++) {
      const thread = this.newThread(threadId);
      this.threads.push(thread);
      thread.onmessage = this.receive.bind(this);
    }
  }

  /**
   * Should return a worker implementing the <MsgToWorkerThread> and <MsgToPool> protocol.
   *
   * We recommend to use webpack and the webpack-worker-plugin and the following implementation:
   *
   * ```ts
   * import ThreadPool from "threading-lib";
   *
   * export class MyThreadPool extends ThreadPool {
   *   getWorker() {
   *     return new Worker("path/to/worker", { type: "module" });
   *   }
   * }
   * ```
   */
  abstract newThread(threadId: ThreadId): Worker;

  /**
   * Multiplexer receiving messages from a thread in the threadpool.
   * You might do some post processing here and then call
   */
  abstract multiplex(query: PendingQuery, msg: MsgToPool): void;

  private receive(msg: MessageEvent<any>) {
    const { queryId, payload } = msg.data as WithQueryId<MsgToPool>;
    const query = this.getQuery(queryId);

    if (query === undefined) {
      logger.error("invariant violated. received answer for", queryId, "which is not pending");
      return;
    }

    this.multiplex(query, payload);
  }

  protected send<T>(msg: MsgToWorkerThread, transferList: Transferable[] = []): Promise<T> {
    return new Promise((resolve, reject) => {
      const queryId = this.newQueryId();
      const threadId = this.selectThread();
      this.pending.set(queryId, { resolve, reject });
      const threadMsg: WithQueryId<MsgToWorkerThread> = {
        queryId,
        payload: msg,
      };
      this.threads[threadId].postMessage(threadMsg, transferList);
    });
  }

  private selectThread(): ThreadId {
    this.nextThreadId = (this.nextThreadId + 1) % this.numThreads;
    return this.nextThreadId;
  }

  private newQueryId(): QueryId {
    this.nextQueryId = (this.nextQueryId + 1) % MAX_IN_FLIGHT_PROMISES;
    return this.nextQueryId;
  }

  private getQuery(id: QueryId): PendingQuery | undefined {
    const query = this.pending.get(id);
    this.pending.delete(id);
    return query;
  }
}

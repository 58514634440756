import { useAppDispatch } from "@/reducer";
import { addCountryPlot, useCountryPlot } from "@/reducer/CountryPlotState";
import type { CountryPlotType } from "@/reducer/client-models/CountryPlot";
import { Suspense, lazy, memo, useEffect } from "react";

export type GridCountryPlotProps = {
  countryPlot: CountryPlotType;
};

export const CountryPlotViewLazy = memo(
  lazy(() =>
    import(/* webpackChunkName: "countryPlotView" */ "@/countryPlotView/index")
      .then(({ CountryPlotView }) => ({ default: CountryPlotView }))
      .catch((e) => {
        console.error(e);
        return { default: () => <div>Loading Country Plot View failed</div> };
      }),
  ),
);

export function GridCountryPlot({ countryPlot }: GridCountryPlotProps) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(addCountryPlot(countryPlot));
  }, [dispatch, countryPlot]);

  const countryPlotFromRedux = useCountryPlot(countryPlot.id);

  if (!countryPlotFromRedux) return null;

  return (
    <Suspense fallback={<mm-loader size="large" />}>
      <CountryPlotViewLazy countryPlot={countryPlotFromRedux} />
    </Suspense>
  );
}

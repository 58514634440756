import type { MapStateCoord } from "@/models/map-interaction/mapInteractionStateCtxTypes";

export function isValidCoordinates(lon: number, lat: number) {
  return validLongitude(lon) && validLatitude(lat);
}

function validLongitude(lon: number) {
  return isNumber(lon) && lon > -180 && lon < 180;
}

function validLatitude(lat: number) {
  return isNumber(lat) && lat > -90 && lat < 90;
}

function isNumber(n: any): boolean {
  return typeof n === "number";
}

export function validateCoordStr(coordsStr: string): boolean {
  const coord = toCoordObj(coordsStr);
  return isValidCoordinates(coord.lon, coord.lat);
}

export function toCoordObj(coordsLatLon: string): MapStateCoord {
  return {
    lat: Number(coordsLatLon.split(",")[0]),
    lon: Number(coordsLatLon.split(",")[1]),
  };
}

export function toLocationObj(coordsLocation: string): MapStateCoord & { label?: string } {
  return {
    lat: Number(coordsLocation.split(",")[0]),
    lon: Number(coordsLocation.split(",")[1]),
    label: coordsLocation.split(",")[2],
  };
}

export function toCoordStr(coord: MapStateCoord): string {
  return `${coord.lat},${coord.lon}`;
}

import { useActor } from "@xstate/react";
import { useContext, useEffect } from "react";
import type { StateFrom } from "xstate";
import { readTimeStateDesc } from "./timeStateApiHandlers";
import { TimeStateMachineContext } from "./timeStateContext";
import { TimeEventType } from "./timeStateEvents";
import type { TimeState, TimeStateMachine } from "./timeStateMachine";

/**
 * Returns a running instance of time state machine (service)
 *
 * Note that this will NOT rerender upon state change.
 */
export function useTimeService() {
  const { timeStateService } = useContext(TimeStateMachineContext);
  return {
    timeService: timeStateService,
    send: timeStateService.send,
  };
}
// Alias of above hook to use for dependency injection in the following hooks.
const useTimeServiceAlias = useTimeService;

export function useCurrTimeState(): StateFrom<TimeStateMachine> {
  const { timeService } = useTimeService();
  const [state] = useActor(timeService);
  return state;
}
const useCurrTimeStateAlias = useCurrTimeState;

/**
 * An observer hook that (re)initializes the time state machine
 * if it's in the idle state.
 */
export function useTimeServiceSync({
  profileId,
  useTimeService = useTimeServiceAlias,
  useCurrTimeState = useCurrTimeStateAlias,
}: {
  profileId: number | undefined;
  useTimeService?: typeof useTimeServiceAlias;
  useCurrTimeState?: typeof useCurrTimeStateAlias;
}) {
  const { send } = useTimeService();
  const timeState = useCurrTimeState();

  useEffect(() => {
    // If the machine is idle and the the app has an active profile ID
    if (timeState.matches("IDLE") && profileId) {
      readTimeStateDesc(profileId, send);
    }
  }, [profileId, timeState, send]);
}

/**
 * Emits an event to reset the time state machine to the idle state
 * upon component mount
 */
export function useIdleTimeMode() {
  const { send } = useTimeService();
  useEffect(() => {
    send({ type: TimeEventType.resetToIdleMode });
  }, [send]);
}

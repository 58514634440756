import type { ModelSchema } from "@mm/api-layers.meteomatics.com/models";
import { CoordinateSystem } from "@mm/api.meteomatics.com";
import { WORLD_BBOX } from "@mm/api.meteomatics.com";
import { Area } from "@mm/api.meteomatics.com/lib";
import type { ModelIdentifier } from "@mm/api.meteomatics.com/lib";
import Logger from "logging";
import { searchEngineWeatherModels } from "./LayersApiContext";
const logger = Logger.fromFilename(__filename);

export function getModelSchemFromStr(modelIdentifier: ModelIdentifier): ModelSchema | null {
  return searchEngineWeatherModels.matchModelExactly(modelIdentifier);
}

export function getModelBoundingBox(modelIdentifier: ModelIdentifier): Area<CoordinateSystem.WGS84> {
  const modelSchema = getModelSchemFromStr(modelIdentifier);
  if (!modelSchema) {
    logger.warn(
      `Could not find a ModelSchema object for model ${modelIdentifier}. Using a fallback model bounding box of the entire world.`,
    );
    return WORLD_BBOX;
  }
  return new Area(CoordinateSystem.WGS84, modelSchema.bounding_box);
}

import { Button, ButtonBar } from "@/overlay/components/CommonParts/buttons";
import { FormField } from "@/overlay/components/form-field";
import type { IconData } from "@/reducer/client-models";
import { Trans, t } from "@lingui/macro";
import { useState } from "react";

export const TEST_IDS = {
  hideIcon: "PoiType_hide-icon-button",
  hideText: "PoiType_hide-text-button",
  hideLabels: "PoiType_hide-labels-button",
};

export interface PoiTypeProps {
  icon?: string;
  text?: string;
  hideLabels?: boolean;
  onToggleLabels: (enabled: boolean) => void;
  onTypeChange: (data: Partial<IconData>) => void;
}

export const PoiTypeField = ({ icon, text, hideLabels, onTypeChange, onToggleLabels }: PoiTypeProps) => {
  const [prevText, setPrevText] = useState<string>("");
  const [prevIcon, setPrevIcon] = useState<string>("");

  function setIcon() {
    if (!icon) {
      onTypeChange({ icon: prevIcon });
    } else {
      setPrevIcon(icon);
      onTypeChange({ icon: "" });
    }
  }

  function setText() {
    if (!text) {
      onTypeChange({ text: prevText });
    } else {
      setPrevText(text);
      onTypeChange({ text: "" });
    }
  }

  function handleToggleLabels() {
    onToggleLabels(!hideLabels);
  }

  return (
    // noSemanticLinking prevents the label to act as a button
    <FormField label={t`POI Type`} noSemanticLinking={true}>
      <ButtonBar pill>
        <Button active={!!icon} onClick={setIcon} type="button" data-testid={TEST_IDS.hideIcon}>
          <Trans>Icon</Trans>
        </Button>
        <Button active={!!text} onClick={setText} type="button" data-testid={TEST_IDS.hideText}>
          <Trans>Text</Trans>
        </Button>
        <Button active={!hideLabels} onClick={handleToggleLabels} type="button" data-testid={TEST_IDS.hideLabels}>
          <Trans>Value</Trans>
        </Button>
      </ButtonBar>
    </FormField>
  );
};

import { type AviationTypes, aviationTypeList } from "@/layers/utility/aviationType";
import { allAviationProperties } from "@/layers/utility/aviationValues";
import { FormField } from "@/overlay/components/form-field";
import { t } from "@lingui/macro";

export function AviationTypeSelect(props: {
  aviationType: string;
  change: (propsValue: { aviation_type: AviationTypes }) => void;
}) {
  return (
    <FormField label={t`Aviation Data Type`}>
      <select
        value={props.aviationType}
        onChange={(event) => props.change({ aviation_type: event.target.value as AviationTypes })}
      >
        {aviationTypeList?.map((aviationType, idx) => (
          <option key={aviationType} value={aviationType}>
            {allAviationProperties[aviationType]?.name()}
          </option>
        ))}
      </select>
    </FormField>
  );
}

import type { Geometry, Position } from "geojson";
import { xml2js } from "xml-js";
import type { WfsCapabilities } from "../models/WfsCapabilities";
import type { WFS_Capabilities_UNSTABLE } from "./WFSCapabilities";
import type { WFSFeatureCollection_UNSTABLE } from "./WFSFeatureCollection";
export type GeoJSONFeatureCollection = GeoJSON.FeatureCollection<GeoJSON.Geometry>;

/**
 * Assumes GML version 3.1.1.
 */

// biome-ignore lint/complexity/noStaticOnlyClass: Bad class
export class WFSParser {
  /**
   * @param wfsXML A raw string of 1.0 WFS GetCapabilities
   * @returns A parsed WFS Capabilities.
   */
  static parseWfsCapabilities(capabilitiesXml: string) {
    function convertWfsCapabilities2json() {
      const parseOptions = {
        compact: true,
        space: 0,
      };
      return xml2js(capabilitiesXml, parseOptions);
    }

    const wfsCapabilitie = <WFS_Capabilities_UNSTABLE>convertWfsCapabilities2json();

    if (!wfsCapabilitie.WFS_Capabilities) {
      `This WFS string does not contain "wfs:FeatureTypeList" attribute. You're possibly trying to convert other WFS format that is not FeatureTypeList.`;
    }

    const capabilities: WfsCapabilities = {
      featureTypes: [],
      namespaces: {},
      operations: [],
      version: "1.0.0", // Meteomatics endpoint is using 1.0.0 version and we parse response that way
    };

    const {
      WFS_Capabilities: {
        Service,
        Capability: { Request },
        FeatureTypeList: { FeatureType },
      },
    } = wfsCapabilitie;

    capabilities.title = Service.Title._text;
    capabilities.abstract = Service.Abstract._text;

    for (const operation of Object.keys(Request)) {
      capabilities.operations.push(operation);
    }

    for (const feature of FeatureType) {
      const {
        LatLongBoundingBox: { _attributes: attr },
      } = feature;
      capabilities.featureTypes.push({
        name: feature.Name._text,
        title: feature.Title._text,
        defaultSrs: feature.SRS._text,
        bounds: [
          Number.parseFloat(attr.minx),
          Number.parseFloat(attr.miny),
          Number.parseFloat(attr.maxx),
          Number.parseFloat(attr.maxy),
        ],
      });
    }

    return capabilities;
  }

  /**
   * @param wfsXML A raw string of WFS GetFeatureRequest that contains "wfs:FeatureCollection" attribute.
   * @returns A GeoJSON FeatureCollection for the WFS feature collection.
   */
  static convertFeatureCollection2GeoJSON(wfsXml: string) {
    function convertWfsFeatureCollection2json() {
      const parseOptions = {
        compact: true,
        space: 0,
        elementNameFn: (eleName: string) => {
          // Replace all the WFS prefixes (eg: "wfs:", "gml:") fron attributes.
          return eleName.replace(/^(.+?):/i, "");
        },
      };
      const featureCollection = xml2js(wfsXml, parseOptions);
      return featureCollection;
    }
    const featureCollection = <WFSFeatureCollection_UNSTABLE>convertWfsFeatureCollection2json();

    function convertFeatureCollection2GeoJson(featureCollection: WFSFeatureCollection_UNSTABLE) {
      if (!featureCollection.FeatureCollection) {
        throw new Error(
          `This WFS string does not contain "wfs:FeatureCollection" attribute as the outer most container. You're possibly trying to convert other WFS format that is not FeatureCollection.`,
        );
      }
      const geoJson: GeoJSONFeatureCollection = { type: "FeatureCollection", features: [] };
      // Parse each feature
      const featuresWfs = featureCollection.FeatureCollection.featureMember;
      if (!featuresWfs) {
        // Return without filling up the features attribute if no features are included in the text.
        return geoJson;
      }

      // Sometimes features is single object. Make sure parser dont break then
      const featuresGeo = ((Array.isArray(featuresWfs) ? featuresWfs : [featuresWfs]) as any[]).reduce(
        (acc, featureWfs) => {
          // Get the first key, and get its object which contains geometries and properties for GeoJSON.
          const featureAttrsWfs = featureWfs[Object.keys(featureWfs)[0]];

          // Parse the geometry attribute.
          //    Get the geometry type from WFS.
          //    geometry.type attribute in GeoJSON must be one of
          //    "Point", "MultiPoint", "LineString","MultiLineString", "Polygon", "MultiPolygon", and "GeometryCollection"
          //    https://datatracker.ietf.org/doc/html/rfc7946#section-1.4
          const geometryTypeNameWfs = Object.keys(featureAttrsWfs.Shape)[0]; //eg) Point
          const geometryWfs = featureAttrsWfs.Shape[geometryTypeNameWfs];

          // Features without geometry breaks mapbox, so filter out it
          if (geometryWfs) {
            const geometryGeo = {
              type: <Geometry["type"]>geometryTypeNameWfs,
              coordinates: [] as Position[] | Position[][],
            };
            if (geometryWfs) {
              if (geometryTypeNameWfs === "LineString") {
                const coords = geometryWfs.coordinates._text
                  .split(" ")
                  .reduce((acc: [number, number][], coordString: string, index: number) => {
                    const [slng, slat] = coordString.split(",");
                    const lng = Number.parseFloat(slng);
                    const lat = Number.parseFloat(slat);
                    if (acc.length) {
                      // https://github.com/mapbox/mapbox-gl-js/issues/3250
                      const [prevLng] = acc[acc.length - 1];
                      const fixedLng = lng + (lng - prevLng > 180 ? -360 : prevLng - lng > 180 ? 360 : 0);
                      acc.push([fixedLng, lat]);
                    } else {
                      acc.push([lng, lat]);
                    }

                    return acc;
                  }, []);
                geometryGeo.coordinates = coords;
              } else {
                // This JSON parsing assumes that the original value in the XML tag is a comma separated value.
                // e.g) <coordinates>6.6103,46.9838</coordinates> ---> Works!
                //      <coordinates>[6.6103,46.9838]</coordinates> ---> Adds unnecessary outer array.
                geometryGeo.coordinates = geometryWfs.coordinates
                  ? JSON.parse(`[${geometryWfs.coordinates._text}]`)
                  : null;
              }
            }

            // Parse the properties attribute from the raw object.
            // Get every attribute from featureAttrs_WFS, except for Shape attribute, which was used for extracting geometry above.
            const { Shape, ...propertiesWfs } = featureAttrsWfs;
            // At this point, some of the attributes' value in properties_WFS is stored as an object under the "_text" atttribute like
            // e.g) <name>Passo del Bernina</name> {_text: "Passo del Bernina"}
            // So we want to just extract the text value.
            for (const attr in propertiesWfs) {
              const obj = propertiesWfs[attr];
              if ("_text" in obj) {
                propertiesWfs[attr] = obj._text;
              }
            }
            const propertiesGeo = { ...propertiesWfs };

            acc.push({
              type: "Feature",
              geometry: geometryGeo,
              properties: propertiesGeo,
            });
          }
          return acc;
        },
        [],
      );
      return { ...geoJson, features: featuresGeo };
    }

    const geoJsonFeatureCollection = convertFeatureCollection2GeoJson(featureCollection);
    return <GeoJSONFeatureCollection>geoJsonFeatureCollection;
  }
}

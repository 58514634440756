import {
  type HashableValues,
  hashSimpleObject,
} from "@/cache/PVSTileService/TileGetters/tile-getter-utils/hashUtilities";
import { type CoordinateSystem, GridSamplingStrategyKind, type PixelGridRequest } from "@mm/api.meteomatics.com";

export function hashPixelGridRequest(request: PixelGridRequest<CoordinateSystem>) {
  // We manually create a string by spreading out the values, as JSON.strinfigy()
  // is inefficient and sometimes doesn't properly serialize values.
  const hashableVals: HashableValues<PixelGridRequest<CoordinateSystem.EPSG3857>> = {
    sampling: `${request.sampling.kind.toString()}/${
      request.sampling.kind === GridSamplingStrategyKind.AreaSample && request.sampling.sample.join(",")
    }`,
    area: hashSimpleObject({ ...request.area.toTransferable() }),
    // Important: Using toISO() method can resulting in different strings
    // even if two datetime objects represent the same time. So we use unix epoch time using toSeconds()
    datetime: request.datetime.toSeconds(),
    width: request.width,
    height: request.height,
    parameters: request.parameters.join(","),
    model: request.model,
    // biome-ignore lint/complexity/noExtraBooleanCast: Checks 3 types
    ensSelect: !!request.ensSelect ? request.ensSelect : "",
    boundingBoxLimit: request.boundingBoxLimit
      ? hashSimpleObject({ ...request.boundingBoxLimit.toTransferable() })
      : "",
    colorMap: request.colorMap != null ? request.colorMap : "",
    calibrated: request.calibrated !== undefined ? request.calibrated : "undefined",
    vertical_interpolation: request.vertical_interpolation ? request.vertical_interpolation : "",
    initDate: request.initDate || "",
  };
  return Object.values(hashableVals).join("#");
}

import type * as MeteomaticsLayers from "@mm/api-layers.meteomatics.com";
import type { PartialWeatherParameter } from "../search-engines/WeatherParamSearchEngine/model/PartialWeatherParameter";
import { autofill, parameterToString } from "./transform";

/**
 *
 *
 * Inverse of `parseParameterRange`.
 *
 * @param range
 * @param value
 */
export function formatParameterRange(baseParameter: MeteomaticsLayers.ParameterRange, value: number) {
  return baseParameter.format.replace("<value>", value.toString());
}

export function escapeRegex(s: string) {
  return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
}

/**
 * Inverse of `formatParameterRange`.
 *
 * @param range
 * @param value
 */
export function parseParameterRange(range: MeteomaticsLayers.ParameterRange, value: string): number | null {
  const matcher = new RegExp(`^${escapeRegex(range.format).replace("<value>", "(?<value>[0-9]+)")}$`);

  let res: number | null = null;

  if (value) {
    const match = value.match(matcher);

    if (match?.groups?.value) {
      res = +match.groups.value;
    }
  }

  return res;
}

/**
 * Format parameter for API request. Concatenate the parameter strings with a comma "," in between.
 * @param parameter
 */
export function formatForApiWithCommas(parameter: PartialWeatherParameter | PartialWeatherParameter[]): string {
  if (Array.isArray(parameter)) {
    return parameter.map((param) => parameterToString(autofill(param)).formatted).join(",");
  }
  return parameterToString(autofill(parameter)).formatted;
}

/**
 * Format parameter for API request. Concatenate the parameter strings with a comma "+" in between.
 * @param parameter
 */
export function formatForApiWithPlus(parameter: PartialWeatherParameter | PartialWeatherParameter[]): string {
  if (Array.isArray(parameter)) {
    return parameter.map((param) => parameterToString(autofill(param)).formatted).join("+");
  }
  return parameterToString(autofill(parameter)).formatted;
}

import { CoordinateSystem } from "./CoordinateSystem";
import proj4 from "proj4";

/**
 * Variant of `SinglePointCoordinate` that is safe to transfer between workers and over the network.
 */
export interface SinglePointCoordinate_Transferable<Crs extends CoordinateSystem> {
  crs: Crs;
  lat: number;
  lng: number;
}

export class SinglePointCoordinate<Crs extends CoordinateSystem> {
  constructor(
    public lat: number,
    public lng: number,
    public readonly crs: Crs,
  ) {}
  private inCrs(v: any): v is Crs {
    return this.crs === v;
  }

  project(toCrs: Crs): SinglePointCoordinate<Crs>;
  project<TargetCrs extends CoordinateSystem>(toCrs: TargetCrs): SinglePointCoordinate<TargetCrs>;
  project(toCrs: CoordinateSystem): SinglePointCoordinate<CoordinateSystem> {
    if (this.inCrs(toCrs)) {
      return this.copy();
    } else {
      const [lng, lat] = proj4(this.crs, toCrs, [this.lng, this.lat]);
      return new SinglePointCoordinate(lat, lng, toCrs);
    }
  }

  toTransferable(): SinglePointCoordinate_Transferable<Crs> {
    return {
      crs: this.crs,
      lng: this.lng,
      lat: this.lat,
    };
  }
  copy(): SinglePointCoordinate<Crs> {
    return new SinglePointCoordinate(this.lat, this.lng, this.crs);
  }

  static fromTransferable<Crs extends CoordinateSystem>(
    singlePointCoordinate: SinglePointCoordinate_Transferable<Crs>,
  ): SinglePointCoordinate<Crs> {
    return new SinglePointCoordinate(singlePointCoordinate.lat, singlePointCoordinate.lng, singlePointCoordinate.crs);
  }
}

export class IdGenerator {
  private nextId = 0;

  /**
   * `9007199254740990` in conforming JS implementations
   */
  private readonly maxId = Number.MAX_SAFE_INTEGER - 1;

  /**
   * Generate a unique id
   *
   * Gurantees:
   * - generated ids are collision free if oldest active id
   *   is younger than  `maxId` invocations of this method
   * - this will only generate negative ids, so ids are guranteed
   *   to not collide with database ids
   */
  public generateId() {
    // [0,maxId)
    this.nextId = (this.nextId + 1) % this.maxId;
    // [-maxId, -1]
    return -this.nextId - 1;
  }
}

import type { ModelSchema } from "@mm/api-layers.meteomatics.com";

export class ModelSearchEngine {
  private models: ModelSchema[] = [];

  replaceCorpus(models: ModelSchema[]) {
    this.models = models;
  }

  /**
   * Lookup a weather model based on its identifer.
   * @param id A model identifier
   * @returns meta information of the weather model, such as its display name, bounding box, etc.
   */
  matchModelExactly(id: string): ModelSchema | null {
    return this.models.find((model) => model.identifier === id) ?? null;
  }
}

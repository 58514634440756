/**
 * This is a spatial temporal cache for binary API requests to the Meteomatics API.
 *
 * We need to support three operations:
 * - iterate over time slices within the cache.
 *   This is used for interpolation between data frames during animation
 * - walk the resolution pyramid for a fixed parameter and a fixed time slice.
 *   This is used to collect and cull tiles within a frame.
 * - cut down a slice to a weather parameter or a set of weather parameters.
 *
 * We thus partition the cache / cache tag:
 *
 * ```
 * [ parameter description ]         [ temporal description ]                    [ spatial description ]
 *   class Cache --(each entry is a)--> class TemporalSubcache --(each entry is a)--> class SpatialSubcache
 * ```
 */

import type { TileDataDescription } from "@/cache/SpatioTemporalTileCache/TileDataDescription";
import type { FailureMsgPayload } from "@/threads";
import { formatByteSize } from "@/utility/bytesize";
import type { CoordinateSystem } from "@mm/api.meteomatics.com";
import { isAbortReasonEmpty, isAbortable, isAborted, isAbortedRequest } from "@mm/api.meteomatics.com/lib/middleware";
import Logger from "logging";
import type { DateTime } from "luxon";
import { type AsyncResult, SynchrounousState } from "../AsyncResult";
import type { TileFromGetter, TileGetter } from "../PVSTileService/TileGetters/TileGetter";
import { type Stats, defaultCacheFormatter, jsxTable } from "../Stats";
import { type DataAge, type MultilevelLruEvictionResult, multilevel_lru_evict } from "../evictionStrategy";
import { apiThreadPool } from "./ApiQueryThreadPool";
import type { PositionedTile } from "./PositionedTile";
import { PotentiallyVisibleTileSet } from "./PotentiallyVisibleTileSet";
import type { SliceDataDescription } from "./SliceDataDescription";
import { type SpatiotemporalTileCacheStats, emptySpatioTemporalTileCacheStats } from "./SpatioTemporalTileCacheStats";
import type { SpatiotemporalTileLookupSettings } from "./SpatiotemporalTileLookupSettings";
import { TemporalSubcache } from "./TemporalSubcache";
import { type TileArea, getMinZoomLevel, tileGeometries, wrapIntoWorldZero } from "./TileArea";

const logger = Logger.fromFilename(__filename);

type CacheId = string;

/**
 * Function to generate a key to uniquely identify a time slice cache instance (in other words, "temporal cache").
 */
function getTimeSliceCacheId(datetime: DateTime) {
  return datetime.toUTC().toISO({ suppressMilliseconds: true });
}

export interface SpatiotemporalTileCacheConfiguration<TTileGetter extends TileGetter<any, any> = TileGetter<any, any>> {
  label: string;
  /**
   * Width and height of each tile. (Spatial resolution/spatial discretization)
   *
   * (This is the reference tile size, you MUST NOT apply any functions related to denormalization of
   * tiles prior to passing the tile size.)
   */
  tileSize: number;
  /**
   * Maximal size of the cache. Unit of this value depends on the cache implementation, might be
   * in bytes, grid points, number of entries or something else.
   */
  maxSize: number;
  /**
   * Format a cache size in a human readable format.
   * For example, if you are measuring cache size in bytes, this should return something like `102 MiB`.
   *
   * @param size
   */
  formatSize: (size: number) => any;
  /**
   * Let the cache temporarily grow up to the given size before triggering the eviction routine
   * that will bring down the cache size back to `maxSize`.
   *
   * This reduces the number of invocations of the eviction routine. Otherwise, in a cache
   * at capacity, the eviction routine will be triggered for each new entry.
   */
  delayEvictionUntilSize: number;
  tileGetter: TTileGetter;
}

/**
 * Caches meteomatics tiles from the network.
 *
 * Cache eviction is currently evicting entries on the granularity of whole time slices. Partial eviction of
 * time slices (e.g. out of viewport tiles) is currently not done.
 */
export class SpatiotemporalTileCache<
  TTileGetter extends TileGetter<any, any>,
  TTile extends TileFromGetter<TTileGetter> = TileFromGetter<TTileGetter>,
> implements Stats<SpatiotemporalTileCacheStats>
{
  public readonly emptyTile: TTile;
  public readonly failureTile: TTile;
  public readonly minZoomLevel: number;

  public stats_: SpatiotemporalTileCacheStats = emptySpatioTemporalTileCacheStats();

  private cache: { [key: string]: TemporalSubcache<TTileGetter> } = {};

  constructor(public readonly conf: SpatiotemporalTileCacheConfiguration<TTileGetter>) {
    this.emptyTile = conf.tileGetter.createEmptyTile();
    this.failureTile = conf.tileGetter.createFailureTile();
    this.minZoomLevel = getMinZoomLevel(conf.tileSize);
  }

  stats(): SpatiotemporalTileCacheStats {
    return this.stats_;
  }

  statsJsx(): JSX.Element {
    return jsxTable(this.stats(), defaultCacheFormatter);
  }

  descJsx(): JSX.Element {
    return (
      <>
        Cache Size Limit is {this.conf.formatSize(this.conf.maxSize)}, Replacement Policy is a garbage collection pass
        followed by a custom multimodal distance measure based on the LRU policy.
      </>
    );
  }

  clear() {
    this.cache = {};
  }

  label(): string {
    return this.conf.label;
  }

  /**
   * Get tiles to composite the tile area. The composite meight contain spatial ( in the sense of zoom level)
   * and temporal neighbours as placeholders.
   *
   * The composite will only contain tiles for world zero. Use the `instantiateTile` method to create instances
   * for world copies.
   */
  compositeViewport(
    optimalTiles: TileArea,
    desc: Omit<TileDataDescription, "geometry">,
    pvs: PotentiallyVisibleTileSet<TTile>,
    conf: SpatiotemporalTileLookupSettings<TTile>,
  ): PotentiallyVisibleTileSet<TTile> {
    // TODO: the current loop structure is
    // for each optimal tile -> lookup parameter -> lookup timeslice -> ... [ tile centric lookup ]
    // would be worthwhile to test: lookup param -> lookup timeslice -> for each optimal tile -> ... [ viewport centric lookup ]
    // on the other hand, we should investicate "optimalTiles" set computation per frame, not per layer

    // Split into two tile area if crossing the internal date time
    // This takes care of the duplications of areas on west and east if they share the
    // same tiles.
    const optimalTilesWorldZero = wrapIntoWorldZero(optimalTiles);
    for (const tileGeometry of tileGeometries(...optimalTilesWorldZero)) {
      const tileDataDesc: TileDataDescription = {
        ...desc,
        geometry: tileGeometry,
      };

      let newPvs = new PotentiallyVisibleTileSet<TTile>(pvs.datetime, pvs.seconds);

      newPvs = this.retrieveDataFrame(tileDataDesc, conf, newPvs);
      pvs.addPvs(newPvs);
    }

    return pvs;
  }

  /**
    When a user interacts with UI and on-going requests become unnecessary, we abort these requests
  */
  dropObsoleteTiles(urls: string[]) {
    // TODO: Maybe this abort logic should not be here, but should be extracted outside tiling service.
    return apiThreadPool.abortRequestByUrl(urls);
  }

  retrieveTile(tileDesc: TileDataDescription): AsyncResult<TTile> {
    const cacheId = getTimeSliceCacheId(tileDesc.datetime);
    const temporalSubcache = this.getTemporalSubcache(cacheId);
    const timeslice = temporalSubcache.getTimeSlice(tileDesc);
    return timeslice.data.tiles.retrieveTile(tileDesc);
  }

  retrieveSlice<C extends CoordinateSystem>(sliceDesc: SliceDataDescription<C>) {
    const cacheId = getTimeSliceCacheId(sliceDesc.datetime);
    const temporalSubcache = this.getTemporalSubcache(cacheId);

    const timeslice = temporalSubcache.getTimeSlice(sliceDesc);
    return timeslice.data.tiles.retrieveSlice<C>(sliceDesc);
  }

  /**
   * Retrieves a single tile at a single time point
   *
   * Note: Currently this is slightly duplicate with retrieveTile - in the future we should merge these two.
   */
  private retrieveDataFrame(
    tileDesc: TileDataDescription,
    conf: SpatiotemporalTileLookupSettings<TTile>,
    pvs: PotentiallyVisibleTileSet<TTile>,
  ): PotentiallyVisibleTileSet<TTile> {
    const cacheId = getTimeSliceCacheId(tileDesc.datetime);
    const temporalSubcache = this.getTemporalSubcache(cacheId);

    const dataFrameDesc: TileDataDescription = { ...tileDesc, datetime: pvs.datetime };

    const timeslice = temporalSubcache.getTimeSlice(dataFrameDesc);

    const { synchronous: tileDataOrFailure, asynchronous: loadTile } = timeslice.data.tiles.retrieveTile(dataFrameDesc);

    if (tileDataOrFailure) {
      // We need to do catch here, otherwise it causes "rejected promise" error
      // and that causes reloading JS chunks (for some mysterious reason) slowing down whole UI drastically
      // This is quite hacky solution tho, but better solution would be to rethink whole "retrieveTile" functionality
      loadTile.catch(() => {});
      // we already have the perfect tile data in CPU and maybe GPU memory.
      const tileData =
        tileDataOrFailure === SynchrounousState.PermanentlyFailed ||
        tileDataOrFailure === SynchrounousState.NotRequested
          ? this.failureTile
          : tileDataOrFailure;
      const isFailedTile = tileDataOrFailure === SynchrounousState.PermanentlyFailed;

      const tile: PositionedTile<TTile> = { tileGeometry: dataFrameDesc.geometry, tileData, isFailedTile };
      const singletonPvs = new PotentiallyVisibleTileSet<TTile>(pvs.datetime, pvs.seconds);
      singletonPvs.add(tile);
      singletonPvs.props = { uncovered: [], isExact: true };
      pvs.addPvs(singletonPvs);
    } else {
      loadTile
        .then((tile: TTile) => {
          conf.onLoad(tile);
        })
        .catch((e: FailureMsgPayload) => {
          if (isAbortedRequest(e)) {
            return conf.onAbort(dataFrameDesc, e.payload);
          }

          // ignore errors orgination from an aborted request
          if (isAbortable(e)) {
            if (!isAborted(e)) {
              logger.warn("permanent API failure", e);
              return conf.onLoad(this.failureTile);
            }

            if (isAbortReasonEmpty(e)) {
              return conf.onLoad(this.emptyTile);
            }
            if (conf.onAbort) {
              return conf.onAbort(dataFrameDesc, e.reason);
            }
          } else {
            logger.warn("permanent API failure", e);
            return conf.onLoad(this.failureTile);
          }
        });

      // Start searching from the current tile by marking it as uncovered. This extends the PVS region
      // to the new tile. This results in the minimal set independent of tile queue order:
      // - we enqueue an inexistent tile, it could already be covered by the pvs, if a magnification A
      //   was previously added for another tile. But...
      // - we want to add minifications, even when a covering magnification A is present. So this
      //   will result in a minifcation search, followed by
      // - a magnification search, which will either find a magnification that is a minification relative
      //   to A, or A itself. Adding A multiple times is not an issue, since the PVS is a set.
      pvs.addTilePromise([loadTile]);
      pvs.props.uncovered.push(tileDesc.geometry);
      // pvs = timeslice.data.tiles.extendCover(renderFrameDesc, pvs, conf);
    }

    return pvs;
  }

  private getTemporalSubcache(cacheId: CacheId): TemporalSubcache<TTileGetter> {
    if (!Object.hasOwn(this.cache, cacheId)) {
      // TODO: schedule requests for actual data here?
      this.cache[cacheId] = new TemporalSubcache(this);
    }
    return this.cache[cacheId];
  }

  /**
   * Check the cache size before or after the insertion of an element and apply the cache replacement policy
   * to keep memory consumption below the configured limit.
   */
  _checkCacheSize(newEntrySize = 0) {
    const cacheSize = this.stats_.estimatedMemoryConsumption + newEntrySize;
    const isEvictionThresholdReached = cacheSize - this.conf.delayEvictionUntilSize > 0;
    const excessSize = cacheSize - this.conf.maxSize;
    if (isEvictionThresholdReached) {
      this.reduceMemoryUsageBy(excessSize);
    }
  }

  /**
   * Drops cache entries using a LRU cache policy until a reduction by `excessSize` is reached.
   *
   * This will not purge pending requests, since pending requests have a very small memory
   * footprint compared to completed requests. Pending requests should be evicted by a
   * separate network congestion control instead.
   *
   * @param excessSize
   */
  reduceMemoryUsageBy(excessSize: number) {
    const cacheSize = this.stats().estimatedMemoryConsumption;

    if (excessSize > cacheSize) {
      return logger.unreachable(
        "trying to evict more memory than allocated: excessSize =",
        formatByteSize(excessSize),
        "; cacheSize =",
        formatByteSize(cacheSize),
      );
    }

    if (excessSize > 0) {
      // TODO: move to standalone function

      // we ran out of data in unreachable subspaces of the 3d+t data sets
      // evict reachable data with LRU. Using actual 'least-recently used' timestamps
      // might be undesirable: if the animation loops, the least recently used data
      // will be the next needed data, so we choose the worst possible strategy!
      //
      // TODO: we should prevent the two most recently used time slice from being deallocated since
      // they are potentially in the current working set. In this case there are exactly two time slice in memory.
      //
      // TODO: we could check with the preload controller how well the data
      // scores and use this as input to the lru eviction routine (lowest scores will be evicted)
      // That would yield the optimal strategy.
      // TODO: a further enhancement would mix LRU with the preload controller's scoring, because
      // the GUI is most likely to support back buttons that will be used to jump back a few
      // seconds in the animation.

      // TODO: since unload within the cache hierarchy is only called, when the child is a leaf/empty, recursing in `unload` is unnecessary
      // and could be a noop.
      logger.logTiming("eviction across whole cache", () => {
        const eviction = multilevel_lru_evict(
          excessSize,
          this.cache,
          this.usage.bind(this),
          this.lru_evict.bind(this),
          logger,
          formatByteSize,
        );

        if (eviction.newLimitLeastRecentlyUsed != null) {
          // the new limit may change for two reasons:
          // 1. we evicted entries, and the LRU element changed
          // 2. the LRU bound was incorrect because the LRU element was marked as used since the last pass of the eviction algorithm

          // biome-ignore lint/style/noNonNullAssertion: usage MUST exist if the cache is not empty
          this.stats_.usage!.leastRecentlyUsed = eviction.newLimitLeastRecentlyUsed;
        } else {
          // cache is empty: does not have a single loaded tile, but might have pending or failed tiles
          this.stats_.usage = null;
        }
      });
    }
  }

  private lru_evict(
    line: TemporalSubcache<TTileGetter>,
    tag: string,
    excessSize: number,
    maxLeastRecentlyUsed: number,
  ): MultilevelLruEvictionResult {
    const eviction: MultilevelLruEvictionResult = line.reduceMemoryUsageBy_lru(excessSize, maxLeastRecentlyUsed);

    if (eviction.canUnload) {
      this.unloadWeatherParameter(line, tag);
    }

    return eviction;
  }

  private unloadWeatherParameter(cacheLine: TemporalSubcache<TTileGetter>, tag: string) {
    cacheLine.unload();
    delete this.cache[tag];
  }

  private usage(cacheLine: TemporalSubcache<TTileGetter>): DataAge | null {
    return cacheLine.stats_.usage;
  }
}

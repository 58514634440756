import Logger from "logging";
const logger = Logger.fromFilename(__filename);

export interface WithInUseMarker<T> {
  readonly obj: T;
  inUse: boolean;
}

// TODO: memory limits should account for this constant
export const DEFAULT_MAX_CONCURRENT_INSTANCES = 4;
export const DEFAULT_MAX_MAPBOX_CONCURRENT_INSTANCES = 4;

/**
 * An object pool that caches and reuses objects. Initially designed to pool mapbox instances for several reasons:
 *
 * - Mapbox downloads, rasterizes and caches vector tiles on a per instance basis
 * - WebGL contexts are constructed on a per instance basis. This means we have to reupload tiles to the
 *   GPU on each request.
 */
// TODO: move the mapbox specific documentation to <Map>
export class ObjectPool<K, V, AllocatorArguments extends any[]> {
  constructor(
    private readonly allocator: (...args: AllocatorArguments) => V,
    private readonly deallocator: (v: V) => void = () => {},
    private readonly releaser: (v: V) => void = () => {},
    private readonly maxConcurrentInstances = DEFAULT_MAX_CONCURRENT_INSTANCES,
    private readonly reallocateable: boolean = false,
    private cache: Map<K, WithInUseMarker<V>> = new Map(),
  ) {}

  /**
   * [Internal API] readonly endpoint for debugging
   */
  _get_cache(): Map<K, WithInUseMarker<V>> {
    return this.cache;
  }

  /**
   * Allocate an object instance, either by reusing an existing instance or by calling the allocator.
   *
   * This will at construction time of the <Map> component attempt the following in sequence:
   * (1) try to reuse an existing, prerendered instance of the exact mapbox map using `id` as identification
   * (2) try to reuse some other currently unused mapbox instance
   * (3) allocate a new mapbox instance if no unused mapbox instance is available
   *
   * The caller has to cleanup the maps state through a reconsolidation pass. (1) is an optimization to reduce
   * reconsolidation work.
   *
   * @param id an id used to optimize reuse within the pool
   */
  allocate(id: K, ...allocatorArguments: AllocatorArguments): V {
    const exactMatch = this.cache.get(id);
    if (exactMatch !== undefined) {
      if (!exactMatch.inUse) {
        exactMatch.inUse = true;
        return exactMatch.obj;
      }

      logger.unreachable("contract violation, cannot allocate or reuse an existing, in-use cache element", id);
    }

    // If we are at budget, reuse some map with a mismatching id to get at least a mapbox vector tile cache reuse.
    // Furthermore, textures for our own layers might already be allocated on the webgl context associated with the mapbox instance
    // by pure luck.
    //
    // However, in this reusage mode, the consolidation algorithm has to discard all attached layers and rebuild the layer stack.
    // TODO: it's not 100% clear if the outer conditional here has benefits or not. Probably the most efficient strategy would
    // be to use actual texture availibility information as tags and insert each map multiple times.
    if (this.reallocateable && this.cache.size >= this.maxConcurrentInstances) {
      for (const [cacheTag, cacheValue] of this.cache.entries()) {
        if (!cacheValue.inUse) {
          cacheValue.inUse = true;
          this.cache.delete(cacheTag);
          this.cache.set(id, cacheValue);
          return cacheValue.obj;
        }
      }
    }

    this.collectGarbage();

    // we allow the cache to get bigger than `MAX_CONCURRENT_MAP_INSTANCES` as long
    // as all instances are in use. We reduce the set back to `maxConcurrentInstances`
    // as instances are freed.
    const newEntry = this.allocator(...allocatorArguments);
    this.cache.set(id, { inUse: true, obj: newEntry });
    return newEntry;
  }

  /**
   * Get an object instance. If it does not exist yet, allocate it.
   *
   * In contrast to `allocate`, this will not error out if the object is already in use.
   * This allows usage of the pool as a general cache that can temporarily exceed its size.
   *
   * However, you MUST still call free exactly once. Multiple `get` calls do not require
   * multiple free calls.
   */
  // TODO: would not hurt to refcount in this pool
  get(id: K, ...allocatorArguments: AllocatorArguments) {
    const el = this.cache.get(id);
    if (el?.inUse) {
      this.cache.delete(id);
      this.cache.set(id, el);
      return el.obj;
    }
    return this.allocate(id, ...allocatorArguments);
  }

  protected collectGarbage() {
    if (this.cache.size >= this.maxConcurrentInstances) {
      const removeCount = this.maxConcurrentInstances - this.cache.size;
      const deleteList = [];
      let count = 0;
      for (const [id, obj] of this.cache) {
        if (!obj.inUse) {
          deleteList.push(id);
          this.deallocator(obj.obj);
          count++;
        }
        if (count >= removeCount) {
          break;
        }
      }
      for (const id of deleteList) {
        this.cache.delete(id);
      }
    }
  }

  /**
   * Return the cached object back to the pool of unused objects. Subsequent calls to `allocate` might thus
   * return this object.
   *
   * @param id some key used for optimization. See `allocate`.
   * @param obj if the object has copy semantics, the value associated with `id` will be updated to `obj`. So,
   *            restores using `allocate` will return `obj`. This is not required when `T` has reference semantics,
   *            since the object was already implicitly updated.
   */
  free(id: K, obj?: V) {
    const el = this.cache.get(id);
    if (el === undefined) {
      return logger.unreachable("contract violation, cannot free unknown cache element", id);
    }

    this.releaser(obj || el.obj);

    if (obj !== undefined) {
      this.cache.set(id, { inUse: false, obj });
    } else {
      el.inUse = false;
    }
  }
}

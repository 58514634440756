import type { MapStateCoord } from "@/models/map-interaction/mapInteractionStateCtxTypes";
import Tooltip from "@/overlay/components/CommonParts/Tooltip";
import { Button } from "@/overlay/components/CommonParts/buttons";
import { LocationInput } from "@/overlay/components/PoiManagerWindow/LocationInput";
import { t } from "@lingui/macro";
import { type FunctionComponent, useState } from "react";
import styles from "./LocationInput/style.module.scss";

type PoiLocationInputProps = {
  onApply?: (location: MapStateCoord & { label?: string }) => void;
};

export const PoiLocationInput: FunctionComponent<PoiLocationInputProps> = ({ onApply }: PoiLocationInputProps) => {
  const [locations, setLocations] = useState<MapStateCoord & { label?: string }>();

  const displayValue = locations ? `${locations.lat}, ${locations.lon}` : "";

  function handleApply() {
    if (locations) {
      onApply?.(locations);
      setLocations(undefined);
    }
  }

  return (
    <div className={`editable-variable ${styles["poi-input"]}`}>
      <LocationInput defaultValue={displayValue} editLocation={setLocations} />
      <Tooltip direction={"left"} message={t`Add Point`} className={styles.successTooltip}>
        <Button icon="add_circle_outline" onClick={handleApply} disabled={!locations} />
      </Tooltip>
    </div>
  );
};

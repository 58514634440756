import type { MeteomaticsApi } from "../MeteomaticsApi";
import type { CoordinateSystem, GridRequest } from "../models";
import type { Float32Area } from "../request-splitters";
import { type Abortable, execute } from "./Abortable";
import { RequestAdaptor } from "./RequestAdaptor";

/**
 * Round area to the given precision. This might be desirable if reprojection inaccuracies move
 * an area out of bounds.
 *
 * Defaults to 6 decimal places, which is less than a meter in WGS84. However, note that
 * the rounding to precision is performed in the request CRS, not necessarily WGS84.
 *
 * The table here gives a nice overview of number of digits to meters for WGS84:
 * https://gis.stackexchange.com/questions/8650/measuring-accuracy-of-latitude-and-longitude
 */
export class Precision<C extends CoordinateSystem> extends RequestAdaptor<GridRequest<C>, Float32Area> {
  constructor(readonly precision: number = 6) {
    super();
  }

  enterStateWaiting(_: MeteomaticsApi<any>, gridRequest_: GridRequest<C>): Abortable<GridRequest<C>> {
    return execute({ ...gridRequest_, area: gridRequest_.area.roundToPrecision(this.precision) });
  }
}

import { FormField } from "@/overlay/components/form-field";
import { useEffect } from "react";
import { useValueNameCreator } from "../../SmartFormUtils/FormHooks/useFormInstructions";
import type { InputInstruction } from "../../SmartFormUtils/commonTypeUtils";

type InputProps = {
  label: string;
  instruction: InputInstruction<string | number | undefined>;
  values: Array<{ value: any; label: string | number }>;
};

export function SelectInput({ label, instruction, values }: InputProps) {
  const { formMethods, currValue } = instruction;
  const { createInputName } = useValueNameCreator(instruction);

  // Input need to listen and react to changes in the currValue when undo is clicked
  // Otherwise the form will be in an invalid state
  // This is fundamental issue, that might be fixed, by improving on undo feature
  // or by creating better, "undoabe" forms
  useEffect(() => {
    formMethods.setValue(createInputName(), currValue);
  }, [currValue, formMethods, createInputName]);

  return (
    <FormField label={label}>
      <select {...formMethods.register(createInputName(), { valueAsNumber: false, value: currValue })}>
        {values.map(({ value, label }, idx) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </select>
    </FormField>
  );
}

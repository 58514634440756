import type { AsyncResult } from "@/cache/AsyncResult";
// WARNING networkCaches must be imported before GridRequestKind !!!
// The file is ignored from linter
import { networkCaches } from "@/cache/GlobalCache";
import { GridRequestKind } from "@/cache/GeoJSONCache";
import { createRawGeoJson } from "@/geojson";
import type { CheckedProps, LayerBase, PropsChecker } from "@/layers";
import type { ScenePublicApi } from "@/layers/Compositor";
import { generateGridLayerCacheId } from "@/layers/geojson/LayerUtils";
import { MapboxGeoJSONLayer } from "@/layers/geojson/MapboxGeoJSONLayer";
import { SENTRY_TRANSACTION_NAMES } from "@/sentry/TransactionConst";
import { captureTransaction } from "@/sentry/helpers";
import {
  applyRoundingFilter, defaultStationAndGridRounding,
} from "@/utility/geojsonValueRounding";
import { safeFmt } from "@/utility/safeFmt";
import { getModelBoundingBox } from "@/weather-parameters/lookup";
import {
  type CoordinateSystem,
  type GeoJSONFeatureCollection,
  type GridRequest,
  GridSamplingStrategyKind,
  type JSONResponseBody,
} from "@mm/api.meteomatics.com";
import type { GridLayer, GuiTimeZone } from "@mm/metx-workbench.meteomatics.com";
import type { DateTime } from "luxon";

/**
 * A wrapper function to apply a rounding filter for RawGeoJson
 * @param parameterUnit
 * @returns A callback for GeoJSONCache.
 */
export function createGeoJSONFormatter(parameterUnit: string) {
  return (jsonResponse: JSONResponseBody) => {
    const data = createRawGeoJson(jsonResponse);
    const newFeatures = data.features.map((feature) => {
      if (feature.properties) {
        const newProperties = feature.properties;
        const value = feature.properties.value;
        newProperties.value = applyRoundingFilter(parameterUnit, value, defaultStationAndGridRounding);
        return { ...feature, properties: { ...newProperties } };
      }
      return feature;
    });
    return { ...data, features: newFeatures };
  };
}

export class GridLayerImpl extends MapboxGeoJSONLayer<GridLayer> {
  constructor(id: number, props: GridLayer, scene: ScenePublicApi, timezone: GuiTimeZone) {
    super(id, props, scene, timezone);

    this.updateMapboxLayoutProperties({
      visibility: this.props.show ? "visible" : "none",
      "text-field": ["get", "value"],
      "text-size": this.props.text_size,
      "text-allow-overlap": true,
      "icon-allow-overlap": true,
      "icon-ignore-placement": true,
    });
    this.updateMapboxPaintProperties({
      "text-color": this.props.text_color,
      "text-opacity": this.props.opacity,
    });
  }

  checker(): PropsChecker<GridLayer, LayerBase<GridLayer>> {
    type CheckerProps = CheckedProps<GridLayer>;
    const checkLayerProp = (property: CheckerProps) => (prev: any, curr: any) => {
      const changed = prev[property] !== curr[property];
      if (changed) {
        this.setLayerProps({ [property]: curr[property] });
        switch (property) {
          case "model":
            this.beforeRender();
            break;
          case "calibrated":
            this.beforeRender();
            break;
          case "step":
            this.beforeRender();
            break;
          case "show":
            this.updateMapboxLayoutProperties({ visibility: curr.show ? "visible" : "none" });
            break;
          case "opacity":
            this.updateMapboxPaintProperties({
              "text-opacity": curr[property],
            });
            break;
          case "parameter_unit":
            this.beforeRender();
            break;
          case "ens_select":
            this.beforeRender();
            break;
          case "text_size":
            this.updateMapboxLayoutProperties({
              "text-size": curr[property],
            });
            break;
          case "text_color":
            this.updateMapboxPaintProperties({
              "text-color": curr[property],
            });
            break;
          case "custom_options":
            break;
          default: {
            const _exhaustive: never = property;
            return _exhaustive;
          }
        }
      }
      return changed;
    };
    return {
      model: checkLayerProp("model"),
      calibrated: checkLayerProp("calibrated"),
      opacity: checkLayerProp("opacity"),
      parameter_unit: checkLayerProp("parameter_unit"),
      show: checkLayerProp("show"),
      step: checkLayerProp("step"),
      text_size: checkLayerProp("text_size"),
      text_color: checkLayerProp("text_color"),
      ens_select: checkLayerProp("ens_select"),
      custom_options: checkLayerProp("custom_options"),
    };
  }

  humanReadableId(): string {
    return safeFmt`metx.gridlayer#${this.uid}#${this.props.model}.${this.props.parameter_unit}`;
  }

  createRequest(dateTimeWithOffset: DateTime): GridRequest<CoordinateSystem.WGS84> {
    const { area, width, height } = this.computeBoundingBox(this.props.step);
    return {
      sampling: { kind: GridSamplingStrategyKind.Area },
      area: area,
      parameters: [this.props.parameter_unit],
      model: this.props.model,
      ensSelect: this.props.ens_select ? this.props.ens_select : "",
      boundingBoxLimit: getModelBoundingBox(this.props.model),
      datetime: dateTimeWithOffset,
      height: height,
      width: width,
      calibrated: this.props.calibrated,
    };
  }

  beforeRender(): void {
    if (this.scene.isAnimation()) {
      this.updateTrigger();
    } else {
      this.debouncedUpdateTrigger();
    }
  }

  updateTrigger(): void {
    const dateTimeWithOffset = this.scene.getDisplayTimeWithOffset();
    const request = this.createRequest(dateTimeWithOffset);

    const result = networkCaches.geojson_cache.retrieveGrid(
      request,
      undefined,
      createGeoJSONFormatter(this.props.parameter_unit),
    );
    this.updateSource(result, dateTimeWithOffset);
  }

  fetchData(timeFrame: DateTime): Promise<any> {
    const dateTimeWithOffset = this.scene.getDateTimeWithOffset(timeFrame);
    const request = this.createRequest(dateTimeWithOffset);
    const tags = {
      layerKind: `${this.props.kind ? this.props.kind : "undefined"}`,
      parameter: `${this.props.parameter_unit}`,
      requestedDate: `${dateTimeWithOffset}`,
    };

    const { asynchronous } = networkCaches.geojson_cache.retrieveGrid(
      request,
      undefined,
      createGeoJSONFormatter(this.props.parameter_unit),
    );

    if (!this.scene.isAnimation()) {
      return captureTransaction(asynchronous, SENTRY_TRANSACTION_NAMES.GRID_LAYER_LOADING_SPEED, tags);
    }

    return asynchronous
      .then(() => {
        return Promise.resolve();
      })
      .catch((err) => {
        return Promise.resolve();
      });
  }

  getActiveWeatherParametersAsString() {
    return [
      {
        model: this.props.model,
        parameter: this.props.parameter_unit,
      },
    ];
  }

  peekLayerCache(request: GridRequest<CoordinateSystem.WGS84>): AsyncResult<GeoJSONFeatureCollection> {
    const cacheId = generateGridLayerCacheId(request, GridRequestKind.Raw);
    return networkCaches.geojson_cache.peekCache(cacheId);
  }
}

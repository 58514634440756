import { InputFormField } from "@/overlay/components/form-field";
import { batchGroupBy } from "@/reducer/batchGroupBy";
import { t } from "@lingui/macro";
import type { BarbsLayer, GridLayer, MapId, SymbolLayer } from "@mm/metx-workbench.meteomatics.com";

export function StepConfigInput(props: {
  id?: MapId;
  layer: GridLayer | SymbolLayer | BarbsLayer;
  stepRange?: { min: number; max: number; step: number };
  change: (propsValue: Partial<GridLayer | SymbolLayer | BarbsLayer>) => void;
}) {
  // Slider uses minus values because reversed value meaning and that the slider looks the same as the others.
  // On the change it returns to positive values
  const minusMultiplier = -1;
  return (
    <InputFormField
      label={t`Resolution`}
      type="range"
      onChange={(event) => props.change({ step: minusMultiplier * Number(event.target.value) })}
      max={props.stepRange ? minusMultiplier * props.stepRange.min : minusMultiplier * 100}
      min={props.stepRange ? minusMultiplier * props.stepRange.max : 0}
      step={props.stepRange ? props.stepRange.step : undefined}
      value={minusMultiplier * Number(props.layer.step)}
      onMouseDown={() => batchGroupBy.start()}
      onMouseUp={() => batchGroupBy.end()}
    />
  );
}

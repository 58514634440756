import type { GridRequestKind, IsoLinesRequestKind, PoiParameterRequestKind } from "@/cache/GeoJSONCache";
import type { WindMetaData } from "@/layers/wind/types";
import type { IconData } from "@/reducer/client-models";
import {
  type CoordinateSystem,
  type GridRequest,
  type IsoLinesRequest,
  type LightningListRequest,
  MeteomaticsApiUrl,
  type PointRequest,
  type WfsRequest,
  type WindVectorRequest,
} from "@mm/api.meteomatics.com";
import type { WeatherFrontsRequest } from "@mm/api.meteomatics.com/lib/models/WeatherFrontsRequest";
import { DateTime } from "luxon";

export const generateGridLayerCacheId = (request: GridRequest<any>, kind: GridRequestKind): string => {
  const url = MeteomaticsApiUrl.forGrid({ format: "json" }, request);
  return `grid${kind}${url.toString()}`;
};

//TODO:
export const generateWindVectorLayerCacheId = (
  request: WindVectorRequest<CoordinateSystem.WGS84>,
  windMetaData: WindMetaData,
): string => {
  const { uMin, uMax, vMin, vMax } = windMetaData;
  const url = MeteomaticsApiUrl.forWindVector({ format: "json" }, request);
  return `wind_vector_${uMin}_${uMax}_${vMin}_${vMax}_${url.toString()}`;
};

export const generateIsoLinesLayerCacheId = (request: IsoLinesRequest, kind: IsoLinesRequestKind): string => {
  const url = MeteomaticsApiUrl.forIsoLines(request);
  return url.toString() + kind;
};

export const generateLightningListLayerCacheId = (request: LightningListRequest<any>): string => {
  const url = MeteomaticsApiUrl.forLightningList(request);
  // Round `request.datetime` to the nearest minute
  const roundedDatetime = request.datetime.plus(request.timeDuration).startOf("minute");
  // Check if `roundedDatetime` is within the last 10 minutes
  const tenMinutesAgo = DateTime.now().minus({ minutes: 10 });
  const isRecent = roundedDatetime >= tenMinutesAgo;
  // Generate the cache ID, adding the rounded timestamp for recent requests
  return isRecent
    ? `${url.toString()}&_${roundedDatetime.toISO()}` // Cache ID with rounded timestamp
    : `${url.toString()}`; // Alternative ID for older requests
};
export const generateWeatherFrontsLayerCacheId = (request: WeatherFrontsRequest): string => {
  const url = MeteomaticsApiUrl.forWeatherFronts(request);
  return url.toString();
};

export const generateWfsCacheId = (request: WfsRequest<any>): string => {
  const url = MeteomaticsApiUrl.forWfs(request);
  return `wfs${url.toString()}`;
};

export const generatePOILayerCacheId = (
  request: PointRequest<any>,
  kind: PoiParameterRequestKind,
  item: IconData,
): string => {
  const url = MeteomaticsApiUrl.forPointAndTime({ format: "json" }, request);
  // add IconData info that it reloads on changes
  const iconOptions = [item.text, item.icon, item.color, item.size];
  return `multiPoints${kind}${url.toString()}${iconOptions.join("-")}`;
};

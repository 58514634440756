import type { LocationTablePoint } from "@/grid/GridMultiLocationTable/hooks/utils";
import type { JsonResponseDataPoints } from "@/json";
import type { NestedRowData } from "@/overlay/components/CommonParts/tables/nestedTable/utils";

export const formatPointResponseToTable = (
  data: JsonResponseDataPoints[],
  locations: LocationTablePoint[],
): NestedRowData[] => {
  if (!data) {
    return [];
  }

  const rows: NestedRowData[] = data.map((coordinateData, index) => {
    const { lat, lon, dates } = coordinateData;

    const locationLabel = locations[index]?.label;

    const rowData: NestedRowData = {
      id: locationLabel || `${lat}, ${lon}`,
      parentId: null,
      children: [],
    };

    for (const { date, value } of dates) {
      rowData[date] = value;
    }
    return rowData;
  });

  return rows;
};

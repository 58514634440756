import { SynchrounousState } from "@/cache/AsyncResult";
import type { LngLatBounds } from "mapbox-gl";
import type { WindLayerProperties } from "../../layer";
import { drawTexture } from "../mixins";
import { createProjectionPlaneProgram, createTextureProgram } from "../shaders";
import {
  bindFramebuffer,
  bindPlaneBuffers,
  bindTexture,
  createBuffer,
  createEmptyPixels,
  createPlane,
  createTexture,
} from "../util";

// Higher = higher polygon count = smoother plane, but more processing time per frame
const VERTICES_COUNT = 40;

export function createProjectionPlaneRenderer(gl: WebGLRenderingContext, width: number, height: number) {
  const _projectionPlaneProgram = createProjectionPlaneProgram(gl);
  const _textureProgram = createTextureProgram(gl);
  const _frameBuffer = gl.createFramebuffer();
  const _quadBuffer = createBuffer(gl, new Float32Array([0, 0, 1, 0, 0, 1, 0, 1, 1, 0, 1, 1]));

  const _projectionPlaneTexture = createTexture(gl, gl.LINEAR, createEmptyPixels(width, height), width, height);
  let _planeBuffers: ReturnType<typeof bindPlaneBuffers>;

  const updatePlane = (bounds: LngLatBounds) => {
    const { vertices, indices, uvs, latLngAttributes } = createPlane(1, 1, VERTICES_COUNT, VERTICES_COUNT, bounds);

    _planeBuffers = bindPlaneBuffers(gl, vertices, indices, uvs, latLngAttributes);
  };

  const prerender = (
    projectionMatrix: number[],
    globeToMercMatrix: number[],
    transition: number,
    centerInMercator: [number, number],
    pixelsPerMeterRatio: number,

    projectedTexture: WebGLTexture | null,
  ) => {
    if (!_planeBuffers) return;

    const { synchronous } = _projectionPlaneProgram;

    if (
      synchronous === SynchrounousState.StillPending ||
      synchronous === SynchrounousState.PermanentlyFailed ||
      synchronous === SynchrounousState.NotRequested
    ) {
      return;
    }

    const { program, parameters } = synchronous;

    gl.useProgram(program);

    bindFramebuffer(gl, _frameBuffer, _projectionPlaneTexture);
    gl.viewport(0, 0, width, height);

    gl.enable(gl.BLEND);
    gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
    // gl.pixelStorei(gl.UNPACK_FLIP_Y_WEBGL, true);
    gl.clear(gl.COLOR_BUFFER_BIT);

    // LatLng attribute
    gl.bindBuffer(gl.ARRAY_BUFFER, _planeBuffers.latLngBuffer);
    gl.vertexAttribPointer(Number(parameters.a_latLng), 2, gl.FLOAT, false, 0, 0);
    gl.enableVertexAttribArray(Number(parameters.a_latLng));

    // Position attribute
    gl.bindBuffer(gl.ARRAY_BUFFER, _planeBuffers.vertexBuffer);
    gl.vertexAttribPointer(Number(parameters.a_position), 3, gl.FLOAT, false, 0, 0);
    gl.enableVertexAttribArray(Number(parameters.a_position));

    // UV attribute
    gl.bindBuffer(gl.ARRAY_BUFFER, _planeBuffers.uvBuffer);
    gl.vertexAttribPointer(Number(parameters.a_uv), 2, gl.FLOAT, false, 0, 0);
    gl.enableVertexAttribArray(Number(parameters.a_uv));

    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, _planeBuffers.indexBuffer);

    gl.uniformMatrix4fv(parameters.u_projection, false, projectionMatrix);
    gl.uniformMatrix4fv(parameters.u_globeToMercMatrix, false, globeToMercMatrix);
    gl.uniform1f(parameters.u_globeToMercatorTransition, transition);
    gl.uniform2f(parameters.u_centerInMerc, centerInMercator[0], centerInMercator[1]);
    gl.uniform1f(parameters.u_pixelsPerMeterRatio, pixelsPerMeterRatio);

    bindTexture(gl, projectedTexture, 1);
    gl.uniform1i(parameters.u_screen, 1);

    gl.drawElements(gl.TRIANGLES, _planeBuffers.indexCount, gl.UNSIGNED_SHORT, 0);
    gl.bindRenderbuffer(gl.RENDERBUFFER, null);
  };

  const render = (props: WindLayerProperties) => {
    gl.enable(gl.BLEND);
    gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
    drawTexture(gl, _projectionPlaneTexture, props.opacity, _quadBuffer, _textureProgram);
    gl.disable(gl.BLEND);
  };

  const clear = () => {
    bindFramebuffer(gl, _frameBuffer, _projectionPlaneTexture);
    gl.clear(gl.COLOR_BUFFER_BIT);
    gl.bindRenderbuffer(gl.RENDERBUFFER, null);
  };

  return {
    updatePlane,
    prerender,
    render,
    clear,
  };
}

export type ProjectionPlaneRenderer = ReturnType<typeof createProjectionPlaneRenderer>;

export enum QueryKey {
  USER = "USER",
  ACCOUNT = "ACCOUNT",
  PROFILE = "PROFILE",
  PROFILES = "PROFILES",
  PROFILE_INFO = "PROFILE_INFO",
  TAB = "TAB",
  LAYER = "LAYER",
  CUSTOM_GEOJSON = "CUSTOM_GEOJSON",
  MULTI_LOCATION_TABLE = "MULTI_LOCATION_TABLE",
}

import { FormField } from "@/overlay/components/form-field";
import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { BUILD_INFO } from "build-info";
import { dynamicActivate } from ".";

export function LocalePicker() {
  const { i18n } = useLingui();
  const currentLocale = i18n.locale;

  // this implicit update is not lost since lingui forces a rerender after a language change
  function changeLocale(event: React.ChangeEvent<HTMLSelectElement>) {
    const newLanguageTag = event.target.value;
    // TODO: downloading the localization may take some time. Should indicate via a loading indicator that something is happening.
    dynamicActivate(newLanguageTag);
  }

  return (
    <FormField label={t`Locale`}>
      <select data-testid="locale-picker-select" value={currentLocale} onChange={changeLocale}>
        {BUILD_INFO.locales.map((locale) => (
          <option key={locale.languageTag} value={locale.languageTag}>
            {locale.languageNativeName}({locale.territoryNativeName})
          </option>
        ))}
      </select>
    </FormField>
  );
}

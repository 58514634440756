import type { MeteomaticsApi } from "../MeteomaticsApi";
import { type Abortable, execute } from "./Abortable";

/**
 * A request adaptor may modify or cancel a request at several times during
 * a network request.
 *
 * A request goes through a simple state machine after you call any method
 * on the `MeteomaticsApi` class.
 *
 * ```
 * ┌─────────────┐     ┌───────────────┐     ┌───────────────┐
 * │             │     │               │     │               │
 * │   Waiting   ├─────►   In Flight   ├─────►   Finished    │
 * │             │     │               │     │               │
 * └─────────────┘     └───────────────┘     └───────────────┘
 * ```
 *
 * The waiting time may be influenced by previous time measurements. See `Timer` and
 * `PerformanceRecorder` for details on the measurement process. The `connectionManagement`
 * strategy decides a requests priority/position within the network queue based
 * on these previous measurements.
 *
 * Timing measurments are started when a request transitions to `In Flight`
 * and are canceled when the request transitions to the `Finished` state.
 */
export interface IRequestAdaptor<Req, Res> {
  /**
   * Allows the request adaptor to rewrite or abort a request before scheduling.
   *
   * A scheduling algorithm will distribute available resources based
   * on the request, so applying signficiant changes to a requests area
   * should be applied before scheduling.
   *
   * @param api
   * @param payload
   */
  enterStateWaiting(api: MeteomaticsApi<any>, payload: Req): Abortable<Req>;

  /**
   * A set of callbacks invoked immediately before the roundtrip time of a grid request
   * is estimated and put into the network waiting queue.
   */
  // TODO: there is no harm in allowing a rewriting of requests when
  // transitioning into `InFlight`. We just have to adapt the `workload`
  // of the timer.
  // Its currently not allowed since I am not sure how we can ensure that
  // parallelized requests are resized correctly.
  enterStateInFlight(api: MeteomaticsApi<any>, payload: Req): Abortable<void>;

  /**
   * Modify the response of a request.
   *
   * This is mostly useful if you want to undo a transformation that you
   * applied in `enterStateWaiting`.
   *
   * This is only called if the request was successful.
   */
  enterStateFinished(api: MeteomaticsApi<any>, response: Res): Res;
}

export class RequestAdaptor<Req, Res> implements IRequestAdaptor<Req, Res> {
  enterStateWaiting(_: MeteomaticsApi<any>, payload: Req): Abortable<Req> {
    return execute(payload);
  }

  enterStateInFlight(api: MeteomaticsApi<any>, payload: Req): Abortable<void> {
    return execute();
  }

  enterStateFinished(_: MeteomaticsApi<any>, response: Res): Res {
    return response;
  }
}

import { mod } from "@/utility/mod";
import type { GridDimension } from "@mm/api.meteomatics.com";
import { TileGeometry } from "./TileGeometry";

// export interface TileVolume {
//   topLeft: TileGeometry;
//   bottomRight: TileGeometry;
// }

/**
 * A rectangular area of the current map viewport.
 * The rectangle is represented by the top left and bottom right corners using XYZ tile coordinates.
 *
 * The lower and upper bound of the intervals represented by this object are inclusive: `[xyMin[i],xyMax[i]]`, not only `[xyMin[i],xyMax[i])`.
 */
// TODO: this could in theory use the `Area` class by adding a `SpericalMercator` and `TileIdx` coordinate system. But especially
// the latter one is problematic since it has a corser resolution than the other representations that use a float domain. So the
// `TileIdx` coordiante system would have to represent fractional values, e.g. `x=1.222,y=2.222,z=2.22` for a lat-lng coordinate within
// a tile
export interface TileArea {
  xyMin: [number, number];
  xyMax: [number, number];
  z: number;
  zLodBias: number;
}

export type TileGeometryGenerator = Generator<TileGeometry, void, unknown>;
/**
 * Iterate through the tiles of a tiled area in row-major order.
 */
export function* tileGeometries(...areas: TileArea[]): TileGeometryGenerator {
  for (const area_ of areas) {
    const area = area_.zLodBias === 0 ? area_ : applyZLodBias(area_);

    for (let y = area.xyMin[1]; y <= area.xyMax[1]; y++) {
      for (let x = area.xyMin[0]; x <= area.xyMax[0]; x++) {
        yield new TileGeometry([x, y, area.z]);
      }
    }
  }
}

/**
 * Instantiate a tile along the x-axis / longitude
 *
 * @param area_
 * @param worldZeroGeometry
 * @returns
 */
export function instantiateTiles(area_: TileArea, worldZeroGeometry: TileGeometry) {
  const geometries: TileGeometry[] = [];

  const area = area_.zLodBias === 0 ? area_ : applyZLodBias(area_);
  const z = Math.max(area.z, 0); // ignore denormalization
  const tilesPerAxis = 1 << z;

  const minWorld = Math.floor(area.xyMin[0] / tilesPerAxis);
  const maxWorld = Math.ceil(area.xyMax[0] / tilesPerAxis);

  for (let w = minWorld; w <= maxWorld; w++) {
    const newX = worldZeroGeometry.x + w * tilesPerAxis;
    if (newX <= area.xyMax[0] && newX >= area.xyMin[0]) {
      geometries.push(new TileGeometry([newX, worldZeroGeometry.y, worldZeroGeometry.z]));
    }
  }

  return geometries;
}

/**
 *
 * Note that this is less precise than `instantiateTiles`, the number of world copies may vary on
 * a per tile basis (since worlds may only be partially visible).
 *
 * @param area_
 * @param worldZeroGeometry
 * @returns
 */
export function* instantiateWorldIndices(area_: TileArea, worldZeroGeometry: TileGeometry) {
  const geometries: TileGeometry[] = [];

  const area = area_.zLodBias === 0 ? area_ : applyZLodBias(area_);
  const z = Math.max(area.z, 0); // ignore denormalization
  const tilesPerAxis = 1 << z;

  const minWorld = Math.floor(area.xyMin[0] / tilesPerAxis);
  const maxWorld = Math.ceil(area.xyMax[0] / tilesPerAxis);

  for (let w = minWorld; w < maxWorld; w++) {
    yield w;
  }

  return geometries;
}

/**
 * Warp into world zero. The resulting areas will contain the same tiles
 * with the minimal number of tile areas.
 *
 * This might return two tile areas. If a wrap around is within the area, you
 * receive a 'west part of the globe' and a 'east part of the globe.
 *
 * We don't replicate worlds around the y coordinate, otherwise you would have
 * to expect up to 4 tile areas per input tile area.
 */
// TODO: we could restore z-lod bias before returning
export function wrapIntoWorldZero(area_: TileArea): TileArea[] {
  // in essence, this is a switch over all cases of the triple (was xMin wrapped around?, was xMax wrapped around?, is xMaxWrapped > xMinWrapped?)

  const area = area_.zLodBias === 0 ? area_ : applyZLodBias(area_);
  const z = Math.max(area.z, 0); // ignore denormalization
  const tilesPerAxis = 1 << z;

  const xMinWrapped = mod(area.xyMin[0], tilesPerAxis);
  const xMaxWrapped = mod(area.xyMax[0], tilesPerAxis);

  const tilesAlongX = area.xyMax[0] - area.xyMin[0];
  const worldsSpanned = tilesAlongX / tilesPerAxis;

  const completelyInWorldZero = xMinWrapped === area.xyMin[0] && xMaxWrapped === area.xyMax[0];

  if (completelyInWorldZero) {
    return [area];
  }

  // if tile area is wide enough to span more than a world -- completely independent of the tile
  // area's wrap around -- all tiles have to be used along the width
  //
  // (this is an optimization, without this case, the split areas would overlap)
  //
  // this is equivalent to the test `xMinWrapped < xMaxWrapped && atLeastOneWasWrapped` with
  // `atLeastOneWasWrapped = xMinWrapped !== area.xyMin[0] || xMaxWrapped !== area.xyMax[0]`
  // However, since `xMinWrapped > xMaxWrapped` with `atLeastOneWasWrapped === false` is only
  // possible with invalid input and since `atLeastOneWasWrapped === false` is already
  // covered by `completelyInWorldZero`, the `atLeastOneWasWrapped` could be omitted.
  if (worldsSpanned >= 1) {
    return [
      {
        xyMin: [0, area.xyMin[1]],
        xyMax: [tilesPerAxis - 1, area.xyMax[1]],
        z: area.z,
        zLodBias: area.zLodBias,
      },
    ];
  }

  // all remaining cases have xMinWrapped > xMaxWrapped since exactly one of both was
  // outside of world zero
  //if (xMinWrapped > xMaxWrapped) {
  return [
    {
      xyMin: [0, area.xyMin[1]],
      xyMax: [xMaxWrapped, area.xyMax[1]],
      z,
      zLodBias: 0,
    },
    {
      xyMin: [xMinWrapped, area.xyMin[1]],
      xyMax: [tilesPerAxis - 1, area.xyMax[1]],
      z,
      zLodBias: 0,
    },
  ];
}

/**
 * Test whether a tile or tile area is denormalized. Denormalization means
 * that the tile has a negative zoom level. A negative zoom level has the
 * same extents as the zero zoom level (the whole model bounds), but the
 * tile size is halved with each iteration.
 */
export function isDenormalized({ z }: { z: number }): boolean {
  return z < 0;
}

export function getBiasedZoomLevel(area: { z: number; zLodBias: number }): number {
  return area.z - area.zLodBias;
}

/**
 *
 * @param tile
 * @param tileSize
 *
 */
export function getTileSize(z: number, tileSize: number) {
  if (isDenormalized({ z })) {
    return Math.max(tileSize >> -z, 2); // Note: minimal tilesize is 2x2 because of api restrictions
  }
  return tileSize;
}

/**
 * Given a reference tile size, get the minimal zoom level that could result from denormalization of the tile pyramid.
 */
export function getMinZoomLevel(tileSize: number) {
  // Note: the `+1` accounts for the fact that 1x1 is not a valid tile size, the minimal tilesize is 2x2 because of api restrictions
  return -Math.log2(tileSize) + 1;
}

/**
 * Apply the z lod bias of the area. So this will recompute tile indices and set `zLodBias` to zero.
 *
 * Beware that this might increase the area projected by the tiles. For example: an area consisting out of 1x3 tiles cannot be biased to
 * a coarser level in the zoom pyramid without enlarging the area to 1x2 tiles, which corresponds to 2x4 tiles on the finer level.
 *
 * @param area
 * @returns
 */
export function applyZLodBias(area: TileArea): TileArea {
  // given a biased tile, how many original tiles are within it?
  const countChildrenPerTile = 1 << area.zLodBias;
  return {
    xyMin: [Math.floor(area.xyMin[0] / countChildrenPerTile), Math.floor(area.xyMin[1] / countChildrenPerTile)],
    xyMax: [Math.ceil(area.xyMax[0] / countChildrenPerTile), Math.ceil(area.xyMax[1] / countChildrenPerTile)],
    z: getBiasedZoomLevel(area),
    zLodBias: 0,
  };
}

export function containsTileGeometry(area_: TileArea, tile: TileGeometry): boolean {
  const area = area_.zLodBias === 0 ? area_ : applyZLodBias(area_);

  return (
    area.z === tile.z &&
    area.xyMin[0] <= tile.x &&
    area.xyMax[0] >= tile.x &&
    area.xyMin[1] <= tile.y &&
    area.xyMax[1] >= tile.y
  );
}

/**
 * Get a unique identifying string for a tile area
 *
 * @param shouldWrapIntoWorldZero wheather the tile area should be normalized to world zero. Enable this, if you only care about the tiles
 * within the area, but not about the positions or number of instantiations of each tile.
 */
export function tileAreaFingerprint(area_: TileArea, shouldWrapIntoWorldZero: boolean): string {
  const area = shouldWrapIntoWorldZero ? wrapIntoWorldZero(area_) : [area_];
  return area.map((area) => [...area.xyMax, ...area.xyMin, area.z, area.zLodBias].join(";")).join("+");
}

/**
 * Returns the size of the aera in pixels
 */
export function tileAreaInPixels(area_: TileArea, tileSize_: number): GridDimension {
  const area = applyZLodBias(area_);
  const tileSize = getTileSize(area.z, tileSize_);
  return {
    width: (area.xyMax[0] - area.xyMin[0]) * tileSize,
    height: (area.xyMax[1] - area.xyMin[1]) * tileSize,
  };
}

/**
 * Returns the size of the area in pixels
 */
export function biasGrid(grid: GridDimension, zLodBias: number): GridDimension {
  const scaleFactor = 1 / (1 << zLodBias);

  // TODO: Spend some time thinking about rounding:
  // So this has to adapt arbitrarily sized regions to a integral division, I suspect ceiling is -- in contrast to flooring -- always safe.
  // Imagine flooring a bias of 5, you would potentially miss a strip of 2**5 -1 = 31 pixels on the borders of the image after rendering.
  // In this sense: Ceiling establishes helper lanes for interpolation in analog to the usual helper lanes in gpu quads.
  return {
    width: Math.ceil(grid.width * scaleFactor),
    height: Math.ceil(grid.height * scaleFactor),
  };
}

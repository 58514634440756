import type { AsyncResult } from "@/cache/AsyncResult";
import { GridRequestKind } from "@/cache/GeoJSONCache";
import { networkCaches } from "@/cache/GlobalCache";
import { symbolLayerConfigWeatherSymbol } from "@/constants/layerConfigAttributes";
import type { CheckedProps, LayerBase, PropsChecker } from "@/layers";
import type { ScenePublicApi } from "@/layers/Compositor";
import { generateGridLayerCacheId } from "@/layers/geojson/LayerUtils";
import { MapboxGeoJSONLayer } from "@/layers/geojson/MapboxGeoJSONLayer";
import { getMapboxIconSize } from "@/layers/utility/iconSize";
import { SENTRY_TRANSACTION_NAMES } from "@/sentry/TransactionConst";
import { captureTransaction } from "@/sentry/helpers";
import { safeFmt } from "@/utility/safeFmt";
import { searchEnginesWeatherParams } from "@/weather-parameters";
import { getModelBoundingBox } from "@/weather-parameters/lookup";
import {
  type CoordinateSystem,
  type GeoJSONFeatureCollection,
  type GridRequest,
  GridSamplingStrategyKind,
} from "@mm/api.meteomatics.com";
import type { GuiTimeZone, SymbolLayer } from "@mm/metx-workbench.meteomatics.com";
import Logger from "logging";
import type { DateTime } from "luxon";
import type { Expression } from "mapbox-gl";
import { parameterToString } from "weather-parameter-utils";

const logger = Logger.fromFilename(__filename);

export const weatherCodeParameterName = "weather_code";
export const wwCodeCloudName = "total_cloud_cover_mean_";
export const DefaultWwCodeCloudParameter = "total_cloud_cover_mean_1h:octas";
export const DefaultSymbolIconOpacity = 1;

export class SymbolLayerImpl extends MapboxGeoJSONLayer<SymbolLayer> {
  // desired icon size in hardware pixels based on our requirements
  private static DESIRED_SIZE_WW_CODE_PX = 50;
  private static DESIRED_SIZE_SYMBOL_PX = 100;
  constructor(id: number, props: SymbolLayer, scene: ScenePublicApi, timezone: GuiTimeZone) {
    super(id, props, scene, timezone);

    this.updateMapboxLayoutProperties({
      visibility: this.props.show ? "visible" : "none",
      "icon-image": ["get", "weather-icon"],
      "icon-size": this.getIconSize(),
      "icon-allow-overlap": true,
      "icon-ignore-placement": true,
    });
    this.updateMapboxPaintProperties({
      "icon-color": ["get", "weather-icon-color"],
      "icon-opacity": getIconOpacityExpression(
        this.props.custom_options.show_only_significant_weather
          ? this.props.custom_options?.show_only_significant_weather
          : null,
        // We disable opacity user input and always set the full opacity.
        DefaultSymbolIconOpacity,
      ),
    });
  }
  getIconSize(): number {
    const iconScaling = this.props.custom_options.icon_size ?? symbolLayerConfigWeatherSymbol.defaultIconSize;
    // Include devicePixelRatio in the calculation
    const desiredSizePx = this.props.parameter_unit.startsWith(weatherCodeParameterName)
      ? SymbolLayerImpl.DESIRED_SIZE_WW_CODE_PX
      : SymbolLayerImpl.DESIRED_SIZE_SYMBOL_PX;
    return getMapboxIconSize(iconScaling, desiredSizePx);
  }

  checker(): PropsChecker<SymbolLayer, LayerBase<SymbolLayer>> {
    type CheckerProps = CheckedProps<SymbolLayer>;
    const checkLayerProp = (property: CheckerProps) => (prev: any, curr: any) => {
      const changed = prev[property] !== curr[property];
      if (changed) {
        this.setLayerProps({ [property]: curr[property] });
        switch (property) {
          case "model":
            this.beforeRender();
            break;
          case "calibrated":
            break;
          case "parameter_unit":
            this.beforeRender();
            break;
          case "ens_select":
            this.beforeRender();
            break;
          case "layer_type":
            // can be ignored
            break;
          case "show":
            this.updateMapboxLayoutProperties({
              visibility: curr.show ? "visible" : "none",
            });
            break;
          case "opacity":
            // Use selected opacity is disabled
            break;
          case "step":
            this.beforeRender();
            break;
          case "custom_options":
            this.updateMapboxPaintProperties({
              "icon-opacity": getIconOpacityExpression(
                curr.custom_options.show_only_significant_weather
                  ? curr.custom_options.show_only_significant_weather
                  : null,
                DefaultSymbolIconOpacity,
              ),
            });
            this.updateMapboxLayoutProperties({
              "icon-size": this.getIconSize(),
            });
            break;
          default: {
            const _exhaustive: never = property;
            return _exhaustive;
          }
        }
      }
      return changed;
    };
    return {
      model: checkLayerProp("model"),
      calibrated: checkLayerProp("calibrated"),
      parameter_unit: checkLayerProp("parameter_unit"),
      layer_type: checkLayerProp("layer_type"),
      opacity: checkLayerProp("opacity"),
      show: checkLayerProp("show"),
      step: checkLayerProp("step"),
      ens_select: checkLayerProp("ens_select"),
      custom_options: checkLayerProp("custom_options"),
    };
  }

  humanReadableId(): string {
    return safeFmt`metx.symbollayer#${this.uid}#${this.props.model}.${this.props.parameter_unit}`;
  }

  createRequest(dateTimeWithOffset: DateTime): GridRequest<CoordinateSystem.WGS84> {
    const { area, width, height } = this.computeBoundingBox(this.props.step);
    const parameterList: [string, ...string[]] = [this.props.parameter_unit];
    // this checks on every request if it is a WW Code Layer and adds the extra cloud parameter
    if (this.props.parameter_unit.startsWith(weatherCodeParameterName)) {
      const cloudParameter = this.getWwCodeCloudCoverParameter(this.props.parameter_unit);
      parameterList.push(cloudParameter);
    }
    return {
      sampling: { kind: GridSamplingStrategyKind.Area },
      area: area,
      parameters: parameterList,
      model: this.props.model,
      ensSelect: this.props.ens_select ? this.props.ens_select : "",
      boundingBoxLimit: getModelBoundingBox(this.props.model),
      datetime: dateTimeWithOffset,
      height: height,
      width: width,
    };
  }

  beforeRender(): void {
    if (this.scene.isAnimation()) {
      this.updateTrigger();
    } else {
      this.debouncedUpdateTrigger();
    }
  }

  updateTrigger() {
    const dateTimeWithOffset = this.scene.getDisplayTimeWithOffset();
    const request = this.createRequest(dateTimeWithOffset);

    const result = networkCaches.geojson_cache.retrieveGrid(
      request,
      this.props.parameter_unit.startsWith(weatherCodeParameterName)
        ? GridRequestKind.WeatherCode
        : GridRequestKind.Symbol,
    );
    this.updateSource(result, dateTimeWithOffset);
  }

  fetchData(timeFrame: DateTime): Promise<void> {
    const dateTimeWithOffset = this.scene.getDateTimeWithOffset(timeFrame);
    const request = this.createRequest(dateTimeWithOffset);
    const tags = {
      layerKind: `${this.props.kind ? this.props.kind : "undefined"}`,
      parameter: `${this.props.parameter_unit}`,
      requestedDate: `${dateTimeWithOffset}`,
    };

    const { asynchronous } = networkCaches.geojson_cache.retrieveGrid(
      request,
      this.props.parameter_unit.startsWith(weatherCodeParameterName)
        ? GridRequestKind.WeatherCode
        : GridRequestKind.Symbol,
    );

    if (!this.scene.isAnimation()) {
      return captureTransaction(asynchronous, SENTRY_TRANSACTION_NAMES.SYMBOL_LAYER_LOADING_SPEED, tags);
    }

    return asynchronous
      .then(() => {
        return Promise.resolve();
      })
      .catch((err) => {
        return Promise.resolve();
      });
  }

  getActiveWeatherParametersAsString() {
    return [
      {
        model: this.props.model,
        parameter: this.props.parameter_unit,
      },
    ];
  }

  /**
   * look up inside search engine to find the (wwCodeCloudName) parameter with same interval as weather_code parameter
   *
   * @param parameterName weather_code parameter
   * @return cloudParameter with corresponding interval 1h, 3h, 6h, 12h, 24h
   */
  getWwCodeCloudCoverParameter(parameterName: string): string {
    const parameterWeatherCode = searchEnginesWeatherParams.standard.matchSomeParameterNameExactly(parameterName);
    let cloudParameter: any;
    if (
      parameterWeatherCode?.parameter.name === weatherCodeParameterName &&
      parameterWeatherCode?.narrowed_selection.fields.interval
    ) {
      cloudParameter = searchEnginesWeatherParams.standard.matchSomeParameterNameExactly(
        `${wwCodeCloudName + parameterWeatherCode.narrowed_selection.fields.interval}:octas`,
      );
    } else {
      // default parameter
      cloudParameter = searchEnginesWeatherParams.standard.matchSomeParameterNameExactly(DefaultWwCodeCloudParameter);
    }
    if (cloudParameter == null) {
      logger.error(`unknown parameter ${cloudParameter}`);
      return cloudParameter as never;
    }
    return parameterToString(cloudParameter).formatted;
  }

  peekLayerCache(request: GridRequest<CoordinateSystem.WGS84>): AsyncResult<GeoJSONFeatureCollection> {
    const kind = this.props.parameter_unit.startsWith(weatherCodeParameterName)
      ? GridRequestKind.WeatherCode
      : GridRequestKind.Symbol;
    const cacheId = generateGridLayerCacheId(request, kind);
    return networkCaches.geojson_cache.peekCache(cacheId);
  }

  isRebuffering(time: DateTime): boolean {
    return super.isRebuffering(time);
  }
}

function getIconOpacityExpression(showOnlySignificantWeather: boolean | null, opacity: number): Expression {
  return [
    "case",
    [
      "all",
      ["==", ["get", "weather-icon-type"], "non-significant-weather"],
      ["to-boolean", showOnlySignificantWeather],
    ],
    0,
    opacity,
  ];
}

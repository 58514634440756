import type Logger from "logging";

export interface DataAge {
  mostRecentlyUsed: number;
  leastRecentlyUsed: number;
}

export function ageOfLeaf(age: number): DataAge {
  return { mostRecentlyUsed: age, leastRecentlyUsed: age };
}

export interface LruEvictionResult {
  purgedSize: number;
  count: number;
}

export interface MultilevelLruEvictionResult extends LruEvictionResult {
  /** the range of the usage-timestamps after eviction. `null` if the subcache is empty. */
  newLimitLeastRecentlyUsed: number | null;
  canUnload: boolean;
}

/**
 * Evict cache lines from cache `cache` using the least recently used policy until
 * the cache size is reduced by `excessSize` measurement units.
 *
 * Note: when evicting data, consider increasing `excessSize` to a factor that removes
 * more values from the cache than the actual cache limit (make a distinction between
 * cache limit and the limit that triggers cache evictions), otherwise each alteration
 * to the cache will trigger an eviction pass.
 *
 * @param excessSize number of units to remove from the cache. a 'unit' may be anything from bytes
 *                   to the number of entries as long as the number is greater than zero.
 * @param cache an object containing cache lines with keys corresponding to cache tags and values corresponding to the contents of the cache entry.
 * @param lastUse closure to get the timestamp of the most recent access to the cache entry.
 *                `timestamp` may be measured in anything as long as timestamps are monolitically increasing (higher numbers MUST correspond to accesses that are temporally closer to now).
 *                You may return `null` if the tile should not be evicted. You can for example, use this to prevent pending requests to not be considered for eviction.
 * @param estimateCacheLineSize closure to get the size of a cache entry in the same unit as `excessSize`. MUST be greater than zero.
 */
type MultilevelLruEvictLeafParameters<T, T2 extends string | number | symbol> = [
  excessSize: number,
  cache: Record<T2, T>,
  lastUse: (_: T, tag: T2) => number | null,
  estimateCacheLineSize: (_: T, tag: T2) => number,
  unload: (value: T, tag: T2) => void,
  // additional arguments used for logging / debugging
  logger?: Logger,
  formatUnits?: (_: number) => string,
  maxLeastRecentlyUsed?: number,
];

export function lru_evict<T, T2 extends string | number | symbol>(
  ...params: MultilevelLruEvictLeafParameters<T, T2>
): LruEvictionResult {
  return mutlilevel_lru_evict_leaf(...params);
}

/**
 * Like `lru_evict`, but for hierarchical data structures.
 *
 * # How it works
 *
 * Each node is not only assigned a timestamp, but a inclusive timestamp range `[a,b]`. Leafs have singleton range with `a===b`.
 *
 * This is a depth first search. Children on the same height are visited in monolitically increasing order of their
 * least recently used timestamp. Each child should only evict the lower `[a,c<b]` members with `c` being the lower bound
 * of all children on the same tree height except for the currently examined child.
 *
 * The `evict` method is the visitor function that is called when the subtree of a child is entered. You will most likely want
 * to visit grandchildren, deallocate grandchildren if the are evicted, and do some bookkeeping to keep the `lastUse` timestamps
 * current.
 *
 * `multilevel_lru_evict` does NOT recurse automatically -- allowing you to implement custom abort strategies or iteration on inhomogenous
 * data structures. (Our hierarchical cache has different types on each level and, thus, needs this feature.)
 * To recurse, call `multilevel_lru_evict` in `evict` with `maxLeastRecentlyUsed` forwarded.
 *
 * Note: limits for `lastUse` are NOT automatically updated. If necessary, update limits within `evict`.
 *
 * @param excessSize
 * @param cache
 * @param lastUse
 * @param evict function responsible for driving the eviction.
 * @param logger
 * @param formatUnits
 * @param maxLeastRecentlyUsed
 */
export function multilevel_lru_evict<T, T2 extends string | number | symbol>(
  excessSize: number,
  cache: Record<T2, T>,
  lastUse: (_: T, tag: T2) => DataAge | null,
  evict: (_: T, tag: T2, excessSize: number, maxLeastRecentlyUsed: number) => MultilevelLruEvictionResult,
  //unload: (value: T, tag: string) => void,
  // additional arguments used for logging / debugging
  logger?: Logger,
  formatUnits: (_: number) => string = (v) => `${v}`,
  maxLeastRecentlyUsed: number = Number.POSITIVE_INFINITY,
): MultilevelLruEvictionResult {
  // TODO: reallocating and sorting here is rather expensive
  let lines = [];

  // true if the cache has content that is excempted from the eviction. This information
  // is necessary to differentiate between a truely empty cache and a cache that is only
  // empty from the viewpoint of the eviction algorithm.
  let hasShadowContent = false;

  for (const tag in cache) {
    if (Object.hasOwn(cache, tag)) {
      const cacheLine = cache[tag];
      const lastUsed = lastUse(cacheLine, tag);

      if (lastUsed != null) {
        lines.push({ tag: tag, lastUsed });
      } else {
        // content cannot be evicted, e.g. do not enqueue and evict inflight tile requests
        hasShadowContent = true;
      }
    }
  }

  // sort ascending by age: smallest request timestamp gets the highest array index
  lines = lines.sort((a, b) => b.lastUsed.leastRecentlyUsed - a.lastUsed.leastRecentlyUsed);

  let purgedSize = 0;
  let levelCount = 0;
  let leafCount = 0;

  let newLimitLeastRecentlyUsed: number | null = Number.POSITIVE_INFINITY;
  let allVisitedChildrenCanUnload = true;

  while (purgedSize < excessSize) {
    const line = lines.pop(); // evict in here
    const nextLine = lines[lines.length - 1]; // but only evict to the lower bound of this
    const maxMostRecentlyUsed = nextLine != null ? nextLine.lastUsed.leastRecentlyUsed : Number.POSITIVE_INFINITY;

    if (!line) {
      // we ran out of content in this subtree of the hierarchical cache. There might
      // be more memory in neighbouring subtrees
      break;
    }

    if (line.lastUsed.leastRecentlyUsed > maxLeastRecentlyUsed) {
      newLimitLeastRecentlyUsed = Math.min(newLimitLeastRecentlyUsed, line.lastUsed.leastRecentlyUsed);
      break;
    }

    const entry = cache[line.tag];

    levelCount++;

    const eviction = evict(entry, line.tag, excessSize - purgedSize, maxMostRecentlyUsed);
    purgedSize += eviction.purgedSize;
    leafCount += eviction.count;

    allVisitedChildrenCanUnload = allVisitedChildrenCanUnload && eviction.canUnload;

    if (eviction.newLimitLeastRecentlyUsed != null) {
      newLimitLeastRecentlyUsed = Math.min(newLimitLeastRecentlyUsed, eviction.newLimitLeastRecentlyUsed);
    }
  }

  // if the timestamp was never set, all not filtered entries were fully perged (no child was partially evicted)
  newLimitLeastRecentlyUsed = newLimitLeastRecentlyUsed === Number.POSITIVE_INFINITY ? null : newLimitLeastRecentlyUsed;
  // if we still have children that were not visited at all, take the timestamp from the least recently unused, unvisited child (all visited were fully evicted and the abort condition was met before the cache was empty)
  if (lines.length > 0 && newLimitLeastRecentlyUsed == null) {
    newLimitLeastRecentlyUsed = lines[lines.length - 1].lastUsed.leastRecentlyUsed;
  }
  // we might have removed all cache contents that we are allowed to remove, but
  // there might be contents that we are not allowed to remove. (e.g. pending or failed tile requests)
  // TODO: it seems stupid to prevent a subcache from deallocation because of a failed tile request
  const hasUnvisitedChildren = lines.length > 0;
  const canUnload = !hasUnvisitedChildren && allVisitedChildrenCanUnload && !hasShadowContent;

  logger?.debug(
    "purged",
    levelCount,
    "entries from current cache to free up",
    formatUnits(purgedSize),
    "from",
    leafCount,
    "leaves",
  );

  return { purgedSize, count: leafCount, newLimitLeastRecentlyUsed, canUnload };
}

export function mutlilevel_lru_evict_leaf<T, T2 extends string | number | symbol>(
  ...params: MultilevelLruEvictLeafParameters<T, T2>
): MultilevelLruEvictionResult {
  const [excessSize, cache, lastUse, estimateCacheLineSize, unload, logger, ...paramsWithDefaults] = params;
  let [formatUnits, maxLeastRecentlyUsed] = paramsWithDefaults;
  formatUnits = formatUnits ?? ((v) => `${v}`);
  maxLeastRecentlyUsed = maxLeastRecentlyUsed ?? Number.POSITIVE_INFINITY;

  const mlru_evict = (v: T, tag: T2) => {
    const purgedSize = estimateCacheLineSize(v, tag);
    unload(v, tag);
    return { purgedSize, count: 1, canUnload: true, newLimitLeastRecentlyUsed: null };
  };

  const mlru_lastUse = (v: T, tag: T2) => {
    const u = lastUse(v, tag);
    return u != null ? ageOfLeaf(u) : u;
  };

  return multilevel_lru_evict<T, T2>(
    excessSize,
    cache,
    mlru_lastUse,
    mlru_evict,
    logger,
    formatUnits,
    maxLeastRecentlyUsed,
  );
}

import { Api } from "@/api/Api";
import type { CountryPlotType } from "@/reducer/client-models/CountryPlot";
import { type CountryPlotCreate, type CountryPlotSchema, LayoutType } from "@mm/metx-workbench.meteomatics.com";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const createCountryForecastPlot = createAsyncThunk(
  "api/country-forecast-plot/create",
  async (payLoad: { data: CountryPlotCreate; layoutId: number }, _thunkAPI) => {
    return await Api.countryForecastPlot
      .v2CreateCountryPlot({
        countryPlotCreate: payLoad.data,
      })
      .then((plot: CountryPlotSchema) => {
        return { tool: plot as CountryPlotType, layoutType: LayoutType.CountryPlot, layoutId: payLoad.layoutId };
      });
  },
);

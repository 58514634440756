import {
  DEFAULT_PARTICLE_AMOUNT,
  DEFAULT_PARTICLE_SIZE,
  particleAmountLimit,
  particleSizeLimit,
} from "@/constants/layerConfigAttributes";
import { mapValues } from "lodash";
import type { Expression } from "mapbox-gl";

export const defaultPropertySpec = {
  "particle-speed": {
    type: "number",
    minimum: 0,
    default: 0.25,
    transition: true,
    expression: {
      interpolated: true,
      parameters: ["zoom"],
    },
    "property-type": "data-constant",
  },
  "particle-size": {
    type: "number",
    minimum: particleSizeLimit.min,
    default: DEFAULT_PARTICLE_SIZE,
    transition: true,
    expression: {
      interpolated: true,
      parameters: ["zoom"],
    },
    "property-type": "data-constant",
  },
  "particle-amount": {
    type: "number",
    minimum: particleAmountLimit.min,
    default: DEFAULT_PARTICLE_AMOUNT,
    transition: true,
    expression: {
      interpolated: true,
      parameters: ["zoom"],
    },
    "property-type": "data-constant",
  },
  opacity: {
    type: "number",
    minimum: 0,
    default: 1,
    transition: true,
    expression: {
      interpolated: true,
      parameters: ["zoom"],
    },
    "property-type": "data-constant",
  },
} as const;

export const defaultPropertyValues = mapValues(defaultPropertySpec, (spec) => spec.default);

type TypeMapping = {
  number: number;
  string: string;
  // Add more mappings as needed
};

export type WindLayerPropertySpec = typeof defaultPropertySpec;

export type WindLayerPropertySpecProp = keyof typeof defaultPropertySpec;

export type WindLayerPropertySpecDefaultValue = {
  [K in keyof typeof defaultPropertySpec]: TypeMapping[(typeof defaultPropertySpec)[K]["type"]];
}[keyof typeof defaultPropertySpec];

export type WindLayerPropertySpecValue = WindLayerPropertySpecDefaultValue | Expression;

export type WindLayerStyleOptions = Partial<{
  [prop in WindLayerPropertySpecProp]: WindLayerPropertySpecValue | Expression;
}>;

export type WindLayerProperties = {
  [prop in WindLayerPropertySpecProp]: WindLayerPropertySpecDefaultValue;
};

export const defaultWindParticleSettings = {
  fadeOpacity: 0.98, // how fast the particle trails fade on each frame. Smaller value means shorter trails.
  speedFactor: 0.25, // how fast the particles move
  dropRate: 0.003, // how often the particles move to a random place
  dropRateBump: 0.01, // drop rate increase relative to individual particle speed
  numParticles: 65536 / 16, // Value must be always power of 2, otherwise everything will break.
  // Best and safest way to calculate numParticles would be deciding how much particles we want
  // to see on single axe and calling numParticles = Math.pow(2, particlesPerAxe)
};

import { LayersApiConfiguration } from "@/env";
import type { LayerUnionWithAll } from "@/layers";
import type { CrossModelParameterSpecification, ModelSchema } from "@mm/api-layers.meteomatics.com";
import { APILayersApi, ModelsApi, StationsApi } from "@mm/api-layers.meteomatics.com";
import Logger from "logging";
import { useContext, useEffect, useState } from "react";
import { createContext } from "react";
import { ModelSearchEngine, WeatherParamSearchParallel } from "weather-parameter-utils";
import type { WeatherParamSearchOptions } from "weather-parameter-utils";
import { PARAMETERS_DISABLED, PARAMETERS_ENABLED } from "./config";
const logger = Logger.fromFilename(__filename);

const layersApi = new APILayersApi(LayersApiConfiguration);
const modelsApi = new ModelsApi(LayersApiConfiguration);
const stationApi = new StationsApi(LayersApiConfiguration);

export interface LayersApiResponses {
  /**
   * Contains a list of currently available general weather params
   */
  generalWeatherParams?: CrossModelParameterSpecification[];
  /**
   * Contains a list of all the available models(data sources) for general weather params
   */
  allSourceModelsGeneralWeatherParams?: ModelSchema[];
}

export const searchOptions: WeatherParamSearchOptions = {
  disabledFlags: PARAMETERS_DISABLED,
  enabledFlags: PARAMETERS_ENABLED,
  maxNumberOfResults: 5,
};

/**
 * Provides search for general weather parameters from api-layer.meteomatics.com's layer endpoint
 */
const searchEngineStandardLayer = new WeatherParamSearchParallel(searchOptions);

/**
 * Provides search for available station weather observation parameters from api-layer.meteomatics.com's station endpoints
 */
const searchEngineStationObs = new WeatherParamSearchParallel(searchOptions);

/**
 * Provides search for available station weather MOS parameters from api-layer.meteomatics.com's station endpoints
 */
const searchEngineStationMos = new WeatherParamSearchParallel(searchOptions);

export const searchEnginesWeatherParams = {
  standard: searchEngineStandardLayer,
  stationObs: searchEngineStationObs,
  stationMos: searchEngineStationMos,
};

export function getSearchEngine(layer: LayerUnionWithAll): WeatherParamSearchParallel {
  if (layer.kind === "StationLayerDescription") {
    // It needs type assertion to get it work bc MapLayer doesn't have model.
    if ((layer as any).model === "mix-obs") {
      return searchEnginesWeatherParams.stationObs;
    }
    if ((layer as any).model === "mm-mos") {
      return searchEnginesWeatherParams.stationMos;
    }
    logger.error(`Station layer currently does no support model ${(layer as any).model}`);
    return searchEnginesWeatherParams.standard;
  }
  return searchEnginesWeatherParams.standard;
}

// TODO-StationFeature:
// There is currently no endpoint for a list of station models. It is debatable if we should put those models together
// with the models for the regular parameters. If we use the model search engine purely to lookup a model regardless of
// the category of the models, it's probably ok to put them all in the same search engine.
// The model search engine is currently used everywhere in the code to lookup the ModelSchema object from a model indentifier string.
// For now, I put mix-obs and mm-mos in the same search engine as a quick fix.
export const searchEngineWeatherModels = new ModelSearchEngine();
// Completely made up ModelSchema for temporal use. This needs to be implemented in the api-layers backend and fetched in the code below.
export const stationObsModel_TEMPORAL = {
  identifier: "mix-obs",
  name: "Station Observations",
  description_de: "<TEMPORAL TXT>",
  description_en: "<TEMPORAL TXT>",
  bounding_box: {
    resolution_lng: 0.000833333,
    resolution_lat: 0.000833333,
    south: -90,
    west: -180,
    north: 90,
    east: 180,
  },
};
export const stationMosModel_TEMPORAL = {
  identifier: "mm-mos",
  name: "MOS Prognoses",
  description_de: "<TEMPORAL TXT>",
  description_en: "<TEMPORAL TXT>",
  bounding_box: {
    resolution_lng: 0.000833333,
    resolution_lat: 0.000833333,
    south: -90,
    west: -180,
    north: 90,
    east: 180,
  },
};
const stationModels_TEMPORAL = [stationObsModel_TEMPORAL, stationMosModel_TEMPORAL];

/**
 * Beware that the weather paramerters live in two places, in the context and the search engines.
 */
export const LayersApiContext = createContext<LayersApiResponses & { searchEngineReady: boolean }>({
  searchEngineReady: false,
});

export function useLayersApiContextValue(): LayersApiResponses & { searchEngineReady: boolean } {
  // Move searchEngineReady to state, as a part of authorization flow refactoring
  // Not sure how, but previous solution caused frozen UI state
  const [searchEngineReady, setSearchEngineReaady] = useState(false);
  const [layersApiCache, setLayersApiCache] = useState<LayersApiResponses>({});

  useEffect(() => {
    Promise.all([
      //   TODO api-layer point of failure
      layersApi.layerModelDescriptionApiV1LayersGet(),
      modelsApi.getModelsApiV1ModelsGet(),
      stationApi.layerModelDescriptionApiV1StationsObsGet(),
      stationApi.layerModelDescriptionApiV1StationsMosGet(),
    ])
      .then(([standardWeatherParams, modelsForWeatherParams, stationObsParams, stationMosParams]) => {
        setLayersApiCache({
          generalWeatherParams: standardWeatherParams,
          allSourceModelsGeneralWeatherParams: modelsForWeatherParams,
        });
        searchEnginesWeatherParams.standard.replaceCorpus(standardWeatherParams);
        searchEnginesWeatherParams.stationObs.replaceCorpus(stationObsParams);
        searchEnginesWeatherParams.stationMos.replaceCorpus(stationMosParams);
        // TODO-StationFeature
        // stationModels_TEMPORAL needs to be replaced once the backend provides the models for station.
        // Refer to where is is defined for further description.
        searchEngineWeatherModels.replaceCorpus([...modelsForWeatherParams, ...stationModels_TEMPORAL]);
        setSearchEngineReaady(true);
      })
      .catch((_e) => {
        logger.error(_e);
        // TODO: show error in gui using the error dialog
        // setAppLoading("rejected");
      });
  }, []);
  return { ...layersApiCache, searchEngineReady };
}

/**
 * @returns An array of the models (data sources) for the standard weather parameters that are currently available.
 * Note: This is for getting a list of whole models. Please prefer to use usePartialWeatherParameterModels or useModel hooks, as they simply the steps for getting the model you want.
 */
export function useWeatherParamModels(): LayersApiResponses["allSourceModelsGeneralWeatherParams"] {
  return useContext(LayersApiContext).allSourceModelsGeneralWeatherParams;
}

/**
 * @returns An array of weather parameter description objects, each of them contains an array of available options and meta data.
 */
export function useWeatherParams(): LayersApiResponses["generalWeatherParams"] {
  return useContext(LayersApiContext).generalWeatherParams;
}

/**
 * @returns A boolean signifying if the search engine has already loaded.
 */
export function useSearchEngineReady() {
  const { searchEngineReady } = useContext(LayersApiContext);
  return searchEngineReady;
}

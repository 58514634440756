import { wrongActionPlacementError } from "@/models/state-machine-utils/stateMachineUtils";
import { assign } from "xstate";
import type { ContextMenuCtx, POISelectCtx } from "./mapInteractionStateCtxTypes";
import { MapInteractionEventType, type MapInteractionEventUnion } from "./mapInteractionStateEvents";
import { roundCoordinate } from "./mapInteractionStateUtils";

export const updateCoordinates = assign((ctx: any, event: MapInteractionEventUnion): POISelectCtx | object => {
  if (event.type === MapInteractionEventType.click) {
    if (event.mapId !== ctx.modeOpts.activeMapId) {
      // Update nothing if the non-active map emits click event.
      return ctx;
    }
    // Note onCoordinateSelect is set by setPoiSelect event
    ctx.modeOpts.onCoordinateSelect(roundCoordinate(event.coordinates));

    return {
      modeOpts: null,
    };
  }
  throw wrongActionPlacementError(updateCoordinates.name);
});

export const setSelectedMap = assign((_ctx: any, event: MapInteractionEventUnion): POISelectCtx => {
  if (event.type === MapInteractionEventType.setCoordSelect) {
    return {
      modeOpts: {
        activeMapId: event.mapId,
        onCoordinateSelect: event.onCoordinateSelect,
      },
    };
  }
  throw wrongActionPlacementError(setSelectedMap.name);
});

export const setContextMenu = assign((_ctx: any, event: MapInteractionEventUnion): ContextMenuCtx => {
  if (event.type === MapInteractionEventType.contextMenu) {
    return {
      modeOpts: {
        activeMapId: event.mapId,
        coordinates: event.coordinates,
        point: event.point,
      },
    };
  }
  throw wrongActionPlacementError(setSelectedMap.name);
});

export const dismissContextMenu = assign((_ctx: any, event: MapInteractionEventUnion): object => {
  if (event.type === MapInteractionEventType.dismiss) {
    return {
      modeOpts: null,
    };
  }
  throw wrongActionPlacementError(setSelectedMap.name);
});

// polyfill the fetch API for node

import { RUNTIME, Runtime } from "detect-runtime-lib";

// (the package internally just reexports the browser built in api, so this switch is unnecessary)
const fetchapi: { _Request: typeof Request; _fetch: typeof fetch } = (() => {
  switch (RUNTIME) {
    case Runtime.BrowserWorker:
    case Runtime.BrowserMain:
      return { _Request: Request, _fetch: fetch.bind(globalThis) };
    case Runtime.Node: {
      // biome-ignore lint/security/noGlobalEval: TODO remove eval
      const _fetch = eval("require")("node-fetch");
      return { _Request: _fetch.Request, _fetch };
    }
  }
})();

export const _Request = fetchapi._Request;
export const _fetch = fetchapi._fetch;

import { ConditionOptions } from "@/utility/conditionalExpressions";
import type { ColorThreshold } from "@mm/metx-workbench.meteomatics.com";
import type { CellClassParams } from "ag-grid-community";

export const generateThresholdCellStyle = (thresholds: ColorThreshold[]) => {
  return (params: CellClassParams) => {
    const meetsCondition = (threshold: ColorThreshold): boolean => {
      switch (threshold.condition) {
        case ConditionOptions["<"]:
          return params.value < threshold.value;
        case ConditionOptions["<="]:
          return params.value <= threshold.value;
        case ConditionOptions[">"]:
          return params.value > threshold.value;
        case ConditionOptions[">="]:
          return params.value >= threshold.value;
        case ConditionOptions["=="]:
          return params.value === threshold.value;
        default:
          return false;
      }
    };

    const matchingThreshold = thresholds.find(meetsCondition);
    // TODO keep eye on background color white
    return matchingThreshold ? { backgroundColor: matchingThreshold.color } : { backgroundColor: "white" };
  };
};

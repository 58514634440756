import { Area, type CoordinateSystem, type GridRequest } from "../models";
import type { Padding } from "./padding";

export interface CropResult<T extends CoordinateSystem> {
  padding: Padding;
  gridRequest: GridRequest<T>;
  originalGridRequest: GridRequest<T>;
}

/**
 * Crop a tile request to a subset of the tiles area. This is for example necessary if you send a request
 * to the meteomatics API that is partially out of bounds of the weather model.
 *
 * @param originalGridRequest
 * @param subarea_
 * @param modelBounds_
 */
export function crop<T extends CoordinateSystem>(
  originalGridRequest: GridRequest<T>,
  subarea_: Area<T>,
  modelBounds_: Area<T>,
): CropResult<T> {
  // we have to crop in the request coordinate system. Otherwise the pixel densities between the padded and requested area
  // will be different.
  const crs = originalGridRequest.area.crs;
  const area = originalGridRequest.area.project(crs);
  const subarea = subarea_.project(crs);
  const modelBounds = modelBounds_.project(crs);

  const scalingLatToPx = originalGridRequest.height / area.latExtent();
  const scalingLngToPx = originalGridRequest.width / area.lngExtent();

  // The API can only render to intergral image sizes, but the actual math here might dictate that the area is layed out on
  // a fractional pixel grid, e.g. 10.5px width and 10.75px height. In theory, we have to resample data to get the projection right.
  //
  // We currently remove or add (!) enough area from the request to gurantee pixel snapping.
  // either adding or removing pixels through a `round` (instead of always removing/shrinking to get a subarea using `ceil` and `floor`)
  // will avoid pixel snapping bugs (single transparent pixel rows and columns) between tiles that might be caused by floating point imprecisions.
  const padding: Padding = {
    top: Math.round(Math.abs(area.latMax - subarea.latMax) * scalingLatToPx),
    right: Math.round(Math.abs(area.lngMax - subarea.lngMax) * scalingLngToPx),
    bottom: Math.round(Math.abs(area.latMin - subarea.latMin) * scalingLatToPx),
    left: Math.round(Math.abs(area.lngMin - subarea.lngMin) * scalingLngToPx),
  };

  const scalingPxToLng = area.lngExtent() / originalGridRequest.width;
  const scalingPxToLat = area.latExtent() / originalGridRequest.height;

  const pixelsnappedSubarea = new Area<T>(crs, {
    latMin: area.latMin + padding.bottom * scalingPxToLat,
    latMax: area.latMax - padding.top * scalingPxToLat,
    lngMin: area.lngMin + padding.left * scalingPxToLng,
    lngMax: area.lngMax - padding.right * scalingPxToLng,
  });

  const pixelsnappedSubareaWgs84 = pixelsnappedSubarea.project(crs);

  // convert `ceil` to `floor`, and `floor` to `ceil` in case we would walk out of the model bounding box otherwise
  if (pixelsnappedSubareaWgs84.latMin < modelBounds.latMin) {
    padding.bottom += 1;
    pixelsnappedSubarea.latMin += scalingPxToLat;
  }

  if (pixelsnappedSubareaWgs84.latMax > modelBounds.latMax) {
    padding.top += 1;
    pixelsnappedSubarea.latMax -= scalingPxToLat;
  }

  if (pixelsnappedSubareaWgs84.lngMax > modelBounds.lngMax) {
    padding.right += 1;
    pixelsnappedSubarea.lngMax -= scalingPxToLng;
  }

  if (pixelsnappedSubareaWgs84.lngMin < modelBounds.lngMin) {
    padding.left += 1;
    pixelsnappedSubarea.lngMin += scalingPxToLng;
  }

  const subareaWidth = originalGridRequest.width - padding.left - padding.right;
  const subareaHeight = originalGridRequest.height - padding.top - padding.bottom;

  const gridRequest: GridRequest<T> = {
    ...originalGridRequest,
    width: subareaWidth,
    height: subareaHeight,
    area: pixelsnappedSubarea.project(crs),
  };
  return { gridRequest, originalGridRequest, padding };
}

/**
 * Convert a grid request to a crop with zero padding.
 */
export function toCrop<T extends CoordinateSystem>(req: GridRequest<T>): CropResult<T> {
  return {
    padding: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    gridRequest: req,
    originalGridRequest: req,
  };
}

/**
 * Returns false if the cropped area is equal to the original area, which results in a padding of zero
 * on each side.
 *
 * @param CropResult
 * @returns
 */
export function isZeroPadding({ left, right, bottom, top }: Padding) {
  return top === 0 && left === 0 && right === 0 && bottom === 0;
}

import { groundStyles } from "@/constants/maptilerStyleOptions";
import type { LayerCreateUnion } from "@/layers";
import { HighchartsYAxis, HighchartsYAxisPointForecast } from "@/plot";
import { DefaultPlotSeries, DefaultPointForecastSeriesList, type PlotCreate } from "@/reducer/client-models";
import type { ExtendedEnergyPlotCreate } from "@/types/data";
import {
  type BackgroundMapLayerCreate,
  type CartographicMapCreate,
  type CountryPlotCreate,
  type GridCellLayout,
  LayoutType,
  type LocationTableCreate,
  type NoteCreate,
  type TabCreate,
  type TephigramCreate,
  ViewportKind,
  type WeatherTableCreate,
} from "@mm/metx-workbench.meteomatics.com";
import { defaultDateTimeDesc } from "./defaultDateTimeDesc";

export function createNewPlot(gridCellLayout: GridCellLayout): PlotCreate {
  return {
    plotSeries: [DefaultPlotSeries],
    gridCellLayout: gridCellLayout,
    location: { lat: 52.51704, lng: 13.38886 },
    datetime_from: null,
    datetime_to: null,
    resolution: null,
    title: "",
    plotYAxis: HighchartsYAxis,
  };
}

export function createNewPointForecastPlot(gridCellLayout: GridCellLayout): PlotCreate {
  return {
    plotSeries: [...DefaultPointForecastSeriesList],
    gridCellLayout: gridCellLayout,
    location: { lat: 52.51704, lng: 13.38886 },
    datetime_from: null,
    datetime_to: null,
    resolution: null,
    title: "",
    plotYAxis: HighchartsYAxisPointForecast,
  };
}

export function createNewEnergyPlot(
  forecastType: string,
  model: string,
  siteId: string,
  gridCellLayout: GridCellLayout,
): ExtendedEnergyPlotCreate {
  return {
    gridCellLayout: gridCellLayout,
    forecast_type: forecastType,
    site_id: siteId,
    model: model,

    plotSeriesStyles: {
      actual: {
        type: "spline",
        dashStyle: "solid",
        color: "#FF0000",
        fontSize: "12px",
        visible: true,
        showInLegend: true,
      },
      power: {
        type: "spline",
        dashStyle: "solid",
        color: "#094e98",
        fontSize: "12px",
        visible: true,
        showInLegend: true,
      },
      delta_1h: {
        type: "area",
        dashStyle: "solid",
        color: "#18ceb3",
        fontSize: "12px",
        visible: true,
        showInLegend: true,
      },
      delta_6h: {
        type: "area",
        dashStyle: "solid",
        color: "#185bce",
        fontSize: "12px",
        visible: true,
        showInLegend: true,
      },
      delta_12h: {
        type: "area",
        dashStyle: "solid",
        color: "#15b548",
        fontSize: "12px",
        visible: true,
        showInLegend: true,
      },
      delta_24h: {
        type: "area",
        dashStyle: "solid",
        color: "#b5b215",
        fontSize: "12px",
        visible: true,
        showInLegend: true,
      },
      combined_delta_1h: {
        type: "spline",
        dashStyle: "solid",
        color: "#18c8ce",
        fontSize: "12px",
        visible: false,
        showInLegend: false,
      },
      combined_delta_6h: {
        type: "spline",
        dashStyle: "solid",
        color: "#1897ce",
        fontSize: "12px",
        visible: false,
        showInLegend: false,
      },
      combined_delta_12h: {
        type: "spline",
        dashStyle: "solid",
        color: "#b0b515",
        fontSize: "12px",
        visible: false,
        showInLegend: false,
      },
      combined_delta_24h: {
        type: "spline",
        dashStyle: "solid",
        color: "#eec210",
        fontSize: "12px",
        visible: false,
        showInLegend: false,
      },
    },
  };
}

export function createNewWeatherTable(
  tabId: number,
  title = "t_2m:C",
  parameter_unit = "t_2m:C",
  model = "mix",
): WeatherTableCreate {
  return {
    title: "Weather Table",
    location: { lat: 52.51704, lng: 13.38886 },
    id_tab: tabId,
    groups: [
      {
        group_info: {
          title: "Default group",
          show: true,
        },
        columns: [
          {
            title: title,
            parameter_unit: parameter_unit,
            model: model,
            show: true,
            index: 0,
            calibrated: true,
            color: "#000000",
          },
        ],
      },
    ],
  };
}

export function createNewLocationTable(
  tabId: number,
  title = "Location Table",
  parameter_unit = "t_2m:C",
  model = "mix",
): LocationTableCreate {
  return {
    title: title,
    id_tab: tabId,
    model: model,
    parameter_unit: parameter_unit,
    // Example

    groups: [
      {
        locations: [
          { lat: 52.519, lon: 13.404, label: "Berlin" },
          { lat: 47.42, lon: 9.38, label: "St. Gallen" },
        ],
      },
    ],
  };
}
export const TEPHIGRAM_DEFAULT_LEVEL_UNIT = "hPa";
export function createNewTephigram(id_tab: number): TephigramCreate {
  return {
    id_tab: id_tab,
    title: "Tephigram",
    location: { lat: 52.51704, lng: 13.38886 },
    model: "mix",
    levelUnit: TEPHIGRAM_DEFAULT_LEVEL_UNIT,
    titleStyle: { color: "#000000", fontSize: 24, fontWeight: 700, fontStyle: "normal", textDecoration: "none" },
  };
}

export function createNewCountryPlot(id_tab: number): CountryPlotCreate {
  return {
    id_tab: id_tab,
    model: "mix",
    plotSeries: [
      {
        ...DefaultPlotSeries,
        parameter_unit: "t_2m_10y_mean:C",
      },
      {
        ...DefaultPlotSeries,
        series_color: "#FF3333",
        parameter_unit: "t_2m:C",
      },
    ],
    site_id: "de",
  };
}

export function createNewNote(tabId: number, title = "New notes"): NoteCreate {
  return {
    title,
    id_tab: tabId,
  };
}

export const defaultBackgroundMapLayer: BackgroundMapLayerCreate = {
  index: 0,
  opacity: 1,
  show: true,
  style: groundStyles[0].id,
  custom_options: { show_state_border: false },
  kind: "BackgroundMapDescription",
};

export function createNewMap(gridCellLayout: GridCellLayout, layers?: LayerCreateUnion[]): CartographicMapCreate {
  return {
    title: "New Map",
    gridCellLayout: gridCellLayout,
    layers: layers ?? [],
    time_offset_mins: 0,
    lod_bias: 0,
  };
}

export const newTab: TabCreate = {
  weather_tables: [],
  title: "New Tab",
  datetime: defaultDateTimeDesc,
  maps: [
    {
      ...createNewMap(
        {
          gridColumnStart: 1,
          gridColumnEnd: 97,
          gridRowStart: 1,
          gridRowEnd: 97,
        },
        [defaultBackgroundMapLayer],
      ),
    },
  ],
  viewports: [
    {
      kind: ViewportKind.ViewportCenterPoint,
      center_lng: 0,
      center_lat: 0,
      zoom: 0,
      southWest_lng: 0,
      southWest_lat: 0,
      northEast_lng: 0,
      northEast_lat: 0,
    },
  ],
  plots: [],
  energy_plots: [],
  tephigrams: [],
  country_plots: [],
  layouts: [
    {
      type: LayoutType.Map,
      gridCellLayout: {
        gridColumnStart: 1,
        gridColumnEnd: 97,
        gridRowStart: 1,
        gridRowEnd: 97,
      },
    },
  ],
};

export const newEmptyTab: TabCreate = {
  title: "New Tab",
  datetime: defaultDateTimeDesc,
  maps: [],
  viewports: [],
  plots: [],
  energy_plots: [],
  tephigrams: [],
  country_plots: [],
  weather_tables: [],
  layouts: [
    {
      type: LayoutType.Undefined,
      gridCellLayout: {
        gridColumnStart: 1,
        gridColumnEnd: 97,
        gridRowStart: 1,
        gridRowEnd: 97,
      },
    },
  ],
};

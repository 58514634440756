import { classNames } from "@/utility/jsx";
import { type DragEvent, type PropsWithChildren, useState } from "react";

import styles from "./style.module.scss";

type DropZoneProps = {
  onDrop?: (files: FileList) => void;
  placeholder: string;
  type: string;
};

export function DropZone({ onDrop, placeholder, type, children }: PropsWithChildren<DropZoneProps>) {
  const [isDragging, setIsDragging] = useState(false);

  function handleDropFile(event: DragEvent<HTMLDivElement>) {
    event.preventDefault();
    setIsDragging(false);

    if (event.dataTransfer?.files) {
      const dataTransfer = new DataTransfer();
      for (const file of Array.from(event.dataTransfer.files)) {
        if (file.type === type) {
          dataTransfer.items.add(file);
        }
      }
      onDrop && dataTransfer.files.length && onDrop(dataTransfer.files);
    }
  }

  function handleDrag(event: DragEvent<HTMLDivElement>) {
    event.preventDefault();
    setIsDragging(true);
  }

  function handleDragEnd(event: DragEvent<HTMLDivElement>) {
    event.preventDefault();
    setIsDragging(false);
  }

  return (
    <div
      className={classNames(styles["drop-zone"], isDragging ? styles["drop-zone__dragging"] : "")}
      onDrop={handleDropFile}
      onDragOver={handleDrag}
      onDragLeave={handleDragEnd}
    >
      {children}
      <div className={classNames(styles["drop-zone__overlay"])}>
        <div>
          <div className={classNames("material-icons")}>file_upload</div>
          <div>{placeholder}</div>
        </div>
      </div>
    </div>
  );
}

import type { BinarySample } from "./BinarySample";
import type { Header } from "./Header";
export class BinaryIterator<T> implements IterableIterator<T> {
  private idx = 0;
  private getter: "getFloat32" | "getFloat64";

  constructor(
    private header: Header,
    private endianess: boolean,
    private grid: DataView,
    private adapter: (_: BinarySample) => T,
  ) {
    switch (this.header.precision) {
      case 4:
        this.getter = "getFloat32";
        break;
      case 8:
        this.getter = "getFloat64";
        break;
      default:
        throw Error();
    }
  }

  public next(): IteratorResult<T> {
    if (this.idx >= this.grid.byteLength) {
      return {
        done: true,
        value: null,
      };
    }

    const byteOffset = this.idx;
    this.idx += this.header.precision;

    // north is at the bottom per default, invert the y axis (?)
    const bytesPerRow = this.header.longitudes.length * this.header.precision;
    const rowIdx = ~~(byteOffset / bytesPerRow);
    const colOffsetBytes = byteOffset % bytesPerRow;
    const rowInvertedIdx = this.header.longitudes.length - 1 - rowIdx;
    const rowInvertedOffsetBytes = rowInvertedIdx * bytesPerRow;

    const correctedByteOffset = rowInvertedOffsetBytes + colOffsetBytes;

    const sample: BinarySample = {
      value: this.grid[this.getter](correctedByteOffset, this.endianess),
      linearIdx: correctedByteOffset / this.header.precision,
    };

    const value = this.adapter(sample);

    return {
      done: false,
      value,
    };
  }

  [Symbol.iterator](): IterableIterator<T> {
    return this;
  }
}

import type { TimeStateMachine } from "@/models/time-control/timeStateMachine";
import { setTimeList } from "@/time";
import { Interval } from "luxon";
import type { DateTime } from "luxon";
import type { StateFrom } from "xstate";

export function createTimeFramesFromState(timeState: StateFrom<TimeStateMachine>): DateTime[] {
  if (timeState.matches("ANIMATION")) {
    const { startTime, endTime, temporalResolution } = timeState.context.modeOpts;
    return setTimeList(Interval.fromDateTimes(startTime, endTime), temporalResolution);
  }
  return [];
}

import { SynchrounousState } from "@/cache/AsyncResult";
import { networkCaches } from "@/cache/GlobalCache";
import type { CombinedMultiPNGTile } from "@/cache/PVSTileService/TileGetters/MultiWMSTileGetter";
import {
  type PotentiallyVisibleTileSet,
  getTileZoomRange,
} from "@/cache/SpatioTemporalTileCache/PotentiallyVisibleTileSet";
import { instantiateTiles } from "@/cache/SpatioTemporalTileCache/TileArea";
import type { SceneLayerApi } from "@/layers/SceneLayerApi";
import { fullscreenQuad, supportsFloatTextures } from "@/layers/utility/webgl";
import { createTileSetFrameBufferHolder } from "@/layers/wind/gl/renderers";
import { calculateTileDepth } from "@/layers/wind/gl/shaders/utils";
import { createBuffer } from "@/layers/wind/gl/util";
import { compileTilingPrograms, drawTexture, drawTile } from "../mixins";

const NEAR_PLANE = 0;
const FAR_PLANE = 1;

const FULLY_TRANSPARENT_TILE = networkCaches?.multi_wms_tile_cache.networkCache.emptyTile;
const FAILURE_TILE = networkCaches?.multi_wms_tile_cache.networkCache.failureTile;

export function createTileMapRenderer(gl: WebGLRenderingContext, scene: SceneLayerApi) {
  const _programs = compileTilingPrograms(gl);
  const _tileSetFbHolder = createTileSetFrameBufferHolder(gl);
  const _quadBuffer = createBuffer(gl, new Float32Array([0, 0, 1, 0, 0, 1, 0, 1, 1, 0, 1, 1]));

  const _textureCoordinatesBuffer = createBuffer(gl, fullscreenQuad);
  const _quadVerticesBuffer = createBuffer(gl, fullscreenQuad);

  const areProgramsCompiled = () => {
    const { synchronous } = _programs.tilingPrograms;
    return !(
      synchronous === SynchrounousState.StillPending ||
      synchronous === SynchrounousState.PermanentlyFailed ||
      synchronous === SynchrounousState.NotRequested
    );
  };

  if (!supportsFloatTextures(gl)) {
    console.error("GPU does not support floating point textures");
    return;
  }

  // TODO Cleanup prerender
  // I'm sure, it's possible to write this more efficiently
  const prerender = (projectionMatrix: number[], pvs: PotentiallyVisibleTileSet<CombinedMultiPNGTile>) => {
    const { synchronous } = _programs.tilingPrograms;
    if (
      synchronous === SynchrounousState.StillPending ||
      synchronous === SynchrounousState.PermanentlyFailed ||
      synchronous === SynchrounousState.NotRequested
    ) {
      return;
    }

    const size = scene.getRenderSize();
    const fb = _tileSetFbHolder.getCleanTileSetFramebuffer(size);

    const { program, parameters } = synchronous;

    gl.useProgram(program);
    gl.viewport(0, 0, size.width, size.height);

    gl.bindFramebuffer(gl.FRAMEBUFFER, fb.framebuffer);
    gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, fb.color, 0);
    gl.framebufferRenderbuffer(gl.FRAMEBUFFER, gl.DEPTH_ATTACHMENT, gl.RENDERBUFFER, fb.depth);

    // clear the buffer, this way we mark a pixel as not rendered by a tile (not yet available)
    // and the main pass will render a transparent pixel.
    gl.clearColor(0, 0, 0, 0);
    gl.clear(gl.COLOR_BUFFER_BIT);

    // Inverted depth test ensures smaller tiles block larger parent tiles using the depth test
    // This is important for two reasons: (1) it reduces overdraw (2) ensures the same layer is not blended multiple times into the output
    gl.enable(gl.DEPTH_TEST);
    gl.depthMask(true);
    gl.depthRange(NEAR_PLANE, FAR_PLANE);
    gl.depthFunc(gl.GREATER); // smaller tiles have greater zoom levels and thus greater depth
    gl.clearDepth(0);
    gl.clear(gl.DEPTH_BUFFER_BIT);

    gl.uniformMatrix4fv(parameters.u_matrix, false, projectionMatrix);

    gl.bindBuffer(gl.ARRAY_BUFFER, _textureCoordinatesBuffer);

    gl.enableVertexAttribArray(Number(parameters.a_texCoord));
    gl.vertexAttribPointer(Number(parameters.a_texCoord), 2, gl.FLOAT, false, 0, 0);

    gl.bindBuffer(gl.ARRAY_BUFFER, _quadVerticesBuffer);
    gl.enableVertexAttribArray(Number(parameters.a_position));
    gl.vertexAttribPointer(Number(parameters.a_position), 3, gl.FLOAT, false, 0, 0);

    const tiles = scene.getMapViewport();

    const sublayers = pvs.get();
    const zoomRange = getTileZoomRange(sublayers);
    const descendingLayers = [...sublayers.entries()].sort((a, b) => b[0] - a[0]);

    for (const [sublayerZoom, sublayerTiles] of descendingLayers) {
      const depth = calculateTileDepth(zoomRange, sublayerZoom);

      for (const [, { tileData, tileGeometry }] of Object.entries(sublayerTiles)) {
        // cull draw calls for out of weather model bounds tiles as an optimization
        if (tileData === FULLY_TRANSPARENT_TILE || tileData === FAILURE_TILE) {
          continue;
        }

        // TODO Fix typing. Typescript doesn't catch if statement on line 105, causing error
        // @ts-ignore
        const [vectorU, vectorV] = tileData.data;

        gl.activeTexture(gl.TEXTURE0);
        const tileTexture = vectorU.texture(gl);
        gl.uniform1i(parameters.u_windVectorUSampler, 0);
        gl.bindTexture(gl.TEXTURE_2D, tileTexture);

        gl.activeTexture(gl.TEXTURE1);
        const tileTexture2 = vectorV.texture(gl);
        gl.uniform1i(parameters.u_windVectorVSampler, 1);
        gl.bindTexture(gl.TEXTURE_2D, tileTexture2);

        const instances = [...instantiateTiles(tiles.viewportArea, tileGeometry)];

        // TODO: a single draw call for all instances instead of a draw call per instance
        for (const instance of instances) {
          // render a quad covering the area of the current tile
          drawTile(gl, instance, depth);
        }
      }
    }
    gl.bindFramebuffer(gl.FRAMEBUFFER, null);
  };

  const render = () => {
    const fb = _tileSetFbHolder.fb;

    if (!fb) {
      return;
    }

    drawTexture(gl, fb.color, 1, _quadBuffer, _programs.textureProgram);
  };

  return {
    areProgramsCompiled,
    prerender,
    render,
    get windMap() {
      return _tileSetFbHolder.fb?.color;
    },
  };
}

export type TileMapRenderer = ReturnType<typeof createTileMapRenderer>;

import type { LayoutViewport } from "@/grid/useCurrentLayout";

export const GRID_SIZE = 96;

export enum GridCellLayoutVariant {
  R1C1 = 0,
  R1C2 = 1,
  R2C2 = 2,
  R2C1 = 3,
  R1C3 = 4,
  R3C1 = 5,
  R2C3 = 6,
  R1C2_2SUB_ROW_LEFT = 7,
  R1C2_3SUB_ROW_LEFT = 8,
}
// See description on LayoutViewport
export const defaultLayouts: Record<GridCellLayoutVariant, LayoutViewport> = {
  [GridCellLayoutVariant.R1C1]: {
    numRows: 1,
    hasSubRows: false,
    numColumns: 1,
    gridCellLayouts: [
      {
        gridColumnEnd: 97,
        gridColumnStart: 1,
        gridRowEnd: 97,
        gridRowStart: 1,
      },
    ],
  },
  [GridCellLayoutVariant.R1C2]: {
    numRows: 1,
    hasSubRows: false,
    numColumns: 2,
    gridCellLayouts: [
      {
        gridColumnEnd: 49,
        gridColumnStart: 1,
        gridRowEnd: 97,
        gridRowStart: 1,
      },
      {
        gridColumnEnd: 97,
        gridColumnStart: 49,
        gridRowEnd: 97,
        gridRowStart: 1,
      },
    ],
  },
  [GridCellLayoutVariant.R2C2]: {
    numRows: 2,
    hasSubRows: false,
    numColumns: 2,
    gridCellLayouts: [
      {
        gridColumnEnd: 49,
        gridColumnStart: 1,
        gridRowEnd: 49,
        gridRowStart: 1,
      },
      {
        gridColumnEnd: 97,
        gridColumnStart: 49,
        gridRowEnd: 49,
        gridRowStart: 1,
      },
      {
        gridColumnEnd: 49,
        gridColumnStart: 1,
        gridRowEnd: 97,
        gridRowStart: 49,
      },
      {
        gridColumnEnd: 97,
        gridColumnStart: 49,
        gridRowEnd: 97,
        gridRowStart: 49,
      },
    ],
  },
  [GridCellLayoutVariant.R2C1]: {
    numRows: 2,
    hasSubRows: false,
    numColumns: 1,
    gridCellLayouts: [
      {
        gridColumnEnd: 97,
        gridColumnStart: 1,
        gridRowEnd: 49,
        gridRowStart: 1,
      },
      {
        gridColumnEnd: 97,
        gridColumnStart: 1,
        gridRowEnd: 97,
        gridRowStart: 49,
      },
    ],
  },
  [GridCellLayoutVariant.R1C3]: {
    numRows: 1,
    hasSubRows: false,
    numColumns: 3,
    gridCellLayouts: [
      {
        gridColumnEnd: 33,
        gridColumnStart: 1,
        gridRowEnd: 97,
        gridRowStart: 1,
      },
      {
        gridColumnEnd: 65,
        gridColumnStart: 33,
        gridRowEnd: 97,
        gridRowStart: 1,
      },
      {
        gridColumnEnd: 97,
        gridColumnStart: 65,
        gridRowEnd: 97,
        gridRowStart: 1,
      },
    ],
  },
  [GridCellLayoutVariant.R3C1]: {
    numRows: 3,
    hasSubRows: false,
    numColumns: 1,
    gridCellLayouts: [
      {
        gridColumnEnd: 97,
        gridColumnStart: 1,
        gridRowEnd: 33,
        gridRowStart: 1,
      },
      {
        gridColumnEnd: 97,
        gridColumnStart: 1,
        gridRowEnd: 65,
        gridRowStart: 33,
      },
      {
        gridColumnEnd: 97,
        gridColumnStart: 1,
        gridRowEnd: 97,
        gridRowStart: 65,
      },
    ],
  },
  [GridCellLayoutVariant.R2C3]: {
    numRows: 2,
    hasSubRows: false,
    numColumns: 3,
    gridCellLayouts: [
      {
        gridColumnEnd: 33,
        gridColumnStart: 1,
        gridRowEnd: 49,
        gridRowStart: 1,
      },
      {
        gridColumnEnd: 65,
        gridColumnStart: 33,
        gridRowEnd: 49,
        gridRowStart: 1,
      },
      {
        gridColumnEnd: 97,
        gridColumnStart: 65,
        gridRowEnd: 49,
        gridRowStart: 1,
      },
      {
        gridColumnEnd: 33,
        gridColumnStart: 1,
        gridRowEnd: 97,
        gridRowStart: 49,
      },
      {
        gridColumnEnd: 65,
        gridColumnStart: 33,
        gridRowEnd: 97,
        gridRowStart: 49,
      },
      {
        gridColumnEnd: 97,
        gridColumnStart: 65,
        gridRowEnd: 97,
        gridRowStart: 49,
      },
    ],
  },
  [GridCellLayoutVariant.R1C2_2SUB_ROW_LEFT]: {
    numRows: 1,
    hasSubRows: true,
    numColumns: 2,
    gridCellLayouts: [
      {
        gridColumnEnd: 49,
        gridColumnStart: 1,
        gridRowEnd: 97,
        gridRowStart: 1,
      },
      {
        gridColumnEnd: 97,
        gridColumnStart: 49,
        gridRowEnd: 49,
        gridRowStart: 1,
      },
      {
        gridColumnEnd: 97,
        gridColumnStart: 49,
        gridRowEnd: 97,
        gridRowStart: 49,
      },
    ],
  },
  [GridCellLayoutVariant.R1C2_3SUB_ROW_LEFT]: {
    numRows: 1,
    hasSubRows: true,
    numColumns: 2,
    gridCellLayouts: [
      {
        gridColumnEnd: 49,
        gridColumnStart: 1,
        gridRowEnd: 97,
        gridRowStart: 1,
      },
      {
        gridColumnEnd: 97,
        gridColumnStart: 49,
        gridRowEnd: 33,
        gridRowStart: 1,
      },
      {
        gridColumnEnd: 97,
        gridColumnStart: 49,
        gridRowEnd: 65,
        gridRowStart: 33,
      },
      {
        gridColumnEnd: 97,
        gridColumnStart: 49,
        gridRowEnd: 97,
        gridRowStart: 65,
      },
    ],
  },
};

export const DRAWING_STYLES = [
  {
    id: "gl-draw-line",
    type: "line",
    filter: ["all", ["==", "$type", "LineString"], ["!=", "mode", "static"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": "#000000",
      "line-dasharray": [0.2, 2],
      "line-width": 2,
    },
  },

  {
    id: "gl-draw-polygon-fill",
    type: "fill",
    filter: ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
    paint: {
      "fill-color": "#45484F",
      "fill-outline-color": "#45484F",
      "fill-opacity": 0.32,
    },
  },
  {
    id: "gl-draw-polygon-stroke-active",
    type: "line",
    filter: ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": "#000000",
      "line-width": 2,
    },
  },
  {
    id: "gl-draw-radius-label",
    type: "symbol",
    filter: ["==", "meta", "radius-handle"],
    layout: {
      "text-field": "{radius} km \n {radiusMiles} mi",
      "text-anchor": "left",
      "text-offset": [1, 0],
      "text-size": 16,
    },
    paint: {
      "text-color": "#000000",
      "text-halo-color": "#FFFFFF",
      "text-halo-width": 2,
      "text-halo-blur": 1,
    },
  },
  {
    id: "gl-draw-radius-center",
    type: "symbol",
    filter: ["==", "meta", "center-handle"],
    layout: {
      "icon-allow-overlap": true,
      "icon-image": "mm-point",
      "icon-size": 0.15,
    },
    paint: {
      "icon-color": "black",
    },
  },
  {
    id: "gl-draw-radius-handle",
    type: "symbol",
    filter: ["==", "meta", "radius-handle"],
    layout: {
      "icon-allow-overlap": true,
      "icon-image": "mm-point",
      "icon-size": 0.15,
    },
    paint: {
      "icon-color": "black",
    },
  },
];

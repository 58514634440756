import { CoordinateSystem, GridRequest, GridResponse } from "../models";
import { Timer } from "../performanceIntrospection/Timer";
import { BaseResponse } from "./BaseResponse";

// ToDo: Also support EPSG:3857
export class JSONGridResponse
  implements
    GridResponse<JSONResponseBody, CoordinateSystem.WGS84>,
    BaseResponse<GridRequest<CoordinateSystem.WGS84>, JSONResponseBody>
{
  request: GridRequest<CoordinateSystem.WGS84>;
  data: JSONResponseBody;
  timer: Timer;

  constructor(response: GridResponse<JSONResponseBody, CoordinateSystem.WGS84>) {
    this.data = response.data;
    this.request = response.request;
    this.timer = response.timer;
  }
}

export type JSONResponseBody = {
  data: {
    coordinates: JSONResponseDataPoints[];
    parameter: string;
  }[];
  dateGenerated: string;
  status: string;
  user: string;
  version: string;
};

export type JSONResponseDataPoints = {
  dates: { date: string; value: any }[];
  lat: number;
  lon: number;
};

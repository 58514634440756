import { Api } from "@/api/Api";
import type { GenericLayoutCreate, Tab } from "@mm/metx-workbench.meteomatics.com";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const replaceLayouts = createAsyncThunk(
  "api/layouts/replace",
  async (payload: { tabId: Tab["id"]; data: GenericLayoutCreate[] }, _thunkAPI) => {
    return await Api.layout
      .v2ReplaceTabLayouts({
        genericLayoutCreate: payload.data,
        tabId: payload.tabId,
      })
      .then((newLayouts) => {
        return newLayouts;
      });
  },
);

import { IconOptions } from "@/overlay/components/LayerStackWindow/PoiLayerPanel/PoiInputFields";
import { iconMetaMapping } from "icon-set";
import Logger from "logging";

const logger = Logger.fromFilename(__filename);

export const getIconMetaData = (iconName: string) => {
  const iconInfo = iconMetaMapping[iconName];
  if (!iconInfo) {
    logger.warn(`Icon information doesn't exist for "${iconName}"`);
    return null as never;
  }
  return iconInfo;
};

export const createIconUrl = (iconName: string) => {
  const iconMeta = getIconMetaData(iconName);

  if (!iconMeta) {
    throw new Error(`Icon with name ${iconName} not found!`);
  }

  return new URL(iconMeta.uri, document.location.origin).href;
};

export class IconStore {
  private static _instance: IconStore;

  constructor(readonly dimension: number = 64) {}

  public static get instance(): IconStore {
    if (!IconStore._instance) {
      IconStore._instance = new IconStore();
    }

    return IconStore._instance;
  }

  /**
   * Returns the image object as a promise.
   * The resolved promise is guaranteed to have loaded image.
   *
   * The returned image object should not be directly modified because
   * the modification can persist for later retrieval.
   */
  getIcon(iconName: string): Promise<Readonly<HTMLImageElement>> {
    return new Promise<HTMLImageElement>((resolve, reject) => {
      const adjustedDimension = this.dimension * window.devicePixelRatio;
      const img = new Image(adjustedDimension, adjustedDimension);
      img.onload = () => {
        resolve(img);
      };
      img.onerror = (e: Event | string) => {
        reject(e);
      };
      // Setting src URL starts fetching the image
      img.src = createIconUrl(iconName);
    });
  }
}

/**
 * A generic interface to represent weather parameters. Can be used to represent incomplete parameter descriptions and
 * user submitted parameter descriptions that are not yet validated.
 *
 * Usage example for incomplete descriptions:
 *
 * ```ts
 * // Create a parameter related to temperature
 * let param = { parameter: parameter_t, narrowed_selection: null }
 * // autocomplete to some valid temperature parameter of the API
 * param = weatherParameter.autocomplete(param)
 * // prints `t_2m:C` (or some other valid temperature parameter `t_*`)
 * console.log(weatherParameter.toString(param))
 * ```
 *
 * Usage example for maybe invalid descriptions:
 *
 * ```ts
 * // Create parameter `t_<height>m:C`. Note: The value 0 is invalid, the valid range is `[2,20000]`.
 * let param = { parameter: parameter_t, narrowed_selection: { layer: 1, params { height: 0 } } }
 * // Validate the parameter. prints something that boils down to `{ layer: VALID, params: { height: INVALID } }`
 * console.log(weatherParameter.validate(param))
 * // remove invalid descriptions. prints `{ layer: 1, params: { } }`
 * console.log(weatherParameter.toString(param))
 * ```
 */
import type { CrossModelParameterSpecification } from "@mm/api-layers.meteomatics.com";

export interface SelectionNarrowing {
  layerIdx: number;
  fields: { [key: string]: string };
}

export interface PartialWeatherParameter {
  /**
   * Read-only reference to a base parameter, e.g. "Temperature", which are all parameters that start with `t_`.
   */
  readonly parameter: CrossModelParameterSpecification;
  /**
   * If the user has narrowed his selection this will be reflected here. For example, for the `parameter` "Temperature" the user might have already:
   * - decided to use the layer format `t_<level>:<unit>` out of the two available formats. (The other available format being `t_<measure>_<level>_<interval>:<unit>`.)
   * - decided to use the value celcius `C` for the field `unit`.
   * The key for a field is not set if the user did not narrow down his selection yet. So for the example above
   * the value would be `{ format: "t_<level>:<unit>", fields: { "unit": "C"}}` or `{ format: "t_<level>:<unit>", fields: { "unit": "C", "level": undefined }}`.
   *
   * Note that this narrowing might be autocompleted without any user interaction. Thus a narrowing should
   * not prevent the user from selecting formats or field values that are not part of the currently narrowed selection.
   * Use it to prefill forms, but not to limit form inputs.
   */
  narrowed_selection?: SelectionNarrowing;
}

export enum BaseParameterProblem {
  NotNarrowedToFormat = 0,
}

/**
 * Asserts that narrowing information exists.
 * Note: "HasLayerIdxNarrowing" carries information of which format of the parameter is selected.
 * e.g:
 * PartialWeatherParameter represents the general parameter category,
 * e.g: "wind_speed"
 *
 * while `HasLayerIdxNarrowing<PartialWeatherParameter>` represents a specific parameter:
 * e.g: wind_speed_10m:kn"
 *
 * Since there is only a single optional field on `PartialWeatherParameter`,
 * this is equivalent to a more generic:
 *
 * ```
 * export type NonNullableProperties<T> = { [P in keyof T]: NonNullable<T[P]> };
 * export type HasLayerIdxNarrowing<T extends PartialWeatherParameter> = NonNullableProperties<T>;
 * ```
 */
export type HasLayerIdxNarrowing<T extends PartialWeatherParameter> = Omit<T, "narrowed_selection"> &
  Pick<Required<T>, "narrowed_selection">;

export type AnyLayerSchema = CrossModelParameterSpecification["layers"][number];

/**
 * Convert any union type (`A | B | C | ...`) to an intersection type (`A & B & C & ...`)
 */
type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never;

export type AllFields = UnionToIntersection<AnyLayerSchema>;

export type AllFieldNames = keyof AllFields;
export type FieldNames = Exclude<AllFieldNames, "format">;

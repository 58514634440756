import { classNames } from "@/utility/jsx";
import type { ReactChild, ReactNode } from "react";

export interface FormFieldProps {
  label: string | ReactNode;
  children?: ReactChild;
  /**
   * By default, the label is semantically linked to the form component. This might result
   * in weird behaviour if the form component is built from multiple browser built-in
   * form components, since the browser will only built an association to the first built-in
   * form component. This is for example the case for a ButtonBar consisting of multiple
   * `<button />` elements. You can disable the assocation by setting this property to `true`.
   * Defaults to `false`.
   */
  noSemanticLinking?: boolean;
  className?: string;
  // Hack to align label on top and add 0.96rem margin
  // This value should work with all fields, but anyway,
  // we should figure out better solution for it, as
  // right now (with FormField) it's not easy achievable
  alignTop?: boolean;
}

export interface TogglableFormFieldProps extends FormFieldProps {
  onToggle: (enabled: boolean) => void;
  enabled: boolean;
}

export function FormField(props: FormFieldProps) {
  const { className } = props;
  const content = (
    <>
      {props.label && (
        <span className={classNames("form-field__label", props.alignTop ? "form-field__label__align-top" : "")}>
          {props.label}
        </span>
      )}
      {props.children}
    </>
  );

  if (props.noSemanticLinking) {
    return <span className={classNames("form-field", "form-field--no-semantic-link", className)}>{content}</span>;
  }
  return <label className={classNames("form-field", "form-field--semantic-link", className)}>{content}</label>;
}

export function TaggableFormField(props: TogglableFormFieldProps) {
  const content = (
    <>
      <div className="toggle">
        <input
          type="checkbox"
          checked={props.enabled}
          onChange={() => {
            props.onToggle(!props.enabled);
          }}
        />
        {props.label && <span className="form-field__label">{props.label}</span>}
      </div>
      <div className={!props.enabled ? "disabled" : ""}>{props.children}</div>
    </>
  );

  if (props.noSemanticLinking) {
    return <span className={classNames("form-field", "form-field--no-semantic-link")}>{content}</span>;
  }
  return <label className={classNames("form-field", "form-field--semantic-link")}>{content}</label>;
}

export function InputFormField(
  props: { label: string } & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
) {
  const { label, ...inputProps } = props;
  return (
    <FormField label={label}>
      <input type="text" {...inputProps} />
    </FormField>
  );
}

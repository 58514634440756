import { FormField } from "@/overlay/components/form-field";
import { useEffect } from "react";
import { useController } from "react-hook-form";
import { useValueNameCreator } from "../../SmartFormUtils/FormHooks/useFormInstructions";
import type { InputInstruction } from "../../SmartFormUtils/commonTypeUtils";

type InputProps = {
  label: string;
  instruction: InputInstruction<boolean>;
};

export function FormCheckbox({ label, instruction }: InputProps) {
  const { formMethods, currValue } = instruction;
  const { createInputName } = useValueNameCreator(instruction);

  const {
    field: { value, onChange, ref, name },
  } = useController({
    name: createInputName(),
    control: formMethods.control,
    defaultValue: currValue,
  });

  useEffect(() => {
    formMethods.setValue(createInputName(), currValue);
  }, [createInputName, currValue, formMethods]);

  return (
    <FormField label={label}>
      <input
        ref={ref}
        name={name}
        type="checkbox"
        onChange={(e) => {
          onChange(e.target.checked);
        }}
        checked={value}
      />
    </FormField>
  );
}

import type { Components as TephigramType } from "@mm/tephigram";
import { defineCustomElements as tephigramElements } from "@mm/tephigram/loader";
import type { Components as ZeusType } from "@mm/zeus";
import { defineCustomElements as zeusElements } from "@mm/zeus/loader";
import type { DetailedHTMLProps, HTMLAttributes } from "react";

// Fix React JSX typing to support web components
declare global {
  namespace JSX {
    interface IntrinsicElements {
      "mm-tephigram": MMTephigramProps;
      "mm-legend": MMLegendProps;
      "mm-toggle-button": MMToggleButtonProps;
      "mm-loader": MMLoaderProps;
      "mm-button": MMButtonProps;
      "mm-fab-button": MMFabButtonProps;
      "mm-menu-button": MMMenuButtonProps;
      "mm-toggle-ui-button": MMToggleUIButtonProps;
      "mm-tooltip-wrapper": MMTooltipWrapperProps;
    }
  }
}

export type MMTephigramProps = DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> &
  Partial<TephigramType.MmTephigram>;

export type MMLegendProps = DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> &
  Partial<TephigramType.MmLegend>;

export type MMToggleButtonProps = DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> &
  Partial<ZeusType.MmToggleButton>;

export type MMLoaderProps = DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> & Partial<ZeusType.MmLoader>;

export type MMButtonProps = DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> & Partial<ZeusType.MmButton>;

export type MMTooltipWrapperProps = DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> &
  Partial<ZeusType.MmTooltipWrapper>;

export type MMFabButtonProps = DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> &
  Partial<ZeusType.MmFabButton>;

export type MMMenuButtonProps = DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> &
  Partial<ZeusType.MmMenuButton>;

export type MMToggleUIButtonProps = DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> &
  Partial<ZeusType.MmToggleUiButton>;

// Define tephigram component
tephigramElements();
zeusElements();

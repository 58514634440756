import { groundStyles, languageOptions } from "@/constants/maptilerStyleOptions";
import { useMemo } from "react";

export const useLanguageOptions = () =>
  useMemo(
    () =>
      languageOptions.map((lang) => ({
        value: lang.value,
        label: lang.label(),
      })),
    [],
  );

export const useMaptilerStyleOptions = () =>
  useMemo(() => groundStyles.map((style) => ({ value: style.id, label: style.label() })), []);

/**
 * Desktop represents an area containing windows. Windows might be in two modes:
 *
 * - they might be in a managed area, called a <WindowTrack>. Windows in this
 *   area automatically do not collide with one another. The WindowTrack might
 *   toggle the visibility of Windows to make room for newly opened Windows,
 *   for example, using a `least recently used policy` or a `least recently
 *   opened policy` or a `only most recently opened window policy`.
 * - otherwise windows might be floating. Meaning they are arbitrarily positioned
 *   on the screen per drag and drop. They are not automatically closed by
 *   the windowing system.
 */
import { Fragment, useCallback } from "react";
import { useDragLayer, useDrop } from "react-dnd";

import type { WindowKind } from "@/overlay/components";
import { classNames } from "@/utility/jsx";

import type { RootState } from "@/reducer";
import { useSelector } from "react-redux";
import {
  DesktopContext,
  type DesktopState,
  type DraggingWindowState,
  type PartialWindowState,
  type WindowInstanceId,
  type WindowPosition,
  useDesktop,
  useDesktopProviderValue,
} from "./DesktopContext";
import { type DragItemType, allDragItemTypes } from "./DragItemType";
import type { TrackId } from "./TrackId";
import type { WindowForwardedHtmlProps, WindowIdentityProps } from "./Window";
import { WindowTrack } from "./WindowTrack";
import { useIsModalVisible, useTracks } from "./hooks";
import { clampPositionToDraggableArea } from "./utils";

export type ComponentFactoryProps<K extends WindowKind> = WindowIdentityProps<K> &
  WindowForwardedHtmlProps & { key?: any };
export type ComponentFactoryFn<K extends WindowKind = WindowKind> = (
  key: string,
  id: ComponentFactoryProps<K>,
) => JSX.Element;

export type ComponentFactory<K extends WindowKind = WindowKind> = {
  component: ComponentFactoryFn<K>;
  defaultWindowState: PartialWindowState;
  instanceId: WindowInstanceId;
  dragItemType: DragItemType;
  /**
   * Default value is true
   */
  draggable?: boolean;
};

// TODO: this set of configuration options to split managed from unmanaged windows is somehow ugly. Looking at the current code, maybe DesktopContent should be split into DesktopContent and a separate DesktopTrack component
export interface DesktopProps {
  className?: string;
  /**
   * Only show a subset of windows. the function should return `true`, if the element should be rendered.
   * Defaults to rendering all windows.
   */
  filter?: (_: { position: WindowPosition; instanceId: WindowInstanceId }) => boolean;
  /**
   * A list of tracks that should be rendered
   */
  tracks: TrackId[];

  /**
   * Whether empty tracks should be rendered
   */
  renderEmptyTracks?: boolean;
}

export function DesktopProvider(props: { value: DesktopState; children: any }) {
  // providers must be established in a parent component. Otherwise, they are not yet
  // available in hooks. Thus, Desktop is split into <DesktopProvider>, which creates all contexts,
  // and <DesktopContent>, which contains the actual implementation.
  const desktop = useDesktopProviderValue(props.value);

  return <DesktopContext.Provider value={desktop}>{props.children}</DesktopContext.Provider>;
}

export function DesktopContent(props: DesktopProps) {
  const { id, dragableArea, dispatch } = useDesktop();
  const isLayoutArrangementActive = useSelector((state: RootState) => state.ui.isLayoutArrangementActive);
  const { tracks, unmanaged } = useTracks(props.tracks, !!props.renderEmptyTracks, props.filter);
  const isModalVisible = useIsModalVisible();

  const moveWindow = useCallback(
    (state: DraggingWindowState, left: number, top: number) => {
      const newPosition = { isManaged: false, left, top, lastUse: performance.now() };
      dispatch({ type: "setPosition", windowId: state.instanceId, position: newPosition });
    },
    [dispatch],
  );

  const isDragging = useDragLayer((monitor) => monitor.isDragging());

  const [, drop] = useDrop({
    // all window types can be freely moved on the desktop
    accept: allDragItemTypes,
    drop(item: DraggingWindowState, monitor) {
      const didDrop = monitor.didDrop();

      if (didDrop) {
        return;
      }

      const delta = monitor.getDifferenceFromInitialOffset() as {
        x: number;
        y: number;
      };

      const left = Math.round(item.position.left + delta.x);
      const top = Math.round(item.position.top + delta.y);

      const { x, y } = clampPositionToDraggableArea(left, top, dragableArea);

      moveWindow(item, x, y);
      return undefined;
    },
  });

  return (
    <div
      ref={drop}
      className={classNames(
        "window-manager__desktop",
        isDragging && !isLayoutArrangementActive && "window-manager__desktop--is-dragging",
        isModalVisible && "window-manager__desktop--contains-visible-modal",
        props.className && props.className,
      )}
      data-desktop-id={id}
    >
      {Array.from(tracks.entries()).map(([trackId, windows]) => {
        return <WindowTrack id={trackId} key={trackId} windows={windows} />;
      })}
      {unmanaged.map(({ renderer, instanceId }) => (
        <Fragment key={instanceId}>{renderer()}</Fragment>
      ))}
    </div>
  );
}

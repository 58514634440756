import type { IHeaderParams } from "ag-grid-community";
import { type FocusEvent, type KeyboardEvent, useEffect, useRef, useState } from "react";

import styles from "./style.module.scss";

export function PoiColumnHeader({
  displayName,
  column,
  api,
  onRemoveColumn,
  onColumnNameChange,
}: IHeaderParams & {
  onRemoveColumn: (displayName: string) => void;
  onColumnNameChange: (newName: string, oldName: string) => void;
}) {
  const [editModeEnabled, setEditModeEnabled] = useState(false);
  const editInputRef = useRef<HTMLInputElement>(null);

  const handleEditTitle = () => {
    api.stopEditing(true);
    setEditModeEnabled(true);
  };

  const handleTitleChanged = (e: FocusEvent<HTMLInputElement, Element>) => {
    // Make sure that target is HTMLInputElement
    const target = e.target as HTMLInputElement;
    const newName = target.value.trim();
    if (newName !== displayName) {
      onColumnNameChange(newName, displayName);
    }
    setEditModeEnabled(false);
  };

  const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    // Make sure that target is HTMLInputElement
    const target = e.target as HTMLInputElement;

    if (e.key === "Escape") {
      setEditModeEnabled(false);
    }

    if (e.key === "Enter") {
      const newName = target.value.trim();
      if (newName !== displayName) {
        onColumnNameChange(newName, displayName);
      }
      setEditModeEnabled(false);
    }
  };

  useEffect(() => {
    if (editModeEnabled) {
      editInputRef.current?.focus();
      editInputRef.current?.select();
    }
  }, [editModeEnabled, editInputRef]);

  return (
    <div className={styles["poi-column"]}>
      <div className={styles["poi-column__editable-area"]} onDoubleClick={handleEditTitle}>
        {displayName}
      </div>
      <div>
        <button
          className={styles["header-button"]}
          onClick={() => {
            onRemoveColumn(column.getColId());
          }}
          type={"button"}
        >
          <span className="material-icons">delete_outline</span>
        </button>
      </div>
      {editModeEnabled && (
        <input
          ref={editInputRef}
          className={styles["poi-column__edit-input"]}
          type="text"
          defaultValue={displayName}
          onBlur={handleTitleChanged}
          onKeyUp={handleKeyUp}
        />
      )}
    </div>
  );
}

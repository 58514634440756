import type { ModelSchema } from "@mm/api-layers.meteomatics.com";
import type { PartialWeatherParameter } from "weather-parameter-utils";

/**
 * Selects an appropriate weather model based on the previous model (if provided) and new parameters.
 *
 * This function checks if the optional `previousModel` is available in the `newCurrentParam.parameter.available_models` array.
 * If it is, the function returns the `previousModel`. If not, it checks if there are any models available in the array.
 * If there are available models, it returns the first model from the array. If the array is empty or undefined,
 * it defaults to returning the string "mix". If no models are available, it returns an empty string.
 *
 * @param parameter - The new set of weather parameters, containing an array of available models.
 * @param previousModel - The model used previously (optional).
 * @returns The selected model as a string. It could be the previous model, the first available model, "mix", or an empty string.
 */
export function getDefaultModelName(
  parameter: PartialWeatherParameter,
  previousModel?: string,
): ModelSchema["identifier"] {
  const availableModels = parameter.parameter.available_models;

  if (previousModel && availableModels?.includes(previousModel)) {
    return previousModel;
  }

  if (availableModels?.includes("mix")) {
    return "mix";
  }

  if (availableModels && availableModels.length > 0) {
    return availableModels[0];
  }

  console.error("No available models found.");
  return "";
}

import { type StateMachine, createMachine } from "xstate";

import { addOrUpdateTempLayer, removeTempLayerIfExists } from "./tempLayerStateActions";
import type { TempLayerStateEventUnion } from "./tempLayerStateEvents";
import type { DefaultTempLayerCtx } from "./tempLayerStateMachineCtxTypes";

type TempLayerTypeState = {
  value: "DEFAULT";
  context: DefaultTempLayerCtx;
};

export type TempLayerStateMachine = StateMachine<
  DefaultTempLayerCtx,
  any,
  TempLayerStateEventUnion,
  TempLayerTypeState
>;

/**
 * TempLayerStateMachine keeps track of map layers that should only be rendered temporarily
 * for the purpose of preview, showing complex map visual effect, etc.
 *
 * Any layers placed within this state machine are automatically passed to the Scene which
 * then renders the map.
 */
export const createTempLayerStateMachine = (
  initialCtx: DefaultTempLayerCtx = { tempLayersByMapId: {} },
  initialState = "DEFAULT",
): TempLayerStateMachine => {
  return createMachine<DefaultTempLayerCtx, TempLayerStateEventUnion, TempLayerTypeState>({
    id: "TEMP_LAYER_STATE",
    initial: initialState,
    context: initialCtx,
    states: {
      DEFAULT: {
        on: {
          addOrUpdateTempLayer: {
            internal: true,
            actions: [addOrUpdateTempLayer],
          },
          removeTempLayerIfExists: {
            internal: true,
            actions: [removeTempLayerIfExists],
          },
        },
      },
    },
    predictableActionArguments: true,
  });
};

export const defaultTempLayerStateMachine = createTempLayerStateMachine();

import { type AsyncResult, SynchrounousState } from "@/cache/AsyncResult";
import type { ColorMapping } from "@/cache/ColorMapCache";
import type { TileGeometry } from "@/cache/SpatioTemporalTileCache/TileGeometry";
import type { Program, WindMetaData } from "@/layers/wind/gl";
import { defaultWindParticleSettings } from "@/layers/wind/layer";
import {
  createDrawProgram,
  createQuadProgram,
  createTextureProgram,
  createTilingProgram,
  createUpdateProgram,
} from "./shaders";
import { bindAttribute, bindFramebuffer, bindTexture, createBuffer, createTexture } from "./util";

export function compilePrograms(gl: WebGLRenderingContext) {
  return {
    drawProgram: createDrawProgram(gl),
    updateProgram: createUpdateProgram(gl),
    textureProgram: createTextureProgram(gl),
  };
}

export function compileTilingPrograms(gl: WebGLRenderingContext) {
  return {
    tilingPrograms: createTilingProgram(gl),
    quadProgram: createQuadProgram(gl),
    textureProgram: createTextureProgram(gl),
  };
}

export type RendererPrograms = ReturnType<typeof compilePrograms>;

export function createEmptyTextures(gl: WebGLRenderingContext, width: number, height: number) {
  const emptyPixels = new Uint8Array(width * height * 4);
  return {
    backgroundTexture: createTexture(gl, gl.NEAREST, emptyPixels, width, height),
    // screen textures to hold the drawn screen for the previous and the current frame
    screenTexture: createTexture(gl, gl.NEAREST, emptyPixels, width, height),
  };
}

export type RendererTextures = ReturnType<typeof createEmptyTextures>;

export function createParticleStateTextures(gl: WebGLRenderingContext, particlesCount: number) {
  // Assume that particle state always will be square
  const particleRes = Math.ceil(Math.sqrt(particlesCount));
  // Multiply by 4, because texture has 4 channels - red, green, blue
  const particleStatePixels = new Uint8Array(particlesCount * 4);

  for (let i = 0; i < particleStatePixels.length; i++) {
    particleStatePixels[i] = Math.floor(Math.random() * 256); // randomize the initial particle positions
  }

  // textures to hold the particle state for the current and the next frame
  return {
    particleStateTexture0: createTexture(gl, gl.NEAREST, particleStatePixels, particleRes, particleRes),
    particleStateTexture1: createTexture(gl, gl.NEAREST, particleStatePixels, particleRes, particleRes),
  };
}

export type ParticleStateTextures = ReturnType<typeof createParticleStateTextures>;

export function createParticleIndexBuffer(gl: WebGLRenderingContext, particlesCount: number) {
  const particleIndices = new Float32Array(particlesCount);
  for (let i = 0; i < particlesCount; i++) {
    particleIndices[i] = i;
  }
  return createBuffer(gl, particleIndices);
}

export function uploadColorMap(gl: WebGLRenderingContext, colorMap: ColorMapping) {
  const colorMapTexture = gl.createTexture();
  gl.bindTexture(gl.TEXTURE_2D, colorMapTexture);
  colorMap.texture.upload(gl);
  gl.bindTexture(gl.TEXTURE_2D, null);

  return colorMapTexture;
}

/**
 * Given a image (WebGLTexture), draw it on the quad buffer.
 */
export function drawTexture(
  gl: WebGLRenderingContext,
  texture: WebGLTexture | null,
  opacity: number,
  quadBuffer: WebGLBuffer | null,
  shader: AsyncResult<Program>,
) {
  const { synchronous } = shader;

  if (
    synchronous === SynchrounousState.StillPending ||
    synchronous === SynchrounousState.PermanentlyFailed ||
    synchronous === SynchrounousState.NotRequested
  ) {
    return;
  }

  const { program, parameters } = synchronous;

  gl.useProgram(program);

  // Feed in the data to the WebGL context
  // parameters.a_pos is equivalent to gl.getAttribLocation - Basically it gives the data location in shader buffer
  bindAttribute(gl, quadBuffer, Number(parameters.a_pos), 2);
  // Bind the texture to location "2"
  bindTexture(gl, texture, 2);
  // "2" represents the location of where the texture is
  gl.uniform1i(parameters.u_screen, 2);
  gl.uniform1f(parameters.u_opacity, opacity);

  // Now that we set the metrices in GL context, we run the shader
  gl.drawArrays(gl.TRIANGLES, 0, 6);
}

/**
 * Given a wind data, run the shader to draw particles
 */
export function drawParticles(
  gl: WebGLRenderingContext,
  particleIndexBuffer: WebGLBuffer | null,
  windMapTexture: WebGLTexture | null,
  particleStateTexture: WebGLTexture | null,
  colorMapTexture: WebGLTexture | null,
  metadata: WindMetaData,
  numParticles: number,
  particleSize: number,
  shader: AsyncResult<Program>,
) {
  const { synchronous } = shader;
  if (
    synchronous === SynchrounousState.StillPending ||
    synchronous === SynchrounousState.PermanentlyFailed ||
    synchronous === SynchrounousState.NotRequested
  ) {
    return;
  }
  const { program, parameters } = synchronous;

  gl.useProgram(program);

  bindAttribute(gl, particleIndexBuffer, Number(parameters.a_index), 1);
  bindTexture(gl, windMapTexture, 0);
  bindTexture(gl, particleStateTexture, 1);
  bindTexture(gl, colorMapTexture, 2);

  gl.uniform1i(parameters.u_wind, 0);
  gl.uniform1i(parameters.u_particles, 1);
  gl.uniform1i(parameters.u_color_map, 2);

  gl.uniform1f(parameters.u_particles_res, Math.ceil(Math.sqrt(numParticles)));
  gl.uniform1f(parameters.u_particle_size, particleSize);
  gl.uniform2f(parameters.u_wind_min, metadata.uMin, metadata.vMin);
  gl.uniform2f(parameters.u_wind_max, metadata.uMax, metadata.vMax);

  gl.uniform1f(parameters.u_device_pixel_ratio, window.devicePixelRatio);

  gl.drawArrays(gl.POINTS, 0, numParticles);
}

export function updateParticles(
  gl: WebGLRenderingContext,
  windMapTexture: WebGLTexture | null,
  { particleStateTexture0, particleStateTexture1 }: ParticleStateTextures,
  quadBuffer: WebGLBuffer | null,
  metadata: WindMetaData,
  amount: number,
  shader: AsyncResult<Program>,
) {
  const { synchronous } = shader;

  if (
    synchronous === SynchrounousState.StillPending ||
    synchronous === SynchrounousState.PermanentlyFailed ||
    synchronous === SynchrounousState.NotRequested
  ) {
    return;
  }

  const { program, parameters } = synchronous;

  // Creating new buffer may be little bit expensive
  // TODO Figure out on how to reuse on, or pass in function parameters
  const framebuffer = gl.createFramebuffer();

  bindFramebuffer(gl, framebuffer, particleStateTexture1);

  gl.viewport(0, 0, amount, amount);

  gl.useProgram(program);

  bindAttribute(gl, quadBuffer, Number(parameters.a_pos), 2);

  bindTexture(gl, windMapTexture, 0);
  bindTexture(gl, particleStateTexture0, 1);

  gl.uniform1i(parameters.u_wind, 0);
  gl.uniform1i(parameters.u_particles, 1);

  gl.uniform1f(parameters.u_rand_seed, Math.random());
  gl.uniform2f(parameters.u_wind_res, metadata.width, metadata.height);
  gl.uniform2f(parameters.u_wind_min, metadata.uMin, metadata.vMin);
  gl.uniform2f(parameters.u_wind_max, metadata.uMax, metadata.vMax);
  gl.uniform1f(parameters.u_speed_factor, defaultWindParticleSettings.speedFactor);
  gl.uniform1f(parameters.u_drop_rate, defaultWindParticleSettings.dropRate);
  gl.uniform1f(parameters.u_drop_rate_bump, defaultWindParticleSettings.dropRateBump);

  gl.drawArrays(gl.TRIANGLES, 0, 6);

  bindFramebuffer(gl, null, null);
}

export function swapParticleStateTextures(particleStateTextures: ParticleStateTextures) {
  return {
    particleStateTexture0: particleStateTextures.particleStateTexture1,
    particleStateTexture1: particleStateTextures.particleStateTexture0,
  };
}

export function drawTile(gl: WebGLRenderingContext, tileInstance: TileGeometry, depth: number) {
  const [n, w, s, e] = tileInstance.mercator(0);

  gl.bufferData(
    gl.ARRAY_BUFFER,
    new Float32Array([
      // lower left triangle of the quad (◣), counter-clockwise
      w,
      s,
      depth,
      e,
      s,
      depth,
      w,
      n,
      depth,
      // top right triangle of the quad (◥), counter-clockwise
      w,
      n,
      depth,
      e,
      s,
      depth,
      e,
      n,
      depth,
    ]),
    gl.STATIC_DRAW,
  );
  gl.drawArrays(gl.TRIANGLES, 0, 6);
}

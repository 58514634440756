import { type Interpreter, type InterpreterFrom, State, type StateMachine, StateValueFrom, interpret } from "xstate";

export function resetMachine(service: Interpreter<any, any, any, any, any>) {
  service.stop();
  service.start(service.machine.initialState);
}

export function createAndStartService<M extends StateMachine<any, any, any, any>>(machine: M, startState?: StateValueFrom<M>): InterpreterFrom<M> {
  const service = interpret(machine) as InterpreterFrom<M>;

  if (startState){
    service.start(startState);
  }else{
    service.start();
  }
  return service;
}

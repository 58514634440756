import type { TileGetter } from "../PVSTileService/TileGetters/TileGetter";
import type { TimeSlice } from "./TimeSlice";

export enum TimeSliceIteratorDirection {
  FutureToPast = -1,
  PastToFuture = 1,
}

/**
 * Iterator over tiles. Each iteration yields a set of tiles with identical datetimes.
 */
export class TimeSliceIterator<TTileGetter extends TileGetter<any, any>>
  implements IterableIterator<TimeSlice<TTileGetter>>
{
  private currIdx: number;
  /**
   *
   * @param sortedTimeSlices time slices sorted monotonically from past to future
   * @param currIdx start index of the iterator within `sortedTimeSlices`
   * @param dir direction of the iterator
   */
  constructor(
    private sortedTimeSlices: TimeSlice<TTileGetter>[],
    private dir: TimeSliceIteratorDirection,
    currIdx?: number,
  ) {
    if (currIdx == null) {
      this.currIdx = dir < 0 ? sortedTimeSlices.length - 1 : 0;
    } else {
      this.currIdx = currIdx;
    }
  }

  next(): IteratorResult<TimeSlice<TTileGetter>> {
    if (this.currIdx < 0 || this.currIdx >= this.sortedTimeSlices.length) {
      return { done: true, value: null };
    }

    const value = this.sortedTimeSlices[this.currIdx];
    this.currIdx += this.dir;

    return {
      done: false,
      value,
    };
  }

  [Symbol.iterator](): IterableIterator<TimeSlice<TTileGetter>> {
    return this;
  }
}

import _ from "lodash";
import { assign } from "xstate";
import { wrongActionPlacementError } from "../state-machine-utils/stateMachineUtils";
import { TempLayerStateEventType, type TempLayerStateEventUnion } from "./tempLayerStateEvents";
import type { DefaultTempLayerCtx } from "./tempLayerStateMachineCtxTypes";

export const addOrUpdateTempLayer = assign(
  (ctx: DefaultTempLayerCtx, event: TempLayerStateEventUnion): DefaultTempLayerCtx => {
    if (event.type === TempLayerStateEventType.addOrUpdateTempLayer) {
      return {
        tempLayersByMapId: {
          ...ctx.tempLayersByMapId,
          [event.mapId]: {
            [event.layer.id]: event.layer,
          },
        },
      };
    }
    throw wrongActionPlacementError(addOrUpdateTempLayer.name);
  },
);

export const removeTempLayerIfExists = assign(
  (ctx: DefaultTempLayerCtx, event: TempLayerStateEventUnion): DefaultTempLayerCtx => {
    if (event.type === TempLayerStateEventType.removeTempLayerIfExists) {
      const targetLayerId = event.layerId;
      const updatedLayersByMapId = _.cloneDeep(ctx).tempLayersByMapId;

      for (const mapId in ctx.tempLayersByMapId) {
        if (targetLayerId in updatedLayersByMapId[+mapId]) {
          // Delete the layer from the map's layers
          delete updatedLayersByMapId[+mapId][targetLayerId];
          if (Object.keys(updatedLayersByMapId[+mapId])) {
            // Delete the whole map's layer object once it's empty
            delete updatedLayersByMapId[+mapId];
          }
          break;
        }
      }

      return {
        tempLayersByMapId: updatedLayersByMapId,
      };
    }
    return ctx;
  },
);

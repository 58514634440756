// Based on list - https://meteomatics.atlassian.net/wiki/spaces/SAL/pages/1212907560/Internal+API+Extended+Documentation+for+internal+use+and+customers+on+inquiry+but+not+to+be+published

import type { ParameterUnit } from "@mm/api.meteomatics.com";

/**
 * Calibrated options of parameters include data calibration using station data nearby
 * to enhance the quality of data. And only some parameters and their options (near ground altitude data) have it.
 *
 * Currently (09.05.2023) calibrated options are by default disabled on API, but a user can opt-in to retrieve
 * the data by `calibrated=true` in the API query parameter. And for only some special customers, it is enabled by default.
 *
 * The purpose of this mapping is to show the calibration UI only for the parameters that have calibration available.
 * Ideally we provide this information from api-layers.meteomatics.com, but this is not possible
 * as API team doesn't have a single place for this information to automate the process.
 */
export const calibratedParameters = [
  "t_2m:.*", // t_2m:<unit>
  "t_.*_2m_.*:.*", // t_<measure>_2m_<interval>:<unit>
  "dew_point_2m:.*", // dew_point_2m:<unit>
  "relative_humidity_2m:p",
  "relative_humidity_.*_2m_.*:p", // relative_humidity_<measure>_2m_<interval>:p
  "wind_speed_10m:ms",
  "wind_speed_mean_10m_.*:.*", // wind_speed_<measure>_10m_<interval>:<unit> for mean, intervals up to 24h and units (ms, kmh, kn)
  "wind_gusts_10m_.*:.*", // wind_gusts_10m_<intervals>:<units>
  "sfc_pressure:hPa",
  "msl_pressure:hPa",
  "precip_.*:mm", // precip_<interval>:mm
];

export function hasCalibratedAvailable(paramUnit?: ParameterUnit) {
  if (!paramUnit) return false;
  return !!calibratedParameters.find((parameterRegex) => paramUnit.match(parameterRegex));
}

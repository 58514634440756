// Show this if the Layout has empty grid cells
import { getBorderClasses } from "@/grid/utils";
import { DragItemType } from "@/overlay/components/window/DragItemType";
import type { RootState } from "@/reducer";
import { classNames } from "@/utility/jsx";
import type { GridCellLayout } from "@mm/metx-workbench.meteomatics.com";
import { type ReactNode, useRef } from "react";
import { type DropTargetMonitor, useDrag, useDrop } from "react-dnd";
import { useSelector } from "react-redux";
import "./style.scss";

type GridSortableElementProps = {
  id: number;
  gridCellLayout: GridCellLayout;
  onDrop: (sourceId: number, targetId: number) => void;
  className?: string;
  children?: ReactNode;
  disabled?: boolean;
};

interface Item {
  id: number;
  type: string;
}

export function GridSortableElement({
  id,
  gridCellLayout,
  className,
  children,
  onDrop,
  disabled = false,
}: GridSortableElementProps) {
  const ref = useRef(null);
  const isLayoutArrangementActive = useSelector((state: RootState) => state.ui.isLayoutArrangementActive);

  const [, connectDrag] = useDrag({
    item: { id: id, type: DragItemType.GridElement, position: gridCellLayout },
    canDrag: isLayoutArrangementActive,
  });
  const [{ isHovered, draggedItem }, connectDrop] = useDrop({
    accept: DragItemType.GridElement,
    collect: (monitor: DropTargetMonitor) => ({
      draggedItem: monitor.getItem(),
      isHovered: monitor.isOver({ shallow: true }),
      canDrop: monitor.canDrop(),
    }),
    drop: (item: Item) => {
      onDrop(item.id, id);
    },
  });
  connectDrag(ref);
  connectDrop(ref);
  const isHighlighted = isHovered && draggedItem.id !== id;

  return (
    <div
      ref={ref}
      className={classNames(
        "grid__cell",
        className,
        getBorderClasses(gridCellLayout),
        disabled || isLayoutArrangementActive ? "disabled__cell" : "",
        isHighlighted ? "highlighted" : "",
      )}
      style={gridCellLayout}
    >
      {children}
      {isLayoutArrangementActive && (
        <div className={"grid-arrangement-overlay"}>
          <span className="material-icons grid-arrangement-icon">open_with</span>
        </div>
      )}
    </div>
  );
}

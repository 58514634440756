import { type AsyncResult, SynchrounousState, createResolvedAsyncResult } from "@/cache/AsyncResult";
import { emptyFeatureCollection, emptyGeoJsonSource } from "@/geojson";
import { LayerBase } from "@/layers";
import { ChangeDetector } from "@/layers/ChangeDetector";
import type { ScenePublicApi } from "@/layers/Compositor";
import type { PoiLayer } from "@/reducer/client-models/PoiLayer";
import { safeFmt } from "@/utility/safeFmt";
import { Area, CoordinateSystem, type GeoJSONFeatureCollection } from "@mm/api.meteomatics.com";
import type { BaseRequest } from "@mm/api.meteomatics.com/lib/models/BaseRequest";
import type {
  BarbsLayer,
  GridLayer,
  GuiTimeZone,
  IsoLinesLayer,
  LightningLayer,
  PressureSystemLayer,
  StationLayer,
  SymbolLayer,
  TropicalCycloneLayer,
  WeatherFrontsLayer,
  WindAnimationLayer,
} from "@mm/metx-workbench.meteomatics.com";
import { debounce } from "lodash";
import Logger from "logging";
import type { DateTime } from "luxon";
import type * as Mapbox from "mapbox-gl";
import type { GeoJSONSource } from "mapbox-gl";
import { computeGridDimensionFromStep } from "../utility/getGridBoundingBox";

const logger = Logger.fromFilename(__filename);

export type SupportedGeoJSONLayers =
  | GridLayer
  | SymbolLayer
  | BarbsLayer
  | IsoLinesLayer
  | LightningLayer
  | StationLayer
  | PoiLayer
  | PressureSystemLayer
  | TropicalCycloneLayer
  | WeatherFrontsLayer
  | WindAnimationLayer;

export abstract class MapboxGeoJSONLayer<LayerDesc extends SupportedGeoJSONLayers> extends LayerBase<LayerDesc> {
  // Mapbox
  /**
   * A string ID for the MapBox layer.
   */
  readonly mapbox_id: string;
  readonly mapbox_source: string;

  // There is no actual officially available typing from Mapbox
  readonly _layerSourceCache: any;

  private timeChanged: ChangeDetector<DateTime> = new ChangeDetector<DateTime>();
  private geoJsonData: ChangeDetector<GeoJSONFeatureCollection | SynchrounousState> =
    new ChangeDetector<GeoJSONFeatureCollection>();

  private latestSourceData: AsyncResult<GeoJSONFeatureCollection> = createResolvedAsyncResult(emptyFeatureCollection);

  protected debouncedUpdateTrigger = debounce(
    () => {
      this.updateTrigger();
      // Note: We always trigger the debounce update at least every second
      // as WindAnimation's frequent update event prevents GeoJSON layers to render due to debounce.
    },
    300,
    { leading: true, maxWait: 1000 },
  );

  constructor(
    uid: number,
    props: LayerDesc,
    scene: ScenePublicApi,
    timezone: GuiTimeZone,
    type: "symbol" | "line" = "symbol",
  ) {
    super(uid, props, scene, timezone);
    this.mapbox_id = this.humanReadableId();
    this.mapbox_source = safeFmt`source_${this.humanReadableId()}`;

    const map = scene.getMapboxMap();
    // Add an empty source.
    map.addSource(this.mapbox_source, emptyGeoJsonSource());
    map.addLayer({
      id: this.mapbox_id,
      source: this.mapbox_source,
      type,
    });

    const layer = map.getLayer(this.mapbox_id);
    this._layerSourceCache = map.style._getLayerSourceCache(layer);
  }

  abstract updateTrigger(): void;

  abstract peekLayerCache(request: BaseRequest): AsyncResult<GeoJSONFeatureCollection>;

  abstract createRequest(dateTimeWithOffset: DateTime): BaseRequest;

  get mapboxIndex(): string {
    return this.mapbox_id;
  }

  protected updateMapboxPaintProperties(props: Mapbox.AnyPaint) {
    for (const entry of Object.entries(props)) {
      this.scene.getMapboxMap().setPaintProperty(this.mapbox_id, entry[0], entry[1]);
    }
  }

  protected updateMapboxLayoutProperties(props: Mapbox.AnyLayout) {
    for (const entry of Object.entries(props)) {
      this.scene.getMapboxMap().setLayoutProperty(this.mapbox_id, entry[0], entry[1]);
    }
  }

  isRebuffering(time: DateTime): boolean {
    const dateTimeWithOffset = this.scene.getDateTimeWithOffset(time);
    const request = this.createRequest(dateTimeWithOffset);
    const { synchronous } = this.peekLayerCache(request);
    return synchronous === SynchrounousState.StillPending;
  }

  moveZIndex(beforeLayerId?: string) {
    const map = this.scene.getMapboxMap();
    map.moveLayer(this.mapbox_id, beforeLayerId);
  }

  /**
   * removeLayer removes MapboxGeoJSON source and layers.
   * see also LayerBase abstract removeLayer() method comment
   */
  removeLayer(): void {
    this.scene.getMapboxMap().removeLayer(this.mapbox_id);
    this.scene.getMapboxMap().removeSource(this.mapbox_source);
  }

  releaseLayer(): void {
    if (this.latestSourceData.synchronous === SynchrounousState.StillPending) {
      const { cancel } = this.latestSourceData;
      cancel?.();
    }
  }

  /**
   * TODO: We should consider cleaning up this function,
   * since the bounding box calculation for Globe mode is now also available from mapbox.
   * And to clean up the whole thing here with the distance, which then comes back as width and height.
   * Notes:
   * - there are two computeBoundingBoxForMercatorProjection functions
   *    - here below this function
   *    - and metx-core/src/layers/wind/utils.ts for the wind
   */
  protected computeBoundingBox(distance: number) {
    const map = this.scene.getMapboxMap();
    const bounds = map.getBounds();
    const canvas = map.getCanvas();
    const { verticalGridNum, horizontalGridNum } = computeGridDimensionFromStep(canvas, distance);
    const area = new Area(CoordinateSystem.WGS84, {
      east: bounds.getEast(),
      west: bounds.getWest(),
      north: bounds.getNorth(),
      south: bounds.getSouth(),
    });
    return { area, height: verticalGridNum, width: horizontalGridNum };
  }

  /**
   * Used to construct a unique ID to indentify different layers, which gets passed as a MapBox source identifier.
   */
  abstract humanReadableId(): string;

  // /**
  //  * Gets the last AsyncResult object used to update the Mapbox source.
  //  * @see {MapboxGeoJSONLayer.updateSource}
  //  */
  // public getLatestSourceData(): AsyncResult<GeoJSONFeatureCollection> {
  //   return this.latestSourceData;
  // }

  /**
   * @param result Async GeoJSONFeatureCollecton to pass as a MapBox source.
   * @param time The time at which the map is showing.
   * @param sourceUpdated optional callback
   * @returns
   */
  protected updateSource(
    result: AsyncResult<GeoJSONFeatureCollection>,
    dateTimeWithOffset: DateTime,
    sourceUpdated?: (data: GeoJSONFeatureCollection) => void,
  ) {
    this.latestSourceData = result;
    const { synchronous: data, asynchronous } = result;
    if (data === SynchrounousState.StillPending) {
      // Keep retrying until the data becomes ready or permanently failed.
      // We do not want to update the mapbox source data with the result here, because the timestamp/viewport
      // might have changed.

      asynchronous
        .then(() => {
          this.beforeRender();
        })
        .catch((e) => {
          if (!e.isCancelled) {
            this.beforeRender();
          }
        });
    }

    const timeChanged = this.timeChanged.changed(dateTimeWithOffset);
    if (this.geoJsonData.changed(data)) {
      if (data === SynchrounousState.NotRequested) {
        return;
      }
      if (data === SynchrounousState.StillPending) {
        if (timeChanged) {
          // Only empty the viewport if the time has changed.
          this.emptyMapboxSource();
        }
        return;
      }
      if (data === SynchrounousState.PermanentlyFailed) {
        // Nothing to do here.
        this.emptyMapboxSource();
        return;
      }
      // Execute callback indicating updated data
      sourceUpdated?.(data);
      // Update data.
      this.updateMapboxSource(data);
      return;
    }
    return;
  }

  /**
   * Update the associated mapbox source.
   * @param data
   * @private
   */
  private updateMapboxSource(data: GeoJSON.FeatureCollection<GeoJSON.Geometry>) {
    const source = this.scene.getMapboxMap().getSource(this.mapbox_source) as GeoJSONSource;
    if (!source) {
      logger.error("GeoJSON layer source not found!", this.mapbox_source);
      return;
    }
    source.setData(data);
  }

  /**
   * Helper function to create an empty mapbox source.
   * @private
   */
  private emptyMapboxSource() {
    this.updateMapboxSource(emptyFeatureCollection);
  }

  // Protected getters to expose the private properties to subclasses
  protected getMapboxSource(): string {
    return this.mapbox_source;
  }

  protected getLatestSourceData() {
    return this.latestSourceData;
  }

  protected getTimeChanged() {
    return this.timeChanged;
  }

  protected getGeoJsonData() {
    return this.geoJsonData;
  }

  protected invokeEmptyMapboxSource() {
    this.emptyMapboxSource();
  }

  protected invokeUpdateMapboxSource(data: GeoJSON.FeatureCollection<GeoJSON.Geometry>) {
    this.updateMapboxSource(data);
  }
}

import { fetchProfiles } from "@/api/hooks/profile";
import { useAuth } from "@/auth";
import { useInitFeatureFlagContext } from "@/feature-flags";
import LoadingScreenPage from "@/pages/loading-screen";
import NotFoundPage from "@/pages/not-found";
import WorkspacePage from "@/pages/workspace";
import { useAppDispatch } from "@/reducer";
import { useProfiles } from "@/reducer/ProfileState";
import { LayersApiContext, useLayersApiContextValue } from "@/weather-parameters";
import { t } from "@lingui/macro";
import { isEmpty } from "lodash";
import { useEffect } from "react";

export default function ActivateWorkspace() {
  useInitFeatureFlagContext();
  const layersApiContextValue = useLayersApiContextValue();
  const dispatch = useAppDispatch();
  const { sessionActivated } = useAuth();
  useEffect(() => {
    // TODO: There is a crash when login in again after the session expired without checking for sessionActivated. This should just be a workaround.
    // Find a better solution!

    if (sessionActivated) {
      dispatch(fetchProfiles());
    }
  }, [sessionActivated, dispatch]);

  const { profiles, isLoading } = useProfiles();

  if (isLoading) {
    // TODO Add nice looking loading page
    return <LoadingScreenPage what={t({ id: "loading-screen.loading.account", message: "Loading" })} />;
  }

  if (isEmpty(profiles)) {
    return <NotFoundPage />;
  }
  return (
    <LayersApiContext.Provider value={layersApiContextValue}>
      <WorkspacePage />
    </LayersApiContext.Provider>
  );
}

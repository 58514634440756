import { SmartSelector } from "@/overlay/components/CommonParts/SmartSelector";
import { setMapLodBiasProps } from "@/reducer/MapsState";
import { t } from "@lingui/macro";
import type { MapId } from "@mm/metx-workbench.meteomatics.com";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { FormField } from "../form-field";

enum DataResolution {
  /**
   * best the user's screen can do, 1 sample per screen pixels of the user.
   * Might be more than 300 pixels per inch on a tablet, mobile phone or laptop.
   * On a macbook pro, this is two times the resolution of `Normal`.
   */
  Native = "high",
  /** approximately 72 to 90 samples per inch of the users screen */
  //Full,
  /** quarter resolution, skips every other pixels on each axis */
  ZoomLodBias1 = "medium",
  /** 1/8th resolution, skip 3 out of 4 pixels on each axis */
  ZoomLodBias2 = "low",
}

const dataResolutionsDisplayName: {
  [key in DataResolution]: () => string;
} = {
  [DataResolution.Native]: () => t`high`,
  [DataResolution.ZoomLodBias1]: () => t`medium`,
  [DataResolution.ZoomLodBias2]: () => t`low`,
};

const zoomLevelBias: Record<DataResolution, number> = {
  [DataResolution.Native]: 0,
  [DataResolution.ZoomLodBias1]: 1,
  [DataResolution.ZoomLodBias2]: 2,
};

/**
 * A reverse function of zoomLevelBias. Converts the zoom level bias back to DataResolution.
 */
function getDataResolutionFromZoom(zoomLevel: number) {
  const dataResolutionOptions = Object.keys(zoomLevelBias) as unknown as DataResolution[];
  const targetResolution = dataResolutionOptions.filter((dataResolution) => {
    return zoomLevelBias[dataResolution] === zoomLevel;
  })[0];
  if (!targetResolution) {
    return null;
  }
  return targetResolution;
}

export function DataResolutionSettings(props: { id: MapId; lod_bias: number; disabled?: boolean }) {
  const dispatch = useDispatch();

  const options = useMemo(() => {
    const labelObject = dataResolutionsDisplayName;
    return Object.entries(labelObject).map(([key]) => {
      const dataResolutionLabel: string = labelObject[key as DataResolution]();

      return {
        key: key,
        label: dataResolutionLabel,
        value: key,
      };
    });
  }, []);

  const resolutionSelector = (
    <SmartSelector
      items={options}
      onSelect={(dataResolutionKey: string) => {
        if (dataResolutionKey == null) {
          return;
        }

        const bias = zoomLevelBias[dataResolutionKey as DataResolution] ?? 0;

        dispatch(
          setMapLodBiasProps({
            id: props.id.map,
            lod_bias: bias,
          }),
        );
      }}
      selectedItemKey={getDataResolutionFromZoom(props.lod_bias) ?? undefined}
      disabled={props.disabled}
    />
  );

  return (
    <FormField label={t`Image Resolution`} noSemanticLinking>
      <div>{resolutionSelector}</div>
    </FormField>
  );
}

import {
  ColorThresholdInput,
  thresholdToColorThreshold,
} from "@/overlay/components/CommonParts/Threshold/ColorThresholdInput";
import { Button, ButtonBar } from "@/overlay/components/CommonParts/buttons";
import { DefaultThreshold, type ThresholdColor } from "@/reducer/client-models";
import { Trans } from "@lingui/macro";
import type { ColorThreshold } from "@mm/metx-workbench.meteomatics.com";
import React from "react";

const CompleteThreshold = (props: {
  updateThresholds: (newThresholds: ColorThreshold[]) => void;
  thresholds: ColorThreshold[];
  paramUnit?: string;
}) => {
  const addThreshold = () => {
    props.updateThresholds([...props.thresholds, DefaultThreshold]);
  };

  function changeThreshold(propsValue: Partial<ThresholdColor>, idx: number) {
    if (props.thresholds[idx]) {
      const tmpThresholds = [...props.thresholds];
      tmpThresholds[idx] = { ...tmpThresholds[idx], ...propsValue };
      props.updateThresholds(tmpThresholds);
    }
  }

  function removeThreshold(idx: number) {
    if (props.thresholds[idx]) {
      const newArray = props.thresholds.filter((_threshold, index) => index !== idx);
      props.updateThresholds(newArray);
    }
  }

  return (
    <>
      <div className={"form-field"}>
        <span className="form-field form-field--no-semantic-link">
          <span />
          <span>
            <Trans>Thresholds</Trans>:
          </span>
        </span>
      </div>

      {props.thresholds.map((threshold, idx) => {
        return (
          <ColorThresholdInput
            key={`${idx}_${threshold.color}`}
            threshold={thresholdToColorThreshold(threshold)}
            onThresholdChange={(data) => changeThreshold(data, idx)}
            onRemove={() => removeThreshold(idx)}
            step={props.paramUnit?.includes("dust_aod") ? 0.1 : 1}
            allowDecimals={true}
          />
        );
      })}
      <ButtonBar align="right" className="layer-stack-actions">
        <Button onClick={() => addThreshold()}>
          <Trans>Add Threshold</Trans>
          <span className="material-icons">add</span>
        </Button>
      </ButtonBar>
    </>
  );
};

export default CompleteThreshold;

import type { PerformanceHint } from "../performanceIntrospection/PerformanceHint";
import {
  type ConnectionRange,
  HardParallelLimitedConnectionManagement,
} from "./HardParallelLimitedConnectionManagement";

// TODO: this is mostly placeholder code. Needs a solid implementation

// TODO: explore a more dynamic approach with a configurable number of performance classes
enum PerformanceClass {
  Fast = 0,
  Slow = 1,
}

/**
 * Connection management strategy that classifies traffic based on latency.
 * Connections are dynamically partition into low and high throughput sets.
 * Requests classified as high latency -- and thus low throughput -- are only
 * executed on a subset of connections. This ensures that low latency requests
 * can be driven to completion without waiting on low throughput requests.
 *
 * This strategy was developed for tiled map rendering and ensures that the perceived
 * map loading time is minimal.
 *
 * This strategy will ensure that the users hard parallel limit is not exceeded.
 * If the hard parallel limit is not announced through ``, at most `GURANTEED_PARALLEL_BUDGET`
 * connections are opened concurrently.
 */
export class ClassificationBasedLatencyOptimization extends HardParallelLimitedConnectionManagement {
  private readonly slowRequestRoundtripTimeThreshold: number = 1000;

  /**
   * Get the connection range used by a performance class
   *
   * @param performanceHint
   * @returns range of valid connections `[min,max)`.
   */
  protected getConnectionRange(performanceHint?: PerformanceHint): ConnectionRange {
    // put in slow performance class by default until we have a measurement
    const performanceClass = performanceHint == null ? PerformanceClass.Slow : this.classifyRequest(performanceHint);

    // TODO: this is very vulnerable to the order of layers on a tiled map, e.g. if a slow layer is scheduled first
    // slow tiles take up most connections and fast connections have heavily degraded performance (since they can only
    // use one connection).
    //
    // If the order of layers is inverted, all fast connections are scheduled first.
    //
    // So, in theory, we would like to know the whole batch of incoming requests at once, which would require lots of
    // refactoring.
    switch (performanceClass) {
      case PerformanceClass.Fast:
        // fastest performance class is assumed to have a neglectable roundtrip time. let it run on all connections
        return [0, this.maxConcurrentConnections];
      case PerformanceClass.Slow:
        // always retain some connections for future fast requests
        return [0, this.maxConcurrentConnections - this.settings.maxConcurrentConnectionsPerHost];
      default: {
        const _exhaustive: never = performanceClass;
        return _exhaustive;
      }
    }
  }

  /**
   * Classifies requests based on historic performance of similar requests to maintain quality of service even when
   * a very slow parameter causes network congestion.
   *
   * The goal here is to only run very slow performing weather parameters on a subset of connections to have some free
   * connections for fast weather parameters. Otherwise the GUI stalls completely when a slow parameter is scheduled.
   *
   * Traffic is dynamically partitioned into low and high throughput sets using weighted faire queueing to assign the number
   * of connections for the parameter:
   *
   * $\var{num connections for performance class i} = var{max_parallelism} * w_i / \sum_i(w_i)$
   *
   * where w_i is for example (1/latency) for fair use of compute power.
   *
   * Dynamic partitioning ensures that the network is used efficiently, even if the mix of connections only contains
   * requests from one performance class.
   *
   * @returns a performance class. lower numbers correspond to faster classes.
   */
  private classifyRequest(performanceHint: PerformanceHint) {
    return performanceHint.estimatedRoundtripTime < this.slowRequestRoundtripTimeThreshold
      ? PerformanceClass.Fast
      : PerformanceClass.Slow;
  }
}

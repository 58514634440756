import { classNames } from "@/utility/jsx";
import styles from "./style.module.scss";

type ToggleLegendProps = {
  id: string;
  legendVisible: boolean;
  onToggle: (enabled: boolean) => void;
};

function ToggleLegend({ id, legendVisible, onToggle }: ToggleLegendProps) {
  return (
    <div className={styles["toggle-legend"]}>
      <input
        className={styles["toggle-legend__checkbox"]}
        type="checkbox"
        checked={legendVisible}
        onChange={() => onToggle(!legendVisible)}
        id={id}
      />
      <label className={styles["toggle-legend__icon"]} htmlFor={id}>
        <span className={classNames("material-icons", styles["toggle-legend__icon"])}>straighten</span>
      </label>
    </div>
  );
}

export { ToggleLegend };

import { fetchTab } from "@/api/hooks/tab";
import type { LocationTableSchema, LocationTableUpdate } from "@mm/metx-workbench.meteomatics.com";
import { type PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import type { RootState } from ".";

import { createLocationTable } from "@/api/hooks/tools/locationTable";
import Logger from "logging";

const logger = Logger.fromFilename(__filename);

export type LocationTableState = {
  [id: number]: LocationTableSchema;
};

const initialState: LocationTableState = {};

const locationTableSlice = createSlice({
  name: "locationTable",
  initialState: initialState as LocationTableState,
  reducers: {
    addLocationTable(state, action: PayloadAction<LocationTableSchema>) {
      state[action.payload.id] = action.payload;
    },
    discardLocationTable(state, action: PayloadAction<{ id: number }>) {
      const { id } = action.payload;
      const tool = state[id];

      if (!tool) {
        logger.error("Could not find tool with id", id);
        return;
      }
      delete state[id];
    },

    updateLocationTableState(
      state,
      action: PayloadAction<{
        id: LocationTableSchema["id"];
        locationTable: LocationTableUpdate;
      }>,
    ) {
      const { locationTable, id } = action.payload;

      const table = state[id];

      if (!table) {
        logger.error("Could not find location table with id", id);
        return;
      }

      const updatedLocationTable = {
        ...table,
        ...locationTable,
      };

      state[id] = updatedLocationTable;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTab.fulfilled, (state, action) => {
      const tab = action.payload;
      for (const key in state) {
        delete state[+key];
      }
      if (tab.location_tables) {
        for (const table of tab.location_tables) {
          state[table.id] = table;
        }
      }
    });
    builder.addCase(createLocationTable.fulfilled, (state, action) => {
      const table = action.payload.tool;
      state[table.id] = table;
    });
  },
});

const selectLocationTables = (state: RootState) => state.tabGroup.present.locationTables;

const makeSelectLocationTablesByTabId = createSelector(
  [selectLocationTables, (state: RootState, tabId: number) => tabId],
  (locationTables, tabId) => Object.values(locationTables).filter((table) => table.id_tab === tabId),
);

export function useLocationTables(tabId: number) {
  return useSelector((state: RootState) => makeSelectLocationTablesByTabId(state, tabId));
}

const makeSelectLocationTableById = createSelector(
  [selectLocationTables, (state: RootState, id: number) => id],
  (locationTables, id) => locationTables[id],
);

export function useLocationTable(id: number) {
  return useSelector((state: RootState) => makeSelectLocationTableById(state, id));
}

export const { addLocationTable, updateLocationTableState, discardLocationTable } = locationTableSlice.actions;

export default locationTableSlice.reducer;

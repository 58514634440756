import { Api } from "@/api/Api";
import { type EnergyPlotCreate, LayoutType, type Tab } from "@mm/metx-workbench.meteomatics.com";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const createEnergyPlot = createAsyncThunk(
  "api/energy-plot/create",
  async (payLoad: { tabId: Tab["id"]; data: EnergyPlotCreate; layoutId: number }, _thunkAPI) => {
    return await Api.energyPlot
      .v2CreateEnergyPlot({
        energyPlotCreate: payLoad.data,
        tabId: payLoad.tabId,
      })
      .then((plot) => {
        return { tool: plot, layoutType: LayoutType.EnergyPlot, layoutId: payLoad.layoutId };
      });
  },
);

import { Api } from "@/api/Api";
import type { TimeState } from "@mm/metx-workbench.meteomatics.com";
import { isEqual } from "lodash";
import { DateTime, Duration } from "luxon";
import type { StringStateValueFrom } from "metx-core/src/models/state-machine-utils/typeUtilts";
import type { InterpreterFrom, StateFrom } from "xstate";
import {
  type DeserializerMap,
  applyPropertyDeserializer,
  stringifyStateVal,
} from "../state-machine-utils/stateMachineUtils";
import { defaultTimeCtx, defaultTimeStateVal, timeStateSchemaVer } from "./timeStateConsts";
import type { TimeContextUnion } from "./timeStateCtxTypes";
import { TimeEventType } from "./timeStateEvents";
import type { TimeStateMachine } from "./timeStateMachine";

// ###########################################################################
//  Utilities to handle backend connections
// ###########################################################################

/**
 * A set of modes that should NOT be persisted after browser refresh.
 *
 * We use array because state value can be an object.
 */
const excludedModesList: Array<StateFrom<TimeStateMachine>["value"]> = ["ANIMATION", "IDLE"];

export function saveTimeStateDesc(
  state: StateFrom<TimeStateMachine>,
  excludedModes: Array<StateFrom<TimeStateMachine>["value"]> = excludedModesList,
): Promise<TimeState | null> {
  const shouldExclude = !!excludedModes.find((mode) => isEqual(mode, state.value));
  if (shouldExclude) {
    return Promise.resolve(null);
  }

  if (!state.context.id_profile) {
    // Profile ID can be null when the machine is in IDLE state.
    return Promise.resolve(null);
  }

  return Api.timeState.v1TimeStateUpdate({
    idProfile: state.context.id_profile,
    timeStateUpdate: {
      schema_ver: timeStateSchemaVer,
      state_value: stringifyStateVal(state.value),
      context: state.context,
    },
  });
}

/**
 * Fetches a time state desc from backend and emits an event to the state machine.
 */
export function readTimeStateDesc(profileId: number, send: InterpreterFrom<TimeStateMachine>["send"]) {
  return Api.timeState.v1TimeStateRead({ idProfile: profileId }).then((data) => {
    const [stateVal, ctx] = deserializeTimeStateDesc(data);
    const ctxWithProfileId = { ...ctx, id_profile: profileId } as TimeContextUnion;

    // Note: stateValue needs to be a dotted notation.
    send({
      type: TimeEventType.initializeStateMachine,
      stateValue: stateVal as StringStateValueFrom<TimeStateMachine>,
      stateCtx: ctxWithProfileId,
    });
  });
}

const realizeIsoDate = (isoStr: string) => DateTime.fromISO(isoStr, { setZone: true });
const realizeIsoDuration = (isoStr: string) => Duration.fromISO(isoStr);
const ctxFieldDeserializers: DeserializerMap = {
  displayTime: realizeIsoDate,
  "modeOpts.startTime": realizeIsoDate,
  "modeOpts.endTime": realizeIsoDate,
  "modeOpts.temporalResolution": realizeIsoDuration,
};

/**
 * Deserialize JSON description of time state from backend into
 * instantiated a context and a state value object.
 */
export function deserializeTimeStateDesc(timeStateDesc: TimeState): [string, Omit<TimeContextUnion, "meta">] {
  const context = applyPropertyDeserializer(timeStateDesc.context ?? {}, ctxFieldDeserializers) as TimeContextUnion;
  const stateVal: string = timeStateDesc.state_value;
  const schema_ver = timeStateDesc.schema_ver;

  if (schema_ver !== timeStateSchemaVer) {
    // If the backend's JSON desc is not matching the current code
    // use the fallback.
    return [defaultTimeStateVal, defaultTimeCtx];
  }

  return [stateVal, context];
}

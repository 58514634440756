import { SUPPORTED_BARBS_SPEED_UNITS } from "@/constants/barbsParameters";
import { ReservedValues } from "@/constants/reservedValues";
import { getWaveNrByKn, getWindNrByKn } from "@/geojson/definitions";
import {
  getPropertyForWeatherCode,
  getPropertyForWeatherSymbol,
  getPropertyForWindDirection,
  getPropertyForWindIcon,
} from "@/geojson/properties";
import { weatherCodeParameterName, wwCodeCloudName } from "@/layers/geojson";
import { getMapboxIconSize } from "@/layers/utility/iconSize";
import { getMapboxTextSize } from "@/layers/utility/textSize";
import type { IconData } from "@/reducer/client-models";
import { WEATHER_SYMBOL_PARAM } from "@/weatherTableView/cellsRenderers/DataCellRenderer";
import type { GeoJSONFeatureCollection, JSONResponseBody } from "@mm/api.meteomatics.com";
import type { FeatureCollection, GeoJsonProperties, MultiPoint, Point } from "geojson";
import Logger from "logging";

const logger = Logger.fromFilename(__filename);

export function createRawGeoJson(data: JSONResponseBody): GeoJSONFeatureCollection {
  const result: FeatureCollection<Point> = {
    features: [],
    type: "FeatureCollection",
  };
  if (data.data.length < 1) {
    logger.error("No data available.");
    return result;
  }

  data.data[0].coordinates.forEach((coordinates, index) => {
    const lat = coordinates.lat;
    const lon = coordinates.lon;
    if (coordinates.dates.length === 0) {
      logger.error("No data available.");
      return result;
    }
    const value = coordinates.dates[0].value;

    result.features.push({
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [lon, lat],
      },
      properties: {
        value: value,
      },
    });
  });
  return result;
}

export function createWeatherSymbolGeoJson(data: JSONResponseBody): GeoJSONFeatureCollection {
  const result: FeatureCollection<Point> = {
    features: [],
    type: "FeatureCollection",
  };

  const parameterNames = data.data.map((param) => param.parameter);
  let weatherSymbolIndex = -1;
  parameterNames.forEach((value, index) => {
    if (value.startsWith(WEATHER_SYMBOL_PARAM)) {
      weatherSymbolIndex = index;
    }
  });

  if (weatherSymbolIndex < -1) {
    return result;
  }

  data.data[0].coordinates.forEach((coordinates, index) => {
    const lat = coordinates.lat;
    const lon = coordinates.lon;

    const weatherSymbolData = data.data[weatherSymbolIndex].coordinates[index].dates;
    if (weatherSymbolData.length === 0) {
      logger.error("No weather symbol data!");
      return result;
    }
    const weatherSymbol = weatherSymbolData[0].value;

    result.features.push({
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [lon, lat],
      },
      properties: {
        ...getPropertyForWeatherSymbol(weatherSymbol),
      },
    });
  });
  return result;
}

export function createWeatherCodeGeoJson(data: JSONResponseBody): GeoJSONFeatureCollection {
  const result: FeatureCollection<Point> = {
    features: [],
    type: "FeatureCollection",
  };

  const parameterNames = data.data.map((param) => param.parameter);
  let weatherCodeIndex = -1;
  let cloudCoverIndex = -1;
  parameterNames.forEach((value, index) => {
    if (value.startsWith(weatherCodeParameterName)) {
      weatherCodeIndex = index;
    } else if (value.startsWith(wwCodeCloudName)) {
      cloudCoverIndex = index;
    }
  });

  if (weatherCodeIndex < 0) {
    return result;
  }
  data.data[0].coordinates.forEach((coordinates, index) => {
    const lat = coordinates.lat;
    const lon = coordinates.lon;

    const wwCodeData = data.data[weatherCodeIndex].coordinates[index].dates;
    const cloudCoverData = cloudCoverIndex < 0 ? null : data.data[cloudCoverIndex].coordinates[index].dates;

    if (wwCodeData.length === 0 || (cloudCoverData !== null && cloudCoverData.length === 0)) {
      logger.error("Data is missing!");
      return result;
    }

    const wwCode = wwCodeData[0].value;
    const cloudCover = cloudCoverData === null ? null : cloudCoverData[0].value;

    result.features.push({
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [lon, lat],
      },
      properties: {
        ...getPropertyForWeatherCode(wwCode, cloudCover),
      },
    });
  });
  return result;
}

export function createBarbsGeoJson(data: JSONResponseBody): GeoJSONFeatureCollection {
  const result: FeatureCollection<Point> = {
    features: [],
    type: "FeatureCollection",
  };

  const parameterNames = data.data.map((param) => param.parameter);
  let speedIndex = -1;
  let directionIndex = -1;
  let isOcean = false;
  parameterNames.forEach((value, index) => {
    if (SUPPORTED_BARBS_SPEED_UNITS.some((speed) => value.indexOf(speed) > -1)) {
      speedIndex = index;
      if (value.startsWith("ocean_")) {
        isOcean = true;
      }
    } else if (value.indexOf(":d") > -1) {
      directionIndex = index;
    }
  });

  if (speedIndex < 0 || directionIndex < 0) {
    logger.error("Speed or direction data is missing!");
    return result;
  }
  data.data[0].coordinates.forEach((coordinates, index) => {
    const lat = coordinates.lat;
    const lon = coordinates.lon;

    const directionData = data.data[directionIndex].coordinates[index].dates;
    const speedData = data.data[speedIndex].coordinates[index].dates;
    if (directionData.length === 0 || speedData.length === 0) {
      logger.error("No data available.");
      return result;
    }

    const direction = directionData[0].value as number;
    const speed = speedData[0].value as number;

    // create no feature for reserved Values
    if (ReservedValues.includes(speed)) {
      return;
    }
    result.features.push({
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [lon, lat],
      },
      properties: {
        ...getPropertyForWindDirection(direction),
        ...getPropertyForWindIcon(speed, lat, isOcean ? getWaveNrByKn : getWindNrByKn),
      },
    });
  });
  return result;
}

export function createItemProperties(item: IconData): GeoJsonProperties {
  const itemColor = { "item-color": item.color };

  return {
    "item-icon": item.icon ?? null,
    "item-text": item.text,
    "item-icon-size": getMapboxIconSize(item.size, 150), // iconLayerConfig.defaultElementSize
    "item-text-size": getMapboxTextSize(item.size, 12, 80), //px //getMapboxIconSize(item.size, 20), // iconLayerConfig.defaultTextSize
    ...itemColor,
  };
}

export function createIconGeoJson(item: IconData): GeoJSONFeatureCollection {
  const result: FeatureCollection<MultiPoint> = {
    features: [],
    type: "FeatureCollection",
  };
  const coords = item.coords.map((coord) => {
    return [coord.lon, coord.lat];
  });
  result.features.push({
    type: "Feature",
    geometry: {
      type: "MultiPoint",
      coordinates: coords,
    },
    properties: createItemProperties(item),
  });
  return result;
}
/**
 * Merge JSONapiResponse with Item inforamtion
 * Single Time - Only created for one Time (no range)
 * Single Parameter - Only create for one Parameter
 * */
export function createIconWithParameterGeoJson(data: JSONResponseBody, item: IconData): GeoJSONFeatureCollection {
  const result: FeatureCollection<Point> = {
    features: [],
    type: "FeatureCollection",
  };
  if (data.data.length < 1) {
    logger.error("No data available.");
    return result;
  }

  for (const coordinates of data.data[0].coordinates) {
    const lat = coordinates.lat;
    const lon = coordinates.lon;
    if (coordinates.dates.length === 0) {
      logger.error("No data available.");
      return result;
    }
    const value = coordinates.dates[0].value; // first date time only

    result.features.push({
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [lon, lat],
      },
      properties: {
        value: value,
        ...createItemProperties(item),
      },
    });
  }
  return result;
}

/**
 * Create formatted GeoJSON feature collection from the raw GeoJSONFeatureCollection retrieved from the WFS interface.
 * @param data GeoJSONFeatureCollection
 * @param filteringProperties Features will be removed if they don't contain 1) all of the filtering properties in its properties attribute, or 2) properties attribute at all.
 * @returns GeoJSONFeatureCollection
 */
export function createWfsGeoJSON(
  data: GeoJSONFeatureCollection,
  filteringProperties: string[],
): GeoJSONFeatureCollection {
  return {
    ...data,
    features: data.features.filter((feature) => {
      let keepFeature = true;
      for (const filteringProperty of filteringProperties) {
        if (!feature.properties) {
          keepFeature = false;
          return;
        }
        if (!feature.properties[filteringProperty]) {
          keepFeature = false;
          return;
        }
      }

      return keepFeature;
    }),
  };
}

import {
  AccountApi,
  type Configuration,
  CountryPlotApi,
  CustomGeojsonApi,
  EnergyPlotApi,
  LayerApi,
  LayoutApi,
  LocationTableApi,
  MapApi,
  NoteApi,
  PlotApi,
  ProfileApi,
  type ResponseContext,
  SessionApi,
  TabApi,
  TephigramApi,
  TimeStateApi,
  UserApi,
  ViewportApi,
  WeatherTableApi,
} from "@mm/metx-workbench.meteomatics.com";

import { oAuthConfiguration } from "@/auth/configuration";
import { InvalidSessionNotification } from "@/auth/device-tracking-session";
import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import { type Configuration as EnergyConfig, EnergyForecastApi } from "@mm/forecast-data.meteomatics.com";
import React from "react";
import ReactDOM from "react-dom";

const ERROR_MSG = "API isn't initialized! Did you forgot to call initialize(configuration) on app startup?";
const handleError = (context: ResponseContext) => {
  const { response } = context;

  // Disable session on 460
  if (response.status === 460) {
    ReactDOM.render(
      <I18nProvider i18n={i18n}>
        <InvalidSessionNotification />,
      </I18nProvider>,
      document.getElementById("root"),
    );
    throw context.response;
  }
  if (response.status === 403) {
    const queryParams = new URLSearchParams({
      client_id: oAuthConfiguration.clientId,
      redirect_url: window.location.origin,
    });
    const signOutUrl = `${oAuthConfiguration.oauthLogoutUrl}?${queryParams.toString()}`;
    window.localStorage.setItem("auth", "null");
    window.location.replace(signOutUrl);
    return Promise.resolve(context.response);
  }
  return Promise.resolve(context.response);
};

export class Api {
  private static instance: Api;

  private accountApi: AccountApi;
  private profileApi: ProfileApi;
  private userApi: UserApi;
  private sessionApi: SessionApi;
  private tabApi: TabApi;
  private layerApi: LayerApi;
  private mapApi: MapApi;
  private plotApi: PlotApi;
  private energyPlotApi: EnergyPlotApi;
  private weatherTableApi: WeatherTableApi;
  private locationTableApi: LocationTableApi;
  private layoutApi: LayoutApi;
  private viewportApi: ViewportApi;
  private energyForecastApi: EnergyForecastApi;
  private timeStateApi: TimeStateApi;
  private tephigramApi: TephigramApi;
  private countryPlotApi: CountryPlotApi;
  private customGeojsonApi: CustomGeojsonApi;
  private noteApi: NoteApi;

  private constructor(configuration: Configuration, energyConfig: EnergyConfig) {
    this.accountApi = new AccountApi(configuration).withPostMiddleware(handleError);
    this.profileApi = new ProfileApi(configuration).withPostMiddleware(handleError);
    this.userApi = new UserApi(configuration).withPostMiddleware(handleError);
    this.sessionApi = new SessionApi(configuration).withPostMiddleware(handleError);
    this.tabApi = new TabApi(configuration).withPostMiddleware(handleError);
    this.layerApi = new LayerApi(configuration).withPostMiddleware(handleError);
    this.mapApi = new MapApi(configuration).withPostMiddleware(handleError);
    this.plotApi = new PlotApi(configuration).withPostMiddleware(handleError);
    this.energyPlotApi = new EnergyPlotApi(configuration).withPostMiddleware(handleError);
    this.weatherTableApi = new WeatherTableApi(configuration).withPostMiddleware(handleError);
    this.layoutApi = new LayoutApi(configuration).withPostMiddleware(handleError);
    this.viewportApi = new ViewportApi(configuration).withPostMiddleware(handleError);
    this.timeStateApi = new TimeStateApi(configuration).withPostMiddleware(handleError);
    this.energyForecastApi = new EnergyForecastApi(energyConfig).withPostMiddleware(handleError);
    this.tephigramApi = new TephigramApi(configuration).withPostMiddleware(handleError);
    this.countryPlotApi = new CountryPlotApi(configuration).withPostMiddleware(handleError);
    this.customGeojsonApi = new CustomGeojsonApi(configuration).withPostMiddleware(handleError);
    this.locationTableApi = new LocationTableApi(configuration).withPostMiddleware(handleError);
    this.noteApi = new NoteApi(configuration).withPostMiddleware(handleError);
  }

  public static get account() {
    if (!Api.instance) {
      throw new Error(ERROR_MSG);
    }

    return Api.instance.accountApi;
  }

  public static get timeState() {
    if (!Api.instance) {
      throw new Error(ERROR_MSG);
    }

    return Api.instance.timeStateApi;
  }

  public static get tab() {
    if (!Api.instance) {
      throw new Error(ERROR_MSG);
    }

    return Api.instance.tabApi;
  }

  public static get layer() {
    if (!Api.instance) {
      throw new Error(ERROR_MSG);
    }

    return Api.instance.layerApi;
  }

  public static get profile() {
    if (!Api.instance) {
      throw new Error(ERROR_MSG);
    }

    return Api.instance.profileApi;
  }

  public static get cartographicMap() {
    if (!Api.instance) {
      throw new Error(ERROR_MSG);
    }

    return Api.instance.mapApi;
  }

  public static get plot() {
    if (!Api.instance) {
      throw new Error(ERROR_MSG);
    }

    return Api.instance.plotApi;
  }

  public static get energyPlot() {
    if (!Api.instance) {
      throw new Error(ERROR_MSG);
    }

    return Api.instance.energyPlotApi;
  }

  public static get countryForecastPlot() {
    if (!Api.instance) {
      throw new Error(ERROR_MSG);
    }

    return Api.instance.countryPlotApi;
  }

  public static get weatherTable() {
    if (!Api.instance) {
      throw new Error(ERROR_MSG);
    }

    return Api.instance.weatherTableApi;
  }

  public static get locationTable() {
    if (!Api.instance) {
      throw new Error(ERROR_MSG);
    }

    return Api.instance.locationTableApi;
  }

  public static get viewport() {
    if (!Api.instance) {
      throw new Error(ERROR_MSG);
    }

    return Api.instance.viewportApi;
  }

  public static get layout() {
    if (!Api.instance) {
      throw new Error(ERROR_MSG);
    }

    return Api.instance.layoutApi;
  }

  public static get user() {
    if (!Api.instance) {
      throw new Error(ERROR_MSG);
    }

    return Api.instance.userApi;
  }

  public static get session() {
    if (!Api.instance) {
      throw new Error(ERROR_MSG);
    }

    return Api.instance.sessionApi;
  }

  public static get energyForecast() {
    if (!Api.instance) {
      throw new Error(ERROR_MSG);
    }

    return Api.instance.energyForecastApi;
  }

  public static get tephigram() {
    if (!Api.instance) {
      throw new Error(ERROR_MSG);
    }

    return Api.instance.tephigramApi;
  }

  public static get customGeojson() {
    if (!Api.instance) {
      throw new Error(ERROR_MSG);
    }

    return Api.instance.customGeojsonApi;
  }

  public static get notes() {
    if (!Api.instance) {
      throw new Error(ERROR_MSG);
    }

    return Api.instance.noteApi;
  }

  public static initialize(configuration: Configuration, energyConfig: EnergyConfig) {
    Api.instance = new Api(configuration, energyConfig);
  }
}

import { DateTime, Duration, DurationLikeObject } from "luxon";

export function DateTime_toTransferable(dt?: DateTime): string {
  return dt ? dt.toISO() : "";
}

export function DateTime_fromTransferable(o: string): DateTime {
  return DateTime.fromISO(o);
}

export type DateTime_Transferable = string;

export function Duration_toTransferable(d: Duration): DurationLikeObject {
  return d.toObject();
}

export function Duration_fromTransferable(o: DurationLikeObject): Duration {
  return Duration.fromObject(o);
}
export type Duration_Transferable = DurationLikeObject;

import type { ValueSetterParams } from "ag-grid-community";

/**
 * A value parse to be used in a AG Grid column definition.
 * Implements a behavior: "When a user types in a non-number, resets it to the previous value".
 * @param params
 */
export const resetToPrevNumberWhenInvaid = (params: Pick<ValueSetterParams, "oldValue" | "newValue">) => {
  const newValue = Number(params.newValue);
  const oldValue = Number(params.oldValue);
  // Check if the new value is a valid number
  if (!Number.isNaN(newValue) && params.newValue !== "") {
    return newValue;
  }
  // Return the old value if the new value is not a valid number
  return oldValue;
};

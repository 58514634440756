
  import {ImgMetaMapping} from "./type";

  /**
   * Meta data mapping of icon library images.
   * Auto generated by script /builds/mm/web/metx-workbench/icon-set/lib/generate.js
   */
  export const iconMetaMapping: ImgMetaMapping = {
      "Cloud_cover_not_reported": {uri: require("@mm/icons/icons/ww-cloud/Cloud_cover_not_reported.svg").default, sdf: true},
      "Cloud_cover_data_missing": {uri: require("@mm/icons/icons/ww-cloud/Cloud_cover_data_missing.svg").default, sdf: true},
      "Cloud_cover_9": {uri: require("@mm/icons/icons/ww-cloud/Cloud_cover_9.svg").default, sdf: true},
      "Cloud_cover_8": {uri: require("@mm/icons/icons/ww-cloud/Cloud_cover_8.svg").default, sdf: true},
      "Cloud_cover_7": {uri: require("@mm/icons/icons/ww-cloud/Cloud_cover_7.svg").default, sdf: true},
      "Cloud_cover_6": {uri: require("@mm/icons/icons/ww-cloud/Cloud_cover_6.svg").default, sdf: true},
      "Cloud_cover_5": {uri: require("@mm/icons/icons/ww-cloud/Cloud_cover_5.svg").default, sdf: true},
      "Cloud_cover_4": {uri: require("@mm/icons/icons/ww-cloud/Cloud_cover_4.svg").default, sdf: true},
      "Cloud_cover_3": {uri: require("@mm/icons/icons/ww-cloud/Cloud_cover_3.svg").default, sdf: true},
      "Cloud_cover_2": {uri: require("@mm/icons/icons/ww-cloud/Cloud_cover_2.svg").default, sdf: true},
      "Cloud_cover_1": {uri: require("@mm/icons/icons/ww-cloud/Cloud_cover_1.svg").default, sdf: true},
      "Cloud_cover_0": {uri: require("@mm/icons/icons/ww-cloud/Cloud_cover_0.svg").default, sdf: true},
      "warning_symbol": {uri: require("@mm/icons/icons/ww-codes/warning_symbol.svg").default, sdf: false},
      "Symbol_code_ww_99": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_99.svg").default, sdf: true},
      "Symbol_code_ww_98": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_98.svg").default, sdf: true},
      "Symbol_code_ww_97": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_97.svg").default, sdf: true},
      "Symbol_code_ww_96": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_96.svg").default, sdf: true},
      "Symbol_code_ww_95": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_95.svg").default, sdf: true},
      "Symbol_code_ww_94": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_94.svg").default, sdf: true},
      "Symbol_code_ww_93": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_93.svg").default, sdf: true},
      "Symbol_code_ww_92": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_92.svg").default, sdf: true},
      "Symbol_code_ww_91": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_91.svg").default, sdf: true},
      "Symbol_code_ww_90": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_90.svg").default, sdf: true},
      "Symbol_code_ww_89": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_89.svg").default, sdf: true},
      "Symbol_code_ww_88": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_88.svg").default, sdf: true},
      "Symbol_code_ww_87": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_87.svg").default, sdf: true},
      "Symbol_code_ww_86": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_86.svg").default, sdf: true},
      "Symbol_code_ww_85": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_85.svg").default, sdf: true},
      "Symbol_code_ww_84": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_84.svg").default, sdf: true},
      "Symbol_code_ww_83": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_83.svg").default, sdf: true},
      "Symbol_code_ww_82": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_82.svg").default, sdf: true},
      "Symbol_code_ww_81": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_81.svg").default, sdf: true},
      "Symbol_code_ww_80": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_80.svg").default, sdf: true},
      "Symbol_code_ww_79": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_79.svg").default, sdf: true},
      "Symbol_code_ww_78": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_78.svg").default, sdf: true},
      "Symbol_code_ww_77": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_77.svg").default, sdf: true},
      "Symbol_code_ww_76": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_76.svg").default, sdf: true},
      "Symbol_code_ww_75": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_75.svg").default, sdf: true},
      "Symbol_code_ww_74": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_74.svg").default, sdf: true},
      "Symbol_code_ww_73": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_73.svg").default, sdf: true},
      "Symbol_code_ww_72": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_72.svg").default, sdf: true},
      "Symbol_code_ww_71": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_71.svg").default, sdf: true},
      "Symbol_code_ww_70": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_70.svg").default, sdf: true},
      "Symbol_code_ww_69": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_69.svg").default, sdf: true},
      "Symbol_code_ww_68": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_68.svg").default, sdf: true},
      "Symbol_code_ww_67": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_67.svg").default, sdf: true},
      "Symbol_code_ww_66": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_66.svg").default, sdf: true},
      "Symbol_code_ww_65": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_65.svg").default, sdf: true},
      "Symbol_code_ww_64": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_64.svg").default, sdf: true},
      "Symbol_code_ww_63": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_63.svg").default, sdf: true},
      "Symbol_code_ww_62": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_62.svg").default, sdf: true},
      "Symbol_code_ww_61": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_61.svg").default, sdf: true},
      "Symbol_code_ww_60": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_60.svg").default, sdf: true},
      "Symbol_code_ww_59": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_59.svg").default, sdf: true},
      "Symbol_code_ww_58": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_58.svg").default, sdf: true},
      "Symbol_code_ww_57": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_57.svg").default, sdf: true},
      "Symbol_code_ww_56": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_56.svg").default, sdf: true},
      "Symbol_code_ww_55": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_55.svg").default, sdf: true},
      "Symbol_code_ww_54": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_54.svg").default, sdf: true},
      "Symbol_code_ww_53": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_53.svg").default, sdf: true},
      "Symbol_code_ww_52": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_52.svg").default, sdf: true},
      "Symbol_code_ww_51": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_51.svg").default, sdf: true},
      "Symbol_code_ww_50": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_50.svg").default, sdf: true},
      "Symbol_code_ww_49": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_49.svg").default, sdf: true},
      "Symbol_code_ww_48": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_48.svg").default, sdf: true},
      "Symbol_code_ww_47": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_47.svg").default, sdf: true},
      "Symbol_code_ww_46": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_46.svg").default, sdf: true},
      "Symbol_code_ww_45": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_45.svg").default, sdf: true},
      "Symbol_code_ww_44": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_44.svg").default, sdf: true},
      "Symbol_code_ww_43": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_43.svg").default, sdf: true},
      "Symbol_code_ww_42": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_42.svg").default, sdf: true},
      "Symbol_code_ww_41": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_41.svg").default, sdf: true},
      "Symbol_code_ww_40": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_40.svg").default, sdf: true},
      "Symbol_code_ww_39": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_39.svg").default, sdf: true},
      "Symbol_code_ww_38": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_38.svg").default, sdf: true},
      "Symbol_code_ww_37": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_37.svg").default, sdf: true},
      "Symbol_code_ww_36": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_36.svg").default, sdf: true},
      "Symbol_code_ww_35": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_35.svg").default, sdf: true},
      "Symbol_code_ww_34": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_34.svg").default, sdf: true},
      "Symbol_code_ww_33": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_33.svg").default, sdf: true},
      "Symbol_code_ww_32": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_32.svg").default, sdf: true},
      "Symbol_code_ww_31": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_31.svg").default, sdf: true},
      "Symbol_code_ww_30": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_30.svg").default, sdf: true},
      "Symbol_code_ww_29": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_29.svg").default, sdf: true},
      "Symbol_code_ww_28": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_28.svg").default, sdf: true},
      "Symbol_code_ww_27": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_27.svg").default, sdf: true},
      "Symbol_code_ww_26": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_26.svg").default, sdf: true},
      "Symbol_code_ww_25": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_25.svg").default, sdf: true},
      "Symbol_code_ww_24": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_24.svg").default, sdf: true},
      "Symbol_code_ww_23": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_23.svg").default, sdf: true},
      "Symbol_code_ww_22": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_22.svg").default, sdf: true},
      "Symbol_code_ww_21": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_21.svg").default, sdf: true},
      "Symbol_code_ww_20": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_20.svg").default, sdf: true},
      "Symbol_code_ww_19": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_19.svg").default, sdf: true},
      "Symbol_code_ww_18": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_18.svg").default, sdf: true},
      "Symbol_code_ww_17": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_17.svg").default, sdf: true},
      "Symbol_code_ww_16": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_16.svg").default, sdf: true},
      "Symbol_code_ww_15": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_15.svg").default, sdf: true},
      "Symbol_code_ww_14": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_14.svg").default, sdf: true},
      "Symbol_code_ww_13": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_13.svg").default, sdf: true},
      "Symbol_code_ww_12": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_12.svg").default, sdf: true},
      "Symbol_code_ww_11": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_11.svg").default, sdf: true},
      "Symbol_code_ww_10": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_10.svg").default, sdf: true},
      "Symbol_code_ww_09": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_09.svg").default, sdf: true},
      "Symbol_code_ww_08": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_08.svg").default, sdf: true},
      "Symbol_code_ww_07": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_07.svg").default, sdf: true},
      "Symbol_code_ww_06": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_06.svg").default, sdf: true},
      "Symbol_code_ww_05": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_05.svg").default, sdf: true},
      "Symbol_code_ww_04": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_04.svg").default, sdf: true},
      "Symbol_code_ww_03": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_03.svg").default, sdf: true},
      "Symbol_code_ww_02": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_02.svg").default, sdf: true},
      "Symbol_code_ww_01": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_01.svg").default, sdf: true},
      "Symbol_code_ww_00": {uri: require("@mm/icons/icons/ww-codes/Symbol_code_ww_00.svg").default, sdf: true},
      "wsymbol_0999_unknown": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0999_unknown.svg").default, sdf: false},
      "wsymbol_0091_volcanic_ash": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0091_volcanic_ash.svg").default, sdf: false},
      "wsymbol_0090_heavy_sleet_night": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0090_heavy_sleet_night.svg").default, sdf: false},
      "wsymbol_0089_heavy_sleet": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0089_heavy_sleet.svg").default, sdf: false},
      "wsymbol_0088_heavy_sleet_showers_night": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0088_heavy_sleet_showers_night.svg").default, sdf: false},
      "wsymbol_0087_heavy_sleet_showers": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0087_heavy_sleet_showers.svg").default, sdf: false},
      "wsymbol_0086_extreme_rain_showers_night": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0086_extreme_rain_showers_night.svg").default, sdf: false},
      "wsymbol_0085_extreme_rain_showers": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0085_extreme_rain_showers.svg").default, sdf: false},
      "wsymbol_0084_heavy_freezing_drizzle_night": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0084_heavy_freezing_drizzle_night.svg").default, sdf: false},
      "wsymbol_0083_heavy_freezing_drizzle": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0083_heavy_freezing_drizzle.svg").default, sdf: false},
      "wsymbol_0082_heavy_drizzle_night": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0082_heavy_drizzle_night.svg").default, sdf: false},
      "wsymbol_0081_heavy_drizzle": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0081_heavy_drizzle.svg").default, sdf: false},
      "wsymbol_0080_tropical_storm_hurricane": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0080_tropical_storm_hurricane.svg").default, sdf: false},
      "wsymbol_0079_tornado": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0079_tornado.svg").default, sdf: false},
      "wsymbol_0078_windy_night": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0078_windy_night.svg").default, sdf: false},
      "wsymbol_0077_thunderstorms_with_hail_night": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0077_thunderstorms_with_hail_night.svg").default, sdf: false},
      "wsymbol_0076_thunderstorms_with_snow_night": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0076_thunderstorms_with_snow_night.svg").default, sdf: false},
      "wsymbol_0075_thundery_snow_showers_night": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0075_thundery_snow_showers_night.svg").default, sdf: false},
      "wsymbol_0074_dust_sand_night": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0074_dust_sand_night.svg").default, sdf: false},
      "wsymbol_0073_smoke_night": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0073_smoke_night.svg").default, sdf: false},
      "wsymbol_0072_blizzard_night": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0072_blizzard_night.svg").default, sdf: false},
      "wsymbol_0071_blowing_snow_night": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0071_blowing_snow_night.svg").default, sdf: false},
      "wsymbol_0070_extreme_snow_night": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0070_extreme_snow_night.svg").default, sdf: false},
      "wsymbol_0069_extreme_rain_night": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0069_extreme_rain_night.svg").default, sdf: false},
      "wsymbol_0068_freezing_rain_night": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0068_freezing_rain_night.svg").default, sdf: false},
      "wsymbol_0067_freezing_drizzle_night": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0067_freezing_drizzle_night.svg").default, sdf: false},
      "wsymbol_0066_drizzle_night": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0066_drizzle_night.svg").default, sdf: false},
      "wsymbol_0065_freezing_fog_night": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0065_freezing_fog_night.svg").default, sdf: false},
      "wsymbol_0064_fog_night": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0064_fog_night.svg").default, sdf: false},
      "wsymbol_0063_mist_night": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0063_mist_night.svg").default, sdf: false},
      "wsymbol_0062_cold_night": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0062_cold_night.svg").default, sdf: false},
      "wsymbol_0061_hot_night": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0061_hot_night.svg").default, sdf: false},
      "wsymbol_0060_windy": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0060_windy.svg").default, sdf: false},
      "wsymbol_0059_thunderstorms_with_hail": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0059_thunderstorms_with_hail.svg").default, sdf: false},
      "wsymbol_0058_thunderstorms_with_snow": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0058_thunderstorms_with_snow.svg").default, sdf: false},
      "wsymbol_0057_thundery_snow_showers": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0057_thundery_snow_showers.svg").default, sdf: false},
      "wsymbol_0056_dust_sand": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0056_dust_sand.svg").default, sdf: false},
      "wsymbol_0055_smoke": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0055_smoke.svg").default, sdf: false},
      "wsymbol_0054_blizzard": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0054_blizzard.svg").default, sdf: false},
      "wsymbol_0053_blowing_snow": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0053_blowing_snow.svg").default, sdf: false},
      "wsymbol_0052_extreme_snow": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0052_extreme_snow.svg").default, sdf: false},
      "wsymbol_0051_extreme_rain": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0051_extreme_rain.svg").default, sdf: false},
      "wsymbol_0050_freezing_rain": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0050_freezing_rain.svg").default, sdf: false},
      "wsymbol_0049_freezing_drizzle": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0049_freezing_drizzle.svg").default, sdf: false},
      "wsymbol_0048_drizzle": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0048_drizzle.svg").default, sdf: false},
      "wsymbol_0047_freezing_fog": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0047_freezing_fog.svg").default, sdf: false},
      "wsymbol_0046_cold": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0046_cold.svg").default, sdf: false},
      "wsymbol_0045_hot": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0045_hot.svg").default, sdf: false},
      "wsymbol_0044_mostly_cloudy_night": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0044_mostly_cloudy_night.svg").default, sdf: false},
      "wsymbol_0043_mostly_cloudy": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0043_mostly_cloudy.svg").default, sdf: false},
      "wsymbol_0042_cloudy_night": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0042_cloudy_night.svg").default, sdf: false},
      "wsymbol_0041_partly_cloudy_night": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0041_partly_cloudy_night.svg").default, sdf: false},
      "wsymbol_0040_thunderstorms_night": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0040_thunderstorms_night.svg").default, sdf: false},
      "wsymbol_0039_cloudy_with_heavy_hail_night": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0039_cloudy_with_heavy_hail_night.svg").default, sdf: false},
      "wsymbol_0038_cloudy_with_light_hail_night": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0038_cloudy_with_light_hail_night.svg").default, sdf: false},
      "wsymbol_0037_cloudy_with_sleet_night": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0037_cloudy_with_sleet_night.svg").default, sdf: false},
      "wsymbol_0036_cloudy_with_heavy_snow_night": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0036_cloudy_with_heavy_snow_night.svg").default, sdf: false},
      "wsymbol_0035_cloudy_with_light_snow_night": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0035_cloudy_with_light_snow_night.svg").default, sdf: false},
      "wsymbol_0034_cloudy_with_heavy_rain_night": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0034_cloudy_with_heavy_rain_night.svg").default, sdf: false},
      "wsymbol_0033_cloudy_with_light_rain_night": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0033_cloudy_with_light_rain_night.svg").default, sdf: false},
      "wsymbol_0032_thundery_showers_night": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0032_thundery_showers_night.svg").default, sdf: false},
      "wsymbol_0031_heavy_hail_showers_night": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0031_heavy_hail_showers_night.svg").default, sdf: false},
      "wsymbol_0030_light_hail_showers_night": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0030_light_hail_showers_night.svg").default, sdf: false},
      "wsymbol_0029_sleet_showers_night": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0029_sleet_showers_night.svg").default, sdf: false},
      "wsymbol_0028_heavy_snow_showers_night": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0028_heavy_snow_showers_night.svg").default, sdf: false},
      "wsymbol_0027_light_snow_showers_night": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0027_light_snow_showers_night.svg").default, sdf: false},
      "wsymbol_0026_heavy_rain_showers_night": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0026_heavy_rain_showers_night.svg").default, sdf: false},
      "wsymbol_0025_light_rain_showers_night": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0025_light_rain_showers_night.svg").default, sdf: false},
      "wsymbol_0024_thunderstorms": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0024_thunderstorms.svg").default, sdf: false},
      "wsymbol_0023_cloudy_with_heavy_hail": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0023_cloudy_with_heavy_hail.svg").default, sdf: false},
      "wsymbol_0022_cloudy_with_light_hail": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0022_cloudy_with_light_hail.svg").default, sdf: false},
      "wsymbol_0021_cloudy_with_sleet": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0021_cloudy_with_sleet.svg").default, sdf: false},
      "wsymbol_0020_cloudy_with_heavy_snow": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0020_cloudy_with_heavy_snow.svg").default, sdf: false},
      "wsymbol_0019_cloudy_with_light_snow": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0019_cloudy_with_light_snow.svg").default, sdf: false},
      "wsymbol_0018_cloudy_with_heavy_rain": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0018_cloudy_with_heavy_rain.svg").default, sdf: false},
      "wsymbol_0017_cloudy_with_light_rain": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0017_cloudy_with_light_rain.svg").default, sdf: false},
      "wsymbol_0016_thundery_showers": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0016_thundery_showers.svg").default, sdf: false},
      "wsymbol_0015_heavy_hail_showers": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0015_heavy_hail_showers.svg").default, sdf: false},
      "wsymbol_0014_light_hail_showers": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0014_light_hail_showers.svg").default, sdf: false},
      "wsymbol_0013_sleet_showers": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0013_sleet_showers.svg").default, sdf: false},
      "wsymbol_0012_heavy_snow_showers": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0012_heavy_snow_showers.svg").default, sdf: false},
      "wsymbol_0011_light_snow_showers": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0011_light_snow_showers.svg").default, sdf: false},
      "wsymbol_0010_heavy_rain_showers": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0010_heavy_rain_showers.svg").default, sdf: false},
      "wsymbol_0009_light_rain_showers": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0009_light_rain_showers.svg").default, sdf: false},
      "wsymbol_0008_clear_sky_night": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0008_clear_sky_night.svg").default, sdf: false},
      "wsymbol_0007_fog": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0007_fog.svg").default, sdf: false},
      "wsymbol_0006_mist": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0006_mist.svg").default, sdf: false},
      "wsymbol_0005_hazy_sun": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0005_hazy_sun.svg").default, sdf: false},
      "wsymbol_0004_black_low_cloud": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0004_black_low_cloud.svg").default, sdf: false},
      "wsymbol_0003_white_cloud": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0003_white_cloud.svg").default, sdf: false},
      "wsymbol_0002_sunny_intervals": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0002_sunny_intervals.svg").default, sdf: false},
      "wsymbol_0001_sunny": {uri: require("@mm/icons/icons/wsymbol/wsymbol_0001_sunny.svg").default, sdf: false},
      "mm-triangle": {uri: require("@mm/icons/icons/weather-fronts/mm-triangle.svg").default, sdf: true},
      "mm-stationary-cold-up": {uri: require("@mm/icons/icons/weather-fronts/mm-stationary-cold-up.svg").default, sdf: true},
      "mm-semi-circle": {uri: require("@mm/icons/icons/weather-fronts/mm-semi-circle.svg").default, sdf: true},
      "mm-warning_sign": {uri: require("@mm/icons/icons/poi-icons/mm-warning_sign.svg").default, sdf: true},
      "mm-turbine": {uri: require("@mm/icons/icons/poi-icons/mm-turbine.svg").default, sdf: true},
      "mm-tractor": {uri: require("@mm/icons/icons/poi-icons/mm-tractor.svg").default, sdf: true},
      "mm-tank": {uri: require("@mm/icons/icons/poi-icons/mm-tank.svg").default, sdf: true},
      "mm-star_5": {uri: require("@mm/icons/icons/poi-icons/mm-star_5.svg").default, sdf: true},
      "mm-solar_collector": {uri: require("@mm/icons/icons/poi-icons/mm-solar_collector.svg").default, sdf: true},
      "mm-ship": {uri: require("@mm/icons/icons/poi-icons/mm-ship.svg").default, sdf: true},
      "mm-rocketdrome": {uri: require("@mm/icons/icons/poi-icons/mm-rocketdrome.svg").default, sdf: true},
      "mm-power_plant_2": {uri: require("@mm/icons/icons/poi-icons/mm-power_plant_2.svg").default, sdf: true},
      "mm-power_plant": {uri: require("@mm/icons/icons/poi-icons/mm-power_plant.svg").default, sdf: true},
      "mm-point": {uri: require("@mm/icons/icons/poi-icons/mm-point.svg").default, sdf: true},
      "mm-offshore_platform": {uri: require("@mm/icons/icons/poi-icons/mm-offshore_platform.svg").default, sdf: true},
      "mm-nuclear_power_plant": {uri: require("@mm/icons/icons/poi-icons/mm-nuclear_power_plant.svg").default, sdf: true},
      "mm-marker": {uri: require("@mm/icons/icons/poi-icons/mm-marker.svg").default, sdf: true},
      "mm-lightning": {uri: require("@mm/icons/icons/poi-icons/mm-lightning.svg").default, sdf: true},
      "mm-hydroelectric_plant": {uri: require("@mm/icons/icons/poi-icons/mm-hydroelectric_plant.svg").default, sdf: true},
      "mm-house": {uri: require("@mm/icons/icons/poi-icons/mm-house.svg").default, sdf: true},
      "mm-heart": {uri: require("@mm/icons/icons/poi-icons/mm-heart.svg").default, sdf: true},
      "mm-fire": {uri: require("@mm/icons/icons/poi-icons/mm-fire.svg").default, sdf: true},
      "mm-electricity_pylon": {uri: require("@mm/icons/icons/poi-icons/mm-electricity_pylon.svg").default, sdf: true},
      "mm-docks": {uri: require("@mm/icons/icons/poi-icons/mm-docks.svg").default, sdf: true},
      "mm-diamond": {uri: require("@mm/icons/icons/poi-icons/mm-diamond.svg").default, sdf: true},
      "mm-dam": {uri: require("@mm/icons/icons/poi-icons/mm-dam.svg").default, sdf: true},
      "mm-cross": {uri: require("@mm/icons/icons/poi-icons/mm-cross.svg").default, sdf: true},
      "mm-circle": {uri: require("@mm/icons/icons/poi-icons/mm-circle.svg").default, sdf: true},
      "mm-broadcasting_tower": {uri: require("@mm/icons/icons/poi-icons/mm-broadcasting_tower.svg").default, sdf: true},
      "mm-aircraft": {uri: require("@mm/icons/icons/poi-icons/mm-aircraft.svg").default, sdf: true},
      "mm-agriculture": {uri: require("@mm/icons/icons/poi-icons/mm-agriculture.svg").default, sdf: true},
      "lightning-cross": {uri: require("@mm/icons/icons/lightning-icons/lightning-cross.svg").default, sdf: true},
      "Military_Unknown_Aligned_Unmanned_Aerial_Vehicles": {uri: require("@mm/icons/icons/nato/Military_Unknown_Aligned_Unmanned_Aerial_Vehicles.svg").default, sdf: false},
      "Military_Unknown_Aligned_Special_Operations_Force_Special_Forces": {uri: require("@mm/icons/icons/nato/Military_Unknown_Aligned_Special_Operations_Force_Special_Forces.svg").default, sdf: false},
      "Military_Unknown_Aligned_Special_Operations_Force": {uri: require("@mm/icons/icons/nato/Military_Unknown_Aligned_Special_Operations_Force.svg").default, sdf: false},
      "Military_Unknown_Aligned_Signals_or_Communication": {uri: require("@mm/icons/icons/nato/Military_Unknown_Aligned_Signals_or_Communication.svg").default, sdf: false},
      "Military_Unknown_Aligned_Signals_Electronic_Ranging": {uri: require("@mm/icons/icons/nato/Military_Unknown_Aligned_Signals_Electronic_Ranging.svg").default, sdf: false},
      "Military_Unknown_Aligned_Reconnaissance": {uri: require("@mm/icons/icons/nato/Military_Unknown_Aligned_Reconnaissance.svg").default, sdf: false},
      "Military_Unknown_Aligned_Radar": {uri: require("@mm/icons/icons/nato/Military_Unknown_Aligned_Radar.svg").default, sdf: false},
      "Military_Unknown_Aligned_Psychological_Operations": {uri: require("@mm/icons/icons/nato/Military_Unknown_Aligned_Psychological_Operations.svg").default, sdf: false},
      "Military_Unknown_Aligned_Naval": {uri: require("@mm/icons/icons/nato/Military_Unknown_Aligned_Naval.svg").default, sdf: false},
      "Military_Unknown_Aligned_Mortars": {uri: require("@mm/icons/icons/nato/Military_Unknown_Aligned_Mortars.svg").default, sdf: false},
      "Military_Unknown_Aligned_Missile_Surface_to_Surface": {uri: require("@mm/icons/icons/nato/Military_Unknown_Aligned_Missile_Surface_to_Surface.svg").default, sdf: false},
      "Military_Unknown_Aligned_Military_Police": {uri: require("@mm/icons/icons/nato/Military_Unknown_Aligned_Military_Police.svg").default, sdf: false},
      "Military_Unknown_Aligned_Military_Geomatics_Mapping": {uri: require("@mm/icons/icons/nato/Military_Unknown_Aligned_Military_Geomatics_Mapping.svg").default, sdf: false},
      "Military_Unknown_Aligned_Military_Engineers_Bridging": {uri: require("@mm/icons/icons/nato/Military_Unknown_Aligned_Military_Engineers_Bridging.svg").default, sdf: false},
      "Military_Unknown_Aligned_Military_Engineers": {uri: require("@mm/icons/icons/nato/Military_Unknown_Aligned_Military_Engineers.svg").default, sdf: false},
      "Military_Unknown_Aligned_Meteorological_Services": {uri: require("@mm/icons/icons/nato/Military_Unknown_Aligned_Meteorological_Services.svg").default, sdf: false},
      "Military_Unknown_Aligned_Medical_Hospital": {uri: require("@mm/icons/icons/nato/Military_Unknown_Aligned_Medical_Hospital.svg").default, sdf: false},
      "Military_Unknown_Aligned_Medical": {uri: require("@mm/icons/icons/nato/Military_Unknown_Aligned_Medical.svg").default, sdf: false},
      "Military_Unknown_Aligned_Infantry": {uri: require("@mm/icons/icons/nato/Military_Unknown_Aligned_Infantry.svg").default, sdf: false},
      "Military_Unknown_Aligned_Headquarters": {uri: require("@mm/icons/icons/nato/Military_Unknown_Aligned_Headquarters.svg").default, sdf: false},
      "Military_Unknown_Aligned_Explosive_Ordnance_Disposal": {uri: require("@mm/icons/icons/nato/Military_Unknown_Aligned_Explosive_Ordnance_Disposal.svg").default, sdf: false},
      "Military_Unknown_Aligned_Electronic_Warfare": {uri: require("@mm/icons/icons/nato/Military_Unknown_Aligned_Electronic_Warfare.svg").default, sdf: false},
      "Military_Unknown_Aligned_Combined_Manoeuvre_Arms": {uri: require("@mm/icons/icons/nato/Military_Unknown_Aligned_Combined_Manoeuvre_Arms.svg").default, sdf: false},
      "Military_Unknown_Aligned_CSS_Transport": {uri: require("@mm/icons/icons/nato/Military_Unknown_Aligned_CSS_Transport.svg").default, sdf: false},
      "Military_Unknown_Aligned_CSS_Supply": {uri: require("@mm/icons/icons/nato/Military_Unknown_Aligned_CSS_Supply.svg").default, sdf: false},
      "Military_Unknown_Aligned_CSS_Petroleum_Oil_Lubricants": {uri: require("@mm/icons/icons/nato/Military_Unknown_Aligned_CSS_Petroleum_Oil_Lubricants.svg").default, sdf: false},
      "Military_Unknown_Aligned_CSS_Ordnance": {uri: require("@mm/icons/icons/nato/Military_Unknown_Aligned_CSS_Ordnance.svg").default, sdf: false},
      "Military_Unknown_Aligned_CSS_Maintenance": {uri: require("@mm/icons/icons/nato/Military_Unknown_Aligned_CSS_Maintenance.svg").default, sdf: false},
      "Military_Unknown_Aligned_CSS_Combat_Service_Support": {uri: require("@mm/icons/icons/nato/Military_Unknown_Aligned_CSS_Combat_Service_Support.svg").default, sdf: false},
      "Military_Unknown_Aligned_CSS_Ammunition": {uri: require("@mm/icons/icons/nato/Military_Unknown_Aligned_CSS_Ammunition.svg").default, sdf: false},
      "Military_Unknown_Aligned_CBRN": {uri: require("@mm/icons/icons/nato/Military_Unknown_Aligned_CBRN.svg").default, sdf: false},
      "Military_Unknown_Aligned_Aviation_Fixed_Wing": {uri: require("@mm/icons/icons/nato/Military_Unknown_Aligned_Aviation_Fixed_Wing.svg").default, sdf: false},
      "Military_Unknown_Aligned_Aviation": {uri: require("@mm/icons/icons/nato/Military_Unknown_Aligned_Aviation.svg").default, sdf: false},
      "Military_Unknown_Aligned_Artillery": {uri: require("@mm/icons/icons/nato/Military_Unknown_Aligned_Artillery.svg").default, sdf: false},
      "Military_Unknown_Aligned_Armour": {uri: require("@mm/icons/icons/nato/Military_Unknown_Aligned_Armour.svg").default, sdf: false},
      "Military_Unknown_Aligned_Anti-Tank": {uri: require("@mm/icons/icons/nato/Military_Unknown_Aligned_Anti-Tank.svg").default, sdf: false},
      "Military_Unknown_Aligned_Air_Defence": {uri: require("@mm/icons/icons/nato/Military_Unknown_Aligned_Air_Defence.svg").default, sdf: false},
      "Military_Neutral_Unmanned_Aerial_Vehicles": {uri: require("@mm/icons/icons/nato/Military_Neutral_Unmanned_Aerial_Vehicles.svg").default, sdf: false},
      "Military_Neutral_Special_Operations_Force_Special_Forces": {uri: require("@mm/icons/icons/nato/Military_Neutral_Special_Operations_Force_Special_Forces.svg").default, sdf: false},
      "Military_Neutral_Special_Operations_Force": {uri: require("@mm/icons/icons/nato/Military_Neutral_Special_Operations_Force.svg").default, sdf: false},
      "Military_Neutral_Signals_or_Communication": {uri: require("@mm/icons/icons/nato/Military_Neutral_Signals_or_Communication.svg").default, sdf: false},
      "Military_Neutral_Signals_Electronic_Ranging": {uri: require("@mm/icons/icons/nato/Military_Neutral_Signals_Electronic_Ranging.svg").default, sdf: false},
      "Military_Neutral_Reconnaissance": {uri: require("@mm/icons/icons/nato/Military_Neutral_Reconnaissance.svg").default, sdf: false},
      "Military_Neutral_Radar": {uri: require("@mm/icons/icons/nato/Military_Neutral_Radar.svg").default, sdf: false},
      "Military_Neutral_Psychological_Operations": {uri: require("@mm/icons/icons/nato/Military_Neutral_Psychological_Operations.svg").default, sdf: false},
      "Military_Neutral_Naval": {uri: require("@mm/icons/icons/nato/Military_Neutral_Naval.svg").default, sdf: false},
      "Military_Neutral_Mortars": {uri: require("@mm/icons/icons/nato/Military_Neutral_Mortars.svg").default, sdf: false},
      "Military_Neutral_Missile_Surface_to_Surface": {uri: require("@mm/icons/icons/nato/Military_Neutral_Missile_Surface_to_Surface.svg").default, sdf: false},
      "Military_Neutral_Military_Police": {uri: require("@mm/icons/icons/nato/Military_Neutral_Military_Police.svg").default, sdf: false},
      "Military_Neutral_Military_Geomatics_Mapping": {uri: require("@mm/icons/icons/nato/Military_Neutral_Military_Geomatics_Mapping.svg").default, sdf: false},
      "Military_Neutral_Military_Engineers_Bridging": {uri: require("@mm/icons/icons/nato/Military_Neutral_Military_Engineers_Bridging.svg").default, sdf: false},
      "Military_Neutral_Military_Engineers": {uri: require("@mm/icons/icons/nato/Military_Neutral_Military_Engineers.svg").default, sdf: false},
      "Military_Neutral_Medical_Hospital": {uri: require("@mm/icons/icons/nato/Military_Neutral_Medical_Hospital.svg").default, sdf: false},
      "Military_Neutral_Medical": {uri: require("@mm/icons/icons/nato/Military_Neutral_Medical.svg").default, sdf: false},
      "Military_Neutral_Infantry": {uri: require("@mm/icons/icons/nato/Military_Neutral_Infantry.svg").default, sdf: false},
      "Military_Neutral_Headquarters": {uri: require("@mm/icons/icons/nato/Military_Neutral_Headquarters.svg").default, sdf: false},
      "Military_Neutral_Explosive_Ordnance_Disposal": {uri: require("@mm/icons/icons/nato/Military_Neutral_Explosive_Ordnance_Disposal.svg").default, sdf: false},
      "Military_Neutral_Electronic_Warfare": {uri: require("@mm/icons/icons/nato/Military_Neutral_Electronic_Warfare.svg").default, sdf: false},
      "Military_Neutral_Combined_Manoeuvre_Arms": {uri: require("@mm/icons/icons/nato/Military_Neutral_Combined_Manoeuvre_Arms.svg").default, sdf: false},
      "Military_Neutral_CSS_Transport": {uri: require("@mm/icons/icons/nato/Military_Neutral_CSS_Transport.svg").default, sdf: false},
      "Military_Neutral_CSS_Supply": {uri: require("@mm/icons/icons/nato/Military_Neutral_CSS_Supply.svg").default, sdf: false},
      "Military_Neutral_CSS_Petroleum_Oil_Lubricants": {uri: require("@mm/icons/icons/nato/Military_Neutral_CSS_Petroleum_Oil_Lubricants.svg").default, sdf: false},
      "Military_Neutral_CSS_Ordnance": {uri: require("@mm/icons/icons/nato/Military_Neutral_CSS_Ordnance.svg").default, sdf: false},
      "Military_Neutral_CSS_Maintenance": {uri: require("@mm/icons/icons/nato/Military_Neutral_CSS_Maintenance.svg").default, sdf: false},
      "Military_Neutral_CSS_Combat_Service_Support": {uri: require("@mm/icons/icons/nato/Military_Neutral_CSS_Combat_Service_Support.svg").default, sdf: false},
      "Military_Neutral_CSS_Ammunition": {uri: require("@mm/icons/icons/nato/Military_Neutral_CSS_Ammunition.svg").default, sdf: false},
      "Military_Neutral_CBRN": {uri: require("@mm/icons/icons/nato/Military_Neutral_CBRN.svg").default, sdf: false},
      "Military_Neutral_Aviation_Fixed_Wing": {uri: require("@mm/icons/icons/nato/Military_Neutral_Aviation_Fixed_Wing.svg").default, sdf: false},
      "Military_Neutral_Aviation": {uri: require("@mm/icons/icons/nato/Military_Neutral_Aviation.svg").default, sdf: false},
      "Military_Neutral_Artillery": {uri: require("@mm/icons/icons/nato/Military_Neutral_Artillery.svg").default, sdf: false},
      "Military_Neutral_Armour": {uri: require("@mm/icons/icons/nato/Military_Neutral_Armour.svg").default, sdf: false},
      "Military_Neutral_Anti-Tank": {uri: require("@mm/icons/icons/nato/Military_Neutral_Anti-Tank.svg").default, sdf: false},
      "Military_Neutral_Air_Defence": {uri: require("@mm/icons/icons/nato/Military_Neutral_Air_Defence.svg").default, sdf: false},
      "Military_Infantry_Mountain": {uri: require("@mm/icons/icons/nato/Military_Infantry_Mountain.svg").default, sdf: false},
      "Military_Hostile_Unmanned_Aerial_Vehicles": {uri: require("@mm/icons/icons/nato/Military_Hostile_Unmanned_Aerial_Vehicles.svg").default, sdf: false},
      "Military_Hostile_Special_Operations_Force_Special_Forces": {uri: require("@mm/icons/icons/nato/Military_Hostile_Special_Operations_Force_Special_Forces.svg").default, sdf: false},
      "Military_Hostile_Special_Operations_Force": {uri: require("@mm/icons/icons/nato/Military_Hostile_Special_Operations_Force.svg").default, sdf: false},
      "Military_Hostile_Signals_or_Communication": {uri: require("@mm/icons/icons/nato/Military_Hostile_Signals_or_Communication.svg").default, sdf: false},
      "Military_Hostile_Signals_Electronic_Ranging": {uri: require("@mm/icons/icons/nato/Military_Hostile_Signals_Electronic_Ranging.svg").default, sdf: false},
      "Military_Hostile_Reconnaissance": {uri: require("@mm/icons/icons/nato/Military_Hostile_Reconnaissance.svg").default, sdf: false},
      "Military_Hostile_Radar": {uri: require("@mm/icons/icons/nato/Military_Hostile_Radar.svg").default, sdf: false},
      "Military_Hostile_Naval": {uri: require("@mm/icons/icons/nato/Military_Hostile_Naval.svg").default, sdf: false},
      "Military_Hostile_Mortars": {uri: require("@mm/icons/icons/nato/Military_Hostile_Mortars.svg").default, sdf: false},
      "Military_Hostile_Missile_Surface_to_Surface": {uri: require("@mm/icons/icons/nato/Military_Hostile_Missile_Surface_to_Surface.svg").default, sdf: false},
      "Military_Hostile_Military_Police": {uri: require("@mm/icons/icons/nato/Military_Hostile_Military_Police.svg").default, sdf: false},
      "Military_Hostile_Military_Geomatics_Mapping": {uri: require("@mm/icons/icons/nato/Military_Hostile_Military_Geomatics_Mapping.svg").default, sdf: false},
      "Military_Hostile_Military_Engineers_Bridging": {uri: require("@mm/icons/icons/nato/Military_Hostile_Military_Engineers_Bridging.svg").default, sdf: false},
      "Military_Hostile_Military_Engineers": {uri: require("@mm/icons/icons/nato/Military_Hostile_Military_Engineers.svg").default, sdf: false},
      "Military_Hostile_Meteorological_Services": {uri: require("@mm/icons/icons/nato/Military_Hostile_Meteorological_Services.svg").default, sdf: false},
      "Military_Hostile_Medical_Hospital": {uri: require("@mm/icons/icons/nato/Military_Hostile_Medical_Hospital.svg").default, sdf: false},
      "Military_Hostile_Medical": {uri: require("@mm/icons/icons/nato/Military_Hostile_Medical.svg").default, sdf: false},
      "Military_Hostile_Infantry": {uri: require("@mm/icons/icons/nato/Military_Hostile_Infantry.svg").default, sdf: false},
      "Military_Hostile_Headquarters": {uri: require("@mm/icons/icons/nato/Military_Hostile_Headquarters.svg").default, sdf: false},
      "Military_Hostile_Explosive_Ordnance_Disposal": {uri: require("@mm/icons/icons/nato/Military_Hostile_Explosive_Ordnance_Disposal.svg").default, sdf: false},
      "Military_Hostile_Electronic_Warfare": {uri: require("@mm/icons/icons/nato/Military_Hostile_Electronic_Warfare.svg").default, sdf: false},
      "Military_Hostile_Combined_Manoeuvre_Arms": {uri: require("@mm/icons/icons/nato/Military_Hostile_Combined_Manoeuvre_Arms.svg").default, sdf: false},
      "Military_Hostile_CSS_Transport": {uri: require("@mm/icons/icons/nato/Military_Hostile_CSS_Transport.svg").default, sdf: false},
      "Military_Hostile_CSS_Supply": {uri: require("@mm/icons/icons/nato/Military_Hostile_CSS_Supply.svg").default, sdf: false},
      "Military_Hostile_CSS_Petroleum_Oil_Lubricants": {uri: require("@mm/icons/icons/nato/Military_Hostile_CSS_Petroleum_Oil_Lubricants.svg").default, sdf: false},
      "Military_Hostile_CSS_Ordnance": {uri: require("@mm/icons/icons/nato/Military_Hostile_CSS_Ordnance.svg").default, sdf: false},
      "Military_Hostile_CSS_Maintenance": {uri: require("@mm/icons/icons/nato/Military_Hostile_CSS_Maintenance.svg").default, sdf: false},
      "Military_Hostile_CSS_Combat_Service_Support": {uri: require("@mm/icons/icons/nato/Military_Hostile_CSS_Combat_Service_Support.svg").default, sdf: false},
      "Military_Hostile_CSS_Ammunition": {uri: require("@mm/icons/icons/nato/Military_Hostile_CSS_Ammunition.svg").default, sdf: false},
      "Military_Hostile_CBRN": {uri: require("@mm/icons/icons/nato/Military_Hostile_CBRN.svg").default, sdf: false},
      "Military_Hostile_Aviation_Fixed_Wing": {uri: require("@mm/icons/icons/nato/Military_Hostile_Aviation_Fixed_Wing.svg").default, sdf: false},
      "Military_Hostile_Aviation": {uri: require("@mm/icons/icons/nato/Military_Hostile_Aviation.svg").default, sdf: false},
      "Military_Hostile_Artillery": {uri: require("@mm/icons/icons/nato/Military_Hostile_Artillery.svg").default, sdf: false},
      "Military_Hostile_Armour": {uri: require("@mm/icons/icons/nato/Military_Hostile_Armour.svg").default, sdf: false},
      "Military_Hostile_Anti-Tank": {uri: require("@mm/icons/icons/nato/Military_Hostile_Anti-Tank.svg").default, sdf: false},
      "Military_Hostile_Air_Defence": {uri: require("@mm/icons/icons/nato/Military_Hostile_Air_Defence.svg").default, sdf: false},
      "Military_Friendly_Unmanned_Aerial_Vehicles": {uri: require("@mm/icons/icons/nato/Military_Friendly_Unmanned_Aerial_Vehicles.svg").default, sdf: false},
      "Military_Friendly_Special_Operations_Force_Special_Forces": {uri: require("@mm/icons/icons/nato/Military_Friendly_Special_Operations_Force_Special_Forces.svg").default, sdf: false},
      "Military_Friendly_Special_Operations_Force": {uri: require("@mm/icons/icons/nato/Military_Friendly_Special_Operations_Force.svg").default, sdf: false},
      "Military_Friendly_Signals_or_Communication": {uri: require("@mm/icons/icons/nato/Military_Friendly_Signals_or_Communication.svg").default, sdf: false},
      "Military_Friendly_Signals_Electronic_Ranging": {uri: require("@mm/icons/icons/nato/Military_Friendly_Signals_Electronic_Ranging.svg").default, sdf: false},
      "Military_Friendly_Reconnaissance": {uri: require("@mm/icons/icons/nato/Military_Friendly_Reconnaissance.svg").default, sdf: false},
      "Military_Friendly_Radar": {uri: require("@mm/icons/icons/nato/Military_Friendly_Radar.svg").default, sdf: false},
      "Military_Friendly_Psychological_Operations": {uri: require("@mm/icons/icons/nato/Military_Friendly_Psychological_Operations.svg").default, sdf: false},
      "Military_Friendly_Naval": {uri: require("@mm/icons/icons/nato/Military_Friendly_Naval.svg").default, sdf: false},
      "Military_Friendly_Mortars": {uri: require("@mm/icons/icons/nato/Military_Friendly_Mortars.svg").default, sdf: false},
      "Military_Friendly_Missile_Surface_to_Surface": {uri: require("@mm/icons/icons/nato/Military_Friendly_Missile_Surface_to_Surface.svg").default, sdf: false},
      "Military_Friendly_Military_Police": {uri: require("@mm/icons/icons/nato/Military_Friendly_Military_Police.svg").default, sdf: false},
      "Military_Friendly_Military_Geomatics_Mapping": {uri: require("@mm/icons/icons/nato/Military_Friendly_Military_Geomatics_Mapping.svg").default, sdf: false},
      "Military_Friendly_Military_Engineers_Bridging": {uri: require("@mm/icons/icons/nato/Military_Friendly_Military_Engineers_Bridging.svg").default, sdf: false},
      "Military_Friendly_Military_Engineers": {uri: require("@mm/icons/icons/nato/Military_Friendly_Military_Engineers.svg").default, sdf: false},
      "Military_Friendly_Meteorological_Services": {uri: require("@mm/icons/icons/nato/Military_Friendly_Meteorological_Services.svg").default, sdf: false},
      "Military_Friendly_Medical_Hospital": {uri: require("@mm/icons/icons/nato/Military_Friendly_Medical_Hospital.svg").default, sdf: false},
      "Military_Friendly_Medical": {uri: require("@mm/icons/icons/nato/Military_Friendly_Medical.svg").default, sdf: false},
      "Military_Friendly_Light_Infantry": {uri: require("@mm/icons/icons/nato/Military_Friendly_Light_Infantry.svg").default, sdf: false},
      "Military_Friendly_Infantry_Mechanized": {uri: require("@mm/icons/icons/nato/Military_Friendly_Infantry_Mechanized.svg").default, sdf: false},
      "Military_Friendly_Infantry_Airmobile": {uri: require("@mm/icons/icons/nato/Military_Friendly_Infantry_Airmobile.svg").default, sdf: false},
      "Military_Friendly_Infantry": {uri: require("@mm/icons/icons/nato/Military_Friendly_Infantry.svg").default, sdf: false},
      "Military_Friendly_Headquarters": {uri: require("@mm/icons/icons/nato/Military_Friendly_Headquarters.svg").default, sdf: false},
      "Military_Friendly_Explosive_Ordnance_Disposal": {uri: require("@mm/icons/icons/nato/Military_Friendly_Explosive_Ordnance_Disposal.svg").default, sdf: false},
      "Military_Friendly_Electronic_Warfare": {uri: require("@mm/icons/icons/nato/Military_Friendly_Electronic_Warfare.svg").default, sdf: false},
      "Military_Friendly_Combined_Manoeuvre_Arms": {uri: require("@mm/icons/icons/nato/Military_Friendly_Combined_Manoeuvre_Arms.svg").default, sdf: false},
      "Military_Friendly_CSS_Transport": {uri: require("@mm/icons/icons/nato/Military_Friendly_CSS_Transport.svg").default, sdf: false},
      "Military_Friendly_CSS_Supply": {uri: require("@mm/icons/icons/nato/Military_Friendly_CSS_Supply.svg").default, sdf: false},
      "Military_Friendly_CSS_Petroleum_Oil_Lubricants": {uri: require("@mm/icons/icons/nato/Military_Friendly_CSS_Petroleum_Oil_Lubricants.svg").default, sdf: false},
      "Military_Friendly_CSS_Ordnance": {uri: require("@mm/icons/icons/nato/Military_Friendly_CSS_Ordnance.svg").default, sdf: false},
      "Military_Friendly_CSS_Maintenance": {uri: require("@mm/icons/icons/nato/Military_Friendly_CSS_Maintenance.svg").default, sdf: false},
      "Military_Friendly_CSS_Combat_Service_Support": {uri: require("@mm/icons/icons/nato/Military_Friendly_CSS_Combat_Service_Support.svg").default, sdf: false},
      "Military_Friendly_CSS_Ammunition": {uri: require("@mm/icons/icons/nato/Military_Friendly_CSS_Ammunition.svg").default, sdf: false},
      "Military_Friendly_CBRN": {uri: require("@mm/icons/icons/nato/Military_Friendly_CBRN.svg").default, sdf: false},
      "Military_Friendly_Aviation_Fixed_Wing": {uri: require("@mm/icons/icons/nato/Military_Friendly_Aviation_Fixed_Wing.svg").default, sdf: false},
      "Military_Friendly_Aviation": {uri: require("@mm/icons/icons/nato/Military_Friendly_Aviation.svg").default, sdf: false},
      "Military_Friendly_Artillery": {uri: require("@mm/icons/icons/nato/Military_Friendly_Artillery.svg").default, sdf: false},
      "Military_Friendly_Armour": {uri: require("@mm/icons/icons/nato/Military_Friendly_Armour.svg").default, sdf: false},
      "Military_Friendly_Anti-Tank": {uri: require("@mm/icons/icons/nato/Military_Friendly_Anti-Tank.svg").default, sdf: false},
      "Military_Friendly_Air_Defence_Missiles": {uri: require("@mm/icons/icons/nato/Military_Friendly_Air_Defence_Missiles.svg").default, sdf: false},
      "Military_Friendly_Air_Defence": {uri: require("@mm/icons/icons/nato/Military_Friendly_Air_Defence.svg").default, sdf: false},
      "airep-wx_TUR-X": {uri: require("@mm/icons/icons/airep-wx/airep-wx_TUR-X.svg").default, sdf: true},
      "airep-wx_TUR-TL": {uri: require("@mm/icons/icons/airep-wx/airep-wx_TUR-TL.svg").default, sdf: true},
      "airep-wx_TUR-S": {uri: require("@mm/icons/icons/airep-wx/airep-wx_TUR-S.svg").default, sdf: true},
      "airep-wx_TUR-N": {uri: require("@mm/icons/icons/airep-wx/airep-wx_TUR-N.svg").default, sdf: true},
      "airep-wx_TUR-MS": {uri: require("@mm/icons/icons/airep-wx/airep-wx_TUR-MS.svg").default, sdf: true},
      "airep-wx_TUR-M": {uri: require("@mm/icons/icons/airep-wx/airep-wx_TUR-M.svg").default, sdf: true},
      "airep-wx_TUR-LM": {uri: require("@mm/icons/icons/airep-wx/airep-wx_TUR-LM.svg").default, sdf: true},
      "airep-wx_TUR-L": {uri: require("@mm/icons/icons/airep-wx/airep-wx_TUR-L.svg").default, sdf: true},
      "airep-wx_PLANE": {uri: require("@mm/icons/icons/airep-wx/airep-wx_PLANE.svg").default, sdf: true},
      "airep-wx_ICE-TL": {uri: require("@mm/icons/icons/airep-wx/airep-wx_ICE-TL.svg").default, sdf: true},
      "airep-wx_ICE-T": {uri: require("@mm/icons/icons/airep-wx/airep-wx_ICE-T.svg").default, sdf: true},
      "airep-wx_ICE-S": {uri: require("@mm/icons/icons/airep-wx/airep-wx_ICE-S.svg").default, sdf: true},
      "airep-wx_ICE-N": {uri: require("@mm/icons/icons/airep-wx/airep-wx_ICE-N.svg").default, sdf: true},
      "airep-wx_ICE-M": {uri: require("@mm/icons/icons/airep-wx/airep-wx_ICE-M.svg").default, sdf: true},
      "airep-wx_ICE-L": {uri: require("@mm/icons/icons/airep-wx/airep-wx_ICE-L.svg").default, sdf: true},
      "s95": {uri: require("@mm/icons/icons/wind-svg/s-wind/s95.svg").default, sdf: true},
      "s90": {uri: require("@mm/icons/icons/wind-svg/s-wind/s90.svg").default, sdf: true},
      "s85": {uri: require("@mm/icons/icons/wind-svg/s-wind/s85.svg").default, sdf: true},
      "s80": {uri: require("@mm/icons/icons/wind-svg/s-wind/s80.svg").default, sdf: true},
      "s75": {uri: require("@mm/icons/icons/wind-svg/s-wind/s75.svg").default, sdf: true},
      "s70": {uri: require("@mm/icons/icons/wind-svg/s-wind/s70.svg").default, sdf: true},
      "s65": {uri: require("@mm/icons/icons/wind-svg/s-wind/s65.svg").default, sdf: true},
      "s60": {uri: require("@mm/icons/icons/wind-svg/s-wind/s60.svg").default, sdf: true},
      "s55": {uri: require("@mm/icons/icons/wind-svg/s-wind/s55.svg").default, sdf: true},
      "s50": {uri: require("@mm/icons/icons/wind-svg/s-wind/s50.svg").default, sdf: true},
      "s5": {uri: require("@mm/icons/icons/wind-svg/s-wind/s5.svg").default, sdf: true},
      "s45": {uri: require("@mm/icons/icons/wind-svg/s-wind/s45.svg").default, sdf: true},
      "s40": {uri: require("@mm/icons/icons/wind-svg/s-wind/s40.svg").default, sdf: true},
      "s35": {uri: require("@mm/icons/icons/wind-svg/s-wind/s35.svg").default, sdf: true},
      "s30": {uri: require("@mm/icons/icons/wind-svg/s-wind/s30.svg").default, sdf: true},
      "s25": {uri: require("@mm/icons/icons/wind-svg/s-wind/s25.svg").default, sdf: true},
      "s20": {uri: require("@mm/icons/icons/wind-svg/s-wind/s20.svg").default, sdf: true},
      "s15": {uri: require("@mm/icons/icons/wind-svg/s-wind/s15.svg").default, sdf: true},
      "s125": {uri: require("@mm/icons/icons/wind-svg/s-wind/s125.svg").default, sdf: true},
      "s120": {uri: require("@mm/icons/icons/wind-svg/s-wind/s120.svg").default, sdf: true},
      "s115": {uri: require("@mm/icons/icons/wind-svg/s-wind/s115.svg").default, sdf: true},
      "s110": {uri: require("@mm/icons/icons/wind-svg/s-wind/s110.svg").default, sdf: true},
      "s105": {uri: require("@mm/icons/icons/wind-svg/s-wind/s105.svg").default, sdf: true},
      "s100": {uri: require("@mm/icons/icons/wind-svg/s-wind/s100.svg").default, sdf: true},
      "s10": {uri: require("@mm/icons/icons/wind-svg/s-wind/s10.svg").default, sdf: true},
      "s0": {uri: require("@mm/icons/icons/wind-svg/s-wind/s0.svg").default, sdf: true},
      "n95": {uri: require("@mm/icons/icons/wind-svg/n-wind/n95.svg").default, sdf: true},
      "n90": {uri: require("@mm/icons/icons/wind-svg/n-wind/n90.svg").default, sdf: true},
      "n85": {uri: require("@mm/icons/icons/wind-svg/n-wind/n85.svg").default, sdf: true},
      "n80": {uri: require("@mm/icons/icons/wind-svg/n-wind/n80.svg").default, sdf: true},
      "n75": {uri: require("@mm/icons/icons/wind-svg/n-wind/n75.svg").default, sdf: true},
      "n70": {uri: require("@mm/icons/icons/wind-svg/n-wind/n70.svg").default, sdf: true},
      "n65": {uri: require("@mm/icons/icons/wind-svg/n-wind/n65.svg").default, sdf: true},
      "n60": {uri: require("@mm/icons/icons/wind-svg/n-wind/n60.svg").default, sdf: true},
      "n55": {uri: require("@mm/icons/icons/wind-svg/n-wind/n55.svg").default, sdf: true},
      "n50": {uri: require("@mm/icons/icons/wind-svg/n-wind/n50.svg").default, sdf: true},
      "n5": {uri: require("@mm/icons/icons/wind-svg/n-wind/n5.svg").default, sdf: true},
      "n45": {uri: require("@mm/icons/icons/wind-svg/n-wind/n45.svg").default, sdf: true},
      "n40": {uri: require("@mm/icons/icons/wind-svg/n-wind/n40.svg").default, sdf: true},
      "n35": {uri: require("@mm/icons/icons/wind-svg/n-wind/n35.svg").default, sdf: true},
      "n30": {uri: require("@mm/icons/icons/wind-svg/n-wind/n30.svg").default, sdf: true},
      "n25": {uri: require("@mm/icons/icons/wind-svg/n-wind/n25.svg").default, sdf: true},
      "n20": {uri: require("@mm/icons/icons/wind-svg/n-wind/n20.svg").default, sdf: true},
      "n15": {uri: require("@mm/icons/icons/wind-svg/n-wind/n15.svg").default, sdf: true},
      "n125": {uri: require("@mm/icons/icons/wind-svg/n-wind/n125.svg").default, sdf: true},
      "n120": {uri: require("@mm/icons/icons/wind-svg/n-wind/n120.svg").default, sdf: true},
      "n115": {uri: require("@mm/icons/icons/wind-svg/n-wind/n115.svg").default, sdf: true},
      "n110": {uri: require("@mm/icons/icons/wind-svg/n-wind/n110.svg").default, sdf: true},
      "n105": {uri: require("@mm/icons/icons/wind-svg/n-wind/n105.svg").default, sdf: true},
      "n100": {uri: require("@mm/icons/icons/wind-svg/n-wind/n100.svg").default, sdf: true},
      "n10": {uri: require("@mm/icons/icons/wind-svg/n-wind/n10.svg").default, sdf: true},
      "n0": {uri: require("@mm/icons/icons/wind-svg/n-wind/n0.svg").default, sdf: true},
      "Wind_barb_data_not_reported": {uri: require("@mm/icons/icons/wind-svg/n-wind/Wind_barb_data_not_reported.svg").default, sdf: true},
      "Wind_barb_data_missing": {uri: require("@mm/icons/icons/wind-svg/n-wind/Wind_barb_data_missing.svg").default, sdf: true}
}
import { createAndStartService } from "@/models/xstate-test-utils/machineTestUtils";
import reduxStore from "@/reducer";
import { setMapDrawings } from "@/reducer/MapsState";
import { type StateMachine, createMachine } from "xstate";
import type { MapDrawingMasterCtx } from "./context";
import { MapDrawingToolEventType, type MapDrawingToolEventUnion } from "./events";

export enum MapDrawingState {
  INACTIVE = "inactive",
  IDLE = "idle",
  DRAW_RADIUS = "draw_radius",
}

export type MapDrawingToolStateType =
  | {
      value: MapDrawingState.INACTIVE;
      context: unknown;
    }
  | {
      value: MapDrawingState.IDLE;
      context: unknown;
    }
  | {
      value: MapDrawingState.DRAW_RADIUS;
      context: unknown;
    };

export type MapDrawingMasterMachine = StateMachine<
  MapDrawingMasterCtx,
  any,
  MapDrawingToolEventUnion,
  MapDrawingToolStateType
>;

/**
 * Responsible synchronizing the drawing modules between different maps.
 * e.g: Switch the drawing mode of all maps.
 */
export const createMapDrawingToolStateMachine = (initialState = MapDrawingState.INACTIVE): MapDrawingMasterMachine => {
  return createMachine<any, MapDrawingToolEventUnion, MapDrawingToolStateType>(
    {
      id: "mapDrawingToolsState",
      initial: initialState,

      states: {
        inactive: {
          on: {
            toggle: {
              target: MapDrawingState.IDLE,
            },
          },
        },
        idle: {
          on: {
            toggle: {
              target: MapDrawingState.INACTIVE,
            },
            toDrawRadius: {
              target: MapDrawingState.DRAW_RADIUS,
            },
          },
        },
        draw_radius: {
          on: {
            toggle: {
              target: MapDrawingState.INACTIVE,
            },
            toDrawRadius: {
              target: MapDrawingState.INACTIVE,
            },
            publish: {
              target: MapDrawingState.IDLE,
              actions: ["store"],
            },
          },
        },
      },
      predictableActionArguments: true,
    },
    {
      actions: {
        store: (_context, event) => {
          if (event.type === MapDrawingToolEventType.publish) {
            reduxStore.dispatch(setMapDrawings({ id: event.mapId, data: event.data }));
          }
        },
      },
    },
  );
};

export const defaultMapDrawingToolStateMachine = createMapDrawingToolStateMachine();
export const globalMapDrawingService = createAndStartService(defaultMapDrawingToolStateMachine);

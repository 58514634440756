import type { NestedRowData, TypedColDef } from "@/overlay/components/CommonParts/tables/nestedTable/utils";
import { useLocationTableThresholds } from "@/reducer/LocationTableThresholdState";
import { generateThresholdCellStyle } from "@/weatherTableView/cellsRenderers/CellStyleGenerator";
import { t } from "@lingui/macro";
import { DateTime } from "luxon";
import { useMemo } from "react";

export type MultiLocationTableColumnDefsProps = {
  tableParam: string;
  childRow?: NestedRowData;
  isColumnGrouped?: boolean;
  tableId: number;
};

export const useMultiLocationColumnDefs = ({
  tableParam,
  childRow,
  isColumnGrouped,
  tableId,
}: MultiLocationTableColumnDefsProps) => {
  const thresholds = useLocationTableThresholds(tableId);
  return useMemo(() => {
    if (!childRow) {
      return [];
    }

    const columns: string[] = Object.keys(childRow).filter((key) => !["id", "children", "parentId"].includes(key));
    const columnGroups: Record<string, string[]> = {};
    const currentColdef: TypedColDef<NestedRowData>[] = [{ headerName: t`Location`, field: "id" }];

    for (const column of columns) {
      const columnHeader = `${DateTime.fromISO(column).toLocaleString({
        month: "short",
        day: "2-digit",
        timeZone: "Europe/Zurich",
      })} ${t`(Europe/Zurich)`}`;
      if (columnGroups[columnHeader]) {
        columnGroups[columnHeader].push(column);
      } else {
        columnGroups[columnHeader] = [column];
      }
    }
    for (const [header, columnList] of Object.entries(columnGroups)) {
      const childRowHeaders: TypedColDef<NestedRowData>[] = columnList.map((column) => ({
        headerName: DateTime.fromISO(column, { zone: "Europe/Zurich" }).toFormat("HH:mm"),
        field: column,
        columnGroupShow: "open",
        width: 85,
        cellStyle: generateThresholdCellStyle(thresholds || []),
      }));
      if (isColumnGrouped) {
        childRowHeaders.push({ field: " ", columnGroupShow: "closed", headerName: " " });
      }
      currentColdef.push({
        headerName: header,
        // @ts-ignore this is a valid column attribute
        groupId: header,
        children: [...childRowHeaders],
      });
    }

    return currentColdef;
  }, [childRow, tableParam, isColumnGrouped, thresholds]);
};

import { Api } from "@/api/Api";
import type { Profile, Tab, TabCreate, TabUpdate } from "@mm/metx-workbench.meteomatics.com";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchTab = createAsyncThunk("api/tab", async (payLoad: { tabId: Tab["id"] }, _thunkAPI) => {
  return await Api.tab.v2ReadTab({ tabId: payLoad.tabId }).then((tab) => {
    return tab;
  });
});

export const createTab = createAsyncThunk(
  "api/tab/create",
  async (payLoad: { profileId: Profile["id"]; data: TabCreate }, _thunkAPI) => {
    return await Api.tab.v2CreateTab({ tabCreate: payLoad.data, profileId: payLoad.profileId }).then((tab) => {
      return tab;
    });
  },
);

export const removeTab = createAsyncThunk(
  "api/tab/delete",
  (payLoad: { tabId: Tab["id"]; profileId: Profile["id"] }, _thunkAPI) => {
    return Api.tab.v2DeleteTab({ tabId: payLoad.tabId }).then(() => {
      return { tabId: payLoad.tabId, profileId: payLoad.profileId };
    });
  },
);

export const updateTabOrders = createAsyncThunk(
  "api/tabs/updateOrders",
  async (payLoad: { tabs: TabUpdate[] }, _thunkAPI) => {
    try {
      const response = await Api.tab.v2UpdateTabOrders({ tabUpdate: payLoad.tabs });
      return payLoad.tabs; // Return the payload as the result of the action
    } catch (error) {
      console.error("Error updating tab orders:", error); // Log any error that occurs during the API call
      throw error; // Re-throw the error to be handled by the caller
    }
  },
);

import type { Feature, LineString, Polygon, Position } from "geojson";

// Helper function to calculate the size of coordinates array in characters
function calculateFeatureSize(coordinates: Position[]): number {
  const coordinatesStr = JSON.stringify(coordinates);
  return new Blob([coordinatesStr]).size / 1024;
}

/**
 * Splits an array of LineString features into chunks based on a maximum size in Kilobytes.
 *
 * This function takes an array of GeoJSON LineString features and splits them into smaller chunks.
 * Each chunk's total size in Kilobytes (based on the blob size with the coordinates) does not exceed the specified maxSize.
 * If a single feature exceeds the maxSize, it will be placed in its own chunk.
 *
 * @param features - An array of GeoJSON LineString features to be chunked.
 * @param maxSize - The maximum size in Kilobytes for each chunk.
 * @returns An array of chunks, where each chunk is an array of LineString features.
 */
export function chunkLineStringFeatureArrayByMaxSize(
  features: Feature<LineString>[],
  maxSize: number,
): Feature<LineString>[][] {
  const chunks: Feature<LineString>[][] = [];
  let currentChunk: Feature<LineString>[] = [];
  let currentChunkSize = 0;

  for (const feature of features) {
    const featureSize = calculateFeatureSize(feature.geometry.coordinates);
    // If adding the current feature exceeds the maximum size
    if (currentChunkSize + featureSize > maxSize) {
      // If the current chunk is not empty, push it to the chunks
      if (currentChunk.length > 0) {
        chunks.push(currentChunk);
        currentChunk = [];
        currentChunkSize = 0;
      }

      // If the feature itself is larger than maxSize, push it directly to chunks
      if (featureSize > maxSize) {
        chunks.push([feature]);
      } else {
        // Otherwise, start a new chunk with the current feature
        currentChunk.push(feature);
        currentChunkSize += featureSize;
      }
    } else {
      // Add the current feature to the current chunk
      currentChunk.push(feature);
      currentChunkSize += featureSize;
    }
  }

  // Add the last chunk if it is not empty
  if (currentChunk.length > 0) {
    chunks.push(currentChunk);
  }

  return chunks;
}

import type { EnsSelectIdentifier } from "@mm/api.meteomatics.com";
import { enumValues } from "@mm/api.meteomatics.com/lib/utility/enum";

export enum Quantile {
  quantile0 = "quantile0",
  "quantile0.1" = "quantile0.1",
  "quantile0.2" = "quantile0.2",
  "quantile0.3" = "quantile0.3",
  "quantile0.4" = "quantile0.4",
  "quantile0.5" = "quantile0.5",
  "quantile0.6" = "quantile0.6",
  "quantile0.7" = "quantile0.7",
  "quantile0.8" = "quantile0.8",
  "quantile0.9" = "quantile0.9",
  "quantile1.0" = "quantile1.0",
}

export enum Spread {
  spread = "spread",
}

export enum EnsSelectStat {
  median = "median",
  mean = "mean",
}

export const band100p = "quantile0,quantile1";
export const band80p = "quantile0.1,quantile0.9";
export const band60p = "quantile0.2,quantile0.8";
export const band40p = "quantile0.3,quantile0.7";
export const band20p = "quantile0.4,quantile0.6";

export const quantileBandList = [band100p, band80p, band60p, band40p, band20p];

export const quantileBands = {
  band100p: "quantile0,quantile1",
  band80p: "quantile0.1,quantile0.9",
  band60p: "quantile0.2,quantile0.8",
  band40p: "quantile0.3,quantile0.7",
  band20p: "quantile0.4,quantile0.6",
};

type Members = string; // member:0 to member:50 (not on all model/source)
export type QuantilenOptionUnion = keyof typeof Quantile;
export type EnsOption = QuantilenOptionUnion | Spread | Members | string;
export const quantilenValues = enumValues(Quantile);
export const spreadValue = enumValues(Spread);
export const ensSelectRemainsValue = enumValues(EnsSelectStat);

export type EnsSelectArray = string[];
export type EnsSelectValue = string;

export function constructStringFromMultiSelectEnsembles(...ensembles: string[]) {
  return ensembles.join(";");
}

export function deconstructEnsStringFromMultiSelectEnsembles(ensembles?: string) {
  if (!ensembles) return [];
  return ensembles.split(";");
}

export function getAllEnsSelect(ens_select?: string, num_ensemble_members?: number): EnsSelectIdentifier {
  // no ens_selected but it's a ens model
  if (!ens_select && num_ensemble_members) {
    //set to member:0
    return `member:0-${num_ensemble_members - 1}`;
  }

  if (!ens_select) {
    return ""; // no ens_select should also not member:0
  }
  if (num_ensemble_members && ens_select.startsWith("member:")) {
    return `member:0-${num_ensemble_members - 1}`;
  }

  if (ens_select.startsWith("quantile-all")) {
    const allQuantile = quantilenValues.join(",");
    return `${allQuantile}`;
  }

  // spread, mean, median
  return ens_select;
}

/**
 * All Ensemble Member Selection
 * https://www.meteomatics.com/en/api/request/optional-parameters/ensemble-member-selection/#ensemble_member_selection
 * Note about a few exceptions:
 * ens_select combine options like member:1-50 or comma separated (quantile0,quantile0.3,...) are only in single location request available (plot)
 * and not for area request like (wms and iso lines)
 *
 * Spread option description
 * https://confluence.ecmwf.int/display/FUG/ENS+Mean+and+Spread#ENSMeanandSpread-EnsembleSpread
 */

const ensSelectWithoutMember = [...quantilenValues, ...spreadValue, ...ensSelectRemainsValue, ...quantileBandList];
const allowedMembers = [...Array(51).keys()].map((num) => `member:${num}`);

// filter all invalid ensSelect values and return an array
export function transformEnsSelectStringToArray(ensSelect: EnsSelectValue): EnsSelectArray {
  const array = ensSelect.split(",").filter((value) => {
    return (
      ensSelectWithoutMember.includes(value as Quantile | Spread | EnsSelectStat) || allowedMembers.includes(value)
    );
  });

  return array;
}

// filter all invalid ensSelect array value and return it as string
export function transformEnsSelectArrayToString(ensSelectArray: EnsSelectArray): EnsSelectValue {
  return ensSelectArray
    .filter((value) => {
      return (
        ensSelectWithoutMember.includes(value as Quantile | Spread | EnsSelectStat) || allowedMembers.includes(value)
      );
    })
    .join(",");
}

export function createMembers(num: number) {
  const members: string[] = [];
  for (let i = 0; i < num; i++) {
    members.push(`member:${i}`);
  }
  return members;
}

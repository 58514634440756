import type { Authentication } from "./Authentication";

/**
 * Abstract authenticaiton method that lets you initialize requests before authentication is available.
 *
 * ```ts
 * const authentication = new BasicAuthentication();
 * const api = new MeteomaticsApi({ authentication });
 *
 * let myRequest = api.png({
 *    width: 512,
 *    height: 512,
 *    // ...
 * }).then(png => console.log("your png is ready!"))
 *
 * // get info through a login dialog at a later point in time.
 * const username = prompt("username?");
 * const password = prompt("password?");
 *
 * // `myRequest` should resolve shortly after the next line
 * authentication.update({ username, password });
 * ```
 */
export interface Pending {
  promise: Promise<string>;
  resolve: (_: string) => void;
  reject: (_: any) => void;
}

export abstract class LazyAuthentication<T> implements Authentication {
  protected value: string | Pending;

  constructor(headerValue?: T) {
    this.value = this._update(headerValue ?? null);
  }

  getAuthorizationHeaderValue(): Promise<string | null> {
    if (typeof this.value === "string") {
      return Promise.resolve(this.value);
    }
    return this.value.promise;
  }

  getAccessToken(): Promise<string | null> {
    return Promise.resolve(null);
  }

  available(): boolean {
    return typeof this.value === "string";
  }

  update(headerValue: T | null) {
    if (typeof this.value !== "string" && headerValue) {
      this.value.resolve(this.makeValue(headerValue));
    }
    this.value = this._update(headerValue);
  }

  forceGetRequest(): boolean {
    return false;
  }

  private _update(headerValue: T | null): string | Pending {
    if (headerValue == null) {
      if (typeof this.value !== "string" && this.value) return this.value;

      let resolve: any;
      let reject: any;
      const promise = new Promise<string>((res, rej) => {
        resolve = res;
        reject = rej;
      });
      return { promise, resolve, reject } as unknown as Pending; // TODO: get rid of unsafe cast
    }

    return this.makeValue(headerValue);
  }

  protected abstract makeValue(args: T): string;
}

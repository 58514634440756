import type { JSONResponseBody, JSONResponseDataPoints } from "@mm/api.meteomatics.com";

/**
 * On the API Response for JSON we have currently a hirarchical data object
 * that looks like this:
 *
 * export type JSONResponseBody = {
 *   data: {
 *     coordinates: JSONResponseDataPoints[];
 *     parameter: string;
 *   }[];
 *   dateGenerated: string;
 *   status: string;
 *   user: string;
 *   version: string;
 * };
 *
 * export type JSONResponseDataPoints = {
 *   dates: { date: string; value: any }[];
 *   lat: number;
 *   lon: number;
 * };
 *
 *
 * usually on the request we would have a parameter name like t_2m:C and response gives us the same back,
 * but with the ensemble request
 * ens_select:member0-50 we get a parameter name back with t_2m:C-m0, t_2m:C-m1, ...
 * To get this flatten we convert it here to a list with objects of
 * {
 *    dates: { date: string; value: any }[];
 *    lat: number;
 *    lon: number;
 *    parameter: string;
 * }
 */
export type JsonResponseDataPoints = JSONResponseDataPoints & { parameter: string };
export type JsonWithParameter = JsonResponseDataPoints[][];

export function createJsonWithParameter(data: JSONResponseBody): JsonWithParameter {
  return data.data.map((singleLocation) => {
    const param = singleLocation.parameter;
    return [
      ...singleLocation.coordinates.map((coord) => {
        return { ...coord, parameter: param };
      }),
    ];
  });
}

import { kmToMiles } from "@/utility/units";
import { circle, feature, geometry, length } from "@turf/turf";
import type { Feature, GeoJsonProperties, LineString } from "geojson";

export const constructRadiusDisplay = (radiusLine: Feature<LineString, GeoJsonProperties>) => {
  if (!radiusLine.geometry.coordinates) {
    return;
  }

  const center = radiusLine.geometry.coordinates[0];

  if (center) {
    const radiusInKm = length(radiusLine, { units: "kilometers" });
    const circleFeature = circle(center, radiusInKm, {
      units: "kilometers",
    });

    const labelFeature = feature(geometry("Point", radiusLine.geometry.coordinates[1]), {
      meta: "radius-handle",
      radius: Math.round(radiusInKm),
      radiusMiles: Math.round(kmToMiles(radiusInKm)),
    });

    return [circleFeature, labelFeature];
  }
};

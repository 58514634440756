import { fetchTab } from "@/api/hooks/tab";
import type { NoteSchema, NoteUpdate } from "@mm/metx-workbench.meteomatics.com";
import { type PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import type { RootState } from ".";

import { createNote } from "@/api/hooks/tools/note";
import Logger from "logging";

const logger = Logger.fromFilename(__filename);

export type NotesState = {
  [id: number]: NoteSchema;
};

const initialState: NotesState = {};

export const noteSlice = createSlice({
  name: "notes",
  initialState: initialState as NotesState,
  reducers: {
    addNote(state, action: PayloadAction<NoteSchema>) {
      state[action.payload.id] = action.payload;
    },
    discardNote(state, action: PayloadAction<{ id: number }>) {
      const { id } = action.payload;
      const tool = state[id];

      if (!tool) {
        logger.error("Could not find tool with id", id);
        return;
      }
      delete state[id];
    },

    updateNote(state, action: PayloadAction<{ id: number; note: Partial<NoteUpdate> }>) {
      const { note, id } = action.payload;

      const noteFromState = state[id];

      if (!noteFromState) {
        logger.error("Could not find note with id", id);
        return;
      }

      const updatedNote = {
        ...noteFromState,
        ...note,
      };

      state[id] = updatedNote;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTab.fulfilled, (state, action) => {
      const tab = action.payload;
      for (const key in state) {
        delete state[+key];
      }
      for (const note of tab.notes) {
        state[note.id] = note;
      }
    });
    builder.addCase(createNote.fulfilled, (state, action) => {
      const table = action.payload.tool;
      state[table.id] = table;
    });
  },
});

const selectNotes = (state: RootState) => state.tabGroup.present.notes;

const makeSelectNotesByTabId = createSelector(
  [selectNotes, (state: RootState, tabId: number) => tabId],
  (notes, tabId) => Object.values(notes).filter(note => note.id_tab === tabId)
);

export function useNotes(tabId: number) {
  return useSelector((state: RootState) => makeSelectNotesByTabId(state, tabId));
}

const makeSelectNoteById = createSelector(
  [selectNotes, (state: RootState, id: number) => id],
  (notes, id) => notes[id]
);

export function useNote(id: number) {
  return useSelector((state: RootState) => makeSelectNoteById(state, id));
}

export const { addNote, discardNote, updateNote } = noteSlice.actions;

export default noteSlice.reducer;

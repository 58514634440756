import { useCallback } from "react";
import { type FieldValues, type UseFormProps, type UseFormReturn, useForm } from "react-hook-form";
import type { PathOrUndefined } from "../commonTypeUtils";

/**
 * Equivalent to react-hook-form useForm, but includes additional in-house utilities.
 */
export function useFormWithUtils<FormDataType extends FieldValues>(
  props?: UseFormProps<FormDataType>,
): {
  /**
   * An utility to check if the given string is a dotted notation of one of the attributes within the form data type.
   *
   * e.g: Given the following to the hook's type parameter:
   * ```
   * type SomeFormData = {
   *  attr1: {
   *    "subAttrA": string
   *  }
   *  attr2: number
   * }
   * ```
   *
   * ```
   * const {formValName} = useFormWithUtils<SomeFormData>()
   * ```
   *
   * Then,
   * ```
   * formValName(<string>)
   * ```
   * will assert if the given string is one of:
   * - `attr1`
   * - `attr1.subAttrA`
   * - `attr2`
   */
  formValName: (valueName: PathOrUndefined<FormDataType>) => string;
  formMethods: UseFormReturn<FormDataType>;
} {
  const formMethods = useForm(props);

  const s = useCallback((s: PathOrUndefined<FormDataType>) => s, []);

  return { formValName: s, formMethods: formMethods };
}

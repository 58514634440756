import {
  aviationLayerConfig,
  backgroundLayerConfig,
  barbsLayerConfig,
  customGeoJsonLayerConfig,
  defaultOpacity,
  defaultTextColor,
  gridLayerConfig,
  isoLinesLayerConfig,
  lightningLayerConfig,
  stationLayerConfig,
  symbolLayerConfigWWcode,
  symbolLayerConfigWeatherSymbol,
  tropicalCycloneLayerConfig,
  weatherFrontsLayerConfig,
} from "@/constants/layerConfigAttributes";
import { groundStyles } from "@/constants/maptilerStyleOptions";
import { IS_NCM } from "@/env";
import type { LayerCreateUnion, LayerUnion } from "@/layers";
import { DefaultSymbolIconOpacity, getKnParameterName, weatherCodeParameterName } from "@/layers/geojson";
import type { ModeTypeSelection } from "@/overlay/components/LayerStackWindow/AddLayer/AddLayer";
import { DefaultIconData, DefaultPoiOptions, type PoiOptions } from "@/reducer/client-models";
import type { PoiLayerCreate } from "@/reducer/client-models/PoiLayer";
import type { PoiDataGrid } from "@/threads/DataProcessingThread/types";
import { createDirectionParameterName } from "@/utility/createDirectionParameterName";
import { searchEnginesWeatherParams } from "@/weather-parameters";
import { t } from "@lingui/macro";
import {
  type AviationLayer,
  type AviationLayerCreate,
  type BackgroundMapLayer,
  type BackgroundMapLayerCreate,
  type BarbsLayerCreate,
  type CustomGeoJSONLayer,
  type CustomGeoJSONLayerCreate,
  type GridLayerCreate,
  type IsoLinesLayerCreate,
  type LightningLayerCreate,
  type PressureSystemLayerCreate,
  type StationLayerCreate,
  type SymbolLayer,
  type SymbolLayerCreate,
  SymbolLayerType,
  type TropicalCycloneLayer,
  type TropicalCycloneLayerCreate,
  type WeatherFrontsLayerCreate,
  type WindAnimationLayerCreate,
  type WmsLayerCreate,
} from "@mm/metx-workbench.meteomatics.com";
import Logger from "logging";
import type { WeatherParamSearchParallel } from "weather-parameter-utils/lib";
const logger = Logger.fromFilename(__filename);

export enum LayerKindName {
  WmsLayerDescription = "WmsLayerDescription",
  GridLayerDescription = "GridLayerDescription",
  IsoLinesLayerDescription = "IsoLinesLayerDescription",
  BarbsLayerDescription = "BarbsLayerDescription",
  SymbolLayerDescription = "SymbolLayerDescription",
  StationLayerDescription = "StationLayerDescription",
  BackgroundMapDescription = "BackgroundMapDescription",
  GenericPoiLayerDescription = "GenericPoiLayerDescription",
  AviationLayerDescription = "AviationLayerDescription",
  PressureSystemLayerDescription = "PressureSystemLayerDescription",
  LightningLayerDescription = "LightningLayerDescription",
  TropicalCycloneLayerDescription = "TropicalCycloneLayerDescription",
  WeatherFrontsLayerDescription = "WeatherFrontsLayerDescription",
  WindAnimationLayerDescription = "WindAnimationLayerDescription",
  CustomGeoJSONLayerDescription = "CustomGeoJSONLayerDescription",
  DrawingLayerDescription = "DrawingLayerDescription",
}

export type LayerKind = keyof typeof LayerKindName;

export type LayerWithParameterUnit = Exclude<LayerUnion, BackgroundMapLayer | AviationLayer | TropicalCycloneLayer>;

export type ModeType = {
  kind: LayerKind;
  name: () => string;
  experimental?: boolean;
  weatherParam?: {
    defaultSelect: string;
  };
  /**
   * Specifies whether or not the mode requires parameter search and which search engines are used for parameter search.
   * A user can choose different search options in the particular mode.
   * Provide an empty array if parameter search is unavailable for this mode.
   */
  paramSearchOptions: {
    /**
     * A label used for the user to select different search engines.
     */
    label: string;
    searchEngine: WeatherParamSearchParallel;
  }[];
};

export const allLayerModes: ModeType[] = [
  {
    kind: LayerKindName.WmsLayerDescription,
    name: () => t`Color Maps`,
    weatherParam: {
      defaultSelect: "",
    },
    paramSearchOptions: [
      {
        label: "Standard",
        searchEngine: searchEnginesWeatherParams.standard,
      },
    ],
  },
  {
    kind: LayerKindName.GridLayerDescription,
    name: () => t`Grid`,
    weatherParam: {
      defaultSelect: "",
    },
    paramSearchOptions: [
      {
        label: "Standard",
        searchEngine: searchEnginesWeatherParams.standard,
      },
    ],
  },
  {
    kind: LayerKindName.IsoLinesLayerDescription,
    name: () => t`Isolines`,
    weatherParam: {
      defaultSelect: "",
    },
    paramSearchOptions: [
      {
        label: "Standard",
        searchEngine: searchEnginesWeatherParams.standard,
      },
    ],
  },
  {
    kind: LayerKindName.IsoLinesLayerDescription,
    name: () => t`Isolines Ensemble`,
    experimental: true,
    weatherParam: {
      defaultSelect: "",
    },
    paramSearchOptions: [
      {
        label: "Standard",
        searchEngine: searchEnginesWeatherParams.standard,
      },
    ],
  },
  {
    kind: LayerKindName.SymbolLayerDescription,
    name: () => t`WW Code`,
    weatherParam: {
      defaultSelect: "weather_code_1h:idx",
    },
    paramSearchOptions: [],
  },
  {
    kind: LayerKindName.SymbolLayerDescription,
    name: () => t`Weather Symbols`,
    weatherParam: {
      defaultSelect: "weather_symbol_1h:idx",
    },
    paramSearchOptions: [],
  },
  {
    kind: LayerKindName.BarbsLayerDescription,
    name: () => t`Wind Barbs`,
    weatherParam: {
      defaultSelect: "wind_speed_10m:kn",
    },
    paramSearchOptions: [],
  },
  {
    kind: LayerKindName.BarbsLayerDescription,
    name: () => t`Wave Barbs`,
    weatherParam: {
      defaultSelect: "ocean_current_speed_10m:kn",
    },
    paramSearchOptions: [],
  },
  {
    kind: LayerKindName.WindAnimationLayerDescription,
    name: () => t`Wind Animation`,
    weatherParam: {
      // Wind animation uses wind U and V component data.
      // Since the format is the same for both wind_speed_u and wind_speed_v,
      // we only store U component and derive the V component options in layer class.
      defaultSelect: "wind_speed_u_10m:ms",
    },
    paramSearchOptions: [],
  },
  {
    kind: LayerKindName.StationLayerDescription,
    name: () => t`Station Data`,
    weatherParam: {
      defaultSelect: "",
    },
    paramSearchOptions: [
      {
        label: "Observations",
        searchEngine: searchEnginesWeatherParams.stationObs,
      },
      {
        label: "MOS Forecasts",
        searchEngine: searchEnginesWeatherParams.stationMos,
      },
    ],
  },
  {
    kind: LayerKindName.BackgroundMapDescription,
    name: () => t`Cartographic Material`,
    paramSearchOptions: [],
  },
  {
    kind: LayerKindName.GenericPoiLayerDescription,
    name: () => t`Point Of Interest`,
    paramSearchOptions: [],
  },
  {
    kind: LayerKindName.AviationLayerDescription,
    name: () => t`Aviation`,
    paramSearchOptions: [],
  },
  {
    kind: LayerKindName.PressureSystemLayerDescription,
    weatherParam: {
      defaultSelect: "msl_pressure:hPa",
    },
    name: () => t`Pressure System`,
    paramSearchOptions: [],
  },
  {
    kind: LayerKindName.LightningLayerDescription,
    name: () => t`Lightning Data`,
    paramSearchOptions: [],
  },
  {
    kind: LayerKindName.TropicalCycloneLayerDescription,
    name: () => t`Tropical Cyclone`,
    paramSearchOptions: [],
  },
  {
    kind: LayerKindName.WeatherFrontsLayerDescription,
    name: () => t`Weather Fronts`,
    paramSearchOptions: [],
  },
  {
    kind: LayerKindName.CustomGeoJSONLayerDescription,
    name: () => t`Custom GeoJson`,
    paramSearchOptions: [],
  },
];

export function createPoiLayerObject(
  newIdx: number,
  selectedMode: ModeTypeSelection,
  importedCoords: PoiDataGrid,
): LayerCreateUnion {
  const layerProps = {
    kind: selectedMode.kind,
    index: newIdx,
    show: true,
  };

  const poiOptions: PoiOptions = {
    hideLabels: true,
    item: {
      ...DefaultIconData,
      ...(importedCoords.length ? { coords: importedCoords } : {}),
    },
  };
  // On Poi Layer create should not be a parameter_unit
  const poiLayer: PoiLayerCreate = {
    model: "mix",
    parameter_unit: "",
    opacity: 1,
    poiOptions,
    ...layerProps,
  };
  return poiLayer;
}

function getSymbolLayerTypeFromParam(props: Pick<SymbolLayer, "parameter_unit">): SymbolLayerType {
  // Note: This function is only to be able to tell which variance of
  // symbol layer we're creating based on parameter. However, if we structure
  // AddLayer component well, we shouldn't have to do this.
  if (props.parameter_unit.startsWith(weatherCodeParameterName)) {
    return SymbolLayerType.WeatherCode;
  }
  if (props.parameter_unit.startsWith("weather_symbol_")) {
    return SymbolLayerType.WeatherSymbol;
  }

  throw Error("Unknown symbol layer variace.");
}

export function createLayerObject(
  parameterName: { formatted: string },
  newIdx: number,
  selectedMode: ModeType,
  selectedModel: string,
): LayerCreateUnion {
  const layerProps = {
    kind: selectedMode.kind,
    index: newIdx,
    show: true,
  };

  switch (selectedMode.kind) {
    case LayerKindName.WmsLayerDescription: {
      const wmsLayer: WmsLayerCreate = {
        model: selectedModel,
        parameter_unit: parameterName.formatted,
        color_map: "",
        opacity: defaultOpacity,
        legend_visible: true,
        custom_options: {},
        ...layerProps,
      };
      return wmsLayer;
    }
    case LayerKindName.GridLayerDescription: {
      const gridLayer: GridLayerCreate = {
        model: selectedModel,
        parameter_unit: parameterName.formatted,
        text_size: gridLayerConfig.defaultTextSize,
        text_color: defaultTextColor,
        step: gridLayerConfig.defaultStep,
        opacity: defaultOpacity,
        ...layerProps,
      };
      return gridLayer;
    }
    case LayerKindName.IsoLinesLayerDescription: {
      const isoLinesLayer: IsoLinesLayerCreate = {
        model: selectedModel,
        parameter_unit: parameterName.formatted,
        text_color: defaultTextColor,
        text_size: isoLinesLayerConfig.defaultTextSize,
        line_width: isoLinesLayerConfig.defaultIsoLineWidth,
        line_color: isoLinesLayerConfig.defaultLineColor,
        opacity: defaultOpacity,
        filter_gauss: isoLinesLayerConfig.defaultGaussian,
        filter_median: isoLinesLayerConfig.defaultMedian,
        // If Iso Line is flagged as experimental, vector tile layer will be used
        experimental: selectedMode.experimental,
        // values: '-10,0,5,10,15', // Both values and value_range are optional parameters.
        // value_range: "-10,20,2",
        ...layerProps,
      };
      return isoLinesLayer;
    }
    case LayerKindName.WeatherFrontsLayerDescription: {
      const weatherFrontsLayer: WeatherFrontsLayerCreate = {
        model: selectedModel,
        parameter_unit: "",
        text_size: weatherFrontsLayerConfig.iconDefaultSize,
        line_width: weatherFrontsLayerConfig.defaultLineWidth,
        opacity: 1,
        // If Iso Line is flagged as experimental, vector tile layer will be used
        experimental: selectedMode.experimental,
        ...layerProps,
      };
      return weatherFrontsLayer;
    }
    case LayerKindName.BarbsLayerDescription: {
      // overwrite parameter and use always kn
      const paramKnSpeed = getKnParameterName(parameterName.formatted);
      const parameterDir = createDirectionParameterName(paramKnSpeed);
      if (!parameterDir) {
        logger.error(`Could not replace speed with direction from parameter: ${paramKnSpeed}`);
      }

      const barbsLayer: BarbsLayerCreate = {
        model: selectedModel,
        parameter_unit: parameterName.formatted,
        parameter_unit_paired: parameterDir,
        opacity: defaultOpacity,
        step: barbsLayerConfig.defaultStep,
        element_color: defaultTextColor,
        custom_options: {
          icon_size: barbsLayerConfig.defaultIconSize,
        },
        ...layerProps,
      };
      return barbsLayer;
    }
    case LayerKindName.SymbolLayerDescription: {
      const symbolLayerType = getSymbolLayerTypeFromParam({ parameter_unit: parameterName.formatted });
      const symbolLayer: SymbolLayerCreate = {
        model: selectedModel,
        parameter_unit: parameterName.formatted,
        opacity: DefaultSymbolIconOpacity,
        layer_type: symbolLayerType, // (parameterName.formatted) has ww_code or weather_code then "WeatherCode" else "WeatherSymbol"
        step:
          symbolLayerType === SymbolLayerType.WeatherSymbol
            ? symbolLayerConfigWeatherSymbol.defaultStep
            : symbolLayerConfigWWcode.defaultStep,
        custom_options: {
          icon_size:
            symbolLayerType === SymbolLayerType.WeatherSymbol
              ? symbolLayerConfigWeatherSymbol.defaultIconSize
              : symbolLayerConfigWWcode.defaultIconSize,
        },
        ...layerProps,
      };
      return symbolLayer;
    }
    case LayerKindName.BackgroundMapDescription: {
      const backgroundMapLayer: BackgroundMapLayerCreate = {
        opacity: defaultOpacity,
        style: groundStyles[0].id,
        custom_options: {},
        ...layerProps,
      };
      return backgroundMapLayer;
    }
    case LayerKindName.StationLayerDescription: {
      const stationLayer: StationLayerCreate = {
        model: selectedModel,
        parameter_unit: parameterName.formatted,
        text_size: stationLayerConfig.defaultTextSize,
        text_color: defaultTextColor,
        ...layerProps,
      };
      return stationLayer;
    }
    case LayerKindName.GenericPoiLayerDescription: {
      const poiOptions: PoiOptions = { ...DefaultPoiOptions };
      const poiLayer: PoiLayerCreate = {
        model: "mix",
        parameter_unit: "t_2m:C",
        opacity: 1,
        poiOptions,
        ...layerProps,
      };
      return poiLayer;
    }
    case LayerKindName.AviationLayerDescription: {
      const aviationLayer: AviationLayerCreate = {
        aviation_type: "metar",
        opacity: 1,
        text_size: aviationLayerConfig.defaultSize,
        ...layerProps,
      };

      return aviationLayer;
    }
    case LayerKindName.PressureSystemLayerDescription: {
      const pressureSystemLayer: PressureSystemLayerCreate = {
        model: selectedModel,
        parameter_unit: parameterName.formatted,
        text_color: defaultTextColor,
        text_size: isoLinesLayerConfig.defaultTextSize,
        line_width: isoLinesLayerConfig.defaultIsoLineWidth,
        line_color: isoLinesLayerConfig.defaultLineColor,
        opacity: defaultOpacity,
        filter_gauss: isoLinesLayerConfig.defaultGaussian,
        filter_median: isoLinesLayerConfig.defaultMedian,
        value_range: "950,1050,4",
        ...layerProps,
      };
      return pressureSystemLayer;
    }
    case LayerKindName.LightningLayerDescription: {
      const lightningLayer: LightningLayerCreate = {
        model: selectedModel,
        parameter_unit: "lightnings_120",
        text_size: lightningLayerConfig.defaultTextSize,
        text_color: lightningLayerConfig.defaultColor,
        ...layerProps,
      };
      return lightningLayer;
    }
    case LayerKindName.TropicalCycloneLayerDescription: {
      const tropicalCycloneLayer: TropicalCycloneLayerCreate = {
        aviation_type: "",
        line_width: tropicalCycloneLayerConfig.defaultLineWidth,
        line_color: tropicalCycloneLayerConfig.defaultLineColor,
        ...layerProps,
      };
      return tropicalCycloneLayer;
    }

    case LayerKindName.WindAnimationLayerDescription: {
      const windAnimationLayer: WindAnimationLayerCreate = {
        model: selectedModel,
        parameter_unit: parameterName.formatted,
        color_map: IS_NCM ? "wind_arabia" : "jet",
        opacity: 1,
        ...layerProps,
      };
      return windAnimationLayer;
    }
    case LayerKindName.CustomGeoJSONLayerDescription: {
      const customGeoJsonLayer: CustomGeoJSONLayerCreate = {
        custom_options: {
          line_color: backgroundLayerConfig.defaultLineColor,
          line_width: customGeoJsonLayerConfig.defaultLineWidth,
        },
        file_id: "",
        opacity: 1,
        ...layerProps,
      };
      return customGeoJsonLayer;
    }
    default: {
      throw new Error(`Unhandled kind: ${selectedMode.kind}`);
    }
  }
}

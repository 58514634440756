/**
 * T MUST not be nullable.
 */
export class ChangeDetector<T> {
  private previousValue: null | T = null;
  constructor(value: null | T = null) {
    this.previousValue = value;
  }

  changed(newValue: null | T, onChange?: (value: T) => void): boolean {
    const changed = newValue !== this.previousValue || newValue == null;
    this.previousValue = newValue;
    if (newValue !== null && changed) {
      onChange?.(newValue);
    }
    return changed;
  }
  get value() {
    return this.previousValue;
  }
}

import { Api } from "@/api/Api";
import {
  type AsyncResult,
  createNotRequestedAsyncResult,
  createPendingAsyncResult,
  createResolvedAsyncResult,
} from "@/cache/AsyncResult";
import { isSlicedCustomGeoJSON, processCustomGeoJson } from "@/cache/CustomGeoJSONCache/processCustomGeoJson";
import type { SlicedCustomGeoJSON } from "@/cache/CustomGeoJSONCache/types";
import { constructHashId } from "@/cache/CustomGeoJSONCache/utils";
import {
  type CacheDatum,
  CacheDatumState,
  type LoadedCacheDatum,
  type PermanentlyFailedCacheDatum,
  networkCaches,
} from "@/cache/GlobalCache";
import { FailureState, type RetryState, createRetryState } from "@/cache/SpatioTemporalTileCache/RetryState";
import { type Stats, jsxTable } from "@/cache/Stats";
import { emptyFeatureCollection } from "@/geojson";
import type { CustomGeoJsonRequestOption } from "@/layers/geojson/custom";
import { dataProcessingThreadPool } from "@/threads/DataProcessingThread/DataProcessingThreadPool";
import type { FeatureCollection, GeoJsonProperties, LineString, Polygon as PolygonGeometry } from "geojson";
import type { DateTime } from "luxon";

const CUSTOM_GEOJSON_FILE_CACHE_KEY = "CUSTOM_GEOJSON";

export interface GeoJSONCacheStats {
  count: number;
  pending: number;
}

export class CustomGeoJSONCache implements Stats<GeoJSONCacheStats> {
  protected cache: {
    [key: string]: CacheDatum<SlicedCustomGeoJSON, RetryState>;
  } = {};

  constructor(private readonly label_: string) {}

  stats(): GeoJSONCacheStats {
    let count = 0;
    let pending = 0;
    const cache = this.cache;

    for (const line in cache) {
      if (Object.hasOwn(cache, line)) {
        count++;

        if (cache[line].state === CacheDatumState.Pending) {
          pending++;
        }
      }
    }

    return { count, pending };
  }

  descJsx(): JSX.Element {
    return <>Special-purpose cache for API JSON Requests. Replacement Policy is LRU.</>;
  }

  label(): string {
    return this.label_;
  }

  statsJsx(): JSX.Element {
    return jsxTable(this.stats(), (_key, val) => val);
  }

  /**
   * A method to return a cached data from cache.
   * It does not trigger a new request when the given request hasn't been emitted.
   * @param cacheId
   * @returns
   */
  peekCache(cacheId: string): AsyncResult<SlicedCustomGeoJSON> {
    const v = this.cache[cacheId];
    if (!v) {
      return createNotRequestedAsyncResult({
        lineStrings: emptyFeatureCollection as FeatureCollection<LineString, GeoJsonProperties>,
        points: emptyFeatureCollection,
        polygons: emptyFeatureCollection as FeatureCollection<PolygonGeometry, GeoJsonProperties>,
      });
    }
    switch (v.state) {
      case CacheDatumState.Pending: {
        const retry_ = v.payload;
        if (retry_.failureState !== FailureState.FailedTemporarily) {
          return createPendingAsyncResult(v.promise);
        }
        // since the current invocation moves the state from [Pending/FailedTemporarily] to [Pending/Queued]
        // mark it as such to prevent concurrent invocations from reentering the remaining function body
        retry_.failureState = undefined;
        return createPendingAsyncResult(v.promise);
      }
      case CacheDatumState.PermanentlyFailed:
        return createResolvedAsyncResult({
          lineStrings: emptyFeatureCollection as FeatureCollection<LineString, GeoJsonProperties>,
          points: emptyFeatureCollection,
          polygons: emptyFeatureCollection as FeatureCollection<PolygonGeometry, GeoJsonProperties>,
        });
      case CacheDatumState.Loaded:
        return createResolvedAsyncResult(v.payload);
      default: {
        const _exhaustive: never = v;
        return _exhaustive;
      }
    }
  }

  retrieveCustomGeoJSON(
    customGeoJsonRequestOption: CustomGeoJsonRequestOption,
    datetime?: DateTime,
  ): AsyncResult<SlicedCustomGeoJSON> {
    const { file_id: fileId, model, parameter_unit, customOption } = customGeoJsonRequestOption;
    const hashId = constructHashId(fileId, datetime, model, parameter_unit, customOption);
    if (Object.hasOwn(this.cache, hashId)) {
      return this.peekCache(hashId);
    }

    const retry_: RetryState | null = null;
    const retry = retry_ ?? createRetryState();

    const promise = networkCaches.generic_cache
      .retrieve<any, any, string>([CUSTOM_GEOJSON_FILE_CACHE_KEY, fileId], () =>
        Api.customGeojson.v2GetCustomGeojson({ fileId }),
      )
      .then((geoJsonString) => dataProcessingThreadPool.run("sliceGeoJSONByFeatures", geoJsonString))
      .then((data) => processCustomGeoJson(data, retry, customGeoJsonRequestOption, datetime))
      .then((data) => {
        if (isSlicedCustomGeoJSON(data)) {
          return data;
        }
        return Promise.resolve(data);
      })
      .then((data) => {
        const cacheData: LoadedCacheDatum<SlicedCustomGeoJSON> = {
          requestDatetime: Date.now(),
          lastUsed: Number.NEGATIVE_INFINITY,
          state: CacheDatumState.Loaded,
          payload: data,
        };

        this.cache[hashId] = cacheData;
        return data;
      })
      .catch((e) => {
        const cacheData: PermanentlyFailedCacheDatum<null> = {
          requestDatetime: Date.now(),
          state: CacheDatumState.PermanentlyFailed,
          payload: null,
        };
        this.cache[hashId] = cacheData;

        return Promise.reject(e);
      });

    this.cache[hashId] = {
      requestDatetime: performance.now(),
      state: CacheDatumState.Pending,
      promise,
      payload: retry,
    };
    return createPendingAsyncResult(promise);
  }
}

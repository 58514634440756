import { t } from "@lingui/macro";

export const defaultTargetFPS = 10;

const targetFPSOptions: number[] = [0.2, 0.5, 1, 2, 5, defaultTargetFPS, 15, 20, 30, 60];

export function TargetFPSSelection(props: { targetFPS: number; onChange: (fps: number) => void }) {
  const targetFPS = props.targetFPS;

  function handler(e: React.ChangeEvent<HTMLSelectElement>) {
    const index = +e.target.value;
    const value = targetFPSOptions[index];
    props.onChange(value);
  }

  function indexOf(value: number) {
    return targetFPSOptions.findIndex((v) => v === value) ?? targetFPSOptions.findIndex((v) => v === defaultTargetFPS);
  }

  const options = targetFPSOptions.map((fps, index) => {
    return (
      <option key={fps.toString()} value={index}>
        {t`${fps} FPS`}
      </option>
    );
  });
  return (
    <>
      <select value={indexOf(targetFPS)} onChange={handler}>
        {options}
      </select>
    </>
  );
}

import Logger from "logging";
import { createContext, useContext, useReducer } from "react";
import type { ComponentFactory } from "./Desktop";
import type { DragItemType } from "./DragItemType";
import { type TrackId, defaultTrack } from "./TrackId";
import type { WindowIdentityProps } from "./Window";
import type { WindowCustomProps, WindowKind } from "./WindowKind";

const logger = Logger.fromFilename(__filename);

export type DesktopId_ = string;
export type WindowInstanceId = string;
export type WindowKind_ = string;

export interface WindowPosition {
  /**
   * `true` if the window is arbitrarily placed using drag and drop. `false` if the windows position and visibility is managed by a window track.
   * In this case, all positioning information including `top` and `left` should be ignored.
   */
  isManaged: boolean;
  /**
   * Track responsible for managing this window if `isManaged` is `true`.
   */
  trackId: TrackId;
  // TODO: in theory to support responsive screens, should position relative to closest edge
  top: number;
  left: number;

  /**
   * Timestamp of the last interaction with the window. Used to implement z-layering of windows and manage window lifetimes.
   */
  lastUse: number;
  /**
   * Timestamp of when the window became visible.
   */
  insertionTime: number;
}

export interface WindowState<K extends WindowKind = WindowKind> {
  isVisible: boolean;
  position: WindowPosition;
  customProps: WindowCustomProps<K>;
  size: WindowSize;
}

export interface WindowInfo<K extends WindowKind = WindowKind> {
  state: WindowState<K>;
  factory: ComponentFactory;
}

export interface DraggingWindowState extends WindowIdentityProps {
  type: DragItemType;
  /**
   * Shape and position of the window within the browser viewport.
   *
   * This information can be used by the preview renderer to match the shape of the window.
   */
  rect: DOMRect | null;
}

type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

export type PartialWindowState = RecursivePartial<WindowState>;

export function defaultWindowPosition(trackId: TrackId = defaultTrack): WindowPosition {
  return {
    isManaged: true,
    trackId,
    top: 0,
    left: 0,
    lastUse: 0,
    insertionTime: 0,
  };
}

export const defaultWindowSize: WindowSize = {
  height: 0,
  width: 0,
};

export function defaultWindowState(): WindowState {
  return {
    isVisible: false,
    position: defaultWindowPosition(),
    customProps: {},
    size: defaultWindowSize,
  };
}

export function mergeWindowState(
  overrides: PartialWindowState = {},
  base: WindowState = defaultWindowState(),
): WindowState {
  const position = {
    ...base.position,
    ...(overrides.position ?? {}),
  };

  const customProps = {
    ...base.customProps,
    ...overrides.customProps,
  };

  const size = {
    ...base.size,
    ...overrides.size,
  };

  return {
    ...base,
    ...overrides,
    customProps,
    position,
    size,
  };
}

export interface DragableArea {
  top?: number;
  left?: number;
  bottom?: number;
  right?: number;
}

export interface DesktopState {
  id: DesktopId_;
  windows: Map<WindowInstanceId, WindowInfo>;
  windowOrder: WindowInstanceId[];
  defaultWindowState: WindowState;
  dragableArea: DragableArea;
}

export function defaultDesktopState(
  windowFactories: ComponentFactory[] = [],
  baseWindowState = defaultWindowState(),
): DesktopState {
  const windowInfo: [WindowInstanceId, WindowInfo][] = windowFactories.map((factory) => [
    factory.instanceId,
    {
      state: mergeWindowState(factory.defaultWindowState, baseWindowState),
      factory,
    },
  ]);

  return {
    id: "default",
    windows: new Map<WindowInstanceId, WindowInfo>(windowInfo),
    windowOrder: windowInfo.map(([id]) => id),
    defaultWindowState: baseWindowState,
    dragableArea: {
      top: 70,
    },
  };
}

export interface DesktopContextApi {
  id: DesktopId_;
  dispatch: <K extends WindowKind>(action: DesktopAction<K>) => void;

  windowMap(): Map<WindowInstanceId, WindowInfo>;
  windows(): WindowInfo[];
  setWindowSize(windowId: WindowInstanceId, size: WindowSize): void;
  isWindowVisible(windowId: WindowInstanceId): boolean;
  getPosition(windowId: WindowInstanceId): WindowPosition;

  dragableArea: DragableArea;
}

export function useDesktopProviderValue(initialState: DesktopState = defaultDesktopState()): DesktopContextApi {
  const [state, dispatch] = useReducer<typeof desktopReducer, DesktopState>(desktopReducer, initialState, (v) => v);
  return {
    id: state.id,
    dispatch,

    windowMap(): Map<WindowInstanceId, WindowInfo> {
      return state.windows;
    },

    windows(): WindowInfo[] {
      return state.windowOrder.map((instanceId) => getWindow(state, instanceId));
    },

    setWindowSize(windowId: WindowInstanceId, size: WindowSize): void {
      dispatch({ type: "setWindowSize", windowId, size });
    },

    getPosition(windowId: WindowInstanceId): WindowPosition {
      return getWindow(state, windowId).state.position;
    },

    isWindowVisible(windowId: WindowInstanceId): boolean {
      return getWindow(state, windowId).state.isVisible;
    },

    dragableArea: state.dragableArea,
  };
}

function getWindow(state: DesktopState, windowId: WindowInstanceId): WindowInfo {
  const windowState = state.windows.get(windowId);

  if (!windowState) {
    return logger.unreachable(
      "unknown window instance <",
      windowId,
      ">. Available are: ",
      [...state.windows.keys()].join(", "),
    );
  }

  return windowState;
}

function updateWindowOrder(
  state: DesktopState,
  windowId: WindowInstanceId,
  before?: WindowInstanceId | number,
): DesktopState {
  const fromIndex = state.windowOrder.indexOf(windowId);
  const newOrder = [...state.windowOrder];
  const [element] = newOrder.splice(fromIndex, 1);

  if (before === undefined) {
    // should become last element
    newOrder.push(element);
  } else {
    const toIndex = typeof before === "number" ? before : state.windowOrder.indexOf(before);

    if (toIndex >= state.windowOrder.length || toIndex < 0) {
      return logger.unreachable("cannot place window before invalid reference <", before, ">.");
    }

    newOrder.splice(toIndex, 0, element);
  }

  return { ...state, windowOrder: newOrder };
}

function mergeState(state: DesktopState, windowId: WindowInstanceId, newWindowState: PartialWindowState): DesktopState {
  const { state: currentWindowState, factory } = getWindow(state, windowId);
  const updatedWindows = new Map(state.windows);
  updatedWindows.set(windowId, { state: mergeWindowState(newWindowState, currentWindowState), factory });
  return { ...state, windows: updatedWindows };
}

export type WindowSize = {
  width: number;
  height: number;
};

export type DesktopAction<K extends WindowKind> =
  | { type: "setWindowSize"; windowId: WindowInstanceId; size: WindowSize }
  | { type: "confirmationWindow"; windowId: WindowInstanceId; customProps: WindowCustomProps<K> }
  | { type: "setWindowVisibility"; windowId: WindowInstanceId; isVisible: boolean }
  | { type: "setAllWindowVisibility"; isVisible: boolean; exclude?: WindowKind[] }
  | { type: "toggleWindowVisibility"; windowId: WindowInstanceId }
  | { type: "setPosition"; windowId: WindowInstanceId; position: Partial<WindowPosition> }
  | { type: "wasUsed"; windowId: WindowInstanceId }
  | { type: "setWindowOrder"; windowId: WindowInstanceId; before?: WindowInstanceId | number }
  | { type: "setWindowCustomProps"; windowId: WindowInstanceId; windowKind: K; customProps: WindowCustomProps<K> };

function desktopReducer<K extends WindowKind>(desktopState: DesktopState, action: DesktopAction<K>) {
  // let state: WindowState<WindowKind> | null = null;
  switch (action.type) {
    case "setWindowSize":
      return mergeState(desktopState, action.windowId, { size: action.size });
    case "confirmationWindow": {
      return mergeState(desktopState, action.windowId, { customProps: action.customProps, isVisible: true });
    }
    case "setWindowVisibility": {
      const newDesktopState = mergeState(desktopState, action.windowId, {
        isVisible: action.isVisible,
        position: { insertionTime: performance.now() },
      });
      return updateWindowOrder(newDesktopState, action.windowId);
    }
    case "toggleWindowVisibility": {
      const { state } = getWindow(desktopState, action.windowId);
      return mergeState(desktopState, action.windowId, { isVisible: !state.isVisible });
    }
    case "setPosition": {
      return mergeState(desktopState, action.windowId, { position: action.position });
    }
    /**
     * Indicate that the user interacted with the given window.
     *
     * @param windowId
     */
    case "wasUsed": {
      const timestamp = performance.now();
      return mergeState(desktopState, action.windowId, { position: { lastUse: timestamp } });
    }
    /**
     * Update the dom order of the element. note that this is not necessarily the depth order (z-index).
     *
     * @param windowId
     * @param before the window that should come after `windowId`. if not given `windowId` will become the last element
     */
    case "setWindowOrder": {
      return updateWindowOrder(desktopState, action.windowId, action.before);
    }
    case "setAllWindowVisibility": {
      // Set all windows' visibility except for the "exclude" window kinds.
      const allWindowIds = [...desktopState.windows].map((idAndFactory) => idAndFactory[0]);
      const windowKindsToExclude = action.exclude ?? [];
      const windowIdsToUpdate = allWindowIds.filter((windowId) => {
        let shouldUpdate = true;
        for (const kind of windowKindsToExclude) {
          if (windowId.includes(kind)) {
            shouldUpdate = false;
          }
        }
        return shouldUpdate;
      });
      const newDesktopState = windowIdsToUpdate.reduce((desktopStatePrev, windowId) => {
        return mergeState(desktopStatePrev, windowId, { isVisible: action.isVisible });
      }, desktopState);
      return newDesktopState;
    }
    case "setWindowCustomProps": {
      return mergeState(desktopState, action.windowId, { customProps: action.customProps });
    }
    default: {
      const _exhaustive: never = action;
      return _exhaustive;
    }
  }
}

export const DesktopContext = createContext<DesktopContextApi>({
  id: "missing provider",
  dispatch: () => {},

  windowMap(): Map<WindowInstanceId, WindowInfo> {
    throw Error("missing provider");
  },

  windows(): WindowInfo[] {
    throw Error("missing provider");
  },

  setWindowSize(windowId: WindowInstanceId): void {
    throw Error("missing provider");
  },

  isWindowVisible(windowId: WindowInstanceId): boolean {
    throw Error("missing provider");
  },

  getPosition(windowId: WindowInstanceId): WindowPosition {
    throw Error("missing provider");
  },

  dragableArea: {},
});

export function useDesktop() {
  return useContext(DesktopContext);
}

import { useAppDispatch } from "@/reducer";
import { addPlot, usePlot } from "@/reducer/PlotsState";
import type { Plot } from "@/reducer/client-models";
import { Suspense, lazy, memo, useEffect } from "react";

export type GridPlotProps = {
  plot: Plot;
};

const PlotView = memo(
  lazy(() =>
    import(/* webpackChunkName: "plotview" */ "@/plotView/index")
      .then(({ PlotView }) => ({ default: PlotView }))
      .catch(() => {
        console.error("error");
        return { default: () => <div>Loading Plot View failed</div> };
      }),
  ),
);

export function GridPlot({ plot }: GridPlotProps) {
  /**
   * Temporary solution to sync up react-query with redux
   */
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(addPlot(plot));
  }, [dispatch, plot]);

  const plotFromRedux = usePlot(plot.id);

  if (!plotFromRedux) {
    return null;
  }

  return (
    <Suspense fallback={<mm-loader size="large" />}>
      <PlotView plot={plotFromRedux} />
    </Suspense>
  );
}

import { CoordinateSystem } from "./CoordinateSystem";
import { Timer } from "../performanceIntrospection/Timer";
import { PointResponse } from "./PointResponse";
import { JSONResponseBody } from "./JSONGridResponse";
import { PointRequest } from "./PointRequest";
import { BaseResponse } from "./BaseResponse";

export class JSONPointResponse
  implements
    PointResponse<JSONResponseBody, CoordinateSystem.WGS84>,
    BaseResponse<PointRequest<CoordinateSystem.WGS84>, JSONResponseBody>
{
  request: PointRequest<CoordinateSystem.WGS84>;
  data: JSONResponseBody;
  timer: Timer;

  constructor(response: PointResponse<JSONResponseBody, CoordinateSystem.WGS84>) {
    this.data = response.data;
    this.request = response.request;
    this.timer = response.timer;
  }
}

import { useInterpret } from "@xstate/react";
import { type PropsWithChildren, createContext } from "react";
import type { InterpreterFrom } from "xstate";
import { type LoadingStateMachine, defaultMapLayerLoadingMachine } from "./loadingStateMachine";

export const LoadingStateMachineContext = createContext<StateMachineContext>({
  layerLoadingService: {} as InterpreterFrom<LoadingStateMachine>,
  // In the future, we could add more state machines to keep track of other entities (plot, etc)
});

type StateMachineContext = {
  layerLoadingService: InterpreterFrom<LoadingStateMachine>;
};

export const LoadingStateMachineProvider = (props: PropsWithChildren<any>) => {
  const layerLoadingService = useInterpret(defaultMapLayerLoadingMachine, { devTools: true });

  return (
    <LoadingStateMachineContext.Provider value={{ layerLoadingService }}>
      {props.children}
    </LoadingStateMachineContext.Provider>
  );
};

import { t } from "@lingui/macro";
import React from "react";
import { SmartSelector } from "../../SmartSelector";

const LIGHTNINGS_120 = "lightnings_120";
const LIGHTNINGS_60 = "lightnings_60";

type LightningParamSelectorProps = {
  onSelect: (parameter: any) => void;
  selectedItemKey: string;
};

export const LightningParamSelector = ({ onSelect, selectedItemKey }: LightningParamSelectorProps) => {
  const activeParameters = React.useMemo(
    () => [
      { label: `60 ${t`min`}`, key: LIGHTNINGS_60, value: LIGHTNINGS_60 },
      { label: `120 ${t`min`}`, key: LIGHTNINGS_120, value: LIGHTNINGS_120 },
    ],
    [],
  );

  return (
    <SmartSelector
      items={activeParameters}
      selectedItemKey={selectedItemKey.includes("_") ? selectedItemKey : LIGHTNINGS_120}
      onSelect={onSelect}
    />
  );
};

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * MetX Workbench
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var runtime = require("../runtime");
/**
 *
 */
var ProfileApi = /** @class */ (function (_super) {
    __extends(ProfileApi, _super);
    function ProfileApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Profile Tab Get Map
     */
    ProfileApi.prototype.profileTabGetMapApiV1ProfileWorkaroundForMapidGetRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.mapId === null || requestParameters.mapId === undefined) {
                            throw new runtime.RequiredError('mapId', 'Required parameter requestParameters.mapId was null or undefined when calling profileTabGetMapApiV1ProfileWorkaroundForMapidGet.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v1/profile/workaround_for_mapid/",
                            method: 'GET',
                            headers: headerParameters,
                            query: queryParameters,
                            body: requestParameters.mapId,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.TextApiResponse(response)];
                }
            });
        });
    };
    /**
     * Profile Tab Get Map
     */
    ProfileApi.prototype.profileTabGetMapApiV1ProfileWorkaroundForMapidGet = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.profileTabGetMapApiV1ProfileWorkaroundForMapidGetRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Bulk import multiple profiles and assign them to a target account.
     * Bulk Profile Import
     */
    ProfileApi.prototype.v1ImportProfilesRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.targetUser === null || requestParameters.targetUser === undefined) {
                            throw new runtime.RequiredError('targetUser', 'Required parameter requestParameters.targetUser was null or undefined when calling v1ImportProfiles.');
                        }
                        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
                            throw new runtime.RequiredError('requestBody', 'Required parameter requestParameters.requestBody was null or undefined when calling v1ImportProfiles.');
                        }
                        queryParameters = {};
                        if (requestParameters.targetUser !== undefined) {
                            queryParameters['target_user'] = requestParameters.targetUser;
                        }
                        if (requestParameters.addAdditionalTitle !== undefined) {
                            queryParameters['add_additional_title'] = requestParameters.addAdditionalTitle;
                        }
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v1/profile/import_profiles",
                            method: 'POST',
                            headers: headerParameters,
                            query: queryParameters,
                            body: requestParameters.requestBody,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.TextApiResponse(response)];
                }
            });
        });
    };
    /**
     * Bulk import multiple profiles and assign them to a target account.
     * Bulk Profile Import
     */
    ProfileApi.prototype.v1ImportProfiles = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1ImportProfilesRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Create new profile.
     * Profile Create
     */
    ProfileApi.prototype.v1ProfileCreateRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.profileCreate === null || requestParameters.profileCreate === undefined) {
                            throw new runtime.RequiredError('profileCreate', 'Required parameter requestParameters.profileCreate was null or undefined when calling v1ProfileCreate.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v1/profile/",
                            method: 'POST',
                            headers: headerParameters,
                            query: queryParameters,
                            body: requestParameters.profileCreate,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response)];
                }
            });
        });
    };
    /**
     * Create new profile.
     * Profile Create
     */
    ProfileApi.prototype.v1ProfileCreate = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1ProfileCreateRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Profile Create Tab
     */
    ProfileApi.prototype.v1ProfileCreateTabRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.idProfile === null || requestParameters.idProfile === undefined) {
                            throw new runtime.RequiredError('idProfile', 'Required parameter requestParameters.idProfile was null or undefined when calling v1ProfileCreateTab.');
                        }
                        if (requestParameters.tabCreate === null || requestParameters.tabCreate === undefined) {
                            throw new runtime.RequiredError('tabCreate', 'Required parameter requestParameters.tabCreate was null or undefined when calling v1ProfileCreateTab.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v1/profile/{id_profile}/tab/".replace("{" + "id_profile" + "}", encodeURIComponent(String(requestParameters.idProfile))),
                            method: 'POST',
                            headers: headerParameters,
                            query: queryParameters,
                            body: requestParameters.tabCreate,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response)];
                }
            });
        });
    };
    /**
     * Profile Create Tab
     */
    ProfileApi.prototype.v1ProfileCreateTab = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1ProfileCreateTabRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Delete profile.
     * Profile Delete
     */
    ProfileApi.prototype.v1ProfileDeleteRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.idProfile === null || requestParameters.idProfile === undefined) {
                            throw new runtime.RequiredError('idProfile', 'Required parameter requestParameters.idProfile was null or undefined when calling v1ProfileDelete.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v1/profile/{id_profile}".replace("{" + "id_profile" + "}", encodeURIComponent(String(requestParameters.idProfile))),
                            method: 'DELETE',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.TextApiResponse(response)];
                }
            });
        });
    };
    /**
     * Delete profile.
     * Profile Delete
     */
    ProfileApi.prototype.v1ProfileDelete = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1ProfileDeleteRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Profile Delete Tab
     */
    ProfileApi.prototype.v1ProfileDeleteTabRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.idTab === null || requestParameters.idTab === undefined) {
                            throw new runtime.RequiredError('idTab', 'Required parameter requestParameters.idTab was null or undefined when calling v1ProfileDeleteTab.');
                        }
                        if (requestParameters.idProfile === null || requestParameters.idProfile === undefined) {
                            throw new runtime.RequiredError('idProfile', 'Required parameter requestParameters.idProfile was null or undefined when calling v1ProfileDeleteTab.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v1/profile/{id_profile}/tab/{id_tab}".replace("{" + "id_tab" + "}", encodeURIComponent(String(requestParameters.idTab))).replace("{" + "id_profile" + "}", encodeURIComponent(String(requestParameters.idProfile))),
                            method: 'DELETE',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.TextApiResponse(response)];
                }
            });
        });
    };
    /**
     * Profile Delete Tab
     */
    ProfileApi.prototype.v1ProfileDeleteTab = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1ProfileDeleteTabRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Import Profile from Profile object
     * Profile Import
     */
    ProfileApi.prototype.v1ProfileImportV2Raw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.body === null || requestParameters.body === undefined) {
                            throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling v1ProfileImportV2.');
                        }
                        queryParameters = {};
                        if (requestParameters.addAdditionalTitle !== undefined) {
                            queryParameters['add_additional_title'] = requestParameters.addAdditionalTitle;
                        }
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v1/profile/import_v2",
                            method: 'POST',
                            headers: headerParameters,
                            query: queryParameters,
                            body: requestParameters.body,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response)];
                }
            });
        });
    };
    /**
     * Import Profile from Profile object
     * Profile Import
     */
    ProfileApi.prototype.v1ProfileImportV2 = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1ProfileImportV2Raw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Profiles List All
     */
    ProfileApi.prototype.v1ProfileListAllRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        queryParameters = {};
                        if (requestParameters.skip !== undefined) {
                            queryParameters['skip'] = requestParameters.skip;
                        }
                        if (requestParameters.limit !== undefined) {
                            queryParameters['limit'] = requestParameters.limit;
                        }
                        headerParameters = {};
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v1/profile/",
                            method: 'GET',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response)];
                }
            });
        });
    };
    /**
     * Profiles List All
     */
    ProfileApi.prototype.v1ProfileListAll = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1ProfileListAllRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Get profile by ID.
     * Profile Read
     */
    ProfileApi.prototype.v1ProfileReadRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.idProfile === null || requestParameters.idProfile === undefined) {
                            throw new runtime.RequiredError('idProfile', 'Required parameter requestParameters.idProfile was null or undefined when calling v1ProfileRead.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v1/profile/{id_profile}".replace("{" + "id_profile" + "}", encodeURIComponent(String(requestParameters.idProfile))),
                            method: 'GET',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response)];
                }
            });
        });
    };
    /**
     * Get profile by ID.
     * Profile Read
     */
    ProfileApi.prototype.v1ProfileRead = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1ProfileReadRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Profile Read Tab
     */
    ProfileApi.prototype.v1ProfileReadTabRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.idTab === null || requestParameters.idTab === undefined) {
                            throw new runtime.RequiredError('idTab', 'Required parameter requestParameters.idTab was null or undefined when calling v1ProfileReadTab.');
                        }
                        if (requestParameters.idProfile === null || requestParameters.idProfile === undefined) {
                            throw new runtime.RequiredError('idProfile', 'Required parameter requestParameters.idProfile was null or undefined when calling v1ProfileReadTab.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v1/profile/{id_profile}/tab/{id_tab}".replace("{" + "id_tab" + "}", encodeURIComponent(String(requestParameters.idTab))).replace("{" + "id_profile" + "}", encodeURIComponent(String(requestParameters.idProfile))),
                            method: 'GET',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response)];
                }
            });
        });
    };
    /**
     * Profile Read Tab
     */
    ProfileApi.prototype.v1ProfileReadTab = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1ProfileReadTabRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Profile Tab Clear Layout
     */
    ProfileApi.prototype.v1ProfileTabClearLayoutRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.layoutId === null || requestParameters.layoutId === undefined) {
                            throw new runtime.RequiredError('layoutId', 'Required parameter requestParameters.layoutId was null or undefined when calling v1ProfileTabClearLayout.');
                        }
                        if (requestParameters.idProfile === null || requestParameters.idProfile === undefined) {
                            throw new runtime.RequiredError('idProfile', 'Required parameter requestParameters.idProfile was null or undefined when calling v1ProfileTabClearLayout.');
                        }
                        if (requestParameters.idTab === null || requestParameters.idTab === undefined) {
                            throw new runtime.RequiredError('idTab', 'Required parameter requestParameters.idTab was null or undefined when calling v1ProfileTabClearLayout.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v1/profile/{id_profile}/tab/{id_tab}/layout/{layout_id}".replace("{" + "layout_id" + "}", encodeURIComponent(String(requestParameters.layoutId))).replace("{" + "id_profile" + "}", encodeURIComponent(String(requestParameters.idProfile))).replace("{" + "id_tab" + "}", encodeURIComponent(String(requestParameters.idTab))),
                            method: 'PUT',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.TextApiResponse(response)];
                }
            });
        });
    };
    /**
     * Profile Tab Clear Layout
     */
    ProfileApi.prototype.v1ProfileTabClearLayout = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1ProfileTabClearLayoutRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Profile Tab Create Energy Plot
     */
    ProfileApi.prototype.v1ProfileTabCreateEnergyPlotRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.idProfile === null || requestParameters.idProfile === undefined) {
                            throw new runtime.RequiredError('idProfile', 'Required parameter requestParameters.idProfile was null or undefined when calling v1ProfileTabCreateEnergyPlot.');
                        }
                        if (requestParameters.idTab === null || requestParameters.idTab === undefined) {
                            throw new runtime.RequiredError('idTab', 'Required parameter requestParameters.idTab was null or undefined when calling v1ProfileTabCreateEnergyPlot.');
                        }
                        if (requestParameters.energyPlotCreate === null || requestParameters.energyPlotCreate === undefined) {
                            throw new runtime.RequiredError('energyPlotCreate', 'Required parameter requestParameters.energyPlotCreate was null or undefined when calling v1ProfileTabCreateEnergyPlot.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v1/profile/{id_profile}/tab/{id_tab}/energy-plot/".replace("{" + "id_profile" + "}", encodeURIComponent(String(requestParameters.idProfile))).replace("{" + "id_tab" + "}", encodeURIComponent(String(requestParameters.idTab))),
                            method: 'POST',
                            headers: headerParameters,
                            query: queryParameters,
                            body: requestParameters.energyPlotCreate,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response)];
                }
            });
        });
    };
    /**
     * Profile Tab Create Energy Plot
     */
    ProfileApi.prototype.v1ProfileTabCreateEnergyPlot = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1ProfileTabCreateEnergyPlotRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Profile Tab Create Layouts
     */
    ProfileApi.prototype.v1ProfileTabCreateLayoutsRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.idProfile === null || requestParameters.idProfile === undefined) {
                            throw new runtime.RequiredError('idProfile', 'Required parameter requestParameters.idProfile was null or undefined when calling v1ProfileTabCreateLayouts.');
                        }
                        if (requestParameters.idTab === null || requestParameters.idTab === undefined) {
                            throw new runtime.RequiredError('idTab', 'Required parameter requestParameters.idTab was null or undefined when calling v1ProfileTabCreateLayouts.');
                        }
                        if (requestParameters.genericLayoutCreate === null || requestParameters.genericLayoutCreate === undefined) {
                            throw new runtime.RequiredError('genericLayoutCreate', 'Required parameter requestParameters.genericLayoutCreate was null or undefined when calling v1ProfileTabCreateLayouts.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        return [4 /*yield*/, this.request({
                                path: "/api/v1/profile/{id_profile}/tab/{id_tab}/layouts/".replace("{" + "id_profile" + "}", encodeURIComponent(String(requestParameters.idProfile))).replace("{" + "id_tab" + "}", encodeURIComponent(String(requestParameters.idTab))),
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                                body: requestParameters.genericLayoutCreate,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response)];
                }
            });
        });
    };
    /**
     * Profile Tab Create Layouts
     */
    ProfileApi.prototype.v1ProfileTabCreateLayouts = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1ProfileTabCreateLayoutsRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Profile Tab Create Map
     */
    ProfileApi.prototype.v1ProfileTabCreateMapRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.idProfile === null || requestParameters.idProfile === undefined) {
                            throw new runtime.RequiredError('idProfile', 'Required parameter requestParameters.idProfile was null or undefined when calling v1ProfileTabCreateMap.');
                        }
                        if (requestParameters.idTab === null || requestParameters.idTab === undefined) {
                            throw new runtime.RequiredError('idTab', 'Required parameter requestParameters.idTab was null or undefined when calling v1ProfileTabCreateMap.');
                        }
                        if (requestParameters.idViewport === null || requestParameters.idViewport === undefined) {
                            throw new runtime.RequiredError('idViewport', 'Required parameter requestParameters.idViewport was null or undefined when calling v1ProfileTabCreateMap.');
                        }
                        if (requestParameters.cartographicMapCreate === null || requestParameters.cartographicMapCreate === undefined) {
                            throw new runtime.RequiredError('cartographicMapCreate', 'Required parameter requestParameters.cartographicMapCreate was null or undefined when calling v1ProfileTabCreateMap.');
                        }
                        queryParameters = {};
                        if (requestParameters.idViewport !== undefined) {
                            queryParameters['id_viewport'] = requestParameters.idViewport;
                        }
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v1/profile/{id_profile}/tab/{id_tab}/map/".replace("{" + "id_profile" + "}", encodeURIComponent(String(requestParameters.idProfile))).replace("{" + "id_tab" + "}", encodeURIComponent(String(requestParameters.idTab))),
                            method: 'POST',
                            headers: headerParameters,
                            query: queryParameters,
                            body: requestParameters.cartographicMapCreate,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response)];
                }
            });
        });
    };
    /**
     * Profile Tab Create Map
     */
    ProfileApi.prototype.v1ProfileTabCreateMap = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1ProfileTabCreateMapRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Profile Tab Create Map W Viewport
     */
    ProfileApi.prototype.v1ProfileTabCreateMapWithViewportRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.idProfile === null || requestParameters.idProfile === undefined) {
                            throw new runtime.RequiredError('idProfile', 'Required parameter requestParameters.idProfile was null or undefined when calling v1ProfileTabCreateMapWithViewport.');
                        }
                        if (requestParameters.idTab === null || requestParameters.idTab === undefined) {
                            throw new runtime.RequiredError('idTab', 'Required parameter requestParameters.idTab was null or undefined when calling v1ProfileTabCreateMapWithViewport.');
                        }
                        if (requestParameters.cartographicMapCreate === null || requestParameters.cartographicMapCreate === undefined) {
                            throw new runtime.RequiredError('cartographicMapCreate', 'Required parameter requestParameters.cartographicMapCreate was null or undefined when calling v1ProfileTabCreateMapWithViewport.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v1/profile/{id_profile}/tab/{id_tab}/map_w_viewport/".replace("{" + "id_profile" + "}", encodeURIComponent(String(requestParameters.idProfile))).replace("{" + "id_tab" + "}", encodeURIComponent(String(requestParameters.idTab))),
                            method: 'POST',
                            headers: headerParameters,
                            query: queryParameters,
                            body: requestParameters.cartographicMapCreate,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response)];
                }
            });
        });
    };
    /**
     * Profile Tab Create Map W Viewport
     */
    ProfileApi.prototype.v1ProfileTabCreateMapWithViewport = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1ProfileTabCreateMapWithViewportRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Profile Tab Create Plot
     */
    ProfileApi.prototype.v1ProfileTabCreatePlotRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.idProfile === null || requestParameters.idProfile === undefined) {
                            throw new runtime.RequiredError('idProfile', 'Required parameter requestParameters.idProfile was null or undefined when calling v1ProfileTabCreatePlot.');
                        }
                        if (requestParameters.idTab === null || requestParameters.idTab === undefined) {
                            throw new runtime.RequiredError('idTab', 'Required parameter requestParameters.idTab was null or undefined when calling v1ProfileTabCreatePlot.');
                        }
                        if (requestParameters.genericPlotCreate === null || requestParameters.genericPlotCreate === undefined) {
                            throw new runtime.RequiredError('genericPlotCreate', 'Required parameter requestParameters.genericPlotCreate was null or undefined when calling v1ProfileTabCreatePlot.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v1/profile/{id_profile}/tab/{id_tab}/plots/".replace("{" + "id_profile" + "}", encodeURIComponent(String(requestParameters.idProfile))).replace("{" + "id_tab" + "}", encodeURIComponent(String(requestParameters.idTab))),
                            method: 'POST',
                            headers: headerParameters,
                            query: queryParameters,
                            body: requestParameters.genericPlotCreate,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response)];
                }
            });
        });
    };
    /**
     * Profile Tab Create Plot
     */
    ProfileApi.prototype.v1ProfileTabCreatePlot = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1ProfileTabCreatePlotRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Profile Tab Create Viewport
     */
    ProfileApi.prototype.v1ProfileTabCreateViewportRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.idProfile === null || requestParameters.idProfile === undefined) {
                            throw new runtime.RequiredError('idProfile', 'Required parameter requestParameters.idProfile was null or undefined when calling v1ProfileTabCreateViewport.');
                        }
                        if (requestParameters.idTab === null || requestParameters.idTab === undefined) {
                            throw new runtime.RequiredError('idTab', 'Required parameter requestParameters.idTab was null or undefined when calling v1ProfileTabCreateViewport.');
                        }
                        if (requestParameters.viewportCreate === null || requestParameters.viewportCreate === undefined) {
                            throw new runtime.RequiredError('viewportCreate', 'Required parameter requestParameters.viewportCreate was null or undefined when calling v1ProfileTabCreateViewport.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v1/profile/{id_profile}/tab/{id_tab}/viewport/".replace("{" + "id_profile" + "}", encodeURIComponent(String(requestParameters.idProfile))).replace("{" + "id_tab" + "}", encodeURIComponent(String(requestParameters.idTab))),
                            method: 'POST',
                            headers: headerParameters,
                            query: queryParameters,
                            body: requestParameters.viewportCreate,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response)];
                }
            });
        });
    };
    /**
     * Profile Tab Create Viewport
     */
    ProfileApi.prototype.v1ProfileTabCreateViewport = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1ProfileTabCreateViewportRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Profile Tab Delete Energy Plot
     */
    ProfileApi.prototype.v1ProfileTabDeleteEnergyPlotRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.idProfile === null || requestParameters.idProfile === undefined) {
                            throw new runtime.RequiredError('idProfile', 'Required parameter requestParameters.idProfile was null or undefined when calling v1ProfileTabDeleteEnergyPlot.');
                        }
                        if (requestParameters.idTab === null || requestParameters.idTab === undefined) {
                            throw new runtime.RequiredError('idTab', 'Required parameter requestParameters.idTab was null or undefined when calling v1ProfileTabDeleteEnergyPlot.');
                        }
                        if (requestParameters.idPlot === null || requestParameters.idPlot === undefined) {
                            throw new runtime.RequiredError('idPlot', 'Required parameter requestParameters.idPlot was null or undefined when calling v1ProfileTabDeleteEnergyPlot.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v1/profile/{id_profile}/tab/{id_tab}/energy-plot/{id_plot}".replace("{" + "id_profile" + "}", encodeURIComponent(String(requestParameters.idProfile))).replace("{" + "id_tab" + "}", encodeURIComponent(String(requestParameters.idTab))).replace("{" + "id_plot" + "}", encodeURIComponent(String(requestParameters.idPlot))),
                            method: 'DELETE',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.TextApiResponse(response)];
                }
            });
        });
    };
    /**
     * Profile Tab Delete Energy Plot
     */
    ProfileApi.prototype.v1ProfileTabDeleteEnergyPlot = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1ProfileTabDeleteEnergyPlotRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Profile Tab Delete Layouts
     */
    ProfileApi.prototype.v1ProfileTabDeleteLayoutsRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.idProfile === null || requestParameters.idProfile === undefined) {
                            throw new runtime.RequiredError('idProfile', 'Required parameter requestParameters.idProfile was null or undefined when calling v1ProfileTabDeleteLayouts.');
                        }
                        if (requestParameters.idTab === null || requestParameters.idTab === undefined) {
                            throw new runtime.RequiredError('idTab', 'Required parameter requestParameters.idTab was null or undefined when calling v1ProfileTabDeleteLayouts.');
                        }
                        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
                            throw new runtime.RequiredError('requestBody', 'Required parameter requestParameters.requestBody was null or undefined when calling v1ProfileTabDeleteLayouts.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        return [4 /*yield*/, this.request({
                                path: "/api/v1/profile/{id_profile}/tab/{id_tab}/layouts/".replace("{" + "id_profile" + "}", encodeURIComponent(String(requestParameters.idProfile))).replace("{" + "id_tab" + "}", encodeURIComponent(String(requestParameters.idTab))),
                                method: 'DELETE',
                                headers: headerParameters,
                                query: queryParameters,
                                body: requestParameters.requestBody,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.TextApiResponse(response)];
                }
            });
        });
    };
    /**
     * Profile Tab Delete Layouts
     */
    ProfileApi.prototype.v1ProfileTabDeleteLayouts = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1ProfileTabDeleteLayoutsRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Profile Tab Delete Map
     */
    ProfileApi.prototype.v1ProfileTabDeleteMapRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.idProfile === null || requestParameters.idProfile === undefined) {
                            throw new runtime.RequiredError('idProfile', 'Required parameter requestParameters.idProfile was null or undefined when calling v1ProfileTabDeleteMap.');
                        }
                        if (requestParameters.idTab === null || requestParameters.idTab === undefined) {
                            throw new runtime.RequiredError('idTab', 'Required parameter requestParameters.idTab was null or undefined when calling v1ProfileTabDeleteMap.');
                        }
                        if (requestParameters.idCartographicmap === null || requestParameters.idCartographicmap === undefined) {
                            throw new runtime.RequiredError('idCartographicmap', 'Required parameter requestParameters.idCartographicmap was null or undefined when calling v1ProfileTabDeleteMap.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v1/profile/{id_profile}/tab/{id_tab}/map/{id_cartographicmap}".replace("{" + "id_profile" + "}", encodeURIComponent(String(requestParameters.idProfile))).replace("{" + "id_tab" + "}", encodeURIComponent(String(requestParameters.idTab))).replace("{" + "id_cartographicmap" + "}", encodeURIComponent(String(requestParameters.idCartographicmap))),
                            method: 'DELETE',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.TextApiResponse(response)];
                }
            });
        });
    };
    /**
     * Profile Tab Delete Map
     */
    ProfileApi.prototype.v1ProfileTabDeleteMap = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1ProfileTabDeleteMapRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Profile Tab Delete Plot
     */
    ProfileApi.prototype.v1ProfileTabDeletePlotRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.idProfile === null || requestParameters.idProfile === undefined) {
                            throw new runtime.RequiredError('idProfile', 'Required parameter requestParameters.idProfile was null or undefined when calling v1ProfileTabDeletePlot.');
                        }
                        if (requestParameters.idTab === null || requestParameters.idTab === undefined) {
                            throw new runtime.RequiredError('idTab', 'Required parameter requestParameters.idTab was null or undefined when calling v1ProfileTabDeletePlot.');
                        }
                        if (requestParameters.idPlot === null || requestParameters.idPlot === undefined) {
                            throw new runtime.RequiredError('idPlot', 'Required parameter requestParameters.idPlot was null or undefined when calling v1ProfileTabDeletePlot.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v1/profile/{id_profile}/tab/{id_tab}/plots/{id_plot}".replace("{" + "id_profile" + "}", encodeURIComponent(String(requestParameters.idProfile))).replace("{" + "id_tab" + "}", encodeURIComponent(String(requestParameters.idTab))).replace("{" + "id_plot" + "}", encodeURIComponent(String(requestParameters.idPlot))),
                            method: 'DELETE',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.TextApiResponse(response)];
                }
            });
        });
    };
    /**
     * Profile Tab Delete Plot
     */
    ProfileApi.prototype.v1ProfileTabDeletePlot = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1ProfileTabDeletePlotRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Profile Tab Delete Viewport
     */
    ProfileApi.prototype.v1ProfileTabDeleteViewportRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.idProfile === null || requestParameters.idProfile === undefined) {
                            throw new runtime.RequiredError('idProfile', 'Required parameter requestParameters.idProfile was null or undefined when calling v1ProfileTabDeleteViewport.');
                        }
                        if (requestParameters.idTab === null || requestParameters.idTab === undefined) {
                            throw new runtime.RequiredError('idTab', 'Required parameter requestParameters.idTab was null or undefined when calling v1ProfileTabDeleteViewport.');
                        }
                        if (requestParameters.idViewport === null || requestParameters.idViewport === undefined) {
                            throw new runtime.RequiredError('idViewport', 'Required parameter requestParameters.idViewport was null or undefined when calling v1ProfileTabDeleteViewport.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v1/profile/{id_profile}/tab/{id_tab}/viewport/{id_viewport}".replace("{" + "id_profile" + "}", encodeURIComponent(String(requestParameters.idProfile))).replace("{" + "id_tab" + "}", encodeURIComponent(String(requestParameters.idTab))).replace("{" + "id_viewport" + "}", encodeURIComponent(String(requestParameters.idViewport))),
                            method: 'DELETE',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.TextApiResponse(response)];
                }
            });
        });
    };
    /**
     * Profile Tab Delete Viewport
     */
    ProfileApi.prototype.v1ProfileTabDeleteViewport = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1ProfileTabDeleteViewportRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Profile Tab List Energy Plots
     */
    ProfileApi.prototype.v1ProfileTabListEnergyPlotsRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.idProfile === null || requestParameters.idProfile === undefined) {
                            throw new runtime.RequiredError('idProfile', 'Required parameter requestParameters.idProfile was null or undefined when calling v1ProfileTabListEnergyPlots.');
                        }
                        if (requestParameters.idTab === null || requestParameters.idTab === undefined) {
                            throw new runtime.RequiredError('idTab', 'Required parameter requestParameters.idTab was null or undefined when calling v1ProfileTabListEnergyPlots.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v1/profile/{id_profile}/tab/{id_tab}/energy-plot/".replace("{" + "id_profile" + "}", encodeURIComponent(String(requestParameters.idProfile))).replace("{" + "id_tab" + "}", encodeURIComponent(String(requestParameters.idTab))),
                            method: 'GET',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response)];
                }
            });
        });
    };
    /**
     * Profile Tab List Energy Plots
     */
    ProfileApi.prototype.v1ProfileTabListEnergyPlots = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1ProfileTabListEnergyPlotsRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Profile Tab List Maps
     */
    ProfileApi.prototype.v1ProfileTabListMapsRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.idProfile === null || requestParameters.idProfile === undefined) {
                            throw new runtime.RequiredError('idProfile', 'Required parameter requestParameters.idProfile was null or undefined when calling v1ProfileTabListMaps.');
                        }
                        if (requestParameters.idTab === null || requestParameters.idTab === undefined) {
                            throw new runtime.RequiredError('idTab', 'Required parameter requestParameters.idTab was null or undefined when calling v1ProfileTabListMaps.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v1/profile/{id_profile}/tab/{id_tab}/map/".replace("{" + "id_profile" + "}", encodeURIComponent(String(requestParameters.idProfile))).replace("{" + "id_tab" + "}", encodeURIComponent(String(requestParameters.idTab))),
                            method: 'GET',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response)];
                }
            });
        });
    };
    /**
     * Profile Tab List Maps
     */
    ProfileApi.prototype.v1ProfileTabListMaps = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1ProfileTabListMapsRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Profile Tab List Plots
     */
    ProfileApi.prototype.v1ProfileTabListPlotsRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.idProfile === null || requestParameters.idProfile === undefined) {
                            throw new runtime.RequiredError('idProfile', 'Required parameter requestParameters.idProfile was null or undefined when calling v1ProfileTabListPlots.');
                        }
                        if (requestParameters.idTab === null || requestParameters.idTab === undefined) {
                            throw new runtime.RequiredError('idTab', 'Required parameter requestParameters.idTab was null or undefined when calling v1ProfileTabListPlots.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v1/profile/{id_profile}/tab/{id_tab}/plots/".replace("{" + "id_profile" + "}", encodeURIComponent(String(requestParameters.idProfile))).replace("{" + "id_tab" + "}", encodeURIComponent(String(requestParameters.idTab))),
                            method: 'GET',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response)];
                }
            });
        });
    };
    /**
     * Profile Tab List Plots
     */
    ProfileApi.prototype.v1ProfileTabListPlots = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1ProfileTabListPlotsRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Profile Tab List Viewports
     */
    ProfileApi.prototype.v1ProfileTabListViewportsRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.idProfile === null || requestParameters.idProfile === undefined) {
                            throw new runtime.RequiredError('idProfile', 'Required parameter requestParameters.idProfile was null or undefined when calling v1ProfileTabListViewports.');
                        }
                        if (requestParameters.idTab === null || requestParameters.idTab === undefined) {
                            throw new runtime.RequiredError('idTab', 'Required parameter requestParameters.idTab was null or undefined when calling v1ProfileTabListViewports.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v1/profile/{id_profile}/tab/{id_tab}/viewport/".replace("{" + "id_profile" + "}", encodeURIComponent(String(requestParameters.idProfile))).replace("{" + "id_tab" + "}", encodeURIComponent(String(requestParameters.idTab))),
                            method: 'GET',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response)];
                }
            });
        });
    };
    /**
     * Profile Tab List Viewports
     */
    ProfileApi.prototype.v1ProfileTabListViewports = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1ProfileTabListViewportsRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Profile Tab Map Create Layer
     */
    ProfileApi.prototype.v1ProfileTabMapCreateLayerRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.idProfile === null || requestParameters.idProfile === undefined) {
                            throw new runtime.RequiredError('idProfile', 'Required parameter requestParameters.idProfile was null or undefined when calling v1ProfileTabMapCreateLayer.');
                        }
                        if (requestParameters.idTab === null || requestParameters.idTab === undefined) {
                            throw new runtime.RequiredError('idTab', 'Required parameter requestParameters.idTab was null or undefined when calling v1ProfileTabMapCreateLayer.');
                        }
                        if (requestParameters.idCartographicmap === null || requestParameters.idCartographicmap === undefined) {
                            throw new runtime.RequiredError('idCartographicmap', 'Required parameter requestParameters.idCartographicmap was null or undefined when calling v1ProfileTabMapCreateLayer.');
                        }
                        if (requestParameters.symbolLayerCreateIsoLinesLayerCreateWmsLayerCreateBarbsLayerCreateGridLayerCreateStationLayerCreateBackgroundMapLayerCreateGenericPoiLayerCreateAviationLayerCreatePressureSystemLayerCreateLightningLayerCreateTropicalCycloneLayerCreateWeatherFrontsLayerCreateWindAnimationLayerCreateCustomGeoJSONLayerCreate === null || requestParameters.symbolLayerCreateIsoLinesLayerCreateWmsLayerCreateBarbsLayerCreateGridLayerCreateStationLayerCreateBackgroundMapLayerCreateGenericPoiLayerCreateAviationLayerCreatePressureSystemLayerCreateLightningLayerCreateTropicalCycloneLayerCreateWeatherFrontsLayerCreateWindAnimationLayerCreateCustomGeoJSONLayerCreate === undefined) {
                            throw new runtime.RequiredError('symbolLayerCreateIsoLinesLayerCreateWmsLayerCreateBarbsLayerCreateGridLayerCreateStationLayerCreateBackgroundMapLayerCreateGenericPoiLayerCreateAviationLayerCreatePressureSystemLayerCreateLightningLayerCreateTropicalCycloneLayerCreateWeatherFrontsLayerCreateWindAnimationLayerCreateCustomGeoJSONLayerCreate', 'Required parameter requestParameters.symbolLayerCreateIsoLinesLayerCreateWmsLayerCreateBarbsLayerCreateGridLayerCreateStationLayerCreateBackgroundMapLayerCreateGenericPoiLayerCreateAviationLayerCreatePressureSystemLayerCreateLightningLayerCreateTropicalCycloneLayerCreateWeatherFrontsLayerCreateWindAnimationLayerCreateCustomGeoJSONLayerCreate was null or undefined when calling v1ProfileTabMapCreateLayer.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v1/profile/{id_profile}/tab/{id_tab}/map/{id_cartographicmap}/layer/".replace("{" + "id_profile" + "}", encodeURIComponent(String(requestParameters.idProfile))).replace("{" + "id_tab" + "}", encodeURIComponent(String(requestParameters.idTab))).replace("{" + "id_cartographicmap" + "}", encodeURIComponent(String(requestParameters.idCartographicmap))),
                            method: 'POST',
                            headers: headerParameters,
                            query: queryParameters,
                            body: requestParameters.symbolLayerCreateIsoLinesLayerCreateWmsLayerCreateBarbsLayerCreateGridLayerCreateStationLayerCreateBackgroundMapLayerCreateGenericPoiLayerCreateAviationLayerCreatePressureSystemLayerCreateLightningLayerCreateTropicalCycloneLayerCreateWeatherFrontsLayerCreateWindAnimationLayerCreateCustomGeoJSONLayerCreate,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response)];
                }
            });
        });
    };
    /**
     * Profile Tab Map Create Layer
     */
    ProfileApi.prototype.v1ProfileTabMapCreateLayer = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1ProfileTabMapCreateLayerRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Profile Tab Map Create Layers
     */
    ProfileApi.prototype.v1ProfileTabMapCreateLayersRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.idProfile === null || requestParameters.idProfile === undefined) {
                            throw new runtime.RequiredError('idProfile', 'Required parameter requestParameters.idProfile was null or undefined when calling v1ProfileTabMapCreateLayers.');
                        }
                        if (requestParameters.idTab === null || requestParameters.idTab === undefined) {
                            throw new runtime.RequiredError('idTab', 'Required parameter requestParameters.idTab was null or undefined when calling v1ProfileTabMapCreateLayers.');
                        }
                        if (requestParameters.idCartographicmap === null || requestParameters.idCartographicmap === undefined) {
                            throw new runtime.RequiredError('idCartographicmap', 'Required parameter requestParameters.idCartographicmap was null or undefined when calling v1ProfileTabMapCreateLayers.');
                        }
                        if (requestParameters.symbolLayerCreateIsoLinesLayerCreateWmsLayerCreateBarbsLayerCreateGridLayerCreateStationLayerCreateBackgroundMapLayerCreateGenericPoiLayerCreateAviationLayerCreatePressureSystemLayerCreateLightningLayerCreateTropicalCycloneLayerCreateWeatherFrontsLayerCreateWindAnimationLayerCreateCustomGeoJSONLayerCreate === null || requestParameters.symbolLayerCreateIsoLinesLayerCreateWmsLayerCreateBarbsLayerCreateGridLayerCreateStationLayerCreateBackgroundMapLayerCreateGenericPoiLayerCreateAviationLayerCreatePressureSystemLayerCreateLightningLayerCreateTropicalCycloneLayerCreateWeatherFrontsLayerCreateWindAnimationLayerCreateCustomGeoJSONLayerCreate === undefined) {
                            throw new runtime.RequiredError('symbolLayerCreateIsoLinesLayerCreateWmsLayerCreateBarbsLayerCreateGridLayerCreateStationLayerCreateBackgroundMapLayerCreateGenericPoiLayerCreateAviationLayerCreatePressureSystemLayerCreateLightningLayerCreateTropicalCycloneLayerCreateWeatherFrontsLayerCreateWindAnimationLayerCreateCustomGeoJSONLayerCreate', 'Required parameter requestParameters.symbolLayerCreateIsoLinesLayerCreateWmsLayerCreateBarbsLayerCreateGridLayerCreateStationLayerCreateBackgroundMapLayerCreateGenericPoiLayerCreateAviationLayerCreatePressureSystemLayerCreateLightningLayerCreateTropicalCycloneLayerCreateWeatherFrontsLayerCreateWindAnimationLayerCreateCustomGeoJSONLayerCreate was null or undefined when calling v1ProfileTabMapCreateLayers.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v1/profile/{id_profile}/tab/{id_tab}/map/{id_cartographicmap}/layers/overwrite/".replace("{" + "id_profile" + "}", encodeURIComponent(String(requestParameters.idProfile))).replace("{" + "id_tab" + "}", encodeURIComponent(String(requestParameters.idTab))).replace("{" + "id_cartographicmap" + "}", encodeURIComponent(String(requestParameters.idCartographicmap))),
                            method: 'POST',
                            headers: headerParameters,
                            query: queryParameters,
                            body: requestParameters.symbolLayerCreateIsoLinesLayerCreateWmsLayerCreateBarbsLayerCreateGridLayerCreateStationLayerCreateBackgroundMapLayerCreateGenericPoiLayerCreateAviationLayerCreatePressureSystemLayerCreateLightningLayerCreateTropicalCycloneLayerCreateWeatherFrontsLayerCreateWindAnimationLayerCreateCustomGeoJSONLayerCreate,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response)];
                }
            });
        });
    };
    /**
     * Profile Tab Map Create Layers
     */
    ProfileApi.prototype.v1ProfileTabMapCreateLayers = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1ProfileTabMapCreateLayersRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Profile Tab Map Delete Layer
     */
    ProfileApi.prototype.v1ProfileTabMapDeleteLayerRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.idProfile === null || requestParameters.idProfile === undefined) {
                            throw new runtime.RequiredError('idProfile', 'Required parameter requestParameters.idProfile was null or undefined when calling v1ProfileTabMapDeleteLayer.');
                        }
                        if (requestParameters.idTab === null || requestParameters.idTab === undefined) {
                            throw new runtime.RequiredError('idTab', 'Required parameter requestParameters.idTab was null or undefined when calling v1ProfileTabMapDeleteLayer.');
                        }
                        if (requestParameters.idCartographicmap === null || requestParameters.idCartographicmap === undefined) {
                            throw new runtime.RequiredError('idCartographicmap', 'Required parameter requestParameters.idCartographicmap was null or undefined when calling v1ProfileTabMapDeleteLayer.');
                        }
                        if (requestParameters.idLayer === null || requestParameters.idLayer === undefined) {
                            throw new runtime.RequiredError('idLayer', 'Required parameter requestParameters.idLayer was null or undefined when calling v1ProfileTabMapDeleteLayer.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v1/profile/{id_profile}/tab/{id_tab}/map/{id_cartographicmap}/layer/{id_layer}".replace("{" + "id_profile" + "}", encodeURIComponent(String(requestParameters.idProfile))).replace("{" + "id_tab" + "}", encodeURIComponent(String(requestParameters.idTab))).replace("{" + "id_cartographicmap" + "}", encodeURIComponent(String(requestParameters.idCartographicmap))).replace("{" + "id_layer" + "}", encodeURIComponent(String(requestParameters.idLayer))),
                            method: 'DELETE',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.TextApiResponse(response)];
                }
            });
        });
    };
    /**
     * Profile Tab Map Delete Layer
     */
    ProfileApi.prototype.v1ProfileTabMapDeleteLayer = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1ProfileTabMapDeleteLayerRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Profile Tab Map List Layers
     */
    ProfileApi.prototype.v1ProfileTabMapListLayersRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.idProfile === null || requestParameters.idProfile === undefined) {
                            throw new runtime.RequiredError('idProfile', 'Required parameter requestParameters.idProfile was null or undefined when calling v1ProfileTabMapListLayers.');
                        }
                        if (requestParameters.idTab === null || requestParameters.idTab === undefined) {
                            throw new runtime.RequiredError('idTab', 'Required parameter requestParameters.idTab was null or undefined when calling v1ProfileTabMapListLayers.');
                        }
                        if (requestParameters.idCartographicmap === null || requestParameters.idCartographicmap === undefined) {
                            throw new runtime.RequiredError('idCartographicmap', 'Required parameter requestParameters.idCartographicmap was null or undefined when calling v1ProfileTabMapListLayers.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v1/profile/{id_profile}/tab/{id_tab}/map/{id_cartographicmap}/layer/".replace("{" + "id_profile" + "}", encodeURIComponent(String(requestParameters.idProfile))).replace("{" + "id_tab" + "}", encodeURIComponent(String(requestParameters.idTab))).replace("{" + "id_cartographicmap" + "}", encodeURIComponent(String(requestParameters.idCartographicmap))),
                            method: 'GET',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response)];
                }
            });
        });
    };
    /**
     * Profile Tab Map List Layers
     */
    ProfileApi.prototype.v1ProfileTabMapListLayers = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1ProfileTabMapListLayersRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Profile Tab Map Read Layer
     */
    ProfileApi.prototype.v1ProfileTabMapReadLayerRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.idProfile === null || requestParameters.idProfile === undefined) {
                            throw new runtime.RequiredError('idProfile', 'Required parameter requestParameters.idProfile was null or undefined when calling v1ProfileTabMapReadLayer.');
                        }
                        if (requestParameters.idTab === null || requestParameters.idTab === undefined) {
                            throw new runtime.RequiredError('idTab', 'Required parameter requestParameters.idTab was null or undefined when calling v1ProfileTabMapReadLayer.');
                        }
                        if (requestParameters.idCartographicmap === null || requestParameters.idCartographicmap === undefined) {
                            throw new runtime.RequiredError('idCartographicmap', 'Required parameter requestParameters.idCartographicmap was null or undefined when calling v1ProfileTabMapReadLayer.');
                        }
                        if (requestParameters.idLayer === null || requestParameters.idLayer === undefined) {
                            throw new runtime.RequiredError('idLayer', 'Required parameter requestParameters.idLayer was null or undefined when calling v1ProfileTabMapReadLayer.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v1/profile/{id_profile}/tab/{id_tab}/map/{id_cartographicmap}/layer/{id_layer}".replace("{" + "id_profile" + "}", encodeURIComponent(String(requestParameters.idProfile))).replace("{" + "id_tab" + "}", encodeURIComponent(String(requestParameters.idTab))).replace("{" + "id_cartographicmap" + "}", encodeURIComponent(String(requestParameters.idCartographicmap))).replace("{" + "id_layer" + "}", encodeURIComponent(String(requestParameters.idLayer))),
                            method: 'GET',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response)];
                }
            });
        });
    };
    /**
     * Profile Tab Map Read Layer
     */
    ProfileApi.prototype.v1ProfileTabMapReadLayer = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1ProfileTabMapReadLayerRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Profile Tab Map Update Layer
     */
    ProfileApi.prototype.v1ProfileTabMapUpdateLayerRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.idProfile === null || requestParameters.idProfile === undefined) {
                            throw new runtime.RequiredError('idProfile', 'Required parameter requestParameters.idProfile was null or undefined when calling v1ProfileTabMapUpdateLayer.');
                        }
                        if (requestParameters.idTab === null || requestParameters.idTab === undefined) {
                            throw new runtime.RequiredError('idTab', 'Required parameter requestParameters.idTab was null or undefined when calling v1ProfileTabMapUpdateLayer.');
                        }
                        if (requestParameters.idCartographicmap === null || requestParameters.idCartographicmap === undefined) {
                            throw new runtime.RequiredError('idCartographicmap', 'Required parameter requestParameters.idCartographicmap was null or undefined when calling v1ProfileTabMapUpdateLayer.');
                        }
                        if (requestParameters.symbolLayerUpdateIsoLinesLayerUpdateWmsLayerUpdateBarbsLayerUpdateGridLayerUpdateStationLayerUpdateBackgroundMapLayerUpdateGenericPoiLayerUpdateAviationLayerUpdatePressureSystemLayerUpdateLightningLayerUpdateTropicalCycloneLayerUpdateWeatherFrontsLayerUpdateWindAnimationLayerUpdateCustomGeoJSONLayerUpdate === null || requestParameters.symbolLayerUpdateIsoLinesLayerUpdateWmsLayerUpdateBarbsLayerUpdateGridLayerUpdateStationLayerUpdateBackgroundMapLayerUpdateGenericPoiLayerUpdateAviationLayerUpdatePressureSystemLayerUpdateLightningLayerUpdateTropicalCycloneLayerUpdateWeatherFrontsLayerUpdateWindAnimationLayerUpdateCustomGeoJSONLayerUpdate === undefined) {
                            throw new runtime.RequiredError('symbolLayerUpdateIsoLinesLayerUpdateWmsLayerUpdateBarbsLayerUpdateGridLayerUpdateStationLayerUpdateBackgroundMapLayerUpdateGenericPoiLayerUpdateAviationLayerUpdatePressureSystemLayerUpdateLightningLayerUpdateTropicalCycloneLayerUpdateWeatherFrontsLayerUpdateWindAnimationLayerUpdateCustomGeoJSONLayerUpdate', 'Required parameter requestParameters.symbolLayerUpdateIsoLinesLayerUpdateWmsLayerUpdateBarbsLayerUpdateGridLayerUpdateStationLayerUpdateBackgroundMapLayerUpdateGenericPoiLayerUpdateAviationLayerUpdatePressureSystemLayerUpdateLightningLayerUpdateTropicalCycloneLayerUpdateWeatherFrontsLayerUpdateWindAnimationLayerUpdateCustomGeoJSONLayerUpdate was null or undefined when calling v1ProfileTabMapUpdateLayer.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v1/profile/{id_profile}/tab/{id_tab}/map/{id_cartographicmap}/layer/".replace("{" + "id_profile" + "}", encodeURIComponent(String(requestParameters.idProfile))).replace("{" + "id_tab" + "}", encodeURIComponent(String(requestParameters.idTab))).replace("{" + "id_cartographicmap" + "}", encodeURIComponent(String(requestParameters.idCartographicmap))),
                            method: 'PUT',
                            headers: headerParameters,
                            query: queryParameters,
                            body: requestParameters.symbolLayerUpdateIsoLinesLayerUpdateWmsLayerUpdateBarbsLayerUpdateGridLayerUpdateStationLayerUpdateBackgroundMapLayerUpdateGenericPoiLayerUpdateAviationLayerUpdatePressureSystemLayerUpdateLightningLayerUpdateTropicalCycloneLayerUpdateWeatherFrontsLayerUpdateWindAnimationLayerUpdateCustomGeoJSONLayerUpdate,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response)];
                }
            });
        });
    };
    /**
     * Profile Tab Map Update Layer
     */
    ProfileApi.prototype.v1ProfileTabMapUpdateLayer = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1ProfileTabMapUpdateLayerRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Profile Tab Read Datetime
     */
    ProfileApi.prototype.v1ProfileTabReadDatetimeRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.idProfile === null || requestParameters.idProfile === undefined) {
                            throw new runtime.RequiredError('idProfile', 'Required parameter requestParameters.idProfile was null or undefined when calling v1ProfileTabReadDatetime.');
                        }
                        if (requestParameters.idTab === null || requestParameters.idTab === undefined) {
                            throw new runtime.RequiredError('idTab', 'Required parameter requestParameters.idTab was null or undefined when calling v1ProfileTabReadDatetime.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v1/profile/{id_profile}/tab/{id_tab}/datetime/".replace("{" + "id_profile" + "}", encodeURIComponent(String(requestParameters.idProfile))).replace("{" + "id_tab" + "}", encodeURIComponent(String(requestParameters.idTab))),
                            method: 'GET',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response)];
                }
            });
        });
    };
    /**
     * Profile Tab Read Datetime
     */
    ProfileApi.prototype.v1ProfileTabReadDatetime = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1ProfileTabReadDatetimeRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Profile Tab Read Energy Plot
     */
    ProfileApi.prototype.v1ProfileTabReadEnergyPlotRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.idProfile === null || requestParameters.idProfile === undefined) {
                            throw new runtime.RequiredError('idProfile', 'Required parameter requestParameters.idProfile was null or undefined when calling v1ProfileTabReadEnergyPlot.');
                        }
                        if (requestParameters.idTab === null || requestParameters.idTab === undefined) {
                            throw new runtime.RequiredError('idTab', 'Required parameter requestParameters.idTab was null or undefined when calling v1ProfileTabReadEnergyPlot.');
                        }
                        if (requestParameters.idPlot === null || requestParameters.idPlot === undefined) {
                            throw new runtime.RequiredError('idPlot', 'Required parameter requestParameters.idPlot was null or undefined when calling v1ProfileTabReadEnergyPlot.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v1/profile/{id_profile}/tab/{id_tab}/energy-plot/{id_plot}".replace("{" + "id_profile" + "}", encodeURIComponent(String(requestParameters.idProfile))).replace("{" + "id_tab" + "}", encodeURIComponent(String(requestParameters.idTab))).replace("{" + "id_plot" + "}", encodeURIComponent(String(requestParameters.idPlot))),
                            method: 'GET',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response)];
                }
            });
        });
    };
    /**
     * Profile Tab Read Energy Plot
     */
    ProfileApi.prototype.v1ProfileTabReadEnergyPlot = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1ProfileTabReadEnergyPlotRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Profile Tab Read Map
     */
    ProfileApi.prototype.v1ProfileTabReadMapRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.idProfile === null || requestParameters.idProfile === undefined) {
                            throw new runtime.RequiredError('idProfile', 'Required parameter requestParameters.idProfile was null or undefined when calling v1ProfileTabReadMap.');
                        }
                        if (requestParameters.idTab === null || requestParameters.idTab === undefined) {
                            throw new runtime.RequiredError('idTab', 'Required parameter requestParameters.idTab was null or undefined when calling v1ProfileTabReadMap.');
                        }
                        if (requestParameters.idCartographicmap === null || requestParameters.idCartographicmap === undefined) {
                            throw new runtime.RequiredError('idCartographicmap', 'Required parameter requestParameters.idCartographicmap was null or undefined when calling v1ProfileTabReadMap.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v1/profile/{id_profile}/tab/{id_tab}/map/{id_cartographicmap}".replace("{" + "id_profile" + "}", encodeURIComponent(String(requestParameters.idProfile))).replace("{" + "id_tab" + "}", encodeURIComponent(String(requestParameters.idTab))).replace("{" + "id_cartographicmap" + "}", encodeURIComponent(String(requestParameters.idCartographicmap))),
                            method: 'GET',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response)];
                }
            });
        });
    };
    /**
     * Profile Tab Read Map
     */
    ProfileApi.prototype.v1ProfileTabReadMap = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1ProfileTabReadMapRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Profile Tab Read Plot
     */
    ProfileApi.prototype.v1ProfileTabReadPlotRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.idProfile === null || requestParameters.idProfile === undefined) {
                            throw new runtime.RequiredError('idProfile', 'Required parameter requestParameters.idProfile was null or undefined when calling v1ProfileTabReadPlot.');
                        }
                        if (requestParameters.idTab === null || requestParameters.idTab === undefined) {
                            throw new runtime.RequiredError('idTab', 'Required parameter requestParameters.idTab was null or undefined when calling v1ProfileTabReadPlot.');
                        }
                        if (requestParameters.idPlot === null || requestParameters.idPlot === undefined) {
                            throw new runtime.RequiredError('idPlot', 'Required parameter requestParameters.idPlot was null or undefined when calling v1ProfileTabReadPlot.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v1/profile/{id_profile}/tab/{id_tab}/plots/{id_plot}".replace("{" + "id_profile" + "}", encodeURIComponent(String(requestParameters.idProfile))).replace("{" + "id_tab" + "}", encodeURIComponent(String(requestParameters.idTab))).replace("{" + "id_plot" + "}", encodeURIComponent(String(requestParameters.idPlot))),
                            method: 'GET',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response)];
                }
            });
        });
    };
    /**
     * Profile Tab Read Plot
     */
    ProfileApi.prototype.v1ProfileTabReadPlot = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1ProfileTabReadPlotRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Profile Tab Read Viewport
     */
    ProfileApi.prototype.v1ProfileTabReadViewportRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.idProfile === null || requestParameters.idProfile === undefined) {
                            throw new runtime.RequiredError('idProfile', 'Required parameter requestParameters.idProfile was null or undefined when calling v1ProfileTabReadViewport.');
                        }
                        if (requestParameters.idTab === null || requestParameters.idTab === undefined) {
                            throw new runtime.RequiredError('idTab', 'Required parameter requestParameters.idTab was null or undefined when calling v1ProfileTabReadViewport.');
                        }
                        if (requestParameters.idViewport === null || requestParameters.idViewport === undefined) {
                            throw new runtime.RequiredError('idViewport', 'Required parameter requestParameters.idViewport was null or undefined when calling v1ProfileTabReadViewport.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v1/profile/{id_profile}/tab/{id_tab}/viewport/{id_viewport}".replace("{" + "id_profile" + "}", encodeURIComponent(String(requestParameters.idProfile))).replace("{" + "id_tab" + "}", encodeURIComponent(String(requestParameters.idTab))).replace("{" + "id_viewport" + "}", encodeURIComponent(String(requestParameters.idViewport))),
                            method: 'GET',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response)];
                }
            });
        });
    };
    /**
     * Profile Tab Read Viewport
     */
    ProfileApi.prototype.v1ProfileTabReadViewport = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1ProfileTabReadViewportRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Profile Tab Update Datetime
     */
    ProfileApi.prototype.v1ProfileTabUpdateDatetimeRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.idProfile === null || requestParameters.idProfile === undefined) {
                            throw new runtime.RequiredError('idProfile', 'Required parameter requestParameters.idProfile was null or undefined when calling v1ProfileTabUpdateDatetime.');
                        }
                        if (requestParameters.idTab === null || requestParameters.idTab === undefined) {
                            throw new runtime.RequiredError('idTab', 'Required parameter requestParameters.idTab was null or undefined when calling v1ProfileTabUpdateDatetime.');
                        }
                        if (requestParameters.dateTimeDescUpdate === null || requestParameters.dateTimeDescUpdate === undefined) {
                            throw new runtime.RequiredError('dateTimeDescUpdate', 'Required parameter requestParameters.dateTimeDescUpdate was null or undefined when calling v1ProfileTabUpdateDatetime.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v1/profile/{id_profile}/tab/{id_tab}/datetime/".replace("{" + "id_profile" + "}", encodeURIComponent(String(requestParameters.idProfile))).replace("{" + "id_tab" + "}", encodeURIComponent(String(requestParameters.idTab))),
                            method: 'PUT',
                            headers: headerParameters,
                            query: queryParameters,
                            body: requestParameters.dateTimeDescUpdate,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response)];
                }
            });
        });
    };
    /**
     * Profile Tab Update Datetime
     */
    ProfileApi.prototype.v1ProfileTabUpdateDatetime = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1ProfileTabUpdateDatetimeRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Profile Tab Update Energy Plot
     */
    ProfileApi.prototype.v1ProfileTabUpdateEnergyPlotRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.idProfile === null || requestParameters.idProfile === undefined) {
                            throw new runtime.RequiredError('idProfile', 'Required parameter requestParameters.idProfile was null or undefined when calling v1ProfileTabUpdateEnergyPlot.');
                        }
                        if (requestParameters.idTab === null || requestParameters.idTab === undefined) {
                            throw new runtime.RequiredError('idTab', 'Required parameter requestParameters.idTab was null or undefined when calling v1ProfileTabUpdateEnergyPlot.');
                        }
                        if (requestParameters.energyPlotUpdate === null || requestParameters.energyPlotUpdate === undefined) {
                            throw new runtime.RequiredError('energyPlotUpdate', 'Required parameter requestParameters.energyPlotUpdate was null or undefined when calling v1ProfileTabUpdateEnergyPlot.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v1/profile/{id_profile}/tab/{id_tab}/energy-plot/".replace("{" + "id_profile" + "}", encodeURIComponent(String(requestParameters.idProfile))).replace("{" + "id_tab" + "}", encodeURIComponent(String(requestParameters.idTab))),
                            method: 'PUT',
                            headers: headerParameters,
                            query: queryParameters,
                            body: requestParameters.energyPlotUpdate,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response)];
                }
            });
        });
    };
    /**
     * Profile Tab Update Energy Plot
     */
    ProfileApi.prototype.v1ProfileTabUpdateEnergyPlot = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1ProfileTabUpdateEnergyPlotRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Profile Tab Update Layout
     */
    ProfileApi.prototype.v1ProfileTabUpdateLayoutRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.idProfile === null || requestParameters.idProfile === undefined) {
                            throw new runtime.RequiredError('idProfile', 'Required parameter requestParameters.idProfile was null or undefined when calling v1ProfileTabUpdateLayout.');
                        }
                        if (requestParameters.idTab === null || requestParameters.idTab === undefined) {
                            throw new runtime.RequiredError('idTab', 'Required parameter requestParameters.idTab was null or undefined when calling v1ProfileTabUpdateLayout.');
                        }
                        if (requestParameters.gridLayoutUpdate === null || requestParameters.gridLayoutUpdate === undefined) {
                            throw new runtime.RequiredError('gridLayoutUpdate', 'Required parameter requestParameters.gridLayoutUpdate was null or undefined when calling v1ProfileTabUpdateLayout.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v1/profile/{id_profile}/tab/{id_tab}/layout".replace("{" + "id_profile" + "}", encodeURIComponent(String(requestParameters.idProfile))).replace("{" + "id_tab" + "}", encodeURIComponent(String(requestParameters.idTab))),
                            method: 'PUT',
                            headers: headerParameters,
                            query: queryParameters,
                            body: requestParameters.gridLayoutUpdate,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response)];
                }
            });
        });
    };
    /**
     * Profile Tab Update Layout
     */
    ProfileApi.prototype.v1ProfileTabUpdateLayout = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1ProfileTabUpdateLayoutRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Profile Tab Update Layouts
     */
    ProfileApi.prototype.v1ProfileTabUpdateLayoutsRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.idProfile === null || requestParameters.idProfile === undefined) {
                            throw new runtime.RequiredError('idProfile', 'Required parameter requestParameters.idProfile was null or undefined when calling v1ProfileTabUpdateLayouts.');
                        }
                        if (requestParameters.idTab === null || requestParameters.idTab === undefined) {
                            throw new runtime.RequiredError('idTab', 'Required parameter requestParameters.idTab was null or undefined when calling v1ProfileTabUpdateLayouts.');
                        }
                        if (requestParameters.genericLayoutUpdate === null || requestParameters.genericLayoutUpdate === undefined) {
                            throw new runtime.RequiredError('genericLayoutUpdate', 'Required parameter requestParameters.genericLayoutUpdate was null or undefined when calling v1ProfileTabUpdateLayouts.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        return [4 /*yield*/, this.request({
                                path: "/api/v1/profile/{id_profile}/tab/{id_tab}/layouts/".replace("{" + "id_profile" + "}", encodeURIComponent(String(requestParameters.idProfile))).replace("{" + "id_tab" + "}", encodeURIComponent(String(requestParameters.idTab))),
                                method: 'PUT',
                                headers: headerParameters,
                                query: queryParameters,
                                body: requestParameters.genericLayoutUpdate,
                            }, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response)];
                }
            });
        });
    };
    /**
     * Profile Tab Update Layouts
     */
    ProfileApi.prototype.v1ProfileTabUpdateLayouts = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1ProfileTabUpdateLayoutsRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Profile Tab Update Map
     */
    ProfileApi.prototype.v1ProfileTabUpdateMapRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.idProfile === null || requestParameters.idProfile === undefined) {
                            throw new runtime.RequiredError('idProfile', 'Required parameter requestParameters.idProfile was null or undefined when calling v1ProfileTabUpdateMap.');
                        }
                        if (requestParameters.idTab === null || requestParameters.idTab === undefined) {
                            throw new runtime.RequiredError('idTab', 'Required parameter requestParameters.idTab was null or undefined when calling v1ProfileTabUpdateMap.');
                        }
                        if (requestParameters.cartographicMapUpdate === null || requestParameters.cartographicMapUpdate === undefined) {
                            throw new runtime.RequiredError('cartographicMapUpdate', 'Required parameter requestParameters.cartographicMapUpdate was null or undefined when calling v1ProfileTabUpdateMap.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v1/profile/{id_profile}/tab/{id_tab}/map/".replace("{" + "id_profile" + "}", encodeURIComponent(String(requestParameters.idProfile))).replace("{" + "id_tab" + "}", encodeURIComponent(String(requestParameters.idTab))),
                            method: 'PUT',
                            headers: headerParameters,
                            query: queryParameters,
                            body: requestParameters.cartographicMapUpdate,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response)];
                }
            });
        });
    };
    /**
     * Profile Tab Update Map
     */
    ProfileApi.prototype.v1ProfileTabUpdateMap = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1ProfileTabUpdateMapRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Profile Tab Update Plot
     */
    ProfileApi.prototype.v1ProfileTabUpdatePlotRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.idProfile === null || requestParameters.idProfile === undefined) {
                            throw new runtime.RequiredError('idProfile', 'Required parameter requestParameters.idProfile was null or undefined when calling v1ProfileTabUpdatePlot.');
                        }
                        if (requestParameters.idTab === null || requestParameters.idTab === undefined) {
                            throw new runtime.RequiredError('idTab', 'Required parameter requestParameters.idTab was null or undefined when calling v1ProfileTabUpdatePlot.');
                        }
                        if (requestParameters.genericPlotUpdate === null || requestParameters.genericPlotUpdate === undefined) {
                            throw new runtime.RequiredError('genericPlotUpdate', 'Required parameter requestParameters.genericPlotUpdate was null or undefined when calling v1ProfileTabUpdatePlot.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v1/profile/{id_profile}/tab/{id_tab}/plots/".replace("{" + "id_profile" + "}", encodeURIComponent(String(requestParameters.idProfile))).replace("{" + "id_tab" + "}", encodeURIComponent(String(requestParameters.idTab))),
                            method: 'PUT',
                            headers: headerParameters,
                            query: queryParameters,
                            body: requestParameters.genericPlotUpdate,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response)];
                }
            });
        });
    };
    /**
     * Profile Tab Update Plot
     */
    ProfileApi.prototype.v1ProfileTabUpdatePlot = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1ProfileTabUpdatePlotRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Profile Tab Update Viewport
     */
    ProfileApi.prototype.v1ProfileTabUpdateViewportRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.idProfile === null || requestParameters.idProfile === undefined) {
                            throw new runtime.RequiredError('idProfile', 'Required parameter requestParameters.idProfile was null or undefined when calling v1ProfileTabUpdateViewport.');
                        }
                        if (requestParameters.idTab === null || requestParameters.idTab === undefined) {
                            throw new runtime.RequiredError('idTab', 'Required parameter requestParameters.idTab was null or undefined when calling v1ProfileTabUpdateViewport.');
                        }
                        if (requestParameters.viewportUpdate === null || requestParameters.viewportUpdate === undefined) {
                            throw new runtime.RequiredError('viewportUpdate', 'Required parameter requestParameters.viewportUpdate was null or undefined when calling v1ProfileTabUpdateViewport.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v1/profile/{id_profile}/tab/{id_tab}/viewport/".replace("{" + "id_profile" + "}", encodeURIComponent(String(requestParameters.idProfile))).replace("{" + "id_tab" + "}", encodeURIComponent(String(requestParameters.idTab))),
                            method: 'PUT',
                            headers: headerParameters,
                            query: queryParameters,
                            body: requestParameters.viewportUpdate,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response)];
                }
            });
        });
    };
    /**
     * Profile Tab Update Viewport
     */
    ProfileApi.prototype.v1ProfileTabUpdateViewport = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1ProfileTabUpdateViewportRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Update an profile.
     * Profile Update
     */
    ProfileApi.prototype.v1ProfileUpdateRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.profileUpdate === null || requestParameters.profileUpdate === undefined) {
                            throw new runtime.RequiredError('profileUpdate', 'Required parameter requestParameters.profileUpdate was null or undefined when calling v1ProfileUpdate.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v1/profile/",
                            method: 'PUT',
                            headers: headerParameters,
                            query: queryParameters,
                            body: requestParameters.profileUpdate,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response)];
                }
            });
        });
    };
    /**
     * Update an profile.
     * Profile Update
     */
    ProfileApi.prototype.v1ProfileUpdate = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1ProfileUpdateRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Profile Update Tab
     */
    ProfileApi.prototype.v1ProfileUpdateTabRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.idProfile === null || requestParameters.idProfile === undefined) {
                            throw new runtime.RequiredError('idProfile', 'Required parameter requestParameters.idProfile was null or undefined when calling v1ProfileUpdateTab.');
                        }
                        if (requestParameters.tabUpdate === null || requestParameters.tabUpdate === undefined) {
                            throw new runtime.RequiredError('tabUpdate', 'Required parameter requestParameters.tabUpdate was null or undefined when calling v1ProfileUpdateTab.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v1/profile/{id_profile}/tab/".replace("{" + "id_profile" + "}", encodeURIComponent(String(requestParameters.idProfile))),
                            method: 'PUT',
                            headers: headerParameters,
                            query: queryParameters,
                            body: requestParameters.tabUpdate,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response)];
                }
            });
        });
    };
    /**
     * Profile Update Tab
     */
    ProfileApi.prototype.v1ProfileUpdateTab = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1ProfileUpdateTabRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Create new profile.
     * Profile Create
     */
    ProfileApi.prototype.v2ProfileCreateRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.profileCreate === null || requestParameters.profileCreate === undefined) {
                            throw new runtime.RequiredError('profileCreate', 'Required parameter requestParameters.profileCreate was null or undefined when calling v2ProfileCreate.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v2/profile/",
                            method: 'POST',
                            headers: headerParameters,
                            query: queryParameters,
                            body: requestParameters.profileCreate,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response)];
                }
            });
        });
    };
    /**
     * Create new profile.
     * Profile Create
     */
    ProfileApi.prototype.v2ProfileCreate = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v2ProfileCreateRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Delete profile.
     * Profile Delete
     */
    ProfileApi.prototype.v2ProfileDeleteRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.profileId === null || requestParameters.profileId === undefined) {
                            throw new runtime.RequiredError('profileId', 'Required parameter requestParameters.profileId was null or undefined when calling v2ProfileDelete.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v2/profile/{profile_id}".replace("{" + "profile_id" + "}", encodeURIComponent(String(requestParameters.profileId))),
                            method: 'DELETE',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.TextApiResponse(response)];
                }
            });
        });
    };
    /**
     * Delete profile.
     * Profile Delete
     */
    ProfileApi.prototype.v2ProfileDelete = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v2ProfileDeleteRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Profiles List All
     */
    ProfileApi.prototype.v2ProfileListAllRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        queryParameters = {};
                        if (requestParameters.skip !== undefined) {
                            queryParameters['skip'] = requestParameters.skip;
                        }
                        if (requestParameters.limit !== undefined) {
                            queryParameters['limit'] = requestParameters.limit;
                        }
                        headerParameters = {};
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v2/profile/",
                            method: 'GET',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response)];
                }
            });
        });
    };
    /**
     * Profiles List All
     */
    ProfileApi.prototype.v2ProfileListAll = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v2ProfileListAllRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Get slim profile by ID.
     * Profile Read
     */
    ProfileApi.prototype.v2ProfileReadRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.profileId === null || requestParameters.profileId === undefined) {
                            throw new runtime.RequiredError('profileId', 'Required parameter requestParameters.profileId was null or undefined when calling v2ProfileRead.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v2/profile/{profile_id}".replace("{" + "profile_id" + "}", encodeURIComponent(String(requestParameters.profileId))),
                            method: 'GET',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response)];
                }
            });
        });
    };
    /**
     * Get slim profile by ID.
     * Profile Read
     */
    ProfileApi.prototype.v2ProfileRead = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v2ProfileReadRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Get detailed profile by ID.
     * Profile Read By Usernames
     */
    ProfileApi.prototype.v2ProfileReadByUsersRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
                            throw new runtime.RequiredError('requestBody', 'Required parameter requestParameters.requestBody was null or undefined when calling v2ProfileReadByUsers.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v2/profile/list/details",
                            method: 'POST',
                            headers: headerParameters,
                            query: queryParameters,
                            body: requestParameters.requestBody,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response)];
                }
            });
        });
    };
    /**
     * Get detailed profile by ID.
     * Profile Read By Usernames
     */
    ProfileApi.prototype.v2ProfileReadByUsers = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v2ProfileReadByUsersRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Get detailed profile by ID.
     * Profile Read Details
     */
    ProfileApi.prototype.v2ProfileReadDetailsRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.profileId === null || requestParameters.profileId === undefined) {
                            throw new runtime.RequiredError('profileId', 'Required parameter requestParameters.profileId was null or undefined when calling v2ProfileReadDetails.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v2/profile/{profile_id}/details".replace("{" + "profile_id" + "}", encodeURIComponent(String(requestParameters.profileId))),
                            method: 'GET',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response)];
                }
            });
        });
    };
    /**
     * Get detailed profile by ID.
     * Profile Read Details
     */
    ProfileApi.prototype.v2ProfileReadDetails = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v2ProfileReadDetailsRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Update an profile.
     * Profile Update
     */
    ProfileApi.prototype.v2ProfileUpdateRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.profileId === null || requestParameters.profileId === undefined) {
                            throw new runtime.RequiredError('profileId', 'Required parameter requestParameters.profileId was null or undefined when calling v2ProfileUpdate.');
                        }
                        if (requestParameters.profileUpdate === null || requestParameters.profileUpdate === undefined) {
                            throw new runtime.RequiredError('profileUpdate', 'Required parameter requestParameters.profileUpdate was null or undefined when calling v2ProfileUpdate.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v2/profile/{profile_id}".replace("{" + "profile_id" + "}", encodeURIComponent(String(requestParameters.profileId))),
                            method: 'PUT',
                            headers: headerParameters,
                            query: queryParameters,
                            body: requestParameters.profileUpdate,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response)];
                }
            });
        });
    };
    /**
     * Update an profile.
     * Profile Update
     */
    ProfileApi.prototype.v2ProfileUpdate = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v2ProfileUpdateRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Reset test account to have valid test data for E2E
     * Reset Test Profiles
     */
    ProfileApi.prototype.v2ResetTestProfilesRaw = function (initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v2/profile/test/reset",
                            method: 'GET',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.TextApiResponse(response)];
                }
            });
        });
    };
    /**
     * Reset test account to have valid test data for E2E
     * Reset Test Profiles
     */
    ProfileApi.prototype.v2ResetTestProfiles = function (initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v2ResetTestProfilesRaw(initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    return ProfileApi;
}(runtime.BaseAPI));
exports.ProfileApi = ProfileApi;

/**
 * Library for special and reserved values
 *
 * Value	Meaning
 * -999:	data is not available (this rarely occurs, if so, try another source)
 * -888:	parameter specific reserved value (check the description of the corresponding parameter, e.g. sunset if the sun does not set at the queried location and time)
 * -777:	parameter specific reserved value (check the description of the corresponding parameter, e.g. sunrise if the sun does not rise at the queried location and time)
 * -666:	data not applicable (e.g. cloud top temperature if there are no clouds)
 *
 * @see https://www.meteomatics.com/en/api/response/reserved-values/
 */

/**
 * Marks data as unavailable, e.g. out of model bounding box or simply absent in the dataset.
 *
 * this should be something that does not occur in actual data, but is supported by WebGL.
 *
 */
export const UNAVAILABLE: number = 131072; // This value is reserved in the backend exactly for this purpose

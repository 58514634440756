import { enumValues } from "../utility/enum";
import { autofill } from "weather-parameter-utils";
import { PartialWeatherParameter } from "weather-parameter-utils";

/**
 * A list of all transfer functions supported by the backend.
 */
export enum ColorMap {
  air_quality_index,
  binary_warning_segmented,
  blue_magenta,
  blue_to_red,
  blues,
  blues_inverted,
  cape,
  ceiling_height_airmet,
  ceiling_height_segmented,
  cin_segmented,
  cloud_type,
  dwd_radar_5min,
  dwd_warnings,
  edr_turbulence,
  fresh_snow_long_interval,
  fresh_snow_short_interval,
  gray,
  gray_inverted,
  gray_transparent,
  gray_transparent_dark,
  greens_shifted,
  heavy_rain_warning_europe_segmented,
  incessant_rain_warning_europe_segmented,
  jet,
  jet_inverted,
  jet_segmented,
  jet_segmented_inverted,
  jetstream,
  land_usage,
  lifted_index_global,
  lifted_index_global_segmented,
  lightning_europe_transparent,
  magenta_blue,
  msg_h03b,
  negative_cold_index_segmented,
  negative_index_segmented,
  periodic,
  periodic_inverted,
  plasma,
  plasma_inverted,
  pollen_europe_segmented,
  pollen_grains_large,
  pollen_grains_medium,
  pollen_grains_small,
  pollen_segmented,
  positive_index_segmented,
  precip_europe_segmented,
  precip_layer_europe_segmented,
  precip_layer_segmented,
  precip_segmented,
  precip_type_europe_segmented,
  precip_type_intensity_europe_segmented,
  precip_type_intensity_segmented,
  precip_type_segmented,
  precip_usa_segmented,
  prism,
  prism_inverted,
  radar_coverage,
  radar_log,
  radar_reflectivity_segmented,
  radar_segmented,
  red_to_blue,
  red_yellow_green,
  reds,
  reds_inverted,
  satellite,
  satellite_fog,
  satellite_ir_clouds,
  satellite_ir_clouds_greys,
  satellite_ir_colored,
  satellite_ir_water_vapor,
  satellite_ndvi,
  seismic,
  seismic_inverted,
  snow_depth,
  storm_warning,
  sunshine_europe_segmented,
  surface_wind_speed_airmet,
  t_arabia,
  t_europe,
  t_europe_segmented,
  t_global,
  t_global_segmented,
  theta_e_global,
  theta_e_global_segmented,
  traffic_light,
  traffic_light_inverted,
  tstorm_warning_europe_segmented,
  turbulence_ellrod3_segmented,
  turbulence_segmented,
  turbulence_segmented_inverted,
  ukmo_radar,
  uv_index_europe_segmented,
  viridis,
  viridis_inverted,
  visibility_airmet,
  visibility_segmented,
  wave_height_segmented,
  wind_arabia,
  wind_arabia_beaufort,
  wind_speed_europe_segmented,
  wind_warning_europe_segmented
}

const _segmentedColorMaps: Record<ColorMap, boolean> = {
  [ColorMap.air_quality_index]: true,
  [ColorMap.binary_warning_segmented]: true,
  [ColorMap.blue_magenta]: false,
  [ColorMap.blue_to_red]: false,
  [ColorMap.blues]: false,
  [ColorMap.blues_inverted]: false,
  [ColorMap.cape]: false,
  [ColorMap.ceiling_height_segmented]: true, // TODO: 5 logical units, sampled 60 times
  [ColorMap.cloud_type]: true,
  [ColorMap.dwd_radar_5min]: true, // TODO: a few logical units, sampled 1000 times
  [ColorMap.dwd_warnings]: true,
  [ColorMap.edr_turbulence]: true,
  [ColorMap.fresh_snow_long_interval]: true,
  [ColorMap.fresh_snow_short_interval]: true, // TODO: fresh_snow_*_interval a few logical units, sampled 300 times
  [ColorMap.gray]: false,
  [ColorMap.gray_inverted]: false,
  [ColorMap.gray_transparent]: false,
  [ColorMap.gray_transparent_dark]: false,
  [ColorMap.greens_shifted]: false,
  [ColorMap.heavy_rain_warning_europe_segmented]: true,
  [ColorMap.incessant_rain_warning_europe_segmented]: true,
  [ColorMap.jet]: false,
  [ColorMap.jet_inverted]: false,
  [ColorMap.jet_segmented]: true,
  [ColorMap.jet_segmented_inverted]: true,
  [ColorMap.jetstream]: false,
  [ColorMap.land_usage]: true, // TODO: 2000 values with only a few logically occupied.
  [ColorMap.lifted_index_global]: false,
  [ColorMap.lifted_index_global_segmented]: true,
  [ColorMap.lightning_europe_transparent]: false,
  [ColorMap.magenta_blue]: false,
  [ColorMap.msg_h03b]: true, // TODO: 5 logical units sampled 288 times
  [ColorMap.negative_cold_index_segmented]: true,
  [ColorMap.negative_index_segmented]: true, // TODO: 5 logical units sampled 800 times
  [ColorMap.periodic]: false,
  [ColorMap.periodic_inverted]: false,
  [ColorMap.plasma]: false,
  [ColorMap.plasma_inverted]: false,
  [ColorMap.pollen_europe_segmented]: true,
  [ColorMap.pollen_grains_large]: true,
  [ColorMap.pollen_grains_medium]: true,
  [ColorMap.pollen_grains_small]: true, // TODO: pollen_grains_* have five logical units and are sampled 60/240/500 times
  [ColorMap.pollen_segmented]: true,
  [ColorMap.positive_index_segmented]: true, // TODO: a few logical units sampled 800 times
  [ColorMap.precip_europe_segmented]: true, // TODO: a few logical units sampled 400 times
  [ColorMap.precip_layer_europe_segmented]: true,
  [ColorMap.precip_layer_segmented]: true,
  [ColorMap.precip_segmented]: true,
  [ColorMap.precip_type_europe_segmented]: true,
  [ColorMap.precip_type_intensity_europe_segmented]: true, // TODO: a few of the percip types here are logically disjunkt legends and could be rendered as such
  [ColorMap.precip_type_intensity_segmented]: true,
  [ColorMap.precip_type_segmented]: true,
  [ColorMap.precip_usa_segmented]: true,
  [ColorMap.prism]: false,
  [ColorMap.prism_inverted]: false,
  [ColorMap.radar_coverage]: false,
  [ColorMap.radar_log]: true, // TODO: this has a few logical units, but is sampled 320 times
  [ColorMap.radar_reflectivity_segmented]: true,
  [ColorMap.radar_segmented]: true,
  [ColorMap.red_to_blue]: false,
  [ColorMap.red_yellow_green]: false,
  [ColorMap.reds]: false,
  [ColorMap.reds_inverted]: false,
  [ColorMap.satellite]: false,
  [ColorMap.satellite_fog]: false,
  [ColorMap.satellite_ir_colored]: false,
  [ColorMap.satellite_ir_clouds]: false,
  [ColorMap.satellite_ir_clouds_greys]: false,
  [ColorMap.satellite_ndvi]: true, // TODO: segmented into a few logical units, but sampled 200 times
  [ColorMap.satellite_ir_water_vapor]: false,
  [ColorMap.seismic]: false,
  [ColorMap.seismic_inverted]: false,
  [ColorMap.snow_depth]: true, // TODO: this has a few logical units, but is sampled 300 times
  [ColorMap.storm_warning]: true, // TODO: this has 4 logical units, but is sampled 120 times
  [ColorMap.sunshine_europe_segmented]: true, // TODO: this has 5 logical units, but is sampled 192 times
  [ColorMap.surface_wind_speed_airmet]: false,
  [ColorMap.t_europe]: false,
  [ColorMap.t_europe_segmented]: true,
  [ColorMap.t_arabia]: false,
  [ColorMap.t_global]: false,
  [ColorMap.t_global_segmented]: true,
  [ColorMap.theta_e_global]: false,
  [ColorMap.theta_e_global_segmented]: true,
  [ColorMap.traffic_light]: false,
  [ColorMap.traffic_light_inverted]: false,
  [ColorMap.turbulence_ellrod3_segmented]: true,
  [ColorMap.tstorm_warning_europe_segmented]: true,
  [ColorMap.turbulence_segmented]: true, // TODO: this has 3 logical segments, but is sampled 60 times to give segments different heights. we currently do not consider this during legend labeling at all.
  [ColorMap.turbulence_segmented_inverted]: true,
  [ColorMap.ukmo_radar]: true,
  [ColorMap.uv_index_europe_segmented]: true,
  [ColorMap.viridis]: false,
  [ColorMap.viridis_inverted]: false,
  [ColorMap.visibility_segmented]: true,
  [ColorMap.wave_height_segmented]: true,
  [ColorMap.wind_speed_europe_segmented]: true,
  [ColorMap.wind_warning_europe_segmented]: true,
  [ColorMap.cin_segmented]: true,
  [ColorMap.ceiling_height_airmet]: false,
  [ColorMap.visibility_airmet]: false,
  [ColorMap.wind_arabia]: false,
  [ColorMap.wind_arabia_beaufort]: false
};

export const colormaps = enumValues(ColorMap);

export function isSegmented(colormap: ColorMap) {
  return _segmentedColorMaps[colormap];
}

/**
 * Chooses a default color map for some weather parameter
 */
export function defaultColorMap(parameter_: PartialWeatherParameter) {
  const { narrowed_selection, parameter } = autofill(parameter_);

  const { unit } = narrowed_selection.fields;
  const name = parameter.name;

  return _defaultColorMap(name, unit);
}

export function defaultColorMapFromString(parameter: string) {
  const [name, unit] = parameter.split(":", 2);
  return _defaultColorMap(name, unit);
}

/**
 * This is word-by-word port of the backend function `get_default_wms_colormap`. Apply the following search-replace pairs
 * to turn the C++ syntax into typescript:
 *
 * ```
 * `starts_with(parameter,` to `parameter.startsWith(`
 * `.find(` to `.include(`
 * `!= string_view::npos` to ``
 * `!= string::npos` to ``
 * ```
 *
 * Last port was done against api commit 508a8c987782689256fafe96ebf40b135442bb06 on Tue Jun 22 05:58:09 2021
 */
function _defaultColorMap(name: string, unit: string) {
  const parameter = `${name}:${unit}`;

  // Find unit, if none is found we return Jet
  if (unit === "") {
    return ColorMap.jet;
  }

  if (parameter.includes("spread")) {
    return ColorMap.jet;
  }

  // Satellite channel covering infrared band
  if (parameter.startsWith("sat_ir_104") || parameter.startsWith("sat_ir_108")) {
    // Currently only available for K and C units
    if (unit == "K" || unit == "C") {
      return ColorMap.satellite_ir_colored;
    }
  }

  if (parameter.startsWith("sat_ir_062")) {
    if (unit == "K" || unit == "C") {
      return ColorMap.satellite_ir_water_vapor;
    }
  }

  if (unit == "C" || unit == "K" || unit == "F") {
    if (parameter.startsWith("lifted_index")) {
      return ColorMap.lifted_index_global;
    }

    if (parameter.startsWith("theta_e")) {
      return ColorMap.theta_e_global;
    }

    if (parameter.startsWith("sat_")) {
      // satellite brightness temperature
      return ColorMap.gray_inverted;
    }

    return ColorMap.t_europe;
  }

  if (unit == "Wm2umsr") {
    // satellite radiance
    return ColorMap.gray;
  }

  /*
 // Velocity: maybe a perceptively linear colormap?
 if (unit == "ms" || unit == "kmh" || unit == "kn")
 {
     return ColorMap.Jet;
 }
 */

  // Pressure
  if (unit == "hPa" || unit == "Pa" || unit == "psi") {
    return ColorMap.viridis;
  }

  // Probability & percentage
  if (unit == "p" || unit == "octas") {
    if (parameter.startsWith("relative_humidity")) {
      return ColorMap.greens_shifted;
    } else if (parameter.includes("cloud_cover")) {
      return ColorMap.gray_transparent_dark;
    } else if (parameter.startsWith("zombie")) {
      if (parameter.includes("survival")) {
        return ColorMap.traffic_light;
      }
      return ColorMap.dwd_warnings;
    } else if (parameter.startsWith("sat_")) {
      // satellite reflectance
      return ColorMap.gray;
    }
    // Otherwise jet, as the rest are probabilities and jet seems to be suitable for that
    else {
      return ColorMap.jet;
    }
  }

  // Directions
  if (unit == "d" && parameter.includes("_dir")) {
    return ColorMap.periodic;
  }

  // Sunshine duration
  if (unit == "h" || unit == "min") {
    if (parameter.startsWith("covid")) {
      return ColorMap.blue_to_red;
    }
    return ColorMap.plasma;
  }

  // Distances
  if (unit == "m" || unit == "km" || unit == "ft" || unit == "hft") {
    if (parameter.startsWith("visibility") || parameter.startsWith("runway_visual_range")) {
      return ColorMap.visibility_segmented;
    } else if (parameter.startsWith("ceiling_height_agl") || parameter.startsWith("cloud_base_agl")) {
      return ColorMap.ceiling_height_segmented;
    } else {
      return ColorMap.jet;
    }
  }

  if (unit === "nmi") {
    if (parameter.startsWith("visibility")) {
      return ColorMap.visibility_segmented;
    }
  }

  // Precipitation: mm
  if (unit == "mm") {
    if (parameter.startsWith("precip_") || parameter.startsWith("calibrated_precip_")) {
      return ColorMap.radar_log;
    } else if (parameter.startsWith("evaporation_")) {
      return ColorMap.blues;
    } else {
      return ColorMap.jet;
    }
  }

  // Snow & tides: cm
  if (unit == "cm") {
    if (parameter.startsWith("fresh_snow")) {
      const get_interval_hour_from_parameter = function () {
        if (parameter.includes("_1h")) return 1;

        if (parameter.includes("_2h")) return 2;

        if (parameter.includes("_3h")) return 3;

        if (parameter.includes("_6h")) return 6;

        if (parameter.includes("_12h")) return 12;

        if (parameter.includes("_24h")) return 24;

        if (parameter.includes("_48h")) return 48;

        return -1;
      };

      const N = get_interval_hour_from_parameter();

      if (N < 6) {
        return ColorMap.fresh_snow_short_interval;
      } else {
        return ColorMap.fresh_snow_long_interval;
      }
    } else if (parameter.startsWith("snow_depth")) {
      return ColorMap.snow_depth;
    } else if (parameter.startsWith("tidal_amplitude") || parameter.startsWith("surge_amplitude")) {
      return ColorMap.seismic;
    } else {
      return ColorMap.jet;
    }
  }

  // Radiation
  if (unit == "W" || unit == "Ws" || unit == "J" || unit == "Wh") {
    return ColorMap.plasma;
  }

  // Indices
  if (unit == "idx") {
    if (parameter.startsWith("dew_or_rime")) {
      return ColorMap.blue_to_red;
    } else if (parameter.startsWith("freezing_rain")) {
      return ColorMap.reds;
    } else if (parameter.startsWith("warnings_active")) {
      return ColorMap.binary_warning_segmented;
    } else if (parameter.includes("cloud_layer")) {
      return ColorMap.gray;
    } else if (parameter.startsWith("sat_")) {
      if (parameter.includes("rgb", 4)) {
        return ColorMap.satellite;
      } else if (parameter.includes("fog", 4)) {
        return ColorMap.satellite_fog;
      } else if (parameter.includes("ndvi", 4)) {
        return ColorMap.satellite_ndvi;
      } else if (parameter.includes("cloud_type", 4)) {
        return ColorMap.cloud_type;
      }
      // for longer wavelengths in IR range, gray inverted is better than gray (it is what is commonly used)
      else if (parameter.includes("ir_039", 4) || parameter.includes("ir_108", 4)) {
        return ColorMap.gray_inverted;
      }
      // Sat Cb storm warning
      else if (parameter.includes("storm_", 4)) {
        if (parameter.includes("severity", 10)) {
          return ColorMap.reds;
        } else if (parameter.includes("warning", 10)) {
          return ColorMap.storm_warning;
        }
      }
      // remaining parameters are the individual channels
      else {
        return ColorMap.gray;
      }
    } else if (parameter.startsWith("is_in_shadow")) {
      return ColorMap.jet_inverted;
    } else if (parameter.startsWith("is_not_in_shadow")) {
      return ColorMap.jet;
    } else if (parameter.startsWith("icing_potential")) {
      return ColorMap.reds;
    } else if (parameter.startsWith("land_usage")) {
      return ColorMap.land_usage;
    } else if (parameter.startsWith("precip_layer")) {
      return ColorMap.precip_layer_segmented;
    } else if (parameter.startsWith("precip_type_intensity")) {
      return ColorMap.precip_type_intensity_segmented;
    } else if (parameter.startsWith("precip_type")) {
      return ColorMap.precip_type_segmented;
    } //ORDER IMPORTANT
    else if (parameter.startsWith("sld_potential")) {
      return ColorMap.reds;
    } else if (parameter.includes("pollen")) {
      return ColorMap.pollen_segmented;
    } else if (parameter.includes("_sot")) {
      return ColorMap.blue_to_red;
    } else if (parameter.includes("_efi")) {
      return ColorMap.blue_to_red;
    } else if (parameter.includes("warning")) {
      return ColorMap.storm_warning;
    } else if (parameter.includes("soaring_index")) {
      return ColorMap.jet_segmented;
    } else if (parameter.startsWith("richardson")) {
      return ColorMap.turbulence_segmented;
    } else if (parameter.startsWith("turbulence_cape")) {
      return ColorMap.turbulence_segmented_inverted;
    } else if (parameter.startsWith("aerodrome_approach")) {
      return ColorMap.traffic_light_inverted;
    } else if (parameter.startsWith("soil_moisture_index")) {
      return ColorMap.jet_inverted;
    } else {
      // Default colormap for ones that don’t apply the conditions above.
      return ColorMap.jet;
    }
  }

  // pollen grain
  if (unit == "grainsm3") {
    if (parameter.startsWith("olive_pollen") || parameter.startsWith("ragweed_pollen")) {
      return ColorMap.pollen_grains_small;
    }
    if (parameter.startsWith("grass_pollen")) {
      return ColorMap.pollen_grains_medium;
    } // birch_pollen, pollen
    else {
      return ColorMap.pollen_grains_large;
    }
  }

  // Turbulence: 1/s^2
  if (unit == "s2") {
    if (parameter.startsWith("turbulence_ellrod")) {
      return ColorMap.turbulence_ellrod3_segmented;
    }
  }

  // Lightnings
  if (unit == "x" && parameter.startsWith("lightning_strikes")) {
    return ColorMap.plasma;
  }

  // Convective inhibition (CIN)
  // unit isn't important, because there is only one available
  if (parameter.startsWith("cin")) {
    return ColorMap.cin_segmented;
  }

  // Convective available potential energy
  if (parameter.startsWith("cape")) {
    return ColorMap.cape;
  }

  // Radar reflectivity
  if (parameter.startsWith("reflectivity")){
    return ColorMap.radar_reflectivity_segmented;
  }

  // Eddy Dissipation Rate - Indicator of turbulance
  if (parameter.startsWith("edr_turbulence")){
    return ColorMap.edr_turbulence
  }

  // Default to jet
  return ColorMap.jet;
}

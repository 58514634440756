"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * MetX Workbench
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var runtime = require("../runtime");
/**
 *
 */
var UserApi = /** @class */ (function (_super) {
    __extends(UserApi, _super);
    function UserApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Create new user.
     * Create User
     */
    UserApi.prototype.v1AdminCreateUserRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.userCreate === null || requestParameters.userCreate === undefined) {
                            throw new runtime.RequiredError('userCreate', 'Required parameter requestParameters.userCreate was null or undefined when calling v1AdminCreateUser.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v1/user/",
                            method: 'POST',
                            headers: headerParameters,
                            query: queryParameters,
                            body: requestParameters.userCreate,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response)];
                }
            });
        });
    };
    /**
     * Create new user.
     * Create User
     */
    UserApi.prototype.v1AdminCreateUser = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1AdminCreateUserRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Delete the user associated with the user id.
     * Delete User
     */
    UserApi.prototype.v1AdminDeleteUserRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.userId === null || requestParameters.userId === undefined) {
                            throw new runtime.RequiredError('userId', 'Required parameter requestParameters.userId was null or undefined when calling v1AdminDeleteUser.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v1/user/{user_id}".replace("{" + "user_id" + "}", encodeURIComponent(String(requestParameters.userId))),
                            method: 'DELETE',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.TextApiResponse(response)];
                }
            });
        });
    };
    /**
     * Delete the user associated with the user id.
     * Delete User
     */
    UserApi.prototype.v1AdminDeleteUser = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1AdminDeleteUserRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Retrieve users.
     * Read Users
     */
    UserApi.prototype.v1AdminReadUsersRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        queryParameters = {};
                        if (requestParameters.skip !== undefined) {
                            queryParameters['skip'] = requestParameters.skip;
                        }
                        if (requestParameters.limit !== undefined) {
                            queryParameters['limit'] = requestParameters.limit;
                        }
                        headerParameters = {};
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v1/user/",
                            method: 'GET',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response)];
                }
            });
        });
    };
    /**
     * Retrieve users.
     * Read Users
     */
    UserApi.prototype.v1AdminReadUsers = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1AdminReadUsersRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Update the user associated with the user id.
     * Update User
     */
    UserApi.prototype.v1AdminUpdateUserRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.userId === null || requestParameters.userId === undefined) {
                            throw new runtime.RequiredError('userId', 'Required parameter requestParameters.userId was null or undefined when calling v1AdminUpdateUser.');
                        }
                        if (requestParameters.userUpdate === null || requestParameters.userUpdate === undefined) {
                            throw new runtime.RequiredError('userUpdate', 'Required parameter requestParameters.userUpdate was null or undefined when calling v1AdminUpdateUser.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v1/user/{user_id}".replace("{" + "user_id" + "}", encodeURIComponent(String(requestParameters.userId))),
                            method: 'PUT',
                            headers: headerParameters,
                            query: queryParameters,
                            body: requestParameters.userUpdate,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response)];
                }
            });
        });
    };
    /**
     * Update the user associated with the user id.
     * Update User
     */
    UserApi.prototype.v1AdminUpdateUser = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1AdminUpdateUserRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Get current user.
     * Read User Me
     */
    UserApi.prototype.v1ReadCurrentUserRaw = function (initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v1/user/me",
                            method: 'GET',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response)];
                }
            });
        });
    };
    /**
     * Get current user.
     * Read User Me
     */
    UserApi.prototype.v1ReadCurrentUser = function (initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1ReadCurrentUserRaw(initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Get a specific user by id.
     * Read User
     */
    UserApi.prototype.v1ReadUserRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.userId === null || requestParameters.userId === undefined) {
                            throw new runtime.RequiredError('userId', 'Required parameter requestParameters.userId was null or undefined when calling v1ReadUser.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v1/user/{user_id}".replace("{" + "user_id" + "}", encodeURIComponent(String(requestParameters.userId))),
                            method: 'GET',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response)];
                }
            });
        });
    };
    /**
     * Get a specific user by id.
     * Read User
     */
    UserApi.prototype.v1ReadUser = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v1ReadUserRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    return UserApi;
}(runtime.BaseAPI));
exports.UserApi = UserApi;

import { useValueNameCreator } from "@/overlay/components/SmartForm/SmartFormUtils/FormHooks/useFormInstructions";
import type { InputInstruction } from "@/overlay/components/SmartForm/SmartFormUtils/commonTypeUtils";
import { FormField } from "@/overlay/components/form-field";
import { unsafeGetColormapByName } from "@/utility/unsafeGetColormapByName";
import { t } from "@lingui/macro";
import { ColorMap, colormaps } from "@mm/api.meteomatics.com";

export type ColorMapStyleSelectData = string;

export type ColorMapStyleSelectProps = {
  instruction: InputInstruction<{
    colorMapName?: string;
    paramUnit?: string;
  }>;
  isCreationMode?: boolean;
};

/**
 * React form hook version of WMSLayerSelect component.
 * Allows selection of color map, which is mainly for WMS layer.
 * @param props
 */
export function ColorMapStyleSelect(props: ColorMapStyleSelectProps) {
  const { instruction, isCreationMode } = props;
  const { currValue, formMethods } = instruction;
  const { createInputName } = useValueNameCreator<ColorMapStyleSelectData>(instruction);

  function defaultColorMap() {
    if (currValue.paramUnit) {
      const defaultColorMap = unsafeGetColormapByName("", currValue.paramUnit);
      return defaultColorMap;
    }
    return undefined;
  }

  return (
    <FormField label={t`Color Map`}>
      <select
        defaultValue={isCreationMode ? currValue.colorMapName : undefined}
        value={!isCreationMode ? currValue.colorMapName : undefined}
        {...formMethods.register(createInputName())}
      >
        {colormaps.map((colorMap, idx) => (
          <option key={colorMap.toString()} value={ColorMap[colorMap]}>
            {ColorMap[colorMap]} {colorMap === defaultColorMap() ? t`(default)` : ""}
          </option>
        ))}
      </select>
    </FormField>
  );
}

import { CoordinateSystem } from "./CoordinateSystem";
import { Area, Area_Transferable } from "./Area";
import { DateTime, Duration } from "luxon";
import {
  DateTime_fromTransferable,
  DateTime_toTransferable,
  DateTime_Transferable,
  Duration_fromTransferable,
  Duration_toTransferable,
  Duration_Transferable,
} from "./DateTime";
import {GridRequest} from "./GridRequest";
import {GridSamplingStrategyKind} from "./GridSamplingStrategy";
import {WORLD_BBOX} from "../bounds";

export interface LightningListRequest<C extends CoordinateSystem> {
  /**
   * Corresponds to BBOX parameter on the endpoint
   */
  area: Area<C>;
  datetime: DateTime;
  timeDuration: Duration;
}

export interface LightningListRequest_Transferable<C extends CoordinateSystem> {
  area: Area_Transferable<C>;
  datetime: DateTime_Transferable;
  timeDuration: Duration_Transferable;
}

export function LightningListRequest_toTransferable<C extends CoordinateSystem>(
  req: LightningListRequest<C>,
): LightningListRequest_Transferable<C> {
  return {
    ...req,
    area: req.area.toTransferable(),
    datetime: DateTime_toTransferable(req.datetime),
    timeDuration: Duration_toTransferable(req.timeDuration),
  };
}

export function LightningListRequest_fromTransferable<C extends CoordinateSystem>(
  req: LightningListRequest_Transferable<C>,
): LightningListRequest<C> {
  return {
    ...req,
    area: Area.fromTransferable(req.area),
    datetime: DateTime_fromTransferable(req.datetime),
    timeDuration: Duration_fromTransferable(req.timeDuration),
  };
}


export function LightningListRequest_toGridRequest<C extends CoordinateSystem>(
    lightningListRequest: LightningListRequest<C>,
): GridRequest<C> {
  return {
    ...lightningListRequest,
    boundingBoxLimit: WORLD_BBOX,
    height: 0,
    model: "",
    width: 0,
    parameters: [""],
    sampling: { kind: GridSamplingStrategyKind.Point }
  };
}

export const getWaveNrByKn = (windSpeedKn: number): number => {
  if (windSpeedKn <= 0.03885) {
    // calm
    return 0;
  }
  if (windSpeedKn <= 0.3885) {
    return 5;
  }
  if (windSpeedKn <= 0.777) {
    return 10;
  }
  if (windSpeedKn <= 1.1656) {
    return 15;
  }
  if (windSpeedKn <= 1.555) {
    return 20;
  }
  if (windSpeedKn <= 1.9438) {
    return 25;
  }
  if (windSpeedKn <= 2.3326) {
    return 30;
  }
  if (windSpeedKn <= 2.7213) {
    return 35;
  }
  if (windSpeedKn <= 3.1102) {
    return 40;
  }
  if (windSpeedKn <= 3.4989) {
    return 45;
  }
  if (windSpeedKn <= 3.8877) {
    return 50;
  }

  return 50;
};

import type { LayerUnionWithAll } from "@/layers";
import type { ModeType } from "@/layers/utility/createLayerObject";
import {
  layerToCreateControllerMapping,
  layerToUpdateControllerMapping,
} from "@/overlay/components/SmartForm/LayerForms/LayerFormMapping";

/**
 * We're gradually migrating input forms to react-hook-form based components.
 * The function returns if the given layer is supported by the new react-hook-form based component.
 */
export function v2LayerUpdateFormSupported(layer: LayerUnionWithAll): boolean {
  if (!layer.kind) {
    return false;
  }
  return layer.kind in layerToUpdateControllerMapping.componentsById;
}

export function v2LayerCreateFormSupported(mode: ModeType): boolean {
  return mode.kind in layerToCreateControllerMapping.componentsById;
}

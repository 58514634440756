import type { ElementStyle, EnergyPlot } from "@mm/metx-workbench.meteomatics.com";
import { type PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import type { RootState } from ".";

import { fetchTab } from "@/api/hooks/tab";
import { createEnergyPlot } from "@/api/hooks/tools/energyPlot";
import Logger from "logging";
const logger = Logger.fromFilename(__filename);

export type EnergyPlotsState = {
  [id: number]: EnergyPlot;
};

const initialState: EnergyPlotsState = {};

const energyPlotsSlice = createSlice({
  name: "plots",
  initialState: initialState as EnergyPlotsState,
  reducers: {
    addEnergyPlot(state, action: PayloadAction<EnergyPlot>) {
      state[action.payload.id] = action.payload;
    },
    discardEnergyPlot(state, action: PayloadAction<{ id: number }>) {
      const { id } = action.payload;
      const tool = state[id];

      if (!tool) {
        logger.error("Could not find tool with id", id);
        return;
      }
      delete state[id];
    },
    setEnergyPlotTitle(state, action: PayloadAction<{ id: number; title?: string }>) {
      const { id, title } = action.payload;

      const energyPlot = state[id];

      if (!energyPlot) {
        logger.error("Could not find energy plot with id", id);
        return;
      }

      energyPlot.title = title;
    },
    setEnergyForecastPlotProps(state, action: PayloadAction<EnergyPlot>) {
      const { payload } = action;

      const energyPlot = state[payload.id];

      if (!energyPlot) {
        logger.error("Could not find energy plot with id", payload.id);
        return;
      }

      energyPlot.forecast_type = payload.forecast_type;
      energyPlot.model = payload.model;
      energyPlot.site_id = payload.site_id;
      energyPlot.sub_site_ids = payload.sub_site_ids;
      energyPlot.ens_members = payload.ens_members || [];

      energyPlot.plotSeriesStyles = payload.plotSeriesStyles;
    },
    setEnergyForecastTitleStyle(state, action: PayloadAction<{ style: ElementStyle; id: number }>) {
      const { style, id } = action.payload;

      const energyPlot = state[id];

      if (!energyPlot) {
        logger.error("Could not find energy plot with id", id);
        return;
      }

      energyPlot.titleStyle = style;
    },
    setEnergyForecastTimeRange(
      state,
      action: PayloadAction<{
        id: number;
        props: { datetimeRange: [string | null, string | null] };
      }>,
    ) {
      const {
        id,
        props: {
          datetimeRange: [from, to],
        },
      } = action.payload;

      const energyPlot = state[id];

      if (!energyPlot) {
        logger.error("Could not find plot with id", id);
        return;
      }
      energyPlot.datetime_from = from;
      energyPlot.datetime_to = to;
    },
    setEnergyForecastLegendStyle(state, action: PayloadAction<{ style: ElementStyle; id: number }>) {
      const { style, id } = action.payload;

      const energyPlot = state[id];

      if (!energyPlot) {
        logger.error("Could not find energy plot with id", id);
        return;
      }

      energyPlot.legendStyle = style;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTab.fulfilled, (state, action) => {
      const tab = action.payload;
      for (const key in state) {
        delete state[+key];
      }
      for (const energyPlot of tab.energy_plots) {
        state[energyPlot.id] = energyPlot;
      }
    });
    builder.addCase(createEnergyPlot.fulfilled, (state, action) => {
      const energyPlot = action.payload.tool;
      state[energyPlot.id] = energyPlot;
    });
  },
});

const selectEnergyPlots = (state: RootState) => state.tabGroup.present.energyPlots;

const makeSelectEnergyPlotsByTabId = createSelector(
  [selectEnergyPlots, (state: RootState, tabId: number) => tabId],
  (energyPlots, tabId) => Object.values(energyPlots).filter((plot) => plot.id_tab === tabId),
);

export function useEnergyPlots(tabId: number) {
  return useSelector((state: RootState) => makeSelectEnergyPlotsByTabId(state, tabId));
}

const makeSelectEnergyPlotById = createSelector(
  [selectEnergyPlots, (state: RootState, id: number) => id],
  (energyPlots, id) => energyPlots[id],
);

export function useEnergyPlot(id: number) {
  return useSelector((state: RootState) => makeSelectEnergyPlotById(state, id));
}

export const {
  addEnergyPlot,
  setEnergyPlotTitle,
  setEnergyForecastPlotProps,
  setEnergyForecastTitleStyle,
  setEnergyForecastLegendStyle,
  discardEnergyPlot,
  setEnergyForecastTimeRange,
} = energyPlotsSlice.actions;

export default energyPlotsSlice.reducer;

import { setTitle } from "@/effects";
import { Trans, t } from "@lingui/macro";
import { useEffect } from "react";

export default function NotFoundPage() {
  useEffect(() => {
    setTitle(t({ id: "not-found.title", message: "Not Found" }));
  }, []);

  return (
    <div className="page page--not-found">
      <Trans id="not-found.heading">Not Found</Trans>
    </div>
  );
}

import { DateTime } from "luxon";
import type { Style } from "mapbox-gl";
import { parse } from "papaparse";
import { DEFAULT_BASE_URL, type MeteomaticsApiConfiguration } from "./MeteomaticsApiConfiguration";
import { MeteomaticsApiUrl } from "./MeteomaticsApiUrl";
import { RestApi } from "./RestApi";
import type { Authentication } from "./auth";
import { BinaryGridResponse } from "./binary";
import { type CropResult, WORLD_BBOX, crop, intersectWeatherModelBoundingBox, withPaddingImg } from "./bounds";
import { type ConnectionManagement, CyclicConnectionDistribution } from "./connectionManagement";
import { type Abortable, type RequestAdaptor, execute, isAborted } from "./middleware";
import {
  Area,
  type AvailableTimeframeRequest,
  AvailableTimeframeResponse,
  type ColorMapRequest,
  ColorMapResponse,
  CoordinateSystem,
  type GeoJSONFeatureCollection,
  type GridFormat,
  type GridPayload,
  type GridRequest,
  type GridRequestOptions,
  type GridResponse,
  GridSamplingStrategyKind,
  type InitialTimeRequest,
  InitialTimeResponse,
  type IsoLinesRequest,
  type IsoLinesRequestOptions,
  IsoLinesRequest_toGridRequest,
  type IsoLinesResponse,
  type JSONGridResponse,
  type JSONPointResponse,
  type JSONPolygonResponse,
  type LightningListRequest,
  type MetarsRequest,
  type PixelGridRequest,
  type PointRequest,
  type PolygonRequest,
  type RawHttpResponse,
  type UserStatsResponse,
  type VectorLayerStyleRequest,
  type VectorTileRequestUnion,
  type WeatherFrontsOptions,
  type WeatherFrontsRequest,
  WeatherFrontsRequest_toGridRequest,
  type WeatherFrontsResponse,
  type WfsCapabilitiesResponse,
  type WfsRequest,
  type WfsResponse,
  type WindVector2JsonResponse,
  type WindVectorRequest,
} from "./models";
import type { BaseRequest } from "./models/BaseRequest";
import type { BaseResponse } from "./models/BaseResponse";
import type { JSONLightningListResponse, JSONLightningListResponseBody } from "./models/JSONLightningListResponse";
import type { LegendRequest } from "./models/LegendRequest";
import type { Metars, MetarsResponse, MetarsResponse_Transferable, Metars_Transferable } from "./models/MetarsResponse";
import * as pixelGridRequest from "./models/PixelGridRequest";
import { type PerformanceEstimate, performanceRecorder } from "./performanceIntrospection/PerformanceRecorder";
import type { Timer } from "./performanceIntrospection/Timer";
import { WFSParser } from "./utility/WFSParser";
type CreateHttpRequest = () => Abortable<{
  pathname: string;
  conf: Omit<RequestInit, "method" | "headers">;
}>;

/**
 * A pure container of Response object used by the core networking logics to transfer Response along with networking time.
 */
type TimedHttpResponse = { data: Response; timer: Timer };

/**
 * Represents a configured interface to the Meteomatics API.
 *
 * **Orchestrating Multiple Instances**
 *
 * Note that some components, like some advanced load balancing strategies perform better, if only a single instance of the API bindings is used.
 * Consult the documentation of the individual components.
 *
 * **A note on Coordinate System Support**
 *
 * The meteomatics API currently only supports WGS84 coordinate specifications. WMS requests additionally support EPSG3857, which is the
 * usual format for web maps in Mapbox GL, Google Maps and Open Street Maps.
 *
 * Grid specifications are reprojected to WGS84 before they are submitted to the server. This reprojection only reprojects the corner
 * points of the bounding box. As a result, if lines of the input projection do not map to straight lines in WGS84, or if in general,
 * the surface density of points changes through this reprojection, this will NOT be reflected in the API response.
 */
export class MeteomaticsApi<A extends Authentication> extends RestApi {
  public readonly connectionManagement: ConnectionManagement;
  public readonly authentication: A;

  constructor(config: MeteomaticsApiConfiguration<A>) {
    super();

    this.authentication = config.authentication;

    if (config.baseUrl == null) {
      this.connectionManagement = new CyclicConnectionDistribution([DEFAULT_BASE_URL]);
    } else if (typeof config.baseUrl === "string") {
      this.connectionManagement = new CyclicConnectionDistribution([config.baseUrl]);
    } else if (Array.isArray(config.baseUrl)) {
      this.connectionManagement = new CyclicConnectionDistribution(config.baseUrl);
    } else {
      this.connectionManagement = config.baseUrl;
    }
  }

  /**
   *
   * @param method
   * @param param1
   * @param createHttpRequest creates a request by possibly applying adaptors. The callback is invoked after all resources
   * have been aquired immediately before the request is started.
   *
   * @returns response data along with network benchmark data. the promise may be rejected by an adaptor instead of networking
   * issues. Please check the documentation for your enabled adaptors.
   */
  protected async req(
    method: "getRaw" | "postRaw",
    { performanceHint, timer }: PerformanceEstimate,
    createHttpRequest: CreateHttpRequest,
  ): Promise<TimedHttpResponse> {
    const accessTokenPromise = this.authentication.getAccessToken();
    const headerAuthorizationPromise = this.authentication.getAuthorizationHeaderValue();
    return Promise.all([accessTokenPromise, headerAuthorizationPromise]).then(([accessToken, headerAuthorization]) => {
      return this.connectionManagement.getBaseUrl(performanceHint).then(({ baseUrl, free }) => {
        const request = createHttpRequest();

        if (request.kind === "Abort") {
          // TODO: can we cancel within the connectionManager to avoid running the scheduler in a loop when
          // a lot of requests are canceled?
          free();
          timer.abort();
          return Promise.reject(request);
        }

        const { pathname, conf } = request.payload;

        // INFO: Below is a workaround for the isolines tiles.json request
        // TODO: Try to find a way to not send the access token in the URL
        const needsUrlToken = pathname.startsWith("/mvt/isolines") || pathname.startsWith("/mvt/aviation_reports");
        const headers: [string, string][] = needsUrlToken
          ? headerAuthorization
            ? [["Authorization", headerAuthorization]]
            : []
          : [["Authorization", `Bearer ${accessToken}`]];
        const url = new URL(`${baseUrl}/${pathname}`, globalThis?.location?.origin);
        if (accessToken && needsUrlToken) {
          url.searchParams.append("access_token", accessToken);
        }

        timer.start();
        return (
          // Pass "pathname.toString()" as unique abort signal key, as url could be most unique and accessible value possible
          super
            [method](url, pathname.toString(), { ...conf, headers })
            // only measure time if the request was successful.
            // its also important to integrate the measurement before returning the connection to the pool using `free`
            .then((data) => {
              timer.end();
              return { data, timer };
            })
            .catch((err) => {
              timer.abort();
              throw err;
            })
            .finally(() => free())
        );
      });
    });
  }

  protected async abortableGet(
    perf: PerformanceEstimate,
    createHttpRequest: CreateHttpRequest,
  ): Promise<TimedHttpResponse> {
    return this.req("getRaw", perf, createHttpRequest);
  }

  protected async abortablePost(
    perf: PerformanceEstimate,
    createHttpRequest: CreateHttpRequest,
  ): Promise<TimedHttpResponse> {
    return this.req("postRaw", perf, createHttpRequest);
  }

  protected async get(
    perf: PerformanceEstimate,
    pathname: string,
    conf: Omit<RequestInit, "method" | "headers"> = {},
  ): Promise<TimedHttpResponse> {
    return this.abortableGet(perf, () => execute({ pathname, conf }));
  }

  protected async post(
    perf: PerformanceEstimate,
    pathname: string,
    conf: Omit<RequestInit, "method" | "headers"> = {},
  ): Promise<TimedHttpResponse> {
    return this.abortablePost(perf, () => execute({ pathname, conf }));
  }

  private applyMiddlewareEnterStateWaiting<Req extends BaseRequest, DataType, Res extends BaseResponse<Req, DataType>>(
    request: Req,
    middleware: RequestAdaptor<Req, Res>[] = [],
  ): Abortable<Req> {
    // [DEBUG-MIDDLEWARE] console.log(`[middleware/waiting] ${request.width}x${request.height}`);
    let adapted = request;
    for (const adaptor of middleware) {
      // [DEBUG-MIDDLEWARE] console.time(`[middleware/waiting/${(adaptor as any).constructor.name}]`);
      const res = adaptor.enterStateWaiting(this, adapted);
      // [DEBUG-MIDDLEWARE] console.timeEnd(`[middleware/waiting/${(adaptor as any).constructor.name}]`);

      if (isAborted(res)) {
        // [DEBUG-MIDDLEWARE] console.log(`[middleware/waiting/${(adaptor as any).constructor.name}] aborting`, res.reason);
        return res;
      }
      // [DEBUG-MIDDLEWARE] console.log(`[middleware/waiting/${(adaptor as any).constructor.name}] ${request.width}x${request.height}`);
      adapted = res.payload;
    }

    return execute(adapted);
  }

  private applyMiddlewareEnterStateInFlight<Req extends BaseRequest, DataType, Res extends BaseResponse<Req, DataType>>(
    request: Req,
    middleware: RequestAdaptor<Req, Res>[] = [],
  ): Abortable<void> {
    // [DEBUG-MIDDLEWARE] console.log(`[middleware/inFlight] ${request.width}x${request.height}`);
    for (const adaptor of middleware) {
      // [DEBUG-MIDDLEWARE] console.time(`[middleware/inFlight/${(adaptor as any).constructor.name}]`);
      const res = adaptor.enterStateInFlight(this, request);
      // [DEBUG-MIDDLEWARE] console.timeEnd(`[middleware/inFlight/${(adaptor as any).constructor.name}]`);
      if (isAborted(res)) {
        // [DEBUG-MIDDLEWARE] console.log(`[middleware/inFlight/${(adaptor as any).constructor.name}] aborting`, res.reason);
        return res;
      }
    }

    return execute();
  }

  private applyMiddlewareEnterStateFinished<Req extends BaseRequest, DataType, Res extends BaseResponse<Req, DataType>>(
    response: Res,
    middleware: RequestAdaptor<Req, Res>[] = [],
  ): Res {
    let res = response;
    for (const adaptor of middleware.reverse()) {
      // [DEBUG-MIDDLEWARE] console.time(`[middleware/finished/${(adaptor as any).constructor.name}]`);
      res = adaptor.enterStateFinished(this, res);
      // [DEBUG-MIDDLEWARE] console.timeEnd(`[middleware/finished/${(adaptor as any).constructor.name}]`);
    }

    return res;
  }

  /**
   * A common entry function that executes a request.
   * Executes the middleware lifecycle as specified in the documentation.
   * @param props
   * @returns
   */
  private async executeRequestEntry<
    GenericRequest extends BaseRequest,
    GenericDataContent,
    GenericResponse extends BaseResponse<GenericRequest, GenericDataContent>,
  >(props: {
    request: GenericRequest;
    requestOptions: GridRequestOptions<GenericRequest, GenericResponse>;
    /**
     * A callback to create an URL object from a request object.
     * Make sure to pass the request from the function parameter to MeteomaticsApiUrl.
     * This ensures that separate URLs are created when the original request is split into multiple requests automatically by request splitter.
     */
    urlCreator: (req: GenericRequest) => MeteomaticsApiUrl;
    /**
     * A callback to measure the networking time for each request, which in turn is used for prioritizing request execution order.
     * Make sure to pass the request from the function parameter to PerformanceEstimater's function, rather than passing the estimater function directly.
     * This ensures that separate performance estimates are created when the original request is split into multiple requests automatically by request splitter.
     */
    performanceEstimater: (req: GenericRequest) => PerformanceEstimate;
    /**
     * A callback to convert the raw RawHttpResponse<GenericRequest> object to a promise of desired response object type.
     * It runs on a response immediately after the data is fetched. In case a request splitter is present, This callback will convert the
     * raw data before merging of split requests.
     */
    convertRawResponse: (res: RawHttpResponse<GenericRequest>) => Promise<GenericResponse>;
  }): Promise<GenericResponse> {
    const { urlCreator, performanceEstimater, request, requestOptions, convertRawResponse } = props;
    // An Example url looks as follows:
    // https://api.meteomatics.com/2021-03-01T13:15:00.000+01:00/t_2m:C/51.6918741,-0.5103751_51.2867601,0.3340155:1024x1024/png?model=mix
    // We post the URL path since automatically generated urls may exceed the maximal url length supported by browsers.

    const { forceGetRequest: _forceGetRequest, middleware, requestSplitter } = requestOptions;
    // Ensure forceGetRequest to be a boolean. Set it to true if it's not given.
    // Certain authentication methods do not work with post requests, thus we change to a get request here.
    const forceGetRequest = _forceGetRequest === undefined ? true : _forceGetRequest;
    const adaptedReq = this.applyMiddlewareEnterStateWaiting(request, middleware);
    if (isAborted(adaptedReq)) {
      return Promise.reject(adaptedReq);
    }

    // 1) Split the request (optional), 2) Apply the EnterStateInFlight middleware, 3) execute the requests,
    // 3) convert the raw response to the desired response type, and 4) Merge the subresponse (optional)
    let finalResponse: Promise<GenericResponse>;
    if (requestSplitter) {
      // Split the requests if the request splitter is provided, and execute the multiple subrequests.
      const subRequests: GenericRequest[] = requestSplitter.splitRequest(adaptedReq.payload);
      const convertedResponsePromises: Promise<GenericResponse>[] = [];
      for (let i = 0; i < subRequests.length; i++) {
        const abortSignal = this.applyMiddlewareEnterStateInFlight(subRequests[i], middleware);
        if (isAborted(abortSignal)) {
          // Stop the execution entirely if one of the sub requests is aborted.
          return Promise.reject(abortSignal);
        }
        convertedResponsePromises.push(
          this._executeSingleReq(subRequests[i], urlCreator, forceGetRequest, performanceEstimater)
            // Convert the raw RawHttpResponse<GenericRequest> object to a promise of desired response object type.
            .then((rawResposne) => convertRawResponse(rawResposne)),
        );
      }

      // Can't use assigned variable, as it causes losing of requestSplitter scope
      // const mergeResponses = requestSplitter.mergeResponses;
      finalResponse = Promise.all(convertedResponsePromises).then((responses) =>
        requestSplitter.mergeResponses(responses),
      );
    } else {
      const abortSignal = this.applyMiddlewareEnterStateInFlight(request, middleware);
      // If no requestSplitter is provided, just execute the original request.
      if (isAborted(abortSignal)) {
        // Stop the execution entirely if one of the sub requests is aborted.
        return Promise.reject(abortSignal);
      }

      finalResponse = this._executeSingleReq(request, urlCreator, forceGetRequest, performanceEstimater)
        // Convert the raw RawHttpResponse<GenericRequest> object to a promise of desired response object type.
        .then((rawResposne) => {
          return convertRawResponse(rawResposne);
        });
    }

    return finalResponse.then((res) => this.applyMiddlewareEnterStateFinished(res));
  }

  /**
   * A supporter function for executeRequestEntry. Executes a single request.
   *
   *
   * Do not call this function alone as the request middleware lifecycle is implemented in executeRequestEntry.
   * Calling this alone will result in skipping the middleware execution.
   * @param request
   * @param urlCreator
   * @param forceGetRequest
   * @param performanceEstimater
   * @returns
   */
  private async _executeSingleReq<GenericRequest extends BaseRequest>(
    request: GenericRequest,
    /**
     * A callback to create an URL object from the request object.
     */
    urlCreator: (req: GenericRequest) => MeteomaticsApiUrl,
    forceGetRequest: boolean,
    performanceEstimater: (req: GenericRequest) => PerformanceEstimate,
  ): Promise<RawHttpResponse<GenericRequest>> {
    // An Example url looks as follows:
    // https://api.meteomatics.com/2021-03-01T13:15:00.000+01:00/t_2m:C/51.6918741,-0.5103751_51.2867601,0.3340155:1024x1024/png?model=mix
    // We post the URL path since automatically generated urls may exceed the maximal url length supported by browsers.

    // Certain authentication methods do not work with post requests, thus we change to a get request here.
    const usedForceGetRequest = forceGetRequest ?? this.authentication.forceGetRequest();
    const url = urlCreator(request);
    const method = usedForceGetRequest ? "abortableGet" : "abortablePost";
    const performanceEstimate = performanceEstimater(request);
    const timedRes = this[method](performanceEstimate, () => {
      // the sampling strategy is applied within the url builder
      return usedForceGetRequest
        ? execute({ pathname: url.toString(), conf: {} })
        : execute({ pathname: url.pathname, conf: { body: url.body } });
    });
    return timedRes.then((timedRes) => {
      return { request, ...timedRes };
    });
  }

  /**
   * Get a png from the WMS server
   * @param pixelRequest specification of the grid including location, resolution, time.
   * @param pixelRequestOptions
   * @todo `area` should not be abused here. provide projection functions to convert between mercator, slippy tiles, epsg etc
   */
  async wms<C extends CoordinateSystem>(
    pixelRequest: PixelGridRequest<C>,
    pixelRequestOptions: GridRequestOptions<PixelGridRequest<C>, GridResponse<GridPayload<ImageBitmap>, C>> = {},
  ): Promise<GridResponse<GridPayload<ImageBitmap>, C>> {
    let request = pixelGridRequest.toGridRequest(pixelRequest);
    let partialTile: CropResult<C> | null = null;
    if (request.boundingBoxLimit) {
      const intersectionModelBounds = intersectWeatherModelBoundingBox(request, request.boundingBoxLimit);
      // TODO: OutOfBounds return emptyTile
      // as an optimization, out of bounds tiles, which are all identical, are deduplicated
      // This logic is currently implemented in the SpatialTemporal subcache in MetX workbench, and requests that are completely
      // out-of-bounds are suppressed.
      // However, when using this library outside the application, a completely out-of-bounds requests are currently
      // unhandled.
      // if (intersectionModelBounds.kind === "OutOfBounds") {
      //   return this.emptyTile;
      // }
      if (intersectionModelBounds.kind === "PartiallyOutOfBounds") {
        // this cast from WGS84 to EPSG3857 is safe, even though EPSG3857 has a smaller latitude range, since
        // the intersection is the result of an intersection with an EPSG3857 AABB. Thus the intersection itself,
        // has to be within the valid EPSG3857 range.
        const intersection = intersectionModelBounds.intersection.project(pixelRequest.area.crs);
        // Note this currently only supports WGS84 projection.
        partialTile = crop(request, intersection, request.boundingBoxLimit.project(request.area.crs));
        request = partialTile.gridRequest;
        pixelRequest.area = request.area;
      }
    }

    return this.executeRequestEntry({
      request: pixelRequest,
      requestOptions: pixelRequestOptions,
      urlCreator: (req) => MeteomaticsApiUrl.forWms(req),
      performanceEstimater: (req) => performanceRecorder.estimateGridRequest(req),
      convertRawResponse: MeteomaticsApi.asImageBitmap,
    }).then((response) => {
      // If we only requested a partial tile, pad it to the expected size
      if (partialTile) {
        return withPaddingImg(response, partialTile);
      }
      return response;
    });
  }

  /**
   * Abort API request
   *
   * @returns urls, that wasn't aborted buy RestApi due of missing abort controllers,
   * it may mean, that request is already finished or sitting in queue
   * @param urls
   */
  public abortRequestByUrl(urls: string[]): string[] {
    // Return list of requests that wasn't aborted, because most likely they isn't active
    return urls.filter((url) => !super.abortRequest(url));
  }

  async user_stats(): Promise<UserStatsResponse> {
    const pathname = "user_stats_json";
    const performanceEstimate = performanceRecorder.estimateUnparameterized(pathname);
    return this.get(performanceEstimate, pathname).then<UserStatsResponse, any>(({ data }) => data.json());
  }

  /**
   * Get samples of a color map.
   *
   * @param opts parameter and name of the color map. If the color map is elided, the default color map will be returned.
   * @returns
   */
  async color_map(opts: ColorMapRequest): Promise<ColorMapResponse> {
    const endpoint = "get_colormap";
    const pathname = MeteomaticsApiUrl.forColorMap(opts).toString();
    const performanceEstimate = performanceRecorder.estimateUnparameterized(endpoint);
    return this.get(performanceEstimate, pathname)
      .then(({ data }) => data.text())
      .then(ColorMapResponse.parseFromCsv);
  }

  /**
   * Get a png directly from the API. You can alternatively request a png image from the WMS server using the `wms` method.
   *
   * We provide several higher order functions to transform the result of this request. See `asImage`, `asImageBuffer`, `asArrayBuffer`, `asBlob`, etc.
   *
   * Note that the area is always sampled using the `SamplingStrategy.Area` strategy.
   *
   * An example usage could look as follows:
   *
   * ```js
   * const api = new MeteomaticsApi(// ...
   * const request = // ...
   * const img = api.png(request).then(MeteomaticsApi.asImage);
   * document.body.append(img)
   * ```
   */
  async png<C extends CoordinateSystem>(
    pixelRequest: PixelGridRequest<C>,
    pixelRequestOptions: GridRequestOptions<GridRequest<C>, GridResponse<Response, C>> = {},
  ): Promise<GridResponse<Response, C>> {
    // TODO: verify that a grid request with png has the same behaviour as wms (Point -> Area transform is performed automatically)
    const format: GridFormat = {
      format: "png",
      colorMap: pixelRequest.colorMap,
    };
    const gridRequest = pixelGridRequest.toGridRequest(pixelRequest);
    return this.executeRequestEntry({
      request: gridRequest,
      requestOptions: pixelRequestOptions,
      urlCreator: (req) => MeteomaticsApiUrl.forGrid(format, req),
      performanceEstimater: performanceRecorder.estimateGridRequest,
      convertRawResponse: MeteomaticsApi.asRawResponse,
    });
  }

  /**
   * Make a binary api request.
   *
   * See the documentation of the data class `Binary` for details.
   */
  async binary<C extends CoordinateSystem>(
    request: GridRequest<C>,
    requestOptions: GridRequestOptions<GridRequest<C>, BinaryGridResponse<C>> = {},
  ): Promise<BinaryGridResponse<C>> {
    // TODO: verify that a grid request with png has the same behaviour as wms (Point -> Area transform is performed automatically)
    const format: GridFormat = { format: "bin" };
    return this.executeRequestEntry({
      request: request,
      requestOptions: requestOptions,
      urlCreator: (req) => MeteomaticsApiUrl.forGrid(format, req),
      performanceEstimater: (req) => performanceRecorder.estimateGridRequest(req),
      convertRawResponse: MeteomaticsApi.asArrayBuffer,
    }).then((res) => new BinaryGridResponse(res));
  }

  /**
   * Make request for vector layer style
   * TODO Improve description
   * @param request
   * @returns
   */
  // TODO Vector tiles - typize vector layer style response
  async vectorLayerStyle(request: VectorLayerStyleRequest): Promise<BaseResponse<VectorLayerStyleRequest, Style>> {
    const pathname = MeteomaticsApiUrl.forVectorLayerStyle(request).toString();
    // TODO Vector tile - figure right approach for performance estimate

    return this.executeRequestEntry({
      request,
      requestOptions: {},
      urlCreator: (req) => MeteomaticsApiUrl.forVectorLayerStyle(request),
      performanceEstimater: (req) => performanceRecorder.estimateUnparameterized(pathname),
      convertRawResponse: MeteomaticsApi.asStyleJSON,
    });
  }

  /**
   *
   * Make a binary api request for vector tile
   *
   */
  // TODO Vector tiles - revisit typing
  async vectorTile(request: VectorTileRequestUnion): Promise<BaseResponse<VectorTileRequestUnion, ArrayBuffer>> {
    return this.executeRequestEntry({
      request,
      requestOptions: {},
      urlCreator: (req) => MeteomaticsApiUrl.forVectorTile(req),
      performanceEstimater: (req) => performanceRecorder.estimateVectorTileRequest(req),
      convertRawResponse: MeteomaticsApi.asVectorTileArrayBuffer,
    });
  }

  // /**
  //  * Same as `binary`, but split across multiple requests.
  //  *
  //  * @param request
  //  * @param requestOptions
  //  * @returns
  //  */
  // // TODO: generalize this. The idea of a middleware stack is great. but the whole middleware stack including this is a little bit unergonomic.
  // async binary_parallel<C extends CoordinateSystem>(
  //   request: GridRequest<C>,
  //   requestOptions: ParallelGridRequestOptions<GridRequest<C>, BinaryGridResponse<C>, Float32Area>
  // ): Promise<Float32Area> {
  //   const adapted = this.applyMiddlewareEnterStateWaiting(request, requestOptions.middleware);

  //   if (request.parameters.length > 1) {
  //     throw Error("Multiple parameters are not yet supported!");
  //   }

  //   if (isAborted(adapted)) {
  //     return Promise.reject(adapted);
  //   }

  //   const subrequests = requestOptions.parallelizationMethod.splitRequest(adapted.payload);

  //   const response = Promise.all(
  //     subrequests.map((subgridRequest) =>
  //       this.grid_({ format: "bin" }, subgridRequest, requestOptions.middleware ?? [], requestOptions.forceGetRequest)
  //         .then(MeteomaticsApi.asArrayBuffer)
  //         .then((response) => new BinaryGridResponse(response))
  //     )
  //   ).then((responses) => requestOptions.parallelizationMethod.mergeResponses(responses));

  //   const adaptedResponse = response.then((res) =>
  //     this.applyMiddlewareEnterStateFinished(res, requestOptions.middleware)
  //   );

  //   return adaptedResponse;
  // }

  static async asBlob<C extends CoordinateSystem>(
    rawResponse: RawHttpResponse<GridRequest<C>>,
  ): Promise<GridResponse<Blob, C>> {
    return Promise.resolve(rawResponse.data)
      .then((v) => v.blob())
      .then((data) => ({ ...rawResponse, data }));
  }

  static async asHtmlImageElement<C extends CoordinateSystem>(
    rawResponse: RawHttpResponse<GridRequest<C>>,
  ): Promise<GridResponse<GridPayload<HTMLImageElement>, C>> {
    return Promise.resolve(rawResponse.data)
      .then((v) => v.blob())
      .then((data) => {
        return new Promise<GridPayload<HTMLImageElement>>((resolve, reject) => {
          const url = URL.createObjectURL(data);
          const img = new Image();

          img.onload = () => {
            URL.revokeObjectURL(url);
            resolve({ payload: img, estimatedByteSize: data.size });
          };
          img.onerror = reject;

          img.src = url;
        });
      })
      .then((data) => ({ ...rawResponse, data }));
  }

  static async asImageBitmap<C extends CoordinateSystem>(
    rawResponse: RawHttpResponse<GridRequest<C>>,
  ): Promise<GridResponse<GridPayload<ImageBitmap>, C>> {
    return Promise.resolve(rawResponse.data)
      .then((v) => v.blob())
      .then((blob) => {
        const estimatedByteSize = blob.size;

        return createImageBitmap(blob).then((payload) => ({
          ...rawResponse,
          data: { payload, estimatedByteSize },
        }));
      });
  }

  async pointJson(
    request: PointRequest<CoordinateSystem.WGS84>,
    requestOptions: GridRequestOptions<PointRequest<CoordinateSystem.WGS84>, JSONPointResponse> = {},
  ): Promise<JSONPointResponse> {
    const format: GridFormat = { format: "json" };
    return this.executeRequestEntry({
      request: request,
      requestOptions: requestOptions,
      urlCreator: (req) => MeteomaticsApiUrl.forPoint(format, req),
      performanceEstimater: (res) => performanceRecorder.estimatePointRequest(res),
      convertRawResponse: MeteomaticsApi.asJSONPoint,
    });
  }

  async polygonJson(
    request: PolygonRequest<CoordinateSystem.WGS84>,
    requestOptions: GridRequestOptions<PolygonRequest<CoordinateSystem.WGS84>, JSONPolygonResponse> = {},
  ): Promise<JSONPolygonResponse> {
    const format: GridFormat = { format: "json" };
    return this.executeRequestEntry({
      request: request,
      requestOptions: requestOptions,
      urlCreator: (req) => MeteomaticsApiUrl.forPolygon(format, req),
      performanceEstimater: (res) => performanceRecorder.estimatePolygonRequest(res),
      convertRawResponse: MeteomaticsApi.asJSONPolygon,
    });
  }

  async timeframe_map(request: AvailableTimeframeRequest): Promise<AvailableTimeframeResponse> {
    const endpoint = "get_availableTimeframe";
    const pathname = MeteomaticsApiUrl.forAvailableTimeRange(request.model, request.parameters).toString();
    const performanceEstimate = performanceRecorder.estimateUnparameterized(endpoint);
    return this.get(performanceEstimate, pathname)
      .then(({ data }) => data.text())
      .then(AvailableTimeframeResponse.parseFromCsv);
  }

  async initTime_map(request: InitialTimeRequest): Promise<InitialTimeResponse> {
    const endpoint = "get_initTime";
    const pathname = MeteomaticsApiUrl.forInitialTime(request.model, request.parameters, request.validDate).toString();
    const performanceEstimate = performanceRecorder.estimateUnparameterized(endpoint);
    return this.get(performanceEstimate, pathname)
      .then(({ data }) => data.text())
      .then(InitialTimeResponse.parseFromCsv);
  }

  private recalculateBBox(request: GridRequest<CoordinateSystem.WGS84>) {
    const gridRequest = request;
    const sameCrsArea = new Area(CoordinateSystem.WGS84, WORLD_BBOX);

    // we exclude here the model/sources with global coverage because otherwise the
    // requestSplitters.AdjustLongitudeForIsolines on the single viewport request that goes over -180 and +180 gets already cuted by the global limit
    // we don't need to do this on the wms because we use there the tiling
    if (request.boundingBoxLimit && !request.boundingBoxLimit.equals(sameCrsArea)) {
      const intersectionModelBounds = intersectWeatherModelBoundingBox(request, request.boundingBoxLimit);

      // TODO: OutOfBounds return emptyTile
      // as an optimization, out of bounds tiles, which are all identical, are deduplicated
      // This logic is currently implemented in the SpatialTemporal subcache in MetX workbench, and requests that are completely
      // out-of-bounds are suppressed.
      // However, when using this library outside the application, a completely out-of-bounds requests are currently
      // unhandled.
      // if (intersectionModelBounds.kind === "OutOfBounds") {
      //   return this.emptyTile;
      // }
      if (intersectionModelBounds.kind === "PartiallyOutOfBounds") {
        // this cast from WGS84 to EPSG3857 is safe, even though EPSG3857 has a smaller latitude range, since
        // the intersection is the result of an intersection with an EPSG3857 AABB. Thus the intersection itself,
        // has to be within the valid EPSG3857 range.
        const intersection = intersectionModelBounds.intersection.project(gridRequest.area.crs);
        // Note this currently only supports WGS84 projection.

        // On Point GridSamplingStrategyKind we use direct the intersection area and not crop
        // because on the crop function is the "point" area additional reduced
        // png layer uses also crop function with Point GridSamplingStrategyKind but per tiles
        let pGridRequest = { ...request, area: intersection };

        // On Area GridSamplingStrategyKind we crop it. And on Point we  use direct the
        if (request.sampling.kind === GridSamplingStrategyKind.Area) {
          const { gridRequest: newGridRequest } = crop(
            request,
            intersection,
            request.boundingBoxLimit.project(request.area.crs),
          );
          pGridRequest = newGridRequest;
        }
        return pGridRequest;
      }
    }

    return gridRequest;
  }

  async gridJson(
    request: GridRequest<CoordinateSystem.WGS84>,
    requestOptions: GridRequestOptions<GridRequest<CoordinateSystem.WGS84>, JSONGridResponse> = {},
  ): Promise<JSONGridResponse> {
    const format: GridFormat = { format: "json" };
    return this.executeRequestEntry({
      request: this.recalculateBBox(request),
      requestOptions: requestOptions,
      urlCreator: (req) => MeteomaticsApiUrl.forGrid(format, req),
      performanceEstimater: (req) => performanceRecorder.estimateGridRequest(req),
      convertRawResponse: MeteomaticsApi.asJSONGrid,
    });
  }

  async windVectorJson<C extends CoordinateSystem>(
    request: WindVectorRequest<C>,
    requestOptions: GridRequestOptions<WindVectorRequest<C>, WindVector2JsonResponse<C>> = {},
  ): Promise<WindVector2JsonResponse<C>[]> {
    const format: GridFormat = { format: "json" };
    // TODO try to replace any
    return this.executeRequestEntry<any, any, any>({
      request: request,
      requestOptions: requestOptions,
      urlCreator: (req) => MeteomaticsApiUrl.forWindVector(format, req),
      performanceEstimater: (req) => performanceRecorder.estimateGridRequest(req),
      convertRawResponse: MeteomaticsApi.asWindVectorImageBitmap,
    });
  }

  async barbJson(
    request: GridRequest<CoordinateSystem.WGS84>,
    requestOptions: GridRequestOptions<GridRequest<CoordinateSystem.WGS84>, JSONGridResponse> = {},
  ): Promise<JSONGridResponse> {
    const format: GridFormat = { format: "json" };
    return this.executeRequestEntry({
      request: this.recalculateBBox(request),
      requestOptions: requestOptions,
      urlCreator: (req) => MeteomaticsApiUrl.forGrid(format, req),
      performanceEstimater: (req) => performanceRecorder.estimateGridRequest(req),
      convertRawResponse: MeteomaticsApi.asJSONGrid,
    });
  }

  /**
   * Query the WFS GetFeature endpoints and return the JSON result.
   * By default, an endpoint with WFS interface returns XML. To retrieve the raw XML, use wfsRaw().
   * @param request
   * @param requestOptions
   * @returns A promise containing the result
   */
  async wfsJson(
    request: WfsRequest<CoordinateSystem.WGS84, "GetFeature">,
    requestOptions: GridRequestOptions<WfsRequest<CoordinateSystem.WGS84>, WfsResponse<CoordinateSystem.WGS84>> = {},
  ): Promise<WfsResponse<CoordinateSystem.WGS84>> {
    return this.executeRequestEntry({
      request: request,
      requestOptions: requestOptions,
      urlCreator: (req) => MeteomaticsApiUrl.forWfs(req),
      performanceEstimater: (req) => performanceRecorder.estimateWfsRequest(req),
      convertRawResponse: MeteomaticsApi.asWfsResponse,
    });
  }

  /**
   * Query the WFS GetCapabilities endpoints and return the JSON result.
   * By default, an endpoint with WFS interface returns XML. To retrieve the raw XML, use wfsRaw().
   * @param request
   * @param requestOptions
   * @returns A promise containing the result
   */
  async wfsCapabilitiesJson(
    request: WfsRequest<CoordinateSystem.WGS84, "GetCapabilities">,
    requestOptions: GridRequestOptions<
      WfsRequest<CoordinateSystem.WGS84>,
      WfsCapabilitiesResponse<CoordinateSystem.WGS84>
    > = {},
  ): Promise<WfsCapabilitiesResponse<CoordinateSystem.WGS84>> {
    return this.executeRequestEntry({
      request: request,
      requestOptions: requestOptions,
      urlCreator: (req) => MeteomaticsApiUrl.forWfsCapabilities(req),
      performanceEstimater: (req) => performanceRecorder.estimateWfsRequest(req),
      convertRawResponse: MeteomaticsApi.asWfsCapabilitiesResponse,
    });
  }

  /**
   * Query the get_weatherfonts endpoint.
   *
   * @param weatherFrontsRequest
   * @param requestOptions
   */
  async weatherFrontsGeoJSON<C extends CoordinateSystem>(
    weatherFrontsRequest: WeatherFrontsRequest,
    requestOptions: WeatherFrontsOptions<WeatherFrontsRequest, WeatherFrontsResponse> = {},
  ): Promise<WeatherFrontsResponse> {
    const request = WeatherFrontsRequest_toGridRequest(weatherFrontsRequest);
    weatherFrontsRequest.area = this.recalculateBBox(request).area;
    return this.executeRequestEntry({
      request: weatherFrontsRequest,
      requestOptions: requestOptions,
      urlCreator: (req) => MeteomaticsApiUrl.forWeatherFronts(req),
      performanceEstimater: (req) => performanceRecorder.estimateWeatherFrontsRequest(req),
      convertRawResponse: MeteomaticsApi.asWeatherFrontsResponse,
    });
  }

  /**
   * Query the get_lightningList endpoint.
   *
   * @param lightningListRequest
   */
  async lightningListGeoJSON(
    lightningListRequest: LightningListRequest<CoordinateSystem.WGS84>,
  ): Promise<JSONLightningListResponse> {
    return this.executeRequestEntry({
      request: lightningListRequest,
      requestOptions: {},
      urlCreator: (req) => MeteomaticsApiUrl.forLightningList(req),
      performanceEstimater: (req) =>
        performanceRecorder.estimateUnparameterized(MeteomaticsApiUrl.forLightningList(req).pathname),
      convertRawResponse: MeteomaticsApi.asJSONLightningList,
    });
  }

  /**
   * Fetch wms legend.
   *
   * @param legendRequest
   */
  async legendSVG(legendRequest: LegendRequest): Promise<string> {
    const legendPath = MeteomaticsApiUrl.forLegend(legendRequest).pathname;
    const performanceEstimate = performanceRecorder.estimateUnparameterized(legendPath);
    return this.get(performanceEstimate, legendPath).then(({ data }) => data.text());
  }

  /**
   * Query the get_isolines endpoint.
   *
   * @param isolineRequest
   * @param requestOptions
   */
  async isolinesGeoJSON<C extends CoordinateSystem>(
    isolineRequest: IsoLinesRequest,
    requestOptions: IsoLinesRequestOptions<IsoLinesRequest, IsoLinesResponse> = {},
  ): Promise<IsoLinesResponse> {
    const request = IsoLinesRequest_toGridRequest(isolineRequest);
    isolineRequest.area = this.recalculateBBox(request).area;

    return this.executeRequestEntry({
      request: isolineRequest,
      requestOptions: requestOptions,
      urlCreator: (req) => MeteomaticsApiUrl.forIsoLines(req),
      performanceEstimater: (req) => performanceRecorder.estimateIsoLinesRequest(req),
      convertRawResponse: MeteomaticsApi.asIsoLinesResponse,
    });
  }

  /**
   * Query the get_metars endpoint.
   *
   * @param MetarsRequest
   */
  async metars(request: MetarsRequest): Promise<MetarsResponse_Transferable> {
    const url = MeteomaticsApiUrl.forMetars(request).pathname;
    const performanceEstimate = performanceRecorder.estimateUnparameterized(url);

    return this.get(performanceEstimate, url)
      .then(({ data }) => data.text())
      .then((content) => {
        const parsedMetars = parse<Metars_Transferable>(content as string, {
          header: true,
          skipEmptyLines: true,
        });

        return {
          metars: parsedMetars.data,
        };
      });
  }

  /**
   * Converter function that does nothing on the data property of the RawHttpResponse.
   * Use this when you want to change the return type of the Response object from RawHttpResponse to other response type such as GridResponse,
   * but leave the data property type as Response.
   * @param rawResponse
   * @returns
   */
  static async asRawResponse<
    GenericResponse extends BaseResponse<GenericRequest, Response>,
    GenericRequest extends BaseRequest,
  >(rawResponse: RawHttpResponse<GenericRequest>): Promise<GenericResponse> {
    return Promise.resolve(<GenericResponse>rawResponse);
  }

  /**
   * Convert a raw binary response to an array buffer.
   *
   * Does not decode image data such as the results of `png()`, use `asArrayBufferOfImage()` for these cases.
   *
   * @returns
   * @param rawResponse
   */
  static async asArrayBuffer<C extends CoordinateSystem>(
    rawResponse: RawHttpResponse<GridRequest<C>>,
  ): Promise<GridResponse<ArrayBuffer, C>> {
    return Promise.resolve(rawResponse.data)
      .then((v) => v.arrayBuffer())
      .then((data) => ({ ...rawResponse, data }));
  }

  /**
   * Convert raw binary response to Vector tile array buffer
   * This is very similar function to asArrayBuffer, but
   * it doesn't extends CoordinateSystem, as it's
   * handled by mapbox internally
   *
   * @param rawResponse
   * @returns
   */
  static async asVectorTileArrayBuffer(
    rawResponse: RawHttpResponse<VectorTileRequestUnion>,
  ): Promise<BaseResponse<VectorTileRequestUnion, ArrayBuffer>> {
    return Promise.resolve(rawResponse.data)
      .then((v) => v.arrayBuffer())
      .then((data) => ({ ...rawResponse, data }));
  }

  static async asArrayBufferOfImage<C extends CoordinateSystem>(grid: GridResponse<Response, C>) {
    return Promise.resolve(grid.data).then((v) => v.arrayBuffer());
  }

  static async asCanvasImageSource<C extends CoordinateSystem>(
    rawResponse: RawHttpResponse<GridRequest<C>>,
  ): Promise<GridResponse<GridPayload<CanvasImageSource>, C>> {
    if (typeof createImageBitmap !== "undefined") {
      return MeteomaticsApi.asImageBitmap(rawResponse);
    }
    return MeteomaticsApi.asHtmlImageElement(rawResponse);
  }

  /**
   * Decode the response to an IsoLinesResponse.
   *
   * @param rawResponse
   */
  static async asIsoLinesResponse(rawResponse: RawHttpResponse<IsoLinesRequest>): Promise<IsoLinesResponse> {
    return Promise.resolve(rawResponse.data)
      .then((v) => v.json())
      .then((data) => {
        if (data.status.toUpperCase() === "OK") {
          if (!data.features) {
            // sometimes getIsolines returns undefined features but we got a 200
            data.features = [];
          }

          return data;
        }
        // status is "ERROR"
        data.features = [];
        data.type = "FeatureCollection";
        return data;
      })
      .then((data) => ({ ...rawResponse, data }));
  }

  /**
   * Decode the response to an WeatherFrontsResponse.
   *
   * @param rawResponse
   */
  static async asWeatherFrontsResponse(
    rawResponse: RawHttpResponse<WeatherFrontsRequest>,
  ): Promise<WeatherFrontsResponse> {
    return Promise.resolve(rawResponse.data)
      .then((v) => v.json())
      .then((data) => {
        if (!data.features) {
          // sometimes getWeatherFronts returns undefined features
          data.features = [];
        }
        return data;
      })
      .then((data) => ({ ...rawResponse, data }));
  }

  /**
   * Decode the response to a JSONPointResponse.
   * @param rawResponse
   */
  static async asJSONPoint(
    rawResponse: RawHttpResponse<PointRequest<CoordinateSystem.WGS84>>,
  ): Promise<JSONPointResponse> {
    return Promise.resolve(rawResponse.data)
      .then((v) => v.json())
      .then((data) => ({ ...rawResponse, data }));
  }

  /**
   * Decode the response to a JSONPolygonResponse.
   * @param rawResponse
   */
  static async asJSONPolygon(
    rawResponse: RawHttpResponse<PolygonRequest<CoordinateSystem.WGS84>>,
  ): Promise<JSONPolygonResponse> {
    return Promise.resolve(rawResponse.data)
      .then((v) => v.json())
      .then((data) => ({ ...rawResponse, data }));
  }

  static async asStyleJSON(
    rawResponse: RawHttpResponse<VectorLayerStyleRequest>,
  ): Promise<BaseResponse<VectorLayerStyleRequest, Style>> {
    return Promise.resolve(rawResponse.data)
      .then((v) => v.json())
      .then((data) => ({ ...rawResponse, data }));
  }

  /**
   * Decode the response to a JSONGridResponse.
   * @param rawResponse
   */
  static async asJSONGrid(
    rawResponse: RawHttpResponse<GridRequest<CoordinateSystem.WGS84>>,
  ): Promise<JSONGridResponse> {
    return Promise.resolve(rawResponse)
      .then((rawResponse) => rawResponse.data.json())
      .then((data) => ({ ...rawResponse, data }));
  }

  static async asJSONLightningList(
    rawResponse: RawHttpResponse<LightningListRequest<CoordinateSystem.WGS84>>,
  ): Promise<JSONLightningListResponse> {
    return rawResponse.data.json().then((json: JSONLightningListResponseBody) => {
      const geoJson: GeoJSONFeatureCollection = {
        type: "FeatureCollection",
        features: [],
      };

      geoJson.features = json.lightning_list.map((lightning) => {
        const properties: {
          [key: string]: string | number;
        } = {};

        for (const [key, value] of Object.entries(lightning)) {
          const [name, unit] = key.split(":");
          properties[name] = value;
          if (name === "stroke_time") {
            properties.stroke_time_ms = DateTime.fromISO(value).valueOf();
          }
        }

        return {
          type: "Feature",
          properties: { ...properties },
          geometry: {
            // x and y => lon and lat
            coordinates: [Number.parseFloat(lightning["stroke_lon:d"]), Number.parseFloat(lightning["stroke_lat:d"])],
            type: "Point",
          },
        };
      });
      return {
        request: rawResponse.request,
        timer: rawResponse.timer,
        data: geoJson,
      };
    });
  }

  //TODO: check if this make sense to merge the two wind json
  /**
   * Decode the response to a JSONGridResponse.
   * @param rawResponse
   */
  static async asWindVectorImageBitmap<C extends CoordinateSystem>(
    rawResponse: RawHttpResponse<WindVectorRequest<C>>,
  ): Promise<WindVector2JsonResponse<C>> {
    return Promise.resolve(rawResponse)
      .then((rawResponse) => rawResponse.data.json())
      .then((data) => ({ ...rawResponse, data }));
  }

  static async asWfsResponse(
    rawResponse: RawHttpResponse<WfsRequest<CoordinateSystem.WGS84>>,
  ): Promise<WfsResponse<CoordinateSystem.WGS84>> {
    return rawResponse.data.text().then((bodyXml) => {
      const json = WFSParser.convertFeatureCollection2GeoJSON(bodyXml);
      return {
        request: rawResponse.request,
        timer: rawResponse.timer,
        data: json,
      };
    });
  }

  static async asWfsCapabilitiesResponse(
    rawResponse: RawHttpResponse<WfsRequest<CoordinateSystem.WGS84>>,
  ): Promise<WfsCapabilitiesResponse<CoordinateSystem.WGS84>> {
    return rawResponse.data.text().then((bodyXml) => {
      const json = WFSParser.parseWfsCapabilities(bodyXml);
      return {
        request: rawResponse.request,
        timer: rawResponse.timer,
        data: json,
      };
    });
  }

  /**
   * Decode the response into something that can be uploaded as a texture to the GPU via WebGL.
   * This method will choose the fastest available method.
   *
   * @param rawResponse
   */
  static async asWebGlTexture<C extends CoordinateSystem>(
    rawResponse: RawHttpResponse<GridRequest<C>>,
  ): Promise<GridResponse<GridPayload<TexImageSource>, C>> {
    if (typeof createImageBitmap !== "undefined") {
      return MeteomaticsApi.asImageBitmap(rawResponse);
    }
    return MeteomaticsApi.asHtmlImageElement(rawResponse);
  }
}

import { Trans } from "@lingui/macro";
import type { SlimProfile } from "@mm/metx-workbench.meteomatics.com";
import { FormProvider, useForm, useWatch } from "react-hook-form";

import { AddDashboardCard, DashboardCard } from "@/dashboard";
import { DashboardHeader } from "@/dashboard/DashboardHeader";
import { useDebounceQueryFilter } from "@/dashboard/hooks";
import type { DashboardProps, FilterAndSorterFormTypes } from "@/dashboard/types";
import { sortAndFilter } from "@/dashboard/utils";
import { useIdleTimeMode } from "@/models/time-control/timeStateHooks";
import { useProfiles } from "@/reducer/ProfileState";
import { classNames } from "@/utility/jsx";
import { useMemo } from "react";

/**
 * Listing of profiles within the account
 */
export function Dashboards({ maximized, heading }: DashboardProps) {
  const methods = useForm<FilterAndSorterFormTypes>({
    defaultValues: {
      query: "",
      sort: {
        property: "title",
        isDescending: true,
      },
    },
  });

  const activeSorter = useWatch({ control: methods.control, name: "sort" });
  const query = useDebounceQueryFilter(methods.control);
  const { profiles, isLoading, hasError } = useProfiles();

  // TODO Refactor state machine, so it's get's constructed only in workspace scope
  useIdleTimeMode();

  // Workaround to fix React 18 warning about endless redraws
  const profilesArray = useMemo<SlimProfile[]>(() => Object.values(profiles), [profiles]);

  if (isLoading) {
    return <mm-loader size="xlarge" />;
  }

  if (hasError) {
    // TODO add nice looking error page
    return <Trans id="profile.fetch.failure">Failed to load profiles from server</Trans>;
  }

  return (
    <FormProvider {...methods}>
      <div
        className={classNames(
          "profiles",
          !maximized && "profiles--compactSize box-section",
          maximized && "profiles--maximizedSize",
        )}
      >
        <DashboardHeader heading={heading} />
        <div className={classNames("profiles__list", !maximized && "box-section--breakout")}>
          <AddDashboardCard maximized={maximized} />
          {profilesArray?.length ? (
            sortAndFilter(profilesArray, query, activeSorter).map((profile) => (
              <DashboardCard key={profile.id} maximized={maximized} profile={profile} />
            ))
          ) : (
            // TODO add nice looking "no profiles found" page
            <Trans id="profile.empty">No dashboards found. Please add one!</Trans>
          )}
        </div>
      </div>
    </FormProvider>
  );
}

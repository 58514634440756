import { SYMBOLCODE_TO_IMAGES } from "@/geojson";
import { MapboxLayerGroup } from "@/layers/utility/mapbox-utils";
import type { ColoringOptions } from "@/reducer/client-models";
import type { PoiLayer } from "@/reducer/client-models/PoiLayer";
import { ConditionOptions } from "@/utility/conditionalExpressions";
import { WEATHER_SYMBOL_PARAM } from "@/weatherTableView/cellsRenderers/DataCellRenderer";
import type { Expression, StyleFunction, SymbolLayout, SymbolPaint } from "mapbox-gl";
import { tryPrettyPrintUnit } from "weather-parameter-utils";
import { TEXT_BACKGROUND_IMG } from "./poi-mapbox-image/poiMapboxImage";

export const thresholdsToPaintProperty = (
  coloringOptions: ColoringOptions | undefined,
  defaultColor: string,
): SymbolPaint => {
  const caseValue: string | StyleFunction | Expression = ["case"];
  if (coloringOptions) {
    if (!coloringOptions.thresholds.length) {
      return {
        "icon-color": defaultColor,
        "text-color": defaultColor,
      };
    }

    for (const threshold of coloringOptions.thresholds) {
      const condition = ConditionOptions[threshold.condition] as string;
      caseValue.push([condition, ["get", "value"], threshold.value]);
      caseValue.push(threshold.color);
    }
    caseValue.push(defaultColor /*["get", "item-color"]*/);
    return {
      "icon-color": caseValue,
      "text-color": caseValue,
    };
  }
  return {
    "icon-color": ["get", "item-color"],
    "text-color": ["get", "item-color"],
  };
};

export const iconLayerLayoutProperties = (props: PoiLayer): SymbolLayout => ({
  visibility: props.show ? "visible" : "none",
  "icon-image": ["get", "item-icon"],
  "icon-size": ["get", "item-icon-size"],
  "icon-allow-overlap": true,
  "icon-ignore-placement": true,
  "text-allow-overlap": true,
  "text-ignore-placement": true,
  "text-field": ["get", "item-text"],
  "text-size": ["get", "item-text-size"],
});

export const iconLayerPaintProperties = (props: PoiLayer): SymbolPaint => ({
  "text-opacity": props.opacity,
  "icon-opacity": props.opacity,
  ...thresholdsToPaintProperty(props.poiOptions.coloringOptions, props.poiOptions.item.color),
});

export const createWeatherSymbolExpression = (): Expression => {
  // Start the match expression with the 'get' command
  const matchExpression: Expression = ["match", ["get", "value"]];
  for (const [key, value] of Object.entries(SYMBOLCODE_TO_IMAGES)) {
    matchExpression.push(Number(key), value);
  }
  // Add a default case in case no keys match
  matchExpression.push("wsymbol_0999_unknown");
  return matchExpression;
};
const DEFAULT_VALUE_LAYER_LAYOUT_PROPERTIES: SymbolLayout = {
  "icon-text-fit": "both",
  "icon-text-fit-padding": [2, 10, 2, 10],
};
export const valueLayerLayoutProperties = (props: PoiLayer): SymbolLayout => {
  const isWeatherSymbol = props.parameter_unit?.startsWith(WEATHER_SYMBOL_PARAM);

  const iconImage: string | StyleFunction | Expression | undefined = isWeatherSymbol
    ? createWeatherSymbolExpression()
    : TEXT_BACKGROUND_IMG;

  const layoutProperties: SymbolLayout = isWeatherSymbol
    ? {
        ...DEFAULT_VALUE_LAYER_LAYOUT_PROPERTIES,
        "icon-size": ["get", "item-icon-size"],
        "icon-allow-overlap": true,
        "icon-ignore-placement": true,
        "symbol-placement": "point",
      }
    : DEFAULT_VALUE_LAYER_LAYOUT_PROPERTIES;

  return {
    "icon-image": iconImage,
    ...layoutProperties,
    ...unitLabelLayout(props),
    ...valueLayerOffset(props),
  };
};

export const valueLayerPaintProperties = (props: PoiLayer): SymbolPaint => {
  return {
    "icon-opacity": [
      "case",
      ["==", ["get", "value"], null], // Check if the value is null
      0, // If value is null, set opacity to 0 (hide both text and icon)
      props.poiOptions.item.labelBackgroundOpacity ?? 1, // Else, set opacity to 1 (show both text and icon)
    ],
    "text-opacity": [
      "case",
      ["==", ["get", "value"], null], // Check if the value is null
      0, // If value is null, set opacity to 0 (hide both text and icon)
      1, // Else, set opacity to 1 (show both text and icon)
    ],
    ...thresholdsToPaintProperty(props.poiOptions.coloringOptions, props.poiOptions.item.color),
  };
};

/**
 * Creates initial mapbox layer specifications for POI layer.
 * @param instanceId
 * @param props
 * @returns
 */
export function poiMapboxLayerGroup(
  instanceId: string,
  props: PoiLayer,
  // TODO: Maybe change this to SourceTracker?
  sourceName: string,
) {
  const group = new MapboxLayerGroup(instanceId, [
    {
      // Main POI layer to show the marker icons and texts
      layerName: "icon-layer",
      type: "symbol",
      source: sourceName,
      paint: iconLayerPaintProperties(props),
      layout: iconLayerLayoutProperties(props),
    },
    {
      // Sub layer to show the value pills when a user adds a parameter.
      // When the parameter is weather_symbol* it shows the weather symbol only
      layerName: "value-layer",
      type: "symbol",
      source: sourceName,
      paint: valueLayerPaintProperties(props),
      layout: valueLayerLayoutProperties(props),
    },
  ]);

  return group;
}

/**
 * Generate a patch of Mapbox layout properties, so we can update the text layout property based on
 * the parameter unit of the MetX layer description.
 */
export function unitLabelLayout(props: Pick<PoiLayer, "parameter_unit">): SymbolLayout {
  const paramUnit = props.parameter_unit; // e.g: t_2m:C
  if (!paramUnit) {
    // Only render the value if no param unit is defined somehow.
    return { "text-field": ["get", "value"] };
  }

  if (paramUnit?.startsWith(WEATHER_SYMBOL_PARAM)) {
    return { "text-field": "" };
  }

  const unitStr = tryPrettyPrintUnit(paramUnit);
  return {
    // This will render "<value><unitStr>" on the Mapbox symbol layer.
    "text-field": ["concat", ["get", "value"], unitStr],
  };
}

/**
 * Generate a patch for Mapbox paint properties for changing label background opacity
 *
 * @param props PoiLayer definition
 * @returns
 */
export function iconOpacityPaint(props: PoiLayer): SymbolPaint {
  return {
    "icon-opacity": [
      "case",
      ["==", ["get", "value"], null], // Check if the value is null
      0, // If value is null, set opacity to 0 (hide both text and icon)
      props.poiOptions.item.labelBackgroundOpacity ?? 1, // Else, set opacity to 1 (show both text and icon)
    ],
  };
}

const _offsetAbove = [0, -2.1];
const _noOffset = [0, 0];
/**
 * Controls the text offset of the value label pill based on the existence of the icon or text on POI.
 * @param props
 * @returns
 */
export function valueLayerOffset(props: PoiLayer): SymbolLayout {
  const valueShouldOffset = props.poiOptions.item.icon !== "" || props.poiOptions.item.text !== "";
  return {
    "text-offset": valueShouldOffset ? _offsetAbove : _noOffset,
  };
}

import { startInactiveSpan } from "@sentry/browser";
import type { Span, StartSpanOptions } from "@sentry/types";

export class SentrySpans {
  private static spans: Record<string, Span> = {};

  get spans(): Record<string, Span> {
    return SentrySpans.spans;
  }

  static getSpan(key: string): Span | undefined {
    return SentrySpans.spans[key];
  }

  static startSpan(key: string, options?: Omit<StartSpanOptions, "name">): void {
    if (!SentrySpans.spans[key]) {
      const spanOptions: StartSpanOptions = { name: key, ...options, forceTransaction: true };
      SentrySpans.spans[key] = startInactiveSpan(spanOptions);
    }
  }

  static setTag(key: string, tag: string, value: string): void {
    const span = SentrySpans.spans[key];
    if (span) {
      span.setAttribute(tag, value);
    }
  }

  static discardLayerSpans(): void {
    const keysToDelete: string[] = [];

    for (const key in SentrySpans.spans) {
      if (key.includes("Layer")) {
        keysToDelete.push(key);
      }
    }

    for (const key of keysToDelete) {
      SentrySpans.discardSpan(key);
    }
  }

  static endSpan(key: string): void {
    const span = SentrySpans.spans[key];
    if (span) {
      span.end();
      delete SentrySpans.spans[key];
    }
  }

  static discardSpan(key: string): void {
    const span = SentrySpans.spans[key];
    if (span) {
      span.end();
      delete SentrySpans.spans[key];
    }
  }
}

import type { TileDataDescription } from "@/cache/SpatioTemporalTileCache/TileDataDescription";
import type { TileGeometry } from "@/cache/SpatioTemporalTileCache/TileGeometry";
import type { AppliedStyle } from "@/layers";
import type { FailureMsgPayload } from "@/threads";
import { formatByteSize } from "@/utility/bytesize";
import { type Area, ColorMap, type EnsSelectIdentifier } from "@mm/api.meteomatics.com";
import { type MeteomaticsApiError, isNonTransferableApiError } from "@mm/api.meteomatics.com/lib/error";
import { isAbortable, isAbortedRequest } from "@mm/api.meteomatics.com/lib/middleware";
import type { CoordinateSystem } from "@mm/api.meteomatics.com/lib/models/CoordinateSystem";
import type { VerticalInterpolationType } from "@mm/metx-workbench.meteomatics.com";
import Logger from "logging";
import {
  type AsyncResult,
  createPendingAsyncResult,
  createPermanentlyFailedAsyncResult,
  createResolvedAsyncResult,
} from "../AsyncResult";
import {
  type CacheDatum,
  CacheDatumState,
  type LoadedCacheDatum,
  type PermanentlyFailedCacheDatum,
} from "../CacheDatumState";
import type { TileFromGetter, TileGetter } from "../PVSTileService/TileGetters/TileGetter";
import { type Stats, defaultCacheFormatter, jsxTable } from "../Stats";
import { type MultilevelLruEvictionResult, ageOfLeaf, mutlilevel_lru_evict_leaf } from "../evictionStrategy";
import { FailureState, NETWORK_EXPONENTIAL_BACKOFF_MAX_RETRIES, type RetryState, createRetryState } from "./RetryState";
import type { SliceDataDescription } from "./SliceDataDescription";
import { type SpatiotemporalTileCacheStats, emptySpatioTemporalTileCacheStats } from "./SpatioTemporalTileCacheStats";
import type { SpatiotemporalTileCache } from "./SpatiotemporalTileCache";
import type { TemporalSubcache } from "./TemporalSubcache";
import type { Tile } from "./Tile";

const logger = Logger.fromFilename(__filename);

type SpatialCacheId = string;

/**
 * The spatio-temporal tile cache sliced to a fixed weather parameter and a fixed time slice. Consequently,
 * only the zoom level and position of the tiles is varying. So, this cache holds a single tile pyramid.
 */
export class SpatialSubcache<
  TTileGetter extends TileGetter<any, any>,
  TTile extends TileFromGetter<TTileGetter> = TileFromGetter<TTileGetter>,
> implements Stats<SpatiotemporalTileCacheStats>
{
  private stats_: SpatiotemporalTileCacheStats = emptySpatioTemporalTileCacheStats();
  private isUnloaded = false;

  /**
   *
   * @param rootCache
   * @param cache
   * @param modelBoundingBox changes the model bounding box of requests. The default bounding box is sometimes incorrect
   * on a per timestep basis. So we retain this information here to avoid unncessary round trips after the first set of
   * failures.
   */
  constructor(
    public readonly rootCache: SpatiotemporalTileCache<TTileGetter, TTile>,
    public readonly parameterCache: TemporalSubcache<TTileGetter, TTile>,
    protected cache: { [key in SpatialCacheId]: CacheDatum<TTile, RetryState> } = {},
    protected modelBoundingBox?: Area<CoordinateSystem.WGS84>,
  ) {}

  stats(): SpatiotemporalTileCacheStats {
    return this.stats_;
  }

  statsJsx(): JSX.Element {
    return jsxTable(this.stats(), defaultCacheFormatter);
  }

  label(): string {
    return "SpatialSubcache";
  }

  descJsx(): JSX.Element {
    return (
      <>
        <p>
          Replacement Policy is LRU. The maximal cache size limit is controlled globally across all spatial subcache
          instances by the root cache.
        </p>
        <p>
          <strong>Note:</strong> The shown statistics are only counted since the last complete eviction of the time
          slice / spatial subcache.
        </p>
      </>
    );
  }

  unload() {
    for (const [tag, v] of Object.entries(this.cache)) {
      this.unloadTile(v, tag);
    }

    // we might dangle for a while because of pending tile requests.
    this.isUnloaded = true;
  }

  private unloadTile(v: CacheDatum<Tile, RetryState>, tag: string) {
    switch (v.state) {
      case CacheDatumState.Pending:
        // TODO: this is a problem. what to do here? we would like to abort
        // the pending request. We should at least make sure the tile is immediately
        // deallocated as soon as it arrives and then let the garbage collector finally
        // evict the time slice
        break;
      case CacheDatumState.PermanentlyFailed:
        // noop
        break;
      case CacheDatumState.Loaded:
        this.adjustStatistic("evicted", +1);
        this.adjustStatistic("count", -1);
        this.adjustStatistic("estimatedMemoryConsumption", -v.payload.memoryConsumptionCpu());
        v.payload.unload();
        delete this.cache[tag];
        break;
      default: {
        const _exhaustive: never = v;
        return _exhaustive;
      }
    }
  }

  private adjustStatistic(stat: Exclude<keyof SpatiotemporalTileCacheStats, "usage">, amountToAdd: number) {
    this.stats_[stat] += amountToAdd; // aggregate for this timeslice of the parameter (only spatial is varying)
    this.parameterCache.stats_[stat] += amountToAdd; // aggregate for all timeslices of the parameter (globally for the parameter)
    this.rootCache.stats_[stat] += amountToAdd; // aggregate globally (over all parameters)
  }

  private retryRetrieveTile(tileDesc: TileDataDescription<any>): AsyncResult<TTile> {
    // TODO: if unloaded, don't retry
    const apiRequest = this.rootCache.conf.tileGetter.createTileApiRequest(tileDesc, this.rootCache.conf.tileSize);
    const cacheId = this.rootCache.conf.tileGetter.getTileCacheId(apiRequest);
    // check if the data is in cache. If it is in the cache and either still pending
    // without errors, already available or in a permanent failure state, return the result.
    //
    // If the tile is not in the cache or if retrieval failed temporarily. (Re)start the request.
    let retry_: RetryState | null = null;
    if (Object.hasOwn(this.cache, cacheId)) {
      const v = this.cache[cacheId];

      switch (v.state) {
        case CacheDatumState.Pending:
          retry_ = v.payload;
          if (retry_.failureState !== FailureState.FailedTemporarily) {
            return createPendingAsyncResult(v.promise);
          }
          // since the current invocation moves the state from [Pending/FailedTemporarily] to [Pending/Queued]
          // mark it as such to prevent concurrent invocations from reentering the remaining function body
          retry_.failureState = undefined;
          break;
        case CacheDatumState.PermanentlyFailed:
          // TODO: store and forward failure reason?
          return createPermanentlyFailedAsyncResult(v.payload);
        case CacheDatumState.Loaded:
          this.markUsed(v);
          return createResolvedAsyncResult(v.payload);
        default: {
          const _exhaustive: never = v;
          return _exhaustive;
        }
      }
    }

    const retry = retry_ ?? createRetryState(this.modelBoundingBox);

    const promise = this.rootCache.conf.tileGetter
      .requestTile(apiRequest, retry)
      .then((tile: TTile) => {
        // TODO: maybe allow eviction of inflight requests and forward similar to abort reasons?
        if (this.isUnloaded) {
          return logger.unreachable("eviction with inflight requests should not occurr");
        }

        const { requestDatetime } = this.cache[cacheId];

        const cacheLine: LoadedCacheDatum<TTile> = {
          requestDatetime,
          lastUsed: Number.NEGATIVE_INFINITY,
          state: CacheDatumState.Loaded,
          payload: tile,
        };
        this.markUsed(cacheLine);

        // reduce cache size __before__ adding the new element
        // TODO: I don't think this is correctly tracked in intermediate caches?

        this.cache[cacheId] = cacheLine;
        this.adjustStatistic("estimatedMemoryConsumption", tile.memoryConsumptionCpu());
        this.adjustStatistic("count", +1);
        this.adjustStatistic("pending", -1);

        this.rootCache._checkCacheSize();

        return tile;
      })
      .catch((e: FailureMsgPayload) => {
        // TODO: maybe allow eviction of inflight requests and forward similar to abort reasons?
        if (this.isUnloaded) {
          return logger.unreachable("eviction with inflight requests should not occurr");
        }

        // mark as no longer pending, we mark the request again as soon as the retry is triggered.
        this.adjustStatistic("pending", -1);

        if (isAbortedRequest(e)) {
          delete this.cache[cacheId];
          this.adjustStatistic("aborted", +1);
          return Promise.reject(e);
        }

        if (isAbortable(e)) {
          // reset state, as if the tile was never requested
          delete this.cache[cacheId];
          this.adjustStatistic("aborted", +1);
          return Promise.reject(e);
        }

        this.adjustStatistic("failed", +1); // accumulated failures for every network interaction
        retry.failedAttempts++; // accumulated failures for the current tile only
        retry.failureState = FailureState.FailedTemporarily;

        const permanentFailure = (e: MeteomaticsApiError) => {
          retry.failureState = FailureState.FailedPermanently;
          this.adjustStatistic("failedPermanently", +1);

          const { requestDatetime } = this.cache[cacheId];

          // TODO: so, the new CacheDatum.PermanentlyFailed seems to make FailureState unnecessary here, could be a boolean now
          const cacheLine: PermanentlyFailedCacheDatum<null> = {
            state: CacheDatumState.PermanentlyFailed,
            requestDatetime,
            payload: null,
          };
          this.cache[cacheId] = cacheLine;

          return Promise.reject(e);
        };

        if (retry.failedAttempts > NETWORK_EXPONENTIAL_BACKOFF_MAX_RETRIES) {
          return permanentFailure(e);
        }

        if (!isNonTransferableApiError(e)) {
          // some error that is neither an abort, nor a API error, so something errored out in our code
          // and we did not account for this case yet.
          logger.error("unexpected error during tile retrieval", e);
          return permanentFailure(e);
        }

        switch (e.kind) {
          case "AuthError":
          case "TimeError":
          case "UnknownParameterError":
          case "UnknownError":
          case "MissingDataError": {
            // TODO: missing data error is like bounds error, but we do not know the actual domain
            // Do nothing for the errors above.
            return permanentFailure(e);
          }
          case "ComputationTimeoutError": {
            // TODO: could reduce gridDataDimension
            // Retry on timeout as the API may give a successful response after several tries.
            return this.retryRetrieveTile(tileDesc).asynchronous;
          }
          case "BoundsError": {
            // retry immediately with decreased bounds
            this.modelBoundingBox = e.available;
            retry.modelBounds = e.available;
            if (retry.failedAttempts === 1) {
              this.adjustStatistic("retried", +1);
            }
            return this.retryRetrieveTile(tileDesc).asynchronous;
          }
          default: {
            const _exhaustive: never = e;
            return _exhaustive;
          }
        }
      });

    this.cache[cacheId] = {
      requestDatetime: performance.now(),
      state: CacheDatumState.Pending,
      promise,
      payload: retry,
    };

    this.adjustStatistic("pending", +1);

    return createPendingAsyncResult(promise);
  }

  private retryRetrieveSlice<TCrs extends CoordinateSystem>(sliceDesc: SliceDataDescription<TCrs>): AsyncResult<TTile> {
    if (!this.rootCache.conf.tileGetter.createDataSliceApiRequest || !this.rootCache.conf.tileGetter.requestDataSlice) {
      return createPermanentlyFailedAsyncResult("createDataSliceApiRequest or requestDataSlice not implemented");
    }
    // TODO: if unloaded, don't retry
    const apiRequest = this.rootCache.conf.tileGetter.createDataSliceApiRequest(sliceDesc);
    const cacheId = this.rootCache.conf.tileGetter.getTileCacheId(apiRequest);
    // check if the data is in cache. If it is in the cache and either still pending
    // without errors, already available or in a permanent failure state, return the result.
    //
    // If the tile is not in the cache or if retrieval failed temporarily. (Re)start the request.
    let retry_: RetryState | null = null;
    if (Object.hasOwn(this.cache, cacheId)) {
      const v = this.cache[cacheId];

      switch (v.state) {
        case CacheDatumState.Pending:
          retry_ = v.payload;
          if (retry_.failureState !== FailureState.FailedTemporarily) {
            return createPendingAsyncResult(v.promise);
          }
          // since the current invocation moves the state from [Pending/FailedTemporarily] to [Pending/Queued]
          // mark it as such to prevent concurrent invocations from reentering the remaining function body
          retry_.failureState = undefined;
          break;
        case CacheDatumState.PermanentlyFailed:
          // TODO: store and forward failure reason?
          return createPermanentlyFailedAsyncResult(v.payload);
        case CacheDatumState.Loaded:
          this.markUsed(v);
          return createResolvedAsyncResult(v.payload);
        default: {
          const _exhaustive: never = v;
          return _exhaustive;
        }
      }
    }

    const retry = retry_ ?? createRetryState(this.modelBoundingBox);

    const promise = this.rootCache.conf.tileGetter
      .requestDataSlice(apiRequest, retry)
      .then((tile: TTile) => {
        // TODO: maybe allow eviction of inflight requests and forward similar to abort reasons?
        if (this.isUnloaded) {
          return logger.unreachable("eviction with inflight requests should not occurr");
        }

        const { requestDatetime } = this.cache[cacheId];

        const cacheLine: LoadedCacheDatum<TTile> = {
          requestDatetime,
          lastUsed: Number.NEGATIVE_INFINITY,
          state: CacheDatumState.Loaded,
          payload: tile,
        };
        this.markUsed(cacheLine);

        // reduce cache size __before__ adding the new element
        // TODO: I don't think this is correctly tracked in intermediate caches?

        this.cache[cacheId] = cacheLine;
        this.adjustStatistic("estimatedMemoryConsumption", tile.memoryConsumptionCpu());
        this.adjustStatistic("count", +1);
        this.adjustStatistic("pending", -1);

        this.rootCache._checkCacheSize();

        return tile;
      })
      .catch((e: FailureMsgPayload) => {
        // TODO: maybe allow eviction of inflight requests and forward similar to abort reasons?
        if (this.isUnloaded) {
          return logger.unreachable("eviction with inflight requests should not occurr");
        }

        // mark as no longer pending, we mark the request again as soon as the retry is triggered.
        this.adjustStatistic("pending", -1);

        if (isAbortedRequest(e)) {
          delete this.cache[cacheId];
          this.adjustStatistic("aborted", +1);
          return Promise.reject(e);
        }

        if (isAbortable(e)) {
          // reset state, as if the tile was never requested
          delete this.cache[cacheId];
          this.adjustStatistic("aborted", +1);
          return Promise.reject(e);
        }

        this.adjustStatistic("failed", +1); // accumulated failures for every network interaction
        retry.failedAttempts++; // accumulated failures for the current tile only
        retry.failureState = FailureState.FailedTemporarily;

        const permanentFailure = (e: MeteomaticsApiError) => {
          retry.failureState = FailureState.FailedPermanently;
          this.adjustStatistic("failedPermanently", +1);

          const { requestDatetime } = this.cache[cacheId];

          // TODO: so, the new CacheDatum.PermanentlyFailed seems to make FailureState unnecessary here, could be a boolean now
          const cacheLine: PermanentlyFailedCacheDatum<null> = {
            state: CacheDatumState.PermanentlyFailed,
            requestDatetime,
            payload: null,
          };
          this.cache[cacheId] = cacheLine;

          return Promise.reject(e);
        };

        if (retry.failedAttempts > NETWORK_EXPONENTIAL_BACKOFF_MAX_RETRIES) {
          return permanentFailure(e);
        }

        if (!isNonTransferableApiError(e)) {
          // some error that is neither an abort, nor a API error, so something errored out in our code
          // and we did not account for this case yet.
          logger.error("unexpected error during tile retrieval", e);
          return permanentFailure(e);
        }

        switch (e.kind) {
          case "AuthError":
          case "TimeError":
          case "UnknownParameterError":
          case "UnknownError":
          case "MissingDataError": {
            // TODO: missing data error is like bounds error, but we do not know the actual domain
            // Do nothing for the errors above.
            return permanentFailure(e);
          }
          case "ComputationTimeoutError": {
            // TODO: could reduce gridDataDimension
            // Retry on timeout as the API may give a successful response after several tries.
            return this.retryRetrieveSlice(sliceDesc).asynchronous;
          }
          case "BoundsError": {
            // retry immediately with decreased bounds
            this.modelBoundingBox = e.available;
            retry.modelBounds = e.available;
            if (retry.failedAttempts === 1) {
              this.adjustStatistic("retried", +1);
            }
            return this.retryRetrieveSlice(sliceDesc).asynchronous;
          }
          default: {
            const _exhaustive: never = e;
            return _exhaustive;
          }
        }
      });

    this.cache[cacheId] = {
      requestDatetime: performance.now(),
      state: CacheDatumState.Pending,
      promise,
      payload: retry,
    };

    this.adjustStatistic("pending", +1);

    return createPendingAsyncResult(promise);
  }

  /**
   * Retrieve Tile form API by description
   *
   * @param desc description of the tile
   */
  retrieveTile(desc: TileDataDescription): AsyncResult<TTile> {
    const result = this.retryRetrieveTile(desc);
    return result;
  }

  /**
   * Retrieve Slice form API by description
   *
   * @param desc description of the slice
   */
  retrieveSlice<TCrs extends CoordinateSystem>(desc: SliceDataDescription<TCrs>): AsyncResult<TTile> {
    const result = this.retryRetrieveSlice(desc);
    return result;
  }

  estimateCacheLineSize(line: CacheDatum<Tile, RetryState>): number {
    switch (line.state) {
      case CacheDatumState.Pending:
        return 0;
      case CacheDatumState.PermanentlyFailed:
        return 0;
      case CacheDatumState.Loaded:
        return line.payload.memoryConsumptionCpu();
      default: {
        const _exhaustive: never = line;
        return _exhaustive;
      }
    }
  }

  /**
   * Mark the cache line as used to make it less likely to be discarded by the cache policy.
   */
  protected markUsed(cacheLine: LoadedCacheDatum<Tile>) {
    const timestamp = performance.now();
    cacheLine.lastUsed = timestamp;

    // TODO: / Note: this will temporarily increase the usage interval of the cache until
    // the next garbage collection pass, since we might move the LRU element to the MRU
    // position without popping the LRU element (this would require a traversal of the whole
    // range or sorting of all tiles.)
    //
    // Example: we have a single tile in the cache loaded at timestamp A. Thus the usage interval
    // is [LRU=A,MRU=A]. If we now use the tile at timestamp B, we always have to set MRU=B. But if
    // the used tile was the least recently used tile, we would have to set LRU to the new least recently
    // used tile to get [LRU=B,MRU=B], instead of the elongated interval [LRU=A,MRU=B].
    if (this.stats_.usage == null) {
      // we just allocated the first entry on an empty (sub)cache
      this.stats_.usage = ageOfLeaf(timestamp);
    } else {
      this.stats_.usage.mostRecentlyUsed = timestamp;
    }

    if (this.parameterCache.stats_.usage == null) {
      this.parameterCache.stats_.usage = ageOfLeaf(timestamp);
    } else {
      this.parameterCache.stats_.usage.mostRecentlyUsed = timestamp;
    }

    if (this.rootCache.stats_.usage == null) {
      this.rootCache.stats_.usage = ageOfLeaf(timestamp);
    } else {
      this.rootCache.stats_.usage.mostRecentlyUsed = timestamp;
    }
  }

  reduceMemoryUsageBy_lru(
    bytes: number,
    maxLeastRecentlyUsed: number = Number.POSITIVE_INFINITY,
  ): MultilevelLruEvictionResult {
    return mutlilevel_lru_evict_leaf<CacheDatum<TTile, RetryState, null>, string>(
      bytes,
      this.cache,
      this.lastUse.bind(this),
      this.estimateCacheLineSize.bind(this),
      this.unloadTile.bind(this),
      logger,
      formatByteSize,
      maxLeastRecentlyUsed,
    );
  }

  protected lastUse(cacheLine: CacheDatum<Tile, RetryState>): number | null {
    switch (cacheLine.state) {
      case CacheDatumState.PermanentlyFailed:
      case CacheDatumState.Pending:
        return null;
      case CacheDatumState.Loaded:
        return cacheLine.lastUsed;
      default: {
        const _exhaustive: never = cacheLine;
        return _exhaustive;
      }
    }
  }

  /**
   * Create a cache id that uniquely identifies a spatial position in tile space.
   *
   * @param desc
   * @returns
   */
  createCacheId(
    geometry: TileGeometry,
    appliedStyle: AppliedStyle,
    appliedEnsSelect: EnsSelectIdentifier,
    calibrated?: boolean,
    vertical_interpolation?: VerticalInterpolationType,
  ): SpatialCacheId {
    const style = appliedStyle ? ColorMap[appliedStyle] : "Undefined";
    const ensSelect = appliedEnsSelect ? appliedEnsSelect : "";
    const calibratedSelected = calibrated ? "calibrated" : "";
    return geometry.toDataId() + style + ensSelect + calibratedSelected + vertical_interpolation || "";
  }
}

import { createProfile, fetchProfiles, importProfile, removeProfile, updateProfile } from "@/api/hooks/profile";
import { createTab, removeTab } from "@/api/hooks/tab";
import type { SlimProfile } from "@mm/metx-workbench.meteomatics.com";
import { type PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import Logger from "logging";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import type { RootState } from ".";
import { setTabTitle } from "./TabsState";
const logger = Logger.fromFilename(__filename);

export type ProfilesState = {
  profiles: { [id: number]: SlimProfile };
  isLoading: boolean;
  hasError: boolean;
};

const initialState: ProfilesState = {
  profiles: {},
  isLoading: false,
  hasError: false,
} as ProfilesState;

const profileSlice = createSlice({
  name: "profiles",
  initialState: initialState,
  reducers: {
    setProfile(state, action: PayloadAction<{ id: number; profileUpdate: SlimProfile }>) {
      const { profileUpdate, id } = action.payload;

      const profile = state.profiles[id];

      if (!profile) {
        logger.error("Could not find profile with id", id);
        return;
      }

      const updatedProfile = {
        ...profile,
        ...profileUpdate,
      };

      state.profiles[id] = updatedProfile;
    },
    setProfileTitle(state, action: PayloadAction<{ id: number; title: string }>) {
      const { id, title } = action.payload;

      const profile = state.profiles[id];
      if (!profile) {
        console.error("Could not find profile with id", id);
        return;
      }

      profile.title = title;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfiles.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(fetchProfiles.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        const profiles = action.payload;

        // Create a new object for profiles
        const newProfiles: {
          [id: number]: SlimProfile;
        } = {};

        if (profiles) {
          for (const profile of profiles) {
            newProfiles[profile.id] = profile;
          }
        }

        // Assign the new profiles object to state.profiles
        state.profiles = newProfiles;
      })
      .addCase(fetchProfiles.rejected, (state, action) => {
        state.hasError = !!action.error.message;
        state.isLoading = false;
      });

    builder.addCase(importProfile.fulfilled, (state, action) => {
      const profile = action.payload;
      state.profiles[profile.id] = profile;
    });
    builder.addCase(createProfile.fulfilled, (state, action) => {
      const profile = action.payload;
      state.profiles[profile.id] = profile;
    });
    builder.addCase(updateProfile.fulfilled, (state, action) => {
      const profile = action.payload;
      state.profiles[profile.id] = profile;
    });
    builder.addCase(removeProfile.fulfilled, (state, action) => {
      delete state.profiles[action.payload];
    });

    builder.addCase(createTab.fulfilled, (state, action) => {
      const tab = action.payload;
      const profile = state.profiles[tab.id_profile];
      if (!profile) {
        logger.error("Could not find profile with id", tab.id_profile);
        return;
      }
      if (profile.tabs) {
        profile.tabs.push(tab);
      } else {
        profile.tabs = [tab];
      }
    });
    builder.addCase(setTabTitle, (state, action) => {
      const { profileId, id, title } = action.payload;
      const profile = state.profiles[profileId];
      profile.tabs = profile.tabs.map((tab) => {
        if (tab.id === id) {
          return { ...tab, title };
        }
        return tab;
      });
    });
    builder.addCase(removeTab.fulfilled, (state, action) => {
      const { tabId, profileId } = action.payload;
      state.profiles[profileId].tabs = state.profiles[profileId].tabs.filter((tab) => tab.id !== tabId);
    });
  },
});

export function useProfiles() {
  return useSelector(
    createSelector(
      (state: RootState) => state.profile,
      (profileState) => {
        return {
          profiles: profileState.profiles,
          isLoading: profileState.isLoading,
          hasError: profileState.hasError,
        };
      },
    ),
  );
}

export function useProfile(id: number) {
  return useSelector((state: RootState) => {
    return state.profile.profiles[id];
  });
}

export const { setProfile, setProfileTitle } = profileSlice.actions;

export default profileSlice.reducer;

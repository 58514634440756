import { lightningLayerConfig } from "@/constants/layerConfigAttributes";
import { batchGroupBy } from "@/reducer/batchGroupBy";
import { t } from "@lingui/macro";
import type { ModelSchema } from "@mm/api-layers.meteomatics.com";
import type { LightningLayer } from "@mm/metx-workbench.meteomatics.com";
import React from "react";
import { LightningParamSelector } from "../CommonParts/ParamSelector/LightningParamSelector/LightningParamSelector";
import { ModelSelect, OpacityInput } from "../LayerStackWindow/LayerFormElements";
import { FormField, InputFormField } from "../form-field";

type updateLayerProps = {
  onChange: (propsValue: Partial<LightningLayer>, isGrouped?: boolean) => void;
  layer: LightningLayer;
  models: ModelSchema[];
};

export const LightningLayerUpdateForm = (props: updateLayerProps) => {
  return (
    <>
      <ModelSelect models={props.models} layerModelIdentifier={"mix"} change={props.onChange} />
      <FormField label={t`Interval`}>
        <LightningParamSelector
          onSelect={(parameter) => {
            props.onChange({ parameter_unit: parameter });
          }}
          selectedItemKey={props.layer.parameter_unit}
        />
      </FormField>
      <InputFormField
        label={t`Size`}
        type="range"
        onChange={(event) => props.onChange({ text_size: Number(event.target.value) })}
        {...lightningLayerConfig.elementSizeRange}
        value={Number(props.layer.text_size)}
        onMouseDown={() => batchGroupBy.start()}
        onMouseUp={() => batchGroupBy.end()}
      />
      <OpacityInput
        layer={props.layer}
        change={props.onChange}
        onStart={() => batchGroupBy.start()}
        onEnd={() => batchGroupBy.end()}
      />
    </>
  );
};

import { GRID_SIZE } from "@/constants/gridCellLayouts";
import { GridCountryPlot } from "@/grid/GridCountryPlot";
import { GridEmptyOptions } from "@/grid/GridEmptyOptions";
import { GridMap } from "@/grid/GridMap";
import { GridMultiLocationTable } from "@/grid/GridMultiLocationTable/GridMultiLocationTable";
import { GridPlot } from "@/grid/GridPlot";
import { GridSortableElement } from "@/grid/GridSortableElement";
import { useCurrMapInteractionState } from "@/models/map-interaction/mapInteractionStateHooks";
import { isDisabledGrid } from "@/models/map-interaction/mapInteractionStateUtils";
import { useOverlay } from "@/overlay/overlays/default/ViewportOverlayProvider";
import { useAppDispatch } from "@/reducer";
import { useCountryPlots } from "@/reducer/CountryPlotState";
import { useEnergyPlots } from "@/reducer/EnergyPlotsState";
import { switchLayouts, useLayouts } from "@/reducer/LayoutsState";
import { useLocationTables } from "@/reducer/LocationTablesState";
import { useMaps } from "@/reducer/MapsState";
import { useNotes } from "@/reducer/NoteState";
import { usePlots } from "@/reducer/PlotsState";
import { useTab } from "@/reducer/TabsState";
import { useTephigrams } from "@/reducer/TephigramState";
import { useWeatherTables } from "@/reducer/WeatherTablesState";
import type { Plot } from "@/reducer/client-models";
import type { CountryPlotType } from "@/reducer/client-models/CountryPlot";
import { getById, getToolByToolId } from "@/reducer/selectors/AccountSelectors";
import { classNames } from "@/utility/jsx";
import {
  type CartographicMap,
  type EnergyPlot,
  LayoutType,
  type LocationTableSchema,
  type NoteSchema,
  type TephigramSchema,
  type WeatherTableSchema,
} from "@mm/metx-workbench.meteomatics.com";
import { type ComponentType, useCallback } from "react";
import { HighlightType, useActiveGridHighlight } from "./GridCellHighlightProvider";
import { GridEnergyPlot } from "./GridEnergyPlot";
import { GridNote } from "./GridNote/GridNote";
import { GridTephigram } from "./GridTephigram";
import { GridWeatherTable } from "./GridWeatherTable";

interface GridProps {
  mapOverlay: ComponentType<{ map: CartographicMap }>;
  tabId: number;
}

const gridDefinition = {
  display: "grid",
  gridTemplateColumns: `repeat(${GRID_SIZE}, 1fr)`,
  gridTemplateRows: `repeat(${GRID_SIZE}, 1fr)`,
};

export function Grid({ mapOverlay: MapOverlay, tabId }: GridProps) {
  // const { value: showMultiLocationTable } = useFlag("location_table", false);
  const showMultiLocationTable = true;
  const { data: tab, isLoading } = useTab();
  const dispatch = useAppDispatch();
  const { highlighted } = useActiveGridHighlight();
  const { showFooter, showHeader } = useOverlay();
  const plots = usePlots(tabId);
  const weatherTables = useWeatherTables(tabId);
  const locationTables = useLocationTables(tabId);
  const maps = useMaps(tabId);
  const energyPlots = useEnergyPlots(tabId);
  const layouts = useLayouts(tabId);
  const tephigrams = useTephigrams(tabId);
  const countryPlots = useCountryPlots(tabId);
  const notes = useNotes(tabId);

  const changeLayoutPosition = useCallback(
    (sourceId: number, targetId: number) => {
      if (layouts && sourceId !== targetId) {
        dispatch(switchLayouts({ layoutId1: sourceId, layoutId2: targetId, tabId }));
      }
    },
    [layouts, tabId, dispatch],
  );

  const mapInteractionState = useCurrMapInteractionState();
  if (isLoading || !tab) {
    // TODO Show neat loading page here
    return <mm-loader size="xlarge" />;
  }

  return (
    <div className="grid workspace-grid grid--no-gap cover-window" style={gridDefinition}>
      {!!layouts &&
        layouts.map((layout) => {
          const disabledGrid = isDisabledGrid(mapInteractionState, layout);
          if (layout.type === LayoutType.Map && layout.id_tool) {
            const map = getById(layout.id_tool, maps);

            if (map) {
              return (
                <GridSortableElement
                  id={layout.id}
                  disabled={disabledGrid}
                  gridCellLayout={layout.gridCellLayout}
                  className={classNames(
                    "grid__cell__map",
                    // Check if grid cell is positioned on bottom
                    layout.gridCellLayout.gridRowEnd === 97 ? "grid__cell__bottom" : "",
                    // Add class if footer is visible, so map can align controls
                    showFooter ? "footer-visible" : "",
                    highlighted && highlighted.type === HighlightType.MAP && highlighted.id === map.id
                      ? "highlighted"
                      : "",
                  )}
                  key={`map-${map.id}`}
                  onDrop={changeLayoutPosition}
                >
                  <GridMap map={map} MapOverlay={MapOverlay} cellLayout={layout.gridCellLayout} />
                </GridSortableElement>
              );
            }
          }
          if (layout.type === LayoutType.Plot && layout.id_tool) {
            // @ts-ignore
            const plot = getToolByToolId<Plot>(layout.id_tool, plots);
            if (plot) {
              return (
                <GridSortableElement
                  id={layout.id}
                  disabled={disabledGrid}
                  gridCellLayout={layout.gridCellLayout}
                  className={classNames(
                    "grid__cell__plot",
                    layout.gridCellLayout.gridRowStart === 1 && showHeader && "extra-padding-top",
                    layout.gridCellLayout.gridRowEnd === 97 && showFooter && "extra-padding-bottom",
                    highlighted && highlighted.type === HighlightType.PLOT && highlighted.id === plot.id
                      ? "highlighted"
                      : "",
                  )}
                  key={`plot-${plot.id}`}
                  onDrop={changeLayoutPosition}
                >
                  <GridPlot plot={plot} />
                </GridSortableElement>
              );
            }
          }
          if (layout.type === LayoutType.EnergyPlot && layout.id_tool) {
            const energyPlot = getToolByToolId<EnergyPlot>(layout.id_tool, energyPlots);
            if (energyPlot) {
              return (
                <GridSortableElement
                  id={layout.id}
                  disabled={disabledGrid}
                  gridCellLayout={layout.gridCellLayout}
                  className={classNames(
                    "grid__cell__plot",
                    layout.gridCellLayout.gridRowStart === 1 && showHeader && "extra-padding-top", //TODO clarify for remove
                    layout.gridCellLayout.gridRowEnd === 97 && showFooter && "extra-padding-bottom",
                    highlighted && highlighted.type === HighlightType.ENERGY_PLOT && highlighted.id === energyPlot.id
                      ? "highlighted"
                      : "",
                  )}
                  key={`energy-plot-${energyPlot.id}`}
                  onDrop={changeLayoutPosition}
                >
                  <GridEnergyPlot energyPlot={energyPlot} />
                </GridSortableElement>
              );
            }
          }
          if (layout.type === LayoutType.WeatherTable && layout.id_tool && weatherTables) {
            const weatherTable = getToolByToolId<WeatherTableSchema>(layout.id_tool, weatherTables);
            if (weatherTable) {
              return (
                <GridSortableElement
                  id={layout.id}
                  disabled={disabledGrid}
                  gridCellLayout={layout.gridCellLayout}
                  className={classNames(
                    "grid__cell__table",

                    layout.gridCellLayout.gridRowEnd === 97 && "grid__cell__table--bottom",
                    highlighted &&
                      highlighted.type === HighlightType.WEATHER_TABLE &&
                      highlighted.id === weatherTable.id
                      ? "highlighted"
                      : "",
                  )}
                  key={`weather-table-${weatherTable.id}`}
                  onDrop={changeLayoutPosition}
                >
                  <GridWeatherTable weatherTable={weatherTable} />
                </GridSortableElement>
              );
            }
          }
          if (layout.type === LayoutType.LocationTable && layout.id_tool && locationTables && showMultiLocationTable) {
            const locationTable = getToolByToolId<LocationTableSchema>(layout.id_tool, locationTables);
            if (locationTable) {
              return (
                <GridSortableElement
                  id={layout.id}
                  disabled={disabledGrid}
                  gridCellLayout={layout.gridCellLayout}
                  className={classNames(
                    "grid__cell__table",

                    layout.gridCellLayout.gridRowEnd === 97 && "grid__cell__table--bottom",
                    highlighted &&
                      highlighted.type === HighlightType.LOCATION_TABLE &&
                      highlighted.id === locationTable.id
                      ? "highlighted"
                      : "",
                  )}
                  key={`location-table-${locationTable.id}`}
                  onDrop={changeLayoutPosition}
                >
                  <GridMultiLocationTable locationTable={locationTable} />
                </GridSortableElement>
              );
            }
          }
          if (layout.type === LayoutType.Tephigram && layout.id_tool && tephigrams) {
            const tephigram = getToolByToolId<TephigramSchema>(layout.id_tool, tephigrams);
            if (tephigram) {
              return (
                <GridSortableElement
                  id={layout.id}
                  disabled={disabledGrid}
                  gridCellLayout={layout.gridCellLayout}
                  className={classNames(
                    layout.gridCellLayout.gridRowEnd === 97 && showFooter ? "extra-padding-bottom" : "padding-bottom",
                    highlighted && highlighted.type === HighlightType.TEPHIGRAM && highlighted.id === tephigram.id
                      ? "highlighted"
                      : "",
                  )}
                  key={`tephigram-${tephigram.id}`}
                  onDrop={changeLayoutPosition}
                >
                  <GridTephigram tephigram={tephigram} />
                </GridSortableElement>
              );
            }
          }
          if (layout.type === LayoutType.CountryPlot && layout.id_tool && countryPlots) {
            const countryPlot = getToolByToolId<CountryPlotType>(layout.id_tool, countryPlots);
            if (countryPlot) {
              return (
                <GridSortableElement
                  id={layout.id}
                  disabled={disabledGrid}
                  gridCellLayout={layout.gridCellLayout}
                  className={classNames(
                    "grid__cell__plot",
                    layout.gridCellLayout.gridRowStart === 1 && showHeader && "extra-padding-top", //TODO clarify for remove
                    layout.gridCellLayout.gridRowEnd === 97 && showFooter && "extra-padding-bottom",
                    highlighted && highlighted.type === HighlightType.COUNTRY_PLOT && highlighted.id === countryPlot.id
                      ? "highlighted"
                      : "",
                  )}
                  key={`countryPlot-${countryPlot.id}`}
                  onDrop={changeLayoutPosition}
                >
                  <GridCountryPlot countryPlot={countryPlot} />
                </GridSortableElement>
              );
            }
          }
          if (layout.type === LayoutType.Notes && layout.id_tool && notes) {
            const note = getToolByToolId<NoteSchema>(layout.id_tool, notes);
            if (note) {
              return (
                <GridSortableElement
                  id={layout.id}
                  disabled={disabledGrid}
                  gridCellLayout={layout.gridCellLayout}
                  className={classNames(
                    "grid__cell__plot",
                    layout.gridCellLayout.gridRowStart === 1 && showHeader && "extra-padding-top", //TODO clarify for remove
                    layout.gridCellLayout.gridRowEnd === 97 && showFooter && "extra-padding-bottom",
                    highlighted && highlighted.type === HighlightType.NOTE && highlighted.id === note.id
                      ? "highlighted"
                      : "",
                  )}
                  key={`note-${note.id}`}
                  onDrop={changeLayoutPosition}
                >
                  <GridNote note={note} />
                </GridSortableElement>
              );
            }
          }

          return (
            <GridSortableElement
              id={layout.id}
              disabled={disabledGrid}
              gridCellLayout={layout.gridCellLayout}
              className={"grid__cell--empty"}
              key={`${layout.gridCellLayout.gridColumnStart}${layout.gridCellLayout.gridColumnEnd}${layout.gridCellLayout.gridRowStart}${layout.gridCellLayout.gridRowEnd}`}
              onDrop={changeLayoutPosition}
            >
              <GridEmptyOptions layout={layout} tabId={tabId} />
            </GridSortableElement>
          );
        })}
    </div>
  );
}

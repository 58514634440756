import * as weatherParameter from "weather-parameter-utils";
import type { PartialWeatherParameter } from "weather-parameter-utils";
import type {
  GridDimension,
  IsoLinesRequest,
  PointRequest,
  PolygonRequest,
  VectorLayerStyleRequest,
  VectorTileRequestUnion,
  WfsRequest,
} from "../models";
import type { CoordinateSystem } from "../models/CoordinateSystem";
import type { GridRequest } from "../models/GridRequest";
import type { WeatherFrontsRequest } from "../models/WeatherFrontsRequest";
import { concatParameterUnits } from "../utility/apiFormat";
import { MovingAverage } from "./MovingAverage";
import type { PerformanceHint } from "./PerformanceHint";
import type { SampleSequenceId } from "./SampleSequenceId";
import { Timer } from "./Timer";

export interface PerformanceEstimate {
  performanceHint?: PerformanceHint;
  timer: Timer;
}

export interface AutomaticQualityScaling {
  targetRoundtripTimeMillis: number;
}

interface GlobalStatistics {
  /** grid points queued and not yet started */
  countGridPointsWaiting: Record<SampleSequenceId, number>;
  /** grid points that are already in flight and no longer waiting  */
  countGridPointsInFlight: Record<SampleSequenceId, number>;
  countRequestsWaiting: Record<SampleSequenceId, number>;
  countRequestsInFlight: Record<SampleSequenceId, number>;
}

function prefixStat(obj: Record<string, any>, prefix: string) {
  const s: Record<string, any> = {};
  for (const [k, v] of Object.entries(obj)) {
    s[`${prefix} (${k})`] = v;
  }

  return s;
}

export class PerformanceRecorder {
  private sequences: Record<SampleSequenceId, MovingAverage> = {};

  public readonly globalStatistics: GlobalStatistics = {
    countGridPointsWaiting: {},
    countGridPointsInFlight: {},
    countRequestsWaiting: {},
    countRequestsInFlight: {},
  };

  networkTrafficStats(): Record<string, any> {
    return {
      ...prefixStat(this.globalStatistics.countGridPointsWaiting, "countGridPointsWaiting"),
      ...prefixStat(this.globalStatistics.countGridPointsInFlight, "countGridPointsInFlight"),
      ...prefixStat(this.globalStatistics.countRequestsWaiting, "countRequestsWaiting"),
      ...prefixStat(this.globalStatistics.countRequestsInFlight, "countRequestsInFlight"),
    };
  }

  stats(workload = 1): Record<string, any> {
    const s: Record<string, any> = {};
    for (const [seq, v] of Object.entries(this.sequences)) {
      const rtt = v.avg() * workload;
      const stddev = v.stddev() * workload;
      s[seq] = `${rtt.toFixed(2)}±${stddev.toFixed(2)}ms`;
    }

    return s;
  }
  /**
   * Get the estimated roundtrip time for a single point request.
   *
   * The returned `Timer` MUST be finished by either calling `Timer.end()` or `Timer.abort()`.
   */
  estimatePointRequest<C extends CoordinateSystem>(singlePoint: PointRequest<C>): PerformanceEstimate {
    const parameters = concatParameterUnits(singlePoint.parameters);
    //TODO: calc workload => total time steps (Duration / StepDuration) 16 h / 30 min = 32
    // const workload = (Duration / StepDuration) 16 h / 30 min = 32
    return this.estimate(parameters /*workload*/);
  }

  /**
   * Get the estimated roundtrip time for a polygon request.
   *
   * The returned `Timer` MUST be finished by either calling `Timer.end()` or `Timer.abort()`.
   */
  estimatePolygonRequest<C extends CoordinateSystem>(polygon: PolygonRequest<C>): PerformanceEstimate {
    const parameters = concatParameterUnits(polygon.parameters);
    //TODO: calc workload => total time steps (Duration / StepDuration) 16 h / 30 min = 32
    // const workload = (Duration / StepDuration) 16 h / 30 min = 32
    return this.estimate(parameters /*workload*/);
  }

  /**
   * Get the estimated roundtrip time for a grid request.
   *
   * The returned `Timer` MUST be finished by either calling `Timer.end()` or `Timer.abort()`.
   */
  estimateGridRequest<C extends CoordinateSystem>(grid: GridRequest<C>): PerformanceEstimate {
    const parameters = concatParameterUnits(grid.parameters);
    const workload = grid.height * grid.width;
    return this.estimate(parameters, workload);
  }

  estimateVectorStyleRequest(style: VectorLayerStyleRequest): PerformanceEstimate {
    const parameters = concatParameterUnits([style.parameter]);
    const workload = 1; // It's just JSON, so I'm not sure, what workload will be
    return this.estimate(parameters, workload);
  }

  estimateVectorTileRequest<C extends CoordinateSystem>(tile: VectorTileRequestUnion): PerformanceEstimate {
    const parameters = concatParameterUnits([tile.parameter]);
    const workload = 1; // width * height but as it's single tile, this doesn't make any sense
    return this.estimate(parameters, workload);
  }

  /**
   * Get estimated roundtrip time for an iso lines request.
   *
   * The returned `Timer` MUST be finished by either calling `Timer.end()` or `Timer.abort()`.
   */
  estimateIsoLinesRequest(grid: IsoLinesRequest): PerformanceEstimate {
    const parameters = concatParameterUnits([grid.parameter]);
    const workload = grid.height * grid.width;
    return this.estimate(parameters, workload);
  }

  /**
   * Get estimated roundtrip time for an weather fronts request.
   *
   * The returned `Timer` MUST be finished by either calling `Timer.end()` or `Timer.abort()`.
   */
  estimateWeatherFrontsRequest(grid: WeatherFrontsRequest): PerformanceEstimate {
    const parameters = concatParameterUnits([grid.parameter]);
    const workload = grid.height * grid.width;
    return this.estimate(parameters, workload);
  }

  /**
   * Get estimated roundtrip time for an WFS request.
   *
   * The returned `Timer` MUST be finished by either calling `Timer.end()` or `Timer.abort()`.
   */
  estimateWfsRequest<C extends CoordinateSystem>(grid: WfsRequest<C>): PerformanceEstimate {
    const parameters = grid.parameters ? concatParameterUnits(grid.parameters) : grid.model;
    // Roughly calculate the requested area.
    const workload = (grid.area.latMax - grid.area.latMin) * (grid.area.lngMax - grid.area.lngMin);
    return this.estimate(parameters, workload);
  }

  /**
   * Get estimated roundtrip time for a request without any parameters.
   *
   * The returned `Timer` MUST be finished by either calling `Timer.end()` or `Timer.abort()`.
   */
  estimateUnparameterized(url: string): PerformanceEstimate {
    return this.estimate(url);
  }

  /**
   * Get estimated roundtrip time for a request identified by `sequence`.
   *
   * The returned `Timer` MUST be finished by either calling `Timer.end()` or `Timer.abort()`.
   *
   * @param sequence some string that is used to partition requests into groups
   * @param workload the number of samples in the request. Since the computation time grows linearly with amount of samples, this
   *                 allows different grid sizes to have a common estimate
   * @returns
   */
  private estimate(sequence: string, workload = 1): PerformanceEstimate {
    // biome-ignore lint/suspicious/noPrototypeBuiltins: <explanation>
    if (!this.sequences.hasOwnProperty(sequence)) {
      this.sequences[sequence] = new MovingAverage();
    }

    const timer = new Timer(sequence, workload);

    this.addToGlobalStatistics(timer);

    return {
      performanceHint: this.estimateRoundtripTime(sequence, workload),
      timer,
    };
  }

  autoscale(
    sequence_: string | PartialWeatherParameter,
    workload: number,
    opts: AutomaticQualityScaling,
  ): number | null {
    const sequence =
      typeof sequence_ === "string"
        ? sequence_
        : weatherParameter.parameterToString(weatherParameter.autofill(sequence_)).formatted;
    const sequenceHistory = this.sequences[sequence];

    if (!sequenceHistory) {
      return null;
    }

    const roundtripTimeMillisPerGridPoint = sequenceHistory.avg();
    const workloadInBudget = opts.targetRoundtripTimeMillis / roundtripTimeMillisPerGridPoint;
    const scaleFactor = workloadInBudget / workload;
    // console.log("------- ", sequence);
    // console.log(
    //   "rtt(1)=",
    //   roundtripTimeMillisPerGridPoint,
    //   `rtt(${workload})=`,
    //   roundtripTimeMillisPerGridPoint * workload,
    //   "#1d=",
    //   workloadInBudget,
    //   "scale_1d=",
    //   scaleFactor,
    //   "scale_2d=",
    //   Math.sqrt(scaleFactor)
    // );
    return scaleFactor;
  }

  autoscale2D(
    sequence_: string | PartialWeatherParameter,
    gridDimension: GridDimension,
    opts: AutomaticQualityScaling,
  ): number | null {
    const oneDim = this.autoscale(sequence_, gridDimension.width * gridDimension.height, opts);
    if (oneDim === null) {
      return null;
    }

    const twoDim = Math.sqrt(oneDim);
    // console.log(
    //   `autoscale2D(${gridDimension.width},${gridDimension.height}) = (${(gridDimension.width * twoDim).toFixed(2)},${(
    //     gridDimension.height * twoDim
    //   ).toFixed(2)})`
    // );
    return twoDim;
  }

  estimateRoundtripTime(sequence: string, workload = 1): PerformanceHint | undefined {
    // we assume
    // 1.) ping time to the server is neglectable in contrast to the total roundtrip time.
    // 2.) round trip time grows linearly with the number of data points queried (`workload`).
    const estimatedRoundtripTime = this.sequences[sequence].avg() * workload;
    return estimatedRoundtripTime === 0 ? undefined : { estimatedRoundtripTime };
  }

  /**
   * [Internal API] Announce the actual roundtrip time of a previously estimated roundtrip time. This allows
   * the performance recorder to improve its estimates.
   *
   * @param timer a timer returned by `estimate`
   */
  _integrateActual(timer: Timer) {
    // TODO: make sure the timer is integrated with the correct grid size after autoscaling!
    const roundtripTime = timer.duration();
    // we assume
    // 1.) ping time to the server is neglectable in contrast to the total roundtrip time.
    // 2.) round trip time grows linearly with the number of data points queried (`workload`).
    const time = roundtripTime / timer.workload;
    this.sequences[timer.sequence].addSample(time);
    this.removeFromGlobalStatistics(timer);
  }

  /**
   * Abort the timer of a request that did not finish successfully.
   */
  abort(timer: Timer) {
    this.removeFromGlobalStatistics(timer);
  }

  /** [Internal API] removing a timer in any state */
  private removeFromGlobalStatistics(timer: Timer) {
    // biome-ignore lint/suspicious/noPrototypeBuiltins: <explanation>
    if (!this.globalStatistics.countGridPointsWaiting.hasOwnProperty(timer.sequence)) {
      throw Error("cannot remove unknown timer");
    }

    if (timer.isWaiting()) {
      this.globalStatistics.countGridPointsWaiting[timer.sequence] -= timer.workload;
      this.globalStatistics.countRequestsWaiting[timer.sequence]--;
    } else {
      this.globalStatistics.countGridPointsInFlight[timer.sequence] -= timer.workload;
      this.globalStatistics.countRequestsInFlight[timer.sequence]--;
    }
  }

  /** [Internal API] moving a timer from untracked to state <Waiting> */
  private addToGlobalStatistics(timer: Timer) {
    if (!timer.isWaiting()) {
      throw Error("cannot add timer to statistics that is not in state <Waiting>");
    }

    // biome-ignore lint/suspicious/noPrototypeBuiltins: <explanation>
    if (!this.globalStatistics.countGridPointsWaiting.hasOwnProperty(timer.sequence)) {
      this.globalStatistics.countGridPointsInFlight[timer.sequence] = 0;
      this.globalStatistics.countGridPointsWaiting[timer.sequence] = 0;
      this.globalStatistics.countRequestsInFlight[timer.sequence] = 0;
      this.globalStatistics.countRequestsWaiting[timer.sequence] = 0;
    }

    this.globalStatistics.countGridPointsWaiting[timer.sequence] += timer.workload;
    this.globalStatistics.countRequestsWaiting[timer.sequence]++;
  }

  /** [Internal API] moving a timer from state <Waiting> to state <InFlight> */
  _markInFlightInGlobalStatistics(timer: Timer) {
    this.globalStatistics.countGridPointsWaiting[timer.sequence] -= timer.workload;
    this.globalStatistics.countRequestsWaiting[timer.sequence]--;
    this.globalStatistics.countGridPointsInFlight[timer.sequence] += timer.workload;
    this.globalStatistics.countRequestsInFlight[timer.sequence]++;
  }
}

export const performanceRecorder = new PerformanceRecorder();

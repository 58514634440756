import { NestedTable } from "@/overlay/components/CommonParts/tables";
import { Suspense, useMemo } from "react";

import { useMultiLocationColumnDefs } from "@/grid/GridMultiLocationTable/hooks/useMultiLocationColumnDefs";
import { useMultiLocationData } from "@/grid/GridMultiLocationTable/hooks/useMultiLocationData";
import "@/style/aggrid.style.scss";
import type { LocationTableSchema } from "@mm/metx-workbench.meteomatics.com";
import type { GridReadyEvent } from "ag-grid-community";

type MultiLocationTableProps = {
  locationTable: LocationTableSchema;
};

export function GridMultiLocationTable({ locationTable }: MultiLocationTableProps): JSX.Element {
  const tableParameter: string = locationTable.parameter_unit || "t_2m:C";
  const { tableRows, loading } = useMultiLocationData({
    tableModel: locationTable.model,
    tableParameter,
    dateTimeFrom: locationTable.datetime_from,
    dateTimeTo: locationTable.datetime_to,
    resolution: locationTable.resolution,
    groups: locationTable.groups,
  });
  const exampleRow = useMemo(() => tableRows?.[0], [tableRows]);
  const columnDefinitions = useMultiLocationColumnDefs({
    tableParam: tableParameter,
    childRow: exampleRow,
    isColumnGrouped: locationTable.are_columns_grouped,
    tableId: locationTable.id,
  });

  const onGridReady = (params: GridReadyEvent) => {
    // when the table is ready, we open the first column group
    if (columnDefinitions?.[1]?.headerName && locationTable.are_columns_grouped) {
      params.api.setColumnGroupOpened(columnDefinitions[1].headerName, true);
    }
  };
  return (
    <Suspense fallback={<mm-loader size="large" />}>
      {loading ? (
        <mm-loader size="large" data-testid={"location-table-loader"} />
      ) : (
        <div style={{ overflow: "auto" }} className={"extra-padding-top"}>
          <NestedTable
            rows={tableRows || []}
            tableOptions={{ isFloatingHeader: true, isNested: locationTable.are_rows_grouped }}
            columnDefs={columnDefinitions}
            onGridReady={onGridReady}
          />
        </div>
      )}
    </Suspense>
  );
}

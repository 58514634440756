import { Duration } from "luxon";
import {
  type Area,
  ColorMap,
  type ColorMapRequest,
  CoordinateSystem,
  FormatMimeType,
  type GridFormat,
  type GridRequest,
  type IsoLinesRequest,
  IsoLinesRequest_toGridRequest,
  type LightningListRequest,
  LightningListRequest_toGridRequest,
  type PixelGridRequest,
  type PointRequest,
  type PolygonRequest,
  type SinglePointFormat,
  type WfsRequest,
} from "./models";
import type { MetarsRequest, VectorLayerStyleRequest, VectorTileRequestUnion } from "./models";
import type { WindVectorRequest } from "./models";
import type { LegendRequest } from "./models/LegendRequest";
import { concatParameterUnits } from "./utility";

/**
 * Models urls in the meteomatics API format. This API is special as it allows REST GET requests to be executed
 * through POST requests. This is desirable if the URL is constructed programmatically since browsers have pretty low limits for the URL length.
 * In this case the parameters are partitioned as follows:
 *
 * ```
 * URL: https://api.meteomatics.com/<validdatetime>/
 * Post-Body: <parameters>/<location>/<format>?<optionals> in plain text
 * ```
 */
export class MeteomaticsApiUrl {
  constructor(
    readonly pathname: string,
    readonly body?: string,
  ) {}

  /**
   * Build a URL for the Meteomatics WMS server
   *
   * @param request specification of the grid including location, resolution, time.
   * @param color_map Transfer function applied to the response data. The default style is a recommended colormap for the requested parameter.
   * @returns Meteomatics api url transformable to a string using `toString`.
   */
  static forWms<C extends CoordinateSystem>(request: PixelGridRequest<C>) {
    const datetime = request.datetime.toUTC().toISO();
    const parameter = concatParameterUnits(request.parameters);
    const model = request.model;
    const formatMimeType = FormatMimeType.WEBP;

    const initDate = request.initDate ? `&init_date=${request.initDate}` : "";

    // only on webp
    const qualityFactor = formatMimeType === FormatMimeType.WEBP ? "&webp_quality_factor=80" : "";
    const colorMapParameter = request.colorMap != null ? `&STYLES=${ColorMap[request.colorMap]}` : "";
    const ensSelect = request.ensSelect ? `&ens_select=${request.ensSelect}` : "";
    const calibrated = `&calibrated=${!!request.calibrated}`;
    const verticalInterpolation =
      request.vertical_interpolation === "none" ? `&vertical_interpolation=${request.vertical_interpolation}` : "";
    const { crsName, boundingBox } = MeteomaticsApiUrl.formatAreaForWms(request.area);
    const pathname = `wms?VERSION=1.3.0&Time=${datetime}&REQUEST=GetMap&FORMAT=${formatMimeType}${qualityFactor}&LAYERS=${model}:${parameter}&MODEL=${model}&bbox=${boundingBox}${colorMapParameter}${ensSelect}&crs=${crsName}&width=${request.width}&height=${request.height}${calibrated}${verticalInterpolation}${initDate}`;
    const body = "";
    return new MeteomaticsApiUrl(pathname, body);
  }
  static forWindVector<C extends CoordinateSystem>(
    gridFormat: GridFormat,
    request: WindVectorRequest<C>,
  ): MeteomaticsApiUrl {
    //TODO: set all props in url
    const datetime = request.datetime.toUTC().toISO();
    const parameters = concatParameterUnits(request.parameters);

    // const location = MeteomaticsApiUrl.formatAreaWithSamplingStrategyForApi({ ...request });
    const location = ""; // MeteomaticsApiUrl.formatAreaWithSamplingStrategyForApi({ ...request });
    const resolution = `${request.width}x${request.height}`;
    const model = request.model;
    const bbox = MeteomaticsApiUrl.formatAreaWithSamplingStrategyForApi(request); // no width and height
    const options = [`source=${model}`];
    options.push(`bbox=${bbox}`);
    if (request.ensSelect) {
      options.push(`ens_select=${request.ensSelect}`);
    }
    options.push(`calibrated=${!!request.calibrated}`);
    const pathname = `${datetime}`;
    const body = `${request.parameters.join(",")}/${bbox}:${resolution}/json`;
    return new MeteomaticsApiUrl(pathname, body);
  }

  static forVectorLayerStyle(request: VectorLayerStyleRequest) {
    const { parameter, vectorTileData } = request;

    const pathname = `/mvt/${vectorTileData}/${parameter}/style.json`;
    const body = "";

    return new MeteomaticsApiUrl(pathname, body);
  }

  /**
   * Build URL for the Meteomatics Vector Tile server
   *
   * @param request specification of the tile
   * @return Meteomatics api url transformable to a string using `toString`.
   */
  // TODO Vector tiles typize
  static forVectorTile(request: VectorTileRequestUnion) {
    const { datetime, geometry, model, vectorTileData, ensSelect, ...rest } = request;

    const optionalParameters = Object.keys(rest)
      .filter(
        (parameter: string) =>
          rest[parameter as keyof typeof rest] !== undefined &&
          !!rest[parameter as keyof typeof rest]?.toString().length,
      )
      .map((parameter: string) => `${parameter}=${rest[parameter as keyof typeof rest]}`);

    if (request.ensSelect) {
      optionalParameters.push(`ens_select=${request.ensSelect}`);
    }

    const modelParam = model ? `&model=${model}` : "";

    const pathname = `mvt/${vectorTileData}/${request.parameter}/tile/${geometry.z}/${geometry.x}/${
      geometry.y
    }.pbf?datetime=${datetime.toUTC().toISO()}${modelParam}&${optionalParameters.join("&")}`;

    const body = "";

    return new MeteomaticsApiUrl(pathname, body);
  }

  /**
   * Build a URL for WFS GetFeature endpoints. Always this endpoint always returns XML format.
   * @param request
   */
  static forWfs<C extends CoordinateSystem>(request: WfsRequest<C>) {
    const parameters = request.parameters ? concatParameterUnits(request.parameters) : null;
    const datetime = request.datetime?.toUTC().toISO();
    const model = request.model;
    const area = MeteomaticsApiUrl.formatAreaForWfs(request.area);
    const requestType = request.requestType;

    const pathname = `wfs?SERVICE=WFS&VERSION=1.0.0&REQUEST=${requestType}&TYPENAME=${model}${
      parameters ? `&PARAMETERS=${parameters}` : ""
    }&BBOX=${area}${datetime ? `&TIME=${datetime}` : ""}`;
    return new MeteomaticsApiUrl(pathname);
  }

  /**
   * Build a URL for WFS GetCapabilities. Always this endpoint always returns XML format.
   * @param request
   */
  static forWfsCapabilities<C extends CoordinateSystem>(request: WfsRequest<C>) {
    const { requestType } = request;
    const body = `wfs?SERVICE=WFS&VERSION=1.0.0&REQUEST=${requestType}`;
    return new MeteomaticsApiUrl("", body);
  }

  static forAvailableTimeRange(model: string, parameterList: Array<string>): MeteomaticsApiUrl {
    const parameters = concatParameterUnits(parameterList);
    const body = `get_time_range?model=${model}&parameters=${parameters}`;
    return new MeteomaticsApiUrl(body);
  }

  static forInitialTime(model: string, parameterList: Array<string>, validDatetime: Date) {
    const parameters = concatParameterUnits(parameterList);
    const dateIso = validDatetime.toISOString();
    const body = `get_init_date?model=${model}&valid_date=${dateIso}&parameters=${parameters}`;
    return new MeteomaticsApiUrl(body);
  }

  static forPoint<C extends CoordinateSystem>(
    singlePointFormat: SinglePointFormat,
    request: PointRequest<C>,
  ): MeteomaticsApiUrl {
    let datetime = request.startDatetime.toUTC().toISO();
    if (request.duration && request.temporalResolution) {
      datetime = `${datetime + request.duration.toISO()}:${request.temporalResolution.toISO()}`;
    } else {
      // default 7 Days and 1h resolution;
      datetime = `${datetime + Duration.fromISO("P7D").toISO()}:${Duration.fromISO("PT1H").toISO()}`;
    }

    const parameters = concatParameterUnits(request.parameters);
    const model = request.model;
    const location = MeteomaticsApiUrl.formatCoordinatesForApi(request);
    const format = singlePointFormat.format;

    const options = [`source=${model}`];
    options.push(`calibrated=${!!request.requestOptions?.calibrated}`);

    if (request.requestOptions?.on_invalid) {
      options.push(`on_invalid=${request.requestOptions.on_invalid}`);
    }

    if (request.ensSelect) {
      options.push(`ens_select=${request.ensSelect}`);
    }

    const pathname = `${datetime}`;
    const body = `${parameters}/${location}/${format}?${options.join("&")}`;
    return new MeteomaticsApiUrl(pathname, body);
  }

  static forPolygon<C extends CoordinateSystem>(
    singlePointFormat: SinglePointFormat,
    request: PolygonRequest<C>,
  ): MeteomaticsApiUrl {
    let datetime = request.startDatetime.toUTC().toISO();
    if (request.duration && request.temporalResolution) {
      datetime = `${datetime + request.duration.toISO()}:${request.temporalResolution.toISO()}`;
    } else {
      // default 7 Days and 1h resolution;
      datetime = `${datetime + Duration.fromISO("P7D").toISO()}:${Duration.fromISO("PT1H").toISO()}`;
    }

    const parameters = concatParameterUnits(request.parameters);
    const model = request.model;
    const polygonString = MeteomaticsApiUrl.formatPolygonForApi(request);
    const format = singlePointFormat.format;

    const options = [`source=${model}`];
    options.push(`calibrated=${!!request.requestOptions?.calibrated}`);

    if (request.ensSelect) {
      options.push(`ens_select=${request.ensSelect}`);
    }

    const pathname = `${datetime}`;
    const body = `${parameters}/${polygonString}/${format}?${options.join("&")}`;
    return new MeteomaticsApiUrl(pathname, body);
  }

  static forPointAndTime<C extends CoordinateSystem>(
    singlePointFormat: SinglePointFormat,
    request: PointRequest<C>,
  ): MeteomaticsApiUrl {
    // TODO: datetime range
    const datetime = request.startDatetime.toUTC().toISO();

    const parameters = concatParameterUnits(request.parameters);
    const model = request.model;
    const location = MeteomaticsApiUrl.formatCoordinatesForApi(request);
    const format = singlePointFormat.format;

    const options = [`source=${model}`];
    options.push(`calibrated=${!!request.requestOptions?.calibrated}`);
    if (request.ensSelect) {
      options.push(`ens_select=${request.ensSelect}`);
    }

    const pathname = `${datetime}`;
    const body = `${parameters}/${location}/${format}?${options.join("&")}`;
    return new MeteomaticsApiUrl(pathname, body);
  }

  static forGrid<C extends CoordinateSystem>(gridFormat: GridFormat, request: GridRequest<C>): MeteomaticsApiUrl {
    const datetime = request.datetime.toUTC().toISO();
    const parameters = concatParameterUnits(request.parameters);

    const location = MeteomaticsApiUrl.formatAreaWithSamplingStrategyForApi({ ...request });
    const resolution = `${request.width}x${request.height}`;
    const model = request.model;
    const bbox = MeteomaticsApiUrl.formatAreaForGrid(request); // no width and height
    const format = MeteomaticsApiUrl.formatGridFormat(gridFormat);

    const options = [`source=${model}`];
    options.push(`bbox=${bbox}`);
    if (request.ensSelect) {
      options.push(`ens_select=${request.ensSelect}`);
    }
    options.push(`calibrated=${!!request.calibrated}`);

    const pathname = `${datetime}`;
    const body = `${parameters}/${location}${resolution && `:${resolution}`}/${format}?${options.join("&")}`;
    return new MeteomaticsApiUrl(pathname, body);
  }

  static forColorMap(request: ColorMapRequest) {
    const parameter = concatParameterUnits([request.parameter]);
    const colorMapParameter = request.style != null ? `&style=${ColorMap[request.style]}` : "";
    const ensSelect = request.ensSelect !== "" ? `&ens_select=${request.ensSelect}` : "";
    const verticalInterpolation =
      request.vertical_interpolation === "none" ? `&vertical_interpolation=${request.vertical_interpolation}` : "";
    const pathname = `get_colormap?parameter=${parameter}${colorMapParameter}${ensSelect}&format=csv&calibrated=${!!request.calibrated}${verticalInterpolation}`;
    return new MeteomaticsApiUrl(pathname);
  }

  static forIsoLines(request: IsoLinesRequest): MeteomaticsApiUrl {
    const datetime = request.datetime.toUTC().toISO();
    const parameter = request.parameter;
    const model = request.model;
    const bbox = MeteomaticsApiUrl.formatAreaForGrid(IsoLinesRequest_toGridRequest(request));
    const pathname = request.path;
    let body = `?datetime=${datetime}&parameter=${parameter}&model=${model}&bbox=${bbox}`;
    body += `&height=${request.height}&width=${request.width}`;
    if (request.isolineRange) {
      body += `&isoline_range=${request.isolineRange}`;
    }
    if (request.isolineValues) {
      body += `&isoline_values=${request.isolineValues}`;
    }
    if (request.radiusMedianFilter) {
      body += `&radius_median_filter=${request.radiusMedianFilter}`;
    }
    if (request.radiusGaussianFilter) {
      body += `&radius_gaussian_filter=${request.radiusGaussianFilter}`;
    }
    body += `&calibrated=${!!request.calibrated}`;
    if (request.ensSelect) {
      body += `&ens_select=${request.ensSelect}`;
    }
    //TODO: possible to add ensSelect= ? ask api team
    return new MeteomaticsApiUrl(pathname, body);
  }

  static forWeatherFronts(request: IsoLinesRequest): MeteomaticsApiUrl {
    const datetime = request.datetime.toUTC().toISO();
    const model = request.model;
    const bbox = MeteomaticsApiUrl.formatAreaForGrid(IsoLinesRequest_toGridRequest(request));
    const pathname = request.path;
    let body = `?datetime=${datetime}&model=${model}&bbox=${bbox}`;
    body += `&calibrated=${!!request.calibrated}`;
    if (request.ensSelect) {
      body += `&ens_select=${request.ensSelect}`;
    }
    return new MeteomaticsApiUrl(pathname, body);
  }

  static forLightningList<C extends CoordinateSystem>(request: LightningListRequest<C>): MeteomaticsApiUrl {
    const datetime = request.datetime.toUTC().toISO();
    const duration = request.timeDuration.toISO();
    const bbox = MeteomaticsApiUrl.formatAreaWithSamplingStrategyForApi(LightningListRequest_toGridRequest(request));
    const pathname = "get_lightning_list";
    const body = `?time_range=${datetime}${duration}&bounding_box=${bbox}&format=json`;
    return new MeteomaticsApiUrl(pathname, body);
  }

  static forMetars({ icao, startDateTime, endDateTime }: MetarsRequest): MeteomaticsApiUrl {
    const pathname = `get_metars?time_range=${startDateTime.toISO()}--${endDateTime.toISO()}&icao_code=${icao}`;
    return new MeteomaticsApiUrl(pathname);
  }

  static forLegend(request: LegendRequest): MeteomaticsApiUrl {
    let pathname = `wms?VERSION=1.3.0&REQUEST=GetLegendGraphic&FORMAT=image/svg+xml&LAYER=${request.parameter}&TRANSPARENT=${request.transparent}`;
    if (request.style) {
      pathname += `&STYLE=${request.style}`;
    }
    return new MeteomaticsApiUrl(pathname);
  }

  private static formatGridFormat(format: GridFormat): string {
    if ("colorMap" in format && format.colorMap != null) {
      return `${format.format}_${ColorMap[format.colorMap]}`;
    }

    return format.format;
  }

  /**
   * Format lat for the API.
   * Clamp values between [-90 and 90]
   *
   * @param lat
   * @private
   */
  private static formatLat(lat: number) {
    if (lat < -90) {
      return -90;
    }
    if (lat > 90) {
      return 90;
    }
    return lat.toFixed(8);
  }

  /**
   * Format lng value for the API.
   *
   * Clamp values between [-180 and 180]
   *
   * @param lng
   * @private
   */
  private static formatLng(lng: number) {
    if (lng < -180) {
      return -180;
    }
    if (lng > 180) {
      return 180;
    }
    return lng.toFixed(8);
  }

  private static formatCoordinatesForApi<C extends CoordinateSystem>(request: PointRequest<C>) {
    const coordinates = request.coordinates.map((coords) => coords.project(CoordinateSystem.WGS84));

    return coordinates
      .map((coord) => {
        return `${coord.lat},${coord.lng}`;
      })
      .join("+");
  }

  private static formatPolygonForApi<C extends CoordinateSystem>(request: PolygonRequest<C>) {
    const positionsList = request.polygons.map((polygon) => polygon.project(CoordinateSystem.WGS84));
    return positionsList
      .map((positions) => {
        const coords = positions.positions
          .map(([lng, lat]) => {
            return `${lat},${lng}`;
          })
          .join("_");
        return `${coords}:${request.aggregate}`;
      })
      .join("+");
  }

  public static formatAreaForWfs<C extends CoordinateSystem>(area: Area<C>) {
    const latMax = MeteomaticsApiUrl.formatLat(area.latMax);
    const latMin = MeteomaticsApiUrl.formatLat(area.latMin);
    const lngMax = MeteomaticsApiUrl.formatLng(area.lngMax);
    const lngMin = MeteomaticsApiUrl.formatLng(area.lngMin);
    return `${lngMin},${latMin},${lngMax},${latMax}`;
  }

  private static formatAreaWithSamplingStrategyForApi<C extends CoordinateSystem>(request: GridRequest<C>) {
    const area = request.area.project(CoordinateSystem.WGS84).applySamplingStrategy(request.sampling, request);
    const latMax = MeteomaticsApiUrl.formatLat(area.latMax);
    const latMin = MeteomaticsApiUrl.formatLat(area.latMin);
    const lngMax = MeteomaticsApiUrl.formatLng(area.lngMax);
    const lngMin = MeteomaticsApiUrl.formatLng(area.lngMin);
    return `${latMax},${lngMin}_${latMin},${lngMax}`;
  }

  private static formatAreaForGrid<C extends CoordinateSystem>(request: GridRequest<C>) {
    const area = request.area.project(CoordinateSystem.WGS84).applySamplingStrategy(request.sampling, request);
    const latMax = MeteomaticsApiUrl.formatLat(area.latMax);
    const latMin = MeteomaticsApiUrl.formatLat(area.latMin);
    const lngMax = MeteomaticsApiUrl.formatLng(area.lngMax);
    const lngMin = MeteomaticsApiUrl.formatLng(area.lngMin);
    return `${lngMin},${latMin},${lngMax},${latMax}`;
  }

  private static formatAreaForWms<C extends CoordinateSystem>(area: Area<C>): { crsName: string; boundingBox: string } {
    const latMax = area.latMax; //this.formatLat(area.latMax);
    const latMin = area.latMin; //this.formatLat(area.latMin);
    const lngMax = area.lngMax; //this.formatLng(area.lngMax);
    const lngMin = area.lngMin; //this.formatLng(area.lngMin);
    switch (area.crs) {
      case CoordinateSystem.WGS84:
        return {
          crsName: "EPSG:4326",
          boundingBox: [latMin, lngMin, latMax, lngMax].join(","),
        };
      case CoordinateSystem.EPSG3857:
        return {
          crsName: "EPSG:3857",
          boundingBox: [lngMin, latMin, lngMax, latMax].join(","),
        };
      case CoordinateSystem.EPSG4326:
        return {
          crsName: "EPSG:4326",
          boundingBox: [latMin, lngMin, latMax, lngMax].join(","),
        };
      default:
        // TODO: `never` does not work here because of the generic
        throw Error("entered unreachable code region");
    }
  }

  /**
   * Format the URL as a string. This URL can be used to make a GET request to the API.
   */
  toString() {
    return this.body ? `${this.pathname}/${this.body}` : this.pathname;
  }
}

import type { ExactMatchResult } from "../search-engines/WeatherParamSearchEngine/model/SearchResults";
import { BASE_PARAMETER_FORMAT_REGEX } from "./transform";
import { isParameterRange, unsafeGetParameterField } from "./typecheck";

import Logger from "logging";
const logger = Logger.fromFilename(__filename);

/**
 * Given some search results for some user input, compute a typeahead by filtering parameter possibilities
 * to the given user input, e.g. `t_1` will cull all <level> values not starting with `1`.
 *
 * Note: In contrast to the actual search, you will most likely want to run this in the GUI thread to avoid lag between
 * prediction updates and user updates. This function should take less than 1 millisecond to execute.
 *
 * @param searchResults search results matching the current user input. The easiest way to obtain this object is through
 *                      the `SearchEngine`.
 */
export function computeTypeahead(searchResults: ExactMatchResult[] | null): string | null {
  if (searchResults == null || searchResults.length === 0) {
    // no prediction possible
    return null;
  }

  // Note: both arrays are currently not scored or sorted, so this is `best` by chance.
  const bestMatch = searchResults[0].matchingLayers[0];
  const bestMatchSpec = searchResults[0].layerGroup.layers[bestMatch.layerIdx];

  let currentSubMatch = 0;

  let firstCompletelyMissingSubMatch = 0;

  // guranteed to terminate since arrays have limited size, and the first out of bounds index will
  // return undefined
  while (bestMatch.match[firstCompletelyMissingSubMatch] !== undefined) {
    firstCompletelyMissingSubMatch++;
  }

  const typeahead = bestMatchSpec.format.replace(
    BASE_PARAMETER_FORMAT_REGEX,
    (_match, fixedSubstring: string | undefined, variableField: string | undefined) => {
      currentSubMatch++;
      if (fixedSubstring !== void 0) {
        return fixedSubstring;
      }
      if (variableField !== void 0) {
        // variableField

        // minus one since the submatch before the first completely missing submatch may only be filled
        // partially
        const isFullyCompleted = currentSubMatch < firstCompletelyMissingSubMatch - 1;

        if (isFullyCompleted) {
          return bestMatch.match[currentSubMatch];
        }

        const isPartiallyCompleted = bestMatch.match[currentSubMatch] !== undefined;

        if (isPartiallyCompleted) {
          const partialCompletionOfUser = bestMatch.match[currentSubMatch];
          // partially completed variable field, try to autocomplete the partial input

          const field = unsafeGetParameterField(bestMatchSpec, variableField);

          // if the value is a range, just assume the user is done. Add unit if still missing.
          if (isParameterRange(field)) {
            if (field.unit != null && !partialCompletionOfUser.includes(field.unit)) {
              return partialCompletionOfUser + field.unit;
            }
            return partialCompletionOfUser;
          }

          for (const validFieldValue of field) {
            if (validFieldValue.startsWith(partialCompletionOfUser)) {
              // take the first match
              return validFieldValue;
            }
          }
          // none of the valid values matched, this might happen if the regular match is only approximate.
          // return what the user typed
          return partialCompletionOfUser;
        }

        // completely missing variable field, return the placeholder
        return `<${variableField}>`;
      }

      // this is a non critical error. return empty prediction instead of crashing the app.
      logger.error("mismatch between post processing and preprocessing. Check BASE_PARAMETER_FORMAT_REGEX.");
      return "";
    },
  );

  return typeahead;
}

/**
 * Base weather parameters annotated with at least one of the given tags will
 * not be visible in Metx2.
 *
 * Set to `null` to disable this feature. This list overwrites `PARAMETERS_ENABLED`.
 */
export const PARAMETERS_DISABLED: string[] | null = ["metx:disable", "docs:hide"];

/**
 * Only base weather parameters annotated with at least one of the given tags will
 * be visible in Metx2.
 *
 * Set to `null` to disable this feature. This list is overwritten by `PARAMETERS_DISABLED`.
 */
export const PARAMETERS_ENABLED: string[] | null = null;

import type { AsyncResult } from "@/cache/AsyncResult";
import { networkCaches } from "@/cache/GlobalCache";
import { createWfsGeoJSON } from "@/geojson/transform";
import type { CheckedProps, LayerBase, PropsChecker } from "@/layers";
import type { ScenePublicApi } from "@/layers/Compositor";
import { generateWfsCacheId } from "@/layers/geojson/LayerUtils";
import { MapboxGeoJSONLayer } from "@/layers/geojson/MapboxGeoJSONLayer";
import { SENTRY_TRANSACTION_NAMES } from "@/sentry/TransactionConst";
import { captureTransaction } from "@/sentry/helpers";
import { applyRoundingFilter, defaultStationAndGridRounding, oneDecimalRounding } from "@/utility/geojsonValueRounding";
import { safeFmt } from "@/utility/safeFmt";
import { searchEnginesWeatherParams } from "@/weather-parameters";
import { getModelBoundingBox } from "@/weather-parameters/lookup";
import { t } from "@lingui/macro";
import {
  Area,
  CoordinateSystem,
  type GeoJSONFeatureCollection,
  type ModelIdentifier,
  type ParameterUnit,
  type WfsRequest,
} from "@mm/api.meteomatics.com";
import type { GuiTimeZone, StationLayer } from "@mm/metx-workbench.meteomatics.com";
import Logger from "logging";
import type { DateTime } from "luxon";
import mapboxgl from "mapbox-gl";
import type { HasLayerIdxNarrowing, PartialWeatherParameter } from "weather-parameter-utils";

const logger = Logger.fromFilename(__filename);

/**
 * A function to transform the parameter unit name in WFS GeoJSON response to a proper format.
 */
function convertWFSParamUnitToParamUnit(parameter_unit: string) {
  // Due to the XMl formatting and WFS specification, GeoJSON retrieved from
  // the WFS interface replaces the last ":" in the parameter_unit with "_".
  // e.g) (1) WFS -> t_2m_C    (2) Usually -> t_2m:C
  // So we need to convert (1) to (2)
  return parameter_unit.replace(/:(?!.*:)/, "_");
}

/**
 * A function to transform the parameter unit name to a the WFS's parameter unit format.
 */
function convertParamUnitToWFSParamUnit(parameter_unit: string) {
  // e.g) (1) WFS -> t_2m_C    (2) Usually -> t_2m:C
  // So we need to convert (2) to (1)
  return parameter_unit.replace(":", "_");
}

/**
 * A wrapper function to pass filtering attribute to createWfsGeoJSON.
 * @param parameterUnit
 * @returns A callback for GeoJSONCache.
 */
function createGeoJSONFormatter(parameterUnit: string) {
  const filteringAttr = convertParamUnitToWFSParamUnit(parameterUnit);
  return (geoJson: GeoJSONFeatureCollection) => {
    const data = createWfsGeoJSON(geoJson, [filteringAttr]);
    const newFeatures = data.features.map((feature) => {
      if (feature.properties?.[filteringAttr]) {
        const newProperties = feature.properties;
        const value = feature.properties[filteringAttr];
        newProperties[filteringAttr] = applyRoundingFilter(parameterUnit, value, defaultStationAndGridRounding);
        newProperties.decimalValue = oneDecimalRounding(value);
        return { ...feature, properties: { ...newProperties } };
      }
      return feature;
    });
    return { ...data, features: newFeatures };
  };
}

function getParamDisplayName(paramUnit: ParameterUnit, modelIdentifier: ModelIdentifier) {
  let param: HasLayerIdxNarrowing<PartialWeatherParameter> | null;
  if (modelIdentifier === "mix-obs") {
    param = searchEnginesWeatherParams.stationObs.matchSomeParameterNameExactly(paramUnit);
  } else if (modelIdentifier === "mm-mos") {
    param = searchEnginesWeatherParams.stationMos.matchSomeParameterNameExactly(paramUnit);
  } else {
    logger.error(`StationLayer should not receive models other than mix-obs and mm-mos. Model:${modelIdentifier}`);
    param = searchEnginesWeatherParams.standard.matchSomeParameterNameExactly(paramUnit);
  }
  return param ? param.parameter.description_en : "";
}

export class StationLayerImpl extends MapboxGeoJSONLayer<StationLayer> {
  private popup = new mapboxgl.Popup();
  /**
   * Mapbox GeoJSON Feature object that a user clicked on.
   */
  private selectedFeature: mapboxgl.MapboxGeoJSONFeature | null = null;
  constructor(id: number, props: StationLayer, scene: ScenePublicApi, timezone: GuiTimeZone) {
    super(id, props, scene, timezone);
    this.updateMapboxLayoutProperties({
      visibility: this.props.show ? "visible" : "none",
      "text-field": [
        "case",
        // Check if the value exists, if true
        ["has", convertWFSParamUnitToParamUnit(props.parameter_unit)],
        ["get", convertWFSParamUnitToParamUnit(props.parameter_unit)],
        // If the value doesn't exist, render am empty string.
        "",
      ],
      "text-keep-upright": false,
      "text-size": this.props.text_size,
      "text-allow-overlap": false, // This could be true? Change it based on user feedback.
    });
    this.updateMapboxPaintProperties({
      "text-color": this.props.text_color,
      "text-opacity": this.props.opacity,
      // "text-halo-color": `rgba(256, 256, 256,  ${this.props.opacity})`,
      // "text-halo-width": 1,
    });
    this.setEventListeners();
  }

  setEventListeners() {
    const mapBoxMap = this.scene.getMapboxMap();

    function createPopupHTML(
      stationFeature: mapboxgl.MapboxGeoJSONFeature,
      paramDisplayName: string,
      parameterUnit: string,
    ) {
      const stationProperties = stationFeature.properties;
      if (stationFeature.geometry.type === "Point" && stationProperties) {
        return `
            <div class="station-meta" style="padding-right:0.3rem; padding-left:10px">
              <div style="font-weight:bold;font-size:15px">${stationProperties.name}</div>
              <hr>
              <div> ${t`Station Info`}</div>
              <div>WMO_ID: ${stationProperties.WMO_ID || t`No Information`}</div>
              <div>
                ${stationProperties.alternative_ID ? `${t`Alternative ID`}: ${stationProperties.alternative_ID}` : ""}
              </div>
              <div>Elevation: ${stationProperties.elevation || t`No Information`}</div>
              <div>
                ${paramDisplayName}: ${stationProperties.decimalValue || t`No Information`}
              </div>
            </div>
          `;
      }
      return "";
    }

    // Add a popup window on click.
    mapBoxMap.on("click", this.mapbox_id, (e) => {
      // TODO-StationFeature:
      // Replace the popup here and migrate it to PlotWindow. Instead, we can dispatch an action here
      // to Redux store to store the clicked station. Then, PlotWindow can react to it, which, in turn, fetches the station data.
      if (!e.features) {
        return;
      }
      const stationFeature = e.features[0];
      this.selectedFeature = stationFeature;
      const popupHTML = createPopupHTML(
        stationFeature,
        getParamDisplayName(this.props.parameter_unit, this.props.model),
        this.props.parameter_unit,
      );

      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      if (stationFeature.geometry.type === "Point") {
        const coordinates = stationFeature.geometry.coordinates;
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }
        this.popup.setLngLat(new mapboxgl.LngLat(coordinates[0], coordinates[1])).setHTML(popupHTML).addTo(mapBoxMap);
        return;
      }
      logger.error(
        "The clicked station feature did not contain 'Point' type geometry, instead contained:",
        stationFeature.geometry.type,
        stationFeature,
      );
    });

    // Update the popup content on every source update.
    mapBoxMap.on("sourcedata", (e) => {
      // TODO-StationFeature:
      // For now, we close the popup on data source update, but once we migrate the popup to PlotWindow,
      // we can just dispatch an action here to fire data update on the plot. We probably don't need to send station data from here,
      // but just to signal the update because station ID should already be in Redux.
      this.popup.remove();
      // e.g)
      // dispatchToRedux({type: "Test Action">})
    });
  }

  humanReadableId(): string {
    return safeFmt`metx.stationlayer#${this.uid}#${this.props.model}.${this.props.parameter_unit}`;
  }

  checker(): PropsChecker<StationLayer, LayerBase<StationLayer>> {
    type CheckerProps = CheckedProps<StationLayer>;
    const checkLayerProp = (property: CheckerProps) => (prev: any, curr: any) => {
      const changed = prev[property] !== curr[property];
      if (changed) {
        this.setLayerProps({ [property]: curr[property] });
        switch (property) {
          case "model":
            this.beforeRender();
            break;
          case "show":
            this.updateMapboxLayoutProperties({ visibility: curr.show ? "visible" : "none" });
            break;
          case "opacity":
            this.updateMapboxPaintProperties({
              "text-opacity": curr[property],
            });
            break;
          case "calibrated":
            break;
          case "parameter_unit":
            this.updateMapboxLayoutProperties({
              "text-field": ["get", curr[property]],
            });
            this.beforeRender();
            break;
          case "ens_select":
            this.beforeRender();
            break;
          case "text_size":
            this.updateMapboxLayoutProperties({ "text-size": curr[property] });
            break;
          case "text_color":
            this.updateMapboxPaintProperties({
              "text-color": curr[property],
            });
            break;
          case "custom_options":
            break;
          default: {
            const _exhaustive: never = property;
            return _exhaustive;
          }
        }
      }
      return changed;
    };
    return {
      model: checkLayerProp("model"),
      opacity: checkLayerProp("opacity"),
      calibrated: checkLayerProp("calibrated"),
      parameter_unit: checkLayerProp("parameter_unit"),
      show: checkLayerProp("show"),
      text_size: checkLayerProp("text_size"),
      text_color: checkLayerProp("text_color"),
      ens_select: checkLayerProp("ens_select"),
      custom_options: checkLayerProp("custom_options"),
    };
  }

  createRequest(dateTimeWithOffset: DateTime): WfsRequest<CoordinateSystem.WGS84> {
    // Currently we fetch the station data for the entire world because GeoJSON cache cache based on the bounding box.
    // This means, if we move the viewport, we'd fetch the data that already exists.
    // To speed up the loading for smaller grid, we should probably split up the grids and fetch only the data we need.
    const area = new Area(CoordinateSystem.WGS84, {
      east: 180,
      west: -180,
      north: 90,
      south: -90,
    });
    // Currently, the station models at the WFS endpoint are formatted differently than the ones on the regular endpoint.
    // e.g) The regular endpoint uses "mix-obs" but the WFS interface TYPENAME uses "stations_mix-obs"
    // So we add the stations prefix for every station model.
    return {
      requestType: "GetFeature",
      area: area,
      parameters: [this.props.parameter_unit],
      model: `stations_${this.props.model}`,
      boundingBoxLimit: getModelBoundingBox(this.props.model),
      datetime: dateTimeWithOffset,
    };
  }
  beforeRender(): void {
    if (this.scene.isAnimation()) {
      this.updateTrigger();
    } else {
      this.debouncedUpdateTrigger();
    }
  }

  updateTrigger(): void {
    const dateTimeWithOffset = this.scene.getDisplayTimeWithOffset();
    const request = this.createRequest(dateTimeWithOffset);

    const result = networkCaches.geojson_cache.retrieveWfs(request, createGeoJSONFormatter(this.props.parameter_unit));
    this.updateSource(result, dateTimeWithOffset);
  }
  fetchData(timeFrame: DateTime): Promise<void> {
    const dateTimeWithOffset = this.scene.getDateTimeWithOffset(timeFrame);
    const request = this.createRequest(dateTimeWithOffset);
    const tags = {
      layerKind: `${this.props.kind ? this.props.kind : "undefined"}`,
      parameter: `${this.props.parameter_unit}`,
      requestedDate: `${dateTimeWithOffset}`,
    };

    const { asynchronous } = networkCaches.geojson_cache.retrieveWfs(
      request,
      createGeoJSONFormatter(this.props.parameter_unit),
    );

    if (!this.scene.isAnimation()) {
      return captureTransaction(asynchronous, SENTRY_TRANSACTION_NAMES.STATION_LAYER_LOADING_SPEED, tags);
    }

    return asynchronous
      .then(() => {
        return Promise.resolve();
      })
      .catch((err) => {
        return Promise.resolve();
      });
  }

  getActiveWeatherParametersAsString() {
    return [
      {
        model: this.props.model,
        parameter: this.props.parameter_unit,
      },
    ];
  }

  peekLayerCache(request: WfsRequest<CoordinateSystem.WGS84>): AsyncResult<GeoJSONFeatureCollection> {
    const cacheId = generateWfsCacheId(request);
    return networkCaches.geojson_cache.peekCache(cacheId);
  }
}

import { Api } from "@/api/Api";
import type { LayerCreateUnion } from "@/layers";
import type { CartographicMap } from "@mm/metx-workbench.meteomatics.com";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const createLayer = createAsyncThunk(
  "api/layer/create",
  async (payload: { mapId: CartographicMap["id"]; data: LayerCreateUnion; profileId: number }, _thunkAPI) => {
    return await Api.layer
      .v2CreateMapLayer({
        mapId: payload.mapId,
        profileId: payload.profileId,
        symbolLayerCreateIsoLinesLayerCreateWmsLayerCreateBarbsLayerCreateGridLayerCreateStationLayerCreateBackgroundMapLayerCreateGenericPoiLayerCreateAviationLayerCreatePressureSystemLayerCreateLightningLayerCreateTropicalCycloneLayerCreateWeatherFrontsLayerCreateWindAnimationLayerCreateCustomGeoJSONLayerCreate:
          payload.data,
      })
      .then((layer) => {
        return layer;
      });
  },
);

export const createMultipleLayers = createAsyncThunk(
  "api/layer/create-multi",
  async (payload: { mapId: CartographicMap["id"]; data: LayerCreateUnion[]; profileId: number }, _thunkAPI) => {
    return await Api.layer
      .v2CreateMapLayers({
        mapId: payload.mapId,
        profileId: payload.profileId,
        symbolLayerCreateIsoLinesLayerCreateWmsLayerCreateBarbsLayerCreateGridLayerCreateStationLayerCreateBackgroundMapLayerCreateGenericPoiLayerCreateAviationLayerCreatePressureSystemLayerCreateLightningLayerCreateTropicalCycloneLayerCreateWeatherFrontsLayerCreateWindAnimationLayerCreateCustomGeoJSONLayerCreate:
          payload.data,
      })
      .then((layers) => {
        return layers;
      });
  },
);

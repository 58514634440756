type StandardEnum<T> = {
  [id: string]: T | string;
  [nu: number]: string;
};

/**
 * Get the variant names of an enum as an array
 */
export function enumKeys<K, T extends StandardEnum<K>>(e: T): (keyof T)[] {
  return Object.keys(e).filter((item) => {
    // biome-ignore lint/suspicious/noGlobalIsNan: Good isNan
    return isNaN(Number(item));
  }) as (keyof T)[];
}

export function enumValues<K, T extends StandardEnum<K>, G extends keyof T = keyof T>(e: T): T[G][] {
  const keys = enumKeys(e);
  return keys.map((k) => e[k]) as any;
}

import { updateProfile } from "@/api/hooks/profile";
import { createTab, fetchTab, removeTab, updateTabOrders } from "@/api/hooks/tab";
import { newEmptyTab } from "@/constants/create";
import { useProfileAndTabParams } from "@/hooks";
import { useAppDispatch } from "@/reducer";
import { setTabOrder, setTabTitle } from "@/reducer/TabsState";
import { PopupPlotContext, togglePlotPopup } from "@/reducer/plot";
import { navigateToTab } from "@/route";
import { t } from "@lingui/macro";
import type { SlimProfile, TabUpdate } from "@mm/metx-workbench.meteomatics.com";
import { useContext, useEffect, useMemo, useRef } from "react";
import { useRouteMatch } from "react-router-dom";
import { WindowKind, instanceId, useDesktop } from "..";
import { findNearestTab } from "./utils";

import type { TabItem } from "@mm/zeus";
import { MmTabList } from "@mm/zeus-react";
import { getSortedTabs } from "./utils";

// TODO: Migrate the style.scss to style.module.scss
import "./style.scss";

export function TabSwitcher({ profile }: { profile: SlimProfile }) {
  const desktop = useDesktop();
  const { tabId } = useProfileAndTabParams();

  const { dispatch: plotDispatch } = useContext(PopupPlotContext);
  const match = useRouteMatch();
  const dispatch = useAppDispatch();
  const tabListRef = useRef<HTMLMmTabListElement>(null);

  useEffect(() => {
    dispatch(
      updateProfile({
        profileId: profile.id,
        data: { id: profile.id, tab_active: tabId },
      }),
    );
  }, [dispatch, profile.id, tabId]);

  const handleTabSelect = (newTabIdString: string) => {
    const newTabId: number = Number.parseInt(newTabIdString, 10);
    if (tabId === newTabId) {
      return;
    }
    plotDispatch(togglePlotPopup(false));
    dispatch(fetchTab({ tabId: newTabId }));
    navigateToTab(match.path, profile.id, newTabId);
  };

  const handleTabCreate = () => {
    plotDispatch(togglePlotPopup(false));
    dispatch(createTab({ profileId: profile.id, data: newEmptyTab }))
      .unwrap()
      .then((tab) => handleTabSelect(tab.id.toString()))
      .catch((e) => console.error(e));
  };

  const handleRemoveTab = (tabIdString: string) => {
    const selectedTab = profile.tabs.find((tab) => tab.id.toString() === tabIdString);
    if (!selectedTab) return;

    desktop.dispatch({
      type: "confirmationWindow",
      windowId: instanceId(WindowKind.ConfirmationWindow),
      customProps: {
        message: t`Do you want to delete tab "${selectedTab.title}"?`,
        callback: (confirmed: boolean) => {
          if (!confirmed) {
            return;
          }

          if (selectedTab.id === profile.tab_active) {
            const nearestTab = findNearestTab(profile.tabs, selectedTab.id);
            if (nearestTab) {
              dispatch(fetchTab({ tabId: nearestTab.id }));
              dispatch(removeTab({ tabId: selectedTab.id, profileId: profile.id }));
              navigateToTab(match.path, profile.id, nearestTab.id);
            }
          } else {
            dispatch(removeTab({ tabId: selectedTab.id, profileId: profile.id }));
          }
        },
      },
    });
  };

  const handleUpdateTabTitle = (tabIdString: string, newTitle: string) => {
    const tabId: number = Number.parseInt(tabIdString, 10);
    dispatch(setTabTitle({ profileId: profile.id, id: tabId, title: newTitle }));
  };

  const handleTabsReordered = (updatedTabsMap: Map<string, string>) => {
    const reorderedTabs = Array.from(updatedTabsMap.keys())
      .map((tabId, index) => {
        const tab = profile.tabs.find((tab) => tab.id.toString() === tabId);
        if (tab) {
          return {
            id: tab.id,
            id_profile: profile.id,
            order: index + 1,
          };
        }
        return null;
      })
      .filter((tab) => tab !== null) as TabUpdate[];

    dispatch(
      setTabOrder({
        profileId: profile.id,
        id: tabId,
        order: reorderedTabs.find((tab) => tab.id === tabId)?.order ?? 0,
      }),
    );

    dispatch(
      updateTabOrders({
        tabs: reorderedTabs,
      }),
    );

    dispatch(
      updateProfile({
        profileId: profile.id,
        data: { id: profile.id, tabs: reorderedTabs },
      }),
    );
  };

  const sortedTabs = useMemo(() => getSortedTabs(profile.tabs), [profile.tabs]);

  const tabItems: TabItem[] = useMemo(() => {
    return sortedTabs.map((tab) => ({
      tabId: tab.id.toString(),
      tabTitle: tab.title ?? "Untitled",
      active: tab.id === tabId,
    }));
  }, [sortedTabs, tabId]);

  return (
    <div className="enable-user-interaction tab-switcher">
      <div className="tab-switcher__component">
        <MmTabList
          items={tabItems}
          activeId={tabId?.toString()}
          data-testid={"tab-list-component"}
          className="tab-list"
          ref={tabListRef}
          tabClickHandler={handleTabSelect}
          tabDeletedHandler={handleRemoveTab}
          tabsReorderedHandler={handleTabsReordered}
          tabRenamedHandler={handleUpdateTabTitle}
        />
      </div>
      <mm-fab-button data-testid={"new-tab-btn"} iconname="add" onClick={handleTabCreate}>
        <span className="material-icons">add</span>
      </mm-fab-button>
    </div>
  );
}

import type { SymbolLayout, SymbolPaint } from "mapbox-gl";

export const MIN_SIZE = 10;
export const MAX_SIZE = 34;

export const TEXT_PAINT_STYLE: SymbolPaint = {
  "text-color": "#404040",
  "text-halo-blur": 0.5,
  "text-halo-color": "rgba(238, 238, 238, 0.8)",
  "text-halo-width": 2,
};

export const COMMON_LAYOUT_STYLE: SymbolLayout = {
  "symbol-spacing": 250,
  "symbol-avoid-edges": true,
  "symbol-placement": "point",
  "text-font": ["Roboto_Medium_Italic"],
  "text-letter-spacing": 0.1,
  "text-ignore-placement": true,
  "text-keep-upright": true,
  "text-pitch-alignment": "map",
  "text-rotation-alignment": "map",
  "text-transform": "none",
  "text-allow-overlap": true,
};

export const COLOR_HAZARD_DEFAULT = "#800000";

export const COLOR_HAZARD = {
  VA: "#808080", // Volcanic Ash
  TC: "#800000", // Tropical cyclone
  TS: "#800000", // Thunderstorm
  TURB: "#A06000", // Turbulence
  ICE: "#000080", // Icing
  RDOACT: "#00e536", // Radioactive Cloud
  MTW: "#800000", // Mountain wave
  DS: "#800000", // Duststorm
  SS: "#800000", // Sandstorm
};

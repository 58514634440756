import { IntegerInput } from "@/overlay/components/CommonParts/CustomInput";
import { ColorPickerInput } from "@/overlay/components/LayerStackWindow/LayerFormElements";
import type { ThresholdColor } from "@/reducer/client-models";
import { ConditionOptions, conditionValues } from "@/utility/conditionalExpressions";
import type { ColorThreshold } from "@mm/metx-workbench.meteomatics.com";
import React from "react";

export interface ColorThresholdProps {
  threshold: ThresholdColor;
  onThresholdChange: (data: Partial<ThresholdColor>) => void;
  onRemove: () => void;
  step?: number;
  allowDecimals?: boolean;
}

export const ColorThresholdInput = ({
  threshold,
  onRemove,
  onThresholdChange,
  step,
  allowDecimals,
}: ColorThresholdProps) => {
  return (
    <div className={"threshold"}>
      <ColorPickerInput
        label={""}
        value={threshold.color}
        change={(color) => onThresholdChange({ color: color })}
        data-testid={"color-picker"}
      />
      <select
        value={threshold.condition}
        onChange={(event) => onThresholdChange({ condition: event.target.value as ConditionOptions })}
        data-testid={"condition-select"}
      >
        {conditionValues?.map((condition) => (
          <option key={condition} value={condition}>
            {ConditionOptions[condition]}
          </option>
        ))}
      </select>
      <IntegerInput
        value={threshold.value}
        onChange={(value) => onThresholdChange({ value })}
        step={step}
        decimals={allowDecimals || !Number.isInteger(step)}
        data-testid={"threshold-value"}
      />
      <span className="material-icons" onClick={onRemove}>
        close
      </span>
    </div>
  );
};

export const thresholdToColorThreshold = (threshold: ColorThreshold): ThresholdColor => {
  const condition = threshold.condition as ConditionOptions;

  return {
    color: threshold.color,
    condition: ConditionOptions[condition],
    value: threshold.value,
  };
};

import type { LayerBase, LayerUnion } from "@/layers";
import type { ScenePublicApi } from "@/layers/Compositor";
import { _debug_scenes } from "@/layers/Compositor";
import { useCurrTimeState } from "@/models/time-control/timeStateHooks";
import { TimeState } from "@/models/time-control/timeStateMachine";
import type { TemporalDiscretization } from "@/time";
import {
  realize_datetime_desc,
  realize_temporal_discretization_rounded,
  realize_temporal_resolution,
  setTimeList,
} from "@/time";
import type { DateTimeDesc, GuiTimeZone } from "@mm/metx-workbench.meteomatics.com";
import { debounce } from "lodash";
import Logger from "logging";
import type { DateTime, Duration } from "luxon";
import { Interval } from "luxon";

const logger = Logger.fromFilename(__filename);
/**
 * Callback invoked to announce changes to the playback state.
 *
 * `stateRef` MUST be constant. passing in a copy of `this` results in undefined behaviour.
 */
export type StateChangeDetection = (stateRef: PlaybackState) => void;

/**
 * Per Tab Playback
 *
 * @deprecated Please prefer to use
 * @see TimeState
 * from
 * @see useCurrTimeState
 */
export class PlaybackState {
  private interval_: Interval;
  private animationTime_: DateTime;
  private nextTime_: DateTime;
  // When user navigate timeline we want to move displayTimePointer immediately and sync up with nextTime_ after debounce
  // That will prevent instant request trigger and we will have displayTimePointer value, that we can display in map label
  // Maybe in future, when we will start cleaning up and optimizing playback, we should consider
  // getting rid of displayTimePointer_ and instead use nextTime_ as timeline pointing value, but for now
  // this is easiest solution for issue without refactoring

  /**
   * Temporal state that only reflects the display time. We added this to enable fast feedback of the display time.
   * @type {DateTime}
   */
  private displayTimePointer_: DateTime;
  private temporalResolution_: Duration;
  private targetFPS_: number;

  constructor(
    private setter: StateChangeDetection,
    private timeDesc_: DateTimeDesc,
    private timeZone: GuiTimeZone,
    private currentTime_: DateTime,
    private isPaused_: boolean,
    private isLooping_: boolean,
  ) {
    logger.perfMark("time step start");
    this.animationTime_ = this.currentTime_;
    this.nextTime_ = this.currentTime_;
    this.displayTimePointer_ = this.currentTime_;
    // freeze the playback interval on construction
    // should be updatable after each loop through the playback
    this.interval_ = realize_datetime_desc(timeDesc_, timeZone);
    this.temporalResolution_ = realize_temporal_resolution(timeDesc_);
    this.targetFPS_ = timeDesc_.fps;
  }

  get targetFPS(): number {
    return this.targetFPS_;
  }

  set targetFPS(v: number) {
    this.targetFPS_ = v;
    this.setter(this);
  }

  get isLooping(): boolean {
    return this.isLooping_;
  }

  set isLooping(v: boolean) {
    this.isLooping_ = v;
    this.setter(this);
  }

  get isPaused(): boolean {
    return this.isPaused_;
  }

  set isPaused(v: boolean) {
    this.isPaused_ = v;
    this.setter(this);
  }

  get currentTime(): DateTime {
    return this.currentTime_;
  }

  set currentTime(v: DateTime) {
    logger.perfMark("time step start");
    this.currentTime_ = v;
    this.setter(this);
  }

  get nextTime(): DateTime {
    return this.nextTime_;
  }

  syncNextTime = debounce((v: DateTime) => {
    this.fetchAllData(v);
    this.nextTime = v;
  }, 200);

  set displayTimePointer(v: DateTime) {
    this.displayTimePointer_ = v;
    this.setter(this);
    this.syncNextTime(v);
  }

  set nextTime(v: DateTime) {
    this.nextTime_ = v;
    this.setter(this);
  }

  get animationTime(): DateTime {
    return this.animationTime_;
  }

  set animationTime(v: DateTime) {
    this.animationTime_ = v;
    this.currentTime_ = this.convertToLastPastIntervalTime(this.animationTime_);
    this.nextTime_ = this.currentTime_.plus(this.temporalResolution_);
    this.displayTimePointer_ = this.currentTime_;
    this.setter(this);
  }
  /**
   * @returns DateTime of the last time that was before new ActiveTime in the Interval
   * */
  convertToLastPastIntervalTime(newActiveTime: DateTime): DateTime {
    const extraStepEndTime = this.interval.end.plus(this.temporalResolution);
    const timeList = setTimeList(
      Interval.fromDateTimes(this.interval.start, extraStepEndTime),
      this.temporalResolution,
    );
    const foundIdx = timeList.findIndex((time, index, list) => {
      return time > newActiveTime;
    });
    let setIndex = 0;
    if (foundIdx > 0) {
      setIndex = foundIdx - 1;
    }
    return timeList[setIndex];
  }

  private sceneIsRebuffering(scene: ScenePublicApi, datetime: DateTime): boolean {
    let rebuffering = false;
    for (const layer of scene.layers) {
      if (layer === undefined || !layer.getLayerProps().show) {
        continue;
      }

      rebuffering = rebuffering || layer.isRebuffering(datetime);
    }
    return rebuffering;
  }

  private createPerLayerLoadedPromise(layer: LayerBase<LayerUnion>, time: DateTime, lastFrame: boolean) {
    const checkRebufferingStatus = (time: DateTime, cb: () => void) => {
      if (!layer.isRebuffering(time) && (lastFrame || !layer.isRebuffering(this.nextTime))) {
        requestAnimationFrame(() => cb());
        return;
      }
      requestAnimationFrame(() => checkRebufferingStatus(time, cb));
    };

    return new Promise<void>((resolve) => {
      checkRebufferingStatus(time, () => resolve());
    });
  }

  private async allScenesAreIdle(time: DateTime, lastFrame: boolean) {
    const promises: Promise<void>[] = [];
    for (const [, { inUse, obj: scene }] of _debug_scenes()) {
      if (!inUse) {
        continue;
      }

      promises.push(
        new Promise((resolve) => {
          // Create waiting promises only for visible layers
          const perLayersPromises = scene.layers
            .filter((layer) => layer.getLayerProps().show)
            .map((layer) => this.createPerLayerLoadedPromise(layer, time, lastFrame));
          Promise.all(perLayersPromises).then(() => {
            scene.getMapboxMap().once("render", () => {
              resolve();
            });

            scene.getMapboxMap().triggerRepaint();
          });
        }),
      );
    }

    await Promise.all(promises);
  }
  fetchAllData(datetime: DateTime = this.currentTime) {
    // Loop over all active scenes and check if a scene is rebuffering a specific time.
    for (const [, { inUse, obj: scene }] of _debug_scenes()) {
      if (!inUse || !scene.isActive) {
        continue;
      }
      this.sceneFetchAllData(scene, datetime);
    }
  }

  private sceneFetchAllData(scene: ScenePublicApi, datetime: DateTime) {
    for (const layer of scene.layers) {
      if (layer === undefined || !layer.getLayerProps().show) {
        continue;
      }

      layer.fetchData(datetime);
    }
  }

  isRebuffering(datetime: DateTime = this.currentTime): boolean {
    let rebuffering = false;
    // Loop over all active scenes and check if a scene is rebuffering a specific time.
    for (const [, { inUse, obj: scene }] of _debug_scenes()) {
      if (!inUse || !scene.isActive) {
        continue;
      }
      rebuffering = rebuffering || this.sceneIsRebuffering(scene, datetime);
    }
    return rebuffering;
  }

  async allScenesLoaded(time: DateTime, lastFrame: boolean) {
    return new Promise((resolve) => {
      // Wait while all scenes are idle and only then resolve promise
      // to make sure mapbox are finished loading and drawing everything

      this.allScenesAreIdle(time, lastFrame)
        .then(() => {
          resolve(true);
        })
        .catch((e) => console.log(e));
    });
  }

  /**
   *  @returns Duration of the DateTime Interval
   */
  get intervalDuration(): Duration {
    return this.interval_.toDuration();
  }

  get lastFrameTime(): DateTime {
    return this.interval_.end;
  }

  get firstFrameTime(): DateTime {
    return this.interval_.start;
  }

  get interval(): Interval {
    return this.interval_;
  }

  get displayTimePointer(): DateTime {
    return this.displayTimePointer_;
  }

  /**
   * Modifies the interval and the current time directly without restoring the interval from the time description object.
   */
  reinitInterval(newInterval: Interval) {
    this.interval_ = newInterval;
    this.currentTime_ = newInterval.start;
    this.animationTime_ = this.currentTime_;
    this.nextTime_ = this.currentTime_;
    this.setter(this);
  }

  /**
   * Converts any relative specification within the DateTimeDesc of this playback into concrete, absolute
   * time units. You can think of setting the point of reference of relative time specifications.
   *
   * So for example: If this is a playback of `the last hour` and you call this function at 18:00, `firstFrameTime()`
   * would return `17:00`. If you call this function again, 30min later at 18:30, subsequent calls to `firstFrameTime()`
   * would return `17:30`.
   *
   * It is intended for when we want to refresh the interval from the raw datetime description.
   * Note it does not reinitialize other attributes that have been directly updated via setters.
   */
  reinitIntervalFromDescription() {
    this.interval_ = realize_datetime_desc(this.timeDesc_, this.timeZone);
  }

  /**
   *
   * Since actually rendered frames are composites of partially available temporal data, this structure just
   * describes the "target temporal resolution". Use methods on the rendering engine logic and the cache to get
   * the actual temporal resolution, which is a __per pixel constant__. Thus, actual data description, is a
   * __per frame aggregate__ summarized as an average, a max, min and a variance, stddev.
   */
  sanitizedDescription(): DateTimeDesc {
    // TODO: reduce temporal resolution to minium of model: max(model_res, this.timeDesc.temporal_res)
    // TODO: reduce to sustainable temporal resolution, e.g. reduce temporal res if the parameter is not fast enough,
    // but were do we get the perf measurements? maybe this is not the right place
    return this.timeDesc_;
  }

  get temporalDiscretization(): TemporalDiscretization {
    return realize_temporal_discretization_rounded(this.temporalResolution);
  }

  get temporalResolution(): Duration {
    return this.temporalResolution_;
  }

  set temporalResolution(v: Duration) {
    this.temporalResolution_ = v;
  }

  /**
   * Unsanitized description of the datetime range -- meaning this is what the user requested, which might be dump,
   * e.g. require terabytes of data.
   *
   * Use this to render information to the GUI or for debugging. To actually fetch data, and to get a description of
   * the actual target temporal resolution of the application, use the `sanitizedDescription()`.
   *
   * @returns
   */
  rawDescription(): DateTimeDesc {
    return this.timeDesc_;
  }

  rawTimeZone(): GuiTimeZone {
    return this.timeZone;
  }
}

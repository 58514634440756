import type { SvgComponentProps } from "@/style/img/MapSvg";
import type React from "react";

const PlotSvg: React.FunctionComponent<SvgComponentProps> = ({ className }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 92.9 74.5"
    xmlSpace="preserve"
    className={className}
  >
    <style>
      {".st0{fill:none;stroke:#000000;stroke-width:3;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}"}
    </style>
    <rect x="2.8" y="3.1" className="st0" width="87.2" height="68.3" />
    <path
      d="M24.2,53.7c0-0.8,0.6-1.4,1.3-1.5c0.1,0,7.5-1,9.2-11.3c0.2-1,0.3-2.5,0.5-4.3c0.9-9.8,2.2-18.7,6.7-20.4
      c3-1.1,5.2,0.5,7,1.8c2,1.4,2.8,1.9,4.1,1.2c0.8-0.5,1.4-1.9,2-3.3c1.2-2.8,2.9-6.6,7.4-6.6c0.8,0,1.5,0.7,1.5,1.5c0,0,0,0,0,0
      c0,0.8-0.7,1.5-1.5,1.5c-2.4,0-3.4,1.8-4.7,4.8c-0.8,1.9-1.6,3.8-3.3,4.7c-3,1.8-5.5,0-7.4-1.3c-1.7-1.2-2.8-2-4.2-1.4
      c-3.2,1.2-4.3,12.5-4.8,17.9c-0.2,1.8-0.3,3.4-0.5,4.5C35.6,54,26.3,55.2,25.9,55.2c-0.8,0.1-1.6-0.5-1.7-1.3
      C24.2,53.8,24.2,53.8,24.2,53.7z"
    />
    <g>
      <line className="st0" x1="17.8" y1="64.1" x2="17.8" y2="10.1" />
      <line className="st0" x1="21.4" y1="15" x2="17.9" y2="10.1" />
      <line className="st0" x1="14.4" y1="15" x2="17.9" y2="10.1" />
    </g>
    <g>
      <line className="st0" x1="10" y1="60.2" x2="82.7" y2="60.2" />
      <line className="st0" x1="77.7" y1="63.8" x2="82.7" y2="60.3" />
      <line className="st0" x1="77.7" y1="56.8" x2="82.7" y2="60.3" />
    </g>
  </svg>
);

export default PlotSvg;

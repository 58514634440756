import { classNames } from "@/utility/jsx";
import { type InputHTMLAttributes, useEffect, useState } from "react";
import styles from "./style.module.scss";

function isInBounds(value: number | undefined, min: number | undefined, max: number | undefined) {
  if (value !== undefined) {
    if (min !== undefined && value < min) {
      return false;
    }

    if (max !== undefined && value > max) {
      return false;
    }
  }

  return true;
}

export enum IntegerInputType {
  WIDE = 0,
  NARROW = 1,
}

export type IntegerInputProps = {
  allowNegative?: boolean;
  value?: number;
  step?: number;
  max?: number;
  min?: number;
  layout?: IntegerInputType;
  onChange: (n: number) => void;
  disabled?: boolean;
  decimals?: boolean;
};

export function IntegerInput({
  decimals,
  onChange,
  ...restProps
}: IntegerInputProps & Omit<InputHTMLAttributes<HTMLInputElement>, "type" | "onChange" | "max" | "min" | "value">) {
  const { min, max, value, step, disabled, layout = IntegerInputType.WIDE } = restProps;
  const [reflectedValue, setReflectedValue] = useState(value);

  useEffect(() => {
    setReflectedValue(value);
  }, [value]);

  function castToInt(v: number) {
    return Math.floor(v);
  }

  return (
    <div
      className={classNames(
        styles["integer-input"],
        layout === IntegerInputType.NARROW ? styles["integer-input__narrow"] : "",
      )}
    >
      <input
        className={classNames(styles["integer-input__input"])}
        type="number"
        {...restProps}
        value={reflectedValue?.toString().replace(/^(?:0+(?=[1-9])|0+(?=0$))/, "")}
        onChange={(e) => {
          const val = decimals ? +e.target.value : castToInt(+e.target.value);
          setReflectedValue(val);

          if (isInBounds(val, min, max)) {
            onChange(val);
          }
        }}
        min={min}
        max={max}
        disabled={disabled}
        // https://stackoverflow.com/questions/28812377/getting-invalid-style-for-any-type-of-decimal-separator-in-input-type-number
        step="any"
      />
      <div className={styles["integer-input__step-buttons"]}>
        <button
          className={styles["integer-input__step-buttons__button"]}
          disabled={disabled || (value !== undefined && max !== undefined && value >= max)}
          onClick={() => {
            if (value !== undefined && (max === undefined || value < max)) {
              // 0.7 + 0.1 is 0.79999 in javascript
              const val = Number((value + (step || 1)).toFixed(3));
              setReflectedValue(val);
              onChange(val);
            }
          }}
          type={"button"}
        >
          <span className={classNames("material-icons", styles["integer-input__step-buttons__button__icon"])}>add</span>
        </button>
        <button
          className={styles["integer-input__step-buttons__button"]}
          disabled={disabled || (value !== undefined && min !== undefined && value <= min)}
          onClick={() => {
            if (value !== undefined && (min === undefined || value > min)) {
              const val = Number((value - (step || 1)).toFixed(3));
              setReflectedValue(val);
              onChange(val);
            }
          }}
          type={"button"}
        >
          <span className={classNames("material-icons", styles["integer-input__step-buttons__button__icon"])}>
            remove
          </span>
        </button>
      </div>
    </div>
  );
}

import {
  type ComponentFactory,
  type DesktopState,
  type WindowIdentityProps,
  WindowKind,
  defaultDesktopState,
  instanceId,
} from "@/overlay/components";
import { DragItemType } from "@/overlay/components/window/DragItemType";
import { TrackId } from "@/overlay/components/window/TrackId";
import { Suspense, lazy } from "react";
import { CustomPopup } from "../CustomPopupWindow/popup";
import LoadingWindow from "../LoadingWindow";
import PoiManagerWindow from "../PoiManagerWindow";

// Lazy load window components so the JS is loaded only after the user opens a window first time.
const AccountWindow = lazy(() => import("@/overlay/components/AccountWindow"));
const DatetimeLabelWindow = lazy(() => import("@/overlay/components/DatetimeLabelWindow"));
const DatetimeSelectionWindow = lazy(() => import("@/overlay/components/DatetimeSelectionWindow"));
const DeveloperWindow = lazy(() => import("@/overlay/components/DeveloperWindow"));
const LayerStackWindow = lazy(() => import("@/overlay/components/LayerStackWindow"));
const LayoutSettingsWindow = lazy(() => import("@/overlay/components/LayoutSettingsWindow"));
const AboutWindow = lazy(() => import("@/overlay/components/AboutWindow"));
const AddLayerWindow = lazy(() => import("@/overlay/components/AddLayerWindow"));
const AddParameterWindow = lazy(() => import("@/overlay/components/AddParameterWindow"));
const ConfirmationWindow = lazy(() => import("@/overlay/components/ConfirmationWindow"));
const DeleteConfirmationWindow = lazy(() => import("@/overlay/components/DeleteConfirmationWindow"));
const GeolocatorWindow = lazy(() => import("@/overlay/components/GeolocatorWindow"));
const LocationSearchWindow = lazy(() => import("@/overlay/components/LocationSearchWindow"));
const PopupPlotWindow = lazy(() => import("@/overlay/components/PopupPlotWindow"));
const FileWindow = lazy(() => import("@/overlay/components//FileWindow"));
const AddDashboardWindow = lazy(() => import("@/overlay/components/AddDashboardWindow"));
const PlotDatetimeRangeWindow = lazy(() => import("@/overlay/components/PlotDatetimeRangeWindow"));
const RenameDashboardWindow = lazy(() => import("@/overlay/components/RenameDashboardWindow"));

function withLoadingWindow<T extends WindowKind>(jsx: JSX.Element, props: WindowIdentityProps<T>) {
  return <Suspense fallback={<LoadingWindow {...props} />}>{jsx}</Suspense>;
}

export const windowFactories = [
  // TODO: provide shorthands for the common cxase where id is fixed from the enum. Should be colocated with the window
  {
    instanceId: instanceId(WindowKind.Geolocator),
    component: (key: string, props: WindowIdentityProps<WindowKind.Geolocator>) =>
      withLoadingWindow(<GeolocatorWindow key={key} {...props} />, props),
    defaultWindowState: {
      position: { trackId: TrackId.Right },
      isVisible: false,
    },
    dragItemType: DragItemType.NormalWindow,
  },
  {
    instanceId: instanceId(WindowKind.LocationSearch),
    component: (key: string, props: WindowIdentityProps<WindowKind.LocationSearch>) =>
      withLoadingWindow(<LocationSearchWindow key={key} {...props} />, props),
    defaultWindowState: {
      position: { trackId: TrackId.Right },
      isVisible: false,
    },
    dragItemType: DragItemType.NormalWindow,
  },
  {
    instanceId: instanceId(WindowKind.Account),
    component: (key: string, props: WindowIdentityProps<WindowKind.Account>) =>
      withLoadingWindow(<AccountWindow key={key} {...props} />, props),
    defaultWindowState: { position: { trackId: TrackId.Right } },
    dragItemType: DragItemType.NormalWindow,
    draggable: false,
  },
  {
    instanceId: instanceId(WindowKind.LayoutSettings),
    component: (key: string, props: WindowIdentityProps<WindowKind.LayoutSettings>) =>
      withLoadingWindow(<LayoutSettingsWindow key={key} {...props} />, props),
    defaultWindowState: { position: { trackId: TrackId.Right } },
    dragItemType: DragItemType.NormalWindow,
  },
  {
    instanceId: instanceId(WindowKind.DatetimeLabel),
    component: (key: string, props: WindowIdentityProps<WindowKind.DatetimeLabel>) =>
      withLoadingWindow(<DatetimeLabelWindow key={key} {...props} />, props),
    defaultWindowState: { position: {}, isVisible: false },
    dragItemType: DragItemType.SmallWindow,
  },
  {
    instanceId: instanceId(WindowKind.LayerStack),
    component: (key: string, props: WindowIdentityProps<WindowKind.LayerStack>) =>
      withLoadingWindow(<LayerStackWindow key={key} {...props} />, props),
    defaultWindowState: {
      position: { trackId: TrackId.Left },
      isVisible: false,
    },
    dragItemType: DragItemType.NormalWindow,
  },
  {
    instanceId: instanceId(WindowKind.About),
    component: (key: string, props: WindowIdentityProps<WindowKind.About>) =>
      withLoadingWindow(<AboutWindow key={key} {...props} />, props),
    defaultWindowState: {},
    dragItemType: DragItemType.NormalWindow,
  },
  {
    instanceId: instanceId(WindowKind.Developer),
    component: (key: string, props: WindowIdentityProps<WindowKind.Developer>) =>
      withLoadingWindow(<DeveloperWindow key={key} {...props} />, props),
    defaultWindowState: { position: { isManaged: false, top: 0, left: 0 } },
    dragItemType: DragItemType.LargeWindow,
  },
  {
    instanceId: instanceId(WindowKind.AddLayer),
    component: (key: string, props: WindowIdentityProps<WindowKind.AddLayer>) =>
      withLoadingWindow(<AddLayerWindow key={key} {...props} />, props),
    defaultWindowState: {
      isVisible: false,
      position: { trackId: TrackId.Modal },
    },
    dragItemType: DragItemType.ModalWindow,
    draggable: false,
  },
  {
    instanceId: instanceId(WindowKind.AddDashboard),
    component: (key: string, props: WindowIdentityProps<WindowKind.AddDashboard>) =>
      withLoadingWindow(<AddDashboardWindow key={key} {...props} />, props),
    defaultWindowState: {
      isVisible: false,
      position: { trackId: TrackId.Modal },
    },
    dragItemType: DragItemType.ModalWindow,
    draggable: false,
  },
  {
    instanceId: instanceId(WindowKind.RenameDashboard),
    component: (key: string, props: WindowIdentityProps<WindowKind.AddDashboard>) =>
      withLoadingWindow(<RenameDashboardWindow key={key} {...props} />, props),
    defaultWindowState: {
      isVisible: false,
      position: { trackId: TrackId.Modal },
    },
    dragItemType: DragItemType.ModalWindow,
    draggable: false,
  },
  {
    instanceId: instanceId(WindowKind.AddParameter),
    component: (key: string, props: WindowIdentityProps<WindowKind.AddParameter>) =>
      withLoadingWindow(<AddParameterWindow key={key} {...props} />, props),
    defaultWindowState: {
      isVisible: false,
      position: { trackId: TrackId.Modal },
    },
    dragItemType: DragItemType.ModalWindow,
    draggable: false,
  },
  {
    instanceId: instanceId(WindowKind.PopupPlot),
    component: (key: string, props: WindowIdentityProps<WindowKind.PopupPlot>) =>
      withLoadingWindow(<PopupPlotWindow key={key} {...props} />, props),
    defaultWindowState: {
      isVisible: true,
      position: { trackId: TrackId.Right },
    }, // TODO: Why is isVisible=true?
    dragItemType: DragItemType.NormalWindow,
  },
  {
    instanceId: instanceId(WindowKind.DeleteConfirmation),
    component: (key: string, props: WindowIdentityProps<WindowKind.DeleteConfirmation>) =>
      withLoadingWindow(<DeleteConfirmationWindow key={key} {...props} />, props),
    defaultWindowState: {
      isVisible: false,
      position: { trackId: TrackId.Modal },
    },
    dragItemType: DragItemType.ModalWindow,
    draggable: false,
  },
  {
    instanceId: instanceId(WindowKind.ConfirmationWindow),
    component: (key: string, props: WindowIdentityProps<WindowKind.ConfirmationWindow>) =>
      withLoadingWindow(<ConfirmationWindow key={key} {...props} />, props),
    defaultWindowState: {
      isVisible: false,
      position: { trackId: TrackId.Modal },
    },
    dragItemType: DragItemType.ModalWindow,
    draggable: false,
  },
  {
    instanceId: instanceId(WindowKind.FileWindow),
    component: (key: string, props: WindowIdentityProps<WindowKind.FileWindow>) =>
      withLoadingWindow(<FileWindow key={key} {...props} />, props),
    defaultWindowState: {
      isVisible: false,
      position: { trackId: TrackId.Modal },
    },
    dragItemType: DragItemType.ModalWindow,
    draggable: false,
  },
  {
    instanceId: instanceId(WindowKind.DatetimeSelection),
    component: (key: string, props: WindowIdentityProps<WindowKind.DatetimeSelection>) =>
      withLoadingWindow(<DatetimeSelectionWindow key={key} {...props} />, props),
    defaultWindowState: {
      isVisible: false,
      position: { trackId: TrackId.Bottom },
    },
    dragItemType: DragItemType.NormalWindow,
  },
  {
    instanceId: instanceId(WindowKind.PlotDatetimeRange),
    component: (key: string, props: WindowIdentityProps<WindowKind.PlotDatetimeRange>) =>
      withLoadingWindow(<PlotDatetimeRangeWindow key={key} {...props} />, props),
    defaultWindowState: {
      isVisible: false,
      position: { trackId: TrackId.Modal },
    },
    dragItemType: DragItemType.ModalWindow,
    draggable: false,
  },
  {
    instanceId: instanceId(WindowKind.PoiManagerWindow),
    component: (key: string, props: WindowIdentityProps<WindowKind.PoiManagerWindow>) =>
      withLoadingWindow(<PoiManagerWindow key={key} {...props} />, props),
    defaultWindowState: {
      position: { trackId: TrackId.Modal },
      isVisible: false,
    },
    dragItemType: DragItemType.ModalWindow,
    draggable: false,
  },
  {
    instanceId: instanceId(WindowKind.CustomPopup),
    component: (key: string, props: WindowIdentityProps<WindowKind.CustomPopup>) =>
      withLoadingWindow(<CustomPopup key={key} {...props} />, props),
    defaultWindowState: {
      isVisible: false,
      position: { trackId: TrackId.Modal },
    },
    dragItemType: DragItemType.ModalWindow,
    draggable: false,
  },
];

export const initialDesktopState: DesktopState = defaultDesktopState(windowFactories as ComponentFactory[]);

import { WindowKind, instanceId, useDesktop } from "@/overlay/components";
import { classNames } from "@/utility/jsx";
import { Trans } from "@lingui/macro";

export function AddDashboardCard(props: { maximized?: boolean }) {
  const desktop = useDesktop();

  const { maximized } = props;
  return (
    // biome-ignore lint/a11y/useKeyWithClickEvents: Accessibility
    <div
      className={classNames(
        "dashboard__card",
        "dashboard__card--add",
        !maximized && "listing listing--actionable",
        maximized && "dashboard__card--maximized",
      )}
      onClick={() =>
        desktop.dispatch({
          type: "toggleWindowVisibility",
          windowId: instanceId(WindowKind.AddDashboard),
        })
      }
    >
      <span className={classNames("material-icons", "add-icon")}>add</span>
      <span>
        <Trans>Create Dashboard</Trans>
      </span>
    </div>
  );
}

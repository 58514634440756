import { useGetCustomGeoJsonList } from "@/api/hooks/customGeojson";
import { backgroundLayerConfig, customGeoJsonLayerConfig } from "@/constants/layerConfigAttributes";
import { WindowKind, instanceId, useDesktop } from "@/overlay/components";
import CompleteThreshold from "@/overlay/components/CommonParts/Threshold/CompleteThreshold";
import { Button } from "@/overlay/components/CommonParts/buttons";
import { CustomGeoJsonFileSelect } from "@/overlay/components/LayerStackWindow/CustomGeoJsonForm/CustomGeoJsonFileSelect";
import { CustomGeoJsonLineResolutionOptions } from "@/overlay/components/LayerStackWindow/CustomGeoJsonForm/CustomGeoJsonLineResolutionOptions";
import { ColorPickerInput, OpacityInput } from "@/overlay/components/LayerStackWindow/LayerFormElements";
import { ModelAndWeatherParameterForm } from "@/overlay/components/ModelAndWeatherParameterForm";
import { FormField } from "@/overlay/components/form-field";
import { getDefaultModelName } from "@/utility/layer";
import { Trans, t } from "@lingui/macro";
import type { CustomGeoJSONLayer, CustomGeoJSONLayerUpdate, MapId } from "@mm/metx-workbench.meteomatics.com";
import { debounce } from "lodash";
import { useCallback, useMemo, useState } from "react";
import type { HasLayerIdxNarrowing, PartialWeatherParameter } from "weather-parameter-utils";
import { parameterToString } from "weather-parameter-utils";
import { IntegerInput } from "../../CommonParts/CustomInput";

export function CustomGeoJsonUpdateForm(props: {
  layer: CustomGeoJSONLayer;
  mapId: MapId;
  onChange: (propsValue: Partial<CustomGeoJSONLayerUpdate>, isGrouped?: boolean) => void;
}) {
  const desktop = useDesktop();
  const { isLoading, list } = useGetCustomGeoJsonList();
  const [segmentLength, setSegmentLength] = useState(
    props.layer.custom_options.segmentLength || customGeoJsonLayerConfig.defaultLineSegmentLength,
  );

  function handleAddParameter() {
    function submitNewParam(param: HasLayerIdxNarrowing<PartialWeatherParameter>) {
      const model = getDefaultModelName(param);

      props.onChange({
        ...props.layer,
        model,
        parameter_unit: parameterToString(param).formatted,
      });
    }

    desktop.dispatch({
      type: "setWindowCustomProps",
      windowId: instanceId(WindowKind.AddParameter),
      windowKind: WindowKind.AddParameter,
      customProps: {
        source: "add-map-param",
        onSubmitParam: (param) => submitNewParam(param),
      },
    });
    desktop.dispatch({
      type: "setWindowVisibility",
      windowId: instanceId(WindowKind.AddParameter),
      isVisible: true,
    });
  }

  const updateSegmentLength = useCallback(
    (segmentLength: number) => {
      props.onChange({
        custom_options: {
          ...props.layer.custom_options,
          segmentLength: segmentLength,
        },
      });
    },
    [props.layer.custom_options],
  );

  const debouncedHandleSegmentLengthUpdate = useMemo(() => debounce(updateSegmentLength, 500), [updateSegmentLength]);

  const handleSegmentLengthChange = (segmentLength: number) => {
    setSegmentLength(segmentLength);
    debouncedHandleSegmentLengthUpdate(segmentLength);
  };

  function handleRemoveParameterAndThreshold() {
    props.onChange({
      parameter_unit: undefined,
      model: undefined,
      thresholds: [],
    });
  }

  if (isLoading) {
    return <mm-loader size="large" />;
  }

  if (!list) {
    return <></>;
  }

  return (
    <>
      <CustomGeoJsonFileSelect
        selectedFileId={props.layer.file_id}
        list={list}
        onChange={(file) =>
          props.onChange({
            ...props.layer,
            file_id: file,
          })
        }
      />
      <OpacityInput layer={props.layer} change={props.onChange} />
      <ColorPickerInput
        label={t`Color`}
        change={(color) =>
          props.onChange({
            ...props.layer,
            custom_options: {
              ...props.layer.custom_options,
              line_color: color,
            },
          })
        }
        value={props.layer.custom_options.line_color || backgroundLayerConfig.defaultLineColor}
      />
      <FormField label={t`Line Width`}>
        <IntegerInput
          {...customGeoJsonLayerConfig.lineWidthLimits}
          onChange={(value) => props.onChange({ custom_options: { ...props.layer.custom_options, line_width: value } })}
          value={props.layer.custom_options.line_width || customGeoJsonLayerConfig.defaultLineWidth}
          data-testid="line-width-input"
        />
      </FormField>

      {!props.layer.parameter_unit ? (
        <FormField label={t`Parameter`}>
          <Button icon="add" onClick={handleAddParameter}>
            <Trans>Add Parameter</Trans>
          </Button>
        </FormField>
      ) : (
        <>
          <CustomGeoJsonLineResolutionOptions
            onSegmentChange={(segmentLength) => {
              handleSegmentLengthChange(segmentLength);
            }}
            unit={props.layer.custom_options.units || customGeoJsonLayerConfig.defaultUnit}
            segmentLength={segmentLength}
            onUnitChange={(unit) => {
              props.onChange({ custom_options: { ...props.layer.custom_options, units: unit } });
            }}
          />
          <ModelAndWeatherParameterForm
            layer={props.layer}
            mapId={props.mapId}
            onEditParameter={handleAddParameter}
            onRemoveParameter={handleRemoveParameterAndThreshold}
          />
          <CompleteThreshold
            paramUnit={props.layer.parameter_unit}
            thresholds={props.layer.thresholds || []}
            updateThresholds={(thresholds) => {
              props.onChange({
                thresholds,
              });
            }}
          />
        </>
      )}
    </>
  );
}

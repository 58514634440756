import { useViewport } from "@/reducer/ViewportsState";
import type { CartographicMap, MapId, Viewport } from "@mm/metx-workbench.meteomatics.com";
import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import type { RootState } from "..";
import { useMap } from "../MapsState";

const selectMapAndViewport = createSelector(
  [
    (state: RootState, map: CartographicMap | undefined) => map,
    (state: RootState, map: CartographicMap | undefined, viewport: Viewport | undefined) => viewport,
  ],
  (map, viewport): [CartographicMap | undefined, Viewport | undefined] => {
    if (map && viewport) {
      return [map, viewport];
    }
    return [undefined, undefined];
  },
);

export function useMapAndViewport(mapId: MapId): [CartographicMap | undefined, Viewport | undefined] {
  const map = useMap(mapId.map);
  const viewport = useViewport(map?.id_viewport);

  return useSelector((state: RootState) => selectMapAndViewport(state, map, viewport));
}

import { Api } from "@/api/Api";
import reduxStore, { type RootState } from "@/reducer";
import type { TabSnapshot } from "@mm/metx-workbench.meteomatics.com";
import { debounce } from "lodash";
import Logger from "logging";
const logger = Logger.fromFilename(__filename);

/**
 * A map to temporarily hold tab snapshots that are "queued to save, but not yet saved in backend"
 * The purpose of this is to allow keeping multiple tab snapshots until the save API calls emit,
 * in a potentially race condition such as tab switch.
 */
const tabSnapshotsByTabId: { [tabId: number]: RootState["tabGroup"]["present"] } = {};

export function tabAutoSaveListener() {
  const tabSnapshot = reduxStore.getState().tabGroup.present;

  if (!tabSnapshot.tabMeta.isLoading && tabSnapshot.tabMeta.data) {
    // Enqueue the tab snapshot for saving, if the tab is loaded, and state update triggered.
    const tabId = tabSnapshot.tabMeta.data.id;
    tabSnapshotsByTabId[tabId] = tabSnapshot;
  }

  saveTabStateDebounced(tabSnapshotsByTabId);
}

const saveTabStateDebounced = debounce(saveTabSnapshot, 600);
function saveTabSnapshot(snapshotQueue: typeof tabSnapshotsByTabId) {
  const tabIds = Object.keys(snapshotQueue);
  for (const tabId of tabIds) {
    const tabState = snapshotQueue[+tabId];
    if (tabState) {
      const tabSnapshot: TabSnapshot = {
        tab_meta: tabState.tabMeta.data,
        layouts: Object.values(tabState.layouts),
        maps: Object.values(tabState.maps),
        plots: Object.values(tabState.plots),
        energy_plots: Object.values(tabState.energyPlots),
        weather_tables: Object.values(tabState.weatherTables),
        location_tables: Object.values(tabState.locationTables),
        tephigrams: Object.values(tabState.tephigrams),
        country_plots: Object.values(tabState.countryPlots),
        notes: Object.values(tabState.notes),
        viewports: Object.values(tabState.viewports),
      };

      Api.tab
        .v2SyncTabSnapshot({ tabSnapshot: tabSnapshot, tabId: +tabId })
        .then((tab) => {
          return tab;
        })
        .then(() => {
          // Remove the tab ID from queue so we don't trigger API request until the next action
          // to the tab is queued.
          delete snapshotQueue[+tabId];
        })
        .catch((e) => {
          // Note: In the future, we can raise an error message to the user if the tab is not saved.
          logger.error(e);
        });
    }
  }
}

// Simple abstraction to ensure routes do not contain typos

import type { Profile, Tab } from "@mm/metx-workbench.meteomatics.com";
import { createBrowserHistory } from "history";
import { generatePath } from "react-router-dom";

export const history = createBrowserHistory();

// Change the current URL
export function push<Args extends any[]>(route: TypedRoute<Args>, ...args: Args) {
  // title should react to user input, so it has to be set in an useEffect hook in the page component.
  // default to empty string until the component has mounted.
  history.push(route.asString(...args));
}

export abstract class TypedRoute<Args extends any[]> {
  abstract asString(...args: Args): string;

  abstract pattern(): string;
}

export function staticRoute(path: string): TypedRoute<[]> {
  return new (class extends TypedRoute<[]> {
    asString() {
      return path;
    }

    pattern() {
      return this.asString();
    }
  })();
}

export const pageLogin = staticRoute("/login");
export const pageLogout = staticRoute("/logout");
export const pageBeta = staticRoute("/beta");
export const pageNcm = staticRoute("/ncm");
export const pageProfiles = staticRoute("/profiles");
export const pageOAuth = staticRoute("/oauth");
export const defaultRoute = staticRoute("/");
export const defaultRouteAfterLogin = pageProfiles;

export const pageWorkspace = new (class extends TypedRoute<[number, number]> {
  asString(profile: number, tab: number) {
    // TODO: we probably want profile and tab to use either a readable name (to make a nicer url)
    // or to use `Id` instead of `Idx` to make urls persistent even after accounts are edited.
    return generatePath(this.pattern(), { profile, tab });
  }

  pattern() {
    return "/workspace/:profile([0-9]+)/:tab([0-9]+)";
  }
})();

export const pageBetaWorkspace = new (class extends TypedRoute<[number, number]> {
  asString(profile: number, tab: number) {
    return generatePath(this.pattern(), { profile, tab });
  }
  pattern() {
    return "/beta/workspace/:profile([0-9]+)/:tab([0-9]+)";
  }
})();

export const pageNcmWorkspace = new (class extends TypedRoute<[number, number]> {
  asString(profile: number, tab: number) {
    return generatePath(this.pattern(), { profile, tab });
  }
  pattern() {
    return "/ncm/workspace/:profile([0-9]+)/:tab([0-9]+)";
  }
})();

export function startsWithExtraPath(path: string): string {
  if (path.startsWith("/beta")) {
    return pageBeta.asString();
  }
  if (path.startsWith("/ncm")) {
    return pageNcm.asString();
  }
  return "";
}

export function navigateToTab(path: string, profileId: Profile["id"], tabId: Tab["id"]) {
  if (startsWithExtraPath(path) === "/beta") {
    push(pageBetaWorkspace, profileId, tabId);
  } else if (startsWithExtraPath(path) === "/ncm") {
    push(pageNcmWorkspace, profileId, tabId);
  } else {
    push(pageWorkspace, profileId, tabId);
  }
}

import type { MapboxMap } from "@/layers/MapboxMap";
import { type EventData, type MapMouseEvent, type MapboxGeoJSONFeature, Popup } from "mapbox-gl";

export class HoverTooltip {
  private popup = new Popup({ closeButton: false, offset: 8 });

  constructor(
    readonly map: MapboxMap,
    readonly interactiveLayers: string[],
    readonly renderTooltip: (features: MapboxGeoJSONFeature[]) => string | undefined,
  ) {
    for (const layer of this.interactiveLayers) {
      map.on("mousemove", layer, this.handleMouseMove.bind(this));
      map.on("mouseleave", layer, this.handleMouseLeave.bind(this));
    }
  }

  private handleMouseMove(
    e: MapMouseEvent & {
      features?: MapboxGeoJSONFeature[] | undefined;
    } & EventData,
  ) {
    if (!e.features) {
      return;
    }

    const tooltip = this.renderTooltip(e.features);
    if (tooltip) {
      this.popup.setLngLat(e.lngLat).setHTML(tooltip).addTo(this.map).setMaxWidth("300px");
    }
  }

  private handleMouseLeave() {
    this.popup.remove();
  }

  dispose() {
    for (const layer of this.interactiveLayers) {
      this.map.off("mousemove", layer, this.handleMouseMove.bind(this));
      this.map.off("mouseleave", layer, this.handleMouseLeave.bind(this));
    }
  }
}

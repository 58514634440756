import { customGeoJsonLayerConfig } from "@/constants/layerConfigAttributes";
import { getLightningStep, lightningColorRange } from "@/geojson/definitions";
import { MapboxLayerGroup } from "@/layers/utility/mapbox-utils";
import type { ParameterUnit } from "@mm/api.meteomatics.com";
import type { ColorThreshold, CustomGeoJSONLayer, LightningLayer } from "@mm/metx-workbench.meteomatics.com";
import { isEmpty } from "lodash";
import type { DateTime } from "luxon";
import type { Expression, StyleFunction } from "mapbox-gl";

export const customGeoJSONMapboxLayerGroup = (
  instanceId: string,
  props: CustomGeoJSONLayer,
  [pointSource, lineSource, polygonSource]: string[],
) => {
  const group = new MapboxLayerGroup(instanceId, [
    {
      layerName: "line-layer",
      type: "line",
      source: lineSource,
      paint: {
        "line-width": props.custom_options.line_width || customGeoJsonLayerConfig.defaultLineWidth,
        "line-opacity": props.opacity || 1,
        "line-color": props.custom_options.line_color || "black",
      },
      layout: {
        visibility: "visible",
        "line-join": "round",
        "line-cap": "round",
      },
    },
    {
      layerName: "point-layer",
      type: "circle",
      source: pointSource,
      paint: {
        "circle-radius": 2,
        "circle-opacity": props.opacity || 1,
        "circle-color": props.custom_options.line_color || "black",
      },
      layout: {
        visibility: "visible",
      },
    },
    {
      layerName: "polygon-layer",
      type: "fill",
      source: polygonSource,
      paint: {
        "fill-opacity": props.opacity || 1,
        "fill-color": props.custom_options.line_color || "black",
      },
      layout: {
        visibility: !isEmpty(props.thresholds) && props.show ? "visible" : "none",
      },
    },
    {
      layerName: "polygon-layer-outline",
      type: "line",
      source: polygonSource,
      paint: {
        "line-width": props.custom_options.line_width || customGeoJsonLayerConfig.defaultLineWidth,
        "line-opacity": props.opacity || 1,
        "line-color": props.custom_options.line_color || "black",
      },
      layout: {
        visibility: isEmpty(props.thresholds) && props.show ? "visible" : "none",
      },
    },
  ]);

  return group;
};

export function createLightningTimerangeFilter(selectedTime: DateTime, param_unit: ParameterUnit, index: number) {
  const selectedTimeMs = selectedTime.valueOf();
  const stepMs = getLightningStep(param_unit) * 60 * 1000;

  const upperBound = selectedTimeMs - index * stepMs;
  const lowerBound = selectedTimeMs - (index + 1) * stepMs;
  return [
    "all",
    [">=", ["to-number", ["get", "stroke_time_ms"]], lowerBound],
    ["<", ["to-number", ["get", "stroke_time_ms"]], upperBound],
  ];
}

export const lightningMapboxLayerGroup = (
  instanceId: string,
  props: LightningLayer,
  source: string,
  selectedTime: DateTime,
  param_unit: ParameterUnit,
) => {
  const group = new MapboxLayerGroup(instanceId, [
    {
      layerName: "timerange-layer-6",
      type: "symbol",
      source: source,
      paint: {
        "icon-opacity": props.opacity,
        "icon-color": lightningColorRange[5],
      },
      layout: {
        "icon-image": "lightning-cross",
        "icon-size": props.text_size / 100,
        "icon-allow-overlap": true,
        "icon-ignore-placement": true,
        visibility: props.show ? "visible" : "none",
      },
      filter: createLightningTimerangeFilter(selectedTime, param_unit, 5),
    },
    {
      layerName: "timerange-layer-5",
      type: "symbol",
      source: source,
      paint: {
        "icon-opacity": props.opacity,
        "icon-color": lightningColorRange[4],
      },
      layout: {
        "icon-image": "lightning-cross",
        "icon-size": props.text_size / 100,
        "icon-allow-overlap": true,
        "icon-ignore-placement": true,
        visibility: props.show ? "visible" : "none",
      },
      filter: createLightningTimerangeFilter(selectedTime, param_unit, 4),
    },
    {
      layerName: "timerange-layer-4",
      type: "symbol",
      source: source,
      paint: {
        "icon-opacity": props.opacity,
        "icon-color": lightningColorRange[3],
      },
      layout: {
        "icon-image": "lightning-cross",
        "icon-size": props.text_size / 100,
        "icon-allow-overlap": true,
        "icon-ignore-placement": true,
        visibility: props.show ? "visible" : "none",
      },
      filter: createLightningTimerangeFilter(selectedTime, param_unit, 3),
    },
    {
      layerName: "timerange-layer-3",
      type: "symbol",
      source: source,
      paint: {
        "icon-opacity": props.opacity,
        "icon-color": lightningColorRange[2],
      },
      layout: {
        "icon-image": "lightning-cross",
        "icon-size": props.text_size / 100,
        "icon-allow-overlap": true,
        "icon-ignore-placement": true,
        visibility: props.show ? "visible" : "none",
      },
      filter: createLightningTimerangeFilter(selectedTime, param_unit, 2),
    },
    {
      layerName: "timerange-layer-2",
      type: "symbol",
      source: source,
      paint: {
        "icon-opacity": props.opacity,
        "icon-color": lightningColorRange[1],
      },
      layout: {
        "icon-image": "lightning-cross",
        "icon-size": props.text_size / 100,
        "icon-allow-overlap": true,
        "icon-ignore-placement": true,
        visibility: props.show ? "visible" : "none",
      },
      filter: createLightningTimerangeFilter(selectedTime, param_unit, 1),
    },
    {
      layerName: "timerange-layer-1",
      type: "symbol",
      source: source,
      paint: {
        "icon-opacity": props.opacity,
        "icon-color": lightningColorRange[0],
      },
      layout: {
        "icon-image": "lightning-cross",
        "icon-size": props.text_size / 100,
        "icon-allow-overlap": true,
        "icon-ignore-placement": true,
        visibility: props.show ? "visible" : "none",
      },
      filter: createLightningTimerangeFilter(selectedTime, param_unit, 0),
    },
  ]);

  return group;
};

// We can't reuse same function used in POI as it has different data type
// TODO make this reusable everywhere
export const thresholdToExpression = (
  thresholds: ColorThreshold[] | undefined,
  defaultColor: string,
): Expression | string => {
  const caseValue: string | StyleFunction | Expression = ["case", ["==", ["get", "value"], null], defaultColor];
  if (thresholds) {
    if (!thresholds.length) {
      return defaultColor;
    }

    for (const threshold of thresholds) {
      caseValue.push([threshold.condition, ["get", "value"], threshold.value]);
      caseValue.push(threshold.color);
    }
    caseValue.push(defaultColor /*["get", "item-color"]*/);
    return caseValue;
  }

  return defaultColor;
};

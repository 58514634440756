import { FormField } from "@/overlay/components/form-field";
import { t } from "@lingui/macro";

export const CalibratedLabel = () => t`Calibrated`;

export function CalibratedToggle(props: {
  value: boolean;
  change: (propsValue: { calibrated: boolean }) => void;
  disabled?: boolean;
}) {
  return (
    <FormField label={t`Calibrated`}>
      <input
        type="checkbox"
        checked={props.value}
        onChange={() => props.change({ calibrated: !props.value })}
        disabled={props.disabled}
      />
    </FormField>
  );
}

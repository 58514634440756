import type { Authentication } from "./Authentication";

export class NoAuthentication implements Authentication {
  getAuthorizationHeaderValue(): Promise<string | null> {
    return Promise.resolve(null);
  }
  getAccessToken(): Promise<null | string> {
    return Promise.resolve(null);
  }

  forceGetRequest(): boolean {
    return false;
  }
}

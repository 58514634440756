import type { Units } from "@turf/helpers";
import lineChunk from "@turf/line-chunk";
import type { Feature, FeatureCollection, GeoJsonProperties, LineString } from "geojson";

/**
 * Chunks an array of LineString features into smaller segments of a specified length.
 *
 * @param lineFeatures - The array of LineString features to be chunked.
 * @param segmentLength - The length of each segment. Defaults to 100.
 * @param units - The units for the segment length. Defaults to kilometers.
 * @returns A FeatureCollection of the chunked LineString segments.
 */
export const chunkLineString = (
  lineFeatures: Feature<LineString, GeoJsonProperties>[],
  segmentLength = 100,
  units: Units = "kilometers",
): FeatureCollection<LineString> => {
  // Initializing an array to hold all the chunked line segments
  const chunkedFeatures: Feature<LineString, GeoJsonProperties>[] = [];

  // Loop through each line feature
  for (const lineFeature of lineFeatures) {
    // Chunk the line feature into segments of specified length
    const chunked = lineChunk(lineFeature, segmentLength, { units });

    // Add the chunked segments to the chunkedFeatures array
    chunkedFeatures.push(...chunked.features);
  }

  // Return the chunked features as a FeatureCollection
  return {
    type: "FeatureCollection",
    features: chunkedFeatures,
  };
};

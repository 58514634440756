import { IdGenerator } from "@/utility/IdGenerator";
import { safeFmt } from "@/utility/safeFmt";
import type { ShaderBuilder } from "./ShaderBuilder";

export type ShaderId = string;
export type GpuContextId = number;
export type ProgramId = string;

export type WithId<T> = T & {
  _webgl_context_id?: GpuContextId;
};

const contextIdGenerator = new IdGenerator();

export function gpuContextId(gl: WithId<WebGLRenderingContext>): GpuContextId {
  gl._webgl_context_id = gl._webgl_context_id ?? contextIdGenerator.generateId();
  return gl._webgl_context_id;
}

export function shaderId(gl: WithId<WebGLRenderingContext>, shaderType: number, builder: ShaderBuilder): ShaderId {
  // TODO: should escape contents of entries
  const shaderId = safeFmt`${gpuContextId(gl)}$${builder.shaderName}$${shaderType}$${[...builder.macros.entries()]
    .map((v) => v.join("="))
    .join(";")}`;
  return shaderId;
}

export type WithPoolId<T> = { _pool_id: string } & T;

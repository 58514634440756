import { fetchTab } from "@/api/hooks/tab";
import type { Location, WeatherTableSchema, WeatherTableUpdate } from "@mm/metx-workbench.meteomatics.com";
import { type PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import type { RootState } from ".";

import { createWeatherTable } from "@/api/hooks/tools/weatherTable";
import Logger from "logging";
const logger = Logger.fromFilename(__filename);

export type WeatherTableState = {
  [id: number]: WeatherTableSchema;
};

const initialState: WeatherTableState = {};

const weatherTableSlice = createSlice({
  name: "weatherTable",
  initialState: initialState as WeatherTableState,
  reducers: {
    addWeatherTable(state, action: PayloadAction<WeatherTableSchema>) {
      state[action.payload.id] = action.payload;
    },
    discardWeatherTable(state, action: PayloadAction<{ id: number }>) {
      const { id } = action.payload;
      const tool = state[id];

      if (!tool) {
        logger.error("Could not find tool with id", id);
        return;
      }
      delete state[id];
    },
    setWeatherTableLocation(state, action: PayloadAction<{ id: number; props: Location }>) {
      const { id, props } = action.payload;

      const weatherTable = state[id];
      if (!weatherTable) {
        logger.error("Could not find tool with id", id);
        return;
      }

      weatherTable.location = props;
    },
    updateWeatherTableState(
      state,
      action: PayloadAction<{
        id: WeatherTableSchema["id"];
        weatherTable: WeatherTableUpdate;
      }>,
    ) {
      const { weatherTable, id } = action.payload;

      const table = state[id];

      if (!table) {
        logger.error("Could not find weather table with id", id);
        return;
      }

      const updatedWeatherTable = {
        ...table,
        ...weatherTable,
      };

      state[id] = updatedWeatherTable;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTab.fulfilled, (state, action) => {
      const tab = action.payload;
      for (const key in state) {
        delete state[+key];
      }
      for (const table of tab.weather_tables) {
        state[table.id] = table;
      }
    });
    builder.addCase(createWeatherTable.fulfilled, (state, action) => {
      const table = action.payload.tool;
      state[table.id] = table;
    });
  },
});

const selectWeatherTables = (state: RootState) => state.tabGroup.present.weatherTables;

const makeSelectWeatherTablesByTabId = createSelector(
  [selectWeatherTables, (state: RootState, tabId: number) => tabId],
  (weatherTables, tabId) => Object.values(weatherTables).filter((table) => table.id_tab === tabId),
);

export function useWeatherTables(tabId: number) {
  return useSelector((state: RootState) => makeSelectWeatherTablesByTabId(state, tabId));
}

const makeSelectWeatherTableById = createSelector(
  [selectWeatherTables, (state: RootState, id: number) => id],
  (weatherTables, id) => weatherTables[id],
);

export function useWeatherTable(id: number) {
  return useSelector((state: RootState) => makeSelectWeatherTableById(state, id));
}

export const { addWeatherTable, updateWeatherTableState, discardWeatherTable, setWeatherTableLocation } =
  weatherTableSlice.actions;

export default weatherTableSlice.reducer;

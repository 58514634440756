import type { AsyncResult } from "@/cache/AsyncResult";
import { networkCaches } from "@/cache/GlobalCache";
import { DEFAULT_LIGHTNING_INTERVAL, getLightningStep, lightningColorRange } from "@/geojson/definitions";
import type { CheckedProps, LayerBase, PropsChecker } from "@/layers";
import { ChangeDetector } from "@/layers/ChangeDetector";
import type { ScenePublicApi } from "@/layers/Compositor";
import { generateLightningListLayerCacheId } from "@/layers/geojson/LayerUtils";
import { MapboxGeoJSONLayer } from "@/layers/geojson/MapboxGeoJSONLayer";
import { SENTRY_TRANSACTION_NAMES } from "@/sentry/TransactionConst";
import { captureTransaction } from "@/sentry/helpers";
import { safeFmt } from "@/utility/safeFmt";
import {
  Area,
  CoordinateSystem,
  type GeoJSONFeatureCollection,
  type LightningListRequest,
  type ParameterUnit,
} from "@mm/api.meteomatics.com";
import type { GuiTimeZone, LightningLayer } from "@mm/metx-workbench.meteomatics.com";
import Logger from "logging";
import { type DateTime, Duration } from "luxon";
import mapboxgl, { type Expression } from "mapbox-gl";
import {
  type MapboxLayerGroup,
  addLayerGroup,
  moveZIndex,
  removeLayerGroup,
  updateMapboxLayoutProperties,
  updateMapboxPaintProperties,
} from "../utility/mapbox-utils";
import { createLightningTimerangeFilter, lightningMapboxLayerGroup } from "./custom/utils";
import { createLightingTooltip } from "./popovers";

import "./style/lightning.scss";

const logger = Logger.fromFilename(__filename);

export class LightningLayerImpl extends MapboxGeoJSONLayer<LightningLayer> {
  private checkTimeChanged: ChangeDetector<DateTime> = new ChangeDetector<DateTime>();
  private popup = new mapboxgl.Popup({ maxWidth: "400px", className: "mapbox-popover-table" });

  /**
   * Mapbox GeoJSON Feature object that a user clicked on.
   */
  private selectedFeature: mapboxgl.MapboxGeoJSONFeature | null = null;

  private mapboxLayerGroup: MapboxLayerGroup<
    | "timerange-layer-1"
    | "timerange-layer-2"
    | "timerange-layer-3"
    | "timerange-layer-4"
    | "timerange-layer-5"
    | "timerange-layer-6"
  >;

  getActiveWeatherParametersAsString(): { model: string; parameter: string }[] {
    return [];
  }

  constructor(id: number, props: LightningLayer, scene: ScenePublicApi, timezone: GuiTimeZone) {
    super(id, props, scene, timezone);

    const source = this.getMapboxSource();

    this.mapboxLayerGroup = lightningMapboxLayerGroup(
      this.mapbox_id,
      props,
      source,
      this.scene.getDisplayTimeWithOffset(),
      this.props.parameter_unit,
    );
    addLayerGroup(this.scene.getMapboxMap(), this.mapboxLayerGroup.initialSpecs());
    this.setEventListeners();
  }

  humanReadableId(): string {
    return safeFmt`metx.LightningLayer#${this.uid}#${this.props.model}.${this.props.parameter_unit}`;
  }

  checker(): PropsChecker<LightningLayer, LayerBase<LightningLayer>> {
    type CheckerProps = CheckedProps<LightningLayer>;
    const map = this.scene.getMapboxMap();

    const checkLayerProp = (property: CheckerProps) => (prev: any, curr: any) => {
      const changed = prev[property] !== curr[property];
      if (changed) {
        this.setLayerProps({ [property]: curr[property] });
        switch (property) {
          case "model":
          case "calibrated":
          case "custom_options":
          case "legend_visible":
          case "parameter_unit":
            this.updateLightningTimerangeFilter();
            break;
          case "ens_select":
            break;
          case "show":
            updateMapboxLayoutProperties(map, this.mapboxLayerGroup.allIds(), {
              visibility: curr.show ? "visible" : "none",
            });
            break;
          case "opacity":
            updateMapboxPaintProperties(map, this.mapboxLayerGroup.allIds(), {
              "icon-opacity": curr[property],
            });
            break;
          case "text_size":
            updateMapboxLayoutProperties(map, this.mapboxLayerGroup.allIds(), {
              "icon-size": curr[property] / 100,
            });
            break;
          case "text_color":
            break;
          default: {
            const _exhaustive: never = property;
            return _exhaustive;
          }
        }
      }
      return changed;
    };
    return {
      legend_visible: checkLayerProp("legend_visible"),
      model: checkLayerProp("model"),
      opacity: checkLayerProp("opacity"),
      calibrated: checkLayerProp("calibrated"),
      parameter_unit: checkLayerProp("parameter_unit"),
      show: checkLayerProp("show"),
      text_size: checkLayerProp("text_size"),
      text_color: checkLayerProp("text_color"),
      ens_select: checkLayerProp("ens_select"),
      custom_options: checkLayerProp("custom_options"),
    };
  }

  setEventListeners() {
    const mapBoxMap = this.scene.getMapboxMap();

    // Add a popup window on click.
    mapBoxMap.on("click", this.mapboxLayerGroup.allIds(), (e) => {
      if (!e.features) {
        return;
      }
      const feature = e.features[0];
      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      if (feature.geometry.type === "Point") {
        const coordinates = feature.geometry.coordinates;
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }
        this.popup
          .setLngLat(new mapboxgl.LngLat(coordinates[0], coordinates[1]))
          .setHTML(createLightingTooltip(e.features, this.timezone))
          .addTo(mapBoxMap);
        return;
      }
      logger.error(
        "The clicked lightning feature did not contain 'Point' type geometry, instead contained:",
        feature.geometry.type,
        feature,
      );
    });

    // Update the popup content on every source update.
    mapBoxMap.on("sourcedata", (e) => {
      // TODO-LightningFeature:
      // For now, we close the popup on data source update, but once we migrate the popup to PlotWindow,
      // we can just dispatch an action here to fire data update on the plot. We probably don't need to send station data from here,
      // but just to signal the update because station ID should already be in Redux.
      this.popup.remove();
      // e.g)
      // dispatchToRedux({type: "Test Action">})
    });
  }

  createRequest(dateTimeWithOffsetTime: DateTime): LightningListRequest<CoordinateSystem.WGS84> {
    // Currently we fetch the lightning data for the entire world because GeoJSON cache based on the bounding box.
    // This means, if we move the viewport, we'd fetch the data that already exists.
    // To speed up the loading for smaller grid, we should probably split up the grids and fetch only the data we need.
    const area = new Area(CoordinateSystem.WGS84, {
      east: 180,
      west: -180,
      north: 90,
      south: -90,
    });

    const timeDuration = Duration.fromObject({
      minutes: this.props.parameter_unit.includes("_")
        ? Number.parseInt(this.props.parameter_unit.split("_")[1])
        : DEFAULT_LIGHTNING_INTERVAL,
    });

    return {
      area: area,
      datetime: dateTimeWithOffsetTime,
      timeDuration: timeDuration,
    };
  }

  updateTrigger() {
    const dateTimeWithOffsetTime = this.scene.getDisplayTimeWithOffset();
    const request = this.createRequest(dateTimeWithOffsetTime);
    const result = networkCaches.geojson_cache.retrieveLightningList(request);

    const checkTimeChanged = this.checkTimeChanged.changed(dateTimeWithOffsetTime);

    if (checkTimeChanged) {
      this.updateLightningTimerangeFilter();
    }

    this.updateSource(result, dateTimeWithOffsetTime);
  }

  fetchData(timeFrame: DateTime): Promise<void> {
    const dateTimeWithOffsetTime = this.scene.getDateTimeWithOffset(timeFrame);
    const request = this.createRequest(dateTimeWithOffsetTime);
    const tags = {
      layerKind: `${this.props.kind ? this.props.kind : "undefined"}`,
      parameter: `${this.props.parameter_unit}`,
      requestedDate: `${dateTimeWithOffsetTime}`,
    };
    const { asynchronous } = networkCaches.geojson_cache.retrieveLightningList(request);

    if (!this.scene.isAnimation()) {
      return captureTransaction(asynchronous, SENTRY_TRANSACTION_NAMES.LIGHTNING_LAYER_LOADING_SPEED, tags);
    }

    return asynchronous
      .then((data) => {
        return Promise.resolve();
      })
      .catch((err) => {
        return Promise.resolve();
      });
  }

  beforeRender(): void {
    if (this.scene.isAnimation()) {
      this.setLayerZoomRangeIfNeeded(3, 24);
      this.updateTrigger();
    } else {
      this.setLayerZoomRangeIfNeeded(0, 24);
      this.debouncedUpdateTrigger();
    }
  }
  // beforeRender is called frequency there for we call also setLayerZoomRangeIfNeeded every time,
  // to avoid the mapbox needs to rerender and never goes into idle we compare if
  // we changed the zoom range for this layer already
  // this way we can to the image export with the idle event
  setLayerZoomRangeIfNeeded(minZoom: number, maxZoom: number) {
    const layer = this.scene.getMapboxMap().getLayer(this.mapbox_id);
    if ("minzoom" in layer && "maxzoom" in layer) {
      if (layer.minzoom !== minZoom || layer.maxzoom !== maxZoom) {
        this.scene.getMapboxMap().setLayerZoomRange(this.mapbox_id, minZoom, maxZoom);
      }
    }
  }

  get mapboxIndex(): string {
    return this.mapboxLayerGroup.mainId();
  }

  peekLayerCache(request: LightningListRequest<CoordinateSystem.WGS84>): AsyncResult<GeoJSONFeatureCollection> {
    const cacheId = generateLightningListLayerCacheId(request);
    return networkCaches.geojson_cache.peekCache(cacheId);
  }

  removeLayer(): void {
    removeLayerGroup(this.scene.getMapboxMap(), this.mapboxLayerGroup.allIds());
    super.removeLayer();
  }

  moveZIndex(beforeLayerId?: string | undefined): void {
    moveZIndex(this.scene.getMapboxMap(), this.mapboxLayerGroup.allIds(), beforeLayerId);
  }

  updateLightningTimerangeFilter() {
    const map = this.scene.getMapboxMap();
    const displayTimeWithOffset = this.scene.getDisplayTimeWithOffset();

    map.setFilter(
      this.mapboxLayerGroup.id("timerange-layer-6")[0],
      createLightningTimerangeFilter(displayTimeWithOffset, this.props.parameter_unit, 5),
    );
    map.setFilter(
      this.mapboxLayerGroup.id("timerange-layer-5")[0],
      createLightningTimerangeFilter(displayTimeWithOffset, this.props.parameter_unit, 4),
    );
    map.setFilter(
      this.mapboxLayerGroup.id("timerange-layer-4")[0],
      createLightningTimerangeFilter(displayTimeWithOffset, this.props.parameter_unit, 3),
    );
    map.setFilter(
      this.mapboxLayerGroup.id("timerange-layer-3")[0],
      createLightningTimerangeFilter(displayTimeWithOffset, this.props.parameter_unit, 2),
    );
    map.setFilter(
      this.mapboxLayerGroup.id("timerange-layer-2")[0],
      createLightningTimerangeFilter(displayTimeWithOffset, this.props.parameter_unit, 1),
    );
    map.setFilter(
      this.mapboxLayerGroup.id("timerange-layer-1")[0],
      createLightningTimerangeFilter(displayTimeWithOffset, this.props.parameter_unit, 0),
    );
  }
}

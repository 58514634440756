// Maybe pass a URl creator callback?
import { difference, pull, pullAll, union } from "lodash";

/**
 * A utility to keep track of "all the requests that are required by the current UI state".
 * Used mainly by API package to determine which requests can be aborted.
 */
export class ActiveRequestMemory {
  private _queue: string[] = [];
  private _obsolete: string[] = [];
  /**
   * TODO: Maybe later pass a url creator function so we don't have to create URL
   * in WMS Layer implementation
   * urlCreator: (req: any) => MeteomaticsApiUrl[]
   */

  public remember(urls: string[]): void {
    // Union queue with urls array, by keeping only unique values
    this._queue = union(this._queue, urls);

    // Find difference between two arrays and remember it
    this._obsolete = difference(this._queue, urls);

    // Remove difference in queue by mutating it
    pullAll(this._queue, this._obsolete);
  }

  public getObsoletes(): string[] {
    return this._obsolete;
  }

  public flush(): string[] {
    // Clone array so we don't lose values
    const queue = [...this._queue];
    // Clear queue array list
    this._queue = [];
    // return cloned values
    return queue;
  }
}
/**
 * Implementation for possible worker based request url store
 * It may make more sense, to just move ActiveRequestMemory to webworker.
 * Then we can eliminate any possible "out of sync" issues
 */
export class WebWorkerAbortableRequestStore {
  private _queue: string[] = [];

  private static _instance: WebWorkerAbortableRequestStore;

  public push(...urls: string[]) {
    this._queue = union(this._queue, urls);
  }

  public drop(url: string) {
    pull(this._queue, url);
  }

  public has(url: string) {
    return this._queue.includes(url);
  }

  public static getInstance(): WebWorkerAbortableRequestStore {
    if (!WebWorkerAbortableRequestStore._instance) {
      WebWorkerAbortableRequestStore._instance = new WebWorkerAbortableRequestStore();
    }

    return WebWorkerAbortableRequestStore._instance;
  }
}

/**
 * Compute nearest lower power of 2 for n in [1, 2**31-1]:
 */
export function nearestPowerOf2Floor(n: number) {
  return 1 << (31 - Math.clz32(n));
}

/**
 * Compute nearest higher power of 2 for n in [1, 2**31-1]:
 */
export function nearestPowerOf2Ceil(n: number) {
  const lower = 1 << (31 - Math.clz32(n));
  if (lower !== n) {
    return lower * 2;
  }

  return lower;
}

import type { FailableMsg, FailureMsg, Msg } from "@/threads";
import type {
  AvailableTimeframeRequest,
  AvailableTimeframeResponse_Transferable,
  ColorMapRequest,
  ColorMapResponse_Transferable,
  CoordinateSystem,
  GeoJSONFeatureCollection,
  GridPayload,
  GridRequest_Transferable,
  InitialTimeRequest,
  InitialTimeResponse_Transferable,
  IsoLinesRequest_Transferable,
  JSONResponseBody,
  LightningListRequest_Transferable,
  MetarsRequest_Transferable,
  PixelGridRequest_Transferable,
  PlotRequest,
  PointRequest_Transferable,
  PolygonRequest_Transferable,
  VectorLayerStyleRequest,
  VectorTileRequest_Transferable,
  WfsRequest_Transferable,
} from "@mm/api.meteomatics.com";
import type { ParameterUnit } from "@mm/api.meteomatics.com";
import type { LegendRequest } from "@mm/api.meteomatics.com/lib/models/LegendRequest";
import type { MetarsResponse_Transferable } from "@mm/api.meteomatics.com/lib/models/MetarsResponse";
import type { WeatherFrontsRequest_Transferable } from "@mm/api.meteomatics.com/lib/models/WeatherFrontsRequest";
import type { Float32Array2D } from "@mm/api.meteomatics.com/lib/request-splitters";
import type { UserStatsResponse } from "@mm/api.meteomatics.com/models/UserStats";
import type { WfsCapabilities } from "@mm/api.meteomatics.com/models/WfsCapabilities";
import type { Style } from "mapbox-gl";
import type { Metadata } from "../Metadata";
import type { ApiQueryAbortDecisionData } from "./ApiQueryAbortDecisionData";
import type { RetryState_Transferable } from "./RetryState";
import type { TileGeometry_Transferable } from "./TileGeometry";

export function isFailureMsg<Kind = any, BadValue = any>(v: any): v is FailureMsg<Kind, BadValue> {
  return Object.hasOwn(v, "isFailure") && (v as any).isFailure === true;
}

export type Msg_UpdateAuth = Msg<
  "Msg_UpdateAuth",
  {
    token: string | null;
  }
>;

export type MsgAnswer_UpdateAuth = Msg<"MsgAnswer_UpdateAuth">;

export type MsgAnswer_ChangeToBetaAPI = Msg<"MsgAnswer_ChangeToBetaAPI", any>;

export type Msg_UpdateHardParallelLimit = Msg<
  "Msg_UpdateHardParallelLimit",
  {
    newHardParallelLimit: number;
  }
>;

export type MsgAnswer_UpdateHardParallelLimit = Msg<
  "MsgAnswer_UpdateHardParallelLimit",
  {
    was?: number;
    isNow?: number;
  }
>;

export type Msg_GetBinaryTile = Msg<
  "Msg_GetBinaryTile",
  {
    request: GridRequest_Transferable<CoordinateSystem.EPSG3857>;
    tileGeometry: TileGeometry_Transferable;
    requestRetryState: RetryState_Transferable;
  }
>;

export type MsgAnswer_GetBinaryTile = FailableMsg<
  "MsgAnswer_GetBinaryTile",
  {
    tile: Float32Array2D;
    parameters: [ParameterUnit, ...ParameterUnit[]];
  }
>;

export type Msg_GetWMSTile = Msg<
  "Msg_GetWMSTile",
  {
    request: PixelGridRequest_Transferable<CoordinateSystem>;
    requestRetryState: RetryState_Transferable;
  }
>;

export type Msg_AbortRequests = Msg<
  "Msg_AbortRequests",
  {
    urls: string[];
  }
>;

export type Msg_GetVectorLayerStyle = Msg<
  "Msg_GetVectorLayerStyle",
  {
    // VectorLayerStyleRequest is already transferable, so we don't need to do
    // some extra steps to send it to worker
    request: VectorLayerStyleRequest;
    requestRetryState: RetryState_Transferable;
  }
>;

export type Msg_GetVectorTile = Msg<
  "Msg_GetVectorTile",
  {
    request: VectorTileRequest_Transferable;
    requestRetryState: RetryState_Transferable;
  }
>;

export type MsgAnswer_GetWMSTile = FailableMsg<
  "MsgAnswer_GetWMSTile",
  {
    bitmap: GridPayload<ImageBitmap>;
    parameter: ParameterUnit;
  }
>;

export type MsgAnswer_GetVectorLayerStyle = FailableMsg<
  "MsgAnswer_GetVectorLayerStyle",
  {
    style: Style;
    parameter: ParameterUnit;
  }
>;

// TODO Vector tile typize data
export type MsgAnswer_GetVectorTile = FailableMsg<
  "MsgAnswer_GetVectorTile",
  {
    data: ArrayBuffer;
    parameter: ParameterUnit;
  }
>;

export type Msg_GetIsoLines = Msg<
  "Msg_GetIsoLines",
  {
    request: IsoLinesRequest_Transferable;
    requestRetryState: RetryState_Transferable;
  }
>;

export type MsgAnswer_GetIsoLines = FailableMsg<
  "MsgAnswer_GetIsoLines",
  {
    isoLinesResponse: GeoJSONFeatureCollection;
    parameter: ParameterUnit;
  }
>;

export type Msg_GetWeatherFronts = Msg<
  "Msg_GetWeatherFronts",
  {
    request: WeatherFrontsRequest_Transferable;
    requestRetryState: RetryState_Transferable;
  }
>;

export type MsgAnswer_GetWeatherFronts = FailableMsg<
  "MsgAnswer_GetWeatherFronts",
  {
    weatherFrontsResponse: GeoJSONFeatureCollection;
    parameter: ParameterUnit;
  }
>;

export type Msg_GetLightningList = Msg<
  "Msg_GetLightningList",
  {
    request: LightningListRequest_Transferable<CoordinateSystem.WGS84>;
    requestRetryState: RetryState_Transferable;
  }
>;

export type MsgAnswer_GetLightningList = FailableMsg<
  "MsgAnswer_GetLightningList",
  {
    lightningListResponse: GeoJSONFeatureCollection;
  }
>;

export type Msg_GetWfs_GetFeature = Msg<
  "Msg_GetWfs_GetFeature",
  {
    request: WfsRequest_Transferable<CoordinateSystem.WGS84>;
    requestRetryState: RetryState_Transferable;
  }
>;

export type Msg_GetWfs_GetCapabilities = Msg<
  "Msg_GetWfs_GetCapabilities",
  {
    request: WfsRequest_Transferable<CoordinateSystem.WGS84>;
    requestRetryState: RetryState_Transferable;
  }
>;

export type MsgAnswer_GetWfs = FailableMsg<
  "MsgAnswer_GetWfs",
  {
    /**
     * WFS API response is in XML. The worker should return a converted JSON.
     */
    wfsApiResponseAsJSON: GeoJSONFeatureCollection;
    parameters?: [ParameterUnit, ...ParameterUnit[]];
  }
>;

export type MsgAnswer_GetWfsCapabilities = FailableMsg<
  "MsgAnswer_GetWfsCapabilities",
  {
    /**
     * WFS API response is in XML. The worker should return a converted JSON.
     */
    wfsApiResponseAsJSON: WfsCapabilities;
    parameters?: [ParameterUnit, ...ParameterUnit[]];
  }
>;

export type Msg_GetAvailableTimeframe = Msg<
  "Msg_GetAvailableTimeframe",
  {
    /**
     * Timeframe API response is in csv. The worker should return a converted JSON.
     */
    request: AvailableTimeframeRequest;
  }
>;

export type MsgAnswer_GetAvailableTimeframe = FailableMsg<
  "MsgAnswer_GetAvailableTimeframe",
  {
    /**
     * Timeframe API response is in csv. The worker should return a converted JSON.
     */
    timeframes: AvailableTimeframeResponse_Transferable;
  }
>;

export type Msg_GetInitTime = Msg<
  "Msg_GetInitTime",
  {
    /**
     * Timeframe API response is in csv. The worker should return a converted JSON.
     */
    request: InitialTimeRequest;
  }
>;

export type MsgAnswer_GetInitTime = FailableMsg<
  "MsgAnswer_GetInitTime",
  {
    /**
     * Timeframe API response is in csv. The worker should return a converted JSON.
     */
    initTimes: InitialTimeResponse_Transferable;
  }
>;

export type Msg_GetPointJSON = Msg<
  "Msg_GetPointJSON",
  {
    request: PointRequest_Transferable<CoordinateSystem.WGS84>;
    requestRetryState: RetryState_Transferable;
    metadata: Metadata;
  }
>;

export type MsgAnswer_GetPointJSON = FailableMsg<
  "MsgAnswer_GetPointJSON",
  {
    jsonApiResponse: JSONResponseBody;
    parameters: [ParameterUnit, ...ParameterUnit[]];
  }
>;

export type Msg_GetPolygonJSON = Msg<
  "Msg_GetPolygonJSON",
  {
    request: PolygonRequest_Transferable<CoordinateSystem.WGS84>;
    requestRetryState: RetryState_Transferable;
    metadata: Metadata;
  }
>;

export type MsgAnswer_GetPolygonJSON = FailableMsg<
  "MsgAnswer_GetPolygonJSON",
  {
    jsonApiResponse: JSONResponseBody;
    parameters: [ParameterUnit, ...ParameterUnit[]];
  }
>;

export type MsgAnswer_GetLineStringJSON = FailableMsg<
  "MsgAnswer_GetLineStringJSON",
  {
    jsonApiResponse: JSONResponseBody;
    parameters: [ParameterUnit, ...ParameterUnit[]];
  }
>;

export type Msg_GetPlot = Msg<
  "Msg_GetPlot",
  {
    // General type here, as typescript can't transfer type between webworker
    request: PlotRequest<{ request: any }>;
    requestRetryState: RetryState_Transferable;
  }
>;

export type MsgAnswer_GetPlot = FailableMsg<
  "MsgAnswer_GetPlot",
  {
    response: unknown;
  }
>;

export type Msg_GetJSON = Msg<
  "Msg_GetJSON",
  {
    request: GridRequest_Transferable<CoordinateSystem.WGS84>;
    requestRetryState: RetryState_Transferable;
  }
>;

export type MsgAnswer_GetJSON = FailableMsg<
  "MsgAnswer_GetJSON",
  {
    jsonApiResponse: JSONResponseBody;
    parameters?: [ParameterUnit, ...ParameterUnit[]];
  }
>;

export type Msg_GetUserStats = Msg<"Msg_GetUserStats">;

export type MsgAnswer_GetUserStats = FailableMsg<
  "MsgAnswer_GetUserStats",
  {
    userStats: UserStatsResponse;
  }
>;

export type Msg_GetLegend = Msg<
  "Msg_GetLegend",
  {
    request: LegendRequest;
  }
>;
export type MsgAnswer_GetLegend = FailableMsg<
  "MsgAnswer_GetLegend",
  {
    legend: string;
  }
>;

export type Msg_GetColorMap = Msg<
  "Msg_GetColorMap",
  {
    request: ColorMapRequest;
  }
>;

export type MsgAnswer_GetColorMap = FailableMsg<
  "MsgAnswer_GetColorMap",
  {
    colormap: ColorMapResponse_Transferable;
  }
>;

export type Msg_GetMetars = Msg<
  "Msg_GetMetars",
  {
    request: MetarsRequest_Transferable;
  }
>;

export type MsgAnswer_GetMetars = FailableMsg<"MsgAnswer_GetMetars", MetarsResponse_Transferable>;

export type Msg_UpdateAbortController = Msg<
  "Msg_UpdateAbortController",
  {
    abortDecisionDataSet: ApiQueryAbortDecisionData[];
  }
>;

export type Msg_ChangeToBetaAPI = Msg<
  "Msg_ChangeToBetaAPI",
  {
    apiUrls: string[];
  }
>;

export type MsgAnswer_UpdateAbortController = Msg<"MsgAnswer_UpdateAbortController">;

export type ApiQueryPool_MsgToWorkerThread =
  | Msg_ChangeToBetaAPI
  | Msg_UpdateAuth
  // | Msg_GetBinaryTile
  | Msg_GetWMSTile
  | Msg_GetVectorLayerStyle
  | Msg_GetVectorTile
  | Msg_GetIsoLines
  | Msg_GetWeatherFronts
  | Msg_GetLightningList
  | Msg_GetAvailableTimeframe
  | Msg_GetInitTime
  | Msg_GetPointJSON
  | Msg_GetPolygonJSON
  | Msg_GetPlot
  | Msg_GetJSON
  | Msg_GetUserStats
  | Msg_UpdateHardParallelLimit
  | Msg_GetColorMap
  | Msg_GetLegend
  | Msg_UpdateAbortController
  | Msg_GetWfs_GetFeature
  | Msg_GetWfs_GetCapabilities
  | Msg_GetMetars

  // Abort request messages
  | Msg_AbortRequests;

export type ApiQueryPool_MsgToPool =
  | MsgAnswer_ChangeToBetaAPI
  | MsgAnswer_UpdateAuth
  // | MsgAnswer_GetBinaryTile
  | MsgAnswer_GetIsoLines
  | MsgAnswer_GetWeatherFronts
  | MsgAnswer_GetLightningList
  | MsgAnswer_GetAvailableTimeframe
  | MsgAnswer_GetInitTime
  | MsgAnswer_GetPointJSON
  | MsgAnswer_GetPolygonJSON
  | MsgAnswer_GetPlot
  | MsgAnswer_GetJSON
  | MsgAnswer_GetUserStats
  | MsgAnswer_UpdateHardParallelLimit
  | MsgAnswer_GetColorMap
  | MsgAnswer_GetLegend
  | MsgAnswer_UpdateAbortController
  | MsgAnswer_GetWMSTile
  | MsgAnswer_GetVectorLayerStyle
  | MsgAnswer_GetVectorTile
  | MsgAnswer_GetWfs
  | MsgAnswer_GetWfsCapabilities
  | MsgAnswer_GetMetars;

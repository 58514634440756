/**
 * Modifies grid requests before they are dispatched to meet a target latency by observing the current network state.
 */

import type { MeteomaticsApi } from "../MeteomaticsApi";
import { minimalApiGridDimension } from "../constraints";
import type { CoordinateSystem, GridDimension, GridRequest } from "../models";
import { performanceRecorder } from "../performanceIntrospection/PerformanceRecorder";
import { clamp } from "../utility/clamp";
import { type Abortable, execute } from "./Abortable";
import { RequestAdaptor } from "./RequestAdaptor";

// TODO: enable and disable automatic quality scaling on a per tile basis? e.g. to render videos
// parallel to using the app?
// The AbortController also needs access to tile information. We work around that issue by
// building a controller per tile. So maybe we should just build an abstraction that can
// save middleware/requestadaptor specific information along each request.
export class AutomaticQualityScaling extends RequestAdaptor<GridRequest<any>, any> {
  // retained here for debugging purposes
  public lastEstimate = {
    estimatedTimeToFinishQueueMillis: Number.NaN,
    scaleFactor1D: Number.NaN,
    scaleFactor2D: Number.NaN,
    original: { width: Number.NaN, height: Number.NaN },
    adapted: { width: Number.NaN, height: Number.NaN },
  };

  private lockedScalingFactor2D: number | null = null;

  // TODO: `minimalGridSize` poses some correctness problems since it might upscale from 1xN or Nx1 to NxM > 2x2
  //        but the contract of this middleware is to only scale down.
  constructor(
    public desiredTimeToFinishQueueMillis: number,
    public minimalGridSize: GridDimension = minimalApiGridDimension,
  ) {
    super();
  }

  /**
   * Disable automatic quality scaling and use the supplied scaling factor independent of
   * network performance.
   */
  lockScalingFactor2D(scalingFactor: number | null = 1.0) {
    // TODO: we have to refetch data if the locked scale factor is set to a higher level
    this.lockedScalingFactor2D = scalingFactor;
  }

  lockScalingFactor1D(scalingFactor = 1.0) {
    this.lockedScalingFactor2D = Math.sqrt(scalingFactor);
  }

  stats(): Record<string, any> {
    if (this.lockedScalingFactor2D != null) {
      return {
        isLocked: true,
        scalingFactor2D: this.lockedScalingFactor2D.toFixed(3),
        original: `${this.lastEstimate.original.width}x${this.lastEstimate.original.height}`,
        adapted: `${this.lastEstimate.adapted.width}x${this.lastEstimate.adapted.height}`,
      };
    }

    return {
      estimatedTimeToFinishQueue: `${this.lastEstimate.estimatedTimeToFinishQueueMillis.toFixed(2)}ms`,
      scaleFactor1D: this.lastEstimate.scaleFactor1D.toFixed(3),
      scaleFactor2D: this.lastEstimate.scaleFactor2D.toFixed(3),
      original: `${this.lastEstimate.original.width}x${this.lastEstimate.original.height}`,
      adapted: `${this.lastEstimate.adapted.width}x${this.lastEstimate.adapted.height}`,
    };
  }

  getScalingFactor2D() {
    // TODO: this should probably be smoothed
    return this.lockedScalingFactor2D ?? this.lastEstimate.scaleFactor2D;
  }

  private automaticScalingFactor2D<C extends CoordinateSystem>(
    api: MeteomaticsApi<any>,
    request: GridRequest<C>,
  ): number {
    // roughly estimate the time to finish the current queue without knowing
    // the actual scheduling strategy of the connection manager

    // we assume
    // - the queue is full here since we only look at waiting req
    // - that all req currently scheduled finish now
    // - that the queue length significantly larger than the maximal number of parallel connections

    // (our estimate is immediate, all inflight requests were already scaled, so looking at the future should be enough)

    let estimatedTimeToFinishQueueMillis = 0;
    for (const [sequence, count] of Object.entries(performanceRecorder.globalStatistics.countGridPointsWaiting)) {
      estimatedTimeToFinishQueueMillis +=
        performanceRecorder.estimateRoundtripTime(sequence, count)?.estimatedRoundtripTime ?? 0;
    }

    estimatedTimeToFinishQueueMillis /= api.connectionManagement.getMaxConcurrentConnections(); // account for parallelization
    const scaleFactor1D = this.desiredTimeToFinishQueueMillis / estimatedTimeToFinishQueueMillis;
    const scaleFactor2D = Math.sqrt(scaleFactor1D);

    this.lastEstimate = {
      estimatedTimeToFinishQueueMillis,
      scaleFactor1D,
      scaleFactor2D,
      original: { width: Number.NaN, height: Number.NaN },
      adapted: { width: Number.NaN, height: Number.NaN },
    };

    return scaleFactor2D;
  }

  enterStateWaiting(api: MeteomaticsApi<any>, request: GridRequest<any>): Abortable<GridRequest<any>> {
    const scaleFactor2D = this.lockedScalingFactor2D ?? this.automaticScalingFactor2D(api, request);

    const scaledRequest = {
      ...request,
      width: this.scale(request.width, scaleFactor2D, this.minimalGridSize.width),
      height: this.scale(request.height, scaleFactor2D, this.minimalGridSize.height),
    };

    this.lastEstimate.original = { width: request.width, height: request.height };
    this.lastEstimate.adapted = { width: scaledRequest.width, height: scaledRequest.height };

    // console.log(
    //   `time to drain: ${this.lastEstimate.estimatedTimeToFinishQueueMillis}ms, scale2D: ${scaleFactor2D}x, ${request.width}x${request.height} -> ${scaledRequest.width}x${scaledRequest.height}`
    // );

    return execute(scaledRequest);
  }

  private scale(val: number, scaleFactor: number, minVal: number) {
    let scaled = Math.round(val * scaleFactor);

    // we only scale down by default, we could add an option to enable upscaling in the future
    // also avoid clamping out of the valid range
    scaled = clamp(scaled, minVal, val);

    return scaled;
  }
}

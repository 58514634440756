import type { SvgComponentProps } from "./MapSvg";

const NoteSvg: React.FunctionComponent<SvgComponentProps> = (props) => (
  <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M28.3677 19.5C28.3677 20.6 27.4677 21.5 26.3677 21.5H8.36768C7.26768 21.5 6.36768 20.6 6.36768 19.5C6.36768 18.4 7.26768 17.5 8.36768 17.5H26.3677C27.4677 17.5 28.3677 18.4 28.3677 19.5ZM6.36768 11.5C6.36768 12.6 7.26768 13.5 8.36768 13.5H26.3677C27.4677 13.5 28.3677 12.6 28.3677 11.5C28.3677 10.4 27.4677 9.5 26.3677 9.5H8.36768C7.26768 9.5 6.36768 10.4 6.36768 11.5ZM20.3677 27.5C20.3677 26.4 19.4677 25.5 18.3677 25.5H8.36768C7.26768 25.5 6.36768 26.4 6.36768 27.5C6.36768 28.6 7.26768 29.5 8.36768 29.5H18.3677C19.4677 29.5 20.3677 28.6 20.3677 27.5ZM36.3877 23.24L37.8077 21.82C38.5877 21.04 39.8477 21.04 40.6277 21.82L42.0477 23.24C42.8277 24.02 42.8277 25.28 42.0477 26.06L40.6277 27.48L36.3877 23.24ZM34.9677 24.66L24.6477 34.98C24.4677 35.16 24.3677 35.4 24.3677 35.68V38.5C24.3677 39.06 24.8077 39.5 25.3677 39.5H28.1877C28.4477 39.5 28.7077 39.4 28.8877 39.2L39.2077 28.88L34.9677 24.66Z"
      fill="#272A31"
    />
  </svg>
);

export default NoteSvg;

import type { TilePosition } from "@/layers/wind/types";
import type { LngLatBounds } from "mapbox-gl";

export function createTexture(
  gl: WebGLRenderingContext,
  filter: any,
  data: TexImageSource | Uint8Array,
  width: number,
  height: number,
) {
  const texture = gl.createTexture();
  gl.bindTexture(gl.TEXTURE_2D, texture);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, filter);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, filter);
  if (data instanceof Uint8Array) {
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, width, height, 0, gl.RGBA, gl.UNSIGNED_BYTE, data);
  } else {
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, data);
  }
  gl.bindTexture(gl.TEXTURE_2D, null);
  return texture;
}

/**
 * Utility to bind the texture (image) from JavaScript to the WebGL context
 * so the texture becomes available to the shader.
 * Given a unit value, bind the texture on the unit.
 *
 * "unit" is essentially the location index of where the texture data is stored.
 * By specifying different unit, you can store and retrieve multiple textures.
 *
 * Ref: https://webglfundamentals.org/webgl/lessons/webgl-texture-units.html#:~:text=Texture%20units%20are%20a%20global,textureUnits%3A%20%5B
 */
export function bindTexture(gl: WebGLRenderingContext, texture: WebGLTexture | null, unit: number) {
  gl.activeTexture(gl.TEXTURE0 + unit);
  gl.bindTexture(gl.TEXTURE_2D, texture);
}

export function createBuffer(gl: WebGLRenderingContext, data: any) {
  const buffer = gl.createBuffer();
  gl.bindBuffer(gl.ARRAY_BUFFER, buffer);
  gl.bufferData(gl.ARRAY_BUFFER, data, gl.STATIC_DRAW);
  return buffer;
}

/**
 * Utility to bind the data from JavaScript to WebGL program (shaders)
 */
export function bindAttribute(
  gl: WebGLRenderingContext,
  buffer: WebGLFramebuffer | null,
  /**
   * The location (index) of the parameter within a program where the data is bound to.
   * Esentially this is the "name" of the parameter within the shader program.
   */
  attribute: number,
  /**
   * The number of components per attribute. e.g: If you're dealing with 2D, this will be 2 (x, y).
   */
  numComponents: number,
) {
  gl.bindBuffer(gl.ARRAY_BUFFER, buffer);
  // Select the the data location within the program and configures
  gl.enableVertexAttribArray(attribute);
  // Specify how the data in the buffer is accessed and sent to the shader
  gl.vertexAttribPointer(attribute, numComponents, gl.FLOAT, false, 0, 0);
}

/**
 * Bind a buffer to the given WegGL context.
 * If a texture is given, bind the texture to the given framebuffer
 * so the output to the subsequent GL commands will be written to the texture.
 */
export function bindFramebuffer(
  gl: WebGLRenderingContext,
  /**
   * The buffer where the subsequent GL commands will be written.
   */
  framebuffer: WebGLFramebuffer | null,
  /**
   * The texture where given framebuffer's data will be drawned.
   */
  texture: WebGLTexture | null,
) {
  gl.bindFramebuffer(gl.FRAMEBUFFER, framebuffer);
  if (texture) {
    // If provided, the texture gets attached to the framebuffer's color attachment point
    // so the output will be written to the texture as 2D.
    gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, texture, 0);
  }
}

export function createEmptyPixels(width: number, height: number) {
  return new Uint8Array(width * height * 4);
}

// Draw tile into buffer, by taking tile transformation to adjust position and size
export function createInterleavedQuadBuffer({ x, y, z }: TilePosition): number[] {
  const normalized_x = -1 + 2 * (x / 2 ** z);
  const normalized_y = 1 - 2 * (y / 2 ** z);
  const normalized_tile_size = 2 / 2 ** z;

  // Vertex coordinates adjusted based on the position and zoom level
  const left = normalized_x;
  const right = normalized_x + normalized_tile_size;
  const top = normalized_y;
  const bottom = normalized_y - normalized_tile_size;

  // Texture coordinates
  const texLeft = 0.0;
  const texRight = 1.0;
  const texTop = 0.0;
  const texBottom = 1.0;

  // Return interleaved data
  return [
    left,
    top,
    texLeft,
    texTop, // Top-left
    left,
    bottom,
    texLeft,
    texBottom, // Bottom-left
    right,
    top,
    texRight,
    texTop, // Top-right

    right,
    top,
    texRight,
    texTop, // Top-right
    left,
    bottom,
    texLeft,
    texBottom, // Bottom-left
    right,
    bottom,
    texRight,
    texBottom, // Bottom-right
  ];
}

// Sample tile from buffer and draw onto second buffer, by taking full viewport
export function createInterleavedQuadBufferForTile({ x, y, z }: TilePosition): number[] {
  // Calculate the texture coordinates based on tile position and zoom level
  const texLeft = x / 2 ** z;
  const texRight = (x + 1) / 2 ** z;
  const texTop = (y + 1) / 2 ** z;
  const texBottom = y / 2 ** z;

  // Texture coordinates
  const left = -1;
  const right = 1;
  const top = 1;
  const bottom = -1;

  // Return interleaved data
  return [
    left,
    top,
    texLeft,
    texTop, // Top-left
    left,
    bottom,
    texLeft,
    texBottom, // Bottom-left
    right,
    top,
    texRight,
    texTop, // Top-right

    right,
    top,
    texRight,
    texTop, // Top-right
    left,
    bottom,
    texLeft,
    texBottom, // Bottom-left
    right,
    bottom,
    texRight,
    texBottom, // Bottom-right
  ];
}

// Creates array of all tiles, that are visible on zoom level - z
export function generateAllTilesForZoomLevel(z: number): TilePosition[] {
  const numberOfTiles = 2 ** z;
  const tiles: TilePosition[] = [];

  for (let x = 0; x < numberOfTiles; x++) {
    for (let y = 0; y < numberOfTiles; y++) {
      tiles.push({ x: x, y: y, z: z });
    }
  }

  return tiles;
}

export function createPlane(
  width: number,
  height: number,
  widthSegments: number,
  heightSegments: number,
  bounds: LngLatBounds,
) {
  const vertices: number[] = [];
  const uvs: number[] = [];
  const indices: number[] = [];

  const minLng = bounds.getWest();
  const maxLng = bounds.getEast();
  const minLat = bounds.getSouth();
  const maxLat = bounds.getNorth();

  const latLngAttributes: number[] = [];

  for (let i = 0; i <= heightSegments; i++) {
    const fractionY = i / heightSegments;
    const latitude = minLat + (maxLat - minLat) * fractionY;
    const y: number = (fractionY - 0.5) * height;

    for (let j = 0; j <= widthSegments; j++) {
      const fractionX = j / widthSegments;
      const longitude = minLng + (maxLng - minLng) * fractionX;
      const x: number = (fractionX - 0.5) * width;

      // Lat/Lng for each vertice
      latLngAttributes.push(latitude, longitude);

      // Vertex
      vertices.push(x, y, 0);

      // UV
      uvs.push(j / widthSegments);
      uvs.push(i / heightSegments);
    }
  }

  // Generate indices (two triangles per grid square)
  for (let i = 0; i < heightSegments; i++) {
    for (let j = 0; j < widthSegments; j++) {
      const a = i * (widthSegments + 1) + j;
      const b = a + widthSegments + 1;
      const c = a + 1;
      const d = b + 1;

      // First triangle
      indices.push(a, b, d);

      // Second triangle
      indices.push(a, d, c);
    }
  }

  return {
    vertices,
    indices,
    uvs,

    latLngAttributes,
  };
}

export function bindPlaneBuffers(
  gl: WebGLRenderingContext,
  vertices: number[],
  indices: number[],
  uvs: number[],
  latLngAttributes: number[],
) {
  // Create and bind the vertex buffer
  const vertexBuffer: WebGLBuffer | null = gl.createBuffer();
  gl.bindBuffer(gl.ARRAY_BUFFER, vertexBuffer);
  gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(vertices), gl.STATIC_DRAW);

  // Create and bind the UV buffer
  const uvBuffer: WebGLBuffer | null = gl.createBuffer();
  gl.bindBuffer(gl.ARRAY_BUFFER, uvBuffer);
  gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(uvs), gl.STATIC_DRAW);

  // Create and bind the index buffer
  const indexBuffer: WebGLBuffer | null = gl.createBuffer();
  gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, indexBuffer);
  gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, new Uint16Array(indices), gl.STATIC_DRAW);

  const latLngBuffer: WebGLBuffer | null = gl.createBuffer();
  gl.bindBuffer(gl.ARRAY_BUFFER, latLngBuffer);
  gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(latLngAttributes), gl.STATIC_DRAW);

  return {
    vertexBuffer,
    uvBuffer,
    indexBuffer,
    latLngBuffer,

    indexCount: indices.length,
  };
}

import type { MeteomaticsApi } from "../MeteomaticsApi";
import { type CropResult, crop, intersectWeatherModelBoundingBox, withPadding32f } from "../bounds";
import { type Area, CoordinateSystem, type GridRequest } from "../models";
import { UNAVAILABLE } from "../normalization";
import type { Float32Area } from "../request-splitters";
import { type Abortable, abort, execute, isAborted } from "./Abortable";
import { RequestAdaptor } from "./RequestAdaptor";

const ABORT_REASON_EMPTY = "ABORT_REASON_EMPTY";

/**
 * Reduces the area of requests by intersecting them with a bounding box.
 * The response is later padded with a fill value to ensure the response has
 * the requested size.
 *
 * Primary use case for this middleware is to reduce the size of requests to the
 * valid area of a weather model.
 */
export class Crop<C extends CoordinateSystem> extends RequestAdaptor<GridRequest<C>, Float32Area> {
  private croppedTile: CropResult<CoordinateSystem.WGS84> | null = null;

  constructor(
    readonly modelBounds: Area<CoordinateSystem.WGS84>,
    fill: number = UNAVAILABLE,
  ) {
    super();
  }

  enterStateWaiting(_: MeteomaticsApi<any>, gridRequest_: GridRequest<C>): Abortable<GridRequest<C>> {
    const gridRequest = { ...gridRequest_, area: gridRequest_.area.project(CoordinateSystem.WGS84) };
    const intersectionModelBounds = intersectWeatherModelBoundingBox(gridRequest, this.modelBounds);

    // as an optimization, out of bounds tiles, which are all identical, are deduplicated
    if (intersectionModelBounds.kind === "OutOfBounds") {
      return abort(ABORT_REASON_EMPTY);
    }

    if (intersectionModelBounds.kind === "PartiallyOutOfBounds") {
      const croppedWgs84 = crop(gridRequest, intersectionModelBounds.intersection, this.modelBounds);
      this.croppedTile = croppedWgs84;
      const cropped = {
        ...croppedWgs84.gridRequest,
        area: croppedWgs84.gridRequest.area.project(gridRequest_.area.crs),
      };
      return execute(cropped);
    }

    return execute(gridRequest_);
  }

  enterStateFinished(_: MeteomaticsApi<any>, response: Float32Area): Float32Area {
    if (this.croppedTile) {
      // TODO: `crop` might have pixel snapped the requested area to avoid resampling during padding.
      // as a result, the `sampleArea` might be slightly shifted compared to the original sample area
      // of the user constructed request. But our assumption here is, that pixel snapping only moved
      // the grid within the region by larger amounts, snapping on the bounds/limits of the original
      // area should only be required to eliminate artefacts from floating point imprecisions.
      //
      // (this assumption is only true for our tiling scheme, and not in general, so this needs some
      // fixing before publication of the bindings)
      const sampleAreaAfterPadding = this.croppedTile.originalGridRequest.area.applySamplingStrategy(
        this.croppedTile.originalGridRequest.sampling,
        this.croppedTile.originalGridRequest,
      );

      return {
        sampleArea: sampleAreaAfterPadding,
        // TODO: this seems to take about 2 to 5ms for a 512x512 tile, which seems rather long.
        ...withPadding32f(response.payload, response, {
          ...this.croppedTile.padding,

          // the binary response tile is upside down, put the padding upside down.
          top: this.croppedTile.padding.bottom,
          bottom: this.croppedTile.padding.top,
        }),
      };
    }

    return response;
  }
}

export function isAbortReasonEmpty(abortSignal: Abortable<any>): boolean {
  return isAborted(abortSignal) && abortSignal.reason === ABORT_REASON_EMPTY;
}

import { PlaybackContext } from "@/animation/PlaybackContext";
import { useNewPlaybackContext } from "@/animation/useNewPlaybackContext";
import { VideoExportProvider } from "@/animation/video-generator/components/VideoExportContext";
import { fetchTab } from "@/api/hooks/tab";
import { setTitle } from "@/effects";
import { Grid } from "@/grid";
import { GridCellHighlightProvider } from "@/grid/GridCellHighlightProvider";
import { LoadingStateMachineProvider } from "@/models/loading-state/loadingStateMachineContext";
import { MapDrawingMasterMachineProvider } from "@/models/map-drawing-tools/reactContext";
import { MapInteractionStateMachineProvider } from "@/models/map-interaction/mapInteractionStateContext";
import { TempLayerStateMachineProvider } from "@/models/temporal-layer/tempLayerStateMachineContext";
import { useTimeServiceSync } from "@/models/time-control/timeStateHooks";
import { ContextMenuProvider } from "@/overlay/components/ContextMenu";
import { PrefetchController } from "@/prefetch-controller";
import { useAppDispatch } from "@/reducer";
import { PopupPlotProvider } from "@/reducer/plot";
import { WorkspaceKeyShortcutProvider } from "@/utility/key-shortcuts/WorkspaceKeyShortcutProvider";
import { useSearchEngineReady } from "@/weather-parameters";
import { t } from "@lingui/macro";
import { useProfileAndTabParams } from "metx-core/src/hooks";
import { type ComponentType, Suspense, lazy, useEffect, useMemo } from "react";
import "./workspace.scss";

/**
 * A GUI overlay consists out of two parts
 * - the ViewportOverlay that is drawn on top of the whole browser viewport (across all maps and plots)
 * - the MapOverlay that is drawn on top of each map.
 *
 * The root of the overlay package is expected to export an object with both these properties. If one
 * of the overlay types is not needed, just return an empty component as follows: `export () => <></>`.
 *
 */
type OverlayModule = {
  ViewportOverlay: ComponentType<any>;
  MapOverlay: ComponentType<any>;
};

// TODO: this file needs a massive cleanup to improve the loading screen
export default function WorkspacePage() {
  const { profileId, tabId } = useProfileAndTabParams();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchTab({ tabId }));
  }, [dispatch, tabId]);

  // in case of the workspace, the title is only shown until the overlay loads. the overlay should set the title afterwards.
  useEffect(() => {
    setTitle(t({ id: "workspace.title.whileLoading", message: "Workspace" }));
  }, []);

  // TODO move overlay name to profile, previously it was on account, but always "default"
  const overlayName: string = "default";
  const playbackValue = useNewPlaybackContext();

  useTimeServiceSync({ profileId });

  // we arbitrarily assert here that the requests that were racing to fetch parameter and model info
  // have already loaded. This lets users login while this info is in-flight. On the other hand, the whole
  // <Grid> of `<Map>`s needs access to weather parameters. So, without placing this check here, the user
  // will end up with a rather blank page since both overlay and grid initialize lazily.
  const searchEngineReady = useSearchEngineReady();

  // TODO: clean error handling if import of overlay fails
  const { ViewportOverlay, MapOverlay } = useMemo(() => {
    const overlayModule: Promise<OverlayModule> = import(
      /* webpackChunkName: "overlay-[request]" */ `@/overlay/overlays/${overlayName}`
    );
    const emptyComponentModule = { default: () => <></> };
    const errorComponentModule = { default: () => <div>Loading overlay failed</div> };

    return {
      ViewportOverlay: lazy(() =>
        overlayModule.then(({ ViewportOverlay }) => ({ default: ViewportOverlay })).catch(() => errorComponentModule),
      ),
      MapOverlay: lazy(() =>
        overlayModule.then(({ MapOverlay }) => ({ default: MapOverlay })).catch(() => emptyComponentModule),
      ),
    };
  }, [overlayName]);

  if (!searchEngineReady || playbackValue == null) {
    return <mm-loader size="xlarge" />;
  }

  return (
    <div className="page page--workspace cover-window overlay">
      <PlaybackContext.Provider value={playbackValue}>
        <VideoExportProvider>
          <PrefetchController>
            <GridCellHighlightProvider>
              <MapDrawingMasterMachineProvider>
                <MapInteractionStateMachineProvider>
                  <LoadingStateMachineProvider>
                    <TempLayerStateMachineProvider>
                      <WorkspaceKeyShortcutProvider>
                        <PopupPlotProvider>
                          <div className="overlay__layer">
                            <ContextMenuProvider>
                              <Grid mapOverlay={MapOverlay} tabId={tabId} />
                            </ContextMenuProvider>
                          </div>
                          <div className="gui-overlay overlay__layer">
                            <Suspense fallback={<mm-loader size="xlarge" />}>
                              <ViewportOverlay />
                            </Suspense>
                          </div>
                        </PopupPlotProvider>
                      </WorkspaceKeyShortcutProvider>
                    </TempLayerStateMachineProvider>
                  </LoadingStateMachineProvider>
                </MapInteractionStateMachineProvider>
              </MapDrawingMasterMachineProvider>
            </GridCellHighlightProvider>
          </PrefetchController>
        </VideoExportProvider>
      </PlaybackContext.Provider>
    </div>
  );
}

export interface ISorter<T> {
  property: Extract<keyof T, string | number | Date>;
  isDescending: boolean;
}

export function genericSort<T>(objectA: T, objectB: T, sorter: ISorter<T>) {
  const result = () => {
    const variableA = objectA[sorter.property];
    const variableB = objectB[sorter.property];

    let newVariableA: any;
    let newVariableB: any;
    switch (typeof variableA) {
      // only handle string cause compare need to lowercase
      case "string":
        newVariableA = String(variableA).toLowerCase();
        newVariableB = String(variableB).toLowerCase();
        break;
      case "number":
        return variableA - Number(variableB);
      default:
        newVariableA = variableA;
        newVariableB = variableB;
        break;
    }

    if (newVariableA > newVariableB) {
      return -1;
    }
    if (newVariableA < newVariableB) {
      return 1;
    }
    return 0;
  };

  return sorter.isDescending ? result() * -1 : result();
}

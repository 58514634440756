import { MapInteractionEventType } from "@/models/map-interaction/mapInteractionStateEvents";
import { useMapInteractionService } from "@/models/map-interaction/mapInteractionStateHooks";
import { Button } from "@/overlay/components/CommonParts/buttons";
import type { Coordinate } from "@mm/api.meteomatics.com";

type MapCoordSelectBtnProps = {
  mapId: number;
  onCoordChange: (coord: Coordinate) => void;
};

/**
 * Triggers the mode switch on the state machine.
 */
export function MapCoordSelectBtn(props: MapCoordSelectBtnProps) {
  const { send } = useMapInteractionService();

  const onClick = () => {
    // Note: For future development, additional map interaction logic
    // should be written in the state machine, instead in components to encapsulate
    // the complexity in the state machine.
    send({
      type: MapInteractionEventType.setCoordSelect, // Triggers the state machine to switch to COORD_SELECT mode.
      mapId: props.mapId,
      onCoordinateSelect: props.onCoordChange, // Called upon a map click.
    });
  };

  return (
    // Note: We specify type="button". Otherwise onClick event gets triggered with a form submission,
    // which is currently used in the parent component
    <Button icon="my_location" onClick={onClick} type="button" />
  );
}

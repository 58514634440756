import Logger from "logging";

const logger = Logger.fromFilename(__filename);

/**
 * Given a pixelated canvas area (height and width) and an arbitrary distanceBetweenItem number representing the distance between each item. This calculates the number of vertical and horizontal grids by dividing the specified width and height of the rectangle. First the vertical grid number and then with the ratio also the horizontal grid number
 */
export function computeGridDimensionFromStep(
  canvas: { height: number; width: number },
  distanceBetweenItem: number,
): {
  horizontalGridNum: number;
  verticalGridNum: number;
  gridEdgeLength: number;
} {
  const height = canvas.height / window.devicePixelRatio;
  const width = canvas.width / window.devicePixelRatio;
  const aspectRatio = height / width;
  // 1. set vertical grid number by dividing the height by distanceBetweenItem
  const verticalGridNum = Math.floor(height / distanceBetweenItem);
  // 2. use the ratio to get also the horizontal grid number
  const horizontalGridNum = Math.floor(verticalGridNum / aspectRatio);
  const gridEdgeLength = height / verticalGridNum;

  if (distanceBetweenItem === 0) {
    /**
     * This is a workaround to not through out warnings on each call.
     * The isRebuffering function calls this with createRequest continuesly times
     * This line result in Infinity when distanceBetweenItem = 0 what then creates the warnings.
     * const verticalGridNum = Math.floor(height / distanceBetweenItem);
     * TODO: refactor this function and the computeBoundingBox
     */
    return { horizontalGridNum: 0, verticalGridNum: 0, gridEdgeLength: 0 };
  }

  return autoScaleGrids({ horizontalGridNum, verticalGridNum, gridEdgeLength });
}

export function computeGridDimensionFromStepAsHorizontal(
  canvas: { height: number; width: number },
  step: number,
): {
  horizontalGridNum: number;
  verticalGridNum: number;
  gridEdgeLength: number;
} {
  const height = canvas.height / window.devicePixelRatio;
  const width = canvas.width / window.devicePixelRatio;

  // Calculate the aspect ratio of the canvas
  const aspectRatio = width / height;

  // Set "step" as the number of grid divisions you want horizontally,
  // then calculate how many squares (grids) of that size can fit vertically
  const horizontalGridNum = step;
  const verticalGridNum = Math.floor(horizontalGridNum / aspectRatio); //Math.floor(horizontalGridNum * aspectRatio);

  // Calculate the length of each grid edge
  const gridEdgeLength = width / horizontalGridNum;
  return autoScaleGrids({ horizontalGridNum, verticalGridNum, gridEdgeLength });
}

function autoScaleGrids<T extends { horizontalGridNum: number; verticalGridNum: number }>(grid: T): T {
  // API has the limitation for the number of data points for grid requests.
  // It should usually not happen as long as the grid request is constructed properly to keep the
  // reasonable number of data points, but we insert a precaution scaling just incase.
  // Past errors:
  // - 150x150"ERROR","message":"The HTTP POST content size is currently limited to 262144. Your query is 473725","error code":"413"}
  // - "Maximum number of data points in one query limited to 1000000 your query contains 1913064"
  if (grid.horizontalGridNum * grid.verticalGridNum >= 1000000) {
    logger.warn("Automatically scaling down grid request resolution.");
    const horizontalGridNum = grid.horizontalGridNum / 10;
    const verticalGridNum = grid.horizontalGridNum / 10;
    return { ...grid, horizontalGridNum, verticalGridNum };
  }
  return grid;
}

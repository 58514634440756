import type {
  MapboxIconsPool_MsgToPool,
  MapboxIconsPool_MsgToWorkerThread,
} from "@/threads/MapboxIconsThread/MapboxIconsThreadPoolMsg";
import type { LoadIconsResponse } from "@/threads/MapboxIconsThread/types";
import { ThreadPool } from "threading-lib";

export class MapboxIconsThreadPool extends ThreadPool<MapboxIconsPool_MsgToWorkerThread, MapboxIconsPool_MsgToPool> {
  constructor() {
    super(1);
  }

  newThread(_threadId: number): Worker {
    return new Worker(new URL("./MapboxIconsThreadPool.worker.ts", import.meta.url), { type: "module" });
  }

  multiplex(
    query: { resolve: (value: any) => void; reject: (reason?: any) => void },
    msg: MapboxIconsPool_MsgToPool,
  ): void {
    switch (msg.kind) {
      case "MsgAnswer_LoadAndSliceSprite": {
        query.resolve(msg.payload);
        break;
      }
    }
  }

  loadAndSliceSprite(id: string, dpi: number) {
    return this.send<LoadIconsResponse>({
      kind: "Msg_LoadAndSliceSprite",
      payload: {
        dpi,
        id,
      },
    });
  }
}

export const mapboxIconsThreadPool = new MapboxIconsThreadPool();

import { type WindowIdentityProps, type WindowKind, useDesktop } from "@/overlay/components";
import { Window, WindowSection, closeAction } from "@/overlay/components/window";
import type { PoiDataGrid } from "@/threads/DataProcessingThread/types";
import { t } from "@lingui/macro";
import { PoiTable } from "./PoITable";

import "./style.scss";

export type PoiManagerWindowProps = {
  mapId: number;
  initialData: PoiDataGrid;
  onUpdate?: (data: PoiDataGrid) => void;
};

export default function PoiManagerWindow(props: WindowIdentityProps<WindowKind.PoiManagerWindow>) {
  const desktop = useDesktop();
  const mapId = props.customProps.mapId;

  const actions = [
    closeAction(() => desktop.dispatch({ type: "setWindowVisibility", windowId: props.instanceId, isVisible: false })),
  ];

  return (
    <Window
      kind="poi-manager"
      title={mapId ? t`Points data manager` : t`Location manager`}
      {...props}
      actions={actions}
    >
      <WindowSection>
        <PoiTable
          mapId={mapId}
          // biome-ignore lint/style/noNonNullAssertion: Cant be nullish
          initialData={props.customProps.initialData!}
          onChange={(data) => {
            props.customProps.onUpdate?.(data);
          }}
        />
      </WindowSection>
    </Window>
  );
}

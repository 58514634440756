import type { ActiveLayers } from "@/overlay/components/PopupPlotWindow";
import { getModel } from "@/overlay/components/SmartForm/SmartFormUtils/FormHooks/useModel";
import type { PlotSeriesType } from "@/reducer/client-models";
import { deconstructEnsStringFromMultiSelectEnsembles, getAllEnsSelect } from "@/utility/ensemble";
import { isEmpty } from "lodash";
import { DateTime, Duration } from "luxon";
import { useMemo } from "react";
import { SPLITTABLE_ENS_MEMEBER, SPLITTED_ENS_MEMEBERS } from "./constants";

// There is huge mess with typings, so we need to keep up with three states
// string | null | undefined
export function useCreateActiveLayers(
  series: PlotSeriesType[],
  title?: string,
  resolution?: string | null,
  datetTimeFrom?: string | null,
  dateTimeTo?: string | null,
) {
  return useMemo(() => {
    const activeLayerList: ActiveLayers[] = [];
    for (const plotSerie of series) {
      if (plotSerie.parameter_unit === null || plotSerie.model === null) {
        // TODO: better error treatment here
        throw Error(`unknown parameter ${plotSerie.model}.${plotSerie.parameter_unit}`);
      }

      const modelSchema = getModel(plotSerie.model);
      const ens = deconstructEnsStringFromMultiSelectEnsembles(plotSerie.ens_select);

      // I don't like idea of flag, for defaulting to band, but without big refactoring
      // this feels like only valid way to force setting default value to 100% band.
      // Problem is that "ens select" dropdown and plot isn't coupled and instead
      // of dropdown controlling plot, values are just synced up, creating some
      // sandbox for new bugs to grow.
      // TODO - Refactor ens logic, so dropdown drives plot
      const ensSelect = ens.map((e) => getAllEnsSelect(e, modelSchema?.num_ensemble_members));
      // In case there is any left from .dev testing
      if (modelSchema) {
        // Check if none ensemble is selected but model has ones
        // Historical data gets saved only for member:0 and that creates some complications
        // We want to display all available data inside range, so we need to adjust start time
        if (!isEmpty(ensSelect) || modelSchema?.num_ensemble_members) {
          for (const ens of ensSelect) {
            // As only member:0 has historical data we need to split request. One that requests data starting
            // from current date and one that starts from actual datetTimeFrom set by user
            if (ens === SPLITTABLE_ENS_MEMEBER) {
              for (const member of SPLITTED_ENS_MEMEBERS) {
                activeLayerList.push({
                  mapOrPlotTitle: title,
                  parameter: plotSerie.parameter_unit,
                  model: modelSchema,
                  plotSerieOptions: { ...plotSerie, ens_select: member },
                  resolution: resolution ? Duration.fromISO(resolution) : undefined,
                  timeFrom: datetTimeFrom ? DateTime.fromISO(datetTimeFrom) : undefined,
                  timeTo: dateTimeTo ? DateTime.fromISO(dateTimeTo) : undefined,
                });
              }
            } else {
              // Otherwise continue as intended
              activeLayerList.push({
                mapOrPlotTitle: title,
                parameter: plotSerie.parameter_unit,
                model: modelSchema,
                plotSerieOptions: { ...plotSerie, ens_select: ens },
                resolution: resolution ? Duration.fromISO(resolution) : undefined,
                timeFrom: datetTimeFrom ? DateTime.fromISO(datetTimeFrom) : undefined,
                timeTo: dateTimeTo ? DateTime.fromISO(dateTimeTo) : undefined,
              });
            }
          }
        } else {
          activeLayerList.push({
            mapOrPlotTitle: title,
            parameter: plotSerie.parameter_unit,
            model: modelSchema,
            plotSerieOptions: { ...plotSerie, ens_select: "" },
            resolution: resolution ? Duration.fromISO(resolution) : undefined,
            timeFrom: datetTimeFrom ? DateTime.fromISO(datetTimeFrom) : undefined,
            timeTo: dateTimeTo ? DateTime.fromISO(dateTimeTo) : undefined,
          });
        }
      } else {
        console.error("Could not find the modelSchema", plotSerie.model);
      }
    }
    return activeLayerList;
  }, [dateTimeTo, datetTimeFrom, resolution, series, title]);
}

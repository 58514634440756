import { classNames } from "@/utility/jsx";
import { type ForwardedRef, forwardRef } from "react";

export interface ButtonBarProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  /**
   * If the container is wider than the buttons, should buttons be placed to the right or to the left of the container?
   * Defaults to `"left"`.
   */
  align?: "left" | "right";
  /**
   * Should the buttons be rendered as usual with some space between them, or should they be merged to a single long button with sections?
   * Defaults to `false` -- buttons are rendered as normal buttons only sharing horizontal space.
   *
   * This might not compose well with special button formats like `<Button round>`.
   */
  pill?: boolean;
  /**
   * Reduced the height of the button bar.
   */
  slim?: boolean;
}

/**
 * Groups a set of buttons horizontally.
 *
 * Examples:
 *
 * ```ts
 * <ButtonBar slim pill>
 *     <Button active>Size XS</Button>
 *     <Button active>Size M</Button>
 *     <Button active>Size L</Button>
 * </ButtonBar>
 * ```
 *
 * ```ts
 * <ButtonBar align="right">
 *     <Button>Cancel</Button>
 *     <Button primary>Accept</Button>
 * </ButtonBar>
 * ```
 */
export const ButtonBar = forwardRef((props: ButtonBarProps, ref: ForwardedRef<HTMLDivElement>) => {
  return (
    <div
      ref={ref}
      className={classNames(
        props.className,
        "button-bar",
        props.align && "button-bar--align-right",
        props.pill && "button-bar--pill",
        props.slim && "button-bar--slim",
      )}
      style={{ ...props.style }}
    >
      {props.children}
    </div>
  );
});

import { ThreadPool } from "threading-lib";
import type {
  MediaExportPool_MsgToPool,
  MediaExportPool_MsgToWorkerThread,
  Snapshot,
} from "./MediaExportThreadPoolMsg";
import type { DOMWindowDimention } from "./MediaManager/video-resolution/videoResolution";

export class MediaExportThreadPool extends ThreadPool<MediaExportPool_MsgToWorkerThread, MediaExportPool_MsgToPool> {
  constructor() {
    // TODO: we currently always run on a single thread since our connection manager
    // has no idea how to manage the hard parallel limit with multiple threads.
    //
    // A simple, suboptimal solution would just assign a subset of connections
    // to each thread.
    super(1);
  }

  newThread(_threadId: number): Worker {
    return new Worker(new URL("./MediaExportThreadPool.worker", import.meta.url), { type: "module" });
  }

  multiplex(
    query: { resolve: (value: any) => void; reject: (reason?: any) => void },
    msg: MediaExportPool_MsgToPool,
  ): void {
    switch (msg.kind) {
      case "MsgAnswer_CreateSnapshot":
        query.resolve(msg.payload.snapshot);
        break;
      case "MsgAnswer_ProcessVideo":
        query.resolve(msg.payload.data);
        break;
    }
  }

  /**
   * Creates a snapshot image of the current UI
   */
  createSnapshot(snapshots: Snapshot[], domWindowDimention: DOMWindowDimention) {
    // Filter out transferable types
    const transferable = snapshots
      .filter(({ snapshot }) => snapshot instanceof ImageBitmap)
      .map((snapshot) => snapshot.snapshot as ImageBitmap);

    return this.send<Blob>(
      {
        kind: "Msg_CreateSnapshot",
        payload: {
          snapshots,
          domWindowDimention,
        },
      },
      transferable,
    );
  }

  /**
   * Initializes the video so it is ready to receive frames to render.
   * Upon the initialization, you can push a each video frame as snapshot array into this video
   * @see {@link MediaExportThreadPool.pushFrame}
   */
  createVideo(domWindowDimention: DOMWindowDimention, fps: number) {
    return this.send({
      kind: "Msg_CreateVideo",
      payload: {
        domWindowDimention,
        fps,
      },
    });
  }

  /**
   * Pushes snapshots of tools for the initialized video to render.
   * A single call to this method is rendered as a single frame in the video.
   */
  pushFrame(snapshots: Snapshot[]) {
    // Filter out transferable types
    const transferable = snapshots
      .filter(({ snapshot }) => snapshot instanceof ImageBitmap)
      .map((snapshot) => snapshot.snapshot as ImageBitmap);

    return this.send(
      {
        kind: "Msg_PushFrame",
        payload: {
          snapshots,
        },
      },
      transferable,
    );
  }

  /**
   * Generates a video using all the frames that have been pushed to the current video.
   */
  processVideo() {
    return this.send<ArrayBuffer>({
      kind: "Msg_ProcessVideo",
      payload: {},
    });
  }

  /**
   * Cleanup all resources opened after creating a video recording (@see {@link MediaExportThreadPool.pushFrame})
   * Intended for aborting video creation.
   */
  disposeVideo() {
    return this.send<ArrayBuffer>({
      kind: "Msg_DisposeVideo",
      payload: {},
    });
  }
}

export const mediaExportThreadPool = new MediaExportThreadPool();

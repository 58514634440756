import { type CleanedEnvAccessors, bool, cleanEnv, str } from "envalid";
import { multiUrls } from "./envalid-validator/urls";

/**
 * The current primary place to define the env variables and their defaults.
 *
 * Note: See also the comment in mainEnv.ts.
 */
export const cleanedEnv = cleanEnv(process.env, {
  // OAuth ID is registered on login.meteomatics.com
  REACT_APP_OAUTH_CLIENT_ID: str({ default: "MetX Workbench" }),
  REACT_APP_MAPTILER_KEY: str({ default: "AhN4rAQQF8aewMg6Z6K7" }),
  // Validation uses "str" to support a relative path for self-hosted MetX's configuration
  REACT_APP_OAUTH_BASE_URL: str({ default: "https://login.meteomatics.com" }),
  REACT_APP_BASE_URL_METX: str({ default: "/api/metx" }),
  REACT_APP_BASE_URL_API_LAYERS: str({ default: "/api/layers" }),
  REACT_APP_BASE_URL_GEOCODER: str({ default: "/api/geocoder" }),
  REACT_APP_BASE_URL_ENERGY_FORECAST: str({ default: "/api/forecast" }),
  REACT_APP_MAPBOX_KEY: str({
    default: "pk.eyJ1IjoianVlcmd1c2dyYW5kdXMiLCJhIjoiY2w2MXliY3hlMW43djNtb2FjY3VpbWViciJ9.KND0fXsUQEL5bWmlZTCxfQ",
  }),
  REACT_APP_BUILD_NAME: str({ default: "METEOMATICS" }),
  REACT_APP_METEOMATICS_API_URLS: multiUrls({
    default: [
      "https://api.meteomatics.com",
      "https://api-a.meteomatics.com",
      "https://api-b.meteomatics.com",
      "https://api-c.meteomatics.com",
      "https://api-d.meteomatics.com",
    ],
  }),
});

// Build-time feature flags. Allows us to toggle certain feature per build.
// e.g: NCM customer self-hosted version of MetX
export const cleanedEnvFF = cleanEnv(process.env, {
  REACT_APP_FF_ENERGY_FORECAST: bool({ default: true }),
  REACT_APP_FF_COUNTRY_PLOT: bool({ default: true }),
});

/**
 * String union of all defined build-time feature flags
 */
export type AvailableBuildFF = Exclude<keyof typeof cleanedEnvFF, keyof CleanedEnvAccessors>;

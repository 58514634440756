import { Button } from "@/overlay/components/CommonParts/buttons";
import type { ISorter } from "@/utility/genericSort";
import type { ReactNode } from "react";

import "./style.scss";

interface ISortersProps<T> {
  object: T;
  activeSorter: ISorter<T>;
  onChangeSorter: (sortProperty: keyof T, isDescending: boolean) => void;
}

export function DropdownSorter<T extends {}>({ object, activeSorter, onChangeSorter }: ISortersProps<T>) {
  // this implicit update is not lost since lingui forces a rerender after a language change
  function changeSortOrder(event: React.ChangeEvent<HTMLSelectElement>) {
    const newOrderKey = event.target.value as keyof T;
    // only flip sort order if the property is already selected
    onChangeSorter(newOrderKey, activeSorter.isDescending);
  }

  return (
    <div className="grid grid--no-gap sortOrderSelectorDropdown" data-columns="1,flex">
      <select className="sortOrderSelectorDropdown__key" value={activeSorter.property} onChange={changeSortOrder}>
        {Object.entries(object).map(([key, value]) => (
          <option key={key} value={key}>
            {/* Without typing typescript complains about unknown type so probably casting is needed */}
            {value as ReactNode}
          </option>
        ))}
      </select>
      <Button
        className="sortOrderSelectorDropdown__desc-asc"
        icon="sort"
        iconFlipped={!activeSorter.isDescending}
        onClick={() => onChangeSorter(activeSorter.property, !activeSorter.isDescending)}
      />
    </div>
  );
}

import { Aggregate } from "@mm/api.meteomatics.com";
import type { CustomGeoJSONCustomOptions } from "@mm/metx-workbench.meteomatics.com";
import type { Feature, FeatureCollection, GeoJsonProperties, Geometry, LineString, Polygon } from "geojson";
import type { DateTime } from "luxon";

export const constructHashId = (
  fileId: string,
  dateTime?: DateTime,
  model?: string,
  parameter?: string,
  customOption?: CustomGeoJSONCustomOptions,
) => {
  const dateTimeHash = dateTime && model && parameter ? dateTime.toMillis() : "none";

  return `${fileId}-${dateTimeHash}-${model || "none"}-${parameter || "none"}-${customOption?.units || "none"}-${
    customOption?.segmentLength || "none"
  }`;
};

export const filterFulfilledPromises = (
  settledResults: PromiseSettledResult<Feature<Geometry, GeoJsonProperties>>[],
) => {
  const hydratedFeatures: Feature<Geometry>[] = [];

  for (const result of settledResults) {
    if (result.status === "fulfilled") {
      hydratedFeatures.push(result.value);
    }
  }

  return hydratedFeatures;
};

export const categorizeFeatures = (
  settledResults: PromiseSettledResult<Feature<Geometry, GeoJsonProperties> | Feature<Geometry, GeoJsonProperties>[]>[],
): {
  polygons: FeatureCollection<Polygon, GeoJsonProperties>;
  lineStrings: FeatureCollection<LineString, GeoJsonProperties>;
} => {
  const hydratedPolygonFeatures: Feature<Polygon, GeoJsonProperties>[] = [];
  const hydratedLineStringFeatures: Feature<LineString, GeoJsonProperties>[] = [];

  // Loop through settled results once
  for (const result of settledResults) {
    if (result.status === "fulfilled") {
      // if array we need to loop.
      const featureOrFeatures = result.value;
      if (Array.isArray(featureOrFeatures)) {
        for (const feature of featureOrFeatures) {
          if (feature.geometry.type === "Polygon") {
            hydratedPolygonFeatures.push(feature as Feature<Polygon, GeoJsonProperties>);
          } else if (feature.geometry.type === "LineString") {
            hydratedLineStringFeatures.push(feature as Feature<LineString, GeoJsonProperties>);
          }
        }
      } else {
        if (featureOrFeatures.geometry.type === "Polygon") {
          hydratedPolygonFeatures.push(featureOrFeatures as Feature<Polygon, GeoJsonProperties>);
        } else if (featureOrFeatures.geometry.type === "LineString") {
          hydratedLineStringFeatures.push(featureOrFeatures as Feature<LineString, GeoJsonProperties>);
        }
      }
    }
  }

  // Create feature collections
  const polygonsFeatureCollection: FeatureCollection<Polygon, GeoJsonProperties> = {
    type: "FeatureCollection",
    features: hydratedPolygonFeatures,
  };
  const lineStringFeatureCollection: FeatureCollection<LineString, GeoJsonProperties> = {
    type: "FeatureCollection",
    features: hydratedLineStringFeatures,
  };

  return {
    polygons: polygonsFeatureCollection,
    lineStrings: lineStringFeatureCollection,
  };
};

export const aggregateOfList = (aggregate: Aggregate, list: number[]): number | null => {
  if (list.length === 0) {
    return null;
  }

  switch (aggregate) {
    case Aggregate.min:
      return Math.min(...list);
    case Aggregate.max:
      return Math.max(...list);
    case Aggregate.mean:
      return list.reduce((sum, value) => sum + value, 0) / list.length;
    // case Aggregate.median:
    // case Aggregate.mode:
    default:
      console.warn("No aggregate set");
      return null;
  }
};

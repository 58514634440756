import type { ChangeEvent } from "react";

import styles from "./style.module.scss";

export function InputFile({
  onChangeFile,
  type,
}: {
  onChangeFile: (event: ChangeEvent<HTMLInputElement>) => void;
  type?: "input" | "button";
}) {
  return (
    <input
      type="file"
      onChange={onChangeFile}
      className={type === "button" ? styles["hidden-input"] : ""}
      accept=".csv"
      multiple
    />
  );
}

import { fetchTab } from "@/api/hooks/tab";
import { createCountryForecastPlot } from "@/api/hooks/tools/countryForecastPlot";
import type { CountryPlotType, CountryPlotTypeUpdate } from "@/reducer/client-models/CountryPlot";
import type { CountryPlotSchema, ElementStyle } from "@mm/metx-workbench.meteomatics.com";
import { type PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import Logger from "logging";
import { useSelector } from "react-redux";
import type { RootState } from ".";

const logger = Logger.fromFilename(__filename);

export type CountryPlotState = {
  [id: number]: CountryPlotType;
};

const initialState: CountryPlotState = {};

const countryPlotSlice = createSlice({
  name: "countryPlot",
  initialState: initialState as CountryPlotState,
  reducers: {
    addCountryPlot(state, action: PayloadAction<CountryPlotType>) {
      state[action.payload.id] = action.payload;
    },
    discardCountryPlot(state, action: PayloadAction<{ id: number }>) {
      const { id } = action.payload;
      const tool = state[id];

      if (!tool) {
        logger.error("Could not find tool with id", id);
        return;
      }
      delete state[id];
    },
    setCountryPlotTitle(state, action: PayloadAction<{ id: number; title: string }>) {
      const { id, title } = action.payload;

      const countryPlot = state[id];
      if (!countryPlot) {
        logger.error("Could not find countryPlot with id", id);
        return;
      }

      countryPlot.title = title;
    },
    setCountryPlotTitleStyle(state, action: PayloadAction<{ style: ElementStyle; id: number }>) {
      const { style, id } = action.payload;

      const countryPlot = state[id];

      if (!countryPlot) {
        logger.error("Could not find countryPlot with id", id);
        return;
      }

      countryPlot.titleStyle = style;
    },
    updateCountryPlotState(
      state,
      action: PayloadAction<{
        id: CountryPlotSchema["id"];
        countryPlot: CountryPlotTypeUpdate;
      }>,
    ) {
      const { countryPlot, id } = action.payload;

      const countryPlotFromState = state[id];

      if (!countryPlotFromState) {
        logger.error("Could not find countryPlot with id", id);
        return;
      }

      const updatedCountryPlot = {
        ...countryPlotFromState,
        ...countryPlot,
      };

      state[id] = updatedCountryPlot;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTab.fulfilled, (state, action) => {
      const tab = action.payload;
      for (const key in state) {
        delete state[+key];
      }
      for (const countryPlot of tab.country_plots) {
        state[countryPlot.id] = countryPlot as CountryPlotType;
      }
    });
    builder.addCase(createCountryForecastPlot.fulfilled, (state, action) => {
      const countryPlot = action.payload.tool;
      state[countryPlot.id] = countryPlot;
    });
  },
});

const selectCountryPlots = (state: RootState) => state.tabGroup.present.countryPlots;

const makeSelectCountryPlotsByTabId = createSelector(
  [selectCountryPlots, (state: RootState, tabId: number) => tabId],
  (countryPlots, tabId) => Object.values(countryPlots).filter((plot) => plot.id_tab === tabId),
);

export function useCountryPlots(tabId: number) {
  return useSelector((state: RootState) => makeSelectCountryPlotsByTabId(state, tabId));
}

const makeSelectCountryPlotById = createSelector(
  [selectCountryPlots, (state: RootState, id: number) => id],
  (countryPlots, id) => countryPlots[id],
);

export function useCountryPlot(id: number) {
  return useSelector((state: RootState) => makeSelectCountryPlotById(state, id));
}

export const {
  addCountryPlot,
  discardCountryPlot,
  setCountryPlotTitle,
  setCountryPlotTitleStyle,
  updateCountryPlotState,
} = countryPlotSlice.actions;

export default countryPlotSlice.reducer;

import { SYMBOLCODE_TO_IMAGES } from "@/geojson";
import type { SYMBOLCODE } from "@/geojson";
import { createIconUrl } from "@/icon-store/IconStore";
import type { ICellRendererParams } from "ag-grid-community";
import styles from "./style.module.scss";

export const WEATHER_SYMBOL_PARAM = "weather_symbol_";

export const DataCellRenderer = (params: ICellRendererParams) => {
  if (params.value && params.colDef?.field?.startsWith(WEATHER_SYMBOL_PARAM)) {
    const iconName = SYMBOLCODE_TO_IMAGES[params.value as SYMBOLCODE];
    const url = createIconUrl(iconName || "wsymbol_0999_unknown");

    return (
      <div className={styles["weather-symbol-cell"]}>
        <img src={url} alt="weather symbol" />
      </div>
    );
  }

  return <>{params.value}</>;
};

import { useAppDispatch } from "@/reducer";
import { addWeatherTable, useWeatherTable } from "@/reducer/WeatherTablesState";
import type { WeatherTableSchema } from "@mm/metx-workbench.meteomatics.com";
import { Suspense, lazy, memo, useEffect } from "react";

export type GridWeatherTableProps = {
  weatherTable: WeatherTableSchema;
};

const WeatherTableView = memo(
  lazy(() =>
    import(/* webpackChunkName: "weatherTableView" */ "@/weatherTableView/index")
      .then(({ WeatherTableView }) => ({ default: WeatherTableView }))
      .catch(() => {
        console.error("error");
        return { default: () => <div>Loading Weather Table View failed</div> };
      }),
  ),
);

export function GridWeatherTable({ weatherTable }: GridWeatherTableProps) {
  /**
   * Temporary solution to sync up react-query with redux
   */
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(addWeatherTable(weatherTable));
  }, [dispatch, weatherTable]);
  //**************************************************** */

  /**
   * Workaround for solving race condition between redux, react query and react
   * TODO Remove once we will have migrated redux to react-query
   */
  const weatherTableFromRedux = useWeatherTable(weatherTable.id);

  if (!weatherTableFromRedux) {
    return null;
  }
  //**************************************************** */

  return (
    <Suspense fallback={<mm-loader size="large" />}>
      <WeatherTableView weatherTable={weatherTableFromRedux} />
    </Suspense>
  );
}

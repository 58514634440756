import type { GridDimension } from "./models";

/**
 * Maximal grid size for any request directed to the WMS endpoint.
 *
 * @see maximalApiGridDimension
 */
export const maximalWmsGridDimension: GridDimension = {
  width: 3072,
  height: 3072,
};

/**
 * Minimal grid size for any request directed to the WMS endpoint.
 *
 * @see minimalApiGridDimension
 */
export const minimalWmsGridDimension: GridDimension = {
  width: 2,
  height: 2,
};

/**
 * Maximal grid size for any request not directed to the WMS endpoint.
 *
 * @see maximalWmsGridDimension
 */
export const maximalApiGridDimension: GridDimension = {
  width: 1000,
  height: 1000,
};
/**
 * Minimal grid size for any request not directed to the WMS endpoint.
 *
 * @see minimalWmsGridDimension
 */
export const minimalApiGridDimension: GridDimension = {
  width: 2,
  height: 2,
};

import { weatherParameterFieldDisplayName } from "@/i18n/weather-parameter-fields";
import { useValueNameCreator } from "@/overlay/components/SmartForm/SmartFormUtils/FormHooks/useFormInstructions";
import type { InputInstruction } from "@/overlay/components/SmartForm/SmartFormUtils/commonTypeUtils";
import { FormField } from "@/overlay/components/form-field";
import type { ParameterRange } from "@mm/api-layers.meteomatics.com";
import { Fragment } from "react";
import * as weatherParamUtil from "weather-parameter-utils";
import type {
  FieldNames,
  HasLayerIdxNarrowing,
  PartialWeatherParameter,
  WeatherParamFormatOption,
} from "weather-parameter-utils";

/**
 * Convert the weather option object to an array for rendering purpose.
 */
function flattenOptions(optionByName: WeatherParamFormatOption): [FieldNames, string[] | ParameterRange][] {
  // TODO: This returns new array which can trigger re-rendering
  const allFields: [FieldNames, string[] | ParameterRange][] = Object.entries(optionByName) as unknown as any;
  return allFields;
}

/**
 * Shape of the data the input component will send on the form hook.
 */
export type ParamOptionInputData = Partial<HasLayerIdxNarrowing<PartialWeatherParameter>>;

type ParamOptionInputProps = {
  instruction: InputInstruction<{
    parameter: HasLayerIdxNarrowing<PartialWeatherParameter>;
  }>;
  isCreationMode?: boolean;
};

/**
 * Given a PartialWeatherParameter object, which has the index narrowing,
 * render all of the selected format options.
 *
 * e.g:
 * Given: HasLayerIdxNarrowing<PartialWeatherParameter> that represents "wind_speed_<level>:<unit>"
 * Render inputs for:
 * - level
 * - unit
 *
 * Note: This is a React form hook version of:
 * <ParameterFormatForm/> under
 * metx-core/src/overlay/components/WeatherParameterForm/index.tsx
 */
export function ParamOptionSelectList(props: ParamOptionInputProps) {
  const { instruction, isCreationMode } = props;
  const {
    formMethods: { register },
    currValue,
  } = instruction;
  const { createInputName } = useValueNameCreator<ParamOptionInputData>(instruction);

  const formatOptions = weatherParamUtil.getSelectedFormatOptions(currValue.parameter);
  const optionFields = flattenOptions(formatOptions);

  return (
    // Note: Providing key in Fragment here forces the component state to clear
    // when the format of the parameter changed
    // narrowed_selection.layerIdx represents which format is selected.
    <Fragment key={`${currValue.parameter.narrowed_selection.layerIdx}_${currValue.parameter.parameter.name}`}>
      {optionFields.map(([fieldName, fieldDesc], fieldIdx) => {
        const currOptionValue: string | undefined = weatherParamUtil.getSelectedFormatOptionValues(currValue.parameter)[
          fieldName
        ];

        if (weatherParamUtil.isParameterRange(fieldDesc)) {
          const rangeValue = weatherParamUtil.parseParameterRange(fieldDesc, currOptionValue) ?? fieldDesc.start;
          return (
            <FormField
              key={`${fieldIdx}_${currValue.parameter.parameter.name}`}
              label={weatherParameterFieldDisplayName[fieldName]()}
            >
              <div className="grid grid--center-horizontal" data-columns="1,flex">
                <input
                  data-testid="range-input"
                  type="number"
                  min={fieldDesc.start}
                  max={fieldDesc.stop}
                  defaultValue={isCreationMode ? rangeValue : undefined}
                  value={!isCreationMode ? rangeValue : undefined}
                  {...register(createInputName(`narrowed_selection.fields.${fieldName}`), {
                    setValueAs: (num) => `${num}${fieldDesc.unit}`,
                  })}
                />
                <div>{fieldDesc.unit}</div>
              </div>
            </FormField>
          );
        }
        {
          // Set the default value to the first option if nothing is selected.
          const hasCurrValue = !!currOptionValue;
          const hasAvailableOptions = fieldDesc.length > 0;
          if (!hasAvailableOptions) {
            // If the option value list is empty, do not render the dropdown.
            return <Fragment key={`${fieldIdx}_${currValue.parameter.parameter.name}`} />;
          }
          const firstOption = fieldDesc[0];
          const _optionValue = hasCurrValue ? currOptionValue : firstOption;
          return (
            <FormField
              key={`${fieldIdx}_${currValue.parameter.parameter.name}`}
              label={weatherParameterFieldDisplayName[fieldName]()}
            >
              <select
                data-testid={"option-dropdown"}
                defaultValue={isCreationMode ? _optionValue : undefined}
                value={!isCreationMode ? _optionValue : undefined}
                {...register(createInputName(`narrowed_selection.fields.${fieldName}`))}
              >
                {fieldDesc.map((val) => {
                  return (
                    <option key={val} value={val}>
                      {val}
                    </option>
                  );
                })}
              </select>
            </FormField>
          );
        }
      })}
    </Fragment>
  );
}

import { getWeatherCodeColor } from "@/geojson/definitions";

export function getPropertyForWindIcon(
  val: number,
  lat: number,
  windNrFunction: (speed: number) => number,
): { [name: string]: string } {
  const getWindIconName = (valueInMeterPerSecond: number, lat: number): string => {
    const globeSide = lat < 0 ? "s" : "n";
    const windNr = windNrFunction(valueInMeterPerSecond);
    return globeSide + windNr;
  };
  return { "wind-icon": getWindIconName(val, lat) };
}

export function getPropertyForWindDirection(val: number): { [name: string]: number } {
  return { "wind-direction": val };
}

export type SYMBOLCODE =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 101
  | 102
  | 103
  | 104
  | 105
  | 106
  | 107
  | 108
  | 109
  | 110
  | 111
  | 112
  | 113
  | 114
  | 115
  | 116;

export const SYMBOLCODE_TO_IMAGES: { [key in SYMBOLCODE]: string } = {
  0: "wsymbol_0999_unknown",
  1: "wsymbol_0001_sunny",
  101: "wsymbol_0008_clear_sky_night",
  2: "wsymbol_0002_sunny_intervals",
  102: "wsymbol_0041_partly_cloudy_night",
  3: "wsymbol_0043_mostly_cloudy",
  103: "wsymbol_0044_mostly_cloudy_night",
  4: "wsymbol_0003_white_cloud",
  104: "wsymbol_0042_cloudy_night",
  5: "wsymbol_0018_cloudy_with_heavy_rain",
  105: "wsymbol_0034_cloudy_with_heavy_rain_night",
  6: "wsymbol_0021_cloudy_with_sleet",
  106: "wsymbol_0037_cloudy_with_sleet_night",
  7: "wsymbol_0020_cloudy_with_heavy_snow",
  107: "wsymbol_0036_cloudy_with_heavy_snow_night",
  8: "wsymbol_0009_light_rain_showers",
  108: "wsymbol_0025_light_rain_showers_night",
  9: "wsymbol_0011_light_snow_showers",
  109: "wsymbol_0027_light_snow_showers_night",
  10: "wsymbol_0013_sleet_showers",
  110: "wsymbol_0029_sleet_showers_night",
  11: "wsymbol_0006_mist",
  111: "wsymbol_0063_mist_night",
  12: "wsymbol_0007_fog",
  112: "wsymbol_0064_fog_night",
  13: "wsymbol_0050_freezing_rain",
  113: "wsymbol_0068_freezing_rain_night",
  14: "wsymbol_0024_thunderstorms",
  114: "wsymbol_0040_thunderstorms_night",
  15: "wsymbol_0048_drizzle",
  115: "wsymbol_0066_drizzle_night",
  16: "wsymbol_0056_dust_sand",
  116: "wsymbol_0074_dust_sand_night",
};

const SIGNIFICANT_WEATHER_SYMBOLS: SYMBOLCODE[] = [
  5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116,
];

export function getPropertyForWeatherSymbol(val: SYMBOLCODE): { [name: string]: string } {
  const propertyExtra = !SIGNIFICANT_WEATHER_SYMBOLS.includes(val)
    ? { "weather-icon-type": "non-significant-weather" }
    : undefined;

  return {
    "weather-icon": SYMBOLCODE_TO_IMAGES[val],
    ...propertyExtra,
  };
}

const weatherCodeLowerThisValueAreFilledWithClouds = 4;

export function getPropertyForWeatherCode(val: number, val2: number | null): { [name: string]: string } {
  let iconName: string;
  let propertyExtra: any;
  if (val < weatherCodeLowerThisValueAreFilledWithClouds) {
    iconName = `Cloud_cover_${val2}`;
    propertyExtra = { "weather-icon-type": "non-significant-weather" };
  } else {
    if (val < 10) {
      iconName = `Symbol_code_ww_0${val.toString()}`;
    } else {
      iconName = `Symbol_code_ww_${val.toString()}`;
    }
  }
  const color = getWeatherCodeColor(val);
  return {
    "weather-icon": iconName,
    "weather-icon-color": color,
    ...propertyExtra,
  };
}

import { MmCheckbox } from "@mm/zeus-react";
import type { ChangeEvent } from "react";
import styles from "./style.module.scss";

type EnsMemberCheckboxProps = {
  name: string;
  value: string;
  checked?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

export const EnsMemberCheckbox = ({ value, checked, onChange, name }: EnsMemberCheckboxProps) => {
  return (
    <div className={styles["ens-checkbox"]}>
      <MmCheckbox
        cid={name}
        value={value}
        checked={checked}
        toggleHandler={(input) => {
          onChange({ target: input } as ChangeEvent<HTMLInputElement>);
        }}
      />
      <label htmlFor={name}>{name}</label>
    </div>
  );
};

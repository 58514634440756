import { type AsyncResult, createPendingAsyncResult, createResolvedAsyncResult } from "@/cache/AsyncResult";
import { type CacheDatum, CacheDatumState } from "@/cache/CacheDatumState";
import { apiThreadPool } from "@/cache/SpatioTemporalTileCache/ApiQueryThreadPool";
import { type Stats, jsxTable } from "@/cache/Stats";
import { cancelable } from "@/cache/utils";
import {
  MeteomaticsApiUrl,
  type VectorLayerStyleRequest,
  type VectorTileRequestUnion,
  WORLD_BBOX,
} from "@mm/api.meteomatics.com";
import type { MeteomaticsApiError } from "@mm/api.meteomatics.com/lib/error";
import { type Abort, isAbortable } from "@mm/api.meteomatics.com/lib/middleware";
import type { Style } from "mapbox-gl";
import { FailureState, type RetryState, createRetryState } from "../SpatioTemporalTileCache/RetryState";

export interface VectorTileCacheStats {
  count: number;
  pending: number;
}

export class VectorTileCache implements Stats<VectorTileCacheStats> {
  protected styleCache: { [key: string]: CacheDatum<Style | undefined, RetryState> } = {};
  protected tilesCache: { [key: string]: CacheDatum<ArrayBuffer | undefined, RetryState> } = {};

  retrieveVectorLayerStyle(request: VectorLayerStyleRequest): AsyncResult<Style | undefined> {
    const url = MeteomaticsApiUrl.forVectorLayerStyle(request);
    const cacheId = url.toString();

    let retry_: RetryState | null = null;

    if (Object.hasOwn(this.styleCache, cacheId)) {
      const v = this.styleCache[cacheId];
      switch (v.state) {
        case CacheDatumState.Pending:
          retry_ = v.payload;
          if (retry_.failureState !== FailureState.FailedTemporarily) {
            return createPendingAsyncResult(v.promise, v.cancel);
          }
          // since the current invocation moves the state from [Pending/FailedTemporarily] to [Pending/Queued]
          // mark it as such to prevent concurrent invocations from reentering the remaining function body
          retry_.failureState = undefined;
          break;
        case CacheDatumState.PermanentlyFailed:
          return createResolvedAsyncResult(undefined);
        case CacheDatumState.Loaded:
          return createResolvedAsyncResult(v.payload);
        default: {
          const _exhaustive: never = v;
          return _exhaustive;
        }
      }
    }

    // TODO Vector tiles - get rid of BBOX in retry state
    // Because vector tile style isn't tied to model
    const retry = retry_ ?? createRetryState(WORLD_BBOX);

    const cancelablePromise = cancelable<Style | undefined>(
      apiThreadPool
        .getVectorLayerStyle(request, retry)
        .then((style) => {
          const { requestDatetime } = this.styleCache[cacheId];

          this.styleCache[cacheId] = {
            requestDatetime,
            lastUsed: Number.NEGATIVE_INFINITY,
            state: CacheDatumState.Loaded,
            payload: style,
          };
          return style;
        })
        .catch((e: MeteomaticsApiError | Abort) => {
          // TODO More robust error handling
          if (isAbortable(e)) {
            delete this.styleCache[cacheId];
          }
          return Promise.reject(e);
        }),
    );
    this.styleCache[cacheId] = {
      requestDatetime: performance.now(),
      state: CacheDatumState.Pending,
      promise: cancelablePromise.promise,
      cancel: cancelablePromise.cancel,
      payload: retry,
    };
    return createPendingAsyncResult(cancelablePromise.promise, cancelablePromise.cancel);
  }

  retrieveVectorTile(
    // As it's possible to have more than one source inside vector tile
    // Make sure we keep each source > tiles separate in cache with unique ID
    { source, ...request }: VectorTileRequestUnion & { source: string },
  ): AsyncResult<ArrayBuffer | undefined> {
    const url: MeteomaticsApiUrl = MeteomaticsApiUrl.forVectorTile(request);
    const cacheId = `${url.toString()}_${source}`;
    let retry_: RetryState | null = null;

    if (Object.hasOwn(this.tilesCache, cacheId)) {
      const v = this.tilesCache[cacheId];
      switch (v.state) {
        case CacheDatumState.Pending:
          retry_ = v.payload;
          if (retry_.failureState !== FailureState.FailedTemporarily) {
            return createPendingAsyncResult(v.promise, v.cancel);
          }
          // since the current invocation moves the state from [Pending/FailedTemporarily] to [Pending/Queued]
          // mark it as such to prevent concurrent invocations from reentering the remaining function body
          retry_.failureState = undefined;
          break;
        case CacheDatumState.PermanentlyFailed:
          return createResolvedAsyncResult(new ArrayBuffer(0));
        case CacheDatumState.Loaded:
          return createResolvedAsyncResult(v.payload);
        default: {
          const _exhaustive: never = v;
          return _exhaustive;
        }
      }
    }

    const modelBoundingBox = request.boundingBoxLimit || WORLD_BBOX;
    const retry = retry_ ?? createRetryState(modelBoundingBox);

    const cancelablePromise = cancelable<ArrayBuffer | undefined>(
      apiThreadPool
        .getVectorTile(request, retry)
        .then((arrayBuffer) => {
          const { requestDatetime } = this.tilesCache[cacheId];

          this.tilesCache[cacheId] = {
            requestDatetime,
            lastUsed: Number.NEGATIVE_INFINITY,
            state: CacheDatumState.Loaded,
            payload: arrayBuffer,
          };

          return arrayBuffer;
        })
        .catch((e: MeteomaticsApiError | Abort) => {
          // TODO More robust error handling
          if (isAbortable(e)) {
            delete this.styleCache[cacheId];
          }
          console.error(e);
          // For now, resolve with empty array buffer to prevent
          // metx chocking with rejected errors
          return Promise.resolve(new ArrayBuffer(0));
          // return Promise.reject(e);
        }),
    );

    this.tilesCache[cacheId] = {
      requestDatetime: performance.now(),
      state: CacheDatumState.Pending,
      promise: cancelablePromise.promise,
      cancel: cancelablePromise.cancel,
      payload: retry,
    };

    return createPendingAsyncResult(cancelablePromise.promise, cancelablePromise.cancel);
  }

  stats(): VectorTileCacheStats {
    let count = 0;
    let pending = 0;
    const cache = this.tilesCache;

    for (const line in cache) {
      if (Object.hasOwn(cache, line)) {
        count++;

        if (cache[line].state === CacheDatumState.Pending) {
          pending++;
        }
      }
    }

    return { count, pending };
  }

  statsJsx(): JSX.Element {
    return jsxTable(this.stats(), (key, val) => val);
  }

  label(): string {
    return "Mapbox vector tiles cache";
  }

  descJsx(): JSX.Element {
    return <>Special-purpose cache for API JSON Requests. Replacement Policy is LRU.</>;
  }
}

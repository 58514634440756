import { feature } from "@turf/helpers";
import type { Feature, Geometry } from "geojson";

export const addValueToFeature = (
  { geometry, bbox, properties }: Feature<Geometry>,
  // Value is unknown, as we don't wan't to limit system to just single type
  value: unknown,
): Feature<Geometry> => {
  return feature(geometry, { ...properties, value: value }, { bbox });
};

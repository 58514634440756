import type { Coordinate } from "@mm/api.meteomatics.com";

export enum PopupPlotType {
  ActiveLayers = "ActiveLayers",
  PointForecast = "PointForecast",
  Tephigram = "Tephigram",
}

export interface PopupPlotState {
  show: boolean;
  hideLegend?: boolean;
  type: PopupPlotType;
  coords: Coordinate | null;
}

export const initialPopupPlotState: PopupPlotState = {
  show: false,
  hideLegend: false,
  type: PopupPlotType.ActiveLayers,
  coords: null,
};

import type { AsyncResult } from "@/cache/AsyncResult";
import { GridRequestKind } from "@/cache/GeoJSONCache";
import { networkCaches } from "@/cache/GlobalCache";
import { barbsLayerConfig } from "@/constants/layerConfigAttributes";
import type { CheckedProps, LayerBase, PropsChecker } from "@/layers";
import type { ScenePublicApi } from "@/layers/Compositor";
import { generateGridLayerCacheId } from "@/layers/geojson/LayerUtils";
import { MapboxGeoJSONLayer } from "@/layers/geojson/MapboxGeoJSONLayer";
import { getMapboxIconSize } from "@/layers/utility/iconSize";
import { SENTRY_TRANSACTION_NAMES } from "@/sentry/TransactionConst";
import { captureTransaction } from "@/sentry/helpers";
import { createDirectionParameterName } from "@/utility/createDirectionParameterName";
import { safeFmt } from "@/utility/safeFmt";
import { getModelBoundingBox } from "@/weather-parameters/lookup";
import {
  type CoordinateSystem,
  type GeoJSONFeatureCollection,
  type GridRequest,
  GridSamplingStrategyKind,
} from "@mm/api.meteomatics.com";
import type { BarbsLayer, GuiTimeZone } from "@mm/metx-workbench.meteomatics.com";
import type { DateTime } from "luxon";

export const SpeedUnitsToSwitchToKn = ["ms", "bft", "kmh", "mph"]; // kn

export function getKnParameterName(parameter: string): string {
  const otherUnit = SpeedUnitsToSwitchToKn.find((unit) => parameter.includes(`:${unit}`));
  if (otherUnit) {
    return parameter.replace(`:${otherUnit}`, ":kn");
  }
  return parameter;
}

export class BarbsLayerImpl extends MapboxGeoJSONLayer<BarbsLayer> {
  private static DESIRED_SIZE_PX = 100;
  constructor(id: number, props: BarbsLayer, scene: ScenePublicApi, timezone: GuiTimeZone) {
    super(id, props, scene, timezone);
    this.updateMapboxLayoutProperties({
      "icon-image": ["get", "wind-icon"],
      "icon-allow-overlap": true,
      "icon-size": this.getIconSize(),
      visibility: this.props.show ? "visible" : "none",
      "icon-keep-upright": true,
      "icon-rotation-alignment": "map",
      "icon-rotate": ["get", "wind-direction"],
      "icon-ignore-placement": true,
    });
    this.updateMapboxPaintProperties({
      "icon-color": this.props.element_color,
      "icon-opacity": this.props.opacity,
    });
  }
  getIconSize(): number {
    const iconScaling = this.props.custom_options.icon_size ?? barbsLayerConfig.defaultIconSize;
    // Include devicePixelRatio in the calculation
    return getMapboxIconSize(iconScaling, BarbsLayerImpl.DESIRED_SIZE_PX);
  }

  checker(): PropsChecker<BarbsLayer, LayerBase<BarbsLayer>> {
    type CheckerProps = CheckedProps<BarbsLayer>;
    const checkLayerProp = (property: CheckerProps) => (prev: any, curr: any) => {
      const changed = prev[property] !== curr[property];
      if (changed) {
        this.setLayerProps({ [property]: curr[property] });
        switch (property) {
          case "model":
            this.beforeRender();
            break;
          case "calibrated":
            this.beforeRender();
            break;
          case "parameter_unit":
            this.beforeRender();
            break;
          case "ens_select":
            this.beforeRender();
            break;
          case "parameter_unit_paired":
            this.beforeRender();
            break;
          case "step":
            this.beforeRender();
            break;
          case "show":
            this.updateMapboxLayoutProperties({ visibility: curr.show ? "visible" : "none" });
            break;
          case "opacity":
            this.updateMapboxPaintProperties({
              "icon-opacity": curr[property],
            });
            break;
          case "element_color":
            this.updateMapboxPaintProperties({
              "icon-color": curr[property],
            });
            break;
          case "custom_options":
            this.updateMapboxLayoutProperties({
              "icon-size": this.getIconSize(),
            });
            break;
          default: {
            const _exhaustive: never = property;
            return _exhaustive;
          }
        }
      }
      return changed;
    };
    return {
      model: checkLayerProp("model"),
      opacity: checkLayerProp("opacity"),
      calibrated: checkLayerProp("calibrated"),
      parameter_unit: checkLayerProp("parameter_unit"),
      parameter_unit_paired: checkLayerProp("parameter_unit_paired"),
      show: checkLayerProp("show"),
      step: checkLayerProp("step"),
      element_color: checkLayerProp("element_color"),
      ens_select: checkLayerProp("ens_select"),
      custom_options: checkLayerProp("custom_options"),
    };
  }

  humanReadableId(): string {
    return safeFmt`metx.barbslayer#${this.uid}#${this.props.model}.${this.props.parameter_unit}`;
  }

  createRequest(dateTimeWithOffset: DateTime): GridRequest<CoordinateSystem.WGS84> {
    const { area, width, height } = this.computeBoundingBox(this.props.step);

    // convert to wind parameter with Kn but we don't set it to this,
    // it is only for finding the directions parameter
    const paramKnSpeed = getKnParameterName(this.props.parameter_unit);
    const paramDir = createDirectionParameterName(paramKnSpeed);
    return {
      sampling: { kind: GridSamplingStrategyKind.Area },
      area: area,
      parameters: [this.props.parameter_unit, paramDir],
      model: this.props.model,
      ensSelect: this.props.ens_select ? this.props.ens_select : "",
      boundingBoxLimit: getModelBoundingBox(this.props.model),
      datetime: dateTimeWithOffset,
      height: height, // /this.props.step,
      width: width, // /this.props.step,
      calibrated: this.props.calibrated,
    };
  }

  beforeRender(): void {
    if (this.scene.isAnimation()) {
      this.updateTrigger();
    } else {
      this.debouncedUpdateTrigger();
    }
  }

  updateTrigger() {
    const dateTimeWithOffset = this.scene.getDisplayTimeWithOffset();
    const request = this.createRequest(dateTimeWithOffset);

    const result = networkCaches.geojson_cache.retrieveGrid(request, GridRequestKind.Barbs);
    this.updateSource(result, dateTimeWithOffset);
  }

  fetchData(timeFrame: DateTime): Promise<void> {
    const dateTimeWithOffset = this.scene.getDateTimeWithOffset(timeFrame);
    const request = this.createRequest(dateTimeWithOffset);
    const tags = {
      layerKind: `${this.props.kind ? this.props.kind : "undefined"}`,
      parameter: `${this.props.parameter_unit}`,
      requestedDate: `${dateTimeWithOffset}`,
    };

    const { asynchronous } = networkCaches.geojson_cache.retrieveGrid(request, GridRequestKind.Barbs);

    if (!this.scene.isAnimation()) {
      return captureTransaction(asynchronous, SENTRY_TRANSACTION_NAMES.BARBS_LAYER_LOADING_SPEED, tags);
    }

    return asynchronous
      .then(() => {
        return Promise.resolve();
      })
      .catch((err) => {
        return Promise.reject();
      });
  }

  getActiveWeatherParametersAsString() {
    return [
      {
        model: this.props.model,
        parameter: this.props.parameter_unit,
      },
    ];
  }

  peekLayerCache(request: GridRequest<CoordinateSystem.WGS84>): AsyncResult<GeoJSONFeatureCollection> {
    const cacheId = generateGridLayerCacheId(request, GridRequestKind.Barbs);
    return networkCaches.geojson_cache.peekCache(cacheId);
  }

  isRebuffering(time: DateTime): boolean {
    return super.isRebuffering(time);
  }
}

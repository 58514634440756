import { onlyKnParamFormat } from "@/utility/weather-param-config/format-filter/formatFilterUtils";
import type { WeatherOptionFilterFlag } from "@/utility/weather-param-config/paramFilterTypes";
import type { WeatherParamFormat } from "weather-parameter-utils";

export type FormatFilter = (props: {
  layerFormat: WeatherParamFormat;
  filterFlag: WeatherOptionFilterFlag;
}) => WeatherParamFormat | null;

/**
 * Given a weather parameter's particular format WeatherParamFormat (e.g: wind_speed_<height>:<unit>),
 * this function will determine
 * 1. If the given format should be used for the layer kind.
 * 2. Disable & add certain options if needed.
 *
 * Note: You can directly modify the object as it's already deep copied.
 *
 * @returns
 * - When returning null, the format will be disabled for the given layer kind.
 * - When returning a WeatherParamFormat, its options will be used for the layer kind.
 */
export const pickAndModifyFormat: FormatFilter = (props: {
  layerFormat: WeatherParamFormat;
  filterFlag: WeatherOptionFilterFlag;
}): WeatherParamFormat | null => {
  const { layerFormat, filterFlag } = props;
  switch (filterFlag) {
    case "BarbsLayerDescription": {
      return onlyKnParamFormat(layerFormat);
    }
    case "WindAnimationLayerDescription": {
      // Wind animation uses only wind_speed_u and wind_speed_v parameter.
      // Note: Wind speed U anv V components are meteorological concept to represent direction and speed.
      if (layerFormat.format.startsWith("wind_speed_u")) {
        // Disable the unit selection completely as only certain unit value is supported.
        layerFormat.unit = [];
        return layerFormat;
      }
      // Filter out other params.
      return null;
    }
    default: {
      return layerFormat;
    }
  }
};

import { type EnsSelectValue, quantileBandList } from "@/utility/ensemble";
import { classNames } from "@/utility/jsx";
import { useMemo } from "react";

import { quantileBandsLabels } from "../EnsSelectForm/EnsSelectGroupedForm";

import styles from "./style.module.scss";

const isAllQuantile = (value: string) => value.split(",").length > 2 && value.startsWith("quantile");
const isMembersRange = (value: string) => value.split("-").length === 2 && value.startsWith("member");

const getClassBySelectedEns = (ens: EnsSelectValue) => {
  if (ens.includes("quantile")) {
    return "quantile";
  }

  if (ens.includes("member")) {
    return "member";
  }

  return "additional";
};

interface EnsCipProps {
  value: EnsSelectValue;
  onRemove?: (value: EnsSelectValue) => void;
}

const EnsChip = ({ value, onRemove }: EnsCipProps) => {
  const humanReadableValue = useMemo(() => {
    if (quantileBandList.includes(value)) {
      return quantileBandsLabels()[value];
    }

    if (isAllQuantile(value)) return "Quantile";
    if (isMembersRange(value)) return "Members";

    return value;
  }, [value]);

  return (
    <div className={classNames(styles.chip, styles[getClassBySelectedEns(value)])}>
      <span>{humanReadableValue}</span>
      <button className={styles["delete-action"]} onClick={() => onRemove?.(value)} type={"button"}>
        <span className={classNames("material-icons", styles.icon)}>close</span>
      </button>
    </div>
  );
};

export { EnsChip };

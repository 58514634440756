import { constructRadiusDisplay } from "@/layers/geojson/drawing/mapbox-draw-mode/idle/drawing-utils";
import { DrawingTypes } from "@/layers/geojson/drawing/mapbox-draw-mode/modeNames";

import type { DrawCustomMode } from "@mapbox/mapbox-gl-draw";
import type { Feature, GeoJSON, LineString } from "geojson";

export const Idle = (): DrawCustomMode => ({
  onSetup(state) {
    return state;
  },
  toDisplayFeatures(_, geojson: Feature<LineString, { user_role: DrawingTypes }>, display: (geojson: GeoJSON) => void) {
    switch (geojson.properties?.user_role) {
      case DrawingTypes.radius:
        {
          const radiusFeature = constructRadiusDisplay(geojson);

          if (radiusFeature) {
            for (const feature of radiusFeature) {
              display(feature);
            }
          }
        }
        break;
      case DrawingTypes.line:
        {
          // TODO Implement line drawing
        }
        break;
    }
  },
});

/**
 * Estimates a running average and a running standard deviation of some metric using moments.
 */

export const DEFAULT_DECAY = 0.1;
export class MovingAverage {
  /**
   * How fast the moving average forgets historical data. This factor thus balances
   * smoothness with reaction time to changes.
   *
   * Value has to be in the range [0,1]. A greater value means the average forgets older
   * values faster.
   */
  private readonly decay: number = DEFAULT_DECAY;

  /**
   * Determines how many samples are considered to be part of the bootstrapping sequence.
   *
   * Estimates of an exponential moving average are far off shortly after initialization.
   * We boost performance during this burn-in/bootstrapping phase by reweighting the contribution of early samples
   * using the inverse of the number of samples accumulated. Biasing weights using inverse sample count in an exponential
   * moving average is equivalent to a simple weighted moving average. See Yang et al. for details:
   *
   * Lei Yang et al. “Amortized supersampling”. In: ACM Transactions on Graphics (TOG) 28.5 (2009), pp. 1–12.
   *
   * A simple alternative used below is to define the bootstrapping sequence length relative to the
   * decay factor as `BOOSTRAPPING_PHASE_LENGTH = 1 / decay`.
   */
  //private readonly BOOSTRAPPING_PHASE_LENGTH = DEFAULT_BOOSTRAPPING_PHASE_LENGTH;

  momentFirst = 0;
  momentSecond = 0;

  sampleCount = 0;

  addSample(sample: number) {
    this.sampleCount++;
    const decay = Math.max(this.decay, 1.0 / this.sampleCount);
    this.momentFirst = this.lerp(sample, this.momentFirst, decay);
    this.momentSecond = this.lerp(sample * sample, this.momentSecond, decay);
  }

  private lerp(newSample: number, history: number, decay: number) {
    return decay * newSample + (1.0 - decay) * history;
  }

  variance(): number {
    return this.momentSecond - this.momentFirst * this.momentFirst;
  }

  stddev(): number {
    return Math.sqrt(this.variance());
  }

  avg(): number {
    return this.momentFirst;
  }
}

import { Api } from "@/api/Api";
import { createCountryForecastPlot } from "@/api/hooks/tools/countryForecastPlot";
import { createEnergyPlot } from "@/api/hooks/tools/energyPlot";
import { createLocationTable } from "@/api/hooks/tools/locationTable";
import { createMapWithViewport } from "@/api/hooks/tools/map";
import { createNote } from "@/api/hooks/tools/note";
import { createPlot } from "@/api/hooks/tools/plot";
import { createTephigram } from "@/api/hooks/tools/tephigram";
import { createWeatherTable } from "@/api/hooks/tools/weatherTable";
import {
  createNewCountryPlot,
  createNewEnergyPlot,
  createNewLocationTable,
  createNewMap,
  createNewNote,
  createNewPlot,
  createNewPointForecastPlot,
  createNewTephigram,
  createNewWeatherTable,
  defaultBackgroundMapLayer,
} from "@/constants/create";
import { FeatureGuard } from "@/featureFlags";
import { useCurrentTabViewportLinkingStatus } from "@/grid/useCurrentTabViewportLinkingStatus";
import { Button } from "@/overlay/components/CommonParts/buttons";
import { type RootState, useAppDispatch } from "@/reducer";
import { useMaps } from "@/reducer/MapsState";
import { CountryPlotSvg } from "@/style/img/CountryPlotSvg";
import EnergySvg from "@/style/img/EnergySvg";
import MapSvg from "@/style/img/MapSvg";
import NoteSvg from "@/style/img/NoteSvg";
import PlotSvg from "@/style/img/PlotSvg";
import TableSvg from "@/style/img/TableSvg";
import TephigramSvg from "@/style/img/TephigramSvg";
import { Trans } from "@lingui/macro";
import type { GenericLayout, Tab } from "@mm/metx-workbench.meteomatics.com";
import { useState } from "react";
import { useSelector } from "react-redux";
import "./GridEmptyOptions.scss";
import { ToolDropdown } from "./ToolDropdown";

type GridEmptyProps = {
  layout: GenericLayout;
  tabId: Tab["id"];
};

export function GridEmptyOptions({ tabId, layout }: GridEmptyProps) {
  // const { value: showLocationTableOption } = useFlag("location_table", false);
  const showLocationTableOption = true;
  const areCurrentMapsLinked = useCurrentTabViewportLinkingStatus();
  const dispatch = useAppDispatch();
  const activeMaps = useMaps(tabId);
  const isLayoutArrangementActive = useSelector((state: RootState) => state.ui.isLayoutArrangementActive);
  // TODO create DebouncedButton and handle click frequency there.
  const [isEnabled, setIsEnabled] = useState<boolean>(true);

  function addNewMap() {
    setIsEnabled(false);
    dispatch(
      createMapWithViewport({
        data: createNewMap(layout.gridCellLayout, [defaultBackgroundMapLayer]),
        tabId,
        layoutId: layout.id,
        linkedViewportId: areCurrentMapsLinked ? activeMaps?.[0]?.id_viewport : undefined,
      }),
    );
  }

  function addNewPlot() {
    setIsEnabled(false);
    dispatch(createPlot({ tabId: tabId, data: createNewPlot(layout.gridCellLayout), layoutId: layout.id }));
  }

  function addNewPointForecastPlot() {
    setIsEnabled(false);
    dispatch(
      createPlot({ tabId: tabId, data: createNewPointForecastPlot(layout.gridCellLayout), layoutId: layout.id }),
    );
  }

  async function addNewEnergyPlot() {
    setIsEnabled(false);
    const availability = await Api.energyForecast.getEnergyForecastAvailability();
    const { 0: defaultForecastType } = Object.keys(availability);
    const { 0: defaultModel } = Object.keys(availability[defaultForecastType]);
    const defaultSite = availability[defaultForecastType][defaultModel].sites[0].site_id;
    dispatch(
      createEnergyPlot({
        tabId: tabId,
        data: createNewEnergyPlot(defaultForecastType, defaultModel, defaultSite, layout.gridCellLayout),
        layoutId: layout.id,
      }),
    );
  }

  function addNewTephigram() {
    setIsEnabled(false);
    dispatch(createTephigram({ data: createNewTephigram(tabId), layoutId: layout.id }));
  }

  function addNewCountryPlot() {
    setIsEnabled(false);
    dispatch(createCountryForecastPlot({ data: createNewCountryPlot(tabId), layoutId: layout.id }));
  }

  function addNewWeatherTable() {
    setIsEnabled(false);
    dispatch(createWeatherTable({ data: createNewWeatherTable(tabId), layoutId: layout.id }));
  }

  function addNewLocationTable() {
    setIsEnabled(false);
    dispatch(createLocationTable({ data: createNewLocationTable(tabId), layoutId: layout.id }));
  }

  function addNewNote() {
    setIsEnabled(false);
    dispatch(createNote({ data: createNewNote(tabId), layoutId: layout.id }));
  }

  return (
    <div className="grid-cell-empty" style={isLayoutArrangementActive || !isEnabled ? { pointerEvents: "none" } : {}}>
      <Button
        className="grid-cell-empty__button button--illustrated"
        onClick={() => addNewMap()}
        data-testid="add-map-btn"
      >
        <MapSvg className="illustration" />
        <Trans>Map</Trans>
      </Button>

      <ToolDropdown id={`plot_${layout.id}`} icon={<PlotSvg />} titleElement={<Trans>Plot</Trans>}>
        <button onClick={() => addNewPlot()} data-testid="new-plot-btn" type={"button"}>
          <Trans>Default</Trans>
        </button>
        <button onClick={() => addNewPointForecastPlot()} data-testid="new-meteogram-btn" type={"button"}>
          <Trans>Meteogram</Trans>
        </button>
      </ToolDropdown>

      {showLocationTableOption ? (
        <ToolDropdown id={`table_${layout.id}`} icon={<TableSvg />} titleElement={<Trans>Table</Trans>}>
          <button onClick={() => addNewWeatherTable()} data-testid="new-table-btn" type={"button"}>
            <Trans>Default</Trans>
          </button>
          {/*TODO dropdown looks  weird*/}
          {showLocationTableOption && (
            <button onClick={() => addNewLocationTable()} data-testid="new-location-table-btn" type={"button"}>
              Multi-location
            </button>
          )}
        </ToolDropdown>
      ) : (
        <Button
          className="grid-cell-empty__button button--illustrated"
          onClick={() => addNewWeatherTable()}
          data-testid="add-table-btn"
        >
          <TableSvg className="illustration table-icon" />
          <Trans>Table</Trans>
        </Button>
      )}
      <FeatureGuard flag="REACT_APP_FF_ENERGY_FORECAST">
        <Button
          className="grid-cell-empty__button button--illustrated"
          onClick={() => addNewEnergyPlot()}
          data-testid="add-energy-plot-btn"
        >
          <EnergySvg className="illustration tool-icon" />
          <Trans>Energy Plot</Trans>
        </Button>
      </FeatureGuard>

      <Button
        className="grid-cell-empty__button button--illustrated"
        onClick={() => addNewTephigram()}
        data-testid="add-tephigram-btn"
      >
        <TephigramSvg className="illustration tool-icon" />
        <Trans>Tephigram</Trans>
      </Button>

      <FeatureGuard flag="REACT_APP_FF_COUNTRY_PLOT">
        <Button
          className="grid-cell-empty__button button--illustrated"
          onClick={() => addNewCountryPlot()}
          data-testid="add-country-plot-btn"
        >
          <CountryPlotSvg className="illustration tool-icon" />
          <Trans>Country forecast</Trans>
        </Button>
      </FeatureGuard>

      {/* TODO: Add back once note is production ready */}
      <Button
        className="grid-cell-empty__button button--illustrated"
        onClick={() => addNewNote()}
        data-testid="add-notes-btn"
      >
        <NoteSvg className="illustration tool-icon" />
        <Trans>Notes</Trans>
      </Button>
    </div>
  );
}

import { type TransitionConfigOrTarget, assign } from "xstate";
import type { StringStateValueFrom } from "../state-machine-utils/typeUtilts";
import { wrongActionPlacementError } from "./timeStateActions";
import type { IdleCtx, TimeContextUnion } from "./timeStateCtxTypes";
import { type InitializationEvent, TimeEventType, type TimeEventUnion } from "./timeStateEvents";
import type { TimeStateMachine } from "./timeStateMachine";

// ######################################################
//  Utility for time state machine config
// ######################################################

function isTargetedFor(e: InitializationEvent, state: StringStateValueFrom<TimeStateMachine>): boolean {
  return e.stateValue === state;
}

/**
 * Construct a conditional transition for the state machine initialization
 * so that the event payload can determine which state it will transition to.
 *
 * This was created because the xstate doesn't allow a simple way to define a conditional
 * transition based on the event value.
 *
 * Ref: https://github.com/statelyai/xstate/discussions/1939#discussioncomment-381565
 */
export function conditionalTransition(props: {
  allowedStates: StringStateValueFrom<TimeStateMachine>[];
  fallback: {
    state: StringStateValueFrom<TimeStateMachine>;
    ctx: Exclude<TimeContextUnion, IdleCtx>;
  };
}): TransitionConfigOrTarget<TimeContextUnion, InitializationEvent, TimeEventUnion> {
  const assignEventCtx = (_: TimeContextUnion, e: TimeEventUnion) => {
    if (e.type === TimeEventType.initializeStateMachine) {
      return e.stateCtx;
    }
    throw wrongActionPlacementError(conditionalTransition.name);
  };

  const transitionCondList = props.allowedStates.map((state) => {
    return {
      // If recomputeDisplayTimeUponInitializationcondition here is true, then the state machine transitions to
      // the state defined in target.
      target: state,
      cond: (_: TimeContextUnion, e: InitializationEvent): boolean => {
        if (e.type === TimeEventType.initializeStateMachine) {
          return isTargetedFor(e, state);
        }
        return false;
      },
      // Update the context using the payload in the event.
      actions: assign(assignEventCtx),
    };
  });

  const fallbackCond = {
    target: props.fallback.state,
    actions: assign((ctx: Exclude<TimeContextUnion, IdleCtx>, e: TimeEventUnion) => {
      let stateName = "UNKNOWN";
      // Take the profile ID in case the event's context value contains it
      // Overwriting the profile ID ensures the rest of the state machine cycle works properly.
      let profileId: number | null = null;
      if (e.type === TimeEventType.initializeStateMachine) {
        stateName = e.stateValue;
        profileId = e.stateCtx.id_profile;
      }
      console.error(
        `The state machine could not be properly reinitialized. State="${stateName}" is either not allowed to transition to upon initialization, or doesn't exist on the state machine.`,
      );

      let updatedCtx = props.fallback.ctx;
      if (profileId) {
        updatedCtx = { ...updatedCtx, id_profile: profileId };
      }
      return updatedCtx;
    }),
  };

  return [...transitionCondList, fallbackCond];
}

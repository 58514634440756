/**
 * Convert bytes into a human readable format.
 *
 * @param bytes
 * @return approximate size in human readable format
 */
export function formatByteSize(bytes: number) {
  if (bytes < 1024) return `${bytes} bytes`;
  if (bytes < 1048576) return `${(bytes / 1024).toFixed(3)} KiB`;
  if (bytes < 1073741824) return `${(bytes / 1048576).toFixed(3)} MiB`;
  return `${(bytes / 1073741824).toFixed(3)} GiB`;
}

// helpers to construct a byte size
export function GiB(v: number) {
  return v * 1073741824;
}
export function MiB(v: number) {
  return v * 1048576;
}
export function KiB(v: number) {
  return v * 1024;
}

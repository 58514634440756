/**
 * The values -999, -888, -777 and -666 are reserved throughout the whole API. If you receive any of these values, the data is either not applicable or not available:
 * -999:	data is not available (this rarely occurs, if so, try another source)
 * -888:	parameter specific reserved value (check the description of the corresponding parameter, e.g. sunset if the sun does not set at the queried location and time)
 * -777:	parameter specific reserved value (check the description of the corresponding parameter, e.g. sunrise if the sun does not rise at the queried location and time)
 * -666:	data not applicable (e.g. cloud top temperature if there are no clouds)
 *
 *  API Link: https://www.meteomatics.com/en/api/response/reserved-values/#reservedvalues
 */
export const ReservedValues: number[] = [-666, -777, -888, -999];

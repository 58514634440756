import { Duration, type DurationUnit } from "luxon";

import { t } from "@lingui/macro";
import { enumValues } from "@mm/api.meteomatics.com/lib/utility/enum";
import type { RelativeDateTimePosition } from "@mm/metx-workbench.meteomatics.com";

export const durationUnitDisplayName: {
  [key in DurationUnit]: () => string;
} = {
  year: () => t`Year`,
  years: () => t`Years`,
  quarter: () => t`Quarter`,
  quarters: () => t`Quarters`,
  month: () => t`Month`,
  months: () => t`Months`,
  week: () => t`Week`,
  weeks: () => t`Weeks`,
  day: () => t`Day`,
  days: () => t`Days`,
  hour: () => t`Hour`,
  hours: () => t`Hours`,
  minute: () => t`Minute`,
  minutes: () => t`Minutes`,
  second: () => t`Second`,
  seconds: () => t`Seconds`,
  millisecond: () => t`Millisecond`,
  milliseconds: () => t`Milliseconds`,
};

export enum DurationWhitelist {
  PT5M = "PT5M",
  PT10M = "PT10M",
  PT15M = "PT15M",
  PT20M = "PT20M",
  PT30M = "PT30M",
  PT1H = "PT1H",
  PT3H = "PT3H",
  PT6H = "PT6H",
  PT12H = "PT12H",
  P1D = "P1D",
}

export const durationDisplayName: {
  [key in DurationWhitelist]: () => string;
} = {
  PT5M: () => t`5 mins`,
  PT10M: () => t`10 mins`,
  PT15M: () => t`15 mins`,
  PT20M: () => t`20 mins`,
  PT30M: () => t`30 mins`,
  PT1H: () => t`1 hour`,
  PT3H: () => t`3 hours`,
  PT6H: () => t`6 hours`,
  PT12H: () => t`12 hours`,
  P1D: () => t`1 day`,
};

export enum OperationalDayDurations {
  "P-4W" = "P-4W",
  "P-3W" = "P-3W",
  "P-2W" = "P-2W",
  "P-1W" = "P-1W",
  "P-6D" = "P-6D",
  "P-5D" = "P-5D",
  "P-4D" = "P-4D",
  "P-3D" = "P-3D",
  "P-2D" = "P-2D",
  "P-1D" = "P-1D",
  PT0S = "PT0S",
  P1D = "P1D",
  P2D = "P2D",
  P3D = "P3D",
  P4D = "P4D",
  P5D = "P5D",
  P6D = "P6D",
  P1W = "P1W",
  P2W = "P2W",
  P3W = "P3W",
  P4W = "P4W",
}
export const operationalDayDurations = enumValues(OperationalDayDurations);
export const operationalDayDurationsDisplayName: {
  [key in OperationalDayDurations]: () => string;
} = {
  "P-4W": () => t`four weeks ago`,
  "P-3W": () => t`three weeks ago`,
  "P-2W": () => t`two weeks ago`,
  "P-1W": () => t`a week ago`,
  "P-6D": () => t`six days ago`,
  "P-5D": () => t`five days ago`,
  "P-4D": () => t`four days ago`,
  "P-3D": () => t`three days ago`,
  "P-2D": () => t`two days ago`,
  "P-1D": () => t`yesterday`,

  PT0S: () => t`Today`,
  P1D: () => t`Tomorrow`,
  P2D: () => t`in two days`,
  P3D: () => t`in three days`,
  P4D: () => t`in four days`,
  P5D: () => t`in five days`,
  P6D: () => t`in six days`,
  P1W: () => t`in a week`,
  P2W: () => t`in two weeks`,
  P3W: () => t`in three weeks`,
  P4W: () => t`in four weeks`,
};

export interface DurationDesc {
  isoFormatted: string;
  duration: Duration;
  displayName: () => string;
}

export const durationOptions: DurationDesc[] = Object.entries(durationDisplayName).map(
  ([isoFormatted, displayName]) => ({
    isoFormatted,
    displayName,
    duration: Duration.fromISO(isoFormatted),
  }),
);

export const resolutionOptions: DurationDesc[] = durationOptions;

export interface RelativeDateTimePositionDesc {
  value: string;
  displayName: () => string;
}

export const relativeDateTimePositionDisplayName: {
  [key in RelativeDateTimePosition]: () => string;
} = {
  day: () => t`midnight`,
  now_with_5min_precision: () => t`Now with 5min precision`,
  now_with_10min_precision: () => t`Now with 10min precision`,
  now_with_15min_precision: () => t`Now with 15min precision`,
  now_with_30min_precision: () => t`Now with 30min precision`,
  now_with_1h_precision: () => t`Now with 1h precision`,
  // now_with_2h_precision: () => t`Now with 2h precision`,
  now_with_6h_precision: () => t`Now with 6h precision`,
  now_with_12h_precision: () => t`Now with 12h precision`,
};

export const relativeDateTimePositionOptions: RelativeDateTimePositionDesc[] = Object.entries(
  relativeDateTimePositionDisplayName,
).map(([value, displayName]) => ({ value, displayName }));

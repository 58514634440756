import type { AnySourceData, GeoJSONSource } from "mapbox-gl";

export function addSource(map: mapboxgl.Map, sources: { sourceId: string; source: AnySourceData }[]) {
  for (let i = 0; i < sources.length; i++) {
    map.addSource(sources[i].sourceId, sources[i].source);
  }
}

export function updateSourceData(
  map: mapboxgl.Map,
  sourceId: string,
  data: GeoJSON.Feature<GeoJSON.Geometry> | GeoJSON.FeatureCollection<GeoJSON.Geometry> | string,
) {
  const source = map.getSource(sourceId) as GeoJSONSource;
  if (!source) {
    console.warn(`Was not able to find the source name ${sourceId}. Skipping to set data to the source`);
    return;
  }
  source.setData(data);
}

export function removeSource(map: mapboxgl.Map, sourceIds: string[]) {
  for (let i = 0; i < sourceIds.length; i++) {
    map.removeSource(sourceIds[i]);
  }
}

import { t } from "@lingui/macro";
import type { GuiTimeZone } from "@mm/metx-workbench.meteomatics.com";
import { compile } from "ejs";
import type { GeoJsonProperties } from "geojson";
import { DateTime } from "luxon";
import template from "./templates/lighting.ejs";

const compiledTemplate = compile(template);

export function createLightingTooltip(features: GeoJsonProperties[], timezone: GuiTimeZone) {
  const data = features.map((feature) => {
    const coordinates = feature?.geometry.coordinates as [number, number];

    const strikeTime = feature?.properties
      ? DateTime.fromISO(feature.properties.stroke_time, {
          zone: timezone,
        }).toISO({ suppressMilliseconds: true })
      : t`No Information`;

    const coordinate = coordinates ? `${coordinates?.[1]?.toFixed(5)}, ${coordinates?.[0]?.toFixed(5)}` : "-";

    return { coordinate, strikeTime };
  });

  return compiledTemplate({ data });
}

import type { LayerKind } from "@/layers/utility/createLayerObject";
import { WeatherParameterForm } from "@/overlay/components/WeatherParameterForm";
import { useEffect } from "react";
import { useController } from "react-hook-form";
import { type HasLayerIdxNarrowing, type PartialWeatherParameter, autofill } from "weather-parameter-utils";
import type { InputInstruction } from "../../SmartFormUtils/commonTypeUtils";

type WeatherParameterFormProps = {
  kind: LayerKind;
  parameter: HasLayerIdxNarrowing<PartialWeatherParameter>;
};

type InputProps = {
  instruction: InputInstruction<WeatherParameterFormProps>;
};

export function WrappedWeatherParameterForm({ instruction }: InputProps) {
  const {
    formMethods,
    formValueName,
    currValue: { kind, parameter },
  } = instruction;

  const {
    field: { onChange, value },
  } = useController({
    name: formValueName,
    control: formMethods.control,
    defaultValue: parameter,
  });

  useEffect(() => {
    formMethods.setValue(formValueName, parameter);
  }, [parameter, formValueName, formMethods]);

  return (
    <WeatherParameterForm
      layerKind={kind}
      withTitle={false}
      showExactParameter={true}
      autofill={false}
      parameter={value}
      onChange={(parameter) => onChange(autofill(parameter))}
    />
  );
}

import { constantByName, createFramebuffer, createRenderTexture, createRenderbuffer } from "@/layers/utility/webgl";
import type { GridDimension } from "@mm/api.meteomatics.com";
import { InterpolationMode } from "weather-parameter-utils";

export interface TileSetFramebuffer {
  dataRasterizationSize: GridDimension;
  framebuffer: WebGLFramebuffer;
  depth: WebGLRenderbuffer;
  color: WebGLTexture;
}

export const createTileSetFrameBufferHolder = (gl: WebGLRenderingContext) => {
  let _tileSetFrameBuffer: TileSetFramebuffer | undefined;

  const _createNewTileSetFrameBuffer = (
    dataRasterizationSize: GridDimension,
    interpolationMode: InterpolationMode,
  ): TileSetFramebuffer => {
    // Right now these are somehow similar to function inside ./util.ts
    // TODO Refactor, so we use same function everywhere
    const framebuffer = createFramebuffer(gl);
    gl.bindFramebuffer(gl.FRAMEBUFFER, framebuffer);

    // rgba
    const color = createRenderTexture(gl, dataRasterizationSize, {
      internalFormat: gl.RGBA,
      format: gl.RGBA,
      type: gl.FLOAT,
      interpolationMode,
    });
    gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, color, 0);

    const depth = createRenderbuffer(gl);
    gl.bindRenderbuffer(gl.RENDERBUFFER, depth);

    gl.renderbufferStorage(
      gl.RENDERBUFFER,
      gl.DEPTH_COMPONENT16,
      dataRasterizationSize.width,
      dataRasterizationSize.height,
    );

    gl.framebufferRenderbuffer(gl.FRAMEBUFFER, gl.DEPTH_ATTACHMENT, gl.RENDERBUFFER, depth);

    const status = gl.checkFramebufferStatus(gl.FRAMEBUFFER);

    if (status !== gl.FRAMEBUFFER_COMPLETE) {
      throw Error(`failed to construct framebuffer for prepass. Status ${constantByName(gl, status)}`);
    }

    return {
      dataRasterizationSize,
      framebuffer,
      color,
      depth,
    };
  };

  const getCleanTileSetFramebuffer = (
    dataRasterizationSize: GridDimension,
    interpolationMode: InterpolationMode = InterpolationMode.IntervalOrRatio_Linear,
  ): TileSetFramebuffer => {
    if (!_tileSetFrameBuffer) {
      _tileSetFrameBuffer = _createNewTileSetFrameBuffer(dataRasterizationSize, interpolationMode);
    } else {
      const size = _tileSetFrameBuffer.dataRasterizationSize;
      if (size.width !== dataRasterizationSize.width || size.height !== dataRasterizationSize.height) {
        const depth = _tileSetFrameBuffer.depth;
        const color = _tileSetFrameBuffer.color;
        const v = _createNewTileSetFrameBuffer(dataRasterizationSize, interpolationMode);
        gl.deleteRenderbuffer(depth);
        gl.deleteTexture(color);
        _tileSetFrameBuffer = v;
      }
    }
    return _tileSetFrameBuffer;
  };

  return {
    getCleanTileSetFramebuffer,
    get fb() {
      return _tileSetFrameBuffer;
    },
  };
};

export type TileSetFrameBufferHolder = ReturnType<typeof createTileSetFrameBufferHolder>;

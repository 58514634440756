import { DateTime } from "luxon";
import { ParameterUnit, ModelIdentifier } from "./Identifier";
import { Area, Area_Transferable } from "./Area";
import { BaseRequest, MultiParamRequest, MultiParamRequest_Transferable } from "./BaseRequest";
import { CoordinateSystem } from "./CoordinateSystem";
import { DateTime_fromTransferable, DateTime_toTransferable, DateTime_Transferable } from "./DateTime";

export interface WfsRequest<
  C extends CoordinateSystem,
  T extends "GetCapabilities" | "DescribeFeatureType" | "GetFeature" =
    | "GetCapabilities"
    | "DescribeFeatureType"
    | "GetFeature",
> extends Omit<MultiParamRequest, "parameters" | "ensSelect">,
    BaseRequest {
  /**
   * Corresponds to REQUEST parameter on the WFS endpoint
   */
  requestType: T;
  /**
   * Corresponds to BBOX parameter on the WFS endpoint
   */
  area: Area<C>;
  /**
   * Corresponds to TYPENAME parameter on the WFS endpoint.
   * Watch out for different format of the model name for WFS endpoints.
   * e.g) For the station obs endpoints, the regular endpoints use mix-obs, but the WFS interface uses stations_mix-obs
   */
  model: ModelIdentifier;
  /**
   * Corresponds to PARAMETERS parameter on the WFS endpoint.
   * Some WFS endpoints such as lightning only requires TYPENAME, in which case, you should use model attribute.
   */
  parameters?: [ParameterUnit, ...ParameterUnit[]];
  /**
   * Corresponds to TIME parameter on the WFS endpoint.
   */
  datetime?: DateTime;
}

export interface WfsRequest_Transferable<C extends CoordinateSystem>
  extends Omit<MultiParamRequest_Transferable, "parameters" | "ensSelect"> {
  requestType: "GetCapabilities" | "DescribeFeatureType" | "GetFeature";
  area: Area_Transferable<C>;
  model: ModelIdentifier;
  parameters?: [ParameterUnit, ...ParameterUnit[]];
  datetime: DateTime_Transferable;
}

export function WfsRequest_toTransferable<C extends CoordinateSystem>(req: WfsRequest<C>): WfsRequest_Transferable<C> {
  return {
    ...req,
    area: req.area.toTransferable(),
    datetime: DateTime_toTransferable(req.datetime),
  };
}

export function WfsRequest_fromTransferable<C extends CoordinateSystem>(
  req: WfsRequest_Transferable<C>,
): WfsRequest<C> {
  return {
    ...req,
    requestType: req.requestType,
    area: Area.fromTransferable(req.area),
    datetime: DateTime_fromTransferable(req.datetime),
    boundingBoxLimit: req.boundingBoxLimit ? Area.fromTransferable(req.boundingBoxLimit) : null,
  };
}

import type { TabBase } from "@mm/metx-workbench.meteomatics.com";
import "array.prototype.tosorted/auto";

export function findNearestTab(arr: TabBase[], targetTabId: number) {
  const index = arr.findIndex((item) => item.id === targetTabId);
  // If not found
  if (index === -1) {
    return;
  }
  // If it's the first item, return the next item
  if (index === 0) {
    return arr[1] || undefined;
  }
  // Otherwise, return the previous item
  return arr[index - 1] || undefined;
}

export function getSortedTabs(tabs: TabBase[]): TabBase[] {
  return tabs.toSorted((a, b) => {
    return (a.order ?? Number.MAX_SAFE_INTEGER) - (b.order ?? Number.MAX_SAFE_INTEGER) || a.id - b.id;
  });
}

import { Api } from "@/api/Api";
import type { Profile, ProfileCreate, ProfileUpdate } from "@mm/metx-workbench.meteomatics.com";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const importProfile = createAsyncThunk("api/profile/import", async (payLoad: { data: Profile }, _thunkAPI) => {
  return await Api.profile.v1ProfileImportV2({ body: payLoad.data }).then((profile) => {
    return profile;
  });
});

export const fetchProfiles = createAsyncThunk("api/profiles", async (_thunkApi) => {
  return await Api.profile.v2ProfileListAll({}).then((profiles) => {
    return profiles;
  });
});

export const createProfile = createAsyncThunk("api/profile/create", async (payLoad: ProfileCreate, _thunkAPI) => {
  return await Api.profile.v2ProfileCreate({ profileCreate: payLoad }).then((profile) => {
    return profile;
  });
});

export const updateProfile = createAsyncThunk(
  "api/profile/update",
  async (payLoad: { profileId: Profile["id"]; data: ProfileUpdate }, _thunkAPI) => {
    return await Api.profile
      .v2ProfileUpdate({ profileId: payLoad.profileId, profileUpdate: payLoad.data })
      .then((profile) => {
        return profile;
      });
  },
);

export const removeProfile = createAsyncThunk(
  "api/profile/delete",
  (payLoad: { profileId: Profile["id"] }, _thunkAPI) => {
    return Api.profile.v2ProfileDelete({ profileId: payLoad.profileId }).then(() => {
      return payLoad.profileId;
    });
  },
);

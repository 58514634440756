/**
 * **OAuth 2.1 implementation for MetX and the Meteomatics API**
 *
 * MetX obtains an Auth-Token by following the OAuth 2.1 draft specification
 * https://tools.ietf.org/html/draft-parecki-oauth-v2-1-03 . Meteomatics implements its own version of the OAuth 2.1
 * server on https://login.meteomatics.com . If the login succeeds, MetX is able to obtain an `access_token` and
 * a `refresh_token`. Both the `access_token` and `refresh_token` are considered a session secret and should not be
 * shared outside of the application.
 *
 * - The `access_token` is a cryptographically signed token that authenticates the user. This token is used to authenticate
 *   the user with the meteomatics API and the metX backend. It has a limited lifetime and must be periodically refreshed.
 * - The `refresh_token` is a token exported by the login service. It ties the user session with the respective
 *   session on the login server. The `refresh_token` is used to fetch a new `access_token`.
 *   The `refresh_token` can only be used once: It is updated when the `refresh_token` is updated.
 */
// Based on https://reactrouter.com/web/example/auth-workflow, which in turn is based on https://usehooks.com/useAuth/ (retrieved 27. Nov 2020)
// This function implements OAuth 2.1: https://tools.ietf.org/html/draft-parecki-oauth-v2-1-03
import {
  computeRefreshDelayMillis,
  decodeOAuthSession,
  isAuthenticated,
  isLoggedIn,
  oauthHandleAuthorizationCode,
  oauthHandleRefreshToken,
  oauthHandleSignin,
} from "@/auth/utils";
import { ENV } from "@/env";
import { usePromise } from "@/hooks";
import { useLocalStorage } from "@/persist";
import { pageLogin } from "@/route";
import type { OAuthTokenResponse } from "@mm/login.meteomatics.com";
import {
  type BaseAPI,
  Configuration,
  ProfileApi,
  type ResponseContext,
  SessionApi,
  ToolsApi,
  type User,
} from "@mm/metx-workbench.meteomatics.com";
import type React from "react";
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import { oAuthConfiguration } from "./configuration";
import { PING_INTERVAL, usePingInterval, withDisabledSessionOverlay } from "./device-tracking-session";

export type { User };

export type WithArrivalTime<S> = S & { arrivalTimeMillis: number };

export enum OAuthState {
  LoggedOut = 0,
  AuthorizationCode = 1,
  Verify = 2,
  LoggedIn = 3,
}

export interface OAuthSession {
  state: OAuthState;
  codeVerifier?: string;
  codeChallenge?: string;
  authorizationUrl?: string;
  redirectUrl?: string;
  token?: WithArrivalTime<OAuthTokenResponse>;
}

export interface AuthStrategy {
  session: OAuthSession | null;
  signin: (redirectUrl?: string) => Promise<OAuthSession>;
  authorizationCode: (code: string) => Promise<OAuthSession | null>;
  signout: () => Promise<void>;

  invalid: boolean;
  sessionActivated: boolean;
  activateSession: () => void;
  disableSession: () => void;
  fetchToken: () => Promise<void>;
}

function useTokenRefresher(state: OAuthSession | null, invalid: boolean) {
  const timerRef = useRef<NodeJS.Timeout>();
  const [authState, setAuthState] = useState<OAuthSession | null>(state);

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  }, [invalid]);

  const fetchToken = useCallback(async () => {
    if (!state) {
      return;
    }
    if (state.state === OAuthState.LoggedIn && state.token) {
      // Avoid fetching the token if it is still valid.
      const delay = computeRefreshDelayMillis(state.token);
      if (delay > 0) {
        if (timerRef.current) {
          clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(fetchToken, computeRefreshDelayMillis(state.token));
        return;
      }
    }

    try {
      const session = await oauthHandleRefreshToken(state);
      if (!session?.token) {
        setAuthState(null);
        return;
      }
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = setTimeout(fetchToken, computeRefreshDelayMillis(session.token));
      setAuthState(session);
    } catch (e) {
      // Logout if refresh failed.
      setAuthState(null);
      return;
    }
  }, [state]);

  useEffect(() => {
    if (!state?.token) {
      return;
    }
    fetchToken();

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [fetchToken, state?.token]);

  return {
    authState,
    fetchToken,
  };
}

/**
 * OAuth2.1 Auth Strategy implementation.
 */
function useProvideAuth(): AuthStrategy {
  const [sessionActivated, setSessionActivated] = useState(false);
  const [sessionInvalid, setSessionInvalid] = useState(false);

  const [storageState, setStorageState] = useLocalStorage<OAuthSession | null>(
    "auth",
    decodeOAuthSession,
    null,
    "persist-on-request",
  );

  const sessionApi = useMemo(() => {
    if (!isAuthenticated(storageState) || storageState?.token == null) {
      return null;
    }
    return new SessionApi(
      new Configuration({ basePath: ENV.baseUrlMetx, accessToken: storageState.token.access_token }),
    );
  }, [storageState]);
  // return authState to make logic easier to follow
  const { authState, fetchToken } = useTokenRefresher(storageState, sessionInvalid);

  const updateSession = useCallback(async () => {
    if (sessionApi && storageState) {
      await sessionApi.v1UpdateSession().then(() => setStorageState(storageState, true));
      return true;
    }
    return false;
  }, [sessionApi, setStorageState, storageState]);

  const activateSession = useCallback(() => {
    updateSession()
      .then((succeeds) => {
        if (succeeds) {
          setSessionInvalid(false);
          setSessionActivated(true);
        }
      })
      .catch(console.error);
  }, [setSessionActivated, updateSession]);

  const disableSession = useCallback(() => {
    setSessionInvalid(true);
  }, [setSessionInvalid]);

  useEffect(() => {
    setSessionActivated(false);
    // TODO not passing "true" to set storage state is causing the problem with refreshed token after timeout
    setStorageState(authState);
  }, [authState, setSessionActivated, setStorageState]);

  const { result, start } = usePingInterval(PING_INTERVAL);

  useEffect(() => {
    if (sessionActivated) {
      start();
    }
  }, [sessionActivated, start]);

  useEffect(() => {
    if (result) {
      if (result.status === 403) {
        fetchToken();
      }
      setSessionInvalid(!!result && result.status === 460);
    }
  }, [result, setSessionInvalid, fetchToken]);

  const signIn = usePromise(
    (redirectUrl?: string): Promise<OAuthSession> => {
      return oauthHandleSignin(redirectUrl).then((session: OAuthSession) => {
        setStorageState(session, true);
        if (!session.codeChallenge) {
          return Promise.reject();
        }
        return session;
      });
    },
    [setStorageState],
  );

  const authorizationCode: (code: string) => Promise<OAuthSession | null> = usePromise(
    (code: string): Promise<OAuthSession | null> => {
      if (!storageState || storageState.state !== OAuthState.AuthorizationCode) {
        return Promise.reject();
      }

      return oauthHandleAuthorizationCode(code, storageState)
        .then((newState) => {
          setStorageState(newState, true);
          return newState;
        })
        .catch((e) => {
          console.error(e);
          return null;
        });
    },
    [storageState, setStorageState],
  );

  const signout = usePromise(() => {
    return Promise.resolve().then(() => {
      // always erase local storage on sign out to clear the token.
      setStorageState(null, true);
      // Redirect to login.meteomatics's logout endpoint to clear the OAuth session and its login cookie.
      // Note: This method should be called on a page which doesn't require the user login.
      // Otherwise login.meteomatics redirection gets cancelled when you are on a route that requires login,
      // which immediately unmounts the page after local storage is cleared.
      const redirectUrlAfterLogout = window.location.origin;
      const queryParams = new URLSearchParams();
      queryParams.set("client_id", oAuthConfiguration.clientId);
      queryParams.set("redirect_url", redirectUrlAfterLogout);
      const signoutUrl = `${oAuthConfiguration.oauthLogoutUrl}?${queryParams.toString()}`;
      window.location.replace(signoutUrl);
    });
  }, [setStorageState]);

  return {
    session: storageState,
    signin: signIn,
    authorizationCode: authorizationCode,
    signout: signout,

    invalid: sessionInvalid,
    sessionActivated,
    activateSession,
    fetchToken,
    disableSession,
  };
}

const authContext = createContext<AuthStrategy>({
  session: null,
  signin(redirect_url?: string): Promise<OAuthSession> {
    throw new Error("missing provider");
  },
  authorizationCode(code: string): Promise<OAuthSession | null> {
    throw new Error("missing provider");
  },
  signout(): Promise<void> {
    throw new Error("missing provider");
  },

  invalid: false,
  sessionActivated: false,
  activateSession() {
    throw new Error("missing provider");
  },
  disableSession() {
    throw new Error("missing provider");
  },
  fetchToken() {
    throw new Error("missing provider");
  },
});

export function useAuth() {
  return useContext(authContext);
}

export function ProvideAuth({ children }: any) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export function useApi<ApiTy extends BaseAPI, ApiConfig>(
  apiCtor: new (_: ApiConfig) => ApiTy,
  apiConfigCtor: new (_: { basePath?: string; accessToken: string }) => ApiConfig,
) {
  const { session, disableSession, sessionActivated, fetchToken } = useAuth();

  const api = useMemo<ApiTy | null>(() => {
    if (!sessionActivated || !isAuthenticated(session) || session?.token == null) {
      return null;
    }
    return new apiCtor(
      new apiConfigCtor({ basePath: ENV.baseUrlMetx, accessToken: session.token.access_token }),
    ).withPostMiddleware((context: ResponseContext) => {
      const { response } = context;

      // Disable session on 460
      if (response.status === 460) {
        disableSession();
        throw context.response;
      }
      if (response.status === 403) {
        return fetchToken().then(() => {
          return Promise.resolve(context.response);
        });
      }
      return Promise.resolve(context.response);
    });
  }, [session, sessionActivated, disableSession, apiConfigCtor, apiCtor, fetchToken]);

  return api;
}

export function useProfileApi() {
  return useApi<ProfileApi, Configuration>(ProfileApi, Configuration);
}

export function useToolsApi() {
  return useApi<ToolsApi, Configuration>(ToolsApi, Configuration);
}

const SessionOverlay = withDisabledSessionOverlay(({ children }: { children: React.ReactNode }) => <>{children}</>);
// A `Route` that can only be activated if the user is logged in. If the user is not
// logged in on activation, the login dialog is shown before activating the route.
export function PrivateRoute({ children, ...rest }: any) {
  const { session, invalid, sessionActivated } = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn(session) ? (
          <SessionOverlay sessionActivated={sessionActivated} invalid={invalid && sessionActivated}>
            {children}
          </SessionOverlay>
        ) : (
          <Redirect
            to={{
              pathname: pageLogin.asString(),
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

// A `Route` that can only be activated if the user is NOT logged in. If the user is logged in, `routeIfLoggedIn` is shown instead.
// TODO: this seems fragile. maybe we should load the login page, show a spinner that the login is verified with the server, and then redirect.
// We will probably run in endless redirect loops when someone forgets to erase expired login credentials somewhere in the codebase
export function PublicOnlyRoute({
  children,
  routeIfLoggedIn,
  ...rest
}: {
  children: any;
  routeIfLoggedIn: string;
  [rest: string]: any;
}) {
  const auth = useAuth();

  return (
    <Route
      {...rest}
      render={() =>
        isLoggedIn(auth.session) ? (
          <Redirect
            to={{
              pathname: routeIfLoggedIn,
            }}
          />
        ) : (
          children
        )
      }
    />
  );
}

export * from "./energyApi";

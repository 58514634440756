import { type AsyncResult, promiseToAsyncResult } from "@/cache/AsyncResult";
import { ShaderBuilder, ShaderCompiler } from "@/shader-compiler";

import type { Program } from "../../types";
import { extractParameterLocations } from "./utils";

// TODO: check when this import runs and catch the error, maybe delay until `compile` calls.
const shaders = import(/* webpackChunkName: "shaders" */ "./all");

export const shaderCompiler = new ShaderCompiler(shaders);

function compileProgram(gl: WebGLRenderingContext, vertexShader: ShaderBuilder, fragmentShader: ShaderBuilder) {
  return promiseToAsyncResult(
    shaderCompiler.compileProgram(gl, vertexShader, fragmentShader).then((program) => {
      if (program instanceof Error) {
        throw program;
      }

      return { program, parameters: extractParameterLocations(gl, program) };
    }),
  );
}

/**
 *
 * Program for drawing particles
 *
 * @param gl webGL context
 * @returns AsyncResult of compiled program
 */
export function createDrawProgram(gl: WebGLRenderingContext): AsyncResult<Program> {
  const vertexShader = new ShaderBuilder("draw.vert");
  const fragmentShader = new ShaderBuilder("draw.frag");

  return compileProgram(gl, vertexShader, fragmentShader);
}

/**
 *
 * Program for drawing texture
 *
 * @param gl webGL context
 * @returns AsyncResult of compiled program
 */
export function createTextureProgram(gl: WebGLRenderingContext): AsyncResult<Program> {
  const vertexShader = new ShaderBuilder("texture.vert");
  const fragmentShader = new ShaderBuilder("texture.frag");

  return compileProgram(gl, vertexShader, fragmentShader);
}

/**
 *
 * Program for updating particle position textures
 *
 * @param gl webGL context
 * @returns AsyncResult of compiled program
 */
export function createUpdateProgram(gl: WebGLRenderingContext): AsyncResult<Program> {
  const vertexShader = new ShaderBuilder("update.vert");
  const fragmentShader = new ShaderBuilder("update.frag");

  return compileProgram(gl, vertexShader, fragmentShader);
}

/**
 *
 * Program for concatenating multiple tiles (Vector U and V) into single wind map tile
 * and drawing on screen space by transforming with matrix
 *
 * @param gl webGL context
 * @returns AsyncResult of compiled program
 */
export function createTilingProgram(gl: WebGLRenderingContext): AsyncResult<Program> {
  const vertexShader = new ShaderBuilder("tile.vert");
  const fragmentShader = new ShaderBuilder("tile.frag");

  return compileProgram(gl, vertexShader, fragmentShader);
}

/**
 *
 * Program for drawing concatenated wind map tile into simple quad without any transformation matrix
 *
 * @param gl webGL context
 * @returns AsyncResult of compiled program
 */
export function createQuadTilingProgram(gl: WebGLRenderingContext): AsyncResult<Program> {
  const vertexShader = new ShaderBuilder("quad.vert");
  const fragmentShader = new ShaderBuilder("tile.frag");

  return compileProgram(gl, vertexShader, fragmentShader);
}

/**
 * Creates an unprojected tile program using the provided WebGL rendering context.
 *
 * @param {WebGLRenderingContext} gl - The WebGL rendering context.
 * @return {AsyncResult<Program>} The compiled program.
 */
export function createUnprojectedTileProgram(gl: WebGLRenderingContext): AsyncResult<Program> {
  const vertexShader = new ShaderBuilder("unprojected_tile.vert");
  const fragmentShader = new ShaderBuilder("tile.frag");

  return compileProgram(gl, vertexShader, fragmentShader);
}

/**
 *
 * Basic quad drawing program
 *
 * @param gl webGL context
 * @returns AsyncResult of compiled program
 */
export function createQuadProgram(gl: WebGLRenderingContext): AsyncResult<Program> {
  const vertexShader = new ShaderBuilder("quad.vert");
  const fragmentShader = new ShaderBuilder("quad.frag");

  return compileProgram(gl, vertexShader, fragmentShader);
}

export function createProjectionPlaneProgram(gl: WebGLRenderingContext): AsyncResult<Program> {
  const vertexShader = new ShaderBuilder("projection_plane.vert");
  const fragmentShader = new ShaderBuilder("projection_plane.frag");

  return compileProgram(gl, vertexShader, fragmentShader);
}

import { debounce } from "lodash";
import type React from "react";
import { type InputHTMLAttributes, useMemo } from "react";

interface DebouncedInputProps extends InputHTMLAttributes<HTMLInputElement> {
  inputText: string;
  onInputChange: (inputText: string) => void;
  placeholderInput?: string;
  delay?: number;
  debouncedCallback: (inputText: string) => void;
}

/**
 *
 * @param onInputChange regular input change
 * @param placeholderInput placeholder that appears in the input
 * @param delay debouncer delay
 * @param inputText input value
 * @param debouncedCallback callback function that is debounced.  >> Must be wrapped in useCallback() <<
 * @param props InputElement props
 */
const DebouncedInput: React.FunctionComponent<DebouncedInputProps> = ({
  onInputChange,
  placeholderInput,
  delay = 300,
  inputText,
  debouncedCallback,
  ...props
}) => {
  const debouncedChangeHandler = useMemo(() => debounce(debouncedCallback, delay), [debouncedCallback, delay]);

  const handleInputChangeEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    onInputChange(inputValue);
    debouncedChangeHandler(inputValue);
  };

  return (
    <>
      <input
        type="text"
        value={inputText}
        onChange={handleInputChangeEvent}
        placeholder={placeholderInput}
        {...props}
      />
    </>
  );
};

export default DebouncedInput;

import { realize_first_value_of_datetime_desc } from "@/time";
import type { DateTimeDesc, GuiTimeZone } from "@mm/metx-workbench.meteomatics.com";
import type { PlaybackSetter } from "./PlaybackSetter";
import { PlaybackState } from "./PlaybackState";

/**
 * Collection of all playback states within the application.
 */
export class Playback {
  private state: Map<string, PlaybackState>;
  /**
   * Create a new playback instance.
   *
   * This class instance MUST have ownership of `setter`. Calling `setter` from elsewhere will result
   * in inconsistent state!
   *
   * @param onUpdate anounces a change to the playback state. the given object is guranteed to have the
   * following semantics. the wrapping object `{playback : Playback }` is guranteed to fail a reference
   * comparison. The inner payload `Playback` is guranteed to pass a reference comparison.
   */
  constructor(readonly onUpdate: PlaybackSetter) {
    this.state = new Map();
    this.onUpdate({ playback: this });
  }

  /**
   * Update the valid datetime interval to match the reactive store.
   */
  private reinitializePlaybackState(key: string, tabDateTimeDesc: DateTimeDesc, timeZone: GuiTimeZone) {
    const previousState = this.state.get(key);

    // if nothing changed, do nothing
    if (
      previousState &&
      previousState.rawDescription() === tabDateTimeDesc &&
      previousState.rawTimeZone() === timeZone
    ) {
      return previousState;
    }

    // TODO: set playhead as close as possible to old location if the timeZone or datetimedesc changed
    // This is underspecified. e.g. if the timezone shifts the interval by 1h, should we move the playhead
    // to the same absolute time, or keep the relative progress within the interval? The second one sounds
    // logical until you consider that this will request new api data if you simply change the display timezone
    const currentTime = realize_first_value_of_datetime_desc(tabDateTimeDesc, timeZone);

    const onUpdate = (tabState: PlaybackState) => {
      this.state.set(key, tabState);
      this.onUpdate({ playback: this });
    };

    const playbackState = new PlaybackState(onUpdate, tabDateTimeDesc, timeZone, currentTime, true, true);
    this.state.set(key, playbackState);
    this.onUpdate({ playback: this });
    return playbackState;
  }

  reinitializeTab(tabId: number, tabDateTimeDesc: DateTimeDesc, timeZone: GuiTimeZone): PlaybackState {
    return this.reinitializePlaybackState(`tab-${tabId}`, tabDateTimeDesc, timeZone);
  }

  reinitializeGlobal(profileId: number, globalDateTimeDesc: DateTimeDesc, timeZone: GuiTimeZone): PlaybackState {
    return this.reinitializePlaybackState(`global-${profileId}`, globalDateTimeDesc, timeZone);
  }

  getTab(tabId: number): PlaybackState | null {
    return this.state.get(`tab-${tabId}`) ?? null;
  }

  getGlobal(profileId: number): PlaybackState | null {
    return this.state.get(`global-${profileId}`) ?? null;
  }
}

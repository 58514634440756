import type { BitField } from "@/utility/bitfield";
import * as bitfield from "@/utility/bitfield";
import { enumValues } from "@/utility/enum";

enum WWCodeProperty {
  SNOW = 1 << 0,
  STORM = 1 << 1,
  RAIN = 1 << 2,
  FOG = 1 << 3,
}

const wwCodeMapping: { [value: number]: BitField<WWCodeProperty> } = {
  10: WWCodeProperty.FOG,
  11: WWCodeProperty.FOG,
  12: WWCodeProperty.FOG,
  13: WWCodeProperty.STORM,
  14: WWCodeProperty.RAIN,
  15: WWCodeProperty.RAIN,
  16: WWCodeProperty.RAIN,
  17: WWCodeProperty.STORM,
  18: WWCodeProperty.STORM,
  19: WWCodeProperty.STORM,
  20: WWCodeProperty.RAIN | WWCodeProperty.SNOW,
  21: WWCodeProperty.RAIN,
  22: WWCodeProperty.RAIN | WWCodeProperty.SNOW,
  23: WWCodeProperty.RAIN | WWCodeProperty.SNOW,
  24: WWCodeProperty.STORM,
  25: WWCodeProperty.RAIN,
  26: WWCodeProperty.RAIN | WWCodeProperty.SNOW,
  27: WWCodeProperty.STORM,
  28: WWCodeProperty.FOG,
  29: WWCodeProperty.STORM,
  36: WWCodeProperty.RAIN | WWCodeProperty.SNOW,
  37: WWCodeProperty.RAIN | WWCodeProperty.SNOW,
  38: WWCodeProperty.RAIN | WWCodeProperty.SNOW,
  39: WWCodeProperty.RAIN | WWCodeProperty.SNOW,
  40: WWCodeProperty.FOG,
  41: WWCodeProperty.FOG,
  42: WWCodeProperty.FOG,
  43: WWCodeProperty.FOG,
  44: WWCodeProperty.FOG,
  45: WWCodeProperty.FOG,
  46: WWCodeProperty.FOG,
  47: WWCodeProperty.FOG,
  48: WWCodeProperty.STORM | WWCodeProperty.SNOW,
  49: WWCodeProperty.STORM | WWCodeProperty.SNOW,
  50: WWCodeProperty.RAIN,
  51: WWCodeProperty.RAIN,
  52: WWCodeProperty.RAIN,
  53: WWCodeProperty.RAIN,
  54: WWCodeProperty.RAIN,
  55: WWCodeProperty.RAIN,
  56: WWCodeProperty.STORM | WWCodeProperty.SNOW,
  57: WWCodeProperty.STORM | WWCodeProperty.SNOW,
  58: WWCodeProperty.RAIN,
  59: WWCodeProperty.RAIN,
  60: WWCodeProperty.RAIN,
  61: WWCodeProperty.RAIN,
  62: WWCodeProperty.RAIN,
  63: WWCodeProperty.RAIN,
  64: WWCodeProperty.RAIN,
  65: WWCodeProperty.RAIN,
  66: WWCodeProperty.STORM,
  67: WWCodeProperty.STORM,
  68: WWCodeProperty.RAIN | WWCodeProperty.SNOW,
  69: WWCodeProperty.RAIN | WWCodeProperty.SNOW,
  70: WWCodeProperty.RAIN | WWCodeProperty.SNOW,
  71: WWCodeProperty.RAIN | WWCodeProperty.SNOW,
  72: WWCodeProperty.RAIN | WWCodeProperty.SNOW,
  73: WWCodeProperty.RAIN | WWCodeProperty.SNOW,
  74: WWCodeProperty.RAIN | WWCodeProperty.SNOW,
  75: WWCodeProperty.RAIN | WWCodeProperty.SNOW,
  76: WWCodeProperty.STORM | WWCodeProperty.SNOW,
  77: WWCodeProperty.RAIN | WWCodeProperty.SNOW,
  78: WWCodeProperty.RAIN | WWCodeProperty.SNOW,
  79: WWCodeProperty.STORM | WWCodeProperty.SNOW,
  80: WWCodeProperty.RAIN,
  81: WWCodeProperty.RAIN,
  82: WWCodeProperty.RAIN,
  83: WWCodeProperty.RAIN | WWCodeProperty.SNOW,
  84: WWCodeProperty.RAIN | WWCodeProperty.SNOW,
  85: WWCodeProperty.RAIN | WWCodeProperty.SNOW,
  86: WWCodeProperty.RAIN | WWCodeProperty.SNOW,
  87: WWCodeProperty.STORM | WWCodeProperty.SNOW,
  88: WWCodeProperty.STORM | WWCodeProperty.SNOW,
  89: WWCodeProperty.STORM,
  90: WWCodeProperty.STORM,
  91: WWCodeProperty.STORM,
  92: WWCodeProperty.STORM,
  93: WWCodeProperty.STORM | WWCodeProperty.SNOW,
  94: WWCodeProperty.STORM | WWCodeProperty.SNOW,
  95: WWCodeProperty.STORM | WWCodeProperty.SNOW,
  96: WWCodeProperty.STORM,
  97: WWCodeProperty.STORM | WWCodeProperty.SNOW,
  98: WWCodeProperty.STORM,
  99: WWCodeProperty.STORM,
};

const colorMap: Record<WWCodeProperty, string> = {
  [WWCodeProperty.SNOW]: "#FF1493",
  [WWCodeProperty.STORM]: "#FF0000",
  [WWCodeProperty.RAIN]: "#0EFC0C",
  [WWCodeProperty.FOG]: "#fff200",
};

const wwCodeProperties = enumValues(WWCodeProperty);

export const getWeatherCodeColor = (value: number): string => {
  for (const prop of wwCodeProperties) {
    if (bitfield.has(wwCodeMapping[value], prop)) {
      return colorMap[prop];
    }
  }

  return "#A9A9A9";
};

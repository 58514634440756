import { ICON_LAYERS } from "@/layers/vector/AviationLayer/sublayers/tafLayerCompositor";
import type { MapboxGeoJSONFeature, Style } from "mapbox-gl";
import { BaseSubLayer } from "./BaseSubLayer";
import {
  INTERACTIVE_LAYER_NAME,
  TEXT_LAYERS,
  composeInternationalSigmetLayers,
} from "./internationalSigmetLayerCompositor";
import { createSigmetTooltip } from "./tooltips/isigmetTooltip";
import { convertRange } from "./utils";

export class InternationalSigmetLayer extends BaseSubLayer {
  createLayers(_style: Style): void {
    const map = this.scene.getMapboxMap();

    const internationalSigmetVectorLayers = composeInternationalSigmetLayers(
      `${this.uuid}_tiles`,
      this.initialProps.text_size,
    );
    for (const layer of internationalSigmetVectorLayers) {
      map.addLayer(layer, this.beforeLayerId);
      this.layers.push(layer.id);
    }
    this.setEventListeners(`${INTERACTIVE_LAYER_NAME}_${this.uuid}_tiles`);
  }

  createTooltip(feature: MapboxGeoJSONFeature): string {
    return createSigmetTooltip(feature.properties);
  }

  updateSize(size: number): void {
    for (const [name, { minSize, maxSize }] of Object.entries(ICON_LAYERS)) {
      const newValue = convertRange(size, [10, 34], [minSize, maxSize]);
      this.updateMapboxLayoutProperties(`${name}_${this.uuid}_tiles`, { "text-size": newValue });
    }
  }
}

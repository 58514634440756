import { useParams } from "react-router-dom";

export function useProfileAndTabParams() {
  const { profile: profileParam, tab: tabParam } = useParams<{ profile: string; tab: string }>();

  const profileId = +profileParam;
  const tabId = +tabParam;

  return {
    profileId,
    tabId,
  };
}

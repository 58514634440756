import { MultiWMSTile } from "@/cache/PVSTileService/TileGetters/MultiWMSTileGetter";
import { EmptyPNGTile, FailurePNGTile, type WMSRequestParams } from "@/cache/PVSTileService/TileGetters/WMSTileGetter";
import { apiThreadPool } from "@/cache/SpatioTemporalTileCache/ApiQueryThreadPool";
import type { RetryState } from "@/cache/SpatioTemporalTileCache/RetryState";
import type { SliceDataDescription } from "@/cache/SpatioTemporalTileCache/SliceDataDescription";
import { getTileSize } from "@/cache/SpatioTemporalTileCache/TileArea";
import type { TileDataDescription } from "@/cache/SpatioTemporalTileCache/TileDataDescription";
import { type CoordinateSystem, GridSamplingStrategyKind, type PixelGridRequest } from "@mm/api.meteomatics.com";
import type { TileGetter } from "../TileGetter";
import { hashPixelGridRequest } from "../tile-getter-utils/hashApiRequest";

export type CombinedMultiPNGTile = MultiWMSTile | EmptyPNGTile | FailurePNGTile;

/**
 * A tile getter to requests multiple images for a single geographical tile.
 *
 * Initial intended cases include
 * - Fetching both wind U and V component WMS images for wind animation, which uses both
 *   of the images to compute the particle movements.
 */
export function createMultiWmsTileGetter<C extends CoordinateSystem>() {
  const multiWMSTileGetter: TileGetter<C, CombinedMultiPNGTile, WMSRequestParams<C>, PixelGridRequest<C>[]> = {
    // TODO Fix this in next iteration. Problem is that returned area always are in EPSG3857
    // @ts-ignore
    createTileApiRequest: (tileDesc: TileDataDescription<WMSRequestParams<C>>, tilePixelDimension: number) => {
      const widthAndHeight = getTileSize(tileDesc.geometry.zoom, tilePixelDimension);
      const requestArea = tileDesc.geometry.toEpsg3857();
      const parameters = tileDesc.requestParams.parameters;
      const requests = parameters.map((param) => {
        // Deconstruct each parameter from the request,
        // then call the image API endpoint for each parameter.
        const apiRequest: PixelGridRequest<CoordinateSystem.EPSG3857> = {
          ...tileDesc.requestParams,
          sampling: { kind: GridSamplingStrategyKind.Point },
          area: requestArea,
          datetime: tileDesc.datetime,
          height: widthAndHeight,
          width: widthAndHeight,
          parameters: [param] as [string],
        };
        return apiRequest;
      });
      return requests;
    },

    createDataSliceApiRequest: (sliceDesc: SliceDataDescription<C, WMSRequestParams<C>>) => {
      const {
        area,
        width,
        height,
        requestParams: { parameters },
      } = sliceDesc;

      const requests = parameters.map((param) => {
        // Deconstruct each parameter from the request,
        // then call the image API endpoint for each parameter.
        const apiRequest: PixelGridRequest<C> = {
          ...sliceDesc.requestParams,
          area,
          width,
          height,
          sampling: { kind: GridSamplingStrategyKind.Point },
          datetime: sliceDesc.datetime,
          parameters: [param] as [string],
        };
        return apiRequest;
      });
      return requests;
    },

    requestTile: (tileApiRequests: PixelGridRequest<C>[], requestRetryState: RetryState): Promise<MultiWMSTile> => {
      // A single tile contains multiple images to fetch
      const images = tileApiRequests.map((apiRequest) => {
        return apiThreadPool.getWMSTile(apiRequest, requestRetryState);
      });
      // Once all images requests resolve, construct a single tile object.
      return Promise.all(images).then((pngTiles) => {
        return new MultiWMSTile(pngTiles);
      });
    },

    requestDataSlice: (
      tileApiRequests: PixelGridRequest<C>[],
      requestRetryState: RetryState,
    ): Promise<MultiWMSTile> => {
      // A single tile contains multiple images to fetch
      const images = tileApiRequests.map((apiRequest) => {
        return apiThreadPool.getWMSTile(apiRequest, requestRetryState);
      });
      // Once all images requests resolve, construct a single tile object.
      return Promise.all(images).then((pngTiles) => {
        return new MultiWMSTile(pngTiles);
      });
    },

    getTileCacheId: (tileApiRequests: PixelGridRequest<C>[]) => {
      return tileApiRequests
        .map((request) => {
          return hashPixelGridRequest(request);
        })
        .join("&");
    },

    createEmptyTile: (): EmptyPNGTile => {
      return new EmptyPNGTile();
    },

    createFailureTile: (): FailurePNGTile => {
      return new FailurePNGTile();
    },
  };

  return multiWMSTileGetter;
}

import { Api } from "@/api/Api";
import { type CartographicMapCreate, LayoutType, type Tab } from "@mm/metx-workbench.meteomatics.com";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const createMapWithViewport = createAsyncThunk(
  "api/map/create-viewport",
  async (
    payload: { tabId: Tab["id"]; data: CartographicMapCreate; layoutId: number; linkedViewportId?: number },
    _thunkAPI,
  ) => {
    return await Api.cartographicMap
      .v2CreateMapWithViewport({
        cartographicMapCreate: payload.data,
        tabId: payload.tabId,
      })
      .then((mapWithViewport) => {
        const map = mapWithViewport.map;
        if (payload.linkedViewportId) {
          map.id_viewport = payload.linkedViewportId;
        }
        return {
          tool: map,
          viewport: mapWithViewport.viewport,
          layoutType: LayoutType.Map,
          layoutId: payload.layoutId,
        };
      });
  },
);

import { CoordinateSystem } from "./CoordinateSystem";
import { Area } from "./Area";
import { DateTime_fromTransferable, DateTime_toTransferable } from "./DateTime";
import { GridRequest, GridRequest_Transferable } from "./GridRequest";
import { GridSamplingStrategyKind } from "./GridSamplingStrategy";
import { BaseRequest } from "./BaseRequest";
import { ParameterUnit } from "./Identifier";

// Sample Query
// https://api-a.meteomatics.com/get_isolines?datetime=2021-06-13T09:05:00Z
// &parameter=relative_humidity_850hPa:p
// &model=ecmwf-ens
// &bbox=-44.021800000000006,32.1421,52.031,62.625099999999996
// &ens_select=member:0
// &radius_median_filter=1&radius_gaussian_filter=10&isoline_range=50,100,10

/**
 * IsoLines are projected in WGS84.
 */

export interface IsoLinesRequest
  extends Omit<GridRequest<CoordinateSystem.WGS84>, "sampling" | "parameters">,
    BaseRequest {
  path: string;
  parameter: ParameterUnit;
  radiusMedianFilter?: number;
  radiusGaussianFilter?: number;
  isolineRange?: string;
  isolineValues?: string;
  calibrated?: boolean;
}

export interface IsoLinesRequest_Transferable
  extends Omit<GridRequest_Transferable<CoordinateSystem.WGS84>, "sampling" | "parameters"> {
  path: string;
  parameter: ParameterUnit;
  radiusMedianFilter?: number;
  radiusGaussianFilter?: number;
  isolineRange?: string;
  isolineValues?: string;
}

export function IsoLinesRequest_toTransferable(req: IsoLinesRequest): IsoLinesRequest_Transferable {
  return {
    ...req,
    area: req.area.toTransferable(),
    boundingBoxLimit: req.boundingBoxLimit ? req.boundingBoxLimit.toTransferable() : null,
    datetime: DateTime_toTransferable(req.datetime),
  };
}

export function IsoLinesRequest_fromTransferable<C extends CoordinateSystem>(
  req: IsoLinesRequest_Transferable,
): IsoLinesRequest {
  return {
    ...req,
    area: Area.fromTransferable(req.area),
    boundingBoxLimit: req.boundingBoxLimit ? Area.fromTransferable(req.boundingBoxLimit) : null,
    datetime: DateTime_fromTransferable(req.datetime),
  };
}

export function IsoLinesRequest_toGridRequest<C extends CoordinateSystem>(
  isoLinesRequest: IsoLinesRequest,
): GridRequest<CoordinateSystem.WGS84> {
  return {
    ...isoLinesRequest,
    parameters: [isoLinesRequest.parameter],
    sampling: { kind: GridSamplingStrategyKind.Point },
  };
}

import { Area } from "./Area";
import { ColorMap } from "./ColorMap";
import { CoordinateSystem } from "./CoordinateSystem";
import { DateTime_fromTransferable, DateTime_toTransferable } from "./DateTime";
import { GridRequest, GridRequest_Transferable } from "./GridRequest";

export interface PixelGridRequest<C extends CoordinateSystem> extends GridRequest<C> {
  colorMap?: ColorMap;
  calibrated?: boolean;
  vertical_interpolation?: "none" | "downscale";
}

export interface PixelGridRequest_Transferable<C extends CoordinateSystem> extends GridRequest_Transferable<C> {
  colorMap?: ColorMap;
  calibrated?: boolean;
  vertical_interpolation?: "none" | "downscale";
}

export function PixelGridRequest_toTransferable<C extends CoordinateSystem>(
  req: PixelGridRequest<C>,
): PixelGridRequest_Transferable<C> {
  return {
    ...req,
    area: req.area.toTransferable(),
    datetime: DateTime_toTransferable(req.datetime),
    boundingBoxLimit: req.boundingBoxLimit ? req.boundingBoxLimit.toTransferable() : null,
  };
}

export function PixelGridRequest_fromTransferable<C extends CoordinateSystem>(
  req: PixelGridRequest_Transferable<C>,
): PixelGridRequest<C> {
  return {
    ...req,
    area: Area.fromTransferable(req.area),
    datetime: DateTime_fromTransferable(req.datetime),
    boundingBoxLimit: req.boundingBoxLimit ? Area.fromTransferable(req.boundingBoxLimit) : null,
  };
}

export function toGridRequest<C extends CoordinateSystem>(grid: PixelGridRequest<C>): GridRequest<C> {
  // the backend automatically performs the `Point` -> `Area` transformation.
  return { ...grid };
}

import { type PropsWithChildren, createContext } from "react";
import { type InterpreterFrom, interpret } from "xstate";
import { type TempLayerStateMachine, defaultTempLayerStateMachine } from "./tempLayerStateMachine";

export const TempLayerStateMachineContext = createContext<StateMachineContext>({
  tempLayerService: {} as InterpreterFrom<TempLayerStateMachine>,
});

type StateMachineContext = {
  tempLayerService: InterpreterFrom<TempLayerStateMachine>;
};

export const TempLayerStateMachineProvider = (props: PropsWithChildren<any>) => {
  const tempLayerService = interpret(defaultTempLayerStateMachine, { devTools: true });

  return (
    <TempLayerStateMachineContext.Provider value={{ tempLayerService }}>
      {props.children}
    </TempLayerStateMachineContext.Provider>
  );
};

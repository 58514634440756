import { createAsyncThunk } from "@reduxjs/toolkit";

import type { EnergyForecastApi } from "@mm/forecast-data.meteomatics.com";

export const getEnergyForecastAvailabilityV2 = createAsyncThunk(
  "api/energy-forecast/availability-v2",
  async (payload: { energyForecastApi: EnergyForecastApi }, _thunkAPI) => {
    return await payload.energyForecastApi.getEnergyForecastAvailabilityV2();
  },
);

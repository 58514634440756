import { type AsyncResult, SynchrounousState, createResolvedAsyncResult } from "@/cache/AsyncResult";
import { PoiParameterRequestKind } from "@/cache/GeoJSONCache";
import { networkCaches } from "@/cache/GlobalCache";
import { emptyFeatureCollection, emptyGeoJsonSource } from "@/geojson";
import { type CheckedProps, LayerBase, type PropsChecker } from "@/layers";
import { ChangeDetector } from "@/layers/ChangeDetector";
import type { ScenePublicApi } from "@/layers/Compositor";
import { generatePOILayerCacheId } from "@/layers/geojson/LayerUtils";
import { handleRenderTooltip } from "@/layers/geojson/poi/tooltips";
import { HoverTooltip } from "@/layers/geojson/tools";
import {
  type MapboxLayerGroup,
  MapboxSourceGroup,
  addLayerGroup,
  addSource,
  moveZIndex,
  removeLayerGroup,
  removeSource,
  updateMapboxLayoutProperties,
  updateMapboxPaintProperties,
  updateSourceData,
} from "@/layers/utility/mapbox-utils";
import type { IconData } from "@/reducer/client-models";
import type { PoiLayer } from "@/reducer/client-models/PoiLayer";
import { SENTRY_TRANSACTION_NAMES } from "@/sentry/TransactionConst";
import { captureTransaction } from "@/sentry/helpers";
import { dataProcessingThreadPool } from "@/threads/DataProcessingThread/DataProcessingThreadPool";
import { safeFmt } from "@/utility/safeFmt";
import { getModelBoundingBox } from "@/weather-parameters/lookup";
import {
  CoordinateSystem,
  type GeoJSONFeatureCollection,
  type PointRequest,
  SinglePointCoordinate,
} from "@mm/api.meteomatics.com";
import type { GuiTimeZone } from "@mm/metx-workbench.meteomatics.com";
import { isEmpty } from "lodash";
import { type DateTime, Duration } from "luxon";
import {
  iconOpacityPaint,
  poiMapboxLayerGroup,
  thresholdsToPaintProperty,
  valueLayerLayoutProperties,
  valueLayerOffset,
} from "./PoiLayerUtilities";
import { loadTextBackgroundImage } from "./PoiLayerUtilities/poi-mapbox-utils/poi-mapbox-image/poiMapboxImage";

export class PoiLayerImpl extends LayerBase<PoiLayer> {
  // Flag indicating layer redraw is necessary. Initially layer is dirty, as we wan't to do initial render
  private dirty = true;
  private tooltip: HoverTooltip;

  private thresholdFlag: ChangeDetector<boolean> = new ChangeDetector<boolean>(false);
  private itemData: ChangeDetector<IconData> = new ChangeDetector<IconData>();

  private mapboxLayerGroup: MapboxLayerGroup<"icon-layer" | "value-layer">;
  private mapboxSrcGroup: MapboxSourceGroup<"poi-data-source">;

  private displayDateTime: ChangeDetector<string>;

  constructor(id: number, props: PoiLayer, scene: ScenePublicApi, timezone: GuiTimeZone) {
    super(id, props, scene, timezone);

    loadTextBackgroundImage(this.scene.getMapboxMap());

    const mapboxLayerGroupId = this.humanReadableId();

    // We first initialize with an empty source, then later set the data after API request.
    this.mapboxSrcGroup = new MapboxSourceGroup(mapboxLayerGroupId, ["poi-data-source"]);
    addSource(this.scene.getMapboxMap(), [
      { sourceId: this.mapboxSrcGroup.id("poi-data-source"), source: emptyGeoJsonSource() },
    ]);

    this.mapboxLayerGroup = poiMapboxLayerGroup(mapboxLayerGroupId, props, this.mapboxSrcGroup.id("poi-data-source"));
    addLayerGroup(this.scene.getMapboxMap(), this.mapboxLayerGroup.initialSpecs());

    this.tooltip = new HoverTooltip(scene.getMapboxMap(), this.mapboxLayerGroup.allIds(), handleRenderTooltip);

    updateMapboxLayoutProperties(this.scene.getMapboxMap(), this.mapboxLayerGroup.id("value-layer"), {
      visibility: this.props.poiOptions.hideLabels ? "none" : "visible",
    });

    // Setup simple change detector, for toggling dirt flag when datetime changes
    this.displayDateTime = new ChangeDetector(this.scene.getDisplayTimeWithOffset().toISO());
  }

  humanReadableId(): string {
    return safeFmt`metx.PoiLayer#${this.uid}`;
  }

  moveZIndex(beforeLayerId?: string | undefined): void {
    moveZIndex(this.scene.getMapboxMap(), this.mapboxLayerGroup.allIds(), beforeLayerId);
  }

  checker(): PropsChecker<PoiLayer, LayerBase<PoiLayer>> {
    type CheckerProps = CheckedProps<PoiLayer>;
    const checkLayerProp = (property: CheckerProps) => (prev: any, curr: any) => {
      const changed = prev[property] !== curr[property];
      if (changed) {
        this.setLayerProps({ [property]: curr[property] });
        switch (property) {
          case "model":
            this.dirty = true;
            this.beforeRender();
            break;
          case "calibrated":
            break;
          case "parameter_unit":
            updateMapboxLayoutProperties(
              this.scene.getMapboxMap(),
              this.mapboxLayerGroup.id("value-layer"), // Only update the sub layer for the value label
              valueLayerLayoutProperties(this.props),
            );
            this.dirty = true;
            this.beforeRender();
            break;
          case "ens_select":
            this.dirty = true;
            this.beforeRender();
            break;
          case "poiOptions":
            updateMapboxLayoutProperties(
              this.scene.getMapboxMap(),
              this.mapboxLayerGroup.id("value-layer"),
              // Handle offset of the value pills
              valueLayerOffset(this.props),
            );
            updateMapboxPaintProperties(
              this.scene.getMapboxMap(),
              this.mapboxLayerGroup.allIds(),
              thresholdsToPaintProperty(this.props.poiOptions.coloringOptions, this.props.poiOptions.item.color),
            );
            updateMapboxLayoutProperties(this.scene.getMapboxMap(), this.mapboxLayerGroup.id("value-layer"), {
              visibility: this.props.poiOptions.hideLabels ? "none" : "visible",
            });
            updateMapboxPaintProperties(
              this.scene.getMapboxMap(),
              this.mapboxLayerGroup.id("value-layer"), // Only update the sub layer for the value label
              iconOpacityPaint(this.props),
            );
            this.dirty = true;
            this.beforeRender();
            break;
          case "show":
            updateMapboxLayoutProperties(this.scene.getMapboxMap(), this.mapboxLayerGroup.allIds(), {
              visibility: curr.show ? "visible" : "none",
            });
            break;
          case "opacity":
            updateMapboxPaintProperties(this.scene.getMapboxMap(), this.mapboxLayerGroup.allIds(), {
              "icon-opacity": curr.opacity,
              "text-opacity": curr.opacity,
            });
            break;
          case "custom_options":
            break;
          default: {
            const _exhaustive: never = property;
            return _exhaustive;
          }
        }
      }
      return changed;
    };
    return {
      model: checkLayerProp("model"),
      calibrated: checkLayerProp("calibrated"),
      parameter_unit: checkLayerProp("parameter_unit"),
      poiOptions: checkLayerProp("poiOptions"),
      opacity: checkLayerProp("opacity"),
      show: checkLayerProp("show"),
      ens_select: checkLayerProp("ens_select"),
      custom_options: checkLayerProp("custom_options"),
    };
  }

  get mapboxIndex(): string {
    return this.mapboxLayerGroup.mainId();
  }

  removeLayer(): void {
    this.tooltip.dispose();
    removeLayerGroup(this.scene.getMapboxMap(), this.mapboxLayerGroup.allIds());
    removeSource(this.scene.getMapboxMap(), this.mapboxSrcGroup.allIds());
  }

  beforeRender(): void {
    // If display datetime has changed, redraw is necessary
    if (this.displayDateTime.changed(this.scene.getDisplayTimeWithOffset().toISO())) {
      this.dirty = true;
    }

    // If layer is dirty, trigger redraw and reset flag to indicate, that it's clean
    if (this.dirty) {
      this.updateTrigger();
      this.dirty = false;
    }
  }

  isRebuffering(time: DateTime): boolean {
    const dateTimeWithOffset = this.scene.getDateTimeWithOffset(time);
    const request = this.createRequest(dateTimeWithOffset);
    const { synchronous } = this.peekLayerCache(request);
    return synchronous === SynchrounousState.StillPending;
  }

  updateTrigger(): void {
    // We need to keep following change of threshold, otherwise, when threshold get's removed and
    // POI list of point's doesn't change, condition (line 141) newer is truly and point's doesn't
    // reappear without threshold
    // This is part of more fundamental issues in layer architecture
    const thresholdFlagChanged = this.thresholdFlag.changed(!isEmpty(this.props.poiOptions.coloringOptions));

    if (this.thresholdFlag.value) {
      // This get's called infinite amount times with debounce interval
      // TODO Fix this to gain more layer performance

      this.requestAllPointsData(this.itemData.changed(this.props.poiOptions.item));
    } else if (this.itemData.changed(this.props.poiOptions.item) || thresholdFlagChanged) {
      this.updateRawItemData(this.props.poiOptions.item);
    }
  }

  private updateRawItemData(item: IconData) {
    dataProcessingThreadPool
      .createPOIGeoJson(item)
      .then((geoJsonFeature) => {
        updateSourceData(this.scene.getMapboxMap(), this.mapboxSrcGroup.id("poi-data-source"), geoJsonFeature);
      })
      .catch((e) => {
        console.error(e);
      });
  }

  createRequest(dateTimeWithOffset: DateTime) {
    const coordsList = this.props.poiOptions.item.coords;
    const request: PointRequest<CoordinateSystem.WGS84> = {
      coordinates: coordsList.map(
        (coord) => new SinglePointCoordinate<CoordinateSystem.WGS84>(coord.lat, coord.lon, CoordinateSystem.WGS84),
      ),
      startDatetime: dateTimeWithOffset,
      duration: Duration.fromISO("PT1H"),
      temporalResolution: Duration.fromISO("PT1H"),
      height: 1,
      parameters: [this.props.parameter_unit],
      model: this.props.model,
      ensSelect: this.props.ens_select ? this.props.ens_select : "",
      boundingBoxLimit: getModelBoundingBox(this.props.model),
      width: 1,
    };
    return request;
  }

  requestAllPointsData(refresh: boolean) {
    // async api request call
    const dateTimeWithOffset = this.scene.getDisplayTimeWithOffset();
    const request = this.createRequest(dateTimeWithOffset);
    const result = networkCaches.poi_cache.retrievePoiByParameter(
      request,
      this.props.poiOptions.item,
      PoiParameterRequestKind.SingleParameterColoring,
      refresh,
    );
    // TODO: This line starts triggering the infinite loop, potential problem may be in Scene/Compositor.
    // The step (3) below is done in Scene/Compositor. And this is a problem for all layer implementation classes.
    // How it goes:
    // 1. After the promise has resolved, the mapbox triggers beforeRender callback
    // 2. Our layer implementation class has also `beforeRender` method.
    // 3. This `beforeRender()` is bind() to mapbox's lifecycle, which gets triggered on source update etc.
    // 4. Since `layerImpl.beforeRender()` triggers the source update, we have a inifinite loop logic :)
    // scheduleSourceUpdate(this.scene.getMapboxMap(), this.mapboxSrcGroup[0], result.asynchronous);
    result.asynchronous
      .then((geoJSON) => {
        updateSourceData(this.scene.getMapboxMap(), this.mapboxSrcGroup.id("poi-data-source"), geoJSON);
      })
      .catch((e) => {
        console.error(e);
      });
  }

  fetchData(timeFrame: DateTime): Promise<void> {
    const dateTimeWithOffset = this.scene.getDateTimeWithOffset(timeFrame);
    const request = this.createRequest(dateTimeWithOffset);
    const tags = {
      layerKind: `${this.props.kind ? this.props.kind : "undefined"}`,
      parameter: `${this.props.parameter_unit}`,
      requestedDate: `${dateTimeWithOffset}`,
    };

    const { asynchronous } = networkCaches.poi_cache.retrievePoiByParameter(
      request,
      this.props.poiOptions.item,
      PoiParameterRequestKind.SingleParameterColoring,
    );

    if (!this.scene.isAnimation()) {
      return captureTransaction(asynchronous, SENTRY_TRANSACTION_NAMES.POI_LAYER_LOADING_SPEED, tags);
    }

    return asynchronous
      .then(() => {
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.resolve();
      });
  }

  getActiveWeatherParametersAsString() {
    return [
      {
        model: this.props.model,
        parameter: this.props.parameter_unit,
      },
    ];
  }

  peekLayerCache(request: PointRequest<CoordinateSystem.WGS84>): AsyncResult<GeoJSONFeatureCollection> {
    // if there is no parameter or no items we don't need to request anything.
    if (request.parameters[0] === "" || this.props.poiOptions.item.coords.length === 0) {
      return createResolvedAsyncResult(emptyFeatureCollection);
    }
    const cacheId = generatePOILayerCacheId(
      request,
      PoiParameterRequestKind.SingleParameterColoring,
      this.props.poiOptions.item,
    );
    return networkCaches.geojson_cache.peekCache(cacheId);
  }
}

import { useAppDispatch } from "@/reducer";
import { addEnergyPlot, useEnergyPlot } from "@/reducer/EnergyPlotsState";
import type { EnergyPlot } from "@mm/metx-workbench.meteomatics.com";
import { Suspense, lazy, memo, useEffect } from "react";

export type GridEnergyPlotProps = {
  energyPlot: EnergyPlot;
};

const EnergyPlotView = memo(
  lazy(() =>
    import(/* webpackChunkName: "energyPlotview" */ "@/energyPlotView/index")
      .then(({ EnergyPlotView }) => ({ default: EnergyPlotView }))
      .catch(() => {
        console.error("error");
        return { default: () => <div>Loading Energy Plot View failed</div> };
      }),
  ),
);

export function GridEnergyPlot({ energyPlot }: GridEnergyPlotProps) {
  /**
   * Temporary solution to sync up react-query with redux
   */
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(addEnergyPlot(energyPlot));
  }, [dispatch, energyPlot]);
  //**************************************************** */

  /**
   * Workaround for solving race condition between redux, react query and react
   * TODO Remove once we will have migrated redux to react-query
   */
  const energyPlotFromRedux = useEnergyPlot(energyPlot.id);

  if (!energyPlotFromRedux) return null;
  //**************************************************** */

  return (
    <Suspense fallback={<mm-loader size="large" />}>
      <EnergyPlotView energyPlot={energyPlotFromRedux} />
    </Suspense>
  );
}

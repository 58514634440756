/**
 * A small library for matching of strings with placeholders. The primary purpose of this is to make authoring of
 * matching regular expressions less error-prone.
 *
 * Example usage:
 *
 * ```ts
 * errorMessage`Model ${VARIABLE_MODEL_ID} not available at queried location. Available domain ${VARIABLE_AREA}. Queried domain: ${VARIABLE_AREA}.`
 *    .test("Model dlr-corine not available at queried location. Available domain 71.5000000000000000,-24.6999999999999993_35.7999999999999972,45.0000000000000000. Queried domain: 46.0000000000000000,-47.2999999999999972_45.5000000000000000,7.7000000000000002.");
 * ```
 */

import { DateTime } from "luxon";
import { Area } from "../models";

export function escapeRegex(s: string) {
  return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
}

export function named(name: string | number | symbol, matcher: string) {
  // TODO: escape name
  return `(?<${String(name)}>${matcher})`;
}

export type Matcher<T extends { kind: string }> = (kind: T["kind"], msg: string) => T | null;

/**
 *
 * You MUST give all fields of `T` as a named variable. The typechecker cannot verify this
 * invariant. Not adhering to this constraint will result in undefined behaviour.
 *
 * @param literals
 * @param vars
 * @returns
 */
export function errorMessage<T extends { kind: string }>(
  literals: TemplateStringsArray,
  ...vars: ([keyof Exclude<T, "kind">, string] | string | [keyof Exclude<T, "kind">, [string, (val: string) => any]])[]
): Matcher<T> {
  const regexParts: string[] = [];
  const validators: Map<string, (val: string) => any> = new Map();

  literals.raw.forEach((lit, litIdx) => {
    regexParts.push(escapeRegex(lit));
    if (litIdx < vars.length) {
      const variable = vars[litIdx];
      if (Array.isArray(variable)) {
        const name = variable[0];
        const [regex, validator] = Array.isArray(variable[1]) ? variable[1] : [variable[1], null];
        regexParts.push(named(name, regex));
        if (validator) {
          validators.set(String(name), validator);
        }
      } else {
        regexParts.push(variable);
      }
    }
  });

  const regex = new RegExp(`${regexParts.join("")}`);

  return (kind: T["kind"], msg: string) => {
    const groups = msg.match(regex)?.groups;
    if (groups != null) {
      const res: any = { kind, isEncodedApiError: false, ...groups };
      for (const [name, validator] of validators.entries()) {
        const parsed = validator(groups[name]);
        if (parsed === null) {
          return null;
        }
        res[name] = parsed;
      }
      return res as T;
    }
    return null;
  };
}

/**
 * Matches an area string. For example: `71.5000000000000000,-24.6999999999999993_35.7999999999999972,45.0000000000000000`
 */
export const T_COORDINATE = "(-?[0-9]+\\.[0-9]{16})";
export const T_AREA: [string, (_: string) => any] = [
  `${T_COORDINATE},${T_COORDINATE}_${T_COORDINATE},${T_COORDINATE}`,
  Area.fromString,
];

/**
 * Matches a model id. For example: "mix" or "dlr-corine"
 */
export const T_MODEL_ID = "[0-9a-z-]+";

export const T_WEATHER_PARAMETER = "[0-9a-zA-Z_:-\\s]+";

export const T_DATETIME: [string, (_: string) => any] = [
  "[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}Z",
  DateTime.fromISO,
];

export const T_SECONDS: [string, (_: string) => any] = ["[+-]?\\d+", (number: string) => DateTime.fromSeconds(+number)];

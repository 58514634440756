import type { LayerUnionWithAll } from "@/layers";
import { setLayersProps } from "@/reducer/MapsState";
import { t } from "@lingui/macro";
import type {
  AviationLayer,
  BackgroundMapLayer,
  CustomGeoJSONLayer,
  MapId,
  TropicalCycloneLayer,
} from "@mm/metx-workbench.meteomatics.com";
import { useDispatch } from "react-redux";
import { FormField } from "../../form-field";

export function InitTimeField(props: {
  layer: Exclude<LayerUnionWithAll, BackgroundMapLayer | AviationLayer | TropicalCycloneLayer | CustomGeoJSONLayer>;
  mapId: MapId;
}) {
  const dispatch = useDispatch();

  function changeShowInitTimeProps(newValue: boolean) {
    const layerProps = { layerId: props.layer.id, id: props.mapId.map, props: { show_init_time: newValue } };
    dispatch(setLayersProps(layerProps));
  }

  return (
    <FormField label={t`Show init date`}>
      <input
        type="checkbox"
        checked={props.layer.show_init_time}
        onChange={() => changeShowInitTimeProps(!props.layer.show_init_time)}
      />
    </FormField>
  );
}

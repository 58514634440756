import type React from "react";

export interface SvgComponentProps {
  className?: string;
}
const MapSvg: React.FunctionComponent<SvgComponentProps> = ({ className }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 92.9 74.5"
    xmlSpace="preserve"
    className={className}
  >
    <g>
      <path
        d="M66.5,53.3C66.5,53.3,66.5,53.3,66.5,53.3c-0.5,0-1-0.3-1.3-0.7L51,28.6c0-0.1-0.1-0.1-0.1-0.2c-1.8-2.9-2.7-6.3-2.7-9.7
		C48.2,8.4,56.6,0,66.9,0c0,0,0,0,0,0c5,0,9.7,2,13.2,5.5c3.5,3.5,5.5,8.2,5.5,13.2l0,0c0,3.4-0.9,6.7-2.7,9.6
		c0,0.1-0.1,0.2-0.1,0.3L67.8,52.6C67.5,53,67,53.3,66.5,53.3z M53.7,27.3l12.9,21.6L80,27.3c0-0.1,0.1-0.2,0.1-0.3
		c1.6-2.5,2.4-5.4,2.4-8.3c0,0,0,0,0,0c0-4.2-1.6-8.1-4.6-11.1C75,4.6,71.1,3,66.9,3c0,0,0,0,0,0c-8.7,0-15.7,7-15.7,15.7
		c0,3,0.8,5.8,2.4,8.3C53.6,27.1,53.6,27.2,53.7,27.3z"
      />
      <path
        d="M66.9,25.7C66.9,25.7,66.9,25.7,66.9,25.7c-2.1,0-4.1-0.8-5.6-2.3c-1.5-1.5-2.3-3.5-2.3-5.6c0-2.1,0.8-4.1,2.3-5.6
		c1.5-1.5,3.5-2.3,5.6-2.3c0,0,0,0,0,0c4.4,0,7.9,3.6,7.9,7.9l0,0c0,2.1-0.8,4.1-2.3,5.6C71,24.9,69,25.7,66.9,25.7z M66.9,12.9
		c-1.3,0-2.5,0.5-3.5,1.4c-0.9,0.9-1.4,2.2-1.4,3.5c0,1.3,0.5,2.6,1.4,3.5c0.9,0.9,2.2,1.4,3.5,1.4c0,0,0,0,0,0
		c1.3,0,2.5-0.5,3.5-1.4c0.9-0.9,1.4-2.2,1.4-3.5C71.8,15.1,69.6,12.9,66.9,12.9C66.9,12.9,66.9,12.9,66.9,12.9z M73.3,17.8
		L73.3,17.8L73.3,17.8z"
      />
      <path
        d="M91.4,74.5H1.5c-0.5,0-1-0.3-1.3-0.7c-0.3-0.4-0.3-1-0.1-1.4l16.3-38.2c0.2-0.6,0.8-0.9,1.4-0.9h38.6
		c0.8,0,1.5,0.7,1.5,1.5s-0.7,1.5-1.5,1.5H18.8L3.8,71.5h85.3L74.5,37.4c-0.3-0.8,0-1.6,0.8-2c0.8-0.3,1.6,0,2,0.8l15.5,36.2
		c0.2,0.5,0.2,1-0.1,1.4C92.3,74.3,91.9,74.5,91.4,74.5z"
      />
      <path
        d="M66.7,74.3c-0.8,0-1.4-0.6-1.5-1.3c0-0.1-1-7.5-11.3-9.2c-1-0.2-2.5-0.3-4.3-0.5c-9.8-0.9-18.7-2.2-20.4-6.7
		c-1.1-3,0.5-5.2,1.8-7c1.4-2,1.9-2.8,1.2-4.1c-0.5-0.8-1.9-1.4-3.3-2c-2.8-1.2-6.6-2.9-6.6-7.4c0-0.8,0.7-1.5,1.5-1.5c0,0,0,0,0,0
		c0.8,0,1.5,0.7,1.5,1.5c0,2.4,1.8,3.4,4.8,4.7c1.9,0.8,3.8,1.6,4.7,3.3c1.8,3,0,5.5-1.3,7.4c-1.2,1.7-2,2.8-1.4,4.2
		c1.2,3.2,12.5,4.3,17.9,4.8c1.8,0.2,3.4,0.3,4.5,0.5c12.5,2.1,13.7,11.4,13.8,11.8c0.1,0.8-0.5,1.6-1.3,1.7
		C66.8,74.3,66.7,74.3,66.7,74.3z"
      />
    </g>
  </svg>
);

export default MapSvg;

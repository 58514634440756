import {
  Language,
  MaptilerBorderId,
  MaptilerEuropeFIRId,
  onlyBorderStyleLayer,
} from "@/constants/maptilerStyleOptions";
import { FormCheckbox } from "@/overlay/components/SmartForm/HookInputs/Checkbox";
import { ColorPicker } from "@/overlay/components/SmartForm/HookInputs/ColorPicker";
import type { ParamOptionInputData } from "@/overlay/components/SmartForm/HookInputs/ParamOptionSelectList";
import { SelectInput } from "@/overlay/components/SmartForm/HookInputs/SelectInput";
import { SliderInput } from "@/overlay/components/SmartForm/HookInputs/SliderInput";
import { ModeSwitchableForm } from "@/overlay/components/SmartForm/SmartFormUtils/FormComponents/ModeSwitchableFormProps";
import { useFormWithUtils } from "@/overlay/components/SmartForm/SmartFormUtils/FormHooks/useFormUtils";
import type { BaseFormProps } from "@/overlay/components/SmartForm/SmartFormUtils/commonTypeUtils";
import { t } from "@lingui/macro";
import type { BackgroundMapLayer, BackgroundMapLayerCreate } from "@mm/metx-workbench.meteomatics.com";
import { useLanguageOptions, useMaptilerStyleOptions } from "./hooks";

type BackgroundMapLayerPatch = Partial<BackgroundMapLayerCreate | BackgroundMapLayer>;

/**
 * Form data passed from the child components.
 */
export type CartographicMaterialUpdateFormData = {
  // Snake case as they get passed as layer props directly.
  layerData: {
    style: string;
    custom_options: {
      map_label_language: string;
      show_state_border?: boolean;
      line_color?: string;
    };
    opacity: number;
  };
  // Form internal data to construct the above data.
  internal: {
    paramOptionPatch: ParamOptionInputData;
  };
};

type CartographicMaterialLayerUpdateFormProps = {
  defaultFormData: BackgroundMapLayerPatch;
} & BaseFormProps<CartographicMaterialUpdateFormData>;

/**
 * Responsible for rendering the whole layer form and send back the data on submit hadler.
 */
export function CartographicMaterialUpdateForm({
  submitMode,
  onSubmit,
  onCancel,
  defaultFormData,
}: CartographicMaterialLayerUpdateFormProps) {
  const { formMethods, formValName } = useFormWithUtils<CartographicMaterialUpdateFormData>({
    mode: submitMode,
  });

  const onSubmitWithFormatting: typeof onSubmit = (formData) => {
    onSubmit(formData);
  };

  const { layerData } = formMethods.watch();

  const languageOptions = useLanguageOptions();
  const styleOptions = useMaptilerStyleOptions();

  return (
    <ModeSwitchableForm
      onSubmit={formMethods.handleSubmit(onSubmitWithFormatting)}
      onCancel={onCancel}
      submitMode={submitMode}
    >
      <SelectInput
        label={t`Basemap`}
        values={styleOptions}
        instruction={{
          currValue: defaultFormData.style,
          formValueName: formValName("layerData.style"),
          formMethods,
        }}
      />

      {/* For some reason values get's populated only when select is open first time */}
      {!onlyBorderStyleLayer.includes(layerData?.style) && (
        <SelectInput
          label={t`Map Label Language`}
          values={languageOptions}
          instruction={{
            currValue: Language.EN,
            formValueName: formValName("layerData.custom_options.map_label_language"),
            formMethods,
          }}
        />
      )}
      <SliderInput
        label={t`Opacity`}
        range={{ min: 0, max: 1, step: 0.1 }}
        instruction={{
          currValue: defaultFormData.opacity ?? 1,
          formValueName: formValName("layerData.opacity"),
          formMethods,
        }}
      />
      {layerData?.style === MaptilerBorderId && (
        <FormCheckbox
          label={t`show state border`}
          instruction={{
            currValue: !!defaultFormData.custom_options?.show_state_border,
            formValueName: formValName("layerData.custom_options.show_state_border"),
            formMethods,
          }}
        />
      )}
      {(layerData?.style === MaptilerBorderId || layerData?.style === MaptilerEuropeFIRId) && (
        <ColorPicker
          label={t`Line Color`}
          instruction={{
            currValue: defaultFormData.custom_options?.line_color || "black",
            formValueName: formValName("layerData.custom_options.line_color"),
            formMethods,
          }}
        />
      )}
    </ModeSwitchableForm>
  );
}

import {
  type AsyncResult,
  createNotRequestedAsyncResult,
  createPendingAsyncResult,
  createResolvedAsyncResult,
} from "@/cache/AsyncResult";
import {
  type CacheDatum,
  CacheDatumState,
  type LoadedCacheDatum,
  type PermanentlyFailedCacheDatum,
} from "@/cache/GlobalCache";
import { apiThreadPool } from "@/cache/SpatioTemporalTileCache/ApiQueryThreadPool";
import { FailureState, type RetryState, createRetryState } from "@/cache/SpatioTemporalTileCache/RetryState";
import { type Stats, jsxTable } from "@/cache/Stats";
import {
  createBarbsGeoJson,
  createRawGeoJson,
  createWeatherCodeGeoJson,
  createWeatherSymbolGeoJson,
  emptyFeatureCollection,
} from "@/geojson";
import {
  generateGridLayerCacheId,
  generateIsoLinesLayerCacheId,
  generateLightningListLayerCacheId,
  generateWeatherFrontsLayerCacheId,
  generateWfsCacheId,
} from "@/layers/geojson/LayerUtils";
import { getProxyUrl } from "@/reducer/api/helper";
import {
  Area,
  CoordinateSystem,
  type GeoJSONFeatureCollection,
  type GridRequest,
  type IsoLinesRequest,
  type JSONResponseBody,
  type LightningListRequest,
  WORLD_BBOX,
  type WfsRequest,
} from "@mm/api.meteomatics.com";
import type { MeteomaticsApiError } from "@mm/api.meteomatics.com/lib/error";
import { type Abort, isAbortable } from "@mm/api.meteomatics.com/lib/middleware";
import type { WeatherFrontsRequest } from "@mm/api.meteomatics.com/lib/models/WeatherFrontsRequest";
import { LightningListTimeSplitter } from "@mm/api.meteomatics.com/lib/request-splitters/LightningListSplitter";
import { cancelable } from "../utils";

export interface GeoJSONCacheStats {
  count: number;
  pending: number;
}

export enum GridRequestKind {
  Raw = "raw",
  Symbol = "symbol",
  WeatherCode = "weather code",
  Barbs = "barbs",
}

export enum IsoLinesRequestKind {
  IsoLines = "isolines",
  HighsAndLows = "highsandlows",
}

export enum PoiParameterRequestKind {
  SingleParameterColoring = "single parameter coloring",
}

export enum WfsRequestKind {
  Station = "station",
}

export class GeoJSONCache implements Stats<GeoJSONCacheStats> {
  protected cache: { [key: string]: CacheDatum<GeoJSONFeatureCollection, RetryState> } = {};

  constructor(private readonly label_: string) {}

  stats(): GeoJSONCacheStats {
    let count = 0;
    let pending = 0;
    const cache = this.cache;

    for (const line in cache) {
      if (Object.hasOwn(cache, line)) {
        count++;

        if (cache[line].state === CacheDatumState.Pending) {
          pending++;
        }
      }
    }

    return { count, pending };
  }

  descJsx(): JSX.Element {
    return <>Special-purpose cache for API JSON Requests. Replacement Policy is LRU.</>;
  }

  label(): string {
    return this.label_;
  }

  statsJsx(): JSX.Element {
    return jsxTable(this.stats(), (key, val) => val);
  }

  /**
   * A method to return a cached data from cache.
   * It does not trigger a new request when the given request hasn't been emitted.
   * @returns
   * @param cacheId
   */
  peekCache(cacheId: string): AsyncResult<GeoJSONFeatureCollection> {
    const v = this.cache[cacheId];
    if (!v) {
      return createNotRequestedAsyncResult(emptyFeatureCollection);
    }
    switch (v.state) {
      case CacheDatumState.Pending: {
        const retry_ = v.payload;
        if (retry_.failureState !== FailureState.FailedTemporarily) {
          return createPendingAsyncResult(v.promise);
        }
        // since the current invocation moves the state from [Pending/FailedTemporarily] to [Pending/Queued]
        // mark it as such to prevent concurrent invocations from reentering the remaining function body
        retry_.failureState = undefined;
        return createPendingAsyncResult(v.promise);
      }
      case CacheDatumState.PermanentlyFailed:
        return createResolvedAsyncResult(emptyFeatureCollection);
      case CacheDatumState.Loaded:
        return createResolvedAsyncResult(v.payload);
      default: {
        const _exhaustive: never = v;
        return _exhaustive;
      }
    }
  }

  retrieveIsoLine(
    request: IsoLinesRequest,
    kind: IsoLinesRequestKind = IsoLinesRequestKind.IsoLines,
  ): AsyncResult<GeoJSONFeatureCollection> {
    const cacheId = generateIsoLinesLayerCacheId(request, kind);
    const retry_: RetryState | null = null;

    if (Object.hasOwn(this.cache, cacheId)) {
      return this.peekCache(cacheId);
    }
    const modelBoundingBox = request.boundingBoxLimit || WORLD_BBOX;
    const retry = retry_ ?? createRetryState(modelBoundingBox);
    const cancelablePromise = cancelable(
      apiThreadPool
        .getIsoLines(request, retry)
        .then((dataGeoJSON) => {
          const { requestDatetime } = this.cache[cacheId];
          const cacheData: LoadedCacheDatum<GeoJSONFeatureCollection> = {
            requestDatetime,
            lastUsed: Number.NEGATIVE_INFINITY,
            state: CacheDatumState.Loaded,
            payload: dataGeoJSON,
          };
          this.cache[cacheId] = cacheData;
          return dataGeoJSON;
        })
        .catch((e: MeteomaticsApiError | Abort) => {
          if (isAbortable(e)) {
            delete this.cache[cacheId];
            return Promise.reject(e);
          }
          return this.reactOnMeteomaticsApiError(e, retry, cacheId);
        }),
    );

    this.cache[cacheId] = {
      requestDatetime: performance.now(),
      state: CacheDatumState.Pending,
      promise: cancelablePromise.promise,
      cancel: cancelablePromise.cancel,
      payload: retry,
    };
    return createPendingAsyncResult(cancelablePromise.promise, cancelablePromise.cancel);
  }

  retrieveLightningList(request: LightningListRequest<CoordinateSystem.WGS84>): AsyncResult<GeoJSONFeatureCollection> {
    const splitter = new LightningListTimeSplitter();
    const cacheId = generateLightningListLayerCacheId(request);

    const retry_: RetryState | null = null;

    if (Object.hasOwn(this.cache, cacheId)) {
      return this.peekCache(cacheId);
    }
    const retry = retry_ ?? createRetryState();

    const requests = splitter.splitRequest(request);
    const listAsync = requests.map((req) => {
      const subCacheId = generateLightningListLayerCacheId(req);
      if (Object.hasOwn(this.cache, subCacheId)) {
        return this.peekCache(subCacheId);
      }
      const subRetry_: RetryState | null = null;
      const subRetry = subRetry_ ?? createRetryState();

      const subCancelablePromise = cancelable(
        apiThreadPool
          .getLightningList(req, subRetry)
          .then((dataGeoJSON) => {
            const { requestDatetime } = this.cache[subCacheId];
            this.cache[subCacheId] = {
              requestDatetime,
              lastUsed: Number.NEGATIVE_INFINITY,
              state: CacheDatumState.Loaded,
              payload: dataGeoJSON,
            };
            return dataGeoJSON;
          })
          .catch((e: MeteomaticsApiError | Abort) => {
            if (isAbortable(e)) {
              delete this.cache[subCacheId];
              return Promise.reject(e);
            }
            // lightning layer split could partly fail
            if (e.kind === "TimeError") {
              const { requestDatetime } = this.cache[subCacheId];
              this.cache[subCacheId] = {
                requestDatetime,
                lastUsed: Number.NEGATIVE_INFINITY,
                state: CacheDatumState.Loaded,
                payload: emptyFeatureCollection,
              };
              return Promise.resolve(emptyFeatureCollection);
            }
            return this.reactOnMeteomaticsApiError(e, subRetry, subCacheId);
          }),
      );

      this.cache[subCacheId] = {
        requestDatetime: performance.now(),
        state: CacheDatumState.Pending,
        promise: subCancelablePromise.promise,
        cancel: subCancelablePromise.cancel,
        payload: subRetry,
      };
      return createPendingAsyncResult(subCancelablePromise.promise, subCancelablePromise.cancel);
    });

    const cancelablePromise = cancelable(
      Promise.all(listAsync.map((resp) => resp.asynchronous))
        .then((resp) => {
          const dataGeoJSON = splitter.mergeResponses(resp);
          const { requestDatetime } = this.cache[cacheId];
          this.cache[cacheId] = {
            requestDatetime,
            lastUsed: Number.NEGATIVE_INFINITY,
            state: CacheDatumState.Loaded,
            payload: dataGeoJSON,
          };
          return dataGeoJSON;
        })
        .catch((e: MeteomaticsApiError | Abort) => {
          if (isAbortable(e)) {
            delete this.cache[cacheId];
            return Promise.reject(e);
          }
          return this.reactOnMeteomaticsApiError(e, retry, cacheId);
        }),
    );

    this.cache[cacheId] = {
      requestDatetime: performance.now(),
      state: CacheDatumState.Pending,
      promise: cancelablePromise.promise,
      cancel: cancelablePromise.cancel,
      payload: retry,
    };
    return createPendingAsyncResult(cancelablePromise.promise, cancelablePromise.cancel);
  }

  retrieveWeatherFronts(request: WeatherFrontsRequest) {
    const cacheId = generateWeatherFrontsLayerCacheId(request);
    const retry_: RetryState | null = null;

    if (Object.hasOwn(this.cache, cacheId)) {
      return this.peekCache(cacheId);
    }
    const modelBoundingBox = request.boundingBoxLimit || WORLD_BBOX;
    const retry = retry_ ?? createRetryState(modelBoundingBox);
    const cancelablePromise = cancelable(
      apiThreadPool
        .getWeatherFronts(request, retry)
        .then((dataGeoJSON) => {
          const { requestDatetime } = this.cache[cacheId];
          const cacheData: LoadedCacheDatum<GeoJSONFeatureCollection> = {
            requestDatetime,
            lastUsed: Number.NEGATIVE_INFINITY,
            state: CacheDatumState.Loaded,
            payload: dataGeoJSON,
          };
          this.cache[cacheId] = cacheData;
          return dataGeoJSON;
        })
        .catch((e: MeteomaticsApiError | Abort) => {
          if (isAbortable(e)) {
            delete this.cache[cacheId];
            return Promise.reject(e);
          }
          return this.reactOnMeteomaticsApiError(e, retry, cacheId);
        }),
    );

    this.cache[cacheId] = {
      requestDatetime: performance.now(),
      state: CacheDatumState.Pending,
      promise: cancelablePromise.promise,
      cancel: cancelablePromise.cancel,
      payload: retry,
    };
    return createPendingAsyncResult(cancelablePromise.promise, cancelablePromise.cancel);
  }

  /**
   * Retrieves the XML from a WFS interface and converts it into GeoJSON.
   */
  retrieveWfs(
    request: WfsRequest<CoordinateSystem.WGS84>,
    /**
     * A callback to format raw GeoJSONFeatureCollection retrieved from the API before storing it in cache.
     * Pass a function if you want to format GeoJSONFeatureCollection before rendering.
     */
    geoJSONFormatter?: (featureCollection: GeoJSONFeatureCollection) => GeoJSONFeatureCollection,
  ) {
    const cacheId = generateWfsCacheId(request);
    if (Object.hasOwn(this.cache, cacheId)) {
      return this.peekCache(cacheId);
    }

    // TODO: This cache script is duplicated among different methods. Put it into a single function.
    const retry_: RetryState | null = null;

    const modelBoundingBox = request.boundingBoxLimit || WORLD_BBOX;
    const retry = retry_ ?? createRetryState(modelBoundingBox);
    const promise = apiThreadPool
      .getWfs(request, retry)
      .then((data) => {
        let result: GeoJSONFeatureCollection;
        if (geoJSONFormatter) {
          result = geoJSONFormatter(data);
        } else {
          result = data;
        }
        const { requestDatetime } = this.cache[cacheId];
        const cacheData: LoadedCacheDatum<GeoJSONFeatureCollection> = {
          requestDatetime,
          lastUsed: Number.NEGATIVE_INFINITY,
          state: CacheDatumState.Loaded,
          payload: result,
        };
        this.cache[cacheId] = cacheData;
        return result;
      })
      .catch((e: MeteomaticsApiError | Abort) => {
        if (isAbortable(e)) {
          delete this.cache[cacheId];
          return Promise.reject(e);
        }
        return this.reactOnMeteomaticsApiError(e, retry, cacheId);
      });

    this.cache[cacheId] = {
      requestDatetime: performance.now(),
      state: CacheDatumState.Pending,
      promise,
      payload: retry,
    };
    return createPendingAsyncResult(promise);
  }

  retrieveGrid(
    request: GridRequest<CoordinateSystem.WGS84>,
    kind: GridRequestKind = GridRequestKind.Raw,
    geoJSONFormatter?: (jsonResponse: JSONResponseBody) => GeoJSONFeatureCollection,
  ): AsyncResult<GeoJSONFeatureCollection> {
    const cacheId = generateGridLayerCacheId(request, kind);
    if (Object.hasOwn(this.cache, cacheId)) {
      return this.peekCache(cacheId);
    }

    const retry_: RetryState | null = null;
    const modelBoundingBox = request.boundingBoxLimit || WORLD_BBOX;
    const retry = retry_ ?? createRetryState(modelBoundingBox);
    const cancelablePromise = cancelable(
      apiThreadPool
        .getJSON(request, retry)
        .then((data) => {
          let result: GeoJSONFeatureCollection;
          switch (kind) {
            // TODO-StationFeature:
            // These JSON transformation function could be passed as a callback from LayerImpl class so we dont't have a huge switch statement?
            case GridRequestKind.Raw:
              if (geoJSONFormatter) {
                result = geoJSONFormatter(data);
              } else {
                result = createRawGeoJson(data);
              }
              break;
            case GridRequestKind.Symbol:
              result = createWeatherSymbolGeoJson(data);
              break;
            case GridRequestKind.WeatherCode:
              result = createWeatherCodeGeoJson(data);
              break;
            case GridRequestKind.Barbs:
              result = createBarbsGeoJson(data);
              break;
            default: {
              const _exhaustive: never = kind;
              return _exhaustive;
            }
          }
          const { requestDatetime } = this.cache[cacheId];
          const cacheData: LoadedCacheDatum<GeoJSONFeatureCollection> = {
            requestDatetime,
            lastUsed: Number.NEGATIVE_INFINITY,
            state: CacheDatumState.Loaded,
            payload: result,
          };
          this.cache[cacheId] = cacheData;
          return result;
        })
        .catch((e: MeteomaticsApiError | Abort) => {
          if (isAbortable(e)) {
            delete this.cache[cacheId];
            return Promise.reject(e);
          }
          return this.reactOnMeteomaticsApiError(e, retry, cacheId);
        }),
    );

    this.cache[cacheId] = {
      requestDatetime: performance.now(),
      state: CacheDatumState.Pending,
      promise: cancelablePromise.promise,
      cancel: cancelablePromise.cancel,
      payload: retry,
    };
    return createPendingAsyncResult(cancelablePromise.promise, cancelablePromise.cancel);
  }

  retrieveGeoJson(requestUrl: string, extern = false): AsyncResult<GeoJSONFeatureCollection> {
    const cacheId = `geojson_${requestUrl.toString()}`;

    let retry_: RetryState | null = null;
    if (Object.hasOwn(this.cache, cacheId)) {
      const v = this.cache[cacheId];
      switch (v.state) {
        case CacheDatumState.Pending:
          retry_ = v.payload;
          if (retry_.failureState !== FailureState.FailedTemporarily) {
            return createPendingAsyncResult(v.promise);
          }
          // since the current invocation moves the state from [Pending/FailedTemporarily] to [Pending/Queued]
          // mark it as such to prevent concurrent invocations from reentering the remaining function body
          retry_.failureState = undefined;
          break;
        case CacheDatumState.PermanentlyFailed:
          // TODO: store and forward failure reason?
          // fill with empty
          return createResolvedAsyncResult(emptyFeatureCollection);
        // return createPermanentlyFailedAsyncResult(v.payload);
        case CacheDatumState.Loaded:
          return createResolvedAsyncResult(v.payload);
        default: {
          const _exhaustive: never = v;
          return _exhaustive;
        }
      }
    }

    const modelBoundingBox = new Area(CoordinateSystem.WGS84, {
      east: 180,
      west: -180,
      north: 90,
      south: -90,
    });
    const retry = retry_ ?? createRetryState(modelBoundingBox);

    const url = extern ? getProxyUrl(requestUrl) : requestUrl;

    const promise = fetch(url)
      .then((data) => {
        return data.json();
      })
      .then((result) => {
        const { requestDatetime } = this.cache[cacheId];
        const cacheData: LoadedCacheDatum<GeoJSONFeatureCollection> = {
          requestDatetime,
          lastUsed: Number.NEGATIVE_INFINITY,
          state: CacheDatumState.Loaded,
          payload: result,
        };
        this.cache[cacheId] = cacheData;
        return result;
      })
      .catch((e) => {
        this.cache[cacheId] = {
          requestDatetime: performance.now(),
          state: CacheDatumState.PermanentlyFailed,
          payload: null,
        };

        return Promise.reject(e);
      });

    this.cache[cacheId] = {
      requestDatetime: performance.now(),
      state: CacheDatumState.Pending,
      promise,
      payload: retry,
    };
    return createPendingAsyncResult(promise);
  }

  reactOnMeteomaticsApiError(e: MeteomaticsApiError, retry: RetryState, cacheId: string): Promise<never> {
    // Todo: Make different reactions on Errors, currently always return a CacheDatumState.PermanentlyFailed
    // ToDo: Handle Out of bounds.
    switch (e.kind) {
      case "BoundsError":
      case "TimeError":
      case "ComputationTimeoutError":
      case "AuthError":
      case "UnknownError":
      case "UnknownParameterError":
      case "MissingDataError": {
        const { requestDatetime } = this.cache[cacheId];
        const cacheData: PermanentlyFailedCacheDatum<null> = {
          requestDatetime,
          state: CacheDatumState.PermanentlyFailed,
          payload: null,
        };
        this.cache[cacheId] = cacheData;
        // retry.failureState = FailureState.FailedPermanently;
        return Promise.reject(e);
      }
      default: {
        const _exhaustive: never = e;
        return _exhaustive;
      }
    }
    // Still here for retry functionality
    // Retry would be something for boundsError -> cropping bbox and retry ...
    // retry.failedAttempts++; // accumulated failures for the current tile only
    // retry.failureState = FailureState.FailedTemporarily;
    // if (retry.failedAttempts > NETWORK_EXPONENTIAL_BACKOFF_MAX_RETRIES) {
    //   retry.failureState = FailureState.FailedPermanently;
    //   return Promise.reject(e);
    // }
    //
    // return Promise.reject(e);
  }
}

import { compile } from "ejs";
import type { GeoJsonProperties } from "geojson";
import template from "./templates/taf.ejs";

const compiledTemplate = compile(template);

export const propertyTransform = (feature: GeoJsonProperties) => {
  const rawTaf = feature?.raw_taf;
  if (rawTaf) {
    const modifiedRawTaf = rawTaf
      .replace("FROM", "</br>&nbsp;&nbsp;FROM")
      .replace(/FM0/g, "</br>&nbsp;&nbsp;FM0")
      .replace(/FM1/g, "</br>&nbsp;&nbsp;FM1")
      .replace(/FM2/g, "</br>&nbsp;&nbsp;FM2")
      .replace(/FM3/g, "</br>&nbsp;&nbsp;FM3")
      .replace(/TEMPO/g, "</br>&nbsp;&nbsp;TEMPO")
      .replace(/BECMG/g, "</br>&nbsp;&nbsp;BECMG")
      .replace(/PROB/g, "</br>&nbsp;&nbsp;PROB")
      .replace(/INTER/g, "</br>&nbsp;&nbsp;INTER");

    return {
      ...feature,
      raw_taf: modifiedRawTaf,
    };
  }
  return feature;
};

export function createTafTooltip(features: GeoJsonProperties) {
  return compiledTemplate({ features: propertyTransform(features) });
}

import { DEFAULT_PARTICLE_AMOUNT } from "@/constants/layerConfigAttributes";
import {
  type RendererPrograms,
  compilePrograms,
  createParticleIndexBuffer,
  createParticleStateTextures,
  drawParticles,
  drawTexture,
  updateParticles,
} from "@/layers/wind/gl/mixins";
import { bindFramebuffer, createBuffer, createEmptyPixels, createTexture } from "@/layers/wind/gl/util";
import { type WindLayerProperties, defaultWindParticleSettings } from "@/layers/wind/layer";
import type { WindMetaData } from "@/layers/wind/types";

export function createWindParticlesRenderer(gl: WebGLRenderingContext, width: number, height: number) {
  const _programs: RendererPrograms = compilePrograms(gl);

  /**
   * Textures
   */
  let _stateTextures = createParticleStateTextures(gl, DEFAULT_PARTICLE_AMOUNT ** 2);

  const __emptyPixels = createEmptyPixels(width, height);
  const _displayTextures = {
    screenTexture: createTexture(gl, gl.NEAREST, __emptyPixels, width, height),
    backgroundTexture: createTexture(gl, gl.NEAREST, __emptyPixels, width, height),
  };

  /**
   * Buffers
   */
  const _frameBuffer = gl.createFramebuffer();
  const _quadBuffer = createBuffer(gl, new Float32Array([0, 0, 1, 0, 0, 1, 0, 1, 1, 0, 1, 1]));
  let _particleIndexBuffer = createParticleIndexBuffer(gl, DEFAULT_PARTICLE_AMOUNT ** 2);

  const updateParticleIndexBuffer = (amount: number) => {
    _stateTextures = createParticleStateTextures(gl, amount ** 2);
    _particleIndexBuffer = createParticleIndexBuffer(gl, amount ** 2);
  };

  /**
   * Pre-renders particles into screen texture
   * @param metadata Wind mapping metadata
   */
  const prerender = (
    windMapTexture: WebGLTexture | null,
    colorMapTexture: WebGLTexture | null,
    metadata: WindMetaData,
    properties: WindLayerProperties,
  ) => {
    if (colorMapTexture === null || windMapTexture === null) {
      return;
    }
    gl.disable(gl.BLEND);

    // Draw screen texture into background texture by applying opacity, to create trails
    bindFramebuffer(gl, _frameBuffer, _displayTextures.backgroundTexture);
    gl.viewport(0, 0, width, height);
    drawTexture(
      gl,
      _displayTextures.screenTexture,
      defaultWindParticleSettings.fadeOpacity,
      _quadBuffer,
      _programs.textureProgram,
    );
    gl.bindRenderbuffer(gl.RENDERBUFFER, null);

    updateParticles(
      gl,
      windMapTexture,
      _stateTextures,
      _quadBuffer,
      metadata,
      properties["particle-amount"],

      _programs.updateProgram,
    );

    // Swap texture state textures
    const temp2 = _stateTextures.particleStateTexture0;
    _stateTextures.particleStateTexture0 = _stateTextures.particleStateTexture1;
    _stateTextures.particleStateTexture1 = temp2;

    // draw the screen into a temporary framebuffer to retain it as the background on the next frame
    bindFramebuffer(gl, _frameBuffer, _displayTextures.screenTexture);
    gl.viewport(0, 0, width, height);
    gl.clearColor(0, 0, 0, 0);
    gl.clear(gl.COLOR_BUFFER_BIT);

    if (gl.checkFramebufferStatus(gl.FRAMEBUFFER) !== gl.FRAMEBUFFER_COMPLETE) {
      console.error("Framebuffer is not complete!");
      return;
    }

    // Draw trails
    drawTexture(gl, _displayTextures.backgroundTexture, 1.0, _quadBuffer, _programs.textureProgram);

    // Draw new particles
    drawParticles(
      gl,
      _particleIndexBuffer,
      windMapTexture,
      _stateTextures.particleStateTexture1,
      colorMapTexture,
      metadata,
      properties["particle-amount"] ** 2,
      properties["particle-size"],
      _programs.drawProgram,
    );

    gl.bindRenderbuffer(gl.RENDERBUFFER, null);
  };

  /**
   * Renders screen texture on display
   * @param props WindLayer props, for example opacity
   */
  const render = (props: WindLayerProperties) => {
    gl.enable(gl.BLEND);
    gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
    drawTexture(gl, _displayTextures.screenTexture, props.opacity, _quadBuffer, _programs.textureProgram);
    gl.disable(gl.BLEND);
  };

  const clear = () => {
    bindFramebuffer(gl, _frameBuffer, _displayTextures.screenTexture);
    gl.clear(gl.COLOR_BUFFER_BIT);
    bindFramebuffer(gl, _frameBuffer, _displayTextures.backgroundTexture);
    gl.clear(gl.COLOR_BUFFER_BIT);
    gl.bindRenderbuffer(gl.RENDERBUFFER, null);
  };

  return {
    prerender,
    render,
    clear,
    updateParticleIndexBuffer,
    texture: _displayTextures.screenTexture,
  };
}

export type WindParticlesRenderer = ReturnType<typeof createWindParticlesRenderer>;

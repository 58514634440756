/**
 * Utility to keep track of multiple mapbox source IDs as a single group.
 * It provides type safe way to access the source ID and acts as a dictionary
 * to lookup the mapbox source IDs by human-readable name.
 *
 * Note that we intentionally don't put the source data in here at the moment,
 * because we can directly derive those information from the mapbox instance using IDs.
 */
export class MapboxSourceGroup<SourceName extends string> {
  private groupId: string;
  /**
   * This represents all the source names that a consumer can use to retrieve the mapbox source ID.
   */
  private registeredSourceNames: Set<SourceName>;

  constructor(groupId: string, sources: [...SourceName[]]) {
    this.groupId = groupId;
    this.registeredSourceNames = new Set<SourceName>(sources);
  }

  private _sourceId(sourceName: SourceName) {
    return `${this.groupId}:${sourceName}`;
  }

  /**
   * Returns a source ID by a source name.
   */
  id(sourceName: SourceName) {
    if (!this.registeredSourceNames.has(sourceName)) {
      throw Error(`Source with name "${sourceName}" doesn't exist in the source group ${this.groupId}`);
    }
    return this._sourceId(sourceName);
  }

  allIds(): string[] {
    return [...this.registeredSourceNames.keys()].map((sourceName) => this._sourceId(sourceName));
  }

  toString() {
    return `${MapboxSourceGroup.name}[${this.groupId}] (${Array.from(this.registeredSourceNames.keys()).join(",")})`;
  }
}

import type { PlaybackState } from "@/animation";
import { useVideoExportContext } from "@/animation/video-generator/components/VideoExportContext";
import { useToolSnapshotConstructor } from "@/animation/video-generator/generator/snapshotGenerator";
import { createTimeFramesFromState } from "@/animation/video-generator/utils/videoUtils";
import { useCurrTimeState } from "@/models/time-control/timeStateHooks";
import { mediaExportThreadPool } from "@/threads/MediaExportThread/MediaExportThreadPool";
import { flatten } from "lodash";
import type { DateTime } from "luxon";
import { useCallback, useMemo } from "react";

export type FrameGeneratorStatus = {
  totalFrames: number;
  processedFrames: number;
};

// Currently frameGenerator has very weak error handling
// Failed frame most likely will get skipped or hang UI
// TODO Implement more robust error handling
export function useVideoGenerator(tabId: number) {
  const { frameQueue, frameRecordingQueueStatus, isVideoRecordingActive } = useVideoExportContext();
  const { snapshotActiveToolsAsync, snapshotMapOverlaysAsync } = useToolSnapshotConstructor(tabId);

  const timeState = useCurrTimeState();

  const timeList = useMemo<DateTime[]>(() => createTimeFramesFromState(timeState), [timeState]);

  /**
   * Generate callback to construct the UI snapshot at a given display time.
   */
  const createFrameTask = useCallback(
    (time: DateTime, playback: PlaybackState) => {
      // You can think of this callback as "tasks" that get queued in the frame queue.
      return async (index: number, totalTaskNum: number) => {
        // Set the current UI to the given time
        playback.animationTime = time;

        // Wait until everything in UI is loaded.
        const isLastFrame = index + 1 === totalTaskNum;
        await playback.allScenesLoaded(time, isLastFrame);

        // Generate the snapshot of the UI
        const snapshots = await Promise.all([snapshotActiveToolsAsync(), snapshotMapOverlaysAsync()]);

        // Pass the generated image to a web worker that processes the video.
        // Note we don't need to wait until the end of pushing
        // Because next time frame doesn't depend on the current one
        mediaExportThreadPool.pushFrame(flatten(snapshots));
      };
    },
    [snapshotActiveToolsAsync, snapshotMapOverlaysAsync],
  );

  /**
   * A callback that generates frame queue and start executing the snapshot taking process.
   */
  const recordUIVideo = useCallback(
    async (playback: PlaybackState) => {
      if (!frameQueue) {
        throw Error("Frame recording queue not found");
      }

      mediaExportThreadPool.createVideo(
        {
          widthLogicalPx: window.innerWidth,
          heightLogicalPx: window.innerHeight,
          devicePixelRatio: window.devicePixelRatio,
        },
        playback.targetFPS,
      );

      // Create a queue for each time step frame
      // Each task within the queue puts the snapshots to the video thread pool
      const frameTasks = timeList.map((time) => createFrameTask(time, playback));
      frameQueue.createQueue(frameTasks);
      const tasks = await frameQueue.process();

      if (tasks.wasCanceled) {
        // Return null video, if the recording has been cancelled in the middle.
        return null;
      }

      // Finalize the video using the taken snapshots
      const videoBlob = mediaExportThreadPool.processVideo();
      return videoBlob;
    },
    [frameQueue, timeList, createFrameTask],
  );

  const cancelUIRecording = () => {
    frameQueue?.cancel();
    // IMPORTANT:
    // Make sure to call disposeVideo() here, as it cleans up the open resources such as video encoder.
    // Failure to do so can cause memory leak.
    mediaExportThreadPool.disposeVideo();
  };

  return {
    recordUIVideo,
    cancelUIRecording,
    status: frameRecordingQueueStatus,
    isActive: isVideoRecordingActive,
  };
}

import {
  type AsyncResult,
  createPendingAsyncResult,
  createPermanentlyFailedAsyncResult,
  createResolvedAsyncResult,
} from "@/cache/AsyncResult";
import type { GeoJSONCacheStats } from "@/cache/GeoJSONCache";
import { type CacheDatum, CacheDatumState, type LoadedCacheDatum } from "@/cache/GlobalCache";
import { apiThreadPool } from "@/cache/SpatioTemporalTileCache/ApiQueryThreadPool";
import {
  FailureState,
  NETWORK_EXPONENTIAL_BACKOFF_MAX_RETRIES,
  type RetryState,
  createRetryState,
} from "@/cache/SpatioTemporalTileCache/RetryState";
import { type Stats, jsxTable } from "@/cache/Stats";
import { type JsonWithParameter, createJsonWithParameter } from "@/json";
import { type PolygonRequest, WORLD_BBOX } from "@mm/api.meteomatics.com";
import { type CoordinateSystem, MeteomaticsApiUrl, type PointRequest } from "@mm/api.meteomatics.com";
import type { MeteomaticsApiError } from "@mm/api.meteomatics.com/lib/error";
import { type Abort, isAbortable } from "@mm/api.meteomatics.com/lib/middleware";
import type { Metadata } from "../Metadata";

export enum SingleRequestKind {
  SingleParameter = "single parameter",
  MultiPointsGeoJson = "multi points geojson",
}

export class PolygonJSONCache implements Stats<GeoJSONCacheStats> {
  protected cache: {
    [key: string]: CacheDatum<JsonWithParameter, RetryState>;
  } = {};
  constructor(private readonly label_: string) {}
  descJsx(): JSX.Element {
    return <>Special-purpose cache for API JSON Requests. Replacement Policy is LRU.</>;
  }

  label(): string {
    return this.label_;
  }

  stats(): GeoJSONCacheStats {
    let count = 0;
    let pending = 0;
    const cache = this.cache;

    for (const line in cache) {
      if (Object.hasOwn(cache, line)) {
        count++;

        if (cache[line].state === CacheDatumState.Pending) {
          pending++;
        }
      }
    }

    return { count, pending };
  }

  statsJsx(): JSX.Element {
    return jsxTable(this.stats(), (key, val) => val);
  }

  /**
   * Lookup a polygon and start fetching it if it is unavailable.
   *
   */
  retrievePolygon(
    request: PolygonRequest<CoordinateSystem.WGS84>,
    metadata?: Metadata,
  ): AsyncResult<JsonWithParameter> {
    const url = MeteomaticsApiUrl.forPolygon({ format: "json" }, request);
    const cacheId = url.toString();
    // TODO: check this, how retry could be null like in GeoJSONCache (const retry = retry_ ?? createRetryState(modelBoundingBox);)
    // here also needed but for timeRange start end???
    let retry_: RetryState | null = null;
    if (Object.hasOwn(this.cache, cacheId)) {
      const v = this.cache[cacheId];
      switch (v.state) {
        case CacheDatumState.Pending:
          retry_ = v.payload;
          if (retry_.failureState !== FailureState.FailedTemporarily) {
            return createPendingAsyncResult(v.promise);
          }
          // since the current invocation moves the state from [Pending/FailedTemporarily] to [Pending/Queued]
          // mark it as such to prevent concurrent invocations from reentering the remaining function body
          retry_.failureState = undefined;
          break;
        case CacheDatumState.PermanentlyFailed:
          // TODO: store and forward failure reason?
          return createPermanentlyFailedAsyncResult(v.payload);
        case CacheDatumState.Loaded:
          return createResolvedAsyncResult(v.payload);
        default: {
          const _exhaustive: never = v;
          return _exhaustive;
        }
      }
    }

    const modelBoundingBox = request.boundingBoxLimit || WORLD_BBOX;
    const retry = retry_ ?? createRetryState(modelBoundingBox);

    const promise = apiThreadPool
      .getPolygonJson(request, retry, metadata)
      .then((data) => {
        // This works very well here, but naming is very confusing, so we will need to rename it
        const result: JsonWithParameter = createJsonWithParameter(data);

        const { requestDatetime } = this.cache[cacheId];
        const cacheLine: LoadedCacheDatum<JsonWithParameter> = {
          requestDatetime,
          lastUsed: Number.NEGATIVE_INFINITY,
          state: CacheDatumState.Loaded,
          payload: result,
        };
        this.cache[cacheId] = cacheLine;
        return result;
      })
      .catch((e: MeteomaticsApiError | Abort) => {
        if (isAbortable(e)) {
          delete this.cache[cacheId];
          return Promise.reject(e);
        }

        retry.failedAttempts++; // accumulated failures for the current tile only
        retry.failureState = FailureState.FailedTemporarily;

        if (retry.failedAttempts > NETWORK_EXPONENTIAL_BACKOFF_MAX_RETRIES) {
          retry.failureState = FailureState.FailedPermanently;
          return Promise.reject(e);
        }
        // ToDo: Handle Out of bounds.
        // ToDo: Retry. Fixme.
        return Promise.reject(e);
      });

    this.cache[cacheId] = {
      requestDatetime: performance.now(),
      state: CacheDatumState.Pending,
      promise,
      payload: retry,
    };
    return createPendingAsyncResult(promise);
  }
}

export function getById<T extends { id: number }>(lookupId: number, list: T[]): T | undefined {
  return list.find(({ id }) => id === lookupId);
}

export function getIndexById<T extends { id: number }>(lookupId: number, list: T[]): number | undefined {
  const idx = list.findIndex(({ id }) => id === lookupId);
  return idx < 0 ? undefined : idx;
}

export function getToolByToolId<T extends { id: number }>(toolId: number, toolList: T[]): T | undefined {
  return toolList.find((tool) => tool.id === toolId);
}

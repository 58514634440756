import { useRef } from "react";

export enum CssCursor {
  Alias = "alias",
  AllScroll = "all-scroll",
  Auto = "auto",
  Cell = "cell",
  ContextMenu = "context-menu",
  ColResize = "col-resize",
  Copy = "copy",
  Crosshair = "crosshair",
  Default = "default",
  EResize = "e-resize",
  EwResize = "ew-resize",
  Grab = "grab",
  Grabbing = "grabbing",
  Help = "help",
  Move = "move",
  NResize = "n-resize",
  NeResize = "ne-resize",
  NeswResize = "nesw-resize",
  NsResize = "ns-resize",
  NwResize = "nw-resize",
  NwseResize = "nwse-resize",
  NoDrop = "no-drop",
  None = "none",
  NotAllowed = "not-allowed",
  Pointer = "pointer",
  Progress = "progress",
  RowResize = "row-resize",
  SResize = "s-resize",
  SeResize = "se-resize",
  SwResize = "sw-resize",
  Text = "text",
  Url = "url",
  VerticalText = "vertical-text",
  WResize = "w-resize",
  Wait = "wait",
  ZoomIn = "zoom-in",
  ZoomOut = "zoom-out",
}

/**
 * Sets a custom global mouse cursor and allows changing and resetting it.
 * Hook stores previous cursor, so it won't conflict with other parts of CSS
 *
 * @return {Object} An object with methods to change and reset the mouse cursor.
 */
export const useMouseCursor = () => {
  const cursorRef = useRef<string | undefined>();
  const changeCursor = (cursor: CssCursor) => {
    if (!cursorRef.current) {
      cursorRef.current = document.body.style.cursor;
    }
    document.body.style.cursor = cursor;
  };

  const resetCursor = () => {
    document.body.style.cursor = cursorRef.current || "";
    cursorRef.current = undefined;
  };

  return {
    changeCursor,
    resetCursor,
  };
};

import type { PerformanceHint } from "../performanceIntrospection/PerformanceHint";
import type { Connection } from "./Connection";
import { ConnectionManagement } from "./ConnectionManagement";
/**
 * This is a simple connection management strategy that evenly distributes requests
 * to API proxies by cycling through a list of base urls.
 *
 * So, for example, assuming three base urls A,B, and C. The first request is assigned to
 * A, the second to B, the third to C, and the fourth again to A, etc.
 *
 * This strategy is sufficient
 * - if requests have similar latency, or if latency does not matter, and
 * - if the number of parallel connections is guranteed to stay below the hard parallel
 *   limit of your api account.
 */
export class CyclicConnectionDistribution extends ConnectionManagement {
  private currIdx = 0;
  private urls: string[];

  constructor(baseUrls: string[]) {
    super();
    this.urls = [...baseUrls];
  }

  getBaseUrl(performanceHint?: PerformanceHint): Promise<Connection> {
    const baseUrl = this.urls[this.currIdx];
    this.currIdx = (this.currIdx + 1) % this.urls.length;
    return Promise.resolve({ baseUrl, free: noop });
  }
}

function noop() {}

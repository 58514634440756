import reduxStore from "@/reducer";
import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";

import { PrivateRoute, PublicOnlyRoute, useAuth } from "@/auth";
import { Redirect, Route, Router, Switch } from "react-router-dom";

import * as route from "@/route";
import ActivateWorkspace from "@/route/activate/workspace";

import { dynamicActivate, negotiateLocale } from "@/i18n";
import { initialDesktopState } from "@/overlay/components/window/DesktopState";
import { ViewportOverlayProvider } from "@/overlay/overlays/default/ViewportOverlayProvider";
import { BetaArea } from "@/pages/beta-area";
import LoginPage from "@/pages/login";
import { NcmArea } from "@/pages/ncm-area";
import NotFoundPage from "@/pages/not-found";
import OAuthAuthorizationCode from "@/pages/oauth";
import ProfilePage from "@/pages/profile";
import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import { useMeteomaticsApi } from "./env/useMeteomaticsApi";
import { DesktopProvider } from "./overlay/components";
import ErrorPage from "./pages/error";
import LogoutPage from "./pages/logout";

// Note: We import filled.css only to minimize the bundle.
// If you like to use other types, you need to import other modules.
// Ref: npmjs.com/package/material-icons
import { StateMachineProvider } from "@/models/time-control/timeStateContext";
import { LocalClipboardContextProvider } from "@/overlay/components/LayerStackWindow/ManageActionPanel/LocalClipboardContext";
import { SentryBootstrapper } from "@/sentry/SentryConfig";
import { MouseFlowTracking } from "@/tracking";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { DndProvider } from "react-dnd";
import { TouchBackend } from "react-dnd-touch-backend";

import { IS_NCM } from "@/env";
import { OpenFeatureProvider } from "@openfeature/react-sdk";

// Setup external web components
import "./web-components";

import "@/style/main.scss";
// Note: We import filled.css only to minimize the bundle.
// If you like to use other types, you need to import other modules.
// Ref: npmjs.com/package/material-icons
import "material-icons/iconfont/filled.css";
import { usePromise } from "./hooks/usePromise";

const negotiatedLocale = negotiateLocale();
const queryClient = new QueryClient();
function Pages() {
  // we simply use it here to ensure the correct credentials are always propagated to the worker thread, even
  // if the `ApiQueryThreadPool` singleton is used directly without the hook.
  useMeteomaticsApi();
  return (
    <MouseFlowTracking>
      <Switch>
        <Route exact={true} path="/error">
          <ErrorPage />
        </Route>

        <Route exact={true} path={route.defaultRoute.pattern()}>
          <Redirect to={route.defaultRouteAfterLogin.asString()} />
        </Route>

        <Route exact={true} path={route.pageOAuth.pattern()}>
          <OAuthAuthorizationCode />
        </Route>

        <PublicOnlyRoute
          exact={true}
          path={route.pageLogin.pattern()}
          routeIfLoggedIn={route.defaultRouteAfterLogin.asString()}
        >
          <LoginPage />
        </PublicOnlyRoute>

        <Route exact={true} path={route.pageLogout.pattern()}>
          <LogoutPage />
        </Route>

        <DesktopProvider value={initialDesktopState}>
          <PrivateRoute
            exact={true}
            path={[
              route.pageBeta.pattern(),
              route.pageBetaWorkspace.pattern(),
              route.pageBeta.pattern() + route.pageProfiles.pattern(),
            ]}
          >
            <BetaArea />
          </PrivateRoute>

          <PrivateRoute
            exact={true}
            path={[
              route.pageNcm.pattern(),
              route.pageNcmWorkspace.pattern(),
              route.pageNcm.pattern() + route.pageProfiles.pattern(),
            ]}
          >
            <NcmArea />
          </PrivateRoute>

          <PrivateRoute exact={true} path={route.pageProfiles.pattern()}>
            <ProfilePage showCustomLogo={IS_NCM} />
          </PrivateRoute>

          <PrivateRoute exact={true} path={route.pageWorkspace.pattern()}>
            <ActivateWorkspace />
          </PrivateRoute>
        </DesktopProvider>

        <Route component={NotFoundPage} />
      </Switch>
    </MouseFlowTracking>
  );
}

// TODO: show loading indicator until locale is loaded

function App() {
  const [appLoading, setAppLoading] = useState<"fulfilled" | "rejected" | "pending">("pending");
  const { activateSession, sessionActivated } = useAuth();

  useEffect(() => {
    if (!sessionActivated) {
      activateSession();
    }
  }, [activateSession, sessionActivated]);

  const initializeLocale = usePromise(dynamicActivate, []);

  useEffect(() => {
    initializeLocale(negotiatedLocale.languageTag).then(
      () => setAppLoading("fulfilled"),
      () => setAppLoading("rejected"),
    );
  }, [initializeLocale]);

  return (
    <React.StrictMode>
      {(!sessionActivated || appLoading === "pending") && <mm-loader size="xlarge" />}
      {appLoading === "rejected" && "Failed to initialize app"}
      {appLoading === "fulfilled" && (
        // Move Router to top of tree as it's context provider for react-router-dom

        <Router history={route.history}>
          {/*@ts-ignore*/}
          <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}>
            <I18nProvider i18n={i18n}>
              <QueryClientProvider client={queryClient}>
                <OpenFeatureProvider>
                  <SentryBootstrapper>
                    <Provider store={reduxStore}>
                      <StateMachineProvider>
                        <ViewportOverlayProvider>
                          <LocalClipboardContextProvider>
                            <div className="App">
                              <Pages />
                            </div>
                          </LocalClipboardContextProvider>
                        </ViewportOverlayProvider>
                      </StateMachineProvider>
                    </Provider>
                  </SentryBootstrapper>
                </OpenFeatureProvider>
              </QueryClientProvider>
            </I18nProvider>
          </DndProvider>
        </Router>
      )}
    </React.StrictMode>
  );
}
export default App;

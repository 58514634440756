export function createProgram(
  gl: WebGLRenderingContext,
  vertexShader: WebGLShader,
  fragmentShader: WebGLShader,
): WebGLProgram | Error {
  // biome-ignore lint/style/noNonNullAssertion: TODO createprogram can return null
  const program = gl.createProgram()!;

  gl.attachShader(program, vertexShader);
  gl.attachShader(program, fragmentShader);

  gl.linkProgram(program);

  const success = gl.getProgramParameter(program, gl.LINK_STATUS);

  if (!success) {
    throw Error(`program failed to link: ${gl.getProgramInfoLog(program)}`);
  }

  return program;
}

import { IconStore } from "@/icon-store/IconStore";
import { colorHazard, fillColorOpacity } from "@/layers/utility/aviationStyle";
import type { AviationTypesUnion } from "@/layers/utility/aviationType";
import { classNames } from "@/utility/jsx";
import { parseHexString } from "color-lib";
import { iconMetaMapping } from "icon-set";
import { useRef } from "react";
import { InternationalSigmetLegend } from "./internationalSigmetLegend";
import { MetarSVG } from "./metarSvg";
import styles from "./style.module.scss";
import { TafSVG } from "./tafSvg";

const ICON_SIZE = IconStore.instance.dimension;

interface AppProps {
  aviationType: AviationTypesUnion;
}

export const AviationLegends = ({ aviationType }: AppProps): JSX.Element => {
  switch (aviationType) {
    case "isigmet":
      return <InternationalSigmetLegend />;
    case "sigmet":
      return <SigmetLegend />;
    case "airep":
      return (
        <div className={styles["flex-start-row"]}>
          <PirepIceLegend />
          <PirepTurbLegend />
        </div>
      );
    case "taf":
      return (
        <>
          <TafLegend /> <WindBarbsLegend />
        </>
      );
    case "metar":
      return (
        <>
          <MetarLegend /> <WindBarbsLegend />
        </>
      );
    default:
      return <></>;
  }
};

interface LegendItemProps {
  text: string;
  textBefore?: boolean;
  fillColor?: string;
  fillOpacity?: number;
  borderColor?: string;
  children: React.ReactNode;
}
/**
 * color must be in css hex #000000 format
 * because we will add opacity in hex
 * */
export const LegendItem = ({
  text,
  textBefore = false,
  fillColor,
  borderColor,
  children,
}: LegendItemProps): JSX.Element => {
  let style: React.CSSProperties = { borderColor: borderColor, backgroundColor: fillColor };
  if (fillColorOpacity != null && fillColor != null) {
    const backgroundColor = parseHexString(fillColor);
    if (backgroundColor) {
      style = { ...style, backgroundColor: `${fillColor}${Math.floor(fillColorOpacity * 255).toString(16)}` };
    }
  }

  return textBefore ? (
    <>
      <span>{text}</span>
      <div className={styles["legend-item-box"]} style={style}>
        {children}
      </div>
    </>
  ) : (
    <>
      <div className={styles["legend-item-box"]} style={style}>
        {children}
      </div>
      <span>{text}</span>
    </>
  );
};

interface SpriteSheetProps {
  imgName: string;
  iconHeight: number;
  color?: string;
  isWindIcon?: boolean;
  iconColorFilter?: string;
}

export const SpriteSheetImg = ({
  imgName,
  iconHeight,
  isWindIcon = false,
  color = "#000000",
  iconColorFilter = undefined,
}: SpriteSheetProps): JSX.Element => {
  let margin = "";
  let transform = "";
  const ref = useRef<HTMLDivElement>(null);

  const factorSmaller = ICON_SIZE / iconHeight;

  if (isWindIcon) {
    margin = `-${ICON_SIZE / 2}px 0`;
    transform = "rotate(270deg)";
  }

  return (
    <div
      ref={ref}
      style={{
        transform: transform,
        margin: margin,
        backgroundColor: color,
        width: ICON_SIZE / factorSmaller,
        height: ICON_SIZE / factorSmaller,
        mask: `url(${iconMetaMapping[imgName]?.uri || "#"}) no-repeat center`,
        maskSize: "contain",
      }}
    />
  );
};
interface LegendItemIconProps {
  text: string;
  textBefore?: boolean;
  children: React.ReactNode;
}
export const LegendItemIcon = ({ text, textBefore = false, children }: LegendItemIconProps): JSX.Element => {
  return textBefore ? (
    <>
      <span>{text}</span>
      {children}
    </>
  ) : (
    <>
      {children}
      <span>{text}</span>
    </>
  );
};

/**
 * Wind Barbs legend
 * */
export const WindBarbsLegend = (): JSX.Element => {
  return (
    <div className={styles["flex-center-column"]}>
      <h1>Wind</h1>
      <ul className={styles["legend-list"]}>
        <li>
          <LegendItemIcon text={"Calm"}>
            <SpriteSheetImg isWindIcon={true} iconHeight={ICON_SIZE} imgName={"n0"} />
          </LegendItemIcon>
        </li>
        <li>
          <LegendItemIcon text={"15kn"}>
            <SpriteSheetImg isWindIcon={true} iconHeight={ICON_SIZE} imgName={"n15"} />
          </LegendItemIcon>
        </li>
        <li>
          <LegendItemIcon text={"60kn"}>
            <SpriteSheetImg isWindIcon={true} iconHeight={ICON_SIZE} imgName={"n60"} />
          </LegendItemIcon>
        </li>
      </ul>
    </div>
  );
};

/**
 * US SIGMETs - CONUS SIGMETs
 * I SIGMETs - International SIGMETs
 *
 * */
export const SigmetLegend = (): JSX.Element => {
  const borderColor = colorHazard.TS;
  return (
    <div className={styles["flex-center-column"]}>
      <h1>SIGMET</h1>
      <ul className={styles["legend-list"]}>
        <li>
          <LegendItem
            text={"Thunderstorms"}
            borderColor={borderColor}
            fillOpacity={fillColorOpacity}
            fillColor={colorHazard.TS}
          >
            TS
          </LegendItem>
        </li>
        <li>
          <LegendItem
            text={"Convection"}
            borderColor={borderColor}
            fillOpacity={fillColorOpacity}
            fillColor={colorHazard.TS}
          >
            Conv
          </LegendItem>
        </li>
        <li>
          <LegendItem
            text={"Tropical Cyclone"}
            borderColor={borderColor}
            fillOpacity={fillColorOpacity}
            fillColor={colorHazard.TC}
          >
            Trop
          </LegendItem>
        </li>
        <li>
          <LegendItem
            text={"Turbulence"}
            borderColor={borderColor}
            fillOpacity={fillColorOpacity}
            fillColor={colorHazard.TURB}
          >
            Turb
          </LegendItem>
        </li>
        <li>
          <LegendItem
            text={"Icing"}
            borderColor={borderColor}
            fillOpacity={fillColorOpacity}
            fillColor={colorHazard.ICE}
          >
            Ice
          </LegendItem>
        </li>
        <li>
          <LegendItem
            text={"Volcanic Ash"}
            borderColor={borderColor}
            fillOpacity={fillColorOpacity}
            fillColor={colorHazard.VA}
          >
            VA
          </LegendItem>
        </li>
      </ul>
    </div>
  );
};

/**
 * PIREP-Turb - Turbulence PIREPs
 * */
export const PirepTurbLegend = (): JSX.Element => {
  return (
    <div className={styles["flex-center-column"]}>
      <h1>Pirep-Turb</h1>
      <ul className={styles["legend-list"]}>
        <li>
          <LegendItemIcon text={"NIL"}>
            <SpriteSheetImg iconHeight={32} color={"#0000ff"} imgName={"airep-wx_TUR-N"} />
          </LegendItemIcon>
        </li>
        <li>
          <LegendItemIcon text={"Smooth-Light"}>
            <SpriteSheetImg iconHeight={32} color={"#00aa00"} imgName={"airep-wx_TUR-TL"} />
          </LegendItemIcon>
        </li>
        <li>
          <LegendItemIcon text={"Light"}>
            <SpriteSheetImg iconHeight={32} color={"#00aa00"} imgName={"airep-wx_TUR-L"} />
          </LegendItemIcon>
        </li>
        <li>
          <LegendItemIcon text={"Light-Moderate"}>
            <SpriteSheetImg iconHeight={32} color={"#ff8800"} imgName={"airep-wx_TUR-LM"} />
          </LegendItemIcon>
        </li>
        <li>
          <LegendItemIcon text={"Moderate"}>
            <SpriteSheetImg iconHeight={32} color={"#ff8800"} imgName={"airep-wx_TUR-M"} />
          </LegendItemIcon>
        </li>
        <li>
          <LegendItemIcon text={"Moderate-Severe"}>
            <SpriteSheetImg iconHeight={32} color={"#aa0000"} imgName={"airep-wx_TUR-MS"} />
          </LegendItemIcon>
        </li>
        <li>
          <LegendItemIcon text={"Severe"}>
            <SpriteSheetImg iconHeight={32} color={"#aa0000"} imgName={"airep-wx_TUR-S"} />
          </LegendItemIcon>
        </li>
        <li>
          <LegendItemIcon text={"Extreme"}>
            <SpriteSheetImg iconHeight={32} color={"#cc00cc"} imgName={"airep-wx_TUR-X"} />
          </LegendItemIcon>
        </li>
      </ul>
    </div>
  );
};
/**
 * PIREP-Ice - Icing PIREPs
 * */
export const PirepIceLegend = (): JSX.Element => {
  return (
    <div className={styles["flex-center-column"]}>
      <h1>Pirep-Ice</h1>
      <ul className={styles["legend-list"]}>
        <li>
          <LegendItemIcon text={"NIL"}>
            <SpriteSheetImg iconHeight={32} color={"#0000ff"} imgName={"airep-wx_ICE-N"} />
          </LegendItemIcon>
        </li>
        <li>
          <LegendItemIcon text={"Trace"}>
            <SpriteSheetImg iconHeight={32} color={"#00aa00"} imgName={"airep-wx_ICE-T"} />
          </LegendItemIcon>
        </li>
        <li>
          <LegendItemIcon text={"Trace-Light"}>
            <SpriteSheetImg iconHeight={32} color={"#00aa00"} imgName={"airep-wx_ICE-TL"} />
          </LegendItemIcon>
        </li>
        <li>
          <LegendItemIcon text={"Light"}>
            <SpriteSheetImg iconHeight={32} color={"#00aa00"} imgName={"airep-wx_ICE-L"} />
          </LegendItemIcon>
        </li>
        {/* Missing Light-Moderate Icon */}
        <li>
          <LegendItemIcon text={"Moderate"}>
            <SpriteSheetImg iconHeight={32} color={"#ff8800"} imgName={"airep-wx_ICE-M"} />
          </LegendItemIcon>
        </li>
        {/* Moderate-Severe */}
        <li>
          <LegendItemIcon text={"Severe"}>
            <SpriteSheetImg iconHeight={32} color={"#aa0000"} imgName={"airep-wx_ICE-S"} />
          </LegendItemIcon>
        </li>
      </ul>
    </div>
  );
};
/**
 * METAR - Current METAR observations
 * CIG - Ceiling
 * VIS - Visibility
 * WX - Weather (thunderstorms, freezing rain)
 * WSpd - Wind speed
 * WGst - Wind gusts
 * */
export const MetarLegend = (): JSX.Element => {
  return (
    <div className={classNames(styles["flex-center-column"], styles["legend-vector-container"])}>
      <MetarSVG />
    </div>
  );
};

export const TafLegend = (): JSX.Element => {
  return (
    <div className={classNames(styles["flex-center-column"], styles["legend-vector-container"])}>
      <TafSVG />
    </div>
  );
};
/**
 * FltCat - Dots showing current flight conditions at that airport
 * */
export const FlatCatLegend = (): JSX.Element => {
  const styleProperties: React.CSSProperties = { fontSize: "0.7em", margin: "0 5px", color: "transparent" };

  return (
    <div>
      <h1>Flt Cat</h1>
      <ul className={styles["legend-list"]}>
        <li>
          <span style={{ ...styleProperties, textShadow: "0 0 0 #1a1dee" }}>&#9899;</span>
          <span>MVFR</span>
        </li>
        <li>
          <span style={{ ...styleProperties, textShadow: "0 0 0 #f82600" }}>&#9899;</span>
          <span>IFR</span>
        </li>
        <li>
          <span style={{ ...styleProperties, textShadow: "0 0 0 #d65bff" }}>&#9899;</span>
          <span>LIFR</span>
        </li>
      </ul>
    </div>
  );
};

import type mapboxgl from "mapbox-gl";

export const TEXT_BACKGROUND_IMG = "rounded";
import textBackgroundImageUri from "./rounded-text-background.png";

export function loadTextBackgroundImage(map: mapboxgl.Map): void {
  if (map.hasImage(TEXT_BACKGROUND_IMG)) {
    return;
  }
  map.loadImage(textBackgroundImageUri, (error, image) => {
    if (error) {
      console.error(error);
      return;
    }
    if (!image) {
      console.error(`Image ${textBackgroundImageUri} could not be loaded.`);
      return;
    }
    // See:
    // https://docs.mapbox.com/mapbox-gl-js/api/map/#map#addimage
    // https://github.com/mapbox/mapbox-gl-js/issues/6856
    map.addImage(TEXT_BACKGROUND_IMG, image, {
      content: [3, 3, 13, 13],
      stretchX: [[7, 9]],
      stretchY: [[7, 9]],
    });
  });
  return;
}

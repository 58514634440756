import type { LayerUnion } from "@/layers";
import type { DrawingLayer } from "@/layers/geojson/drawing/TEMPORAL_TYPE_DRAWING";
import { useActor } from "@xstate/react";
import { useContext } from "react";
import { MapDrawingMasterMachineContext } from "./reactContext";

/**
 * Provides a service. It does NOT re-render on state change.
 */
export function useMapDrawingService() {
  const { mapDrawingService } = useContext(MapDrawingMasterMachineContext);
  return {
    mapDrawingService,
    send: mapDrawingService.send,
  };
}

export function useDrawingLayerDesc(mapId: number): LayerUnion {
  const layer: DrawingLayer = {
    id: -1,
    id_cartographicmap: mapId,
    show: true,
    index: 0,
    kind: "DrawingLayerDescription",
    time_created: "",
    time_updated: "",
    id_profile: -1,
    opacity: -1,
  };

  return layer as LayerUnion;
}

import { type ReactNode, useRef } from "react";
import { type DragObjectWithType, type DragSourceMonitor, useDrag, useDrop } from "react-dnd";

export interface CardProps {
  id: string | number;
  content: ReactNode;
  index: number; // Index of this Card component within the ListContainer.
  itemType: string;
  onHoverNewItem: (dragIndex: number, hoverIndex: number) => void; // Callback to run everytime a dragged item is hovered over other items.
  onDropItem: (dropTargetIndex: number) => void; // Callback to run when an item is dropped. Returns the item index where item was dropped.
}

export function Card(props: CardProps) {
  const ref = useRef<HTMLLIElement>(null);
  const [{ handlerId }, drop] = useDrop<CardProps & DragObjectWithType, any, any>({
    accept: props.itemType,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop(item) {
      props.onDropItem(item.index);
    },
    hover(draggedItem) {
      if (!ref.current) {
        return;
      }
      const dragIndex = draggedItem.index; // Current position of the dragged item.
      const hoverIndex = props.index; // The position of the item hovered by the mouse.
      // Do nothing while user hasn't dragged the item anywhere.
      if (dragIndex === hoverIndex) {
        return;
      }
      // Swap the items within the list internal state so we can correctly render the list in order.
      props.onHoverNewItem(dragIndex, hoverIndex);
      // Move the dragged item to the current hover index in the DnD state.
      draggedItem.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag<any, any, any>({
    item: { type: props.itemType, id: props.id, index: props.index },
    collect(monitor: DragSourceMonitor) {
      // Get status if the user is currently dragging an item to conditionally render the faded visual effect.
      return { isDragging: monitor.isDragging() };
    },
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <li className={"draggable-item"} ref={ref} style={{ opacity }} data-handler-id={handlerId}>
      {props.content}
    </li>
  );
}

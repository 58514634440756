import type { MapStateCoord } from "@/models/map-interaction/mapInteractionStateCtxTypes";
import Tooltip from "@/overlay/components/CommonParts/Tooltip";
import { Button } from "@/overlay/components/CommonParts/buttons";
import { LocationInput } from "@/overlay/components/PoiManagerWindow/LocationInput";
import { MapCoordSelectBtn } from "@/overlay/components/PoiManagerWindow/MapCoordSelectBtn";
import { t } from "@lingui/macro";
import { type FunctionComponent, useState } from "react";
import styles from "./LocationInput/style.module.scss";

type PoiInputProps = {
  mapId: number;
  onApply?: (coord: MapStateCoord) => void;
};

export const PoiInput: FunctionComponent<PoiInputProps> = ({ mapId, onApply }: PoiInputProps) => {
  const [coordinates, setCoordinates] = useState<MapStateCoord>();

  const displayValue = coordinates ? `${coordinates.lat}, ${coordinates.lon}` : "";

  function handleSetCoord(coordinates: MapStateCoord) {
    onApply?.(coordinates);
  }

  function handleApply() {
    if (coordinates) {
      onApply?.(coordinates);
      setCoordinates(undefined);
    }
  }

  return (
    <div className={`editable-variable ${styles["poi-input"]}`}>
      <LocationInput defaultValue={displayValue} editCoord={setCoordinates} />
      <Tooltip direction={"left"} message={t`Add Point`} className={styles.successTooltip}>
        <Button icon="add_circle_outline" onClick={handleApply} disabled={!coordinates} />
      </Tooltip>
      <Tooltip message={t`Locate on map`} direction={"left"} className={styles.successTooltip}>
        <MapCoordSelectBtn mapId={mapId} onCoordChange={handleSetCoord} />
      </Tooltip>
    </div>
  );
};

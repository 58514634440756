import type { FilterAndSorterFormTypes } from "@/dashboard/types";
import { genericSearch } from "@/utility/genericSearch";
import { genericSort } from "@/utility/genericSort";
import type { SlimProfile } from "@mm/metx-workbench.meteomatics.com";

export function sortAndFilter(
  profiles: SlimProfile[],
  query: FilterAndSorterFormTypes["query"],
  activeSorter: FilterAndSorterFormTypes["sort"],
) {
  return profiles
    .filter((profile) => genericSearch<SlimProfile>(profile, ["title"], query))
    .sort((profileA, profileB) => genericSort<SlimProfile>(profileA, profileB, activeSorter));
}

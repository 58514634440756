import { mod } from "@/utility/mod";
import { Area } from "@mm/api.meteomatics.com/lib/models/Area";
import { CoordinateSystem } from "@mm/api.meteomatics.com/lib/models/CoordinateSystem";

export type TileGeometry_Transferable = [number, number, number];

// TODO: make readonly and cache id in constructor
/**
 * A data container to represent the geographical areas of a single map tile.
 */
export class TileGeometry {
  constructor(public readonly xyz: [number, number, number]) {}

  get zoom(): number {
    return this.xyz[2];
  }
  get z(): number {
    return this.xyz[2];
  }
  get x(): number {
    return this.xyz[0];
  }
  get y(): number {
    return this.xyz[1];
  }

  /**
   * Takes a tile in a replicated world and translates it to the same position within
   * world zero
   */
  wrapToWorldZero(): TileGeometry {
    const z = Math.max(this.z, 0); // ignore denormalization
    const tilesPerAxis = 1 << z;

    // wrap replicated worlds into world zero
    const x = mod(this.x, tilesPerAxis);
    // in theory we do not allow wraps into y-worlds, so this should be a unnecessary
    const y = mod(this.y, tilesPerAxis);

    return new TileGeometry([x, y, this.z]);
  }

  isWorldZero(): boolean {
    const wrapped = this.wrapToWorldZero();
    return wrapped.x === this.x && wrapped.y === this.y;
  }

  getWorldIndex(): [number, number] {
    const z = Math.max(this.z, 0); // ignore denormalization
    const tilesPerAxis = 1 << z;
    return [Math.floor(this.x / tilesPerAxis), Math.floor(this.y / tilesPerAxis)];
  }

  /**
   * Ignores geometric placement, e.g. replication, and generates an id that should
   * be unique per tile geometry content.
   */
  toDataId(): string {
    return this.wrapToWorldZero().toId();
  }

  /**
   * Gets an id that is unique per tile geometry.
   *
   * Note that in some cases, you might want to ignore replication since the content
   * of replicated worlds is identical to the original world. See `toDataId` for these cases.
   * @returns
   */
  toId(): string {
    //return [this.z, this.x, this.y].join("/");
    // ┌──────────┬──────────┬───────────┬───────────┐
    // │ 8bit     │ 8bit     │ 8bit      │ 8bit      │
    // │          │          │           │           │
    // │ 0x00     │ tile X   │ tile Y    │ tile zoom │
    // └──────────┴──────────┴───────────┴───────────┘
    const bytes = new Uint8Array([0x00, ...this.xyz]);
    const codepoints = new Uint16Array(bytes.buffer);
    return String.fromCharCode(codepoints[0], codepoints[1]);
  }

  toHumanReadableId(): string {
    return this.xyz.join("/");
  }

  parent(chainLength = 1): TileGeometry {
    const factor = 2 << chainLength;

    const newZoom = this.z - factor;

    if (newZoom < 0) {
      throw new Error(`invalid zoom level ${this.z} - ${chainLength} =${newZoom}`);
    }

    return new TileGeometry([~~(this.x / factor), ~~(this.y / factor), newZoom]);
  }

  /**
   * Get unwrapped spherical mercator region of the tile
   *
   * This uses the web mercator projection (EPSG:3857) with slightly different units:
   * - the size of 1 unit is the width of the projected world instead of the "mercator meter"
   * - the origin of the coordinate space is at the north-west corner instead of the middle
   *
   * @return unwrapped mercator, e.g. the float is structured as `[world].[mercator within world]` and thus
   *         values outside of the unit square `[0,1]^2` may be returned for world copies.
   */
  mercator(zLodBias: number): [number, number, number, number] {
    const biasedZ = Math.max(this.zoom - zLodBias, 0);
    const worldSize = 1 << biasedZ; // Bitwise operation equivalent of "Math.pow(2, biasedZ)".

    return [
      this.xyz[1] / worldSize, // north bound
      this.xyz[0] / worldSize, // west bound
      (this.xyz[1] + 1) / worldSize, // south bound
      (this.xyz[0] + 1) / worldSize, // east bound
    ];
  }

  toTransferable(): TileGeometry_Transferable {
    return this.xyz;
  }

  static fromTransferable(v: TileGeometry_Transferable): TileGeometry {
    return new TileGeometry(v);
  }

  toEpsg3857(): Area<CoordinateSystem.EPSG3857> {
    const mercator = this.mercator(0);

    // Caution: Changing these value can cause a mis-projection and the misalignment of tile data.
    const radiusEarthInMeter = 6378137;
    const earthHalfCircumferenceInMeter = Math.PI * radiusEarthInMeter;
    const earthCircumferenceInMeter = 2 * earthHalfCircumferenceInMeter;

    // note: we have to invert the y axis
    const [north, west, south, east] = [
      // so, either mapbox or epsg.info is wrong concerning the bounds here. but the
      // implementation below matches our old implementation exactly.
      (1.0 - mercator[0]) * earthCircumferenceInMeter - earthHalfCircumferenceInMeter,
      mercator[1] * earthCircumferenceInMeter - earthHalfCircumferenceInMeter,
      (1.0 - mercator[2]) * earthCircumferenceInMeter - earthHalfCircumferenceInMeter,
      mercator[3] * earthCircumferenceInMeter - earthHalfCircumferenceInMeter,
    ];

    return new Area(CoordinateSystem.EPSG3857, { north, west, south, east });
  }
}

const _canvas = document.createElement("canvas");
const _gl = _canvas.getContext("webgl");
if (_gl) {
  _canvas.height = 1;
  _canvas.width = 1;
}

export function webgl1Supports(fn: (context: WebGLRenderingContext) => boolean) {
  return (gl?: WebGLRenderingContext) => {
    const context = gl ?? _gl;

    // does not support webgl and thus does not support any webgl extensions
    if (!context) {
      return false;
    }

    return fn(context);
  };
}

/**
 * Try to enable a set of webgl extension
 * @return `true`, if all extensions could be enabled. `false` if an extension is not supported.
 */
export function webgl1SupportsExtensions(extensionNames: string[]) {
  return webgl1Supports((gl) => {
    let acc = true;
    for (const extensionName of extensionNames) {
      acc = acc && !!gl.getExtension(extensionName);
    }
    return acc;
  });
}

export const supportsFloatTextures = webgl1SupportsExtensions([
  "OES_texture_float",
  "OES_texture_float_linear",
  "WEBGL_color_buffer_float",
  "EXT_float_blend",
]);
export const supportsDerivatives = webgl1SupportsExtensions(["OES_standard_derivatives"]);
export const supportsGpuTiming = webgl1SupportsExtensions(["EXT_disjoint_timer_query"]);

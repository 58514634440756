// Track identifiers may be arbitrary strings and change at runtime. These are just
// some type-safe wrappers for the strings available in metx.

import { enumValues } from "@/utility/enum";
import { DragItemType } from "./DragItemType";

/**
 * Track identifiers may be arbitrary strings and change at runtime. These are just
 * some type-safe wrappers for the strings available in metx.
 */
export enum TrackId {
  /**
   * Vertical, top-aligned, stack of windows on the left side of the screen
   */
  Left = "Left_",
  /**
   * Vertical, top-aligned, stack of windows on the right side of the screen
   */
  Right = "Right_",
  /**
   * Horizontally, center-aligned stack of windows on the bottom of the screen
   */
  Bottom = "Bottom_",
  /**
   * Special Track where only the top most window is rendered and mouse interactions
   * for the whole other GUI disabled.
   *
   * MUST be combined with (DragItemType.ModalWindow) or a window that is not draggable,
   * but managed by a Modal track. Otherwise, the user can convert the modal to
   * a non-modal window by dragging it out of the modal track.
   */
  Modal = "Modal_",
}

export const defaultTrack = TrackId.Left;

export const trackIds: TrackId[] = enumValues(TrackId);
export const trackIdsWithoutModal = trackIds.filter((trackId) => trackId !== TrackId.Modal);

export const allowedDragItemTypes: Record<TrackId, DragItemType[]> = {
  [TrackId.Left]: [DragItemType.NormalWindow, DragItemType.SmallWindow],
  [TrackId.Right]: [DragItemType.NormalWindow, DragItemType.SmallWindow],
  [TrackId.Bottom]: [DragItemType.ThinWindow, DragItemType.SmallWindow],
  [TrackId.Modal]: [DragItemType.ModalWindow],
};

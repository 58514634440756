import { t } from "@lingui/macro";
import { useEnsSelectionContext } from "..";
import styles from "./style.module.scss";

interface ControlsProps {
  onSelectOrRemoveAllQuentilens: (remove: boolean) => void;
  onSelectOrRemoveAllMembers: (remove: boolean) => void;
}

const Controls = ({ onSelectOrRemoveAllMembers, onSelectOrRemoveAllQuentilens }: ControlsProps) => {
  const { allMembersSelected, allQuantilesSelected } = useEnsSelectionContext();

  return (
    <div className={styles.controls}>
      <button
        className={styles["control-button"]}
        onClick={() => {
          onSelectOrRemoveAllQuentilens(allQuantilesSelected);
        }}
        type={"button"}
      >
        {allQuantilesSelected ? t`remove quantile` : t`all quantile`}
      </button>
      <button
        className={styles["control-button"]}
        onClick={() => {
          onSelectOrRemoveAllMembers(allMembersSelected);
        }}
        type={"button"}
      >
        {allMembersSelected ? t`remove member` : t`all member`}
      </button>
    </div>
  );
};

export { Controls };

import { BuildInfoComponent } from "@/build-info";
import { setTitle } from "@/effects";
import { LocalePicker } from "@/i18n/locale-picker";
import * as route from "@/route";
import { t } from "@lingui/macro";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { OAuthState, useAuth } from "../auth/index";
import "./login.scss";

interface LoginPageProps {
  redirectToAfterLogin?: string;
}

/**
 * On component mount, it will redirect the user to the login page
 * at the authorization server login.meteomatics.com.
 */
export default function LoginPage({ redirectToAfterLogin }: LoginPageProps) {
  const auth = useAuth();
  const location = useLocation();
  const from: string = redirectToAfterLogin ?? (location.state as any)?.from.pathname ?? route.defaultRoute.asString();

  useEffect(() => {
    setTitle(t({ id: "login.title", message: "Login" }));

    auth
      .signin(from)
      .then((session) => {
        if (session.state === OAuthState.AuthorizationCode && session.authorizationUrl) {
          return session.authorizationUrl;
        }
      })
      .then((authUrl) => {
        if (authUrl) {
          // Redirect to the login.meteomatics.com.
          window.location.assign(authUrl);
        }
      });
    // NOTE: Run login redirection once on component mount.
    // useAuth() returns a new object on every render, so adding auth object to the deps array will
    // trigger numerous requests to the login redirection endpoint, which results in numerous redirect responses.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from]);

  return (
    <div className="page page--login cover-window background--primary">
      <LocalePicker />
      <mm-loader size="large">{t`Taking you to the login page...`}</mm-loader>
      <BuildInfoComponent copyright />
    </div>
  );
}

import type { DragableArea } from "@/overlay/components";
import { clamp } from "lodash";

/**
 * Clamps the position coordinates to the draggable area boundaries.
 *
 * @param {number} x - The x-coordinate position
 * @param {number} y - The y-coordinate position
 * @param {DragableArea} param - The draggable area boundaries
 * @return {{ x: number, y: number }} The clamped position coordinates
 */
export const clampPositionToDraggableArea = (
  x: number,
  y: number,
  { top, left, bottom, right }: DragableArea,
): { x: number; y: number } => {
  return {
    x: clamp(x, left || 0, right || document.body.clientWidth),
    y: clamp(y, top || 0, bottom || document.body.clientHeight),
  };
};

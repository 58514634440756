import type { GuiTimeZone } from "@mm/metx-workbench.meteomatics.com";
import { DateTime } from "luxon";
import { type Dispatch, type MutableRefObject, useCallback, useEffect, useRef, useState } from "react";

/**
 * Set up an interval that keeps running for every interval.
 * Passing null to the interval delay will clear the interval.
 * @param callback
 * @param delayInMilliSecs
 */

export function useInterval(callback: () => void, delayInMilliSecs: number | null) {
  const savedCallback = useRef<() => void>();
  const savedIntervalId = useRef<NodeJS.Timeout>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => {
      savedCallback.current?.();
    };
    if (delayInMilliSecs !== null) {
      savedIntervalId.current = setInterval(tick, delayInMilliSecs);
      return () => savedIntervalId.current && clearInterval(savedIntervalId.current);
    }
    savedIntervalId.current && clearInterval(savedIntervalId.current);
  }, [delayInMilliSecs]);
}

/**
 * Set up a timeout that runs once.
 * Passing null to the timeout delay will clear the timeout.
 * @param callback
 * @param delayInMilliSecs
 */
export function useTimeout(callback: () => void, delayInMilliSecs: number | null) {
  const savedCallback = useRef<() => void>();
  const savedTimeoutId = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the timeout.
  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }
    if (delayInMilliSecs !== null) {
      savedTimeoutId.current = setTimeout(tick, delayInMilliSecs);
    } else {
      savedTimeoutId.current && clearTimeout(savedTimeoutId.current);
    }
    return () => {
      savedTimeoutId.current && clearInterval(savedTimeoutId.current);
    };
  }, [delayInMilliSecs]);
}

/**
 * Similar to useState, it initializes the state value using the passed observedState,
 * but it also updates the state as the observedState changes by **reference**.
 * @param observedState
 * @returns [state: T, setState: Dispatch<React.SetStateAction<T>>]
 *
 *
 * This can replace a common pattern to update the state based on a prop value like the following.
 * ```
 * const { propValue } = props;
 * const [value, setValue] = useState(propValue);
 * useEffect(()=>{
 *      setValue(propValue)
 * }, [propValue])
 * ```
 */
export function useObserverState<T>(observedState: T) {
  const [state, setState] = useState<T>(observedState);
  useEffect(() => {
    setState(observedState);
  }, [observedState]);
  return [state, setState] as [T, Dispatch<React.SetStateAction<T>>];
}

/**
 * Runs a callback every beginning of minute by clock.
 */
export function useClockInterval(callback: () => any, stop = false) {
  const savedCallback = useCallback(callback, [callback]);
  const savedTimeoutId = useRef<NodeJS.Timeout>();

  const setNextTimeout = useCallback(() => {
    // Clear the previous timer
    savedTimeoutId.current && clearTimeout(savedTimeoutId.current);

    const time = new Date();
    const secsBeforeNextMinute = (60 - time.getSeconds()) * 1000;
    savedTimeoutId.current = setTimeout(() => {
      savedCallback();
      setNextTimeout();
    }, secsBeforeNextMinute);
  }, [savedCallback]);

  useEffect(() => {
    if (stop) {
      savedTimeoutId.current && clearTimeout(savedTimeoutId.current);
      return;
    }
    // Start the clock interval.
    setNextTimeout();
    return () => {
      savedTimeoutId.current && clearTimeout(savedTimeoutId.current);
    };
  }, [stop, setNextTimeout]);
}

export function useCurrentDateTime(timezone: GuiTimeZone): DateTime {
  const [currDateTime, setCurrDateTime] = useState<DateTime>(DateTime.utc().setZone(timezone));
  useClockInterval(() => {
    setCurrDateTime(DateTime.utc().setZone(timezone));
  });
  return currDateTime;
}

export function useOutsideClick(ref: MutableRefObject<HTMLElement | null>, onClickOutside: () => void) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClickOutside();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onClickOutside]);
}

export function usePrevious<T>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

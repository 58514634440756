export type NoAbort<T> = {
  kind: "Exec";
  payload: T;
};

export type Abort = {
  kind: "Abort";
  reason?: any;
};

export type Abortable<T> = Abort | NoAbort<T>;

export function abort<T>(reason?: any): Abort {
  return { kind: "Abort", reason };
}
export function execute(payload?: undefined): NoAbort<void>;
export function execute<T>(payload: T): NoAbort<T>;
export function execute(payload: any): NoAbort<any> {
  return { kind: "Exec", payload };
}

export function isAborted(v: Abortable<any>): v is Abort {
  return v.kind === "Abort";
}

export function isAbortable(v: any): v is Abortable<any> {
  return v && Object.hasOwnProperty.call(v, "kind") && (v.kind === "Exec" || v.kind === "Abort");
}

export function isAbortedRequest(v: any) {
  return v && Object.hasOwnProperty.call(v, "payload") && v.payload.name === "AbortError";
}

import { WindowKind, instanceId, useDesktop } from "@/overlay/components";
import { DatetimeLabel } from "@/overlay/components/CommonParts/DatetimeLabel";
import { DeleteConfirmationTrigger } from "@/overlay/components/CommonParts/DeleteConfirmationTrigger";
import { getSortedTabs } from "@/overlay/components/TabSwitcher/utils";
import { pageWorkspace, startsWithExtraPath } from "@/route";
import { t } from "@lingui/macro";
import { GuiTimeZone, type SlimProfile } from "@mm/metx-workbench.meteomatics.com";
import { DateTime } from "luxon";
import type React from "react";
import { NavLink, useRouteMatch } from "react-router-dom";
import "./style.scss";

export interface DashboardCardProps {
  profile: SlimProfile;
  /**
   * Render additional controls and meta data. Enable if a lot of space is available.
   */
  maximized?: boolean;
}

export function DashboardCard(props: DashboardCardProps) {
  const match = useRouteMatch();
  const desktop = useDesktop();
  const { maximized } = props;
  const { id, title, time_created, time_updated } = props.profile;

  const handleRenameDashboard = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();

    desktop.dispatch({
      type: "toggleWindowVisibility",
      windowId: instanceId(WindowKind.RenameDashboard),
    });
    desktop.dispatch({
      type: "setWindowCustomProps",
      windowId: instanceId(WindowKind.RenameDashboard),
      windowKind: WindowKind.RenameDashboard,
      customProps: { profile: props.profile },
    });
  };
  const orderedTabs = getSortedTabs(props.profile.tabs);

  return (
    <div className="dashboard-parent__card">
      <NavLink
        style={{ textDecoration: "none", color: "inherit" }}
        className="dashboard__card dashboard__card--maximized dashboard__card__current-tab"
        data-testid={"profile-card"}
        to={`${startsWithExtraPath(match.path)}${pageWorkspace.asString(
          id,
          props.profile.tab_active || props.profile.tabs[0].id,
        )}`}
      >
        <div className="dashboard__card__title" data-testid={"dashboard-card-title"}>
          {title}
        </div>
        {maximized ? (
          <>
            <div className="dashboard__card__metadata">
              <span className="card-info">
                <span className="card-info__label">Last Edited</span>
                <span className="card-info__value">
                  <DatetimeLabel datetime={DateTime.fromISO(time_updated)} timezone={GuiTimeZone.local} />
                </span>
              </span>
              <span className="card-info">
                <span className="card-info__label">Creation Date</span>
                <span className="card-info__value">
                  <DatetimeLabel datetime={DateTime.fromISO(time_created)} timezone={GuiTimeZone.local} />
                </span>
              </span>
            </div>

            <div className="dashboard__card__actions">
              <div className="dashboard__card__actions__grid">
                {/* biome-ignore lint/a11y/useKeyWithClickEvents: no a11y yet */}
                <mm-menu-button
                  iconname="edit"
                  onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleRenameDashboard(event)}
                />
                <DeleteConfirmationTrigger
                  element={{
                    type: "profile",
                    item: props.profile,
                    label: t`Do you want to delete profile "${title}"?`,
                  }}
                >
                  <mm-menu-button iconname="delete" />
                </DeleteConfirmationTrigger>
              </div>
            </div>
          </>
        ) : null}
      </NavLink>
      <div className="dashboard__tabs">
        {orderedTabs.map((tab) => (
          <NavLink
            key={tab.id}
            data-testid="dashboard-tab-link"
            className="dashboard__tabs__link"
            to={`${startsWithExtraPath(match.path)}${pageWorkspace.asString(id, tab.id)}`}
          >
            {tab.is_favorite && "★ "}
            {tab.title ?? t`(unnamed)`}
          </NavLink>
        ))}
      </div>
    </div>
  );
}

import { Area, type Area_Transferable, type CoordinateSystem } from "@mm/api.meteomatics.com";

/**
 * The amount of time to wait after an initial network failure when downloading a tile.
 * The second attempt for the tile request will be delayed by the given amount of milliseconds
 * and doubled with each subsequent failure.
 */
export const NETWORK_EXPONENTIAL_BACKOFF_INITIAL_DELAY_MS: number = 500;

/**
 * Make at most the given amount of retries.
 *
 * MAY be set to `Infinity`.
 */
export const NETWORK_EXPONENTIAL_BACKOFF_MAX_RETRIES: number = 8;

export enum FailureState {
  FailedPermanently = 0,
  FailedTemporarily = 1,
}
export interface RetryState {
  /**
   * It may happen for several reasons, that the initial model bounds are not correct. (The model bounds
   * stored in a weather model description give an upper ceiling: at most the specified bounds are available).
   *
   * Reasons for actually smaller model bounds are:
   * - incomplete ingestion (so the model box is time dependent),
   * - smaller area for some parameters in the mix model (for example land_usage:idx is only available in europe).
   *
   * This check may recurse a few times because of how the backend checks model bounds.
   */
  modelBounds?: Area<CoordinateSystem.WGS84>;
  /**
   * Count of previously, failed attempts.
   */
  failedAttempts: number;
  /**
   * Retry delay.
   *
   * Implements exponential backoff for network issues.
   *
   * Reasons may include:
   * - exceeding the hard parallel limit because a second tab is active.
   * - temporary networking issues, e.g. "the train I am working in enters a tunnel"
   */
  delayMs: number;
  failureState?: FailureState;
}

export interface RetryState_Transferable {
  modelBounds?: Area_Transferable<CoordinateSystem.WGS84>;
  failedAttempts: number;
  delayMs: number;
  failureState?: FailureState;
}

export function createRetryState(modelBounds?: Area<CoordinateSystem.WGS84>): RetryState {
  return {
    failedAttempts: 0,
    delayMs: NETWORK_EXPONENTIAL_BACKOFF_INITIAL_DELAY_MS,
    modelBounds,
  };
}

export function RetryState_toTransferable(retryState: RetryState): RetryState_Transferable {
  return {
    ...retryState,
    modelBounds: retryState.modelBounds?.toTransferable(),
  };
}

export function RetryState_fromTransferable(retryState: RetryState_Transferable): RetryState {
  return {
    ...retryState,
    modelBounds: retryState.modelBounds ? Area.fromTransferable(retryState.modelBounds) : undefined,
  };
}

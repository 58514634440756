import { Api } from "@/api/Api";
import { LayoutType, type LocationTableCreate } from "@mm/metx-workbench.meteomatics.com";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const createLocationTable = createAsyncThunk(
  "api/location-table/create",
  async (payLoad: { data: LocationTableCreate; layoutId: number }, _thunkAPI) => {
    return await Api.locationTable
      .v2CreateLocationTable({
        locationTableCreate: payLoad.data,
      })
      .then((table) => {
        return { tool: table, layoutType: LayoutType.LocationTable, layoutId: payLoad.layoutId };
      });
  },
);

import type { NoteSchema } from "@mm/metx-workbench.meteomatics.com";
import { Suspense, lazy, memo } from "react";

export type GridNoteProps = {
  note: NoteSchema;
};

const NoteViewLazy = memo(
  lazy(() =>
    import(/* webpackChunkName: "noteView" */ "@/noteView/index")
      .then(({ NoteView }) => ({ default: NoteView }))
      .catch(() => {
        console.error("error");
        return { default: () => <div>Loading Note View failed</div> };
      }),
  ),
);

export function GridNote({ note }: GridNoteProps): JSX.Element {
  return (
    <Suspense fallback={<mm-loader size="large" />}>
      <NoteViewLazy note={note} />
    </Suspense>
  );
}

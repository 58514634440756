export const lightningColorRange: string[] = ["#ffffe8", "#FFFF00", "#FF7F00", "#ff5602", "#ff0000", "#bf0101"];

export const getLightningLabels = (lightningStepMin: number): string[] =>
  lightningColorRange.map((_color, index) => `${(index + 1) * lightningStepMin} min`);

export const DEFAULT_LIGHTNING_STEP = 20;
export const DEFAULT_LIGHTNING_INTERVAL = 120;

export const getLightningStep = (param: string): number => {
  let minutes = 120;
  const match = param.match(/_(\d+)$/);
  if (match) {
    minutes = Number(match[1]);
  }
  return minutes / lightningColorRange.length;
};

/**
 * Determines how a grid points should be placed on a geographic area `Area`.
 */
export type GridSamplingStrategy =
  | { kind: GridSamplingStrategyKind.Point | GridSamplingStrategyKind.Area }
  | { kind: GridSamplingStrategyKind.AreaSample; sample: [number, number] };

export enum GridSamplingStrategyKind {
  /**
   * @type {GridSamplingStrategyKind.Point}:
   *
   * Grid points are taken as is. So for example, if you are requesting a grid
   * with width 2 and height 2 of the whole world, which corresponds to
   * the mercator coordinates `[0,1]^2`, you would get values for the
   * points: `[{0,0},{0,1},{1,0},{1,1}]`, which are the corner points
   * of the `Area` object.
   */
  Point,

  /**
   * @type {GridSamplingStrategyKind.Area}:
   *
   * Grid points are interpreted as cells. So for example, if you are requesting a grid
   * with width 2 and height 2 of the whole world, which corresponds to
   * the mercator coordinates `[0,1]^2`, your request would be interpreted as "give me
   * a representative value for the area of each quadrant". This is the correct type
   * of request if you subsequently plan to display the data as an image or as a grid
   * of symbols.
   *
   * In its current implementation, this will return the center point of each cell.
   * So in our example, you would get values for the
   * points: `[{.25,.25},{.25,.75},{.75,.25},{.75,.75}]`
   *
   * Shorthand for `{ kind: GridSamplingStrategyKind.AreaSample; sample: [0.5,0.5] }`
   */
  Area,
  /**
   * Grid points are interpreted as cells. The area of each cell is given by the
   * uv-coordinates [0,1]^2. Pick a sample at position `sample` within this uv-coordinates.
   * the corners of the uv-coordinates system are at the bottom left `[0,0]` and top right
   * `[1,1]`.
   */
  AreaSample,
  // TODO: this would be the correct place to add a JitteredArea kind for temporal anti aliasing
}

import { useValueNameCreator } from "@/overlay/components/SmartForm/SmartFormUtils/FormHooks/useFormInstructions";
import type { InputInstruction } from "@/overlay/components/SmartForm/SmartFormUtils/commonTypeUtils";
import { FormField } from "@/overlay/components/form-field";
import { t } from "@lingui/macro";
import type { ModelSchema } from "@mm/api-layers.meteomatics.com";

type ModelSelectProps = {
  instruction: InputInstruction<string | undefined>;
  availableModels: ModelSchema[];
  isCreationMode?: boolean;
};

/**
 * Shape of the data the input component will send on the form hook.
 */
export type ModelSelectData = ModelSchema["identifier"];

/**
 * When no model is pre-selected, returns a default model to be selected in the dropdown.
 */
function getFallbackModelIdentifier(availableModels: ModelSchema[]): ModelSchema["identifier"] {
  const hasMix = availableModels.reduce((_hasMix, curr) => {
    if (curr.identifier === "mix") {
      return true;
    }
    return _hasMix;
  }, false);
  return hasMix ? "mix" : availableModels[0].identifier;
}

export function ParamModelSelect(props: ModelSelectProps) {
  const { availableModels, instruction, isCreationMode } = props;
  const {
    formMethods: { register },
    currValue,
  } = instruction;
  const { createInputName } = useValueNameCreator<ModelSelectData>(instruction);

  if (availableModels.length === 0) {
    return <></>;
  }

  // If the value is not selected, take the first model as selected.
  const value = currValue ? currValue : getFallbackModelIdentifier(availableModels);

  // Future note: To filter out certain models, we should use paramGroupFilter.
  return (
    <FormField label={t`Source`}>
      <select
        defaultValue={isCreationMode ? value : undefined}
        value={!isCreationMode ? value : undefined}
        {...register(`${createInputName()}`)}
      >
        {availableModels.map((model) => (
          <option key={model.identifier} value={model.identifier}>
            {model.name}
          </option>
        ))}
      </select>
    </FormField>
  );
}

import { flatten } from "lodash";
import type { Expression } from "mapbox-gl";
import { COLOR_HAZARD, COLOR_HAZARD_DEFAULT } from "./styles";

// Vector tiles style has different Windbarb icons names, than in our icon library
// so we need to map them together
const WINDBARB_ICON_MAP = {
  Wind_barb_0: "n0",
  Wind_barb_1: "n5",
  Wind_barb_2: "n10",
  Wind_barb_3: "n15",
  Wind_barb_4: "n20",
  Wind_barb_5: "n25",
  Wind_barb_6: "n30",
  Wind_barb_7: "n35",
  Wind_barb_8: "n40",
  Wind_barb_9: "n45",
  Wind_barb_10: "n50",
};

export const constructWindBarbsIconMapExpression = (): Expression => {
  const expressions: Expression = ["match", ["get", "wind_barb"]];

  for (const [sourceIconName, targetIconName] of Object.entries(WINDBARB_ICON_MAP)) {
    expressions.push(sourceIconName, targetIconName);
  }

  // And empty string as default icon meaning mapbox will not display anything
  expressions.push("");

  return expressions;
};

export const convertRange = (value: number, r1: [number, number], r2: [number, number]) => {
  return ((value - r1[0]) * (r2[1] - r2[0])) / (r1[1] - r1[0]) + r2[0];
};

export const constructSigmetHazardColorExpression = (): Expression => {
  const colorMap = flatten(Object.entries(COLOR_HAZARD));
  return ["match", ["get", "hazard_type"], ...colorMap, COLOR_HAZARD_DEFAULT];
};

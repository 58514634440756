import { BaseResponse } from "./BaseResponse";
import { Timer } from "../performanceIntrospection/Timer";
import { CoordinateSystem } from "./CoordinateSystem";
import { GridRequest, GridRequest_Transferable } from "./GridRequest";
import { Area } from "./Area";
import { DateTime_fromTransferable, DateTime_toTransferable } from "./DateTime";
import { ColorMap } from "./ColorMap";
import { JSONResponseBody } from "./JSONGridResponse";

export interface WindVectorRequest<C extends CoordinateSystem> extends GridRequest<C> {
  appliedStyle?: string;
}

export interface WindVectorRequest_Transferable<C extends CoordinateSystem> extends GridRequest_Transferable<C> {
  appliedStyle?: string;
  calibrated?: boolean;
  vertical_interpolation?: "none" | "downscale";
}
export function WindVectorRequest_toTransferable<C extends CoordinateSystem>(
  req: WindVectorRequest<C>,
): WindVectorRequest_Transferable<C> {
  return {
    ...req,
    area: req.area.toTransferable(),
    datetime: DateTime_toTransferable(req.datetime),
    boundingBoxLimit: req.boundingBoxLimit ? req.boundingBoxLimit.toTransferable() : null,
  };
}

export function WindVectorRequest_fromTransferable<C extends CoordinateSystem>(
  req: WindVectorRequest_Transferable<C>,
): WindVectorRequest<C> {
  return {
    ...req,
    area: Area.fromTransferable(req.area),
    datetime: DateTime_fromTransferable(req.datetime),
    boundingBoxLimit: req.boundingBoxLimit ? Area.fromTransferable(req.boundingBoxLimit) : null,
  };
}

export interface WindVectorResponse<C extends CoordinateSystem>
  extends BaseResponse<WindVectorRequest<C>, ImageBitmap> {
  request: WindVectorRequest<C>;
  data: ImageBitmap;
  timer: Timer;
}

export interface WindVector2JsonResponse<C extends CoordinateSystem>
  extends BaseResponse<WindVectorRequest<C>, JSONResponseBody> {
  request: WindVectorRequest<C>;
  data: JSONResponseBody;
  timer: Timer;
}

import type * as MeteomaticsLayers from "@mm/api-layers.meteomatics.com";
import { WeatherParamSearchEngine } from "./WeatherParamSearchEngine";
import type { MsgFromWorker, MsgReplaceSearchCorpus, MsgRequestSearchResults, ResultValidity } from "./model/Msg";
import type { HasLayerIdxNarrowing, PartialWeatherParameter } from "./model/PartialWeatherParameter";
import type { ExactMatchResult, SearchResults } from "./model/SearchResults";
import type { WeatherParamSearchOptions } from "./model/WeatherParamSearchOptions";
import { defaultSearchOptions } from "./model/WeatherParamSearchOptions";

export interface SearchEngineResult {
  results: SearchResults | null;
  validity: ResultValidity;
}

const MAX_IN_FLIGHT_PROMISES = Number.MAX_SAFE_INTEGER;

/**
 * Parameter search engine runs in parallel using a web worker.
 */
export class WeatherParamSearchParallel {
  private pending: Map<number, { resolve: (value: SearchEngineResult) => void; reject: (reason?: any) => void }> =
    new Map();
  private nextId = 0;
  private searchOptions: WeatherParamSearchOptions | null;
  private readonly worker: Worker;
  /**
   * This weather param search instance is defined only to provide methods that are yet to be implemented in web worker.
   * Once all the methods in WeatherParamSearch can be provided by this class, you can delete this instance.
   */
  private weatherParamSearch_TEMPORAL: WeatherParamSearchEngine;
  public label = "WeatherParamSearchParallel";

  constructor(searchOptions: WeatherParamSearchOptions = defaultSearchOptions) {
    const worker = new Worker(new URL(`${__dirname}/WeatherParamSearch.worker.js`, import.meta.url), {
      type: "module",
    });
    this.worker = worker;
    this.searchOptions = searchOptions;
    this.weatherParamSearch_TEMPORAL = new WeatherParamSearchEngine([], defaultSearchOptions);
    this.worker.onmessage = this.multiplexMessageFromSearchEngineWorkerThread.bind(this);
  }

  private generateId() {
    this.nextId = (this.nextId + 1) % MAX_IN_FLIGHT_PROMISES;
    return this.nextId;
  }

  /**
   * Perform a regular, prefix search and a fuzzy full text corpus search on all available weather parameters.
   *
   * Common use cases:
   * - pass the name of a weather parameter like `t_2m:C` for parsing.
   * - pass a prefix of a weather parameter for typeahead or autocompletion. For example `t_2`
   * - pass some term, which might contain typos, to search through the parameter documentation.
   *   For example `Temperature`.
   *
   *
   * @return a ranked list of matching parameters, `null` if the search is not yet initialized
   * @param searchQuery
   * @param searchOptions
   */
  search(searchQuery: string, searchOptions?: WeatherParamSearchOptions): Promise<SearchEngineResult> {
    const usedSearchOptions = searchOptions ?? this.searchOptions ?? defaultSearchOptions;

    const id = this.generateId();

    const msg: MsgRequestSearchResults = {
      tag: "RequestSearchResults",
      searchQuery,
      searchOptions: usedSearchOptions,
      validity: { id },
    };

    return new Promise((resolve, reject) => {
      this.pending.set(id, { resolve, reject });
      this.worker.postMessage(msg);
    });
  }

  multiplexMessageFromSearchEngineWorkerThread(event: MessageEvent<MsgFromWorker>) {
    const msg = <MsgFromWorker>event.data;

    switch (msg.tag) {
      case "SearchResults": {
        const result = { results: msg.results, validity: msg.validity };
        const pendingMessage = this.pending.get(msg.validity.id);
        if (pendingMessage) {
          pendingMessage.resolve(result);
        }
        // TODO: this is missing a this.pending.remove(msg.id)???
        return;
      }
      default: {
        const _ensure_switch_is_exhaustive: never = msg.tag;
        return _ensure_switch_is_exhaustive;
      }
    }
  }

  replaceCorpus(newParameters: MeteomaticsLayers.CrossModelParameterSpecification[]) {
    const msg: MsgReplaceSearchCorpus = {
      tag: "ReplaceSearchCorpus",
      newParameters,
    };
    this.worker.postMessage(msg);

    // TODO: Once other match methods are implemented using web worker, we can delete the following code
    // and stop using the instance of the WeatherParamSearch
    this.weatherParamSearch_TEMPORAL.replaceCorpus(newParameters);
  }

  /**
   * Parse a serialized parameter.
   *
   * @param searchQuery
   * @return all matching parameters. Some parameters like `t_2m:C` are not unique and are described
   * by a range-based layer and a explicitly listed layer at the same time.
   */
  matchParameterNameExactly(
    searchQuery: string,
    maxResults: number = Number.POSITIVE_INFINITY,
  ): HasLayerIdxNarrowing<PartialWeatherParameter>[] {
    // TODO: Replace the following method call with worker message so this method can execute in a web worker.
    return this.weatherParamSearch_TEMPORAL.matchParameterNameExactly(searchQuery, maxResults);
  }

  /**
   * Like `matchParameterNameExactly` but halts after the first match
   */
  matchSomeParameterNameExactly(searchQuery: string): HasLayerIdxNarrowing<PartialWeatherParameter> | null {
    // TODO: Replace the following method call with worker message so this method can execute in a web worker.
    return this.weatherParamSearch_TEMPORAL.matchSomeParameterNameExactly(searchQuery);
  }

  /**
   * Exactly match a parameter prefix. This means `t_2` will as if `t_2m:C` is given.
   *
   * @param searchQuery
   * @return all matching parameters (some like `t_2m:C` are not unique).
   */
  matchParameterPrefixExactly(searchQuery: string): ExactMatchResult[] {
    // TODO: Replace the following method call with worker message so this method can execute in a web worker.
    return this.weatherParamSearch_TEMPORAL.matchParameterPrefixExactly(searchQuery);
  }
}

import { realize_relative_datetime_position } from "@/time";
import {
  type DateTimeDescCreate,
  RelativeDateTimePosition,
  RelativeDateTimeRoundingDirection,
} from "@mm/metx-workbench.meteomatics.com";
import { DateTime, Duration } from "luxon";

/**
 * Note: We currently only support the following relative time as display time:
    1. (Current time) - 15mins
    2. After (1), round to the nearest 15 mins backward.

    @deprecated
 */
export const defaultDateTimeDesc: DateTimeDescCreate = {
  is_relative: true,
  is_series: false,
  is_auto_time_refresh_on: false,
  abs_start:
    realize_relative_datetime_position(
      RelativeDateTimePosition.now_with_15min_precision,
      DateTime.utc(),
      RelativeDateTimeRoundingDirection.backward,
    )
      .toUTC()
      .toISO() || "",
  abs_end:
    realize_relative_datetime_position(
      RelativeDateTimePosition.now_with_15min_precision,
      DateTime.utc().plus(Duration.fromObject({ days: 1 })),
      RelativeDateTimeRoundingDirection.backward,
    )
      .toUTC()
      .toISO() || "",
  rel_rounding_on: true,
  rel_position: RelativeDateTimePosition.now_with_15min_precision,
  rel_rounding_direction: RelativeDateTimeRoundingDirection.backward,
  rel_shift_on: true,
  rel_start: "PT-15M",
  rel_end: "P1D",
  temporal_resolution: "PT3H",
  fps: 10,
};

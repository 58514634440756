import { useCurrTimeState } from "@/models/time-control/timeStateHooks";
import { PrefetchPool } from "@/prefetch-controller/PrefetchPool";
import { useActiveLayerPropsPrefetchState, useViewportPrefetchState } from "@/reducer/selectors/PrefetchStateSelectors";
import { Interval } from "luxon";
import { type ReactNode, useEffect } from "react";

type PrefetchControllerProps = {
  children?: ReactNode;
};

/**
 * A component that triggers the prefetcher pool at the right timing
 * (layer change, time state change, viewport change etc)
 */
export function PrefetchController({ children }: PrefetchControllerProps) {
  const viewportChanged = useViewportPrefetchState();
  const activeLayersPropsChanged = useActiveLayerPropsPrefetchState();
  const timeState = useCurrTimeState();
  const { temporalResolution, startTime, endTime } = timeState.matches("ANIMATION")
    ? timeState.context.modeOpts
    : { temporalResolution: null, startTime: null, endTime: null };

  useEffect(() => {
    let prefetchPool: PrefetchPool;
    // When the time state enters animation, trigger the prefetch.
    if (timeState.matches("ANIMATION") && activeLayersPropsChanged && viewportChanged && temporalResolution) {
      const interval = Interval.fromDateTimes(startTime, endTime);

      prefetchPool = new PrefetchPool(interval, temporalResolution);
      prefetchPool.processPool();
    }
    return () => {
      if (prefetchPool) {
        prefetchPool.dispose();
      }
    };
  }, [temporalResolution, startTime, endTime, activeLayersPropsChanged, viewportChanged]);

  return <>{children}</>;
}

import { useAppDispatch } from "@/reducer";
import { addTephigram, useTephigram } from "@/reducer/TephigramState";
import type { TephigramSchema } from "@mm/metx-workbench.meteomatics.com";
import { Suspense, lazy, memo, useEffect } from "react";

export type GridTephigramProps = {
  tephigram: TephigramSchema;
};

export const TephigramViewLazy = memo(
  lazy(() =>
    import(/* webpackChunkName: "tephigramView" */ "@/tephigramView/index")
      .then(({ TephigramView }) => ({ default: TephigramView }))
      .catch((e) => {
        console.error(e);
        return { default: () => <div>Loading Tephigram View failed</div> };
      }),
  ),
);

export function GridTephigram({ tephigram }: GridTephigramProps) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(addTephigram(tephigram));
  }, [dispatch, tephigram]);

  const tephigramFromRedux = useTephigram(tephigram.id);

  if (!tephigramFromRedux) return null;

  return (
    <Suspense fallback={<mm-loader size="large" />}>
      <TephigramViewLazy tephigram={tephigramFromRedux} />
    </Suspense>
  );
}

import { useMemo } from "react";
import type { InputInstruction, PathOrUndefined } from "../commonTypeUtils";

function inputValueName(valuePrefix: string | null) {
  return (inputValNameSuffix?: string) => {
    if (!inputValNameSuffix) {
      return `${valuePrefix}`;
    }
    return `${valuePrefix}.${inputValNameSuffix}`;
  };
}

export function useValueNameCreator<InputDataType>(instruction: Pick<InputInstruction<any>, "formValueName">): {
  /**
   * Generates the input name string according to the input instruction.
   *
   * To send the value in a nested object, you can
   * 1. Set the shape of the object to the `useValueNameCreator` hook.
   * 2. Pass a dotted string notation of the key.
   *
   * The purpose of the function is to generate a input value name within a child input
   * component, based on the input instruction.
   * which allows the parent component to easily handle the input data.
   *
   * To be used with react-hook-form.
   *
   * An input component shoud create the value name using this function.
   */
  createInputName: (nestedValueAttrName?: PathOrUndefined<InputDataType>) => string;
} {
  const { formValueName } = instruction;
  const result = useMemo(
    () => ({
      createInputName: inputValueName(formValueName),
    }),
    [formValueName],
  );
  return result;
}

import { BARBS_PARAMETERS } from "@/constants/barbsParameters";
import type { WeatherOptionFilterFlag } from "@/utility/weather-param-config/paramFilterTypes";
import { cloneDeep } from "lodash";
import Logger from "logging";
import type { HasLayerIdxNarrowing, PartialWeatherParameter, WeatherParamFormat } from "weather-parameter-utils";

const logger = Logger.fromFilename(__filename);

/**
 * A function to filter LayerGroupModel's weather option depending on the kind of layer.
 * In other words, it filters out the options in parameter unit format.
 * Used to hide weather options that are unavailable for a particular kind of layers.
 * @param option
 * @param filterFlag
 *
 * @deprecated
 * As we migrate to react-hook-form, we are rewrting the option filter.
 * Please add new functionality to metx-core/src/utility/weather-param-config
 */
export function weatherParamOptionFilter(
  option: WeatherParamFormat,
  filterFlag: WeatherOptionFilterFlag | undefined,
): WeatherParamFormat {
  if (!filterFlag) {
    return option;
  }
  // Deep clone the options so the original options don't get modified.
  const updatedOption = cloneDeep(option);

  // WARNING:
  //  the union typing on the options variable means that
  //  the editor doesn't suggest the property on options object during runtime.
  //  Insert "if ("property" in updatedOptions)" check to access the property.
  //  Note: Only "format" and "unit" are currently the common property.
  try {
    switch (filterFlag) {
      case "BarbsLayerDescription": {
        if ("unit" in updatedOption && "format" in updatedOption) {
          // For wind and wave barbs layers.
          for (const barbsParameter of BARBS_PARAMETERS) {
            if (updatedOption.format.startsWith(barbsParameter)) {
              updatedOption.unit = updatedOption.unit.filter((unitOption) => unitOption === "kn");
            }
          }
        }
        break;
      }
    }
    return updatedOption;
  } catch (err) {
    logger.error(
      `Error ocurred while trying to filter out some weather options for ${filterFlag} with the format "${option.format}".\n`,
      err,
    );
    return option;
  }
}

/**
 * A function to get all layers format idx from LayerGroupModel's weather option depending on the kind of layer
 * that aren't supported because there are i.e. a format has only unit ["idx"] which not a speed parameter.
 * Used to hide this format options that are unavailable for a particular kind of layers.
 * @param parameter
 * @param filterFlag
 *
 * The return value represents the index values in `LayerGroupModel.layers`
 *
 * @deprecated
 * As we migrate to react-hook-form, we are rewrting the option filter.
 * Please add new functionality to metx-core/src/utility/weather-param-config
 */
export function weatherParamOptionLayersIdxToFilter(
  parameter: HasLayerIdxNarrowing<PartialWeatherParameter>,
  filterFlag: WeatherOptionFilterFlag | undefined,
): number[] {
  if (!filterFlag) {
    return [];
  }
  const layers = parameter.parameter.layers;
  switch (filterFlag) {
    case "BarbsLayerDescription": {
      return (
        layers
          .map((layer, i) => {
            if ("unit" in layer && "format" in layer) {
              // For all parameter inside wind barbs layers.
              for (const barbsParameter of BARBS_PARAMETERS) {
                if (layer.format.startsWith(barbsParameter)) {
                  return !layer.unit.includes("kn") ? i : -1;
                }
              }
              return -1;
            }
            return -1;
          })
          // Remove everything that is not -1
          .filter((index) => index !== -1)
      );
    }
  }
  return [];
}

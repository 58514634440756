import { type DependencyList, useCallback, useRef } from "react";

/**
 * Custom React hook that memoizes an asynchronous function and ensures the Promise is only created once.
 *
 * @template Args - The types of the arguments that the asynchronous function accepts.
 * @template ReturnType - The type of the value that the Promise resolves to.
 * @param {AsyncFunction<Args, ReturnType>} fnct - The asynchronous function to be memoized.
 * @param {DependencyList} deps - The dependency list that determines when the memoized function should be recreated.
 * @returns {(...args: Args) => Promise<ReturnType>} A memoized version of the asynchronous function that returns the same Promise instance.
 *
 * @example
 * const fetchData = async (id: number) => {
 *   const response = await fetch(`https://api.example.com/data/${id}`);
 *   return response.json();
 * };
 * const memoizedFetchData = usePromise(fetchData, [fetchData]);
 * memoizedFetchData(1).then(data => console.log(data));
 */
type AsyncFunction<Args extends unknown[], ReturnType> = (...args: Args) => Promise<ReturnType>;

export const usePromise = <Args extends unknown[], ReturnType>(
  fnct: AsyncFunction<Args, ReturnType>,
  deps: DependencyList,
): ((...args: Args) => Promise<ReturnType>) => {
  const promiseRef = useRef<Promise<ReturnType>>();

  return useCallback(
    (...args: Args) => {
      if (!promiseRef.current) {
        promiseRef.current = fnct(...args);
      }

      return promiseRef.current;
    },
    [fnct, ...deps],
  );
};

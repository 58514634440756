import { QueryKey } from "@/api";
import { fetchMultiLocationTableData } from "@/grid/GridMultiLocationTable/hooks/fetchMultiLocationTableData";
import { getPointsWithInfoFromTableGroups } from "@/grid/GridMultiLocationTable/hooks/utils";
import { useCurrTimeState } from "@/models/time-control/timeStateHooks";
import type { PointRequest } from "@mm/api.meteomatics.com";
import { CoordinateSystem, SinglePointCoordinate } from "@mm/api.meteomatics.com";
import type { LocationTableGroup } from "@mm/metx-workbench.meteomatics.com";
import { useQuery } from "@tanstack/react-query";
import { DateTime, Duration } from "luxon";
import { useMemo } from "react";

type UseMultiLocationTableDataProps = {
  tableModel?: string;
  tableParameter: string;
  groups?: LocationTableGroup[];
  dateTimeFrom?: string;
  dateTimeTo?: string;
  resolution?: string;
};

export const useMultiLocationData = ({
  tableModel = "mix",
  tableParameter,
  dateTimeFrom,
  dateTimeTo,
  resolution,
  groups,
}: UseMultiLocationTableDataProps) => {
  const { context: timeContext } = useCurrTimeState();
  // Time that we can use can come from 3 different places: layerstack, state display time, and state options
  const currentTime = useMemo(
    () => timeContext.displayTime?.set({ minute: 0, second: 0 }).toISO() || "",
    [timeContext.displayTime],
  );
  const animationStartTime = useMemo(
    () => timeContext.modeOpts?.startTime?.set({ minute: 0, second: 0 }).toISO() || "",
    [timeContext.modeOpts?.startTime],
  );
  const animationEndTime = useMemo(
    () => timeContext.modeOpts?.endTime?.set({ minute: 0, second: 0 }).toISO() || "",
    [timeContext.modeOpts?.endTime],
  );
  const timeFrom =
    getHighestPriorityTime(dateTimeFrom, animationStartTime, currentTime) ||
    DateTime.local().set({ minute: 0, second: 0, millisecond: 0 });
  const timeTo = getHighestPriorityTime(dateTimeTo, animationEndTime);

  const duration = timeTo && timeFrom ? timeTo.diff(timeFrom) : Duration.fromObject({ days: 1 });
  const locations = getPointsWithInfoFromTableGroups(groups);

  const coordinateList: SinglePointCoordinate<CoordinateSystem.WGS84>[] = locations.map(
    (coord) => new SinglePointCoordinate<CoordinateSystem.WGS84>(coord.lat, coord.lon, CoordinateSystem.WGS84),
  );

  const request: PointRequest<CoordinateSystem.WGS84> = {
    boundingBoxLimit: null,
    coordinates: coordinateList,
    duration: duration,
    ensSelect: "",
    height: 0,
    model: tableModel,
    parameters: [tableParameter],
    startDatetime: timeFrom,
    temporalResolution: resolution ? Duration.fromISO(resolution) : undefined,
    width: 0,
  };

  const { data, isLoading, error } = useQuery(
    [QueryKey.MULTI_LOCATION_TABLE, request, locations], // Unique query key
    () => fetchMultiLocationTableData(request, locations),
    {
      staleTime: 1000 * 60 * 5, // Cache data for 5 minutes
      refetchOnWindowFocus: false, // Prevent refetching on window focus if unnecessary
      retry: 2, // Retry failed requests up to 2 times
    },
  );

  return { tableRows: data, loading: isLoading, error: error };
};

const getHighestPriorityTime = (
  layoutTime?: string,
  animationTime?: string,
  stateTime?: string,
): DateTime | undefined => {
  if (layoutTime) {
    return DateTime.fromISO(layoutTime).set({ second: 0, millisecond: 0 });
  }
  if (animationTime) {
    return DateTime.fromISO(animationTime).set({ second: 0, millisecond: 0 });
  }
  if (stateTime) {
    return DateTime.fromISO(stateTime).set({ second: 0, millisecond: 0 });
  }
  return undefined;
};

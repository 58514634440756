import { GeocoderApiConfiguration } from "@/env";
import { languageFromLocale } from "@/i18n";
import { GeocoderApi, type GeocoderResult, Language, type LocationResult } from "@mm/geocoder.meteomatics.com";
import Logger from "logging";
import * as stream from "rxjs";
import * as streamOps from "rxjs/operators";
const logger = Logger.fromFilename(__filename);

type SearchResult = Pick<LocationResult, "formatted">;

export type SearchStreamItemResponse = GeocoderResult | { err: any } | null;
export type SearchStreamItem = { searchTerm: string; response: SearchStreamItemResponse };
export const MAX_RECENT_SEARCH_RESULTS = 6;
export const MIN_SEARCHTERM_LENGTH = 3;
export const geocoderApi = new GeocoderApi(GeocoderApiConfiguration);
export function isOkResponse(res: SearchStreamItemResponse): res is GeocoderResult {
  return res !== null && !Object.hasOwn(res, "err");
}
export function decodeSearchResult(storage: any): storage is SearchResult {
  if (storage == null) {
    return true;
  }

  const _if_this_errors_please_add_the_field_and_a_check_below: SearchResult = {
    formatted: "",
  };

  return Object.hasOwn(storage, "formatted") && typeof storage.formatted === "string";
}
export function decodeSearchResultListing(storage: any): storage is null | SearchResult {
  if (storage == null) {
    return true;
  }
  if (!Array.isArray(storage)) {
    return false;
  }

  return storage.reduce((acc, curr) => acc && decodeSearchResult(curr), true);
}

function isLanguageSupportedByGeocoderApi(lang: string): lang is Language {
  return Object.hasOwn(Language, lang);
}
export function apiLanguage(locale: string) {
  const guiLanguage = languageFromLocale(locale);

  if (isLanguageSupportedByGeocoderApi(guiLanguage)) {
    return guiLanguage;
  }

  logger.warn(
    `current GUI language <${guiLanguage}> not supported by the geolocator API. Falling back to default language.`,
  );
  return Language.en;
}

export class ApiSearchStream {
  private searchterm: stream.Subject<string> = new stream.Subject();
  private mostRecentlyCompletedSearchTerm: string | null = null;

  constructor(
    private currentSearchTerm: string,
    setSearchResults: React.Dispatch<SearchStreamItemResponse>,
    private language: Language | undefined = undefined,
    private timestampLastResetMilliseconds: number = performance.now(),
  ) {
    this.updateSearchterm(this.currentSearchTerm);
    this.handle().subscribe(({ searchTerm, response }) => {
      this.mostRecentlyCompletedSearchTerm = searchTerm;
      setSearchResults(response);
    });
  }

  getCurrentSearchTerm(): string {
    return this.currentSearchTerm;
  }

  reset() {
    this.timestampLastResetMilliseconds = performance.now();
  }

  /**
   * Update the searchterm with high frequency.
   *
   * @param searchterm
   */
  updateSearchterm(searchterm: string) {
    this.currentSearchTerm = searchterm;
    this.searchterm.next(this.currentSearchTerm);
  }

  handle(): stream.Observable<SearchStreamItem> {
    const [longEnough, tooShort] = stream.partition(
      this.searchterm,
      (searchTerm) => searchTerm.length >= MIN_SEARCHTERM_LENGTH,
    );

    // don't requiry the API if the search term is too short to provide good suggestions
    const ifTooShort = tooShort.pipe<SearchStreamItem>(streamOps.map((searchTerm) => ({ searchTerm, response: null })));

    const ifLongEnough = longEnough.pipe(
      streamOps.auditTime(333), // at most 3 requests per second
      streamOps.distinctUntilChanged(), // only emit if value is different from previous value
      // Short note why we use flatMap here:
      // - switchMap would cancel requests if the user adds another letter, which will result in no prediction while typing
      // - concatMap would not cancel requests and maintain order among requests, but a slow old request might prevent faster more recent requests from being displayed in the GUI
      streamOps.flatMap((searchterm) => {
        const requestStartMilliseconds = performance.now();

        const apiRes: stream.Observable<GeocoderResult> = stream.from(
          geocoderApi.directGeocoderApiV1GeocoderDirectGet({ location: searchterm, language: this.language }),
        );

        const withMeta = apiRes.pipe(
          streamOps.catchError((err) => stream.of({ err })),
          streamOps.map((response) => ({
            searchTerm: searchterm,
            requestStartMilliseconds,
            response,
          })),
        );

        return withMeta;
      }),
      streamOps.filter(({ searchTerm, requestStartMilliseconds, response }) => {
        // We have to reject results that belong to a previous search, e.g. the following might happen:
        // 1. user types, we send request A
        // 2. user accepts a currently displayed prediction and the search clears
        // 3. response for request B arrives and must be discarded instead of rendered
        // TODO: maybe there is a way to cancel all previous inflight requests?
        if (requestStartMilliseconds < this.timestampLastResetMilliseconds) {
          return false;
        }

        // only emit errors to the GUI if the user is no longer typing.
        if (searchTerm === this.currentSearchTerm) {
          return true;
        }

        // If a query fails while the user is typing, just keep the previous result a little bit longer.
        if (!isOkResponse(response)) {
          return false;
        }

        // prevent slow, old requests to replace the current value if the user stopped typing
        // it is not the most recent request, but the most recent request did not yet complete
        // prevent slow, old requests to replace the current value if the user stopped typing
        return (
          this.mostRecentlyCompletedSearchTerm === null || // there is no previous result, let anything pass
          this.mostRecentlyCompletedSearchTerm !== this.currentSearchTerm // it is not the most recent request, but the most recent request did not yet complete
        );
      }),
    );

    return stream.merge(ifTooShort, ifLongEnough);
  }
}

import { FormField } from "@/overlay/components/form-field";
import { t } from "@lingui/macro";
import type { ModelSchema } from "@mm/api-layers.meteomatics.com";

export function ModelSelect(props: {
  layerModelIdentifier?: string;
  models: ModelSchema[];
  change: (propsValue: { model: string }) => void;
}) {
  return (
    <FormField label={t`Source`}>
      <select
        data-testid={"source-select"}
        value={props.layerModelIdentifier}
        onChange={(event) => props.change({ model: event.target.value })}
      >
        {props.models?.map((model) => (
          <option key={model.identifier} value={model.identifier}>
            {model.name}
          </option>
        ))}
      </select>
    </FormField>
  );
}

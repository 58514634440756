import { compileShader, createProgram } from "@/layers/utility/webgl";
import { ShaderBuilder } from "./ShaderBuilder";

export class ShaderCompiler {
  constructor(readonly sources: Promise<any>) {}

  getShaderSourceLookupTable(): Promise<(path: string) => string> {
    return this.sources.then((sources) => (path: string) => sources.default(`./${path}`).default);
  }

  compileProgram(
    gl: WebGLRenderingContext,
    vertex: ShaderBuilder | string,
    fragment: ShaderBuilder | string,
  ): Promise<WebGLProgram> {
    const vertexShaderBuilder = vertex instanceof ShaderBuilder ? vertex : new ShaderBuilder(vertex);
    const vertexShader = this.compile(gl, gl.VERTEX_SHADER, vertexShaderBuilder);
    const fragmentShaderBuilder = fragment instanceof ShaderBuilder ? fragment : new ShaderBuilder(fragment);
    const fragmentShader = this.compile(gl, gl.FRAGMENT_SHADER, fragmentShaderBuilder);

    return Promise.all([vertexShader, fragmentShader]).then(([vertexShader, fragmentShader]) =>
      createProgram(gl, vertexShader, fragmentShader),
    );
  }

  /**
   * Compile a shader. See `ShaderPool` and `ProgramPool` for a cached variant.
   *
   * @param gl webgl context used for compulation
   * @param shaderType gl.FRAGMENT_SHADER or gl.VERTEX_SHADER
   * @param builder shader source code
   * @returns
   */
  // TODO: cache result and do not recompile shaders if the same shader is added or removed multiple times
  compile(gl: WebGLRenderingContext, shaderType: number, builder: ShaderBuilder): Promise<WebGLShader> {
    return this.getShaderSourceLookupTable().then((getShaderSource) => {
      let shaderSource = getShaderSource(builder.shaderName);

      // parse includes and string replace
      shaderSource = shaderSource.replace(
        /^#include "(.*?)";?/gm,
        (fullIncludeStatement: string, path: string, idx: number, fullShaderSource: string) => getShaderSource(path),
      );

      // set preprocessor macros
      const macros = [];

      for (const [macroName, macroVal] of builder.macros.entries()) {
        if (typeof macroVal === "number") {
          // TODO: code injection possibility here through macroName
          macros.push(`#define ${macroName} ${macroVal}`);
        } else if (typeof macroVal === "boolean" && macroVal) {
          macros.push(`#define ${macroName}`);
        }
      }

      const macroMatch = /precision (mediump|highp) float;/m.exec(shaderSource);
      const insertionPoint =
        macroMatch == null ? 0 : macroMatch.index + macroMatch[0].length; /* matchStart + lengthOfFullMatch */

      shaderSource = `${shaderSource.substring(0, insertionPoint)}\n${macros.join("\n")}\n${shaderSource.substring(
        insertionPoint,
      )}`;

      // actual compilation
      // prepass
      const compiledShader = compileShader(gl, shaderSource, shaderType);

      if (compiledShader instanceof Error) {
        const withContext = new Error(
          `failure while compiling <${builder.shaderName}>: ${compiledShader}\nSource Code:\n${shaderSource}`,
        );
        return Promise.reject(withContext);
      }
      return Promise.resolve(compiledShader);
    });
  }
}

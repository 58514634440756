export enum SpatialUpsamplingTechnique {
  /**
   * Uses the built in hardware interpolation of texture units.
   */
  HarwareBilinear = 0,
  /**
   * Same as `HardwareBilinear` interpolation but interpolates
   * values within the shader. This should result in a higher
   * quality interpolation since hardware interpolation is performed
   * with lower precision.
   */
  //SoftwareBilinear,
  /**
   * Uses catmul rom for trilinear interpolation.
   *
   * Beware: This has to do 8 times the I/O work and is very expensive. But the
   * quality is massively improved.
   */
  CatmulRom = 1,
}

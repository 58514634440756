import styles from "./style.module.scss";

export function MetemoaticsNegativeSymbol() {
  return (
    <svg width="34mm" height="12mm" viewBox="0 0 20 25">
      <g>
        <rect className={styles.black} x="8.3609" y="18.036" width="3.1181" height="3.1181" />
        <rect
          className={styles.black}
          x="1.4161"
          y="11.0911"
          width="3.1181"
          height="3.1181"
          transform="translate(15.6253 9.675) rotate(90)"
        />
        <polygon
          className={styles.black}
          points="8.361 4.1462 8.361 7.1793 15.3058 7.1793 15.3058 14.2092 18.4239 14.2092 18.4239 4.1462 8.361 4.1462"
        />
        <rect
          className={styles.black}
          x="3.4502"
          y="16.0019"
          width="3.1181"
          height="3.1181"
          transform="translate(13.8846 1.6014) rotate(45)"
        />
        <rect
          className={styles.black}
          x="3.4502"
          y="6.1803"
          width="3.1181"
          height="3.1181"
          transform="translate(14.0239 9.6699) rotate(135)"
        />
        <rect
          className={styles.black}
          x="13.2717"
          y="16.0019"
          width="3.1181"
          height="3.1181"
          transform="translate(37.7352 19.4914) rotate(135)"
        />
      </g>
    </svg>
  );
}

export function MeteomaticsLogo() {
  return (
    <svg width="34mm" height="12mm" viewBox="0 0 96.378 34.0157">
      <g>
        <g>
          <path
            className={styles.primary}
            d="M24.0932,4.1462h2.9905v1.1057c1.0051-1.0303,2.337-1.4326,3.3923-1.4326,1.5579,0,2.8395,.7287,3.6185,1.9351,.98-1.2313,2.6133-1.9351,4.3222-1.9351,2.7892,0,4.5985,1.9098,4.5985,4.9506v7.6642h-2.7893v-7.0109c0-1.8597-.9297-3.0155-2.5128-3.0155-1.382,0-2.7643,.9549-2.7643,3.0404v6.986h-2.7892v-7.0109c0-1.8849-.98-3.0155-2.6135-3.0155-1.382,0-2.6636,1.0051-2.6636,3.2417v6.7847h-2.7893V4.1462Z"
          />
          <path
            className={styles.primary}
            d="M44.5818,10.4283c0-3.7695,2.9903-6.609,6.433-6.609,3.4178,0,6.0058,2.6137,6.0058,5.9557,0,.3016-.0253,.7035-.1007,1.2565h-9.5989c.2763,2.0603,1.8344,3.342,3.7692,3.342,1.4574,0,2.6887-.7287,3.0155-1.8344h2.7641c-.6032,2.4124-2.8647,4.2215-5.9553,4.2215-3.6689,0-6.3327-2.8142-6.3327-6.3323Zm9.574-1.6836c-.1003-1.4073-1.4069-2.5379-3.1411-2.5379-1.583,0-2.9398,.9798-3.4928,2.5379h6.6339Z"
          />
          <path
            className={styles.primary}
            d="M67.1733,10.4283c0-3.7695,2.9903-6.609,6.433-6.609,3.4178,0,6.0058,2.6137,6.0058,5.9557,0,.3016-.0253,.7035-.1003,1.2565h-9.5993c.2763,2.0603,1.8344,3.342,3.7692,3.342,1.4574,0,2.6887-.7287,3.0155-1.8344h2.7641c-.6032,2.4124-2.8647,4.2215-5.9553,4.2215-3.6689,0-6.3327-2.8142-6.3327-6.3323Zm9.574-1.6836c-.1003-1.4073-1.4069-2.5379-3.1411-2.5379-1.583,0-2.9401,.9798-3.4928,2.5379h6.6339Z"
          />
          <path
            className={styles.primary}
            d="M81.0869,10.3028c0-3.6187,2.8647-6.4835,6.4835-6.4835,3.5931,0,6.4831,2.8647,6.4831,6.4835s-2.89,6.4578-6.4831,6.4578c-3.6187,0-6.4835-2.8395-6.4835-6.4578Zm6.4835,3.8698c2.0855,0,3.7439-1.6836,3.7439-3.8698,0-2.2114-1.6584-3.8951-3.7439-3.8951-2.136,0-3.7443,1.6836-3.7443,3.8951,0,2.1862,1.6082,3.8698,3.7443,3.8698Z"
          />
          <path
            className={styles.primary}
            d="M57.5989,4.1462h2.337V1.8092h2.7893v2.337h3.2919v2.4376h-3.2919v5.5282c0,1.2565,.6281,1.8846,1.8092,1.8846h1.4827v2.4376h-1.8344c-2.8142,0-4.2468-1.3066-4.2468-4.1212V6.5838h-2.337v-2.4376Z"
          />
        </g>
        <g>
          <path
            className={styles.primary}
            d="M24.0932,20.3755h2.9905v1.1057c1.0051-1.0303,2.337-1.4322,3.3923-1.4322,1.5579,0,2.8395,.7287,3.6185,1.9347,.98-1.2313,2.6133-1.9347,4.3222-1.9347,2.7892,0,4.5985,1.9095,4.5985,4.9503v7.6642h-2.7893v-7.0109c0-1.8597-.9297-3.0155-2.5128-3.0155-1.382,0-2.7643,.9549-2.7643,3.0408v6.9856h-2.7892v-7.0109c0-1.8846-.98-3.0155-2.6135-3.0155-1.382,0-2.6636,1.0051-2.6636,3.2417v6.7847h-2.7893v-12.288Z"
          />
          <path
            className={styles.primary}
            d="M44.9439,29.2208c0-2.4124,2.0103-4.096,5.78-4.096h2.2111v-.3016c0-1.5328-.9297-2.4878-2.5126-2.4878-1.3572,0-2.4878,.8546-2.5884,2.1108h-2.7895c.1257-2.588,2.3624-4.3972,5.4281-4.3972,3.2666,0,5.2771,1.9849,5.2771,5.0257v7.5888h-2.7392v-1.2814c-.8041,.9546-2.1609,1.6082-3.4928,1.6082-2.7643,0-4.5736-1.4827-4.5736-3.7695Zm4.7998,1.583c1.8344,0,3.2666-1.4823,3.2666-3.4174v-.2009h-2.2114c-2.0354,0-3.1158,.6786-3.1158,1.96,0,.9802,.7287,1.6584,2.0606,1.6584Z"
          />
          <path
            className={styles.primary}
            d="M56.6168,20.3755h2.337v-2.337h2.7893v2.337h3.2919v2.4376h-3.2919v5.5282c0,1.2565,.6281,1.8846,1.8092,1.8846h1.4827v2.4376h-1.8344c-2.8142,0-4.2468-1.3066-4.2468-4.1212v-5.7291h-2.337v-2.4376Z"
          />
          <path className={styles.primary} d="M69.7847,20.3755v12.288h-2.7893v-12.288h2.7893Z" />
          <path
            className={styles.primary}
            d="M71.5144,26.5321c0-3.6941,2.8395-6.4831,6.433-6.4831,2.9654,0,5.478,2.0101,5.855,4.7241h-2.8395c-.2763-1.1811-1.583-2.1108-3.0657-2.1108-2.0606,0-3.6436,1.6331-3.6436,3.8698,0,2.2114,1.583,3.8445,3.6436,3.8445,1.4827,0,2.7893-.9044,3.0909-2.2111h2.8142c-.3265,2.8644-2.8647,4.8247-5.855,4.8247-3.5935,0-6.433-2.7645-6.433-6.4582Z"
          />
          <path
            className={styles.primary}
            d="M84.9582,28.8943h2.6638c.1003,1.1306,1.03,1.9095,2.4372,1.9095,1.382,0,2.1862-.6782,2.1862-1.583,0-2.6133-7.061-.7789-7.061-5.3525,0-2.3117,1.9347-3.8193,4.5985-3.8193,2.6887,0,4.6992,1.3066,4.9503,3.7692h-2.7388c-.1508-.9297-1.0303-1.6584-2.2367-1.6584-1.0303,0-1.96,.4524-1.96,1.4322,0,2.4376,7.1617,.5278,7.1617,5.3024,0,2.4124-2.0101,4.096-4.9001,4.096s-4.9001-1.5079-5.1011-4.096Z"
          />
        </g>
      </g>
      <g>
        <rect className={styles.primary} x="8.3609" y="18.036" width="3.1181" height="3.1181" />
        <rect
          className={styles.primary}
          x="1.4161"
          y="11.0911"
          width="3.1181"
          height="3.1181"
          transform="translate(15.6253 9.675) rotate(90)"
        />
        <polygon
          className={styles.secondary}
          points="8.361 4.1462 8.361 7.1793 15.3058 7.1793 15.3058 14.2092 18.4239 14.2092 18.4239 4.1462 8.361 4.1462"
        />
        <rect
          className={styles.primary}
          x="3.4502"
          y="16.0019"
          width="3.1181"
          height="3.1181"
          transform="translate(13.8846 1.6014) rotate(45)"
        />
        <rect
          className={styles.primary}
          x="3.4502"
          y="6.1803"
          width="3.1181"
          height="3.1181"
          transform="translate(14.0239 9.6699) rotate(135)"
        />
        <rect
          className={styles.primary}
          x="13.2717"
          y="16.0019"
          width="3.1181"
          height="3.1181"
          transform="translate(37.7352 19.4914) rotate(135)"
        />
      </g>
    </svg>
  );
}

import { polygon, simplify } from "@turf/turf";
import type { Position } from "geojson";

const STARTING_TOLERANCE = 0.00001;
const TOLERANCE_STEP = 0.00001;

// As Ramer-Douglas-Peucker algorithm doesn't have any relation between tolerance and point count
// this is simplest and most precise approach to simplifying polygon to reach maxPointCount
export const simplifyPolygon = (points: Position[], maxPointCount: number) => {
  const currentPointsCount = points.length;

  if (currentPointsCount < maxPointCount) {
    return points;
  }

  const polygonFeature = polygon([points]);

  let tolerance = STARTING_TOLERANCE;

  while (true) {
    const simplifiedPolygonFeature = simplify(polygonFeature, {
      tolerance: tolerance,
    });

    if (simplifiedPolygonFeature.geometry.coordinates[0].length <= maxPointCount) {
      return simplifiedPolygonFeature.geometry.coordinates[0];
    }
    tolerance += TOLERANCE_STEP;
  }
};

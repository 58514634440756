import type { ComponentFactoryFn, DraggingWindowState, WindowInstanceId } from "@/overlay/components/window";
import type { WindowKind } from "./WindowKind";

export interface WindowDragPreviewProps<K extends WindowKind = WindowKind> {
  component: ComponentFactoryFn<K>;
  instanceId: WindowInstanceId;
}

/**
 * This element is shown instead of the window during a drag operation. Can be used to optimize performance,
 * for example by throttling updates or showing a less complex placeholder window.
 */
export const WindowDragPreview = (draggingWindowState: DraggingWindowState) => {
  // Render the window preview with self positioning disabled (`isManaged: true`).
  // This window preview defaults to the window itself.
  const preview = draggingWindowState.componentPreviewFactory ?? draggingWindowState.componentFactory;

  return preview(draggingWindowState.instanceId, {
    ...draggingWindowState,
    position: { ...draggingWindowState.position, isManaged: true },
    style: { position: "absolute", left: 0, top: 0 },
  });
};

import { networkCaches } from "@/cache/GlobalCache";
import { formatPointResponseToTable } from "@/grid/GridMultiLocationTable/hooks/formatPointResponseToTable";
import type { LocationTablePoint } from "@/grid/GridMultiLocationTable/hooks/utils";
import type { NestedRowData } from "@/overlay/components/CommonParts/tables/nestedTable/utils";
import type { PointRequest } from "@mm/api.meteomatics.com";
import type { CoordinateSystem } from "@mm/api.meteomatics.com";

export async function fetchMultiLocationTableData(
  request: PointRequest<CoordinateSystem.WGS84>,
  locations: LocationTablePoint[],
): Promise<NestedRowData[]> {
  // we don't want to make a request without coordinates
  if (request.coordinates.length === 0) {
    return [];
  }

  const data = await networkCaches.pointJson_cache.retrievePoint(request).asynchronous;
  // data[0] is fine if only one parameter is used
  return formatPointResponseToTable(data[0], locations);
}

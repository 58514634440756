import { ENV } from "@/env";

// Set the document title.
//
// In order to support linting, you have to wrap this setter yourself as follows:
//
// ```ts
// useEffect(() => {
//    setTitle(t`login.title`);
// }, []);
// ```
export function setTitle(title: string) {
  document.title = ENV.documentTitlePrefix + title + ENV.documentTitleSuffix;
}

import { performanceRecorder } from "./PerformanceRecorder";
import type { SampleSequenceId } from "./SampleSequenceId";

// make performance API available in node
// biome-ignore lint/security/noGlobalEval: Ugly
const _perf = typeof performance !== "undefined" ? performance : eval("require")("perf_hooks").performance;

/**
 * A timer with a simple state machine: `Waiting -> InFlight (start time measured) -> Finished (start and end time measured)`
 *
 * - The timer cannot be reset.
 * - A timer that was instantiated MUST be finished by either calling `Timer.end()` or `Timer.abort()`.
 * - It is tightly coupled to the `performanceRecorder` singleton. A singleton is desirable since a performance estimate shared by all api instances will result in better estimates.
 */
export class Timer {
  startTime?: number;
  endTime?: number;

  constructor(
    readonly sequence: SampleSequenceId,
    readonly workload: number,
  ) {}

  start() {
    if (!this.isWaiting()) {
      throw Error("cannot start timer that is not in state <Waiting>");
    }

    this.startTime = _perf.now();
    performanceRecorder._markInFlightInGlobalStatistics(this);
  }

  end() {
    if (!this.isInFlight()) {
      throw Error("cannot stop timer that is not in state <InFlight>");
    }

    this.endTime = _perf.now();

    performanceRecorder._integrateActual(this);
  }

  isWaiting() {
    return this.startTime == null && this.endTime == null;
  }

  isInFlight() {
    return this.startTime != null && this.endTime == null;
  }

  isFinished() {
    return this.startTime != null && this.endTime != null;
  }

  duration(): number {
    if (!this.isFinished()) {
      throw Error("cannot get duration of timer that is not in state <Finished>");
    }

    // biome-ignore lint/style/noNonNullAssertion: TODO error prone
    return this.endTime! - this.startTime!;
  }

  abort() {
    performanceRecorder.abort(this);
  }
}

import { useMemo } from "react";

import type { LayerKind } from "@/layers/utility/createLayerObject";
import { WindowSection } from "@/overlay/components";
import type { ParamOptionInputData } from "@/overlay/components/SmartForm/HookInputs/ParamOptionSelectList";
import { ToggleParametersGroup } from "@/overlay/components/SmartForm/HookInputs/ToggleParametersGroup";
import { WrappedWeatherParameterForm } from "@/overlay/components/SmartForm/HookInputs/WrappedWeatherParameterForm";
import { ModeSwitchableForm } from "@/overlay/components/SmartForm/SmartFormUtils/FormComponents/ModeSwitchableFormProps";
import { getUpdatedParamUnit } from "@/overlay/components/SmartForm/SmartFormUtils/FormDataFormatter/mergePartialWeatherParameter";
import { useFormWithUtils } from "@/overlay/components/SmartForm/SmartFormUtils/FormHooks/useFormUtils";
import { usePartialWeatherParameter } from "@/overlay/components/SmartForm/SmartFormUtils/FormHooks/usePartialWeatherParameter";
import type { BaseFormProps } from "@/overlay/components/SmartForm/SmartFormUtils/commonTypeUtils";
import { getDefaultModelName } from "@/utility/layer";
import { searchEnginesWeatherParams } from "@/weather-parameters";
import type { BarbsLayer, BarbsLayerCreate } from "@mm/metx-workbench.meteomatics.com";
import type { HasLayerIdxNarrowing, PartialWeatherParameter } from "weather-parameter-utils";

type BarbsLayerPatch = Partial<BarbsLayerCreate | BarbsLayer>;

/**
 * Form data passed from the child components.
 */
export type BarbsLayerCreateFormData = {
  // Snake case as they get passed as layer props directly.
  layerData: {
    parameter_unit: string;
    model: string;
  };
  // Form internal data to construct the above data.
  internal: {
    defaultParameter: HasLayerIdxNarrowing<PartialWeatherParameter>;
    paramOptionPatch: ParamOptionInputData;
  };
};

type BarbsLayerCreateFormProps = {
  defaultFormData: BarbsLayerPatch;
} & BaseFormProps<BarbsLayerCreateFormData>;

const searchEngine = searchEnginesWeatherParams.standard;

/**
 * Responsible for rendering the whole layer form and send back the data on submit hadler.
 */
export function BarbsLayerCreateForm({ submitMode, onSubmit, onCancel, defaultFormData }: BarbsLayerCreateFormProps) {
  const { formMethods, formValName } = useFormWithUtils<BarbsLayerCreateFormData>({ mode: submitMode });

  const defaultParameterUnits = useMemo(() => {
    if (!defaultFormData.parameter_unit) return [];
    if (defaultFormData.parameter_unit?.startsWith("wind_speed")) {
      return [
        searchEnginesWeatherParams.standard.matchParameterNameExactly("wind_speed_10m:kn")[0],
        searchEnginesWeatherParams.standard.matchParameterNameExactly("wind_gusts_10m_1h:kn")[0],
      ];
    }
    return [
      searchEnginesWeatherParams.standard.matchParameterNameExactly("ocean_current_speed_10m:kn")[0],
      searchEnginesWeatherParams.standard.matchParameterNameExactly("stokes_drift_speed:kn")[0],
    ];
  }, [defaultFormData.parameter_unit]);

  const formData = formMethods.watch();

  const { parameter } = usePartialWeatherParameter(
    defaultFormData.parameter_unit ?? "",
    defaultFormData.kind as LayerKind,
    searchEngine,
  );

  const onSubmitWithFormatting: typeof onSubmit = (formData) => {
    if (formData.internal?.paramOptionPatch.parameter?.available_models) {
      const paramUnit = getUpdatedParamUnit(parameter, [formData.internal.paramOptionPatch]);

      const selectedModel = getDefaultModelName({ parameter: formData.internal.paramOptionPatch.parameter });
      onSubmit({ ...formData, layerData: { parameter_unit: paramUnit, model: selectedModel } });
    }
  };

  if (!defaultFormData.kind) return <></>;

  return (
    <ModeSwitchableForm
      onSubmit={formMethods.handleSubmit(onSubmitWithFormatting)}
      onCancel={onCancel}
      submitMode={submitMode}
    >
      <WindowSection>
        <ToggleParametersGroup
          parameters={defaultParameterUnits}
          instruction={{
            formValueName: formValName("internal.defaultParameter"),
            currValue: parameter,
            formMethods,
          }}
        />
        <WrappedWeatherParameterForm
          instruction={{
            currValue: {
              kind: defaultFormData.kind as LayerKind,
              parameter: formData.internal?.defaultParameter || parameter,
            },
            formMethods,
            formValueName: formValName("internal.paramOptionPatch"),
          }}
        />
      </WindowSection>
    </ModeSwitchableForm>
  );
}

import { type Color, toCssColor } from "color-lib";
import Logger from "logging";
import type { CoordinateSystem, GridPayload, GridResponse } from "../models";
import { UNAVAILABLE } from "../normalization";
import type { FloatArray2D } from "../request-splitters";
import type { CropResult } from "./crop";
const logger = Logger.fromFilename(__filename);

export interface Padding {
  top: number;
  left: number;
  bottom: number;
  right: number;
}

// TODO: add variants for other formats, e.g. rgba8888 and for non-pixel and non-grid data
export function withPadding32f<T extends Float64Array | Float32Array>(
  array: T,
  arrayDimensions: { width: number; height: number },
  padding: Padding,
  fill: number = UNAVAILABLE,
): FloatArray2D<T> {
  const width = arrayDimensions.width + padding.left + padding.right;
  const height = arrayDimensions.height + padding.top + padding.bottom;
  // TODO: this cast throws away any typesafety
  const padded = new (array.constructor as Float64ArrayConstructor | Float32ArrayConstructor)(width * height);

  if (fill !== 0) {
    padded.fill(fill);
  }

  // [DEBUG-TILE-POSTPROCESS] debugger;

  for (let y = 0; y < arrayDimensions.height; y++) {
    for (let x = 0; x < arrayDimensions.width; x++) {
      const linIdxIn = x + y * arrayDimensions.width;
      const linIdxOut = padding.left + x + (padding.top + y) * width;
      padded[linIdxOut] = array[linIdxIn];
    }
  }

  return {
    payload: padded as T,
    estimatedByteSize: padded.byteLength,
    width,
    height,
  };
}

export const transparentBlack: Color = [0, 0, 0, 0];

// Padding for Wms Tiles padding
export function withPaddingImg<T extends CanvasImageSource, C extends CoordinateSystem>(
  response: GridResponse<GridPayload<ImageBitmap>, C>,
  cropResult: CropResult<C>,
  fill: Color = transparentBlack,
): Promise<GridResponse<GridPayload<ImageBitmap>, C>> {
  const padding = cropResult.padding;
  const { width, height } = cropResult.originalGridRequest;

  const offscreen = new OffscreenCanvas(width, height);
  // Note In Firefox -> User must explicitly enable this feature. Is only for WebGL context not 2d possible
  if (offscreen === null) {
    logger.error("Offscreen canvas not supported");
    return Promise.resolve({
      ...response,
    });
  }
  const ctx = offscreen.getContext("2d");
  if (ctx === null) {
    logger.error("2d Offscreen canvas not supported");
    return Promise.resolve({
      ...response,
    });
  }
  const wholeCanvas: [number, number, number, number] = [0, 0, ctx.canvas.width, ctx.canvas.height];

  // reset the canvas content
  ctx.fillStyle = toCssColor(fill).color;
  ctx.fillRect(...wholeCanvas);

  // root of the canvas coordinate system is in the top, left corner
  ctx.drawImage(
    response.data.payload,
    padding.left,
    padding.top,
    width - (padding.right + padding.left),
    height - (padding.top + padding.bottom),
  );

  const payload = createImageBitmap(ctx.canvas, ...wholeCanvas);

  return payload.then((value) => {
    return {
      ...response,
      data: {
        estimatedByteSize: value.width * value.height * 4,
        payload: value,
      },
    };
  });
}

import { symbolLayerConfigWeatherSymbol } from "@/constants/layerConfigAttributes";
import { InputFormField } from "@/overlay/components/form-field";
import { batchGroupBy } from "@/reducer/batchGroupBy";
import { t } from "@lingui/macro";
import type { BarbsLayer, MapId, SymbolLayer } from "@mm/metx-workbench.meteomatics.com";

export function IconSizeInput(props: {
  id: MapId;
  layer: SymbolLayer | BarbsLayer;
  stepRange?: { min: number; max: number; step: number };
  change: (propsValue: Partial<SymbolLayer | BarbsLayer>) => void;
}) {
  const iconSize: number = props.layer.custom_options?.icon_size ?? symbolLayerConfigWeatherSymbol.defaultIconSize;
  return (
    <InputFormField
      label={t`Icon Size`}
      type="range"
      onMouseDown={() => batchGroupBy.start()}
      onMouseUp={() => batchGroupBy.end()}
      onChange={(event) =>
        props.change({
          custom_options: {
            ...props.layer.custom_options,
            icon_size: Number(event.target.value),
          },
        })
      }
      max={props.stepRange ? props.stepRange.max : 0}
      min={props.stepRange ? props.stepRange.min : 100}
      step={props.stepRange?.step ?? 0.1}
      value={iconSize}
    />
  );
}

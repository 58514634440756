import { getEnergyForecastAvailabilityV2 } from "@/api/hooks/micro-service/EnergyForecast";
import type { EnsembleModelInfo, StandardModelInfo } from "@mm/forecast-data.meteomatics.com";
import { createSlice } from "@reduxjs/toolkit";

export type EnergyForecastState = {
  availability?: {
    [key: string]: {
      [key: string]: EnsembleModelInfo | StandardModelInfo;
    };
  };
  isLoading: boolean;
};

const initialState: EnergyForecastState = {
  isLoading: false,
};

const energyForecastSlice = createSlice({
  name: "account",
  initialState: initialState as EnergyForecastState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEnergyForecastAvailabilityV2.pending, (_state, action) => {
      return { isLoading: true };
    });
    builder.addCase(getEnergyForecastAvailabilityV2.fulfilled, (_state, action) => {
      return { availability: action.payload, isLoading: false };
    });
  },
});

export default energyForecastSlice.reducer;

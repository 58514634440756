import { RUNTIME, Runtime } from "detect-runtime-lib";

export const encodeBase64 = (() => {
  switch (RUNTIME) {
    case Runtime.Node:
      return _nodeEncodeBase64;
    case Runtime.BrowserMain:
    case Runtime.BrowserWorker:
      return _browserEncodeBase64;
    default: {
      const _exhaustive: never = RUNTIME;
      return _exhaustive;
    }
  }
})();

function _browserEncodeBase64(v: string) {
  return btoa(unescape(encodeURIComponent(v)));
}

function _nodeEncodeBase64(v: string) {
  return Buffer.from(v).toString("base64");
}

/**
 * Utilities for tiling of an image into a multiresolution pyramid.
 */
import { type TileArea, type TileGeometryGenerator, tileGeometries } from "@/cache/SpatioTemporalTileCache/TileArea";
import type { GridDimension } from "@mm/api.meteomatics.com";
import type * as Mapbox from "mapbox-gl";
import { mercatorXfromLng, mercatorYfromLat } from "./mercator";

/**
 * Get the exact zoom level. For example, the returned value might be `3.5` for a zoom level exactly halfway between tile zoom 3 and tile zoom 4.
 *
 * @param viewport size of the map viewport in pixel. whether this should be hardware pixel or software pixel depends on your rendering code. The computations are pixel density based.
 * @param screenBounds latitude and longitude, axis aligned bounding box specifying the portion of the world visible in the viewport
 * @param tileSize reference tile size, MUST NOT be denormalized using a z LOD bias
 */
export function zoomLevel(viewport: GridDimension, screenBounds: Mapbox.LngLatBounds, tileSize: number): number {
  // short note: tilesize in mapbox is harcoded to 512.
  // short note: halfing the tilesize corresponds to an increment of the zoom. So we could compute zoom for tilesize 256 as `this.map.zoom() + 1` where `this.map.zoom()` is the zoom level computed by mapbox
  // short note: doubling the tilesize corresponds to a decrement of the zoom. So we could compute zoom for tilesize 1024 as `this.map.zoom() - 1`
  // however: these tricks fail as soon as clamping is involved ;)

  // translate our viewport problem to the whole world/map
  const degreeWidth = Math.abs(screenBounds.getWest() - screenBounds.getEast());
  const worldWidthPx = viewport.width * (360 / degreeWidth);
  const worldWidthTiles = worldWidthPx / tileSize;

  const correspondingZoom = Math.log2(worldWidthTiles) - 1;
  return correspondingZoom;
}

/**
 * Given some pixel on the whole map/world, get the tile id
 *
 * @param tileSize reference tile size, MUST NOT be denormalized using the zLodBias
 */
export function pixelToTile(pixel: [number, number], tileSize: number): [number, number] {
  return [~~(Math.ceil(pixel[0] / tileSize) - 1), ~~(Math.ceil(pixel[1] / tileSize) - 1)];
}

/**
 *
 * @param tileSize reference tile size, MUST NOT be denormalized using the zLodBias
 */
export function lnglatToPixel(coord: Mapbox.LngLat, zoom: number, tileSize: number): [number, number] {
  const meractorX = mercatorXfromLng(coord.lng);
  const meractorY = mercatorYfromLat(coord.lat);

  const worldSizeInPixel = (1 << zoom) * tileSize;

  return [meractorX * worldSizeInPixel, meractorY * worldSizeInPixel];
}

/**
 *
 * @param tileSize reference tile size, MUST NOT be denormalized using the zLodBias
 */
export function tilesInMapBounds(
  map: Mapbox.Map,
  tileSize: number,
  zLodBias: number,
): { zoom: number; zoomWithPrecision: number; viewportArea: TileArea } {
  const viewportPixelSize = { width: map.getCanvas().width, height: map.getCanvas().height };
  const bounds = map.getBounds();

  return tilesInPixelBounds(viewportPixelSize, bounds, tileSize, zLodBias);
}

/**
 *
 * @param tileSize reference tile size, MUST NOT be denormalized using the zLodBias
 */
export function tilesInPixelBounds(
  viewportPixelSize: GridDimension,
  bounds: Mapbox.LngLatBounds,
  tileSize: number,
  zLodBias: number,
): { zoom: number; zoomWithPrecision: number; viewportArea: TileArea } {
  const zoomWithPrecision = zoomLevel(viewportPixelSize, bounds, tileSize);
  const zoom = Math.round(zoomWithPrecision);

  // this is not the full view frustrum culled tile pyramid, but only the optimal zoom level
  const tiles = tilesInBounds(bounds, zoom, tileSize, zLodBias);

  return { zoom, zoomWithPrecision, viewportArea: tiles };
}

/**
 * Get tiles within the given bounds.
 *
 * Returns a cover, meaning that tiles partially within the given bounds are part of the returned set.
 */
export function tilesInBounds(bounds: Mapbox.LngLatBounds, zoom: number, tileSize: number, zLodBias: number): TileArea {
  const viewportMapScrollTopLeftPixel = lnglatToPixel(bounds.getNorthWest(), zoom, tileSize);
  const viewportMapScrollBottomRightPixel = lnglatToPixel(bounds.getSouthEast(), zoom, tileSize);

  return pixelsToTileArea(viewportMapScrollTopLeftPixel, viewportMapScrollBottomRightPixel, zoom, tileSize, zLodBias);
}

/**
 * Given a scroll position in pixels, compute the set of visible tiles.
 *
 * @param viewportMapScrollTopLeftPixel scroll position of the top left viewport corner within the map
 * @param viewportMapScrollBottomRightPixel scroll position of the bottom right viewport corner within the map
 * @param zoom zoom level of the map
 * @param tileSize size of a tile in pixels
 */
export function pixelsToTileArea(
  viewportMapScrollTopLeftPixel: [number, number],
  viewportMapScrollBottomRightPixel: [number, number],
  zoom: number,
  tileSize: number,
  zLodBias: number,
): TileArea {
  // TODO: this essentially adds TILE_SIZE and then removes it with pixelToTile, we could implement `latlngToTile` as an optimization
  const topLeftTile = pixelToTile(viewportMapScrollTopLeftPixel, tileSize);
  const bottomRightTile = pixelToTile(viewportMapScrollBottomRightPixel, tileSize);

  // At the maximal zoom level, calculations are slightly imprecise and sometimes contains a pixel row of different worlds above and below.
  // however, we only want to wrap worlds along the x-axis. This clamp discards tiles of wrapped worlds along the y-axis
  const tilesPerWorld = 1 << zoom;
  const maxTileIndex = tilesPerWorld - 1;
  topLeftTile[1] = Math.min(maxTileIndex, Math.max(0, topLeftTile[1]));
  bottomRightTile[1] = Math.min(maxTileIndex, Math.max(0, bottomRightTile[1]));

  return {
    xyMin: topLeftTile,
    xyMax: bottomRightTile,
    z: zoom,
    zLodBias,
  };
}

/**
 * Get a set of CPU-side view frustrum culled tiles
 *
 * @param viewportMapScrollTopLeftPixel camera position, top left limit relative to map
 * @param viewportMapScrollBottomRightPixel camera position, bottom right limit relative to map
 * @param zoom camera position, height above map
 * @param tileSize
 *
 * @return tiles in row major order, from top left to bottom right tile
 */
export function tilesInViewport(
  viewportMapScrollTopLeftPixel: [number, number],
  viewportMapScrollBottomRightPixel: [number, number],
  zoom: number,
  tileSize: number,
  zLodBias: number,
): TileGeometryGenerator {
  const tileArea = pixelsToTileArea(
    viewportMapScrollTopLeftPixel,
    viewportMapScrollBottomRightPixel,
    zoom,
    tileSize,
    zLodBias,
  );
  return tileGeometries(tileArea);
}

import type { Area } from "../models/Area";
import type { BoundsIntersection } from "../models/BoundsIntersection";
import type { CoordinateSystem } from "../models/CoordinateSystem";
import type { GridRequest } from "../models/GridRequest";

// TODO: should maybe bee an overload of crop()
export function intersectWeatherModelBoundingBox<C extends CoordinateSystem>(
  gridRequest: GridRequest<C>,
  modelBounds: Area<CoordinateSystem.WGS84>,
): BoundsIntersection<Area<CoordinateSystem.WGS84>> {
  const intersection = modelBounds.contains(gridRequest.area.project(modelBounds.crs));
  return intersection;
}

export function isWholeAreaOutsideWeatherModelBoundingBox<C extends CoordinateSystem>(
  gridRequest: GridRequest<C>,
): boolean {
  if (!gridRequest.boundingBoxLimit) {
    return false;
  }
  return intersectWeatherModelBoundingBox(gridRequest, gridRequest.boundingBoxLimit).kind === "OutOfBounds";
}

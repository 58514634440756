import SvgIllustrationMap from "@/style/img/illustration_map.msvg";
import SvgIllustrationPlot from "@/style/img/illustration_plot.msvg";
import { useEffect, useRef } from "react";
import { type DragLayerMonitor, useDragLayer } from "react-dnd";
import { type DragableArea, type DraggingWindowState, useDesktop } from ".";
import { DragItemType } from "./DragItemType";
import { WindowDragPreview } from "./WindowDragPreview";

import Logger from "logging";
import { CssCursor, useMouseCursor } from "./hooks/useMouseCursor";
import { clampPositionToDraggableArea } from "./utils";

const logger = Logger.fromFilename(__filename);

const createTransformation = (x: number, y: number) => `translate(${x}px, ${y}px)`;

function clampCurrentOffsetToDraggableArea(monitor: DragLayerMonitor, draggableArea: DragableArea) {
  const clientOffset = monitor.getSourceClientOffset();
  if (clientOffset !== null) {
    const { x, y } = clientOffset;
    return clampPositionToDraggableArea(x, y, draggableArea);
  }
  return null;
}

export function WindowDragLayer(_: any) {
  const { changeCursor, resetCursor } = useMouseCursor();
  const draggableRef = useRef<HTMLDivElement>(null);
  const mousePosition = useRef<{ x: number; y: number }>({ x: 0, y: 0 });

  const { dragableArea: draggableArea } = useDesktop();
  const { itemType, isDragging, item } = useDragLayer((monitor) => {
    const isDragging = monitor.isDragging();

    // As React-dnd requires pointer-event:none; for all draggable elements,
    // CSS resets mouse cursor to default on drag start.
    // To overcome this, we need to change cursor at higher level while dragging
    if (isDragging) {
      changeCursor(CssCursor.Grabbing);
    } else {
      resetCursor();
    }

    const itemType = monitor.getItemType() as DragItemType;
    if (draggableRef.current) {
      if (itemType === DragItemType.GridElement) {
        const { x, y } = mousePosition.current;
        draggableRef.current.style.transform = createTransformation(x, y);
      } else {
        const offset = clampCurrentOffsetToDraggableArea(monitor, draggableArea);
        if (offset !== null) {
          const { x, y } = offset;
          draggableRef.current.style.transform = createTransformation(x, y);
        }
      }
    }

    return {
      item: monitor.getItem() as DraggingWindowState,
      itemType: monitor.getItemType() as DragItemType,
      isDragging,
    };
  });

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      mousePosition.current = { x: event.clientX, y: event.clientY };
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  if (!isDragging) {
    return null;
  }

  switch (itemType) {
    case DragItemType.NormalWindow:
    case DragItemType.LargeWindow:
    case DragItemType.SmallWindow:
    case DragItemType.ThinWindow:
    case DragItemType.ModalWindow:
      return (
        <div className="drag-preview-container" ref={draggableRef}>
          <WindowDragPreview {...item} />
        </div>
      );
    case DragItemType.GridElement:
      return (
        <div>
          <div
            className="drag-preview-container drag-preview-grid"
            ref={draggableRef}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="button--illustrated">
              <SvgIllustrationMap className={"illustration"} />
            </div>
            <div className="button--illustrated">
              <SvgIllustrationPlot className={"illustration"} />
            </div>
          </div>
        </div>
      );
    default: {
      const _exhaustive: never = itemType;
      logger.unreachable("entered unreachable code, `monitor.getItemType() as DragItemType` cast invalid");
      return _exhaustive;
    }
  }
}

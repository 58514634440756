import { useContext } from "react";
import { type ReactNode, createContext, useState } from "react";

interface OverlayContextType {
  showFooter: boolean;
  showHeader: boolean;
  processingImage: boolean;
  showVideoExportToast: boolean;
  setShowFooter: (visible: boolean) => void;
  setShowHeader: (visible: boolean) => void;
  setProcessingImage: (processing: boolean) => void;
  setShowVideoExportToast: (visible: boolean) => void;
}

const defaultContextValue: OverlayContextType = {
  showFooter: true,
  showHeader: true,
  processingImage: false,
  showVideoExportToast: true,
  setShowFooter: () => {
    console.warn("ShowFooter not set");
  },
  setShowHeader: () => {
    console.warn("ShowHeader not set");
  },
  setProcessingImage: () => {
    console.warn("ProcessingImage not set");
  },
  setShowVideoExportToast: () => {
    console.warn("ShowVideoExportToast not set");
  },
};

const ViewportOverlayContext = createContext<OverlayContextType>(defaultContextValue);

export function useOverlay() {
  return useContext(ViewportOverlayContext);
}

interface OverlayProviderProps {
  children?: ReactNode;
}

export function ViewportOverlayProvider({ children }: OverlayProviderProps) {
  const [showFooter, setShowFooter] = useState(true);
  const [showHeader, setShowHeader] = useState(true);
  const [processingImage, setProcessingImage] = useState(false);
  const [showVideoExportToast, setShowVideoExportToast] = useState(true);

  return (
    <ViewportOverlayContext.Provider
      value={{
        showFooter,
        showHeader,
        setShowFooter,
        setShowHeader,
        showVideoExportToast,
        setShowVideoExportToast,
        processingImage,
        setProcessingImage,
      }}
    >
      {children}
    </ViewportOverlayContext.Provider>
  );
}

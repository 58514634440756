import type { PopupPlotState } from "@/reducer/plot/state";

export enum PopupPlotActionType {
  ResetPopupPlot = 0,
  SetPopupPlotState = 1,
  SetShow = 2,
  SetTimeAndLayers = 3,
}
export interface ResetPopupPlot {
  type: PopupPlotActionType.ResetPopupPlot;
  payload: PopupPlotState;
}
export interface SetPopupPlotState {
  type: PopupPlotActionType.SetPopupPlotState;
  payload: PopupPlotState;
}
export interface SetShow {
  type: PopupPlotActionType.SetShow;
  payload: boolean;
}

export type PopupPlotsActions = SetShow | ResetPopupPlot | SetPopupPlotState;

type HashableObject = {
  [key: string]: string | number | null | undefined;
};

/**
 * Utility to generate an unique ID for an simple object.
 * Only supports a primitive value object as other nested objects can be cumbersome
 * to hash uniquely.
 */
export function hashSimpleObject(request: HashableObject) {
  const cacheId = Object.values(request)
    .map((val) => {
      return val;
    })
    .join("#");
  return cacheId;
}

/**
 * Given a generic Obj, creates an object type that contains all keys in the Obj,
 * and maps to a primitive values.
 *
 * This utility allows us to create a hand-written hashing funtion for an object.
 */
export type HashableValues<Obj> = { [key in keyof Required<Obj>]: string | number | boolean };

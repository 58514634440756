/**
 * Sets a value in sessionStorage. If partialUpdate is true, it merges the provided value
 * with the existing value under the same key.
 */
export function setSessionStorage<T extends object>(key: string, val: T, partialUpdate = false): void {
  try {
    if (!partialUpdate) {
      window.sessionStorage.setItem(key, JSON.stringify(val));
      return;
    }
    const item = window.sessionStorage.getItem(key);
    let newVal = val;
    if (item) {
      const existingVal = JSON.parse(item);
      newVal = { ...existingVal, ...newVal };
    }
    window.sessionStorage.setItem(key, JSON.stringify(newVal));
  } catch (e) {
    console.error(e);
  }
}
/**
 * Retrieves a value from sessionStorage. Returns null if the key does not exist or
 * if the data is not a valid JSON.
 */
export function getSessionStorage<T extends object>(key: string): T | null {
  const val = window.sessionStorage.getItem(key);
  if (!val) {
    return null;
  }
  try {
    return JSON.parse(val);
  } catch (e) {
    console.error(e);
    return null;
  }
}

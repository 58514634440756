import { Button, ButtonBar } from "@/overlay/components/CommonParts/buttons";
import * as windowManager from "@/overlay/components/window";
import { t } from "@lingui/macro";
import type { DOMAttributes, HTMLProps, PropsWithChildren } from "react";
import type { UseFormProps } from "react-hook-form";

type ModeSwitchableFormProps = PropsWithChildren<{
  onSubmit: DOMAttributes<HTMLFormElement>["onChange"];
  /**
   * During the onSubmit mode, this callback gets called upon clicking on abort button.
   */
  onCancel?: () => void;
  submitMode: Exclude<UseFormProps<any>["mode"], undefined>;
}> &
  HTMLProps<HTMLFormElement>;

/**
 * A form container that
 * 1. Changes the event listener behavior based on the submit mode.
 * 2. Renders the necessary additional components such as a submit button.
 */
export function ModeSwitchableForm(props: ModeSwitchableFormProps) {
  const { submitMode, onSubmit, onCancel, children, ...rest } = props;
  const formProps = {
    // e.g: "onChange": onSubmit -> <form onChange={onSubmit}/>
    // e.g: "onSubmit": onSubmit -> <form onSubmit={onSubmit}/>
    [submitMode]: onSubmit,
  };

  return (
    <form {...rest} {...formProps}>
      {children}
      {submitMode === "onSubmit" && (
        // This component cannot be reused with different styles.
        // If we want to use different style for the buttons in the future,
        // we could inject different button components from props.
        <windowManager.WindowSection>
          <ButtonBar align="right">
            {onCancel && <Button onClick={onCancel} type="button">{t`Cancel`}</Button>}
            <Button primary type="submit">
              {t`Apply`}
            </Button>
          </ButtonBar>
        </windowManager.WindowSection>
      )}
    </form>
  );
}

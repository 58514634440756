import { type OAuthSession, OAuthState, type WithArrivalTime } from "@/auth";
import { ENV } from "@/env";
import * as LoginApi from "@mm/login.meteomatics.com";
import { oAuthConfiguration } from "../configuration";

const oauthApi = new LoginApi.OAuthApi(new LoginApi.Configuration({ basePath: ENV.baseUrlLogin }));

/**
 * Check if we have an active login:
 * - The user has a `refresh_token` from a previous login.
 * - The `access_token` can be expired.
 */
export function isLoggedIn(session: OAuthSession | null) {
  return session?.state === OAuthState.LoggedIn && session.token != null;
}

/**
 * Check if the session is authenticated: The user has a valid `access_token` that is not yet expired.
 */
export function isAuthenticated(session: OAuthSession | null) {
  return session?.state === OAuthState.LoggedIn && session.token != null && !isTokenExpired(session.token);
}

/**
 * Encode a buffer in base64.
 */
function base64EncodeBuffer(buffer: ArrayBuffer): string {
  return btoa(new Uint8Array(buffer).reduce((data, byte) => data + String.fromCharCode(byte), ""));
}

/**
 * Computes the code challenge based on the code verifier.
 * https://tools.ietf.org/html/rfc7636#appendix-A
 */
function generateCodeChallenge(codeVerifier: string): Promise<string> {
  const encoder = new TextEncoder();
  const data = encoder.encode(codeVerifier);
  return window.crypto.subtle.digest("SHA-256", data.buffer).then((value) => {
    return base64EncodeBuffer(value).replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, "");
  });
}

/**
 * Generates the code verifier according to
 * https://tools.ietf.org/html/draft-parecki-oauth-v2-1-03#appendix-A.17
 *
 * code-challenge = 43*128unreserved
 * unreserved = ALPHA / DIGIT / "-" / "." / "_" / "~"
 * ALPHA = %x41-5A / %x61-7A
 * DIGIT = %x30-39
 */
function generateCodeVerifier(length: number): string {
  const charset: string = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_.~";
  const array = new Uint8Array(length);
  window.crypto.getRandomValues(array);
  return Array.from(array, (value) => {
    return charset.charAt(value % charset.length);
  }).join("");
}

export function oauthHandleSignin(redirectUrl?: string): Promise<OAuthSession> {
  // RFC limits it to 128 characters.
  const codeVerifier = generateCodeVerifier(128);
  return generateCodeChallenge(codeVerifier).then((codeChallenge) => {
    const params = new URLSearchParams();
    params.append("client_id", oAuthConfiguration.clientId);
    params.append("code_challenge_method", oAuthConfiguration.codeChallengeMethod);
    params.append("code_challenge", codeChallenge);
    params.append("redirect_uri", oAuthConfiguration.oauthRedirectUrl);
    params.append("response_type", "code");
    const url = `${oAuthConfiguration.oauthAuthorizationUrl}?${params.toString()}`;

    return {
      state: OAuthState.AuthorizationCode,
      redirectUrl: redirectUrl,
      authorizationUrl: url,
      codeVerifier: codeVerifier,
      codeChallenge: codeChallenge,
    };
  });
}

// TODO: we do not want to write these decoders/validators by hand. OpenAPI can generate these validators, but
// the current master is broken. We are waiting on an upstream patch. (2. Dec 2020)
export function decodeOAuthSession(storage: any): storage is null | OAuthSession {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const _if_this_errors_please_add_the_field_and_a_check_below: OAuthSession = {
    state: OAuthState.LoggedOut,
    codeVerifier: undefined,
    codeChallenge: undefined,
    authorizationUrl: undefined,
    redirectUrl: undefined,
    token: undefined,
  };

  return (
    storage === null ||
    (Object.hasOwn(storage, "state") &&
      typeof storage.state === "number" &&
      Object.hasOwn(storage, "code_verifier") === (typeof storage.code_verifier === "string") &&
      Object.hasOwn(storage, "code_challenge") === (typeof storage.code_challenge === "string") &&
      Object.hasOwn(storage, "authorization_url") === (typeof storage.authorization_url === "string") &&
      Object.hasOwn(storage, "redirect_url") === (typeof storage.redirect_url === "string") &&
      Object.hasOwn(storage, "token") === decodeTokenWithArrivalTime(storage.token))
  );
}

function decodeToken(token: any): token is LoginApi.OAuthTokenResponse {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const _if_this_errors_please_add_the_field_and_a_check_below: LoginApi.OAuthTokenResponse = {
    access_token: "",
    expires_in: 0,
    token_type: LoginApi.OAuthTokenTypes.bearer,
    refresh_token: "",
  };

  return (
    Object.hasOwn(token, "access_token") &&
    typeof token.access_token === "string" &&
    Object.hasOwn(token, "expires_in") &&
    typeof token.expires_in === "number" &&
    Object.hasOwn(token, "refresh_token") &&
    typeof token.refresh_token === "string" &&
    Object.hasOwn(token, "token_type") &&
    typeof token.token_type === "string"
  );
}

function decodeTokenWithArrivalTime(token: any): token is WithArrivalTime<LoginApi.OAuthTokenResponse> {
  if (token == null) {
    return false;
  }
  return decodeToken(token) && Object.hasOwn(token, "arrivalTimeMillis") && typeof token.access_token === "string";
}

export function getTokenExpirationDate(token: WithArrivalTime<LoginApi.OAuthTokenResponse>): number {
  const tokenValidityDurationMillis = token.expires_in * 1000;
  return token.arrivalTimeMillis + tokenValidityDurationMillis;
}

// Given a __newly__ aquired token, compute the delay after which a new token should be acquired
export function computeRefreshDelayMillis(token: WithArrivalTime<LoginApi.OAuthTokenResponse>) {
  // shorten request interval by 1 minute to ensure that a valid token is always available.
  // Otherwise a token may be unavailable because the waiting time is extended by the
  // roundtrip-time for the token.
  const conservativeRoundTripTimeMillis = 60000;
  const delayMillis = getRemainingValidityDuration(token) - conservativeRoundTripTimeMillis;
  return Math.max(0, delayMillis);
}

// May be negative for already expired tokens.
function getRemainingValidityDuration(token: WithArrivalTime<LoginApi.OAuthTokenResponse>): number {
  return getTokenExpirationDate(token) - Date.now();
}

/**
 * Handle token refresh.
 *
 * https://tools.ietf.org/html/draft-parecki-oauth-v2-1-03#section-6
 */
export function oauthHandleRefreshToken(session: OAuthSession): Promise<OAuthSession | null> {
  if (!session?.token || session.state !== OAuthState.LoggedIn) {
    return Promise.resolve(null);
  }
  return oauthApi
    .oauth2Token({
      grantType: "refresh_token",
      clientId: oAuthConfiguration.clientId,
      refreshToken: session.token.refresh_token,
    })
    .then((token) => {
      return {
        state: OAuthState.LoggedIn,
        token: decodeOAuthTokenResponse(token),
      };
    })
    .catch(() => ({
      state: OAuthState.LoggedOut,
    }));
}

/**
 * Check if a token is expired.
 */
export function isTokenExpired(token: WithArrivalTime<LoginApi.OAuthTokenResponse>) {
  return getRemainingValidityDuration(token) <= 0;
}

export function decodeOAuthTokenResponse(
  token: LoginApi.OAuthTokenResponse,
): WithArrivalTime<LoginApi.OAuthTokenResponse> {
  return { ...token, arrivalTimeMillis: Date.now() };
}

/**
 * Handle the authorization code received from the login server.
 *
 * Use the code to obtain the token from the server.
 * https://tools.ietf.org/html/draft-parecki-oauth-v2-1-03#section-4.1.3
 */
export function oauthHandleAuthorizationCode(code: string, session: OAuthSession): Promise<OAuthSession | null> {
  if (!session?.codeChallenge || !session?.codeVerifier || session.state !== OAuthState.AuthorizationCode) {
    return Promise.reject();
  }
  return oauthApi
    .oauth2Token({
      grantType: "authorization_code",
      clientId: oAuthConfiguration.clientId,
      code: code,
      codeVerifier: session.codeVerifier,
      redirectUri: oAuthConfiguration.oauthRedirectUrl,
    })
    .then((token) => {
      return {
        state: OAuthState.LoggedIn,
        redirectUrl: session.redirectUrl,
        token: decodeOAuthTokenResponse(token),
      };
    });
  // ToDo: There is currently a bug which calls this function multiple times. If we return { state: OAuthState.loggedIn }
  // during an error, the application will log itself out, thus we silently ignore it.
}

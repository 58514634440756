import { Area } from "../models";
import { DateTime_fromTransferable, DateTime_toTransferable } from "../models/DateTime";
import type { MeteomaticsApiError, MeteomaticsApiError_Transferable } from "./MeteomaticsApiError";

/**
 * Encode api error into a format that can be passed between threads
 */
export function MeteomaticsApiError_toTransferable(e: MeteomaticsApiError): MeteomaticsApiError_Transferable {
  switch (e.kind) {
    case "BoundsError":
      return {
        ...e,
        isEncodedApiError: true,
        kind: "BoundsError_Transferable",
        queried: e.queried.toTransferable(),
        available: e.available.toTransferable(),
      };
    case "TimeError":
      return {
        ...e,
        isEncodedApiError: true,
        kind: "TimeError_Transferable",
        given: DateTime_toTransferable(e.given),
        availableStart: DateTime_toTransferable(e.availableStart),
        availableEnd: DateTime_toTransferable(e.availableEnd),
      };
    case "ComputationTimeoutError":
      return {
        ...e,
        isEncodedApiError: true,
        kind: "ComputationTimeoutError_Transferable",
        processingTime: e.processingTime.toString(),
      };
    case "AuthError":
    case "UnknownError":
    case "UnknownParameterError":
    case "MissingDataError":
      return { ...e, isEncodedApiError: true };
    default: {
      const _exhaustive: never = e;
      return _exhaustive;
    }
  }
}

/**
 * Decode api error into a format that can be passed between threads
 */
export function MeteomaticsApiError_fromTransferable(e: MeteomaticsApiError_Transferable): MeteomaticsApiError {
  switch (e.kind) {
    case "BoundsError_Transferable":
      return {
        ...e,
        isEncodedApiError: false,
        kind: "BoundsError",
        queried: Area.fromTransferable(e.queried),
        available: Area.fromTransferable(e.available),
      };
    case "TimeError_Transferable":
      return {
        ...e,
        isEncodedApiError: false,
        kind: "TimeError",
        given: DateTime_fromTransferable(e.given),
        availableStart: DateTime_fromTransferable(e.availableStart),
        availableEnd: DateTime_fromTransferable(e.availableEnd),
      };
    case "ComputationTimeoutError_Transferable":
      return {
        ...e,
        isEncodedApiError: false,
        kind: "ComputationTimeoutError",
        processingTime: Number(e.processingTime),
      };
    case "AuthError":
    case "UnknownError":
    case "UnknownParameterError":
    case "MissingDataError":
      return { ...e, isEncodedApiError: false };
    default: {
      const _exhaustive: never = e;
      return _exhaustive;
    }
  }
}

export function isTransferableApiError(v: any): v is MeteomaticsApiError_Transferable {
  return v && v.isEncodedApiError === true;
}

export function isNonTransferableApiError(v: any): v is MeteomaticsApiError {
  return v && v.isEncodedApiError === false;
}

export function isApiError(v: any): v is MeteomaticsApiError_Transferable | MeteomaticsApiError {
  return v && typeof v.isEncodedApiError === "boolean";
}

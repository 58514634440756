import { PotentiallyVisibleTileSet } from "@/cache/SpatioTemporalTileCache/PotentiallyVisibleTileSet";
import type { SpatiotemporalTileCache } from "@/cache/SpatioTemporalTileCache/SpatiotemporalTileCache";
import type { SpatiotemporalTileLookupSettings } from "@/cache/SpatioTemporalTileCache/SpatiotemporalTileLookupSettings";
import { tileGeometries, wrapIntoWorldZero } from "@/cache/SpatioTemporalTileCache/TileArea";
import type { TileDataDescription } from "@/cache/SpatioTemporalTileCache/TileDataDescription";
import { TILE_MAX_ZOOM_MAGNIFICATION, TILE_MAX_ZOOM_MINIFICATION } from "@/cache/SpatioTemporalTileCache/config";
import type { AreaRequest } from "@/layers/AreaRequest";
import type { CoordinateSystem } from "@mm/api.meteomatics.com";
import type { SliceDataDescription } from "../SpatioTemporalTileCache/SliceDataDescription";
import type { SpatiotemporalTileCacheStats } from "../SpatioTemporalTileCache/SpatioTemporalTileCacheStats";
import { type Stats, defaultCacheFormatter, jsxTable } from "../Stats";
import type {
  ApiRequestFromGetter,
  RequestParamsFromGetter,
  TileFromGetter,
  TileGetter,
} from "./TileGetters/TileGetter";
import type { WMSRequestParams } from "./TileGetters/WMSTileGetter";

export type WithDirtyMarker<T> = { isDirty: boolean; val: T };

// TODO: the garbage collector / cache replacement policy of the tile caches does currently not invalidate PVSes cached
// in the `PvsCache`. This is not an issue:
// - `Tile#unload` will delete GPU state and will reupload on demand (However, reupload was deemed unlikely by the cache replacement policy)
// - the PVSCache will hold a reference to the `Tile` (which was already dropped from the tile cache). So CPU memory usage
//   will only drop as soon as the PVS is evicted from the PVSCache.
//
// In practice, this will not cause any trouble, since the TileCache should significantly outgrow the PVSCache while both have
// a coupled access pattern (PVS Cache is the only one fetching data from the TileCache).

/**
 * An utility to serve tiles based on a viewport area request.
 *
 * Note (25.08.2023):
 * This class has been refactored to support different data type of tile, not just WMS.
 * However, it's WIP and still has too many things going on.
 */
export class PvsTileService<
  TCrs extends CoordinateSystem,
  TTileGetter extends TileGetter<any, any>,
  TTile extends TileFromGetter<TTileGetter> = TileFromGetter<TTileGetter>,
  C extends SpatiotemporalTileCache<TTileGetter> = SpatiotemporalTileCache<TTileGetter>,
  RequestParams extends RequestParamsFromGetter<TTileGetter> = RequestParamsFromGetter<TTileGetter>,
> implements Stats<SpatiotemporalTileCacheStats>
{
  constructor(readonly networkCache: C) {}

  /**
   * Given a area request for a certain area on a globe viewport,
   * returns a tile set (PVS) containing all the tiles within the area.
   *
   * The returned set contains only the tiles from the cache which are already loaded
   * **at the time of calling the method (=instant tile set)**.
   * This means, in order to get all the loaded tiles, you have to call this method at least twice:
   * - The first time to trigger the requests
   * - The second time after all the requests have resolved.
   *
   * Note: This method is optimized for the current layer implementation where the render function get called
   * on every frame. In other words, this method allows you to ignore the async logic for API requests,
   * and focus on rendering current tile data.
   *
   * In the future, if we need to run a callback when each tile is loaded, it is recommended
   * to create a separate method.
   */
  public getTileSetInstant(areaRequest: AreaRequest<RequestParams>): PotentiallyVisibleTileSet<TTile> {
    // is permanently called
    return this.computePvs(areaRequest);
  }

  /**
   * Returns a promise that resolves when "all tiles within the request area are fetched or failed".
   * Unlike getInstantTileSet, this returns a promise so you can run operation that needs to wait for
   * all tile requests to be settled.
   */
  public getTileSetPromise(areaRequest: AreaRequest<RequestParams>): Promise<PotentiallyVisibleTileSet<TTile>> {
    // is permanently called
    const pvs = this.computePvs(areaRequest);
    return new Promise((resolve) => {
      Promise.allSettled(pvs.tilePromises).then(() => resolve(pvs));
    });
  }

  /**
   * While getTileSet allows you to retrieve "all tiles within a requested area"
   * This method gives a single tile at a specified location.
   */
  public getSingleTile(tileDesc: TileDataDescription<RequestParams>) {
    return this.networkCache.retrieveTile(tileDesc);
  }

  public getDataSlice(sliceDesc: SliceDataDescription<TCrs, RequestParams>) {
    return this.networkCache.retrieveSlice<TCrs>(sliceDesc);
  }

  /**
   * A method to retrieve all API requests for an viewport area request without actually invoking the API calls.
   *
   * Currently used to externally retrieve what API requests are constructed within the tiling service,
   * in order to use for the abort functionality.
   */
  public getApiRequestsForArea(areaRequest: AreaRequest<WMSRequestParams<TCrs>>): ApiRequestFromGetter<TTileGetter>[] {
    const viewportDataDesc: Omit<TileDataDescription<WMSRequestParams<TCrs>>, "geometry"> = {
      datetime: areaRequest.datetime,
      requestParams: areaRequest.requestParams,
    };

    const optimalTilesWorldZero = wrapIntoWorldZero(areaRequest.area);

    // As "tileGeometries" is generator, most elegant way to get array out of it is to use spread operator
    const apiRquests = [...tileGeometries(...optimalTilesWorldZero)].map<ApiRequestFromGetter<TTileGetter>>(
      (geometry) => {
        const tileDataDesc = {
          ...viewportDataDesc,
          geometry,
        };

        return this.networkCache.conf.tileGetter.createTileApiRequest(tileDataDesc, this.networkCache.conf.tileSize);
      },
    );
    return apiRquests;
  }

  /**
    When a user interacts with UI and on-going requests become unnecessary, we abort these requests
  **/
  dropObsoleteTiles(urls: string[]) {
    // Note: It is questionable if the abort functionality should be handled in this tiling service.
    this.networkCache.dropObsoleteTiles(urls);
  }

  private computePvs(areaRequest: AreaRequest): PotentiallyVisibleTileSet<TTile> {
    // [DEBUG-RECOMPUTATION-FREQUENCY] console.log("recomputing pvs", this.fingerprint(areaRequest));
    const pvsEmpty = new PotentiallyVisibleTileSet<TTile>(areaRequest.datetime, areaRequest.datetime.toSeconds());

    const viewportDataDesc: Omit<TileDataDescription, "geometry"> = {
      datetime: areaRequest.datetime,
      requestParams: areaRequest.requestParams,
    };

    const lookupConf: SpatiotemporalTileLookupSettings<TTile> = {
      // Note: (25.09.2023) lookupConf seems to have been used for invalidating
      // some tiles, but we already have aborting logic under "request-controller/ActiveRequestMemory.ts"
      // and in the middleware. So this code below might be obsolete.
      onLoad: (tile) => {},
      // there might be a race condition between actual viewport state and the abort controller (from a theoretical standpoint, I haven't observed the issue yet since
      // the current code structure seems to guarantee order between both updates.)
      // trigger a repaint to make sure the viewport is fully populated after the users stops navigation.
      // TODO: would be interesting to track if a abort->requeue actually happens
      onAbort: (tile) => {}, // Currently this catches the exception in deeper cache. In the future, we should get rid of this.
      maxZoomMagnification: TILE_MAX_ZOOM_MAGNIFICATION,
      maxZoomMinification: TILE_MAX_ZOOM_MINIFICATION,
    };

    // TODO: composite should only return world zero tiles, the caller should reconstruct the whole viewport. Our current caching scheme caches based on world zero content
    // so tiles out of world zero might be missing otherwise
    const pvsFilled = this.networkCache.compositeViewport(areaRequest.area, viewportDataDesc, pvsEmpty, lookupConf);

    return pvsFilled;
  }

  stats() {
    return this.networkCache.stats_;
  }

  statsJsx(): JSX.Element {
    return jsxTable(this.stats(), defaultCacheFormatter);
  }

  descJsx(): JSX.Element {
    return (
      <>
        Cache Size Limit is {this.networkCache.conf.formatSize(this.networkCache.conf.maxSize)}, Replacement Policy is a
        garbage collection pass followed by a custom multimodal distance measure based on the LRU policy.
      </>
    );
  }

  label(): string {
    return this.networkCache.conf.label;
  }
}

import type { AsyncResult } from "@/cache/AsyncResult";
import { IsoLinesRequestKind } from "@/cache/GeoJSONCache";
import { networkCaches } from "@/cache/GlobalCache";
import type { CheckedProps, LayerBase, PropsChecker } from "@/layers";
import type { ScenePublicApi } from "@/layers/Compositor";
import { generateIsoLinesLayerCacheId } from "@/layers/geojson/LayerUtils";
import { MapboxGeoJSONLayer } from "@/layers/geojson/MapboxGeoJSONLayer";
import { SENTRY_TRANSACTION_NAMES } from "@/sentry/TransactionConst";
import { captureTransaction } from "@/sentry/helpers";
import { safeFmt } from "@/utility/safeFmt";
import { getModelBoundingBox } from "@/weather-parameters/lookup";
import type { GeoJSONFeatureCollection, IsoLinesRequest } from "@mm/api.meteomatics.com";
import type { GuiTimeZone, PressureSystemLayer } from "@mm/metx-workbench.meteomatics.com";
import type { DateTime } from "luxon";
import type { SymbolLayout } from "mapbox-gl";

export class PressureSystemLayerImpl extends MapboxGeoJSONLayer<PressureSystemLayer> {
  readonly mapboxTextLayerId: string;

  constructor(id: number, props: PressureSystemLayer, scene: ScenePublicApi, timezone: GuiTimeZone) {
    super(id, props, scene, timezone);

    this.mapboxTextLayerId = `${this.humanReadableId()}-text`;
    this.scene.getMapboxMap().addLayer({
      id: this.mapboxTextLayerId,
      type: "symbol",
      source: this.mapbox_source,
      layout: {
        "text-field": ["get", "value"], // value is pressure
        "text-ignore-placement": true,
        "text-allow-overlap": true,
        "text-offset": [0, 1.3],
      },
      paint: {
        "text-halo-color": "#ffffff",
        "text-halo-blur": 0.1,
        "text-halo-width": 2,
      },
    });

    // Update the symbol layer. (label)
    this.updateMapboxLayoutProperties({
      visibility: this.props.show ? "visible" : "none",
      "text-field": ["get", "title"], // title is L or H
      "text-size": 26,
      "text-ignore-placement": true,
      "text-allow-overlap": true,
    });
    this.updateMapboxPaintProperties({
      "text-halo-color": "#ffffff",
      "text-halo-blur": 0.5,
      "text-halo-width": 2,
    });
  }

  createRequest(dateTimeWithOffset: DateTime): IsoLinesRequest {
    const map = this.scene.getMapboxMap();

    /**
     * TODO: rm distance 0 and remove width and height on url request
     * (has so far no effect on url but may on the request splitter)
     */
    const { area } = this.computeBoundingBox(0);
    const { width, height } = map.getCanvas();
    return {
      path: "get_highs_lows",
      area: area,
      parameter: this.props.parameter_unit,
      model: this.props.model,
      ensSelect: this.props.ens_select ? this.props.ens_select : "",
      boundingBoxLimit: getModelBoundingBox(this.props.model),
      datetime: dateTimeWithOffset,
      height: height,
      width: width,
      radiusGaussianFilter: this.props.filter_gauss,
      radiusMedianFilter: this.props.filter_median,
      isolineRange: this.props.value_range,
      isolineValues: this.props.values,
      calibrated: this.props.calibrated,
    };
  }

  updateTrigger() {
    const dateTimeWithOffset = this.scene.getDisplayTimeWithOffset();
    const request = this.createRequest(dateTimeWithOffset);

    const result = networkCaches.geojson_cache.retrieveIsoLine(request, IsoLinesRequestKind.HighsAndLows);
    this.updateSource(result, dateTimeWithOffset);
  }

  fetchData(timeFrame: DateTime): Promise<void> {
    const dateTimeWithOffset = this.scene.getDateTimeWithOffset(timeFrame);
    const request = this.createRequest(dateTimeWithOffset);
    const tags = {
      layerKind: `${this.props.kind ? this.props.kind : "undefined"}`,
      parameter: `${this.props.parameter_unit}`,
      requestedDate: `${dateTimeWithOffset}`,
    };

    const { asynchronous } = networkCaches.geojson_cache.retrieveIsoLine(request, IsoLinesRequestKind.HighsAndLows);

    if (!this.scene.isAnimation()) {
      return captureTransaction(asynchronous, SENTRY_TRANSACTION_NAMES.PRESSURE_SYSTEM_LAYER_LOADING_SPEED, tags);
    }

    return asynchronous
      .then(() => {
        return Promise.resolve();
      })
      .catch((err) => {
        return Promise.resolve();
      });
  }
  beforeRender(): void {
    if (this.scene.isAnimation()) {
      this.updateTrigger();
    } else {
      this.debouncedUpdateTrigger();
    }
  }

  humanReadableId(): string {
    return safeFmt`metx.pressureSystemlayer#${this.uid}`;
  }

  checker(): PropsChecker<PressureSystemLayer, LayerBase<PressureSystemLayer>> {
    type CheckerProps = CheckedProps<PressureSystemLayer>;
    const checkLayerProp = (property: CheckerProps) => (prev: any, curr: any) => {
      const changed = prev[property] !== curr[property];
      if (changed) {
        this.setLayerProps({ [property]: curr[property] });
        switch (property) {
          case "model":
            this.beforeRender();
            break;
          case "parameter_unit":
            this.beforeRender();
            break;
          case "ens_select":
            this.beforeRender();
            break;
          case "show":
            this.updateLayoutProperties({ visibility: curr.show ? "visible" : "none" });
            break;
          case "calibrated":
            this.beforeRender();
            break;
          case "filter_median":
          case "filter_gauss":
          case "values":
          case "value_range":
            this.beforeRender();
            break;
          case "opacity":
          case "text_size":
          case "text_color":
          case "line_width":
          case "line_color":
          case "custom_options":
            break;
          default: {
            const _exhaustive: never = property;
            return _exhaustive;
          }
        }
      }
      return changed;
    };
    return {
      model: checkLayerProp("model"),
      parameter_unit: checkLayerProp("parameter_unit"),
      opacity: checkLayerProp("opacity"),
      show: checkLayerProp("show"),
      calibrated: checkLayerProp("calibrated"),
      text_size: checkLayerProp("text_size"),
      text_color: checkLayerProp("text_color"),
      line_width: checkLayerProp("line_width"),
      line_color: checkLayerProp("line_color"),
      filter_median: checkLayerProp("filter_median"),
      filter_gauss: checkLayerProp("filter_gauss"),
      values: checkLayerProp("values"),
      value_range: checkLayerProp("value_range"),
      ens_select: checkLayerProp("ens_select"),
      custom_options: checkLayerProp("custom_options"),
    };
  }

  updateLayoutProperties(props: SymbolLayout) {
    for (const entry of Object.entries(props)) {
      this.scene.getMapboxMap().setLayoutProperty(this.mapbox_id, entry[0], entry[1]);
      this.scene.getMapboxMap().setLayoutProperty(this.mapboxTextLayerId, entry[0], entry[1]);
    }
  }

  getActiveWeatherParametersAsString(): { model: string; parameter: string }[] {
    return [];
  }

  get mapboxIndex(): string {
    return this.mapbox_id;
  }

  removeLayer(): void {
    this.scene.getMapboxMap().removeLayer(this.mapboxTextLayerId);
    super.removeLayer();
  }

  moveZIndex(beforeLayerId?: string) {
    const map = this.scene.getMapboxMap();
    super.moveZIndex(beforeLayerId);
    map.moveLayer(this.mapboxTextLayerId, beforeLayerId);
  }

  peekLayerCache(request: IsoLinesRequest): AsyncResult<GeoJSONFeatureCollection> {
    const cacheId = generateIsoLinesLayerCacheId(request, IsoLinesRequestKind.HighsAndLows);
    return networkCaches.geojson_cache.peekCache(cacheId);
  }
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
var GriddableToolType;
(function (GriddableToolType) {
    GriddableToolType["cartographic_map"] = "cartographic_map";
    GriddableToolType["energy_plot"] = "energy_plot";
    GriddableToolType["plot"] = "plot";
})(GriddableToolType = exports.GriddableToolType || (exports.GriddableToolType = {}));
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
var GuiTimeZone;
(function (GuiTimeZone) {
    GuiTimeZone["utc"] = "utc";
    GuiTimeZone["local"] = "local";
})(GuiTimeZone = exports.GuiTimeZone || (exports.GuiTimeZone = {}));
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
var LayoutType;
(function (LayoutType) {
    LayoutType["Map"] = "Map";
    LayoutType["Plot"] = "Plot";
    LayoutType["WeatherTable"] = "WeatherTable";
    LayoutType["LocationTable"] = "LocationTable";
    LayoutType["EnergyPlot"] = "EnergyPlot";
    LayoutType["Tephigram"] = "Tephigram";
    LayoutType["CountryPlot"] = "CountryPlot";
    LayoutType["Notes"] = "Notes";
    LayoutType["Undefined"] = "Undefined";
})(LayoutType = exports.LayoutType || (exports.LayoutType = {}));
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
var ProjectionNameEnum;
(function (ProjectionNameEnum) {
    ProjectionNameEnum["mercator"] = "mercator";
    ProjectionNameEnum["albers"] = "albers";
    ProjectionNameEnum["equalEarth"] = "equalEarth";
    ProjectionNameEnum["equirectangular"] = "equirectangular";
    ProjectionNameEnum["lambertConformalConic"] = "lambertConformalConic";
    ProjectionNameEnum["naturalEarth"] = "naturalEarth";
    ProjectionNameEnum["winkelTripel"] = "winkelTripel";
    ProjectionNameEnum["globe"] = "globe";
})(ProjectionNameEnum = exports.ProjectionNameEnum || (exports.ProjectionNameEnum = {}));
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
var RelativeDateTimePosition;
(function (RelativeDateTimePosition) {
    RelativeDateTimePosition["now_with_5min_precision"] = "now_with_5min_precision";
    RelativeDateTimePosition["now_with_10min_precision"] = "now_with_10min_precision";
    RelativeDateTimePosition["now_with_15min_precision"] = "now_with_15min_precision";
    RelativeDateTimePosition["now_with_30min_precision"] = "now_with_30min_precision";
    RelativeDateTimePosition["now_with_1h_precision"] = "now_with_1h_precision";
    RelativeDateTimePosition["now_with_6h_precision"] = "now_with_6h_precision";
    RelativeDateTimePosition["now_with_12h_precision"] = "now_with_12h_precision";
    RelativeDateTimePosition["day"] = "day";
})(RelativeDateTimePosition = exports.RelativeDateTimePosition || (exports.RelativeDateTimePosition = {}));
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
var RelativeDateTimeRoundingDirection;
(function (RelativeDateTimeRoundingDirection) {
    RelativeDateTimeRoundingDirection["forward"] = "forward";
    RelativeDateTimeRoundingDirection["backward"] = "backward";
})(RelativeDateTimeRoundingDirection = exports.RelativeDateTimeRoundingDirection || (exports.RelativeDateTimeRoundingDirection = {}));
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
var SymbolLayerType;
(function (SymbolLayerType) {
    SymbolLayerType["WeatherSymbol"] = "WeatherSymbol";
    SymbolLayerType["WeatherCode"] = "WeatherCode";
})(SymbolLayerType = exports.SymbolLayerType || (exports.SymbolLayerType = {}));
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
var Units;
(function (Units) {
    Units["kilometers"] = "kilometers";
    Units["miles"] = "miles";
    Units["radians"] = "radians";
    Units["degrees"] = "degrees";
})(Units = exports.Units || (exports.Units = {}));
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
var VerticalInterpolationType;
(function (VerticalInterpolationType) {
    VerticalInterpolationType["none"] = "none";
    VerticalInterpolationType["downscale"] = "downscale";
})(VerticalInterpolationType = exports.VerticalInterpolationType || (exports.VerticalInterpolationType = {}));
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
var ViewportKind;
(function (ViewportKind) {
    ViewportKind["ViewportFull"] = "ViewportFull";
    ViewportKind["ViewportCenterPoint"] = "ViewportCenterPoint";
    ViewportKind["ViewportContainBounds"] = "ViewportContainBounds";
})(ViewportKind = exports.ViewportKind || (exports.ViewportKind = {}));

import { useCapabilities } from "@/cache/Hooks";
import { FormField } from "@/overlay/components/form-field";
import { t } from "@lingui/macro";
import type { WfsFeatureType } from "@mm/api.meteomatics.com/models/WfsCapabilities";
import { useEffect, useMemo, useState } from "react";

export function TropicalCycloneFeatureSelect({
  tropicalyCyclone,
  change,
}: {
  tropicalyCyclone?: string;
  change: (propsValue: { aviation_type: string }) => void;
}) {
  const [cyclones, setCyclones] = useState<WfsFeatureType[]>([]);
  const { pending, capabilities } = useCapabilities();

  useEffect(() => {
    if (capabilities) {
      const filteredCyclones = capabilities.featureTypes.filter((cap) => cap.name?.includes("tropical_cyclone"));
      setCyclones(filteredCyclones);

      if (!tropicalyCyclone) {
        change({ aviation_type: filteredCyclones[0].name || "Cyclone" });
      }
    }
  }, [capabilities, tropicalyCyclone, change]);

  const selectedCyclone = useMemo(() => {
    if (!tropicalyCyclone && cyclones.length) return cyclones[0].name;
    return tropicalyCyclone;
  }, [tropicalyCyclone, cyclones]);

  return (
    <FormField label={t`Tropical Cyclone`}>
      {pending ? (
        <div>{t`Loading`}...</div>
      ) : (
        <select value={selectedCyclone} onChange={(event) => change({ aviation_type: event.target.value })}>
          {/* Hacky solution to make select input support missing value */}
          {/* https://github.com/sveltejs/svelte/issues/6126 */}
          <option style={{ display: "none" }} />
          {cyclones.map((tropicalCyclone, idx) => (
            <option
              key={`${tropicalCyclone.name}_${tropicalCyclone.defaultSrs}_${tropicalCyclone}`}
              value={tropicalCyclone.name}
            >
              {tropicalCyclone.title?.replace("tropical_cyclone_", "")}
            </option>
          ))}
        </select>
      )}
    </FormField>
  );
}

import type { SvgComponentProps } from "@/style/img/MapSvg";
import type React from "react";

const TableSvg: React.FunctionComponent<SvgComponentProps> = (props) => (
  <svg width="55" height="52" viewBox="0 0 55 52" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.38078 11.1138C10.7646 12.244 15.4732 12.9542 20.7056 12.9542C25.9379 12.9542 30.651 12.244 34.0169 11.1138C36.9845 10.1222 38.8196 8.92501 38.8196 7.75466C38.8196 6.58431 37.0024 5.38717 34.0169 4.3955C30.651 3.27429 25.9603 2.56851 20.71 2.56851C15.4598 2.56851 10.769 3.27429 7.38078 4.3955C1.08765 6.49497 0.90861 8.95628 7.38078 11.096V11.1138ZM2.60498 20.4453C2.83773 21.5397 4.61914 22.6386 7.38525 23.5722C10.6169 24.6532 15.0659 25.3367 20.0252 25.3947V27.9722C14.8018 27.9141 10.0663 27.177 6.57511 26.0112C5.17423 25.5654 3.84137 24.9296 2.61393 24.1216V31.8361C2.84668 32.9305 4.62809 34.0294 7.39421 34.963C10.6258 36.035 15.0704 36.723 20.0252 36.781V39.3495C14.8018 39.287 10.0663 38.5544 6.57511 37.3841C5.17408 36.9431 3.83989 36.3134 2.60946 35.5124V43.2179C2.8422 44.3123 4.62809 45.4112 7.38973 46.3448C10.6213 47.4214 15.0704 48.1316 20.0252 48.1673V50.7358C14.8018 50.6778 10.0708 49.9407 6.59749 48.7704C4.65495 48.1182 0 46.0098 0 43.4994V7.77699C0 5.35143 2.51099 3.31002 6.56169 1.96993C10.214 0.750451 15.2181 0 20.71 0C26.202 0 31.1971 0.750451 34.8539 1.96993C38.5107 3.18942 40.9591 4.99854 41.3664 7.12035C41.4154 7.26412 41.4397 7.41517 41.438 7.56705V20.3426H38.8509V11.6543C37.6098 12.4596 36.2653 13.0937 34.8539 13.5394C31.2061 14.7678 26.2109 15.5093 20.719 15.5093C15.2271 15.5093 10.2185 14.741 6.56169 13.5394C5.16149 13.1003 3.82744 12.4737 2.59603 11.6767V20.4453H2.60498Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.915 28.7233C24.915 26.5142 26.7095 24.7233 28.923 24.7233H50.9915C53.2051 24.7233 54.9995 26.5142 54.9995 28.7233V48C54.9995 50.2091 53.2051 52 50.9915 52H28.923C26.7095 52 24.915 50.2091 24.915 48V28.7233ZM26.9366 30.0549H39.0086V35.4148H26.9366V30.0549ZM39.0086 37.1609H26.9366V42.5209H39.0086V37.1609ZM26.9366 44.2669H39.0086V49.6268H26.9366V44.2669ZM52.839 30.0549H40.767V35.4148H52.839V30.0549ZM40.767 37.1609H52.839V42.5209H40.767V37.1609ZM52.839 44.2669H40.767V49.6268H52.839V44.2669Z"
      fill="black"
    />
  </svg>
);

export default TableSvg;

import { DateTime } from "luxon";
import { Area, Area_Transferable } from "./Area";
import { MultiParamRequest, MultiParamRequest_Transferable } from "./BaseRequest";
import { CoordinateSystem } from "./CoordinateSystem";
import { DateTime_fromTransferable, DateTime_toTransferable, DateTime_Transferable } from "./DateTime";
import { GridDimension } from "./GridDimension";
import { GridSamplingStrategy } from "./GridSamplingStrategy";

export interface GridRequest<C extends CoordinateSystem> extends GridDimension, MultiParamRequest {
  sampling: GridSamplingStrategy;
  area: Area<C>;
  datetime: DateTime;
  calibrated?: boolean;
  vertical_interpolation?: "none" | "downscale";
  initDate?: string;
}

export interface GridRequest_Transferable<C extends CoordinateSystem>
  extends GridDimension,
    MultiParamRequest_Transferable {
  sampling: GridSamplingStrategy;
  area: Area_Transferable<C>;
  datetime: DateTime_Transferable;
  step?: number;
}

export function GridRequest_toTransferable<C extends CoordinateSystem>(
  req: GridRequest<C>,
): GridRequest_Transferable<C> {
  return {
    ...req,
    area: req.area.toTransferable(),
    datetime: DateTime_toTransferable(req.datetime),
    boundingBoxLimit: req.boundingBoxLimit ? req.boundingBoxLimit.toTransferable() : null,
  };
}

export function GridRequest_fromTransferable<C extends CoordinateSystem>(
  req: GridRequest_Transferable<C>,
): GridRequest<C> {
  return {
    ...req,
    area: Area.fromTransferable(req.area),
    datetime: DateTime_fromTransferable(req.datetime),
    boundingBoxLimit: req.boundingBoxLimit && Area.fromTransferable(req.boundingBoxLimit),
  };
}

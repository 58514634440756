import type { LayerUnionWithAll } from "@/layers";
import { serializeMapId } from "@/map";
import type { MapId, Viewport } from "@mm/metx-workbench.meteomatics.com";
import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

type SerializedMapAndLayerId = string;

export function serializeMapAndLayerId(id: MapId & { layerId: number }): SerializedMapAndLayerId {
  return [id.map, id.profile, id.tab, id.layerId].join(".");
}

export type ViewportChanges = {
  [mapId: string]: Omit<Viewport, "id_profile" | "id">;
};

export type ActiveLayersPropsChanges = {
  [mapIdAndLayerId: string]: string; // props concat maybe
};

export type PrefetchStateType = {
  viewports: ViewportChanges;
  activeLayersProps: ActiveLayersPropsChanges;
};

const initialState: PrefetchStateType = {
  viewports: {},
  activeLayersProps: {},
};

const prefetchStateReducer = createSlice({
  name: "prefetchStates",
  initialState: initialState,
  reducers: {
    setViewportChanges(state, action: PayloadAction<{ id: MapId; viewport: Omit<Viewport, "id_profile" | "id"> }>) {
      if (action.payload.id) {
        state.viewports[serializeMapId(action.payload.id)] = action.payload.viewport;
      }
    },
    setLayerPropsChanges(state, action: PayloadAction<{ id: MapId; layers: LayerUnionWithAll[] }>) {
      if (action.payload.id) {
        for (const layer of action.payload.layers) {
          const id = layer.id;
          state.activeLayersProps[serializeMapAndLayerId({ ...action.payload.id, layerId: id })] =
            JSON.stringify(layer);
        }
      }
    },
  },
});

export const { setViewportChanges, setLayerPropsChanges } = prefetchStateReducer.actions;
export default prefetchStateReducer.reducer;

import { createTab, fetchTab } from "@/api/hooks/tab";
import type { TabBase } from "@mm/metx-workbench.meteomatics.com";
import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import type { RootState } from ".";

export type TabsState = {
  data: TabBase;
  isLoading: boolean;
  hasError: boolean;
};

const initialState: TabsState = {} as TabsState;

const tabMetaSlice = createSlice({
  name: "tabs",
  initialState: initialState,
  reducers: {
    setTabTitle(state, action: PayloadAction<{ profileId: number; id: number; title: string }>) {
      const { id, title } = action.payload;

      const tab = state.data;
      if (!tab) {
        console.error("Could not find tab with id", id);
        return;
      }

      tab.title = title;
    },
    setTabOrder(state, action: PayloadAction<{ profileId: number; id: number; order: number }>) {
      const { id, order } = action.payload;

      const tab = state.data;
      if (!tab) {
        console.error("Could not find tab with id", id);
        return;
      }

      tab.order = order;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTab.pending, (state, action) => {
        if (!state) {
          // biome-ignore lint/style/noParameterAssign: TODO don't modify state directly??
          state = { data: {} as TabBase, isLoading: true, hasError: false };
        } else {
          state.isLoading = true;
          state.hasError = false;
        }
      })
      .addCase(fetchTab.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        state.data = action.payload;
      })
      .addCase(fetchTab.rejected, (state, action) => {
        state.hasError = !!action.error.message;
        state.isLoading = false;
      });
    builder.addCase(createTab.fulfilled, (state, action) => {
      state.data = action.payload;
    });
  },
});

const selectTabMeta = (state: RootState) => state.tabGroup.present.tabMeta;

/**
 * WARNING: useTab hook has issues with reactivity
 */
export function useTab() {
  return useSelector(selectTabMeta);
}

export function usePresentTab() {
  return useSelector((state: RootState) => state.tabGroup.present);
}

// TODO return a slim tab, and use that in most of the places
// export function useCoreTab(id: number) {
export const { setTabTitle, setTabOrder } = tabMetaSlice.actions;
export default tabMetaSlice.reducer;

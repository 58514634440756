import type { GuiTimeZone, WindAnimationLayer } from "@mm/metx-workbench.meteomatics.com";
import type { DateTime } from "luxon";

import type { AsyncResult } from "@/cache/AsyncResult";
import type { ColorMapping } from "@/cache/ColorMapCache";
import { networkCaches } from "@/cache/GlobalCache";
import { DEFAULT_PARTICLE_AMOUNT, DEFAULT_PARTICLE_SIZE } from "@/constants/layerConfigAttributes";
import { computeGridDimensionFromStepAsHorizontal } from "@/layers/utility/getGridBoundingBox";
import { SentrySpans } from "@/sentry/SentrySpans";
import { SENTRY_TRANSACTION_NAMES } from "@/sentry/TransactionConst";
import { unsafeGetColormapByName } from "@/utility/unsafeGetColormapByName";
import { getModelBoundingBox } from "@/weather-parameters/lookup";
import { type CoordinateSystem, GridSamplingStrategyKind, type WindVectorRequest } from "@mm/api.meteomatics.com";
import type { EventData, MapboxEvent } from "mapbox-gl";
import { ChangeDetector } from "../ChangeDetector";
import { type CheckedProps, LayerBase, type PropsChecker } from "../LayerBase";
import type { SceneLayerApi } from "../SceneLayerApi";
import { WindLayer } from "./layer";
import { computeBoundingBoxForMercatorProjection, createwindMetaData } from "./utils";

export class WindAnimationLayerImpl extends LayerBase<WindAnimationLayer> {
  interacting = false;
  layer: WindLayer;

  private _layerId: string;
  private _amountChangeDetector: ChangeDetector<number>;

  constructor(id: number, props: WindAnimationLayer, scene: SceneLayerApi, timezone: GuiTimeZone) {
    super(id, props, scene, timezone);

    SentrySpans.discardLayerSpans();
    SentrySpans.endSpan(SENTRY_TRANSACTION_NAMES.MAP_LOADING_SPEED);
    this._layerId = this._constructHumanReadableId(id, props);
    const map = this.scene.getMapboxMap();
    this.layer = new WindLayer(scene, this.props, {
      id: this._layerId,
      options: {
        "particle-speed": ["interpolate", ["linear"], ["zoom"], 1, 0.25, 10, 1],
        "particle-size": this.props.custom_options?.size ?? DEFAULT_PARTICLE_SIZE,
        "particle-amount": this.props.custom_options?.amount ?? DEFAULT_PARTICLE_AMOUNT,
        opacity: this.props.opacity,
      },
    });

    // Need to ignore this error as mapbox typings doesn't support POC features
    // @ts-ignore
    map.addLayer(this.layer);

    map.on("movestart", this.handleMapManipulationStart);
    map.on("moveend", this.handleMapManipulationEnd);
    map.on("zoom", this.handleMapZoom);
    map.on("resize", this.handleMapResize);

    this.updateColorMap();

    this._amountChangeDetector = new ChangeDetector(this.props.custom_options?.amount ?? DEFAULT_PARTICLE_AMOUNT);
  }

  handleMapZoom = () => {
    this.layer.zoom();
  };

  handleMapResize = () => {
    this.layer.refresh();
  };

  handleMapManipulationStart = () => {
    // React to manipulation only when layer is visible
    if (this.props.show) {
      this.layer.freeze();
      this.interacting = true;
      this.layer.refresh();
    }
  };

  handleMapManipulationEnd = (e: MapboxEvent<MouseEvent | TouchEvent | WheelEvent | undefined> & EventData) => {
    this.layer.updateBounds(e.target.getBounds());
    // React to manipulation only when layer is visible
    this.interacting = false;
    this.layer.unfreeze();
  };

  createRequest(timeWithOffset: DateTime): WindVectorRequest<CoordinateSystem.WGS84> {
    const map = this.scene.getMapboxMap();
    const canvas = map.getCanvas();
    const step = 128; //TODO: Could make the Image more granularly here
    const { verticalGridNum: height, horizontalGridNum: width } = computeGridDimensionFromStepAsHorizontal(
      canvas,
      step,
    );

    const { area } = computeBoundingBoxForMercatorProjection(step, map);

    return {
      area,
      width,
      height,
      sampling: { kind: GridSamplingStrategyKind.Area },
      parameters: [this.props.parameter_unit], //TODO: get right from props
      model: this.props.model,
      ensSelect: this.props.ens_select ? this.props.ens_select : "",
      boundingBoxLimit: getModelBoundingBox(this.props.model),
      datetime: timeWithOffset,
      calibrated: this.props.calibrated,
      appliedStyle: this.props.color_map,
    };
  }

  updateColorMap() {
    const request = this.createRequest(this.scene.getDisplayTimeWithOffset());

    const { asynchronous: asyncColorMap } = this._fetchColorMap(request);

    asyncColorMap
      .then((colorMapping) => {
        this.layer.setColorMap(colorMapping);

        const { width, height } = request;
        const windMetaData = createwindMetaData(colorMapping.recommendedValueRange, width, height);
        this.interacting = false;
        this.layer.updateWindMetadata(windMetaData);
        this.layer.unfreeze();
        this.beforeRender();
      })
      .catch((e) => {
        console.error(e);
      });
  }

  get mapboxIndex(): string {
    return this._layerId;
  }

  getActiveWeatherParametersAsString(): { model: string; parameter: string }[] {
    return [
      {
        model: this.props.model,
        parameter: this.props.parameter_unit,
      },
    ];
  }

  checker(): PropsChecker<WindAnimationLayer, LayerBase<WindAnimationLayer>> {
    this.layer.updateProps(this.props);
    type CheckerProps = CheckedProps<WindAnimationLayer>;
    // TODO Fix signal value typing
    const checkLayerProp = (name: CheckerProps, signal?: (value: any) => void) => (prev: any, curr: any) => {
      const changed = prev[name] !== curr[name];
      if (changed) {
        this.setLayerProps({
          [name]: curr[name],
        });

        if (signal) {
          signal(curr[name]);
        }
      }
      return changed;
    };

    return {
      opacity: checkLayerProp("opacity", (opacity: string) => {
        this.layer.setProperty("opacity", +opacity);
      }),
      color_map: checkLayerProp("color_map", () => {
        this.updateColorMap();
      }),
      calibrated: checkLayerProp("calibrated"),
      custom_options: checkLayerProp("custom_options", (customOptions) => {
        this.layer.setProperty("particle-size", customOptions.size ?? DEFAULT_PARTICLE_SIZE);

        if (this._amountChangeDetector.changed(customOptions.amount)) {
          this.layer.setProperty("particle-amount", customOptions.amount ?? DEFAULT_PARTICLE_AMOUNT);
        }
      }),
      ens_select: checkLayerProp("ens_select"),
      model: checkLayerProp("model", () => {
        requestAnimationFrame(() => {
          // Update bounds on next frame, when model is already changed, but not loaded
          this.layer.updateBounds(this.scene.getMapboxMap().getBounds());
        });
        this.updateWindMap();
      }),
      parameter_unit: checkLayerProp("parameter_unit", () => {
        this.updateWindMap();
      }),
      show: checkLayerProp("show", (show: boolean) => {
        if (!show) {
          // Freze rendering
          this.layer.freeze();
          // Refresh textures to clean state
          this.layer.refresh();
        } else {
          // Resume rendering
          this.layer.unfreeze();
          this.scene.repaint();
        }
      }),
    };
  }
  updateWindMap() {
    this.layer.refresh();
  }

  isRebuffering(time: DateTime): boolean {
    // TODO: offset ?? and time
    return this.layer.isBuffering();
  }

  beforeRender(): void {
    if (!this.interacting && this.props.show && !this.layer.isFrozen) {
      this.scene.repaint();
    }
  }

  removeLayer(): void {
    const map = this.scene.getMapboxMap();
    map.off("movestart", this.handleMapManipulationStart);
    map.off("moveend", this.handleMapManipulationEnd);
    map.off("resize", this.handleMapResize);
    map.off("zoom", this.handleMapZoom);

    map.removeLayer(this._layerId);
  }

  moveZIndex(beforeLayerId?: string) {
    const map = this.scene.getMapboxMap();
    map.moveLayer(this._layerId, beforeLayerId);
  }

  fetchData(_timeFrame: DateTime): Promise<void> {
    // Temporary disable preloading in animation mode
    return Promise.resolve();
  }

  private _fetchColorMap(request: WindVectorRequest<CoordinateSystem.WGS84>): AsyncResult<ColorMapping> {
    return networkCaches.colormap_cache.retrieveColorMap({
      parameter: request.parameters[0],
      style: unsafeGetColormapByName(request.appliedStyle ?? "", this.props.parameter_unit),
      ensSelect: request.ensSelect ? request.ensSelect : "",
      calibrated: request.calibrated,
      // TODO Figure out better type conversion or get VerticalInterpolationType
      // inside @mm/api.meteomatics.com
      vertical_interpolation: request.vertical_interpolation
        ? (request.vertical_interpolation as "none" | "downscale")
        : "downscale",
    });
  }

  private _constructHumanReadableId(id: number, props: WindAnimationLayer) {
    return `wind_${id}_${props.model}_${props.parameter_unit}`;
  }
}

import { ENV } from "@/env";
import {
  type BaseAPI,
  Configuration,
  EnergyForecastApi,
  type ResponseContext,
} from "@mm/forecast-data.meteomatics.com";
import { useMemo } from "react";

import { useAuth } from ".";
import { isAuthenticated } from "./utils";

function useEnergyApi<ApiTy extends BaseAPI, ApiConfig>(
  apiCtor: new (configuration: ApiConfig) => ApiTy,
  apiConfigCtor: new (_: { basePath?: string; accessToken: string }) => ApiConfig,
) {
  const { session, disableSession, sessionActivated, fetchToken } = useAuth();

  const api = useMemo<ApiTy | null>(() => {
    if (!sessionActivated || !isAuthenticated(session) || session?.token == null) {
      return null;
    }
    return new apiCtor(
      new apiConfigCtor({ basePath: ENV.baseUrlEnergyForecast, accessToken: session.token.access_token }),
    ).withPostMiddleware((context: ResponseContext) => {
      const { response } = context;

      // Disable session on 460
      if (response.status === 460) {
        disableSession();
        throw context.response;
      }
      if (response.status === 403) {
        return fetchToken().then(() => {
          return Promise.resolve(context.response);
        });
      }
      return Promise.resolve(context.response);
    });
  }, [session, sessionActivated, disableSession, apiConfigCtor, apiCtor, fetchToken]);

  return api;
}

export function useEnergyForecastApi() {
  return useEnergyApi<EnergyForecastApi, Configuration>(EnergyForecastApi, Configuration);
}

import { kmToMiles } from "@/utility/units";
import { circle, feature, geometry, length } from "@turf/turf";
import type { Feature, LineString } from "geojson";

export const findFeatureByMeta = (features: Feature[], meta: string) => {
  return features.find((feature) => feature.properties?.meta === meta);
};

export const constructRadiusDisplay = (geojson: Feature<LineString>) => {
  const center = geojson.geometry.coordinates[0];

  const radiusInKm = length(geojson, { units: "kilometers" });
  const circleFeature = circle(center, radiusInKm, {
    units: "kilometers",
    properties: {
      meta: "radius-fill",
      parent: geojson.properties?.id,
    },
  });

  const centerFeature = feature(geometry("Point", center), {
    meta: "center-handle",
    parent: geojson.properties?.id,
  });

  const handleFeature = feature(geometry("Point", geojson.geometry.coordinates[1]), {
    meta: "radius-handle",
    radius: Math.round(radiusInKm),
    radiusMiles: Math.round(kmToMiles(radiusInKm)),
    parent: geojson.properties?.id,
  });

  return [geojson, circleFeature, centerFeature, handleFeature];
};

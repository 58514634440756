import { useUser } from "@/api/hooks/user";
import { useAuth } from "@/auth";
import { SentryLogMode } from "@/sentry/SentryRuntimeStorage";
import { type PropsWithChildren, useEffect } from "react";

export function MouseFlowTracking({ children }: PropsWithChildren<unknown>) {
  const { sessionActivated } = useAuth();
  const user = useUser(sessionActivated);

  useEffect(() => {
    const mf = document.createElement("script");
    mf.id = "mouseflow-tracking";
    if (user.user && user.user.log_mode === SentryLogMode.MET_TEAM_USERS) {
      window._mfq = window._mfq || [];
      mf.type = "text/javascript";
      mf.defer = true;
      mf.src = "//cdn.mouseflow.com/projects/af050fb8-37b9-4d79-9614-e811fcf50120.js";
      document.head.appendChild(mf);
    }

    return () => {
      if (document.body.querySelector("#mouseflow-tracking")) {
        document.head.removeChild(mf);
      }
    };
  }, [user?.user]);

  return <>{children}</>;
}

import { type PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import type { RootState } from ".";
import type { Plot, PlotSeriesType } from "./client-models";

import { fetchTab } from "@/api/hooks/tab";
import { createPlot } from "@/api/hooks/tools/plot";
import type { ModifiableYAxisOptions } from "@/overlay/components/LayerStackWindow/PlotYaxisOptions";
import { HighchartsYAxis } from "@/plot";
import { hasCalibratedAvailable } from "@/utility/calibratedAvailabilityMap";
import type { ElementStyle, Location } from "@mm/metx-workbench.meteomatics.com";
import type { YAxisOptions } from "highcharts";
import Logger from "logging";

const logger = Logger.fromFilename(__filename);

// Highcharts Y Axis provides a complete list of 8 objects for the Y axis. If any objects are missing, it automatically fills them in.
// See our HighchartsYAxis
function fillYAxisToFullLength(arr: YAxisOptions[]): YAxisOptions[] {
  if (arr.length < HighchartsYAxis.length) {
    const diff = HighchartsYAxis.length - arr.length;
    return arr.concat(HighchartsYAxis.slice(0, diff));
  }
  return arr;
}

export type PlotsState = {
  [id: number]: Plot;
};

const initialState: PlotsState = {};

const plotsSlice = createSlice({
  name: "plots",
  initialState: initialState as PlotsState,
  reducers: {
    addPlot(state, action: PayloadAction<Plot>) {
      state[action.payload.id] = action.payload;
    },
    discardPlot(state, action: PayloadAction<{ id: number }>) {
      const { id } = action.payload;
      const tool = state[id];

      if (!tool) {
        logger.error("Could not find tool with id", id);
        return;
      }
      delete state[id];
    },
    pastePlot(state, action: PayloadAction<{ id: number; plot: Partial<Plot> }>) {
      const { id, plot } = action.payload;
      const tool = state[id];

      if (!tool) {
        logger.error("Could not find tool with id", id);
        return;
      }
      state[id] = { ...tool, ...plot };
    },
    removePlotSerie(state, action: PayloadAction<{ serieIndex: PlotSeriesType["index"]; plotId: number }>) {
      const { serieIndex, plotId } = action.payload;
      const tool = state[plotId];

      if (!tool) {
        logger.error("Could not find tool with id", plotId);
        return;
      }
      tool.plotSeries = tool.plotSeries
        .filter((plotSerie) => plotSerie.index !== serieIndex)
        .sort((plotSerie) => plotSerie.index)
        .map((plotSerie, index) => ({
          ...plotSerie,
          index,
        }));
    },
    addPlotSerie(state, action: PayloadAction<{ serie: PlotSeriesType; plotId: number }>) {
      const { serie, plotId } = action.payload;
      const tool = state[plotId];

      if (!tool) {
        logger.error("Could not find tool with id", plotId);
        return;
      }
      tool.plotSeries = [...tool.plotSeries, serie];
    },
    setPlotSerieProps(
      state,
      action: PayloadAction<{ id: number; plotSerieIdx: number; props: Partial<PlotSeriesType> }>,
    ) {
      const { id, plotSerieIdx, props } = action.payload;
      const plot = state[id];

      if (!plot) {
        logger.error("Could not find plot with id", id);
        return;
      }

      if (!plot.plotSeries[plotSerieIdx]) {
        logger.error("Could not find plotserie from Plot", plot.plotSeries);
        return;
      }

      const updatedProps = { ...plot.plotSeries[plotSerieIdx], ...props };

      if (props.parameter_unit && plot.plotSeries[plotSerieIdx].calibrated) {
        updatedProps.calibrated = hasCalibratedAvailable(props.parameter_unit);
      }

      plot.plotSeries[plotSerieIdx] = updatedProps;
    },
    setPlotTitleStyleProps(state, action: PayloadAction<{ id: number; props: ElementStyle }>) {
      const { id, props } = action.payload;

      const plot = state[id];
      if (!plot) {
        logger.error("Could not find plot with id", id);
        return;
      }

      plot.titleStyle = { ...plot.titleStyle, ...props };
    },

    setPlotTitle(state, action: PayloadAction<{ id: number; title: string }>) {
      const { id, title } = action.payload;

      const plot = state[id];
      if (!plot) {
        logger.error("Could not find plot with id", id);
        return;
      }

      plot.title = title;
    },
    setPlotLocation(state, action: PayloadAction<{ id: number; props: Location }>) {
      const { id, props } = action.payload;

      const plot = state[id];
      if (!plot) {
        logger.error("Could not find plot with id", id);
        return;
      }

      plot.location = props;
    },
    setPlotDateTimeRange(
      state,
      action: PayloadAction<{
        id: number;
        props: { datetimeRange: [string | null, string | null]; resolution: string | null };
      }>,
    ) {
      const {
        id,
        props: {
          datetimeRange: [from, to],
          resolution,
        },
      } = action.payload;

      const plot = state[id];

      if (!plot) {
        logger.error("Could not find plot with id", id);
        return;
      }

      plot.datetime_from = from;
      plot.datetime_to = to;
      plot.resolution = resolution;
    },
    setPlotYaxisOption(
      state,
      action: PayloadAction<{ id: number; plotSerieYAxisIdx: number; yAxis: ModifiableYAxisOptions }>,
    ) {
      const { id, plotSerieYAxisIdx, yAxis } = action.payload;

      const plot = state[id];
      if (!plot) {
        logger.error("Could not find plot with id", id);
        return;
      }

      if (!plot.plotYAxis[plotSerieYAxisIdx]) {
        logger.error("Could not find plotYAxis from Plot", plot.plotYAxis);
        return;
      }
      plot.plotYAxis[plotSerieYAxisIdx] = { ...plot.plotYAxis[plotSerieYAxisIdx], ...yAxis };
    },
    setPlotLegendStyleProps(state, action: PayloadAction<{ id: number; props: ElementStyle }>) {
      const { id, props } = action.payload;

      const plot = state[id];
      if (!plot) {
        logger.error("Could not find plot with id", id);
        return;
      }

      plot.legendStyle = { ...plot.legendStyle, ...props };
    },
    setPlotSerieOrder(
      state,
      action: PayloadAction<{ id: number; plotSerieIdx: PlotSeriesType["index"]; moveUpBy: number }>,
    ) {
      const { id, plotSerieIdx, moveUpBy } = action.payload;

      const plot = state[id];

      if (!plot) {
        logger.error("Could not find plot with id", id);
        return;
      }

      if (!plot.plotSeries) {
        logger.error("Could not get plotSeries from plot: ", plot);
        return;
      }
      // Sort the layers so they are from the smaller z index to the bigger z index.
      const copyOfPlotSeries = [...plot.plotSeries];
      const sortedPlotSeries = copyOfPlotSeries.sort((a, b) => (a.index > b.index ? 1 : -1));
      // Get the current position of the target layer and the index to move the target to.
      const fromIdx = sortedPlotSeries[plotSerieIdx] ? sortedPlotSeries[plotSerieIdx].index : -1;
      if (fromIdx === undefined) {
        // Use "=== undefined" otherwise index -1 and 1 evaluate to true/false.
        logger.error("Could not get PlotSeries from plot with idx", plotSerieIdx);
        return;
      }
      let toIdx = fromIdx - moveUpBy;
      if (toIdx >= sortedPlotSeries.length) {
        // If the toIdx is bigger than the legnth, set it to the last idx
        toIdx = sortedPlotSeries.length - 1;
      }
      if (toIdx < 0) {
        // If toIdx is smaller than 0, just set it to the first index
        toIdx = 0;
      }
      // Move the target layer to the toIdx
      const targetLayer = sortedPlotSeries.splice(fromIdx, 1)[0];
      sortedPlotSeries.splice(toIdx, 0, targetLayer);
      // Re-asign the z-index to each layer according to the order in the array
      plot.plotSeries = sortedPlotSeries.map((plotSerie, i) => {
        plotSerie.index = i; //Z-index starts from 1.
        return plotSerie as PlotSeriesType;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTab.fulfilled, (state, action) => {
      const tab = action.payload;
      for (const key in state) {
        delete state[+key];
      }
      for (const plot of tab.plots) {
        // We have a backend model GenericPlot with some JSON fields.
        // And a frontend model Plot. Here, we cast it to Plot as we always use Plot in frontend.
        plot.plotYAxis = fillYAxisToFullLength(plot.plotYAxis);
        state[plot.id] = plot as Plot;
      }
    });
    builder.addCase(createPlot.fulfilled, (state, action) => {
      const plot = action.payload.tool;
      state[plot.id] = plot;
    });
  },
});

const selectPlots = (state: RootState) => state.tabGroup.present.plots;

const makeSelectPlotsByTabId = createSelector(
  [selectPlots, (state: RootState, tabId: number) => tabId],
  (plots, tabId) => Object.values(plots).filter((plot) => plot.id_tab === tabId),
);

export function usePlots(tabId: number) {
  return useSelector((state: RootState) => makeSelectPlotsByTabId(state, tabId));
}

const makeSelectPlotById = createSelector(
  [selectPlots, (state: RootState, id: number) => id],
  (plots, id) => plots[id],
);

export function usePlot(id: number) {
  return useSelector((state: RootState) => makeSelectPlotById(state, id));
}

export const {
  addPlot,
  pastePlot,
  setPlotSerieProps,
  removePlotSerie,
  addPlotSerie,
  setPlotTitleStyleProps,
  setPlotTitle,
  setPlotDateTimeRange,
  setPlotLegendStyleProps,
  setPlotYaxisOption,
  setPlotSerieOrder,
  setPlotLocation,
  discardPlot,
} = plotsSlice.actions;

export default plotsSlice.reducer;

import type { GeoJSONSourceRaw } from "mapbox-gl";

export const emptyFeatureCollection: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
  type: "FeatureCollection",
  features: [],
};

export function emptyGeoJsonSource(): GeoJSONSourceRaw {
  return {
    type: "geojson",
    data: emptyFeatureCollection,
  };
}

import { _Request, _fetch } from "./utility/fetch";

export class RestApi {
  private _abortControllerPool: { [key: string]: AbortController } = {};

  /**
   * Create GET request
   *
   * @param url_ url
   * @param conf request configuration
   * @param key unique key, for accessing abort signal
   * @returns
   */
  protected async getRaw(url_: string | URL, key: string, conf: Omit<RequestInit, "method"> = {}): Promise<Response> {
    // Create new abort controller
    const controller = new AbortController();

    const url = url_ instanceof URL ? url_.toString() : url_;
    const req = new _Request(url, { ...conf, method: "GET", signal: controller.signal });

    // Cache it for later use if needed
    // Use url as key, because it's as unique as it gets
    this._abortControllerPool[key] = controller;

    return _fetch(req).then(async (response) => {
      // Cleanup memory, when abort controller isn't needed anymore
      if (this._abortControllerPool[key]) delete this._abortControllerPool[key];

      if (!response.ok) {
        throw response;
      }

      return response;
    });
  }

  /**
   * Create POST request
   *
   * @param url_ url
   * @param conf request configuration
   * @param key unique key, for accessing abort signal
   * @returns
   */
  protected async postRaw(url_: string | URL, key: string, conf: Omit<RequestInit, "method"> = {}): Promise<Response> {
    // Create new abort controller
    const controller = new AbortController();

    const url = url_ instanceof URL ? url_.toString() : url_;
    const req = new _Request(url, { ...conf, method: "POST", signal: controller.signal });

    // Cache it for later use if needed
    // Use url as key, because it's as unique as it gets
    this._abortControllerPool[key] = controller;

    return _fetch(req).then(async (response) => {
      // Cleanup memory, when abort controller isn't needed anymore
      if (this._abortControllerPool[key]) delete this._abortControllerPool[key];

      if (!response.ok) {
        throw response;
      }

      return response;
    });
  }

  /**
   * Abort active request if it exists
   *
   * @param key unique key, that is passed when creating request
   */
  protected abortRequest(key: string) {
    if (this._abortControllerPool[key]) {
      this._abortControllerPool[key].abort();
      delete this._abortControllerPool[key];

      return true;
    }

    return false;
  }
}

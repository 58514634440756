import { Children, type PropsWithChildren, cloneElement, isValidElement, useEffect } from "react";

import type { ContextMenuCtx } from "@/models/map-interaction/mapInteractionStateCtxTypes";
import {
  FloatingFocusManager,
  FloatingOverlay,
  flip,
  shift,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from "@floating-ui/react";
import type { ContextMenuActions } from "./constants";

import styles from "./style.module.scss";

interface MenuProps {
  context: ContextMenuCtx;
  onAction: (action: ContextMenuActions, context: ContextMenuCtx) => void;
  onClose: () => void;
}

export const Menu = ({ context, onClose, onAction, children, ...props }: PropsWithChildren<MenuProps>) => {
  const {
    modeOpts: { point },
  } = context;

  const {
    x,
    y,
    strategy,
    refs,
    context: floatingContext,
  } = useFloating({
    open: true,
    onOpenChange: (open) => {
      !open && onClose();
    },
    middleware: [flip(), shift()],
  });

  const role = useRole(floatingContext, { role: "menu" });
  const dismiss = useDismiss(floatingContext);

  const { getFloatingProps, getItemProps } = useInteractions([role, dismiss]);

  useEffect(() => {
    refs.setPositionReference({
      getBoundingClientRect() {
        return {
          width: 0,
          height: 0,
          x: point.x,
          y: point.y,
          top: point.y,
          right: point.x,
          bottom: point.y,
          left: point.x,
        };
      },
    });
  }, [point, refs]);

  return (
    <>
      <FloatingOverlay lockScroll>
        <FloatingFocusManager context={floatingContext} initialFocus={refs.floating}>
          <div
            data-testid={"context-menu"}
            ref={refs.setFloating}
            {...props}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            }}
            className={styles.menu}
            {...getFloatingProps()}
          >
            {Children.map(
              children,
              (child) =>
                isValidElement(child) &&
                cloneElement(
                  child,
                  getItemProps({
                    onClick() {
                      onAction(child.props.action, context);
                    },
                  }),
                ),
            )}
          </div>
        </FloatingFocusManager>
      </FloatingOverlay>
    </>
  );
};

import { nearestPowerOf2Ceil } from "@/utility/powerOfTwo";
import type { RgbaColor } from "color-lib";
import { Texture } from "../Texture";

/**
 * A GPU interpolated color map created from a uniformly sampled transfer function
 */
export class ColorMapTexture extends Texture {
  private samples_: Uint8ClampedArray;

  /**
   *
   * @param samples a list of unit range color samples, or a uint8 vector containing rgba quadruples.
   * @param isSegmented `true` if the color map should not be interpolated. This is nost often the case for color maps for nominal data.
   */
  constructor(
    samples: RgbaColor[] | Uint8ClampedArray,
    readonly isSegmented: boolean,
  ) {
    super();
    this.samples_ = samples instanceof Uint8ClampedArray ? samples : ColorMapTexture.packUint8(samples);
    this.premultiplyAlpha();
  }

  get sampleCount(): number {
    return this.samples_.length / 4;
  }

  get samples(): Uint8ClampedArray {
    return this.samples_;
  }

  get estimatedByteSizeCpu(): number {
    return this.samples_.byteLength;
  }

  get estimatedByteSizeGpu(): number {
    // Note: colormaps are not mip mapped
    const timesUploaded = this._textures.size;
    return nearestPowerOf2Ceil(this.samples_.byteLength) * timesUploaded;
  }

  private premultiplyAlpha() {
    // see one of the following links for an explainer:
    // - http://www.realtimerendering.com/blog/gpus-prefer-premultiplication/
    // - https://limnu.com/webgl-blending-youre-probably-wrong/
    // - https://webglfundamentals.org/webgl/lessons/webgl-and-alpha.html
    // - http://tomforsyth1000.github.io/blog.wiki.html#[[Premultiplied%20alpha%20part%202]]

    for (let idx = 0; idx < this.samples_.length; idx += 4) {
      const alpha = this.samples_[idx + 3] / 255;
      // TODO: we could also use a floating point texture for colormaps to avoid rounding
      this.samples_[idx + 0] = Math.round(this.samples_[idx + 0] * alpha);
      this.samples_[idx + 1] = Math.round(this.samples_[idx + 1] * alpha);
      this.samples_[idx + 2] = Math.round(this.samples_[idx + 2] * alpha);
    }
  }

  static packUint8(samples: RgbaColor[]): Uint8ClampedArray {
    const packed = new Uint8ClampedArray(samples.length * 4);
    let idx = 0;
    for (const sample of samples) {
      packed[idx++] = sample[0] * 255;
      packed[idx++] = sample[1] * 255;
      packed[idx++] = sample[2] * 255;
      packed[idx++] = sample[3] * 255;
    }
    return packed;
  }

  public upload(gl: WebGLRenderingContext) {
    const level = 0;
    const internalFormat = gl.RGBA;
    const srcFormat = gl.RGBA;
    const srcType = gl.UNSIGNED_BYTE;
    const border = 0;
    const height = 1;
    const width = this.sampleCount;
    gl.texImage2D(gl.TEXTURE_2D, level, internalFormat, width, height, border, srcFormat, srcType, this.samples_);

    // access outside of the transfer function range should just return the min/max value of the transfer function
    // By a lucky coincidence, this is also the only supported mode for non-power-of-two-sized textures.
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);

    // disable linear interpolation for colormaps targeting nominal data
    if (this.isSegmented) {
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
    } else {
      // since the texture might be non-power of two, LINEAR and NEAREST are the only supported modes in WebGL1.
      // Without explicitly setting LINEAR here, vec4(0,0,0,1), is sampled instead!
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
    }
  }
}

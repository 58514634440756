import type { ColoringOptions } from "@/reducer/client-models";
import { ConditionOptions } from "@/utility/conditionalExpressions";
import type { PartialWeatherParameter } from "weather-parameter-utils";

export const aerodromePartialWeatherParameter: PartialWeatherParameter = {
  parameter: {
    name: "aerodrome_approach",
    description_de: "aerodrome_approach",
    description_en: "aerodrome_approach",
    description_fr: "aerodrome_approach",
    flags: [],
    default_values: {},
    layers: [
      {
        unit: ["idx"],
        format: "aerodrome_approach:<unit>",
      },
    ],
    available_models: ["mix"],
  },
};
export const aerodromeExtraPartialWeatherParameter: PartialWeatherParameter = {
  parameter: {
    name: "aerodrome_approach_extra",
    description_de: "aerodrome_approach_extra",
    description_en: "aerodrome_approach_extra",
    description_fr: "aerodrome_approach_extra",
    flags: [],
    default_values: {},
    layers: [
      {
        unit: ["idx"],
        format: "aerodrome_approach_extra:<unit>",
      },
    ],
    available_models: ["mix"],
  },
};

export const coloringOptionsAerodrome: ColoringOptions = {
  // parameter_unit: "aerodrome_approach:idx",
  //     model: "mix",
  thresholds: [
    {
      color: "#00ff00",
      value: 0,
      condition: ConditionOptions["=="],
    },
    {
      color: "#ff9900",
      value: 1,
      condition: ConditionOptions["=="],
    },
    {
      color: "#ff0000",
      value: 2,
      condition: ConditionOptions["=="],
    },
  ],
};

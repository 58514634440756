import { DateTime } from "luxon";
import proj4 from "proj4";
import type { GridRequest } from "../models";
import { Area } from "../models/Area";
import type { BaseResponse } from "../models/BaseResponse";
import { CoordinateSystem } from "../models/CoordinateSystem";
import type { GridResponse } from "../models/GridResponse";
import type { Timer } from "../performanceIntrospection/Timer";
import { clamp } from "../utility/clamp";
import { BinaryIterator } from "./BinaryIterator";
import type { BinarySample } from "./BinarySample";
import type { Header } from "./Header";
import { Precision } from "./Precision";

export const littleEndian = true;
export const bigEndian = false;
export const endianess = littleEndian;

const magicNumber = "MBG_".split("").map((chr) => chr.charCodeAt(0));

// TODO: typing should enforce `Float64Array` or `Float32Array` after construction, not return
// `Float64Array | Float32Array` everywhere.
export class BinaryGridResponse<RequestCrs extends CoordinateSystem>
  implements GridResponse<ArrayBuffer, RequestCrs>, BaseResponse<GridRequest<RequestCrs>, ArrayBuffer>
{
  private header: Header;
  request: GridRequest<RequestCrs>;
  data: ArrayBuffer;
  timer: Timer;

  /**
   * Wrap a raw binary grid response
   *
   * @param binary unprocessed binary api response
   * @throws if the binary format is invalid
   */
  constructor(response: GridResponse<ArrayBuffer, RequestCrs>) {
    this.data = response.data;
    this.request = response.request;
    this.timer = response.timer;
    this.header = this.parse();
  }

  raw(): ArrayBuffer {
    return this.data;
  }

  /**
   * Parse and validate a raw binary grid response
   *
   * @throws RangeError if the file ends unexpectedly.
   * @throws Error if format invariants are violated.
   */
  private parse(): Header {
    const view = new DataView(this.data);

    // chomp fixed length header
    let byteOffset = 0;

    for (; byteOffset < magicNumber.length; byteOffset++) {
      const chr = magicNumber[byteOffset];
      if (view.getUint8(byteOffset) !== chr) {
        throw Error("invalid magic number");
      }
    }

    const version = view.getInt32(byteOffset, endianess);
    byteOffset += 4;
    if (version !== 2) {
      throw Error(`expected version '2', got ${version}`);
    }

    // chomp tensor shape and type
    const precision = view.getInt32(byteOffset, endianess);
    byteOffset += 4;
    if (precision !== Precision.f32 && precision !== Precision.f64) {
      throw Error(`expected precision of '4' or '8' bytes per element, got ${precision}`);
    }

    const countParams = view.getInt32(byteOffset, endianess);
    byteOffset += 4;
    if (countParams !== 1) {
      throw Error(`expected count_params of '1', got ${countParams}`);
    }

    const payloadMeta = view.getInt32(byteOffset, endianess);
    byteOffset += 4;
    if (payloadMeta !== 0) {
      throw Error(`expected payload_meta of '0', got ${payloadMeta}`);
    }

    const countDatetime = view.getInt32(byteOffset, endianess);
    byteOffset += 4;
    if (countDatetime !== this.countTimeSlices()) {
      throw Error(`expected ${this.countTimeSlices()} timeslices, got ${countDatetime}`);
    }

    const datetimes = [];

    for (let datetimeIdx = 0; datetimeIdx < countDatetime; datetimeIdx++) {
      const seconds = view.getFloat64(byteOffset, endianess);
      byteOffset += 8;
      datetimes.push(DateTime.fromSeconds(seconds));
    }

    const countLats = view.getInt32(byteOffset, endianess);
    byteOffset += 4;
    if (countLats !== this.height()) {
      throw Error(`expected ${this.height()} latitudes, got ${countLats}`);
    }

    const latitudes = [];

    for (let latIdx = 0; latIdx < countLats; latIdx++) {
      latitudes.push(view.getFloat64(byteOffset, endianess));
      byteOffset += 8;
    }

    const countLngs = view.getInt32(byteOffset, endianess);
    byteOffset += 4;
    if (countLngs !== this.width()) {
      throw Error(`expected ${this.width()} longitudes, got ${countLngs}`);
    }

    const longitudes = [];

    for (let lngIdx = 0; lngIdx < countLngs; lngIdx++) {
      longitudes.push(view.getFloat64(byteOffset, endianess));
      byteOffset += 8;
    }

    // prepare metadata for tensor data parsing
    const byteSizeSlice = precision * countLats * countLngs;
    const byteSizeHeader = byteOffset;

    const expectedBodyLength = byteSizeSlice * countParams * countDatetime;
    const expectedLength = byteSizeHeader + expectedBodyLength;
    const actualLength = this.data.byteLength;

    if (actualLength !== expectedLength) {
      throw Error(`expected binary to have a bytelength of ${expectedLength}, got ${actualLength}`);
    }

    return {
      byteSizeSlice,
      byteSizeHeader,
      precision,
      datetimes,
      latitudes,
      longitudes,
    };
  }

  width() {
    return this.request.width;
  }

  height() {
    return this.request.height;
  }

  // TODO: for more than one timeslice we have to adapt the MeteomaticsApi.binary` arguments, should be set on `this.binary.request`
  countTimeSlices() {
    return 1;
  }

  /**
   * Get area of the data points.
   *
   * Note that this is the area of the actual data points that were __sampled__. This area might differ from the request
   * area if a sampling strategy other than `SamplingStrategy.Point` was used or some other middleware transforms the
   * request.
   */
  area(): Area<CoordinateSystem.WGS84> {
    // should be equivalent, but let's not trust the backend
    //const srcArea = this.binary.request.area.applySamplingStrategy(this.binary.request.samplingStrategy);
    const [srcLatMin, srcLatMax] = [this.header.latitudes[0], this.header.latitudes[this.header.latitudes.length - 1]];
    const [srcLngMin, srcLngMax] = [
      this.header.longitudes[0],
      this.header.longitudes[this.header.longitudes.length - 1],
    ];
    return new Area(CoordinateSystem.WGS84, {
      latMin: srcLatMin,
      latMax: srcLatMax,
      lngMin: srcLngMin,
      lngMax: srcLngMax,
    });
  }

  /**
   * Get the response body in WGS84 with the maschines native endianess. For other CRS formats use `reproject`.
   * Also see `valueIterator`.
   */
  sampleIterator(): BinaryIterator<BinarySample> {
    const grid = new DataView(this.data, this.header.byteSizeHeader);
    return new BinaryIterator(this.header, endianess, grid, (sample) => sample);
  }

  /**
   * Get the response body in WGS84 with the maschines native endianess. For other CRS formats use `reproject`.
   * Also see `sampleIterator`, which carries additional meta information about each value.
   */
  valueIterator(): BinaryIterator<number> {
    const grid = new DataView(this.data, this.header.byteSizeHeader);
    return new BinaryIterator(this.header, endianess, grid, ({ value }) => value);
  }

  /**
   * Return a view of the binary data with an index per value.
   *
   * This object should be considered consumed if the returned data is modified.
   * Subsequent usage of `this : BinaryGridResponse` is undefined and may result in exceptions.
   */
  getRawBodyFloats(): Float32Array | Float64Array {
    switch (this.header.precision) {
      case Precision.f32:
        return new Float32Array(this.data, this.header.byteSizeHeader);
      case Precision.f64:
        return new Float64Array(this.data, this.header.byteSizeHeader);
      default:
        throw Error();
    }
  }

  /**
   * Return a view of the binary data with an index per byte. (So depending on the precision
   * a single sample is either 4 or 8 indices long.)
   *
   * This object should be considered consumed if the returned data is modified.
   * Subsequent usage of `this : BinaryGridResponse` is undefined and may result in exceptions.
   */
  getRawBodyBytes<T extends Uint8ClampedArray | Uint8Array>(
    ctor: new (buffer: ArrayBufferLike, byteOffset?: number | undefined, length?: number | undefined) => T,
  ): T {
    return new ctor(this.data, this.header.byteSizeHeader);
  }

  /**
   * Get metadata about the binary
   */
  getHeader(): Header {
    return this.header;
  }

  /**
   * In-place reprojection of the API response, which is always in WGS84, to another CRS.
   *
   * This trashes the memory of `this : BinaryGridResponse`. Behaviour on access to `this : BinaryGridResponse` after
   * a call to this function is undefined and may result in exceptions.
   *
   * The returned single channel floating point image will have south up, and north down.
   *
   * Note: currently only works on a global map, not on tiles. Needs to find the correct additional splitpoints of
   * the projection where compression switches to stretching???
   *
   * @param targetCrs
   * @param targetGrid
   */
  reprojectInplace<C2 extends CoordinateSystem.EPSG3857>(targetCrs: C2): Float32Array | Float64Array {
    const mem = this.getRawBodyFloats();
    // Beware: in-place modification in combination with `flipY` is not supported!
    return this.reproject(targetCrs, { targetGrid: mem, flipY: false });
  }

  /**
   * Reproject the API response, which is always in WGS84, to another CRS.
   *
   * Note: Converts endianness to the platforms endianess.
   *
   * @param targetCrs
   * @param opts if `targetGrid` is given, the output will be written to this buffer. if `flipY` is given, north will be put to the top, otherwise south.
   *             In place modification in combination with `flipY` is not supported. Thus `targetGrid` cannot point to `this.binary.data` if `flipY` is true.
   * @returns
   */
  reproject<C2 extends CoordinateSystem.EPSG3857>(
    targetCrs: C2,
    opts_?: { targetGrid?: Float32Array | Float64Array; flipY?: boolean },
  ): Float32Array | Float64Array {
    const srcGrid = new DataView(this.data, this.header.byteSizeHeader);
    return reproject(
      this.header.precision,
      endianess,
      srcGrid,
      this.area(),
      this.height(),
      this.width(),
      targetCrs,
      opts_,
    );
  }
}

/**
 * Reproject from one CRS to another CRS. (Currently only from WGS84 to EPSG3857 using nearest neighbour sampling.)
 *
 * @param precision byte size of each sample within `srcGrid`
 * @param endianess endianess of the data in `srcGrid`
 * @param srcGrid a uniform/equidistant grid of samples in WGS84, row-major, latitudes sorted in an ascending order. This means that the source data is assumed to have south __up__ (first row has the smallest latitude of the area) and north __down__ (last row has the largest latitude).
 * @param srcArea the area corresponding to `srcGrid`. This should be the true convex hull of the sample points, not the area before applying a sampling strategy.
 * @param height number of grid samples along x/latitude
 * @param width number of grid samples along y/longitude
 * @param targetCrs the target CRS, currently only EPSG3857 (web-mercator) is supported.
 * @param opts_ reprojection options. `targetGrid` will write into the given array instead of allocating a new target grid. flipY will invert the latitude to put north up.
 *  Note that a combination of `flipY : true` and `targetGrid === srcGrid` (in place reprojection) is not supported.
 * @returns
 */
export function reproject<C2 extends CoordinateSystem.EPSG3857>(
  precision: Precision,
  endianess: boolean | undefined,
  srcGrid: DataView,
  srcArea: Area<CoordinateSystem.WGS84>,
  height: number,
  width: number,
  targetCrs: C2,
  opts_?: { targetGrid?: Float32Array | Float64Array; flipY?: boolean },
): Float32Array | Float64Array {
  const opts = { flipY: false, ...opts_ };
  const targetArea = srcArea.project(targetCrs);

  const nLats = height; // targetHeightPx
  const nEquidistantLats = height; // srcHeightPx (always equal to targetHeightPx in the backend)
  const nLons = width; // srcWidthPx = targetWidthPx

  const targetGridSize = nLons * nLats;

  if (opts.targetGrid && opts.targetGrid.length !== targetGridSize) {
    throw Error(`expected targetGrid with space for ${targetGridSize} samples, got ${opts.targetGrid.length}`);
  }

  const maxY = targetArea.latMax;
  const minY = targetArea.latMin;
  const dy = (maxY - minY) / (nLats - 1);

  const lowerLat = srcArea.latMin;
  const maxEquidistantLatIndex = nEquidistantLats - 1; // srcMaxIdx
  const srcHeightDegree = srcArea.height();
  const dlat = srcHeightDegree / maxEquidistantLatIndex; // degree per sample, resp degrees between sample points

  let getter: "getFloat32" | "getFloat64";
  let targetGrid: Float32Array | Float64Array;

  switch (precision) {
    case Precision.f32:
      getter = "getFloat32";
      if (opts.targetGrid) {
        if (!(opts.targetGrid instanceof Float32Array)) {
          throw Error("expected float32 targetGrid, got float64");
        }
        targetGrid = opts.targetGrid;
      } else {
        targetGrid = new Float32Array(targetGridSize);
      }
      break;
    case Precision.f64:
      getter = "getFloat64";
      if (opts.targetGrid) {
        if (!(opts.targetGrid instanceof Float64Array)) {
          throw Error("expected float64 targetGrid, got float32");
        }
        targetGrid = opts.targetGrid;
      } else {
        targetGrid = new Float64Array(targetGridSize);
      }
      break;
    default: {
      const _exhaustive: never = precision;
      return _exhaustive;
    }
  }

  // Given an area, wich percentage of the latitude covered (height), do we have to walk from the south bound (top of the srcGrid) to reach the equator?
  // If this value is in the range [0,1] the equator is within the area, otherwise it is outside of the area.
  // if the value is negative, the equator is further to the south, we are in the northern hemisphere.
  // if the value is greater than one, the equator is further north, we are on the soutern hemisphere.
  // clamp deals with the out of bounds conditions if the tile is completely in the northern or southern hemisphere:
  // - completely lower hemisphere: equatorIdx < 0 => only second loop should run: cond first loop `i >= 0` should be immediately satisfied, second loop should start at 0 with i = equatorIdx + 1 => clamp to i=-1
  // - completely upper hemisphere: equatorIdx >= n_lats => only first loop should run: cond second loop `i < n_lats` with initial `equatorIdx + 1` should be immediately satisfied, first loop should start at n_lats - 1 with i = equatorIdx => clamp to i= n_lats - 1
  const equatorIdx = clamp(Math.round((0 - lowerLat) / dlat), -1, nEquidistantLats);

  const reprojectRow = (i: number) => {
    // calling with `i` here, means that we want to write to row `i` in the target buffer ("write this web mercator row")
    // So, read from a computed row `j=latNearestNeighborIdx` in srcGrid, and write to the given row `i`.

    const targetLat = minY + i * dy; // grid point to sample in the target crs in WGS84 lats
    const anyTargetLng = 0; // Assumption: the target CRS only stretches lats towards north and east, leaving lngs unaffected, WGS84 lngs

    // grid point to sample in the source crs given in the source CRS = EPSG3857 meters
    const [, srcLat] = proj4(targetCrs, CoordinateSystem.WGS84, [anyTargetLng, targetLat]);

    // grid point to sample as a row index
    const latNearestNeighborIdx = clamp(Math.round((srcLat - lowerLat) / dlat), 0, maxEquidistantLatIndex);
    // the binary format has north at the bottom by default, this is a really great place to invert the y-axis for free
    // (does not work if we operate in place, reflect in shader instead)
    const targetI = opts.flipY ? nLats - 1 - i : i;

    for (let j = 0; j < nLons; ++j) {
      const targetIdx = j + nLons * targetI;
      const srcIndex = j + nLons * latNearestNeighborIdx;
      targetGrid[targetIdx] = srcGrid[getter](srcIndex * precision, endianess);
    }
  };

  // walk away from equator towards top of the tile (south)
  for (let i = equatorIdx; i >= 0; --i) {
    reprojectRow(i);
  }

  // walk away from equator towards bottom of the tile (north)
  for (let i = equatorIdx + 1; i < nLats; ++i) {
    reprojectRow(i);
  }

  return targetGrid;
}

import { type CheckedProps, LayerBase, type PropsChecker } from "@/layers";
import { safeFmt } from "@/utility/safeFmt";
import type { GuiTimeZone, WmsLayer } from "@mm/metx-workbench.meteomatics.com";
import { debounce } from "lodash";
import type { DateTime } from "luxon";
import type { RasterLayout, RasterPaint } from "mapbox-gl";
import type { ScenePublicApi } from "../Compositor";
import { IntegratedSource } from "./IntegratedSource";

export class FallbackWmsLayerImpl extends LayerBase<WmsLayer> {
  readonly mapbox_id: string;
  readonly mapbox_source: string;

  readonly source: IntegratedSource;

  constructor(uid: number, props: WmsLayer, scene: ScenePublicApi, timezone: GuiTimeZone) {
    super(uid, props, scene, timezone);

    this.mapbox_id = this.humanReadableId();
    this.mapbox_source = safeFmt`source_${this.humanReadableId()}`;

    this.source = new IntegratedSource(this.mapbox_source, scene, props);
    this.scene.getMapboxMap().addSource(this.mapbox_source, this.source);

    this.scene.getMapboxMap().addLayer({
      id: this.mapbox_id,
      type: "raster",
      source: this.mapbox_source,
      paint: {
        // Zero out some transitions, so they aren't interfering with animation
        "raster-opacity-transition": {
          duration: 0,
          delay: 0,
        },
        "raster-fade-duration": 0,
        "raster-opacity": props.opacity,
      },
      layout: {
        visibility: props.show ? "visible" : "none",
      },
    });
  }

  get mapboxIndex(): string {
    return this.mapbox_id;
  }

  getActiveWeatherParametersAsString(): { model: string; parameter: string }[] {
    return [
      {
        model: this.props.model,
        parameter: this.props.parameter_unit,
      },
    ];
  }

  checker(): PropsChecker<WmsLayer, LayerBase<WmsLayer>> {
    type CheckerProps = CheckedProps<WmsLayer>;
    const checkLayerProp = (property: CheckerProps, signal?: any) => (prev: any, curr: any) => {
      const changed = prev[property] !== curr[property];

      if (changed) {
        this.setLayerProps({ [property]: curr[property] });

        switch (property) {
          case "legend_visible":
            break;
          case "model":
          case "color_map":
          case "calibrated":
          case "ens_select":
          case "parameter_unit":
          case "vertical_interpolation":
            this.beforeRender();
            break;
          case "opacity":
            this.updateMapboxPaintProperties({
              "raster-opacity": curr[property],
            });
            break;
          case "show":
            this.updateMapboxLayoutProperties({ visibility: curr.show ? "visible" : "none" });
            break;
          case "custom_options":
            break;
        }
      }

      return changed;
    };

    return {
      model: checkLayerProp("model"),
      opacity: checkLayerProp("opacity"),
      parameter_unit: checkLayerProp("parameter_unit"),
      show: checkLayerProp("show"),
      calibrated: checkLayerProp("calibrated"),
      vertical_interpolation: checkLayerProp("vertical_interpolation"),
      color_map: checkLayerProp("color_map"),
      legend_visible: checkLayerProp("legend_visible"),
      ens_select: checkLayerProp("ens_select"),
      custom_options: checkLayerProp("custom_options"),
    };
  }

  protected updateMapboxPaintProperties(props: RasterPaint) {
    for (const [name, value] of Object.entries(props)) {
      this.scene.getMapboxMap().setPaintProperty(this.mapbox_id, name, value);
    }
  }

  protected updateMapboxLayoutProperties(props: RasterLayout) {
    for (const [name, value] of Object.entries(props)) {
      this.scene.getMapboxMap().setLayoutProperty(this.mapbox_id, name, value);
    }
  }

  fetchData(timeFrame: DateTime): Promise<void> {
    const dateTimeWithOffset = this.scene.getDateTimeWithOffset(timeFrame);
    return this.source.getTilePromiseList(dateTimeWithOffset).then(() => {});
  }

  isRebuffering(time: DateTime): boolean {
    return this.source.isRebuffering(time);
  }

  beforeRender(): void {
    if (this.scene.isAnimation()) {
      this.updateTrigger();
    } else {
      this.debouncedUpdateTrigger();
    }
  }

  protected debouncedUpdateTrigger = debounce(
    () => {
      this.updateTrigger();
    },
    300,
    // As wind animation layer triggers Mapbox redraw event frequently on each frame, the debounce function never gets called when wind animation layer is present.
    // Here we use debounce leading=true with maxWait so we make sure update trigger function gets called occasionally.
    // This should be fixed at layer level, not in debounce
    { leading: true, maxWait: 1000 },
  );

  /**
   * Update the associated mapbox source.
   */
  updateTrigger(): void {
    this.source.updateSource(this.props);
  }

  removeLayer(): void {
    this.scene.getMapboxMap().removeLayer(this.mapbox_id);
    this.scene.getMapboxMap().removeSource(this.mapbox_source);
  }

  moveZIndex(beforeLayerId?: string) {
    const map = this.scene.getMapboxMap();
    map.moveLayer(this.mapbox_id, beforeLayerId);
  }

  humanReadableId(): string {
    return safeFmt`metx.Raster.${this.props.model}:${this.props.parameter_unit}${
      this.props.ens_select ? `-${this.props.ens_select}` : ""
    }@${this.props.color_map}#${this.uid}`;
  }
}

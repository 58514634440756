import { Api } from "@/api/Api";
import type { Plot, PlotCreate } from "@/reducer/client-models";
import { LayoutType, type Tab } from "@mm/metx-workbench.meteomatics.com";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const createPlot = createAsyncThunk(
  "api/plot/create",
  async (payLoad: { tabId: Tab["id"]; data: PlotCreate; layoutId: number }, _thunkAPI) => {
    return await Api.plot.v2CreatePlot({ genericPlotCreate: payLoad.data, tabId: payLoad.tabId }).then((plot) => {
      return { tool: plot as Plot, layoutType: LayoutType.Plot, layoutId: payLoad.layoutId };
    });
  },
);

// NOTE: this MUST be a deep import, even though the package root reexports the model, because the root exports other things that depend on DOM APIs not available in webworkers
import type { ParameterRange } from "@mm/api-layers.meteomatics.com/models";

import Logger from "logging";
const logger = Logger.fromFilename(__filename);

export function isParameterRange(v: any): v is ParameterRange {
  // biome-ignore lint/suspicious/noPrototypeBuiltins: ES2022 needed
  return v?.hasOwnProperty("start") && typeof v.start === "number";
}

export function isParameterField(v: any): v is ParameterRange | string[] {
  return isParameterRange(v) || (Array.isArray(v) && (v[0] === undefined || typeof v[0] === "string"));
}

export function unsafeGetParameterField(spec: { [_: string]: any }, fieldName: string): ParameterRange | string[] {
  if (fieldName in spec && isParameterField(spec[fieldName])) {
    return spec[fieldName];
  }

  return logger.unreachable(
    `invariant violation. Format description of '${spec.format}' does not contain field description for <${fieldName}>.`,
  );
}

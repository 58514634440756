import { CoordinateSystem } from "./CoordinateSystem";
import { Timer } from "../performanceIntrospection/Timer";
import { JSONResponseBody } from "./JSONGridResponse";
import { BaseResponse } from "./BaseResponse";
import { PolygonResponse } from "./PolygonResponse";
import { PolygonRequest } from "./PolygonRequest";

export class JSONPolygonResponse
  implements
    PolygonResponse<JSONResponseBody, CoordinateSystem.WGS84>,
    BaseResponse<PolygonRequest<CoordinateSystem.WGS84>, JSONResponseBody>
{
  request: PolygonRequest<CoordinateSystem.WGS84>;
  data: JSONResponseBody;
  timer: Timer;

  constructor(
    response: PolygonResponse<JSONResponseBody, CoordinateSystem.WGS84>
  ) {
    this.data = response.data;
    this.request = response.request;
    this.timer = response.timer;
  }
}

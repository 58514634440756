export interface WeatherParamSearchOptions {
  /**
   * Only parameters annotated with at least one of the given
   * flags will be returned in search results.
   */
  enabledFlags?: null | string[];
  /**
   * Parameters annotated with at least one of the given flags
   * will not be returned in search results.
   *
   * If `enabledFlags` is given in addition to `disabledFlags`,
   * `disabledFlags` will overwrite `enabledFlags`.
   */
  disabledFlags?: null | string[];
  /**
   * Exact matching search results will be padded to at most
   * the given number of results using fuzzy search.
   *
   * Note: If there are more **exact** search results than the given number,
   * all exact search results will be returned.
   *
   * May be set to `Infinity` to disable the feature.
   */
  maxNumberOfResults: number;
}

export const MAX_SEARCH_RESULTS = 5;

export const defaultSearchOptions: WeatherParamSearchOptions = {
  maxNumberOfResults: MAX_SEARCH_RESULTS,
};

import { aerodromePartialWeatherParameter, coloringOptionsAerodrome } from "@/constants/areodrome-extra-parameter";
import type { LayerUnionWithAll } from "@/layers";
import { hasCalibratedAvailable } from "@/utility/calibratedAvailabilityMap";
import type { CartographicMap, ElementStyle, MapProjection } from "@mm/metx-workbench.meteomatics.com";
import { type PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { differenceBy, merge, omit, pullAllBy, unionBy } from "lodash";
import { useSelector } from "react-redux";
import type { RootState } from ".";
import { DefaultThreshold, type IconData, type PoiOptions } from "./client-models";
import type { PoiLayer } from "./client-models/PoiLayer";

import { createLayer, createMultipleLayers } from "@/api/hooks/layer";
import { fetchTab } from "@/api/hooks/tab";
import { createMapWithViewport } from "@/api/hooks/tools/map";
import type { FeatureCollection } from "geojson";
import Logger from "logging";

const logger = Logger.fromFilename(__filename);

export type MapsState = {
  [id: number]: CartographicMap;
};

const initialState: MapsState = {};

const mapsSlice = createSlice({
  name: "maps",
  initialState: initialState as MapsState,
  reducers: {
    addMap(state, action: PayloadAction<CartographicMap>) {
      const map = action.payload;
      if (state[map.id]) {
        const mapState = state[map.id];

        const layers = pullAllBy(
          unionBy(mapState.layers || [], map.layers || [], "id"),
          differenceBy(mapState.layers, map.layers || [], "id"),
          "id",
        );

        state[map.id] = {
          ...omit(map, ["layers", "map_projection"]),
          map_projection: mapState.map_projection,
          layers: layers,
        };
      } else {
        state[map.id] = map;
      }
    },
    updateMapLayers(state, action: PayloadAction<{ id: number; layers: CartographicMap["layers"] }>) {
      const { id, layers } = action.payload;
      const map = state[id];

      if (!map) {
        logger.error("Could not find map with id", id);
        return;
      }

      map.layers = layers;
    },
    setMapViewport(state, action: PayloadAction<{ mapId: number; viewportId: number }>) {
      const { mapId, viewportId } = action.payload;
      if (state[mapId]) {
        state[mapId].id_viewport = viewportId;
      }
    },

    setLayersProps: (
      state,
      action: PayloadAction<{ id: number; layerId: number; props: Partial<LayerUnionWithAll> }>,
    ) => {
      const { id, layerId, props } = action.payload;
      const map = state[id];

      if (!map) {
        logger.error("Could not find map with id", id);
        return;
      }

      if (!map.layers) {
        logger.error(`Map with id ${id} doesn't have any layers`);
        return;
      }

      const layerIdx = map.layers.findIndex((layer: LayerUnionWithAll) => layer.id === layerId);
      if (layerIdx < 0) {
        logger.error("Could not find Layer from Layers", map.layers);
        return;
      }

      const updatedLayerProps: any = { ...map.layers[layerIdx], ...props };
      if (!hasCalibratedAvailable((updatedLayerProps as any).parameter_unit)) {
        // Disable calibrated if selected parameter isn't supported
        // Leaving calibrated = true doesn't affect anything, but let's keep
        // layer object clean
        updatedLayerProps.calibrated = false;
      }

      map.layers[layerIdx] = updatedLayerProps;
    },

    setLayersIconItemProps(state, action: PayloadAction<{ id: number; layerId: number; item: IconData }>) {
      const { id, layerId, item } = action.payload;

      const map = state[id];

      if (!map) {
        logger.error("Could not find map with id", id);
        return;
      }

      if (!map.layers) {
        logger.error(`Map with id ${id} doesn't have any layers`);
        return;
      }

      const layerIdx = map.layers.findIndex((layer: LayerUnionWithAll) => layer.id === layerId);
      if (layerIdx < 0) {
        logger.error("Could not find Layer from Layers", map.layers);
        return;
      }

      const poiLayer = map.layers[layerIdx] as PoiLayer;
      poiLayer.poiOptions.item = { ...poiLayer.poiOptions.item, ...item };
    },

    setPoiOptions(state, action: PayloadAction<{ id: number; layerId: number; poiOptions: Partial<PoiOptions> }>) {
      const { id, layerId, poiOptions } = action.payload;
      const map = state[id];

      if (!map) {
        logger.error("Could not find map with id", id);
        return;
      }

      if (!map.layers) {
        logger.error(`Map with id ${id} doesn't have any layers`);
        return;
      }

      const layerIdx = map.layers.findIndex((layer: LayerUnionWithAll) => layer.id === layerId);
      if (layerIdx < 0) {
        logger.error("Could not find Layer from Layers", map.layers);
        return;
      }

      const layer = map.layers[layerIdx] as PoiLayer;
      map.layers[layerIdx] = { ...map.layers[layerIdx], poiOptions: merge(layer.poiOptions, poiOptions) };
    },

    setLayersPropsAndColoringOptions(
      state,
      action: PayloadAction<{ id: number; layerId: number; props: Partial<PoiLayer> }>,
    ) {
      const { id, layerId, props } = action.payload;
      const map = state[id];

      if (!map) {
        logger.error("Could not find map with id", id);
        return;
      }

      if (!map.layers) {
        logger.error(`Map with id ${id} doesn't have any layers`);
        return;
      }

      const layerIdx = map.layers.findIndex((layer: LayerUnionWithAll) => layer.id === layerId);
      if (layerIdx < 0) {
        logger.error("Could not find Layer from Layers", map.layers);
        return;
      }

      if (props.kind != null && map.layers[layerIdx].kind !== props.kind) {
        logger.warn("Changing layer kind", layerId);
      }

      const layer = map.layers[layerIdx] as PoiLayer;

      if (layer.poiOptions) {
        if (layer.parameter_unit.includes(aerodromePartialWeatherParameter.parameter.name)) {
          const tmpPoiOptions = { ...layer.poiOptions } as PoiOptions;
          tmpPoiOptions.coloringOptions = coloringOptionsAerodrome;
          map.layers[layerIdx] = { ...map.layers[layerIdx], ...props, poiOptions: tmpPoiOptions };
        } else {
          const tmpPoiOptions = { ...layer.poiOptions } as PoiOptions;
          tmpPoiOptions.coloringOptions = { thresholds: [DefaultThreshold] };
          map.layers[layerIdx] = { ...map.layers[layerIdx], ...props, poiOptions: tmpPoiOptions };
        }
      }
    },
    setMapTitleStyleProps(state, action: PayloadAction<{ id: number; props: ElementStyle }>) {
      const { id, props } = action.payload;
      const map = state[id];

      if (!map) {
        logger.error("Could not find map with id", id);
        return;
      }

      map.titleStyle = { ...map.titleStyle, ...props };
    },
    setMapLegendProps(state, action: PayloadAction<{ id: number; legendSize: number }>) {
      const { id, legendSize } = action.payload;
      const map = state[id];

      if (!map) {
        logger.error("Could not find map with id", id);
        return;
      }

      map.legend_size = legendSize;
    },
    setMapProjectionProps(state, action: PayloadAction<{ id: number; props: MapProjection }>) {
      const { id, props } = action.payload;
      const map = state[id];

      if (!map) {
        logger.error("Could not find map with id", id);
        return;
      }

      map.map_projection = { ...map.map_projection, ...props };
    },
    setMapLodBiasProps(state, action: PayloadAction<{ id: number; lod_bias: CartographicMap["lod_bias"] }>) {
      const { id, lod_bias } = action.payload;
      const map = state[id];

      if (!map) {
        logger.error("Could not find map with id", id);
        return;
      }

      map.lod_bias = lod_bias;
    },
    setMapDrawings(state, action: PayloadAction<{ id: number; data: FeatureCollection }>) {
      const { id, data } = action.payload;
      const map = state[id];

      if (!map) {
        logger.error("Could not find map with id", id);
        return;
      }

      map.drawing = data;
    },
    setMapTitleProps(state, action: PayloadAction<{ id: number; title: CartographicMap["title"] }>) {
      const { id, title } = action.payload;
      const map = state[id];

      if (!map) {
        logger.error("Could not find map with id", id);
        return;
      }

      map.title = title;
    },
    setMapOffsetTimeProps(
      state,
      action: PayloadAction<{ id: number; timeOffsetMinutes: CartographicMap["time_offset_mins"] }>,
    ) {
      const { id, timeOffsetMinutes } = action.payload;
      const map = state[id];

      if (!map) {
        logger.error("Could not find map with id", id);
        return;
      }

      map.time_offset_mins = timeOffsetMinutes;
    },
    discardMap(state, action: PayloadAction<{ id: number }>) {
      const { id } = action.payload;
      const tool = state[id];

      if (!tool) {
        logger.error("Could not find tool with id", id);
        return;
      }
      delete state[id];
    },
    removeLayer(state, action: PayloadAction<{ mapId: number; layerId: number }>) {
      const { mapId, layerId } = action.payload;
      const layers = state[mapId].layers;

      if (!layers) {
        logger.error("Could not find tool with id", mapId);
        return;
      }
      // We do not actually delete the layer in DB at this step
      state[mapId].layers = [...layers.filter((layer) => layer.id !== layerId)];
    },
    setLayerOrder(state, action: PayloadAction<{ id: number; layerId: number; moveUpBy: number }>) {
      const { id, layerId, moveUpBy } = action.payload;
      const map = state[id];

      if (!map) {
        logger.error("Could not find map with id", id);
        return;
      }

      if (!map.layers) {
        logger.error(`Map with id ${id} doesn't have any layers`);
        return;
      }

      // Sort the layers so they are from the smaller z index to the bigger z index.
      const copyOfLayers = [...map.layers];
      const sortedLayers = copyOfLayers.sort((a, b) => (a.index > b.index ? 1 : -1));

      // Get the current position of the target layer and the index to move the target to.
      const fromIdx = sortedLayers.findIndex((layer: LayerUnionWithAll) => layer.id === layerId);
      if (fromIdx === undefined) {
        // Use "=== undefined" otherwise index -1 and 1 evaluate to true/false.
        logger.error("Could not get Layers from map with id", layerId);
        return;
      }
      let toIdx = fromIdx + moveUpBy;
      if (toIdx >= sortedLayers.length) {
        // If the toIdx is bigger than the legnth, set it to the last idx
        toIdx = sortedLayers.length - 1;
      }
      if (toIdx < 0) {
        // If toIdx is smaller than 0, just set it to the first index
        toIdx = 0;
      }
      // Move the target layer to the toIdx
      const targetLayer = sortedLayers.splice(fromIdx, 1)[0];
      sortedLayers.splice(toIdx, 0, targetLayer);
      // Re-asign the z-index to each layer according to the order in the array
      map.layers = sortedLayers.map((layer, i) => {
        layer.index = i + 1; //Z-index starts from 1.
        return layer;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTab.fulfilled, (state, action) => {
      const tab = action.payload;
      for (const key in state) {
        delete state[+key];
      }
      for (const map of tab.maps) {
        state[map.id] = map;
      }
    });
    builder.addCase(createMapWithViewport.fulfilled, (state, action) => {
      const map = action.payload.tool;
      state[map.id] = map;
    });
    builder.addCase(createLayer.fulfilled, (state, action) => {
      const layer = action.payload;
      const map = state[layer.id_cartographicmap];
      if (!map) {
        logger.error("Could not find map with id", layer.id_cartographicmap);
        return;
      }
      if (map.layers) {
        map.layers.push(layer);
      } else {
        map.layers = [layer];
      }
    });
    builder.addCase(createMultipleLayers.fulfilled, (state, action) => {
      const layers = action.payload;
      const mapId = layers[0].id_cartographicmap;
      const map = state[mapId];
      if (!map) {
        logger.error("Could not find map with id", mapId);
        return;
      }
      if (map.layers) {
        map.layers = [...map.layers, ...layers];
      } else {
        map.layers = [...layers];
      }
    });
  },
});

const selectMaps = (state: RootState) => state.tabGroup.present.maps;

const makeSelectMapsByTabId = createSelector([selectMaps, (state: RootState, tabId: number) => tabId], (maps, tabId) =>
  Object.values(maps).filter((map) => map.id_tab === tabId),
);

export function useMaps(tabId: number) {
  return useSelector((state: RootState) => makeSelectMapsByTabId(state, tabId));
}

const makeSelectMapById = createSelector([selectMaps, (state: RootState, id: number) => id], (maps, id) => maps[id]);

export function useMap(id: number) {
  return useSelector((state: RootState) => makeSelectMapById(state, id));
}

export function useDescriptionLayers(id: number): LayerUnionWithAll[] | undefined {
  const map = useMap(id);
  return map?.layers;
}

export const {
  addMap,
  updateMapLayers,
  setPoiOptions,
  setMapViewport,
  setLayersProps,
  setLayersIconItemProps,
  setLayersPropsAndColoringOptions,
  setMapTitleStyleProps,
  setMapProjectionProps,
  setMapLodBiasProps,
  setMapTitleProps,
  setMapDrawings,
  setMapOffsetTimeProps,
  setLayerOrder,
  removeLayer,
  setMapLegendProps,
  discardMap,
} = mapsSlice.actions;

export default mapsSlice.reducer;

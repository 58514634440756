export interface InitTime {
  parameter: string;
  initTime: string;
}

export interface InitialTimeResponse_Transferable {
  readonly initTimes: Array<InitTime>;
}

export class InitialTimeResponse {
  /**
   * Wrap the raw response of a timeframe.
   *
   * @param initTimes with parameter and init_date
   */
  constructor(readonly initTimes: Array<InitTime>) {}

  /**
   * Parse a CSV API response
   *
   * @param txt
   */
  static parseFromCsv(txt: string): InitialTimeResponse {
    const format = txt
      .split("\n")
      .filter((l) => l.trim() !== "")
      .map((line, lineNumber) => ({ line, lineNumber }));

    const header = format.shift();
    const firstLine = format.shift();
    const array: Array<InitTime> = [];

    if (header && firstLine) {
      const parameters = header?.line.split(";");
      parameters?.shift();

      const initTimes = firstLine?.line.split(";");
      initTimes?.shift();

      parameters.forEach((element, index) => {
        array.push({
          parameter: element,
          initTime: initTimes[index],
        });
      });
    } else {
      throw Error(`Not a valid CSV`);
    }

    return new InitialTimeResponse(array);
  }

  toTransferable(): InitialTimeResponse_Transferable {
    return {
      initTimes: this.initTimes,
    };
  }

  static fromTransferable(v: InitialTimeResponse_Transferable): InitialTimeResponse {
    return new InitialTimeResponse(v.initTimes);
  }
}

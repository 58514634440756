import { DateTime } from "luxon";
import { SingleParamRequest } from "./BaseRequest";
import { DateTime_fromTransferable, DateTime_toTransferable, DateTime_Transferable } from "./DateTime";
import { ModelIdentifier, ParameterUnit } from "./Identifier";

export enum VectorTileData {
  ISO_LINES = "isolines",
  AVIATION_REPORT = "aviation_reports",
  SYMBOLS = "symbols",
  BARBS = "barbs",
}

export interface TileGeomatery {
  x: number;
  y: number;
  z: number;
}

export interface VectorTileRequest extends SingleParamRequest {
  path?: string;
  geometry: TileGeomatery;
  calibrated?: boolean;
  verticalInterpolation?: "none" | "downscale";
  datetime: DateTime;

  // Type of vector tile data, that request should receive
  vectorTileData: VectorTileData;
}

export interface IsoLinesParameters extends VectorTileRequest {
  radius_median_filter?: number;
  radius_gaussian_filter?: number;
  isoline_min_points?: string;
  isoline_values?: string;
  isoline_range?: string;
}

export type VectorTileRequestUnion = VectorTileRequest & IsoLinesParameters; // | SymbolsParameters | BarbsParameters;

export interface VectorTileRequest_Transferable extends Omit<VectorTileRequestUnion, "datetime"> {
  datetime: DateTime_Transferable;
}

export function VectorTileRequest_toTransferable(req: VectorTileRequestUnion): VectorTileRequest_Transferable {
  return {
    ...req,
    datetime: DateTime_toTransferable(req.datetime),
  };
}

export function VectorTileRequest_fromTransferable(req: VectorTileRequest_Transferable): VectorTileRequestUnion {
  return {
    ...req,
    datetime: DateTime_fromTransferable(req.datetime),
  };
}

export interface VectorLayerStyleRequest {
  parameter: ParameterUnit;
  vectorTileData: VectorTileData;
}

import { FormField } from "@/overlay/components/form-field";
import { useValueNameCreator } from "../../SmartFormUtils/FormHooks/useFormInstructions";
import type { InputInstruction } from "../../SmartFormUtils/commonTypeUtils";

type InputProps = {
  label: string;
  instruction: InputInstruction<string | undefined>;
};

export function ColorPicker({ label, instruction }: InputProps) {
  const { formMethods, currValue } = instruction;
  const { createInputName } = useValueNameCreator<string>(instruction);

  return (
    <FormField label={label} noSemanticLinking={true}>
      <input type="color" value={currValue} {...formMethods.register(createInputName())} />
    </FormField>
  );
}

import { useMemo } from "react";
import {
  type WindowElement,
  type WindowInfo,
  type WindowInstanceId,
  type WindowKind,
  type WindowPosition,
  useDesktop,
} from "..";
import { DragItemType } from "./DragItemType";
import type { TrackId } from "./TrackId";

export function useTracks(
  trackIds: TrackId[],
  renderEmptyTracks: boolean,
  filter?: (_: {
    position: WindowPosition;
    instanceId: WindowInstanceId;
  }) => boolean,
) {
  // explicit management of modal windows is not necessary, but allows us to implement optimizations.
  //
  // For example: if the modals backdrop is opaque, no other tracks have to be rendered, or
  // if multiple modals are visible, we may decide to only render the top most modal

  const desktop = useDesktop();
  const windows = useMemo(() => desktop.windows(), [desktop]);

  const layerStack: [WindowInstanceId, WindowInfo<WindowKind>][] = useMemo(
    () => windows.map((window) => [window.factory.instanceId, window]),
    [windows],
  );

  const { tracks, unmanaged } = useMemo(() => {
    const tracks: Map<TrackId, { instanceId: WindowInstanceId; renderer: () => WindowElement }[]> = renderEmptyTracks
      ? new Map(trackIds.map((trackId) => [trackId, []]))
      : new Map([]);
    const unmanaged: {
      instanceId: WindowInstanceId;
      renderer: () => WindowElement;
    }[] = [];

    // sort rendering order by LRU strategy => most recently used window will be rendered on top
    const monolithic = [...Array(layerStack.length).keys()]; // [0, 1, 2, ...]
    const mappingRenderToDomOrder = [...monolithic];
    mappingRenderToDomOrder.sort(
      (z1, z2) => layerStack[z1][1].state.position.lastUse - layerStack[z2][1].state.position.lastUse,
    );
    const mappingDomToRenderOrder = Array(layerStack.length);
    mappingRenderToDomOrder.forEach((domOrder, zIndex) => {
      mappingDomToRenderOrder[domOrder] = zIndex;
    });

    for (const domOrder of monolithic) {
      const zIndex = mappingDomToRenderOrder[domOrder];
      const window = layerStack[domOrder];
      const [
        windowId,
        {
          state: { position, customProps, size },
          factory: { component, ...windowProps },
        },
      ] = window;

      if (filter && !filter({ instanceId: windowId, position })) {
        continue;
      }

      const onClick = () => desktop.dispatch({ type: "wasUsed", windowId });

      const trackId = position.trackId;

      if (desktop.isWindowVisible(windowId) && trackIds.includes(trackId)) {
        if (position.isManaged) {
          const trackWindows = tracks.get(trackId) ?? [];

          // set zIndex even on managed tracks, which allows correct layering between different overlapping tracks,
          // as well as unmanaged windows by default. Tracks not needing a z layering can just overwrite the definition.

          const style: any = { zIndex };
          const renderer = () =>
            component(windowId, {
              ...windowProps,
              // Make sure that draggable default value is true, even when it's undefined
              draggable: windowProps.draggable === undefined ? true : windowProps.draggable,
              customProps: customProps,
              position,
              size,
              componentFactory: component,
              style,
              onClick,
            });
          trackWindows.push({ instanceId: windowId, renderer });

          tracks.set(trackId, trackWindows);
        } else {
          // set zIndex even on managed tracks, which allows correct layering between different overlapping tracks,
          // as well as unmanaged windows by default. Tracks not needing a z layering can just overwrite the definition.
          const style: any = {
            zIndex,
            position: "absolute",
            left: position.left,
            top: position.top,
          };
          const renderer = () =>
            component(windowId, {
              ...windowProps,
              // Make sure that draggable default value is true, even when it's undefined
              draggable: windowProps.draggable === undefined ? true : windowProps.draggable,
              customProps: customProps,
              position,
              size,
              componentFactory: component,
              style,
              onClick,
            });
          unmanaged.push({ instanceId: windowId, renderer });
        }
      }
    }

    return {
      tracks,
      unmanaged,
    };
  }, [renderEmptyTracks, trackIds, layerStack, filter, desktop]);

  return {
    tracks,
    unmanaged,
  };
}

export function useIsModalVisible() {
  const desktop = useDesktop();
  const windows = useMemo(() => desktop.windows(), [desktop]);

  let isVisible = false;

  for (const window of windows) {
    isVisible = isVisible || (window.state.isVisible && window.factory.dragItemType === DragItemType.ModalWindow);
  }

  return isVisible;
}

import type { LayerWithParameterUnit } from "@/layers/utility/createLayerObject";
import { ColorThresholdInput } from "@/overlay/components/CommonParts/Threshold/ColorThresholdInput";
import { Button } from "@/overlay/components/CommonParts/buttons";
import { PoiInputFields } from "@/overlay/components/LayerStackWindow/PoiLayerPanel/PoiInputFields";
import { ModelAndWeatherParameterForm } from "@/overlay/components/ModelAndWeatherParameterForm";
import type { ParameterEditCallback } from "@/overlay/components/WeatherParameterForm";
import { FormField } from "@/overlay/components/form-field";
import { WindowKind, instanceId, useDesktop } from "@/overlay/components/window";
import {
  type ColoringOptions,
  DefaultThreshold,
  type IconData,
  type PoiOptions,
  type ThresholdColor,
} from "@/reducer/client-models";
import type { PoiLayer } from "@/reducer/client-models/PoiLayer";
import type { PoiDataGrid } from "@/threads/DataProcessingThread/types";
import { Trans, t } from "@lingui/macro";
import type { MapId } from "@mm/metx-workbench.meteomatics.com";
import { useEffect, useState } from "react";

const PoiLayerPanel = (props: {
  mapId: MapId;
  poiOptions: PoiOptions;
  onChangeItem: (newItem: IconData) => void;
  onChangePoiOptions: (options: Partial<PoiOptions>) => void;
  onChangeColoringOptions: (coloringOptions: ColoringOptions | undefined) => void;
  onEditParameter: ParameterEditCallback;
  onAddParameter: () => void;
  layer: PoiLayer;
}) => {
  const desktop = useDesktop();
  const [item, setItem] = useState<IconData>(props.poiOptions.item);
  const [coloringOptions, setColoringOptions] = useState<ColoringOptions | undefined>(props.poiOptions.coloringOptions);
  const poiParamUnit = props.layer.parameter_unit;
  useEffect(() => {
    setColoringOptions(props.poiOptions.coloringOptions);
    setItem(props.poiOptions.item);
  }, [props.poiOptions]);

  function triggerColoringOptionsOnChange(propsValue: Partial<ColoringOptions>) {
    if (coloringOptions) {
      const options: ColoringOptions = { ...coloringOptions, ...propsValue };
      setColoringOptions(options);
      props.onChangeColoringOptions(options);
    }
  }

  function addThreshold() {
    if (coloringOptions?.thresholds) {
      const tmpThresholds = [...coloringOptions.thresholds, DefaultThreshold];
      triggerColoringOptionsOnChange({ ...coloringOptions, thresholds: tmpThresholds });
    }
  }

  function changeThreshold(propsValue: Partial<ThresholdColor>, idx: number) {
    if (coloringOptions?.thresholds[idx]) {
      const tmpThresholds = [...coloringOptions.thresholds];
      tmpThresholds[idx] = { ...tmpThresholds[idx], ...propsValue };
      triggerColoringOptionsOnChange({ ...coloringOptions, thresholds: tmpThresholds });
    }
  }

  function removeThreshold(idx: number) {
    if (coloringOptions?.thresholds[idx]) {
      const newArray = coloringOptions.thresholds.filter((_threshold, index) => index !== idx);
      triggerColoringOptionsOnChange({ ...coloringOptions, thresholds: newArray });
    }
  }

  function triggerOnChange(propsValue: Partial<IconData>) {
    const newItem = { ...item, ...propsValue };
    setItem(newItem);
    props.onChangeItem(newItem);
  }

  function handleToggleLabels(hideLabels: boolean) {
    props.onChangePoiOptions({ hideLabels });
    if (!hideLabels) {
      if (!poiParamUnit) {
        props.onAddParameter();
      }
    }
  }

  function handleRemoveParameterAndThresholds() {
    setColoringOptions(undefined);
    props.onChangeColoringOptions(undefined);
  }

  function handleEditPoints() {
    desktop.dispatch({
      type: "confirmationWindow",
      windowId: instanceId(WindowKind.PoiManagerWindow),
      customProps: {
        onUpdate: (data: PoiDataGrid) => {
          triggerOnChange({ coords: data });
        },
        mapId: props.mapId.map,
        initialData: item.coords,
      },
    });
  }
  return (
    <div>
      <PoiInputFields
        item={item}
        hideLabels={!poiParamUnit || props.layer.poiOptions.hideLabels}
        onInputChange={triggerOnChange}
        onToggleLabels={handleToggleLabels}
      />
      <FormField label={t`Points`}>
        <Button icon="my_location" onClick={handleEditPoints}>
          <Trans>Manage</Trans>
        </Button>
      </FormField>

      {!poiParamUnit && (
        <FormField label={t`Parameter`}>
          <Button icon="add" onClick={props.onAddParameter}>
            <Trans>Add Parameter</Trans>
          </Button>
        </FormField>
      )}

      {poiParamUnit && (
        <>
          <ModelAndWeatherParameterForm
            layer={props.layer as LayerWithParameterUnit}
            mapId={props.mapId}
            onEditParameter={props.onEditParameter}
            onRemoveParameter={handleRemoveParameterAndThresholds}
          />

          <div className={"form-field"}>
            <span className="form-field form-field--no-semantic-link">
              <span />
              <span>
                <Trans>Thresholds</Trans>:
              </span>
            </span>
          </div>

          {coloringOptions?.thresholds.map((threshold, idx) => {
            return (
              <ColorThresholdInput
                key={`${idx}_${threshold.color}`}
                threshold={threshold}
                onThresholdChange={(data) => changeThreshold(data, idx)}
                onRemove={() => removeThreshold(idx)}
                step={poiParamUnit.includes("dust_aod") ? 0.1 : 1}
              />
            );
          })}
          <div className={"center-btn separator"}>
            <Button onClick={() => addThreshold()}>
              <Trans>Add Threshold</Trans>
              <span className="material-icons">add</span>
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default PoiLayerPanel;

import { setTitle } from "@/effects";
import { Trans, t } from "@lingui/macro";
import { useEffect } from "react";

import "./loading-screen.scss";

export default function LoadingScreenPage(props: { what?: string }) {
  useEffect(() => {
    setTitle(t({ id: "loading-screen.title", message: "Loading..." }));
  }, []);

  return (
    <div className="loading-page">
      <mm-loader size="xlarge" />
    </div>
  );
}

import { useUser } from "@/api/hooks/user";
import { getSentryLocalStorage, setSentryLocalStorage } from "@/sentry/SentryRuntimeStorage";
import { SENTRY_TRANSACTION_NAMES } from "@/sentry/TransactionConst";
import { setTag } from "@sentry/react";
import type { ErrorEvent, Event, TransactionEvent } from "@sentry/types";
import { type PropsWithChildren, useEffect } from "react";

const SENTRY_IGNORED_TRANSACTION_NAMES = ["/oauth", "/beta", "/beta/profiles", "/login"];
const SENTRY_ISSUE_RESIZE_OBSERVER = "ResizeObserver loop";

/**
 * Sentry callback to be called on every transaction.
 * This function dictates when to send transaction logs to Sentry server.
 *
 * See: https://docs.sentry.io/platforms/javascript/configuration/filtering/#using-platformidentifier-namebefore-send-transaction-
 */
export function sentryBeforeSendTransaction(
  event: TransactionEvent,
): TransactionEvent | PromiseLike<TransactionEvent | null> | null {
  const { log_mode } = getSentryLocalStorage();
  // Skip custom transaction if log_mode is not set
  if (SENTRY_TRANSACTION_NAMES.MAP_LOADING_SPEED === event.transaction && log_mode == null) {
    return null;
  }

  // Skip transactions that provide almost no useful data
  if (event.transaction && SENTRY_IGNORED_TRANSACTION_NAMES.indexOf(event.transaction) >= 0) {
    return null;
  }

  return event;
}

/**
 * Sentry callback called on every event, but transaction
 * This function dictates when to send error logs to Sentry server.
 * @param event Sentry event
 */
export function sentryBeforeSendEvent(event: ErrorEvent): ErrorEvent | PromiseLike<ErrorEvent | null> | null {
  // Skip ResizeObserver limit issue
  if (event.message?.includes?.(SENTRY_ISSUE_RESIZE_OBSERVER)) {
    return null;
  }
  return event;
}

/**
 * Utility to configure sentry within the React lifecycle, especially based on the application data.
 */
export function useSentryBootstrap() {
  const { user } = useUser(false);
  useEffect(() => {
    if (user) {
      // Set Sentry tags to distinguish special agents
      if (user?.log_mode) {
        setTag("log_mode", user.log_mode);
      }
      if (user?.is_superuser) {
        setTag("user_type", "superuser");
      }

      // -- Tags for all users --
      user.username && setTag("username", user.username);
      setTag("screen_resolution", `${window.screen.width}x${window.screen.height}`);
      // ------------------------

      // Set the per-user sentry related configurations to session storage
      // so we can control determine the sentry behavior based on these flags.
      if (user.log_mode) {
        setSentryLocalStorage({ log_mode: user.log_mode });
      }
    }
  }, [user]);
}

/**
 * Logical layer to run everything Sentry related before app components mount.
 */
export const SentryBootstrapper = ({ children }: PropsWithChildren<unknown>) => {
  useSentryBootstrap();
  return <>{children}</>;
};

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * MetX Workbench
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var runtime = require("../runtime");
/**
 *
 */
var TabApi = /** @class */ (function (_super) {
    __extends(TabApi, _super);
    function TabApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Create Tab
     */
    TabApi.prototype.v2CreateTabRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.profileId === null || requestParameters.profileId === undefined) {
                            throw new runtime.RequiredError('profileId', 'Required parameter requestParameters.profileId was null or undefined when calling v2CreateTab.');
                        }
                        if (requestParameters.tabCreate === null || requestParameters.tabCreate === undefined) {
                            throw new runtime.RequiredError('tabCreate', 'Required parameter requestParameters.tabCreate was null or undefined when calling v2CreateTab.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v2/tab/{profile_id}".replace("{" + "profile_id" + "}", encodeURIComponent(String(requestParameters.profileId))),
                            method: 'POST',
                            headers: headerParameters,
                            query: queryParameters,
                            body: requestParameters.tabCreate,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response)];
                }
            });
        });
    };
    /**
     * Create Tab
     */
    TabApi.prototype.v2CreateTab = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v2CreateTabRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Delete Tab
     */
    TabApi.prototype.v2DeleteTabRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.tabId === null || requestParameters.tabId === undefined) {
                            throw new runtime.RequiredError('tabId', 'Required parameter requestParameters.tabId was null or undefined when calling v2DeleteTab.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v2/tab/{tab_id}".replace("{" + "tab_id" + "}", encodeURIComponent(String(requestParameters.tabId))),
                            method: 'DELETE',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.TextApiResponse(response)];
                }
            });
        });
    };
    /**
     * Delete Tab
     */
    TabApi.prototype.v2DeleteTab = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v2DeleteTabRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Read Profile Tabs
     */
    TabApi.prototype.v2ReadProfileTabsRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.profileId === null || requestParameters.profileId === undefined) {
                            throw new runtime.RequiredError('profileId', 'Required parameter requestParameters.profileId was null or undefined when calling v2ReadProfileTabs.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v2/tab/profile/{profile_id}".replace("{" + "profile_id" + "}", encodeURIComponent(String(requestParameters.profileId))),
                            method: 'GET',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response)];
                }
            });
        });
    };
    /**
     * Read Profile Tabs
     */
    TabApi.prototype.v2ReadProfileTabs = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v2ReadProfileTabsRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Read Tab
     */
    TabApi.prototype.v2ReadTabRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.tabId === null || requestParameters.tabId === undefined) {
                            throw new runtime.RequiredError('tabId', 'Required parameter requestParameters.tabId was null or undefined when calling v2ReadTab.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v2/tab/{tab_id}".replace("{" + "tab_id" + "}", encodeURIComponent(String(requestParameters.tabId))),
                            method: 'GET',
                            headers: headerParameters,
                            query: queryParameters,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response)];
                }
            });
        });
    };
    /**
     * Read Tab
     */
    TabApi.prototype.v2ReadTab = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v2ReadTabRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Profile Sync Tab Snapshot
     */
    TabApi.prototype.v2SyncTabSnapshotRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.tabId === null || requestParameters.tabId === undefined) {
                            throw new runtime.RequiredError('tabId', 'Required parameter requestParameters.tabId was null or undefined when calling v2SyncTabSnapshot.');
                        }
                        if (requestParameters.tabSnapshot === null || requestParameters.tabSnapshot === undefined) {
                            throw new runtime.RequiredError('tabSnapshot', 'Required parameter requestParameters.tabSnapshot was null or undefined when calling v2SyncTabSnapshot.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v2/tab/snapshot/{tab_id}".replace("{" + "tab_id" + "}", encodeURIComponent(String(requestParameters.tabId))),
                            method: 'PUT',
                            headers: headerParameters,
                            query: queryParameters,
                            body: requestParameters.tabSnapshot,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.TextApiResponse(response)];
                }
            });
        });
    };
    /**
     * Profile Sync Tab Snapshot
     */
    TabApi.prototype.v2SyncTabSnapshot = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v2SyncTabSnapshotRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Profile Update Tab
     */
    TabApi.prototype.v2UpdateTabRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.tabId === null || requestParameters.tabId === undefined) {
                            throw new runtime.RequiredError('tabId', 'Required parameter requestParameters.tabId was null or undefined when calling v2UpdateTab.');
                        }
                        if (requestParameters.tabUpdate === null || requestParameters.tabUpdate === undefined) {
                            throw new runtime.RequiredError('tabUpdate', 'Required parameter requestParameters.tabUpdate was null or undefined when calling v2UpdateTab.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v2/tab/tab/{tab_id}".replace("{" + "tab_id" + "}", encodeURIComponent(String(requestParameters.tabId))),
                            method: 'PUT',
                            headers: headerParameters,
                            query: queryParameters,
                            body: requestParameters.tabUpdate,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response)];
                }
            });
        });
    };
    /**
     * Profile Update Tab
     */
    TabApi.prototype.v2UpdateTab = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v2UpdateTabRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Update Tab Orders
     */
    TabApi.prototype.v2UpdateTabOrdersRaw = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, _a, _b, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (requestParameters.tabUpdate === null || requestParameters.tabUpdate === undefined) {
                            throw new runtime.RequiredError('tabUpdate', 'Required parameter requestParameters.tabUpdate was null or undefined when calling v2UpdateTabOrders.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.apiKey) {
                            queryParameters["access_token"] = this.configuration.apiKey("access_token"); // APIKeyQuery authentication
                        }
                        if (!(this.configuration && this.configuration.accessToken)) return [3 /*break*/, 2];
                        // oauth required
                        _a = headerParameters;
                        _b = "Authorization";
                        return [4 /*yield*/, this.configuration.accessToken("OAuth2", [])];
                    case 1:
                        // oauth required
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2: return [4 /*yield*/, this.request({
                            path: "/api/v2/tab/tabs/orders",
                            method: 'PUT',
                            headers: headerParameters,
                            query: queryParameters,
                            body: requestParameters.tabUpdate,
                        }, initOverrides)];
                    case 3:
                        response = _c.sent();
                        return [2 /*return*/, new runtime.TextApiResponse(response)];
                }
            });
        });
    };
    /**
     * Update Tab Orders
     */
    TabApi.prototype.v2UpdateTabOrders = function (requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.v2UpdateTabOrdersRaw(requestParameters, initOverrides)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    return TabApi;
}(runtime.BaseAPI));
exports.TabApi = TabApi;

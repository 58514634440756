/**
 * Event types to trigger self transition within a single state
 */
enum SelfTransitionEventType {
  updateLoadingState = "updateLoadingState",
}

export const LoadingStateEventType = { ...SelfTransitionEventType };

export type LoadingStateEventUnion = {
  type: SelfTransitionEventType.updateLoadingState;
  stateById: { [id: number]: boolean }; // boolean specifies isLoading=true
};

import React from "react";

export interface SmartSelectorItem<T> {
  value: T;
  label: string;
  key: string | number;
}

interface SmartSelectorProps<T> {
  className?: string;
  items: SmartSelectorItem<T>[]; //Items to render as options.
  selectedItemKey?: string | number; // If provided and it matches with one of the items' key, it will select the item option.
  onSelect: (itemValue: T) => void; // This callbacks run when option value changed. items.value instance is passed back.
  disabled?: boolean;
  maxSize?: number;
  minSize?: number;
  hidden?: boolean;
  "data-testid"?: string;
}

const SmartSelector = <ValueType,>(props: SmartSelectorProps<any>) => {
  // define the number
  const selectSize = props.maxSize ? Math.max(Math.min(props.maxSize, props.items.length), props.minSize || 1) : 1;
  const handleOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    // Find the selected map instance based on option input and run the onMapSelect callback.
    const selectedItem: SmartSelectorItem<ValueType> = props.items.filter(
      (item) => item.key.toString() === event.target.value,
    )[0];
    props.onSelect(selectedItem.value);
  };
  return (
    <select
      className={props.className}
      onChange={handleOnChange}
      value={props.selectedItemKey ?? undefined}
      disabled={props.disabled}
      size={selectSize}
      hidden={props.hidden}
      data-testid={props["data-testid"]}
    >
      {
        // Render map options.
        props.items.map((item) => (
          <option key={item.key} value={item.key}>
            {item.label}
          </option>
        ))
      }
    </select>
  );
};

export default React.memo(SmartSelector);

import { useValueNameCreator } from "@/overlay/components/SmartForm/SmartFormUtils/FormHooks/useFormInstructions";
import type { InputInstruction } from "@/overlay/components/SmartForm/SmartFormUtils/commonTypeUtils";
import { FormField } from "@/overlay/components/form-field";

export type SliderInputData = number;

type InputProps = {
  label: string;
  instruction: InputInstruction<number | undefined>;
  range: { step?: number; min: number; max: number };
};

export function SliderInput(props: InputProps) {
  const { label, instruction, range } = props;
  const { formMethods, currValue } = instruction;
  const { createInputName } = useValueNameCreator<SliderInputData>(instruction);

  return (
    <FormField label={label}>
      <input
        type="range"
        value={currValue}
        {...range}
        {...formMethods.register(createInputName(), { valueAsNumber: true })}
      />
    </FormField>
  );
}

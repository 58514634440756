import { CacheDatumState, networkCaches } from "@/cache/GlobalCache";
import type { MapboxMap } from "@/layers/MapboxMap";
import { StartColor } from "@/reducer/client-models";
import { transformEnsSelectStringToArray } from "@/utility/ensemble";
import { highchartsColors } from "@/utility/highchartsValues";
import { safeFmt } from "@/utility/safeFmt";
import { getModelBoundingBox } from "@/weather-parameters/lookup";
import { VectorTileData } from "@mm/api.meteomatics.com";
import type { GuiTimeZone, IsoLinesLayer } from "@mm/metx-workbench.meteomatics.com";
import { flatten } from "lodash";
import type { DateTime } from "luxon";
import type mapboxgl from "mapbox-gl";
import {
  type EventData,
  type LineLayout,
  type LinePaint,
  type MapMouseEvent,
  type MapboxGeoJSONFeature,
  Popup,
  type SymbolLayout,
  type SymbolPaint,
} from "mapbox-gl";
import { ChangeDetector } from "../ChangeDetector";
import type { ScenePublicApi } from "../Compositor";
import { type CheckedProps, LayerBase, type PropsChecker } from "../LayerBase";

// TODO Cleanup all ts-ignore flags
export class IsoLinesLayerImpl extends LayerBase<IsoLinesLayer> {
  private popup = new Popup({
    closeButton: false,
    closeOnClick: false,
  });
  readonly layer_placeholder_id: string;
  readonly mapbox_source: string;

  private sources: string[] = [];
  private layers: string[] = [];

  prevDateTime: DateTime | undefined;

  private beforeLayerId?: string;
  private displayDateTime: ChangeDetector<string>;
  private style: mapboxgl.Style | undefined;

  constructor(uid: number, props: IsoLinesLayer, scene: ScenePublicApi, timezone: GuiTimeZone) {
    super(uid, props, scene, timezone);
    this.layer_placeholder_id = this.humanReadableId();
    this.mapbox_source = safeFmt`source_${this.humanReadableId()}`;

    this.displayDateTime = new ChangeDetector(this.scene.getDisplayTimeWithOffset().toISO());

    // Workaround for fixing z-order, without big refactoring on Compositor
    // Problem is, that sources and layers are created async, and compositor
    // isn't aware of it, causing wrong z-order on initial load
    // TODO Refactor Compositor setLayerStackDescription to support
    // async created sources and layers
    const map = this.scene.getMapboxMap();
    map.addSource(this.mapbox_source, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });
    map.addLayer({
      type: "line",
      id: this.layer_placeholder_id,
      source: this.mapbox_source,
    });

    const { asynchronous } = networkCaches.spatiotemporal_vector_tile_cache.retrieveVectorLayerStyle({
      parameter: this.props.parameter_unit,
      vectorTileData: VectorTileData.ISO_LINES,
    });
    asynchronous
      .then((style) => {
        if (style) {
          this.style = style;
          this.createSourceAndLayer(style);
        }
      })
      .catch();
  }

  private createSourceAndLayer(style: mapboxgl.Style) {
    const ensSelectList = transformEnsSelectStringToArray(this.props.ens_select || "");
    if (ensSelectList.length) {
      // create sources and layers for each ensSelect
      ensSelectList.forEach((ensSelectValue, index) => {
        // we set the color for each ensSelect only if there are more than one value
        // highchart colors default list is an array of strings, but it can be gradients too, if customised.
        // Since we use the default list, we can assume that it is a string
        const color =
          ensSelectList.length > 1
            ? highchartsColors
              ? (highchartsColors[index % 10] as string)
              : StartColor
            : undefined;

        this._createSources(style, ensSelectValue);
        this._createLayers(style, ensSelectValue, color);
      });
    } else {
      // create usual source and layer without ensSelect
      this._createSources(style, "");
      this._createLayers(style, "");
    }
  }

  private _createSources(style: mapboxgl.Style, ensSelectValue: string) {
    const map = this.scene.getMapboxMap();

    const { lngMin, latMin, lngMax, latMax } = getModelBoundingBox(this.props.model);
    for (const sourceName of Object.keys(style.sources)) {
      const sourceId = `${this.mapbox_source}_${sourceName}_${ensSelectValue}`;

      map.addSource(sourceId, {
        ...style.sources[sourceName],
        // @ts-ignore
        bounds: [lngMin, latMin, lngMax, latMax],
        // @ts-ignore
        fetchTile: (params) => {
          const { asynchronous } = this._fetchTile(
            params,
            this.scene.getDisplayTimeWithOffset(),
            sourceId,
            ensSelectValue,
          );
          return asynchronous;
        },
        ensSelectValue,
      });

      this.sources.push(sourceId);
    }
  }

  private _createPaintProperty(layerType: "line" | "symbol", color?: string): SymbolPaint | LinePaint {
    if (layerType === "line") {
      return {
        "line-color": color ? color : this.props.line_color,
        "line-width": this.props.line_width,
        "line-opacity": this.props.opacity,
      };
    }
    return {
      "text-color": color ? color : this.props.text_color,
      "text-opacity": this.props.opacity,
    };
  }

  removeHoverListener(map: MapboxMap) {
    for (const layerId of this.layers) {
      map.off(
        "mouseenter",
        layerId,
        this.mouseEnterListener.bind(this, { map, popup: this.popup, ensSelectValue: "" }),
      );
      map.off("mouseleave", layerId, this.mouseLeaveListener.bind(this, { map, popup: this.popup }));
    }
  }

  mouseEnterListener(
    layerObj: {
      map: MapboxMap;
      popup: mapboxgl.Popup;
      ensSelectValue: string;
    },
    e: MapMouseEvent & { features?: MapboxGeoJSONFeature[] | undefined } & EventData,
  ) {
    const { map, ensSelectValue, popup } = layerObj;
    if (e.features) {
      map.getCanvas().style.cursor = "pointer";
      const {
        features: [feature],
      } = e;
      // biome-ignore lint/style/noNonNullAssertion: TODO porperties can be null
      const description = `<b>${ensSelectValue}</b><br/> ${feature.properties!.value}`;
      if (feature.geometry.type === "LineString") {
        const coordinates = e.lngLat.wrap();
        popup.setLngLat(coordinates).setHTML(description).addTo(map);
      }
    }
  }

  mouseLeaveListener(
    layerObj: {
      map: MapboxMap;
      popup: mapboxgl.Popup;
    },
    e: any,
  ) {
    const { map, popup } = layerObj;
    map.getCanvas().style.cursor = "";
    popup.remove();
  }

  addHoverListener(map: MapboxMap, layerId: string, ensSelectValue: string) {
    map.on("mouseenter", layerId, this.mouseEnterListener.bind(this, { map, popup: this.popup, ensSelectValue }));
    map.on("mouseleave", layerId, this.mouseLeaveListener.bind(this, { map, popup: this.popup }));
  }

  private _createLayers(style: mapboxgl.Style, ensSelectValue: string, color?: string) {
    const map = this.scene.getMapboxMap();
    for (const layer of style.layers) {
      const layerId = `${this.humanReadableId()}_${layer.id}_${ensSelectValue}`;
      if (ensSelectValue) {
        this.addHoverListener(map, layerId, ensSelectValue);
      }
      map.addLayer(
        // @ts-ignore
        {
          id: layerId,
          type: layer.type,
          // @ts-ignore
          source: `${this.mapbox_source}_${layer.source}_${ensSelectValue}`,
          // @ts-ignore
          "source-layer": layer["source-layer"],
          layout: {
            // @ts-ignore
            ...layer.layout,
            visibility: this.props.show ? "visible" : "none",
          },
          // @ts-ignore
          paint: this._createPaintProperty(layer.type, color),
        },
        this.beforeLayerId,
      );

      this.layers.push(layerId);
    }
  }

  private _fetchTile(
    { x, y, z }: { x: number; y: number; z: number },
    datetime: DateTime,
    source: string,
    ensSelect: string,
  ) {
    // @ts-ignore
    return networkCaches.spatiotemporal_vector_tile_cache.retrieveVectorTile({
      source,
      model: this.props.model,
      parameter: this.props.parameter_unit,
      vectorTileData: VectorTileData.ISO_LINES,
      geometry: {
        x,
        y,
        z,
      },
      datetime,
      isoline_values: this.props.values,
      isoline_range: this.props.value_range,
      radius_gaussian_filter: this.props.filter_gauss,
      radius_median_filter: this.props.filter_median,
      calibrated: this.props.calibrated,
      ensSelect: ensSelect ? ensSelect : "",
    });
  }

  get mapboxIndex(): string {
    return this.layer_placeholder_id;
  }

  getActiveWeatherParametersAsString() {
    return [
      {
        model: this.props.model,
        parameter: this.props.parameter_unit,
      },
    ];
  }

  checker(): PropsChecker<IsoLinesLayer, LayerBase<IsoLinesLayer>> {
    type CheckerProps = CheckedProps<IsoLinesLayer>;
    const checkLayerProp = (property: CheckerProps) => (prev: any, curr: any) => {
      const changed = prev[property] !== curr[property];
      if (changed) {
        this.setLayerProps({ [property]: curr[property] });
        switch (property) {
          case "model":
          case "parameter_unit":
          case "ens_select":
          case "calibrated":
          case "filter_median":
          case "filter_gauss":
          case "values":
          case "value_range":
            // removes all layers and sources and add it again with new ens select string
            this.removeLayer();
            if (this.style) {
              this.createSourceAndLayer(this.style);
            }
            break;
          case "show":
            this._updateMapboxSymbolLayoutProperties({ visibility: curr.show ? "visible" : "none" });
            this._updateMapboxLineLayoutProperties({ visibility: curr.show ? "visible" : "none" });
            break;
          case "opacity":
            this._updateMapboxSymbolPaintProperties({
              "text-opacity": curr[property],
            });
            this._updateMapboxLinePaintProperties({
              "line-opacity": curr[property],
            });
            break;
          case "text_size":
            this._updateMapboxSymbolLayoutProperties({
              "text-size": curr[property],
            });
            break;
          case "text_color":
            this._updateMapboxSymbolPaintProperties({
              "text-color": curr[property],
            });
            break;
          case "line_width":
            this._updateMapboxLinePaintProperties({
              "line-width": curr[property],
            });
            break;
          case "line_color":
            this._updateMapboxLinePaintProperties({
              "line-color": curr[property],
            });
            break;
          case "custom_options":
            break;
          default: {
            const _exhaustive: never = property;
            return _exhaustive;
          }
        }
      }
      return changed;
    };
    return {
      model: checkLayerProp("model"),
      opacity: checkLayerProp("opacity"),
      parameter_unit: checkLayerProp("parameter_unit"),
      calibrated: checkLayerProp("calibrated"),
      show: checkLayerProp("show"),
      text_size: checkLayerProp("text_size"),
      text_color: checkLayerProp("text_color"),
      line_width: checkLayerProp("line_width"),
      line_color: checkLayerProp("line_color"),
      filter_median: checkLayerProp("filter_median"),
      filter_gauss: checkLayerProp("filter_gauss"),
      values: checkLayerProp("values"),
      value_range: checkLayerProp("value_range"),
      ens_select: checkLayerProp("ens_select"),
      custom_options: checkLayerProp("custom_options"),
    };
  }

  isRebuffering(_time: DateTime): boolean {
    return !this._hasAllSourcesLoaded();
  }

  beforeRender(): void {
    // Let's assume, if sources array is empty, no sources are created to update
    if (!this.sources.length) return;

    if (this.displayDateTime.changed(this.scene.getDisplayTimeWithOffset().toISO())) {
      this._expire();
    }
  }

  removeLayer(): void {
    const map = this.scene.getMapboxMap();
    this.removeHoverListener(map);
    for (const layer of this.layers) {
      map.removeLayer(layer);
    }
    this.layers = [];
    for (const source of this.sources) {
      map.removeSource(source);
    }
    this.sources = [];
  }

  fetchData(timeFrame: DateTime): Promise<void> {
    const dateTimeWithOffset = this.scene.getDateTimeWithOffset(timeFrame);
    return this._getTilePromiseList(dateTimeWithOffset);
  }

  humanReadableId(): string {
    return safeFmt`metx.isolinelayer#${this.uid}#${this.props.model}.${this.props.parameter_unit}`;
  }

  moveZIndex(beforeLayerId?: string) {
    // Cache before layer, as at first load layers isn't create yet
    this.beforeLayerId = beforeLayerId;

    const map = this.scene.getMapboxMap();
    super.moveZIndex(beforeLayerId);

    map.moveLayer(this.layer_placeholder_id, beforeLayerId);
    // Use reversed array as we don't want to mess up order of layers
    for (const layer of this.layers) {
      if (map.getLayer(layer)) {
        map.moveLayer(layer, beforeLayerId);
      }
    }
  }

  private _expire() {
    const map = this.scene.getMapboxMap();

    for (const sourceId of this.sources) {
      const source = map.getSource(sourceId);
      // @ts-ignore
      source.expireTiles();
    }
  }

  private _updateMapboxSymbolPaintProperties(props: SymbolPaint) {
    const map = this.scene.getMapboxMap();
    for (const [name, value] of Object.entries(props)) {
      for (const layer of this.layers) {
        if (map.getLayer(layer).type === "symbol") map.setPaintProperty(layer, name, value);
      }
    }
  }

  private _updateMapboxSymbolLayoutProperties(props: SymbolLayout) {
    const map = this.scene.getMapboxMap();
    for (const [name, value] of Object.entries(props)) {
      for (const layer of this.layers) {
        if (map.getLayer(layer).type === "symbol") this.scene.getMapboxMap().setLayoutProperty(layer, name, value);
      }
    }
  }

  private _updateMapboxLinePaintProperties(props: LinePaint) {
    const map = this.scene.getMapboxMap();
    for (const [name, value] of Object.entries(props)) {
      for (const layer of this.layers) {
        if (map.getLayer(layer).type === "line") map.setPaintProperty(layer, name, value);
      }
    }
  }

  private _updateMapboxLineLayoutProperties(props: LineLayout) {
    const map = this.scene.getMapboxMap();
    for (const [name, value] of Object.entries(props)) {
      for (const layer of this.layers) {
        if (map.getLayer(layer).type === "line") this.scene.getMapboxMap().setLayoutProperty(layer, name, value);
      }
    }
  }

  private _hasAllSourcesLoaded() {
    const tileStates = this._getTileRequestList(this.scene.getDisplayTimeWithOffset()).map(
      ({ synchronous }) => synchronous,
    );
    return !tileStates.includes(CacheDatumState.Pending);
  }

  private _getTileRequestList(dateTime: DateTime) {
    const map = this.scene.getMapboxMap();

    const statuses = flatten(
      this.sources.map((sourceName) => {
        const source = map.getSource(sourceName);
        // @ts-ignore
        const ensSelectValue = source?._options?.ensSelectValue ?? "";
        // Check if source has implemented coveringTiles function
        // Otherwise just return empty array
        // @ts-ignore
        if (source.coveringTiles) {
          return (
            source
              // @ts-ignore
              .coveringTiles()
              .map((tile: any) => this._fetchTile(tile, dateTime, sourceName, ensSelectValue))
          );
        }

        return [];
      }),
    );

    return statuses;
  }

  private async _getTilePromiseList(dateTimeWithOffset: DateTime) {
    // fetchData is expecting Promise<void> so let it be
    return Promise.all(this._getTileRequestList(dateTimeWithOffset).map(({ asynchronous }) => asynchronous)).then(() =>
      Promise.resolve(),
    );
  }
}

import { GuiTimeZone } from "@mm/metx-workbench.meteomatics.com";
import { DateTime } from "luxon";
import type { StringStateValueFrom } from "../state-machine-utils/typeUtilts";
import { getAutoRefreshTime } from "./timeStateActions";
import type { TimePointCtx } from "./timeStateCtxTypes";
import type { TimeStateMachine } from "./timeStateMachine";

/**
 * The current version of the state machine schema which this version of code expects.
 * It gets persisted to DB upon update API call
 * and provides us to have an identifier to accomodate future changes and migration.
 *
 * Be careful to change this attribute, as we currently throw away the persisted state in DB
 * if the version in the frontend code doesn't match with the one on API response.
 */
export const timeStateSchemaVer = "1.0";

export const defaultTimeStateVal: StringStateValueFrom<TimeStateMachine> = "TIME_POINT.DEFAULT_TIME";
export const defaultTimeCtx: TimePointCtx = {
  id_profile: -1,
  displayTime: getAutoRefreshTime(DateTime.utc()),
  timezone: GuiTimeZone.utc,
  modeOpts: null,
  meta: {
    timeStateApi: null,
  },
};

import { WindowKind, instanceId, useDesktop } from "@/overlay/components";
import { type RemovableElement, selectedElementToRemove } from "@/reducer/UIState";
import type React from "react";
import type { ReactChild } from "react";
import { useDispatch } from "react-redux";
type ConfirmDialogProps = {
  children: ReactChild;
  element: RemovableElement;
  classNames?: string;
};

export function DeleteConfirmationTrigger(props: ConfirmDialogProps) {
  const dispatch = useDispatch();
  const desktop = useDesktop();
  const openConfirmWindow = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(selectedElementToRemove(props.element));
    desktop.dispatch({
      type: "setWindowVisibility",
      windowId: instanceId(WindowKind.DeleteConfirmation),
      isVisible: true,
    });
  };

  return (
    <span className={` ${props.classNames ?? ""}`} onClick={(event) => openConfirmWindow(event)}>
      {props.children}
    </span>
  );
}

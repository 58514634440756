import { EnvError, makeExactValidator } from "envalid";

const isValidUrl = (val: string) => {
  try {
    new URL(val);
    return true;
  } catch (e) {
    return false;
  }
};

/**
 * Custom env variable validator.
 * Expects a csv of multiple URLs:
 * e.g: ENV_URLS="http://example1.com,http://example2.com,http://example3.com"
 */
export const multiUrls = makeExactValidator<string[]>((input: string) => {
  const values = input.split(",");
  for (const value of values) {
    if (!isValidUrl(value)) {
      throw new EnvError(
        `Invalid URL in the CSV value: "${value}". Each URL value needs to contain the protocol and the domain. e.g: "https://api.example.com"`,
      );
    }
  }
  return values;
});

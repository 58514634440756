export enum CoordinateSystem {
  WGS84 = "WGS84",
  /**
   * Web mercator projection in some fake meters unit. Do not use these meters for distance calculations.
   *
   * Proj4js aliases are 'EPSG:3785', 'GOOGLE', 'EPSG:900913', 'EPSG:102113'.
   * Alternative codes in general: 900913 3587 54004 41001 102113 102100 3785.
   *
   * Projected bounds are:
   *
   * ```
   * West -20026376.39
   * South -20048966.10
   * East 20026376.39
   * North 20048966.10
   * ```
   *
   * @see https://epsg.io/3857
   */
  EPSG3857 = "EPSG:3857",
  EPSG4326 = "EPSG:4326",
}

export const DEFAULT_EPSILON: Record<CoordinateSystem, number> = {
  [CoordinateSystem.WGS84]: 1e-6,
  [CoordinateSystem.EPSG3857]: 1e-3,
  [CoordinateSystem.EPSG4326]: 1e-6,
};

import type { WeatherLayerUnion } from "@/layers";
import type { ActiveLayers } from "@/overlay/components/PopupPlotWindow";
import { getAllEnsSelect } from "@/utility/ensemble";
import { highchartsColors } from "@/utility/highchartsValues";
import { useMemo } from "react";
import { useMaps } from "../MapsState";
import { EXCLUDED_LAYERS } from "./constants";

import type { LayerKind } from "@/layers/utility/createLayerObject";
import { getModel } from "@/overlay/components/SmartForm/SmartFormUtils/FormHooks/useModel";
import { DefaultPlotSeries, StartColor } from "@/reducer/client-models";
import Logger from "logging";

const logger = Logger.fromFilename(__filename);

export const useActiveLayers = (tabId: number) => {
  const maps = useMaps(tabId);

  const layers = useMemo(() => {
    const activeLayerList: ActiveLayers[] = [];
    for (const cartographicMap of maps) {
      if (cartographicMap) {
        cartographicMap.layers
          ?.filter(
            (layer) =>
              layer.kind && !EXCLUDED_LAYERS.includes(layer.kind as LayerKind) && layer.opacity !== 0 && layer.show,
          )
          .forEach((_layer, index) => {
            const layer = _layer as WeatherLayerUnion;
            if (!layer.kind) {
              logger.error("Layer doesn't have a type label [layer.kind]");
              return;
            }
            const { parameter_unit: parameter, model: layerModel, calibrated } = layer;
            const modelSchema = getModel(layerModel);
            const ensSelect = getAllEnsSelect(layer.ens_select, modelSchema?.num_ensemble_members);

            if (modelSchema) {
              activeLayerList.push({
                mapOrPlotTitle: cartographicMap.title,
                parameter,
                model: modelSchema,
                plotSerieOptions: {
                  ...DefaultPlotSeries,
                  ens_select: ensSelect,
                  series_color: highchartsColors ? highchartsColors[index % 10].toString() : StartColor,
                  // TODO move calibrated outside plotSerieOptions, so
                  // we don't need to pass DefaultPlotSeries
                  calibrated: !!calibrated,
                },
              });
            } else {
              console.error("Could not find the modelSchema", layerModel);
            }
          });
      }
    }

    return activeLayerList;
  }, [maps]);

  return {
    layers,
  };
};

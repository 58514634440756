/**
 * A higher order component to implement a window manager.
 *
 * Provides a simple box, optionally enhanced with close buttons, drag functionality, etc.
 * that can contain arbitrary content and be positioned somewhere in a containing overlay.
 */

import type { WindowKind, WindowSize } from "@/overlay/components";
import { classNames } from "@/utility/jsx";
import { useWindowSize } from "@/window-size";
import { useGesture, useHover } from "@use-gesture/react";
import { clamp, pickBy } from "lodash";
import Logger from "logging";
import {
  type DetailedHTMLProps,
  type HTMLAttributes,
  type HTMLProps,
  type PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { type DragSourceMonitor, useDrag } from "react-dnd";
import type { ComponentFactoryFn, WindowKind_ } from ".";
import { type DraggingWindowState, type WindowPosition, useDesktop } from "./DesktopContext";
import type { DragItemType } from "./DragItemType";
import type { WindowCustomProps } from "./WindowKind";
import ResizeHandle from "./assets/resize-handle.msvg";
import "./style.scss";

const logger = Logger.fromFilename(__filename);

export interface Action {
  icon: string;
  callback: () => void;
}

export function closeAction(callback: () => void): Action {
  return {
    icon: "close",
    callback,
  };
}

const calculateSize = (
  size: number,
  delta: number,
  windowSize?: WindowSize,
  isHeight = false,
  minVal = 0,
  maxVal = 100,
): number => {
  if (!windowSize) {
    return size + delta;
  }

  const windowDimension = isHeight ? windowSize.height : windowSize.width;
  return clamp(size + delta, (minVal * windowDimension) / 100, (maxVal * windowDimension) / 100);
};

export type WindowIdentityProps<K extends WindowKind = WindowKind> = {
  instanceId: string;
  /**
   * The drag item type defines where a window may be moved on the desktop.
   */
  dragItemType: DragItemType;
  /**
   * Flag that indicates whether the window is draggable or not.
   */
  draggable: boolean;
  /**
   * Position description of the window.
   *
   * Note: we differentiate between window preview during drag and drops and the window itself.
   * This is not the position of the preview during drag and drop operations.
   */
  position: WindowPosition;
  /**x
   * Calling this function should render the window itself.
   */
  componentFactory: ComponentFactoryFn;
  /**
   * Function used to render the preview during a drag and drop. Defaults to `componentFactory`.
   */
  componentPreviewFactory?: ComponentFactoryFn;
  /**
   * A custom prop object to be passed to the window.
   */
  customProps: WindowCustomProps<K>;

  size: WindowSize;
  /**
   * Whether the window is resizable or not.
   */
  resizable?: boolean;

  /**
   * The maximum and minimum size of the window in percent of the viewport.
   */
  maxWidth?: number;
  minWidth?: number;
  maxHeight?: number;
  minHeight?: number;

  initialSize?: { width: number; height: number };
};

export type WindowForwardedHtmlProps = Pick<
  DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>,
  "onClick" | "style"
>;

export interface WindowProps<K extends WindowKind> extends WindowIdentityProps<K>, WindowForwardedHtmlProps {
  /**
   * An id that is unique to each component, but consistent across all instances of the component.
   * In contrast, the instanceId should be unique for all instances of a component.
   */
  kind: WindowKind_;
  children: any;
  /**
   * Title of the window inside the title bar.
   */
  title?: string;
  /**
   * Action buttons in the title bar. Windowing systems usually offer: close, minimize, maximize
   */
  actions?: Action[];
  className?: string;
}

const sizeToStyle = (size: WindowSize) =>
  size
    ? {
        width: `${size.width}px`,
        height: `${size.height}px`,
      }
    : {};

function extractWindowsDataProps<K extends WindowKind>(props: WindowProps<K>) {
  const dataProps = Object.keys(props).filter((key) => key.startsWith("data-"));

  return pickBy(props, (_prop, key) => dataProps.includes(key));
}

export type WindowElement = React.ReactElement<WindowIdentityProps<WindowKind>>;

const defaultWindowProps: Partial<WindowProps<WindowKind>> = {
  initialSize: { width: 80, height: 60 },
  maxHeight: 100,
  minHeight: 20,
  maxWidth: 100,
  minWidth: 20,
  resizable: false,
};

export function Window<K extends WindowKind>(props: WindowProps<K> = defaultWindowProps as WindowProps<K>) {
  const windowRef = useRef<HTMLElement>(null);
  const resizeHandleRef = useRef<HTMLDivElement>(null);
  const windowSize = useWindowSize();
  const [windowOverlaySize, setWindowOverlaySize] = useState(props.size);
  const [isResizing, setIsResizing] = useState(false);

  const desktop = useDesktop();

  const dragItem: DraggingWindowState = {
    ...props,
    type: props.dragItemType,
    rect: null,
  };

  useEffect(() => {
    if (!windowSize || (props.size.height > 0 && props.size.width > 0) || !props.initialSize) {
      return;
    }

    const newWindowSize = {
      width: windowSize.width * (props.initialSize.width / 100),
      height: windowSize.height * (props.initialSize.height / 100),
    };

    setWindowOverlaySize(newWindowSize);

    desktop.setWindowSize(props.instanceId, newWindowSize);
  }, [windowSize, props.initialSize, props.instanceId, desktop, props.size.height, props.size.width]);

  const [{ isDragging }, drag] = useDrag({
    item: dragItem,
    begin: () => {
      // starting a drag out of a managed area, get the actual position.
      // TODO: This assumes the DesktopContent rect is equal to the viewport.
      if (props.position.isManaged) {
        const rect = windowRef.current?.getBoundingClientRect();

        if (!rect) {
          logger.error("cannot move out of managed area without reference to window");
          return;
        }

        return { ...dragItem, rect, position: { ...dragItem.position, left: rect.left, top: rect.top } };
      }
    },
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: props.draggable,
  });

  useGesture(
    {
      onDrag: ({ event, delta: [dx, dy], movement: [mx, my], down }) => {
        event.stopPropagation();

        if (!windowSize || !props.minHeight || !props.minWidth || !props.maxHeight || !props.maxWidth) {
          return;
        }

        setIsResizing(down);

        const pointerEvent = event as MouseEvent;
        const { clientX, clientY } = pointerEvent;

        const isOutsideViewport =
          clientX < 0 || clientX > windowSize.width || clientY < 0 || clientY > windowSize.height;

        if (isOutsideViewport) {
          setWindowOverlaySize(props.size);
          setIsResizing(false);
          return;
        }

        setWindowOverlaySize((prevSize: WindowSize) => ({
          width: calculateSize(prevSize.width, dx, windowSize, false, props.minWidth, props.maxWidth),
          height: calculateSize(prevSize.height, dy, windowSize, true, props.minHeight, props.maxHeight),
        }));

        if (!down) {
          const newSize: WindowSize = {
            width: calculateSize(props.size.width, mx, windowSize, false, props.minWidth, props.maxWidth),
            height: calculateSize(props.size.height, my, windowSize, true, props.minHeight, props.maxHeight),
          };
          desktop.setWindowSize(props.instanceId, newSize);
          setWindowOverlaySize(newSize);
        }
      },
      onPointerLeave: () => {
        setIsResizing(false);
      },
    },
    { target: resizeHandleRef },
  );

  const common = useMemo(
    () => ({ maxWidth: `${props.maxWidth}vw`, maxHeight: `${props.maxHeight}vw` }),
    [props.maxWidth, props.maxHeight],
  );
  const { height, width, ...restStyle }: any = props.style;

  const windowStyle = useMemo(
    () => (props.resizable ? { ...common, ...restStyle, ...sizeToStyle(props.size) } : restStyle),
    [props.size, props.resizable, restStyle, common],
  );
  const windowOverlayStyle = useMemo(
    () => ({ ...sizeToStyle(windowOverlaySize), ...common }),
    [windowOverlaySize, common],
  );

  const position = desktop.getPosition(props.instanceId);
  const dispatch = desktop.dispatch;

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (!windowSize || !windowRef.current) {
      return;
    }

    const rect = windowRef.current.getBoundingClientRect();

    if (!rect) {
      logger.error("cannot prevent window from going out of bounds without reference to window");
      return;
    }

    const isOutOfBounds =
      rect.right > windowSize.width || rect.bottom > windowSize.height || rect.left < 0 || rect.top < 0;

    if (isOutOfBounds) {
      const position: Partial<WindowPosition> = {
        top: Math.max(0, Math.min(rect.top, windowSize.height - rect.height)),
        left: Math.max(0, Math.min(rect.left, windowSize.width - rect.width)),
      };
      dispatch({ type: "setPosition", windowId: props.instanceId, position });
    }
  }, [windowSize, dispatch, position.left, position.top, position.isManaged, props.instanceId]);

  return (
    <section
      className={classNames(
        "enable-user-interaction float float--box window",
        props.resizable && "resizable",
        isDragging && "window--is-dragging",
        props.className,
      )}
      key={props.instanceId}
      ref={windowRef}
      data-window-kind={props.kind}
      onClick={props.onClick}
      {...extractWindowsDataProps(props)}
      style={windowStyle}
    >
      {props.resizable && (
        <div
          className={classNames("window-resize-overlay", isResizing && "active")}
          style={isResizing ? windowOverlayStyle : {}}
        >
          {props.resizable && (
            <div className="window-resize-overlay__handle" ref={resizeHandleRef}>
              <ResizeHandle />
            </div>
          )}
        </div>
      )}
      <div className={classNames("window-titlebar", props.draggable ? "window-titlebar--draggable" : "")}>
        {props.draggable ? <span className={"material-icons window-titlebar__icon"}>drag_indicator</span> : <span />}
        <span className="window-titlebar__title">{props.title}</span>
        <div className="window-titlebar__actions">
          {props.actions?.map((action) => (
            <button
              className={`window-titlebar__action window-titlebar__action--${action.icon}`}
              data-testid={`window-action-${action.icon}`}
              onClick={action.callback}
              key={action.callback.name}
              type="button"
            >
              <span key={`${action.callback.name}_icon`} className={"material-icons"}>
                {action.icon}
              </span>
            </button>
          ))}
        </div>
        <div className="active-zone" ref={drag} />
      </div>
      <div className="window-body scrollable">{props.children}</div>
    </section>
  );
}

/**
 * A logical subsection of the window according to the HTML definition of a section element.
 *
 * You MUST only specify inline HTML for the title property.
 */
export function WindowSection(
  props: PropsWithChildren<{
    title?: string | JSX.Element;
    classNames?: string;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
  }>,
) {
  const bind = useHover(
    (state) => {
      if (state.hovering) {
        props.onMouseEnter?.();
      } else {
        props.onMouseLeave?.();
      }
    },
    {
      // Don't bind hover if none is listening
      enabled: !!props.onMouseEnter || !!props.onMouseLeave,
    },
  );

  return (
    <section className={`box-section ${props.classNames ?? ""}`} {...bind()}>
      {props.title && <h1 className="box-section__title">{props.title}</h1>}
      {props.children}
    </section>
  );
}

/**
 * A simple header component to use in the window.
 */
export function WindowHeader(props: { title: string } & HTMLProps<HTMLDivElement>) {
  const { title, className, ...forward } = props;
  return (
    <h1 className={classNames("box-section__title", className)} {...forward}>
      {title}
    </h1>
  );
}

import { DatetimeLabel } from "@/overlay/components/CommonParts/DatetimeLabel";
import { classNames } from "@/utility/jsx";
import { Trans, t } from "@lingui/macro";
import { GuiTimeZone } from "@mm/metx-workbench.meteomatics.com";
import { BUILD_INFO, type BuildInfo } from "build-info";
import { DateTime } from "luxon";
import { Fragment } from "react";
import "./style.scss";

/**
 * Weather to format build info as a `singe-line`, which is good for footers.
 * Or as a `table` of data, which is good for an about dialog or a legals page.
 */
export type BuildInfoLayout = "table" | "single-line";
export type BuildInfoField = keyof Pick<BuildInfo, "gitVersion" | "isProduction" | "datetimeOfBuild">;
export const defaultFields: BuildInfoField[] = ["gitVersion", "isProduction", "datetimeOfBuild"];

const fieldFormatter: Record<BuildInfoField, () => any> = {
  gitVersion() {
    return (
      <Fragment key="version">
        <dt>
          <Trans>Version</Trans>
        </dt>
        <dd>{BUILD_INFO.gitVersion}</dd>
      </Fragment>
    );
  },
  isProduction() {
    return (
      <Fragment key="variant">
        <dt>
          <Trans>Program Variant</Trans>
        </dt>
        <dd>{BUILD_INFO.isProduction ? t`Production` : t`Development`}</dd>
      </Fragment>
    );
  },
  datetimeOfBuild() {
    const releaseDate = DateTime.fromISO(BUILD_INFO.datetimeOfBuild);

    return (
      <Fragment key="date">
        <dt>
          <Trans>Release Date</Trans>
        </dt>
        <dd>
          {releaseDate.isValid ? (
            <DatetimeLabel datetime={releaseDate} timezone={GuiTimeZone.local} />
          ) : (
            BUILD_INFO.datetimeOfBuild
          )}
        </dd>
      </Fragment>
    );
  },
};

export interface BuildInfoProps {
  layout?: BuildInfoLayout;
  fields?: BuildInfoField[];
  copyright?: boolean;
  authors?: boolean;
}

export function BuildInfoComponent(props: BuildInfoProps) {
  const currentYear: number = new Date().getFullYear();
  const layout: BuildInfoLayout = props.layout ?? "single-line";
  const fields = props.fields ?? defaultFields;

  return (
    <section className={classNames("build-info", `build-info--${layout}`)}>
      <div>
        {props.copyright && (
          <div className="copyright">
            <Trans>Copyright &copy; {currentYear} Meteomatics AG</Trans>
          </div>
        )}
        {props.authors && <div className="authors">{BUILD_INFO.authors.join(", ")}</div>}
      </div>
      <dl>{fields.map((field) => fieldFormatter[field]())}</dl>
    </section>
  );
}

import { StepConfigInput } from "@/overlay/components/LayerStackWindow/LayerFormElements";
import type { BarbsLayer, GridLayer, SymbolLayer } from "@mm/metx-workbench.meteomatics.com";
import { useEffect } from "react";
import { useController } from "react-hook-form";
// TODO Remove wrapping and migrate StepConfigInput to forms v2
import type { InputInstruction } from "../../SmartFormUtils/commonTypeUtils";

type WeatherParameterFormProps = {
  layer: GridLayer | SymbolLayer | BarbsLayer;
  stepRange?: { min: number; max: number; step: number };
};

type InputProps = {
  instruction: InputInstruction<WeatherParameterFormProps>;
};

export function WrappedStepConfigInputForm({ instruction }: InputProps) {
  const {
    formMethods,
    formValueName,
    currValue: { layer, stepRange },
  } = instruction;

  const {
    field: { onChange, value },
  } = useController({
    name: formValueName,
    control: formMethods.control,
    defaultValue: layer,
  });

  useEffect(() => {
    formMethods.setValue(formValueName, layer);
  }, [layer, formValueName, formMethods]);

  return <StepConfigInput layer={value} stepRange={stepRange} change={onChange} />;
}

import type { LayerUnion } from "@/layers";

/**
 * Event types to trigger self transition within a single state
 */
enum SelfTransitionEventType {
  addOrUpdateTempLayer = "addOrUpdateTempLayer",
  removeTempLayerIfExists = "removeTempLayerIfExists",
}

export const TempLayerStateEventType = { ...SelfTransitionEventType };

export type TempLayerStateEventUnion =
  | {
      type: SelfTransitionEventType.addOrUpdateTempLayer;
      mapId: number;
      layer: LayerUnion;
    }
  | {
      type: SelfTransitionEventType.removeTempLayerIfExists;
      layerId: LayerUnion["id"];
    };

import { BoundingBox, ModelSchema } from "@mm/api-layers.meteomatics.com";
import proj4 from "proj4";
import { BoundsIntersection } from "./BoundsIntersection";
import { CoordinateSystem, DEFAULT_EPSILON } from "./CoordinateSystem";
import { GridDimension } from "./GridDimension";
import { GridSamplingStrategy, GridSamplingStrategyKind } from "./GridSamplingStrategy";

export interface AreaFromMinMax {
  latMin: number;
  latMax: number;
  lngMin: number;
  lngMax: number;
}

export interface AreaFromCardinalDirections {
  south: number;
  north: number;
  west: number;
  east: number;
}

export type AreaDescription = AreaFromMinMax | AreaFromCardinalDirections;

function isAreaFromMinMax(v: AreaDescription): v is AreaFromMinMax {
  return v.hasOwnProperty("latMin");
}

/**
 * Variant of `Area` that is safe to transfer between workers and over the network.
 */
export interface Area_Transferable<Crs extends CoordinateSystem> extends AreaFromMinMax {
  crs: Crs;
}

export class Area<Crs extends CoordinateSystem> {
  public latMin: number;
  public latMax: number;
  public lngMin: number;
  public lngMax: number;

  constructor(
    public readonly crs: Crs,
    area: AreaDescription,
  ) {
    if (isAreaFromMinMax(area)) {
      this.latMin = area.latMin;
      this.latMax = area.latMax;
      this.lngMin = area.lngMin;
      this.lngMax = area.lngMax;
    } else {
      this.latMin = area.south;
      this.latMax = area.north;
      this.lngMin = area.west;
      this.lngMax = area.east;
    }
  }

  get south() {
    return this.latMin;
  }
  set south(value: number) {
    this.latMin = value;
  }
  get north() {
    return this.latMax;
  }
  set north(value: number) {
    this.latMax = value;
  }
  get west() {
    return this.lngMin;
  }
  set west(value: number) {
    this.lngMin = value;
  }
  get east() {
    return this.lngMax;
  }
  set east(value: number) {
    this.lngMax = value;
  }

  width() {
    return this.lngMax - this.lngMin;
  }
  height() {
    return this.latMax - this.latMin;
  }
  lngExtent() {
    return this.width();
  }
  latExtent() {
    return this.height();
  }

  private inCrs(v: any): v is Crs {
    return this.crs === v;
  }

  /**
   * Project this area object into another CRS
   *
   * Note that the domains of both CRS systems might not match. Projections outside
   * of the domain of the target CRS result in undefined behaviour.
   * e.g) Projection of a world bounding box in WGS84 to EPSG:3857 can result in undefined coordinates.
   *
   * @param toCrs target CRS
   * @returns a copy in another CRS
   */
  project(toCrs: Crs): Area<Crs>;
  project<TargetCrs extends CoordinateSystem>(toCrs: TargetCrs): Area<TargetCrs>;
  project(toCrs: CoordinateSystem): Area<CoordinateSystem> {
    if (this.inCrs(toCrs)) {
      return this.copy();
    } else {
      const [lngMin, latMin] = proj4(this.crs, toCrs, [this.lngMin, this.latMin]);
      const [lngMax, latMax] = proj4(this.crs, toCrs, [this.lngMax, this.latMax]);
      return new Area(toCrs, { latMin, latMax, lngMin, lngMax });
    }
  }

  static ofWeatherModel(weatherModel: ModelSchema): Area<CoordinateSystem.WGS84> {
    return this.ofWgs84BoundingBox(weatherModel.bounding_box);
  }

  static ofWgs84BoundingBox(bounding_box: BoundingBox): Area<CoordinateSystem.WGS84> {
    return new Area(CoordinateSystem.WGS84, bounding_box);
  }

  static fromString(boundingBox: string): Area<CoordinateSystem.WGS84> | null {
    const coordinate = (name: keyof Area<any>) => `(?<${name}>-?[0-9]+\\.[0-9]{16})`;
    const area = new RegExp(
      `^${coordinate("latMax")},${coordinate("lngMin")}_${coordinate("latMin")},${coordinate("lngMax")}$`,
    );
    const matches = boundingBox.match(area)?.groups;
    return matches != null
      ? new Area(CoordinateSystem.WGS84, {
          latMax: +matches.latMax,
          lngMin: +matches.lngMin,
          latMin: +matches.latMin,
          lngMax: +matches.lngMax,
        })
      : null;
  }

  /**
   * Intersect two areas to yield a third area.
   *
   * Note, that the order `area1.intersect(area2.project(area1.crs))` matters if both areas are represented in different crs.
   * This is because `area2` has to be within the projectable region of `area1.crs`. The following example illustrates the issue:
   *
   * ```ts
   * const world_wgs84 = new Area(CoordinateSystem.WGS84, { latMin: -90, latMax: 90, lngMin: -180, lngMax: 180 });
   * const epsg3857_cutoff = 85.06;
   * const world_epsg3857 = new Area(CoordinateSystem.WGS84, { latMin: -epsg3857_cutoff, latMax: epsg3857_cutoff, lngMin: -180, lngMax: 180 });
   *
   * // errors since latitude of 90 in wgs84 cannot be projected onto epsg3857
   * world_epsg3857.intersect(world_wgs84.project(CoordinateSystem::EPSG3857));
   * // works since `epsg3857_cutoff` can be projected onto wgs84.
   * world_wgs84.intersect(world_epsg3857.project(CoordinateSystem::WGS84);
   * ```
   *
   * @return intersection or `null` if both areas do not overlap (area would be ill-defined since it is either negative or any zero area
   * point within the valid region of the projection)
   */
  intersect(other: Area<Crs>): Area<Crs> | null {
    const area = new Area(this.crs, {
      latMax: Math.min(this.latMax, other.latMax),
      lngMax: Math.min(this.lngMax, other.lngMax),
      latMin: Math.max(this.latMin, other.latMin),
      lngMin: Math.max(this.lngMin, other.lngMin),
    });

    if (area.latMax < area.latMin || area.lngMax < area.lngMin) {
      return null;
    }

    return area;
  }

  /**
   * Check if two objects contain the same area in the same crs.
   * Returns `false` if `other` represents the same area in a different crs.
   */
  equals(other: Area<any>, eps: number = DEFAULT_EPSILON[this.crs]): boolean {
    return (
      this === other ||
      (this.crs === other.crs &&
        Math.abs(this.latMax - other.latMax) <= eps &&
        Math.abs(this.lngMax - other.lngMax) <= eps &&
        Math.abs(this.latMin - other.latMin) <= eps &&
        Math.abs(this.lngMin - other.lngMin) <= eps)
    );
  }

  /**
   * Check if this area contains the given area.
   *
   * @param smallerArea
   * @param eps disregards some smaller imprecisions. This can be helpful since reprojection roundtrips can
   * introduce small deltas.
   *
   * @returns
   */
  contains(smallerArea: Area<Crs>, eps: number = DEFAULT_EPSILON[this.crs]): BoundsIntersection<Area<Crs>> {
    const intersection = this.intersect(smallerArea);

    if (intersection === null) {
      return { kind: "OutOfBounds" };
    }

    if (smallerArea.equals(intersection, eps)) {
      return { kind: "WithinBounds" };
    }

    return {
      kind: "PartiallyOutOfBounds",
      intersection,
    };
  }

  /**
   * Adapt the area to a grid sampling strategy.
   *
   * Effectively, this slightly shrinks the area to assign different meanings to grid points. See `GridSamplingStrategy` for details.
   * This operation is not invertible: `myArea.applySamplingStrategy(GridSamplingStrategy.Point) !== myArea.applySamplingStrategy(GridSamplingStrategy.Area).applySamplingStrategy(GridSamplingStrategy.Point)`
   */
  applySamplingStrategy(strategy: GridSamplingStrategy, grid: GridDimension): Area<Crs> {
    switch (strategy.kind) {
      case GridSamplingStrategyKind.Area:
        return this.applyAreaSamplingStrategy(grid, [0.5, 0.5]);
      case GridSamplingStrategyKind.AreaSample:
        return this.applyAreaSamplingStrategy(grid, strategy.sample);
      case GridSamplingStrategyKind.Point:
        return this.copy();
    }
  }

  /**
   * Compute the sample point of each cell and shrink the area to the new convex hull
   *
   * @param grid number of cells along each axis of the area
   * @param uv position of the sample within the cell
   *
   * @returns
   */
  private applyAreaSamplingStrategy(grid: GridDimension, uv: [number, number]) {
    const cellHeight = this.height() / grid.height;
    const cellWidth = this.width() / grid.width;

    return new Area(this.crs, {
      latMin: this.latMin + cellHeight * uv[1],
      latMax: this.latMax - cellHeight * (1 - uv[1]),
      lngMin: this.lngMin + cellWidth * uv[0],
      lngMax: this.lngMax - cellWidth * (1 - uv[0]),
    });
  }

  copy(): Area<Crs> {
    return new Area(this.crs, this);
  }

  toTransferable(): Area_Transferable<Crs> {
    return {
      crs: this.crs,
      latMin: this.latMin,
      latMax: this.latMax,
      lngMin: this.lngMin,
      lngMax: this.lngMax,
    };
  }

  roundToPrecision(precision: number): Area<Crs> {
    return new Area(this.crs, {
      latMin: roundToPrecision(this.latMin, precision),
      latMax: roundToPrecision(this.latMax, precision),
      lngMin: roundToPrecision(this.lngMin, precision),
      lngMax: roundToPrecision(this.lngMax, precision),
    });
  }

  static fromTransferable<Crs extends CoordinateSystem>(area: Area_Transferable<Crs>): Area<Crs> {
    return new Area(area.crs, area);
  }
}

function roundToPrecision(val: number, precision: number): number {
  // TODO: this roundtrip through string is not necessarily performant
  // Math.round(num * Math.pow(10, precision)) / Math.pow(10, precision)
  return +val.toFixed(precision);
}

import type { ProgramParameters } from "../../types";

// Minimum allowed depth value
const DEPTH_EPS = 0.1;

export function extractParameterLocations(gl: WebGLRenderingContext, program: WebGLProgram) {
  const parameters: ProgramParameters = {};

  const numAttributes = gl.getProgramParameter(program, gl.ACTIVE_ATTRIBUTES);
  for (let i = 0; i < numAttributes; i++) {
    const attribute = gl.getActiveAttrib(program, i);

    if (attribute) {
      parameters[attribute.name] = gl.getAttribLocation(program, attribute.name);
    }
  }
  const numUniforms = gl.getProgramParameter(program, gl.ACTIVE_UNIFORMS);
  for (let i = 0; i < numUniforms; i++) {
    const uniform = gl.getActiveUniform(program, i);
    if (uniform) {
      parameters[uniform.name] = gl.getUniformLocation(program, uniform.name);
    }
  }

  return parameters;
}

export function calculateTileDepth(zoomRange: { min: number; max: number }, sublayerZoom: number) {
  // `sublayerZoom / maxZoom` would compress the zoom range into [0,1]. But we want (0,1], since 0 is reserved
  // for `no tile drawn yet`. So we use 1-based indexing and end up with `((sublayerZoom + 1) / (maxZoom + 1))`.
  // `* 2 - 1` then scales this [0,1] range to the usual clipspace range [-1,1].
  //const sublayerZoomClipSpace = clamp(((sublayerZoom + 1) / (maxZoom + 1)) * 2 - 1, -1, 1);
  const depth_ = ((sublayerZoom - zoomRange.min) / (zoomRange.max - zoomRange.min)) * (1 - 2 * DEPTH_EPS) + DEPTH_EPS;
  return Number.isNaN(depth_) ? DEPTH_EPS : depth_;
}

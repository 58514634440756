/**
 * Type-safe translation for parameter fields of the layer api.
 */

import { t } from "@lingui/macro";
import type { AllFieldNames } from "weather-parameter-utils";

export const weatherParameterFieldDisplayName: {
  [key in AllFieldNames]: () => string;
} = {
  unit: () => t`unit`,
  measure: () => t`measure`,
  level: () => t`level`,
  upper_level: () => t`upper_level`,
  lower_level: () => t`lower_level`,
  interval: () => t`interval`,
  format: () => t`format`,
  height: () => t`height`,
  lower_height: () => t`lower_height`,
  upper_height: () => t`upper_height`,
  range: () => t`range`,
  type: () => t`type`,
  channel: () => t`channel`,
  leisure: () => t`leisure`,
};

export const isValidWeatherParameterFieldDisplayName = (key: string): key is AllFieldNames => {
  return key in weatherParameterFieldDisplayName;
};

import { ColorMapTexture } from "@/cache/ColorMapCache/ColorMapTexture";
import { formatByteSize } from "@/utility/bytesize";
import {
  type ColorMap,
  type ColorMapRequest,
  type ColorMapResponse,
  type ValueRange,
  defaultColorMapFromString,
  isSegmented,
} from "@mm/api.meteomatics.com";
import Logger from "logging";
import {
  type AsyncResult,
  createPendingAsyncResult,
  createPermanentlyFailedAsyncResult,
  createResolvedAsyncResult,
} from "../AsyncResult";
import { type CacheDatum, CacheDatumState, type LoadedCacheDatum } from "../CacheDatumState";
import { apiThreadPool } from "../SpatioTemporalTileCache/ApiQueryThreadPool";
import { type Stats, defaultCacheFormatter, jsxTable } from "../Stats";
import { lru_evict } from "../evictionStrategy";

const logger = Logger.fromFilename(__filename);

export interface ColorMapping {
  texture: ColorMapTexture;
  recommendedValueRange: ValueRange;
  colormap: ColorMap;
}

export interface ColorMapCacheStats {
  parameterCount: number;
  colormapCount: number;
  pending: number;
  /**
   * Estimate of the consumed CPU memory in bytes.
   */
  estimatedMemoryConsumption: number;
}

type DeduplicatedColorMapTexture = { texture: ColorMapTexture; lastUsed: number; loadedUsageSites: string[] };

/**
 * Caches API lookups for colormaps and recommended value ranges for mapping colormaps to raw data.
 */
export class ColorMapCache implements Stats<ColorMapCacheStats> {
  // our cache has to track all combinations of weather parameters and color maps. We thus have this
  // small `marker` cache called `cache`, that just tells us that this combination is available. And a
  // larger, deduplicated `payload` cache called `colorTextures` that holds all GPU state.
  protected cache: { [key: string]: CacheDatum<ColorMapping> } = {};
  protected colorTextures: { [key in ColorMap]?: DeduplicatedColorMapTexture } = {};

  /**
   * Create a new colormap cache
   *
   * @param label_ a human readable name for this cache, used for debugging
   * @param maxSizeInBytes maximal size of the cache in bytes
   * @param delayEvictionUntilSizeInBytes let the cache grow up to this amount, then trigger a cache eviction
   * and unload entries until `maxSizeInBytes` is reached. this reduces the number of cache state aggregations.
   * Otherwise a cache under load will trigger its eviction routines on each new entry. Must be greater than or equal
   * to `maxSizeInBytes`.
   */
  constructor(
    private readonly label_: string,
    public readonly maxSizeInBytes: number,
    public readonly delayEvictionUntilSizeInBytes: number = maxSizeInBytes,
  ) {}

  stats(): ColorMapCacheStats {
    let parameterCount = 0;
    let colormapCount = 0;
    let pending = 0;
    let estimatedMemoryConsumption = 0;
    const cache = this.cache;

    for (const line in cache) {
      if (Object.hasOwn(cache, line)) {
        parameterCount++;

        if (cache[line].state === CacheDatumState.Pending) {
          pending++;
        }
      }
    }

    for (const v of Object.values(this.colorTextures)) {
      if (v === undefined) {
        continue;
      }
      colormapCount++;
      estimatedMemoryConsumption += v.texture.estimatedByteSizeCpu;
    }

    return { parameterCount, colormapCount, pending, estimatedMemoryConsumption };
  }

  statsJsx(): JSX.Element {
    return jsxTable(this.stats(), defaultCacheFormatter);
  }

  label(): string {
    return this.label_;
  }

  descJsx(): JSX.Element {
    return (
      <>
        Special-purpose cache for GPU based color maps. Cache size limit is {formatByteSize(this.maxSizeInBytes)},
        Replacement Policy is LRU.
      </>
    );
  }

  /**
   * Estimate the memory consumption of a colormap and all its parameter pairings
   */
  private estimateColormapSize(line: DeduplicatedColorMapTexture | undefined): number {
    return line?.texture.estimatedByteSizeCpu ?? 0;
  }

  private reduceMemoryUsageBy(bytes: number) {
    logger.debug("memory usage before LRU eviction is", formatByteSize(this.stats().estimatedMemoryConsumption));

    logger.logTiming("colormap cache eviction", () => {
      // TODO: typing here is shit, should be: <DeduplicatedColorMapTexture, ColorMap>
      lru_evict<DeduplicatedColorMapTexture | undefined, number>(
        bytes,
        this.colorTextures,
        this.lastUse.bind(this),
        this.estimateColormapSize.bind(this),
        this.unload.bind(this),
        logger,
        formatByteSize,
      );

      logger.debug("memory usage after LRU eviction is", formatByteSize(this.stats().estimatedMemoryConsumption));
    });
  }

  /**
   * Remove a colormap and all its parameter pairings from the cache
   */
  protected unload(v: DeduplicatedColorMapTexture | undefined, tag: ColorMap) {
    if (v !== undefined) {
      delete this.colorTextures[tag];

      // Beware when changing code here: pending pairs using the colormap that is unloaded should
      // be considered. What we do here is erase everything -- even though an entry is pending --
      // and reinserting it as soon as the pending entry resolves.
      for (const usageSite of v.loadedUsageSites) {
        delete this.cache[usageSite];
      }

      // unload the texture itself
      v.texture.unload();
    }
  }

  protected lastUse(v: DeduplicatedColorMapTexture | undefined, tag: ColorMap): number | null {
    return v?.lastUsed ?? null;
  }

  /**
   * Lookup a colormap and start fetching it if it is unavailable.
   *
   * @param desc description of the tile
   * @param api the api instance used to fetch data if necessary
   */
  retrieveColorMap(request: ColorMapRequest): AsyncResult<ColorMapping> {
    const colormap = request.style ?? defaultColorMapFromString(request.parameter);
    const { parameter, ensSelect, calibrated, vertical_interpolation } = request;
    const cacheId = [colormap, parameter].join("@");

    if (Object.hasOwn(this.cache, cacheId)) {
      const v = this.cache[cacheId];
      switch (v.state) {
        case CacheDatumState.Pending:
          return createPendingAsyncResult(v.promise);
        case CacheDatumState.PermanentlyFailed:
          // TODO: store and forward failure reason?
          return createPermanentlyFailedAsyncResult(v.payload);
        case CacheDatumState.Loaded:
          this.markUsed(v, colormap);
          return createResolvedAsyncResult(v.payload);
        default: {
          const _exhaustive: never = v;
          return _exhaustive;
        }
      }
    }
    const promise = apiThreadPool
      .getColorMap({
        style: colormap,
        parameter,
        ensSelect,
        calibrated,
        vertical_interpolation,
      })
      .then(({ usualValueRange, samples }: ColorMapResponse) => {
        const { requestDatetime } = this.cache[cacheId];
        const lastUsed = Number.NEGATIVE_INFINITY;

        // do not reupload to GPU if we already have one mapped (!)
        // this happens if two layers share the same colormap but have different parameters.
        // Hence, the request is only initiated to get the value range.
        const colormapAlreadyAvailable = Object.hasOwn(this.colorTextures, colormap);

        //Ensures that colormap has value
        if (!colormapAlreadyAvailable) {
          const texture = new ColorMapTexture(samples, isSegmented(colormap));
          this.colorTextures[colormap] = { lastUsed, texture, loadedUsageSites: [] };
        }

        this.colorTextures[colormap]?.loadedUsageSites.push(cacheId);

        const cacheLine: LoadedCacheDatum<ColorMapping> = {
          requestDatetime,
          lastUsed,
          state: CacheDatumState.Loaded,
          payload: {
            // biome-ignore lint/style/noNonNullAssertion: Already checked
            texture: this.colorTextures[colormap]!.texture,
            recommendedValueRange: usualValueRange,
            colormap,
          },
        };

        this.cache[cacheId] = cacheLine;

        this.markUsed(cacheLine, colormap);

        // TODO: calling stats here is unnecessarily expensive
        if (!colormapAlreadyAvailable) {
          // TODO: delete gpu state if limit is reached
          const cacheSize = this.stats().estimatedMemoryConsumption;
          const isEvictionThresholdReached = cacheSize - this.delayEvictionUntilSizeInBytes > 0;
          const excessSize = cacheSize - this.maxSizeInBytes;
          if (isEvictionThresholdReached) {
            this.reduceMemoryUsageBy(excessSize);
          }
        }

        return {
          // biome-ignore lint/style/noNonNullAssertion: Already checked
          texture: this.colorTextures[colormap]!.texture,
          recommendedValueRange: usualValueRange,
          colormap,
        };
      })
      .catch((e) => {
        this.cache[cacheId] = {
          requestDatetime: performance.now(),
          state: CacheDatumState.PermanentlyFailed,
          payload: null,
        };

        return Promise.reject(e);
      });

    this.cache[cacheId] = {
      requestDatetime: performance.now(),
      state: CacheDatumState.Pending,
      promise,
      payload: null,
    };

    return createPendingAsyncResult(promise);
  }

  /**
   * Mark the cache line as used to make it less likely to be discarded by the cache policy.
   */
  protected markUsed(cache: LoadedCacheDatum<ColorMapping>, colormap: ColorMap) {
    const now = performance.now();
    cache.lastUsed = now; // mark combination of `(colormap, parameter)` as used
    // biome-ignore lint/style/noNonNullAssertion: colortextures might be null
    this.colorTextures[colormap]!.lastUsed = now; // mark the dedouplicated and hence shared `colormap` as used
  }
}

import type { PoiDataGrid } from "@/threads/DataProcessingThread/types";
import type { Coordinate } from "@mm/api.meteomatics.com";
import type { LocationTableGroup } from "@mm/metx-workbench.meteomatics.com";
export type LocationTablePoint = Coordinate & {
  label?: string;
  groupName?: string;
};
export const getPointsFromTableGroups = (tableGroups?: LocationTableGroup[]): Coordinate[] => {
  if (!tableGroups) {
    return [];
  }
  const points: Coordinate[] = [];
  for (const tableGroup of tableGroups) {
    for (const location of tableGroup.locations) {
      points.push({ lat: location.lat, lon: location.lon });
    }
  }
  return points;
};

export const getPointsWithInfoFromTableGroups = (tableGroups?: LocationTableGroup[]): LocationTablePoint[] => {
  if (!tableGroups) {
    return [];
  }
  const points: LocationTablePoint[] = [];
  for (const tableGroup of tableGroups) {
    for (const location of tableGroup.locations) {
      points.push({ lat: location.lat, lon: location.lon, label: location.label });
      // TODO groupName is not yet used - will need to use it as parentId in the NestedRow
      // points.push({ lat: location.lat, lon: location.lon, label: location.label, groupName: tableGroup.name });
    }
  }
  return points;
};

export const getGroupsFromDataGrid = (dataGrid?: PoiDataGrid): LocationTableGroup[] => {
  if (!dataGrid) {
    return [];
  }

  const groupMap: { [key: string]: LocationTableGroup } = {};

  for (const poiData of dataGrid) {
    const groupName = poiData.groupName || null;
    if (!groupMap[groupName]) {
      groupMap[groupName] = {
        name: groupName,
        locations: [],
      };
    }
    groupMap[groupName].locations.push({
      lat: poiData.lat,
      lon: poiData.lon,
      label: poiData.label,
    });
  }

  return Object.values(groupMap);
};

import type { IconData } from "@/reducer/client-models";
import type { GeoJSONFeatureCollection, JSONResponseDataPoints } from "@mm/api.meteomatics.com";
import { ThreadPool } from "threading-lib";
import type { DataProcessingPool_MsgToPool, DataProcessingPool_MsgToWorkerThread } from "./DataProcessingThreadPoolMsg";
import type {
  WebWorkerProcessFnNames,
  WebWorkerProcessInput,
  WebWorkerProcessOutput,
} from "./transformer/dataProcessingFn";
import type { ParsedPoiListResponse } from "./types";

export class DataProcessingThreadPool extends ThreadPool<
  DataProcessingPool_MsgToWorkerThread,
  DataProcessingPool_MsgToPool
> {
  constructor() {
    // TODO: we currently always run on a single thread since our connection manager
    // has no idea how to manage the hard parallel limit with multiple threads.
    //
    // A simple, suboptimal solution would just assign a subset of connections
    // to each thread.
    super(1);
  }

  newThread(_threadId: number): Worker {
    return new Worker(new URL("./DataProcessingThreadPool.worker.ts", import.meta.url), { type: "module" });
  }

  multiplex(
    query: { resolve: (value: any) => void; reject: (reason?: any) => void },
    msg: DataProcessingPool_MsgToPool,
  ): void {
    switch (msg.kind) {
      case "MsgAnswer_CreatePOIGeoJson": {
        query.resolve(msg.payload.geoJson);
        break;
      }
      case "MsgAnswer_LoadCSV": {
        query.resolve(msg.payload);
        break;
      }
      case "MsgAnswer_GenericTransform": {
        query.resolve(msg.payload);
        break;
      }
    }
  }

  parseCsvFiles(files: FileList) {
    return this.send<ParsedPoiListResponse>({
      kind: "Msg_LoadCSV",
      payload: { files },
    });
  }

  createPOIGeoJson(points: IconData, data?: JSONResponseDataPoints[]) {
    return this.send<GeoJSONFeatureCollection>({
      kind: "Msg_CreatePOIGeoJson",
      payload: { points, data, devicePixelRatio: window.devicePixelRatio },
    });
  }

  /**
   * A generic function to execute any function in the web worker.
   * Note you have to register a function in the config object before using.
   *
   * You can
   * 1. Pass the name of the function.
   * 2. Pass the arguments to the function.
   * 3. Receive the result.
   */
  run<T extends WebWorkerProcessFnNames>(transformerName: T, ...parameters: WebWorkerProcessInput<T>) {
    return this.send<WebWorkerProcessOutput<T>>({
      kind: "Msg_GenericTransform",
      payload: { fnName: transformerName, input: parameters },
    });
  }
}

export const dataProcessingThreadPool = new DataProcessingThreadPool();

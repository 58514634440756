export interface Timeframe {
  parameter: string;
  min_date: string; //evtl. datetime?
  max_date: string;
}

export interface AvailableTimeframeResponse_Transferable {
  readonly timeframe: Array<Timeframe>;
}

export class AvailableTimeframeResponse {
  /**
   * Wrap the raw response of a timeframe.
   *
   * @param timeframe timeframe with parameter, min_date, max_date
   */
  constructor(readonly timeframe: Array<Timeframe>) {}

  /**
   * Parse a CSV API response
   *
   * - Valid format has a single line header
   * - at least two timeframes lines of the transfer function
   * - each timeframe has format `parameter;min_date;max_date`
   *
   * @param txt
   */
  static parseFromCsv(txt: string): AvailableTimeframeResponse {
    // TODO: rewrite this as a clean parser

    const format = txt
      .split("\n")
      .filter((l) => l.trim() !== "")
      .map((line, lineNumber) => ({ line, lineNumber }));

    const regex = /^(?<parameter>[^\n]+);(?<min_date>[^\n]+);(?<max_date>[^\n]+)/;

    const readTimeframe = (v: undefined | { line: string; lineNumber: number }): Timeframe => {
      if (!v) {
        throw Error(`unexpected end of CSV`);
      }
      const { line, lineNumber } = v;

      const groups = line?.match(regex)?.groups!;
      if (!groups) {
        throw Error(`'${line}' not a valid timeframe at line ${lineNumber}`);
      }
      const parameter = groups.parameter;
      const min_date = groups.min_date;
      const max_date = groups.max_date;
      return { parameter, min_date, max_date };
    };

    const _header = format.shift();
    const firstLine = readTimeframe(format.shift());
    const otherLines = format.map((v, idx) => readTimeframe(v));

    const timeframe = [firstLine, ...otherLines];

    return new AvailableTimeframeResponse(timeframe);
  }

  toTransferable(): AvailableTimeframeResponse_Transferable {
    return {
      timeframe: this.timeframe,
    };
  }

  static fromTransferable(v: AvailableTimeframeResponse_Transferable): AvailableTimeframeResponse {
    return new AvailableTimeframeResponse(v.timeframe);
  }
}

import { apiLanguage } from "@/overlay/components/CommonParts/ApiSearchStream";
import { DebouncedInput } from "@/overlay/components/CommonParts/Input";
import { SmartSelector } from "@/overlay/components/CommonParts/SmartSelector";
import {
  type SearchLocationResult,
  useSearchLocation,
} from "@/overlay/components/LayerStackWindow/LocationSearch/useSearchLocation";
import { useObserverState } from "@/utility/customHooks";
import { validateCoordStr } from "@/utility/validateCoordinates";
import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import type { Language } from "@mm/geocoder.meteomatics.com";
import React, { type FocusEvent, useCallback, useState } from "react";
import style from "./style.module.scss";

type LocationSearchDropdownProps = {
  onChangeLocation: (newLocation: string) => void;
  defaultValue: string;
  onValidCoordinateBlur?: (validCoordinate: string) => void;
};

const LocationSearchDropdown: React.FunctionComponent<LocationSearchDropdownProps> = ({
  onChangeLocation,
  defaultValue,
  onValidCoordinateBlur,
}: LocationSearchDropdownProps) => {
  const [searchTerm, setSearchTerm] = useState("");
  const language: Language = apiLanguage(useLingui().i18n.locale);
  const { data: locations } = useSearchLocation({ searchTerm: searchTerm, language: language });
  const [showOptions, setShowOptions] = useState(true);
  const [inputValue, setInputValue] = useObserverState<string>(defaultValue);

  const handleLocationSelect = (newLocation: SearchLocationResult) => {
    setShowOptions(false);
    onChangeLocation(`${newLocation.geometry.lat},${newLocation.geometry.lng},${newLocation.name}`);
  };

  const handleInputChange = useCallback((newInput: string) => {
    if (newInput.length >= 3) {
      if (!validateCoordStr(newInput)) {
        setSearchTerm(newInput);
        setShowOptions(true);
      } else {
        setShowOptions(false);
      }
    }
  }, []);

  const handleValidCoordString = (coordString: string) => {
    if (validateCoordStr(coordString)) {
      onValidCoordinateBlur?.(coordString);
    }
  };
  const handleInputOnBlur = (e: FocusEvent<HTMLInputElement>) => {
    const targetValue = e.target.value;
    handleValidCoordString(targetValue);
  };

  return (
    <div style={{ position: "relative", width: "70%" }}>
      <DebouncedInput
        debouncedCallback={handleInputChange}
        onInputChange={setInputValue}
        inputText={inputValue}
        onBlur={handleInputOnBlur}
        placeholderInput={t`Enter 'lat,lon' or address`}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleValidCoordString(inputValue);
          }
        }}
      />
      {locations && (
        <SmartSelector
          items={locations.map((location) => {
            return {
              value: location,
              label: location.name,
              key: location.name + location.geometry.lng + location.geometry.lat,
            };
          })}
          onSelect={handleLocationSelect}
          maxSize={4}
          minSize={2}
          hidden={!showOptions || locations.length === 0}
          className={style.dropdown}
        />
      )}
    </div>
  );
};

export default React.memo(LocationSearchDropdown);

import type { TileGetter } from "@/cache/PVSTileService/TileGetters/TileGetter";
import { EmptyPNGTile, FailurePNGTile, type PNGTile } from "@/cache/PVSTileService/TileGetters/WMSTileGetter";
import { apiThreadPool } from "@/cache/SpatioTemporalTileCache/ApiQueryThreadPool";
import type { RetryState } from "@/cache/SpatioTemporalTileCache/RetryState";
import { getTileSize } from "@/cache/SpatioTemporalTileCache/TileArea";
import type { TileDataDescription } from "@/cache/SpatioTemporalTileCache/TileDataDescription";
import { type CoordinateSystem, GridSamplingStrategyKind, type PixelGridRequest } from "@mm/api.meteomatics.com";
import { hashPixelGridRequest } from "../tile-getter-utils/hashApiRequest";

export type CombinedPNGTile = PNGTile | EmptyPNGTile | FailurePNGTile;

/**
 * Option values for WMS request without the properties computed by tiling service or the tile getter.
 * Defines the object that should be passed to the tile service's getTileSet method.
 */
export type WMSRequestParams<C extends CoordinateSystem> = Omit<
  PixelGridRequest<C>,
  "area" | "width" | "height" | "datetime" | "sampling"
>;

export function createWmsTileGetter<C extends CoordinateSystem>() {
  const wmsTileGetter: TileGetter<C, CombinedPNGTile, WMSRequestParams<C>, PixelGridRequest<C>> = {
    // TODO Fix this in next iteration. Problem is that returned area always are in EPSG3857
    // @ts-ignore
    createTileApiRequest: (tileDesc: TileDataDescription<WMSRequestParams<C>>, tilePixelDimension: number) => {
      const widthAndHeight = getTileSize(tileDesc.geometry.zoom, tilePixelDimension);
      const requestArea = tileDesc.geometry.toEpsg3857();
      return {
        ...tileDesc.requestParams,
        sampling: { kind: GridSamplingStrategyKind.Point },
        area: requestArea,
        datetime: tileDesc.datetime,
        height: widthAndHeight,
        width: widthAndHeight,
      };
    },

    requestTile(tileApiRequest: PixelGridRequest<C>, requestRetryState: RetryState): Promise<PNGTile> {
      return apiThreadPool.getWMSTile(tileApiRequest, requestRetryState);
    },

    getTileCacheId: (tileApiRequest: PixelGridRequest<C>) => {
      return hashPixelGridRequest(tileApiRequest);
    },

    createEmptyTile: (): PNGTile | EmptyPNGTile | FailurePNGTile => {
      return new EmptyPNGTile();
    },

    createFailureTile(): PNGTile | EmptyPNGTile | FailurePNGTile {
      return new FailurePNGTile();
    },
  };

  return wmsTileGetter;
}

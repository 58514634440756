export const parentCellRenderer = (expandedRowIds: string[], firstColField?: string) => (params: any) => {
  const hasChildren = params.data.children?.length > 0;
  const isExpanded = expandedRowIds.includes(params.data.id);
  const isFirstColumn = params.colDef.field === firstColField;

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {isFirstColumn && hasChildren && (
        <span className="material-icons">{isExpanded ? "expand_less" : "expand_more"}</span>
      )}
      {isFirstColumn && !hasChildren && <span style={{ marginLeft: "25px" }} />}
      {params.value}
    </div>
  );
};

import { chunkLineStringFeatureArrayByMaxSize } from "@/cache/CustomGeoJSONCache/chunkLineStringFeatureArrayByMaxSize";
import { MAX_POINT_COUNT_PER_POLYGON } from "@/cache/CustomGeoJSONCache/limitConfig";
import type { SlicedCustomGeoJSON } from "@/cache/CustomGeoJSONCache/types";
import { aggregateOfList, categorizeFeatures } from "@/cache/CustomGeoJSONCache/utils";
import { apiThreadPool } from "@/cache/SpatioTemporalTileCache/ApiQueryThreadPool";
import type { RetryState } from "@/cache/SpatioTemporalTileCache/RetryState";
import { customGeoJsonLayerConfig } from "@/constants/layerConfigAttributes";
import type { CustomGeoJsonRequestOption } from "@/layers/geojson/custom";
import {
  addValueToFeature,
  chunkLineString,
  simplifyPolygon,
} from "@/threads/DataProcessingThread/utils/custom-geojson";
import {
  Aggregate,
  CoordinateSystem,
  type PointRequest,
  type PolygonRequest,
  Positions,
  SinglePointCoordinate,
} from "@mm/api.meteomatics.com";
import type { Feature, GeoJsonProperties, Geometry, LineString, Polygon } from "geojson";
import { type DateTime, Duration } from "luxon";

// Used to limit the body in the POST request
export const MAX_BATCH_SIZE_IN_KB = 256;

function hydratePolygonFeatures(
  features: Array<Feature<Polygon, GeoJsonProperties>>,
  parameter: string,
  model: string,
  datetime: DateTime,
  retry: RetryState,
): FlatArray<Promise<Feature<Geometry, GeoJsonProperties>[]>[], 1>[] {
  const promises: Promise<Feature<Geometry, GeoJsonProperties>[]>[] = features.map((feature) => {
    const simplifiedCoordinates = simplifyPolygon(feature.geometry.coordinates[0], MAX_POINT_COUNT_PER_POLYGON);
    const request: PolygonRequest<CoordinateSystem.WGS84> = {
      polygons: [new Positions(simplifiedCoordinates, CoordinateSystem.WGS84)],
      // Right now hardcode to Max, but later we should have dropdown, that allows user to select aggregation
      aggregate: Aggregate.max,
      startDatetime: datetime,
      duration: Duration.fromISO("PT1H"),
      temporalResolution: Duration.fromISO("PT1H"),
      width: 0,
      height: 0,
      parameters: [parameter],
      model,
      boundingBoxLimit: null,
    };
    return apiThreadPool.getPolygonJson(request, retry).then(({ data }) => {
      // This looks very ugly, but we don't have nice way to traverse API data
      const value = data[0]?.coordinates[0]?.dates[0]?.value;

      if (value != null) {
        return [addValueToFeature(feature, value)];
      }

      return [feature];
    });
  });
  // Flatten the array of promises
  return promises.flat();
}

function hydrateLineStringFeatures(
  lineStringFeatures: Array<Feature<LineString, GeoJsonProperties>>,
  parameter: string,
  model: string,
  datetime: DateTime,
  retry: RetryState,
  customGeoJsonRequestOption: CustomGeoJsonRequestOption,
): FlatArray<Promise<Feature<Geometry, GeoJsonProperties>[]>[], 1>[] {
  const segmentLength =
    customGeoJsonRequestOption.customOption.segmentLength || customGeoJsonLayerConfig.defaultLineSegmentLength;

  const units = customGeoJsonRequestOption.customOption.units || customGeoJsonLayerConfig.defaultLineSegmentUnit;

  // split into chunks by length resolution
  const featuresLinesString = chunkLineString(lineStringFeatures, segmentLength, units);

  const lineStringBatches = chunkLineStringFeatureArrayByMaxSize(featuresLinesString.features, MAX_BATCH_SIZE_IN_KB);

  const promises: Promise<Feature<Geometry, GeoJsonProperties>[]>[] = [];
  lineStringBatches.forEach((batch, batchIndex) => {
    const correspondingLineFeatures = batch.map((_, index) => {
      return lineStringBatches[batchIndex][index];
    });

    const lineStringCoordinates: SinglePointCoordinate<CoordinateSystem.WGS84>[] = [];
    const lineStringIndexRanges: { startIndex: number; endIndex: number }[] = [];
    for (const feature of batch) {
      const coordinates = feature.geometry.coordinates.map(
        ([lon, lat]) => new SinglePointCoordinate<CoordinateSystem.WGS84>(lat, lon, CoordinateSystem.WGS84),
      );

      const startIndex = lineStringCoordinates.length;
      lineStringCoordinates.push(...coordinates);
      const endIndex = lineStringCoordinates.length - 1;

      lineStringIndexRanges.push({ startIndex, endIndex });
    }

    const aggregate = Aggregate.max;

    const request: PointRequest<CoordinateSystem.WGS84> = {
      coordinates: lineStringCoordinates,
      startDatetime: datetime,
      duration: Duration.fromISO("PT0H"),
      temporalResolution: Duration.fromISO("PT1H"),
      width: 0,
      height: 0,
      parameters: [parameter],
      model,
      boundingBoxLimit: null,
    };

    const promise = apiThreadPool
      .GetPointJSON(request, retry, { forcePostRequest: true, preventRequestSplitter: true })
      .then(({ data }) => {
        return correspondingLineFeatures.map((feature, index) => {
          const valuesByLineString = lineStringIndexRanges.map(({ startIndex, endIndex }) => {
            return data[0].coordinates
              .slice(startIndex, endIndex + 1)
              .map((datesValue) => datesValue.dates[0].value as number);
          });
          const singleValue = aggregateOfList(aggregate, valuesByLineString[index]);

          if (singleValue != null) {
            return addValueToFeature(feature, singleValue);
          }
          return feature;
        });
      });

    promises.push(promise);
  });

  return promises.flat();
}

export const processCustomGeoJson = (
  data: SlicedCustomGeoJSON,
  retry: RetryState,
  customGeoJsonRequestOption: CustomGeoJsonRequestOption,
  datetime: DateTime | undefined,
): Promise<SlicedCustomGeoJSON> | SlicedCustomGeoJSON => {
  const { model, parameter_unit: parameter } = customGeoJsonRequestOption;
  if (model && parameter && datetime) {
    const hydratePolygonPromises = hydratePolygonFeatures(data.polygons.features, parameter, model, datetime, retry);

    const hydrateLineStringPromises = hydrateLineStringFeatures(
      data.lineStrings.features,
      parameter,
      model,
      datetime,
      retry,
      customGeoJsonRequestOption,
    );

    return Promise.allSettled([...hydratePolygonPromises, ...hydrateLineStringPromises]).then((results) => {
      const { polygons, lineStrings } = categorizeFeatures(results);

      const updatedGeoJson: SlicedCustomGeoJSON = {
        lineStrings: lineStrings,
        points: data.points,
        polygons: polygons,
      };
      return updatedGeoJson;
    });
  }

  return data;
};

export function isSlicedCustomGeoJSON(data: any): data is SlicedCustomGeoJSON {
  return (
    data &&
    typeof data === "object" &&
    data.polygons &&
    isFeatureCollection(data.polygons, "Polygon") &&
    data.lineStrings &&
    isFeatureCollection(data.lineStrings, "LineString") &&
    data.points &&
    isFeatureCollection(data.points, "Geometry")
  );
}

function isFeatureCollection(collection: any, type: string): boolean {
  return (
    collection &&
    typeof collection === "object" &&
    collection.type === "FeatureCollection" &&
    Array.isArray(collection.features) &&
    collection.features.every((feature: any) => feature.geometry && feature.geometry.type === type)
  );
}

import { type AsyncResult, SynchrounousState, promiseToAsyncResult } from "@/cache/AsyncResult";
import { networkCaches } from "@/cache/GlobalCache";
import { getTileZoomRange } from "@/cache/SpatioTemporalTileCache/PotentiallyVisibleTileSet";
import { instantiateTiles } from "@/cache/SpatioTemporalTileCache/TileArea";
import { LayerBase, type PropsChecker } from "@/layers";
import type { ScenePublicApi } from "@/layers/Compositor";
import { shaderCompiler } from "@/layers/shaders";
import {
  bufferData,
  constantByName,
  createBuffer,
  createFramebuffer,
  createRenderTexture,
  createRenderbuffer,
  supportsFloatTextures,
} from "@/layers/utility/webgl";
import { ActiveRequestMemory } from "@/request-controller/ActiveRequestMemory";
import { ShaderBuilder } from "@/shader-compiler";
import { safeFmt } from "@/utility/safeFmt";
import { type GridDimension, MeteomaticsApiUrl } from "@mm/api.meteomatics.com";
import type { GuiTimeZone, WmsLayer } from "@mm/metx-workbench.meteomatics.com";
import Logger from "logging";
import type { DateTime } from "luxon";
import type * as Mapbox from "mapbox-gl";
import { type InterpolationMode, getSpatialInterpolationMode } from "weather-parameter-utils";
import { getAreaRequest, getTileSet, getTileSetPromise } from "./WMSLayerUtilities";

const logger = Logger.fromFilename(__filename);

const MACRO_BRIGHTNESS_CONTRAST_SATURATION = "USE_BCS";
const MACRO_CATMULL_ROM_SAMPLING = "USE_CATMULL_ROM_SAMPLING";

interface PrepassFramebuffer {
  dataRasterizationSize: GridDimension;
  framebuffer: WebGLFramebuffer;
  depth: WebGLRenderbuffer;
  color: WebGLTexture;
}

function createPrepassFramebuffer(
  gl: WebGLRenderingContext,
  dataRasterizationSize: GridDimension,
  interpolationMode: InterpolationMode,
): PrepassFramebuffer {
  const framebuffer = createFramebuffer(gl);
  gl.bindFramebuffer(gl.FRAMEBUFFER, framebuffer);

  // rgba
  const color = createRenderTexture(gl, dataRasterizationSize, {
    internalFormat: gl.RGBA,
    format: gl.RGBA,
    type: gl.FLOAT,
    interpolationMode,
  });
  gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, color, 0);

  const depth = createRenderbuffer(gl);
  gl.bindRenderbuffer(gl.RENDERBUFFER, depth);

  gl.renderbufferStorage(
    gl.RENDERBUFFER,
    gl.DEPTH_COMPONENT16,
    dataRasterizationSize.width,
    dataRasterizationSize.height,
  );

  gl.framebufferRenderbuffer(gl.FRAMEBUFFER, gl.DEPTH_ATTACHMENT, gl.RENDERBUFFER, depth);

  const status = gl.checkFramebufferStatus(gl.FRAMEBUFFER);

  if (status !== gl.FRAMEBUFFER_COMPLETE) {
    throw Error(`failed to construct framebuffer for prepass. Status ${constantByName(gl, status)}`);
  }

  return {
    dataRasterizationSize,
    framebuffer,
    color,
    depth,
  };
}

interface PrepassProgram {
  program: WebGLProgram;
  buffer_QuadVertices: WebGLBuffer;
  buffer_TextureCoordinates: WebGLBuffer;
  a_position: number;
  a_texCoord: number;
  u_mercatorToGl: WebGLUniformLocation;
  u_tileSampler: WebGLUniformLocation;
  u_opacity: WebGLUniformLocation;
}

function createPrepassProgram(gl: WebGLRenderingContext): AsyncResult<PrepassProgram> {
  if (!supportsFloatTextures(gl)) {
    throw new Error("GPU does not support floating point textures");
  }

  return promiseToAsyncResult(
    shaderCompiler.compileProgram(gl, "prepass_tile.vert", "prepass_tile.frag").then((program) => {
      // TODO: this should be somehow picked up in the GUI
      if (program instanceof Error) {
        throw program;
      }

      const u_mercatorToGl = gl.getUniformLocation(program, "u_mercatorToGl");
      if (u_mercatorToGl == null) {
        throw Error("u_mercatorToGl not found in shader.");
      }
      const u_tileSampler = gl.getUniformLocation(program, "u_tileSampler");
      if (u_tileSampler == null) {
        throw Error("u_tileSampler not found in shader.");
      }
      const u_opacity = gl.getUniformLocation(program, "u_opacity");
      if (u_opacity == null) {
        throw Error("u_opacity not found in shader.");
      }

      const a_position = gl.getAttribLocation(program, "a_position");
      const buffer_QuadVertices = createBuffer(gl);
      bufferData(gl, buffer_QuadVertices);

      const a_texCoord = gl.getAttribLocation(program, "a_texCoord");

      const buffer_TextureCoordinates = createBuffer(gl);
      bufferData(gl, buffer_TextureCoordinates);

      return {
        program,
        buffer_QuadVertices,
        buffer_TextureCoordinates,
        a_position,
        a_texCoord,
        u_mercatorToGl,
        u_tileSampler,
        u_opacity,
      };
    }),
  );
}

interface MainpassProgram {
  program: WebGLProgram;
  buffer_QuadVertices: WebGLBuffer;
  buffer_TextureCoordinates: WebGLBuffer;
  a_position: number;
  a_texCoord: number;
  u_sampler: WebGLUniformLocation;
  u_props: WebGLUniformLocation;
  u_bcs: WebGLUniformLocation;
}

function createMainpassProgram(gl: WebGLRenderingContext): AsyncResult<MainpassProgram> {
  if (!supportsFloatTextures(gl)) {
    throw new Error("GPU does not support floating point textures");
  }
  const vertexShader = new ShaderBuilder("mainpass.vert");
  const fragmentShader = new ShaderBuilder("mainpass.frag");
  fragmentShader.set(MACRO_BRIGHTNESS_CONTRAST_SATURATION, true);
  fragmentShader.set(MACRO_CATMULL_ROM_SAMPLING, false);

  return promiseToAsyncResult(
    shaderCompiler.compileProgram(gl, vertexShader, fragmentShader).then((program) => {
      // TODO: this should be somehow picked up in the GUI
      if (program instanceof Error) {
        throw program;
      }

      const u_sampler = gl.getUniformLocation(program, "u_sampler");
      if (u_sampler == null) {
        throw Error("u_sampler not found in shader.");
      }
      const u_props = gl.getUniformLocation(program, "u_props");
      if (u_props == null) {
        throw Error("u_props not found in shader.");
      }
      const u_bcs = gl.getUniformLocation(program, "u_bcs");
      if (u_bcs == null) {
        throw Error("u_bcs not found in shader.");
      }

      const a_position = gl.getAttribLocation(program, "a_position");
      const buffer_QuadVertices = createBuffer(gl);
      bufferData(gl, buffer_QuadVertices);

      const a_texCoord = gl.getAttribLocation(program, "a_texCoord");

      const buffer_TextureCoordinates = createBuffer(gl);
      bufferData(gl, buffer_TextureCoordinates);

      return {
        program,
        buffer_QuadVertices,
        buffer_TextureCoordinates,
        a_position,
        a_texCoord,
        u_sampler,
        u_props,
        u_bcs,
      };
    }),
  );
}

export class WmsLayerImpl extends LayerBase<WmsLayer> implements Mapbox.CustomLayerInterface {
  id: string;
  readonly type = "custom" as const;

  private prepassProgram?: AsyncResult<PrepassProgram>;
  private _prepassFramebuffer?: PrepassFramebuffer;
  private mainpassProgram?: AsyncResult<MainpassProgram>;

  private readonly activeRequestMemory: ActiveRequestMemory = new ActiveRequestMemory();

  public interpolationMode: InterpolationMode;
  constructor(uid: number, props: WmsLayer, scene: ScenePublicApi, timezone: GuiTimeZone) {
    super(uid, props, scene, timezone);

    this.id = this.humanReadableId();
    this.scene.getMapboxMap().addLayer(this);
    this.interpolationMode = getSpatialInterpolationMode(this.props.parameter_unit);
  }

  get mapboxIndex(): string {
    return this.id;
  }

  checker(): PropsChecker<WmsLayer, LayerBase<WmsLayer>> {
    // TODO: tighter typing here to validate `name`
    const checkLayerProp = (name: any, signal?: (value: string) => void) => (prev: any, curr: any) => {
      const changed = prev[name] !== curr[name];
      if (changed) {
        this.setLayerProps({
          [name]: curr[name],
        });

        if (signal) {
          signal(curr[name]);
        }
      }
      return changed;
    };

    return {
      model: checkLayerProp("model"),
      opacity: checkLayerProp("opacity"),
      parameter_unit: checkLayerProp("parameter_unit"),
      show: checkLayerProp("show"),
      calibrated: checkLayerProp("calibrated"),
      vertical_interpolation: checkLayerProp("vertical_interpolation"),
      color_map: checkLayerProp("color_map"),
      legend_visible: checkLayerProp("legend_visible"),
      ens_select: checkLayerProp("ens_select"),
      custom_options: checkLayerProp("custom_options"),
    };
  }

  humanReadableId(): string {
    return safeFmt`metx.PNG.${this.props.model}:${this.props.parameter_unit}${
      this.props.ens_select ? `-${this.props.ens_select}` : ""
    }@${this.props.color_map}#${this.uid}`;
  }

  isRebuffering(time: DateTime): boolean {
    const dateTimeWithOffset = this.scene.getDateTimeWithOffset(time);
    const pvs = getTileSet(dateTimeWithOffset, this.props, this.scene.getMapViewport().viewportArea);
    return !(pvs.isCovering() && pvs.props.isExact);
  }

  removeLayer(): void {
    this.scene.getMapboxMap().removeLayer(this.id);
  }

  beforeRender(): void {
    networkCaches.wms_tile_cache.dropObsoleteTiles(this.activeRequestMemory.getObsoletes());
  }

  private rememberActiveRequests(datetime: DateTime) {
    const areaRequest = getAreaRequest(datetime, this.props, this.scene.getMapViewport().viewportArea);
    const current = networkCaches.wms_tile_cache.getApiRequestsForArea(areaRequest);
    const currentUrls = current.map((desc) => MeteomaticsApiUrl.forWms(desc).toString());

    this.activeRequestMemory.remember(currentUrls);
  }

  fetchData(timeFrame: DateTime): Promise<void> {
    const dateTimeWithOffset = this.scene.getDateTimeWithOffset(timeFrame);
    // We trigger the tile set request, which triggers pre-fetching of the data via tiling service.
    // The data stays in the cache, so when we need it, we can get it instantly.
    const tileSetPromise = getTileSetPromise(dateTimeWithOffset, this.props, this.scene.getMapViewport().viewportArea);
    return tileSetPromise.then(() => {});
  }

  prerender(gl: WebGLRenderingContext, matrix: number[]): void {
    const size = this.scene.getRenderSize();
    if (!this.props.show || this.props.opacity <= 0.0) {
      return;
    }
    const fb = this.getPrepassFramebuffer(gl, size, this.interpolationMode);
    if (this.prepassProgram == null || fb == null) {
      return;
    }
    const { synchronous: prepass } = this.prepassProgram;
    if (
      prepass === SynchrounousState.StillPending ||
      prepass === SynchrounousState.PermanentlyFailed ||
      prepass === SynchrounousState.NotRequested
    ) {
      return;
    }

    const viewport = this.scene.getMapViewport();
    const dateTimeWithOffset = this.scene.getDisplayTimeWithOffset();

    this.rememberActiveRequests(this.scene.getNextTime());

    const pvs = getTileSet(dateTimeWithOffset, this.props, this.scene.getMapViewport().viewportArea);

    if (!pvs.isCovering()) {
      this.scene.repaint();
    } else {
      logger.perfMark("time step end");
      logger.perfMeasure("1 step", "time step start", "time step end");
    }

    gl.useProgram(prepass.program);
    gl.viewport(0, 0, size.width, size.height);

    gl.bindFramebuffer(gl.FRAMEBUFFER, fb.framebuffer);
    gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, fb.color, 0);
    gl.framebufferRenderbuffer(gl.FRAMEBUFFER, gl.DEPTH_ATTACHMENT, gl.RENDERBUFFER, fb.depth);

    const tilesByZoomLevel = pvs.get();
    const zoomRange = getTileZoomRange(tilesByZoomLevel);

    const nearPlane = 0;
    const farPlane = 1;

    // clear the buffer, this way we mark a pixel as not rendered by a tile (not yet available)
    // and the main pass will render a transparent pixel.
    gl.clearColor(0, 0, 0, 0);
    gl.clear(gl.COLOR_BUFFER_BIT);

    // Inverted depth test ensures smaller tiles block larger parent tiles using the depth test
    // This is important for two reasons: (1) it reduces overdraw (2) ensures the same layer is not blended multiple times into the output
    gl.enable(gl.DEPTH_TEST);
    gl.depthMask(true);
    gl.depthRange(nearPlane, farPlane);
    gl.depthFunc(gl.GREATER); // smaller tiles have greater zoom levels and thus greater depth
    gl.clearDepth(0);
    gl.clear(gl.DEPTH_BUFFER_BIT);

    gl.uniformMatrix4fv(prepass.u_mercatorToGl, false, matrix);

    // Hardcode the opacity to 1.0 for the prerendering.
    gl.uniform1f(prepass.u_opacity, 1.0);

    gl.bindBuffer(gl.ARRAY_BUFFER, prepass.buffer_TextureCoordinates);
    gl.enableVertexAttribArray(prepass.a_texCoord);
    gl.vertexAttribPointer(prepass.a_texCoord, 2, gl.FLOAT, false, 0, 0);

    gl.bindBuffer(gl.ARRAY_BUFFER, prepass.buffer_QuadVertices);
    gl.enableVertexAttribArray(prepass.a_position);
    gl.vertexAttribPointer(prepass.a_position, 3, gl.FLOAT, false, 0, 0);

    gl.activeTexture(gl.TEXTURE0);
    gl.uniform1i(prepass.u_tileSampler, 0);

    // TODO: Consider getting the empty tile in other way
    const fullyTransparentTile = networkCaches.wms_tile_cache.networkCache.emptyTile;
    const failureTile = networkCaches.wms_tile_cache.networkCache.failureTile;
    //let passes = 0;

    // draw from largest zoom level (smallest tile) to smallest zoom level (largest tile) to minimize overdraw.
    const descendingLayers = [...tilesByZoomLevel.entries()].sort((a, b) => b[0] - a[0]);
    for (const [sublayerZoom, sublayerTiles] of descendingLayers) {
      // `sublayerZoom / maxZoom` would compress the zoom range into [0,1]. But we want (0,1], since 0 is reserved
      // for `no tile drawn yet`. So we use 1-based indexing and end up with `((sublayerZoom + 1) / (maxZoom + 1))`.
      // `* 2 - 1` then scales this [0,1] range to the usual clipspace range [-1,1].
      //const sublayerZoomClipSpace = clamp(((sublayerZoom + 1) / (maxZoom + 1)) * 2 - 1, -1, 1);
      const depth_eps = 0.1;
      const depth_ =
        ((sublayerZoom - zoomRange.min) / (zoomRange.max - zoomRange.min)) * (1 - 2 * depth_eps) + depth_eps;
      const depth = Number.isNaN(depth_) ? depth_eps : depth_;

      for (const [, { tileData, tileGeometry }] of Object.entries(sublayerTiles)) {
        // cull draw calls for out of weather model bounds tiles as an optimization
        if (tileData === fullyTransparentTile || tileData === failureTile) {
          continue;
        }
        // tileData: CombinedPNGTile
        const tileTexture = tileData.texture(gl);
        gl.bindTexture(gl.TEXTURE_2D, tileTexture);

        const instances = [...instantiateTiles(viewport.viewportArea, tileGeometry)];

        // TODO: a single draw call for all instances instead of a draw call per instance
        for (const instance of instances) {
          // render a quad covering the area of the current tile
          const [n, w, s, e] = instance.mercator(0);

          gl.bufferData(
            gl.ARRAY_BUFFER,
            new Float32Array([
              // lower left triangle of the quad (◣), counter-clockwise
              w,
              s,
              depth,
              e,
              s,
              depth,
              w,
              n,
              depth,
              // top right triangle of the quad (◥), counter-clockwise
              w,
              n,
              depth,
              e,
              s,
              depth,
              e,
              n,
              depth,
            ]),
            gl.STATIC_DRAW,
          );

          const primitiveType = gl.TRIANGLES;
          const offset = 0;
          const count = 6;
          gl.drawArrays(primitiveType, offset, count);
        }
      }
    }
    gl.bindFramebuffer(gl.FRAMEBUFFER, null);
  }

  render(gl: WebGLRenderingContext, matrix: number[]): void {
    if (!this.props.show || this.props.opacity <= 0.0) {
      return;
    }

    const fb = this._prepassFramebuffer;
    const size = this.scene.getRenderSize();
    if (fb == null || this.mainpassProgram == null || this.prepassProgram == null) {
      return;
    }

    // Make sure that both prepass and mainpass exist.
    const { synchronous: prepass } = this.prepassProgram;
    if (prepass === SynchrounousState.StillPending || prepass === SynchrounousState.PermanentlyFailed) {
      return;
    }
    const { synchronous: mainpass } = this.mainpassProgram;
    if (
      mainpass === SynchrounousState.StillPending ||
      mainpass === SynchrounousState.PermanentlyFailed ||
      mainpass === SynchrounousState.NotRequested
    ) {
      return;
    }

    gl.useProgram(mainpass.program);

    // Hardcode the opacity to 1.0 for the prerendering.
    gl.uniform4f(mainpass.u_props, this.props.opacity, 1, size.width, size.height);

    // TODO: expose in GUI, store in backend
    const brightness = 1.0;
    const contrast = 1.0;
    const saturation = 1.0;
    gl.uniform4f(mainpass.u_bcs, brightness, contrast, saturation, 0.0);

    gl.viewport(0, 0, size.width, size.height);
    gl.disable(gl.DEPTH_TEST);

    gl.bindBuffer(gl.ARRAY_BUFFER, mainpass.buffer_TextureCoordinates);

    gl.enableVertexAttribArray(mainpass.a_position);
    gl.vertexAttribPointer(mainpass.a_position, 2, gl.FLOAT, false, 0, 0);

    gl.activeTexture(gl.TEXTURE0);
    gl.uniform1i(mainpass.u_sampler, 0);
    gl.bindTexture(gl.TEXTURE_2D, fb.color);

    const primitiveType = gl.TRIANGLES;
    const offset = 0;
    const count = 6;
    gl.drawArrays(primitiveType, offset, count);
  }

  /**
   * Method called when the layer is added to the mapbox map.
   * See https://docs.mapbox.com/mapbox-gl-js/api/#styleimageinterface#onadd
   */
  onAdd(map: Mapbox.Map, gl: WebGLRenderingContext) {
    this.prepassProgram = createPrepassProgram(gl);
    this.mainpassProgram = createMainpassProgram(gl);
  }

  private getPrepassFramebuffer(
    gl: WebGLRenderingContext,
    dataRasterizationSize: GridDimension,
    interpolationMode: InterpolationMode,
  ): PrepassFramebuffer {
    if (this._prepassFramebuffer == null) {
      this._prepassFramebuffer = createPrepassFramebuffer(gl, this.scene.getRenderSize(), this.interpolationMode);
    } else {
      const size = this._prepassFramebuffer.dataRasterizationSize;
      if (size.width !== dataRasterizationSize.width || size.height !== dataRasterizationSize.height) {
        const depth = this._prepassFramebuffer.depth;
        const color = this._prepassFramebuffer.color;
        const v = createPrepassFramebuffer(gl, dataRasterizationSize, interpolationMode);
        gl.deleteRenderbuffer(depth);
        gl.deleteTexture(color);
        this._prepassFramebuffer = v;
      }
    }
    return this._prepassFramebuffer;
  }

  getActiveWeatherParametersAsString() {
    return [
      {
        model: this.props.model,
        parameter: this.props.parameter_unit,
        ensSelect: this.props.ens_select,
      },
    ];
  }
}

import { DropdownSorter } from "@/dashboard/Sorter/Sorter";
import { t } from "@lingui/macro";
import type { SlimProfile } from "@mm/metx-workbench.meteomatics.com";
import { Controller, useFormContext } from "react-hook-form";

function FiltersAndSorters() {
  const { control, register } = useFormContext();

  const filterOptions = { title: t`Title`, time_created: t`Creation Date`, time_updated: t`Edit Date` };

  return (
    <div className="grid" data-columns="1,flex">
      <input className="form-input" type="search" placeholder={t`Filter…`} {...register("query")} />
      <Controller
        control={control}
        name="sort"
        render={({ field: { value, onChange } }) => (
          <DropdownSorter<Partial<SlimProfile>>
            object={filterOptions}
            activeSorter={value}
            onChangeSorter={(property, isDescending) => {
              onChange({
                property,
                isDescending,
              });
            }}
          />
        )}
      />
    </div>
  );
}

export function DashboardHeader({ heading }: { heading?: string | JSX.Element }) {
  if (heading) {
    return (
      <div className="grid" data-columns="1,flex">
        <h2 className="profiles__heading" data-testid="profile-heading">
          {heading}
        </h2>
        <FiltersAndSorters />
      </div>
    );
  }

  return <FiltersAndSorters />;
}

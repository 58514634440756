// NOTE: this file should compile in the main thread and worker threads
// NOTE: some imports like `LayerGroupModel` MUST be a deep import, even though the package root reexports the model, because the root exports other things that depend on DOM APIs not available in webworkers
import type { CrossModelParameterSpecification } from "@mm/api-layers.meteomatics.com/models";
import Fuse from "fuse.js";
import { escapeRegex } from "../../fn";
import { isParameterRange } from "../../fn/typecheck";
import type { HasLayerIdxNarrowing, PartialWeatherParameter } from "./model/PartialWeatherParameter";
import type { ExactLayerMatch, ExactMatchResult, SearchResults } from "./model/SearchResults";
import type { WeatherParamSearchOptions } from "./model/WeatherParamSearchOptions";
import { defaultSearchOptions as searchOptions } from "./model/WeatherParamSearchOptions";

const FUSE_OPTIONS = {
  includeScore: true,
  keys: [
    {
      name: "name",
      weight: 1.0,
    },
    {
      name: "description_en",
      weight: 0.5,
    },
    {
      name: "description_de",
      weight: 0.5,
    },
    {
      name: "description_fr",
      weight: 0.5,
    },
  ],
};

/**
 * Parameter search engine that runs in the GUI thread in the synchronization mode.
 */
export class WeatherParamSearchEngine {
  private fuzzySearchEngine: Fuse<CrossModelParameterSpecification>;
  private exactMatchRegularExpressions: {
    layerGroup: CrossModelParameterSpecification;
    layerIdx: number;
    regex: RegExp;
  }[];
  private prefixMatchRegularExpressions: { layerGroup: CrossModelParameterSpecification; regexes: RegExp[] }[];
  /**
   * A simple string label to indentify different instances of engine.
   */
  public label = "WeatherParamSearchEngine";

  constructor(
    private parameters: CrossModelParameterSpecification[],
    private defaultSearchOptions: WeatherParamSearchOptions = searchOptions,
  ) {
    // NOTE: identical to `replaceCorpus`. Unfortuntely, Typescripts typechecking cannot deal with non-optional
    // initializations in callees of the constructor.
    this.fuzzySearchEngine = new Fuse(this.parameters, FUSE_OPTIONS);
    this.exactMatchRegularExpressions = [];
    this.prefixMatchRegularExpressions = [];
    this.compileRegularExpressions(parameters);
  }

  replaceCorpus(newParameters: CrossModelParameterSpecification[]) {
    this.parameters = newParameters;
    this.fuzzySearchEngine = new Fuse(this.parameters, FUSE_OPTIONS);
    this.exactMatchRegularExpressions = [];
    this.prefixMatchRegularExpressions = [];
    this.compileRegularExpressions(this.parameters);
  }

  searchCorpus(
    searchQuery: string,
    searchOptions: WeatherParamSearchOptions = this.defaultSearchOptions,
  ): SearchResults | null {
    // this would prompt us to return all results
    if (searchQuery.length === 0) {
      return null;
    }

    let regular = this.matchParameterPrefixExactly(searchQuery);

    if (!regular) {
      return null;
    }

    function hasNecessaryFlags(flags: string[]) {
      if (searchOptions.disabledFlags) {
        for (const disabled of searchOptions.disabledFlags) {
          if (flags.includes(disabled)) {
            return false;
          }
        }
      }

      if (searchOptions.enabledFlags) {
        for (const enabled of searchOptions.enabledFlags) {
          if (flags.includes(enabled)) {
            return true;
          }
        }

        return false;
      }

      return true;
    }

    regular = regular.filter((parameter) => hasNecessaryFlags(parameter.layerGroup.flags));

    // we are allowed to return up to MAX_SEARCH_RESULTS, prefer exact/regular matches
    // over fuzzy results.
    if (regular.length >= searchOptions.maxNumberOfResults) {
      return {
        // TODO: this omits fuzzy search as an optimization, so the `numResults` is incorrect
        numResults: regular.length,
        regular,
        fuzzy: [],
      };
    }

    // we do not have enough regular matches, attempt a fuzzy search
    const allFuzzyMatches = this.fuzzySearchEngine.search(searchQuery) || [];

    // add fuzzy results until we have MAX_SEARCH_RESULTS, avoid
    // duplicates that are already in regular results

    const fuzzy = [];

    for (const fuzzyMatch of allFuzzyMatches) {
      if (
        void 0 === regular.find((exactMatch) => exactMatch.layerGroup.name === fuzzyMatch.item.name) &&
        hasNecessaryFlags(fuzzyMatch.item.flags)
      ) {
        fuzzy.push(fuzzyMatch);

        if (regular.length + fuzzy.length >= searchOptions.maxNumberOfResults) {
          break;
        }
      }
    }

    return {
      // TODO: this is missing deduplication
      numResults: allFuzzyMatches.length + regular.length,
      fuzzy,
      regular,
    };
  }

  /**
   * Parse a serialized parameter.
   *
   * @param searchQuery
   * @return all matching parameters. Some parameters like `t_2m:C` are not unique and are described
   * by a range-based layer and a explicitly listed layer at the same time.
   */
  matchParameterNameExactly(
    searchQuery: string,
    maxResults: number = Number.POSITIVE_INFINITY,
  ): HasLayerIdxNarrowing<PartialWeatherParameter>[] {
    const results = [];

    for (const format of this.exactMatchRegularExpressions) {
      const match = searchQuery.match(format.regex);
      if (match) {
        if (!format?.layerGroup) {
          console.warn("Warning: Missing layer group for format in match name exactly. Skipping entry.");
          continue; // Skip if malformed
        }
        const param: HasLayerIdxNarrowing<PartialWeatherParameter> = {
          parameter: format.layerGroup,
          narrowed_selection: {
            layerIdx: format.layerIdx,
            fields: match.groups ?? {},
          },
        };
        // TODO: validate ranges etc and filter if not matching
        results.push(param);

        if (results.length >= maxResults) {
          return results;
        }
      }
    }

    return results;
  }

  /**
   * Like `matchParameterNameExactly` but halts after the first match
   */
  matchSomeParameterNameExactly(searchQuery: string): HasLayerIdxNarrowing<PartialWeatherParameter> | null {
    return this.matchParameterNameExactly(searchQuery, 1)[0] ?? null;
  }

  /**
   * Exactly match a parameter prefix. This means `t_2` will as if `t_2m:C` is given.
   *
   * @param searchQuery
   * @return all matching parameters (some like `t_2m:C` are not unique).
   */
  matchParameterPrefixExactly(searchQuery: string): ExactMatchResult[] {
    const results: ExactMatchResult[] = [];

    for (const layerGroupMatcher of this.prefixMatchRegularExpressions) {
      const matchingLayers: ExactLayerMatch[] = [];
      for (const [layerIdx, regex] of layerGroupMatcher.regexes.entries()) {
        const match = searchQuery.match(regex);
        if (match) {
          matchingLayers.push({ layerIdx, match });
        }
      }

      if (matchingLayers.length > 0) {
        results.push({
          layerGroup: layerGroupMatcher.layerGroup,
          matchingLayers,
        });
      }
    }

    return results;
  }

  /**
   * Compile regular expressions for a set of layers and append them to the existing set of regular expressions.
   *
   * @param additionalCorpus
   */
  private compileRegularExpressions(additionalCorpus: CrossModelParameterSpecification[]) {
    for (const layerGroup of additionalCorpus) {
      const layerGroupPrefixMatchers = [];
      for (const [layerIdx, layer] of layerGroup.layers.entries()) {
        this.exactMatchRegularExpressions.push({
          regex: this.compileFormatToRegex(layer),
          layerGroup,
          layerIdx,
        });
        layerGroupPrefixMatchers[layerIdx] = this.compileFormatToRegexPrefix(layer, {
          shouldMatchVariablesExactly: true,
        });
      }

      this.prefixMatchRegularExpressions.push({
        regexes: layerGroupPrefixMatchers,
        layerGroup,
      });
    }
  }

  /**
   * Transforms the format of a base parameter into a regex that can parse all instantiations.
   *
   * The regex is not necessarily exact and may be able to parse parameters that are not valid instantiations. The range for example,
   * is not parsed.
   */
  private compileFormatToRegex(baseParameter: { format: string }) {
    const regex_str = escapeRegex(baseParameter.format).replace(/<([^>]+)>/g, (_match, fieldName) => {
      if (!(fieldName in baseParameter)) {
        console.warn(
          `Warning: Missing key '${fieldName}' in parameter format: '${baseParameter.format}'. Defaulting to match any string.`,
        );
        return `(?<${fieldName}>[a-zA-Z0-9]*)`;
      }
      return `(?<${fieldName}>[a-zA-Z0-9-]+)`;
    });
    return new RegExp(`^${regex_str}$`);
  }

  /**
   * This just builds a chain of optional matches. For example:
   *
   * ```
   * startsWithRegex("air") = "(a(?:i(?:r)?)?)"
   * ```
   *
   * which will match "a", "ai" and "air"
   *
   * @param word some string
   * @returns a regex matching any prefix of `word`, including `word` itself
   */
  private compilePrefixRegex(word: string): string {
    const chars = word.split("").map((char) => escapeRegex(char));
    const suffix = `${")?".repeat(chars.length - 1)})`;
    const regex_str = `(?:${chars.join("(?:")}${suffix}`;
    return regex_str;
  }

  private compileFormatToRegexPrefix(
    baseParameter: { format: string },
    settings: { shouldMatchVariablesExactly: boolean },
  ) {
    // I am sorry.
    let numParts = 0;
    let regex_str = baseParameter.format.replace(/([^<>]+)|<([^>]+)>/g, (_match, fixedSubstring, variableField) => {
      numParts++;
      if (fixedSubstring !== void 0) {
        const startsWith = this.compilePrefixRegex(fixedSubstring);
        return `(?:(?<STATIC_PART_${numParts}>${startsWith})`;
      }
      if (!(variableField in baseParameter)) {
        console.warn(
          `Warning: Missing key '${variableField}' in parameter format: '${baseParameter.format}'. Defaulting to match any string.`,
        );
        return `(?:(?<${variableField}>[a-zA-Z0-9]*)`; // Fallback: match any alphanumeric string if key is missing
      }
      if (!settings.shouldMatchVariablesExactly) {
        return `(?:(?<${variableField}>[a-zA-Z0-9]+)`;
      }
      if (isParameterRange((baseParameter as any)[variableField])) {
        return `(?:(?<${variableField}>[0-9]+[a-zA-Z]*)`;
      }
      const allowedValuesRegexes = (baseParameter as any)[variableField].map(this.compilePrefixRegex);
      return `(?:(?<${variableField}>${allowedValuesRegexes.join("|")})`;
    });
    // the first group is not optional
    regex_str += `${")?".repeat(numParts - 1)})`;
    const regex = new RegExp(`^${regex_str}$`);
    return regex;
  }
}

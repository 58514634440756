import { fetchProfiles } from "@/api/hooks/profile";
import { useAuth } from "@/auth";
import { Dashboards } from "@/dashboard";
import { setTitle } from "@/effects";
import { MeteomaticsLogo } from "@/meteomatics-logo";
import { useAppDispatch } from "@/reducer";
import { Trans, t } from "@lingui/macro";
import React, { type ComponentType, lazy, Suspense, useEffect, useMemo } from "react";
import logo from "../logo-ncm.png";
import "./profile.scss";

type OverlayModule = {
  ProfileOverlay: ComponentType<any>;
};
type ProfilePageProps = {
  showCustomLogo?: boolean;
};
export default function ProfilePage({ showCustomLogo }: ProfilePageProps) {
  const dispatch = useAppDispatch();
  const { sessionActivated } = useAuth();

  useEffect(() => {
    // TODO: There is a crash when login in again after the session expired without checking for sessionActivated. This should just be a workaround.
    // Find a better solution!
    if (sessionActivated) {
      dispatch(fetchProfiles());
    }
  }, [sessionActivated, dispatch]);

  useEffect(() => {
    setTitle(t({ id: "profiles.title", message: "Profiles" }));
  }, []);

  // TODO: clean error handling if import of overlay fails
  const { ProfileOverlay } = useMemo(() => {
    const overlayModule: Promise<OverlayModule> = import(
      /* webpackChunkName: "overlay-[request]" */ "@/overlay/overlays/profile"
    );
    const errorComponentModule = { default: () => <div>Loading overlay failed</div> };

    return {
      ProfileOverlay: lazy(() =>
        overlayModule
          .then(({ ProfileOverlay }) => ({ default: ProfileOverlay }))
          .catch(() => {
            console.error("error");
            return errorComponentModule;
          }),
      ),
    };
  }, []);

  return (
    <>
      <div className="page page--profile cover-window overlay">
        <div className={`box-section background-img  ${showCustomLogo ? "custom-logo-background" : ""}`}>
          <div className="intro-watermark">
            <h1>Welcome to MetX</h1>
          </div>
          {showCustomLogo ? <img height="80" src={logo} alt="logo ncm" /> : <MeteomaticsLogo />}
        </div>
        <div className="box-section">
          <Dashboards maximized={true} heading={<Trans id="profiles.heading.your-dashboard">Your Dashboards</Trans>} />
        </div>
      </div>
      <div className="gui-overlay overlay__layer">
        <Suspense fallback={<mm-loader size="large" />}>
          <ProfileOverlay />
        </Suspense>
      </div>
    </>
  );
}

import type { DataAge } from "../evictionStrategy";

export interface SpatiotemporalTileCacheStats {
  /**
   * Count of __loaded__ available tiles without pending tiles
   */
  count: number;
  /**
   * Count of in flight, pending tiles.
   */
  pending: number;
  /**
   * Count of tiles that were aborted by a middleware.
   */
  aborted: number;
  /**
   * Count of tile requests that failed. Increments once for each retry.
   * Does not include aborted tiles.
   */
  failed: number;
  /**
   * Count of tile requests that had to be retried at least once.
   * Includes retried tiles independent of their current and final state, e.g.
   * the retry might still be pending, failed permanently or successful.
   */
  retried: number;
  /**
   * Count of tile requests that failed permanently. Either because there is
   * no retry logic for the specific error or because the maximal number of retries
   * was exceeded.
   */
  failedPermanently: number;
  /**
   * Count of tiles that were loaded and then discarded by the cache replacement policy
   * to adhere to memory limits.
   */
  evicted: number;
  /**
   * Estimate of the consumed CPU memory in some unit determined by the implementing class.
   *
   * @see SpatiotemporalTileCache#formatSize
   */
  estimatedMemoryConsumption: number;
  /**
   * The most recent and least recent time any content of the cache was used. The timestamp is monotonically increasing; more recent
   * usages have higher values.
   */
  usage: DataAge | null;
}

export function emptySpatioTemporalTileCacheStats(): SpatiotemporalTileCacheStats {
  return {
    count: 0,
    pending: 0,
    aborted: 0,
    failed: 0,
    retried: 0,
    failedPermanently: 0,
    evicted: 0,
    estimatedMemoryConsumption: 0,
    usage: null,
  };
}

import { parentCellRenderer } from "@/overlay/components/CommonParts/tables/cellRenderers";
import {
  type NestedRowData,
  type TableOptions,
  type TypedColDef,
  getExpandedRows,
} from "@/overlay/components/CommonParts/tables/nestedTable/utils";
import type { RowClickedEvent } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact, type AgGridReactProps } from "ag-grid-react";
import React, { useMemo, useState } from "react";

import "./NestedTable.module.scss";

export type NestedTableProps<T extends NestedRowData> = Partial<AgGridReactProps> & {
  rows: T[];
  columnDefs: TypedColDef<T>[];
  tableOptions?: Partial<TableOptions>;
};

export const NestedTable = <T extends NestedRowData>({
  rows,
  columnDefs,
  tableOptions = { isNested: true },
  ...agGridProps
}: NestedTableProps<T>) => {
  const [expandedRowIds, setExpandedRowIds] = useState<string[]>([]);

  const onRowClicked = (event: RowClickedEvent) => {
    const rowData = event.data as NestedRowData;
    if (rowData.children?.length) {
      if (expandedRowIds.includes(rowData.id)) {
        setExpandedRowIds(expandedRowIds.filter((id) => id !== rowData.id));
      } else {
        setExpandedRowIds([...expandedRowIds, rowData.id]);
      }
    }
  };
  const rowDataWithExpandedRows = useMemo(
    () => getExpandedRows(rows, expandedRowIds, !!tableOptions?.isNested),
    [rows, expandedRowIds],
  );

  const modifiedColumnDefs = useMemo(
    () =>
      columnDefs.map((colDef) => ({
        ...colDef,
        cellRenderer: parentCellRenderer(expandedRowIds, columnDefs[0].field),
      })),
    [expandedRowIds, columnDefs],
  );

  return (
    <div className="ag-theme-alpine" style={{ height: "100%", width: "100%" }} data-testid="nested-table">
      <AgGridReact
        rowData={rowDataWithExpandedRows}
        columnDefs={modifiedColumnDefs}
        headerHeight={32}
        rowHeight={32}
        domLayout={tableOptions?.isFloatingHeader ? "normal" : "autoHeight"}
        onRowClicked={onRowClicked}
        defaultColDef={{ sortable: false, suppressMovable: true }}
        {...agGridProps}
      />
    </div>
  );
};

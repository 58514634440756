import { formatByteSize } from "@/utility/bytesize";

/**
 * Marks an object as being queriable for statistics.
 *
 * Allows quick and dirty live inspection of object state via the metx developer panel.
 *
 * @param Stats an object aggregating information
 */
export interface Stats<Stats extends { [key: string]: any }> {
  stats(): Stats;

  statsJsx(): JSX.Element;

  /**
   * A short title or label for the statistics. Used as a heading.
   */
  label(): string;

  /**
   * Short description of the statistics. Used as a introductory paragraph below the heading.
   * Could for example, display configurations.
   */
  descJsx(): JSX.Element;
}

export type TableCellFormatter = (val: any, key: string) => any;

export const defaultFormatter: TableCellFormatter = (v) => v;
export const defaultCacheFormatter: TableCellFormatter = (val, key) => {
  switch (key) {
    case "estimatedMemoryConsumption":
      return formatByteSize(val);
    case "usage":
      return val == null ? (
        "(cache empty)"
      ) : (
        <>
          LRU={val.leastRecentlyUsed}
          <br />
          MRU={val.mostRecentlyUsed}
        </>
      );
    default:
      return val;
  }
};

export function jsxTable(stats: { [key: string]: any }, formatter: TableCellFormatter = defaultFormatter) {
  const entries = Object.entries(stats);

  if (entries.length === 0) {
    return <></>;
  }

  return (
    <table>
      <tbody>
        {Object.entries(stats).map(([label, value]) => (
          <tr key={label}>
            <th>{label}</th>
            <td>{formatter(value, label)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

// Small abstraction around the javascript console to add subsystem prefixes to log messages
export interface Label {
  workspaceMember: string | null;
  subsystem: string;
}

export default class Logger implements Pick<Console, "log" | "warn" | "error" | "debug"> {
  public readonly prefix: string;

  constructor(public readonly label: Label) {
    this.prefix = Logger.makePrefix(label);
  }

  private static makePrefix({ workspaceMember, subsystem }: Label): string {
    return workspaceMember == null ? `[${subsystem}]` : `[${workspaceMember.toUpperCase()}][${subsystem}]`;
  }

  log(...args: any[]) {
    console.log(this.prefix, ...args);
  }

  warn(...args: any[]) {
    console.warn(this.prefix, ...args);
  }

  error(...args: any[]) {
    console.error(this.prefix, ...args);
  }

  debug(...args: any[]) {
    console.log(this.prefix, ...args);
  }

  logTiming<T>(id: string, cb: (mark: (...args: any[]) => void) => T): T {
    const label = `${this.prefix} ${id}`;
    console.time(label);
    const res = cb((...args) => console.timeLog(label, ...args));
    console.timeEnd(label);
    return res;
  }

  perfMark(id: string) {
    performance.mark(id);
  }
  perfMeasure(label: string, startMarkId: string, endMarkId: string) {
    performance.measure(label, startMarkId, endMarkId);
  }

  /**
   * Like `error`, but throws an exception asserting unreachability at the same time
   */
  unreachable(...args: any[]): never {
    this.error(...args);
    throw new Error("entered unreachable code section");
  }

  public static getSubsystemName(path: string): Label {
    // First strip extension and basename `index`.
    // Then strip the `src/` prefix
    let subsystem = path.replace(/(\/)?(index)?\.[^/.]+$/, "").replace(/^src\//, "");

    // if we are in another workspace member, extract the folder name (assumes single nesting level)
    let workspaceMember: string | null = null;

    subsystem = subsystem.replace(/^\.\.\/([^/]+)\/(lib|dist)?\//, (_, member) => {
      workspaceMember = member;
      return "";
    });

    // logging in `src/index.tsx` or using directory in `src`. Should be avoided.
    if (subsystem === "" || subsystem === "src") {
      subsystem = "(in entry files)";
    }

    return { workspaceMember, subsystem };
  }

  static fromFilename(filename: string): Logger {
    return new Logger(Logger.getSubsystemName(filename));
  }

  static fromDirname(dirname: string): Logger {
    return new Logger(Logger.getSubsystemName(dirname));
  }
}

import energyForecastReducer from "@/reducer/EnergyForecastState";
import prefetchStateReducer, { setViewportChanges } from "@/reducer/PrefetchState";
import uiReducer from "@/reducer/UIState";

import { fetchTab } from "@/api/hooks/tab";
import countryPlotReducer from "@/reducer/CountryPlotState";
import energyPlotsReducer from "@/reducer/EnergyPlotsState";
import layoutsReducer from "@/reducer/LayoutsState";
import thresholdsReducer from "@/reducer/LocationTableThresholdState";
import locationTableReducer from "@/reducer/LocationTablesState";
import mapsReducer, { setLayersProps, setMapDrawings } from "@/reducer/MapsState";
import noteReducer from "@/reducer/NoteState";
import plotsReducer from "@/reducer/PlotsState";
import profileReducer from "@/reducer/ProfileState";
import tabMetaReducer from "@/reducer/TabsState";
import tephigramReducer from "@/reducer/TephigramState";
import viewportReducer, { setViewport } from "@/reducer/ViewportsState";
import weatherTableReducer from "@/reducer/WeatherTablesState";
import { batchGroupBy } from "@/reducer/batchGroupBy";
import { type ThunkDispatch, configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { type UnknownAction, combineReducers } from "redux";
import undoable, { ActionCreators } from "redux-undo";
import { tabAutoSaveListener } from "./auto-save/subscription";

const customMiddleware = (store: any) => (next: any) => (action: any) => {
  if (action.type === fetchTab.fulfilled.type) {
    // Clear the undo history upon switchin / fetching a new tab.
    // Note calling next(action) before emitting clearing history allows
    // clearing the history AFTER the new tab is loaded to the store.
    const tmp = next(action);
    store.dispatch(ActionCreators.clearHistory());
    return tmp;
  }
  return next(action);
};

export const tabReducer = combineReducers({
  maps: mapsReducer,
  plots: plotsReducer,
  energyPlots: energyPlotsReducer,
  weatherTables: weatherTableReducer,
  locationTables: locationTableReducer,
  locationTableThresholds: thresholdsReducer,
  tephigrams: tephigramReducer,
  countryPlots: countryPlotReducer,
  viewports: viewportReducer,
  notes: noteReducer,
  layouts: layoutsReducer,
  tabMeta: tabMetaReducer,
});

export const rootReducer = combineReducers({
  ui: uiReducer,
  prefetchState: prefetchStateReducer,
  energyForecast: energyForecastReducer,
  profile: profileReducer,
  tabGroup: undoable(tabReducer, {
    limit: 8,
    // Filter out drawing undo, as it anyway doesn't work
    filter: (action) => action.type !== setMapDrawings.type,
    groupBy: batchGroupBy.init([
      setViewport.type,
      setViewportChanges.type,
      setLayersProps.type,
      "prefetchStates/setLayerPropsChanges",
    ]), // TODO this grouping works, but only with a single map
  }),
});

const reduxStore = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(customMiddleware),
});

reduxStore.subscribe(tabAutoSaveListener);

// Expose BUILD_VERSION in JS console for debug purpose.
// This is only for manual check.
const __COMMIT_HASH__ = "change-me"; // TODO: Fix this
(window as any).BUILD_VERSION = __COMMIT_HASH__;

export type RootState = ReturnType<typeof reduxStore.getState>;
export type AppDispatch = ThunkDispatch<RootState, void, UnknownAction>;
export type AppReduxStore = typeof reduxStore;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export default reduxStore;

/**
 * A function to dispatch an action to Redux store outside React components.
 */
export const dispatchToRedux = (action: UnknownAction) => {
  reduxStore.dispatch(action);
};

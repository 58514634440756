import { type ReactNode, createContext, useContext, useState } from "react";

export enum HighlightType {
  MAP = 0,
  PLOT = 1,
  ENERGY_PLOT = 2,
  COUNTRY_PLOT = 3,
  WEATHER_TABLE = 4,
  TEPHIGRAM = 5,
  NOTE = 6,
  LOCATION_TABLE = 7,
}

export type HighlightedCell = {
  type: HighlightType;
  id: number;
};

type HighlightContextType = {
  highlighted?: HighlightedCell;
  setHighlighted: (cell: HighlightedCell | undefined) => void;
};

type GridCellHighlightProviderProps = {
  children?: ReactNode;
};

const HighlightContext = createContext<HighlightContextType>({
  setHighlighted: (_value) => {},
});

const GridCellHighlightProvider = ({ children }: GridCellHighlightProviderProps) => {
  const [highlighted, setHighlighted] = useState<HighlightedCell>();

  return <HighlightContext.Provider value={{ highlighted, setHighlighted }}>{children}</HighlightContext.Provider>;
};

const useActiveGridHighlight = () => useContext(HighlightContext);

export { GridCellHighlightProvider, useActiveGridHighlight };

import { Api } from "@/api/Api";
import { LayoutType, type NoteCreate } from "@mm/metx-workbench.meteomatics.com";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const createNote = createAsyncThunk(
  "api/note/create",
  async (payLoad: { data: NoteCreate; layoutId: number }, _thunkAPI) => {
    return await Api.notes
      .v2CreateNote({
        noteCreate: payLoad.data,
      })
      .then((table) => {
        return { tool: table, layoutType: LayoutType.Notes, layoutId: payLoad.layoutId };
      });
  },
);

import { SentrySpans } from "./SentrySpans";

export function captureTransaction<T>(
  asynchronous: Promise<T>,
  key: string,
  tags?: { [key: string]: string },
): Promise<void> {
  // TODO(sentry): Use `startInactiveSpan()` instead - see https://github.com/getsentry/sentry-javascript/blob/develop/docs/v8-new-performance-apis.md
  SentrySpans.startSpan(key, tags);

  return asynchronous
    .then(() => {
      SentrySpans.endSpan(key);
    })
    .catch((err) => {
      SentrySpans.setTag(key, "error", err.message);
      SentrySpans.endSpan(key);
      throw err;
    });
}

import type { LayerUnion } from "@/layers/LayerUnion";
import type { SceneLayerApi } from "@/layers/SceneLayerApi";
//import { BitField } from "@/utility/bitfield";
import type { ColorMap, EnsSelectIdentifier } from "@mm/api.meteomatics.com";
import type { GuiTimeZone, WmsLayer } from "@mm/metx-workbench.meteomatics.com";
import type { DateTime } from "luxon";

export type AppliedStyle = ColorMap | undefined;

/**
 * Reconciler for a single property of a layer.
 * @return `true` if the value changed.
 */
type CheckerFn<A, B> = (this: A, prev: B, curr: B) => boolean;
type UncheckedLayerProps =
  | Exclude<keyof LayerUnion, "custom_options" | "opacity" | "show" | "calibrated">
  | "show_init_time"
  | "id_profile";
export type CheckedProps<B> = keyof Required<
  Omit<B, B extends WmsLayer ? Exclude<UncheckedLayerProps, "vertical_interpolation"> : UncheckedLayerProps>
>;
/**
 * Collection of reconcilers for a whole layer.
 */
export type PropsChecker<A extends LayerUnion, B extends LayerBase<A>> = Record<CheckedProps<A>, CheckerFn<B, A>>;

// Class and interface merging is completely accepted approach
// and is used inside React so we are safe to use it also there
// Merging is necessary as "releaseLayer" should be optional abstract function
// for fallback support

export interface LayerBase<A extends LayerUnion> {
  // Lifecycle function for cleaning up async tasks
  // that may complete when layer is already destroyed
  releaseLayer?(): void;
}
// biome-ignore lint/suspicious/noUnsafeDeclarationMerging: TODO check
export abstract class LayerBase<A extends LayerUnion> {
  /**
   * Loading state of the layer's data.
   * Note you can directly modify this from the child class
   */
  protected constructor(
    readonly uid: number,
    protected props: A,
    public readonly scene: SceneLayerApi,
    public timezone: GuiTimeZone,
  ) {}

  setLayerProps(props: Partial<A> /*, invalidationType: InvalidationType = InvalidationType.TransferFunction*/) {
    this.props = { ...this.props, ...props };
    //this.invalidate(invalidationType);
  }

  getLayerProps(): A {
    return this.props;
  }

  get zIndex(): number {
    return this.props.index;
  }

  setTimezone(value: GuiTimeZone) {
    this.timezone = value;
  }

  moveZIndex(beforeLayerId?: string) {
    const map = this.scene.getMapboxMap();

    if (map.getLayer(this.mapboxIndex) && beforeLayerId && map.getLayer(beforeLayerId)) {
      map.moveLayer(this.mapboxIndex, beforeLayerId);
    }
  }

  abstract get mapboxIndex(): string;
  /**
   *
   * MUST be overridden if the layer supports zoom level biasing.
   *
   * @returns
   */
  getZoomLevelBias() {
    return 0;
  }

  /**
   * Define the data that is required
   */
  //abstract getActiveDataSubspaces();

  // TODO: this will make sense for all layers as soon as all layers are tiled. It currently does not make
  // sense for non-tiled layers. In this case, just return `[]`.
  abstract getActiveWeatherParametersAsString(): { model: string; parameter: string }[];

  getAppliedStyle(): AppliedStyle {
    return undefined;
  }
  getAppliedEnsSelect(): EnsSelectIdentifier {
    return "";
  }
  abstract checker(): PropsChecker<A, LayerBase<A>>; //: PropsChecker<A, LayerBase<A>>; //TODO:
  // abstract checkChanges(layerDesc: A | undefined): void;

  /**
   * Reconciler integrating a description of the layers state with best-effort minimal changes.
   *
   * @return boolean indicating if anything changed that would require a redraw of the layer.
   */
  checkChanges(layerDesc: A): boolean {
    const checkers = this.checker();

    // Reduce returns "true", if all values are false. We want to check if some call is true, thus we negate the result.
    return !Object.values<CheckerFn<LayerBase<A>, A>>(checkers).reduce(
      (acc, checker) => checker.call(this, this.props, layerDesc),
      false,
    );
  }

  /**
   * Checks if the layer has pending network requests. True if it does.
   * The method needs to be able to check the rebuffering status for the given display time.
   * @param time
   */
  abstract isRebuffering(time: DateTime): boolean;

  /**
   * Automatically invoked before a layer is redrawn.
   */
  abstract beforeRender(): void;

  /**
   * The removeLayer method ensures that all layers and sources are removed from Mapbox in the correct order.
   * Layers must be removed before sources to avoid dependency conflicts.
   * Make sure all layers created by this component are removed, as the scene and compositor will deallocate them.
   * This ensures that reinitializing Mapbox when switching tabs functions correctly.
   * If a source is not removed, recreating it with the same source ID will fail.
   */
  abstract removeLayer(): void;

  // needs to be overwritten by each layer class that needs it
  // for preload
  abstract fetchData(timeFrame: DateTime): Promise<void>;
}

import type { AsyncResult } from "@/cache/AsyncResult";
import { networkCaches } from "@/cache/GlobalCache";
import { emptyGeoJsonSource } from "@/geojson";
import { generateWfsCacheId } from "@/layers/geojson/LayerUtils";
import { MapboxGeoJSONLayer } from "@/layers/geojson/MapboxGeoJSONLayer";
import { safeFmt } from "@/utility/safeFmt";
import {
  Area,
  CoordinateSystem,
  type GeoJSONFeatureCollection,
  WORLD_BBOX,
  type WfsRequest,
} from "@mm/api.meteomatics.com";
import type { GuiTimeZone, TropicalCycloneLayer } from "@mm/metx-workbench.meteomatics.com";
import type { Feature, FeatureCollection, LineString, Position } from "geojson";
import { flatten } from "lodash";
import Logger from "logging";
import type { DateTime } from "luxon";
import type * as Mapbox from "mapbox-gl";
import { type GeoJSONSource, LngLat, Popup } from "mapbox-gl";
import type { ScenePublicApi } from "../Compositor";
import type { CheckedProps, LayerBase, PropsChecker } from "../LayerBase";

const logger = Logger.fromFilename(__filename);
export class TropicalTropicalCycloneLayerImpl extends MapboxGeoJSONLayer<TropicalCycloneLayer> {
  private popup = new Popup({
    closeButton: false,
    closeOnClick: false,
  });

  readonly line_layer_id: string;
  readonly point_layer_id: string;
  readonly point_source: string;

  constructor(id: number, props: TropicalCycloneLayer, scene: ScenePublicApi, timezone: GuiTimeZone) {
    super(id, props, scene, timezone);

    this.point_source = safeFmt`source_points_${this.humanReadableId()}`;
    this.scene.getMapboxMap().addSource(this.point_source, emptyGeoJsonSource());

    // A lines layer is needed to display the lines of the GeoJSON.
    this.line_layer_id = `${this.humanReadableId()}-line`;
    this.point_layer_id = `${this.humanReadableId()}-points`;
    this.scene.getMapboxMap().addLayer({
      id: this.line_layer_id,
      type: "line",
      source: this.mapbox_source,
      layout: {
        visibility: this.props.show ? "visible" : "none",
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": this.props.line_color,
        "line-width": this.props.line_width,
        "line-opacity": this.props.opacity,
      },
    });
    this.scene.getMapboxMap().addLayer({
      id: this.point_layer_id,
      type: "circle",
      source: this.point_source,
      layout: {
        visibility: this.props.show ? "visible" : "none",
      },
      paint: {
        "circle-color": this.props.line_color,
        "circle-stroke-color": "transparent",
        "circle-stroke-width": this.props.line_width + 2,
        "circle-radius": this.props.line_width + 1,
      },
    });

    this.scene.getMapboxMap().on("mouseenter", this.point_layer_id, (e) => {
      if (e.features) {
        this.scene.getMapboxMap().getCanvas().style.cursor = "pointer";

        const {
          features: [feature],
        } = e;
        const description = `<b>Member ${feature.properties?.member}</b> ${feature.properties?.timestamp}`;

        if (feature.geometry.type === "Point") {
          const { coordinates } = feature.geometry;
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }

          this.popup
            .setLngLat(new LngLat(coordinates[0], coordinates[1]))
            .setHTML(description)
            .addTo(this.scene.getMapboxMap());
        }
      }
    });

    this.scene.getMapboxMap().on("mouseleave", this.point_layer_id, () => {
      this.scene.getMapboxMap().getCanvas().style.cursor = "";
      this.popup.remove();
    });
  }

  updateTrigger(): void {
    const dateTimeWithOffset = this.scene.getDisplayTimeWithOffset();
    const request = this.createRequest(/*dateTimeWithOffset*/);
    const result = networkCaches.geojson_cache.retrieveWfs(request);

    this.updateSource(result, dateTimeWithOffset, (data) => {
      const source = this.scene.getMapboxMap().getSource(this.point_source) as GeoJSONSource;
      if (!source) {
        logger.error("Tropical cyclone source not found!");
        return;
      }
      source.setData(this.constructPointMap(data));
    });
  }

  fetchData(_timeFrame: DateTime): Promise<void> {
    // when time related do => const timeDateWithOffset = this.scene.getADateTimeWithOffset(timeFrame);
    return new Promise((resolve) => resolve());
  }

  // We need to construct seperate geojson for points with extracted timestamp in properties, so we can display tooltip
  // Currently there is no other way or I couldn't find one - to get timestamp for position in geoJSON line
  private constructPointMap(data: GeoJSONFeatureCollection): FeatureCollection {
    const featurePointMap = data.features
      // Sometimes feture type is undefined so filter broken features out
      // .filter((feature) => ((feature.geometry as LineString).type))
      .map((feature) => {
        const coords: Position[] = (feature.geometry as LineString).coordinates;
        const timestamps = feature.properties?.timestamps.split(",");
        const memeber = feature.properties?.member;

        return coords.map<Feature>((coord, index) => {
          return {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: coord,
            },
            properties: {
              timestamp: timestamps[index],
              member: memeber,
            },
          };
        });
      });

    return {
      features: flatten(featurePointMap),
      type: "FeatureCollection",
    };
  }

  beforeRender(): void {
    if (this.scene.isAnimation()) {
      this.updateTrigger();
    } else {
      this.debouncedUpdateTrigger();
    }
  }

  humanReadableId(): string {
    return safeFmt`metx.TropicalCycloneLayer#${this.uid}#${this.props.aviation_type}}`;
  }
  getActiveWeatherParametersAsString(): { model: string; parameter: string }[] {
    return [];
  }
  checker(): PropsChecker<TropicalCycloneLayer, LayerBase<TropicalCycloneLayer>> {
    type CheckerProps = CheckedProps<TropicalCycloneLayer>;
    const checkLayerProp = (property: CheckerProps) => (prev: any, curr: any) => {
      const changed = prev[property] !== curr[property];
      if (changed) {
        this.setLayerProps({ [property]: curr[property] });
        switch (property) {
          case "calibrated":
          case "aviation_type":
            break;
          case "show":
            this.updateMapboxLayoutProperties({ visibility: curr.show ? "visible" : "none" });
            break;
          case "opacity":
            this.updateMapboxLinePaintProperties({
              "line-opacity": curr[property],
            });
            this.updateMapboxCirclePaintProperties({
              "circle-opacity": curr[property],
            });
            break;
          case "line_width":
            this.updateMapboxLinePaintProperties({
              "line-width": curr[property],
            });
            this.updateMapboxCirclePaintProperties({
              "circle-radius": curr[property] + 1,
            });
            break;
          case "line_color":
            this.updateMapboxLinePaintProperties({
              "line-color": curr[property],
            });
            this.updateMapboxCirclePaintProperties({
              "circle-color": curr[property],
            });
            break;
          case "custom_options":
            break;
          default: {
            const _exhaustive: never = property;
            return _exhaustive;
          }
        }
      }
      return changed;
    };

    return {
      opacity: checkLayerProp("opacity"),
      show: checkLayerProp("show"),
      aviation_type: checkLayerProp("aviation_type"),
      calibrated: checkLayerProp("calibrated"),
      line_width: checkLayerProp("line_width"),
      line_color: checkLayerProp("line_color"),
      custom_options: checkLayerProp("custom_options"),
    };
  }

  moveZIndex(beforeLayerId?: string) {
    const map = this.scene.getMapboxMap();
    map.moveLayer(this.mapbox_id, beforeLayerId);
    map.moveLayer(this.line_layer_id, beforeLayerId);
    map.moveLayer(this.point_layer_id, beforeLayerId);
  }

  removeLayer(): void {
    this.scene.getMapboxMap().removeLayer(this.line_layer_id);
    this.scene.getMapboxMap().removeLayer(this.point_layer_id);
    this.scene.getMapboxMap().removeSource(this.point_source);
    super.removeLayer();
  }

  createRequest(): WfsRequest<CoordinateSystem.WGS84, "GetFeature"> {
    const area = new Area(CoordinateSystem.WGS84, {
      east: 180,
      west: -180,
      north: 90,
      south: -90,
    });

    return {
      requestType: "GetFeature",
      area,
      model: this.props.aviation_type,
      boundingBoxLimit: WORLD_BBOX,
    };
  }

  protected updateMapboxLineLayoutProperties(props: Mapbox.LineLayout) {
    for (const entry of Object.entries(props)) {
      this.scene.getMapboxMap().setLayoutProperty(this.line_layer_id, entry[0], entry[1]);
    }
  }
  protected updateMapboxLinePaintProperties(props: Mapbox.LinePaint) {
    for (const entry of Object.entries(props)) {
      this.scene.getMapboxMap().setPaintProperty(this.line_layer_id, entry[0], entry[1]);
    }
  }

  protected updateMapboxCirclePaintProperties(props: Mapbox.CirclePaint) {
    for (const entry of Object.entries(props)) {
      this.scene.getMapboxMap().setPaintProperty(this.point_layer_id, entry[0], entry[1]);
    }
  }

  protected updateMapboxLayoutProperties(props: Mapbox.SymbolLayout) {
    for (const entry of Object.entries(props)) {
      this.scene.getMapboxMap().setLayoutProperty(this.mapbox_id, entry[0], entry[1]);
      this.scene.getMapboxMap().setLayoutProperty(this.line_layer_id, entry[0], entry[1]);
      this.scene.getMapboxMap().setLayoutProperty(this.point_layer_id, entry[0], entry[1]);
    }
  }

  peekLayerCache(request: WfsRequest<CoordinateSystem.WGS84>): AsyncResult<GeoJSONFeatureCollection> {
    const cacheId = generateWfsCacheId(request);
    return networkCaches.geojson_cache.peekCache(cacheId);
  }
}

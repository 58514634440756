import Logger from "logging";

const logger = Logger.fromFilename(__filename);

export function createDirectionParameterName(firstParam: string): string {
  // TODO: Should find all combination, dir and direction should be possible always but it isn't!!
  if (firstParam.includes("wind_speed")) {
    // Interval wind speed (e.g: wind_speed_mean_6m_1h:d) converts to an interval wind direction parameter `wind_dir_mean_6m_1h:d`
    // Instantanious wind speed (e.g: wind_speed_6m:d) converts to an instantanious wind direction parameter `wind_dir_6m:d`
    return firstParam.replace("speed", "dir").replace(":kn", ":d");
  }
  if (firstParam.includes("ocean_current_speed")) {
    return firstParam.replace("speed", "direction").replace(":kn", ":d");
  }
  if (firstParam.includes("wind_gusts")) {
    // TODO: match interval 1h, 3h
    return firstParam.replace("wind_gusts_", "wind_dir_mean_").replace(":kn", ":d");
  }
  if (firstParam.includes("stokes_drift_speed")) {
    return firstParam.replace("speed", "dir").replace(":kn", ":d");
  }

  logger.error(`Could not create the direction parameter name of ${firstParam}`);
  return "";
}

import { type AsyncResult, SynchrounousState } from "@/cache/AsyncResult";
import { networkCaches } from "@/cache/GlobalCache";
import { emptyFeatureCollection, emptyGeoJsonSource } from "@/geojson";
import { type CheckedProps, LayerBase, type PropsChecker } from "@/layers";
import { ChangeDetector } from "@/layers/ChangeDetector";
import type { SceneLayerApi } from "@/layers/SceneLayerApi";
import {
  airepColor,
  airepIconDirExpression,
  airepIconExpression,
  airepLabel,
  coverIcon,
  fillColorOpacity,
  fltCatColor,
  gustsWindIconFromProperties,
  iSigmetFillColorStyle,
  iSigmetLabel,
  sigmetFillColorStyle,
  sigmetLabel,
  visibInMetric,
  windIconFromProperties,
  wxIconExpression,
} from "@/layers/utility/aviationStyle";
import type { AviationTypes, AviationTypesUnion } from "@/layers/utility/aviationType";
import {
  allAviationProperties,
  createMapboxSymbolLayerIcon,
  createMapboxSymbolLayerText,
} from "@/layers/utility/aviationValues";
import { getMapboxIconSize } from "@/layers/utility/iconSize";
import { safeFmt } from "@/utility/safeFmt";
import { Area, CoordinateSystem, type GeoJSONFeatureCollection, MeteomaticsApiUrl } from "@mm/api.meteomatics.com";
import type { AviationLayer, GuiTimeZone } from "@mm/metx-workbench.meteomatics.com";
import type { Geometry } from "geojson";
import { debounce } from "lodash";
import Logger from "logging";
import type { DateTime } from "luxon";
import type * as Mapbox from "mapbox-gl";
import mapboxgl, { type GeoJSONSource, type GeoJSONSourceRaw, type SymbolLayer } from "mapbox-gl";

const logger = Logger.fromFilename(__filename);

export class AviationLayerImpl extends LayerBase<AviationLayer> {
  // There is no actual officially available typing from Mapbox
  private _layerSourceCache: any;

  private popup = new mapboxgl.Popup();
  private selectedFeature: mapboxgl.MapboxGeoJSONFeature | null = null;
  private mapbox_id: string;
  private mapbox_source: string;

  private layerIdList: string[] = [];
  private createUrl: (time: DateTime, bbox: string, zoom: number) => string = () => "";
  private fixedBbox?: string = "-180,-90,180,90";
  private prevCursorStyle = "unset";

  debouncedUpdateTrigger = debounce(() => {
    this.updateTrigger();
  }, 400);

  private timeChanged: ChangeDetector<DateTime> = new ChangeDetector<DateTime>();
  private geoJsonData: ChangeDetector<GeoJSONFeatureCollection | SynchrounousState> =
    new ChangeDetector<GeoJSONFeatureCollection>();
  private layerIdWithScaleIcon: { [value: string]: string } = {};

  constructor(id: number, props: AviationLayer, scene: SceneLayerApi, timezone: GuiTimeZone) {
    super(id, props, scene, timezone);
    this.mapbox_id = this.humanReadableId();
    this.mapbox_source = safeFmt`source_${this.humanReadableId()}`;

    this.addPlaceholderLayer();
    // Add an empty source.
    this.scene.getMapboxMap().addSource(this.mapbox_source, emptyGeoJsonSource());
    this.createLayersForAviationType(this.props.aviation_type as AviationTypes, this.props.text_size);
  }

  humanReadableId(): string {
    return safeFmt`metx.aviationlayer#${this.uid}#${this.props.aviation_type}`;
  }

  createRequest(dateTimeWithOffset: DateTime, fixBbox?: string) {
    if (!fixBbox) {
      const map = this.scene.getMapboxMap();
      const bounds = map.getBounds();
      const zoomLevel = map.getZoom();

      const area = new Area(CoordinateSystem.WGS84, {
        east: bounds.getEast(),
        west: bounds.getWest(),
        north: bounds.getNorth(),
        south: bounds.getSouth(),
      });
      const bbox = MeteomaticsApiUrl.formatAreaForWfs(area);
      return this.createUrl(dateTimeWithOffset, bbox, zoomLevel);
    }

    return this.createUrl(dateTimeWithOffset, fixBbox, 0);
  }

  fetchData(timeFrame: DateTime): Promise<void> {
    const dateTimeWithOffset = this.scene.getDateTimeWithOffset(timeFrame);
    return networkCaches.geojson_cache
      .retrieveGeoJson(this.createRequest(dateTimeWithOffset, this.fixedBbox), true)
      .asynchronous.then((data) => {});
  }

  updateTrigger() {
    const dateTimeWithOffset = this.scene.getDisplayTimeWithOffset();
    const result = networkCaches.geojson_cache.retrieveGeoJson(
      this.createRequest(dateTimeWithOffset, this.fixedBbox),
      true,
    );
    this.updateSource(result, dateTimeWithOffset);
  }

  beforeRender(): void {
    if (this.scene.isAnimation()) {
      this.updateTrigger();
    } else {
      this.debouncedUpdateTrigger();
    }
  }

  checker(): PropsChecker<AviationLayer, LayerBase<AviationLayer>> {
    type CheckerProps = CheckedProps<AviationLayer>;
    const checkLayerProp = (property: CheckerProps) => (prev: any, curr: any) => {
      const changed = prev[property] !== curr[property];
      if (changed) {
        this.setLayerProps({ [property]: curr[property] });
        switch (property) {
          case "aviation_type":
            break;
          case "show":
            this.updateAllMapboxLayoutProperties({ visibility: curr.show ? "visible" : "none" });
            break;
          case "opacity":
            // not implement in Layerconfig
            this.updateAllMapboxPaintProperties({
              "icon-opacity": curr.opacity,
            });
            break;
          case "text_size": {
            // identify layer with icons for sizing
            const iconScale = allAviationProperties[this.props.aviation_type as AviationTypesUnion].iconScale;
            if (iconScale) {
              const scaledIcons = Object.keys(iconScale);
              for (const layerId of this.layerIdList) {
                if (this.scene.getMapboxMap().getLayer(layerId)) {
                  if (scaledIcons.some((iconName) => layerId.includes(iconName))) {
                    for (const iconName of scaledIcons) {
                      if (layerId.includes(iconName)) {
                        if (iconScale[iconName] !== undefined) {
                          const newValue = getMapboxIconSize(iconScale[iconName], curr[property]);
                          this.updateMapboxLayoutProperties(layerId, { "icon-size": newValue });
                        }
                      }
                    }
                  } else {
                    this.updateMapboxLayoutProperties(layerId, { "icon-size": curr[property] });
                  }
                }
              }
              for (const iconName of scaledIcons) {
                const layerId = this.layerIdWithScaleIcon[iconName];
                if (layerId) {
                  if (this.scene.getMapboxMap().getLayer(layerId)) {
                    if (iconScale[iconName] !== undefined) {
                      const newValue = getMapboxIconSize(iconScale[iconName], curr[property]);
                      this.updateMapboxLayoutProperties(layerId, { "icon-size": newValue });
                    }
                  }
                }
              }

              this.updateAllMapboxLayoutProperties({
                "text-size": curr[property],
              });
            } else {
              this.updateAllMapboxLayoutProperties({
                "text-size": curr[property],
              });
              this.updateAllMapboxLayoutProperties({
                "icon-size": curr[property],
              });
            }
            break;
          }
          case "calibrated":
          case "custom_options":
            break;
          default: {
            const _exhaustive: never = property;
            return _exhaustive;
          }
        }
      }
      return changed;
    };
    return {
      text_size: checkLayerProp("text_size"),
      aviation_type: checkLayerProp("aviation_type"),
      calibrated: checkLayerProp("calibrated"),
      opacity: checkLayerProp("opacity"),
      show: checkLayerProp("show"),
      custom_options: checkLayerProp("custom_options"),
    };
  }

  setEventListeners(
    idLayer: string,
    geometryType: Geometry["type"],
    poupTextCall: (feature: mapboxgl.MapboxGeoJSONFeature) => string,
  ) {
    const mapBoxMap = this.scene.getMapboxMap();

    function createPopupHTML(feature: mapboxgl.MapboxGeoJSONFeature) {
      const featureProperties = feature.properties;
      if (feature.geometry.type === geometryType && featureProperties) {
        return `
            <div class="station-meta" style="padding-right:0.3rem; padding-left:10px">
            ${poupTextCall(feature)}
            </div>
          `;
      }
      return "";
    }

    // Add a popup window on click.
    mapBoxMap.on("click", idLayer, (e) => {
      // TODO-StationFeature:
      // Replace the popup here and migrate it to PlotWindow. Instead, we can dispatch an action here
      // to Redux store to store the clicked station. Then, PlotWindow can react to it, which, in turn, fetches the station data.
      if (!e.features) {
        return;
      }
      const feature = e.features[0];
      this.selectedFeature = feature;
      const popupHTML = createPopupHTML(feature);

      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      if (feature.geometry.type === geometryType) {
        this.popup.setLngLat(e.lngLat).setHTML(popupHTML).addTo(mapBoxMap).setMaxWidth("300px");
        return;
      }
      logger.error(
        "The clicked station feature did not contain 'Point' type geometry, instead contained:",
        feature.geometry.type,
        feature,
      );
    });

    // Update the popup content on every source update.
    mapBoxMap.on("sourcedata", () => {
      // TODO-StationFeature:
      // For now, we close the popup on data source update, but once we migrate the popup to PlotWindow,
      // we can just dispatch an action here to fire data update on the plot. We probably don't need to send station data from here,
      // but just to signal the update because station ID should already be in Redux.
      this.popup.remove();
      // e.g)
      // dispatchToRedux({type: "Test Action">})
    });

    mapBoxMap.on("mouseenter", idLayer, () => {
      this.prevCursorStyle = mapBoxMap.getCanvas().style.cursor;
      mapBoxMap.getCanvas().style.cursor = "pointer";
    });

    // Change it back to a pointer when it leaves.
    mapBoxMap.on("mouseleave", idLayer, () => {
      // Setting back to the previous cursor allows other module to modify temporal cursor
      // e.g: POI map select feature
      mapBoxMap.getCanvas().style.cursor = this.prevCursorStyle;
    });
  }

  getActiveWeatherParametersAsString(): { model: string; parameter: string }[] {
    return [];
  }

  isRebuffering(time: DateTime): boolean {
    if (this.scene.isAnimation()) {
      const dateTimeWithOffset = this.scene.getDateTimeWithOffset(time);
      const { synchronous } = networkCaches.geojson_cache.retrieveGeoJson(
        this.createRequest(dateTimeWithOffset, this.fixedBbox),
        true,
      );
      return synchronous === SynchrounousState.StillPending;
    }
    return false;
  }

  get mapboxIndex(): string {
    return this.mapbox_id;
  }

  private getPlaceholderId(): string {
    return `metx.aviationlayer#placeholder#${this.uid}`;
  }

  private addPlaceholderLayer() {
    if (this.scene.getMapboxMap().getLayer(this.getPlaceholderId())) {
      return;
    }
    const source: GeoJSONSourceRaw = {
      type: "geojson",
      data: emptyFeatureCollection,
    };
    const placeholderLayer: SymbolLayer = {
      id: this.getPlaceholderId(),
      type: "symbol",
      source: source,
    };
    this.scene.getMapboxMap().addLayer(placeholderLayer);
  }

  private removePlaceholderLayer() {
    this.scene.getMapboxMap().removeLayer(this.getPlaceholderId());
    this.scene.getMapboxMap().removeSource(this.getPlaceholderId());
  }

  removeLayer(): void {
    this.removePlaceholderLayer();
    for (const layerId of this.layerIdList) {
      this.scene.getMapboxMap().removeLayer(layerId);
    }
    this.layerIdList = [];
    this.layerIdWithScaleIcon = {};
    this.scene.getMapboxMap().removeSource(this.mapbox_source);
  }

  moveZIndex(beforeLayerId?: string) {
    const map = this.scene.getMapboxMap();
    map.moveLayer(this.getPlaceholderId(), beforeLayerId);
    for (const layerId of this.layerIdList) {
      if (map.getLayer(layerId)) {
        map.moveLayer(layerId, this.getPlaceholderId());
      }
    }
  }

  /**
   * @param result Async GeoJSONFeatureCollection to pass as a MapBox source.
   * @param time The time at which the map is showing.
   * @returns
   */
  protected updateSource(result: AsyncResult<GeoJSONFeatureCollection>, dateTimeWithOffset: DateTime) {
    // Note: Longitude values will be greater than 180 deg when an International SIGMET crosses the International Date Line. Subtract 360 deg from this value to get the true longitude value.
    const { synchronous: data, asynchronous } = result;
    if (data === SynchrounousState.StillPending) {
      // Trigger repaint once the data is available.
      // We do not want to update the mapbox source data with the result here, because the timestamp/viewport
      // might have changed.
      asynchronous
        .then(() => {
          this.beforeRender();
        })
        .catch(() => {
          this.beforeRender();
        });
    }
    const timeChanged = this.timeChanged.changed(dateTimeWithOffset);
    if (this.geoJsonData.changed(data)) {
      if (data === SynchrounousState.NotRequested) {
        return;
      }
      if (data === SynchrounousState.StillPending) {
        if (timeChanged) {
          // Only empty the viewport if the time has changed.
          this.emptyMapboxSource();
        }
        return;
      }
      if (data === SynchrounousState.PermanentlyFailed) {
        // Nothing to do here.
        this.emptyMapboxSource();
        return;
      }
      // Update data.
      this.updateMapboxSource(data);
      return;
    }
    return;
  }

  updateMapboxPaintProperties(layerId: string, props: Mapbox.SymbolPaint) {
    if (this.scene.getMapboxMap().getLayer(layerId)) {
      for (const entry of Object.entries(props)) {
        try {
          this.scene.getMapboxMap().setPaintProperty(this.mapbox_id, entry[0], entry[1]);
        } catch (e) {
          console.warn(e);
        }
      }
    }
  }

  updateAllMapboxPaintProperties(props: Mapbox.SymbolPaint) {
    for (const layerId of this.layerIdList) {
      this.updateMapboxPaintProperties(layerId, props);
    }
  }

  updateMapboxLayoutProperties(layerId: string, props: Mapbox.SymbolLayout) {
    if (this.scene.getMapboxMap().getLayer(layerId)) {
      for (const entry of Object.entries(props)) {
        try {
          this.scene.getMapboxMap().setLayoutProperty(layerId, entry[0], entry[1]);
        } catch (e) {
          console.warn(e);
        }
      }
    }
  }

  updateAllMapboxLayoutProperties(props: Mapbox.SymbolLayout) {
    for (const layerId of this.layerIdList) {
      this.updateMapboxLayoutProperties(layerId, props);
    }
  }

  /**
   * Update the associated mapbox source.
   * @param data
   * @private
   */
  private updateMapboxSource(data: GeoJSON.FeatureCollection<GeoJSON.Geometry>) {
    const source = this.scene.getMapboxMap().getSource(this.mapbox_source) as GeoJSONSource;
    if (!source) {
      logger.error("Aviation layer source not found!");
      return;
    }
    source.setData(data);
  }

  /**
   * Helper function to create an empty mapbox source.
   * @private
   */
  private emptyMapboxSource() {
    this.updateMapboxSource(emptyFeatureCollection);
  }

  private createAviationLayerId(aviationType: AviationTypesUnion, layerWithScaleIcon = "", isMainId = false): string {
    if (isMainId) {
      // every group of layer need one main layer with this id
      this.layerIdList.push(this.mapbox_id);
      this.layerIdWithScaleIcon[layerWithScaleIcon] = this.mapbox_id;
      return this.mapbox_id;
    }
    const id = this.mapbox_id + aviationType + layerWithScaleIcon + this.layerIdList.length;
    this.layerIdList.push(id);
    return id;
  }

  /**
   * Compose multiple layers to display each aviation data type.
   *
   * for individuell icon scale we set a special naming in the layer id
   * to scale the right icon-image also in the checker function
   *
   *
   * @param aviationType
   * @param textSize
   */
  private createLayersForAviationType(aviationType: AviationTypesUnion, textSize: number) {
    const mapboxStyles = allAviationProperties[aviationType];
    this.createUrl = mapboxStyles.createUrl;
    this.fixedBbox = mapboxStyles.fixedBbox;

    switch (aviationType) {
      case "isigmet":
        this.scene.getMapboxMap().addLayer(
          {
            id: this.createAviationLayerId(aviationType, "", true),
            type: "fill",
            source: this.mapbox_source,
            layout: {},
            paint: {
              "fill-color": iSigmetFillColorStyle,
              "fill-opacity": fillColorOpacity,
            },
          },
          this.getPlaceholderId(),
        );
        this.scene.getMapboxMap().addLayer(
          {
            id: this.createAviationLayerId(aviationType),
            type: "line",
            source: this.mapbox_source,
            layout: {},
            paint: {
              "line-color": iSigmetFillColorStyle,
              "line-width": 4,
            },
          },
          this.getPlaceholderId(),
        );
        this.scene.getMapboxMap().addLayer(
          {
            id: this.createAviationLayerId(aviationType),
            type: "symbol",
            source: this.mapbox_source,
            layout: {
              "text-field": iSigmetLabel,
            },
            paint: {},
          },
          this.getPlaceholderId(),
        );
        this.setEventListeners(this.mapbox_id, "Polygon", mapboxStyles.mapboxStyleOptions.popupText);
        break;
      case "sigmet":
        this.scene.getMapboxMap().addLayer(
          {
            id: this.createAviationLayerId(aviationType, "", true),
            type: "fill",
            source: this.mapbox_source,
            layout: {},
            paint: {
              "fill-color": sigmetFillColorStyle,
              "fill-opacity": fillColorOpacity,
            },
          },
          this.getPlaceholderId(),
        );
        this.scene.getMapboxMap().addLayer(
          {
            id: this.createAviationLayerId(aviationType),
            type: "line",
            source: this.mapbox_source,
            layout: {},
            paint: {
              "line-color": sigmetFillColorStyle,
              "line-width": 4,
            },
          },
          this.getPlaceholderId(),
        );
        this.scene.getMapboxMap().addLayer(
          {
            id: this.createAviationLayerId(aviationType),
            type: "symbol",
            source: this.mapbox_source,
            layout: {
              "text-field": sigmetLabel,
            },
            paint: {},
          },
          this.getPlaceholderId(),
        );
        this.setEventListeners(this.mapbox_id, "Polygon", mapboxStyles.mapboxStyleOptions.popupText);
        break;
      case "taf":
        // gusts
        this.scene
          .getMapboxMap()
          .addLayer(
            createMapboxSymbolLayerIcon(
              this.createAviationLayerId(aviationType, "wind__icon"),
              this.mapbox_source,
              gustsWindIconFromProperties,
              ["get", "wdir"],
              getMapboxIconSize(mapboxStyles.iconScale.wind__icon, textSize),
              [0, 0],
              { "icon-color": "#ff0000" },
            ),
            this.getPlaceholderId(),
          );
        // wind
        this.scene
          .getMapboxMap()
          .addLayer(
            createMapboxSymbolLayerIcon(
              this.createAviationLayerId(aviationType, "wind__icon", true),
              this.mapbox_source,
              windIconFromProperties,
              ["get", "wdir"],
              getMapboxIconSize(mapboxStyles.iconScale.wind__icon, textSize),
              [-3, 0],
            ),
            this.getPlaceholderId(),
          );

        // Center Cloud
        this.scene
          .getMapboxMap()
          .addLayer(
            createMapboxSymbolLayerIcon(
              this.createAviationLayerId(aviationType, "cloud__icon"),
              this.mapbox_source,
              coverIcon,
              0,
              getMapboxIconSize(mapboxStyles.iconScale.cloud__icon, textSize),
              [0, 0],
              { "icon-color": fltCatColor, "icon-opacity": 1 },
            ),
            this.getPlaceholderId(),
          );

        // WX TODO: check with data
        this.scene.getMapboxMap().addLayer(
          createMapboxSymbolLayerIcon(
            this.createAviationLayerId(aviationType, "wx__icon"),
            this.mapbox_source,
            wxIconExpression,
            0,
            getMapboxIconSize(mapboxStyles.iconScale.wx__icon, textSize),
            [0, 0],
            {
              "icon-color": "#ff0000",
              "icon-translate": [-20, 0],
            },
          ),
          this.getPlaceholderId(),
        );

        // Vis
        this.scene
          .getMapboxMap()
          .addLayer(
            createMapboxSymbolLayerText(
              this.createAviationLayerId(aviationType),
              this.mapbox_source,
              visibInMetric,
              [-2, 0],
              {},
              textSize,
            ),
            this.getPlaceholderId(),
          );

        // Ceil
        this.scene
          .getMapboxMap()
          .addLayer(
            createMapboxSymbolLayerText(
              this.createAviationLayerId(aviationType),
              this.mapbox_source,
              ["get", "ceil"],
              [1, 0],
              {},
              textSize,
            ),
            this.getPlaceholderId(),
          );

        // Id
        this.scene
          .getMapboxMap()
          .addLayer(
            createMapboxSymbolLayerText(
              this.createAviationLayerId(aviationType),
              this.mapbox_source,
              ["get", "id"],
              [1, 1],
              {},
              textSize,
            ),
            this.getPlaceholderId(),
          );

        this.setEventListeners(this.mapbox_id, "Point", mapboxStyles.mapboxStyleOptions.popupText);
        break;
      case "airep":
        // Icon
        this.scene.getMapboxMap().addLayer(
          createMapboxSymbolLayerIcon(
            this.createAviationLayerId(aviationType, "wx__icon", true),
            this.mapbox_source,
            airepIconExpression,
            airepIconDirExpression,
            getMapboxIconSize(mapboxStyles.iconScale.wx__icon, textSize),
            [0, 0],
            {
              "icon-color": airepColor,
            },
          ),
          this.getPlaceholderId(),
        );

        // FL
        this.scene.getMapboxMap().addLayer(
          createMapboxSymbolLayerText(
            this.createAviationLayerId(aviationType),
            this.mapbox_source,
            airepLabel,
            [0, 1],
            {
              "text-color": airepColor,
            },
            textSize,
          ),
          this.getPlaceholderId(),
        );

        this.setEventListeners(this.mapbox_id, "Point", mapboxStyles.mapboxStyleOptions.popupText);
        break;
      case "metar":
        // gusts
        this.scene.getMapboxMap().addLayer(
          createMapboxSymbolLayerIcon(
            this.createAviationLayerId(aviationType, "wind__icon"),
            this.mapbox_source,
            gustsWindIconFromProperties,
            ["get", "wdir"],
            getMapboxIconSize(mapboxStyles.iconScale.wind__icon, textSize),
            [0, 0],
            {
              "icon-color": "#ff0000",
            },
          ),
          this.getPlaceholderId(),
        );
        // wind
        this.scene
          .getMapboxMap()
          .addLayer(
            createMapboxSymbolLayerIcon(
              this.createAviationLayerId(aviationType, "wind__icon", true),
              this.mapbox_source,
              windIconFromProperties,
              ["get", "wdir"],
              getMapboxIconSize(mapboxStyles.iconScale.wind__icon, textSize),
              [0, 0],
            ),
            this.getPlaceholderId(),
          );

        // Center Cloud
        this.scene
          .getMapboxMap()
          .addLayer(
            createMapboxSymbolLayerIcon(
              this.createAviationLayerId(aviationType, "cloud__icon"),
              this.mapbox_source,
              coverIcon,
              0,
              getMapboxIconSize(mapboxStyles.iconScale.cloud__icon, textSize),
              [0, 0],
              { "icon-color": fltCatColor, "icon-opacity": 1 },
            ),
            this.getPlaceholderId(),
          );

        // DP TODO: check with data
        this.scene.getMapboxMap().addLayer(
          createMapboxSymbolLayerText(
            this.createAviationLayerId(aviationType),
            this.mapbox_source,
            ["get", "dewp"],
            [-1, 1],
            {
              "text-color": "#0000ff",
            },
            textSize,
          ),
          this.getPlaceholderId(),
        );
        // WX TODO: check with data
        this.scene.getMapboxMap().addLayer(
          createMapboxSymbolLayerIcon(
            this.createAviationLayerId(aviationType, "wx__icon"),
            this.mapbox_source,
            wxIconExpression,
            0,
            getMapboxIconSize(mapboxStyles.iconScale.wx__icon, textSize),
            [0, 0], // does not work here see icon-translate
            {
              "icon-color": "#ff0000",
              "icon-translate": [-20, 0], // TODO: iconScale.wx__icon -20px are here
            },
          ),
          this.getPlaceholderId(),
        );
        // if( this.metric )
        //   return Math.round(value);
        // else
        //   return Math.round(value*9/5+32);

        // temp TODO: check with data
        this.scene
          .getMapboxMap()
          .addLayer(
            createMapboxSymbolLayerText(
              this.createAviationLayerId(aviationType),
              this.mapbox_source,
              ["get", "temp"],
              [-1, -1],
              {},
              textSize,
            ),
            this.getPlaceholderId(),
          );

        // Vis
        this.scene
          .getMapboxMap()
          .addLayer(
            createMapboxSymbolLayerText(
              this.createAviationLayerId(aviationType),
              this.mapbox_source,
              visibInMetric,
              [-2, 0],
              {},
              textSize,
            ),
            this.getPlaceholderId(),
          );

        //ALTM  TODO: check with data
        this.scene
          .getMapboxMap()
          .addLayer(
            createMapboxSymbolLayerText(
              this.createAviationLayerId(aviationType),
              this.mapbox_source,
              ["get", "altim"],
              [2, -1],
              {},
              textSize,
            ),
            this.getPlaceholderId(),
          );
        // "outAltim":
        // function(altim){
        //   if( altim == undefined || altim <= -990)
        //     return "-";
        //   if( this.metric )
        //     altim = Math.round(altim*10)%1000;
        //   else
        //     altim = Math.round(altim*2992/1013.25)%1000;
        //   altim = altim.toString();
        //   if( altim.length == 1 ) altim = '00'+altim;
        //   else if( altim.length == 2 ) altim = '0'+altim;
        //   return altim;
        // },
        //   "outAltimUnits":
        // function(){
        //   if( this.metric )
        //     return "hPa";
        //   else
        //     return "inHg";
        // },

        //CIG
        this.scene
          .getMapboxMap()
          .addLayer(
            createMapboxSymbolLayerText(
              this.createAviationLayerId(aviationType),
              this.mapbox_source,
              ["get", "ceil"],
              [2, 0],
              {},
              textSize,
            ),
            this.getPlaceholderId(),
          );

        // Id
        this.scene
          .getMapboxMap()
          .addLayer(
            createMapboxSymbolLayerText(
              this.createAviationLayerId(aviationType),
              this.mapbox_source,
              ["get", "id"],
              [2, 1],
              {},
              textSize,
            ),
            this.getPlaceholderId(),
          );

        this.setEventListeners(this.mapbox_id, "Point", mapboxStyles.mapboxStyleOptions.popupText);
        break;
      default: {
        this.createUrl = () => "";
        const _exhaustive: never = aviationType;
        logger.error("No Layer for this AvationType prepaired!!");
        return _exhaustive;
      }
    }

    this.updateSourceCache();
  }

  private updateSourceCache() {
    // Cache newly created source cache, to get access to tiles dependencies
    const map = this.scene.getMapboxMap();
    const layer = map.getLayer(this.mapbox_id);
    this._layerSourceCache = map.style._getLayerSourceCache(layer);
  }
}

import { getSessionStorage, setSessionStorage } from "@/persist/SessionObjectStorage";
import _ from "lodash";

export enum SentryLogMode {
  E2E = "E2E",
  MET_TEAM_USERS = "MET_TEAM_USERS",
}

// Note: We use Partial type because none of the attributes in local storage can be guanrateeded to exist.
export type SentryLocalConfig = Partial<{
  log_mode: string | null;
}>;

const defaultSentryLocalConfig: Required<SentryLocalConfig> = {
  log_mode: null,
};

/**
 * Utility to set and get all Sentry related runtime configurations.
 * We keep all the runtime parameters that affect the behavior of Sentry in session storage.
 *
 * This also allows an external browser runner (e.g: Cypress) to dynamically change the sentry behavior.
 */

export const setSentryLocalStorage = (config: SentryLocalConfig, partialUpdate = true) => {
  setSessionStorage("sentry", config, partialUpdate);
};

export const getSentryLocalStorage = (): Required<SentryLocalConfig> => {
  const localObj = getSessionStorage<SentryLocalConfig>("sentry");
  // If local storage object have missing attributes, replace them by default.
  return _.extend(defaultSentryLocalConfig, localObj);
};

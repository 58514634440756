import { DateTime } from "luxon";

export interface MetarsRequest {
  icao: string;
  startDateTime: DateTime;
  endDateTime: DateTime;
}

export interface MetarsRequest_Transferable {
  icao: string;
  startDateTime: string;
  endDateTime: string;
}

export function MetarsRequest_toTransferable({icao, startDateTime, endDateTime}: MetarsRequest): MetarsRequest_Transferable {
  return {
    icao,
    startDateTime: startDateTime.toISO(),
    endDateTime: endDateTime.toISO(),
  }
}

export function MetarsRequest_fromTransferable({ icao, startDateTime, endDateTime }: MetarsRequest_Transferable) {
  return {
    icao,
    startDateTime: DateTime.fromISO(startDateTime),
    endDateTime: DateTime.fromISO(endDateTime),
  }
}

import { CoordinateSystem } from "./CoordinateSystem";
import { DateTime, Duration } from "luxon";
import {
  DateTime_fromTransferable,
  DateTime_toTransferable,
  DateTime_Transferable,
  Duration_fromTransferable,
  Duration_toTransferable,
  Duration_Transferable,
} from "./DateTime";
import { GridDimension } from "./GridDimension";
import { SinglePointCoordinate, SinglePointCoordinate_Transferable } from "./SinglePointCoordinate";
import { ApiRequestOptions } from "./ApiRequestOptions";
import { BaseRequest, MultiParamRequest, MultiParamRequest_Transferable } from "./BaseRequest";
import { Area } from "./Area";

export interface PointRequest<C extends CoordinateSystem> extends GridDimension, BaseRequest, MultiParamRequest {
  coordinates: [...SinglePointCoordinate<C>[]];
  startDatetime: DateTime;
  duration?: Duration;
  temporalResolution?: Duration;
  requestOptions?: ApiRequestOptions;
}

export interface PointRequest_Transferable<C extends CoordinateSystem>
  extends GridDimension,
    MultiParamRequest_Transferable {
  coordinates: [...SinglePointCoordinate_Transferable<C>[]];
  startDatetime: DateTime_Transferable;
  duration?: Duration_Transferable;
  temporalResolution?: Duration_Transferable;
  requestOptions?: ApiRequestOptions;
}

export function PointRequest_toTransferable<C extends CoordinateSystem>(
  req: PointRequest<C>,
): PointRequest_Transferable<C> {
  return {
    ...req,
    coordinates: req.coordinates.map((coords) => coords.toTransferable()),
    startDatetime: DateTime_toTransferable(req.startDatetime),
    duration: req.duration ? Duration_toTransferable(req.duration) : undefined,
    temporalResolution: req.temporalResolution ? Duration_toTransferable(req.temporalResolution) : undefined,
    boundingBoxLimit: req.boundingBoxLimit ? req.boundingBoxLimit.toTransferable() : null,
  };
}

export function PointRequest_fromTransferable<C extends CoordinateSystem>(
  req: PointRequest_Transferable<C>,
): PointRequest<C> {
  return {
    ...req,
    coordinates: req.coordinates.map((coords) =>
      SinglePointCoordinate.fromTransferable({
        lng: coords.lng,
        lat: coords.lat,
        crs: coords.crs,
      }),
    ),
    startDatetime: DateTime_fromTransferable(req.startDatetime),
    duration: req.duration ? Duration_fromTransferable(req.duration) : undefined,
    temporalResolution: req.temporalResolution ? Duration_fromTransferable(req.temporalResolution) : undefined,
    boundingBoxLimit: req.boundingBoxLimit ? Area.fromTransferable(req.boundingBoxLimit) : null,
  };
}

import { networkCaches } from "@/cache/GlobalCache";
import { Area, CoordinateSystem, type WfsRequest } from "@mm/api.meteomatics.com";
import { WORLD_BBOX } from "@mm/api.meteomatics.com";
import type { WfsCapabilities } from "@mm/api.meteomatics.com/models/WfsCapabilities";
import { useCallback, useEffect, useState } from "react";

export function useCapabilities() {
  const [pending, setPending] = useState(false);
  const [capabilities, setCapabilities] = useState<WfsCapabilities>();

  const constructRequest = useCallback((): WfsRequest<CoordinateSystem.WGS84, "GetCapabilities"> => {
    // Fetch capabilities for whole map, because GetCapabilities endpoint doesn't support that parameter
    const area = new Area(CoordinateSystem.WGS84, {
      east: 180,
      west: -180,
      north: 90,
      south: -90,
    });

    return {
      requestType: "GetCapabilities",
      area: area,
      model: "",
      boundingBoxLimit: WORLD_BBOX,
    };
  }, []);

  const requestCapabilities = useCallback(async () => {
    setPending(true);
    const request = constructRequest();
    const { asynchronous } = networkCaches.capabilities_cache.retrieveWfsCapabilities(request);

    const capabilitiesList = (await asynchronous) as WfsCapabilities;
    setCapabilities(capabilitiesList);
    setPending(false);
  }, [constructRequest]);

  useEffect(() => {
    requestCapabilities();
  }, [requestCapabilities]);

  return {
    capabilities,
    pending,
  };
}

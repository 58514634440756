import type { CartographicMaterialFormData } from "@/overlay/components/SmartForm/LayerForms/CartographicMaterialLayerForm/view";
import type { LayerUpdateControllerProp } from "@/overlay/components/SmartForm/LayerForms/LayerFormMapping";
import { useAppDispatch } from "@/reducer";
import { setLayersProps } from "@/reducer/MapsState";
import type { BackgroundMapLayer } from "@mm/metx-workbench.meteomatics.com";
import type { SubmitHandler } from "react-hook-form";
import { CartographicMaterialUpdateForm } from "../view/CartographicMaterialLayerUpdateForm";

/**
 * Handles all the logic for map background implementation update.
 * Note:
 * This is a controller, meaning that we should generally NOT delegate layer creation logic
 * to the parent component by passing callback from parent etc.
 */
export function CartographicMaterialLayerUpdateController(props: LayerUpdateControllerProp<BackgroundMapLayer>) {
  const dispatch = useAppDispatch();

  const onSubmit: SubmitHandler<CartographicMaterialFormData> = (formData) => {
    dispatch(setLayersProps({ layerId: props.layer.id, id: props.mapId.map, props: formData.layerData }));
  };

  return (
    <CartographicMaterialUpdateForm
      defaultFormData={props.layer}
      onSubmit={onSubmit}
      submitMode="onChange"
      showAllConfig={true}
    />
  );
}

import type mapboxgl from "mapbox-gl";
import type { AnyLayer, AnyLayout, AnyPaint } from "mapbox-gl";

/**
 * Add the mapbox layers to the given map
 * @param map
 * @param mapboxLayerDescs
 * @returns
 */
export function addLayerGroup(map: mapboxgl.Map, mapboxLayerDescs: AnyLayer[]) {
  const addedLayerIds = [];
  for (let i = 0; i < mapboxLayerDescs.length; i++) {
    map.addLayer(mapboxLayerDescs[i]);
    addedLayerIds.push(mapboxLayerDescs[i].id);
  }
  return addedLayerIds;
}

/**
 * Remove all the mapbox layers to the given map
 * @param map
 * @param mapboxLayerDescs
 * @returns
 */
export function removeLayerGroup(map: mapboxgl.Map, mapboxLayerGroup: string[]) {
  for (let i = 0; i < mapboxLayerGroup.length; i++) {
    map.removeLayer(mapboxLayerGroup[i]);
  }
}

/**
 * Move all the given layers BELOW the layer with given beforeLayerId
 * @param map
 * @param mapboxLayerGroup
 * @param mapboxLayerId
 * @param props
 */
export function moveZIndex(map: mapboxgl.Map, mapboxLayerGroup: string[], beforeLayerId?: string) {
  for (let i = 0; i < mapboxLayerGroup.length; i++) {
    const mapboxLayerId = mapboxLayerGroup[i];
    map.moveLayer(mapboxLayerId, beforeLayerId);
  }
}

/**
 * Given a mapbox layer group, update all layers' layout properties.
 * @param map
 * @param layerGroup
 * @param props
 */
export function updateMapboxLayoutProperties<LayoutProps extends AnyLayout>(
  map: mapboxgl.Map,
  layerGroup: string[],
  props: LayoutProps,
) {
  for (let i = 0; i < layerGroup.length; i++) {
    const mapboxLayerId = layerGroup[i];
    for (const [name, value] of Object.entries(props)) {
      map.setLayoutProperty(mapboxLayerId, name, value);
    }
  }
}

/**
 * Given a mapbox layer group, update all layers' paint properties.
 * @param map
 * @param layerGroup
 * @param props
 */
export function updateMapboxPaintProperties<PaintProp extends AnyPaint>(
  map: mapboxgl.Map,
  layerGroup: string[],
  props: PaintProp,
) {
  for (let i = 0; i < layerGroup.length; i++) {
    const mapboxLayerId = layerGroup[i];
    for (const [name, value] of Object.entries(props)) {
      map.setPaintProperty(mapboxLayerId, name, value);
    }
  }
}

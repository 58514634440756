import { type DurationDesc, resolutionOptions } from "@/time";
import { Duration } from "luxon";

export interface TemporalResolutionPickerProps {
  currentResolution: Duration;
  options?: DurationDesc[];
  onResolutionChange: (resolution: Duration) => void;
}

/**
 * Let's a user select from a list of durations
 */
export function TemporalResolutionPicker(props: TemporalResolutionPickerProps) {
  function onChange(temporalResolutionIso: string) {
    props.onResolutionChange(Duration.fromISO(temporalResolutionIso));
  }

  return (
    <select
      value={props.currentResolution.toISO()}
      onChange={(e) => onChange(e.currentTarget.value)}
      data-testid={"interval-select"}
    >
      {(props.options || resolutionOptions).map(({ displayName, isoFormatted }) => (
        <option key={isoFormatted} value={isoFormatted}>
          {displayName()}
        </option>
      ))}
    </select>
  );
}

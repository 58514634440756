import type { AviationLayer, GuiTimeZone } from "@mm/metx-workbench.meteomatics.com";

import { type CheckedProps, LayerBase, type PropsChecker } from "../../LayerBase";

import { safeFmt } from "@/utility/safeFmt";
import type { DateTime } from "luxon";
import type { GeoJSONSourceRaw, SymbolLayer, SymbolPaint } from "mapbox-gl";
import type { ScenePublicApi } from "../../Compositor";

import { emptyFeatureCollection } from "@/geojson";
import { AviationTypes } from "@/layers/utility/aviationType";
import { ChangeDetector } from "../../ChangeDetector";
import type { IBaseSubLayer } from "./sublayers/BaseSubLayer";
import { InternationalSigmetLayer } from "./sublayers/internationalSigmetLayer";
import { MetarLayer } from "./sublayers/metarLayer";
import { TafLayer } from "./sublayers/tafLayer";

export class VectorAviationLayerImpl extends LayerBase<AviationLayer> {
  private displayDateTime: ChangeDetector<string>;
  private activeSubLayerOutlet?: IBaseSubLayer;

  constructor(uid: number, props: AviationLayer, scene: ScenePublicApi, timezone: GuiTimeZone) {
    super(uid, props, scene, timezone);
    const displayDateTime = this.scene.getDisplayTimeWithOffset();
    this.displayDateTime = new ChangeDetector(displayDateTime.toISO());

    // Workaround for fixing z-order, without big refactoring on Compositor
    // Problem is, that sources and layers are created async, and compositor
    // isn't aware of it, causing wrong z-order on initial load
    // TODO Refactor Compositor setLayerStackDescription to support
    // async created sources and layers
    this.addPlaceholderLayer();

    switch (props.aviation_type) {
      case AviationTypes.metar:
        this.activeSubLayerOutlet = new MetarLayer(
          uid,
          this.scene,
          this.props,
          timezone,
          displayDateTime,
          this.placeholderId,
        );
        break;
      case AviationTypes.taf:
        this.activeSubLayerOutlet = new TafLayer(
          uid,
          this.scene,
          this.props,
          timezone,
          displayDateTime,
          this.placeholderId,
        );
        break;
      case AviationTypes.isigmet:
        this.activeSubLayerOutlet = new InternationalSigmetLayer(
          uid,
          this.scene,
          this.props,
          timezone,
          displayDateTime,
          this.placeholderId,
        );
        break;
    }
  }

  updateMapboxPaintProperties(layerId: string, props: SymbolPaint) {
    if (this.scene.getMapboxMap().getLayer(layerId)) {
      for (const [name, value] of Object.entries(props)) {
        try {
          this.scene.getMapboxMap().setPaintProperty(layerId, name, value);
        } catch (e) {
          console.warn(e);
        }
      }
    }
  }

  getActiveWeatherParametersAsString() {
    return [
      {
        model: "",
        parameter: this.props.aviation_type,
      },
    ];
  }

  checker(): PropsChecker<AviationLayer, LayerBase<AviationLayer>> {
    type CheckerProps = CheckedProps<AviationLayer>;
    const checkLayerProp = (property: CheckerProps) => (prev: any, curr: any) => {
      const changed = prev[property] !== curr[property];
      if (changed) {
        this.setLayerProps({ [property]: curr[property] });
        switch (property) {
          case "aviation_type":
            break;
          case "show":
            this.activeSubLayerOutlet?.toggleVisibility(curr.show);
            break;
          case "opacity":
            // not implement in Layerconfig
            break;
          case "text_size":
            if (this.activeSubLayerOutlet) {
              this.activeSubLayerOutlet.updateSize(curr[property]);
            }
            break;
          case "calibrated":
            break;
          case "custom_options":
            break;
          default: {
            const _exhaustive: never = property;
            return _exhaustive;
          }
        }
      }
      return changed;
    };
    return {
      text_size: checkLayerProp("text_size"),
      aviation_type: checkLayerProp("aviation_type"),
      calibrated: checkLayerProp("calibrated"),
      opacity: checkLayerProp("opacity"),
      show: checkLayerProp("show"),
      custom_options: checkLayerProp("custom_options"),
    };
  }
  isRebuffering(time: DateTime): boolean {
    return !this.activeSubLayerOutlet?.hasAllSourcesLoaded(time);
  }

  beforeRender(): void {
    if (this.displayDateTime.changed(this.scene.getDisplayTimeWithOffset().toISO())) {
      this.activeSubLayerOutlet?.expireTiles();
    }
  }

  removeLayer(): void {
    const map = this.scene.getMapboxMap();

    if (map.getLayer(this.placeholderId)) {
      map.removeLayer(this.placeholderId);
    }

    if (map.getSource(this.placeholderId)) {
      map.removeSource(this.placeholderId);
    }

    if (this.activeSubLayerOutlet) {
      this.activeSubLayerOutlet.dispose();
    }
  }

  fetchData(timeFrame: DateTime): Promise<void> {
    if (this.activeSubLayerOutlet) {
      return this.activeSubLayerOutlet.loadTiles(timeFrame);
    }

    return Promise.resolve();
  }

  moveZIndex(beforeLayerId?: string) {
    const map = this.scene.getMapboxMap();
    if (map.getLayer(this.placeholderId)) {
      map.moveLayer(this.placeholderId, beforeLayerId);
    }
    this.activeSubLayerOutlet?.moveLayers(beforeLayerId);
  }

  humanReadableId(): string {
    return safeFmt`metx.aviationlayer#${this.uid}#${this.props.aviation_type}`;
  }

  setTimezone(value: GuiTimeZone): void {
    this.timezone = value;
    this.activeSubLayerOutlet?.setTimezone(value);
  }

  private addPlaceholderLayer() {
    if (this.scene.getMapboxMap().getLayer(this.placeholderId)) {
      return;
    }
    const source: GeoJSONSourceRaw = {
      type: "geojson",
      data: emptyFeatureCollection,
    };

    this.scene.getMapboxMap().addSource(this.placeholderId, source);

    const placeholderLayer: SymbolLayer = {
      id: this.placeholderId,
      type: "symbol",
      source: this.placeholderId,
    };
    this.scene.getMapboxMap().addLayer(placeholderLayer);
  }

  private get placeholderId(): string {
    return `metx.aviationlayer#placeholder#${this.uid}`;
  }

  get mapboxIndex(): string {
    return this.placeholderId;
  }
}

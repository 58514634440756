export enum SynchrounousState {
  StillPending = 0,
  PermanentlyFailed = 1,
  NotRequested = 2,
}
export interface AsyncResult<T> {
  synchronous: T | SynchrounousState;
  asynchronous: Promise<T>;
  cancel?: () => void;
}

export function createResolvedAsyncResult<T>(synchronous: T): AsyncResult<T> {
  return { synchronous, asynchronous: Promise.resolve(synchronous) };
}

export function createPermanentlyFailedAsyncResult<T>(failureReason: any = null): AsyncResult<T> {
  return { synchronous: SynchrounousState.PermanentlyFailed, asynchronous: Promise.reject(failureReason) };
}

export function createPendingAsyncResult<T>(asynchronous: Promise<T>, cancel?: () => void): AsyncResult<T> {
  return { synchronous: SynchrounousState.StillPending, asynchronous, cancel };
}

export function createNotRequestedAsyncResult<T>(synchronous: T, cancel?: () => void): AsyncResult<T> {
  return { synchronous: SynchrounousState.NotRequested, asynchronous: Promise.resolve(synchronous), cancel };
}

export function mapAsyncResult<T, R>(
  res: AsyncResult<T>,
  fn: (_: T | SynchrounousState.PermanentlyFailed) => R,
  fnAsync?: (_: T) => R | Promise<R>,
): AsyncResult<R> {
  if (res.synchronous !== SynchrounousState.StillPending && res.synchronous !== SynchrounousState.NotRequested) {
    return createResolvedAsyncResult(fn(res.synchronous));
  }
  return createPendingAsyncResult(res.asynchronous.then(fnAsync ?? fn));
}

export function promiseToAsyncResult<T>(promise: Promise<T>): AsyncResult<T> {
  const asyncRes: AsyncResult<T> = {
    synchronous: SynchrounousState.StillPending,
    asynchronous: promise,
  };

  promise.then((v) => {
    asyncRes.synchronous = v;
  });
  promise.catch((v) => {
    asyncRes.synchronous = SynchrounousState.PermanentlyFailed;
  });

  return asyncRes;
}

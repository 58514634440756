import { fetchTab } from "@/api/hooks/tab";
import { createMapWithViewport } from "@/api/hooks/tools/map";
import type { MapId, Viewport } from "@mm/metx-workbench.meteomatics.com";
import { type PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import type { RootState } from ".";

export type ViewportsState = {
  [id: number]: Viewport & { tabId: number };
};

const initialState: ViewportsState = {};

const viewportSlice = createSlice({
  name: "viewports",
  initialState: initialState as ViewportsState,
  reducers: {
    setViewport(
      state,
      action: PayloadAction<{
        id: MapId;
        viewportId: number;
        viewport: Omit<Viewport, "id_profile" | "id">;
        isMapSync?: boolean;
      }>,
    ) {
      const { id, viewportId, viewport } = action.payload;
      state[viewportId] = {
        ...viewport,
        id_profile: id.profile,
        id: viewportId,
        lastUpdatedBy: id.map,
        tabId: id.tab,
      };
    },
    updateViewport(state, action: PayloadAction<{ viewport: Partial<Viewport>; tabId: number; viewportId: number }>) {
      const { viewport: partialViewport, tabId, viewportId } = action.payload;
      const viewport = state[viewportId];
      state[viewport.id] = {
        ...viewport,
        ...partialViewport,
        tabId: tabId,
      };
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchTab.fulfilled, (state, action) => {
      const tab = action.payload;
      for (const key in state) {
        delete state[+key];
      }
      for (const viewport of tab.viewports) {
        state[viewport.id] = { ...viewport, tabId: tab.id };
      }
    });
    builder.addCase(createMapWithViewport.fulfilled, (state, action) => {
      const viewport = action.payload.viewport;
      const tabId = action.payload.tool.id_tab;
      state[viewport.id] = { ...viewport, tabId: tabId };
    });
  },
});

const selectViewports = (state: RootState) => state.tabGroup.present.viewports;

const makeSelectViewportsByTabId = createSelector(
  [selectViewports, (state: RootState, tabId: number) => tabId],
  (viewports, tabId) => Object.values(viewports).filter((viewport) => viewport.tabId === tabId),
);

export function useViewports(tabId: number) {
  return useSelector((state: RootState) => makeSelectViewportsByTabId(state, tabId));
}

const makeSelectViewportById = createSelector(
  [selectViewports, (state: RootState, id: number) => id],
  (viewports, id) => viewports[id],
);

export function useViewport(id: number) {
  return useSelector((state: RootState) => makeSelectViewportById(state, id));
}

export const { setViewport, updateViewport } = viewportSlice.actions;

export default viewportSlice.reducer;

import * as LayersApi from "@mm/api-layers.meteomatics.com";
import * as GeocoderApi from "@mm/geocoder.meteomatics.com";
import { ENV } from "./mainEnv";

//////////////////////////////////////////////////////////////
// Configuration for the microservices that frontend uses. //
//////////////////////////////////////////////////////////////

export const LayersApiConfiguration = new LayersApi.Configuration(
  ENV.baseUrlApiLayers != null ? { basePath: ENV.baseUrlApiLayers } : {},
);

export const GeocoderApiConfiguration = new GeocoderApi.Configuration(
  ENV.baseUrlGeocoder != null ? { basePath: ENV.baseUrlGeocoder } : {},
);

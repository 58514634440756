import { Api } from "@/api/Api";
import { ProvideAuth } from "@/auth";
import { ENV } from "@/env";
import { FeatureHubProvider } from "@/feature-flags";
import { sentryBeforeSendEvent, sentryBeforeSendTransaction } from "@/sentry/SentryConfig";
import { Configuration as EnergyConfig } from "@mm/forecast-data.meteomatics.com";
import { Configuration } from "@mm/metx-workbench.meteomatics.com";
import { OpenFeature } from "@openfeature/react-sdk";
import {
  type BrowserOptions,
  globalHandlersIntegration,
  init,
  reactRouterV5BrowserTracingIntegration,
} from "@sentry/react";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

if (process.env.REACT_APP_FEATURE_HUB_URL && process.env.REACT_APP_FEATURE_HUB_API_KEY) {
  OpenFeature.setProvider(
    new FeatureHubProvider(process.env.REACT_APP_FEATURE_HUB_URL, process.env.REACT_APP_FEATURE_HUB_API_KEY),
  );
}

const sentryConfig: BrowserOptions = {
  dsn: process.env.REACT_APP_METX_SENTRY_DSN,
  /* Ignore all Non-Errors
   * Non-Error is caused by rejected promise, that doesn't return Error instance
   * In this case there is nothing sentry can log out, so best and only way is
   * to just ignore theses "non-errors" and wrote promise rejections correctly.
   */
  ignoreErrors: ["Non-Error exception captured", "Non-Error promise rejection captured"],
  // tracingOrigins is used to pass cors header
  integrations: [
    reactRouterV5BrowserTracingIntegration({
      history,
    }),
    globalHandlersIntegration({
      onerror: true,
      onunhandledrejection: true,
    }),
  ],
  tracesSampleRate: Number.parseFloat(process.env.REACT_APP_SENTRY_TRACE_RATE || "0.1"),
  environment: process.env.NODE_ENV,
  beforeSendTransaction: sentryBeforeSendTransaction,
  beforeSend: sentryBeforeSendEvent,
};

if (process.env.NODE_ENV === "production") {
  sentryConfig.release = process.env.REACT_APP_SENTRY_RELEASE || "unknown";
}

init(sentryConfig);

// Create singleton to access API
Api.initialize(
  new Configuration({
    basePath: ENV.baseUrlMetx,
    accessToken: () => {
      const authLocalStorageStr = window.localStorage.getItem("auth");
      if (!authLocalStorageStr) {
        return "";
      }
      const authJson = JSON.parse(authLocalStorageStr);
      if (!authJson || !authJson?.token?.access_token) {
        return "";
      }
      return authJson.token.access_token;
    },
  }),
  new EnergyConfig({
    basePath: ENV.baseUrlEnergyForecast,
    accessToken: () => {
      const authLocalStorageStr = window.localStorage.getItem("auth");
      if (!authLocalStorageStr) {
        return "";
      }
      return JSON.parse(authLocalStorageStr).token.access_token;
    },
  }),
);

const container = document.getElementById("root");
if (container) {
  const root = createRoot(container);
  root.render(
    <StrictMode>
      <ProvideAuth>
        <App />
      </ProvideAuth>
    </StrictMode>,
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

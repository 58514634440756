import type { LayerUpdateControllerProp } from "@/overlay/components/SmartForm/LayerForms/LayerFormMapping";
import {
  type WindAnimationFormData,
  WindAnimationLayerForm,
} from "@/overlay/components/SmartForm/LayerForms/WindAnimationLayerForm/view";
import { useAppDispatch } from "@/reducer";
import { setLayersProps } from "@/reducer/MapsState";
import type { WindAnimationLayer } from "@mm/metx-workbench.meteomatics.com";
import type { SubmitHandler } from "react-hook-form";

/**
 * Handles all the logic for wind animation update.
 * Note:
 * This is a controller, meaning that we should generally NOT delegate layer creation logic
 * to the parent component by passing callback from parent etc.
 */
export function WindAnimationLayerUpdateController(props: LayerUpdateControllerProp<WindAnimationLayer>) {
  const dispatch = useAppDispatch();

  const onSubmit: SubmitHandler<WindAnimationFormData> = (formData) => {
    dispatch(setLayersProps({ layerId: props.layer.id, id: props.mapId.map, props: formData.layerData }));
  };

  return (
    <WindAnimationLayerForm
      defaultFormData={props.layer}
      onSubmit={onSubmit}
      submitMode="onChange"
      showAllConfig={true}
    />
  );
}

import { ENV } from "@/env";
import type { Plot } from "@/reducer/client-models";
import type {
  CartographicMap,
  CartographicMapUpdate,
  CountryPlotSchema,
  EnergyPlot,
  Profile,
  ProfileUpdate,
  SlimProfile,
  Tab,
  TabUpdate,
  TephigramSchema,
} from "@mm/metx-workbench.meteomatics.com";
import { omit } from "lodash";

export function removeTimestampsFromProfileAndTabs(profile: Profile): ProfileUpdate {
  return {
    id: profile.id,
    tabs: profile.tabs.map((tab) => removeTimestampsFromTab(tab)),
    title: profile.title,
    tab_active: profile.tab_active,
  };
}

export function removeTimestampsFromProfile(profile: SlimProfile): ProfileUpdate {
  return {
    id: profile.id,
    title: profile.title,
    tab_active: profile.tab_active,
  };
}

export function removeTimestampsFromTab(tab: Tab): TabUpdate {
  const { time_created, time_updated, ...newTab } = tab;
  return {
    ...newTab,
    maps: newTab.maps.map((cartographicMap) => removeTimestampsFromMap(cartographicMap)),
    plots: newTab.plots.map((plot) => removeTimestampsFromPlot(plot as Plot)),
    energy_plots: newTab.energy_plots.map((energy_plot) => removeTimestampsFromPlot(energy_plot)),
    tephigrams: newTab.tephigrams.map((tephigram) => removeTimestampsFromPlot(tephigram)),
    country_plots: newTab.country_plots.map((country_plot) => removeTimestampsFromPlot(country_plot)),
  };
}

export function removeTimestampsFromMap(cartographicMap: CartographicMap): CartographicMapUpdate {
  const { time_created, time_updated, layers, ...newMap } = cartographicMap;
  const newLayers = layers?.map((layer) => {
    const { time_created, time_updated, ...newLayer } = layer;
    return newLayer;
  });
  return { ...newMap, layers: newLayers };
}

export function removeTimestampsFromPlot<T extends Plot | EnergyPlot | TephigramSchema | CountryPlotSchema>(plot: T) {
  return omit(plot, ["time_created", "time_updated"]);
}

/**
 * In order to call an external API, we need to go through our own backend proxy.
 * This is because we're using SharedArrayBuffer feature for video rendering which prevents
 * cross origin requests for security reason.
 *
 * Note: Use this function to call the proxy endpoint outside React components.
 * In React components, use useProxyApi hook.
 */
export function getProxyUrl(url: string) {
  // Note: Encoding the query param URL is important, otherwise
  // some query parameters within the original URL can get stripped off
  // as the browser misinterpret the end of URL.
  return `${ENV.baseUrlMetx}/api/v1/proxy?url=${encodeURIComponent(url)}`;
}
